import PropTypes from "prop-types";
import { useState, useCallback } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Tooltip, Avatar, Box } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { projectProps } from "@shared/props/project";
import FSTGTypography from "@shared/theme/typography";

import CollaboratorMenu from "./CollaboratorMenu/CollaboratorMenu";

const useStyles = makeStyles({
  avatarContainer: {
    cursor: "pointer",
  },
  avatar: {
    width: 35,
    height: 35,
    fontSize: FSTGTypography.fontSize_1_3,
    lineHeight: FSTGTypography.lineHeight_1_3,
    opacity: 0.9,
    "&:hover": {
      opacity: 1,
    },
  },
});

function Collaborator({ project, collaborator, index, avatarClassName }) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState();

  const openMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Tooltip title={collaborator.displayName}>
        <Box
          className={classnames(classes.avatarContainer, avatarClassName)}
          ml={-1.25}
          zIndex={index}
          onClick={openMenu}
          data-testid="project-collaborator"
        >
          <Avatar
            user={collaborator}
            className={classes.avatar}
            data-testid="project-collaborator-avatar"
          />
        </Box>
      </Tooltip>
      {anchorEl && (
        <CollaboratorMenu
          project={project}
          closeMenu={closeMenu}
          menuAnchorEl={anchorEl}
          collaborator={collaborator}
        />
      )}
    </>
  );
}

Collaborator.propTypes = {
  collaborator: userProp.isRequired,
  index: PropTypes.number.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  avatarClassName: PropTypes.string,
};

Collaborator.defaultProps = {
  avatarClassName: "",
};

export default Collaborator;
