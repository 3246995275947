import Bowser from "bowser";
import isPlainObject from "lodash/isPlainObject";
import isString from "lodash/isString";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import * as CloudWatchLogger from "./CloudWatchLogger.js";
import logToConsole from "./ConsoleLogger.js";
import logToFullstory from "./FullstoryLogger.js";

const LEVELS = {
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
};

export let instance;

export function initialize() {
  const config = window.fs.config;
  const browser = Bowser.getParser(window.navigator.userAgent);
  const context = {
    env: config.appEnv,
    version: config.appVersion,
    userAgent: {
      browser: browser.getBrowser(),
      os: browser.getOSName(),
    },
  };

  const loggers = [];

  function log(level, tag, message, meta) {
    if (!isString(tag)) {
      throw new Error("tag should be a string");
    }
    if (!isString(message)) {
      throw new Error("message should be a string");
    }
    if (meta && !isPlainObject(meta)) {
      throw new Error("meta should be an object");
    }
    meta = Object.assign({}, meta, context);
    if (window._fs_loaded) {
      meta.fullstorySession = window.FS.getCurrentSessionURL(true);
    }
    for (const log of loggers) {
      log(level, tag, message, meta);
    }
  }

  instance = {};
  Object.keys(LEVELS).forEach((level) => {
    if (LEVELS[level] >= LEVELS[config.logger.level]) {
      instance[level] = log.bind(null, level);
    } else {
      instance[level] = () => {};
    }
  });

  eventService.addListener(
    EVENT_NAMES.USER.AUTHENTICATED,
    ({ eventData: { user } }) => {
      Object.assign(context, {
        userId: user._id,
      });
    }
  );

  if (config.logger.handlers.includes("console")) {
    loggers.push(logToConsole);
  }
  if (config.logger.handlers.includes("fullstory")) {
    loggers.push(logToFullstory);
  }
  if (config.logger.handlers.includes("cloudwatch")) {
    // don't wait, add cloud watch when it finishes initializing
    (async () => {
      try {
        await CloudWatchLogger.initialize();
        loggers.push(CloudWatchLogger.instance.log);
      } catch (error) {
        console.warn("failed to initialize cloud watch logger", error);
      }
    })();
  }
}
