/* eslint-disable complexity */
import PropTypes from "prop-types";
import { useCallback, useState, useMemo, memo } from "react";

import { makeStyles, useTheme } from "@mui/styles";
import CollapseButton from "@workflow/components/CollapseButton/CollapseButton";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import SectionHeaderMenu from "@workflow/components/SectionHeaderMenu/SectionHeaderMenu";
import SectionName from "@workflow/components/SectionName/SectionName";
import FileButton from "@workflow/components/UploadFileButton/VariantButton/FileButton/FileButton";
import useUploadPanelDialog from "@workflow/components/UploadPanelDialog/useUploadPanelDialog";
import sectionService from "@workflow/services/sectionService";
import classnames from "classnames";
import first from "lodash/first";

import { Box } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import { useMediaQuery } from "@shared/hooks";
import { projectProps, sectionProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import errorHandlerService from "@shared/services/errorHandler";

import SectionFileCount from "./SectionFileCount/SectionFileCount";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    cursor: "default",
    clipPath: "inset(0px -15px 0px 0px)",
    [theme.breakpoints.up("md")]: {
      left: 0,
    },
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  addSectionLabel: {
    paddingLeft: 10,
    "& > div:first-child": {
      height: 28,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  addSectionLabelEditing: {
    flexGrow: 1,
    maxWidth: "inherit",
  },
  addSectionLabelCollapsed: {
    color: theme.color.gray[500],
  },
  headerIndicator: {
    height: "1px !important",
    transform: "scaleY(75)",
  },
  tooltipContainerDisabled: {
    width: "auto !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: "34%",
    },
  },
  uploadFileButton: {
    height: 28,
    marginTop: 5,
    minWidth: 110,
    marginLeft: 8,
  },
}));

function SectionHeader({
  section,
  project,
  steps,
  className,
  onCollapseSection,
}) {
  const { width, minWidth, maxWidth } = useFileCardResized();
  const theme = useTheme();
  const classes = useStyles({
    width,
    minWidth,
    maxWidth,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isEditing, setIsEditing] = useState(false);
  const canUpload =
    !project.isArchived &&
    project.permissions.uploadFiles &&
    !section.isCollapsed;
  const canManage = !project.isArchived && project.permissions.manageSections;

  const defaultStep = first(steps);
  const defaultStepIds = useMemo(
    () => (defaultStep ? [defaultStep.id] : []),
    [defaultStep]
  );

  const { openUploadPanelDialog } = useUploadPanelDialog({
    projectId: project.id,
    sectionId: section.id,
    stepIds: defaultStepIds,
    teamId: project.teamId,
    page: pagesEnum.PROJECT_DASHBOARD,
    component: UPLOAD_COMPONENT_TYPE.POPOVER,
    className: classes.uploadFileButton,
  });

  const handleChangeEditMode = useCallback((value) => {
    setIsEditing(value);
  }, []);

  /* istanbul ignore next */
  const isEditableLabelDisabled = !canManage || (isMobile && !isEditing);

  const renameSection = useCallback(
    async (name) => {
      try {
        await sectionService.renameProjectSection(project.id, section.id, name);
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [section.id, project.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box
      zIndex={47}
      position="sticky"
      top={50}
      boxSizing="border-box"
      minHeight={37}
      display="flex"
      alignItems="center"
      bgcolor={theme.color.gray[50]}
      data-testid="section-header-root"
      className={classnames(classes.sectionHeader, className)}
    >
      <CollapseButton
        isCollapsed={section.isCollapsed}
        onCollapse={onCollapseSection}
        textCollapsed="SECTIONS.COLLAPSE.TOOLTIP.SHOW"
        textExpanded="SECTIONS.COLLAPSE.TOOLTIP.HIDE"
      />

      <SectionName
        text={section.name}
        onChange={handleChangeEditMode}
        className={classnames("tour__section", classes.editableLabel, {
          [classes.addSectionLabel]: !isEditableLabelDisabled,
          [classes.addSectionLabelEditing]: isEditing,
          [classes.addSectionLabelCollapsed]: section.isCollapsed,
        })}
        tooltipContainerDisabledClassName={classes.tooltipContainerDisabled}
        isDisabled={isEditableLabelDisabled}
        isEditing={isEditing}
        onSave={renameSection}
      />

      {!isEditing && (
        <>
          <Box
            display="flex"
            flexDirection="row"
            flexGrow={1}
            ml={0.875}
            alignItems="center"
          >
            {/* eslint-disable-next-line eqeqeq */}
            {section.fileCount != null && (
              <SectionFileCount
                isCollapsed={section.isCollapsed}
                fileCount={section.fileCount}
              />
            )}

            {canManage && (
              <SectionHeaderMenu
                section={section}
                project={project}
                onRename={handleChangeEditMode}
              />
            )}
          </Box>
          {canUpload && <FileButton onClick={openUploadPanelDialog} />}
        </>
      )}
    </Box>
  );
}

SectionHeader.propTypes = {
  section: sectionProps.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  className: PropTypes.string,
  onCollapseSection: PropTypes.func.isRequired,
};

SectionHeader.defaultProps = {
  className: "",
};

export default memo(SectionHeader);
