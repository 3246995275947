import { useEffect } from "react";
import { useSearchParams, useLocation, useParams } from "react-router-dom";

import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import authenticationService from "@supporting/services/authentication";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";

const appCallbackEndpoints = {
  slack: "/integrations/slack-app/auth/callback",
  "monday-app": "/integrations/monday-app/auth/callback",
  "google-drive": "/integrations/google-drive/auth/callback",
  "microsoft-teams": "/integrations/microsoft-teams/auth/callback",
};

export default function IntegrationAuthorizationCallback() {
  const { goTo } = useNavigation();

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const { appId } = useParams();
  const selectedTeam = useSelectedTeam();

  useEffect(() => {
    const stateParams = Object.fromEntries(searchParams.entries());

    const redirectUser = async () => {
      try {
        const appSessionStorageState = sessionStorage.getItem(
          "integrations.slack.state"
        );

        const redirectUrl = await backend.get(appCallbackEndpoints[appId], {
          teamId: selectedTeam._id,
          ...(appSessionStorageState && {
            browserStateId: appSessionStorageState,
          }),
          ...stateParams,
          ...location.state,
        });

        const userId = authenticationService.fetchSession().userId;

        if (appId === "slack") {
          analytics.identify(userId, {
            integrationsAppsSlack: true,
          });
        } else if (appId === "google-drive") {
          analytics.identify(userId, {
            integrationsAppsGoogleDrive: true,
          });
        }

        analytics.track(analytics.ACTION.CONNECTED, appId, {
          appTitle: appId,
        });

        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          goTo(ROUTE_STATES.INTEGRATIONS_DETAILS, { params: { appId } });
        }
      } catch (error) {
        goTo(ROUTE_STATES.INTEGRATIONS_AUTHORIZATION_FAILED, {
          params: { appId },
        });
      }
    };

    redirectUser();
  }, [searchParams, location, goTo, appId, selectedTeam]);

  return null;
}
