import PropTypes from "prop-types";

import { userProp } from "@shared/props/authentication";
import { fileDataProp } from "@shared/props/fileData";

export const attachmentProps = PropTypes.shape({
  original: fileDataProp.isRequired,
  thumbnail: fileDataProp,
  transcoded: fileDataProp,
});

export const uploadProps = PropTypes.shape({
  uploading: PropTypes.bool.isRequired,
  uploadId: PropTypes.string.isRequired,
  fileData: fileDataProp,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
});

export const likeProps = PropTypes.shape({
  user: userProp,
  userId: PropTypes.string,
  createdTime: PropTypes.string,
});

export const commentShape = {
  id: PropTypes.string.isRequired,
  author: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
  isResolved: PropTypes.bool,
  teamOnly: PropTypes.bool,
  createdTime: PropTypes.string,
  updatedTime: PropTypes.string,
  authorId: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  annotation: PropTypes.arrayOf(PropTypes.object),
  attachments: PropTypes.arrayOf(attachmentProps),
  likes: PropTypes.arrayOf(likeProps),
  isLiked: PropTypes.bool,
  fileVersionId: PropTypes.string.isRequired,
  copiedFrom: PropTypes.shape({
    user: PropTypes.shape({ displayName: PropTypes.string }),
    copiedTime: PropTypes.string,
    originalComment: PropTypes.shape({
      id: PropTypes.string,
      fileVersionId: PropTypes.string,
    }),
    copyType: PropTypes.oneOf(["review-step", "version"]),
  }),
};
commentShape.replies = PropTypes.arrayOf(PropTypes.shape(commentShape));

export const commentProps = {
  isCommentReply: PropTypes.bool,
  comment: PropTypes.shape(commentShape),
  isReadOnlyComment: PropTypes.bool,
  isActiveComment: PropTypes.bool,
  highlighted: PropTypes.bool,
  isNewComment: PropTypes.bool,
  removeComment: PropTypes.func,
  searchTerms: PropTypes.string,
  version: PropTypes.object,
  addNewReply: PropTypes.func,
  updateCommentCompletionState: PropTypes.func,
  localUpdateCommentLikes: PropTypes.func,
  selectComment: PropTypes.func,
  selectAttachment: PropTypes.func,
  setAttachmentLightboxOpen: PropTypes.func,
  isSaving: PropTypes.bool,
  editExistingComment: PropTypes.bool,
  save: PropTypes.func,
  update: PropTypes.func,
  cancel: PropTypes.func,
  currentUser: userProp,
  toggleNewComment: PropTypes.bool,
  isSelected: PropTypes.bool,
  copyMode: PropTypes.oneOf(["review-step", "version"]),
  selectCommentToCopy: PropTypes.func.isRequired,
};
