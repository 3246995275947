import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as analytics } from "@shared/services/analytics";
import { instance as logger } from "@shared/services/logger";
import { history } from "@shared/services/navigation";

const TAG = "error-handler-service";

const handleError = (error) => {
  switch (error?.status) {
    case 401:
      logger.warn(TAG, "unauthorized error", { error });
      authenticationService.redirectToLogout();
      break;

    case 403:
      logger.warn(TAG, "forbidden error", { error });
      history.navigate(APP_ROUTES.ACCESS_DENIED.path);
      break;

    case 400:
      logger.info(TAG, "validation error", { error });
      if (error.message === "action has been blocked for security reasons") {
        toastService.sendToast({
          title: "ERROR.ACTION_BLOCKED_BY_SECURITY.TITLE",
          body: "ERROR.ACTION_BLOCKED_BY_SECURITY.BODY",
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
        analytics.track(
          analytics.ACTION.BLOCKED_BY,
          analytics.CATEGORY.SECURITY
        );
      } else {
        toastService.sendToast({
          title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
          body: "ERROR.ACTION_NOT_POSSIBLE.BODY",
          preset: toastService.PRESETS().ERROR,
        });
      }
      break;

    default:
      logger.error(TAG, "unexpected error", { error });
      toastService.sendToast({
        title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
        body: "ERROR.ACTION_NOT_POSSIBLE.BODY",
        preset: toastService.PRESETS().ERROR,
      });
  }
};

export default { handleError };
