import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import {
  ProjectTemplateGate,
  AddProjectGate,
} from "@supporting/hooks/useGatedFeature/featureGates";
import CreateProjectDialog from "@workflow/components/CreateProjectDialog/CreateProjectDialog";

import { Box } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import useDialogState from "@shared/hooks/useDialogState";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import AddProjectTemplateDialog from "@workflow/pages/ProjectTemplate/AddProjectTemplateDialog/AddProjectTemplateDialog";

function ProjectListMobileCreateMenu({ team }) {
  const createProjectDialog = useDialogState();
  const addProjectTemplateDialog = useDialogState();
  const { goTo } = useNavigation();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const { checkAndProceed: checkProjectAndProceed } = useGatedFeature({
    featureGate: AddProjectGate,
    currentValue: team.usedBillingLimits?.numberOfActiveProjects,
    teamId: team._id,
  });
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const addProject = useCallback(
    () => {
      createProjectDialog.openDialog();
      handleClose();
    },
    [handleClose] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleOpenMenu = useCallback((event) => {
    setAnchorEl(event.target);
  }, []);

  const addProjectTemplateHandler = useCallback(
    (template) => {
      analytics.track(
        analytics.ACTION.CREATED,
        analytics.CATEGORY.PROJECT_TEMPLATE
      );

      goTo("TEMPLATE_ID", {
        params: {
          projectTemplateId: template.id,
        },
      });
    },
    [goTo]
  );

  const { checkAndProceed: checkTemplatesAndProceed } = useGatedFeature({
    featureGate: ProjectTemplateGate,
    currentValue: team.usedBillingLimits?.numberOfProjectTemplates,
    teamId: team._id,
  });

  return (
    <Box>
      <IconButton
        onClick={handleOpenMenu}
        data-testid="create-folder-button"
        size="large"
      >
        <CreateNewFolderIcon />
      </IconButton>
      {createProjectDialog.isOpen && (
        <CreateProjectDialog cancel={createProjectDialog.closeDialog} />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="project-list-mobile-create-menu"
      >
        {team.permissions.canCreateProjects && (
          <MenuItem
            onClick={checkProjectAndProceed(addProject)}
            data-testid="create-new-project-menu-item"
          >
            {t("PROJECT.NEWPROJECT.CREATE")}
          </MenuItem>
        )}
        {team.permissions.canManageTemplates && (
          <MenuItem
            onClick={checkTemplatesAndProceed(
              addProjectTemplateDialog.openDialog
            )}
            data-testid="create-new-project-template-menu-item"
          >
            {t("PROJECT_TEMPLATES.LIST.LABEL_ADD")}
          </MenuItem>
        )}
      </Menu>

      {addProjectTemplateDialog.isOpen && (
        <AddProjectTemplateDialog
          cancel={addProjectTemplateDialog.closeDialog}
          answer={addProjectTemplateHandler}
        />
      )}
    </Box>
  );
}

ProjectListMobileCreateMenu.propTypes = {
  team: teamProp.isRequired,
};

export default ProjectListMobileCreateMenu;
