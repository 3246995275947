export default {
  //New color scheme approved by Product
  //Please refrain from adding new colors directly to this file
  gray: {
    50: "#f9fafb",
    100: "#f3f4f6",
    200: "#e5e7eb",
    250: "#edeef1",
    300: "#d1d5db",
    400: "#9ca3af",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#1f2937",
    900: "#111827",
  },

  red: {
    50: "#ffede8",
    100: "#fec8b3",
    200: "#fea380",
    300: "#fe7e4d",
    400: "#fd591a",
    500: "#fd4701",
    600: "#ca3901",
    700: "#b13201",
    800: "#7f2401",
    900: "#4c1500",
  },

  green: {
    50: "#f0fff6",
    100: "#e6f7eb",
    200: "#cceed7",
    300: "#99deaf",
    400: "#66cd87",
    500: "#00ac37",
    600: "#008a2c",
    700: "#006721",
    800: "#004516",
  },

  blue: {
    50: "#e6eefe",
    100: "#b5cdfc",
    200: "#84abfa",
    300: "#5389f9",
    400: "#3b71dd",
    500: "#2B6EF7",
    600: "#0847c9",
    700: "#06379c",
    800: "#042870",
    900: "#031a4a",
  },

  purple: {
    50: "#ebe8ff",
    100: "#f8eeff",
    200: "#e3bafd",
    300: "#d698fc",
    400: "#c875fb",
    500: "#ba53fa",
    600: "#9542c8",
    700: "#703296",
    800: "#4a2164",
  },

  gold: {
    50: "#fff7e9",
    100: "#fff0d4",
    400: "#cfb68d",
    500: "#a58a5d",
  },

  black: "#000000",
  white: "#ffffff",
  yellow: "#ffbf3e",

  orange: {
    50: "#fff3e0",
    100: "#ffe0b2",
    200: "#ffcc80",
    300: "#ffb74d",
    400: "#ffa726",
    500: "#ff9800",
    600: "#fb8c00",
    700: "#f57c00",
    800: "#ef6c00",
    900: "#e65100",
  },

  secondary: {
    50: "#f6fbff",
    100: "#ebf6ff",
    200: "#d1ecff",
    300: "#ADDDFF",
    400: "#84cbff",
    500: "#33A9FF",
    600: "#0093FF",
    700: "#0082E1",
    800: "#005899",
    900: "#003B66",
  },

  // Translucent
  "black-translucent-06": "rgba(0,0,0,0.06)",
  "black-translucent-60": "rgba(0,0,0,0.6)",
  "black-translucent-85": "rgba(0,0,0,0.85)",
  "black-translucent-87": "rgba(0,0,0,0.87)",
  "black-translucent-35": "rgba(0, 0, 0, 0.35)",
  "black-translucent-38": "rgba(0, 0, 0, 0.38)",
  "black-translucent-54": "rgba(0,0,0,0.54)",
  "silver-translucent": "rgba(0, 0, 0, 0.15)",
  "white-smoke-translucent": "rgba(0, 0, 0, 0.04)",
  "white-smoke-light-translucent": "rgba(0, 0, 0, 0.02)",
  "white-smoke-light-translucent-25": "rgba(237, 238, 241, 0.25)",
  "white-smoke-light-translucent-100": "rgba(207, 223, 215, 100)",
  "white-smoke-light-translucent-85": "rgba(248, 249, 251, 0.85)",
  "white-translucent-85": "rgba(255, 255, 255, 0.85)",
  "white-translucent-90": "rgba(255, 255, 255, 0.9)",
  "white-translucent-100": "rgba(255, 250, 243, 100)",
  "mint-cream-translucent": "rgba(0, 255, 90, 0.06)",
  "pigment-green-translucent-01": "rgba(0, 172, 55, 0.1)",
  "pigment-green-translucent": "rgba(0, 172, 55, 0.3)",
  "pigment-green-translucent-005": "rgba(0, 172, 55, 0.05)",
  "malachite1-translucent": "rgba(20, 172, 56, 0.49)",
  "malachite1-translucent-008": "rgba(20, 172, 56, 0.08)",
  "malachite1-translucent-50": "rgba(20, 172, 56, 0.5)",
  "dark-orange-translucent-01": "rgba(255, 144, 0, 0.1)",
  "dark-orange-translucent": "rgba(255, 144, 0, 0.3)",
  "neon-red-translucent": "rgba(255, 61, 6, 0.3)",
  "vermilion-translucent-50": "rgba(253, 71, 1, 0.5)",
  "dusty-gray-translucent": "rgba(155, 155, 155, 0.3)",
  "dusty-gray-translucent-018": "rgba(158, 158, 158, 0.18)",
  "green-translucent-90": "rgba(200, 255, 218, 0.9)",
  "chip-blue-translucent-008": "rgba(54, 160, 230, 0.08)",
  "chip-yellow-translucent-008": "rgba(255, 170, 0, 0.08)",

  // Transparent
  "white-transparent": "rgba(255, 255, 255, 0)",
  "white-smoke-light-transparent": "rgba(248, 249, 251, 0)",

  // Needed for drophint classes on Safari
  "gainsboro-transparent": "rgba(220,220,220,0)",
  "silver-transparent": "rgba(198, 198, 198, 0)",
  "pigment-green-transparent": "rgba(0,172,55,0)",
  "white-smoke-dark-transparent": "rgba(0, 0, 0, 0.06)",
  "green-transparent": "rgba(200, 255, 218, 0.5)",
};
