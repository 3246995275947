import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton as Button } from "@mui/lab";
import {
  Box,
  Dialog,
  Slider,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import { instance as fileStorageService } from "@supporting/services/fileStorageService";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

import { getCroppedImg } from "./canvautil";

const CropAvatar = ({ setIsOpenDialog, image, imageName }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const onClickSubmit = async () => {
    try {
      const avatar = await getCroppedImg(image, croppedAreaPixels);
      avatar.name = imageName;
      fileStorageService.processFile(avatar, "USER_AVATAR", {
        userId: authenticationService.fetchUser()._id,
      });
      setIsLoading(true);
    } catch (err) {
      setIsLoading(false);
      setIsOpenDialog(false);
    }
  };
  const cancelDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [setIsOpenDialog]);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  useEffect(() => {
    /* istanbul ignore next */
    function onUserAvatarUpdateFailed() {
      setIsLoading(false);
      setIsOpenDialog(false);
    }
    websocket.addListener(
      websocket.EVENT_NAMES.USER.AVATAR.UPDATE.SUCCEEDED,
      (event) => {
        eventService.emitEvent({
          eventName: EVENT_NAMES.USER.UPDATED,
          eventData: { user: event.user },
        });
        setIsOpenDialog(false, true);
      }
    );

    websocket.addListener(
      websocket.EVENT_NAMES.USER.AVATAR.UPDATE.FAILED,
      onUserAvatarUpdateFailed
    );
    eventService.addListener(
      EVENT_NAMES.UPLOAD.ERROR,
      onUserAvatarUpdateFailed
    );
  }, [setIsOpenDialog]);

  const content = (
    <Box width="100%" height="100%" margin={0}>
      <Box
        margin={0}
        width="100%"
        height="90%"
        display="flex"
        position="relative"
        flex-direction="column"
      >
        <Cropper
          image={image}
          zoom={zoom}
          crop={crop}
          onCropChange={setCrop}
          aspect={1}
          cropShape="round"
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Box>
      <Box>
        <Slider
          data-testid="crop-avatar-dialog-slider"
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(_, zoom) => setZoom(zoom)}
        />
      </Box>
    </Box>
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={cancelDialog}
      data-testid="crop-avatar-dialog"
    >
      <DialogTitle textAlign="center" fontWeight="fontWeight.medium">
        {t("SETTINGS.AVATAR.DIALOG.HEADLINE")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={cancelDialog}
        data-testid="dialog-cancel-icon"
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ px: 4, height: 400 }}>{content}</DialogContent>
      <DialogActions sx={{ pb: 4, pr: 4 }}>
        <Button
          color="primary"
          loading={isLoading}
          variant="contained"
          onClick={onClickSubmit}
          data-testid="crop-avatar-dialog-action-button"
        >
          {t("SETTINGS.AVATAR.DIALOG.CONFIRM")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
CropAvatar.propTypes = {
  image: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  setIsOpenDialog: PropTypes.func.isRequired,
};

export default CropAvatar;
