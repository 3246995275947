import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Paper, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CouponDetails from "@supporting/components/CouponDetails/CouponDetails";
import BILLING_INTERVAL from "@supporting/constants/billingIntervals";
import { instance as billingService } from "@supporting/services/billingService";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import KeyLock from "@assets/img/icons/outline-lock-24px.svg";

import {
  switchBillingRate,
  useTaxPaymentInfo,
} from "../CreditCardForm/useTaxPaymentInfo";

const useStyles = makeStyles((theme) => ({
  couponData: {
    gap: 8,
    margin: 16,
    display: "flex",
    borderRadius: 6,
    whiteSpace: "nowrap",
    backgroundColor: theme.color.white,
    fontSize: FSTGTypography.fontSize_1_3,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));

function CheckoutSummary({ couponData }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { price, plan } = useTaxPaymentInfo();

  const currencySymbol = billingService.getCurrencySymbol(plan.currency);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={2.5}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <SvgIcon color="default-light" component={KeyLock} />
        <Text
          variant="textSm"
          color="text.secondary"
          translate="BILLING.CHECKOUT.ENCRYPTION"
        />
      </Box>
      <Paper elevation={0} sx={{ alignSelf: "stretch", padding: 2 }}>
        <Text variant="textSm" translate="BILLING.CHECKOUT.PAYING" />
        <Text data-testid="total-price" variant="textSm">
          {`${currencySymbol}${Math.round(price)} ${
            plan.interval === BILLING_INTERVAL.MONTHLY
              ? t("BILLING.CHECKOUT.PER_MONTH")
              : t("BILLING.CHECKOUT.PER_YEAR")
          }.`}
        </Text>
        <CouponDetails couponData={couponData} classes={classes.couponData} />
      </Paper>
      {plan.amount !== 0 && (
        <Button
          variant="text"
          size="small"
          color="primary"
          onClick={switchBillingRate}
          data-testid="switch-plan-interval"
        >
          {t(
            plan.interval === BILLING_INTERVAL.MONTHLY
              ? "BILLING.CHECKOUT.SWITCH_YEARLY"
              : "BILLING.CHECKOUT.SWITCH_MONTHLY"
          )}
        </Button>
      )}
    </Box>
  );
}

CheckoutSummary.propTypes = {
  couponData: PropTypes.shape({
    isValid: PropTypes.bool,
    coupon: PropTypes.shape({
      id: PropTypes.id,
      duration: PropTypes.string,
      currency: PropTypes.string,
      amount_off: PropTypes.number,
      percent_off: PropTypes.number,
      duration_in_months: PropTypes.number,
    }),
  }),
};

CheckoutSummary.defaultProps = {
  couponData: null,
};

export default CheckoutSummary;
