import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { INTEGRATION_APPS } from "@integrations/constants";
import { Button } from "@mui/material";

import { Box } from "@shared/UIKit";

function IntegrationsSettingsPopperFooter({
  appId,
  disabled,
  handleSave,
  handleClose,
}) {
  const { t } = useTranslation();

  const integrations = INTEGRATION_APPS;
  const integration = integrations.find(
    (integration) => integration.id === appId
  );

  return (
    <Box display="flex" justifyContent="flex-end">
      <Button
        data-testid="notification-dialog-cancel-button"
        color="primaryV2"
        onClick={handleClose}
      >
        {t(`PROJECT.${integration.localizationKey}.DIALOG.CANCEL`)}
      </Button>
      <Button
        data-testid="notification-dialog-save-button"
        variant="contained"
        color="primary"
        disabled={disabled}
        onClick={handleSave}
      >
        {t(`PROJECT.${integration.localizationKey}.DIALOG.SAVE`)}
      </Button>
    </Box>
  );
}

IntegrationsSettingsPopperFooter.propTypes = {
  disabled: propTypes.bool.isRequired,
  appId: propTypes.string.isRequired,
  handleSave: propTypes.func.isRequired,
  handleClose: propTypes.func.isRequired,
};

export default IntegrationsSettingsPopperFooter;
