import { useTranslation } from "react-i18next";

import { KeyboardArrowRight } from "@mui/icons-material";
import { Box, Link } from "@mui/material";

import { Text, Image } from "@shared/UIKit";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName, useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import { VIDEO_TUTORIALS } from "./constants";

function VideoTutorials() {
  const { t } = useTranslation();
  const currentRouteName = useCurrentRouteName();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleVideoTutorialsClick = () => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.HELP_MENU.VIDEO_TUTORIALS,
      {
        page: getCurrentPage(currentRouteName),
      }
    );
  };

  return (
    <Box px={3} py={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        py={1}
        gap={1}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Text
          variant="textSm"
          fontWeight="fontWeight.medium"
          translate="CORE.VIDEO_TUTORIALS"
        />
        <Link
          onClick={handleVideoTutorialsClick}
          href="https://www.youtube.com/@FilestageIO/videos"
          color="grey.700"
          target="_blank"
          sx={{
            fontSize: "textSm.fontSize",
            display: "flex",
            alignItems: "flex-end",
          }}
          data-testid="help-menu-video-tutorial"
        >
          {t("CORE.SEE_MORE")}
          <KeyboardArrowRight fontSize="small" />
        </Link>
      </Box>
      <Box flexDirection={isMobile ? "column" : "row"} display="flex" gap={1}>
        {VIDEO_TUTORIALS.map(({ thumbnail, url, duration, title }) => (
          <Box key={title} flexGrow={1} flexBasis={0}>
            <Box position="relative" width="fit-content">
              <Link
                href={t(url)}
                onClick={handleVideoTutorialsClick}
                target="_blank"
              >
                <Image
                  src={t(thumbnail)}
                  alt={t(title)}
                  width={isMobile ? "100%" : "auto"}
                  height={isMobile ? "auto" : 86}
                />
              </Link>
              <Box
                position="absolute"
                bottom={0}
                right={0}
                margin={1}
                px={0.5}
                backgroundColor="grey.800"
                borderRadius={1}
              >
                <Text variant="textXs" color="grey.100">
                  {t(duration)}
                </Text>
              </Box>
            </Box>
            <Box pt={1}>
              <Text
                variant="textXs"
                fontWeight="fontWeight.medium"
                sx={{
                  whiteSpace: "pre-wrap",
                  overflowWrap: "break-word",
                }}
              >
                {t(title)}
              </Text>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default VideoTutorials;
