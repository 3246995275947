import { Box } from "@mui/material";

import { LoadingSpinner, Text } from "@shared/UIKit";

function OnboardingLoadingScreen() {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <LoadingSpinner />
        <Text
          variant="textMd"
          color="primary.contrastText"
          translate="LOGIN.ONBOARDING.CHECK_SCREEN"
        />
      </Box>
      <Box
        zIndex={-1}
        width="100%"
        height="100%"
        position="fixed"
        bgcolor="grey.800"
        sx={{ opacity: "70%" }}
      />
    </Box>
  );
}

export default OnboardingLoadingScreen;
