export default Object.freeze({
  DOCUMENT: {
    ZOOM_TO_WIDTH: "ZOOM_TO_WIDTH",
    ZOOM_TO_FIT: "ZOOM_TO_FIT",
    DEFAULT_MODE: "ZOOM_TO_WIDTH",
  },
  IMAGE: {
    ZOOM_TO_WIDTH: "ZOOM_TO_WIDTH",
    ZOOM_TO_FIT: "ZOOM_TO_FIT",
    DEFAULT_MODE: "ZOOM_TO_WIDTH",
  },
});
