import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import TypeformDialog from "@integrations/components/TypeformDialog/TypeformDialog";
import { AddCircleOutline } from "@mui/icons-material";
import { Button, Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { Box, Text } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

import AIIcon from "@assets/img/icons/AIIcon.svg";

const useStyles = makeStyles((theme) => ({
  container: { width: "100%" },
  requestAIButton: {
    float: "right",
    backgroundColor: theme.color.white,
    borderRadius: 5,
    border: `0.5px solid ${theme.color.gray[200]}`,
    padding: "8px 12px",
    height: 36,
    marginTop: 8,
  },
  icon: { float: "left", marginRight: 14 },
  title: {
    textAlign: "left",
    lineHeight: "53px",
    [theme.breakpoints.up("md")]: {
      lineHeight: "inherit",
    },
  },
  description: {
    textAlign: "left",
    marginTop: 4,
    [theme.breakpoints.down("md")]: {
      marginTop: 15,
    },
  },
}));

function AIMarketplaceDialogHeader() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const user = useActiveUser();
  const { isOpen: isTypeformOpen, openDialog, closeDialog } = useDialogState();

  const requestAIButtonHandler = useCallback(() => {
    openDialog();
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AI_MARKETPLACE.REQUEST_BOT_BUTTON
    );
  }, [openDialog]);

  const requestAIButton = (
    <Button
      data-testid="ai-request-button"
      className={classes.requestAIButton}
      onClick={requestAIButtonHandler}
      startIcon={
        <AddCircleOutline
          htmlColor={theme.color.gray[700]}
          className={classes.requestAppIconButton}
        />
      }
    >
      <Text
        variant="subtitle2"
        fontWeight={theme.typography.fontWeight.semiBold}
        color={theme.color.gray[700]}
      >
        {t("BOTS.DIALOG.CTA")}
      </Text>
    </Button>
  );

  const learnMoreClickHandler = () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AI_MARKETPLACE.LEARN_MORE_LINK
    );
  };

  return (
    <Box mt={3}>
      {requestAIButton}
      <AIIcon className={classes.icon} />
      <Text
        fontSize={FSTGTypography.fontSize_2_0}
        fontWeight={FSTGTypography.fontWeightMedium}
        className={classes.title}
      >
        {t("CORE.BOTS.TITLE")}
      </Text>
      <Text
        fontSize={FSTGTypography.fontSize_1_4}
        color={theme.color.gray[600]}
        className={classes.description}
      >
        <Trans
          components={{
            anchor: (
              <Link
                onClick={learnMoreClickHandler}
                href={t("BOTS.TEASER_LEARN_MORE")}
                data-testid="learn-more-link"
                target="_blank"
                color="accent"
              />
            ),
          }}
        >
          BOTS.DIALOG.DESCRIPTION
        </Trans>
      </Text>

      {isTypeformOpen && (
        <TypeformDialog
          formId="zFhVrMnU"
          hidden={`email=${user.email},firstname=${user.fullName}`}
          closeDialog={closeDialog}
        />
      )}
    </Box>
  );
}

export default AIMarketplaceDialogHeader;
