import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import reviewReportService from "@feedback/services/reviewReportService";

import { Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { instance as analytics } from "@shared/services/analytics";

import DownloadFileReportIcon from "@assets/img/icons/export-file-report.svg";

import ExportReportMenuItem from "../ExportReportMenuItem/ExportReportMenuItem";
import UpsellExportFileReportDialog from "../UpsellExportFileReportDialog/UpsellExportFileReportDialog";

function ExportFileReportMenuItem({
  fileId,
  closeMenu,
  isExportFileReportEnabled,
  page,
  label,
  teamId,
}) {
  const { t } = useTranslation();

  const exportFileReport = () => {
    if (isExportFileReportEnabled) {
      closeMenu();
      reviewReportService.exportFileReport(fileId, page);
    } else {
      NiceModal.show(UpsellExportFileReportDialog, { teamId });
      analytics.track(
        analytics.ACTION.REACHED,
        `${analytics.CATEGORY.SUBSCRIPTION_LIMIT}-${analytics.CATEGORY.FILE_REPORT}`
      );
    }
  };

  return (
    <Tooltip
      placement="right"
      title={t("FILE.MENU.EXPORT-FILE-REPORT.TOOLTIP")}
    >
      <ExportReportMenuItem
        exportReport={exportFileReport}
        label={label}
        resourceId={fileId}
        showUpgradeIcon={!isExportFileReportEnabled}
        startIcon={DownloadFileReportIcon}
        data-testid="export-file-report"
      />
    </Tooltip>
  );
}

ExportFileReportMenuItem.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired,
  isExportFileReportEnabled: PropTypes.bool.isRequired,
  page: PropTypes.oneOf([pagesEnum.PROJECT_DASHBOARD, pagesEnum.WORKSPACE])
    .isRequired,
  label: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default ExportFileReportMenuItem;
