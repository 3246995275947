/* eslint react/forbid-elements:0 */
import PropTypes from "prop-types";
import { ReactElement, forwardRef, useCallback, useState } from "react";

import { Button, Link, Grid, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Popper, Text, Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import PremiumIcon from "@assets/img/icons/ic_gold_upgrade_24px.svg";

const useStyles = makeStyles((theme) => ({
  Popover: {
    zIndex: 50,
  },
  popper: {
    width: "100%",
    padding: 12,
    backgroundColor: `${theme.color.blue[50]} !important`,
    border: `solid 1px ${theme.color.blue[200]}`,
    borderRadius: 5,
  },
  link: {
    [theme.breakpoints.down("sm")]: {
      fontSize: FSTGTypography.fontSize_1_3,
    },
  },
  premiumIcon: {
    width: "1.4em",
    height: "1.4em",
    fontSize: FSTGTypography.fontSize_2_6,
  },
  text: {
    fontSize: FSTGTypography.fontSize_1_4,
    color: theme.color.gray[800],
    fontWeight: FSTGTypography.fontWeightMedium,
    [theme.breakpoints.down("sm")]: {
      fontSize: FSTGTypography.fontSize_1_3,
    },
  },
  textBoxContainer: {
    display: "flex",
    flexWrap: "nowrap",
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 240,
    },
  },
  buttonContainer: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      marginTop: 10,
    },
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  floatLeft: {
    float: "left",
  },
  clearBoth: {
    clear: "both",
  },
}));

/**
 * UIKit Upsell Popup Component
 * @description A component that renders a popover with a premium icon and a text.
 * This component is being used specifically on the customers which doesn't included in a subscribed team.
 * @param {object} props - Component props
 * @param {ReactElement} [props.children] [Required] - The content of the popup.
 * @param {string} [props.primaryText] [Required] - The primary text of the popup.
 * @param {string} [props.buttonText] [Required] - The text/label of the upsell popup main button.
 * @param {Function} [props.buttonAction] [Required] - The action of the upsell popup main button.
 * @param {boolean} [props.isDisabled] [Optional] - If true, the popup will be disabled and it
 * will render the children as it is.
 * @param {string} [props.urlText] [Optional] - An anchored text of the upsell popup which
 * will be rendered below the `primaryText`.
 * @param {string} [props.urlLink] [Optional] - The anchor of the text on the upsell popup which
 * will be rendered below the `primaryText`.
 * @returns {ReactElement} - React component
 */
const UpsellPopup = forwardRef(
  (
    {
      children,
      buttonAction,
      primaryText,
      urlText,
      urlLink,
      buttonText,
      isDisabled,
      ...restProps
    },
    ref
  ) => {
    const classes = useStyles();
    const [isClosed, setIsClosed] = useState(false);

    const buttonClick = useCallback(
      (event) => {
        setIsClosed(true);
        buttonAction(event);
      },
      [buttonAction]
    );

    const onClose = useCallback(() => setIsClosed(false), []);

    if (!isDisabled) {
      return children;
    }

    return (
      <Popper
        data-testid="upsell-popper"
        ref={ref}
        content={
          <div className={classes.popper}>
            <Grid container direction="row" alignItems="center">
              <Grid
                container
                item
                xs={12}
                sm={8}
                className={classes.textBoxContainer}
                spacing={1}
              >
                <SvgIcon
                  component={PremiumIcon}
                  classes={{ root: classes.premiumIcon }}
                />
                <Box className={classes.textBox}>
                  <Text className={classes.text}>{primaryText}</Text>
                  {urlText && (
                    <Link
                      href={urlLink}
                      fontSize={FSTGTypography.fontSize_1_0}
                      color="accent"
                      target="_blank"
                      className={classes.link}
                    >
                      {urlText}
                    </Link>
                  )}
                </Box>
                <span className={classes.clearBoth} />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={4}
                className={classes.buttonContainer}
              >
                <Button
                  data-testid="popper-upsell-button"
                  onClick={buttonClick}
                  color="upgrade"
                  variant="contained"
                  shape="rounded"
                >
                  {buttonText}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
        actionVariant="click"
        className={classes.Popover}
        onClose={onClose}
        isClosed={isClosed}
        {...restProps}
      >
        {children}
      </Popper>
    );
  }
);
UpsellPopup.displayName = "UpsellPopup";

UpsellPopup.propTypes = {
  children: PropTypes.node.isRequired,
  primaryText: PropTypes.string.isRequired,
  urlText: PropTypes.string,
  urlLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

UpsellPopup.defaultProps = {
  urlText: "",
  urlLink: "",
  isDisabled: true,
};

export default UpsellPopup;
