import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { SvgIcon, Button, Box } from "@mui/material";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { StepFeatureGate } from "@supporting/hooks/useGatedFeature/featureGates";
import { instance as teamService } from "@supporting/services/team";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import { Tooltip } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

function AddStep({ stepCount, projectId, isTemplate }) {
  const { t } = useTranslation();

  const team = teamService.getSelectedTeam();

  const [disableAdd, setDisableAdd] = useState(false);

  const { checkAndProceed, isLimitReached, IconAsset } = useGatedFeature({
    featureGate: StepFeatureGate,
    currentValue: stepCount,
    teamId: team._id,
  });

  const createStep = async () => {
    setDisableAdd(true);
    const stepName = t("PROJECTS.REVIEWER_GROUP.HEADER", {
      StepCount: stepCount + 1,
    });
    if (isTemplate) {
      await projectTemplateService.createStep(projectId, stepName);
    } else {
      await stepService.createStep(projectId, stepName);
    }
    setDisableAdd(false);
    analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.REVIEW_STEP, {
      teamId: team._id,
      teamName: team.name,
    });
  };

  return (
    <Box
      bgcolor="grey.100"
      width={290}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Tooltip
        title={t("PROJECT_TEMPLATE.ADD_REVIEW_STEP.TOOLTIP.TITLE")}
        description={t("PROJECT.ADD_REVIEW_STEP.TOOLTIP.DESCRIPTION")}
      >
        <Box>
          <Button
            onClick={checkAndProceed(createStep)}
            className="tour__add-step"
            data-testid="add-new-step"
            disabled={disableAdd}
            variant="text"
            color="primary"
            startIcon={<AddCircleOutline />}
            endIcon={
              isLimitReached ? (
                <SvgIcon
                  component={IconAsset}
                  color="upgrade"
                  data-testid="add-new-step-upgrade"
                />
              ) : null
            }
          >
            {t("STEP.CREATE")}
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
}

AddStep.defaultProps = {
  isTemplate: false,
};

AddStep.propTypes = {
  stepCount: PropTypes.number.isRequired,
  projectId: PropTypes.string.isRequired,
  isTemplate: PropTypes.bool,
};

export default AddStep;
