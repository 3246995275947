import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";
import { useTheme } from "@mui/styles";

import { Box, Image } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import LogoPoweredBy from "@assets/img/sprite-source/sprite--logo-powered-by.png";
import LogoPoweredBy2x from "@assets/img/sprite-source/sprite--logo-powered-by@2x.png";

function PoweredBy() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Link href={t("URLS.WEBSITE_SIDEBAR_CTA_REVIEWERS")} target="_blank">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        minWidth={135}
        color={theme.color.gray[500]}
        fontSize={FSTGTypography.fontSize_1_2}
        fontWeight={FSTGTypography.fontWeightMedium}
        ml={1.5}
      >
        {t("CORE.POWERED_BY")}
        <Box mx={0.5}>
          <Image
            src={LogoPoweredBy}
            srcSet={`${LogoPoweredBy} 1x, ${LogoPoweredBy2x} 2x`}
            alt="Powered by Filestage"
          />
        </Box>
        {t("CORE.FILESTAGE")}
      </Box>
    </Link>
  );
}
export default PoweredBy;
