import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";

import { Box, Text } from "@shared/UIKit";

import { reviewDecisionProp } from "@shared/props/review";

import ReviewDecision from "./ReviewDecision/ReviewDecision";

const TRANSLATION_KEYS = {
  PENDING: "FILE_REVIEW.SUMMARY.PENDING",
  [REVIEW_DECISION_STATES.APPROVED]: "FILE_REVIEW.SUMMARY.APPROVED",
  [REVIEW_DECISION_STATES.NEEDS_CHANGES]: "FILE_REVIEW.SUMMARY.DECLINED",
  [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]:
    "REVIEW_DECISION_OVERVIEW.APPROVED2",
  [REVIEW_DECISION_STATES.REJECTED]: "REVIEW_DECISION_OVERVIEW.REJECTED",
};

const COLORS = {
  PENDING: "pending.dark",
  [REVIEW_DECISION_STATES.APPROVED]: "approved.main",
  [REVIEW_DECISION_STATES.NEEDS_CHANGES]: "needsChanges.main",
  [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]: "approved.main",
  [REVIEW_DECISION_STATES.REJECTED]: "rejected.main",
};

function ReviewDecisionList({ reviews, variant, ...restProps }) {
  const { t } = useTranslation();

  return (
    reviews.length > 0 && (
      <Box data-testid="review-decision-list" {...restProps} pb={1.875}>
        <Box data-testid="review-decision-list-header">
          <Text
            mb={0.75}
            variant="subtitle1"
            display="inline-block"
            textTransform="uppercase"
            fontWeight="fontWeight.bold"
            color={COLORS[variant]}
          >
            {t(TRANSLATION_KEYS[variant])}
          </Text>
          {reviews.map((review) => (
            <ReviewDecision
              data-testid="review-decision-list-review"
              key={review.reviewer._id}
              reviewState={review.state}
              reviewer={review.reviewer}
              reviewDate={review.date}
              digitallySigned={review.digitallySigned}
            />
          ))}
        </Box>
      </Box>
    )
  );
}

ReviewDecisionList.propTypes = {
  reviews: PropTypes.arrayOf(reviewDecisionProp),
  variant: PropTypes.oneOf(["PENDING", ...Object.keys(REVIEW_DECISION_STATES)]),
};

ReviewDecisionList.defaultProps = {
  reviews: [],
  variant: "PENDING",
};

export default ReviewDecisionList;
