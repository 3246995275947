/* istanbul ignore file */

import PropTypes from "prop-types";
import { useState } from "react";

import {
  Checkbox,
  FormGroup,
  TextField,
  FormControlLabel,
  Button,
} from "@mui/material";
import toastService from "@supporting/services/toast";

import { Dialog, ErrorBoundary } from "@shared/UIKit";

import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

const TEST_USD_PRICE = "price_1MtwQcHX5QcAeVAPTWGHrIbz";

// eslint-disable-next-line react/prop-types
const BooleanField = ({ name, value, onChange }) => (
  <FormControlLabel
    label={name}
    control={
      <Checkbox
        name={name}
        color="primary"
        checked={value}
        onChange={onChange}
      />
    }
  />
);

const NumberField = (props) => <TextField {...props} type="number" />;

const StringField = (props) => <TextField {...props} type="text" />;

const FormComponent = (type) => {
  switch (type) {
    case "boolean": {
      return BooleanField;
    }
    case "number": {
      return NumberField;
    }
    default: {
      return StringField;
    }
  }
};

const parseValue = (value, type, checked) => {
  switch (type) {
    case "checkbox": {
      return checked;
    }
    case "number": {
      return Number(value);
    }
    default: {
      return value;
    }
  }
};

function HiddenMetaDataUpdater({ priceId, metadata, subscriptionId }) {
  const [openMetadataForm, setOpenMetadataForm] = useState(false);
  const [data, setData] = useState(metadata);
  const updateMetaDataHandler = async () => {
    try {
      setOpenMetadataForm(false);
      await backend.post("/billing/update-price-metadata", {
        metadata: data,
        subscriptionId,
        priceId: TEST_USD_PRICE,
      });
      toastService.sendToast({
        title: "Price meta data updated",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const handleDataChange = (event) => {
    const { value, name, type, checked } = event.currentTarget;
    setData((prevValue) => ({
      ...prevValue,
      [name]: parseValue(value, type, checked),
    }));
  };

  return (
    <ErrorBoundary>
      {priceId === TEST_USD_PRICE && (
        <Button color="default-light" onClick={() => setOpenMetadataForm(true)}>
          Update Meta Data
        </Button>
      )}
      {openMetadataForm && (
        <Dialog
          isOpen
          hideBackdrop={false}
          actions={<Button onClick={updateMetaDataHandler}>Update</Button>}
          cancel={() => setOpenMetadataForm(false)}
        >
          <FormGroup>
            {Object.keys(data).map((key) => {
              const MetaDataInputField = FormComponent(typeof data[key]);
              return (
                <MetaDataInputField
                  id={key}
                  key={key}
                  name={key}
                  label={key}
                  value={data[key]}
                  onChange={handleDataChange}
                />
              );
            })}
          </FormGroup>
        </Dialog>
      )}
    </ErrorBoundary>
  );
}

HiddenMetaDataUpdater.propTypes = {
  metadata: PropTypes.any,
  priceId: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired,
};

HiddenMetaDataUpdater.defaultProps = {
  metadata: {},
};

export default HiddenMetaDataUpdater;
