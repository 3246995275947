import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Link } from "@mui/material";
import VideoDialog from "@supporting/components/VideoDialog/VideoDialog";

import { Box, Image, InfoToolTip, Text, Toggle } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";

import { FeaturePropType } from "../EarlyAccessPropTypes";

const isReactComponent = (data) => data && typeof data === "function";

function EarlyAccessFeatureItem({
  feature,
  featureToggleValue,
  onChangeFeatureToggle,
}) {
  const { t } = useTranslation();
  const {
    isOpen: isVideoDialogOpen,
    openDialog: openVideoDialog,
    closeDialog: closeVideoDialog,
  } = useDialogState();

  const handleImageClick = () => {
    if (feature.video || feature.videoId) {
      openVideoDialog();
    }
  };

  /* istanbul ignore next */
  const FeatureImage = isReactComponent(feature.image)
    ? feature.image
    : () => (
        <Image
          src={feature.image}
          alt={t(feature.titleKey)}
          onClick={handleImageClick}
          sx={{
            maxWidth: "100%",
            marginTop: 1,
            "&:hover": {
              cursor: feature.video ? "pointer" : "default",
            },
            maxHeight: { xs: 110, sm: 152, md: "unset" },
          }}
          data-testid="video-dialog-preview-image"
        />
      );
  return (
    <Box display="flex" mb={3.75} flexDirection="column">
      <Toggle
        label={
          <>
            {t(feature.titleKey)}{" "}
            {feature.helpTextKey && (
              <InfoToolTip title={t(feature.helpTextKey)}>
                <HelpOutlineIcon fontSize="small" color="default-light" />
              </InfoToolTip>
            )}
          </>
        }
        helpText={
          <Trans
            components={{
              anchor: feature.disableIntercom ? (
                <Link target="_blank" />
              ) : (
                <Link
                  href={feature.descriptionUrl}
                  target="_blank"
                  {...(!feature.descriptionUrl && {
                    className: "launch-intercom-chat",
                  })}
                />
              ),
            }}
          >
            {feature.descriptionKey}
          </Trans>
        }
        slotProps={{
          switch: {
            checked: featureToggleValue,
            onChange: onChangeFeatureToggle,
            inputProps: { id: feature.id, "aria-label": `label-${feature.id}` },
          },
        }}
      />
      <Box ml={6}>
        {feature.infoKey && (
          <Box
            bgcolor="grey.50"
            borderRadius="3px"
            p={0.5}
            my={1.5}
            mx={0}
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
          >
            <InfoOutlined fontSize="small" color="default-light" />
            <Text variant="subtitle1" color="text.secondary">
              {t(feature.infoKey)}
            </Text>
          </Box>
        )}
        {feature.image && (
          <FeatureImage
            onClick={openVideoDialog}
            data-testid="video-dialog-preview-image"
          />
        )}
        {(feature.video || feature.videoId) && (
          <VideoDialog
            video={feature.video}
            videoId={feature.videoId}
            cancel={closeVideoDialog}
            isOpen={isVideoDialogOpen}
            variant={feature.videoId ? "wistia" : "youtube"}
          />
        )}
      </Box>
    </Box>
  );
}

EarlyAccessFeatureItem.propTypes = {
  feature: PropTypes.shape(FeaturePropType).isRequired,
  featureToggleValue: PropTypes.bool.isRequired,
  onChangeFeatureToggle: PropTypes.func.isRequired,
};

export default EarlyAccessFeatureItem;
