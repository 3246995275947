/* istanbul ignore file */

import { CssBaseline } from "@mui/material";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  StyledEngineProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";

import { Box, LoadingSpinner } from "@shared/UIKit";

import theme from "@shared/theme/default";
const muiTheme = extendTheme(theme);

const RouterFallback = () => {
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider theme={muiTheme}>
        <CssBaseline />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          width="100vw"
        >
          <LoadingSpinner />
        </Box>
      </CssVarsProvider>
    </StyledEngineProvider>
  );
};

export default RouterFallback;
