import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import slackAppService from "@integrations/services/slackAppService";
import { SvgIcon, IconButton } from "@mui/material";
import projectService from "@workflow/services/projectService";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import SlackDisabledLogo from "@assets/img/icons/slack-disabled-icon.svg";
import SlackLogo from "@assets/img/icons/slack-icon.svg";

function SlackAppSettingsButton({ onClick }) {
  const [connected, setConnected] = useState(false);
  const { projectId } = useParams();

  useEffect(() => {
    async function loadSlackProjectChannel() {
      const project = await projectService.fetchProjectById(projectId);

      setConnected(false);

      try {
        const slackAppProjectChannel = await slackAppService.getProjectChannel(
          project.teamId,
          project.id
        );

        setConnected(Boolean(slackAppProjectChannel));
        // eslint-disable-next-line no-empty
      } catch {}
    }

    loadSlackProjectChannel();

    eventService.addListener(
      EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_CONNECTED,
      loadSlackProjectChannel
    );
    eventService.addListener(
      EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_DISCONNECTED,
      loadSlackProjectChannel
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_CONNECTED,
        loadSlackProjectChannel
      );
      eventService.removeListener(
        EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_DISCONNECTED,
        loadSlackProjectChannel
      );
    };
  }, [projectId]);

  const handleButtonClick = (event) => {
    onClick(event, "slack");
  };

  return (
    <IconButton
      data-testid="slack-app-settings-button"
      onClick={handleButtonClick}
      size="small"
    >
      <SvgIcon
        component={connected ? SlackLogo : SlackDisabledLogo}
        inheritViewBox
        fontSize="small"
      />
    </IconButton>
  );
}

SlackAppSettingsButton.propTypes = {
  onClick: propTypes.func.isRequired,
};

export default SlackAppSettingsButton;
