import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Card, CardContent, CardHeader } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as billingService } from "@supporting/services/billingService";

import { Box, Text, Image } from "@shared/UIKit";

import errorHandlerService from "@shared/services/errorHandler";

import OpenCreditCardDialog from "@supporting/pages/Teams/BillingTab/OpenCreditCardDialog/OpenCreditCardDialog";

import CreditCards from "@assets/img/images/creditcards.png";
import CreditCards2x from "@assets/img/images/creditcards@2x.png";

import BillingInvoices from "./BillingInvoices/BillingInvoices";
import BillingOverview from "./BillingOverview/BillingOverview";
import Coupon from "./Coupon/Coupon";
import CreditCard from "./CreditCard/CreditCard";
import InvoiceData from "./InvoiceData/InvoiceData";

export default function BillingTab() {
  const [searchParams] = useSearchParams();

  const action = searchParams.get("action");
  const { t } = useTranslation();
  const [creditCards, setCreditCards] = useState([]);
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const team = useSelectedTeam();

  const refetchPaymentMethods = useCallback(async () => {
    try {
      const paymentMethods = await billingService.getPaymentMethods(team._id);
      setCreditCards(paymentMethods);
    } catch (errorGetPaymentMethods) {
      errorHandlerService.handleError(errorGetPaymentMethods);
    }
  }, [team._id]);

  useEffect(() => {
    const fetchPaymentMethods = async () => {
      try {
        const paymentMethods = await billingService.getPaymentMethods(team._id);
        setCreditCards(paymentMethods);
      } catch (errorGetPaymentMethods) {
        errorHandlerService.handleError(errorGetPaymentMethods);
      } finally {
        setIsLoadingCards(false);
      }
    };

    fetchPaymentMethods();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team._id, team.permissions.canManageBilling]);

  return (
    <Box display="flex" flexDirection="column" gap={2} flex={1}>
      <BillingOverview />

      <Card>
        <CardHeader
          title={t("BILLING.PAYMENT_INFORMATION")}
          action={
            !isLoadingCards && (
              <OpenCreditCardDialog
                creditCards={creditCards}
                action={action}
                updatePaymentList={refetchPaymentMethods}
              />
            )
          }
          sx={{ position: "relative" }}
        />
        <CardContent>
          {creditCards.map((creditCard) => (
            <CreditCard key={creditCard.id} {...creditCard} />
          ))}
          {creditCards.length === 0 && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              data-testid="empty-cards"
            >
              <Image
                src={CreditCards}
                srcSet={`${CreditCards} 1x, ${CreditCards2x} 2x`}
                alt=""
              />
              <Box pt={2.5}>
                <Text translate="BILLING.CHANGE_PAYMENT_DETAILS.EMPTY_TEXT" />
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>

      <BillingInvoices />
      <InvoiceData />
      <Coupon />
    </Box>
  );
}
