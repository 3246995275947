import PropTypes from "prop-types";
import { useState } from "react";

import OTPContent from "@supporting/components/ReviewersAuthenticationFlow/OTPContent";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

const ReviewerVerifyOTPContent = ({ email, onSubmit }) => {
  const [errorText, setErrorText] = useState("");
  const handleResendClick = async () => {
    try {
      await authenticationService.sendOTP(email);
      toastService.sendToast({
        title: "SIGNUP.OTP_SENT.TITLE",
        body: "SIGNUP.OTP_SENT.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };
  const submitOTP = async (code) => {
    try {
      await authenticationService.verifyOTP(email, code, true);
      analytics.track(
        analytics.ACTION.CONFIRMED,
        analytics.CATEGORY.EMAIL_VERIFICATION,
        {
          source: "reviewer-login",
        }
      );
      onSubmit("CLOSE_DIALOG");
    } catch (error) {
      setErrorText("AUTHENTICATION.DIALOGS.OTP.INCORRECT_CODE");
    }
  };

  return (
    <OTPContent
      errorText={errorText}
      onSubmit={submitOTP}
      setErrorText={setErrorText}
      handleResendClick={handleResendClick}
      email={email}
    />
  );
};

ReviewerVerifyOTPContent.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ReviewerVerifyOTPContent;
