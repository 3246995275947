import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";

function StepHeaderMenuList({
  anchorEl,
  closeMenu,
  isRenameAllowed,
  isDeleteAllowed,
  isMoveLeftAllowed,
  isMoveRightAllowed,
  onRename,
  moveStep,
  deleteStep,
  ...restProps
}) {
  const { t } = useTranslation();
  return (
    <Menu
      id="step-header-menu"
      data-testid="step-header-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={closeMenu}
      {...restProps}
    >
      {isMoveLeftAllowed && (
        <MenuItem
          data-testid="step-header-menu-item-move-step-left"
          onClick={moveStep(-1)}
        >
          <ListItemIcon>
            <ArrowBackIcon />
          </ListItemIcon>
          <ListItemText primary={t("PROJECT.STEP.MOVE_LEFT")} />
        </MenuItem>
      )}

      {isMoveRightAllowed && (
        <MenuItem
          data-testid="step-header-menu-item-move-step-right"
          onClick={moveStep(1)}
        >
          <ListItemIcon>
            <ArrowForwardIcon />
          </ListItemIcon>
          <ListItemText primary={t("PROJECT.STEP.MOVE_RIGHT")} />
        </MenuItem>
      )}

      {isRenameAllowed && (
        <MenuItem
          data-testid="step-header-menu-item-rename-step"
          onClick={onRename}
        >
          <ListItemIcon>
            <EditOutlined />
          </ListItemIcon>
          <ListItemText primary={t("PROJECT.STEP.RENAME")} />
        </MenuItem>
      )}
      {isDeleteAllowed && (
        <MenuItem
          data-testid="step-header-menu-item-delete-step"
          onClick={deleteStep}
          data-accent-color="error"
        >
          <ListItemIcon>
            <DeleteOutline />
          </ListItemIcon>
          <ListItemText primary={t("PROJECT.STEP.DELETE")} />
        </MenuItem>
      )}
    </Menu>
  );
}

StepHeaderMenuList.propTypes = {
  anchorEl: PropTypes.any,
  closeMenu: PropTypes.func.isRequired,
  isRenameAllowed: PropTypes.bool.isRequired,
  isDeleteAllowed: PropTypes.bool.isRequired,
  onRename: PropTypes.func.isRequired,
  deleteStep: PropTypes.func.isRequired,
  isMoveLeftAllowed: PropTypes.bool.isRequired,
  isMoveRightAllowed: PropTypes.bool.isRequired,
  moveStep: PropTypes.func.isRequired,
};

StepHeaderMenuList.defaultProps = {
  anchorEl: null,
};

export default StepHeaderMenuList;
