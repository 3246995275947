import MARKER_CLAZZES from "@feedback/constants/markerClazzes";

/**
 * Prepends a zero to a "timeString" (minutes, seconds, frames)
 * if the value would be below the group of ten.
 *
 * @param  {Number} timeString
 * @return {String} Either a zero prepended version of the time string or
 *
 */
function convertToTimeString(time) {
  if (time < 10) {
    return `0${time}`;
  }
  return String(time);
}

/**
 * Checks if the markerData has the correct properties (time and frame rate)
 *
 * @param  {Object}  markerData contains the marker
 * @return {Boolean} true if the object is valid otherwise false
 */
const isMarkerDataValid = function (markerData) {
  if (!markerData) {
    return false;
  }

  if (markerData[MARKER_CLAZZES.VIDEO.DATA.TIME] === undefined) {
    return false;
  }

  return true;
};

/**
 * Calculates the actual frame of the parameter time.
 * @param  {Number} time
 * @param  {Number} frameRate
 * @return {Number}           frame
 */
const calculateFrame = function (time, frameRate) {
  const frame = Math.floor(time * frameRate);
  return frame;
};

/**
 * Splits the time into minutes and seconds
 * @param  {Number} time
 * @return {Object} an object containing minutes and seconds
 */
const splitTime = function (time) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time) % 60;

  return {
    minutes: convertToTimeString(minutes),
    seconds: convertToTimeString(seconds),
  };
};

/**
 * Calculates and formats the standard time.
 * @param  {[type]} time [description]
 * @return {String}      standa
 */
const calculateStandardFormat = function (time) {
  const splitedTime = splitTime(time);

  return `${splitedTime.minutes}:${splitedTime.seconds}`;
};

/**
 * Calculates and formats the timecode by using time and frameRate
 * @param  {Number} time      [description]
 * @param  {Number} frameRate [description]
 * @return {String}           [description]
 */
const calculateTimecodeFormat = function (time, frameRate) {
  // calculate frame
  const frame = Math.floor((time - Math.floor(time)) / (1.0 / frameRate));
  // calculate standard time and generate frame part
  return `${calculateStandardFormat(time)}:${convertToTimeString(frame)}`;
};

/**
 * Calculates the distance of two time parameters. If the frameRate parameter
 * is set, then the times will be converted into frames and the difference
 * of both frames will be returned.
 * Otherwise the distance of the time will be returned.
 * @param  {Number} time1
 * @param  {Number} time2
 * @param  {Number|String} frameRate the frameRate of the videio
 * @return {Number} the distance of the times either in "seconds" or frames
 */
const calculateTimeDifference = function (time1, time2, frameRate) {
  if (!frameRate) {
    return Math.abs(time1 - time2);
  }
  if (typeof frameRate === "string") {
    frameRate = Number(frameRate);
  }

  const frame1 = calculateFrame(time1, frameRate);
  const frame2 = calculateFrame(time2, frameRate);

  return Math.abs(frame1 - frame2);
};

export default {
  convertToTimeString,
  isMarkerDataValid,
  calculateFrame,
  splitTime,
  calculateStandardFormat,
  calculateTimecodeFormat,
  calculateTimeDifference,
};
