import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";

import { Box, useMediaQuery } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser/useActiveUser";
import { instance as teamService } from "@supporting/services/team";
import projectService from "@workflow/services/projectService";
import Cookies from "js-cookie";

import { Image, LoadingSpinner, Text } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";
import { instance as websocket } from "@shared/services/websocket";

import DashboardSkeleton from "@assets/img/images/onboarding-wizard-dashboard-skeleton.png";

function LoadingScreen({ teamId, teamMembers }) {
  const user = useActiveUser();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { routeNames, goTo } = useNavigation();

  const navigateToDashBoard = useCallback(async () => {
    const inviteLinkId = Cookies.get("teamlink_inviteLinkId");
    analytics.track(
      analytics.ACTION.COMPLETED,
      analytics.CATEGORY.ONBOARDING_WIZARD
    );
    projectService.setCloneProjectStatus({
      status: "",
      projectId: "",
    });
    await teamService.wizardProgressTrack(teamId, {
      currentStep: "completed",
    });
    if (inviteLinkId) {
      goTo(routeNames.TEAM_JOIN_LINK, { params: { inviteLinkId } });
    } else {
      goTo(routeNames.DASHBOARD);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const navigateOnCloneProjectSuccess = useCallback(
    async ({ projectId }) => {
      if (teamMembers.length) {
        try {
          healthMetrics.trackStart("supporting.onboarding");
          await projectService.addCollaborators(
            projectId,
            teamMembers,
            "",
            true
          );
          healthMetrics.trackSuccess("supporting.onboarding");
          analytics.identify(user._id, {
            demoProjectId: projectId,
          });
        } catch (error) {
          healthMetrics.trackFailure("supporting.onboarding", error);
          errorHandlerService.handleError(error);
        }
      }
      navigateToDashBoard();
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const cloneProjectStatus = projectService.getCloneProjectStatus();
    if (!cloneProjectStatus) {
      navigateToDashBoard();
      return;
    }

    const { projectId, status } = cloneProjectStatus;

    if (!status || status === "failed") {
      navigateToDashBoard();
    }

    if (projectId) {
      navigateOnCloneProjectSuccess({ projectId });
    }

    websocket.addListener(
      websocket.EVENT_NAMES.PROJECT_CLONE.SUCCEEDED,
      navigateOnCloneProjectSuccess
    );

    websocket.addListener(
      websocket.EVENT_NAMES.PROJECT_CLONE.FAILED,
      navigateToDashBoard
    );

    const websocketCheck = setInterval(() => {
      if (!websocket.isConnected()) {
        logger.warn("Onboarding:LoadingScreen", "websocket disconencted", {
          teamId,
        });
        navigateToDashBoard();
      }
    }, 10000);

    const navigateToDashboardTimeout = setTimeout(() => {
      logger.warn(
        "Onboarding:LoadingScreen",
        "Navigating to dashboard after 30 seconds",
        {
          teamId,
        }
      );
      navigateToDashBoard();
    }, 30000);

    return () => {
      clearInterval(websocketCheck);
      clearTimeout(navigateToDashboardTimeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box width="100%" height="100%" position="fixed" bgcolor="grey.50">
      <Box
        zIndex={1}
        width="100%"
        height="100%"
        display="flex"
        position="absolute"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <LoadingSpinner />
        <Text
          variant="textMd"
          color="primary.contrastText"
          translate="ONBOARDING_WIZARD.PREPARING_DEMO_ACCOUNT"
        />
      </Box>
      <Box
        zIndex={0}
        width="100%"
        height="100%"
        bgcolor="grey.800"
        position="absolute"
        sx={{ opacity: "70%" }}
      />
      {!isMobile && (
        <Image
          src={DashboardSkeleton}
          objectFit="cover"
          width="100%"
          height="auto"
        />
      )}
    </Box>
  );
}

LoadingScreen.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.string),
};

LoadingScreen.defaultProps = {
  teamMembers: [],
};

export default LoadingScreen;
