import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";

import { Text, Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "auto",
  },
  versionBox: {
    display: "flex",
    minWidth: 40,
    borderRadius: 11.5,
    padding: 2,
    lineHeight: 24,
    justifyContent: "center",
    color: theme.color.gray[600],
    border: `1px solid ${theme.color.gray[200]}`,
  },
}));

const PendingReviewsCardVersion = ({ versionNumber }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.versionBox}>
        <Text variant="subtitle2">v{versionNumber}</Text>
      </Box>
    </Box>
  );
};

PendingReviewsCardVersion.propTypes = {
  versionNumber: PropTypes.number.isRequired,
};

export default PendingReviewsCardVersion;
