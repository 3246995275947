import { useInfiniteQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { Link as ReactLink } from "react-router-dom";
import { Waypoint } from "react-waypoint";

import { Box, Link } from "@mui/material";
import classnames from "classnames";

import * as parseMentions from "@shared/helpers/parseMentions.js";
import { useMediaQuery } from "@shared/hooks";
import { useDebounceValue } from "@shared/hooks/useDebounceValue";
import { SEARCH_KEYS } from "@shared/query-keys";
import backendClient from "@shared/services/backendClient";

import EmptyStateComponent from "./EmptyStateComponent";
import LoadingSkeleton from "./LoadingSkeletons";
import { Presenter } from "./Presenter";
import { usePresenterStyles } from "./styles";

const TYPE = {
  PROJECT: "projectSearchResults",
  FILE: "fileSearchResults",
  COMMENT: "commentSearchResults",
};

const useDetailSearch = ({ query, resource, resourceType }) => {
  return useInfiniteQuery({
    queryKey: SEARCH_KEYS.detail({ query, resource }),
    queryFn: async ({ pageParam }) => {
      const response = await backendClient.get("/search", {
        query,
        resource,
        searchAfter: pageParam,
      });

      const items = response[resourceType].topResults;

      const result = items.map((item) => {
        return {
          ...item,
          ...(resource === "COMMENT" && {
            searchResultText: parseMentions.decode(
              item.searchResultText,
              item.mentionedUsers
            ),
          }),
        };
      });
      return result;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.length === 0) {
        return undefined;
      }
      const lastItem = lastPage[lastPage.length - 1];
      return lastItem.paginationToken;
    },
    enabled: query.length >= 3,
  });
};

export default function DetailSearch({
  query,
  resource,
  onClickResult,
  sectionActive,
  sectionActiveIndex,
}) {
  const itemsRef = useRef([]);
  const contentRef = useRef(null);
  const resourceType = TYPE[resource];
  const presenterStyles = usePresenterStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const querySearch = useDebounceValue(query, 500);

  const {
    data,
    status,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useDetailSearch({
    query: querySearch,
    resource,
    resourceType,
  });

  useEffect(() => {
    contentRef.current?.scrollIntoView({
      behavior: "instant",
      block: "end",
    });
  }, [resource]);

  if (query.length < 3) {
    return null;
  }

  if (status === "pending") {
    return <LoadingSkeleton />;
  }

  const hasNoResults = data.pages[0].length === 0;

  if (hasNoResults) {
    return <EmptyStateComponent resource={resource} />;
  }

  const scrollToSelectedItem = () => {
    const element = itemsRef.current[sectionActiveIndex];
    if (element) {
      element.scrollIntoView({ behavior: "instant", block: "center" });
    }
  };

  const checkIfSelected = (pageIndex, itemIndex) => {
    if (sectionActive !== resource.toLowerCase()) {
      return false;
    }

    const isRightPage = Math.floor(sectionActiveIndex / 10) === pageIndex;
    const isRightItem = sectionActiveIndex % 10 === itemIndex;

    const isSelected = isRightPage && isRightItem;
    if (isSelected) {
      scrollToSelectedItem();
    }

    return isSelected;
  };

  return (
    <Box maxHeight={650} data-testid="detail-search-results" ref={contentRef}>
      {data.pages.map((page, pageIndex) =>
        page.map((item, itemIndex) => {
          const isSelected = checkIfSelected(pageIndex, itemIndex);

          return (
            <Link
              key={item.resourceLinkUrl}
              ref={(element) =>
                (itemsRef.current[pageIndex * 10 + itemIndex] = element)
              }
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              component={ReactLink}
              to={item.resourceLinkUrl}
              onClick={(event) =>
                onClickResult(
                  item.resourceLinkUrl,
                  resource.toLowerCase(),
                  event,
                  "filtered_results",
                  item.resource
                )
              }
              className={classnames(presenterStyles.SearchResultItem, {
                [presenterStyles.Selected]: isSelected,
              })}
              data-testid={
                isSelected
                  ? "search-result-item-selected"
                  : "search-result-item"
              }
            >
              <Presenter
                searchQuery={query}
                searchResult={item}
                isMobile={isMobile}
              />
            </Link>
          );
        })
      )}
      {isFetchingNextPage && (
        /* istanbul ignore next */
        <LoadingSkeleton rows={3} />
      )}
      {!isFetching && !isFetchingNextPage && hasNextPage && (
        <Box height={10}>
          <Waypoint
            data-testid="load-more-waypoint"
            onEnter={() => fetchNextPage()}
          />
        </Box>
      )}
    </Box>
  );
}

DetailSearch.propTypes = {
  query: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  onClickResult: PropTypes.func.isRequired,
  sectionActive: PropTypes.string.isRequired,
  sectionActiveIndex: PropTypes.number,
};

DetailSearch.defaultProps = {
  sectionActiveIndex: null,
};
