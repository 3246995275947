import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import TypeformDialog from "@integrations/components/TypeformDialog/TypeformDialog";
import { FEATURE_STATUS_ENUM, INTEGRATION_APPS } from "@integrations/constants";
import { AddCircleOutline, ChatBubbleOutline } from "@mui/icons-material";
import { Box, Button, Link, SvgIcon } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { Text, HintBox } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import GiftOpenIcon from "@assets/img/icons/gift-open-24px.svg";
import PlugIcon from "@assets/img/icons/plug-disconnected-regular-24px.svg";

import IntegrationsList from "./IntegrationsList/IntegrationsList";

function Integrations() {
  const [integrations, setIntegrations] = useState([]);

  const { isOpen: isTypeformOpen, openDialog, closeDialog } = useDialogState();

  const { t } = useTranslation();

  const user = useActiveUser();

  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.APP_MARKETPLACE.APPS,
      { page: "overview" }
    );
  }, []);

  useEffect(() => {
    const filterIntegrations = (integrations) => {
      const earlyAccessFeatures = user.settings.earlyAccessFeatures;
      const filteredIntegrationFeatureIds = {};
      const integrationsByStatusOrEarlyAccess = integrations.filter(
        ({ featureId, featureStatus }) => {
          if (
            featureStatus === FEATURE_STATUS_ENUM.PUBLIC ||
            (featureStatus === FEATURE_STATUS_ENUM.EARLY &&
              earlyAccessFeatures[featureId])
          ) {
            filteredIntegrationFeatureIds[featureId] = 1;
            return true;
          }

          const isFutureApp = [
            FEATURE_STATUS_ENUM.COMING_SOON,
            FEATURE_STATUS_ENUM.UPCOMING,
          ].includes(featureStatus);

          return isFutureApp && !filteredIntegrationFeatureIds[featureId];
        }
      );
      return integrationsByStatusOrEarlyAccess.sort(
        (integrationA, integrationB) =>
          integrationA.isNew && !integrationB.isNew ? -1 : 1
      );
    };

    const integrations = INTEGRATION_APPS;
    setIntegrations(filterIntegrations(integrations));
  }, [user.settings.earlyAccessFeatures]);

  const onClickRequestApp = () => {
    openDialog();
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.APP_MARKETPLACE.REQUEST_INTEGRATION_FORM,
      { page: "overview" }
    );
  };

  /* istanbul ignore next */
  const onClickGetInTouch = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.CUSTOM_INTEGRATIONS,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_INTEREST_BUTTON,
    });
  };

  return (
    <Box
      flex={1}
      overflowY="auto"
      bgcolor="white"
      data-testid="integrations"
      display="flex"
      flexDirection="column"
      alignItems="center"
      px={3}
    >
      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        gap={2}
        width="100%"
        maxWidth={1000}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Box display="flex" mb={0.8} alignItems="center">
              <SvgIcon component={PlugIcon} />
              <Box pl={1}>
                <Text variant="textXl" fontWeight="fontWeight.medium">
                  {t("INTEGRATIONS.TITLE")}
                </Text>
              </Box>
            </Box>
            <Text variant="textSm" color="text.secondary">
              {t("INTEGRATIONS.DESCRIPTION")}
            </Text>
          </Box>
          <Button
            variant="outlined"
            color="primaryV2"
            data-testid="integrations-request-app-button"
            onClick={onClickRequestApp}
            startIcon={<AddCircleOutline />}
          >
            {t("INTEGRATIONS.CTA.REQUEST_APP")}
          </Button>
        </Box>

        <IntegrationsList apps={integrations} />

        <Box mb={4}>
          <Text fontWeight="fontWeight.medium">
            {t("INTEGRATIONS.SIDEBAR.CUSTOM_INTEGRATIONS.TITLE")}
          </Text>
          <Box
            display="flex"
            alignItems="center"
            borderRadius={1}
            p={2}
            mt={1.5}
            bgcolor="accent.main"
          >
            <Box flex={1} display="flex" alignItems="center" mr={0.5}>
              <Box
                width={40}
                height={40}
                borderRadius={2}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="white"
                mr={1}
              >
                <SvgIcon component={GiftOpenIcon} />
              </Box>
              <Text color="grey.50" fontWeight="fontWeight.medium">
                {t("INTEGRATIONS.SIDEBAR.CUSTOM_INTEGRATIONS.DESCRIPTION")}
              </Text>
            </Box>
            <Button
              variant="outlined"
              color="accent"
              className="launch-intercom-chat"
              onClick={onClickGetInTouch}
              startIcon={<ChatBubbleOutline />}
            >
              {t("INTEGRATIONS.SIDEBAR.CUSTOM_INTEGRATIONS.CTA")}
            </Button>
          </Box>
        </Box>

        <HintBox data-testid="hint-box">
          <Trans
            components={{
              anchor: (
                <Link
                  target="_blank"
                  color="accent"
                  href={t("INTEGRATIONS.API.DESC.HELP_CENTER")}
                  sx={{ ml: 0.5 }}
                />
              ),
            }}
          >
            {t("INTEGRATIONS_OVERVIEW.API.HINT")}
          </Trans>
        </HintBox>
      </Box>

      {isTypeformOpen && (
        <TypeformDialog
          formId="vGfH6mcz"
          hidden={`email=${user.email},firstname=${user.fullName}`}
          closeDialog={closeDialog}
        />
      )}
    </Box>
  );
}

export default Integrations;
