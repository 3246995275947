import PropTypes from "prop-types";

import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import AddStep from "./AddStep/AddStep";
import StepHeader from "./StepHeader/StepHeader";
import StepHeaderLoadingState from "./StepHeaderLoadingState/StepHeaderLoadingState";
import cssClasses from "./StepHeaders.module.css";
import StepHeadersMenu from "./StepHeadersMenu";

function StepHeaders({ project, steps, isLoadingSteps, team, isTemplate }) {
  const { width, minWidth, maxWidth } = useFileCardResized();

  const isAddStepAllowed = isTemplate
    ? project.permissions.canmanageTemplates
    : !project.isArchived && project.permissions.manageSteps;

  return (
    <Box
      display="flex"
      position="sticky"
      backgroundColor="grey.50"
      zIndex={51}
      top={0}
    >
      <Box display="flex">
        <Box
          position={{ xs: "relative", md: "sticky" }}
          left={0}
          bgcolor="grey.50"
          zIndex={100}
          boxSizing="border-box"
          display="flex"
          alignItems="flex-start"
          justifyContent="end"
          pr={1.625}
          width={width}
          minWidth={minWidth}
          maxWidth={maxWidth}
          minHeight={86.5}
          pt={0.5}
        >
          {!isTemplate && <StepHeadersMenu project={project} />}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          bgcolor="grey.50"
          className={cssClasses.stepHeaderContainer}
        >
          {isLoadingSteps ? (
            <>
              <StepHeaderLoadingState />
              <StepHeaderLoadingState />
            </>
          ) : (
            <>
              {steps.map((step) => (
                <Box
                  key={step.id}
                  width={290}
                  data-testid="step-header"
                  className="tour__review-step-header"
                >
                  <StepHeader
                    step={step}
                    numberOfSteps={steps.length}
                    project={project}
                    team={team}
                    isTemplate={isTemplate}
                  />
                </Box>
              ))}
            </>
          )}
          {isAddStepAllowed && (
            <AddStep
              stepCount={steps.length}
              projectId={project.id}
              isTemplate={isTemplate}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

StepHeaders.defaultProps = {
  isTemplate: false,
};

StepHeaders.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  isLoadingSteps: PropTypes.bool.isRequired,
  team: teamProp.isRequired,
  isTemplate: PropTypes.bool,
};

export default StepHeaders;
