import PropTypes from "prop-types";
import { ReactElement } from "react";

import {
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  SvgIcon,
} from "@mui/material";

import SortIcon from "@assets/img/icons/ic_fs_sort_24px.svg";

/**
 * UIKit Sort panel
 * @description Renders a label with the selected sort option text and when clicked shows a popup menu with the available sort options.
 * @typedef FstgId
 * @param {{
 *  selectTitle: string,
 *  options: object,
 *  selectedOption: string,
 *  hideTitleText: boolean,
 *  onSelect: Function,
 * }} props - Component props
 * @param {string} props.selectTitle The text to show on top of the select popup menu.
 * @param {object} props.options The available options (eg. { NEWEST: "Newest first", OLDEST: "Oldest first" }).
 * @param {string} props.selectedOption The current selected value.
 * @param {Function} props.onSelect [Optional] Function to call when an option is selected. The selected option value is provided as argument to the function.
 * @returns {ReactElement} React component
 */
function SortPanel({ selectTitle, options, selectedOption, onSelect }) {
  return (
    <TextField
      select
      data-testid="sort-panel"
      name="sortPanel"
      value={selectedOption}
      variant="standard"
      SelectProps={{
        disableUnderline: true,
        autoWidth: true,
        labelId: "sort-panel",
      }}
      size="small"
      onChange={(event) => onSelect(event.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SvgIcon component={SortIcon} inheritViewBox fontSize="inherit" />
          </InputAdornment>
        ),
      }}
      sx={{
        "&:focus": {
          background: "none",
        },
      }}
    >
      <ListSubheader>{selectTitle}</ListSubheader>
      {Object.keys(options).map((optionKey) => (
        <MenuItem
          key={optionKey}
          value={optionKey}
          data-testid={`sort-panel-${optionKey}`}
        >
          {options[optionKey]}
        </MenuItem>
      ))}
    </TextField>
  );
}

SortPanel.propTypes = {
  selectTitle: PropTypes.string.isRequired,
  options: PropTypes.objectOf(PropTypes.string).isRequired,
  selectedOption: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  hideTitleText: PropTypes.bool,
};

SortPanel.defaultProps = {
  onSelect: null,
  hideTitleText: false,
};

export default SortPanel;
