import PropTypes from "prop-types";

import { Checkbox, FormControlLabel } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

export default function ConfirmationCheckbox({
  label,
  isError,
  isConfirmed,
  toggleCheckbox,
  errorMessageText,
}) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box
        p={1.25}
        borderRadius={1}
        display="inline-flex"
        backgroundColor="var(--mui-color-orange-50)"
        border={isError ? `1px solid var(--mui-palette-error-main)` : "none"}
      >
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              color="primaryV2"
              checked={isConfirmed}
              onChange={toggleCheckbox}
              inputProps={{ "data-testid": "confirmation-checkbox" }}
            />
          }
        />
      </Box>
      {errorMessageText && isError && (
        <Text
          component="p"
          color="error.main"
          variant="textSm"
          data-testid="confirmation-error-text"
        >
          {errorMessageText}
        </Text>
      )}
    </Box>
  );
}

ConfirmationCheckbox.propTypes = {
  isError: PropTypes.bool,
  errorMessageText: PropTypes.string,
  label: PropTypes.string.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  toggleCheckbox: PropTypes.func.isRequired,
};

ConfirmationCheckbox.defaultProps = {
  isError: false,
  errorMessageText: "",
};
