const VIDEO_TUTORIALS = [
  {
    thumbnail: "CHECKLIST.ONBOARDING.VIDEO.THUMBNAIL_1",
    url: "CHECKLIST.ONBOARDING.VIDEO.LINK_1",
    duration: "CHECKLIST.ONBOARDING.VIDEO.DURATION_1",
    title: "CHECKLIST.ONBOARDING.VIDEO.TITLE_1",
  },
  {
    thumbnail: "CHECKLIST.ONBOARDING.VIDEO.THUMBNAIL_2",
    url: "CHECKLIST.ONBOARDING.VIDEO.LINK_2",
    duration: "CHECKLIST.ONBOARDING.VIDEO.DURATION_2",
    title: "CHECKLIST.ONBOARDING.VIDEO.TITLE_2",
  },
];

const STATIC_GUIDE = "create-account";

export { STATIC_GUIDE, VIDEO_TUTORIALS };
