import PropTypes from "prop-types";
import { useCallback, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import { usePendingReviewsCount } from "@workflow/data/reviews";
import noop from "lodash/noop";

import { Tooltip } from "@shared/UIKit";

import { KEYBOARD_SHORTCUTS } from "@shared/constants/shortcutKey";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

function PendingReviewsContainer({ children, onClick }) {
  const { t } = useTranslation();

  const { data: pendingReviewsCount, isLoading } = usePendingReviewsCount();
  const pendingReviewButtonRef = useRef();

  const openPendingReviewsPanel = useCallback((type) => {
    eventService.emitEvent({
      eventName: EVENT_NAMES.PENDING_REVIEWS.NAV_BUTTON.CLICKED,
      eventData: {
        [analytics.LABEL.OPENED]:
          type === "button"
            ? analytics.LABEL.VIA_BUTTON
            : analytics.LABEL.VIA_SHORTCUT,
      },
    });
  }, []);

  const openPendingReviews = useCallback(
    (event, type) => {
      onClick();
      openPendingReviewsPanel(type);
    },
    [onClick, openPendingReviewsPanel]
  );

  useHotkeys(KEYBOARD_SHORTCUTS.P, (event) => {
    /* istanbul ignore next */
    openPendingReviews(event);
  });

  return (
    <Tooltip
      shortcutKeys={[KEYBOARD_SHORTCUTS.P.toUpperCase()]}
      title={t("PENDING_REVIEWS.NAVIGATION_BUTTON")}
    >
      {children({
        ref: pendingReviewButtonRef,
        pendingReviewsCount,
        onClick: (event) => openPendingReviews(event, "button"),
        isLoading,
      })}
    </Tooltip>
  );
}

PendingReviewsContainer.propTypes = {
  children: PropTypes.func.isRequired,
  onClick: PropTypes.func,
};

PendingReviewsContainer.defaultProps = {
  onClick: noop,
};

export default PendingReviewsContainer;
