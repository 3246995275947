import VIEWER_DISPLAY_FORMATS from "@feedback/constants/viewerDisplayFormats";
import VIEWER_LOCAL_STORAGE from "@feedback/constants/viewerLocalStorage";
import VIEWER_ZOOM_MODES from "@feedback/constants/viewerZoomModes";
import { markerService } from "@feedback/services/marker";
import reviewService from "@feedback/services/reviewService";
import authenticationService from "@supporting/services/authentication";
import userService from "@supporting/services/userService";
import { instance as fileService } from "@workflow/services/fileService";

import MEDIA_TYPES from "@shared/constants/mediaTypes";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

const displayFormats = {};
const zoomModes = {};

const highlightMarker = function (marker, staticHighlight, reviewId) {
  markerService.highlightMarker(marker, staticHighlight, reviewId);
};

const unHighlightMarker = function (marker, staticHighlight, reviewId) {
  markerService.unHighlightMarker(marker, staticHighlight, reviewId);
};

const focusMarker = function (marker, reviewId) {
  markerService.focusMarker(marker, reviewId);
};

const getDefaultDisplayFormat = function (mediaType) {
  if (VIEWER_DISPLAY_FORMATS[mediaType]) {
    return VIEWER_DISPLAY_FORMATS[mediaType].DEFAULT_FORMAT;
  }
};

const getDisplayFormatFromLocalStorage = function (mediaType) {
  if (VIEWER_LOCAL_STORAGE[mediaType]?.DISPLAY_FORMAT_KEY) {
    return localStorage.getItem(
      VIEWER_LOCAL_STORAGE[mediaType].DISPLAY_FORMAT_KEY
    );
  }
};

const setDisplayToLocalStorage = function (mediaType, displayFormat) {
  localStorage.setItem(
    VIEWER_LOCAL_STORAGE[mediaType].DISPLAY_FORMAT_KEY,
    displayFormat
  );
};

const setDisplayFormat = function (mediaType, displayFormat) {
  const user = authenticationService.fetchUser();

  if (mediaType === MEDIA_TYPES.VIDEO) {
    const { settings } = user;
    userService.update({
      settings: {
        ...settings,
        viewerDisplayFormat: {
          ...settings.viewerDisplayFormat,
          video: displayFormat,
        },
      },
    });
    return;
  }

  displayFormats[mediaType] = displayFormat;
  setDisplayToLocalStorage(mediaType, displayFormat);
};

const getDisplayFormat = function (mediaType) {
  if (!mediaType) {
    return;
  }

  if (mediaType === MEDIA_TYPES.VIDEO) {
    const user = authenticationService.fetchUser();
    return user.settings.viewerDisplayFormat.video;
  }

  const displayFormat =
    displayFormats[mediaType] ||
    getDisplayFormatFromLocalStorage(mediaType) ||
    getDefaultDisplayFormat(mediaType);

  if (
    !displayFormats[mediaType] ||
    displayFormats[mediaType] !== displayFormat
  ) {
    setDisplayFormat(mediaType, displayFormat);
  }

  return displayFormat;
};

const setZoomModeToLocalStorage = function (mediaType, zoomMode) {
  localStorage.setItem(VIEWER_LOCAL_STORAGE[mediaType].ZOOM_MODE_KEY, zoomMode);
};
const setZoomMode = function (mediaType, zoomMode) {
  zoomModes[mediaType] = zoomMode;

  setZoomModeToLocalStorage(mediaType, zoomMode);
};

const getZoomModeFromLocalStorage = function (mediaType) {
  if (VIEWER_LOCAL_STORAGE[mediaType]?.ZOOM_MODE_KEY) {
    return localStorage.getItem(VIEWER_LOCAL_STORAGE[mediaType].ZOOM_MODE_KEY);
  }
};

const getDefaultZoomMode = function (mediaType) {
  if (VIEWER_ZOOM_MODES[mediaType]) {
    return VIEWER_ZOOM_MODES[mediaType].DEFAULT_MODE;
  }
};

const getZoomMode = function (mediaType) {
  if (!mediaType) {
    return;
  }

  const zoomMode =
    zoomModes[mediaType] ||
    getZoomModeFromLocalStorage(mediaType) ||
    getDefaultZoomMode(mediaType);

  if (!zoomModes[mediaType] || zoomModes[mediaType] !== zoomMode) {
    setZoomMode(mediaType, zoomMode);
  }

  return zoomMode;
};

const onDisplayUnitChanged = function (viewerType, displayFormat, reviewId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.VIEWER.DISPLAY_FORMAT.CHANGED,
    eventData: { displayFormat, reviewId },
  });

  setDisplayFormat(viewerType, displayFormat);
};

const onZoomModeChanged = function (viewerType, zoomMode) {
  setZoomMode(viewerType, zoomMode);
};

const getMarkerType = function (versionMediaType) {
  if (
    versionMediaType === MEDIA_TYPES.DOCUMENT ||
    versionMediaType === MEDIA_TYPES.DOCUMENT_XOD
  ) {
    return "PAGE";
  }

  if (versionMediaType === MEDIA_TYPES.VIDEO) {
    return getDisplayFormat(MEDIA_TYPES.VIDEO);
  }

  return "STANDARD";
};

/**
 * Fires an event, such that the handles can handle the creation of a marker
 * @param  {Object} marker
 * @param  {String} reviewId
 */
const onMarkerCreated = function (marker, reviewId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.VIEWER.MARKER.CREATED,
    eventData: { marker, reviewId },
  });
};

const trackFileEvent = async function (
  reviewId,
  eventAction,
  eventCategory,
  trackingProps
) {
  const user = authenticationService.fetchUser();
  const review = reviewService.getReviewById(reviewId);
  const file = await fileService.getFile(review.fileId);

  const version = file.versions.find(
    (version) => version.id === review.versionId
  );

  const properties = {
    fileId: file._id,
    versionId: version.id,
    versionName: version.name,
    versionNumber: version.number,
    versionExtension: version.original.extension,
    versionSize: version.original.sizeInBytes,
    versionType: fileService.convertToAnalyticsFileType(
      version.original.mediaType
    ),
    isUploader: user._id === version.uploadedBy,
    projectId: file.projectId,
    ...trackingProps,
  };

  analytics.track(eventAction, eventCategory, properties);
};

export default {
  highlightMarker,
  unHighlightMarker,
  focusMarker,
  setDisplayFormat,
  setZoomMode,
  getDisplayFormat,
  getZoomMode,
  onDisplayUnitChanged,
  onZoomModeChanged,
  getMarkerType,
  onMarkerCreated,
  trackFileEvent,
};
