/* eslint-disable complexity */
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";

import { Chip, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";
import {
  pendingReviewsData,
  SORT_OPTIONS,
  usePendingReviews,
  usePendingReviewsCount,
} from "@workflow/data/reviews";

import PendingReviewsCard from "./PendingReviewsCard";
import PendingReviewsEmptyState from "./PendingReviewsEmptyState";
import PendingReviewsHeader from "./PendingReviewsHeader";
import PendingReviewsLoading from "./PendingReviewsLoading";
import PendingReviewsPaginationLoading from "./PendingReviewsPaginationLoading";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    position: "sticky",
    width: 400,
    zIndex: 1000,
    boxShadow: "0 1px 4px 0 #dbdbdb;",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    background: theme.color.gray[50],
  },
  newAvailableChip: {
    color: theme.color.purple[500],
    background: theme.color.purple[100],
    "&:hover": {
      background: theme.color.purple[200],
      color: theme.color.purple[600],
    },
    fontWeight: theme.typography.fontWeight.semiBold,
    position: "absolute",
    top: 60,
    boxShadow: `0px 4px 10px rgba(112, 112, 112, 0.25);`,
  },
}));

const checkShowChip = (isLoading, isStale) => {
  return !isLoading && isStale;
};

function PendingReviews() {
  const { t } = useTranslation();
  const classes = useStyles();

  const user = useActiveUser();
  const sort =
    user.settings?.selectedPendingReviewSortingOption || SORT_OPTIONS.DUE_DATE;

  const { data: pendingReviewsCount } = usePendingReviewsCount();
  const { data, hasNextPage, fetchNextPage, isLoading, isFetching, isStale } =
    usePendingReviews(sort);

  const showChip = checkShowChip(isLoading, isStale);

  const handleInvalidate = () => {
    pendingReviewsData.refetchPendingReviews(sort);
  };

  return (
    <>
      <Box className={classes.headerWrapper}>
        <PendingReviewsHeader
          pendingReviewsCount={pendingReviewsCount}
          sortOption={sort}
          onSortOptionChanged={handleInvalidate}
        />
      </Box>

      {!isFetching && pendingReviewsCount === 0 && <PendingReviewsEmptyState />}
      {pendingReviewsCount > 0 && (
        <Box overflow="auto">
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            mt={0.5}
            data-testid="pending-reviews-card-list"
          >
            {showChip && (
              <Chip
                className={classes.newAvailableChip}
                label={t("AWAITING-MY-REVIEW.UPDATES-AVAILABLE")}
                size="small"
                data-testid="new-available-chip"
                onClick={handleInvalidate}
              />
            )}
            {data?.pages.map((page) =>
              page.map((pendingReview) => (
                <PendingReviewsCard
                  key={pendingReview.id}
                  pendingReview={pendingReview}
                />
              ))
            )}
            {!isLoading && (
              <>
                {!isFetching && hasNextPage && (
                  <Waypoint
                    fireOnRapidScroll
                    onEnter={() => {
                      fetchNextPage();
                    }}
                    data-testid="pending-reviews-next-page"
                    bottomOffset="-200px"
                  />
                )}
                <PendingReviewsPaginationLoading
                  isPaginationLoading={isFetching}
                />
              </>
            )}
          </Box>
        </Box>
      )}
      {isLoading && <PendingReviewsLoading />}
    </>
  );
}

export default PendingReviews;
