import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import IntegrationsSettingsButtons from "@integrations/components/Integrations/IntegrationsSettings/IntegrationsSettingsButtons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { AutomationFeatureGate } from "@supporting/hooks/useGatedFeature/featureGates";
import AutomationsButton from "@workflow/components/AutomationsButton/AutomationsButton";
import AutomationsDialog from "@workflow/components/AutomationsDialog/AutomationsDialog";
import Collaborators from "@workflow/components/Collaborators/Collaborators";
import HeaderSeparator from "@workflow/components/HeaderSeparator/HeaderSeparator";
import ProjectListMobile from "@workflow/components/ProjectListMobile/ProjectListMobile";
import useProjectSteps from "@workflow/hooks/useProjectSteps";

import { Box, InfoToolTip } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import useDialogState from "@shared/hooks/useDialogState";
import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";

import ProjectHeaderName from "./ProjectHeaderName/ProjectHeaderName";
import ProjectMenu from "./ProjectMenu/ProjectMenu";

const useStyles = makeStyles({
  projectHeader: {
    padding: "10px 10px 10px 17px",
    minHeight: 60,
  },
  separator: {
    marginTop: 9,
  },
});

// eslint-disable-next-line complexity
function ProjectHeader({
  project,
  currentUserTeam,
  projectTeam,
  isCurrentProjectLocked,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isExternalProject = projectTeam._id !== currentUserTeam._id;

  const automationsDialog = useDialogState();

  const canDeleteProject = project.permissions.delete;

  const [steps] = useProjectSteps(project.id);

  const { checkAndProceed, isLimitReached } = useGatedFeature({
    featureGate: AutomationFeatureGate,
    currentValue: project.automations.length,
    teamId: currentUserTeam._id,
  });

  const automationsClickHandler = useCallback(() => {
    if (project.automations.length === 0 && isLimitReached) {
      checkAndProceed()();
    } else {
      automationsDialog.openDialog();
    }
  }, [checkAndProceed, automationsDialog, project.automations, isLimitReached]);

  return (
    <Box component="header">
      <Box
        className={classes.projectHeader}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        boxSizing="border-box"
        data-testid="project-header"
      >
        {isMobile ? (
          <ProjectListMobile />
        ) : (
          <ProjectHeaderName project={project} team={currentUserTeam} />
        )}
        {(!isMobile || !isCurrentProjectLocked) && (
          <ProjectMenu
            team={currentUserTeam}
            project={project}
            openAutomationsDialog={automationsClickHandler}
          />
        )}
        {!canDeleteProject && !isMobile && (
          <InfoToolTip
            placement="bottom"
            height={30}
            title={
              <Box marginY={1.875} marginX={2.5}>
                {t("PROJECT.PROJECT.HELP_CANNOT_MANAGE_PROJECT-2")}
              </Box>
            }
          >
            <Button
              color="primaryV2"
              size="small"
              variant="text"
              sx={{ cursor: "help" }}
              startIcon={<InfoOutlinedIcon fontSize="small" />}
              data-testid="manage-project-disabled-btn"
            >
              {t("PROJECT.PROJECT.HELP_CANNOT_MANAGE_PROJECT-1")}
            </Button>
          </InfoToolTip>
        )}

        {canDeleteProject && <IntegrationsSettingsButtons />}

        <>
          {!isExternalProject && !isMobile && (
            <>
              <HeaderSeparator />
              <AutomationsButton
                project={project}
                onClick={automationsClickHandler}
                steps={steps}
              />
            </>
          )}

          {automationsDialog.isOpen && (
            <AutomationsDialog
              project={project}
              closeDialog={automationsDialog.closeDialog}
              isOpen={automationsDialog.isOpen}
              canManageAutomations={
                projectTeam.permissions.canManageAutomations
              }
              steps={steps}
            />
          )}
        </>
        {project.permissions.manageMembers && (
          <>
            {!isMobile && <HeaderSeparator />}
            <Collaborators team={currentUserTeam} project={project} />
          </>
        )}
      </Box>
    </Box>
  );
}

ProjectHeader.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  currentUserTeam: teamProp.isRequired,
  projectTeam: teamProp,
  isCurrentProjectLocked: PropTypes.bool,
};

ProjectHeader.defaultProps = {
  projectTeam: null,
  isCurrentProjectLocked: false,
};

export default ProjectHeader;
