import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteOutline } from "@mui/icons-material";
import { Divider, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";

import ConfirmationDialog from "@shared/UIKit/ConfirmationDialog";

import { fileProp, versionProp } from "@shared/props/file";

function DeleteVersionMenuItem({ file, version, variant, closeMenu }) {
  const { t } = useTranslation();
  const [isVersionRemoveDialogOpen, setIsVersionRemoveDialogOpen] =
    useState(false);
  const openVersionRemoveDialog = () => {
    setIsVersionRemoveDialogOpen(true);
  };

  const closeVersionRemoveDialog = () => {
    setIsVersionRemoveDialogOpen(false);
    closeMenu();
  };

  const removeVersion = async () => {
    await fileService.removeVersion(file, version.id);
  };

  return (
    <>
      {variant === "version" && <Divider />}
      <MenuItem
        data-testid="project-file-menu-delete-version"
        onClick={openVersionRemoveDialog}
        data-accent-color="error"
      >
        <ListItemIcon>
          <DeleteOutline />
        </ListItemIcon>
        <ListItemText
          primary={t("FILE.MENU.DELETE_VERSION", {
            versionNumber: version.number,
          })}
        />
      </MenuItem>

      {isVersionRemoveDialogOpen && (
        <ConfirmationDialog
          isOpen
          title={t("PROJECT.DIALOGS.DELETEVERSION.HEADLINE", {
            number: version.number,
          })}
          description={t("PROJECT.DIALOGS.DELETEVERSION.DESCRIPTION", {
            number: version.number,
          })}
          confirmLabel={t("VERSION.DELETE")}
          closeDialog={closeVersionRemoveDialog}
          answer={removeVersion}
        />
      )}
    </>
  );
}

DeleteVersionMenuItem.propTypes = {
  file: fileProp.isRequired,
  version: versionProp.isRequired,
  variant: PropTypes.oneOf(["file", "version"]),
  closeMenu: PropTypes.func.isRequired,
};

DeleteVersionMenuItem.defaultProps = {
  variant: "file",
};

export default DeleteVersionMenuItem;
