import PropTypes, { number, string, bool, object } from "prop-types";

export const billingProp = PropTypes.shape({
  usedStorageSizeByte: number,
  customerId: string,
  subscriptionId: string,
});

export const billingPrice = PropTypes.shape({
  amount: number,
  currency: string,
  description: string,
  id: string,
  interval: string,
  isFree: bool,
  limits: object,
  name: string,
});

export const cardStateProps = {
  submitError: PropTypes.shape({ message: string }),
  card: PropTypes.shape({ name: string }),
  stripeElement: PropTypes.shape({ isComplete: bool }),
  isProcessingCheckout: bool,
  cardCvc: PropTypes.shape({ error: object, focused: bool, empty: bool }),
  cardExpiry: PropTypes.shape({ error: object, focused: bool, empty: bool }),
  cardNumber: PropTypes.shape({ error: object, focused: bool, empty: bool }),
};
