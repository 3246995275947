import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Box } from "@shared/UIKit";

import DeleteTeamCard from "./deleteTeam/DeleteTeamCard";
import EditTeamNameCard from "./EditTeamNameCard";
import PreviewQualitySetting from "./PreviewQualitySetting";
import ReviewDecisionSetting from "./reviewDecisionSetting/ReviewDecisionSetting";
import VerifyReviewerEmailSetting from "./VerifyReviewerEmailSetting";

function TeamSettings() {
  const team = useSelectedTeam();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <EditTeamNameCard team={team} />
      <VerifyReviewerEmailSetting team={team} />
      <PreviewQualitySetting team={team} />
      <ReviewDecisionSetting team={team} />
      <DeleteTeamCard team={team} />
    </Box>
  );
}

export default TeamSettings;
