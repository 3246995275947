import PropTypes from "prop-types";
import { useState, useCallback, useEffect, useRef, memo } from "react";
import { useTranslation } from "react-i18next";

import reviewService from "@feedback/services/reviewService";
import { Button } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import ReviewCardLoadingState from "@workflow/components/ReviewCardLoadingState/ReviewCardLoadingState";
import findLast from "lodash/findLast";

import { Tooltip, Box } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { FILE_VERSION_UPLOAD_STATUS } from "@shared/constants/upload";
import { versionProp, fileProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import ReviewStatus from "../../ReviewStatus/ReviewStatus";
import classes from "./NotSharedState.module.css";

function NotSharedState({ file, project, version, step }) {
  const { t } = useTranslation();
  const uploadStatus = file.versions[file.versions.length - 1].uploadStatus;
  const [isCreatingReview, setCreatingReview] = useState(false);
  const user = useActiveUser();
  const isNewOverviewEnabled =
    user?.settings?.earlyAccessFeatures?.fileViewRedesign;

  const getPreviousReview = useCallback(() => {
    if (isNewOverviewEnabled) {
      return null;
    }
    const activeIndex = file.versions.findIndex(
      (fileVersion) => fileVersion.id === version.id
    );
    return activeIndex > 0
      ? findLast(file.versions, (version) =>
          version.reviews.find((review) => review.stepId === step.id)
        )?.reviews.find((review) => review.stepId === step.id)
      : null;
  }, [file, step, version, isNewOverviewEnabled]);

  const previousReview = getPreviousReview(file, step, version);
  const isReviewApproved = Boolean(
    previousReview?.status.state === REVIEW_STATUS.APPROVED
  );

  const isComponentMounted = useRef(true);
  useEffect(() => {
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  const handleStartReviewClicked = useCallback(
    async (event) => {
      event.stopPropagation();
      setCreatingReview(true);
      try {
        const review = await reviewService.create(version.id, step.id);
        analytics.track(analytics.ACTION.STARTED, analytics.CATEGORY.REVIEW, {
          stepId: review.stepId,
          fileId: review.fileId,
          versionNumber: review.number,
        });
      } catch (event) {
        errorHandlerService.handleError(event);

        /* istanbul ignore else */
        if (isComponentMounted.current) {
          setCreatingReview(false);
        }
      }
    },
    [setCreatingReview, version.id, step.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box
      pt={1}
      pb={0.75}
      px={1.25}
      height="100%"
      bgcolor={isReviewApproved ? "success.light" : "grey.50"}
      className={classes.root}
    >
      {isCreatingReview ? (
        <ReviewCardLoadingState />
      ) : (
        <Box
          display="flex"
          justifyContent={previousReview ? "space-between" : "flex-end"}
          data-testid="file-review-card-not-shared"
          flexDirection="column"
          height="100%"
        >
          {previousReview && (
            <Box
              display="flex"
              justifyContent="end"
              data-testid="previous-review-approved-container"
            >
              <ReviewStatus review={previousReview} project={project} />
            </Box>
          )}

          {!project.isArchived &&
            version.permissions.startReview &&
            (uploadStatus === FILE_VERSION_UPLOAD_STATUS.COMPLETED ||
              !uploadStatus) && (
              <Tooltip
                title={t("FILE_REVIEW.START_REVIEW_TOOLTIP", {
                  version: version.number,
                  filename: file.name,
                  step: step.name,
                })}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="extraSmall"
                  shape="rounded"
                  onClick={handleStartReviewClicked}
                  data-testid="start-review-link"
                  className={classes.startReviewButton}
                >
                  {t("FILE_REVIEW.START_REVIEW")}
                </Button>
              </Tooltip>
            )}
        </Box>
      )}
    </Box>
  );
}

NotSharedState.propTypes = {
  step: stepProps.isRequired,
  version: versionProp.isRequired,
  file: fileProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
};

export default memo(NotSharedState);
