import PropTypes from "prop-types";
import { useCallback } from "react";

import { Icon, SvgIcon } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import sectionService from "@workflow/services/sectionService";
import cx from "classnames";

import { Box, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import FileUpload from "@assets/img/icons/ic_file_upload_24px.svg";

import SectionHeader from "./Section/SectionHeader/SectionHeader";

const STEP_WIDTH = 290;

const useStyles = makeStyles((theme) => ({
  fileUploadIcon: {
    marginLeft: -2,
  },
  fileDropzoneText: {
    maxWidth: "85%",
    zIndex: 500,
  },
  Icon: {
    color: theme.color.green[500],
    width: 40,
    height: 38,
    marginLeft: 15,
    marginTop: 10,
  },
  separatorCollapseSection: {
    borderBottom: `1px dashed ${theme.color.gray[300]}`,
    boxSizing: "border-box",
  },
  sectionDragActive: {
    [theme.breakpoints.down("md")]: {
      width: "98vw",
    },
    maxWidth: ({ maxWidth, steps }) => maxWidth + steps * STEP_WIDTH,
    minWidth: ({ minWidth, steps }) => minWidth + steps * STEP_WIDTH,
    width: ({ width, steps }) => width + steps * STEP_WIDTH,
  },
}));

function SectionHeaderItem({
  section,
  steps,
  project,
  sectionDragActive,
  isSectionRowActiveSticky,
}) {
  const { minWidth, maxWidth, width } = useFileCardResized();
  const classes = useStyles({
    steps: steps.length,
    minWidth,
    maxWidth,
    width,
  });
  const theme = useTheme();

  const handleCollapseSection = useCallback(async () => {
    try {
      await sectionService.collapseSection(
        project.id,
        section.id,
        !section.isCollapsed
      );
      analytics.track(
        !section.isCollapsed
          ? analytics.ACTION.COLLAPSED
          : analytics.ACTION.EXPANDED,
        analytics.CATEGORY.SECTION
      );
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [project.id, section.id, section.isCollapsed]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {sectionDragActive && sectionDragActive.includes(section.id) && (
        <>
          <Box
            data-testid="section-drop-file-section-header"
            display="flex"
            height={isSectionRowActiveSticky ? 35 : 40}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            borderBottom={`2px dashed ${theme.color.green[500]}`}
            position="absolute"
            left={0}
            bottom={0}
            top={1}
            zIndex={200}
            color={theme.color.green[500]}
            marginLeft={{ sm: 0.5, md: 0 }}
            paddingBottom="5px"
            className={classes.sectionDragActive}
          />
          <Box
            data-testid="section-drop-file-upload"
            display="flex"
            height={35}
            width={360 + steps.length * 295}
            marginRight={theme.spacing(2)}
            bgcolor="transparent"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            bottom={0}
            color={theme.color.green[500]}
            top={
              section.files.length > 2 || section.files.length === 0 ? 90 : 60
            }
            style={{
              pointerEvents: "none",
              gap: 15,
            }}
          >
            <Box
              bgcolor={theme.color.white}
              data-testid="section-drop-file-upload-icon"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width={40}
              height={35}
              paddingBottom="2px"
              className={cx(
                "animated",
                "fadeIn",
                "dragover",
                "drop-hint__icon-container"
              )}
            >
              <Icon fontSize="small">
                <SvgIcon
                  component={FileUpload}
                  className={classes.fileUploadIcon}
                />
              </Icon>
            </Box>
            <Text
              color={theme.color.green[500]}
              fontSize={theme.typography.body1.fontSize}
              fontWeight={theme.typography.fontWeightBold}
              textAlign="center"
              className={classes.fileDropzoneText}
              translate="PROJECT.FILE.DRAG&DROP.PLACEHOLDER"
            />
          </Box>
        </>
      )}
      <Box
        position="relative"
        width="max-content"
        mb={0.25}
        data-testid="section-data"
        className={cx({
          [classes.separatorCollapseSection]: section?.isCollapsed,
        })}
      >
        <Box display="flex">
          <SectionHeader
            section={section}
            steps={steps}
            project={project}
            onCollapseSection={handleCollapseSection}
          />
          <Box
            minWidth={steps.length * 290}
            minHeight={37}
            bgcolor={theme.color.gray[50]}
          />
        </Box>
      </Box>
    </>
  );
}

SectionHeaderItem.propTypes = {
  section: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  sectionDragActive: PropTypes.array,
  isSectionRowActiveSticky: PropTypes.bool,
};

SectionHeaderItem.defaultProps = {
  sectionDragActive: [],
  isSectionRowActiveSticky: false,
};

export default SectionHeaderItem;
