import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

const projectStepsCache = new Map();
const fetchProjectStepsPromises = new Map();

const stepsCache = new Map();
const fetchStepsPromises = new Map();

export function initialize() {
  websocket.addListener(websocket.EVENT_NAMES.STEP.CREATED, onStepCreated);
  websocket.addListener(websocket.EVENT_NAMES.STEP.REMOVED, onStepRemoved);
  websocket.addListener(websocket.EVENT_NAMES.STEP.MOVED, onStepMoved);
  websocket.addListener(websocket.EVENT_NAMES.STEP.RENAMED, onStepRenamed);

  websocket.addListener(
    websocket.EVENT_NAMES.STEP.ACCESS.GAINED,
    onStepCreated
  );
  websocket.addListener(websocket.EVENT_NAMES.STEP.ACCESS.LOST, onStepRemoved);

  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.ADDED,
    onStepReviewerAdded
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.REMOVED,
    onStepReviewerRemoved
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.DECISION_REQUESTED,
    onStepReviewerUpdated
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.SETTINGS.UPDATED,
    onStepSettingsUpdated
  );

  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);
}

export async function getProjectSteps(projectId) {
  return projectStepsCache.has(projectId)
    ? projectStepsCache.get(projectId)
    : await fetchProjectSteps(projectId);
}

export async function getStep(stepId) {
  return stepsCache.has(stepId)
    ? stepsCache.get(stepId)
    : await fetchStep(stepId);
}

export async function createStep(projectId, name) {
  const step = await backend.post(`/projects/${projectId}/steps`, { name });
  step.isNew = true;

  stepsCache.set(step.id, step);
  updateOrAddStepOnProjectStepsCache(step);

  eventService.emitEvent({
    eventName: EVENT_NAMES.STEP.CREATED,
    eventData: {
      stepId: step.id,
    },
  });

  return step;
}

export function hasReviews(stepId) {
  return backend.get(`/steps/${stepId}/have_versions`);
}

export async function deleteStep(step) {
  await backend.del(`/steps/${step.id}`);
}

export async function updateStepPosition(step, position) {
  await backend.put(`/steps/${step.id}/position`, {
    position,
  });
}

export function updateStepFocusMode(stepId) {
  if (stepsCache.has(stepId)) {
    const updatedStep = { ...stepsCache.get(stepId), isNew: false };
    stepsCache.set(stepId, updatedStep);
    updateOrAddStepOnProjectStepsCache(updatedStep);
  }
}

export async function updateName(stepId, name) {
  await backend.put(`/steps/${stepId}/update_name`, {
    name,
  });
}

export async function updateSettings(stepId, settings) {
  await backend.put(`/steps/${stepId}/settings`, settings);
}

export async function addReviewers(
  stepId,
  reviewerEmails,
  message,
  notifyEmail,
  requestDecision
) {
  await backend.post(`/steps/${stepId}/reviewers`, {
    reviewerEmails,
    message,
    notifyEmail,
    requestDecision,
  });
}

export async function removeReviewer(stepId, reviewerId) {
  await backend.del(`/steps/${stepId}/reviewers/${reviewerId}`);
  analytics.track(analytics.ACTION.REMOVED, analytics.CATEGORY.REVIEWER);
}

export function fetchMentionSuggestions(stepId, isTeamOnlyComment) {
  return backend.get(`/steps/${stepId}/mention-suggestions`, {
    is_team_only_comment: Boolean(isTeamOnlyComment),
  });
}

export async function changeReviewerDecisionRequest(
  stepId,
  reviewerId,
  requestDecision
) {
  await backend.put(`/steps/${stepId}/reviewers/${reviewerId}/request-review`, {
    requestDecision,
  });
}

async function fetchProjectSteps(projectId) {
  if (fetchProjectStepsPromises.has(projectId)) {
    return fetchProjectStepsPromises.get(projectId);
  }
  try {
    const promise = fetchStepsAndUpdateCache(projectId);
    fetchProjectStepsPromises.set(projectId, promise);
    return await promise;
  } finally {
    fetchProjectStepsPromises.delete(projectId);
  }
}

async function fetchStepsAndUpdateCache(projectId) {
  const steps = await backend.get(`/projects/${projectId}/steps`);
  projectStepsCache.set(projectId, steps);
  steps.forEach((step) => stepsCache.set(step.id, step));
  emitProjectStepsUpdated(projectId, steps);
  return steps;
}

async function fetchStep(stepId) {
  if (fetchStepsPromises.has(stepId)) {
    return fetchStepsPromises.get(stepId);
  }
  try {
    const promise = fetchStepAndUpdateCache(stepId);
    fetchStepsPromises.set(stepId, promise);
    return await promise;
  } finally {
    fetchStepsPromises.delete(stepId);
  }
}

async function fetchStepAndUpdateCache(stepId) {
  const step = await backend.get(`/steps/${stepId}`);
  stepsCache.set(stepId, step);
  updateOrAddStepOnProjectStepsCache(step);
  return step;
}

function updateOrAddStepOnProjectStepsCache(newStep) {
  if (projectStepsCache.has(newStep.projectId)) {
    const oldSteps = projectStepsCache.get(newStep.projectId);
    const isUpdate = oldSteps.some((step) => step.id === newStep.id);
    const newSteps = isUpdate
      ? oldSteps.map((step) => (step.id === newStep.id ? newStep : step))
      : [...oldSteps, newStep];
    projectStepsCache.set(newStep.projectId, newSteps);
    emitProjectStepsUpdated(newStep.projectId, newSteps);
  }
}

function emitProjectStepsUpdated(projectId, steps) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT_STEPS_WITHOUT_FILES.UPDATED,
    eventData: {
      projectId,
      steps,
    },
  });
}

function emitStepUpdatedEvent(step) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.STEP.UPDATED,
    eventData: {
      step,
    },
  });
}

async function onStepCreated(event) {
  if (projectStepsCache.has(event.projectId) && !stepsCache.has(event.stepId)) {
    await fetchStep(event.stepId);
  }
}

async function onStepRenamed(event) {
  if (stepsCache.has(event.stepId)) {
    const step = await fetchStep(event.stepId);
    emitStepUpdatedEvent(step);
  }
}

async function onStepReviewerAdded(event) {
  if (stepsCache.has(event.stepId)) {
    const step = await fetchStep(event.stepId);
    emitStepReviewersUpdatedEvent(step);
  }
}

async function onStepReviewerRemoved(event) {
  if (stepsCache.has(event.stepId)) {
    const step = await fetchStep(event.stepId);
    emitStepReviewersUpdatedEvent(step);
  }
}

async function onStepReviewerUpdated(event) {
  if (stepsCache.has(event.stepId)) {
    const step = await fetchStep(event.stepId);
    emitStepReviewersUpdatedEvent(step);
  }
}

async function onStepSettingsUpdated(event) {
  if (stepsCache.has(event.stepId)) {
    const step = await fetchStep(event.stepId);
    emitStepUpdatedEvent(step);
  }
}

async function onStepRemoved(event) {
  stepsCache.delete(event.stepId);
  if (projectStepsCache.has(event.projectId)) {
    await fetchStepsAndUpdateCache(event.projectId);
  }
  eventService.emitEvent({
    eventName: EVENT_NAMES.STEP.REMOVED,
    eventData: { stepId: event.stepId, projectId: event.projectId },
  });
}

async function onStepMoved(event) {
  if (projectStepsCache.has(event.projectId)) {
    await fetchStepsAndUpdateCache(event.projectId);
  }
}

function emitStepReviewersUpdatedEvent(step) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.STEP.REVIEWERS.UPDATED,
    eventData: {
      step,
    },
  });
}

function clearCache() {
  projectStepsCache.clear();
  stepsCache.clear();
}

export function fetchStepAccessRequests(stepId) {
  return backend.get(`/steps/${stepId}/access-requests`);
}

export function createAccessRequest(stepId, reviewId, fileId) {
  return backend.post(`/steps/${stepId}/access-requests`, {
    reviewId,
    fileId,
  });
}

export function submitAccessRequestDecision(stepId, reviewerId, decision) {
  return backend.put(`/steps/${stepId}/access-requests`, {
    decision,
    reviewerId,
  });
}
