import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Tooltip } from "@shared/UIKit";

function ShowAllReviewers({ showAllUsersPopper, extraAvatarsNumber }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("REVIEWER.SHOW_MORE_REVIEWERS")} placement="top">
      <Button
        onClick={showAllUsersPopper}
        variant="outlined"
        color="primaryV2"
        shape="round"
        data-testid="step-reviewers-show-all-reviewers"
        size="small"
        sx={{ ml: -1 }}
      >
        +{extraAvatarsNumber}
      </Button>
    </Tooltip>
  );
}

ShowAllReviewers.propTypes = {
  showAllUsersPopper: PropTypes.func.isRequired,
  extraAvatarsNumber: PropTypes.number.isRequired,
};

export default ShowAllReviewers;
