import { Elements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { useRef } from "react";

import { loadStripe } from "@stripe/stripe-js";

const StripeProvider = ({ children }) => {
  const stripePromise = useRef(null);

  if (!stripePromise.current) {
    stripePromise.current = loadStripe(window.fs.config.stripe.publishableKey);
  }

  return <Elements stripe={stripePromise.current}>{children}</Elements>;
};
StripeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default StripeProvider;
