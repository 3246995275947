import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";

import { Dialog, Text } from "@shared/UIKit";

function RemoveTeamDomainDialog({ cancel, isOpen, domainId, answer }) {
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmDialog = useCallback(() => {
    setIsSubmitting(true);
    answer(domainId);
  }, [answer, domainId]);

  return (
    <Dialog
      title={t("JOIN_WITH_SAME_DOMAIN.REMOVE_MODAL.TITLE")}
      cancel={cancel}
      open={isOpen}
      maxWidth="xs"
      actions={
        <Button
          onClick={confirmDialog}
          disabled={isSubmitting}
          loading={isSubmitting}
          color="error"
          variant="contained"
          size="large"
        >
          {t("CORE.DELETE")}
        </Button>
      }
      hideBackdrop={false}
    >
      <Text
        variant="body2"
        translate="JOIN_WITH_SAME_DOMAIN.REMOVE_MODAL_BODY"
      />
    </Dialog>
  );
}

RemoveTeamDomainDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  domainId: PropTypes.string.isRequired,
  answer: PropTypes.func.isRequired,
};

export default RemoveTeamDomainDialog;
