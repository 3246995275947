import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

import { Box, Text } from "@shared/UIKit";

const BillingRateOption = ({ billingRate, setBillingRate }) => {
  const { t } = useTranslation();

  const handleChange = (event) => {
    setBillingRate(event.target.value);
  };
  return (
    <Box display="flex" justifyContent="center">
      <FormControl>
        <RadioGroup row value={billingRate} onChange={handleChange}>
          <FormControlLabel
            data-testid="change-interval-yearly"
            value="year"
            control={<Radio color="primary" />}
            label={
              <Box display="flex" flexDirection="row">
                <Text translate="SELECT_PLAN_DIALOG.YEARLY" />
                <Text color="grey.500" pl={0.5}>
                  {t("YEARLY_SUBSCRIPTION.V9.TEXT")}
                </Text>
              </Box>
            }
          />
          <FormControlLabel
            data-testid="change-interval-monthly"
            value="month"
            control={<Radio color="primary" />}
            label={t("SELECT_PLAN_DIALOG.MONTHLY")}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

BillingRateOption.propTypes = {
  billingRate: PropTypes.string.isRequired,
  setBillingRate: PropTypes.func.isRequired,
};

export default BillingRateOption;
