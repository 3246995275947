import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DisabledCell from "./DisabledCell";
import SharedCell from "./SharedCell";

const ReviewCell = ({
  version,
  step,
  activeReviewId,
  reviewPanePosition,
  onOpenReview,
}) => {
  const { t } = useTranslation();
  const review = version.reviews.find((review) => review.stepId === step.id);

  return review ? (
    <SharedCell
      key={step.id}
      review={review}
      activeReviewId={activeReviewId}
      reviewPanePosition={reviewPanePosition}
      onOpenReview={onOpenReview}
    />
  ) : (
    <DisabledCell key={step.id}>{t("VERSION_REVIEW.NOT_SHARED")}</DisabledCell>
  );
};

ReviewCell.propTypes = {
  version: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
  activeReviewId: PropTypes.string.isRequired,
  reviewPanePosition: PropTypes.string,
  onOpenReview: PropTypes.func.isRequired,
};
ReviewCell.defaultProps = {
  reviewPanePosition: null,
};
export default ReviewCell;
