export default Object.freeze({
  PROJECT_DASHBOARD: "project-dashboard",
  ONBOARDING_WIZARD: "onboarding-wizard",
  ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS:
    "onboarding-wizard-invite-team-members",
  FILE_VIEW: "file-view",
  WORKSPACE: "workspace",
  TEAM: "team",
  TEAM_SETTINGS: "team-settings",
  PROFILE: "profile",
  STEP_LOGIN: "restricted-review-link",
  NAVIGATION_BAR: "navigation-bar",
});
