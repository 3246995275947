import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Chip } from "@mui/material";

const CHIP_TRANSLATIONS = {
  project: "SEARCH.PROJECTS",
  file: "SEARCH.FILES",
  comment: "SEARCH.COMMENTS",
};

export default function FilterChip({
  type,
  onClick,
  isSelected,
  onDelete,
  isSectionActive,
  isSectionActiveIndex,
}) {
  const { t } = useTranslation();

  return (
    <Chip
      data-testid={`search-filter-chip-${type}`}
      label={t(CHIP_TRANSLATIONS[type])}
      color="primaryV2"
      variant={isSelected ? "default" : "outlined"}
      size="small"
      onClick={() => onClick(type)}
      {...(isSelected && { onDelete: () => onDelete(type) })}
      sx={{
        backgroundColor:
          !isSelected && isSectionActive && isSectionActiveIndex && "grey.300",
      }}
    />
  );
}

FilterChip.propTypes = {
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isSectionActive: PropTypes.bool.isRequired,
  isSectionActiveIndex: PropTypes.bool.isRequired,
};

FilterChip.defaultProps = {
  isSelected: false,
};
