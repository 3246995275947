import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { useNavigation } from "@shared/hooks";

function StepIndicator({ currentStep }) {
  const { routeNames, goTo } = useNavigation();

  const routes = [
    routeNames.ONBOARDING_WIZARD_COMPANY_SIZE,
    routeNames.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS,
    routeNames.ONBOARDING_WIZARD_UPLOAD_FILES,
  ];

  const handleOnStepClick = (step, route) => {
    if (step < currentStep) {
      goTo(route);
    }
  };

  return (
    <Box display="flex" gap={1}>
      {routes.map((route, step) => (
        <Box
          key={route}
          height={4}
          width={32}
          borderRadius={1}
          aria-label={`Step ${step + 1}`}
          onClick={() => handleOnStepClick(step, route)}
          bgcolor={step < currentStep ? "primary.700" : "primary.300"}
        />
      ))}
    </Box>
  );
}

StepIndicator.propTypes = {
  currentStep: PropTypes.number.isRequired,
};

export default StepIndicator;
