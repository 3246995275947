import PropTypes from "prop-types";

import { useTheme } from "@mui/styles";

import { Text, Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

function SectionFileCount({ fileCount, isCollapsed }) {
  const theme = useTheme();

  return (
    <Box
      zIndex={20}
      data-testid="section-file-count"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={isCollapsed ? theme.color.gray[100] : theme.color.gray[200]}
      borderRadius="3px"
      ml={1.25}
      px={1}
      py={0.25}
    >
      <Text
        color={isCollapsed ? theme.color.gray[500] : theme.color.black}
        fontSize={FSTGTypography.fontSize_1_2}
        fontWeight={theme.typography.fontWeight.semiBold}
        lineHeight={FSTGTypography.lineHeight_1_3}
        data-testid="section-file-count-label"
      >
        {fileCount}
      </Text>
    </Box>
  );
}

SectionFileCount.propTypes = {
  fileCount: PropTypes.number.isRequired,
  isCollapsed: PropTypes.bool,
};

SectionFileCount.defaultProps = {
  isCollapsed: false,
};

export default SectionFileCount;
