import { instance as teamService } from "@supporting/services/team";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

const projectTemplatesCache = new Map();

function initialize() {
  clearCache();

  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);

  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.REVIEWER.ADDED,
    onStepTemplateReviewerAdded
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.REVIEWER.REMOVED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.REVIEWER.DECISION_REQUESTED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.CREATED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.REMOVED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.RENAMED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.STEP_TEMPLATE.MOVED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.SECTION.CREATED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.SECTION.RENAMED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.SECTION.MOVED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.SECTION.REMOVED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.AUTOMATION.CREATED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.AUTOMATION.REMOVED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.AUTOMATION.UPDATED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.AUTOMATION.PAUSED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.AUTOMATION.UNPAUSED,
    onProjectTemplateUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.REMOVED,
    onProjectTemplateRemoved
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_TEMPLATE.CREATED,
    onProjectTemplateAdded
  );
}

function clearCache() {
  projectTemplatesCache.clear();
}

async function fetchProjectTemplatesByTeamId(teamId) {
  const projectTemplates = await backend.get(`/project-templates`, {
    team_id: teamId,
  });
  projectTemplates.forEach((template) => {
    projectTemplatesCache.set(template.id, template);
  });
  return projectTemplates;
}

function fetchProjectTemplateById(projectTemplateId, refreshCache = false) {
  return projectTemplatesCache.has(projectTemplateId) && !refreshCache
    ? Promise.resolve(projectTemplatesCache.get(projectTemplateId))
    : fetchProjectTemplate(projectTemplateId);
}

async function updateProjectTemplateName(projectTemplateId, name) {
  const response = await backend.put(
    `/project-templates/${projectTemplateId}/name`,
    { name }
  );
  projectTemplatesCache.set(response.id, response);
  emitTemplatesUpdatedEvent();
  emitTemplateCacheUpdatedEvent(response.id);
  return response;
}

function onProjectTemplateAdded({ projectTemplate }) {
  projectTemplatesCache.set(projectTemplate.id, projectTemplate);
  emitTemplatesUpdatedEvent();
  emitTemplateCacheUpdatedEvent(projectTemplate.id);
}

async function addProjectTemplate(projectTemplateData) {
  const response = await backend.post(
    `/project-templates`,
    projectTemplateData
  );
  await teamService.updateUsedBillingLimitsOfSelectedTeam();
  projectTemplatesCache.set(response.id, response);
  emitTemplatesUpdatedEvent();
  emitTemplateCacheUpdatedEvent(response.id);
  return response;
}

function onProjectTemplateRemoved({ projectTemplateId }) {
  projectTemplatesCache.delete(projectTemplateId);
  emitTemplateRemovedEvent(projectTemplateId);
}

async function removeProjectTemplate(projectTemplateId) {
  await backend.delete(`/project-templates/${projectTemplateId}`);
  await teamService.updateUsedBillingLimitsOfSelectedTeam();
  projectTemplatesCache.delete(projectTemplateId);
  emitTemplateRemovedEvent(projectTemplateId);

  analytics.track(
    analytics.ACTION.DELETED,
    analytics.CATEGORY.PROJECT_TEMPLATE
  );
}

async function createStep(projectTemplateId, name) {
  const step = await backend.post(
    `/project-templates/${projectTemplateId}/steps`,
    { name }
  );
  const projectTemplate = projectTemplatesCache.get(projectTemplateId);
  projectTemplate.steps.push({ ...step, isNew: true });
  projectTemplatesCache.set(projectTemplate.id, projectTemplate);
  emitTemplateCacheUpdatedEvent(projectTemplate.id);
  return step;
}

async function updateStepName(stepTemplateId, name) {
  const step = await backend.put(`/step-templates/${stepTemplateId}/name`, {
    name,
  });
  updateStepInCache(step);
  emitTemplateCacheUpdatedEvent(step.projectTemplateId);
  return step;
}

function updateStepFocusMode(stepTemplate) {
  if (projectTemplatesCache.has(stepTemplate.projectTemplateId)) {
    const projectTemplate = projectTemplatesCache.get(
      stepTemplate.projectTemplateId
    );
    const index = projectTemplate.steps.findIndex(
      (step) => step.id === stepTemplate.id
    );

    projectTemplate.steps[index].isNew = false;
    emitTemplateCacheUpdatedEvent(stepTemplate.projectTemplateId);
    return stepTemplate;
  }
}

async function updateStepSettings(stepTemplateId, settings) {
  const step = await backend.put(`/step-templates/${stepTemplateId}/settings`, {
    settings,
  });
  updateStepInCache(step);
  emitTemplateCacheUpdatedEvent(step.projectTemplateId);
  return step;
}

async function deleteStepTemplate(step) {
  await backend.delete(`/step-templates/${step.id}`);
  const projectTemplate = projectTemplatesCache.get(step.projectTemplateId);
  const steps = projectTemplate.steps.filter(
    (stepTemplate) => stepTemplate.id !== step.id
  );
  projectTemplatesCache.set(projectTemplate.id, {
    ...projectTemplate,
    steps,
  });
  emitTemplateCacheUpdatedEvent(projectTemplate.id);
}

async function removeReviewer(stepId, reviewerId) {
  const step = await backend.delete(
    `/step-templates/${stepId}/reviewers/${reviewerId}`
  );
  analytics.track(
    analytics.ACTION.REMOVED,
    analytics.CATEGORY.STEP_TEMPLATE_REVIEWER
  );
  return step;
}

function addReviewers(stepTemplateId, reviewerEmails, requestDecision) {
  return backend.post(
    `/step-templates/${stepTemplateId}/reviewers`,
    {
      reviewerEmails,
      requestDecision,
    },
    {
      withCredentials: true,
    }
  );
}

function changeReviewerDecisionRequest(
  stepTemplateId,
  reviewerId,
  requestDecision
) {
  return backend.put(
    `/step-templates/${stepTemplateId}/reviewers/${reviewerId}/request-review`,
    {
      requestDecision,
    }
  );
}

async function updateStepPosition(stepTemplate, position) {
  await backend.put(`/step-templates/${stepTemplate.id}/position`, {
    position,
  });
  const projectTemplate = await fetchProjectTemplate(
    stepTemplate.projectTemplateId
  );
  emitTemplateCacheUpdatedEvent(projectTemplate.id);
  return projectTemplate;
}

async function addSection(projectTemplateId, sectionName) {
  analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.SECTION, {
    createdVia: "Project Template",
  });
  const response = await backend.post(
    `/project-templates/${projectTemplateId}/sections`,
    { name: sectionName }
  );
  projectTemplatesCache.set(response.id, response);
  emitTemplateCacheUpdatedEvent(response.id);
}

async function renameProjectTemplateSection(
  projectTemplateId,
  sectionId,
  name
) {
  analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.SECTION, {
    createdVia: "Project Template",
  });
  const response = await backend.put(
    `/project-templates/${projectTemplateId}/sections/${sectionId}/name`,
    { name }
  );

  projectTemplatesCache.set(response.id, response);
  emitTemplateCacheUpdatedEvent(response.id);
}

async function removeProjectTemplateSection(projectTemplateId, sectionId) {
  analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.SECTION, {
    createdVia: "Project Template",
  });
  await backend.delete(
    `/project-templates/${projectTemplateId}/sections/${sectionId}`
  );

  await fetchProjectTemplate(projectTemplateId);
  emitTemplateCacheUpdatedEvent(projectTemplateId);
}

async function moveProjectTemplateSectionPosition(
  projectTemplateId,
  sectionId,
  position
) {
  analytics.track(analytics.ACTION.REORDERED, analytics.CATEGORY.SECTION, {
    createdVia: "Project Template",
  });
  const response = await backend.put(
    `/project-templates/${projectTemplateId}/sections/${sectionId}/position`,
    {
      position,
    }
  );

  projectTemplatesCache.set(response.id, response);
  emitTemplateCacheUpdatedEvent(response.id);
}

function onStepTemplateReviewerAdded({ stepTemplate }) {
  if (projectTemplatesCache.has(stepTemplate.projectTemplateId)) {
    updateStepInCache(stepTemplate);
    emitTemplateCacheUpdatedEvent(stepTemplate.projectTemplateId);
  }
}

async function onProjectTemplateUpdated({ projectTemplateId }) {
  if (projectTemplatesCache.has(projectTemplateId)) {
    await fetchProjectTemplate(projectTemplateId);
    emitTemplateCacheUpdatedEvent(projectTemplateId);
  }
}

async function fetchProjectTemplate(projectTemplateId) {
  const response = await backend.get(`/project-templates/${projectTemplateId}`);
  projectTemplatesCache.set(response.id, response);
  return response;
}

function updateStepInCache(stepTemplate) {
  const projectTemplate = projectTemplatesCache.get(
    stepTemplate.projectTemplateId
  );
  projectTemplate.steps = projectTemplate.steps.map((step) =>
    step.id === stepTemplate.id ? stepTemplate : step
  );
}

function emitTemplateCacheUpdatedEvent(projectTemplateId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT_TEMPLATE.UPDATED,
    eventData: {
      template: projectTemplatesCache.get(projectTemplateId),
    },
  });
}

function emitTemplatesUpdatedEvent() {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT_TEMPLATES.UPDATED,
    eventData: {
      templates: [...projectTemplatesCache.values()],
    },
  });
}

function emitTemplateRemovedEvent(projectTemplateId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT_TEMPLATE.REMOVED,
    eventData: {
      templateId: projectTemplateId,
    },
  });
}

async function createAutomation(projectId, { triggerId, conditions, actions }) {
  const project = await fetchProjectTemplateById(projectId);
  const automations = await backend.post(
    `/project-templates/${projectId}/automations`,
    {
      triggerId,
      conditions,
      actions,
    }
  );

  projectTemplatesCache.set(project.id, { ...project, automations });
  return emitTemplateCacheUpdatedEvent(project.id);
}

async function removeAutomation(projectId, automationId) {
  await backend.delete(
    `/project-templates/${projectId}/automations/${automationId}`
  );

  const project = await fetchProjectTemplateById(projectId, true);
  projectTemplatesCache.set(project.id, project);
  return emitTemplateCacheUpdatedEvent(project.id);
}

async function editAutomation(
  projectId,
  { automationId, triggerId, conditions, actions }
) {
  const project = await fetchProjectTemplateById(projectId);
  const automations = await backend.put(
    `/project-templates/${projectId}/automations/${automationId}`,
    {
      triggerId,
      conditions,
      actions,
    }
  );

  projectTemplatesCache.set(project.id, { ...project, automations });
  return emitTemplateCacheUpdatedEvent(project.id);
}

async function enableProjectAutomations(projectId, automationId, enabled) {
  const project = await fetchProjectTemplateById(projectId);
  const updatedAutomation = await backend.put(
    `/project-templates/${projectId}/automations/${automationId}/enabled`,
    {
      enabled,
    }
  );
  const updatedAutomations = project.automations.map((automation) =>
    automation.automationId === updatedAutomation.automationId
      ? updatedAutomation
      : automation
  );

  projectTemplatesCache.set(project.id, {
    ...project,
    automations: updatedAutomations,
  });
  return emitTemplateCacheUpdatedEvent(project.id);
}

export default {
  initialize,
  fetchProjectTemplatesByTeamId,
  fetchProjectTemplateById,
  updateProjectTemplateName,
  addProjectTemplate,
  removeProjectTemplate,
  createStep,
  updateStepName,
  updateStepFocusMode,
  updateStepSettings,
  deleteStepTemplate,
  removeReviewer,
  addReviewers,
  changeReviewerDecisionRequest,
  updateStepPosition,
  addSection,
  renameProjectTemplateSection,
  removeProjectTemplateSection,
  moveProjectTemplateSectionPosition,
  createAutomation,
  removeAutomation,
  editAutomation,
  enableProjectAutomations,
};
