import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { AUTOMATION_TRANSLATIONS } from "@workflow/constants/automation";
import useAutomationStatus from "@workflow/hooks/useAutomationStatus";

import { Tooltip } from "@shared/UIKit";

import { automationProps } from "@shared/props/automations";

function AutomationStatusButton({
  onChange,
  automationId,
  automation,
  canManageAutomations,
}) {
  const { t } = useTranslation();
  const { isAutomationReviewDecisionDisabled } =
    useAutomationStatus(automation);

  const isAutomationEnabled =
    Boolean(automation?.enabled) && !isAutomationReviewDecisionDisabled;

  const isDisabled =
    !automationId ||
    !canManageAutomations ||
    isAutomationReviewDecisionDisabled;

  const getTooltipTitle = () => {
    if (isAutomationReviewDecisionDisabled) {
      return t("PAUSED_AUTOMATION.CUSTOM_REVIEW_DECISION.TOOLTIP", {
        reviewDecision: `"${t(
          AUTOMATION_TRANSLATIONS.REVIEW_DECISION_STATES[
            automation.conditions.reviewDecision
          ]
        )}"`,
      });
    }

    return t(
      automationId
        ? `AUTOMATIONS.CREATE.TOGGLE.${
            isAutomationEnabled ? `ACTIVE` : `PAUSED`
          }.TOOLTIP`
        : "AUTOMATIONS.CREATE.TOGGLE.PAUSED.DISABLED.TOOLTIP"
    );
  };

  return (
    <Box
      bgcolor={
        isAutomationEnabled && canManageAutomations
          ? "success.light"
          : "grey.100"
      }
      data-testid="automation-status-button"
      px={2}
      py={0.625}
    >
      <Tooltip title={getTooltipTitle()} placement="top">
        <FormControlLabel
          control={
            <Switch
              disabled={isDisabled}
              checked={isAutomationEnabled}
              color="primary"
              size="small"
              onChange={onChange}
              data-testid="automation-status-switch"
            />
          }
          label={t(
            isAutomationEnabled
              ? "AUTOMATIONS.CREATE.TOGGLE.ACTIVE.TEXT"
              : "AUTOMATIONS.CREATE.TOGGLE.PAUSED.TEXT"
          )}
          sx={{
            marginRight: 0,
            "& .MuiFormControlLabel-label": {
              fontWeight: "fontWeight.medium",
              fontSize: "textSm.fontSize",
              color:
                isAutomationEnabled && !isDisabled
                  ? "success.main"
                  : "grey.400",
              display: { xs: "none", md: "block" },
            },
          }}
        />
      </Tooltip>
    </Box>
  );
}

AutomationStatusButton.propTypes = {
  automationId: PropTypes.string,
  automation: automationProps,
  onChange: PropTypes.func.isRequired,
  canManageAutomations: PropTypes.bool,
};

AutomationStatusButton.defaultProps = {
  automationId: null,
  automation: null,
  canManageAutomations: false,
};

export default AutomationStatusButton;
