import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { ErrorOutlineOutlined, ArrowDownwardSharp } from "@mui/icons-material";
import { Chip, SvgIcon, Box } from "@mui/material";
import {
  AUTOMATION_CONDITIONS_PROPERTIES,
  AUTOMATION_CARD_ACTION_KEYS,
  AUTOMATION_TRANSLATIONS,
} from "@workflow/constants/automation";
import useAutomationStatus from "@workflow/hooks/useAutomationStatus";

import { Text, Tooltip } from "@shared/UIKit";
import { useWizardDialog } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import { sectionProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";

import { createAutomationObject } from "./utils";

const AutomationCard = ({ automation, steps, sections }) => {
  const { t } = useTranslation();
  const { moveNextStep, setDialogState, state } = useWizardDialog();
  const automationObject = createAutomationObject(automation, steps, sections);
  const { isAutomationReviewDecisionDisabled } =
    useAutomationStatus(automationObject);

  const openAutomationEdit = () => {
    const trigger = state.triggers.find(
      (trigger) => trigger.id === automation.triggerId
    );
    setDialogState((prevState) => ({ ...prevState, automation, trigger }));
    moveNextStep();
  };

  const getConditionLabel = (key) => {
    if (
      key === "reviewer" &&
      automationObject.conditions[key] !== "ALL_REVIEWERS" &&
      automationObject.conditions[key] !== "AT_LEAST_ONE_REVIEWER"
    ) {
      return steps
        .map((step) => step.reviewers)
        .flat()
        .find((reviewer) => reviewer._id === automationObject.conditions[key])
        ?.displayName;
    }

    if (key === "setInactiveDays") {
      return `${automationObject.conditions[key]} ${t(
        "AUTOMATIONS.CONDITION.IF.DAYS_INACTIVE"
      )}`;
    }

    return t(
      AUTOMATION_TRANSLATIONS[automationObject.conditions[key]] ||
        automationObject.conditions[key]
    );
  };

  const getBadgeLabel = (actionKey, actionValue) => {
    if (actionKey === "setDueDate") {
      return `${automationObject.actions[actionKey]} ${t(
        "AUTOMATIONS.ACTION.CRITERIA.WORKING_DAYS"
      )}`;
    }

    if (AUTOMATION_TRANSLATIONS[actionValue]) {
      return t(AUTOMATION_TRANSLATIONS[actionValue]);
    }
    return actionValue;
  };

  const isAutomationEnabled =
    automationObject.enabled && !isAutomationReviewDecisionDisabled;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width={{ xs: "95%", sm: 350 }}
        my={2}
        data-testid="project-automation-card"
        onClick={openAutomationEdit}
        border="1px solid var(--mui-palette-grey-300)"
        overflow="clip"
        borderRadius={1}
        sx={{
          cursor: "pointer",
          "&:hover": { border: "1px solid var(--mui-palette-primary-main)" },
        }}
      >
        <Box
          data-testid="automation-card-header"
          bgcolor="grey.100"
          position="relative"
        >
          <Box
            px={2}
            pt={1}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              translate={automationObject.name}
              fontWeight="fontWeight.semiBold"
              variant="textSm"
            />

            <Box display="flex" alignItems="center">
              {isAutomationReviewDecisionDisabled && (
                <Tooltip
                  title={t("PAUSED_AUTOMATION.CUSTOM_REVIEW_DECISION.TOOLTIP", {
                    reviewDecision: `"${t(
                      AUTOMATION_TRANSLATIONS.REVIEW_DECISION_STATES[
                        automationObject.conditions.reviewDecision
                      ]
                    )}"`,
                  })}
                >
                  <ErrorOutlineOutlined color="error" fontSize="20px" />
                </Tooltip>
              )}

              <Chip
                variant="filled"
                label={
                  isAutomationEnabled
                    ? t("AUTOMATIONS.CREATE.TOGGLE.ACTIVE.TEXT")
                    : t("AUTOMATIONS.CREATE.TOGGLE.PAUSED.TEXT")
                }
                color={isAutomationEnabled ? "primary" : "primaryV2"}
                sx={{
                  height: 20,
                  fontSize: "textXs.fontSize",
                  marginLeft: 0.5,
                }}
              />
            </Box>
          </Box>
          <Box
            pl={1}
            pr={1.5}
            pb={3}
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            ml={0.5}
            mt={0.5}
          >
            {AUTOMATION_CONDITIONS_PROPERTIES[automationObject.triggerId]?.map(
              (key) => (
                <Chip
                  key={key}
                  size="small"
                  label={getConditionLabel(key)}
                  variant="outlined"
                  sx={{
                    fontSize: "textXs.fontSize",
                    border: "1px solid var(--mui-palette-grey-300)",
                    backgroundColor: "white",
                    color: "grey.600",
                  }}
                />
              )
            )}
          </Box>
          <Box position="relative">
            <SvgIcon
              component={ArrowDownwardSharp}
              sx={{
                backgroundColor: "white",
                borderRadius: "50%",
                position: "absolute",
                top: -15,
                left: 13,
                border: "1px solid var(--mui-palette-grey-300)",
                color: "grey.600",
                fontSize: "textXl.fontSize",
                fontWeight: "fontWeight.bold",
                padding: 0.2,
              }}
            />
          </Box>
        </Box>

        <Box
          data-testid="automation-card-body"
          display="flex"
          flexDirection="column"
        >
          {Object.keys(AUTOMATION_CARD_ACTION_KEYS).map((actionKey) => {
            const actionValue = automationObject.actions[actionKey];

            if (actionValue === undefined) {
              return null;
            }

            const badgeLabel = getBadgeLabel(actionKey, actionValue);

            return (
              <Box
                key={actionKey}
                py={1.75}
                px={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                textAlign="left"
              >
                <Text
                  variant="subtitle1"
                  color="grey.800"
                  lineHeight={1.5}
                  translate={AUTOMATION_CARD_ACTION_KEYS[actionKey]}
                  pb={0.5}
                />
                <Chip
                  variant="outlined"
                  size="small"
                  sx={{
                    border: "1px solid var(--mui-palette-grey-300)",
                    backgroundColor: "white",
                    fontSize: "textXs.fontSize",
                  }}
                  label={badgeLabel}
                />
              </Box>
            );
          })}
          {automationObject.actions.changeReviewStatus &&
            automationObject.actions.startReviewStep && (
              <Box position="relative">
                <Chip
                  variant="outlined"
                  sx={{
                    border: "1px solid var(--mui-palette-grey-300)",
                    backgroundColor: "white",
                    width: 24,
                    height: 24,
                    position: "absolute",
                    top: -86,
                    ml: 2,
                    "& .MuiChip-label": {
                      px: 0,
                      fontSize: "textXs.fontSize",
                    },
                  }}
                  label="&"
                />
              </Box>
            )}
          {automationObject.actions.archiveProject && (
            <Box
              py={1.75}
              px={2}
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              textAlign="left"
            >
              <Text
                variant="subtitle1"
                lineHeight={1.5}
                translate="AUTOMATIONS.ACTION.ARCHIVE_PROJECT"
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

AutomationCard.propTypes = {
  automation: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  sections: PropTypes.arrayOf(sectionProps).isRequired,
};

export default AutomationCard;
