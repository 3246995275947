import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RemoveTeamRoleDialog from "@supporting/components/RemoveTeamRoleDialog/RemoveTeamRoleDialog";
import UpdateTeamRoleDialog from "@supporting/components/UpdateTeamRoleDialog/UpdateTeamRoleDialog";

import { Box, Tooltip } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { roleProp, teamProp } from "@shared/props/team";

const useStyles = makeStyles((theme) => ({
  actionIcon: {
    color: theme.color.gray[300],
    height: 16,
    width: 16,
  },
}));

function TeamRoleActions({ team, teamRole, teamRoles, permissions }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const {
    isOpen: isRemoveTeamRoleDialog,
    openDialog,
    closeDialog,
  } = useDialogState();

  const updateTeamRole = useCallback(
    () => {
      setIsOpen(true);
    },
    [team, teamRole, teamRoles, permissions] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {isOpen && (
        <UpdateTeamRoleDialog
          teamId={team._id}
          setIsOpenDialog={setIsOpen}
          teamRoles={teamRoles}
          orderedPermissions={permissions}
          teamRole={teamRole}
        />
      )}
      <RemoveTeamRoleDialog
        cancel={closeDialog}
        teamRole={teamRole}
        isOpen={isRemoveTeamRoleDialog}
        team={team}
      />
      <Box display="flex" data-testid="team-role-table-actions">
        <Tooltip title={t("TEAM.ROLES.EDIT")}>
          <IconButton
            onClick={updateTeamRole}
            data-testid="team-role-table-actions-edit"
            size="large"
          >
            <EditOutlinedIcon className={classes.actionIcon} />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("TEAM.ROLES.DELETE_ROLE")}>
          <IconButton
            onClick={openDialog}
            data-testid="team-role-table-actions-remove"
            size="large"
          >
            <CancelOutlinedIcon className={classes.actionIcon} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}

TeamRoleActions.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamRoles: PropTypes.arrayOf(roleProp).isRequired,
  teamRole: roleProp.isRequired,
  team: teamProp.isRequired,
};

export default TeamRoleActions;
