import { useEffect } from "react";

import { makeStyles } from "@mui/styles";
import authenticationService from "@supporting/services/authentication";

import { Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    background: theme.color.white,
  },
  zapierHeader: {
    paddingTop: 27,
    paddingBottom: 16,
  },
}));

function ZapierEmbed() {
  const classes = useStyles();

  const user = authenticationService.fetchUser();

  /* istanbul ignore next */
  useEffect(() => {
    const interval = setInterval(() => {
      if (window.Intercom && window.Intercom.booted) {
        window.Intercom("update", { hide_default_launcher: true });
        clearInterval(interval);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // Load JS
  const script = document.createElement("script");
  script.type = "module";
  script.src =
    "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
  document.head.appendChild(script);

  // Load CSS
  const stylesheet = document.createElement("link");
  stylesheet.rel = "stylesheet";
  stylesheet.href =
    "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
  document.head.appendChild(stylesheet);

  return (
    <Box className={classes.container} data-testid="zapier-embed-container">
      <zapier-workflow
        sign-up-email={user.email}
        sign-up-first-name={user.fullName}
        client-id="F053sgLBrL7zR2xP1ZG6yS5UMYrUzaLzBSkDAN3E"
        theme="light"
        intro-copy-display="hide"
        manage-zaps-display="show"
        template-style="card"
        template-ids="1800638,1804991,1804996,1805023,1805030,1805035,1805039,1805047"
      />
    </Box>
  );
}

export default ZapierEmbed;
