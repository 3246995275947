import PropTypes from "prop-types";
import { ReactElement, forwardRef } from "react";

import { Tooltip } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { useMediaQuery } from "@shared/hooks";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.color.white,
    color: theme.color.gray[800],
    boxShadow: theme.shadow["box-shadow-z5"],
    fontWeight: theme.typography.fontWeight.regular,
    fontSize: FSTGTypography.fontSize_1_3,
    lineHeight: FSTGTypography.lineHeight_1_5,
    padding: "8px 12px",
    textAlign: "left",
  },
  popper: {
    zIndex: 2001,
  },
}));

/**
 * UIKit Info Tooltip Component
 * @description InfoToolTip component is used to display information in a tooltip. It is different
 * than the UIKit Tooltip component with its style. It extends the Material UI Tooltip component.
 * @param {object} props - Component props
 * @param {ReactElement} props.children [Required] - The content of the tooltip.
 * @param {string} [props.styles] [Optional] - Additional styles of the tooltip.
 * @param {import("@mui/material/Tooltip").TooltipProps} [props.otherProps] [Optional] - Additional props to pass to the underlying MUI Tooltip component.
 * @returns {ReactElement} - React component
 */
const InfoToolTip = forwardRef(({ children, styles, ...otherProps }, ref) => {
  const classes = useStyles(styles);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    otherProps = {
      ...otherProps,
      enterTouchDelay: 50,
      leaveTouchDelay: 30000,
      placement: "top",
    };
  }

  return (
    <Tooltip
      ref={ref}
      classes={{
        tooltip: classes.tooltip,
        popper: classes.popper,
        ...otherProps.classes,
      }}
      placement="right-end"
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
});
InfoToolTip.displayName = "InfoToolTip";

InfoToolTip.propTypes = {
  children: PropTypes.element.isRequired,
  styles: PropTypes.string,
};

InfoToolTip.defaultProps = {
  styles: "",
};

export default InfoToolTip;
