/* eslint-disable complexity */
import { queryClient } from "@shared/react-query";

import { SEARCH_KEYS } from "@shared/query-keys";

const RESOURCE_SEARCH_RESULTS = {
  PROJECT: "projectSearchResults",
  FILE: "fileSearchResults",
  COMMENT: "commentSearchResults",
};

const SECTIONS = [
  "input",
  "recent_searches",
  "filter_chips",
  "project",
  "file",
  "comment",
];

const getNextSection = (state, currentSection, defaultValue, tabSelected) => {
  const sections = SECTIONS.slice(SECTIONS.indexOf(currentSection) + 1);

  const { projectSearchResults, fileSearchResults, commentSearchResults } =
    state;

  for (const section of sections) {
    if (tabSelected === 0 && section === "recent_searches") {
      return ["recent_searches", 0];
    }

    if (section === "filter_chips") {
      return ["filter_chips", 0];
    }

    if (section === "project" && projectSearchResults?.topResults.length > 0) {
      return ["project", 0];
    }

    if (section === "file" && fileSearchResults?.topResults.length > 0) {
      return ["file", 0];
    }

    if (section === "comment" && commentSearchResults?.topResults.length > 0) {
      return ["comment", 0];
    }
  }

  return defaultValue;
};

const nextRecentSearchesMovement = (state) => {
  const data = queryClient.getQueryData(SEARCH_KEYS.recent());
  if (state.selectedSearchResult.index < data.length - 1) {
    return ["recent_searches", state.selectedSearchResult.index + 1];
  }

  return ["recent_searches", data.length - 1];
};

const nextFilterChipsMovement = (state) => {
  if (state.selectedSearchResult.index < 2) {
    return ["filter_chips", state.selectedSearchResult.index + 1];
  }

  if (state.resourceSelected !== "ALL") {
    const hasItems =
      state[RESOURCE_SEARCH_RESULTS[state.resourceSelected]]?.topResults
        .length > 0;

    if (hasItems) {
      return [state.resourceSelected.toLowerCase(), 0];
    }

    return ["filter_chips", 2];
  }

  return getNextSection(state, "filter_chips", ["filter_chips", 2]);
};

const nextProjectMovement = (state) => {
  /* istanbul ignore next */
  if (state.resourceSelected !== "ALL") {
    const data = queryClient.getQueryData(
      SEARCH_KEYS.detail({
        query: state.searchQuery,
        resource: state.resourceSelected,
      })
    );

    const totalItems = data?.pages.reduce((acc, page) => acc + page.length, 0);
    if (state.selectedSearchResult.index < totalItems - 1) {
      return ["project", state.selectedSearchResult.index + 1];
    }

    return ["project", state.selectedSearchResult.index];
  }

  if (
    state.selectedSearchResult.index <
    state.projectSearchResults.topResults.length - 1
  ) {
    return ["project", state.selectedSearchResult.index + 1];
  }

  if (
    state.projectSearchResults.totalCount > 10 &&
    state.selectedSearchResult.index ===
      state.projectSearchResults.topResults.length - 1
  ) {
    return ["project", state.projectSearchResults.topResults.length];
  }

  return getNextSection(state, "project", [
    "project",
    state.selectedSearchResult.index,
  ]);
};

const nextFileMovement = (state) => {
  /* istanbul ignore next */
  if (state.resourceSelected !== "ALL") {
    const data = queryClient.getQueryData(
      SEARCH_KEYS.detail({
        query: state.searchQuery,
        resource: state.resourceSelected,
      })
    );

    const totalItems = data?.pages.reduce((acc, page) => acc + page.length, 0);
    if (state.selectedSearchResult.index < totalItems - 1) {
      return ["file", state.selectedSearchResult.index + 1];
    }

    return ["file", state.selectedSearchResult.index];
  }

  if (
    state.selectedSearchResult.index <
    state.fileSearchResults.topResults.length - 1
  ) {
    return ["file", state.selectedSearchResult.index + 1];
  }

  if (
    state.fileSearchResults.totalCount > 10 &&
    state.selectedSearchResult.index ===
      state.fileSearchResults.topResults.length - 1
  ) {
    return ["file", state.fileSearchResults.topResults.length];
  }

  return getNextSection(state, "file", [
    "file",
    state.selectedSearchResult.index,
  ]);
};

const nextCommentMovement = (state) => {
  /* istanbul ignore next */
  if (state.resourceSelected !== "ALL") {
    const data = queryClient.getQueryData(
      SEARCH_KEYS.detail({
        query: state.searchQuery,
        resource: state.resourceSelected,
      })
    );

    const totalItems = data?.pages.reduce((acc, page) => acc + page.length, 0);
    if (state.selectedSearchResult.index < totalItems - 1) {
      return ["comment", state.selectedSearchResult.index + 1];
    }

    return ["comment", state.selectedSearchResult.index];
  }

  if (
    state.selectedSearchResult.index <
    state.commentSearchResults.topResults.length - 1
  ) {
    return ["comment", state.selectedSearchResult.index + 1];
  }

  if (
    state.commentSearchResults.totalCount > 10 &&
    state.selectedSearchResult.index ===
      state.commentSearchResults.topResults.length - 1
  ) {
    return ["comment", state.commentSearchResults.topResults.length];
  }

  return ["comment", state.selectedSearchResult.index];
};

const getPreviousSection = (state, currentSection) => {
  const sections = SECTIONS.slice(0, SECTIONS.indexOf(currentSection));
  const { projectSearchResults, fileSearchResults } = state;

  for (const section of sections.reverse()) {
    if (section === "filter_chips") {
      return ["filter_chips", 2];
    }

    if (section === "project" && projectSearchResults?.topResults.length > 0) {
      let index = projectSearchResults.topResults.length - 1;
      if (state.projectSearchResults.totalCount > 10) {
        index = projectSearchResults.topResults.length;
      }
      return ["project", index];
    }

    if (section === "file" && fileSearchResults?.topResults.length > 0) {
      let index = fileSearchResults.topResults.length - 1;
      if (state.fileSearchResults.totalCount > 10) {
        index = fileSearchResults.topResults.length;
      }
      return ["file", index];
    }
  }

  return ["input", null];
};

const prevRecentSearchesMovement = (state) => {
  if (state.selectedSearchResult.index > 0) {
    return ["recent_searches", state.selectedSearchResult.index - 1];
  }

  return ["input", null];
};

const prevFilterChipsMovememnt = (state) => {
  if (state.selectedSearchResult.index > 0) {
    return ["filter_chips", state.selectedSearchResult.index - 1];
  }
  return ["input", null];
};

const prevProjectMovement = (state) => {
  if (state.resourceSelected !== "ALL") {
    if (state.selectedSearchResult.index > 0) {
      return ["project", state.selectedSearchResult.index - 1];
    }

    return ["filter_chips", 2];
  }

  if (state.selectedSearchResult.index > 0) {
    return ["project", state.selectedSearchResult.index - 1];
  }

  return getPreviousSection(state, "project");
};

const prevFileMovememnt = (state) => {
  if (state.resourceSelected !== "ALL") {
    if (state.selectedSearchResult.index > 0) {
      return ["file", state.selectedSearchResult.index - 1];
    }

    return ["filter_chips", 2];
  }

  if (state.selectedSearchResult.index > 0) {
    return ["file", state.selectedSearchResult.index - 1];
  }

  return getPreviousSection(state, "file");
};

const prevCommentMovement = (state) => {
  if (state.resourceSelected !== "ALL") {
    if (state.selectedSearchResult.index > 0) {
      return ["comment", state.selectedSearchResult.index - 1];
    }

    return ["filter_chips", 2];
  }

  if (state.selectedSearchResult.index > 0) {
    return ["comment", state.selectedSearchResult.index - 1];
  }

  return getPreviousSection(state, "comment");
};

export const getPreviousMovement = (state) => {
  const { type } = state.selectedSearchResult;
  switch (type) {
    case "input":
      return getPreviousSection(state, "input");
    case "recent_searches":
      return prevRecentSearchesMovement(state);
    case "filter_chips":
      return prevFilterChipsMovememnt(state);
    case "project":
      return prevProjectMovement(state);
    case "file":
      return prevFileMovememnt(state);
    case "comment":
      return prevCommentMovement(state);
  }
};

export const getNextMovement = (state, tabsSelected) => {
  const { type } = state.selectedSearchResult;
  switch (type) {
    case "input":
      return getNextSection(state, "input", ["input", null], tabsSelected);
    case "recent_searches":
      return nextRecentSearchesMovement(state);
    case "filter_chips":
      return nextFilterChipsMovement(state);
    case "project":
      return nextProjectMovement(state);
    case "file":
      return nextFileMovement(state);
    case "comment":
      return nextCommentMovement(state);
  }
};
