import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Alert, AlertTitle, Button, Chip, SvgIcon, Link } from "@mui/material";

import { APP_ROUTES } from "@shared/constants/routes";
import { useMediaQuery } from "@shared/hooks";

import ConnectedIcon from "@assets/img/icons/ic-connected.svg";

const IntegrationOauthEnabledStatus = ({
  integration,
  connected,
  handleInitializeOauth,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (!integration.descriptionPage?.oauthEnabled) {
    return null;
  }
  if (!connected) {
    return (
      <Button
        size="large"
        variant="contained"
        color="primary"
        fullWidth={!isMobile}
        data-testid="integration-connect-button"
        onClick={handleInitializeOauth}
      >
        {t("APPS.CONNECT")}
      </Button>
    );
  }
  if (isMobile) {
    return (
      <Chip
        color="success"
        size="small"
        variant="filled-light"
        icon={
          <SvgIcon
            component={ConnectedIcon}
            inheritViewBox
            fontSize="inherit"
          />
        }
      />
    );
  }

  return (
    <>
      <Alert
        severity="success"
        color="success"
        sx={{ alignSelf: "stretch", padding: 0.5 }}
        icon={
          <SvgIcon
            component={ConnectedIcon}
            inheritViewBox
            fontSize="inherit"
          />
        }
      >
        <AlertTitle>{!isMobile && t("INTEGRATIONS.CONNECTED")}</AlertTitle>
      </Alert>
      {integration.descriptionPage.localizationKeys
        ?.integrationsConnectedState && (
        <Chip
          variant="outlined"
          color="primaryV2"
          sx={{
            height: "auto",
            padding: 1,
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "normal",
              fontSize: "textMd.fontSize",
            },
          }}
          label={
            <Trans
              components={{
                anchor: (
                  <Link
                    component={RouterLink}
                    color="primaryV2"
                    target="_blank"
                    to={APP_ROUTES.DASHBOARD.path}
                    fontWeight="fontWeight.semiBold"
                    textDecoration="underline"
                  />
                ),
              }}
            >
              {
                integration.descriptionPage.localizationKeys
                  .integrationsConnectedState
              }
            </Trans>
          }
        />
      )}
    </>
  );
};

IntegrationOauthEnabledStatus.propTypes = {
  connected: PropTypes.bool,
  integration: PropTypes.object.isRequired,
  handleInitializeOauth: PropTypes.func.isRequired,
};

IntegrationOauthEnabledStatus.defaultProps = {
  connected: false,
};

export default IntegrationOauthEnabledStatus;
