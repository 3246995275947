import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Box, Dialog, Text } from "@shared/UIKit";

export default function IntegrationDisconnectDialog({
  open,
  onClose,
  integration,
}) {
  const { t } = useTranslation();
  const [disconnecting, setDisconnecting] = useState(false);
  const selectedTeam = useSelectedTeam();

  const {
    appService: integrationsService,
    descriptionPage: { localizationKeys },
  } = integration;

  const handleDisconnect = async () => {
    setDisconnecting(true);

    await integrationsService.disconnectApp(selectedTeam._id);

    setDisconnecting(false);
    onClose();
  };

  return (
    <Dialog
      hideBackdrop={false}
      isOpen={open}
      onClose={onClose}
      cancel={onClose}
      title={t("APPS.DIALOG.DISCONNECT.TITLE")}
      actions={
        <>
          <Button variant="text" color="primary" onClick={onClose}>
            {t("APPS.DIALOG.BUTTON.CANCEL")}
          </Button>
          <Button
            variant="text"
            color="error"
            loading={disconnecting}
            onClick={handleDisconnect}
            data-testid="integration-disconnect-button"
          >
            {t("APPS.DIALOG.BUTTON.DISCONNECT_PROJECT")}
          </Button>
        </>
      }
      content={
        <Box pt={1} pb={1.75}>
          <Text
            translate={localizationKeys.integrationsDisconnectDialog}
            variant="textMd"
          />
        </Box>
      }
    />
  );
}

IntegrationDisconnectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  integration: PropTypes.object.isRequired,
};
