import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  headingPointerIcon: {
    "&::before": {
      marginRight: 10,
      content: '"✔"',
      color: theme.color.green[500],
      paddingLeft: 6,
      fontSize: FSTGTypography.fontSize_1_7,
      [theme.breakpoints.down("md")]: {
        marginLeft: -23,
      },
    },
  },
}));

function UpgradeDialogContentDescription({ isAdminView, variant }) {
  const { t } = useTranslation();
  const classes = useStyles();
  let description;

  if (variant === "compareVersions") {
    description = (
      <Trans
        components={{
          anchor: <Link color="accent" target="_blank" />,
        }}
      >
        {t(
          isAdminView
            ? "COMPARE_VERSIONS.UPGRADE_CTA.ADMIN_DESCRIPTION"
            : "COMPARE_VERSIONS.UPGRADE_CTA.NON_ADMIN_DESCRIPTION"
        )}
      </Trans>
    );
  }

  if (variant === "verifyReviewerEmailSetting") {
    description = (
      <Trans
        components={{
          anchor: (
            <Link
              color="accent"
              target="_blank"
              fontWeight={FSTGTypography.fontWeightMedium}
              href={t(
                "TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.SETTINGS_TOGGLE.DESCRIPTION.LINK"
              )}
            />
          ),
        }}
      >
        TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.SETTINGS_TOGGLE.DESCRIPTION.TEXT
      </Trans>
    );
  }

  if (variant === "addSection") {
    description = [
      "UPGRADE.SECTIONS.DESCRIPTION-1",
      "UPGRADE.SECTIONS.DESCRIPTION-2",
    ].map((description) => (
      <Box
        width="max-width"
        display="flex"
        flexDirection="row"
        alignItems="start"
        key={description}
        textAlign="start"
        className={classes.headingPointerIcon}
      >
        {t(description)}
      </Box>
    ));
  }

  if (variant === "interactiveHtml") {
    description = (
      <Trans
        components={{
          anchor: <Link color="accent" target="_blank" />,
        }}
      >
        {t("BILLING.LIMIT_REACHED_DIALOG.INTERACTIVE_HTML.DESCRIPTION")}
      </Trans>
    );
  }

  if (variant.startsWith("copyComments")) {
    description = (
      <Trans
        components={{
          anchor: (
            <Link
              color="accent"
              target="_blank"
              href={t("URLS.FAQ_COPY_COMMENTS")}
            />
          ),
        }}
      >
        {t(
          variant === "copyComments"
            ? "COPY_COMMENTS.UPGRADE_POP_OVER.DESCRIPTION"
            : "GATED.COPY_COMMENT.MODAL.BODY"
        )}
      </Trans>
    );
  }

  if (variant === "customReviewDecisions") {
    description = t("UPGRADE_MODAL.CUSTOM_REVIEW_DECISION.BODY");
  }

  return (
    description && (
      <Text variant="body2" component="span">
        {description}
      </Text>
    )
  );
}

UpgradeDialogContentDescription.propTypes = {
  isAdminView: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default UpgradeDialogContentDescription;
