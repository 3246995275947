import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, MenuItem, TextField, Box } from "@mui/material";

import { Dialog, Text as Typography } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

function RemoveSeatsDialog({ isOpen, cancel, answer, team }) {
  const { t } = useTranslation();

  const [seatCount, setSeatCount] = useState("");

  const availableSeats =
    team.subscription.limits.includedUsers -
    Math.max(
      team.subscription.basePrice.limits.includedUsers,
      team.members.length
    );

  const confirmDialog = () => {
    answer(seatCount);
    setSeatCount("");
  };

  const cancelDialog = () => {
    setSeatCount("");
    cancel();
  };

  const handleSeatCountChange = (event) => {
    setSeatCount(event.target.value);
  };

  return (
    <Dialog
      isOpen={isOpen}
      title={t("SEATS.REMOVE.HEADLINE")}
      cancel={cancelDialog}
      actions={
        <>
          <Button
            onClick={cancelDialog}
            variant="text"
            color="primaryV2"
            data-testid="remove-seats-cancel"
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            disabled={seatCount < 1}
            onClick={confirmDialog}
            color="primary"
            variant="contained"
            data-testid="remove-seats-submit"
          >
            {t("SEATS.REMOVE.CTA.CONFIRM")}
          </Button>
        </>
      }
      hideBackdrop={false}
      disableBackdropClick
    >
      <Box data-testid="remove-seats-dialog">
        <Typography color="text.secondary" variant="subtitle1">
          {t("SEATS.REMOVE.DESCRIPTION")}
        </Typography>
        <Box mt={4} display="flex" justifyContent="center">
          <TextField
            select
            fullWidth
            variant="outlined"
            color="success"
            disabled={availableSeats === 0}
            label={t("SEATS.ADD.INPUT-LABEL")}
            name={t("SEATS.ADD.INPUT-LABEL")}
            value={seatCount}
            onChange={handleSeatCountChange}
            inputProps={{ "data-testid": "seat-selector" }}
            size="small"
            helperText={t("SEATS.REMOVE.SEAT-LIMIT", {
              usedSeatCount: team.members.length,
              totalSeatCount: team.subscription.limits.includedUsers,
            })}
          >
            {Array.from({ length: availableSeats }, (_, num) => (
              <MenuItem
                key={num}
                value={num + 1}
                data-testid={`seat-count-${num}`}
              >
                <Typography>{num + 1}</Typography>
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
    </Dialog>
  );
}

RemoveSeatsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  team: teamProp.isRequired,
};

export default RemoveSeatsDialog;
