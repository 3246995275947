import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import reviewService from "@feedback/services/reviewService";
import { LoadingButton as Button } from "@mui/lab";

import { instance as analytics } from "@shared/services/analytics";

const ReviewDecisionNotificationPopupButton = ({
  reviewIds,
  hidePopup,
  transKeyPrefix,
}) => {
  const { t } = useTranslation();

  const [countDown, setCounter] = useState(10);
  const [undoDecisionsRequestInProgress, setUndoDecisionsRequestInProgress] =
    useState(false);

  const submitUndoReviewDecisions = useCallback(async () => {
    setUndoDecisionsRequestInProgress(true);

    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.UNDO_REVIEW_DECISION
    );

    await reviewService.undoReviewDecisions(reviewIds);
    hidePopup();
  }, [hidePopup, reviewIds]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCount) => prevCount - 1);
    }, 1100);
    const timeoutId = setTimeout(() => {
      hidePopup();
    }, 11000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeoutId);
    };
  }, [hidePopup]);

  return (
    <Button
      variant="contained"
      color="default-light"
      size="small"
      data-testid="review-decision-notification-undo-button"
      loading={undoDecisionsRequestInProgress}
      onClick={submitUndoReviewDecisions}
      disabled={undoDecisionsRequestInProgress}
    >
      {`${t(`${transKeyPrefix}.BUTTON`)} (${countDown})`}
    </Button>
  );
};

ReviewDecisionNotificationPopupButton.propTypes = {
  reviewIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  hidePopup: PropTypes.func.isRequired,
  transKeyPrefix: PropTypes.string.isRequired,
};

export default ReviewDecisionNotificationPopupButton;
