import PropTypes from "prop-types";
import { createContext, useContext, useState } from "react";

const MIN_WIDTH = 360;
const MAX_WIDTH = 650;

const FileCardResizerContext = createContext();

export const useFileCardResized = () => {
  const context = useContext(FileCardResizerContext);
  if (!context) {
    throw new Error(
      `useFileCardResized must be used within a FileCardResizerProvider`
    );
  }
  return context;
};

export const FileCardResizerProvider = ({ children }) => {
  const [width, setWidth] = useState(MIN_WIDTH);

  const handleResize = (positionLeft) => {
    const delta = positionLeft - width;
    const newWidth = width + delta;
    window.requestAnimationFrame(() => {
      setWidth(newWidth);
    });
  };

  const value = {
    width,
    minWidth: MIN_WIDTH,
    maxWidth: MAX_WIDTH,
    handleResize,
  };

  return (
    <FileCardResizerContext.Provider value={value}>
      {children}
    </FileCardResizerContext.Provider>
  );
};

FileCardResizerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
