import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import remove from "lodash/remove";

import { Dialog, Image } from "@shared/UIKit";

import loadScript from "@shared/helpers/loadScript";

function WistiaDialog({ cancel, isOpen, videoId }) {
  const [responsiveTopPadding, setResponsiveTopPadding] = useState("56.25%"); // 16:9

  const fetchVideoData = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`;

  useEffect(() => {
    async function loadAllScripts() {
      await Promise.all([
        loadScript("https://fast.wistia.com/assets/external/E-v1.js"),
        loadScript(fetchVideoData),
      ]);

      /* istanbul ignore next */
      window._wq = window._wq || [];

      /* istanbul ignore next */
      remove(window._wq, { id: videoId });

      /* istanbul ignore next */
      window._wq.push({
        id: videoId,
        onHasData(video) {
          setResponsiveTopPadding(`${(1 / video.aspect()) * 100}%`);
        },
      });
    }

    loadAllScripts();
  });

  return (
    <Dialog
      isOpen={isOpen}
      hideBackdrop={false}
      maxWidth="lg"
      fullWidth
      cancel={cancel}
      useDefaultBackground={false}
    >
      <Box
        className="wistia_responsive_padding"
        position="relative"
        padding={`${responsiveTopPadding} 0 0 0`}
      >
        <Box
          className="wistia_responsive_wrapper"
          height="100%"
          left={0}
          position="absolute"
          top={0}
          width="100%"
        >
          <Box
            className={`wistia_embed wistia_async_${videoId} videoFoam=true`}
            height="100%"
            position="relative"
            width="100%"
          >
            <Box
              className="wistia_swatch"
              height="100%"
              left={0}
              overflow="hidden"
              position="absolute"
              top={0}
              width="100%"
              sx={{ opacity: 0, transition: "opacity 200ms" }}
            >
              <Image
                src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
                alt="Video"
                aria-hidden="true"
                sx={{
                  filter: "blur(5px)",
                  height: "100%",
                  objectFit: "contain",
                  width: "100%;",
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

WistiaDialog.propTypes = {
  videoId: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default WistiaDialog;
