import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Check } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import { instance as teamService } from "@supporting/services/team";
import noop from "lodash/noop";

import { Dialog, Text, Image } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

import CustomRoleImage from "@assets/img/images/custom-roles.png";
import StorageImage from "@assets/img/images/project-storage-limit.png";
import ReviewStepImage from "@assets/img/images/review-steps.png";

const getTranslations = (whileUnarchiving) => ({
  project: {
    heading: whileUnarchiving
      ? "BILLING.LIMIT_REACHED_DIALOG.PROJECT.HEADING_WHILE_UNARCHIVING"
      : "BILLING.LIMIT_REACHED_DIALOG.PROJECT.HEADING",
    illustrationImage: StorageImage,
    upgradeBenefits: [
      "BILLING.LIMIT_REACHED_DIALOG.PROJECT.BENEFIT1",
      "BILLING.LIMIT_REACHED_DIALOG.PROJECT.BENEFIT2",
    ],
  },
  customRole: {
    heading: "BILLING.LIMIT_REACHED_DIALOG.CUSTOM_ROLE.HEADING",
    illustrationImage: CustomRoleImage,
    upgradeBenefits: [
      "BILLING.LIMIT_REACHED_DIALOG.CUSTOM_ROLE.BENEFIT1",
      "BILLING.LIMIT_REACHED_DIALOG.CUSTOM_ROLE.BENEFIT2",
    ],
  },
  reviewStep: {
    heading: "BILLING.LIMIT_REACHED_DIALOG.REVIEW_STEP.HEADING",
    illustrationImage: ReviewStepImage,
    upgradeBenefits: [
      "BILLING.LIMIT_REACHED_DIALOG.REVIEW_STEP.BENEFIT1",
      "BILLING.LIMIT_REACHED_DIALOG.REVIEW_STEP.BENEFIT2",
      "BILLING.LIMIT_REACHED_DIALOG.REVIEW_STEP.BENEFIT3",
    ],
  },
  storage: {
    heading: "BILLING.LIMIT_REACHED_DIALOG.STORAGE.HEADING",
    illustrationImage: StorageImage,
    upgradeBenefits: [
      "BILLING.LIMIT_REACHED_DIALOG.STORAGE.BENEFIT1",
      "BILLING.LIMIT_REACHED_DIALOG.STORAGE.BENEFIT2",
    ],
  },
});

function LimitReachedDialog({
  cancel,
  concernedTeam,
  whileUnarchiving,
  selectedLimit,
  onOpenSelectPlanDialog,
}) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);
  const BILLING_LIMITS_TRANSLATIONS = getTranslations(whileUnarchiving);

  const currentTeam = concernedTeam || teamService.getSelectedTeam();

  const cancelDialog = useCallback(() => {
    setIsOpen(false);
    cancel();
  }, [cancel, setIsOpen]);

  const openSubscriptionDialog = () => {
    setIsOpen(false);
    onOpenSelectPlanDialog();
    NiceModal.show(SelectPlanDialog, {
      cancel: cancelDialog,
      teamId: currentTeam._id,
    });
  };

  return (
    <Dialog
      cancel={cancelDialog}
      open={isOpen}
      maxWidth="lg"
      data-testid="billing-limit-reached-dialog"
      title={t(BILLING_LIMITS_TRANSLATIONS[selectedLimit].heading)}
      content={
        <Box mx={6}>
          <Box mb={2.5}>
            <Image
              src={BILLING_LIMITS_TRANSLATIONS[selectedLimit].illustrationImage}
              alt={t(BILLING_LIMITS_TRANSLATIONS[selectedLimit].heading)}
            />
          </Box>
          <Box mr={2.5}>
            <List>
              {BILLING_LIMITS_TRANSLATIONS[selectedLimit].upgradeBenefits.map(
                (benefit) => (
                  <ListItem key={benefit} sx={{ marginTop: 0, marginRight: 0 }}>
                    <ListItemIcon>
                      <Check color="primary" sx={{ marginRight: 5 }} />
                    </ListItemIcon>
                    <ListItemText>
                      <Text>{t(benefit)}</Text>
                    </ListItemText>
                  </ListItem>
                )
              )}
            </List>

            {currentTeam.permissions.canManageBilling && (
              <Box bgcolor="grey.100" p={1.875}>
                <Text
                  fontWeight="fontWeight.semiBold"
                  color="grey.800"
                  translate="BILLING.RESTRICTED_USER"
                />
              </Box>
            )}
          </Box>
        </Box>
      }
      actions={
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={openSubscriptionDialog}
        >
          {t("BILLING.CHOOSE_PLAN")}
        </Button>
      }
      hideBackdrop={false}
    />
  );
}

LimitReachedDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  concernedTeam: teamProp,
  whileUnarchiving: PropTypes.bool,
  onOpenSelectPlanDialog: PropTypes.func,
  selectedLimit: PropTypes.string.isRequired,
};

LimitReachedDialog.defaultProps = {
  concernedTeam: null,
  whileUnarchiving: false,
  onOpenSelectPlanDialog: noop,
};

export default LimitReachedDialog;
