/* eslint react/forbid-elements:0 */
import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import CompareIcon from "@mui/icons-material/Compare";
import { Badge, IconButton, SvgIcon } from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import UpgradeDialogContent from "@supporting/components/UpgradePlanDialog/UpgradeDialogContent";
import UpgradePlanDialog from "@supporting/components/UpgradePlanDialog/UpgradePlanDialog";

import { Box, Tooltip } from "@shared/UIKit";

import MEDIA_TYPES from "@shared/constants/mediaTypes";
import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation, useCurrentRouteName } from "@shared/hooks";
import { reviewProp } from "@shared/props/review";
import { instance as analytics } from "@shared/services/analytics";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

const comparableMediaTypes = [
  MEDIA_TYPES.DOCUMENT,
  MEDIA_TYPES.DOCUMENT_XOD,
  MEDIA_TYPES.IMAGE,
  MEDIA_TYPES.IMAGE_XOD,
  MEDIA_TYPES.INTERACTIVE_HTML,
  MEDIA_TYPES.VIDEO,
];

const tooltipMessages = [
  "AUTOCOMPARE.DISABLED.WEBSITE_AUDIO.TOOLTIP",
  "COMPARE_VERSIONS.COMPARE_BUTTON.DISABLED_MESSAGE",
];

function CompareReviewsButton({
  reviews,
  doesPlanIncludeFeature,
  isAdminView,
}) {
  const { t } = useTranslation();

  const { goTo } = useNavigation();
  const currentRouteName = useCurrentRouteName();
  const params = useParams();

  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);

  const comparableReviews = reviews.filter(({ mediaType }) =>
    comparableMediaTypes.includes(mediaType)
  );
  const comparableReviewsCount = comparableReviews.length;

  const isCompareReviewsButtonDisabled =
    doesPlanIncludeFeature && comparableReviewsCount < 2;

  useEffect(() => {
    if (!isCompareReviewsButtonDisabled) {
      analytics.track(
        analytics.ACTION.UNLOCKED,
        analytics.CATEGORY.COMPARE_REVIEWS.COMPARE_REVIEWS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function goToCompareReviewsView() {
    if (!doesPlanIncludeFeature) {
      setIsUpgradePlanDialogOpen(true);
      analytics.track(
        analytics.ACTION.REACHED,
        analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        {},
        {},
        analytics.STATE.COMPARE_REVIEWS_FEATURE
      );
      return;
    }

    const [rightReview, leftReview] = comparableReviews;

    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.COMPARE_REVIEWS.COMPARE_REVIEWS,
      {
        fileType: rightReview.mediaType,
        fileExtension: rightReview.original.extension,
      }
    );
    goTo(ROUTE_STATES.COMPARE_REVIEWS, {
      search: new URLSearchParams({
        leftReviewId: leftReview.id,
        rightReviewId: rightReview.id,
      }).toString(),
      options: {
        state: {
          from: {
            name: currentRouteName,
            params,
          },
        },
      },
    });
  }

  const onCancel = () => setIsUpgradePlanDialogOpen(false);
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, {
      hideFree: true,
      teamId: reviews[0].teamId,
    });
  const tooltipMessage =
    tooltipMessages[comparableReviewsCount] ?? "CORE.COMPARE_VERSIONS";

  return (
    <>
      <Box display="flex" alignItems="center">
        <Tooltip title={t(tooltipMessage)}>
          <div data-testid="compare-reviews-upgrade-container">
            <Badge
              badgeContent={
                <SvgIcon
                  component={PremiumIcon}
                  color="upgrade"
                  data-testid="compare-reviews-upgrade"
                  fontSize="extraSmall"
                />
              }
              invisible={doesPlanIncludeFeature}
              overlap="circular"
            >
              <IconButton
                className="tour_compare-reviews-button"
                disabled={isCompareReviewsButtonDisabled}
                onClick={goToCompareReviewsView}
                data-testid="compare-reviews-button"
                size="small"
              >
                <CompareIcon fontSize="small" />
              </IconButton>
            </Badge>
          </div>
        </Tooltip>
      </Box>
      <UpgradePlanDialog
        dialogTitle="COMPARE_VERSIONS.UPGRADE_CTA.HEADING"
        isUpgradePlanDialogOpen={isUpgradePlanDialogOpen}
        onCancel={onCancel}
      >
        <UpgradeDialogContent
          isAdminView={isAdminView}
          variant="compareVersions"
          onCancel={onCancel}
          openBillingDialog={openDialog}
          teamId={reviews[0].teamId}
        />
      </UpgradePlanDialog>
    </>
  );
}

CompareReviewsButton.propTypes = {
  doesPlanIncludeFeature: PropTypes.bool.isRequired,
  reviews: PropTypes.arrayOf(reviewProp).isRequired,
  isAdminView: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

CompareReviewsButton.defaultProps = {
  className: "",
};

export default CompareReviewsButton;
