import propTypes from "prop-types";
import { useMemo } from "react";

import { Paper, Popper } from "@mui/material";

import { Box } from "@shared/UIKit";

import GoogleDriveSettingsPopper from "./GoogleDrive/GoogleDriveSettingsPopper";
import SlackAppSettingsPopper from "./Slack/SlackAppSettingsPopper";

function IntegrationsSettingsPoppers({
  buttonAnchorEl,
  connected,
  handleCloseDialog,
  integrationType,
}) {
  const showSettingsModal = Boolean(buttonAnchorEl);

  const SettingsPopper = useMemo(() => {
    switch (integrationType) {
      case "slack":
        return SlackAppSettingsPopper;
      case "google-drive":
        return GoogleDriveSettingsPopper;
    }
  }, [integrationType]);

  return (
    showSettingsModal && (
      <Popper
        open={showSettingsModal}
        anchorEl={buttonAnchorEl}
        data-testid="integrations-settings-popper"
      >
        <Paper sx={{ px: 3, py: 2 }}>
          <Box display="flex" flexDirection="column" gap={4}>
            <SettingsPopper onClose={handleCloseDialog} connected={connected} />
          </Box>
        </Paper>
      </Popper>
    )
  );
}

IntegrationsSettingsPoppers.propTypes = {
  handleCloseDialog: propTypes.func.isRequired,
  buttonAnchorEl: propTypes.object,
  connected: propTypes.bool.isRequired,
  integrationType: propTypes.string.isRequired,
};

IntegrationsSettingsPoppers.defaultProps = {
  buttonAnchorEl: null,
};

export default IntegrationsSettingsPoppers;
