import PropTypes from "prop-types";
import { ReactElement } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Tooltip, Text } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  text: {
    overflow: "hidden",
    border: "1px solid transparent",
    padding: "2px 5px 1.5px 3px",
    borderRadius: 4,
  },
  outlinedText: {
    width: "auto",
    "&:hover": {
      width: "auto",
      outline: `1px ${theme.color.gray[300]} dashed`,
    },
  },
  disabledText: {
    "&:hover": {
      borderColor: "transparent",
    },
  },
}));

/**
 * UIKit Editable Label Text Component.
 * @param {object} props Component props
 * @param {string} props.text The text of the label.
 * @param {Function} props.handleClick Callback function that is called before saving the text.
 * @param {boolean?} props.isEditing If true, the component will be in edit mode and render the text field.
 * @param {string?} props.className Additional class name for styling.
 * @param {boolean?} props.disabled If true, the button will be disabled
 * @param {string?} props.textVariant The text variant.
 * @param {{
 * title: string,
 * placement: string,
 * text: string,
 * }} props.tooltipProps Additional props for the tooltip component.
 * @param {string?} props.tooltipContainerDisabledClassName The tooltip container disabled class name.
 * @returns {ReactElement} - React component
 */
function EditableLabelText({
  text,
  handleClick,
  isEditing,
  className,
  disabled,
  textVariant,
  tooltipProps,
  tooltipContainerDisabledClassName,
}) {
  const classes = useStyles();

  return (
    <Tooltip
      title={tooltipProps.title}
      description={tooltipProps.text}
      className={classnames({
        [tooltipContainerDisabledClassName]:
          disabled && tooltipContainerDisabledClassName,
      })}
      placement={tooltipProps.placement}
    >
      <Text
        variant={textVariant}
        cursor={disabled ? "default" : "text"}
        onClick={handleClick}
        data-testid="editable-label-text"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        className={classnames(classes.text, className, {
          [classes.outlinedText]: !isEditing && !disabled,
          [classes.disabledText]: disabled,
        })}
      >
        {text}
      </Text>
    </Tooltip>
  );
}

EditableLabelText.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  textProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  disabled: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  textVariant: PropTypes.string,
  tooltipProps: PropTypes.shape({
    title: PropTypes.string,
    placement: PropTypes.string,
    text: PropTypes.string,
  }),
  tooltipContainerDisabledClassName: PropTypes.string,
};

EditableLabelText.defaultProps = {
  textProps: {},
  isEditing: false,
  className: "",
  textVariant: null,
  tooltipProps: {
    title: "",
    placement: "top",
    text: "",
  },
  tooltipContainerDisabledClassName: "",
};

export default EditableLabelText;
