import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, FormControlLabel, Switch } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import { Box, Text } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles(() => ({
  label: {
    marginLeft: 0,
    marginRight: 0,
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
  },
  switchRoot: {
    marginRight: -10,
  },
}));

function ReviewRequestDecisionSwitch({
  reviewDecisionRequested,
  step,
  reviewer,
  isProjectTemplate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isChecked, setIsChecked] = useState(reviewDecisionRequested);
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const handleToggleChange = useCallback(
    async (event) => {
      const {
        target: { checked },
      } = event;
      setIsLoading(true);
      try {
        setIsChecked(checked);
        if (isProjectTemplate) {
          await projectTemplateService.changeReviewerDecisionRequest(
            step.id,
            reviewer._id,
            checked
          );
        } else {
          await stepService.changeReviewerDecisionRequest(
            step.id,
            reviewer._id,
            checked
          );
        }
      } catch (error) {
        setIsChecked(!checked);
        errorHandlerService.handleError(error);
      } finally {
        setIsLoading(false);
      }

      analytics.track(
        checked ? analytics.ACTION.ENABLED : analytics.ACTION.DISABLED,
        analytics.CATEGORY.REVIEWER_MENU_REQUEST_REVIEWER_DECISION,
        {
          page: isProjectTemplate
            ? analytics.CATEGORY.PROJECT_TEMPLATE
            : analytics.CATEGORY.PROJECT,
        }
      );
    },
    [step, reviewer] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      mx={2.1}
      py={0.5}
      borderBottom={`1px solid ${theme.color.gray[200]}`}
    >
      <FormControlLabel
        control={
          <>
            <Switch
              disabled={isLoading}
              data-testid="step-reviewer-menu-request-review-decision-switch"
              checked={isChecked}
              color="primary"
              onChange={handleToggleChange}
              classes={{
                root: classes.switchRoot,
              }}
            />
            {isLoading && (
              <CircularProgress
                size={20}
                sx={{
                  position: "absolute",
                  right: 24,
                  zIndex: 1,
                  color: theme.color.gray[700],
                }}
              />
            )}
          </>
        }
        label={
          <Text
            variant="body2"
            fontWeight={FSTGTypography.fontWeightMedium}
            color={theme.color.gray[800]}
          >
            {t("REVIEWER.MENU.REQUEST_REVIEW_DECISION")}
          </Text>
        }
        labelPlacement="start"
        classes={{
          root: classes.label,
        }}
      />
    </Box>
  );
}

ReviewRequestDecisionSwitch.propTypes = {
  reviewDecisionRequested: PropTypes.bool,
  step: stepProps.isRequired,
  reviewer: userProp.isRequired,
  isProjectTemplate: PropTypes.bool,
};

ReviewRequestDecisionSwitch.defaultProps = {
  reviewDecisionRequested: false,
  isProjectTemplate: false,
};

export default ReviewRequestDecisionSwitch;
