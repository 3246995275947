import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Dialog, Text } from "@shared/UIKit";

import { useQueryParams } from "@shared/hooks/useQueryParams";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

const DisabledPlanDialog = ({ cancel, isOpen, hasSubscription }) => {
  const [, setSearchParams] = useQueryParams();
  const { t } = useTranslation();

  const cancelDialog = useCallback(() => {
    cancel();
    setSearchParams({ action: null }, true);
    healthMetrics.trackCancellation("supporting.billing");
  }, [cancel, setSearchParams]);

  return (
    <Dialog
      cancel={cancelDialog}
      open={isOpen}
      content={
        <Text
          translate={
            hasSubscription
              ? "MANUAL_BILLING.MODAL.BODY"
              : "BILLING.CONTACT_ADMIN"
          }
        />
      }
      title={t("MANUAL_BILLING.MODAL.TITLE")}
      actions={
        <Button
          variant="contained"
          sx={{ flex: 1 }}
          onClick={() => {
            window.Intercom?.("show");
            cancelDialog();
          }}
        >
          {t("MANUAL_BILLING.MODAL.CHAT_CTA")}
        </Button>
      }
      useDefaultBackground={false}
      hideBackdrop={false}
      sx={{
        ".MuiDialogContent-root": {
          padding: "8px 0px",
        },
      }}
      actionProps={{
        sx: {
          marginTop: 2,
          paddingInline: 0,
        },
      }}
      PaperProps={{
        sx: {
          borderRadius: 3,
          minHeight: "unset",
        },
      }}
    />
  );
};

DisabledPlanDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
};

export default DisabledPlanDialog;
