import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InfoOutlined } from "@mui/icons-material";
import {
  FormControlLabel,
  Radio,
  IconButton,
  MenuItem,
  TextField,
  SvgIcon,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import RoleTooltip from "@supporting/components/TeamRolePicker/RoleTooltip";
import { TEAM_ROLES } from "@supporting/constants/team";
import teamRoleService from "@supporting/services/teamRoleService";
import orderBy from "lodash/orderBy";

import { Text, InfoToolTip, Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

const useStyles = makeStyles((theme) => ({
  teamRole: {
    padding: "8px 16px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
    flexGrow: 1,

    "&:hover": {
      backgroundColor: theme.color.gray[50],
    },

    "&:last-child": {
      borderBottom: "none",
    },

    "&:active,&:focus": {
      outline: "none",
    },
  },
  disabledRoleUpgradeMessage: {
    "pointer-events": "all",
    cursor: "pointer",
  },
  menuPaper: {
    width: 256,
    borderRadius: 8,
    minWidth: "256px !important",
  },
  menuItem: {
    "white-space": "normal",
    padding: 8,
  },
  upsellIcon: {
    margin: 0,
    padding: 4,
    color: theme.color.blue[500],
    fontSize: FSTGTypography.fontSize_2_0,
  },
}));

function TeamRolePicker({ team, onTeamRoleSelected, defaultSelected }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const [selectedTeamRoleId, setSelectedTeamRoleId] = useState(defaultSelected);
  const [teamRoles, setTeamRoles] = useState([]);

  const onOpenSelectPlanDialog = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.MEMBER_ROLE,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_BUTTON,
    });
    NiceModal.show(SelectPlanDialog, { teamId: team._id });
  };

  useEffect(() => {
    async function fetchRoles() {
      const teamRoles = await teamRoleService.fetchTeamRoles(team._id);
      setTeamRoles(teamRoles);
      if (!selectedTeamRoleId) {
        const preSelectedTeamRole = teamRoles.find(
          ({ roleName }) => roleName === TEAM_ROLES.FULL_MEMBER
        );

        setSelectedTeamRoleId(preSelectedTeamRole._id);
        onTeamRoleSelected(preSelectedTeamRole._id);
      }
    }

    fetchRoles();
  }, [onTeamRoleSelected, selectedTeamRoleId, team._id]);

  const isRoleDisabled = function (role) {
    return (
      role.roleName === TEAM_ROLES.LITE_MEMBER &&
      !team.subscriptionLimits.isMemberRoleEnabled
    );
  };

  const getRoleTranslation = (role) => {
    if (!role) {
      return "";
    }
    return role.isDefault ? t(`TEAM.ROLES.${role.roleName}`) : role.roleName;
  };

  const handleRoleIdChange = (event) => {
    const selectedRole = teamRoles.find(
      (role) => role._id === event.target.value
    );
    if (!isRoleDisabled(selectedRole)) {
      setSelectedTeamRoleId(selectedRole._id);
      onTeamRoleSelected(selectedRole._id);
    }
  };

  const RoleMenuContent = orderBy(
    teamRoles,
    ["isDefault", "roleName"],
    ["desc", "asc"]
  ).map((role) => {
    return (
      <MenuItem
        value={role._id}
        key={role._id}
        disableGutters
        classes={{ root: classes.menuItem }}
        disabled={isRoleDisabled(role)}
        data-testid={`role-${role.roleName}-menuItem`}
        sx={{ "&.Mui-disabled": { opacity: 1 } }}
      >
        <Box
          bgcolor={isRoleDisabled(role) && "rgba(151, 151, 151, 0.1)"}
          className={classes.teamRole}
          data-testid={`role-${role.roleName}`}
        >
          <Box display="flex" width="100%" justifyContent="space-between">
            <Box
              mb={-0.625}
              minWidth={160}
              textAlign="left"
              color={isRoleDisabled(role) ? theme.color.gray[600] : undefined}
            >
              <FormControlLabel
                value={role._id}
                checked={role._id === selectedTeamRoleId}
                control={<Radio color="primary" />}
                label={
                  <Box display="flex" alignItems="center">
                    <Text
                      variant="body2"
                      fontWeight={theme.typography.fontWeight.semiBold}
                      translate={
                        role.isDefault
                          ? `TEAM.ROLES.${role.roleName}`
                          : role.roleName
                      }
                    />
                    {isRoleDisabled(role) && (
                      <SvgIcon
                        component={PremiumIcon}
                        className={classes.upsellIcon}
                        data-testid="add-new-section-upgrade"
                      />
                    )}
                  </Box>
                }
                disabled={isRoleDisabled(role)}
                data-testid={`role-${role.roleName}-radio`}
              />
            </Box>

            <InfoToolTip placement="right" title={<RoleTooltip role={role} />}>
              <IconButton size="small" disableRipple>
                <InfoOutlined fontSize="small" />
              </IconButton>
            </InfoToolTip>
          </Box>
          <Box
            flex="auto"
            pl={3}
            lineHeight={FSTGTypography.lineHeight_1_2}
            color={theme.color.gray[500]}
            textAlign="left"
          >
            <Box>
              {role?.isDefault && (
                <Text
                  variant="body2"
                  translate={`TEAM.ROLES.ROLE_DESCRIPTION.${role.roleName}`}
                />
              )}
              {isRoleDisabled(role) && (
                <Box
                  color={theme.color.blue[500]}
                  lineHeight={FSTGTypography.fontSize_1_7}
                  fontWeight={FSTGTypography.fontWeightBold}
                  mt={0.75}
                  className={classes.disabledRoleUpgradeMessage}
                >
                  {team.permissions.canManageBilling && (
                    <Text
                      aria-hidden="true"
                      onClick={onOpenSelectPlanDialog}
                      data-testid="open-dialog-select-plan"
                      variant="body2"
                      translate="CORE.SEE_PLANS"
                    />
                  )}
                  {!team.permissions.canManageBilling && (
                    <Text
                      data-testid="restricted-warning"
                      translate="TEAM.ROLES.USER_RESTRICTED"
                    />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </MenuItem>
    );
  });

  return (
    <TextField
      size="medium"
      id="team-role-picker"
      select
      label={t("TEAM.ADD_MEMBER.ROLE")}
      value={selectedTeamRoleId}
      onChange={handleRoleIdChange}
      fullWidth
      SelectProps={{
        renderValue: (value) => (
          <>
            {getRoleTranslation(teamRoles.find((role) => role._id === value))}
          </>
        ),
        MenuProps: {
          anchorOrigin: { vertical: 40, horizontal: "right" },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
          classes: { paper: classes.menuPaper },
        },
      }}
      data-testid="team-role-picker"
    >
      {RoleMenuContent}
    </TextField>
  );
}

TeamRolePicker.propTypes = {
  team: teamProp.isRequired,
  onTeamRoleSelected: PropTypes.func.isRequired,
  defaultSelected: PropTypes.string,
};
TeamRolePicker.defaultProps = {
  defaultSelected: "",
};

export default TeamRolePicker;
