import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import { projectProps } from "@shared/props/project";
import { reviewProp } from "@shared/props/review";

import ReviewStatusBadge from "./ReviewStatusBadge/ReviewStatusBadge";
import ReviewStatusMenu from "./ReviewStatusMenu/ReviewStatusMenu";
import ReviewStatusMenuButton from "./ReviewStatusMenuButton/ReviewStatusMenuButton";

function ReviewStatus({
  project,
  review,
  isReadOnly,
  isFileViewPage,
  ...restProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [reviewState, setReviewState] = useState(review.status.state);

  useEffect(() => {
    setReviewState(review.status.state);
  }, [review]);

  const openMenu = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  return isReadOnly || project.isArchived || !review.permissions.canSetState ? (
    <ReviewStatusBadge reviewState={reviewState} isReadOnly {...restProps} />
  ) : (
    <>
      <ReviewStatusMenuButton
        openMenu={openMenu}
        reviewState={reviewState}
        isReadOnly={isReadOnly}
        {...restProps}
      />

      {anchorEl && (
        <ReviewStatusMenu
          review={review}
          setReviewState={setReviewState}
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          reviewState={reviewState}
          isFileViewPage={isFileViewPage}
        />
      )}
    </>
  );
}

ReviewStatus.propTypes = {
  review: reviewProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  isReadOnly: PropTypes.bool,
  isFileViewPage: PropTypes.bool,
};

ReviewStatus.defaultProps = {
  isReadOnly: false,
  isFileViewPage: false,
};

export default ReviewStatus;
