/**
 * Find right helper function
 *
 * @description This is a function that finds the element by running
 * predicate function starting from the end.
 * @typedef ArrayElement
 * @param {Array} array - [Required] Array to find the element in.
 * @param {Function} predicate - [Required] Predicate function to
 * match the element.
 * @returns {ArrayElement} Returns the found element or undefined.
 */
function findRight(array, predicate) {
  for (let index = array.length - 1; index >= 0; index--) {
    const item = array[index];
    if (predicate(item, index)) {
      return item;
    }
  }

  return null;
}

export default findRight;
