import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, Link as ReactLink } from "react-router-dom";

import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useSelectedFiles } from "@workflow/components/SelectFilesContext";
import maxBy from "lodash/maxBy";

import { Box, MimeTypeIcon, Image, Tooltip, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import useSessionStorage from "@shared/hooks/useSessionStorage";
import { fileProp, versionProp } from "@shared/props/file";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  thumbnail: {
    "& > img": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
    "& > svg": {
      color: theme.color.gray[50],
    },
    "& > $thumbnailOverlay": {
      display: "none",
    },
    "&:hover > $thumbnailOverlay": {
      display: "flex",
    },
    "& > $selectionOverlay": {
      display: "none",
    },
    "&:hover > $selectionOverlay": {
      display: "flex",
    },
  },
  thumbnailOverlay: {},
  thumbnailLink: {
    flexGrow: 1,
  },
  thumbnailLinkText: {
    lineHeight: "68px",
  },
  selectionOverlay: {
    backgroundColor: theme.color["black-translucent-60"],
    width: 100,
    height: "100%",
    display: "flex",
    cursor: "pointer",
    position: "absolute",
    "& > p": {
      margin: "auto",
    },
  },
}));

function FileCardThumbnail({ file, lastVersion, lastReviewedVersion }) {
  const [, setSelectedFileId] = useSessionStorage("selectedFileId");
  const { isSelectionState, isFileSelected, toggleSelectFile } =
    useSelectedFiles();
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const lastReviewUrl = useMemo(() => {
    if (lastReviewedVersion) {
      const latestReview = maxBy(
        lastReviewedVersion.reviews,
        (review) => new Date(review.created)
      );
      return generatePath(APP_ROUTES.REVIEW_LINK.path, {
        stepId: latestReview.stepId,
        reviewId: latestReview.id,
      });
    }
    return null;
  }, [lastReviewedVersion]);

  const handleThumbnailClick = () => {
    setSelectedFileId(file.id);
  };

  return (
    <Tooltip
      title={t("FILE_REVIEW.OPEN_LATEST_REVIEW")}
      placement="bottom"
      disabled={!lastReviewedVersion || isSelectionState}
    >
      <Box
        width={100}
        minWidth={100}
        height="100%"
        bgcolor={theme.color.gray[200]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.thumbnail}
        data-testid="file-card-thumbnail-view"
      >
        {lastVersion.thumbnail ? (
          <Image
            src={lastVersion.thumbnail.url}
            alt={file.name}
            data-testid="file-card-thumbnail-image"
            enableLazyLoad
          />
        ) : (
          <MimeTypeIcon
            version={lastVersion}
            data-testid="file-card-fallback-thumbnail"
          />
        )}
        {lastReviewedVersion && !isSelectionState && (
          <Box
            bgcolor={theme.color["black-translucent-60"]}
            position="absolute"
            width={100}
            minWidth={100}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.thumbnailOverlay}
          >
            <Link
              onClick={handleThumbnailClick}
              data-testid="file-card-thumbnail-link"
              className={classes.thumbnailLink}
              component={ReactLink}
              to={lastReviewUrl}
              fontSize={FSTGTypography.fontSize_1_5}
              fontWeight="fontWeight.bold"
              textAlign="center"
              color="white"
            >
              {t("FILE_REVIEW.OPEN_REVIEW")}
            </Link>
          </Box>
        )}
        {isSelectionState && (
          <Box
            className={classes.selectionOverlay}
            onClick={() => toggleSelectFile(file.id)}
            data-testid="file-card-thumbnail-selection-overlay"
          >
            <Text
              color={theme.color.white}
              fontSize={FSTGTypography.fontSize_1_5}
              fontWeight={theme.typography.fontWeight.bold}
              textAlign="center"
              translate={
                isFileSelected(file.id)
                  ? "BULK_DOWNLOAD.HOVER.DESELECT"
                  : "BULK_DOWNLOAD.HOVER.SELECT"
              }
            />
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}

FileCardThumbnail.propTypes = {
  file: fileProp.isRequired,
  lastVersion: versionProp.isRequired,
  lastReviewedVersion: versionProp,
};

FileCardThumbnail.defaultProps = {
  lastReviewedVersion: null,
};

export default FileCardThumbnail;
