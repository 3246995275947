import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { TEAM_PERMISSIONS } from "@supporting/constants/team";
import { z } from "zod";

export const teamRoleFormSchema = (teamRoles) =>
  z.object({
    roleName: z
      .string()
      .min(1, "TEAM.ROLES.INVALID_NAME")
      .refine((name) => !teamRoles.includes(name), "TEAM.ROLES.EXIST"),
    permissions: z.object(
      Object.fromEntries(
        Object.values(TEAM_PERMISSIONS).map((permissionName) => [
          permissionName,
          z.boolean().optional(),
        ])
      )
    ),
  });

export const useTeamRoleForm = ({ defaultValues, teamRoles }) => {
  const { t } = useTranslation();

  return useForm({
    mode: "onChange",
    resolver: zodResolver(
      teamRoleFormSchema(
        teamRoles
          .map((role) =>
            role.isDefault ? t(`TEAM.ROLES.${role.roleName}`) : role.roleName
          )
          .filter((roleName) => roleName !== defaultValues.roleName)
      )
    ),
    defaultValues,
  });
};
