import PropTypes from "prop-types";
import { useEffect } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles(() => ({
  clickableWarning: {
    cursor: "pointer",
  },
}));

function SeatLimitWarning({ icon, text, color, testId, clickHandler }) {
  const classes = useStyles();

  useEffect(() => {
    analytics.trackV2({
      action: analytics.ACTION.REACHED,
      category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
      subCategory: analytics.SUB_CATEGORY.SEATS,
      type: analytics.TYPE.FEATURE,
    });
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      data-testid={testId}
      color={color}
      textAlign="left"
    >
      {icon}
      <Text
        onClick={clickHandler}
        className={classnames(classes.Button, {
          [classes.clickableWarning]: clickHandler,
        })}
        fontSize={FSTGTypography.fontSize_1_3}
        data-testid="seat-limit-warning-text"
      >
        {text}
      </Text>
    </Box>
  );
}

SeatLimitWarning.propTypes = {
  icon: PropTypes.object.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  color: PropTypes.string.isRequired,
  testId: PropTypes.string,
  clickHandler: PropTypes.func,
};

SeatLimitWarning.defaultProps = {
  testId: "seat-limit-warning",
  clickHandler: undefined,
};

export default SeatLimitWarning;
