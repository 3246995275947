import PropTypes from "prop-types";
import { useState, useCallback, ReactElement, Children } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton } from "@mui/material";

import { WizardDialogContext } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import { automationWizardProps } from "@shared/props/automations";

import WizardStep from "./WizardStep";

/**
 * UIKit Wizard Dialog Component
 * @description A dialog component that hosts a sequential wizard and provides support for consistent shared state management as well as navigation between steps.
 * @param {object} props - Component props
 * @param {ReactElement} [props.children] Defines the steps for the wizard. Only WizardStep component is allowed
 * @param {number?} props.initialStepIndex Specifies the index of initial step to load when wizard is loaded
 * @param {Function} props.handleClose A callback invoked when dialog is closed
 * @param {object?} props.dialogProps Additional props for dialog
 * @param {boolean} props.isOpen Determines whether dialog is opened or closed
 * @param {Function} props.onStepChange A callback to be invoked when transitioning from one step to another
 * @param {object?} props.initialState The default state for wizard. This state will be available for all steps within this wizard
 * @returns {ReactElement} - React component
 */
function WizardDialog({
  children,
  dialogProps,
  isOpen,
  initialStepIndex,
  initialState,
  handleClose,
  onStepChange,
}) {
  const stepsChildrenArray = Children.toArray(children);
  const [activeStepIndex, setActiveStepIndex] = useState(initialStepIndex);
  const [dialogState, setDialogState] = useState(initialState || {});

  const activeStep =
    activeStepIndex in stepsChildrenArray
      ? stepsChildrenArray[activeStepIndex]
      : null;
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === stepsChildrenArray.length - 1;

  const moveToStepWithIndex = useCallback(
    (index) => {
      const eventParams = {
        previousStep: activeStepIndex,
        currentStep: index,
      };

      setActiveStepIndex(index);

      if (onStepChange) {
        onStepChange(eventParams);
      }
    },
    [activeStepIndex, onStepChange]
  );

  const handleMoveStep = useCallback(
    (key) => {
      const stepIndex = stepsChildrenArray.findIndex(
        (step, index) => step.props.stepKey === key || index === key
      );

      if (stepIndex >= 0) {
        moveToStepWithIndex(stepIndex);
      }
    },
    [moveToStepWithIndex, stepsChildrenArray]
  );

  const handleMovePreviousStep = useCallback(() => {
    if (!isFirstStep) {
      moveToStepWithIndex(activeStepIndex - 1);
    }
  }, [activeStepIndex, isFirstStep, moveToStepWithIndex]);

  const handleMoveNextStep = useCallback(() => {
    if (!isLastStep) {
      moveToStepWithIndex(activeStepIndex + 1);
    }
  }, [activeStepIndex, isLastStep, moveToStepWithIndex]);

  const handleCloseDialog = (event, reason) => {
    if (reason !== "backdropClick") {
      handleClose(event);
    }
    return;
  };

  return (
    <WizardDialogContext.Provider
      value={{
        activeStepIndex,
        activeStepKey: activeStep?.props.stepKey || activeStepIndex.toString(),
        setDialogState,
        state: dialogState,
        moveNextStep: handleMoveNextStep,
        movePreviousStep: handleMovePreviousStep,
        moveToStep: handleMoveStep,
        onClose: handleCloseDialog,
        isFirstStep,
        isLastStep,
      }}
    >
      <Dialog
        data-testid="wizard-dialog"
        {...dialogProps}
        open={isOpen}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
        hideBackdrop={false}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseDialog}
          data-testid="dialog-cancel-icon"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {activeStep?.props.title}
        <DialogContent sx={{ bgcolor: "grey.50" }}>{activeStep}</DialogContent>
      </Dialog>
    </WizardDialogContext.Provider>
  );
}

WizardDialog.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(WizardStep),
    PropTypes.objectOf(WizardStep),
  ]).isRequired,
  initialStepIndex: PropTypes.number,
  handleClose: PropTypes.func.isRequired,
  dialogProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
  ),
  onStepChange: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  initialState: PropTypes.oneOfType([automationWizardProps]),
};

WizardDialog.defaultProps = {
  dialogProps: null,
  initialStepIndex: 0,
  onStepChange: null,
  initialState: null,
};

export default WizardDialog;
