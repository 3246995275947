import PropTypes from "prop-types";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Link } from "@mui/material";

import { Text } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

import UsageBar from "./UsageBar";
import UsageTooltip from "./UsageTooltip";

function AutomationsUsage({ team, openBillingDialog }) {
  const { t } = useTranslation();

  const getAutomationsUsageLimit = useCallback(() => {
    if (!team.subscriptionLimits.maxProjectAutomationNumber) {
      return 0;
    }
    if (team.subscriptionLimits.maxProjectAutomationNumber > -1) {
      return team.subscriptionLimits.maxProjectAutomationNumber;
    }

    return t("BILLING.UNLIMITED");
  }, [team.subscriptionLimits.maxProjectAutomationNumber, t]);

  return (
    <UsageBar
      name="BILLING.FEATURE.AUTOMATIONS.LABEL"
      tooltip={
        <UsageTooltip usageMessage1={t("BILLING.FEATURE.AUTOMATIONS.HELP")} />
      }
      used={getAutomationsUsageLimit()}
      isLinearProgressVisible={false}
      isHelperVisible={
        team.subscriptionLimits.maxProjectAutomationNumber !== -1
      }
      helperTooltip={
        <Text variant="subtitle1" color="text.secondary">
          <Trans
            components={{
              anchor: (
                <Link
                  onClick={openBillingDialog}
                  color="upgrade"
                  variant="textSm"
                  fontWeight="fontWeight.medium"
                  data-testid="upgrade-plan-link"
                />
              ),
            }}
          >
            {t("BILLING.FEATURE.AUTOMATIONS.UPGRADE_HELP")}
          </Trans>
        </Text>
      }
      helperText="BILLING.FEATURE.AUTOMATIONS.UPGRADE"
    />
  );
}

AutomationsUsage.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default AutomationsUsage;
