/* eslint react/forbid-component-props:0 */
import PropTypes from "prop-types";
import { ReactElement, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useShareLink from "@feedback/hooks/useShareLink";
import stepShareLinkService from "@feedback/services/stepShareLinkService";
import { Link, OpenInNew } from "@mui/icons-material";
import {
  Grow,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Popper,
} from "@mui/material";

import { Box, Text, Tooltip } from "@shared/UIKit";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import ShareLinkPopper from "./ShareLinkPopper/ShareLinkPopper";

/* eslint-disable complexity */
/**
 * UIKit Share Link Component
 * @description Renders a share link icon with Tooltip. On click, it opens a ShareLinkPopper component
 * and copies the share link to the clipboard. It builds the share link with `fileId`, `stepId` and `versionNumber`.
 * @typedef FstgId
 * @param {{
 *  stepId: FstgId,
 *  fileId: FstgId,
 *  reviewId: FstgId,
 *  link: string,
 *  tooltipDirection: string,
 *  small: boolean,
 *  isReviewCard: boolean,
 *  className: string
 * }} props - Component props
 * @param {FstgId} props.stepId [Optional] Step Id for the share link.
 * @param {FstgId} props.fileId [Optional] File Id for the share link.
 * @param {FstgId} props.reviewId [Optional] Review Id for the share batch review link.
 * @param {string} props.link [Optional] Share link. If this options is provided, then the component will not build the share link and use this link instead.
 * @param {string} props.tooltipDirection [Optional] Tooltip direction. Can be `top`, `bottom`, `left`, `right`. Default is `top`.
 * @param {boolean} props.small [Optional] If true, the icon will be small. Default is `false`.
 * @param {boolean} props.isReviewCard [Optional] If true, the icon will be rendered for the ReviewCard component. Default is `false`.
 * @returns {ReactElement} React component
 */
function ShareLink({
  stepId,
  fileId,
  reviewId,
  link,
  tooltipDirection,
  small,
  isReviewCard,
  iconButtonProps,
}) {
  const { t } = useTranslation();
  const tooltipElement = useRef(null);
  const [reviewLinkButtonEl, setReviewLinkButtonEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openShareMenu = useCallback(({ currentTarget }) => {
    setMenuAnchorEl(currentTarget);
  }, []);

  const closeShareMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const batchReviewLink = stepShareLinkService.getBatchReviewLinkUrl(
    stepId,
    reviewId
  );

  const { anchorEl, copyShareLink, wasCopiedSuccessful } = useShareLink({
    fileId,
    stepId,
    reviewId,
    link,
    ...(!isReviewCard ? { onClose: closeShareMenu } : {}),
  });
  const {
    anchorEl: batchReviewAnchorEl,
    copyShareLink: copyBatchReviewLink,
    wasCopiedSuccessful: wasBatchReviewCopiedSuccessful,
  } = useShareLink({
    fileId,
    stepId,
    reviewId,
    link: batchReviewLink,
    onClose: closeShareMenu,
  });

  const handleCopyShareLink = (event, isBatchReview) => {
    if (isBatchReview) {
      copyBatchReviewLink(event);
    } else {
      copyShareLink(event);
    }

    eventService.emitEvent({
      eventName: EVENT_NAMES.REVIEW.REMINDER.SHOW,
      eventData: {},
    });
  };

  const handleReviewLinkButtonRefChange = useCallback((node) => {
    setReviewLinkButtonEl(node);
  }, []);

  const openBatchReviewInNewTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(batchReviewLink, "_blank");
  };

  const open = Boolean(anchorEl) || Boolean(batchReviewAnchorEl);
  const linkCopiedSuccessfully =
    wasCopiedSuccessful || wasBatchReviewCopiedSuccessful;

  let color = "default";
  if (open) {
    color = linkCopiedSuccessfully ? "success" : "error";
  }

  const iconButton = (
    <IconButton
      data-testid="share-link-copy-review-link"
      aria-label={t("REVIEWER.INVITE.TITLE")}
      color={color}
      onClick={isReviewCard ? handleCopyShareLink : openShareMenu}
      size={small ? "small" : "large"}
      {...iconButtonProps}
    >
      <Link
        color="inherit"
        fontSize="inherit"
        sx={{ transform: "rotate(-45deg)" }}
      />
    </IconButton>
  );

  return (
    <>
      {open && isReviewCard && (
        <ShareLinkPopper
          open={open}
          anchorEl={anchorEl}
          tooltipDirection={tooltipDirection}
          wasCopiedSuccessful={wasCopiedSuccessful}
        />
      )}

      {open && !isReviewCard && (
        <Popper
          style={{ zIndex: 10000 }}
          open={open}
          anchorEl={
            (wasCopiedSuccessful && anchorEl) ||
            (wasBatchReviewCopiedSuccessful && batchReviewAnchorEl)
          }
          placement="right"
          transition
          data-testid="share-link-menu-popper"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Box
                bgcolor="grey.700"
                textAlign="center"
                borderRadius="4px"
                padding="2px 8px"
                ml={-3}
              >
                <Text
                  variant="textSm"
                  color="white"
                  data-testid="share-link-popper-text"
                >
                  {t("PROJECT.FILE.GET_SHARELINK.COPY_SUCCESS")}
                </Text>
              </Box>
            </Grow>
          )}
        </Popper>
      )}

      {isReviewCard ? (
        <Tooltip
          ref={tooltipElement}
          placement={tooltipDirection}
          title={t("PROJECT.FILE.GET_SHARELINK.TOOLTIP_TITLE")}
          description={t("PROJECT.FILE.GET_SHARELINK.TOOLTIP_DESCRIPTION")}
        >
          {iconButton}
        </Tooltip>
      ) : (
        <Tooltip
          placement={tooltipDirection}
          title={t("PROJECT.FILE.GET_SHARELINK.TOOLTIP_TITLE")}
        >
          {iconButton}
        </Tooltip>
      )}

      <Menu
        id="share-menu"
        data-testid="share-menu"
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeShareMenu}
        TransitionProps={{
          onEntered: () => {
            copyShareLink({ currentTarget: reviewLinkButtonEl }, true);
          },
        }}
      >
        <Tooltip
          ref={tooltipElement}
          placement={tooltipDirection}
          title={t("GRID_LINK.FILE_VIEW_TOOLTIP_TITLE")}
          description={t("PROJECT.FILE.GET_SHARELINK.TOOLTIP_DESCRIPTION")}
        >
          <MenuItem
            onClick={handleCopyShareLink}
            ref={handleReviewLinkButtonRefChange}
            data-testid="copy-file-review"
          >
            <ListItemText>{t("GRID_LINK.DROPDOWN.FILE_VIEW")}</ListItemText>
          </MenuItem>
        </Tooltip>

        <MenuItem
          onClick={(event) => {
            handleCopyShareLink(event, true);
          }}
          data-testid="copy-batch-review"
        >
          <Tooltip
            ref={tooltipElement}
            placement={tooltipDirection}
            title={t("GRID_LINK.GRID_VIEW_TOOLTIP_TITLE")}
            description={t("GRID_LINK.GRID_VIEW_TOOLTIP_BODY")}
          >
            <ListItemText>{t("GRID_LINK.DROPDOWN.GRID_VIEW")}</ListItemText>
          </Tooltip>

          <Tooltip title={t("GRID_LINK.DROPDOWN.TOOLTIP")} placement="right">
            <IconButton
              onClick={openBatchReviewInNewTab}
              data-testid="open-batch-review"
              size="small"
            >
              <OpenInNew fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </MenuItem>
      </Menu>
    </>
  );
}

ShareLink.propTypes = {
  stepId: PropTypes.string,
  fileId: PropTypes.string,
  reviewId: PropTypes.string,
  versionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  link: PropTypes.string,
  tooltipDirection: PropTypes.oneOf(["bottom", "left", "right", "top"]),
  small: PropTypes.bool,
  isReviewCard: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  iconButtonProps: PropTypes.object,
};

ShareLink.defaultProps = {
  tooltipDirection: "top",
  stepId: "",
  fileId: "",
  reviewId: "",
  versionNumber: 0,
  link: "",
  small: false,
  isReviewCard: false,
  iconButtonProps: {},
};

export default ShareLink;
