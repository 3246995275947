import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import AIAvatar from "@assets/img/icons/AIAvatar.svg";

const AIAutocompleteButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      color="accent"
      variant="highlight"
      startIcon={<AIAvatar />}
      data-testid="ai-marketplace-button"
      onClick={onClick}
      sx={{
        position: "fixed",
        right: 0,
        bottom: 0,
        marginRight: 1.25,
        marginBottom: 1.25,
        "&:hover": {
          backgroundColor: "var(--mui-color-purple-200)",
        },
      }}
    >
      {t("CORE.BOTS.TITLE")}
    </Button>
  );
};

AIAutocompleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AIAutocompleteButton;
