export const UPLOAD_AVATAR_SIZE = Object.freeze({
  FILESIZE_MAX: 20,
  FILESIZE_UNIT: "MB",
});

export const UPLOAD_COMPONENT_TYPE = Object.freeze({
  POPOVER: "file-upload-popover",
  NAVIGATION_WORKSPACE: "navigation-workspace",
  WORKSPACE_SIDEBAR: "workspace-sidebar",
});

export const UPLOAD_ATTACHMENT_SIZE = Object.freeze({
  FILESIZE_MAX: 1,
  FILESIZE_UNIT: "GB",
});

export const FILE_UPLOAD_CONFIG = Object.freeze({
  FILESIZE_MAX: 20,
  FILESIZE_UNIT: "GB",
  MAX_FILES_BULK_UPLOADABLE: 1000,
});

export const FILE_UPLOAD_STATES = Object.freeze({
  PENDING: "PENDING",
  UPLOADING: "UPLOADING",
  UPLOADING_FROM_PLUGIN: "UPLOADING_FROM_PLUGIN",
  TRANSCODING: "TRANSCODING",
  IMPORTING: "IMPORTING",
});
export const FILE_VERSION_UPLOAD_STATUS = Object.freeze({
  PROCESSING: "processing",
  COMPLETED: "completed",
  FAILED: "failed",
});
