import { generatePath } from "react-router-dom";

import { APP_ROUTES } from "@shared/constants/routes";
import fstgId from "@shared/helpers/fstgId";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

let teamConnectionCache;
const projectSettingsCache = new Map();

const generateOauthUrl = (userId, teamId) => {
  const { config } = window.fs;

  const { clientId } = config.slackApp;
  const redirectUrl = `${config.appBase}${generatePath(
    APP_ROUTES.INTEGRATIONS_AUTHORIZATION_CALLBACK.path,
    {
      appId: "slack",
    }
  )}`;

  const uniqueState = fstgId.generate();

  sessionStorage.setItem("integrations.slack.state", uniqueState);

  return `https://slack.com/oauth/v2/authorize?scope=chat:write,channels:read,channels:join,groups:read&client_id=${clientId}&redirect_uri=${redirectUrl}&state=${teamId}${userId}${uniqueState}`;
};

const getTeamConnection = async (teamId) => {
  if (teamConnectionCache?.teamId === teamId) {
    return teamConnectionCache;
  }

  try {
    const teamConnection = await backend.get(`/integrations/slack`, {
      teamId,
    });

    teamConnectionCache = teamConnection;
  } catch {
    teamConnectionCache = null;
  }

  return teamConnectionCache;
};

const getTeamChannels = async (teamId, cursor) => {
  const teamChannels = await backend.get(`/integrations/slack/channels`, {
    teamId,
    cursor,
  });

  return teamChannels;
};

const getProjectChannel = async (teamId, projectId) => {
  const projectChannelCacheValue = projectSettingsCache[projectId];

  if (projectChannelCacheValue) {
    return projectChannelCacheValue;
  }

  const projectChannel = await backend.get(`/integrations/slack/project`, {
    teamId,
    projectId,
  });

  projectSettingsCache[projectId] = projectChannel;

  return projectChannel;
};

const disconnectApp = async (teamId) => {
  await backend.delete(`/integrations/slack`, {
    teamId,
  });

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.DISCONNECTED,
    eventData: {
      teamId,
      type: "slack",
    },
  });
};

const connectChannelsToProject = async (
  teamId,
  projectId,
  channelId,
  notificationSettings
) => {
  const savedProjectSettings = await backend.post(
    `/integrations/slack/project`,
    {
      teamId,
      projectId,
      channelId,
      notificationSettings,
    }
  );

  projectSettingsCache[projectId] = savedProjectSettings;

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_CONNECTED,
    eventData: {
      teamId,
      projectId,
      channelId,
      notificationSettings,
    },
  });

  return savedProjectSettings;
};

const disconnectProject = async (teamId, projectId) => {
  await backend.delete(`/integrations/slack/project`, {
    projectId,
    teamId,
  });

  projectSettingsCache[projectId] = null;

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_DISCONNECTED,
    eventData: {
      teamId,
      projectId,
    },
  });
};

export default {
  generateOauthUrl,
  getTeamConnection,
  disconnectApp,
  getTeamChannels,
  connectChannelsToProject,
  getProjectChannel,
  disconnectProject,
};
