import { makeStyles } from "@mui/styles";

export const useUploadPanelStyles = makeStyles((theme) => ({
  ButtonText: {
    height: "100%",
    width: "100%",
    border: `1px dashed ${theme.color.gray[300]}`,
    borderRadius: "9px",
    "&:hover": {
      backgroundColor: theme.color.white,
    },
  },
  ButtonTextWithDrag: {
    backgroundColor: theme.color["pigment-green-translucent-005"],
  },
  UploadPanelWrapper: {
    minWidth: 600,
    maxWidth: 800,
    padding: 12,
    borderRadius: "8px",
    outline: "none",
    backgroundColor: theme.color.gray[50],
    [theme.breakpoints.only("sm")]: {
      width: 427,
      minWidth: 427,
    },
    [theme.breakpoints.only("xs")]: {
      width: "auto",
      minWidth: "auto",
    },
  },
  InDesignUploadPanelWrapper: {
    width: "100%",
  },
  CloudUploadOutlined: { fontSize: 30 },
  DescriptionText: {
    width: 388,
  },
  LocalFileButtonLabel: {
    "& a": {
      textDecoration: "underline",
    },
  },
  UpgradeIcon: {
    color: `${theme.color.blue[500]} !important`,
    fill: `${theme.color.blue[500]} !important`,
    marginLeft: "auto",
    width: 18,
    height: 18,
  },
}));
