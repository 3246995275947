import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import projectReviewService from "@workflow/services/projectReviewService";
import projectService from "@workflow/services/projectService";
import projectTemplateService from "@workflow/services/projectTemplateService";

import { useNavigation, useCurrentRouteName } from "@shared/hooks";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

function useProjectListMobile() {
  const { goTo } = useNavigation();
  const currentRouteName = useCurrentRouteName();
  const { projectId, projectTemplateId } = useParams();
  const [
    {
      folders,
      selectedTeam,
      templates,
      externalProjects,
      selectedOption,
      favoriteProjects,
    },
    setState,
  ] = useState(() => {
    return {
      folders: [],
      externalProjects: [],
      favoriteProjects: [],
      templates: [],
      selectedTeam: teamService.getSelectedTeam(),
      selectedOption: currentRouteName + (projectId || projectTemplateId || ""),
    };
  });

  const handleSelectedOptionChanged = useCallback(
    (event) => {
      const { value } = event.target;

      setState((prevState) => ({
        ...prevState,
        selectedOption: value,
      }));

      if (value.startsWith("PROJECT_ID")) {
        goTo("PROJECT_ID", {
          params: {
            projectId: value.replace("PROJECT_ID", ""),
          },
        });
      } else if (value.startsWith("TEMPLATE_ID")) {
        goTo("TEMPLATE_ID", {
          params: {
            projectTemplateId: value.replace("TEMPLATE_ID", ""),
          },
        });
      }
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getOptionValue = useCallback(
    (type, id) => (type === "project" ? "PROJECT_ID" : "TEMPLATE_ID") + id,
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const naturalSort = useCallback(
    (param1, param2, field = "name") => {
      const userLanguage = authenticationService.fetchUser().language;

      const collator = new Intl.Collator(userLanguage, {
        numeric: true,
        sensitivity: "base",
      });

      return collator.compare(param1[field], param2[field]);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const sortFolders = useCallback(
    (folders) => {
      return folders
        .map((folder) => {
          folder.projects = (folder.projects || []).sort(naturalSort);

          return folder;
        })
        .sort(naturalSort);
    },
    [naturalSort]
  );

  const sortReviewersProject = useCallback(
    (externalProjects) => {
      return externalProjects.sort((project1, project2) =>
        naturalSort(project1, project2, "teamName")
      );
    },
    [naturalSort]
  );

  useEffect(
    () => {
      const onTeamUpdated = async (event) => {
        const { team } = event.eventData;

        const folders = await projectService.fetchProjectsByTeamId(team._id);

        setState((prevState) => ({
          ...prevState,
          folders: sortFolders(folders),
          selectedTeam: team,
        }));
      };

      const onProjectSelected = (event) => {
        const { project } = event.eventData;
        if (project.teamId === selectedTeam._id) {
          setState((prevState) => ({
            ...prevState,
            selectedOption: currentRouteName + project.id,
          }));
        }
      };

      const fetchInitialData = async () => {
        const [folders, externalProjects] = await Promise.all([
          projectService.fetchProjectsByTeamId(selectedTeam._id),
          projectReviewService.fetchExternalProjects(),
        ]);
        const favoriteProjects = await projectService.fetchFavoriteProjects();
        setState((prevState) => ({
          ...prevState,
          folders: sortFolders(folders),
          externalProjects: sortReviewersProject(externalProjects),
          favoriteProjects,
        }));
      };

      const onFoldersUpdated = (event) => {
        if (event.eventData.teamId === selectedTeam._id) {
          setState((prevState) => ({
            ...prevState,
            folders: sortFolders(event.eventData.folders),
          }));
        }
      };
      const onReviewProjectsUpdated = (event) => {
        setState((prevState) => ({
          ...prevState,
          externalProjects: sortReviewersProject(
            event.eventData.externalProjects
          ),
        }));
      };

      const updateFavoriteProjects = ({ eventData }) => {
        const { projects } = eventData;
        setState((prevState) => ({
          ...prevState,
          favoriteProjects: projects,
        }));
      };

      eventService.addListener(EVENT_NAMES.TEAM.SELECTED.ADDED, onTeamUpdated);
      eventService.addListener(
        EVENT_NAMES.TEAM.SELECTED.UPDATED,
        onTeamUpdated
      );
      eventService.addListener(EVENT_NAMES.PROJECT.SELECTED, onProjectSelected);
      eventService.addListener(EVENT_NAMES.FOLDERS.UPDATED, onFoldersUpdated);
      eventService.addListener(
        EVENT_NAMES.REVIEWER_PROJECTS.UPDATED,
        onReviewProjectsUpdated
      );
      eventService.addListener(
        EVENT_NAMES.FAVORITE_PROJECTS.UPDATED,
        updateFavoriteProjects
      );

      fetchInitialData();

      return () => {
        eventService.removeListener(
          EVENT_NAMES.TEAM.SELECTED.ADDED,
          onTeamUpdated
        );
        eventService.removeListener(
          EVENT_NAMES.TEAM.SELECTED.UPDATED,
          onTeamUpdated
        );
        eventService.removeListener(
          EVENT_NAMES.PROJECT.SELECTED,
          onProjectSelected
        );
        eventService.removeListener(
          EVENT_NAMES.FOLDERS.UPDATED,
          onFoldersUpdated
        );
        eventService.removeListener(
          EVENT_NAMES.REVIEWER_PROJECTS.UPDATED,
          onReviewProjectsUpdated
        );
        eventService.removeListener(
          EVENT_NAMES.FAVORITE_PROJECTS.UPDATED,
          updateFavoriteProjects
        );
      };
    },
    [selectedTeam] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    () => {
      async function fetchTemplates() {
        const templates =
          await projectTemplateService.fetchProjectTemplatesByTeamId(
            selectedTeam._id
          );
        setState((prevState) => ({ ...prevState, templates }));
      }

      fetchTemplates();
    },
    [selectedTeam] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return {
    selectedTeam,
    folders,
    externalProjects,
    favoriteProjects,
    selectedOption,
    handleSelectedOptionChanged,
    templates,
    getOptionValue,
  };
}

export default useProjectListMobile;
