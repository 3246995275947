/* eslint react/forbid-elements:0 */
import PropTypes from "prop-types";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";

import { Button, Chip, Divider, Link, Box } from "@mui/material";
import classnames from "classnames";

import { Text } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import FSTGTypography from "@shared/theme/typography";

import { Presenter } from "./Presenter";
import { usePresenterStyles } from "./styles";

const MIN_SEARCH_RESULTS = 10;
const SHOW_MORE_RESULTS_KEYS = {
  project: "SEARCH.ALL_PROJECTS",
  file: "SEARCH.ALL_FILES",
  comment: "SEARCH.ALL_COMMENTS",
};

const SearchComponent = ({
  header,
  searchDialog,
  results,
  type,
  showDivider,
  onSearchMore,
  isSectionActive,
  activeItemIndex,
  ...restProps
}) => {
  const itemsRef = useRef([]);
  const presenterStyles = usePresenterStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const {
    goToSearchResult,
    state: { searchQuery },
  } = searchDialog;

  const hasResults = Boolean(
    results && results.topResults && results.topResults.length > 0
  );

  const checkIfSelected = (key) => {
    if (!isSectionActive) {
      return false;
    }

    const isSelected = activeItemIndex === key;
    if (isSelected && itemsRef.current[key]) {
      itemsRef.current[key].scrollIntoView({
        behavior: "instant",
        block: "center",
      });
    }

    return isSelected;
  };

  return (
    hasResults && (
      <>
        {showDivider && <Divider />}
        <Box display="flex" flexDirection="column" mb={1} pt={2} {...restProps}>
          <Box
            color="text.secondary"
            fontSize="subtitle1"
            fontWeight="fontWeight.medium"
            lineHeight={FSTGTypography.lineHeight_1_5}
            pl={4.4}
            mb={0.5}
            display="flex"
            gap={1}
          >
            <Text
              component="span"
              data-testid="search-result-header"
              fontWeight="fontWeight.medium"
              fontSize="textSm.fontSize"
              color="default-light"
            >
              {header}
            </Text>
            {results.totalCount && (
              <Chip
                variant="filled-light"
                color="default-light"
                size="small"
                label={`${results.topResults.length}${
                  results.totalCount > results.topResults.length
                    ? ` ${t("SEARCH.OF")} ${results.totalCount}`
                    : ""
                }`}
                sx={{ height: 22 }}
              />
            )}
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center">
            {results.topResults.map((searchResult, key) => {
              const isSelected = checkIfSelected(key);
              return (
                <Link
                  key={searchResult.resourceLinkUrl}
                  ref={(element) => (itemsRef.current[key] = element)}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="center"
                  component={ReactLink}
                  to={searchResult.resourceLinkUrl}
                  onClick={(event) =>
                    goToSearchResult(
                      searchResult.resourceLinkUrl,
                      type,
                      event,
                      "results_overview",
                      searchResult.resource
                    )
                  }
                  className={classnames(presenterStyles.SearchResultItem, {
                    [presenterStyles.Selected]: isSelected,
                  })}
                  data-testid={
                    isSelected
                      ? "search-result-item-selected"
                      : "search-result-item"
                  }
                >
                  <Presenter
                    searchQuery={searchQuery}
                    searchResult={searchResult}
                    isMobile={isMobile}
                  />
                </Link>
              );
            })}
          </Box>
          {results.totalCount > MIN_SEARCH_RESULTS && (
            <Box display="flex" ml={2.5} data-testid="search-more-results">
              <Button
                variant="text"
                size="small"
                color="secondary"
                onClick={() => onSearchMore(type)}
                data-testid="show-more-results-button"
                sx={{
                  color:
                    activeItemIndex === results.topResults.length &&
                    "accent.main",
                }}
              >
                {t(SHOW_MORE_RESULTS_KEYS[type])}
              </Button>
            </Box>
          )}
        </Box>
      </>
    )
  );
};

SearchComponent.propTypes = {
  searchDialog: PropTypes.object.isRequired,
  results: PropTypes.object,
  type: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  showDivider: PropTypes.bool,
  onSearchMore: PropTypes.func.isRequired,
  isSectionActive: PropTypes.bool.isRequired,
  activeItemIndex: PropTypes.number,
};

SearchComponent.defaultProps = {
  results: undefined,
  showDivider: false,
  activeItemIndex: 0,
};

export default SearchComponent;
