import { useState, useEffect } from "react";

import LinearProgress from "@mui/material/LinearProgress";

import { Box } from "@shared/UIKit";

import backend from "@shared/services/backendClient";

export default function NetworkLoadingBar() {
  const [pendingRequests, setPendingRequests] = useState(0);
  useEffect(() => {
    const interceptor = {
      request: () => setPendingRequests((pr) => pr + 1),
      response: () => setPendingRequests((pr) => pr - 1),
      error: () => setPendingRequests((pr) => pr - 1),
    };
    backend.interceptors.push(interceptor);
    return () =>
      backend.interceptors.splice(backend.interceptors.indexOf(interceptor), 1);
  }, [setPendingRequests]);

  return (
    pendingRequests > 0 && (
      <Box
        data-testid="network-loading-bar"
        position="absolute"
        width="100%"
        zIndex={9999}
      >
        <LinearProgress variant="indeterminate" />
      </Box>
    )
  );
}
