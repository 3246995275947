import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { Link } from "@mui/material";

import { Box, Text, Image } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

function FeatureTooltip({
  description,
  linkHref,
  linkText,
  imageSrc,
  isEnabled,
  analyticsCategory,
  upgradePlanMessage1,
  upgradePlanMessage2,
  upgradePlanLinkText,
  getInTouchUpgrade,
  openBillingDialog,
  getInTouchDescription,
  getInTouchUpgradeText,
}) {
  const onGetInTouchClick = () => {
    /* istanbul ignore else */
    if (analyticsCategory) {
      analytics.trackV2({
        action: analytics.ACTION.CLICKED,
        category: analytics.CATEGORY[analyticsCategory],
        subCategory: analytics.SUB_CATEGORY.UPGRADE_INTEREST_BUTTON,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Text variant="subtitle1" color="text.secondary">
        <Trans>{description}</Trans>{" "}
        {linkHref && (
          <Link
            color="accent"
            href={linkHref}
            target="_blank"
            fontWeight="fontWeight.medium"
          >
            {linkText}
          </Link>
        )}
        {getInTouchDescription && (
          <Link
            href="mailto:hello@filestage.io"
            className="launch-intercom-chat"
            fontWeight="fontWeight.medium"
          >
            {getInTouchDescription}
          </Link>
        )}
      </Text>
      {imageSrc && (
        <Image
          src={imageSrc}
          width="100%"
          height="100%"
          alt="Workflow Feature"
        />
      )}

      {!isEnabled && (
        <Text variant="subtitle1" color="text.secondary">
          <Trans>{upgradePlanMessage1}</Trans>{" "}
          {openBillingDialog && (
            <Link
              onClick={openBillingDialog}
              color="upgrade"
              fontWeight="fontWeight.medium"
              sx={{ cursor: "pointer" }}
              data-testid="upgrade-billing-action-link"
            >
              {upgradePlanLinkText}
            </Link>
          )}
          {/*TODO: This getInTouchUpgrade and upgradePlanMessage2 should
               eventually replaced by getInTouchUpgradeText. Splitting a
               sentance into multiple phrase keys are difficult for product
               to translate in to different languages
               */}
          {getInTouchUpgrade && (
            <Link
              href="mailto:hello@filestage.io"
              className="launch-intercom-chat"
              fontWeight="fontWeight.medium"
              data-testid="get-in-touch-link"
              onClick={onGetInTouchClick}
            >
              {getInTouchUpgrade}
            </Link>
          )}
          <Trans>{upgradePlanMessage2}</Trans>
          {getInTouchUpgradeText && (
            <Trans
              components={{
                anchor: (
                  <Link
                    href="#"
                    data-testid="get-in-touch-link"
                    className="launch-intercom-chat"
                    fontWeight="fontWeight.medium"
                  />
                ),
              }}
            >
              {getInTouchUpgradeText}
            </Trans>
          )}
        </Text>
      )}
    </Box>
  );
}

FeatureTooltip.propTypes = {
  description: PropTypes.string,
  linkHref: PropTypes.string,
  linkText: PropTypes.string,
  imageSrc: PropTypes.string,
  isEnabled: PropTypes.bool,
  analyticsCategory: PropTypes.string,
  upgradePlanMessage1: PropTypes.string,
  upgradePlanMessage2: PropTypes.string,
  upgradePlanLinkText: PropTypes.string,
  getInTouchUpgrade: PropTypes.string,
  getInTouchUpgradeText: PropTypes.string,
  getInTouchDescription: PropTypes.string,
  openBillingDialog: PropTypes.func,
};

FeatureTooltip.defaultProps = {
  description: null,
  linkHref: null,
  linkText: null,
  imageSrc: null,
  analyticsCategory: "",
  upgradePlanMessage1: null,
  upgradePlanMessage2: null,
  upgradePlanLinkText: null,
  openBillingDialog: null,
  getInTouchUpgrade: null,
  getInTouchUpgradeText: null,
  getInTouchDescription: null,
  isEnabled: false,
};

export default FeatureTooltip;
