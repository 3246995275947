import { useState, useRef } from "react";

/**
 * Use Field Hook
 *
 * @description This hook is being used for handling the state of an input field.
 * It gets an initial value of the input and and array of validator functions.
 * @typedef ReactRef
 * @param {string} initialValue - [Required] Initial value of the input field.
 * @param {Array.<Function>?} validators - [Required] Array of validator functions.
 * That will be run on every change of the input field.
 * @returns {{
 *  value: string,
 *  error: string,
 *  change: Function,
 *  isPristine: ReactRef,
 *  reset: Function,
 * }} Returns the latest input value and error message along with the
 * change and reset functions. It also contains the pristine state of the input
 * which is a React Ref.
 */
export default function useField(initialValue = "", validators = []) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState("");

  const isPristine = useRef(true);

  const change = (nextValue) => {
    setValue(nextValue);
    if (isPristine.current) {
      isPristine.current = false;
    }

    try {
      for (const validator of validators) {
        validator(nextValue);
      }
      setError("");
    } catch (err) {
      setError(err.message);
    }
  };

  const reset = () => {
    setValue(initialValue);
    setError("");
    isPristine.current = true;
  };

  return {
    value,
    error,
    change,
    isPristine: isPristine.current,
    reset,
  };
}
