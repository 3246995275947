import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import { Button } from "@mui/material";
import toastService from "@supporting/services/toast";

import { Box } from "@shared/UIKit";

import { fileProp } from "@shared/props/file";

import getPresetFiles from "./presets";

function UploadPremiereSequenceButtons({
  closePanel,
  uploadSequenceInput,
  selectedSteps,
  sectionId,
  selectedFile,
}) {
  const { t } = useTranslation();
  const SEQUENCE_PRESETS = getPresetFiles();
  const [uploading, setUploading] = useState(false);

  const { videoName, preset, sequenceId } = uploadSequenceInput;

  useEffect(() => {
    const handleSequenceUploadEvents = (event) => {
      if (event.origin === window.location.origin || !event.data?.eventType) {
        return;
      }

      switch (event.data.eventType) {
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_EXPORT_START:
          setUploading(true);
          break;
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_UPLOAD_SUCCESS:
          closePanel();
          break;
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_UPLOAD_FAILED:
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_EXPORT_FAILED:
          closePanel();
          toastService.sendToast({
            title:
              "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.UPLOAD_FAILED.TITLE",
            body: "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.UPLOAD_FAILED.BODY",
            preset: toastService.PRESETS().ERROR,
          });
          break;
      }
    };

    window.addEventListener("message", handleSequenceUploadEvents);

    return () => {
      window.removeEventListener("message", handleSequenceUploadEvents);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSequenceUpload = useCallback(
    (event) => {
      event.preventDefault();
      const presetFileUrl = SEQUENCE_PRESETS[preset];

      window.postMessage(
        {
          eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_EXPORT_AND_UPLOAD,
          data: {
            exportData: {
              videoName,
              sequenceId,
              renderQuality: preset,
              selectedSteps: Object.keys(selectedSteps),
              presetFile: presetFileUrl,
              sectionId,
              ...(selectedFile ? { fileId: selectedFile._id } : {}),
            },
          },
        },
        "*"
      );
    },
    [videoName, preset, sequenceId, sectionId, selectedSteps] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box
      display="flex"
      pt={2.7}
      pb={2.7}
      justifyContent="center"
      data-testid="upload-premier-sequence-buttons"
      gap={1.5}
    >
      <Button
        variant="text"
        color="primaryV2"
        onClick={closePanel}
        data-testid="cancel-button"
      >
        {t("FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.CANCEL")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        data-testid="upload-button"
        disabled={!(videoName && preset) || uploading}
        onClick={handleSequenceUpload}
      >
        {t(
          uploading
            ? "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.UPLOADING"
            : "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.UPLOAD"
        )}
      </Button>
    </Box>
  );
}

UploadPremiereSequenceButtons.propTypes = {
  closePanel: PropTypes.func.isRequired,
  uploadSequenceInput: PropTypes.objectOf(PropTypes.string),
  selectedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
  selectedFile: fileProp,
  sectionId: PropTypes.string,
};

UploadPremiereSequenceButtons.defaultProps = {
  selectedFile: null,
  sectionId: null,
  uploadSequenceInput: {
    videoName: "",
    sequenceId: "",
    preset: "",
  },
};

export default UploadPremiereSequenceButtons;
