import authenticationService from "@supporting/services/authentication";
import { PROJECT_FILE_SORTING } from "@workflow/constants/project";
import orderBy from "lodash/orderBy";

/**
 * Sort files by placing dummy files first sorted by descending creation date,
 * then sorting the rest by the user sort option.
 * @param {Array<File>} files the files to sort.
 * @returns {Array<File>} the sorted files.
 */
export function sortFiles(files) {
  const { dummyFiles, serverFiles } = splitFiles(files);
  return [
    ...sortFilesByDate(dummyFiles),
    ...sortFilesByUserOption(serverFiles),
  ];
}

/**
 * Sort files by user sort option.
 * @param {Array<File>} files the files to sort.
 * @returns {Array<File>} the sorted files.
 */
export function sortFilesByUserOption(files) {
  const user = authenticationService.fetchUser();
  const sortOption =
    user?.settings?.selectedFileSortingOption ||
    PROJECT_FILE_SORTING.OPTIONS.NEWEST;
  return sortOption === PROJECT_FILE_SORTING.OPTIONS.FILE_NAME
    ? sortFilesByName(files)
    : sortFilesByDate(files);
}

function splitFiles(files) {
  return files.reduce(
    ({ dummyFiles, serverFiles }, file) => {
      return {
        dummyFiles: file.isDummy ? [...dummyFiles, file] : dummyFiles,
        serverFiles: !file.isDummy ? [...serverFiles, file] : serverFiles,
      };
    },
    {
      dummyFiles: [],
      serverFiles: [],
    }
  );
}

function sortFilesByDate(files) {
  return orderBy(files, "latestVersionCreatedAt", "desc");
}

function sortFilesByName(files) {
  const user = authenticationService.fetchUser();
  const collator = new Intl.Collator((user && user.language) || [], {
    numeric: true,
    sensitivity: "base",
  });
  return files.sort((f1, f2) => collator.compare(f1.name, f2.name));
}
