import toastService from "@supporting/services/toast";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

const inviteReviewers = async (preparedData, step, teamId) => {
  const reviewerEmails = preparedData.reviewers.map(({ email }) => email);

  reviewerEmails.forEach((reviewerEmail) => {
    const isReviewerAlreadyInvited = step.reviewers.find(
      (reviewer) => reviewer.email === reviewerEmail
    );

    analytics.track(
      isReviewerAlreadyInvited
        ? analytics.ACTION.RE_INVITED
        : analytics.ACTION.INVITED,
      analytics.CATEGORY.REVIEWER,
      {
        addedPersonalMessage: Boolean(preparedData.message),
        invitedEmail: reviewerEmail,
        teamId,
        [`emailNotification${
          preparedData.notifyEmail ? "Enabled" : "Disabled"
        }`]: true,
        [`requireReviewDecision${
          preparedData.requestDecision ? "Enabled" : "Disabled"
        }`]: true,
        label: `REQUIRE_REVIEW_DECISION_${
          preparedData.requestDecision ? "ENABLED" : "DISABLED"
        }`,
      }
    );
  });

  healthMetrics.trackStart("workflow.invite-reviewers");

  try {
    await stepService.addReviewers(
      step.id,
      reviewerEmails,
      preparedData.message,
      preparedData.notifyEmail,
      preparedData.requestDecision
    );
    healthMetrics.trackSuccess("workflow.invite-reviewers");
    return toastService.sendToast({
      title: `${
        reviewerEmails.length === 1
          ? "REVIEWER.ADD.SUCCESS-SINGULAR"
          : "REVIEWER.ADD.SUCCESS-PLURAL"
      }.TITLE`,
      preset: toastService.PRESETS().SUCCESS,
    });
  } catch (error) {
    healthMetrics.trackFailure("workflow.invite-reviewers", error);
    errorHandlerService.handleError(error);
  }
};

const addReviewersToProjectTemplate = async (
  preparedData,
  stepTemplate,
  teamId
) => {
  const reviewerEmails = preparedData.reviewers.map(({ email }) => email);

  analytics.track(
    analytics.ACTION.ADDED,
    analytics.CATEGORY.STEP_TEMPLATE_REVIEWER,
    {
      invitedEmail: reviewerEmails,
      teamId,
      [`requireReviewDecision${
        preparedData.requestDecision ? "Enabled" : "Disabled"
      }`]: true,
      label: `REQUIRE_REVIEW_DECISION_${
        preparedData.requestDecision ? "ENABLED" : "DISABLED"
      }`,
    }
  );
  try {
    await projectTemplateService.addReviewers(
      stepTemplate.id,
      reviewerEmails,
      preparedData.requestDecision
    );

    return toastService.sendToast({
      title: "REVIEWER.INVITE.PROJECT-TEMPLATE.TOAST.TITLE",
      body: "REVIEWER.INVITE.PROJECT-TEMPLATE.TOAST.BODY",
      preset: toastService.PRESETS().SUCCESS,
      translationVariables: {
        body: {
          addedMembers: reviewerEmails.join(", "),
        },
      },
    });
  } catch (error) {
    errorHandlerService.handleError(error);
  }
};

const addReviewers = async ({
  preparedData,
  isProjectTemplate,
  step,
  teamId,
}) => {
  healthMetrics.trackAttempt("workflow.invite-reviewers");
  return !isProjectTemplate
    ? await inviteReviewers(preparedData, step, teamId)
    : await addReviewersToProjectTemplate(preparedData, step, teamId);
};

export default {
  addReviewers,
};
