import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { Image, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import NoSearchResults from "@assets/img/sprite-source/sprite--nosearchresults.png";
import NoSearchResults2x from "@assets/img/sprite-source/sprite--nosearchresults@2x.png";

const EMPTY_STATE_TITLES = {
  PROJECT: "SEARCH.EMPTY_STATE.TITLE_PROJECTS",
  FILE: "SEARCH.EMPTY_STATE.TITLE_FILES",
  COMMENT: "SEARCH.EMPTY_STATE.TITLE_COMMENTS",
};

const EmptyStateComponent = ({ resource }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      px={2}
      py={4}
      gap={3}
      data-testid="empty-state-component-root"
    >
      <Box>
        <Image
          src={NoSearchResults}
          srcSet={`${NoSearchResults2x} 2x`}
          alt="No search results"
          width="100%"
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center"
        gap={1}
      >
        <Text
          variant="textLg"
          color="primaryV2.main"
          fontSize="textXl.fontSize"
          fontWeight="fontWeight.regular"
          lineHeight={FSTGTypography.lineHeight_1_0}
          translate={
            !resource ? "NO_SEARCH_RESULTS.TITLE" : EMPTY_STATE_TITLES[resource]
          }
        />
        <Text
          variant="textSm"
          color="grey.500"
          fontSize="textMd.fontSize"
          lineHeight={FSTGTypography.lineHeight_1_1}
          translate="SEARCH.EMPTY_STATE.DESCRIPTION"
        />
      </Box>
    </Box>
  );
};

EmptyStateComponent.propTypes = {
  resource: PropTypes.string,
};

EmptyStateComponent.defaultProps = {
  resource: null,
};

export default EmptyStateComponent;
