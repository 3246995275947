import { Box } from "@mui/material";

import Title from "../../components/Admin/Title";

export default function AdminDashboard() {
  return (
    <Box data-testid="page-dashboard">
      <Title>Please select and option from the sidebar</Title>
    </Box>
  );
}
