import PropTypes from "prop-types";

import WistiaDialog from "./WistiaDialog/WistiaDialog";
import YoutubeDialog from "./YoutubeDialog/YoutubeDialog";

function VideoDialog({ variant, ...restProps }) {
  return variant === "wistia" ? (
    <WistiaDialog {...restProps} />
  ) : (
    <YoutubeDialog {...restProps} />
  );
}

VideoDialog.propTypes = {
  variant: PropTypes.oneOf(["wistia", "youtube"]),
};

VideoDialog.defaultProps = {
  variant: "wistia",
};

export default VideoDialog;
