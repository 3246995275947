import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { ButtonBase } from "@mui/material";
import clsx from "clsx";
import noop from "lodash/noop";

import { Text, Box } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

import FileUploadIcon from "@assets/img/icons/fileUploadOutlined.svg";

import classes from "./UploadFromDevice.module.css";

function UploadFromDevice({ open, isDragActive, isDisabled }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  let buttonText = t("Upload");

  if (isMobile) {
    buttonText = t("UPLOAD_DIALOG_MOBILE.CTA");
  } else if (isDragActive) {
    buttonText = t("UPLOAD_DIALOG.DRAG");
  } else {
    buttonText = t("UPLOAD_DIALOG.CTA");
  }

  return (
    <Box flexGrow={2}>
      <ButtonBase
        variant="text"
        data-testid="upload-panel-from-local-machine"
        onClick={open}
        disabled={isDisabled}
        className={clsx(classes.uploadButton, {
          [classes.uploadButtonWithDrag]: isDragActive,
        })}
        TouchRippleProps={{ classes: { root: classes.buttonRipple } }}
      >
        <FileUploadIcon />
        <Text
          variant="body1"
          whiteSpace="initial"
          sx={{
            color: isDisabled ? "text.disabled" : "text.primary",
          }}
        >
          <Trans>{buttonText}</Trans>
        </Text>
      </ButtonBase>
    </Box>
  );
}

UploadFromDevice.propTypes = {
  open: PropTypes.func,
  isDragActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

UploadFromDevice.defaultProps = {
  open: noop,
};

export default UploadFromDevice;
