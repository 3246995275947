export const METRIC_TYPES = {
  PROJECTS_CREATED: {
    name: "INSIGHTS.METRICS.PROJECTS.TITLE",
    tooltip: "INSIGHTS.METRICS.PROJECTS.TOOLTIP",
  },
  VERSIONS_UPLOADED: {
    name: "INSIGHTS.METRICS.VERSIONS.TITLE",
    tooltip: "INSIGHTS.METRICS.VERSIONS.TOOTLIP",
  },
  REVIEWS_STARTED: {
    name: "INSIGHTS.METRICS.REVIEWS.TITLE",
    tooltip: "INSIGHTS.METRICS.REVIEWS.TOOLTIP",
  },
  REVIEW_DECISION_SUBMITTED: {
    name: "INSIGHTS.METRICS.REVIEW_DECISION.TITLE",
    tooltip: "INSIGHTS.METRICS.REVIEW_DECISION.TOOLTIP",
  },
  COMMENTS_AVERAGE: {
    name: "INSIGHTS.METRICS.COMMENTS.TITLE",
    tooltip: "INSIGHTS.METRICS.COMMENTS.TOOLTIP",
  },
  AVERAGE_VERSIONS_PER_FILE: {
    isEarlyAccess: true,
    name: "ADVANCED_INSIGHTS.OVERVIEW.METRIC_AVG_VERSIONS",
  },
  ALL_REVIEWERS: {
    isEarlyAccess: true,
    name: "ADVANCED_INSIGHTS.REVIEWERS.METRICS_ALL_REVIEWERS",
  },
};
