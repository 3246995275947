import { useCallback, useState, useEffect } from "react";

import {
  Switch,
  FormControlLabel,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import userService from "@supporting/services/userService";

import { Box } from "@shared/UIKit";

import backend from "@shared/services/backendClient";
import { instance as logger } from "@shared/services/logger";

function DebugTab() {
  const [user, setUser] = useState(authenticationService.fetchUser());
  const [firstRender, setFirstRender] = useState(true);
  const [unit, setUnit] = useState("minute");
  const [projectId, setProjectId] = useState();

  useEffect(() => {
    async function updateUserSettings() {
      await userService.update({ settings: user.settings });
    }

    if (firstRender) {
      setFirstRender(false);
    } else {
      updateUserSettings();
    }
  }, [user, firstRender]);

  const handleSelectChange = (event) => setUnit(event.target.value);
  const handleProjectIdChange = (event) => setProjectId(event.target.value);

  const updateBetaSettings = useCallback(() => {
    const currentUser = { ...user };
    currentUser.settings.isBeta = !currentUser.settings.isBeta;
    setUser(currentUser);
  }, [user]);

  const testLogger = () => {
    logger.info("browser:test:tag", "test info", { test: "meta data" });
    logger.warn("browser:test:tag", "test warn", { test: "meta data" });
    logger.error("browser:test:tag", "test error", {
      test: "meta data",
      error: new Error("test"),
    });
  };

  const handleCronJob = async (job) => {
    const data = { job };

    switch (job) {
      case "due-date-passed":
        data.projectId = projectId;
        break;
      case "project-no-activity":
        data.projectId = projectId;
        data.unit = unit;
        break;
      case "review-submitted-decision":
        data.projectId = projectId;
        break;
    }

    await backend.post("/admin/cron-execute", data);
    toastService.sendToast({
      title: "Cron Job Triggered",
      preset: toastService.PRESETS().SUCCESS,
    });
  };

  return (
    <Box
      data-testid="content"
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      gap={3}
    >
      <Card data-testid="user-settings-debug-tab">
        <CardHeader title="Debug Options" />
        <CardContent>
          <Button onClick={testLogger} color="primary" variant="contained">
            Test Logger
          </Button>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={user.settings.isBeta}
                  onChange={updateBetaSettings}
                />
              }
              label="Beta Mode Enabled"
            />
          </Box>
        </CardContent>
      </Card>
      <Card data-testid="automations-tab">
        <CardHeader title="Automations" />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 4,
          }}
        >
          <TextField
            data-testid="automation-projectId"
            label="Project Id"
            value={projectId}
            inputProps={{
              "data-testid": "automation-projectId-input",
            }}
            onChange={handleProjectIdChange}
          />
          <Box display="flex" gap={3}>
            <Select
              data-testid="automation-project-no-activity-unit"
              value={unit}
              label="Unit"
              onChange={handleSelectChange}
            >
              <MenuItem value="second" data-testid="second">
                Second
              </MenuItem>
              <MenuItem value="minute" data-testid="minute">
                Minute
              </MenuItem>
              <MenuItem value="day" data-testid="day">
                Day
              </MenuItem>
            </Select>
            <Button
              onClick={() => handleCronJob("project-no-activity")}
              color="primary"
              variant="contained"
              data-testid="project-no-activity"
            >
              Project No Activity
            </Button>
          </Box>
          <Button
            onClick={() => handleCronJob("due-date-passed")}
            color="primary"
            variant="contained"
            data-testid="due-date-passed"
          >
            Due Date Reached
          </Button>
          <Button
            onClick={() => handleCronJob("review-submitted-decision")}
            color="primary"
            variant="contained"
            data-testid="review-submitted-decision"
          >
            Review Submitted Decision
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default DebugTab;
