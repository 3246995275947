import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import AdobePluginGoogleAuthButton from "@integrations/components/AdobePluginGoogleAuthButton/AdobePluginGoogleAuthButton";
import { Box, Divider } from "@mui/material";
import useGoogleAuth from "@supporting/hooks/useGoogleAuth";
import authenticationService from "@supporting/services/authentication";

import { Text } from "@shared/UIKit";

function GoogleAuth({
  testId,
  text,
  authState,
  showButtonOnly,
  shareSessionStepId,
  isAdobePlugin,
  adobePluginSessionId,
  adobePluginText,
}) {
  const ref = useRef();

  const { loaded } = useGoogleAuth(
    text,
    ref,
    shareSessionStepId,
    adobePluginSessionId
  );
  useEffect(() => {
    authenticationService.setAuthenticationState(authState);
  }, [authState]);
  return (
    <Box gap={2} mt={2} display="flex" flexDirection="column">
      {!showButtonOnly && loaded && (
        <Divider>
          <Text color="grey.600" translate="CORE.OR" />
        </Divider>
      )}

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="row"
        data-testid={testId}
      >
        {isAdobePlugin ? (
          <AdobePluginGoogleAuthButton
            adobePluginSessionId={adobePluginSessionId}
            shareSessionStepId={shareSessionStepId}
            testId="adobe-plugin-google-auth-button"
            adobePluginText={adobePluginText}
          />
        ) : (
          <Box ref={ref} />
        )}
      </Box>
    </Box>
  );
}

GoogleAuth.propTypes = {
  text: PropTypes.string,
  testId: PropTypes.string,
  authState: PropTypes.object,
  showButtonOnly: PropTypes.bool,
  shareSessionStepId: PropTypes.string,
  isAdobePlugin: PropTypes.bool,
  adobePluginSessionId: PropTypes.string,
  adobePluginText: PropTypes.string,
};
GoogleAuth.defaultProps = {
  testId: "sign-in-google",
  text: "signin_with",
  authState: {},
  showButtonOnly: false,
  shareSessionStepId: undefined,
  isAdobePlugin: false,
  adobePluginSessionId: "",
  adobePluginText: undefined,
};

export default GoogleAuth;
