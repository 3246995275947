import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Close, DeleteOutlined, GetAppOutlined } from "@mui/icons-material";
import { IconButton, Checkbox, FormControlLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import toastService from "@supporting/services/toast";
import { useSelectedFiles } from "@workflow/components/SelectFilesContext";
import dashboardSectionFilesCache from "@workflow/services/dashboardSectionFilesCache";
import { instance as fileService } from "@workflow/services/fileService";
import projectService from "@workflow/services/projectService";

import { Box, ConfirmationDialog, Text, Tooltip } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  floatingBar: {
    backgroundColor: theme.color.gray[800],
    color: theme.color.white,
    borderRadius: 10,
  },
  floatingBarActions: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 3,
  },
  selectAllCheckbox: {
    marginRight: 35,
    "& > span": {
      fontSize: FSTGTypography.fontSize_1_3,
      color: theme.color.white,
      fontWeight: FSTGTypography.fontWeightMedium,
    },
  },
  selectedFileText: {
    fontSize: FSTGTypography.fontSize_1_3,
    color: theme.color.white,
    paddingRight: 8,
  },
}));

function FloatingSelectionBar({ projectId }) {
  const classes = useStyles();
  const {
    selectAll,
    toggleSelectAll,
    exitSelectState,
    selectedFiles,
    excludedFiles,
  } = useSelectedFiles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [fileCount, setFileCount] = useState(0);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [projectFileCount, setProjectFileCount] = useState(0);

  useEffect(() => {
    const fetchFileCount = () => {
      const count = dashboardSectionFilesCache.getProjectFileCount(projectId);
      setProjectFileCount(count);
    };
    fetchFileCount();
  }, [projectId]);

  useEffect(() => {
    if (selectAll) {
      setFileCount(projectFileCount - excludedFiles.length);
    } else {
      setFileCount(selectedFiles.length);
    }
  }, [selectAll, projectFileCount, excludedFiles, selectedFiles]);

  useEffect(() => {
    if (!fileCount && selectAll) {
      toggleSelectAll(false);
    }
  }, [fileCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectAll = useCallback(
    ({ target }) => {
      analytics.track(
        analytics.ACTION[selectAll ? "DESELECTED" : "SELECTED"],
        analytics.CATEGORY.FILES,
        {
          page: "project-dashboard",
          view: "grid-view",
          fileSelection: "all-files",
        }
      );
      toggleSelectAll(target.checked);
    },
    [selectAll, toggleSelectAll]
  );

  const openDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.DASHBOARD_BULK_DELETE_FILES_BUTTON,
      {
        page: "project-dashboard",
        view: "grid-view",
      }
    );
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
    analytics.track(
      analytics.ACTION.CANCELLED,
      analytics.CATEGORY.DELETE_LATEST_FILE_VERSIONS,
      {
        page: "project-dashboard",
        view: "grid-view",
        projectId,
      }
    );
  };

  const handleFileDownload = async () => {
    if (fileCount) {
      await projectService.bulkDownloadProjectFiles({
        projectId,
        includedFileIds: selectedFiles,
        excludedFileIds: excludedFiles,
        allFiles: selectAll,
      });
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.BULK_DOWNLOAD_FILES_BUTTON,
        {
          page: "project-dashboard",
          view: "grid-view",
        }
      );
      exitSelectState();
    }
  };

  const handleBulkDelete = async () => {
    if (fileCount) {
      exitSelectState();
      toastService.sendToast({
        title: "BULK_DELETE.IN_PROGRESS_TOAST.TITLE",
        body: "BULK_DELETE.IN_PROGRESS_TOAST.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: "BULK_DELETE.IN_PROGRESS_TOAST.DESCRIPTION",
        },
      });
      await fileService.bulkRemoveFiles(projectId, {
        includedFileIds: selectedFiles,
        excludedFileIds: excludedFiles,
        allFiles: selectAll,
      });
      toastService.sendToast({
        title: "BULK_DELETE.SUCCESS_TOAST.TITLE",
        body: "BULK_DELETE.SUCCESS_TOAST.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: "BULK_DELETE.SUCCESS_TOAST.DESCRIPTION",
        },
      });
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.DELETE_LATEST_FILE_VERSIONS,
        {
          page: "project-dashboard",
          view: "grid-view",
          projectId,
        }
      );
    }
  };

  const exitFileSelectionState = () => {
    analytics.track(
      analytics.ACTION.CLOSED,
      analytics.CATEGORY.BULK_ACTION_TOOLBAR,
      {
        page: "project-dashboard",
        view: "grid-view",
      }
    );
    exitSelectState();
  };

  return (
    <Box
      position="absolute"
      bottom={{ xs: 60, md: 20 }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      data-color-scheme="dark"
    >
      <Box
        zIndex={50}
        px={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        className={classes.floatingBar}
        data-testid="floating-selection-bar"
      >
        <Box>
          <FormControlLabel
            label={t("BULK_DOWNLOAD.SELECT_ALL_FILES")}
            labelPlacement="end"
            classes={{ root: classes.selectAllCheckbox }}
            control={
              <Checkbox
                color="primaryV2"
                indeterminate={Boolean(excludedFiles.length)}
                checked={selectAll || selectedFiles.length === projectFileCount}
                onChange={handleSelectAll}
                data-testid="file-select-all-checkbox"
              />
            }
          />
        </Box>
        <Box className={classes.floatingBarActions}>
          {!isMobile && (
            <Text
              className={classes.selectedFileText}
              data-testid="selected-files-count"
            >
              {t("BULK_DOWNLOAD.FILES_SELECTED", { fileCount })}
            </Text>
          )}
          <Tooltip title={t("BULK_DOWNLOAD.BUTTON.TOOLTIP")}>
            <IconButton
              onClick={handleFileDownload}
              data-testid="floating-selection-bar-download-button"
              size="small"
              color="light"
              edge="start"
            >
              <GetAppOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("BULK_DELETE.TOOLTIP")}>
            <IconButton
              onClick={openDeleteConfirmation}
              data-testid="floating-selection-bar-delete-button"
              size="small"
              color="light"
              edge="start"
            >
              <DeleteOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>

          <IconButton
            onClick={exitFileSelectionState}
            data-testid="floating-selection-bar-exit-button"
            size="small"
            color="light"
            edge="start"
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
      <ConfirmationDialog
        isOpen={isDeleteConfirmationOpen}
        title={t("BULK_DELETE.DIALOG.TITLE")}
        description={t("BULK_DELETE.DIALOG.DESCRIPTION")}
        confirmLabel={t("BULK_DELETE.DIALOG.CTA")}
        closeDialog={closeDeleteConfirmation}
        answer={handleBulkDelete}
      />
    </Box>
  );
}

FloatingSelectionBar.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default FloatingSelectionBar;
