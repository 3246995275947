import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import authenticationService from "@supporting/services/authentication";

import { Box, LoadingSpinner } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";

function Logout() {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const logout = async () => {
      await authenticationService.logout();
      navigate(APP_ROUTES.LOGIN.path, { state: location.state });
    };
    logout();
  }, [location.state, navigate]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
    >
      <LoadingSpinner />
    </Box>
  );
}

export default Logout;
