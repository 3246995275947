import PropTypes from "prop-types";
import { ReactElement } from "react";

import { OpenInNewOutlined } from "@mui/icons-material";
import {
  Dialog as MuiDialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";
import clsx from "clsx";

import { Box } from "@shared/UIKit";

import DialogCloseButton from "./DialogCloseButton";

/**
 * UIKit Dialog Component
 * @description UIKit Dialog Component that has custom size, background, backdrop and actions.
 * It is a flexible component that can support different classes and alternative sub-components.
 * This component extends the Material UI Dialog component.
 * @param {object} props - Component props
 * @param {ReactElement} [props.content] [Required] - The content of the dialog
 * @param {Function} [props.cancel] [Required] - The function to be called when the dialog is cancelled or closed.
 * @param {ReactElement} [props.actions] [Optional] - Custom actions content that will be displayed on the dialog.
 * @param {boolean} [props.isOpen] [Optional] - A boolean to indicate if the dialog is open or not.
 * @param {boolean} [props.showCloseButton] [Optional] - A boolean to indicate if the dialog should have a close button.
 * @param {ReactElement} [props.title] [Optional] - Title of the dialog.
 * @param {boolean} [props.useDefaultBackground] [Optional] - A boolean to indicate if the dialog should use the default background.
 * @param {string} [props.maxWidth] [Optional] - The max width of the dialog.
 * The options are `xs`, `sm`, `md`, `lg`, `video`, `auto`, `fullscreen`.
 * Default is `md`.
 * @param {{link: string, text:string}} [props.helpLink] [Optional] - Help link object
 * @param {string} [props.helpLink.text] [Optional] - Help link text to be displayed
 * @param {string} [props.helpLink.link] [Optional] - Help link to be displayed
 * @param {string} [props.contentClassName] [Optional] - The class name of the content for styling.
 * @param {string} [props.actionClassName] [Optional] - The class name of the action for styling.
 * @param {import("@mui/material").DialogActionsProps} props.actionProps [Optional] - The props of the DialogActions component.
 * @param {boolean} props.disableBackdropClick [Optional] - If true, the backdrop click will be disabled.
 * @param {string} [props.customPaperClasses] [Optional] - The class name of the paper for styling.
 * @param {string} [props.titleClassName] [Optional] - The class name of the title for styling.
 * @param {ReactElement} [props.children] [Optional] - The children of the dialog.
 * @returns {ReactElement} - React component
 */
function Dialog({
  content,
  actions,
  cancel,
  isOpen,
  showCloseButton,
  contentClassName,
  actionProps,
  useDefaultBackground,
  title,
  titleProps,
  helpLink,
  customPaperClasses,
  children,
  disableBackdropClick,
  contentProps,
  ...restProps
}) {
  const { PaperProps, maxWidth, ...rest } = restProps;

  const handleClose = (_event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    }
    cancel();
  };

  return (
    <MuiDialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        className: clsx(customPaperClasses, {
          noDefaultBackground: !useDefaultBackground,
        }),
        ...PaperProps,
      }}
      maxWidth={maxWidth}
      fullWidth={Boolean(maxWidth)}
      {...rest}
    >
      {title && (
        <DialogTitle
          variant="textXl"
          fontWeight="fontWeight.bold"
          {...titleProps}
        >
          {title}
        </DialogTitle>
      )}
      {helpLink && (
        <Box position="absolute" top={10} left={5}>
          <Button
            href={helpLink.link}
            target="_blank"
            size="small"
            color="default-light"
            variant="text"
            startIcon={<OpenInNewOutlined fontSize="inherit" />}
          >
            {helpLink.text}
          </Button>
        </Box>
      )}
      {showCloseButton && <DialogCloseButton onClick={handleClose} />}
      {(content || children) && (
        <DialogContent className={contentClassName} {...contentProps}>
          {children ?? content}
        </DialogContent>
      )}
      {actions && <DialogActions {...actionProps}>{actions}</DialogActions>}
    </MuiDialog>
  );
}

const contentOrChildrenRequired = (props, _propName, componentName) => {
  /* istanbul ignore next */
  if (props.content && props.children) {
    return new Error(
      `Both of props 'children' or 'content' cannot be specified at the same time in '${componentName}'.`
    );
  }
  /* istanbul ignore next */
  if (!props.content && !props.children) {
    return new Error(
      `One of props 'children' or 'content' was not specified in '${componentName}'.`
    );
  }
};
Dialog.propTypes = {
  actions: PropTypes.node,
  contentClassName: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  actionProps: PropTypes.object,
  cancel: PropTypes.func.isRequired,
  showCloseButton: PropTypes.bool,
  isOpen: PropTypes.bool,
  useDefaultBackground: PropTypes.bool,
  title: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  titleProps: PropTypes.object,
  helpLink: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  customPaperClasses: PropTypes.string,
  content: contentOrChildrenRequired,
  children: contentOrChildrenRequired,
  disableBackdropClick: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  contentProps: PropTypes.object,
};

Dialog.defaultProps = {
  actions: null,
  isOpen: false,
  useDefaultBackground: true,
  showCloseButton: true,
  contentClassName: undefined,
  actionProps: {},
  title: null,
  titleProps: {},
  helpLink: null,
  customPaperClasses: "",
  content: null,
  children: null,
  disableBackdropClick: false,
  contentProps: {},
};

export default Dialog;
