import PropTypes from "prop-types";
import { createElement } from "react";

const escapeRegExpChars = (searchTerms) =>
  searchTerms.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");

export default function Highlight({
  searchTerms,
  highlightClass,
  caseSensitive,
  children,
}) {
  if (!searchTerms) {
    return children;
  }

  const regex = new RegExp(
    `(${escapeRegExpChars(searchTerms)})`,
    caseSensitive ? "g" : "gi"
  );

  const parts = children.split(regex);
  const results = parts.map((part, index) =>
    part.toLowerCase() === searchTerms.toLowerCase()
      ? /* eslint-disable-next-line react/forbid-elements */
        createElement("span", { className: highlightClass, key: index }, part)
      : part
  );
  return results;
}

Highlight.propTypes = {
  children: PropTypes.string,
  searchTerms: PropTypes.string,
  highlightClass: PropTypes.string,
  caseSensitive: PropTypes.bool,
};

Highlight.defaultProps = {
  children: "",
  searchTerms: "",
  highlightClass: "highlight",
  caseSensitive: false,
};
