import { useStore } from "zustand";
import { createStore } from "zustand/vanilla";

import eventService, { EVENT_NAMES } from "./eventService";

const store = createStore((set, get) => ({
  title: "Filestage",
  showNotificationIndicator: false,
  getTitle() {
    return `${get().showNotificationIndicator ? "● " : ""}${get().title}`;
  },
  setTitle(newTitle) {
    set((state) => ({ ...state, title: `${newTitle} | Filestage` }));
  },
}));

eventService.addListener(
  EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.ENABLED,

  () => {
    store.setState((state) => ({ ...state, showNotificationIndicator: true }));
  }
);
eventService.addListener(
  EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.DISABLED,
  () => {
    store.setState((state) => ({ ...state, showNotificationIndicator: false }));
  }
);

export default {
  getTitle: store.getState().getTitle,
  setTitle: store.getState().setTitle,
};

export const usePageTitle = (selector) => useStore(store, selector);
