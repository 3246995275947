import { queryClient } from "@shared/react-query";
import { useCallback, useState, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useNavigate } from "react-router-dom";

import userService from "@supporting/services/userService";

import { SEARCH_KEYS } from "@shared/query-keys";
import { instance as analytics } from "@shared/services/analytics";

import { getNextMovement, getPreviousMovement } from "./dialogSearchMovement";

const CHIPS = ["PROJECT", "FILE", "COMMENT"];

const initialValues = {
  searchQuery: "",
  isSearchInProgress: false,
  isSearchEmpty: false,
  selectedSearchResult: { type: "input", index: null },
  projectSearchResults: undefined,
  fileSearchResults: undefined,
  commentSearchResults: undefined,
  resourceSelected: "ALL",
};

const TABS = ["recent_searches", "results_overview", "filtered_results"];

const getRecentSearchItem = (item) => {
  const recentSearches = queryClient.getQueryData(SEARCH_KEYS.recent());
  if (!recentSearches) {
    return null;
  }
  return recentSearches[item.index];
};

export const useSearchDialog = ({ closeSearchDialog }) => {
  //  combined state here - due to frequent changes in more than one state at a time
  const [state, setState] = useState(() => ({
    ...initialValues,
  }));
  const navigate = useNavigate();

  const [tabSelected, setTabSelected] = useState(0);

  const updateState = useCallback(
    (newState) => {
      setState((prevState) => ({ ...prevState, ...newState }));
    },
    [setState]
  );

  const searchInput = useRef("");

  const hasResults =
    !state.isSearchEmpty &&
    (state.projectSearchResults?.topResults.length > 0 ||
      state.fileSearchResults?.topResults.length > 0 ||
      state.commentSearchResults?.topResults.length > 0);

  const hasMoreResults =
    state?.projectSearchResults?.topResults.length >= 10 ||
    state?.fileSearchResults?.topResults.length >= 10 ||
    state?.commentSearchResults?.topResults.length >= 10;

  const clearSearch = useCallback(() => {
    searchInput.current = "";
    updateState({
      searchQuery: "",
      projectSearchResults: undefined,
      fileSearchResults: undefined,
      commentSearchResults: undefined,
      isSearchInProgress: false,
      isSearchEmpty: false,
    });

    if (tabSelected === 1) {
      setTabSelected(0);
    }
  }, [tabSelected, updateState]);

  const handleSearchUpdate = useCallback(
    ({ target: { value, forceNewSearch } }) => {
      state.searchQuery = value;
      state.resourceSelected = forceNewSearch ? "ALL" : state.resourceSelected;

      searchInput.current = value;

      if (value.trim().length >= 3 && tabSelected === 0) {
        setTabSelected(1);
      }

      if (value.trim().length === 0 && tabSelected === 1) {
        setTabSelected(0);
      }

      updateState({
        searchQuery: value,
        selectedSearchResult: { type: "input", index: 0 },
        ...(forceNewSearch && {
          resourceSelected: "ALL",
          projectSearchResults: undefined,
          fileSearchResults: undefined,
          commentSearchResults: undefined,
        }),
      });

      if (!value) {
        clearSearch();
        return;
      }

      if (value.trim().length < 3) {
        return;
      }
    },
    [clearSearch, state, tabSelected, updateState]
  );

  const goToSearchResult = useCallback(
    async (resourceLink, resultType, $event, page, resource) => {
      switch (resultType) {
        case "project":
          analytics.track(
            analytics.ACTION.OPENED_PROJECT,
            analytics.CATEGORY.SEARCH_RESULT,
            { page }
          );
          break;
        case "file":
          analytics.track(
            analytics.ACTION.OPENED_FILE,
            analytics.CATEGORY.SEARCH_RESULT,
            { page }
          );
          break;
        case "comment":
          analytics.track(
            analytics.ACTION.OPENED_COMMENT,
            analytics.CATEGORY.SEARCH_RESULT,
            { page }
          );
          break;
      }

      if ($event.metaKey || $event.ctrlKey) {
        return;
      }

      if (page !== "recent_searches") {
        await userService.updateRecentSearches(resource);
      }

      const url = new URL(resourceLink);
      navigate(url.pathname);
      closeSearchDialog();
    },
    [closeSearchDialog, navigate]
  );

  const goToSelectedSearchResult = useCallback(
    // eslint-disable-next-line complexity
    (event) => {
      switch (state.selectedSearchResult.type) {
        case "recent_searches": {
          const item = getRecentSearchItem(state.selectedSearchResult);
          if (!item) {
            return null;
          }

          goToSearchResult(
            item.resourceLinkUrl,
            item.resource.resourceType.toLowerCase(),
            event,
            "recent_searches",
            item.resource
          );
          break;
        }
        case "filter_chips": {
          if (
            state.resourceSelected === CHIPS[state.selectedSearchResult.index]
          ) {
            updateState({ resourceSelected: "ALL" });
            setTabSelected(1);
            return;
          }

          updateState({
            resourceSelected: CHIPS[state.selectedSearchResult.index],
          });
          setTabSelected(2);
          break;
        }
        case "project": {
          if (
            state.selectedSearchResult.index ===
            state.projectSearchResults.topResults.length
          ) {
            updateState({
              selectedSearchResult: { type: "input", index: 0 },
              resourceSelected: "PROJECT",
            });
            setTabSelected(2);
            return;
          }

          const { resourceLinkUrl, resource } =
            state.projectSearchResults.topResults[
              state.selectedSearchResult.index
            ];

          goToSearchResult(
            resourceLinkUrl,
            state.selectedSearchResult.type,
            event,
            TABS[tabSelected],
            resource
          );
          break;
        }
        case "file": {
          if (
            state.selectedSearchResult.index ===
            state.fileSearchResults.topResults.length
          ) {
            updateState({
              selectedSearchResult: { type: "input", index: 0 },
              resourceSelected: "FILE",
            });
            setTabSelected(2);
            return;
          }

          const { resourceLinkUrl, resource } =
            state.fileSearchResults.topResults[
              state.selectedSearchResult.index
            ];
          goToSearchResult(
            resourceLinkUrl,
            state.selectedSearchResult.type,
            event,
            TABS[tabSelected],
            resource
          );
          break;
        }
        case "comment": {
          if (
            state.selectedSearchResult.index ===
            state.commentSearchResults.topResults.length
          ) {
            updateState({
              selectedSearchResult: { type: "input", index: 0 },
              resourceSelected: "COMMENT",
            });
            setTabSelected(2);
            return;
          }

          const { resourceLinkUrl, resource } =
            state.commentSearchResults.topResults[
              state.selectedSearchResult.index
            ];
          goToSearchResult(
            resourceLinkUrl,
            state.selectedSearchResult.type,
            event,
            TABS[tabSelected],
            resource
          );
          break;
        }
      }
    },
    [
      goToSearchResult,
      state.commentSearchResults?.topResults,
      state.fileSearchResults?.topResults,
      state.projectSearchResults?.topResults,
      state.resourceSelected,
      state.selectedSearchResult,
      tabSelected,
      updateState,
    ]
  );

  const navigateDown = () => {
    const [sectionToBeSelected, sectionToBeSelectedIndex] = getNextMovement(
      state,
      tabSelected
    );

    updateState({
      selectedSearchResult: {
        type: sectionToBeSelected,
        index: sectionToBeSelectedIndex,
      },
    });
  };

  const navigateUp = () => {
    const [sectionToBeSelected, sectionToBeSelectedIndex] =
      getPreviousMovement(state);

    updateState({
      selectedSearchResult: {
        type: sectionToBeSelected,
        index: sectionToBeSelectedIndex,
      },
    });
  };

  useHotkeys("down", navigateDown);
  useHotkeys("up", navigateUp);
  useHotkeys("enter", goToSelectedSearchResult);

  return {
    onSearchChange: handleSearchUpdate,
    searchInput,
    clearSearch,
    navigateDown,
    navigateUp,
    goToSelectedSearchResult,
    goToSearchResult,
    state,
    setState,
    updateState,
    hasMoreResults,
    hasResults,
    tabSelected,
    setTabSelected,
  };
};
