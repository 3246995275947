import { Helmet } from "react-helmet";

import { usePageTitle } from "@shared/services/pageTitleService";

const PageTitle = () => {
  const pageTitle = usePageTitle((state) => state.getTitle());

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default PageTitle;
