import mondaySdk from "monday-sdk-js";

import isInIframe from "@shared/helpers/isInIframe";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";

const monday = mondaySdk();

const getContext = async () => {
  const settings = await monday.get("context");

  const {
    itemId,
    boardId,
    user: { id: mondayUserId },
    account: { id: mondayAccountId },
    instanceType,
    workspaceId,
    app: { id: appId, clientId },
  } = settings.data;

  return {
    itemId,
    boardId,
    mondayUserId: Number(mondayUserId),
    mondayAccountId: Number(mondayAccountId),
    instanceType,
    workspaceId,
    clientId,
    appId: Number(appId),
  };
};

const getMondayAppParams = (locationState) => {
  const searchParams = new URLSearchParams(window.location.search);
  const searchEntries = Object.fromEntries(searchParams.entries());

  return {
    ...locationState,
    ...searchEntries,
  };
};

const checkIfInMondayApp = (locationState) => {
  const appParams = getMondayAppParams(locationState);

  return Boolean(
    isInIframe() && (appParams["itemViewId"] || appParams["boardViewId"])
  );
};

const linkItemOrBoardToProject = async (teamId, projectId) => {
  const {
    itemId,
    boardId,
    mondayUserId,
    mondayAccountId,
    instanceType,
    workspaceId,
    appId,
    clientId,
  } = await getContext();

  return backend.post(`/integrations/monday-app/instance`, {
    projectId,
    teamId,
    itemId,
    boardId,
    mondayUserId,
    mondayAccountId,
    instanceType,
    workspaceId,
    clientId,
    appId,
  });
};

const getConnectedInstance = async (teamId) => {
  const {
    itemId,
    boardId,
    mondayUserId,
    mondayAccountId,
    instanceType,
    workspaceId,
    clientId,
    appId,
  } = await getContext();

  return backend.get(`/integrations/monday-app/instance`, {
    teamId,
    itemId,
    boardId,
    mondayUserId,
    mondayAccountId,
    instanceType,
    workspaceId,
    clientId,
    appId,
  });
};

const addValueCreatedEvent = () => {
  return monday.execute("valueCreatedForUser");
};

const addInstallationCookie = (type) => {
  return monday.storage.instance.setItem(
    `monday-app:${type}:installed`,
    "true"
  );
};

const hasInstallationCookie = async (type) => {
  const {
    data: { value: mondayAppInstallationCookie },
  } = await monday.storage.instance.getItem(`monday-app:${type}:installed`);

  return Boolean(mondayAppInstallationCookie);
};

const addInstalledAnalytics = async (locationState) => {
  const isInMonday = checkIfInMondayApp(locationState);

  if (!isInMonday) {
    return;
  }

  const appParams = getMondayAppParams(locationState);

  const hasItemViewId = appParams["itemViewId"];
  const hasBoardViewId = appParams["boardViewId"];

  const hasInstalledItemView = await hasInstallationCookie("item-view");
  const hasInstalledBoardView = await hasInstallationCookie("board-view");

  if (hasItemViewId && !hasInstalledItemView) {
    analytics.track(
      analytics.ACTION.ADDED,
      analytics.CATEGORY.MONDAY_APP.FILESTAGE_ITEM_VIEWER,
      {
        trigger: "monday",
        trigger_type: "installed",
        itemViewId: hasItemViewId,
        boardViewId: hasBoardViewId,
      }
    );

    return addInstallationCookie("item-view");
  }
  if (!hasItemViewId && hasBoardViewId && !hasInstalledBoardView) {
    analytics.track(
      analytics.ACTION.ADDED,
      analytics.CATEGORY.MONDAY_APP.FILESTAGE_BOARD_VIEWER,
      {
        trigger: "monday",
        trigger_type: "installed",
        itemViewId: hasItemViewId,
        boardViewId: hasBoardViewId,
      }
    );

    return addInstallationCookie("board-view");
  }
};

export default {
  linkItemOrBoardToProject,
  getConnectedInstance,
  checkIfInMondayApp,
  addValueCreatedEvent,
  addInstalledAnalytics,
};
