import { useState } from "react";

import { ChatBubbleOutlineOutlined } from "@mui/icons-material";
import { LoadingButton as Button } from "@mui/lab";
import {
  FormControl,
  TextField,
  FormLabel,
  Checkbox,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";
import clsx from "clsx";

import { Text, Box } from "@shared/UIKit";

import backendClient from "@shared/services/backendClient";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  formContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    gap: 50,
  },
}));

const parseLines = (value) => value.replace(/(\\n)/g, "\n");

const defaultPrompt = {
  messages: [
    {
      role: "system",
      content:
        "You are a helpful assistant who analyse comments made on a file and provide next actionable items summarised in least amount of words possible. The comments are followed by replies if any.",
    },
    {
      role: "user",
      content:
        "Here are the comments separated by newline and replies are in next line just after the comment information:\n${formattedComments}",
    },
    {
      role: "user",
      content: `Keep these points in mind while summarising the comments. 
        1. Please consider replies while summarising the comments. 
        2. If a comment is of single word, then include it in the results if it contains a marker or annotation, which means user intend to request a change to this comment 
        3. If there are multiple comments with same content, then consider replies of of all those comments together 
        4. If the reply start with ignore then ignore the whole comment from the end result`,
    },
    {
      role: "user",
      content:
        "Please return the result in JSON format and include the sourceCommentId and isResolved of the comment used to summarise each point. Please use the these keys for respective values in JSON, actionableItems, sourceCommentId, isResolved, summary",
    },
  ],
  model: "gpt-3.5-turbo",
  response_format: { type: "json_object" },
};

function TestConsolidatedComments() {
  const classes = useStyles();

  const [prompt, setPrompt] = useState(JSON.stringify(defaultPrompt, null, 2));
  const [updateErrorMessage, setUpdateErrorMessage] = useState(false);

  const [reviewId, setReviewId] = useState("");
  const [testPromptErrorMessage, setTestPromptErrorMessage] = useState(false);
  const [isConsolidatingComments, setIsConsolidatingComments] = useState(false);
  const [consolidatedComments, setConsolidatedComments] = useState();

  const theme = useTheme();

  const handleTestPrompt = async () => {
    try {
      setIsConsolidatingComments(true);
      setTestPromptErrorMessage("");

      const promptObj = JSON.parse(prompt.replace(/\n/g, ""));

      const consolidatedCommentsResponse = await backendClient.post(
        "/admin/test-prompt",
        {
          reviewId,
          prompt: promptObj,
        }
      );

      setConsolidatedComments(consolidatedCommentsResponse);
      setIsConsolidatingComments(false);
    } catch (err) /* istanbul ignore next */ {
      setIsConsolidatingComments(false);
      setTestPromptErrorMessage(err.message);
    }
  };

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
    try {
      JSON.parse(event.target.value.replace(/\n/g, ""));
      setUpdateErrorMessage("");
    } catch (err) {
      setUpdateErrorMessage(`Error in prompt : ${err.message}`);
    }
  };

  const handleReviewIdChange = (event) => {
    setReviewId(event.target.value);
    setTestPromptErrorMessage("");
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Title>Test Consolidated Comments</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Box className={classes.formContainer}>
              <Box display="flex" flexDirection="column" mt={2} width="60%">
                <FormLabel>Test prompt for consolidated comments</FormLabel>

                <FormControl classes={{ root: classes.form }}>
                  <TextField
                    variant="standard"
                    name="PromptTextarea"
                    value={parseLines(prompt)}
                    onChange={handlePromptChange}
                    id="prompt-textarea"
                    placeholder="Enter prompt here"
                    aria-label="Prompt"
                    multiline
                    autoFocus
                    fullWidth
                  />
                </FormControl>
                {updateErrorMessage && (
                  <Box my={2} ml={5} data-testid="form-error">
                    <Text color="error.main">{updateErrorMessage}</Text>
                  </Box>
                )}
              </Box>

              <Box display="flex" flexDirection="column" mt={2} width="35%">
                <FormLabel>ReviewId to test the prompt with</FormLabel>

                <FormControl classes={{ root: classes.form }}>
                  <TextField
                    variant="standard"
                    name="ReviewId"
                    value={reviewId}
                    onChange={handleReviewIdChange}
                    placeholder="Enter reviewId here"
                    aria-label="ReviewId"
                    autoFocus
                    fullWidth
                  />
                </FormControl>

                <Box mt={2} maxWidth={400} width="100%">
                  <Button
                    size="large"
                    color="primary"
                    loading={isConsolidatingComments}
                    variant="contained"
                    onClick={handleTestPrompt}
                    fullWidth
                  >
                    {isConsolidatingComments
                      ? "Generating summary..."
                      : "Test prompt"}
                  </Button>
                </Box>
                {testPromptErrorMessage && (
                  <Box my={2} ml={5} data-testid="form-error">
                    <Text color="error.main">{testPromptErrorMessage}</Text>
                  </Box>
                )}
                {consolidatedComments && (
                  <Box p={2}>
                    {consolidatedComments.actionableItems.map((item) => (
                      <Box
                        key={item.sourceCommentId}
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Checkbox
                          color="accent"
                          checked={item.isResolved}
                          inputProps={{
                            "data-testid":
                              "select-consolidated-comment-checkbox",
                          }}
                        />
                        <Text
                          variant="textSm"
                          color={theme.color.gray[700]}
                          textAlign="left"
                          whiteSpace="break-spaces"
                        >
                          {item.summary}
                        </Text>
                        <IconButton
                          data-testid="scroll-to-comment"
                          size="large"
                          color="upgrade"
                        >
                          <ChatBubbleOutlineOutlined />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default TestConsolidatedComments;
