import PropTypes from "prop-types";
import { ReactElement } from "react";
import LazyLoad from "react-lazy-load";

/**
 * Lazy load component if lazyLoad is true.
 * @param {object} props - Component props
 * @param {boolean} props.lazyLoad enable lazy load?
 * @param {ReactElement} props.children - [Required] User object.
 * @param {...object} props.otherProps other props for LazyLoad component
 * @returns {ReactElement} the children elements or LazyLoad component.
 */
export default function MaybeLazyLoad({ lazyLoad, children, ...otherProps }) {
  return lazyLoad ? <LazyLoad {...otherProps}>{children}</LazyLoad> : children;
}

MaybeLazyLoad.propTypes = {
  lazyLoad: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
