import { useState, useEffect } from "react";

import { Link, Divider } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import HelpMenu from "@supporting/components/HelpMenu/HelpMenu";
import NotificationBellIcon from "@supporting/components/NotificationBellIcon/NotificationBellIcon";
import NotificationCenter from "@supporting/components/NotificationCenter/NotificationCenter";
import TeamLogo from "@supporting/components/TeamLogo/TeamLogo";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import notificationCenterService from "@supporting/services/notificationCenterService";
import PendingReviews from "@workflow/components/PendingReviews";
import PendingReviewsNavButton from "@workflow/components/PendingReviewsNavButton/PendingReviewsNavButton";
import QuickSearchButton from "@workflow/components/QuickSearchButton/QuickSearchButton";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import UserMenu from "@shared/components/DashboardNavBar/UserMenu/UserMenu";
import pagesEnum from "@shared/constants/pages";
import { useNavigation, useMediaQuery } from "@shared/hooks";

import InviteTeamMemberButton from "@supporting/pages/Teams/members/InviteTeamMemberButton/InviteTeamMemberButton";

import DashboardNavButtons from "./DashboardNavButtons/DashboardNavButtons";

const useStyles = makeStyles((theme) => ({
  Divider: {
    margin: "0 4px 0 21px",
    height: 20,
    borderColor: theme.color.gray[300],
  },
  MarginLeft: {
    marginLeft: 10,
  },
  BackButton: {
    padding: 8,
    margin: "0 6px",
    color: theme.color.gray[500],
    "&:hover": {
      backgroundColor: "unset",
      color: theme.color.gray[600],
    },
  },
}));

export default function DashboardNavBar() {
  const theme = useTheme();
  const [isNotificationEnabled, setIsNotificationEnabled] = useState(false);
  const { goTo } = useNavigation();
  const classes = useStyles();
  const team = useSelectedTeam();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));

  const navigateToDashboard = (event) => {
    event.preventDefault();
    goTo("DASHBOARD", { options: { reload: true } });
  };

  useEffect(() => {
    setIsNotificationEnabled(notificationCenterService.isEnabled());
  }, []);

  return (
    <>
      <Box
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height={48}
        maxHeight={48}
        bgcolor={theme.color.white}
        borderBottom={1}
        borderColor={theme.color.gray[200]}
        flexGrow={1}
        pl={2}
        pr={1}
        gap={2}
      >
        <Box display="flex" alignItems="center">
          <Box maxWidth={200}>
            <Link
              href="#"
              underline="none"
              onClick={navigateToDashboard}
              data-testid="navigate-dashboard"
            >
              <TeamLogo
                logoUrl={
                  team?.subscriptionLimits.isBrandingEnabled
                    ? team?.branding.logo?.retina?.url
                    : null
                }
              />
            </Link>
          </Box>
          {mdUp && (
            <>
              <Divider className={classes.Divider} orientation="vertical" />
              <DashboardNavButtons />
            </>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {team.permissions.canManageMembers &&
            team.subscription.status === "trialing" && (
              <InviteTeamMemberButton
                team={team}
                page={pagesEnum.NAVIGATION_BAR}
                variant="outlined"
                size="small"
                sx={{
                  "@media (max-width: 1100px)": {
                    display: "none",
                  },
                }}
              />
            )}
          {mdUp && (
            <>
              <PendingReviewsNavButton squared />
              <Divider
                className={classnames(classes.Divider, classes.MarginLeft)}
                orientation="vertical"
              />
            </>
          )}
          <QuickSearchButton />
          {isNotificationEnabled && <NotificationBellIcon />}
          <HelpMenu />
          <UserMenu />
        </Box>
      </Box>
      {isNotificationEnabled && <NotificationCenter />}
      <PendingReviews />
    </>
  );
}
