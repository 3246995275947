import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { instance as fileService } from "@workflow/services/fileService";
import noop from "lodash/noop";

import { EditableLabel } from "@shared/UIKit";

import validationUtils from "@shared/helpers/validationUtils";
import { useMediaQuery } from "@shared/hooks";
import errorHandlerService from "@shared/services/errorHandler";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  editableLabel: {
    width: "100%",
  },
  input: {
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: FSTGTypography.fontSize_1_4,
    letterSpacing: "0px",
  },
}));

function FileName({
  fileName,
  fileId,
  isDisabled,
  isEditMode,
  onChangeEditMode,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const onBeforeSave = (newFileName) => validationUtils.checkName(newFileName);

  const onAfterSave = useCallback(
    async (newFileName) => {
      try {
        await fileService.updateFileName(fileId, newFileName);
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [fileId]
  );

  const disabled = isDisabled || (isMobile && !isEditMode);

  return (
    <EditableLabel
      text={fileName}
      onBeforeSave={onBeforeSave}
      onAfterSave={onAfterSave}
      onChangeEditMode={onChangeEditMode}
      disabled={disabled}
      isEditing={isEditMode}
      className={classes.editableLabel}
      classes={{
        input: classes.input,
        text: classes.input,
      }}
      tooltipProps={{
        title: disabled ? fileName : t("FILE.RENAME"),
        placement: "bottom",
        text: disabled ? "" : fileName,
      }}
      textVariant="h4"
    />
  );
}

FileName.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool,
  onChangeEditMode: PropTypes.func,
};

FileName.defaultProps = {
  isEditMode: false,
  onChangeEditMode: noop,
};

export default FileName;
