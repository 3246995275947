import { Box } from "@mui/material";

import EditAvatar from "./EditAvatar/EditAvatar";
import EditProfileInformation from "./EditProfileInformation/EditProfileInformation";

const EditProfile = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2} flex={1}>
      <EditProfileInformation />
      <EditAvatar />
    </Box>
  );
};

export default EditProfile;
