/* eslint react/forbid-component-props:0 */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";
import classnames from "classnames";
import noop from "lodash/noop";

import {
  Text,
  Tooltip,
  Box,
  ReviewDecisionIndicator as SharedReviewDecisionIndicator,
} from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles(() => ({
  Indicator: {
    cursor: "pointer",
  },
  DecisionLabel: {
    marginLeft: 10,
    overflow: "hidden",
    position: "absolute",
  },
  IndicatorOverlap: {
    marginLeft: -10,
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
}));

function ReviewDecisionIndicator({
  variant,
  zIndex,
  isPendingYourReview,
  className,
  onClick,
  disabled,
  onHover,
  state,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const mouseEnter = () => {
    onHover(true);
  };
  const mouseLeave = () => {
    onHover(false);
  };

  if (disabled) {
    return <Box height={9} data-testid="review-decicion-indicator-spacer" />;
  }

  const PendingReviewIndicator = (
    <>
      <SharedReviewDecisionIndicator
        state={state}
        variant="noBorder"
        className={classnames(classes.Indicator, className)}
        data-testid="review-decision-indicator-pending-review"
      />
      <Text
        variant="subtitle2"
        color={theme.color.red[500]}
        fontWeight={FSTGTypography.fontWeightMedium}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        className={classes.DecisionLabel}
        data-testid="review-decision-indicator-pending-review-text"
      >
        {t("FILE_REVIEW.REVIEWER_STATES.IN_REVIEW")}
      </Text>
    </>
  );
  const NonPendingReviewIndicator = (
    <SharedReviewDecisionIndicator
      state={state}
      className={classnames(
        classes.Indicator,
        {
          [classes.IndicatorOverlap]: variant !== "workspace",
        },
        className
      )}
      style={{
        zIndex: `${zIndex}`,
      }}
      data-testid="review-decision-indicator-default"
    />
  );

  return (
    <Tooltip title={t("FILE_REVIEW.VIEW_REVIEWS_BUTTON")} placement="top">
      <Box
        data-testid="review-decision-indicator-box"
        display="flex"
        alignItems="start center"
        justifyContent="center"
        flexDirection="row"
        position="relative"
        onClick={onClick}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
      >
        {isPendingYourReview
          ? PendingReviewIndicator
          : NonPendingReviewIndicator}
      </Box>
    </Tooltip>
  );
}

ReviewDecisionIndicator.propTypes = {
  variant: PropTypes.oneOf(["workspace", "dashboard"]),
  isPendingYourReview: PropTypes.bool,
  disabled: PropTypes.bool,
  zIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onHover: PropTypes.func,
  state: PropTypes.string,
};

ReviewDecisionIndicator.defaultProps = {
  variant: "dashboard",
  disabled: false,
  zIndex: "1",
  className: "",
  isPendingYourReview: false,
  onHover: noop,
  state: "PENDING",
};

export default ReviewDecisionIndicator;
