import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import PlanDialog from "@supporting/components/SelectPlanDialog/PlanDialog/PlanDialog";
import { CURRENCIES } from "@supporting/constants/currencies";
import { instance as teamService } from "@supporting/services/team";
import noop from "lodash/noop";

import DisabledPlanDialog from "./DisabledPlanDialog/DisabledPlanDialog";

const SelectPlanDialog = ({ cancel, teamId, ...props }) => {
  const [team, setTeam] = useState(null);
  const modal = useModal();

  const cancelDialog = () => {
    cancel();
    modal.hide();
  };

  useEffect(() => {
    const fetchTeam = async () => {
      const team = await teamService.fetchTeamByTeamId(teamId);
      setTeam(team);
    };

    fetchTeam();
  }, [teamId]);

  if (
    !team?.subscription ||
    !CURRENCIES.includes(team.subscription.basePrice.currency)
  ) {
    return (
      <DisabledPlanDialog
        cancel={cancelDialog}
        isOpen={modal.visible}
        hasSubscription={Boolean(team?.subscription)}
      />
    );
  }

  return (
    <PlanDialog
      cancel={cancelDialog}
      isOpen={modal.visible}
      team={team}
      {...props}
    />
  );
};

export default NiceModal.create(SelectPlanDialog);

SelectPlanDialog.propTypes = {
  cancel: PropTypes.func,
  hideFree: PropTypes.bool,
  page: PropTypes.string,
  teamId: PropTypes.string.isRequired,
};

SelectPlanDialog.defaultProps = {
  cancel: noop,
  hideFree: false,
  page: undefined,
};
