import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { SvgIcon } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Text, ReviewDecisionIndicator, Box, Tooltip } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import FSTGTypography from "@shared/theme/typography";

import SignatureIcon from "@assets/img/icons/signature.svg";

const useStyles = makeStyles(() => ({
  ReviewerName: {
    marginLeft: 10,
    marginRight: 10,
  },
  YouTag: {
    marginRight: 3,
    marginLeft: 3,
  },
}));

function ReviewDecision({
  reviewState,
  reviewer,
  reviewDate,
  digitallySigned,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      data-testid="review-decision"
      display="flex"
      mb={1}
      alignItems="center"
      {...restProps}
    >
      {digitallySigned ? (
        <Tooltip title={t("VERIFIED_APPROVAL.PROJECT_DASHBOARD_TOOLTIP")}>
          <SvgIcon
            component={SignatureIcon}
            inheritViewBox
            color="approved"
            fontSize="small"
            data-testid="signature-icon"
          />
        </Tooltip>
      ) : (
        <ReviewDecisionIndicator state={reviewState} variant="noBorder" />
      )}

      <Text
        className={classes.ReviewerName}
        variant="body2"
        fontWeight={FSTGTypography.fontWeightMedium}
        data-testid="review-decision-display-name"
      >
        {reviewer.displayName}

        {reviewer.youTagEnabled && (
          <Text
            className={classes.YouTag}
            variant="subtitle2"
            color={theme.color.gray[500]}
            component="span"
            data-testid="review-decision-you-tag"
          >
            {t("CORE.YOU")}
          </Text>
        )}
      </Text>

      {reviewDate && (
        <Text
          variant="subtitle1"
          color={theme.color.gray[600]}
          data-testid="review-decision-review-date"
        >
          {formatWithLocale(new Date(reviewDate), "PP p")}
        </Text>
      )}
    </Box>
  );
}

ReviewDecision.propTypes = {
  reviewState: PropTypes.oneOf([
    "WAITING",
    "PENDING",
    ...Object.keys(REVIEW_DECISION_STATES),
  ]),
  reviewer: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    youTagEnabled: PropTypes.bool,
  }).isRequired,
  reviewDate: PropTypes.string,
  digitallySigned: PropTypes.bool,
};

ReviewDecision.defaultProps = {
  reviewState: "PENDING",
  reviewDate: null,
  digitallySigned: false,
};

export default ReviewDecision;
