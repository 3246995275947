import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";

import projectService from "@workflow/services/projectService";

import { FOLDER_KEYS } from "@shared/query-keys";
import { instance as ws } from "@shared/services/websocket";

const PROJECT_SORT_OPTIONS = {
  NEWEST: "NEWEST",
  BY_NAME: "BY_NAME",
};

function sortFolderProjects(sortOption) {
  return (entity1, entity2) => {
    if (sortOption === PROJECT_SORT_OPTIONS.NEWEST) {
      return new Date(entity2.createdTime) - new Date(entity1.createdTime);
    }
    return entity1.name.localeCompare(entity2.name);
  };
}

const folderMutations = {
  add: (prevData, newFolder) => {
    return [...prevData, { ...newFolder, projects: [] }];
  },
  update: (prevData, updatedFolder) => {
    return prevData.map((folder) =>
      folder.id === updatedFolder.id ? { ...folder, ...updatedFolder } : folder
    );
  },
  remove: (prevData, removedFolder) => {
    return prevData.filter((folder) => folder.id !== removedFolder.id);
  },
};

const projectMutations = {
  add: (prevData, newProject) => {
    return prevData.map((folder) => {
      if (folder.id === newProject.folderId) {
        return {
          ...folder,
          projects: [...folder.projects, newProject],
        };
      }
      return folder;
    });
  },
  update: (prevData, updatedProject) => {
    const result = prevData.map((folder) => {
      if (folder.id === updatedProject.folderId) {
        return {
          ...folder,
          projects: folder.projects.map((project) => {
            if (project.id === updatedProject.id) {
              return { ...project, ...updatedProject };
            }
            return project;
          }),
        };
      }
      return folder;
    });
    return result;
  },
  remove: (prevData, removedProject) => {
    return prevData.map((folder) => {
      return {
        ...folder,
        projects: folder.projects.filter(
          (project) => project.id !== removedProject.projectId
        ),
      };
    });
  },
};

const sortTeamProjects = (folderProjects, sortOption) => {
  if (!sortOption) {
    return folderProjects;
  }

  const sortFn = sortFolderProjects(sortOption);
  const sortedList = folderProjects.sort(sortFn).map((folder) => ({
    ...folder,
    projects: folder.projects
      .filter((project) => project)
      .sort((project1, project2) => {
        if (project1.isArchived && !project2.isArchived) {
          return 1;
        }
        if (!project1.isArchived && project2.isArchived) {
          return -1;
        }
        return sortFn(project1, project2);
      }),
  }));

  return sortedList;
};

export const useTeamProjects = (teamId, sortOption, newLayout) => {
  return useQuery({
    queryKey: FOLDER_KEYS.lists(teamId),
    queryFn: async () => {
      if (!newLayout) {
        return [];
      }
      const response = await projectService.fetchProjectsByTeamId(teamId);
      return response;
    },
    select: useCallback(
      (data) => sortTeamProjects(data, sortOption),
      [sortOption]
    ),
  });
};

export const useDashboardSidebarWebSockets = (newLayout = false) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!newLayout) {
      return;
    }

    const mutation = (data, handler) => {
      queryClient.setQueryData(FOLDER_KEYS.lists(data.teamId), (prevData) => {
        return handler(prevData, data);
      });
    };

    ws.addListener(ws.EVENT_NAMES.FOLDER.CREATED, ({ folder }) =>
      mutation(folder, folderMutations.add)
    );

    ws.addListener(ws.EVENT_NAMES.FOLDER.UPDATED, ({ folder }) =>
      mutation(folder, folderMutations.update)
    );

    ws.addListener(ws.EVENT_NAMES.FOLDER.REMOVED, ({ folder }) =>
      mutation(folder, folderMutations.remove)
    );

    ws.addListener(
      ws.EVENT_NAMES.FOLDER.PROJECT.MOVED,
      /* istanbul ignore next */
      (event) => {
        /* istanbul ignore next */
        queryClient.resetQueries(FOLDER_KEYS.lists(event.teamId));
      }
    );

    ws.addListener(ws.EVENT_NAMES.PROJECT.CREATED, async ({ projectId }) => {
      const data = await projectService.fetchProjectById(projectId);
      mutation(data, projectMutations.add);
    });

    ws.addListener(ws.EVENT_NAMES.PROJECT.UPDATED, async ({ projectId }) => {
      const data = await projectService.fetchProjectById(projectId, true);
      mutation(data, projectMutations.update);
    });

    ws.addListener(ws.EVENT_NAMES.PROJECT.REMOVED, (event) => {
      mutation(event, projectMutations.remove);
    });

    return () => {
      ws.removeListeners(ws.EVENT_NAMES.FOLDER.CREATED);
      ws.removeListeners(ws.EVENT_NAMES.FOLDER.UPDATED);
      ws.removeListeners(ws.EVENT_NAMES.FOLDER.REMOVED);
      ws.removeListeners(ws.EVENT_NAMES.FOLDER.PROJECT.MOVED);
      ws.removeListeners(ws.EVENT_NAMES.PROJECT.CREATED);
      ws.removeListeners(ws.EVENT_NAMES.PROJECT.UPDATED);
      ws.removeListeners(ws.EVENT_NAMES.PROJECT.REMOVED);
    };
  }, [newLayout, queryClient]);
};
