import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Dialog, Text } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    fontSize: FSTGTypography.fontSize_2_0,
    fontWeight: FSTGTypography.fontWeightMedium,
    color: theme.color.gray[800],
  },
  dialogBody: {
    fontWeight: FSTGTypography.fontWeightRegular,
    fontSize: FSTGTypography.fontSize_1_6,
    color: theme.color.gray[500],
  },

  dialog: { maxWidth: 690, maxHeight: 300, padding: "44px 70px" },
  dialogActions: { justifyContent: "center" },
}));

function WaitingApprovalDialog({ pendingTeam, closeDialog }) {
  const classes = useStyles();

  const { t } = useTranslation();
  return (
    <Dialog
      maxWidth="lg"
      isOpen={Boolean(pendingTeam)}
      cancel={closeDialog}
      title={
        <Text className={classes.dialogTitle}>
          {t("TEAM_INVITE_LINK.REQUESTED_TITLE", { Team: pendingTeam.name })}
        </Text>
      }
      classes={{ paper: classes.dialog }}
      content={
        <Text
          className={classes.dialogBody}
          translate="TEAM_INVITE_LINK.REQUESTED_BODY"
        />
      }
      hideBackdrop={false}
      actions={
        <Button
          onClick={() => {
            window.open(t("TEAM_INVITE_LINK.REQUESTED.LINK"), "_blank");
          }}
          variant="contained"
          size="large"
          color="primary"
          data-testid="guides-link"
        >
          {t("TEAM_LINK.PENDING_CONFIRMATION.CTA")}
        </Button>
      }
      actionProps={{ className: classes.dialogActions }}
    />
  );
}

WaitingApprovalDialog.propTypes = {
  pendingTeam: teamProp.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default WaitingApprovalDialog;
