import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { HelpOutlineOutlined } from "@mui/icons-material";
import { MenuItem, Link, IconButton, TextField } from "@mui/material";

import { Box, InfoToolTip, Toggle } from "@shared/UIKit";

const PROJECT_ACTIVITY_TIME_PERIODS = [10, 20, 30, 60, 240, 480];

function NotificationsTab({
  type,
  changeEmailPreferences,
  preferencesValue,
  projectActivityTimePeriod,
  id,
}) {
  const { t } = useTranslation();

  return (
    <Box display="flex" mb={3.75} flexDirection="column">
      <Toggle
        label={
          <>
            {t(`SETTINGS.NOTIFICATIONS.${type}.TITLE`)}
            <InfoToolTip
              title={
                <Trans
                  components={{
                    anchor: (
                      <Link
                        color="accent"
                        href={t(`SETTINGS.NOTIFICATIONS.${type}.HELP.LINK`)}
                        target="_blank"
                      />
                    ),
                  }}
                >
                  {t(`SETTINGS.NOTIFICATIONS.${type}.HELP.TEXT`)}
                </Trans>
              }
            >
              <IconButton size="small" disableRipple color="default-light">
                <HelpOutlineOutlined fontSize="inherit" />
              </IconButton>
            </InfoToolTip>
          </>
        }
        helpText={t(`SETTINGS.NOTIFICATIONS.${type}.DESCRIPTION`)}
        slotProps={{
          switch: {
            checked: preferencesValue,
            onChange: changeEmailPreferences,
            id,
          },
        }}
      />
      <Box ml={6}>
        {type === "PROJECT_ACTIVITY" && (
          <Box
            mt={1}
            borderLeft={2}
            borderColor="var(--mui-palette-grey-300)"
            pl={1.25}
          >
            <TextField
              select
              label={t(`SETTINGS.NOTIFICATIONS.${type}.TIME_PERIOD_TITLE`)}
              name="projectActivityTimePeriod"
              disabled={!preferencesValue}
              value={projectActivityTimePeriod}
              onChange={changeEmailPreferences}
              inputProps={{
                "data-testid": "project-activity-email-time-period-label",
              }}
            >
              {PROJECT_ACTIVITY_TIME_PERIODS.map((period, index) => (
                <MenuItem
                  key={period}
                  value={period}
                  data-testid={`activity-time-period-${index}`}
                >
                  {t(
                    `SETTINGS.NOTIFICATIONS.${type}.TIME_PERIOD_OPTIONS.${period}`
                  )}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
      </Box>
    </Box>
  );
}

NotificationsTab.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  changeEmailPreferences: PropTypes.func.isRequired,
  preferencesValue: PropTypes.bool.isRequired,
  projectActivityTimePeriod: PropTypes.oneOf(PROJECT_ACTIVITY_TIME_PERIODS),
};

NotificationsTab.defaultProps = {
  projectActivityTimePeriod: 60,
};
export default NotificationsTab;
