import { useTranslation } from "react-i18next";

import { Box, Button, Card, CardContent, CardActions } from "@mui/material";

import { Text } from "@shared/UIKit";

import GetInTouchImage from "@assets/img/images/get_in_touch_1.png";

const HelpCard = () => {
  const { t } = useTranslation();

  return (
    <Card
      data-testid="team-subscription-help"
      sx={{
        minHeight: 200,
        backgroundImage: { xs: "none", sm: `url(${GetInTouchImage})` },
        backgroundRepeat: "no-repeat",
        px: 4,
      }}
    >
      <CardContent>
        <Box ml={{ xs: 0, sm: 20 }}>
          <Text variant="h5">{t("HELP_CARD.TITLE")}</Text>
          <Text color="text.secondary">{t("HELP_CARD.DESCRIPTION")}</Text>
        </Box>
      </CardContent>
      <CardActions>
        <Button
          href="mailto:hello@filestage.io"
          variant="contained"
          color="primary"
          data-testid="team-subscription-help-button"
          sx={{ marginLeft: "auto" }}
        >
          {t("HELP_CARD.BUTTON")}
        </Button>
      </CardActions>
    </Card>
  );
};

export default HelpCard;
