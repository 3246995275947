import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Publish } from "@mui/icons-material";
import { Button } from "@mui/material";
import noop from "lodash/noop";

function WorkspaceReviewButton({ onClick }) {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      type="success"
      shape="rounded"
      startIcon={<Publish fontSize="small" />}
      onClick={onClick}
      data-testid="upload-button"
    >
      {t("VERSION.UPLOAD")}
    </Button>
  );
}

WorkspaceReviewButton.propTypes = {
  onClick: PropTypes.func,
};

WorkspaceReviewButton.defaultProps = {
  onClick: noop,
};

export default WorkspaceReviewButton;
