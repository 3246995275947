import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardHeader, CardContent } from "@mui/material";
import { instance as billingService } from "@supporting/services/billingService";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { LoadingSpinner } from "@shared/UIKit";

import errorHandlerService from "@shared/services/errorHandler";

import InvoiceDataForm from "./InvoiceDataForm";

const InvoiceData = () => {
  const [invoiceEmail, setInvoiceEmail] = useState("");
  const [loading, setIsLoading] = useState(false);

  const teamId = teamService.getSelectedTeam()._id;

  useEffect(() => {
    const getInvoiceData = async (id) => {
      setIsLoading(true);
      try {
        const resData = await billingService.getInvoiceData(id);
        setInvoiceEmail(resData.invoiceEmail);
        setIsLoading(false);
      } catch (error) {
        errorHandlerService.handleError(error);
        setIsLoading(false);
      }
    };

    getInvoiceData(teamId);
  }, [teamId]);

  const { t } = useTranslation();

  const submitInvoiceDataForm = async (invoiceDataFormData) => {
    const { invoiceEmail } = invoiceDataFormData;
    try {
      await billingService.updateInvoiceData(teamId, {
        invoiceEmail,
      });

      toastService.sendToast({
        title: "BILLING.EDIT_INVOICE_DATA.SUCCESS.TITLE",
        body: "BILLING.EDIT_INVOICE_DATA.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      /* istanbul ignore next */
      errorHandlerService.handleError(error);
    }
  };

  return (
    <Card data-testid="user-settings-early-access-tab">
      <CardHeader title={t("BILLING.INVOICE_DATA")} />
      <CardContent>
        {!loading ? (
          <InvoiceDataForm
            onSubmit={submitInvoiceDataForm}
            defaultValues={{ invoiceEmail }}
          />
        ) : (
          <LoadingSpinner />
        )}
      </CardContent>
    </Card>
  );
};

export default InvoiceData;
