/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Box, Dialog } from "@shared/UIKit";

import FolderForm from "../FolderForm/FolderForm";
import useFolderForm from "../FolderForm/useFolderForm";

const RenameFolderDialog = ({ name, answer, cancel }) => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useFolderForm({ name });

  const handleFormSubmit = ({ folderName }) => {
    answer(folderName);
  };

  const actions = (
    <Button
      type="submit"
      form="rename-folder-form"
      color="primary"
      data-testid="rename-team-folder-dialog-button"
      variant="contained"
      disabled={!isValid}
    >
      {t("CORE.SAVE")}
    </Button>
  );

  return (
    <Dialog
      data-testid="rename-folder-dialog"
      maxWidth="xs"
      hideBackdrop={false}
      disableBackdropClick
      open
      cancel={cancel}
      title={t("PROJECT.FOLDER.RENAME.HEADER")}
      actions={actions}
    >
      <Box data-testid="rename-folder-dialog" p={2}>
        <FolderForm
          formId="rename-folder-form"
          handleSubmit={handleSubmit(handleFormSubmit)}
          control={control}
          errors={errors}
        />
      </Box>
    </Dialog>
  );
};

RenameFolderDialog.propTypes = {
  name: PropTypes.string.isRequired,
  answer: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default RenameFolderDialog;
