import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";
import classnames from "classnames";

import { Box, Text } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  pendingReviewLabel: {
    transition: theme.transitions.create(["transform"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    transform: "translate(-50%,0px)",
  },
}));

function PendingReviewLabel({ className }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      minWidth={115}
      px={0.5}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="2px"
      bgcolor={theme.color.orange[900]}
      position="absolute"
      zIndex={21}
      top={0}
      pointerEvents="none"
      left="50%"
      className={classnames(classes.pendingReviewLabel, className)}
    >
      <Text
        variant="subtitle2"
        color={theme.color.white}
        fontWeight={theme.typography.fontWeight.semiBold}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        data-testid="pending-review-label"
      >
        {t("FILE_REVIEW.REVIEWER_STATES.IN_REVIEW")}
      </Text>
    </Box>
  );
}

PendingReviewLabel.propTypes = {
  className: PropTypes.string,
};

PendingReviewLabel.defaultProps = {
  className: null,
};

export default PendingReviewLabel;
