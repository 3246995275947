import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { Dialog } from "@shared/UIKit";

import DisplayAPIKeyDialogContent from "./DisplayAPIKeyDialogContent";

export default function DisplayAPIKeyDialog({
  isOpen,
  closeDialog,
  generatedAPIKey,
}) {
  const { t } = useTranslation();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);

  const handleCloseDialog = useCallback(() => {
    if (!isConfirmed) {
      setIsCheckboxError(true);
      return;
    }
    closeDialog();
  }, [closeDialog, isConfirmed]);

  const toggleConfirmationCheckbox = useCallback(() => {
    if (isCheckboxError) {
      setIsCheckboxError(false);
    }
    setIsConfirmed((value) => !value);
  }, [isCheckboxError]);

  return (
    <Dialog
      title={t("INTEGRATIONS.API.GENERATE_POP_OVER.TITLE_TEXT")}
      hideBackdrop={false}
      disableBackdropClick
      isOpen={isOpen}
      content={
        <Box p={0} textAlign="left">
          <DisplayAPIKeyDialogContent
            apiKey={generatedAPIKey}
            isConfirmed={isConfirmed}
            onContinue={handleCloseDialog}
            isCheckboxError={isCheckboxError}
            toggleConfirmationCheckbox={toggleConfirmationCheckbox}
          />
        </Box>
      }
      cancel={handleCloseDialog}
      useDefaultBackground={false}
    />
  );
}

DisplayAPIKeyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  generatedAPIKey: PropTypes.string.isRequired,
};
