/* istanbul ignore file */

import { queryClient } from "@shared/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState, useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import "@uppy/core/dist/style.min.css";
import "@shared/styles/global.css";

import "@shared/styles/variables.css";

import FsDevTools from "@shared/components/FsDevTools";
import RouterFallback from "@shared/pages/RouterFallback";

// eslint-disable-next-line react/prop-types
const App = ({ router }) => {
  const [showDevTools, setShowDevTools] = useState(false);

  useEffect(() => {
    window.toggleDevTools = () => {
      setShowDevTools((prev) => !prev);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} fallbackElement={<RouterFallback />} />

      {showDevTools ? (
        <>
          <FsDevTools />
          <ReactQueryDevtools initialIsOpen={false} />
        </>
      ) : null}
    </QueryClientProvider>
  );
};

export default App;
