import PropTypes from "prop-types";
import { ReactElement } from "react";

import { SvgIcon } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import TipIcon from "@assets/img/icons/tipmessage_icon.svg";

/**
 * UIKit Hint Box Component
 * @description Renders an the hint box component that we use in the UIKit.
 * It shows an icon and a text.
 * @param {object} props - Component props
 * @param {ReactElement} props.children - [Required] User object.
 * @returns {ReactElement} - React component
 */
function HintBox({ children, ...restProps }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      border="1px solid var(--mui-palette-grey-200)"
      borderRadius={2}
      gap={1}
      bgcolor="grey.50"
      p={1.5}
      {...restProps}
    >
      <SvgIcon component={TipIcon} inheritViewBox color="default-light" />
      <Text
        fontSize={FSTGTypography.fontSize_1_3}
        lineHeight={FSTGTypography.lineHeight_1_5}
        color="text.secondary"
        data-testid="hint-box-text"
        mt="auto"
      >
        {children}
      </Text>
    </Box>
  );
}

HintBox.propTypes = {
  children: PropTypes.node.isRequired,
  textContainerClassname: PropTypes.string,
};

HintBox.defaultProps = {
  textContainerClassname: null,
};

export default HintBox;
