import PropTypes from "prop-types";
import { Link as ReactLink } from "react-router-dom";

import { Box, Link } from "@mui/material";

import { Text } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";

const PanelHeader = ({ canNavigateToDashboard, folderName, project }) => (
  <Box
    height={50}
    display="flex"
    alignItems="center"
    borderBottom="1px solid var(--mui-palette-grey-100)"
    bgcolor="grey.50"
    px={4}
    gap={1}
  >
    <Text
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      variant="textSm"
      color="default-light"
    >
      {folderName && `${folderName} / `}
      <Box
        component="span"
        fontWeight="fontWeight.semiBold"
        color="primary.main"
      >
        {canNavigateToDashboard ? (
          <Link
            component={ReactLink}
            to={`/projects/${project.id}`}
            underline="none"
            color="inherit"
          >
            {project.name}
          </Link>
        ) : (
          project.name
        )}
      </Box>
    </Text>
  </Box>
);

PanelHeader.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  folderName: PropTypes.string,
  canNavigateToDashboard: PropTypes.bool.isRequired,
};

PanelHeader.defaultProps = {
  folderName: null,
};

export default PanelHeader;
