import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton as Button } from "@mui/lab";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import { instance as billingService } from "@supporting/services/billingService";
import toastService from "@supporting/services/toast";

import { Text, Dialog } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";

import ConfirmDeletionDialog from "./ConfirmDeletionDialog";

const footerMessage = {
  Basic: "PROJECT_LIMIT.SUBSCRIPTION.MODAL.FOOTER_2",
  Professional: "PROJECT_LIMIT.SUBSCRIPTION.MODAL.FOOTER",
};
const DIALOG_CONTENT = {
  ADD: {
    title: "PROJECT_LIMIT.SUBSCRIPTION.MODAL.TITLE",
    submitButton: "PROJECT_LIMIT.SUBSCRIPTION.MODAL.BUTTON",
    footerMessage: (planName) =>
      planName === "Basic"
        ? "PROJECT_LIMIT.SUBSCRIPTION.MODAL.FOOTER_2"
        : "PROJECT_LIMIT.SUBSCRIPTION.MODAL.FOOTER",
  },
  REMOVE: {
    title: "PROJECT_LIMIT.SUBSCRIPTION.MODAL.TITLE_2",
    submitButton: "PROJECT_LIMIT.SUBSCRIPTION.MODAL.DECREASE_BUTTON",
    footerMessage: () => "PROJECT_LIMIT.SUBSCRIPTION.MODAL.DECREASE_TEXT",
  },
};

const getDropDownCount = (variant, team) => {
  if (variant === "ADD") {
    return 6;
  }
  const priceItem = team.subscription.items.find(
    (item) => item.price.id === team.subscriptionLimits.additionalProjects
  );
  return priceItem.quantity;
};

function AddOrRemoveProjectsDialog({ variant, team }) {
  const modal = useModal();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const projectsBulkCount =
    team.subscription.additionalProjectsPrice.limits.maxProjectNumber;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCount, setSelectedCount] = useState(1);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onSelectProjectCount = (count) => {
    setSelectedCount(count);
    handleClose();
  };
  /* istanbul ignore next */
  const showPlanDialog = () => {
    NiceModal.show(SelectPlanDialog, { teamId: team._id });
  };
  const handleProjectsUpdate = async () => {
    try {
      setIsLoading(true);
      const count = variant === "ADD" ? selectedCount : -1 * selectedCount;
      await billingService.updateProjectCount(team._id, count);
      modal.remove();
    } catch (error) {
      setIsLoading(false);
      if (error.message === "SUBSCRIPTION_ON_SCHEDULE") {
        toastService.sendToast({
          title: "SUBSCRIPTION.PROJECTS_ADD_ERROR.TOAST.TITLE",
          body: "SUBSCRIPTION.PROJECTS_ADD_ERROR.TOAST.BODY",
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
      } else if (error.message === "MANUAL_PAYING_CUSTOMER") {
        toastService.sendToast({
          title: "MANUAL_BILLING.MODAL.TITLE",
          body: "MANUAL_BILLING.MODAL.BODY",
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
      } else {
        errorHandlerService.handleError(error);
      }
    }
  };
  const handleDeleteProject = () => {
    NiceModal.show(ConfirmDeletionDialog, {
      confirm: handleProjectsUpdate,
      projectsCount:
        selectedCount *
        team.subscription.additionalProjectsPrice.limits.maxProjectNumber,
    });
  };

  const dropdownItems = Array.from(
    { length: getDropDownCount(variant, team) },
    (value, index) => (index + 1) * projectsBulkCount
  );

  return (
    <Dialog
      hideBackdrop={false}
      disableBackdropClick
      isOpen={modal.visible}
      cancel={modal.remove}
      useDefaultBackground={false}
      title={t(DIALOG_CONTENT[variant].title)}
    >
      <Box display="flex" flexDirection="column" gap={6}>
        <Box
          gap={3}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ sm: "row", xs: "column" }}
        >
          <Box display="flex" gap={2} alignItems="center">
            <Button
              color="primaryV2"
              variant="outlined"
              aria-haspopup="true"
              onClick={handleClick}
              aria-expanded={open ? "true" : undefined}
              aria-controls={open ? "basic-menu" : undefined}
              endIcon={<KeyboardArrowDownIcon />}
              sx={{
                width: 75,
                justifyContent: "flex-end",
              }}
            >
              {dropdownItems[selectedCount - 1]}
            </Button>
            <Menu
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              sx={{
                ".MuiMenu-paper": {
                  width: 75,
                  maxWidth: 75,
                  borderRadius: "6px",
                },
              }}
            >
              {dropdownItems.map((value, index) => (
                <MenuItem
                  key={value}
                  selected={index + 1 === selectedCount}
                  onClick={() => onSelectProjectCount(index + 1)}
                >
                  {value}
                </MenuItem>
              ))}
            </Menu>
            <Text whiteSpace="nowrap">
              <Trans
                values={{
                  currencySymbol: billingService.getCurrencySymbol(
                    team.subscription.additionalProjectsPrice.currency
                  ),
                  amount:
                    (team.subscription.additionalProjectsPrice.amount *
                      selectedCount) /
                    100,
                  interval: team.subscription.additionalProjectsPrice.interval,
                }}
              >
                PROJECT_LIMIT.SUBSCRIPTION.MODAL.PRICE
              </Trans>
            </Text>
          </Box>
          <Button
            variant="contained"
            loading={isLoading}
            onClick={
              variant === "ADD" ? handleProjectsUpdate : handleDeleteProject
            }
            color={variant === "ADD" ? "primary" : "error"}
          >
            {t(DIALOG_CONTENT[variant].submitButton)}
          </Button>
        </Box>
        {footerMessage[team.subscription.basePrice.name] && (
          <Text
            p={1}
            maxWidth={460}
            borderRadius={1}
            textAlign="left"
            bgcolor="primary.lighter"
          >
            <Trans
              components={{
                anchor: (
                  <Link
                    color="primary"
                    onClick={showPlanDialog}
                    fontWeight="fontWeight.medium"
                  />
                ),
              }}
            >
              {t(
                DIALOG_CONTENT[variant].footerMessage(
                  team.subscription.basePrice.name
                ),
                {
                  number: team.subscription.basePrice.limits.maxProjectNumber,
                }
              )}
            </Trans>
          </Text>
        )}
      </Box>
    </Dialog>
  );
}

AddOrRemoveProjectsDialog.propTypes = {
  team: teamProp.isRequired,
  variant: PropTypes.oneOf(["ADD", "REMOVE"]).isRequired,
};

export default NiceModal.create(AddOrRemoveProjectsDialog);
