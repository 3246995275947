import ConnectionInterrupted from "./pages/ConnectionInterrupted/ConnectionInterrupted";
import LinkInvalid from "./pages/LinkInvalid/LinkInvalid";

import { APP_ROUTES } from "./constants/routes";

export const routes = [
  {
    path: APP_ROUTES.LINK_INVALID.path,
    element: <LinkInvalid />,
    id: "LINK_INVALID",
  },
  {
    path: APP_ROUTES.CONNECTION_INTERRUPTED.path,
    element: <ConnectionInterrupted />,
    id: "CONNECTION_INTERRUPTED",
  },
  {
    path: APP_ROUTES.ACCESS_DENIED.path,
    element: <LinkInvalid />,
    id: "ACCESS_DENIED",
  },
];

export const unsupportedRoutes = [];
