import PropTypes from "prop-types";
import { useState, useCallback } from "react";

import { Box, TextField } from "@mui/material";

import { Text } from "@shared/UIKit";

import { automationFieldConditionProps } from "@shared/props/project";

function TriggerConditionsInput({ id, condition, onChange, defaultValue }) {
  const [value, setValue] = useState(
    defaultValue || condition.props.defaultValue
  );

  const updateValue = useCallback(
    ({ target }) => {
      const isValid = new RegExp(condition.props.pattern).test(target.value);
      const value = Number(target.value);
      if (isValid) {
        setValue(value);
        onChange(id, value);
      } else {
        /* istanbul ignore else */
        if (!target.value) {
          setValue(condition.props.defaultValue);
          onChange(id, condition.props.defaultValue);
        }
      }
    },
    [id, condition] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box display="flex" gap={2} alignItems="center">
      <TextField
        variant="outlined"
        id={id}
        size="small"
        value={value}
        onChange={updateValue}
        InputProps={{
          inputProps: {
            "data-testid": `automation-condition-input-${id}`,
          },
        }}
        sx={{ flex: 1 }}
      />
      <Text
        variant="subtitle2"
        color="text.secondary"
        translate={condition.text}
        sx={{ flex: 2 }}
      />
    </Box>
  );
}

TriggerConditionsInput.defaultProps = {
  defaultValue: null,
};

TriggerConditionsInput.propTypes = {
  condition: PropTypes.shape(automationFieldConditionProps).isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TriggerConditionsInput;
