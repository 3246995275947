import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useLocation } from "react-router-dom";

import { makeStyles, useTheme } from "@mui/styles";
import projectService from "@workflow/services/projectService";
import classNames from "classnames";

import { Box, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import FSTGTypography from "@shared/theme/typography";

import { PROJECT_LIST_ACTION_TYPES, useSidebarState } from "../../sidebarState";
import Folder from "../Folder/Folder";
import FavoriteProjectSection from "./FavoriteProjectSection";
import useLazyRenderChunks from "./hooks/useLazyRenderChunks";
import useScrollToProjectListItem from "./hooks/useScrollToProjectListItem";
import { ProjectListScrollContainerContext } from "./projectListScrollContainerContext";

const useStyles = makeStyles((theme) => ({
  scrollContainer: {
    height: 0,
    overflowX: "hidden",
    overflowY: "auto",
    paddingTop: 16,
    [theme.breakpoints.up("sm")]: {
      background: `
        linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%
      `,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 40px, 100% 40px, 100% 14px, 100% 14px",
      backgroundAttachment: "local, local, scroll, scroll",
    },
  },
  externalTeam: {
    border: `1px solid ${theme.color.gray[200]}`,
    borderRadius: 2,
    overflow: "hidden",
    backgroundColor: theme.color["white-smoke-light-translucent"],
    "&:hover": {
      borderColor: theme.color.gray[50],
    },
    "&:first-child": {
      marginTop: 20,
    },
  },
  externalTeamHeader: {
    padding: "10px 10px 4px",
  },
  externalTeamContainer: {
    marginTop: 16,
    borderTop: `1px solid ${theme.color.gray[200]}`,
  },
  externalProjectsTitle: {
    padding: "16px 16px 4px 16px",
  },
  externalTeamName: {
    padding: "0 5px",
  },
}));

const ProjectSection = ({ onCollapse, folderTeamProjects }) => {
  const theme = useTheme();
  const { state, dispatch } = useSidebarState();
  const styles = useStyles();
  const scrollContainerRef = useRef(null);
  const { mapProjectsToChunks, projectToChunk } = useScrollToProjectListItem();
  const { addChunk, removeChunk, chunks } =
    useLazyRenderChunks(scrollContainerRef);
  const [isProjectRoute, setIsProjectRoute] = useState(false);

  const newLayoutEnabled = folderTeamProjects.length > 0;

  const location = useLocation();

  useEffect(() => {
    const isCurrentRouteProject = location.pathname.includes(
      APP_ROUTES.DASHBOARD.path
    );
    setIsProjectRoute(isCurrentRouteProject);
  }, [location.pathname]);

  /* istanbul ignore next */
  const onDragEnd = useCallback(
    ({ destination, source }) => {
      if (destination) {
        const project = state.sortedFolders.find(
          ({ id }) => id === source.droppableId
        ).projects[source.index];
        projectService.moveProjectToFolder(project, destination.droppableId);
      }
      dispatch({
        type: PROJECT_LIST_ACTION_TYPES.CHANGE_DND_SOURCE,
        payload: { dndSourceId: "" },
      });
    },
    [dispatch, state.sortedFolders]
  );
  /* istanbul ignore next */
  const onDragStart = useCallback(
    ({ source }) => {
      const { droppableId } = source;
      dispatch({
        type: PROJECT_LIST_ACTION_TYPES.CHANGE_DND_SOURCE,
        payload: { dndSourceId: droppableId },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (projectToChunk?.current?.size <= 0) {
      return;
    }

    requestAnimationFrame(() => {
      eventService.emitEvent({
        eventName: EVENT_NAMES.PROJECT.OPENED,
        eventData: { project: state.selectedProject },
      });
    });
  }, [projectToChunk, projectToChunk?.current?.size, state.selectedProject]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      className={classNames(styles.scrollContainer, "tour__projects-sidebar")}
      data-testid="project-list-scroll-container"
      ref={scrollContainerRef}
    >
      <ProjectListScrollContainerContext.Provider
        value={{ addChunk, removeChunk, chunks, mapProjectsToChunks }}
      >
        <FavoriteProjectSection />
        <Box>
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            {
              /* istanbul ignore next */
              newLayoutEnabled &&
                scrollContainerRef &&
                scrollContainerRef.current &&
                folderTeamProjects.map((folder) => {
                  return (
                    <Folder
                      key={folder.id}
                      isProjectRoute={isProjectRoute}
                      onCollapse={() => onCollapse(folder)}
                      newLayoutEnabled
                      {...folder}
                    />
                  );
                })
            }
            {!newLayoutEnabled &&
              state.sortedFolders.map((folder) => {
                return (
                  <Folder
                    key={folder.id}
                    isProjectRoute={isProjectRoute}
                    onCollapse={() => onCollapse(folder)}
                    {...folder}
                  />
                );
              })}
          </DragDropContext>
          {state.sortedReviewerTeams.length > 0 && (
            <Box className={styles.externalTeamContainer}>
              <Text
                variant="body2"
                color={theme.color.gray[500]}
                className={styles.externalProjectsTitle}
                translate="SIDEBAR.EXTERNAL_PROJECTS.TITLE"
              />
              {state.sortedReviewerTeams.map(
                ({ teamId, teamName, folders }) => (
                  <DragDropContext
                    key={teamId}
                    onDragEnd={onDragEnd}
                    onDragStart={onDragStart}
                  >
                    <Box
                      key={teamId}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      className={styles.externalTeamHeader}
                    >
                      <Text
                        variant="body2"
                        fontWeight={FSTGTypography.fontWeightMedium}
                        color={theme.color.gray[800]}
                        className={styles.externalTeamName}
                      >
                        {teamName}
                      </Text>
                    </Box>
                    {folders.map((folder) => (
                      <Folder
                        key={folder.id}
                        isProjectRoute={isProjectRoute}
                        onCollapse={() => onCollapse(folder, true)}
                        {...folder}
                        isExternalTeam
                        isDragDisabled
                      />
                    ))}
                  </DragDropContext>
                )
              )}
            </Box>
          )}
        </Box>
      </ProjectListScrollContainerContext.Provider>
    </Box>
  );
};

ProjectSection.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  folderTeamProjects: PropTypes.array,
};

ProjectSection.defaultProps = {
  folderTeamProjects: [],
};

export default ProjectSection;
