import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import GridView from "@mui/icons-material/GridView";
import { IconButton } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { Tooltip } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { stepProps } from "@shared/props/step";

const selectUserIdAndSessionType = (user) => ({
  currentUserId: user._id,
  sessionType: user.session?.type,
});

const BatchReviewButton = ({ step, reviewId }) => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { currentUserId, sessionType } = useActiveUser(
    selectUserIdAndSessionType
  );

  const isReviewable = useMemo(() => {
    const reviewer = step.reviewers.find(
      (reviewer) => reviewer._id === currentUserId
    );

    return reviewer?.reviewDecisionRequested;
  }, [step.reviewers, currentUserId]);

  const isBatchReviewAllowed = isReviewable || sessionType === "Guest";

  const goToBatchReview = () => {
    goTo(ROUTE_STATES.BATCH_REVIEW, {
      params: {
        stepId: step.id,
      },
      search: new URLSearchParams({
        reviewId,
      }).toString(),
    });
  };

  return (
    <Tooltip
      title={t(
        isBatchReviewAllowed
          ? "BATCH_REVIEW.TOOTLIP"
          : "BATCH_REVIEW.NON_REVIEWER.TOOLTIP"
      )}
      placement="top"
    >
      <IconButton
        onClick={goToBatchReview}
        data-testid="batch-review-button"
        size="small"
      >
        <GridView fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

BatchReviewButton.propTypes = {
  step: stepProps.isRequired,
  reviewId: PropTypes.string,
};

BatchReviewButton.defaultProps = {
  reviewId: null,
};

export default BatchReviewButton;
