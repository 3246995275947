/**
 * Merge classes helper function
 *
 * @description This function takes MUI class objects and merges
 * them into one by uniqueing by attribute name.
 * @param {...Object} classMaps - [Required] MUI Classes that wanted to be merged.
 * @returns {Object} Returns a single class object.
 */
export default function mergeClasses(...classMaps) {
  return classMaps.reduce(
    (result, classMap) => merge2Classes(result, classMap),
    {}
  );
}

function merge2Classes(c1, c2) {
  return Object.fromEntries(
    unique([...Object.keys(c1), ...Object.keys(c2)]).map((key) => [
      key,
      c1[key] && c2[key] ? [c1[key], c2[key]].join(" ") : c1[key] || c2[key],
    ])
  );
}

function unique(list) {
  return [...new Set(list)];
}
