import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import MoreVert from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import toastService from "@supporting/services/toast";
import {
  AUTOMATION_CHECK_TYPE,
  checkIsUsedInAutomation,
} from "@workflow/helpers/checkIsUsedInAutomation";
import projectService from "@workflow/services/projectService";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import { ConfirmationDialog } from "@shared/UIKit";

import { useDialogState, useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import StepHeaderMenuList from "./StepHeaderMenuList/StepHeaderMenuList";

// eslint-disable-next-line complexity
function StepHeaderMenu({
  project,
  step,
  onRename,
  numberOfSteps,
  isTemplate,
  ...restProps
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const { isOpen, closeDialog, openDialog } = useDialogState();

  const { rename, manageSteps, canmanageTemplates } = project.permissions;
  const hasMenuItems = isTemplate
    ? canmanageTemplates
    : (project.permissions.rename && isMobile) ||
      project.permissions.manageSteps;
  const isActiveProject = isTemplate || !project.isArchived;

  const isMenuButtonVisible = hasMenuItems && isActiveProject;
  const isMenuActive =
    (isTemplate ? canmanageTemplates : manageSteps) && isActiveProject;
  const isRenameAllowed =
    (isTemplate ? canmanageTemplates : rename) && isMobile;
  const isDeleteAllowed = isTemplate ? canmanageTemplates : manageSteps;
  const position = step.position || 0;
  const isMoveRightAllowed = position < numberOfSteps - 1;
  const isMoveLeftAllowed = position > 0;

  const deleteStepHandler = async (step) => {
    await stepService.deleteStep(step);
    analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.REVIEW_STEP);
  };

  const deleteStep = useCallback(async () => {
    setAnchorEl(null);
    if (
      checkIsUsedInAutomation(
        project.automations,
        step.id,
        AUTOMATION_CHECK_TYPE.STEP
      )
    ) {
      toastService.sendToast({
        title: "DELETE_REVIEWER_GROUP.AUTOMATION.TOAST.TITLE",
        body: "DELETE_REVIEWER_GROUP.AUTOMATION.TOAST.BODY",
        preset: toastService.PRESETS().ERROR_DELAYED,
      });
      return;
    }
    try {
      if (isTemplate) {
        await projectTemplateService.deleteStepTemplate(step);
        analytics.track(
          analytics.ACTION.DELETED,
          analytics.CATEGORY.REVIEW_STEP
        );
      } else {
        const haveVersions = await stepService.hasReviews(step.id);
        const hasAutomations = projectService.hasAutomationsByProjectIdStepId(
          project.id,
          step.id
        );
        if (haveVersions || hasAutomations) {
          openDialog();
        } else {
          deleteStepHandler(step);
        }
      }
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [isTemplate, openDialog, project.automations, project.id, step]);

  const openMenu = useCallback(
    (event) => {
      /* istanbul ignore else */
      if (isMenuActive) {
        setAnchorEl(event.currentTarget);
      }
    },
    [isMenuActive]
  );

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMoveStep = useCallback(
    (increment) => {
      return async function () {
        setAnchorEl(null);
        try {
          analytics.track(
            analytics.ACTION.REORDERED,
            analytics.CATEGORY.REVIEW_STEP
          );
          if (isTemplate) {
            await projectTemplateService.updateStepPosition(
              step,
              position + increment
            );
          } else {
            await stepService.updateStepPosition(step, position + increment);
          }
        } catch (error) {
          errorHandlerService.handleError(error);
        }
      };
    },
    [step, position] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      {isMenuButtonVisible && (
        <IconButton
          aria-controls="step-header-menu"
          aria-haspopup="true"
          position="absolute"
          data-testid="step-header-menu-open-icon"
          onClick={openMenu}
          size="small"
        >
          <MoreVert fontSize="inherit" />
        </IconButton>
      )}

      {isMenuActive && anchorEl && (
        <StepHeaderMenuList
          anchorEl={anchorEl}
          closeMenu={closeMenu}
          isMoveLeftAllowed={isMoveLeftAllowed}
          isMoveRightAllowed={isMoveRightAllowed}
          isRenameAllowed={isRenameAllowed}
          isDeleteAllowed={isDeleteAllowed}
          onRename={onRename}
          moveStep={handleMoveStep}
          deleteStep={deleteStep}
          {...restProps}
        />
      )}
      {isOpen && (
        <ConfirmationDialog
          data-testid="remove-step-dialog"
          isOpen
          title={
            <Trans values={{ stepName: step.name }}>
              PROJECT.DIALOGS.DELETESTEP.HEADLINE
            </Trans>
          }
          description={t("PROJECT.DIALOGS.DELETESTEP.DESCRIPTION-1")}
          checkMessage={t("PROJECT.DIALOGS.DELETESTEP.DESCRIPTION-2")}
          cancelLabel={t("CORE.CANCEL")}
          confirmLabel={t("PROJECT.DIALOGS.DELETESTEP.CONFIRM")}
          closeDialog={closeDialog}
          answer={() => deleteStepHandler(step)}
        />
      )}
    </>
  );
}

StepHeaderMenu.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  step: stepProps.isRequired,
  onRename: PropTypes.func,
  numberOfSteps: PropTypes.number.isRequired,
  isTemplate: PropTypes.bool,
};

StepHeaderMenu.defaultProps = {
  onRename: null,
  isTemplate: false,
};

export default StepHeaderMenu;
