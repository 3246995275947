import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import DoneIcon from "@mui/icons-material/Done";
import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  newTeamMemberTextWrapper: {
    backgroundColor: theme.color.gray[50],
    border: `1px solid ${theme.color.gray[200]}`,
    padding: 5,
    borderRadius: 5,
  },
  newTeamMemberIcon: {
    color: theme.color["shamrock"],
  },
  newTeamMemberText: {
    marginLeft: 5,
    paddingTop: 2,
  },
}));

function CollaboratorNewMemberMessage({ newTeamMembers }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      mb={0.5}
      className={classes.newTeamMemberTextWrapper}
    >
      <Box display="flex" flexDirection="row" mb={0.5}>
        <DoneIcon fontSize="small" className={classes.newTeamMemberIcon} />
        <Text
          color={theme.color.gray[800]}
          variant="subtitle1"
          textAlign="left"
          className={classes.newTeamMemberText}
        >
          {newTeamMembers.map((member) => member.inputValue).join(", ")}{" "}
          {t(
            `COLLABORATORS.ADD.NEW_TEAM_MEMBER${
              newTeamMembers.length > 1 ? "_MANY" : ""
            }`
          )}
        </Text>
      </Box>
      <Box display="flex" flexDirection="row" mb={0.5}>
        <DoneIcon fontSize="small" className={classes.newTeamMemberIcon} />
        <Text
          className={classes.newTeamMemberText}
          variant="subtitle1"
          textAlign="left"
        >
          {newTeamMembers.length === 1 ? (
            <Trans>COLLABORATORS.ADD.DESCRIPTION_ONE</Trans>
          ) : (
            <Trans>COLLABORATORS.ADD.DESCRIPTION_MANY</Trans>
          )}
        </Text>
      </Box>
    </Box>
  );
}

CollaboratorNewMemberMessage.propTypes = {
  newTeamMembers: PropTypes.array.isRequired,
};

export default CollaboratorNewMemberMessage;
