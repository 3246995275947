import { useState, useEffect, useCallback } from "react";

import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import {
  AUTOMATION_KEY_VALUES,
  DIALOG_STATE,
} from "@workflow/constants/automation";

function useAutomationsState(project, steps) {
  const [automationsState, setAutomationsState] = useState(
    DIALOG_STATE.HAS_LIMIT
  );
  const [visibleAutomations, setVisibleAutomations] = useState([]);
  const team = useSelectedTeam();

  const determineDialogState = useCallback(
    (maxProjectAutomationNumber) => {
      const visibleAutomations = project.automations.filter(
        (automation) =>
          !automation.conditions.step ||
          automation.conditions.step === AUTOMATION_KEY_VALUES.ANY ||
          steps.some((step) => step.id === automation.conditions.step)
      );
      setVisibleAutomations(visibleAutomations);
      if (!maxProjectAutomationNumber || maxProjectAutomationNumber === 0) {
        return setAutomationsState(DIALOG_STATE.NOT_SUPPORTED);
      }

      if (maxProjectAutomationNumber === 1 && visibleAutomations.length > 0) {
        return visibleAutomations.length === 1
          ? setAutomationsState(DIALOG_STATE.PROFESSIONAL)
          : setAutomationsState(DIALOG_STATE.PROFESSIONAL_LIMIT);
      }

      setAutomationsState(DIALOG_STATE.HAS_LIMIT);
    },
    [project.automations, steps]
  );

  useEffect(() => {
    determineDialogState(team.subscriptionLimits.maxProjectAutomationNumber);
  }, [
    determineDialogState,
    team.subscriptionLimits.maxProjectAutomationNumber,
  ]);

  return [automationsState, visibleAutomations];
}

export default useAutomationsState;
