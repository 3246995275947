export const AUTOMATION_CONDITIONS_PROPERTIES = Object.freeze({
  1: ["step", "reviewer", "reviewDecision"],
  2: ["step"],
  3: ["step"],
  4: ["reviewStatus", "step"],
  6: ["setInactiveDays"],
});

export const AUTOMATION_KEY_VALUES = Object.freeze({
  ANY: "ANY",
  REVIEW_NEXT_STEP: "REVIEW_NEXT_STEP",
});

export const AUTOMATION_INPUT_TYPES = Object.freeze({
  SELECT: "SELECT",
  INPUT: "INPUT",
});

export const AUTOMATION_CARD_ACTION_KEYS = Object.freeze({
  changeReviewStatus: "AUTOMATIONS.ACTION.REVIEW_STATUS.CHANGE",
  setDueDate: "AUTOMATIONS.ACTION.SET_DUE_DATE",
  startReviewStep: "AUTOMATIONS.ACTION.START_REVIEW",
  moveFileToSection: "AUTOMATIONS.ACTION.MOVE_TO_SECTION",
});

export const AUTOMATION_TRANSLATIONS = Object.freeze({
  REVIEWER_SUBMITS_REVIEW_DECISION:
    "AUTOMATIONS.TRIGGER.REVIEW_DECISION.SUBMIT",
  STEP: "AUTOMATIONS.CONDITION.IF.INPUT_LABEL.REVIEW_STEP",
  ANY: "AUTOMATIONS.CONDITION.IF.INPUT_OPTIONS.ALL-REVIEW-STEPS",
  REVIEWER: "AUTOMATIONS.CONDITION.IF.INPUT_LABEL.WHO",
  ALL_REVIEWERS: "AUTOMATIONS.CONDITION.IF.INPUT_OPTIONS.ALL_REVIEWERS",
  AT_LEAST_ONE_REVIEWER: "AUTOMATIONS.CONDITION.IF.INPUT_OPTIONS.ONE_REVIEWER",
  REVIEW_DECISION: "AUTOMATIONS.CONDITION.IF.INPUT_LABEL.REVIEW_DECISION",
  APPROVED: "AUTOMATION.CARD.REVIEW_STATUS.APPROVED",
  REQUEST_CHANGES: "AUTOMATIONS.CONDITION.IF.INPUT_OPTIONS.REQUESTED_CHANGES",
  APPROVED_WITH_CHANGES:
    "UNDO_REVIEW_DECISION.TOAST.APPROVED_WITH_CHANGES.TITLE",
  REJECTED: "UNDO_REVIEW_DECISION.TOAST.REJECTED.TITLE",
  CHANGE_REVIEW_STATUS: "AUTOMATIONS.ACTION.REVIEW_STATUS.CHANGE",
  IN_REVIEW: "AUTOMATION.CARD.REVIEW_STATUS.IN_REVIEW",
  NEEDS_CHANGES: "AUTOMATION.CARD.REVIEW_STATUS.NEEDS_CHANGES",
  REVIEW_IN_NEXT_STEP: "AUTOMATIONS.ACTION.START_REVIEW.NEXT_STEP",
  SECTION: "AUTOMATIONS.ACTION.MOVE_TO_SECTION",
  REVIEW_NEXT_STEP: "START_REVIEW.AUTOMATION.DROPDOWN",
  REVIEW_DECISION_STATES: {
    APPROVED: "AUTOMATION.CARD.REVIEW_STATUS.APPROVED",
    NEEDS_CHANGES: "AUTOMATIONS.CONDITION.IF.INPUT_OPTIONS.REQUESTED_CHANGES",
    APPROVED_WITH_CHANGES:
      "UNDO_REVIEW_DECISION.TOAST.APPROVED_WITH_CHANGES.TITLE",
    REJECTED: "UNDO_REVIEW_DECISION.TOAST.REJECTED.TITLE",
  },
});

export const DIALOG_STATE = Object.freeze({
  HAS_LIMIT: "HAS_LIMIT",
  NOT_SUPPORTED: "NOT_SUPPORTED",
  PROFESSIONAL: "PROFESSIONAL",
  PROFESSIONAL_LIMIT: "PROFESSIONAL_LIMIT",
});

export const UPGRADE_MESSAGE_TRANSLATIONS = Object.freeze({
  NOT_SUPPORTED: "AUTOMATIONS.UPGRADE.FREE.MESSAGE",
  PROFESSIONAL: "AUTOMATIONS.UPGRADE.PROFESSIONAL.MESSAGE.1",
  PROFESSIONAL_LIMIT: "AUTOMATIONS.UPGRADE.PROFESSIONAL.MESSAGE.2",
});
