import { PublicClientApplication } from "@azure/msal-browser";

import STORAGE from "@shared/constants/storage";
import urlUtils from "@shared/helpers/urlUtils";

const loginRequest = {
  scopes: ["OneDrive.ReadWrite"],
};

let authData = {};

export const msalConfig = {
  auth: {
    clientId: window.fs?.config.oneDrive.clientID,
    authority: "https://login.microsoftonline.com/consumers",
    redirectUri: window.fs?.config.oneDrive.redirectURI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

let msalInstance = null;

/**
 * Use One Drive hook
 *
 * @description This hook loads the One Drive API into DOM and returns
 * function to launch the picker.
 * Usually being used on Upload Panel component.
 * @returns {{
 *  showFilePicker: function,
 * }} Returns the `launchPicker` values.
 */
export default function useOneDrive() {
  const requestAPIData = async () => {
    if (msalInstance === null) {
      msalInstance = new PublicClientApplication(msalConfig);
      await msalInstance.initialize();
    }
    if (authData && authData.accessToken && authData.account) {
      return authData;
    }
    let data = null;

    if (authData && authData.account) {
      data = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: authData.account,
      });
    } else {
      data = await msalInstance.loginPopup(loginRequest);
    }

    authData = {
      accessToken: data.accessToken,
      account: data.account,
    };
    return authData;
  };

  const baseUrl = "https://onedrive.live.com/picker";

  const params = {
    sdk: "8.0",
    authentication: {},
    entry: {
      oneDrive: {
        files: {},
      },
    },
    messaging: {
      origin: urlUtils.getBaseURI(),
      channelId: "27",
    },
    typesAndSources: {
      mode: "files",
      filters: [],
      pivots: {
        oneDrive: true,
        photos: false,
        recent: false,
      },
    },
  };

  async function getFileData(file, token) {
    const headers = new Headers();
    const bearer = `Bearer ${token}`;

    headers.append("Authorization", bearer);

    const options = {
      method: "GET",
      headers,
    };

    const URL = `${file["@sharePoint.endpoint"]}/drives/${file.parentReference.driveId}/items/${file.id}`;

    const resp = await fetch(URL, options);
    const result = await resp.json();
    return {
      mimeType: result.file?.mimeType,
      url: result["@content.downloadUrl"],
    };
  }

  const buildOneDriveFile = async (file, token) => {
    const fileData = await getFileData(file, token);
    return {
      isRemote: true,
      name: file.name,
      type: fileData.mimeType,
      size: parseInt(file.size, 10),
      url: fileData.url,
    };
  };

  async function launchPicker(event, callback, teamId, enableMultiSelect) {
    event.preventDefault();

    const authData = await requestAPIData();

    let port = null;

    const win = window.open("", "Picker", "width=800,height=600");
    const fileUploadHandler = callback;
    const currentTeamId = teamId;

    const queryString = new URLSearchParams({
      filePicker: JSON.stringify({
        ...params,
        selection: {
          mode: enableMultiSelect ? "multiple" : "single",
        },
      }),
    });

    const url = `${baseUrl}?${queryString}`;

    const form = win.document.createElement("form");
    form.setAttribute("action", url);
    form.setAttribute("method", "POST");
    win.document.body.append(form);

    const input = win.document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", "access_token");
    input.setAttribute("value", authData.accessToken);
    form.appendChild(input);

    form.submit();

    function messageListener(message) {
      let command;
      const token = authData.accessToken;
      const handlePick = async (selectedFiles, teamId) => {
        const files = await Promise.all(
          selectedFiles.map(async (file) => {
            return await buildOneDriveFile(file, token);
          })
        );
        fileUploadHandler(files, teamId, STORAGE.ONE_DRIVE);
      };

      switch (message.data.type) {
        case "notification":
          break;

        case "command":
          port.postMessage({
            type: "acknowledge",
            id: message.data.id,
          });

          command = message.data.data;
          switch (command.command) {
            case "authenticate":
              /* istanbul ignore next */
              if (typeof token !== "undefined" && token !== null) {
                port.postMessage({
                  type: "result",
                  id: message.data.id,
                  data: {
                    result: "token",
                    token,
                  },
                });
              }
              break;

            case "close":
              win.close();
              break;

            case "pick":
              handlePick(command.items, currentTeamId);
              port.postMessage({
                type: "result",
                id: message.data.id,
                data: {
                  result: "success",
                },
              });

              win.close();

              break;

            default:
              port.postMessage({
                result: "error",
                error: {
                  code: "unsupportedCommand",
                  message: command.command,
                },
                isExpected: true,
              });
              break;
          }

          break;
      }
    }

    window.addEventListener("message", (event) => {
      if (event.source && event.source === win) {
        const message = event.data;

        if (
          message.type === "initialize" &&
          message.channelId === params.messaging.channelId
        ) {
          port = event.ports[0];

          port.addEventListener("message", messageListener);

          port.start();

          port.postMessage({
            type: "activate",
          });
        }
      }
    });
  }

  return {
    launchPicker,
  };
}
