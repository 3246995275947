import PropTypes from "prop-types";
import { createContext, useContext, useState } from "react";

export const initialSelectContext = {
  selectedFiles: [],
  excludedFiles: [],
  selectAll: false,
  isSelectionState: false,
  isFileSelected: () => {},
};
export const SelectedFilesContext = createContext(initialSelectContext);

export const useSelectedFiles = () => useContext(SelectedFilesContext);

export const SelectFilesProvider = ({ children }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [excludedFiles, setExcludedFiles] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isSelectionState, setIsSelectionState] = useState(false);

  const toggleSelectAll = (value) => {
    setIsSelectionState(true);
    setSelectedFiles([]);
    setExcludedFiles([]);
    setSelectAll(value);
  };

  const handleFileExclusion = (fileId) => {
    if (excludedFiles.includes(fileId)) {
      setExcludedFiles(excludedFiles.filter((file) => file !== fileId));
    } else {
      setExcludedFiles([...excludedFiles, fileId]);
    }
  };

  const handleFileSelection = (fileId) => {
    if (selectedFiles.includes(fileId)) {
      setSelectedFiles(selectedFiles.filter((file) => file !== fileId));
    } else {
      setSelectedFiles([...selectedFiles, fileId]);
    }
  };

  const toggleSelectFile = (fileId) => {
    setIsSelectionState(true);
    if (selectAll) {
      handleFileExclusion(fileId);
    } else {
      handleFileSelection(fileId);
    }
  };

  const exitSelectState = () => {
    setIsSelectionState(false);
    setSelectedFiles([]);
    setExcludedFiles([]);
    setSelectAll(false);
  };

  if (
    !excludedFiles.length &&
    !selectedFiles.length &&
    !selectAll &&
    isSelectionState
  ) {
    exitSelectState();
  }

  const isFileSelected = (fileId) =>
    selectAll
      ? !excludedFiles.includes(fileId)
      : selectedFiles.includes(fileId);

  const value = {
    selectedFiles,
    excludedFiles,
    selectAll,
    isSelectionState,
    exitSelectState,
    toggleSelectAll,
    toggleSelectFile,
    isFileSelected,
  };

  return (
    <SelectedFilesContext.Provider value={value}>
      {children}
    </SelectedFilesContext.Provider>
  );
};

SelectFilesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
