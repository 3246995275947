import { useCallback, useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { Check } from "@mui/icons-material";
import { Box, Button, Link } from "@mui/material";
import EmailInput from "@supporting/components/EmailInput/EmailInput";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import authenticationService from "@supporting/services/authentication";

import { Text } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import pageTitleService from "@shared/services/pageTitleService";

import FormFeedback from "../../../feedback/pages/ValidateStepPassword/FormFeedback";

import ForgotPasswordOtp from "./ForgotPasswordOtp/ForgotPasswordOtp";

const ENTER_OTP_PASSWORD = "sendOTP";
const ENTER_EMAIL = "enterEmail";
const PASSWORD_UPDATED_SUCCESS = "updatedPasswordSuccess";

function ForgotPassword() {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const [searchParams] = useSearchParams();

  const [formState, setFormState] = useState(ENTER_EMAIL);
  const [emailState, setEmailState] = useState({
    isEmailValid: false,
    email: searchParams.get("email"),
  });
  const [response, setResponse] = useState("");

  const submitForm = useCallback(
    async (event) => {
      try {
        event.preventDefault();

        if (!emailState.isEmailValid) {
          return;
        }

        analytics.track(
          analytics.ACTION.RESET_PASSWORD,
          analytics.CATEGORY.USER
        );

        const { requireOTP } = await authenticationService.forgotPassword(
          emailState.email
        );
        if (requireOTP) {
          setFormState(ENTER_OTP_PASSWORD);
        } else {
          setResponse("success");
        }
      } catch (err) {
        setFormState(ENTER_EMAIL);
        setResponse("error");
      }
    },
    [emailState]
  );

  const passwordUpdateSuccess = useCallback(() => {
    setFormState(PASSWORD_UPDATED_SUCCESS);
  }, []);

  const navigateToForgotPassword = () => {
    goTo(ROUTE_STATES.LOGIN);
  };

  const renderForm = (type) => {
    switch (type) {
      case ENTER_EMAIL:
      default:
        return (
          <Box gap={2} display="flex" maxWidth={400} flexDirection="column">
            <Text
              variant="text3xl"
              fontWeight="fontWeight.medium"
              translate="FORGOT_PW.HEADLINE"
            />
            <Text
              color="grey.600"
              variant="textMd"
              translate="FORGOT_PW.INSTRUCTIONS"
            />
            <Box
              gap={2}
              component="form"
              onSubmit={submitForm}
              noValidate
              display="flex"
              flexDirection="column"
            >
              <EmailInput
                updateAuthState={setEmailState}
                defaultErrorMessage="FORM.EMAIL_CURRENT.MESSAGE_REQUIRED"
                label="FORM.EMAIL.LABEL"
                autoFocusEmail
                defaultValue={searchParams.get("email")}
              />
              {response === "error" && (
                <FormFeedback variant="error">
                  {t("ERROR.GENERAL")}
                </FormFeedback>
              )}
              {response === "success" && (
                <FormFeedback variant="success">
                  {t("FORGOT_PW.EMAIL_SENT")}
                </FormFeedback>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                data-testid="reset-password-link-button"
              >
                {t("FORGOT_PW.CTA.CONTINUE")}
              </Button>
            </Box>
          </Box>
        );

      case ENTER_OTP_PASSWORD:
        return (
          <ForgotPasswordOtp
            email={emailState.email}
            passwordUpdateSuccess={passwordUpdateSuccess}
          />
        );

      case PASSWORD_UPDATED_SUCCESS:
        return (
          <Box
            gap={2}
            display="flex"
            flexDirection="column"
            data-testid="password-update-success"
          >
            <Check color="primary" sx={{ fontSize: "4rem" }} />
            <Text
              variant="text3xl"
              fontWeight="fontWeight.medium"
              translate="FORGOT_PW.SUCCESS.HEADLINE"
            />
            <Text variant="textMd">
              <Trans
                components={{
                  anchor: <Link color="secondary" />,
                }}
              >
                {t("FORGOT_PW.SUCCESS.INSTRUCTIONS")}
              </Trans>
            </Text>
          </Box>
        );
    }
  };

  useEffect(() => {
    pageTitleService.setTitle(t("FORGOT_PW.PAGE-TITLE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WizardBase variant="LOGIN">
      <Box flex={1} display="flex" flexDirection="row" justifyContent="center">
        <Box flex="1 1 100%" display="flex" flexDirection="column" gap={2}>
          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            {renderForm(formState)}
          </Box>
          {formState !== PASSWORD_UPDATED_SUCCESS && (
            <Link
              color="secondary"
              fontSize="textSm.fontSize"
              onClick={navigateToForgotPassword}
              alignSelf="center"
            >
              {t("SUBTEXT.BACKTOLOGIN_LINK")}
            </Link>
          )}
        </Box>
      </Box>
    </WizardBase>
  );
}

export default ForgotPassword;
