import PropTypes from "prop-types";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";

import ReviewStatusBadge from "../ReviewStatusBadge/ReviewStatusBadge";

const useStyles = makeStyles((theme) => ({
  reviewSection: {
    borderRadius: 4,
    cursor: "pointer",
    "&:hover": {
      "& > svg": {
        fill: theme.color.white,
      },
      "&$inReview": {
        backgroundColor: theme.palette.inReview.dark,
        color: theme.color.white,
        "& h6": {
          color: theme.color.white,
        },
      },
      "&$needsChanges": {
        backgroundColor: theme.palette.needsChanges.dark,
        color: theme.color.white,
        "& h6": {
          color: theme.color.white,
        },
      },
      "&$approved": {
        backgroundColor: theme.palette.approved.dark,
        color: theme.color.white,
        "& h6": {
          color: theme.color.white,
        },
      },
    },
  },
  inReview: {
    backgroundColor: theme.palette.inReview.light,
    color: theme.palette.inReview.main,
    "& > svg": {
      fill: theme.palette.inReview.main,
    },
  },
  needsChanges: {
    backgroundColor: theme.palette.needsChanges.light,
    color: theme.palette.needsChanges.main,
    "& > svg": {
      fill: theme.palette.needsChanges.main,
    },
  },
  approved: {
    backgroundColor: theme.palette.approved.light,
    color: theme.palette.approved.main,
    "& > svg": {
      fill: theme.palette.approved.main,
    },
  },
}));

function ReviewStatusMenuButton({
  openMenu,
  reviewState,
  isReadOnly,
  className,
}) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      onClick={openMenu}
      data-testid="step-review"
      width="auto"
      height={20}
      alignItems="center"
      pl={0.25}
      pr={0.5}
      mr={-0.5}
      className={classnames(
        className,
        classes.reviewSection,
        {
          [classes.approved]: reviewState === REVIEW_STATUS.APPROVED,
          [classes.inReview]: reviewState === REVIEW_STATUS.IN_REVIEW,
          [classes.needsChanges]: reviewState === REVIEW_STATUS.NEEDS_CHANGES,
        },
        "tour__review-status"
      )}
    >
      <Box width={10} height={20} pr={1}>
        <ExpandMoreIcon fontSize="small" />
      </Box>
      <ReviewStatusBadge reviewState={reviewState} isReadOnly={isReadOnly} />
    </Box>
  );
}

ReviewStatusMenuButton.propTypes = {
  openMenu: PropTypes.func.isRequired,
  reviewState: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  className: PropTypes.string,
};
ReviewStatusMenuButton.defaultProps = {
  className: "",
};

export default ReviewStatusMenuButton;
