/* This hook wraps the react-router-dom useSearchParams hook and returns:
 * - the search params
 * - a function to update the search params via the setSearchParams or via the history object
 */

import { useSearchParams } from "react-router-dom";

export const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = (newSearchParams, replace = false) => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    Object.keys(newSearchParams).forEach((key) => {
      const value = newSearchParams[key];
      if (value === undefined || value === null) {
        currentSearchParams.delete(key);
      } else {
        currentSearchParams.set(key, value);
      }
    });
    const newUrl = [window.location.pathname, currentSearchParams.toString()]
      .filter(Boolean)
      .join("?");
    if (replace) {
      window.history.replaceState(null, "", newUrl);
    } else {
      setSearchParams(currentSearchParams);
    }
  };

  return [searchParams, updateSearchParams];
};
