import PropTypes from "prop-types";

import AddSection from "@workflow/components/AddSection/AddSection";
import EmptySectionItem from "@workflow/components/Section/EmptySectionItem";
import ProjectFile from "@workflow/components/Section/ProjectFile/ProjectFile";
import SectionHeaderItem from "@workflow/components/SectionHeaderItem";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import ProjectFileLoadingState from "./ProjectFileLoadingState/ProjectFileLoadingState";
import { swimlaneRowTypesEnum } from "./SwimlaneRowTypesEnum";

const STEP_WIDTH = 295;

function RenderRow({
  data,
  index,
  toggleOpenVersions,
  openedFileIds,
  isDragActive,
  sectionDragActive,
  isSectionRowActiveSticky,
  isLastItemInSection,
  showSectionHeader,
}) {
  const { items, steps, project, team, isTeamMember } = data;

  const item = items[index];

  if (!item) {
    return null;
  }

  if (item.type === swimlaneRowTypesEnum.LOADER) {
    return (
      <ProjectFileLoadingState
        numberOfItems={5}
        numberOfSteps={steps.length}
        showSectionHeader={showSectionHeader}
      />
    );
  }

  if (
    item.type === swimlaneRowTypesEnum.EMPTY_SECTION ||
    item.type === swimlaneRowTypesEnum.EMPTY_SECTION_NO_UPLOAD_ACCESS
  ) {
    return (
      <EmptySectionItem
        project={project}
        isDragActive={isDragActive}
        steps={steps}
      />
    );
  }

  if (item.type === swimlaneRowTypesEnum.SECTION) {
    return (
      <SectionHeaderItem
        section={item}
        steps={steps}
        project={project}
        team={team}
        sectionDragActive={sectionDragActive}
        isSectionRowActiveSticky={isSectionRowActiveSticky}
      />
    );
  }

  if (item.type === swimlaneRowTypesEnum.FILE) {
    return (
      <ProjectFile
        key={`${item._id}`}
        team={team}
        file={item}
        isTeamMember={isTeamMember}
        steps={steps}
        project={project}
        rowIndex={index}
        onVersionOpen={toggleOpenVersions}
        isOpenedVersionSection={openedFileIds.includes(item._id)}
        isDragActive={isDragActive}
        isLastItemInSection={isLastItemInSection}
      />
    );
  }

  if (item.type === swimlaneRowTypesEnum.ADD_SECTION) {
    return (
      <Box width={`${steps.length * STEP_WIDTH}px`}>
        <AddSection project={project} team={team} />
      </Box>
    );
  }

  return null;
}

RenderRow.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    // @TODO: create type variants instead object (https://app.asana.com/0/948797479489416/1203012362327955/f)
    items: PropTypes.arrayOf(PropTypes.object),
    steps: PropTypes.arrayOf(stepProps),
    project: PropTypes.shape(projectProps),
    team: teamProp.isRequired,
    isTeamMember: PropTypes.bool,
  }).isRequired,
  toggleOpenVersions: PropTypes.func.isRequired,
  openedFileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDragActive: PropTypes.bool,
  isLastItemInSection: PropTypes.bool,
  sectionDragActive: PropTypes.array,
  isSectionRowActiveSticky: PropTypes.bool,
  showSectionHeader: PropTypes.bool,
};

RenderRow.defaultProps = {
  isDragActive: false,
  isLastItemInSection: false,
  sectionDragActive: [],
  isSectionRowActiveSticky: false,
  showSectionHeader: false,
};

export default RenderRow;
