import PropTypes from "prop-types";
import { useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const folderFormSchema = z.object({
  folderName: z.string().min(1, "VALIDATIONS.FOLDER_NAME.REQUIRED"),
});

const useFolderForm = (defaultValues) => {
  return useForm({
    resolver: zodResolver(folderFormSchema),
    mode: "onChange",
    defaultValues: {
      folderName: defaultValues?.name || "",
    },
  });
};

useFolderForm.propTypes = {
  defaultValues: PropTypes.shape({
    name: PropTypes.string,
  }),
};

useFolderForm.defaultProps = {
  defaultValues: null,
};

export default useFolderForm;
