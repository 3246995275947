import { PropTypes } from "prop-types";

export const apiKeyProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  token: PropTypes.shape({
    first: PropTypes.string.isRequired,
    last: PropTypes.string.isRequired,
  }),
  createdAt: PropTypes.string.isRequired,
});
