import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Divider,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { instance as billingService } from "@supporting/services/billingService";
import toastService from "@supporting/services/toast";

import { Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import AddSeatsDialog from "./AddSeatsDialog";
import RemoveSeatsDialog from "./RemoveSeatsDialog";
import UsageBar from "./UsageBar";
import UsageTooltip from "./UsageTooltip";

function SeatUsage({ team, openBillingDialog }) {
  const { t } = useTranslation();

  const [isAddSeatsDialogOpen, setIsAddSeatsDialogOpen] = useState(false);
  const [isRemoveSeatsDialogOpen, setIsRemoveSeatsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const getTeamMemberLimit = () => {
    if (!team.subscriptionLimits.includedUsers) {
      return team.members?.length;
    }

    if (team.subscriptionLimits.includedUsers > -1) {
      return team.subscriptionLimits.includedUsers;
    }

    return t("BILLING.UNLIMITED");
  };

  const handleAddSeats = useCallback(
    async (seatCount) => {
      setIsAddSeatsDialogOpen(false);
      try {
        await billingService.updateSeatCount(team._id, seatCount);
        analytics.track(analytics.ACTION.ADDED, analytics.CATEGORY.SEATS, {
          label: analytics.LABEL.ADD_SEATS_DIALOG,
        });
      } catch (error) {
        if (error.message === "SUBSCRIPTION_ON_SCHEDULE") {
          toastService.sendToast({
            title: "SUBSCRIPTION.SEATS_ADD_ERROR.TOAST.TITLE",
            body: "SUBSCRIPTION.SEATS_ADD_ERROR.TOAST.BODY",
            preset: toastService.PRESETS().ERROR_DELAYED,
          });
        } else {
          errorHandlerService.handleError(error);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team._id]
  );

  const handleRemoveSeat = useCallback(
    async (seatCount) => {
      setIsRemoveSeatsDialogOpen(false);
      try {
        await billingService.updateSeatCount(team._id, -seatCount);
        analytics.track(analytics.ACTION.REMOVED, analytics.CATEGORY.SEATS);
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team._id]
  );
  const addSeatDialogOpen = useCallback(() => {
    setIsAddSeatsDialogOpen(true);
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.ADD_SEATS_DIALOG
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addSeatDialogClose = useCallback(() => {
    setIsAddSeatsDialogOpen(false);
  }, []);

  const removeSeatDialogOpen = useCallback(() => {
    handleClose();
    setIsRemoveSeatsDialogOpen(true);
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.REMOVE_SEATS_DIALOG
    );
  }, [handleClose]); // eslint-disable-line react-hooks/exhaustive-deps

  const removeSeatDialogClose = useCallback(() => {
    setIsRemoveSeatsDialogOpen(false);
  }, []);

  const determineUsagePercentage = useCallback(() => {
    return team.subscriptionLimits.includedUsers === 0
      ? 100
      : Math.max(
          (team.members?.length / team.subscriptionLimits.includedUsers) * 100,
          0
        );
  }, [team.members?.length, team.subscriptionLimits.includedUsers]);

  return (
    <>
      <UsageBar
        name="BILLING.FEATURE.TEAM_MEMBERS.LABEL"
        tooltip={
          <UsageTooltip
            usageMessage1={t("BILLING.FEATURE.TEAM_MEMBERS.HELP_MESSAGE.PART1")}
            hrefText={
              team.subscriptionLimits.includedUsers > -1
                ? t("BILLING.UPGRADE_YOUR_PLAN")
                : null
            }
            openBillingDialog={openBillingDialog}
            usageMessage2={t("BILLING.FEATURE.TEAM_MEMBERS.HELP_MESSAGE.PART2")}
          />
        }
        used={team.members?.length}
        limit={getTeamMemberLimit()}
        percentage={determineUsagePercentage()}
        isLinearProgressVisible={team.subscriptionLimits.includedUsers !== -1}
      />
      {team.subscriptionLimits.includedUsers > -1 &&
        team.subscription.additionalPrice && (
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              size="small"
              onClick={addSeatDialogOpen}
              variant="text"
              color="upgrade"
              data-testid="add-seats"
              startIcon={<AddIcon />}
            >
              {t("BILLING.FEATURE.ADD-SEATS.CTA")}
            </Button>
            <Divider orientation="vertical" flexItem py={1} />
            <IconButton
              aria-label="settings"
              onClick={handleClick}
              data-testid="usage-menu"
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
            {anchorEl && (
              <Menu
                id="subscription-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={removeSeatDialogOpen}
                  data-testid="remove-seats"
                >
                  <ListItemIcon>
                    <CancelOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {t("BILLING.FEATURE.REMOVE-SEATS.CTA")}
                  </ListItemText>
                </MenuItem>
              </Menu>
            )}
            <AddSeatsDialog
              isOpen={isAddSeatsDialogOpen}
              cancel={addSeatDialogClose}
              answer={handleAddSeats}
              team={team}
            />
            <RemoveSeatsDialog
              isOpen={isRemoveSeatsDialogOpen}
              cancel={removeSeatDialogClose}
              answer={handleRemoveSeat}
              team={team}
            />
          </Box>
        )}
    </>
  );
}

SeatUsage.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default SeatUsage;
