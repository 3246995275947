import PropTypes from "prop-types";
import { ReactElement } from "react";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

const useStyles = makeStyles((theme) => ({
  indicator: {
    width: 16,
    height: 16,
    transition: `transform 0.4s ${theme.animation.easeOutQuint}`,
    border: `2px solid ${theme.color.white}`,
    backgroundColor: theme.color.gray[200],
  },
  noBorder: {
    border: 0,
  },
  waiting: {
    backgroundColor: theme.palette.pending.main,
    "&.overflow:nth-last-child(2)": {
      backgroundColor: theme.palette.pending.light,
    },
    "&.overflow:last-child": {
      backgroundColor: theme.palette.pending.lighter,
    },
  },
  approved: {
    backgroundColor: theme.palette.approved.main,
    "&.overflow:nth-last-child(2)": {
      backgroundColor: theme.palette.approved.light,
    },
    "&.overflow:last-child": {
      backgroundColor: theme.palette.approved.lighter,
    },
  },
  needsChanges: {
    backgroundColor: theme.palette.needsChanges.main,
    "&.overflow:nth-last-child(2)": {
      backgroundColor: theme.palette.needsChanges.light,
    },
    "&.overflow:last-child": {
      backgroundColor: theme.palette.needsChanges.lighter,
    },
  },
  rejected: {
    backgroundColor: theme.palette.rejected.main,
    "&.overflow:nth-last-child(2)": {
      backgroundColor: theme.palette.rejected.light,
    },
    "&.overflow:last-child": {
      backgroundColor: theme.palette.rejected.lighter,
    },
  },
  reviewApproved: {
    border: `2px solid ${theme.color.green[50]}`,
  },
}));

/**
 * UIKit Review Decision Indicator Component
 * @description This component is used to display the review decision indicator.
 * It contains a MUI Avatar component that will change the colors depending on the state.
 * @param {object} props - Component
 * @param {string} [props.variant] [Optional] - The variant of the button.
 * It can be `noBorder` or `default`. Default is `default`.
 * @param {string} [props.state] [Optional] - The state of the review decision indicator.
 * It can be one of `PENDING`, `WAITING`, `APPROVED` or `NEEDS_CHANGES`. Default is `PENDING`.
 * @param {boolean} [props.isApproved] [Optional] - If the review is approved. Default is `false`.
 * @param {boolean} [props.isOverflowing] [Optional] - If the indicator is overflowed. Default is `false`.
 * @param {string} [props.className] [Optional] - Additional class name for styling.
  @returns {ReactElement} - React component
 */
function ReviewDecisionIndicator({
  variant,
  state,
  className,
  isApproved,
  isOverflowing,
  ...restProps
}) {
  const classes = useStyles();
  return (
    <Avatar
      data-testid="review-decision-indicator"
      className={classnames(
        "tour__review-decision-dashboard",
        classes.indicator,
        {
          [classes.noBorder]: variant === "noBorder",
          [classes.waiting]: state === "WAITING" || state === "PENDING",
          [classes.approved]:
            state === REVIEW_DECISION_STATES.APPROVED ||
            state === REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES,
          [classes.needsChanges]:
            state === REVIEW_DECISION_STATES.NEEDS_CHANGES,
          [classes.rejected]: state === REVIEW_DECISION_STATES.REJECTED,
          [classes.reviewApproved]: isApproved,
          ["overflow"]: isOverflowing,
        },
        className
      )}
      {...restProps}
    >
      &nbsp;
    </Avatar>
  );
}

ReviewDecisionIndicator.propTypes = {
  state: PropTypes.oneOf([
    "WAITING",
    "PENDING",
    ...Object.keys(REVIEW_DECISION_STATES),
  ]),
  variant: PropTypes.oneOf(["noBorder", "default"]),
  className: PropTypes.string,
  isApproved: PropTypes.bool,
  isOverflowing: PropTypes.bool,
};

ReviewDecisionIndicator.defaultProps = {
  state: "PENDING",
  variant: "default",
  className: "",
  isApproved: false,
  isOverflowing: false,
};

export default ReviewDecisionIndicator;
