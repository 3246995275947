import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import AutomationsIcon from "@mui/icons-material/FlashOnRounded";
import { Button } from "@mui/material";
import { AUTOMATION_KEY_VALUES } from "@workflow/constants/automation";
import useAutomationStatus from "@workflow/hooks/useAutomationStatus";

import { Box, Text, Tooltip } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import FSTGTypography from "@shared/theme/typography";

function AutomationsButton({ project, onClick, steps }) {
  const { t } = useTranslation();
  const { checkIsAutomationReviewDecisionDisabled } = useAutomationStatus();

  const automationsCount = useMemo(() => {
    return project.automations.filter(
      (automation) =>
        automation.enabled &&
        !checkIsAutomationReviewDecisionDisabled(automation) &&
        (automation.conditions.step === AUTOMATION_KEY_VALUES.ANY ||
          steps.some((step) => step.id === automation.conditions.step) ||
          Object.keys(automation.conditions).length === 0 ||
          Object.keys(automation.actions).length > 0)
    ).length;
  }, [project.automations, steps]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="row"
      alignItems="center"
      data-testid="automations-button"
    >
      <Tooltip
        title={t(
          automationsCount > 0
            ? "AUTOMATIONS.EDIT_AUTOMATIONS.TOOLTIP"
            : "AUTOMATIONS.BUTTON.TOOLTIP"
        )}
      >
        <Button
          className="tour__automations"
          variant="outlined"
          shape="rounded"
          color="primary"
          data-testid="open-automations-dialog"
          startIcon={<AutomationsIcon />}
          onClick={onClick}
          sx={{
            borderColor: "grey.200",
            "&:hover": { borderColor: "grey.400" },
          }}
        >
          {t("AUTOMATIONS.BUTTON.TITLE")}
        </Button>
      </Tooltip>

      {automationsCount > 0 && (
        <Box
          width={30}
          height={30}
          marginLeft={-0.85}
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
          borderRadius="50%"
          border="1px solid var(--mui-palette-grey-200)"
        >
          <Text
            fontSize={FSTGTypography.fontSize_1_3}
            color="text.secondary"
            data-testid="automations-count-label"
          >
            {automationsCount}
          </Text>
        </Box>
      )}
    </Box>
  );
}

AutomationsButton.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  onClick: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
};

export default AutomationsButton;
