import PropTypes from "prop-types";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Add, InfoOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { TEAM_ROLES } from "@supporting/constants/team";
import useActiveUser from "@supporting/hooks/useActiveUser";
import { instance as teamService } from "@supporting/services/team";
import teamRoleService from "@supporting/services/teamRoleService";
import toastService from "@supporting/services/toast";

import { Box, Text, Tooltip, Toggle } from "@shared/UIKit";

import useDialogState from "@shared/hooks/useDialogState";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import AddTeamDomainDialog from "./sameDomainJoin/AddTeamDomainDialog";
import TeamDomainItem from "./sameDomainJoin/TeamDomainItem";

function SameDomainJoin({
  sameDomainJoinSetting,
  updateSameDomainJoinSetting,
  teamId,
  teamName,
  domains,
  onTeamDomainsChanged,
  isMemberRoleEnabled,
}) {
  const user = useActiveUser();
  const { t } = useTranslation();

  const handleSwitchSameDomainJoin = () => {
    updateSameDomainJoinSetting(Boolean(!sameDomainJoinSetting));
  };

  const [teamRoles, setTeamRoles] = useState([]);

  const { isOpen, openDialog, closeDialog } = useDialogState();

  const getDomainNames = useMemo(() => {
    return domains.map((domain) => domain.domainName);
  }, [domains]);

  useEffect(() => {
    const isRoleEnabled = (role) =>
      role.roleName === TEAM_ROLES.LITE_MEMBER ? isMemberRoleEnabled : true;

    const updateTeamRoles = async (teamId) => {
      const newRoles = await teamRoleService.fetchTeamRoles(teamId);
      setTeamRoles(newRoles.filter(isRoleEnabled));
    };

    const onTeamRolesChanged = ({ eventData }) => {
      const newRoles = eventData.teamRoles;
      setTeamRoles(newRoles.filter(isRoleEnabled));
    };

    eventService.addListener(
      EVENT_NAMES.TEAM.ROLES.UPDATED,
      onTeamRolesChanged
    );

    updateTeamRoles(teamId);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.TEAM.ROLES.UPDATED,
        onTeamRolesChanged
      );
    };
  }, [teamId, isMemberRoleEnabled]);

  const handleAddTeamDomain = async (
    domainName,
    defaultRole,
    recipientEmail
  ) => {
    try {
      const team = await teamService.addTeamDomain(
        domainName,
        defaultRole,
        recipientEmail
      );
      toastService.sendToast({
        title: "DOMAIN_ADDED.TOAST.TITLE",
        body: "DOMAIN_ADDED.TOAST.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            team: teamName,
          },
        },
      });
      onTeamDomainsChanged([
        ...domains,
        team.domains.find((domain) => domain.domainName === domainName),
      ]);
      analytics.track(
        analytics.ACTION.ADDED,
        analytics.CATEGORY.TEAM_SETTINGS_DOMAIN,
        { domain: domainName, defaultRole }
      );
      analytics.identify(user._id, {
        domainNames: team.domains.map((domain) => domain.domainName).join(","),
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const handleRemoveTeamDomain = (domainId) => {
    const updatedTeamDomains = domains.filter(
      (domain) => domain.id !== domainId
    );
    onTeamDomainsChanged(updatedTeamDomains);
    analytics.track(
      analytics.ACTION.DELETED,
      analytics.CATEGORY.TEAM_SETTINGS_DOMAIN
    );

    analytics.identify(user._id, {
      domainNames: updatedTeamDomains
        .map((domain) => domain.domainName)
        .join(","),
    });
  };

  const handleUpdateTeamDomain = (domainId, data) => {
    const updatedTeamDomains = [...domains];
    const updatedDomainIndex = updatedTeamDomains.findIndex(
      (domain) => domain.id === domainId
    );
    updatedTeamDomains[updatedDomainIndex] = {
      ...updatedTeamDomains[updatedDomainIndex],
      ...data,
    };

    onTeamDomainsChanged(updatedTeamDomains);
  };

  return (
    <Box px={2}>
      <Toggle
        label={t("JOIN_WITH_SAME_DOMAIN.SETTINGS_HEADER")}
        helpText={t("JOIN_WITH_SAME_DOMAIN.SETTINGS_BODY")}
        slotProps={{
          switch: {
            "data-testid": "toggle-same-domain-join",
            checked: Boolean(sameDomainJoinSetting),
            onChange: handleSwitchSameDomainJoin,
          },
        }}
      />
      {Boolean(sameDomainJoinSetting) && (
        <Box width="100%" overflow="auto" pl={6} pr={1}>
          <Box mt={3} display="flex" alignItems="center">
            <Box flex={3} display="flex" minWidth={120}>
              <Text
                variant="textSm"
                translate="JOIN_WITH_SAME_DOMAIN.DOMAIN"
                fontWeight="fontWeight.bold"
              />
            </Box>
            <Box
              flex={2}
              minWidth={120}
              display="flex"
              alignItems="flex-start"
              gap={1}
            >
              <Text
                variant="textSm"
                translate="TEAM_INVITE_LINK.DEFAULT_ROLE"
                fontWeight="fontWeight.bold"
              />
              <Tooltip
                placement="top"
                title={t("JOIN_WITH_SAME_DOMAIN.TOOLTIP")}
              >
                <InfoOutlined fontSize="small" color="default-light" />
              </Tooltip>
            </Box>
            <Box
              flex={3}
              minWidth={120}
              display="flex"
              alignItems="center"
              gap={1}
            >
              <Text
                variant="textSm"
                translate="TEAM_INVITE_LINK.NEW_MEMBER_APPROVE"
                fontWeight="fontWeight.bold"
              />
              <Tooltip
                placement="top"
                title={t("TEAM_INVITE_LINK.NEW_MEMBER_APPROVE_TOOLTIP")}
              >
                <InfoOutlined fontSize="small" color="default-light" />
              </Tooltip>
            </Box>
          </Box>
          {Boolean(teamRoles.length) && (
            <>
              {domains.map((domain) => (
                <TeamDomainItem
                  key={domain.id}
                  domain={domain}
                  teamRoles={teamRoles}
                  teamName={teamName}
                  updateTeamDomain={handleUpdateTeamDomain}
                  removeTeamDomain={handleRemoveTeamDomain}
                />
              ))}
            </>
          )}
          <Button
            onClick={openDialog}
            startIcon={<Add />}
            variant="text"
            color="default-light"
            data-testid="add-team-domain-button"
          >
            {t("JOIN_WITH_SAME_DOMAIN.ADD_DOMAIN")}
          </Button>
        </Box>
      )}
      <AddTeamDomainDialog
        isOpen={isOpen}
        cancel={closeDialog}
        roles={teamRoles}
        domainNames={getDomainNames}
        answer={handleAddTeamDomain}
      />
    </Box>
  );
}

SameDomainJoin.propTypes = {
  sameDomainJoinSetting: PropTypes.bool,
  updateSameDomainJoinSetting: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  domains: PropTypes.array,
  onTeamDomainsChanged: PropTypes.func.isRequired,
  isMemberRoleEnabled: PropTypes.bool.isRequired,
};

SameDomainJoin.defaultProps = {
  sameDomainJoinSetting: undefined,
  domains: [],
};

export default SameDomainJoin;
