import PropTypes from "prop-types";

export const FeaturePropType = {
  id: PropTypes.string.isRequired,
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string.isRequired,
  descriptionUrl: PropTypes.string,
  helpTextKey: PropTypes.string,
  image: PropTypes.elementType,
};
