import PropTypes from "prop-types";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { Popover } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box } from "@shared/UIKit";

import { reviewProp } from "@shared/props/review";
import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

import ReviewDecisionList from "./ReviewDecisionList/ReviewDecisionList";

const useStyles = makeStyles((theme) => ({
  Version: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.color.gray[50],
    padding: "5px 10px",
  },
  Footer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderTop: `1px solid ${theme.color.gray[200]}`,
    width: "100%",
    backgroundColor: theme.color.gray[50],
    padding: "0 6px",
    "& span": {
      fontSize: FSTGTypography.fontSize_1_3,
      display: "flex",
    },
  },
  FooterButton: {
    cursor: "help",
    margin: "8px 6px",
    minHeight: 30,
  },
  FooterIcon: {
    marginRight: 5,
    "& svg": {
      fontSize: FSTGTypography.fontSize_1_3,
    },
  },
  Approved: {
    backgroundColor: theme.color.green[50],
    borderBottom: `1px solid ${theme.color.green[200]}`,
    padding: "15px 20px",
  },
}));

function ReviewDecisionOverview({
  review,
  id,
  anchorEl,
  onClose,
  open,
  ...restProps
}) {
  const classes = useStyles();

  const getReviews = (state) => {
    return review.reviews.filter((review) => review.state === state);
  };

  if (open) {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.REVIEW_DECISIONS
    );
  }

  return (
    <Popover
      onClose={onClose}
      open={open}
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      classes={{
        paper: classes.Popover,
      }}
      {...restProps}
      data-testid="review-decision-overview"
    >
      <Box position="relative">
        <Box padding="25px 30px 0px 30px">
          <ReviewDecisionList
            reviews={getReviews(REVIEW_DECISION_STATES.APPROVED)}
            variant={REVIEW_DECISION_STATES.APPROVED}
          />
          <ReviewDecisionList
            reviews={getReviews(REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES)}
            variant={REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES}
          />
          <ReviewDecisionList
            reviews={getReviews(REVIEW_DECISION_STATES.NEEDS_CHANGES)}
            variant={REVIEW_DECISION_STATES.NEEDS_CHANGES}
          />
          <ReviewDecisionList
            reviews={getReviews(REVIEW_DECISION_STATES.REJECTED)}
            variant={REVIEW_DECISION_STATES.REJECTED}
          />
          <ReviewDecisionList
            reviews={getReviews("PENDING")}
            variant="PENDING"
          />
        </Box>
      </Box>
    </Popover>
  );
}

ReviewDecisionOverview.propTypes = {
  review: reviewProp.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  anchorEl: PropTypes.object,
};

ReviewDecisionOverview.defaultProps = {
  anchorEl: null,
};

export default ReviewDecisionOverview;
