import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { PersonOutline } from "@mui/icons-material";
import { LoadingButton as Button } from "@mui/lab";
import { InputAdornment, TextField } from "@mui/material";
import PasswordFieldWithTooltip from "@supporting/components/PasswordFieldWithTooltip/PasswordFieldWithTooltip";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";

import { Text, Box } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX } from "@shared/constants/validation";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useField } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
const MINIMUM_PASSWORD_LENGTH = 8;

export default function CaptureSignupCredentials() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  const [showError, setShowError] = useState(false);
  const [validation, setValidation] = useState({
    isMinimum: false,
    isOneUpperCase: false,
    isOneLowerCase: false,
    isOneNumberOrSpecial: false,
  });

  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.USER_CREDENTIALS_PAGE
    );
  }, []);

  const location = useLocation();
  const pathname = location.pathname;
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const code = searchParams.get("code");

  const notEmpty = (value) => {
    if (!value) {
      throw new Error(t("ONBOARDING_WIZARD.NAME_HELPER_TEXT.EMPTY"));
    }
  };

  const [submitIsInProgress, setSubmitIsInProgress] = useState(false);

  const {
    value: name,
    error: errorName,
    change: changeName,
  } = useField(authenticationService.nameFrom(email), [notEmpty]);
  const handleNameChange = ({ target }) => {
    changeName(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value);
  };
  const submit = async (event) => {
    event.preventDefault();
    if (
      validation.isMinimum &&
      validation.isOneLowerCase &&
      validation.isOneNumberOrSpecial &&
      validation.isOneUpperCase &&
      !errorName
    ) {
      setSubmitIsInProgress(true);
      try {
        analytics.track(
          analytics.ACTION.SUBMITTED,
          analytics.CATEGORY.USER_CREDENTIALS_PAGE
        );

        await authenticationService.signup({
          fullName: name,
          email,
          password,
          code,
        });
        const teams = await teamService.getTeams();
        if (teams.length === 1) {
          await teamService.switchTeam(teams[0]);
        }
        // We redirect to dashboard because it has a middleware to handle onboarding state
        navigate(APP_ROUTES.DASHBOARD.path);
      } catch (error) {
        setSubmitIsInProgress(false);
        errorHandlerService.handleError(error);
      }
    } else {
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.ACCOUNT_USER_PASSWORD_FAILED,
        { page: getCurrentPage(pathname) }
      );
      setShowError(true);
    }
  };
  useEffect(() => {
    setValidation({
      isMinimum: password.length >= MINIMUM_PASSWORD_LENGTH,
      isOneUpperCase: password.toLowerCase() !== password,
      isOneLowerCase: password.toUpperCase() !== password,
      isOneNumberOrSpecial: new RegExp(
        INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX
      ).test(password),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);
  return (
    <WizardBase variant="VERIFY_OTP">
      <Box
        gap={5}
        flexGrow={1}
        flexDirection="column"
        flexWrap="unset"
        display="flex"
        component="form"
        noValidate
        onSubmit={submit}
      >
        <Box gap={2} display="flex" flexDirection="column">
          <Text
            variant="text3xl"
            fontWeight="fontWeight.medium"
            translate="ONBOARDING_WIZARD.CREDENTIALS.TITLE"
          />
          <Text
            color="grey.600"
            variant="textMd"
            translate="FINISH_SETUP.ONBOARDING.BODY"
          />
        </Box>
        <Box gap={2} display="flex" flexDirection="column">
          <Box width="100%">
            <TextField
              value={email}
              autoComplete="username"
              sx={{ display: "none" }}
            />
            <TextField
              fullWidth
              autoComplete="name"
              id="onboarding-wizard-capture-signup-name-input"
              onChange={handleNameChange}
              helperText={errorName || " "}
              value={name}
              label={t("ONBOARDING_WIZARD.CREDENTIALS.INPUT_NAME_TITLE")}
              inputProps={{
                "data-testid":
                  "onboarding-wizard-capture-signup-credentials-name-input",
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutline sx={{ color: "primary" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <PasswordFieldWithTooltip
            isMinimum={validation.isMinimum}
            isOneLowerCase={validation.isOneLowerCase}
            isOneNumberOrSpecial={validation.isOneNumberOrSpecial}
            isOneUpperCase={validation.isOneUpperCase}
            value={password}
            autoFocus
            onChange={handlePasswordChange}
            testId="onboarding-wizard-capture-signup-credentials-password-input"
            id="onboarding-wizard-capture-signup-password-input"
            name="onboarding-wizard-capture-signup-password-input"
            labelTranslate="ONBOARDING_WIZARD.CREDENTIALS.INPUT_PASSWORD_TITLE"
            error={showError}
            autoComplete="current-password"
          />
          <Button
            size="large"
            fullWidth
            color="primary"
            variant="contained"
            loading={submitIsInProgress}
            type="submit"
            data-testid="onboarding-wizard-capture-signup-user-credentials-submit-button"
            sx={{ alignSelf: "flex-start" }}
          >
            {t("ONBOARDING_WIZARD.CREDENTIALS.SUBMIT_TITLE")}
          </Button>
        </Box>
      </Box>
    </WizardBase>
  );
}
