import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { queryClient } from "@shared/react-query";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
  Dialog,
  DialogContent,
  Divider,
} from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import { useMediaQuery, useCurrentRouteName } from "@shared/hooks";
import { SEARCH_KEYS } from "@shared/query-keys";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import DetailSearch from "./DetailSearch";
import FilterChip from "./FilterChip";
import { useSearchDialog } from "./hooks/useSearchDialog";
import useSearchInput from "./hooks/useSearchInput";
import OverviewSearch from "./OverviewSearch";
import RecentSearches from "./RecentSearches";

const SEARCH_TYPES = ["project", "file", "comment"];

function QuickSearchDialog({ openViaHotKey }) {
  const modal = useModal();
  const { t } = useTranslation();
  const dialogContent = useRef();

  const currentRouteName = useCurrentRouteName();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  /* istanbul ignore next */
  const searchDialog = useSearchDialog({
    closeSearchDialog: () => modal.remove(),
  });
  const {
    clearSearch,
    onSearchChange,
    hasResults,
    state,
    updateState,
    tabSelected,
    setTabSelected,
  } = searchDialog;
  const searchField = useRef();
  const searchInputProps = useSearchInput(searchDialog);

  const handleClear = useCallback(() => {
    clearSearch();
    searchField.current.focus();
  }, [clearSearch]);

  const cancel = () => {
    healthMetrics.trackCancellation("workflow.search");
    modal.remove();
    queryClient.removeQueries({ queryKey: SEARCH_KEYS.all });
  };

  useEffect(() => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.SEARCH, {
      label: openViaHotKey
        ? analytics.LABEL.VIA_SHORTCUT
        : analytics.LABEL.VIA_BUTTON,
      page: "results_overview",
    });
    healthMetrics.trackAttempt("workflow.search");
  }, [openViaHotKey, currentRouteName]);

  useEffect(() => {
    setTimeout(() => searchField.current && searchField.current.focus());
  }, [searchField]);

  const handleSearchMore = (type) => {
    setTabSelected(2);
    analytics.track(
      analytics.ACTION.SELECTED,
      analytics.CATEGORY.SEARCH_RESULT_FILTER,
      { selectedFilter: type }
    );
    updateState({ resourceSelected: type.toUpperCase() });
  };

  const handleOnRemoveFilter = (type) => {
    setTabSelected(state.searchQuery.length >= 3 ? 1 : 0);

    onSearchChange({
      target: { value: state.searchQuery, forceNewSearch: true },
    });

    analytics.track(
      analytics.ACTION.DESELECTED,
      analytics.CATEGORY.SEARCH_RESULT_FILTER,
      { deselectedFilter: type }
    );
  };

  const handleClickResultItem = (
    resourceLink,
    resultType,
    resultEvent,
    page,
    resource
  ) => {
    searchDialog.goToSearchResult(
      resourceLink,
      resultType,
      resultEvent,
      page,
      resource
    );
  };

  return (
    <Dialog
      open={modal.visible}
      onClose={cancel}
      hideBackdrop={false}
      maxWidth={isMobile ? false : "md"}
      fullWidth
      PaperProps={{
        "data-testid": "quick-search-dialog",
        sx: { backgroundImage: "none" },
      }}
      sx={{
        "& .MuiDialog-container": { alignItems: "flex-start" },
        "& .MuiPaper-root, .MuiDialogContent-root": { py: 0, px: 0 },
      }}
    >
      <DialogContent sx={{ position: "relative" }} ref={dialogContent}>
        <Box position="sticky" top={0} left={0} right={0} zIndex={10}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            bgcolor="white"
            pt={0.5}
            pr={1}
            pl={4}
          >
            <TextField
              id="quickSearchField"
              variant="standard"
              value={state.searchQuery}
              onChange={onSearchChange}
              fullWidth
              type="text"
              inputRef={searchField}
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
                onKeyDown: (event) => {
                  if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                    event.preventDefault();
                  }
                },
              }}
              inputProps={{ "data-testid": "quick-search-text-input" }}
              placeholder={t("SEARCH.SEARCH_HINT")}
              aria-label={t("SEARCH.SEARCH_HINT")}
              autoComplete="off"
              {...searchInputProps}
            />
            {state.searchQuery && (
              <Box display="flex" flexDirection="row" gap={1} pr={1}>
                <Button
                  variant="text"
                  color="primaryV2"
                  aria-label={t("SEARCH.CLEAR")}
                  onClick={handleClear}
                  data-testid="clear-search-button"
                  size="small"
                >
                  {t("SEARCH.CLEAR")}
                </Button>
                <Divider orientation="vertical" flexItem variant="middle" />
              </Box>
            )}
            <IconButton
              onClick={cancel}
              aria-label={t("CORE.CANCEL")}
              data-testid="close-search-dialog-button"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            orientation="horizontal"
            sx={{ bgcolor: "grey.200", height: 1, width: "100%" }}
          />
          {tabSelected !== 0 &&
            (hasResults || state.resourceSelected !== "ALL") && (
              <Box
                display="flex"
                position="sticky"
                top={0}
                left={0}
                right={0}
                pl={4}
                py={2}
                bgcolor="grey.50"
                gap={2}
                alignItems="center"
              >
                <Text
                  component="span"
                  variant="subtitle1"
                  color="text.secondary"
                  fontWeight="fontWeight.medium"
                  translate="SEARCH.ADD_FILTERS"
                />
                <Box display="flex" gap={1}>
                  {SEARCH_TYPES.map((type, index) => (
                    <FilterChip
                      key={type}
                      type={type}
                      onClick={handleSearchMore}
                      isSelected={state.resourceSelected === type.toUpperCase()}
                      onDelete={handleOnRemoveFilter}
                      isSectionActive={
                        state.selectedSearchResult.type === "filter_chips"
                      }
                      isSectionActiveIndex={
                        state.selectedSearchResult.index === index
                      }
                    />
                  ))}
                </Box>
              </Box>
            )}
        </Box>
        {tabSelected === 0 && (
          <RecentSearches
            isSectionActive={
              state.selectedSearchResult.type === "recent_searches"
            }
            sectionActiveIndex={state.selectedSearchResult.index}
            onClickResult={handleClickResultItem}
          />
        )}
        {tabSelected === 1 && (
          <OverviewSearch
            query={state.searchQuery}
            searchDialog={searchDialog}
            onSearchMore={handleSearchMore}
            sectionActive={state.selectedSearchResult.type}
            sectionActiveIndex={state.selectedSearchResult.index}
            onUpdateState={updateState}
          />
        )}
        {tabSelected === 2 && (
          <DetailSearch
            query={state.searchQuery}
            resource={state.resourceSelected}
            onClickResult={handleClickResultItem}
            sectionActive={state.selectedSearchResult.type}
            sectionActiveIndex={state.selectedSearchResult.index}
          />
        )}
      </DialogContent>
    </Dialog>
  );
}

QuickSearchDialog.propTypes = {
  openViaHotKey: PropTypes.bool,
};

QuickSearchDialog.defaultProps = {
  openViaHotKey: false,
};

export default NiceModal.create(QuickSearchDialog);
