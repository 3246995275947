import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import { Button, Card, CardContent, SvgIcon } from "@mui/material";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material/styles";
import { instance as billingService } from "@supporting/services/billingService";
import noop from "lodash/noop";

import { Box, Text, Tooltip } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import { instance as analytics } from "@shared/services/analytics";

import Bolt from "@assets/img/icons/bolt.svg";

import Feature from "./Feature";
import { calculatePrice, isPlanDowngrade } from "./selectplan.helpers";

export default function PlanCard({
  plan,
  selectPlan,
  currentPlan,
  currentPeriodEnd,
  isSubscriptionOnTrail,
  canUpgradePlan,
  billingPlans,
  color,
  showTrialEndedDialog,
}) {
  const theme = useTheme();

  const planColor = {
    free: {
      borderColor: "grey.200",
      backgroundColor: isSubscriptionOnTrail ? "grey.100" : "white",
    },
    enterprise: {
      borderColor: "grey.200",
    },
    professional: {
      borderColor: "accent.main",
    },
    basic: {
      borderColor: "grey.200",
    },
  };

  const currencySymbol = billingService.getCurrencySymbol(currentPlan.currency);
  const { t } = useTranslation();

  const planCardColor = color || planColor[plan];

  const isActivePlan =
    currentPlan.id === billingPlans[plan].data?.id && !isSubscriptionOnTrail;

  const boltIcon = (
    <SvgIcon
      viewBox="0 0 11 13"
      component={Bolt}
      sx={{ width: 12, height: 12 }}
      fontSize="small"
    />
  );

  const handleClick = () => {
    selectPlan(billingPlans[plan].data);

    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.SUBSCRIPTION_PLAN_SELECTION_BUTTON,
      { plan }
    );
  };

  const tooltipWithButton = (plan) => {
    const freePlanOnTrialFlag = isSubscriptionOnTrail && plan.name === "Free";

    const button = (
      <Box
        data-testid={`button-container-select-plan-${plan.name}`}
        component="span"
        display="flex"
        flexDirection="column"
        alignItems="stretch"
      >
        <Button
          color={plan.name === "Professional" ? "accent" : "primaryV2"}
          variant={plan.name === "Professional" ? "contained" : "outlined"}
          size="large"
          onClick={handleClick}
          data-testid={
            isActivePlan ? "current-subscription" : `select-plan-${plan.name}`
          }
          disabled={isActivePlan || freePlanOnTrialFlag}
        >
          {isActivePlan
            ? t("PRICING.CURRENT_PLAN_BUTTON")
            : t("SELECT_PLAN_DIALOG.SELECT_PLAN")}
        </Button>
      </Box>
    );

    if (freePlanOnTrialFlag) {
      return (
        <Tooltip title={t("CREDIT CARD.PRICING.TOOLTIP")}>{button}</Tooltip>
      );
    }

    return (
      <Tooltip
        title={
          <Trans
            components={{
              // eslint-disable-next-line id-length
              bold: <b />, //eslint-disable-line react/forbid-elements
            }}
            values={{
              endDate: formatWithLocale(currentPeriodEnd * 1000),
            }}
          >
            SUBSCRIPTION.DOWNGRADE.TOOLTIP
          </Trans>
        }
        disabled={!isPlanDowngrade(currentPlan, plan) || isSubscriptionOnTrail}
      >
        {button}
      </Tooltip>
    );
  };

  const renderPlanButtons = (plan) => {
    if (plan.name === "Enterprise") {
      return (
        <Button
          className="launch-intercom-chat"
          color="primaryV2"
          variant="outlined"
        >
          {t("SELECT_PLAN_DIALOG.CHAT_TO_US.CTA")}
        </Button>
      );
    }

    if (!canUpgradePlan) {
      return (
        <Text
          margin={0.5}
          variant="body2"
          color="error.main"
          fontWeight="fontWeight.medium"
          data-testid="contact-admin-message"
          translate="SELECT_PLAN_DIALOG.CONTACT_ADMIN"
        />
      );
    }
    return tooltipWithButton(plan);
  };

  const processFeatureText = (plan) => {
    const plans = Object.keys(billingPlans);
    const planIndex = plans.indexOf(plan);
    if (planIndex === 0 || !billingPlans[plan].featureHeader) {
      return null;
    }
    return (
      <Box display="flex">
        <CheckIcon sx={{ color: "grey.500" }} />
        <Text
          ml={1}
          mb={1.5}
          color="grey.700"
          lineHeight="1.5"
          fontWeight="fontWeight.medium"
          translate={billingPlans[plan].featureHeader}
        />
      </Box>
    );
  };

  const renderPriceDescription = (plan) => {
    if (plan.amount === 0) {
      return;
    }
    if (showTrialEndedDialog) {
      return (
        <Box mb={0.5} ml={1}>
          <Text
            translate="PRICING.TRIAL.CAPTION_1"
            color="grey.500"
            variant="textSm"
            lineHeight="1.0"
          />
          <Text
            color="grey.500"
            variant="textSm"
            lineHeight="1.0"
            translate={t("PRICING.TRIAL.CAPTION_2", {
              currency: currencySymbol,
              price: calculatePrice(
                plan.monthlyPayment?.amount,
                plan.monthlyPayment?.interval,
                1
              ),
            })}
          />
        </Box>
      );
    }

    return (
      <Text
        translate="SELECT_PLAN_DIALOG.PER_MONTH"
        color="grey.500"
        variant="textSm"
        marginTop={1}
      />
    );
  };
  return (
    <Card
      sx={{
        minWidth: 295,
        maxWidth: 358,
        borderRadius: 1,
        border: "1px solid",
        margin: { xs: 0, md: "auto" },
        height: { xs: "auto", md: "100%" },
        borderColor: planCardColor.borderColor,
        boxShadow: theme.shadow["box-shadow-z8"],
        backgroundColor: planCardColor.backgroundColor || "white",
      }}
    >
      <CardContent
        sx={{
          pr: 0.5,
          pl: 2.5,
          pb: 1.5,
          minHeight: 115,
          textAlign: "left",
        }}
      >
        <Box>
          <Box display="flex" overflow="hidden" flexWrap="wrap">
            <Text
              color="grey.900"
              lineHeight="1.2"
              fontSize="text2xl.fontSize"
              fontWeight="fontWeight.medium"
              translate={billingPlans[plan].title}
            />
            {plan === "professional" && (
              <Chip
                size="small"
                label={t("SELECT_PLAN_DIALOG.MOST_POPULAR")}
                sx={{
                  fontSize: 10,
                  maxHeight: 20,
                  marginLeft: 2,
                  color: "grey.900",
                  alignSelf: "center",
                  backgroundColor: "warning.light",
                  fontWeight: "fontWeight.semiBold",
                }}
                icon={boltIcon}
              />
            )}
          </Box>
          <Text
            height={50}
            marginTop={1.5}
            color="grey.700"
            variant="textSm"
            translate={billingPlans[plan].subtitleKey}
          />
        </Box>
        <Box mt={1.25} mb={1.25} pr={1}>
          {plan === "enterprise" ? (
            <Text
              marginRight={1}
              color="grey.900"
              lineHeight="1.6"
              fontSize="text2xl.fontSize"
              fontWeight="fontWeight.medium"
              translate="SELECT_PLAN_DIALOG.CONTACT_SALES.CTA"
            />
          ) : (
            <Box display="flex" alignItems="end">
              <Text
                variant="h2"
                color="grey.900"
                fontWeight="fontWeight.medium"
                data-testid={`price-${billingPlans[plan].data.interval}-${plan}`}
              >
                {currencySymbol}
                {calculatePrice(
                  billingPlans[plan].data.amount,
                  billingPlans[plan].data.interval,
                  1,
                  plan
                )}
              </Text>
              {renderPriceDescription(billingPlans[plan].data)}
            </Box>
          )}
          <Text
            marginTop={1}
            color="grey.500"
            variant="textSm"
            lineHeight="1.0"
          >
            {t(billingPlans[plan].pricingDescriptionKey, {
              count: `${currencySymbol}${calculatePrice(
                billingPlans[plan].data.amount,
                billingPlans[plan].data.interval,
                1,
                plan
              )}`,
            })}
          </Text>
        </Box>
      </CardContent>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "center",
        }}
      >
        {renderPlanButtons(billingPlans[plan].data)}
      </CardContent>

      <CardContent
        sx={{
          pr: 0.5,
          pl: 2.5,
          minHeight: 115,
          textAlign: "left",
        }}
      >
        {processFeatureText(plan)}
        {Object.keys(billingPlans[plan].features.main).map(
          (toolTipKey, index) => (
            <Feature
              key={index}
              tooltipText={toolTipKey}
              text={billingPlans[plan].features.main[toolTipKey].text}
              amount={billingPlans[plan].features.main[toolTipKey].amount}
            />
          )
        )}

        {Object.keys(billingPlans[plan].features.additional).map(
          (toolTipKey, index) => (
            <Feature
              key={index}
              tooltipText={toolTipKey}
              text={billingPlans[plan].features.additional[toolTipKey].text}
              amount={billingPlans[plan].features.additional[toolTipKey].amount}
            />
          )
        )}
      </CardContent>
    </Card>
  );
}
PlanCard.defaultProps = {
  selectPlan: noop,
  showTrialEndedDialog: false,
  color: null,
};

PlanCard.propTypes = {
  plan: PropTypes.string.isRequired,
  selectPlan: PropTypes.func,
  currentPlan: PropTypes.object.isRequired,
  isSubscriptionOnTrail: PropTypes.bool.isRequired,
  canUpgradePlan: PropTypes.bool.isRequired,
  billingPlans: PropTypes.object.isRequired,
  color: PropTypes.object,
  currentPeriodEnd: PropTypes.number.isRequired,
  showTrialEndedDialog: PropTypes.bool,
};
