import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import AddReviewer from "./AddReviewer/AddReviewer";
import ExtraReviewersPopper from "./ExtraReviewersPopper/ExtraReviewersPopper";
import ShowAllReviewers from "./ShowAllReviewers/ShowAllReviewers";
import StepReviewersList from "./StepReviewersList/StepReviewersList";

function StepReviewers({
  step,
  max,
  project,
  addReviewer,
  review,
  showStatus,
  variant,
  isProjectTemplate,
  team,
}) {
  const [userListAnchorEl, setUserListAnchorEl] = useState(null);

  const closeUsersPopper = useCallback(() => {
    setUserListAnchorEl(null);
  }, []);

  const showAllUsersPopper = useCallback(
    (event) => {
      setUserListAnchorEl(userListAnchorEl ? null : event.currentTarget);
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.REVIEWER_LIST
      );
      event.stopPropagation();
    },
    [userListAnchorEl, setUserListAnchorEl] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const reviewers = step.reviewers || [];
  const limit = max || reviewers.length;

  return (
    <Box
      maxWidth="100%"
      display="flex"
      flexDirection="row"
      flex="1"
      alignItems="center"
      alignContent="center"
      justifyContent="flex-start"
      className="tour__reviewers"
      data-testid="step-reviewers-root-element"
    >
      <AddReviewer
        teamId={project.teamId}
        step={step}
        disabled={!addReviewer || project.isArchived}
        isProjectTemplate={isProjectTemplate}
      />
      <StepReviewersList
        users={reviewers}
        automations={project.automations}
        canManageProjectMembers={project.permissions.manageMembers}
        canManageProjectTemplates={project.permissions.canmanageTemplates}
        isProjectArchived={project.isArchived}
        teamId={project.teamId}
        step={step}
        showStatus={showStatus}
        review={review}
        variant={variant}
        usersIndexFrom={0}
        usersIndexTo={limit}
        isProjectTemplate={isProjectTemplate}
        team={team}
      />
      {reviewers.length > limit && project.teamId && (
        <>
          <ShowAllReviewers
            showAllUsersPopper={showAllUsersPopper}
            extraAvatarsNumber={reviewers.length - limit}
          />

          <ExtraReviewersPopper
            users={reviewers}
            userListAnchorEl={userListAnchorEl}
            closeUsersPopper={closeUsersPopper}
            teamId={project.teamId}
            step={step}
            canManageProjectMembers={project.permissions.manageMembers}
            canManageProjectTemplates={project.permissions.canmanageTemplates}
            isProjectArchived={project.isArchived}
            usersIndexFrom={limit}
            usersIndexTo={reviewers.length}
            isProjectTemplate={isProjectTemplate}
            team={team}
          />
        </>
      )}
    </Box>
  );
}

StepReviewers.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  step: stepProps,
  max: PropTypes.number,
  showStatus: PropTypes.bool,
  addReviewer: PropTypes.bool,
  review: reviewProp,
  variant: PropTypes.oneOf(["workspace", "dashboard"]),
  isProjectTemplate: PropTypes.bool,
  team: teamProp.isRequired,
};

StepReviewers.defaultProps = {
  showStatus: false,
  addReviewer: true,
  review: null,
  step: {},
  max: null,
  variant: "workspace",
  isProjectTemplate: false,
};

export default StepReviewers;
