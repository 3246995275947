import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import { Box } from "@mui/material";
import useGoogleAuth from "@supporting/hooks/useGoogleAuth";
import authentication from "@supporting/services/authentication";

import { Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

const StepLoginGoogleAuth = ({ redirect }) => {
  const ref = useRef();

  useEffect(() => {
    authentication.setAuthenticationState({ redirect });
  }, [redirect]);

  useGoogleAuth("continue_with", ref, "", "", "");
  useEffect(() => {
    analytics.trackV2({
      category: analytics.CATEGORY.USER_CREDENTIALS_PAGE,
      action: analytics.ACTION.OPENED,
      metaData: {
        loginType: "google-sso",
      },
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
      <Text
        variant="textSm"
        color="text.secondary"
        translate="ACCESS_CONTROL.GOOGLE_SSO.CAPTION"
      />
      <Box ref={ref} />
    </Box>
  );
};
StepLoginGoogleAuth.propTypes = {
  redirect: PropTypes.string.isRequired,
};

export default StepLoginGoogleAuth;
