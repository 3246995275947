import { useEffect, useState } from "react";

import isInIframe from "@shared/helpers/isInIframe";
import { instance as logger } from "@shared/services/logger";

export const generateGoogleAuthCallbackUrl = ({
  externalPluginId,
  shareSessionStepId,
  apiEndpoint,
}) => {
  const loginUrl = new URL(
    `${apiEndpoint}${
      externalPluginId ? "/adobe-plugin/auth/google" : "/auth/google"
    }`
  );

  if (shareSessionStepId) {
    loginUrl.searchParams.set("shareSessionStepId", shareSessionStepId);
  }

  if (externalPluginId) {
    loginUrl.searchParams.set("externalPluginId", externalPluginId);
  }

  try {
    const iframeSupport = Boolean(isInIframe() || window.opener);

    if (iframeSupport) {
      loginUrl.searchParams.set("iframe", iframeSupport);
    }
  } catch (error) {
    logger.info("helpers:google", "unable to open access window opener", {
      error,
    });
  }

  return loginUrl;
};

const useGoogleAuth = (text, ref, shareSessionStepId, externalPluginId) => {
  const [loaded, setLoaded] = useState(false);
  const { config, apiEndpoint } = window.fs;

  useEffect(() => {
    function initializeGoogleSigIn() {
      const loginUrl = generateGoogleAuthCallbackUrl({
        shareSessionStepId,
        externalPluginId,
        apiEndpoint,
      });

      try {
        window.google.accounts.id.initialize({
          client_id: config.google.clientID,
          ux_mode: "redirect",
          login_uri: loginUrl.toString(),
        });

        window.google.accounts.id.renderButton(ref.current, {
          theme: "outline",
          size: "large",
          logo_alignment: "center",
          shape: "rectangular",
          text,
        });
        setLoaded(true);
      } catch (error) {
        logger.error("helpers:google", "unable to load google", { error });
        setLoaded(false);
      }
    }

    initializeGoogleSigIn();
  }, [
    apiEndpoint,
    config.google.clientID,
    externalPluginId,
    ref,
    shareSessionStepId,
    text,
  ]);

  return {
    loaded,
  };
};
export default useGoogleAuth;
