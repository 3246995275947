import PropTypes from "prop-types";
import { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TeamInviteLink from "@supporting/components/TeamInviteLink/TeamInviteLink";
import {
  doesBaseSubscriptionUserLimitReached,
  canAddMoreUsersToSubscription,
} from "@supporting/helpers/subscriptionUtility";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Box, Dialog } from "@shared/UIKit";

import AUTOCOMPLETE from "@shared/constants/autoComplete";
import getFormattedPrice from "@shared/helpers/getFormattedPrice";
import { useMediaQuery } from "@shared/hooks";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import SeatUpsellPopup from "../SeatUpsellPopup/SeatUpsellPopup";
import AddEmailInvite from "./AddEmailInvite";
import TeamMemberDialogWarning from "./TeamMemberDialogWarning";

const useStyles = makeStyles((theme) => ({
  content: {
    maxHeight: 600,
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  contentWrapper: {
    width: "100%",
    textAlign: "left",
  },
  addMemberWrapper: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  teamRole: {
    cursor: "pointer",
    backgroundColor: theme.color.white,
    minWidth: 140,
    display: "flex",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
  },
  dialogContentRoot: {
    [theme.breakpoints.down("sm")]: {
      paddingRight: 0,
      paddingLeft: 0,
      overflow: "unset",
    },
  },
  dialogPaper: {
    width: "100%",
    margin: 32,
    [theme.breakpoints.down("sm")]: {
      margin: 8,
    },
  },
  popperIndex: {
    zIndex: 2000,
  },
  teamLinkContainer: {
    backgroundColor: theme.color.gray[50],
    marginTop: 32,
    paddingRight: 16,
    alignSelf: "stretch",
    [theme.breakpoints.down(720)]: {
      minWidth: "unset",
    },
  },
}));

function InviteNewTeamMemberDialog({
  team,
  page,
  closeDialog,
  currentSubscription,
}) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [newTeamMembersEmails, setNewTeamMembersEmails] = useState([]);
  const [selectedRoleId, setSelectedRoleId] = useState("");

  const [inviteLink, setInviteLink] = useState(
    team.settings?.inviteLink || {
      defaultRole: "",
      requestApproval: false,
      linkId: "",
    }
  );
  const [enableInviteSetting, setEnableInviteSetting] = useState(
    Boolean(team.settings?.inviteLink) || false
  );

  const subscriptionHelper = {
    doesBaseSubscriptionUserLimitReached: doesBaseSubscriptionUserLimitReached(
      currentSubscription,
      team.members.length,
      newTeamMembersEmails.length
    ),
    canAddMoreUsersToSubscription: canAddMoreUsersToSubscription(
      currentSubscription,
      team.members.length,
      newTeamMembersEmails.length
    ),
  };

  const cancelDialog = () => {
    healthMetrics.trackCancellation("supporting.invite-team-members");
    closeDialog();
  };

  const handleInviteNewMember = async ({ email, roleId }) => {
    try {
      healthMetrics.trackStart("supporting.invite-team-members");
      const updatedTeam = await teamService.addTeamMember(email, roleId);

      const emailsInLowerCase = email.map((email) => email.toLowerCase());
      const newTeamMembers = updatedTeam.members.filter(({ user }) =>
        emailsInLowerCase.includes(user.email)
      );

      newTeamMembers.forEach(({ user }) => {
        analytics.track(
          analytics.ACTION.INVITED_MEMBER,
          analytics.CATEGORY.TEAM,
          {
            teamId: updatedTeam._id,
            teamName: updatedTeam.name,
            invitedEmail: user.email,
            numberOfMembers: updatedTeam.members.length,
            page,
          }
        );
      });

      healthMetrics.trackSuccess("supporting.invite-team-members");

      toastService.sendToast({
        title: `${
          newTeamMembers.length === 1
            ? "INVITE_TEAM_MEMBER.SUCCESS_TOAST"
            : "INVITE_TEAM_MEMBERS.SUCCESS_TOAST"
        }.TITLE`,
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      errorHandlerService.handleError(error);
      healthMetrics.trackFailure("supporting.invite-team-members", error);
    }
  };

  const confirmDialog = () => {
    healthMetrics.trackAttempt("supporting.invite-team-members");
    const emails = newTeamMembersEmails.map((email) => email.inputValue);
    handleInviteNewMember({
      email: emails,
      roleId: selectedRoleId,
    });
    closeDialog();
  };

  const updateTeamSettings = async function ({
    enableInviteSettingUpdate,
    inviteLinkUpdate,
  }) {
    const updateTeam = {};

    if (enableInviteSettingUpdate) {
      updateTeam.settings = { inviteLink: inviteLinkUpdate };
      analytics.track(
        !team.settings.inviteLink
          ? analytics.ACTION.ENABLED
          : /* istanbul ignore next */
            analytics.ACTION.UPDATED,
        analytics.CATEGORY.TEAM_SETTINGS_INVITE_LINK,
        {
          defaultRole: inviteLinkUpdate.defaultRole,
          isApprovalRequired: inviteLinkUpdate.requestApproval,
        }
      );
    } else {
      analytics.track(
        analytics.ACTION.DISABLED,
        analytics.CATEGORY.TEAM_SETTINGS_INVITE_LINK
      );
      updateTeam.settings = { inviteLink: null };
    }
    try {
      await teamService.updateTeamSettings(updateTeam);
    } catch (err) {
      errorHandlerService.handleError(err);
    }
  };

  const onClickSubmit = () => {
    if (subscriptionHelper.doesBaseSubscriptionUserLimitReached) {
      setIsConfirmationOpen(true);
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.CONFIRM_SEAT_UPGRADE_POPOVER,
        { label: analytics.LABEL.INVITE_TEAM_MEMBER_DIALOG }
      );
    } else {
      confirmDialog();
    }
  };

  const addSeatAndMember = () => {
    confirmDialog();
    analytics.track(analytics.ACTION.ADDED, analytics.CATEGORY.SEATS, {
      label: analytics.LABEL.INVITE_TEAM_MEMBER_DIALOG,
    });
  };

  const getAdditionalSeatCount = useMemo(() => {
    if (team.subscription.additionalPrice) {
      return (
        newTeamMembersEmails.length +
        team.members.length -
        team.subscriptionLimits.includedUsers
      );
    }
    return newTeamMembersEmails.length;
  }, [newTeamMembersEmails, team]);

  const getUpsellSeatInfo = useMemo(() => {
    const price =
      team.subscription.additionalPrice || team.subscription.basePrice;
    if (price) {
      return {
        interval: price.interval,
        price: getFormattedPrice(price, getAdditionalSeatCount),
      };
    }
  }, [
    getAdditionalSeatCount,
    team.subscription.additionalPrice,
    team.subscription.basePrice,
  ]);

  const updateInviteLink = async (linkData) => {
    setInviteLink((prev) => ({ ...prev, ...linkData }));
    await updateTeamSettings({
      enableInviteSettingUpdate: enableInviteSetting,
      inviteLinkUpdate: { ...inviteLink, ...linkData },
    });
  };

  const handleSetEnableInviteSetting = async (enableInviteSettingUpdate) => {
    setEnableInviteSetting(enableInviteSettingUpdate);
    await updateTeamSettings({
      enableInviteSettingUpdate,
      inviteLinkUpdate: inviteLink,
    });
  };

  return (
    <Dialog
      customPaperClasses={classes.dialogPaper}
      contentClassName={classes.dialogContentRoot}
      useDefaultBackground={false}
      open
      hideBackdrop={false}
      cancel={cancelDialog}
      title={t("TEAM.ADD_NEW_MEMBER.HEADLINE")}
      disableBackdropClick
      helpLink={
        isMobile
          ? null
          : {
              link: t("URLS.FAQ_TEAM_ROLES"),
              text: t("TEAM.ADD_MEMBER.HELP"),
            }
      }
      actions={
        <Box>
          {isConfirmationOpen && getAdditionalSeatCount > 0 && (
            <SeatUpsellPopup
              onSubmit={addSeatAndMember}
              text={t(
                `TEAM.ADD_MEMBER.SEAT-LIMIT-REACHED-SEAT-LIMIT-REACHED-${getUpsellSeatInfo.interval.toUpperCase()}.CONFIRM`,
                {
                  price: getUpsellSeatInfo.price,
                  count: getAdditionalSeatCount,
                }
              )}
              isMultiple={getAdditionalSeatCount > 1}
              isDisabled={
                newTeamMembersEmails.length > AUTOCOMPLETE.MAX_INVITE_COUNT
              }
            />
          )}
        </Box>
      }
    >
      <Box
        className={classes.content}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Box className={classes.contentWrapper}>
          <AddEmailInvite
            team={team}
            setSelectedRoleId={setSelectedRoleId}
            newTeamMembersEmails={newTeamMembersEmails}
            setNewTeamMembersEmails={setNewTeamMembersEmails}
          />
          <TeamMemberDialogWarning
            canAddMoreUsersToSubscription={
              subscriptionHelper.canAddMoreUsersToSubscription
            }
            doesBaseSubscriptionUserLimitReached={
              subscriptionHelper.doesBaseSubscriptionUserLimitReached
            }
            canManageBilling={team.permissions.canManageBilling}
            teamId={team._id}
          />
        </Box>
        <Box alignSelf="end">
          <Button
            onClick={onClickSubmit}
            color="primary"
            disabled={
              !subscriptionHelper.canAddMoreUsersToSubscription ||
              !selectedRoleId ||
              !newTeamMembersEmails.length ||
              newTeamMembersEmails.length > AUTOCOMPLETE.MAX_INVITE_COUNT
            }
            size="large"
            variant="contained"
            data-testid="invite-member-submit"
          >
            {t("ONBOARDING_WIZARD.INVITE_TEAMMEMBER.CTA")}
          </Button>
        </Box>
        <Box className={classes.teamLinkContainer}>
          <TeamInviteLink
            page={analytics.CATEGORY.INVITE_TEAM_MEMBER_DIALOG}
            teamId={team._id}
            inviteLink={inviteLink}
            updateInviteLink={updateInviteLink}
            isMemberRoleEnabled={team.subscriptionLimits.isMemberRoleEnabled}
            enableInviteSetting={enableInviteSetting}
            setEnableInviteSetting={handleSetEnableInviteSetting}
            keepOpen={false}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

InviteNewTeamMemberDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  team: teamProp.isRequired,
  page: PropTypes.string.isRequired,
  currentSubscription: PropTypes.object.isRequired,
};

export default InviteNewTeamMemberDialog;
