import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import ArrowDownwardRounded from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRounded from "@mui/icons-material/ArrowUpwardRounded";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import projectTemplateService from "@workflow/services/projectTemplateService";
import sectionService from "@workflow/services/sectionService";

import { projectProps, sectionProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";

const SectionHeaderMoveMenuItem = forwardRef(
  ({ moveUp, project, section, sectionPosition, isTemplate }, ref) => {
    const { t } = useTranslation();

    const moveSection = async () => {
      const newSectionPosition = moveUp
        ? sectionPosition - 1
        : sectionPosition + 1;

      try {
        if (isTemplate) {
          await projectTemplateService.moveProjectTemplateSectionPosition(
            project.id,
            section.id,
            newSectionPosition
          );
        } else {
          await sectionService.moveProjectSection(
            project.id,
            section.id,
            newSectionPosition
          );
        }
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    };

    return moveUp ? (
      <MenuItem
        ref={ref}
        data-testid="section-header-menu-item-move-up"
        onClick={moveSection}
      >
        <ListItemIcon>
          <ArrowUpwardRounded />
        </ListItemIcon>
        <ListItemText>{t(`PROJECT.SECTION.MENU.MOVE_UP`)}</ListItemText>
      </MenuItem>
    ) : (
      <MenuItem
        ref={ref}
        data-testid="section-header-menu-item-move-down"
        onClick={moveSection}
      >
        <ListItemIcon>
          <ArrowDownwardRounded />
        </ListItemIcon>
        <ListItemText>{t(`PROJECT.SECTION.MENU.MOVE_DOWN`)}</ListItemText>
      </MenuItem>
    );
  }
);

SectionHeaderMoveMenuItem.displayName = "SectionHeaderMoveMenuItem";

SectionHeaderMoveMenuItem.defaultProps = {
  isTemplate: false,
  moveUp: false,
};

SectionHeaderMoveMenuItem.propTypes = {
  moveUp: PropTypes.bool,
  section: sectionProps.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  sectionPosition: PropTypes.number.isRequired,
  isTemplate: PropTypes.bool,
};

export default SectionHeaderMoveMenuItem;
