import { redirect } from "react-router-dom";

import { processSignup } from "@supporting/helpers/processSignup";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import projectReviewService from "@workflow/services/projectReviewService";
import Cookies from "js-cookie";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as analytics } from "@shared/services/analytics";

import { redirectToRouteName, stripBasename } from "./navigation";

export const validateIsRegistered = async ({ routeKey, url }) => {
  try {
    const { user, session } = await authenticationService.authenticate();

    if (session.type === "Guest") {
      throw redirectToRouteName("LOGIN", {
        search: new URLSearchParams({
          redirect: `${stripBasename(url.pathname)}${url.search}`,
        }).toString(),
      });
    }

    if (
      session.type === "Registered" &&
      session.weakPassword &&
      routeKey !== "ENFORCE_PASSWORD_CHANGE"
    ) {
      redirectToRouteName("ENFORCE_PASSWORD_CHANGE");
    } else if (
      session.type === "Registered" &&
      !session.weakPassword &&
      routeKey === "ENFORCE_PASSWORD_CHANGE"
    ) {
      redirectToRouteName("DASHBOARD");
    }

    return user;
  } catch (error) {
    if (routeKey === "INTEGRATIONS") {
      throw error;
    }

    throw redirectToRouteName("LOGIN", {
      search: new URLSearchParams({
        redirect: `${stripBasename(url.pathname)}${url.search}`,
      }).toString(),
    });
  }
};

export function getEmbedStateParam(param) {
  try {
    const embedState = window.opener?.customEmbedState;

    if (embedState) {
      return embedState[param];
    }
    // eslint-disable-next-line no-empty
  } catch {}
}

function closeWindowInIframe() {
  const iframeSource = getEmbedStateParam("auth_triggered_from_iframe");

  if (iframeSource === "monday-app") {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.MONDAY_APP.FILESTAGE_LOGIN
    );
  }
}

const redirectToTeamWizardState = function (wizardProgressState) {
  if (wizardProgressState) {
    if (wizardProgressState.reason) {
      Cookies.set("onboarding:signup-reason", wizardProgressState.reason);
    }
    if (wizardProgressState.companySize) {
      Cookies.set("onboarding:company-size", wizardProgressState.companySize);
    }
    const onboardingWizardRoutes = {
      inviteTeamMembers: APP_ROUTES.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS.path,
      companySize: APP_ROUTES.ONBOARDING_WIZARD_COMPANY_SIZE.path,
      uploadFiles: APP_ROUTES.ONBOARDING_WIZARD_UPLOAD_FILES.path,
    };

    if (onboardingWizardRoutes[wizardProgressState.currentStep]) {
      throw redirect(onboardingWizardRoutes[wizardProgressState.currentStep]);
    }
  }

  try {
    if (
      getEmbedStateParam("auth_triggered_from_iframe") &&
      window.opener?.onEmbedSuccessfulAuthentication
    ) {
      closeWindowInIframe();

      window.opener.onEmbedSuccessfulAuthentication();
      return null;
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return null;
};

export const validateIsQualificationComplete = async (team) => {
  if (team) {
    return redirectToTeamWizardState(team.wizardProgressState);
  }

  let signupReason = Cookies.get("authentication:signup-form:reason");
  if (!signupReason) {
    const externalProjects = await projectReviewService.fetchExternalProjects();
    signupReason = externalProjects.length ? "review-files" : "start-trial";

    Cookies.set("authentication:signup-form:reason", signupReason);
  }
  Cookies.set(
    "onboarding:signup-reason",
    signupReason === "review-files" ? "reviewer" : "collaborator"
  );

  if (!Cookies.get("authentication:signup-flow")) {
    analytics.track(analytics.ACTION.STARTED, analytics.CATEGORY.SIGNUP_FLOW, {
      signupReason,
    });

    if (getEmbedStateParam("auth_triggered_from_iframe") === "monday-app") {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.MONDAY_APP.FILESTAGE_SIGNUP
      );
    }

    Cookies.set("authentication:signup-flow", "started");
  }

  const sameDomainteams = await teamService.fetchTeamsWithSameDomain();
  if (sameDomainteams.length && signupReason !== "review-files") {
    throw redirect(APP_ROUTES.ONBOARDING_WIZARD_JOIN_TEAMS.path);
  }

  await processSignup(signupReason);
};

export const fetchDefault =
  (routeKey, isValidateIsQualificationComplete = false) =>
  async ({ params, request }) => {
    const url = request?.url && new URL(request.url);

    const user = await validateIsRegistered({
      routeKey,
      url,
    });
    const team = params?.teamId
      ? await teamService.determineSelectedTeamByTeamId(params.teamId)
      : await teamService.determineSelectedTeamByDefault();

    if (isValidateIsQualificationComplete) {
      await validateIsQualificationComplete(team);
    }
    return { user, team };
  };

export const fetchToSignUpTeamLink =
  (isValidateIsQualificationComplete = false) =>
  async ({ params }) => {
    Cookies.set("teamlink_inviteLinkId", params.inviteLinkId);
    try {
      const { user, session } = await authenticationService.authenticate();

      if (session.type === "Guest") {
        throw redirectToRouteName("SIGNUP", {
          search: "reason=start-trial",
        });
      }

      const team = await teamService.determineSelectedTeamByDefault();

      if (isValidateIsQualificationComplete) {
        validateIsQualificationComplete(team);
      }
      return { user, team };
    } catch (error) {
      throw redirectToRouteName("SIGNUP", {
        search: "reason=start-trial",
      });
    }
  };

export const fetchAdminLoader =
  (routeKey) =>
  async ({ params, request }) => {
    const { user, team } = await fetchDefault(routeKey)({ params, request });

    if (!user.isAdmin) {
      return redirectToRouteName("DASHBOARD");
    }

    return { user, team };
  };
