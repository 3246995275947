import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import UsageBar from "./UsageBar";
import UsageTooltip from "./UsageTooltip";

function ProjectTemplatesUsage({ team, openBillingDialog }) {
  const { t } = useTranslation();

  const getprojectTemplatesLimit = () => {
    if (team.subscriptionLimits.maxProjectTemplateNumber > -1) {
      return team.subscriptionLimits.maxProjectTemplateNumber;
    }

    return t("BILLING.UNLIMITED");
  };

  const determineUsagePercentage = useCallback(() => {
    return team.subscriptionLimits.maxProjectTemplateNumber === 0
      ? 100
      : Math.max(
          (team.usedBillingLimits?.numberOfProjectTemplates /
            team.subscriptionLimits.maxProjectTemplateNumber) *
            100,
          0
        );
  }, [
    team.subscriptionLimits.maxProjectTemplateNumber,
    team.usedBillingLimits?.numberOfProjectTemplates,
  ]);

  return (
    <UsageBar
      name="BILLING.FEATURE.PROJECT_TEMPLATES.LABEL"
      tooltip={
        <UsageTooltip
          usageMessage1={t(
            "BILLING.FEATURE.PROJECT_TEMPLATES.HELP_MESSAGE.PART1"
          )}
          hrefText={t("BILLING.UPGRADE_YOUR_PLAN")}
          openBillingDialog={openBillingDialog}
          usageMessage2={t(
            "BILLING.FEATURE.PROJECT_TEMPLATES.HELP_MESSAGE.PART2"
          )}
        />
      }
      used={team.usedBillingLimits?.numberOfProjectTemplates}
      limit={getprojectTemplatesLimit()}
      percentage={determineUsagePercentage()}
    />
  );
}

ProjectTemplatesUsage.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default ProjectTemplatesUsage;
