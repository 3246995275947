// Stardard set of shadows
// The higher z, the larger the shadow
export default {
  "box-shadow-z0": "0 2px 3px 0 rgba(0, 0, 0, 0.10)",
  "box-shadow-z1": "0 2px 6px 0 rgba(0, 0, 0, 0.05)",
  "box-shadow-z2": "0 3px 9px rgba(0, 0, 0, 0.08)",
  "box-shadow-z3":
    "0 7px 14px rgba(50, 50, 93, 0.19), 0 3px 6px rgba(0, 0, 0, 0.08)",
  "box-shadow-z4":
    "0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07)",
  "box-shadow-z5":
    "0 18px 35px rgba(50, 50, 93, 0.1), 0 8px 15px rgba(0, 0, 0, 0.07)",
  "box-shadow-z6":
    "0 0 39px 0 rgba(0, 0, 0, 0.15), 0 2px 15px 0 rgba(0, 0, 0, 0.2)",
  "box-shadow-z7":
    "0 18px 115px rgba(50, 50, 93, 0.34), 0 8px 35px rgba(0, 0, 0, 0.36)",
  "box-shadow-table": "0 0 6px 0 rgba(203,203,203,0.5)",
  "box-shadow-z8": "0 2px 8px 2px rgba(229,229,229,0.5)",
  "box-shadow-upload-button": "0 2px 4px 0 rgba(194,194,194,0.5)",
  "box-shadow-upload-panel": "0 2px 6px 0 rgba(177,177,177,0.5)",
  "box-shadow-add-reviewer-button": "0px 2px 2px rgba(178, 178, 178, 0.5)",
  "box-shadow-upload-panel-step": "0 2px 4px 0 rgba(201,201,201,0.5)",
  "box-shadow-file-card": "-2px 0 13px rgb(0 0 0 / 20%)",
  "box-shadow-upload-button-panel": "0px 0px 10px rgba(145, 145, 145, 0.5)",
  "box-shadow-team-branding": "0 2px 5px 0 rgba(0,0,0,.26)",
  "box-shadow-step-settings": "0 2px 7px 1px rgba(207, 205, 205,0.5)",
  "box-shadow-notification-group": "0 3px 9px 0 rgba(0, 0, 0, 0.12)",
  "box-shadow-notification-center": "0 -1px 30px 0 rgba(0, 0, 0, 0.30)",
  "box-shadow-navbar": "0 1px 4px 0 #dbdbdb",
  "box-shadow-shared-version-review-cell": "0px 10px 20px rgba(0, 0, 0, 0.1)",
  "box-shadow-step-reviewer-menu": "0px 4px 10px rgba(224, 224, 224, 0.25)",
  "box-shadow-undo-review-decision-popup":
    "0px 2px 4px rgba(151, 151, 151, 0.25)",
  "box-shadow-start-review-button":
    "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
  "box-shadow-automations-empty-state-card": "0px 3px 6px rgba(0, 0, 0, 0.161)",
  "box-shadow-xlarge":
    "0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)",
  "box-shadow-integrations-sidebar": "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
};
