import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Tooltip } from "@shared/UIKit";

function ShowAllCollaboratorsButton({ showAllCollaborators, hiddenCount }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("COLLABORATORS.SHOW_MORE_COLLABORATORS")} placement="top">
      <Button
        variant="outlined"
        shape="round"
        color="primaryV2"
        onClick={showAllCollaborators}
        data-testid="show-all-collaborators-button"
        size="large"
      >
        +{hiddenCount}
      </Button>
    </Tooltip>
  );
}

ShowAllCollaboratorsButton.propTypes = {
  showAllCollaborators: PropTypes.func.isRequired,
  hiddenCount: PropTypes.number.isRequired,
};

export default ShowAllCollaboratorsButton;
