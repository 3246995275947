import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import ConfirmationDialog from "@shared/UIKit/ConfirmationDialog";

function CancelUploadDialog({ cancel, answer, open }) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(open);

  const cancelDialog = useCallback(() => {
    cancel();
    setIsOpen(false);
  }, [cancel, setIsOpen]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t("FILE.CANCEL_UPLOAD.TITLE")}
      description={t("FILE.CANCEL_UPLOAD.DESCRIPTION")}
      cancelLabel={t("CORE.BACK")}
      confirmLabel={t("FILE.CANCEL_UPLOAD.CONFIRM")}
      closeDialog={cancelDialog}
      answer={answer}
    />
  );
}

CancelUploadDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

CancelUploadDialog.defaultProps = {
  open: true,
};

export default CancelUploadDialog;
