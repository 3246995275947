import PropTypes from "prop-types";

import SidebarListItem from "../../SidebarList/SidebarListItem/SidebarListItem";

export default function TemplateListItem({
  name,
  isSelected,
  id,
  isLocked,
  onClick,
}) {
  return (
    <SidebarListItem
      id={id}
      name={name}
      isSelected={isSelected}
      isTemplate
      isLocked={isLocked}
      onClick={onClick}
    />
  );
}

TemplateListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
