import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { Text, Dialog } from "@shared/UIKit";

function RemoveLastTeamAdminDialog({ cancel, isOpen }) {
  const { t } = useTranslation();
  return (
    <Dialog
      cancel={cancel}
      open={isOpen}
      hideBackdrop={false}
      title={t("TEAM.REMOVE_MEMBER.REJECTED.HEADLINE")}
    >
      <Box mb={2.5}>
        <Text
          variant="body2"
          translate="TEAM.REMOVE_MEMBER.REJECTED.DESCRIPTION"
        />
      </Box>
    </Dialog>
  );
}

RemoveLastTeamAdminDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default RemoveLastTeamAdminDialog;
