/* istanbul ignore file */
import PropTypes from "prop-types";

import { Box, Image, Text } from "@shared/UIKit";

import Protected from "@assets/img/sprite-source/sprite--protected.png";

const AccessRequestHeading = ({ title, subtitle }) => (
  <Box display="flex" alignItems="center" flexDirection="column" gap={0.5}>
    <Image src={Protected} alt="Protected" />
    <Text
      component="h1"
      variant="text5xl"
      fontWeight="fontWeight.bold"
      translate={title}
    />
    {subtitle ? (
      <Text variant="textXl" color="text.secondary" translate={subtitle} />
    ) : null}
  </Box>
);
AccessRequestHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};
AccessRequestHeading.defaultProps = {
  subtitle: null,
};

export default AccessRequestHeading;
