import { queryClient } from "@shared/react-query";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import pendingReviewsService from "@workflow/services/pendingReviewsService";
import debounce from "lodash/debounce";

import { PENDING_REVIEWS_KEYS } from "@shared/query-keys";

const PAGE_SIZE = 10;

export const SORT_OPTIONS = {
  DUE_DATE: "DUE_DATE",
  NEWEST: "NEWEST",
  OLDEST: "OLDEST",
};

export const usePendingReviewsCount = () => {
  return useQuery({
    queryKey: PENDING_REVIEWS_KEYS.count(),
    queryFn: pendingReviewsService.fetchPendingReviewsCount,
  });
};

export const usePendingReviews = (sort) => {
  return useInfiniteQuery({
    queryKey: PENDING_REVIEWS_KEYS.sort(sort),
    queryFn: async ({ pageParam }) => {
      const response = await pendingReviewsService.fetchPendingReviews(
        sort,
        pageParam * PAGE_SIZE,
        PAGE_SIZE
      );
      return response;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) =>
      lastPage.length < PAGE_SIZE ? null : pages.length,
  });
};

// istanbul ignore next
export const pendingReviewsData = {
  invalidatePendingReviewsCount: () => {
    debounce(() => {
      queryClient.invalidateQueries({ queryKey: PENDING_REVIEWS_KEYS.count() });
    }, 3000)();
  },
  invalidatePendingReviews: () => {
    queryClient.invalidateQueries({
      queryKey: PENDING_REVIEWS_KEYS.list(),
      refetchType: "none",
    });
  },
  refetchPendingReviews: (sort) => {
    queryClient.refetchQueries({
      queryKey: PENDING_REVIEWS_KEYS.sort(sort),
    });
  },
  removeQueryIfInvalidated: () => {
    debounce(() => {
      queryClient.removeQueries({
        queryKey: PENDING_REVIEWS_KEYS.list(),
        exact: false,
        predicate: (query) => {
          return (
            query.state.fetchStatus === "idle" && query.state.isInvalidated
          );
        },
      });
    }, 3000)();
  },
};
