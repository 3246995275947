import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton as Button } from "@mui/lab";
import {
  Alert,
  TextField,
  IconButton,
  SvgIcon,
  Card,
  CardContent,
  Box,
} from "@mui/material";
import authenticationService from "@supporting/services/authentication";

import { Text, Tooltip } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as analytics } from "@shared/services/analytics";
import { usePageTitle } from "@shared/services/pageTitleService";

import KeyIcon from "@assets/img/icons/outline-vpn_key-24px.svg";

const selectPageTitleSetter = (state) => ({ setPageTitle: state.setTitle });

function ValidateStepPassword() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { setPageTitle } = usePageTitle(selectPageTitleSetter);
  const [passwordError, setPasswordError] = useState(null);
  const [password, setPassword] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const session = authenticationService.fetchSession();
  const isLoggedIn = Boolean(session) && session.type !== "Guest";

  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.REVIEW_STEP_PASSWORD_PAGE
    );
    setPageTitle(t("REVIEWER.ENTER_PASSWORD.PAGE-TITLE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const authorize = async (password) => {
    const redirect = searchParams.get("redirect");

    const stepId = searchParams.get("stepId");
    const reviewId = searchParams.get("reviewId");
    setIsLoading(true);

    try {
      await authenticationService.checkStepPassword({
        reviewId,
        stepId,
        password,
      });
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.REVIEW_STEP_PASSWORD,
        {
          page: "review-step-password",
        }
      );

      navigate(redirect);
    } catch (error) {
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.REVIEW_STEP_PASSWORD_FAILED,
        {
          page: "review-step-password",
        }
      );
      switch (error.status) {
        case 400:
          setPasswordError("FILE.PASSWORD_PROTECTED.ERROR.INVALID");
          break;
        case 500:
          setPasswordError("ERROR.SERVER_NOT_AVAILABLE");
          break;
        default:
          setPasswordError("ERROR.GENERAL");
          break;
      }
    }
    setIsLoading(false);
  };

  const goBack = () => {
    navigate(APP_ROUTES.DASHBOARD.path);
  };

  const handleChange = (event) => {
    setPassword(event.target.value);
    setIsSubmitted(true);
    setPasswordError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(false);
    authorize(password);
  };

  const hasError = !isSubmitted && passwordError && password.length;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
    >
      <Card sx={{ maxWidth: 700 }}>
        <CardContent
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            gap: 2,
            padding: 4,
          }}
        >
          {isLoggedIn && (
            <Tooltip
              title={t("WORKSPACE.SIDEBAR.BACK")}
              placement="right"
              enterDelay={100}
              enterNextDelay={100}
            >
              <IconButton
                size="small"
                onClick={goBack}
                sx={{ position: "absolute", left: 18, top: 28 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          )}
          <Text
            variant="textXl"
            fontWeight="fontWeight.medium"
            textAlign="center"
          >
            {t("FILE.PASSWORD_PROTECTED.LABEL")}
          </Text>
          <Text variant="textMd" textAlign="center">
            {t("FILE.PASSWORD_PROTECTED.DESCRIPTION")}
          </Text>
          <Box
            component="form"
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                px: 8,
              }}
            >
              <SvgIcon
                component={KeyIcon}
                sx={{
                  color: password.length ? "success.main" : undefined,
                  mr: 1,
                  mb: 2.5,
                }}
              />
              <TextField
                type="password"
                fullWidth
                id="step-password"
                label={t("FILE.PASSWORD_PROTECTED.INPUT-LABEL")}
                error={isSubmitted && !password.length}
                helperText={
                  isSubmitted && !password.length
                    ? t("FILE.PASSWORD_PROTECTED.ERROR.REQUIRED")
                    : " "
                }
                value={password}
                onChange={handleChange}
              />
            </Box>
            {hasError && (
              <Alert variant="filled" severity="error">
                {t(passwordError)}
              </Alert>
            )}

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!password.length}
              type="submit"
              loading={isLoading}
              sx={{ alignSelf: "flex-end", mt: 8 }}
            >
              {t("FILE.PASSWORD_PROTECTED.CTA")}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ValidateStepPassword;
