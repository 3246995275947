import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import UpgradePlanDialog from "@supporting/components/UpgradePlanDialog/UpgradePlanDialog";

import { Text, Image, Box } from "@shared/UIKit";

import ExportFileReportImage from "@assets/img/images/export-file-report-feature-image.jpg";

export function UpsellExportFileReportDialog({ teamId }) {
  const { t } = useTranslation();
  const modal = useModal();

  const onUpgradeButtonClick = () => {
    modal.hide();
    NiceModal.show(SelectPlanDialog, { teamId });
  };

  return (
    <UpgradePlanDialog
      id="UPGRADE.REVIEW-REPORTS.TITLE"
      dialogTitle="UPGRADE.REVIEW-REPORTS.TITLE"
      isUpgradePlanDialogOpen={modal.visible}
      onCancel={() => modal.hide()}
    >
      <>
        <Text variant="body2">{t("UPGRADE.REVIEW-REPORTS.DESCRIPTION")}</Text>
        <Box mx="auto" my={3.75}>
          <Image
            src={ExportFileReportImage}
            alt="export-file-report-thumbnail"
            width="100%"
            height="auto"
            maxWidth="100%"
          />
        </Box>
        <Button
          onClick={onUpgradeButtonClick}
          color="upgrade"
          variant="contained"
        >
          {t("UPGRADE_BUTTON.LABEL")}
        </Button>
      </>
    </UpgradePlanDialog>
  );
}

export default NiceModal.create(UpsellExportFileReportDialog);

UpsellExportFileReportDialog.propTypes = {
  teamId: PropTypes.string.isRequired,
};
