import toastService from "@supporting/services/toast";
import * as stepService from "@workflow/services/stepService";

import errorHandler from "@shared/services/errorHandler";
import { instance as i18next } from "@shared/services/localisationService";
import { history } from "@shared/services/navigation";
export const processStepAccessControlDecision = async (request, project) => {
  const url = new URL(request.url);

  const searchParams = new URLSearchParams(url.search);
  const accessRequest = searchParams.get("accessRequest");
  if (!accessRequest) {
    return;
  }
  const stepId = searchParams.get("stepId");
  const reviewerId = searchParams.get("reviewerId");
  const steps = await stepService.getProjectSteps(project.id);

  const step = steps.find((step) => step.id === stepId);

  if (!step) {
    toastService.sendToast({
      title: "error",
      body: "step not found",
      preset: toastService.PRESETS().ERROR,
    });
    return;
  }

  const accessRequests = step.accessRequests;

  const reviewerAccessRequest = accessRequests.find(
    (accessRequest) => accessRequest.reviewerId === reviewerId
  );

  if (!reviewerAccessRequest) {
    toastService.sendToast({
      title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
      body: "ERROR.ACTION_NOT_POSSIBLE.BODY",
      preset: toastService.PRESETS().ERROR,
    });
    return;
  }

  if (reviewerAccessRequest.status !== "PENDING") {
    toastService.sendToast({
      title: i18next.i18n.t("ACCESS_CONTROL.REVIEWED.TOAST.TITLE"),
      body: i18next.i18n.t(
        `ACCESS_CONTROL.REVIEWER_${reviewerAccessRequest.status}.TOAST.BODY`,
        { name: reviewerAccessRequest.reviewer.displayName }
      ),
      preset: toastService.PRESETS().ERROR,
    });
    return;
  }
  try {
    const isAccepted = accessRequest === "true";
    await stepService.submitAccessRequestDecision(
      stepId,
      reviewerId,
      isAccepted
    );
    toastService.sendToast({
      title: i18next.i18n.t(
        `ACCESS_CONTROL.${isAccepted ? "APPROVED" : "DECLINED"}.TOAST.TITLE`
      ),
      body: i18next.i18n.t(
        `ACCESS_CONTROL.${isAccepted ? "APPROVED" : "DECLINED"}.TOAST.BODY`,
        {
          name: reviewerAccessRequest.reviewer.displayName,
          reviewerGroup: step.name,
        }
      ),
      preset: toastService.PRESETS()[isAccepted ? "SUCCESS" : "ERROR"],
    });
    searchParams.delete("accessRequest");
    searchParams.delete("stepId");
    searchParams.delete("reviewerId");
    history.navigate({ search: searchParams.toString() });
  } catch (error) {
    errorHandler.handleError(error);
  }
};
