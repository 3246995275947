import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { Image } from "@shared/UIKit";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

function TeamLogo({ logoUrl }) {
  const handleLogoHover = () => {
    eventService.emitEvent({
      eventName: EVENT_NAMES.REVIEW.REMINDER.SHOW,
      eventData: {},
    });
  };

  const containerProps = {
    display: "flex",
    onMouseOver: handleLogoHover,
    sx: {
      "& img": {
        maxWidth: "100%",
        maxHeight: 40,
      },
    },
  };

  return logoUrl ? (
    <Box data-testid="topbar-logo-container" {...containerProps}>
      <Image
        noretina="true"
        src={logoUrl}
        alt="Logo"
        data-testid="topbar-logo-image-view"
      />
    </Box>
  ) : (
    <Box
      className="tour__filestage-logo"
      data-testid="topbar-no-logo-container"
      {...containerProps}
    >
      <FilestageResponsiveLogo />
    </Box>
  );
}

TeamLogo.propTypes = {
  logoUrl: PropTypes.string,
};

TeamLogo.defaultProps = {
  logoUrl: null,
};

export default TeamLogo;
