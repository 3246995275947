import PropTypes from "prop-types";

import { Box, Dialog } from "@shared/UIKit";

import { useScript } from "@shared/hooks";

function TypeformDialog({ formId, closeDialog, hidden }) {
  useScript("//embed.typeform.com/next/embed.js");

  /* istanbul ignore next */
  window.typeFormSubmitted = () => {
    setTimeout(() => {
      closeDialog();
    }, 5000);
  };

  return (
    <Dialog
      useDefaultBackground={false}
      content={
        <Box
          data-tf-widget={formId}
          data-tf-medium="snippet"
          data-tf-hidden={hidden}
          data-tf-on-submit="typeFormSubmitted"
          height={500}
          width="100%"
        />
      }
      isOpen
      maxWidth="lg"
      cancel={closeDialog}
      data-testid="typeform-dialog"
      hideBackdrop={false}
      disableBackdropClick
    />
  );
}

TypeformDialog.propTypes = {
  formId: PropTypes.string.isRequired,
  hidden: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default TypeformDialog;
