import toastService from "@supporting/services/toast";
import AwsS3 from "@uppy/aws-s3";
import { Uppy } from "@uppy/core";
import assign from "lodash/assign";
import find from "lodash/find";
import some from "lodash/some";

import fstgId from "@shared/helpers/fstgId.js";
export const fields = {};
import backend from "@shared/services/backendClient";
import browserTabId from "@shared/services/browserTabId";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";
import nativeWarningHandlerService from "@shared/services/nativeWarningHandlerService";
import { instance as websocket } from "@shared/services/websocket";
export let instance;
const MEGABYTES = 1048576;
export async function initialize() {
  const TAG = "file-storage-v2";

  function unregisterUnloadWarning() {
    nativeWarningHandlerService.unregisterUnloadWarning();
  }

  function registerUnloadWarning() {
    nativeWarningHandlerService.registerUnloadWarning();
  }

  const shouldStartReviewBeforeTranscode = (fileType = "") => {
    return fileType === "application/pdf" || fileType.includes("video");
  };

  async function isS3EndpointAccessible() {
    try {
      const resp = await fetch(window.fs.config.s3AcceleratedBaseUrl, {
        method: "PUT",
      });
      if (resp.status === 403) {
        return true;
      }
      logger.warn(TAG, "s3 endpoint not accessible", {
        statusText: resp.statusText,
        status: resp.status,
      });
    } catch (error) {
      logger.warn(TAG, "s3 endpoint not accessible", {
        error,
      });
    }
    return false;
  }

  const remoteAssemblies = {};
  instance = {
    processFile,
    processLocalFile,
    processRemoteFile,
    processWebsiteImport,
    isUploadQueued,
    cancel,
    getFile,
    remoteAssemblies,
    isUploadFailed: false,
  };
  const isS3Available = await isS3EndpointAccessible();
  const uppy = new Uppy({
    id: "aws-multipart",
    autoProceed: true,
    allowMultipleUploadBatches: true,
  });
  uppy.use(AwsS3, {
    retryDelays: [0, 1000, 3000, 5000],
    async getUploadParameters(file) {
      healthMetrics.trackStart("supporting.upload-file-v2");
      const file_fields = fields[file.meta.id];

      file.meta.started = Date.now();
      const data = await backend.post("/file-storage/s3-create", {
        ...file_fields,
        contentType: file.type,
        fileName: file.name,
        fileExtension: file.extension,
        useCDN: !isS3Available || instance.isUploadFailed,
      });
      fields[file.meta.id].key = data.key;
      return data;
    },
    async createMultipartUpload(file) {
      const file_fields = fields[file.meta.id];

      healthMetrics.trackStart("supporting.upload-file-v2");

      file.meta.started = Date.now();
      const data = await backend.post("/file-storage/s3-create", {
        ...file_fields,
        contentType: file.type,
        fileName: file.name,
        fileExtension: file.extension,
      });
      fields[file.meta.id].key = data.key;
      return data;
    },
    shouldUseMultipart(file) {
      fields[file.meta.id].shouldUseMultipart = file.size > 100 * MEGABYTES;
      return fields[file.meta.id].shouldUseMultipart;
    },
    //eslint-disable-next-line no-unused-vars
    async signPart(file, fields) {
      const { key, uploadId, partNumber } = fields;
      return await backend.post("/file-storage/s3-multipart-create-signedurl", {
        key,
        uploadId,
        partNumber,
        useCDN: !isS3Available || instance.isUploadFailed,
      });
    },
    getChunkSize(file) {
      if (file.size / MEGABYTES < 500) {
        return 20 * MEGABYTES;
      }
      return 50 * MEGABYTES;
    },
    //eslint-disable-next-line no-unused-vars
    abortMultipartUpload(file, { uploadId, key }) {
      healthMetrics.trackCancellation("supporting.upload-file-v2");
      return backend.post("/file-storage/s3-multipart-abort", {
        uploadId,
        key,
      });
    },
    //eslint-disable-next-line no-unused-vars
    completeMultipartUpload(file, { uploadId, key, parts }) {
      return backend.post("/file-storage/s3-multipart-complete", {
        uploadId,
        key,
        parts,
      });
    },

    limit: 4,
  });

  uppy.on("upload-progress", (file, progress) => {
    eventService.emitEvent({
      eventName: EVENT_NAMES.UPLOAD.PROGRESS,
      eventData: {
        uploadId: file.meta.id,
        total: progress.bytesTotal,
        loaded: progress.bytesUploaded,
        percentage: (progress.bytesUploaded / progress.bytesTotal) * 100,
      },
    });
  });

  uppy.on("upload-success", async (file) => {
    const file_fields = fields[file.meta.id];
    await backend.post("/file-storage/s3-complete", {
      key: file_fields.key,
      retryTranscodingFlow: true,
      meta: {
        fileName: file.name,
        browserTabId: browserTabId.get(),
        ...file_fields,
        ...(shouldStartReviewBeforeTranscode(file.type) && {
          startReviewBeforeTranscode: "true",
        }),
      },
    });
    healthMetrics.trackSuccess("supporting.upload-file-v2");
    unregisterUnloadWarning();

    eventService.emitEvent({
      eventName: EVENT_NAMES.TRANSCODING.PROGRESS,
      eventData: {
        uploadId: file.meta.id,
      },
    });

    logger.info(TAG, "successfully uploaded file v2", {
      id: file.meta.id,
      isS3Available,
      file: file.name,
      template: fields[file.meta.id].template,
      fileSizeInMB: file.progress.bytesTotal / MEGABYTES,
      timeElapsedInMilliSeconds: Date.now() - file.progress.uploadStarted,
      avgSpeedInMBps:
        file.progress.bytesTotal /
        ((Date.now() - file.progress.uploadStarted) / 1000) /
        MEGABYTES,
    });
    delete fields[file.meta.id];
  });
  uppy.on("upload-error", (file, error) => {
    healthMetrics.trackFailure("supporting.upload-file-v2", error);
    unregisterUnloadWarning();
    instance.isUploadFailed = true;
    if (error.isNetworkError) {
      logger.warn(
        TAG,
        "unable to reach S3",
        assign({ error }, fields[file.meta.id])
      );
      toastService.sendToast({
        title: "UPLOAD.UPLOAD_NOT_AVAILABLE.TITLE",
        body: "UPLOAD.UPLOAD_NOT_AVAILABLE.BODY",
        preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
      });
    } else {
      logger.error(
        TAG,
        "error uploading file",
        assign({ error }, fields[file.meta.id], { isS3Available })
      );
      toastService.sendToast({
        title: "UPLOAD.ERROR.TITLE",
        body: "UPLOAD.ERROR.BODY",
        preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
        translationVariables: {
          body: { fileName: file.name },
        },
      });
    }
    eventService.emitEvent({
      eventName: EVENT_NAMES.UPLOAD.ERROR,
      eventData: {
        uploadId: file.meta.id,
        error,
      },
    });
    delete fields[file.meta.id];
    uppy.removeFile(file.id);
  });

  function onWebsocketsUnavailable(id) {
    logger.error(TAG, "websockets are unavailable");
    toastService.sendToast({
      title: "UPLOAD.UPLOAD_NOT_AVAILABLE.TITLE",
      body: "UPLOAD.UPLOAD_NOT_AVAILABLE.BODY",
      preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
    });
    eventService.emitEvent({
      eventName: EVENT_NAMES.UPLOAD.ERROR,
      eventData: {
        uploadId: id,
      },
    });
  }

  function processFile(fileHandle, template, meta) {
    const id = fstgId.generate();
    if (!websocket.isConnected()) {
      onWebsocketsUnavailable(id);
      return id;
    }
    if (template === "WEBSITE_THUMBNAIL") {
      processWebsiteImport(id, fileHandle, template, meta);
    } else if (fileHandle.isRemote) {
      processRemoteFile(id, fileHandle, template, meta);
    } else {
      processLocalFile(id, fileHandle, template, meta);
    }

    logger.info(TAG, "started uploading file v2", {
      id,
      file: fileHandle.name,
      template,
      meta,
      isS3Available,
    });

    return id;
  }

  function processLocalFile(id, fileHandle, template, meta) {
    fields[id] = Object.assign(
      {
        id,
        template,
        mimeType: fileHandle.type,
        size: fileHandle.size,
      },
      meta
    );
    uppy.addFile({
      name: fileHandle.name,
      type: fileHandle.type,
      data: fileHandle,
      meta: {
        id,
        relativePath: id, // to allow uploading the same file multiple times
      },
    });
    registerUnloadWarning();
  }

  async function processWebsiteImport(id, remoteFile, template, meta) {
    try {
      await backend.post("/file-storage/process-website", {
        template,
        url: remoteFile.url,
        headers: remoteFile.headers,
        meta: Object.assign(
          {
            id,
            fileName: remoteFile.name,
            browserTabId: browserTabId.get(),
          },
          meta
        ),
      });
      eventService.emitEvent({
        eventName: EVENT_NAMES.TRANSCODING.PROGRESS,
        eventData: {
          uploadId: id,
        },
      });
    } catch {
      eventService.emitEvent({
        eventName: EVENT_NAMES.UPLOAD.ERROR,
        eventData: {
          uploadId: id,
        },
      });
      toastService.sendToast({
        title: "IMPORT.ERROR.TITLE",
        body: "IMPORT.ERROR.BODY",
        preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
      });
    }
  }

  async function processRemoteFile(id, remoteFile, template, meta) {
    try {
      const assemblyId = await backend.post(
        `/file-storage/process-remote-file`,
        {
          template,
          url: remoteFile.url,
          headers: remoteFile.headers,
          meta: Object.assign({ id, fileName: remoteFile.name }, meta),
        }
      );
      remoteAssemblies[id] = assemblyId;

      eventService.emitEvent({
        eventName: EVENT_NAMES.TRANSCODING.PROGRESS,
        eventData: {
          uploadId: id,
        },
      });
    } catch {
      eventService.emitEvent({
        eventName: EVENT_NAMES.UPLOAD.ERROR,
        eventData: {
          uploadId: id,
        },
      });
    }
  }

  function isUploadQueued(fileId) {
    return some(uppy.getFiles(), { meta: { id: fileId } });
  }

  async function cancel(fileId) {
    if (remoteAssemblies[fileId]) {
      await backend.delete(
        `/file-storage/cancel-remote-file/${remoteAssemblies[fileId]}`
      );
      delete remoteAssemblies[fileId];
    }

    unregisterUnloadWarning();

    const file = getFile(fileId);

    if (file) {
      uppy.removeFile(file.id);
    }
    return Promise.resolve();
  }

  function getFile(fileId) {
    return find(uppy.getFiles(), { meta: { id: fileId } });
  }
}
