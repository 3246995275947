import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { useMediaQuery, Tabs, Tab, IconButton } from "@mui/material";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";

import { Box } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import ReviewerGroupEmailNotificationsTab from "./ReviewerGroupEmailNotificationsTab";
import ReviewerGroupPermissionsTab from "./ReviewerGroupPermissionsTab";
import ReviewerGroupSecurityTab from "./ReviewerGroupSecurityTab";

function CustomTabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      data-testid={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box overflow="auto">{children}</Box>}
    </div>
  );
}

function parseEvent(field) {
  const categories = {
    downloadInReview: "Download-In-Review",
    downloadNeedsChanges: "Download-Needs-Changes",
    downloadApproved: "Download-Approved",
    commentInReview: "Comment-In-Review",
    commentNeedsChanges: "Comment-Needs-Changes",
    commentApproved: "Comment-Approved",
    emailNotifications: "Project-Activity-Email",
    uploadingEnabled: "Upload-Files",
    anonymousReviewers: "Anonymize-Reviewers",
    digitalSignatureRequired: "Digital-Signature-Required",
    reviewStartedEmailNotification: "email-preferences-review-started",
    reviewAccessControl: "review-access-control",
  };
  return field in categories ? categories[field] : "Password";
}

function ReviewerGroupSettingsPanel({
  teamId,
  onClose,
  settings,
  stepId,
  isStepTemplate,
  onSettingsChanged,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [value, setValue] = useState(0);
  const [stepSettings, setStepSettings] = useState(settings);

  const handleSave = useCallback(
    async (stepId, stepSettings) => {
      if (!isEqual(settings, stepSettings)) {
        let updateSettings;
        if (isStepTemplate) {
          updateSettings = projectTemplateService.updateStepSettings;
          eventService.emitEvent({
            eventName: EVENT_NAMES.STEP_TEMPLATE.SETTINGS.UPDATED,
            eventData: {
              stepTemplateId: stepId,
              settings: stepSettings,
            },
          });
        } else {
          updateSettings = stepService.updateSettings;
        }
        onSettingsChanged(stepSettings);

        await updateSettings(stepId, stepSettings);
        return true;
      }
      return false;
    },
    [isStepTemplate, onSettingsChanged, settings]
  );

  const handleChange = useCallback(
    async ({ field, value }) => {
      const newStepSettings = { ...stepSettings, [field]: value };
      if (field === "passwordProtection" && !value) {
        delete newStepSettings["password"];
      }

      if (field !== "passwordProtection" || !value) {
        try {
          if (await handleSave(stepId, newStepSettings)) {
            const category = `${
              analytics.CATEGORY.REVIEW_STEP_SETTINGS
            }-${parseEvent(field)}`;
            analytics.track(
              value ? analytics.ACTION.ENABLED : analytics.ACTION.DISABLED,
              category
            );
          }
        } catch (error) {
          errorHandlerService.handleError(error);
        }
      }

      setStepSettings(newStepSettings);
    },
    [stepSettings, stepId, handleSave]
  );

  useEffect(() => {
    setStepSettings(settings);
  }, [settings]);

  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      data-testid="reviewer-group-settings-panel"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
        mb={2}
      >
        <Tabs
          value={value}
          onChange={handleTabChange}
          data-size="small"
          sx={{
            "& .MuiTab-root": {
              textTransform: "none", // to remove when theme is ready
            },
          }}
          {...(isMobile
            ? {
                variant: "scrollable",
                scrollButtons: true,
                allowScrollButtonsMobile: true,
              }
            : {})}
        >
          <Tab
            icon={<PermIdentityIcon />}
            data-testid="tab-permissions"
            iconPosition="start"
            label={t("REVIEWER_SETTINGS.TAB_PERMISSIONS")}
          />
          <Tab
            data-testid="tab-email"
            icon={<MailOutlineIcon />}
            iconPosition="start"
            label={t("REVIEWER_SETTINGS.TAB_EMAIL")}
          />
          <Tab
            data-testid="tab-security"
            icon={<LockOpenIcon />}
            iconPosition="start"
            label={t("REVIEWER_SETTINGS.TAB_SECURITY")}
          />
        </Tabs>
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          data-testid="close-reviewer-settings-button"
          sx={{
            mr: 1,
            mt: 0.5,
            alignSelf: "baseline",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ReviewerGroupPermissionsTab
          teamId={teamId}
          settings={stepSettings}
          onChange={handleChange}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ReviewerGroupEmailNotificationsTab
          settings={stepSettings}
          onChange={handleChange}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ReviewerGroupSecurityTab
          teamId={teamId}
          settings={stepSettings}
          onChange={handleChange}
        />
      </CustomTabPanel>
    </Box>
  );
}

ReviewerGroupSettingsPanel.propTypes = {
  teamId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    downloadInReview: PropTypes.bool.isRequired,
    downloadNeedsChanges: PropTypes.bool,
    downloadApproved: PropTypes.bool.isRequired,
    commentInReview: PropTypes.bool.isRequired,
    commentNeedsChanges: PropTypes.bool,
    commentApproved: PropTypes.bool.isRequired,
    emailNotifications: PropTypes.bool.isRequired,
    uploadingEnabled: PropTypes.bool.isRequired,
    anonymousReviewers: PropTypes.bool.isRequired,
    passwordProtection: PropTypes.bool.isRequired,
  }).isRequired,
  onClose: PropTypes.func,
  onSettingsChanged: PropTypes.func,
  stepId: PropTypes.string.isRequired,
  isStepTemplate: PropTypes.bool.isRequired,
};

ReviewerGroupSettingsPanel.defaultProps = {
  onClose: noop,
  onSettingsChanged: noop,
};

export default ReviewerGroupSettingsPanel;
