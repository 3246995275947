import { Box } from "@mui/material";
import WizardBase from "@supporting/components/WizardBase/WizardBase";

import EnforcePasswordChangeForm from "./EnforcePasswordChangeForm";

function EnforcePasswordChangePage() {
  return (
    <WizardBase variant="LOGIN">
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="row"
        boxSizing="border-box"
      >
        <Box
          display="flex"
          flex="1 1 100%"
          maxHeight="100%"
          boxSizing="border-box"
          flexDirection="column"
        >
          <EnforcePasswordChangeForm />
        </Box>
      </Box>
    </WizardBase>
  );
}

export default EnforcePasswordChangePage;
