import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Box, Dialog, Text } from "@shared/UIKit";

import FolderForm from "../../../Folder/FolderForm/FolderForm";
import useFolderForm from "../../../Folder/FolderForm/useFolderForm";

const AddFolderDialog = ({ teamId, answer, cancel }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useFolderForm();

  const cancelDialog = () => {
    cancel();
  };

  const handleFormSubmit = async ({ folderName }) => {
    setIsLoading(true);
    await answer({
      teamId,
      name: folderName,
    });
    setIsLoading(false);
  };

  return (
    <Dialog
      data-testid="new-folder-dialog"
      maxWidth="xs"
      hideBackdrop={false}
      disableBackdropClick
      open
      cancel={cancelDialog}
      title={t("PROJECT.FOLDER.ADD.HEADER")}
      actions={
        <Button
          type="submit"
          form="add-folder-form"
          color="primary"
          data-testid="add-team-folder-dialog-button"
          variant="contained"
          disabled={!isValid || isLoading}
        >
          {t("PROJECT.FOLDER.ADD.ACTION")}
        </Button>
      }
    >
      <Box
        data-testid="new-folder-dialog"
        display="flex"
        flexDirection="column"
        gap={4}
        p={2}
      >
        <Box>
          <Text
            variant="textSm"
            color="text.secondary"
            whiteSpace="pre-line"
            translate="PROJECT.FOLDER.ADD.DESCRIPTION-1"
          />
        </Box>
        <FolderForm
          formId="add-folder-form"
          handleSubmit={handleSubmit(handleFormSubmit)}
          control={control}
          errors={errors}
        />
      </Box>
    </Dialog>
  );
};

AddFolderDialog.propTypes = {
  teamId: PropTypes.string.isRequired,
  answer: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default AddFolderDialog;
