import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";

import { STATIC_GUIDE } from "./constants";

function ChecklistItem({ index, checklistItem, previewGuide }) {
  const { t } = useTranslation();
  const { guideId, completed } = checklistItem;

  return (
    <ListItem
      sx={{
        ...(guideId !== STATIC_GUIDE && {
          "&:hover .MuiChip-root": {
            visibility: "visible",
          },
        }),
      }}
    >
      <ListItemButton
        data-testid={`checklist-item-${guideId}`}
        sx={{ bgcolor: "white" }}
        onClick={() => previewGuide(guideId)}
      >
        <ListItemIcon>
          <Radio
            checked={completed}
            disableRipple
            tabIndex={-1}
            inputProps={{ "aria-labelledby": guideId }}
            sx={{ ml: -0.5 }}
          />
        </ListItemIcon>
        <ListItemText
          id={guideId}
          primaryTypographyProps={{
            sx: {
              color: completed ? "grey.400" : "grey.800",
              textDecoration: completed ? "line-through" : "none",
            },
          }}
        >
          {t(`CHECKLIST.ONBOARDING_SECTION.${index + 1}`)}
        </ListItemText>
        <Chip
          label={t("CHECKLIST.ONBOARDING_SECTION.START_TOUR")}
          size="small"
          sx={{ visibility: "hidden", mr: 1 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

ChecklistItem.propTypes = {
  index: PropTypes.number.isRequired,
  checklistItem: PropTypes.object.isRequired,
  previewGuide: PropTypes.func.isRequired,
};

export default ChecklistItem;
