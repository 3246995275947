import { useEffect, useState } from "react";

import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

const useUnreadMessagesCount = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(
    analytics.unreadCount
  );

  useEffect(() => {
    const onUnreadCountChange = ({ eventData: { count } }) => {
      setUnreadMessagesCount(count);
    };

    eventService.addListener(
      EVENT_NAMES.INTERCOM.ON_UNREAD_COUNT_CHANGE,
      onUnreadCountChange
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.INTERCOM.ON_UNREAD_COUNT_CHANGE,
        onUnreadCountChange
      );
    };
  }, []);

  return unreadMessagesCount;
};

export default useUnreadMessagesCount;
