import PropTypes from "prop-types";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { Check, Close, Replay } from "@mui/icons-material";
import { Box, Checkbox } from "@mui/material";

import { Text } from "@shared/UIKit";

const TRANSLATION_KEYS = {
  APPROVED: "REVIEW_BAR.APPROVE_BUTTON",
  NEEDS_CHANGES: "REVIEW_BAR.REQUEST_CHANGES_BUTTON",
  APPROVED_WITH_CHANGES: "REVIEW_BAR.APPROVE_WITH_CHANGES_BUTTON",
  REJECTED: "REVIEW_BAR.REJECT_BUTTON",
};

const ICONS = {
  APPROVED: <Check fontSize="inherit" />,
  NEEDS_CHANGES: (
    <Replay
      fontSize="inherit"
      sx={{
        transform: "rotate(-45deg)",
      }}
    />
  ),
  APPROVED_WITH_CHANGES: <Check fontSize="inherit" />,
  REJECTED: <Close fontSize="inherit" />,
};

const COLORS = {
  APPROVED: "white",
  NEEDS_CHANGES: "white",
  APPROVED_WITH_CHANGES: "approved.main",
  REJECTED: "rejected.main",
};

const BG_COLORS = {
  APPROVED: "approved.main",
  NEEDS_CHANGES: "needsChanges.main",
  APPROVED_WITH_CHANGES: "transparent",
  REJECTED: "transparent",
};

const BORDER_COLORS = {
  APPROVED: "transparent",
  NEEDS_CHANGES: "transparent",
  APPROVED_WITH_CHANGES: "var(--mui-color-green-300)",
  REJECTED: "var(--mui-color-red-300)",
};

const ReviewDecisionOption = ({
  state,
  isSelected,
  isDisabled,
  toggleSelect,
}) => {
  const checkboxId = `${state}-checkbox`;

  return (
    <Box mb={2} display="flex" alignItems="center">
      <Checkbox
        checked={isSelected}
        disabled={isDisabled}
        onChange={toggleSelect}
        id={checkboxId}
      />

      <Box
        as="label"
        htmlFor={checkboxId}
        minWidth={{ xs: "unset", sm: "130px" }}
        width="fit-content"
        height="40px"
        px={2}
        display="flex"
        alignItems="center"
        bgcolor={BG_COLORS[state]}
        color={COLORS[state]}
        border={1}
        borderColor={BORDER_COLORS[state]}
        borderRadius={1.335}
        boxSizing="border-box"
      >
        <Box as="span" height="20px" fontSize="20px">
          {ICONS[state]}
        </Box>

        <Text
          ml={1}
          variant="textSm"
          fontWeight="fontWeight.semiBold"
          lineHeight="20px"
          translate={TRANSLATION_KEYS[state]}
        />
      </Box>
    </Box>
  );
};

ReviewDecisionOption.propTypes = {
  state: PropTypes.oneOf(Object.keys(REVIEW_DECISION_STATES)).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  toggleSelect: PropTypes.func.isRequired,
};

ReviewDecisionOption.defaultProps = {
  isDisabled: false,
};

export default ReviewDecisionOption;
