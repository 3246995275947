import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";
import projectService from "@workflow/services/projectService";
import * as stepService from "@workflow/services/stepService";

import pagesEnum from "@shared/constants/pages";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import { fileProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as logger } from "@shared/services/logger";

import UploadedFilesContainer from "./UploadedFilesContainer";

const FOLDER_NAME = "My first folder";
const CUSTOM_PROJECT_NAME = "My first project";

function UploadOnlyFromDevice({
  teamId,
  project,
  setProject,
  setIsLoading,
  isLoading,
  files,
}) {
  const { t } = useTranslation();

  const handleProcessUpload = (files, projectId, sectionId, steps) => {
    const stepIds = steps.map((step) => step.id);
    return fileService.uploadFiles({
      files,
      projectId,
      stepIds,
      sectionId,
      page: pagesEnum.ONBOARDING_WIZARD,
      component: UPLOAD_COMPONENT_TYPE.POPOVER,
      steps,
    });
  };

  const handleCreateProjectAndUploadFiles = async (files) => {
    try {
      const validFiles = await fileService.validateFileHandles(files, teamId);

      let selectedProject = project;
      if (!selectedProject) {
        setIsLoading(true);
        const folders = await projectService.fetchProjectsByTeamId(teamId);

        let selectedFolder;
        if (folders.length > 0) {
          selectedFolder = folders[0];
        } else {
          selectedFolder = await projectService.addFolder({
            teamId,
            name: FOLDER_NAME,
          });
        }
        selectedProject = selectedFolder.projects.find(
          (project) => project.name === CUSTOM_PROJECT_NAME
        );

        if (!selectedProject) {
          selectedProject = await projectService.addProject({
            name: CUSTOM_PROJECT_NAME,
            teamId,
            folderId: selectedFolder.id,
            projectTemplateId: null,
          });
        }

        setProject(selectedProject);
      }

      const steps = await stepService.getProjectSteps(selectedProject.id);
      await handleProcessUpload(
        validFiles,
        selectedProject.id,
        selectedProject.sections[0].id,
        steps
      );
    } catch (error) {
      errorHandlerService.handleError(error);

      logger.warn("UploadPanel", "upload failed", {
        files,
        teamId,
        error,
      });
    }
  };

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleCreateProjectAndUploadFiles,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    useFsAccessApi: false,
  });

  return (
    <Box
      display="flex"
      minHeight={68}
      maxHeight={150}
      borderRadius={1}
      overflow="scroll"
      alignItems="center"
      border="1px dashed"
      flexDirection="column"
      bgcolor={isDragActive ? "grey.200" : "grey.50"}
      borderColor={isDragActive ? "grey.50" : "grey.200"}
      justifyContent={
        files.length === 0 || isLoading ? "center" : "space-between"
      }
      sx={{
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      {...getRootProps()}
    >
      <UploadedFilesContainer files={files} />
      {/* eslint-disable-next-line react/forbid-elements */}
      <input
        {...getInputProps()}
        accept="*/*"
        data-testid="upload-panel-from-local-machine-input"
      />

      <Button
        onClick={open}
        variant="text"
        color="primary"
        loading={isLoading}
        data-testid="onboarding-file-upload"
        sx={{
          alignSelf: files.length > 0 ? "flex-end" : "auto",
        }}
      >
        {t(
          files.length > 0
            ? "ONBOARDING_WIZARD.UPLOAD_FILES.BUTTONS.UPLOAD_MORE"
            : "ONBOARDING_WIZARD.DEMO.OWN_FILE"
        )}
      </Button>
    </Box>
  );
}

UploadOnlyFromDevice.propTypes = {
  files: PropTypes.arrayOf(fileProp),
  teamId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setProject: PropTypes.func.isRequired,
  project: PropTypes.shape(projectProps),
  setIsLoading: PropTypes.func.isRequired,
};

UploadOnlyFromDevice.defaultProps = {
  files: [],
  project: null,
};

export default UploadOnlyFromDevice;
