import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { ListSubheader } from "@mui/material";
import capitalize from "lodash/capitalize";

import { Box, ReviewDecisionIndicator, Text } from "@shared/UIKit";

import { reviewDecisionProp } from "@shared/props/review";

import ReviewDecision from "./ReviewDecision";

const TRANSLATION_KEYS = {
  PENDING: "FILE_REVIEW.SUMMARY.PENDING",
  [REVIEW_DECISION_STATES.APPROVED]: "FILE_REVIEW.SUMMARY.APPROVED",
  [REVIEW_DECISION_STATES.NEEDS_CHANGES]: "FILE_REVIEW.SUMMARY.DECLINED",
  [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]:
    "REVIEW_DECISION_OVERVIEW.APPROVED2",
  [REVIEW_DECISION_STATES.REJECTED]: "REVIEW_DECISION_OVERVIEW.REJECTED",
};

const COLORS = {
  PENDING: "pending.dark",
  [REVIEW_DECISION_STATES.APPROVED]: "approved.main",
  [REVIEW_DECISION_STATES.NEEDS_CHANGES]: "needsChanges.main",
  [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]: "approved.main",
  [REVIEW_DECISION_STATES.REJECTED]: "rejected.main",
};

function ReviewDecisionList({
  reviews,
  variant,
  openAddReviewerDialog,
  isReadOnly,
}) {
  const { t } = useTranslation();

  return (
    reviews.length > 0 && (
      <>
        <ListSubheader
          disableSticky
          sx={{
            pl: 0.5,
          }}
        >
          <Box
            display="flex"
            gap={1}
            my={1}
            mt={2.5}
            data-testid={`review-decision-list-${variant}`}
          >
            <ReviewDecisionIndicator state={variant} variant="noBorder" />
            <Text
              variant="subtitle1"
              display="inline-block"
              fontWeight="fontWeight.bold"
              color={COLORS[variant]}
            >
              {capitalize(t(TRANSLATION_KEYS[variant]))}
            </Text>
          </Box>
        </ListSubheader>
        {reviews.map((review) => (
          <ReviewDecision
            openAddReviewerDialog={openAddReviewerDialog}
            data-testid="review-decision-list-review"
            key={review.reviewer._id}
            reviewer={review.reviewer}
            reviewDate={review.date}
            digitallySigned={review.digitallySigned}
            isReadOnly={isReadOnly}
          />
        ))}
      </>
    )
  );
}

ReviewDecisionList.propTypes = {
  reviews: PropTypes.arrayOf(reviewDecisionProp),
  variant: PropTypes.oneOf(["PENDING", ...Object.keys(REVIEW_DECISION_STATES)]),
  openAddReviewerDialog: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

ReviewDecisionList.defaultProps = {
  reviews: [],
  variant: "PENDING",
  isReadOnly: false,
};

export default ReviewDecisionList;
