import authentication from "@supporting/services/authentication";
import userService from "@supporting/services/userService";

import { instance as analytics } from "@shared/services/analytics";
import errorHandler from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

/**
 * @description Try to login a guest user
 * @param {string} email The email address of the user
 * @param {String} stepId The Step ID that the user is trying to access
 * @param {Object} param2 The callbacks to handle the different scenarios
 * @param {Object} opts The options for guest login flow
 * @param {Function} param2.onFullnameMissing Callback to handle when the user does not have a full name
 * @param {Function} param2.onGuestSuccess Callback to handle when the guest login was successful
 * @param {Function} param2.onGuestNotFound Callback to handle when the guest user was not found
 * @param {Boolean} opts.checkVerified Whether to check if the guest user is verified
 *
 * @returns {Promise<void>}
 */
export const tryGuestLogin = async (
  email,
  stepId,
  { onFullnameMissing, onGuestSuccess, onGuestNotFound, onGuestNotVerified },
  { checkVerified }
) => {
  try {
    const { user, session } = await authentication.guestSignup({
      email,
      stepId,
    });
    if (!user.fullName) {
      onFullnameMissing();
      return;
    }
    if (checkVerified && !session.verified) {
      authentication.sendOTP(email);
      onGuestNotVerified();
    } else {
      onGuestSuccess();
    }
  } catch (error) {
    if (error.status === 404) {
      onGuestNotFound();
    } else {
      errorHandler.handleError(error);
    }
  }
};

/**
 *
 * @param {String} email The email address of the user
 * @param {String} stepId The Step ID that the user is trying to access
 * @param {Object} param2 The callbacks to handle the different scenarios
 * @param {Function} param2.onExternal Callback to handle when the user is an external user
 * @param {Function} param2.onFullnameMissing Callback to handle when the user does not have a full name
 * @param {Function} param2.onGuestSuccess Callback to handle when the guest login was successful
 * @param {Function} param2.onExistingUser Callback to handle when the user is an existing user
 * @returns {Promise<void>}
 */
export const emailSubmitted = async (
  email,
  stepId,
  {
    onExternal,
    onFullnameMissing,
    onGuestSuccess,
    onExistingUser,
    onGuestNotVerified,
  },
  guestFlowOpts = { checkVerified: false },
  redirect,
  page
) => {
  try {
    const identity = await authentication.fetchIdentity(email);
    analytics.trackV2({
      category: analytics.CATEGORY.LOGIN_REGISTERED_EMAIL,
      action: analytics.ACTION.SUBMITTED,
      metaData: {
        page,
      },
    });
    if (identity.isExternal) {
      if (identity.name === "filestage-google-oauth2") {
        onExternal();
        return;
      }
      await authentication.startExternalLogin(identity.name, { redirect });
      return;
    }

    onExistingUser();
  } catch (error) {
    if (error.status === 404) {
      await tryGuestLogin(
        email,
        stepId,
        {
          onFullnameMissing,
          onGuestSuccess,
          onGuestNotFound: onFullnameMissing,
          onGuestNotVerified,
        },
        guestFlowOpts
      );
    } else {
      errorHandler.handleError(error);
    }
  }
};

/**
 *
 * @param {String} email The email address of the user
 * @param {string} password The password of the user
 * @param {object} param2
 * @param {Function} param2.onSuccess Callback to handle when the login was successful
 * @param {Function} param2.onIncorrectPassword Callback to handle when the password is incorrect
 * @param {Function} param2.onError Callback to handle when an error occurred
 * @returns {Promise<void>}
 */
export const submitPassword = async (
  email,
  password,
  { onSuccess, onIncorrectPassword, onError },
  page
) => {
  healthMetrics.trackStart("supporting.authentication");
  healthMetrics.trackStart("supporting.authentication-pwd");

  try {
    await authentication.login(
      {
        email,
        password,
      },
      {},
      true
    );
    onSuccess();
    healthMetrics.trackSuccess("supporting.authentication");
    healthMetrics.trackSuccess("supporting.authentication-pwd");
    analytics.trackV2({
      category: analytics.CATEGORY.USER_CREDENTIALS_PAGE,
      action: analytics.ACTION.SUBMITTED,
      metaData: {
        page,
      },
    });
  } catch (error) {
    if (error.code === "access_denied" || error.status === 400) {
      onIncorrectPassword();
      healthMetrics.trackSuccess("supporting.authentication");
      healthMetrics.trackSuccess("supporting.authentication-pwd");
    } else {
      onError();
      healthMetrics.trackFailure("supporting.authentication", error);
      healthMetrics.trackFailure("supporting.authentication-pwd", error);
    }
  }
};

/**
 *
 * @param {String} email The email address of the user
 * @param {String} stepId The Step ID that the user is trying to access
 * @param {string} fullName The full name of the user
 * @param {object} param3
 * @param {Function} param3.onSuccess Callback to handle when the name was submitted successfully
 * @returns {Promise<void>}
 */
export const submitName = async (email, stepId, fullName, { onSuccess }) => {
  // we can be authenticated already with a user
  // that does not have a full name
  const user = authentication.fetchUser();
  if (user) {
    await userService.update({
      fullName,
    });
  } else {
    await authentication.guestSignup({
      email,
      stepId,
      fullName,
    });
  }
  analytics.trackV2({
    category: analytics.CATEGORY.NAME_UNREGISTERED_EMAIL,
    action: analytics.ACTION.SUBMITTED,
  });
  onSuccess();
};
