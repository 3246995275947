import PropTypes from "prop-types";
import { useState } from "react";

import { REVIEW_DECISION_STATES_TO_ACTIONS } from "@feedback/constants/reviewDecisionState";
import EmailContent from "@supporting/components/ReviewersAuthenticationFlow/EmailContent";
import authenticationService from "@supporting/services/authentication";

import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";
import isInIframe from "@shared/helpers/isInIframe";
import { useNavigation, useSessionStorage } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import nativeWarningHandlerService from "@shared/services/nativeWarningHandlerService";

export default function ReviewerEmailContent({
  email,
  context,
  action,
  onSubmit,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [, setPendingVerifiedApprovalContext] = useSessionStorage(
    "pendingVerifiedApprovalContext"
  );
  const [, setSelectedReviewsInSession] = useSessionStorage("selectedReviews");

  const { goTo, buildUrl } = useNavigation();
  const authRouteState = {
    redirect: buildUrl(ROUTE_STATES.WORKSPACE_AUTH_CALLBACK),
    state: {
      action,
      context,
    },
    shareSessionStepId: context.stepId,
  };
  const [authState, setAuthState] = useState({
    email: email.current,
    isEmailValid: false,
  });

  async function startExternalLogin(identity) {
    healthMetrics.trackStart("supporting.authentication");
    healthMetrics.trackStart("integrations.enterprise-sso");
    nativeWarningHandlerService.unregisterUnloadWarning();

    try {
      await authenticationService.startExternalLogin(
        identity.name,
        authRouteState
      );
    } catch (err) {
      healthMetrics.trackFailure("integrations.enterprise-sso", err);
      throw err;
    }
  }

  function guestLoginInNewWindow() {
    const iframeLoginWindow = window.open(
      APP_ROUTES.LOGIN.path,
      "MsgWindow",
      "width=850,height=650"
    );

    window.onSuccessfulAuthentication = function (response) {
      authenticationService.onSuccessfulAuthentication(response);
      iframeLoginWindow.close();
      onSubmit("CLOSE_DIALOG");
    };
  }

  function handleNotRegistered(error) {
    const notRegistered = error.status === 404;
    if (notRegistered) {
      if (
        context.digitalSignatureRequired &&
        action === REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED
      ) {
        setPendingVerifiedApprovalContext({
          pathBeforeRedirect: context.pathBeforeRedirect,
          queryParamsBeforeRedirect: context.queryParamsBeforeRedirect,
          digitalSignatureRequired: true,
        });
        const isBatchReview = Boolean(context.reviewIds?.length);

        if (isBatchReview) {
          setSelectedReviewsInSession(context.reviewIds);
        }

        onSubmit("CLOSE_DIALOG");
        return goTo(ROUTE_STATES.SIGNUP, {
          search: new URLSearchParams({
            email: authState.email,
            reason: "review-files",
          }).toString(),
        });
      }
      onSubmit("SHOW_NAME");
      return;
    }
    errorHandlerService.handleError(error);
  }

  async function onEmailSubmit(event) {
    event.preventDefault();
    email.current = authState.email;
    setIsLoading(true);
    try {
      const identity = await authenticationService.fetchIdentity(
        authState.email
      );
      if (isInIframe()) {
        return guestLoginInNewWindow();
      }
      if (identity.isExternal) {
        if (identity.name === "filestage-google-oauth2") {
          onSubmit("SHOW_GOOGLE");
          nativeWarningHandlerService.unregisterUnloadWarning();
          return;
        }
        await startExternalLogin(identity);
        return;
      }
      onSubmit("SHOW_PASSWORD");
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.REVIEWER_IDENTIFICATION_DIALOG_PASSWORD
      );
    } catch (error) {
      await handleNotRegistered(error);
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <form noValidate onSubmit={onEmailSubmit} id="reviewer-auth-dialog-email">
      <EmailContent
        defaultValue={email.current}
        authState={authState}
        setAuthState={setAuthState}
        title="REVIEWER.ENTER_USERDATA.DESCRIPTION-1"
        isLoading={isLoading}
      />
    </form>
  );
}
ReviewerEmailContent.propTypes = {
  action: PropTypes.string.isRequired,
  email: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
};
