import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import userService from "@supporting/services/userService";

import { Box, Image, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

import AITutorial from "@assets/img/images/aiTutorial.jpg";

function AIMarketplaceDialogTutorial() {
  const { t } = useTranslation();

  const dismissTutorialVideo = useCallback(async () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AI_MARKETPLACE.EXPLAINER_VIDEO_DISMISS_BUTTON
    );
    await userService.update({
      settings: { dismissedAIMarketplaceVideoAt: new Date() },
    });
  }, []);

  const watchTutorialVideo = useCallback(() => {
    window.open("https://youtu.be/kbzJkO86CDs", "_blank");
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AI_MARKETPLACE.EXPLAINER_VIDEO_WATCH_VIDEO_BUTTON
    );
  }, []);

  return (
    <Box
      bgcolor="grey.50"
      display="flex"
      mt={3}
      pl={{ xs: 1, md: 3 }}
      minHeight={164}
      alignItems="center"
      borderRadius="4px"
    >
      <Box width={178}>
        <Image width="100%" src={AITutorial} alt="" />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        minHeight={116}
        ml={{ xs: 1.5, md: 2.5 }}
        mr={{ xs: 1 }}
      >
        <Text
          variant="textMd"
          fontWeight="fontWeight.medium"
          textAlign="left"
          translate="BOTS.GETTING_STARTED.TITLE"
        />
        <Text
          variant="textXs"
          color="text.secondary"
          textAlign="left"
          translate="BOTS.GETTING_STARTED.DESCRIPTION"
        />
        <Box
          display="flex"
          mb={{ xs: 1.5, md: 2 }}
          mt={{ xs: 1.5, md: 2 }}
          gap={1}
        >
          <Button
            size="extraSmall"
            color="success"
            variant="contained"
            onClick={watchTutorialVideo}
            data-testid="watch-video"
          >
            {t("BOTS.GETTING_STARTED.BUTTON.WATCH_VIDEO")}
          </Button>
          <Button
            size="extraSmall"
            variant="outlined"
            color="default-light"
            onClick={dismissTutorialVideo}
            data-testid="dismiss-video"
          >
            {t("BOTS.GETTING_STARTED.BUTTON.DISMISS")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AIMarketplaceDialogTutorial;
