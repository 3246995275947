import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { PersonOutline } from "@mui/icons-material";
import { TextField } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

export default function NameContent({
  fullName,
  setFullName,
  title,
  autoFocus,
  invalidName,
}) {
  const { t } = useTranslation();
  const handleNameChange = (event) => {
    setFullName(event.target.value);
  };
  return (
    <>
      <Text variant="body2" translate={title} textAlign="center" />
      <Box
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
      >
        <Box mr={1} mb={2} data-testid="password-field-logo">
          <PersonOutline />
        </Box>
        <TextField
          id="review-name-input"
          onChange={handleNameChange}
          value={fullName}
          error={invalidName}
          helperText={
            invalidName ? t("ONBOARDING_WIZARD.NAME_HELPER_TEXT.EMPTY") : " "
          }
          fullWidth
          label={t("FORM.NAME.LABEL")}
          inputProps={{
            "data-testid": "review-name-input",
          }}
          autoFocus={autoFocus}
        />
      </Box>
    </>
  );
}
NameContent.propTypes = {
  fullName: PropTypes.string.isRequired,
  setFullName: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  invalidName: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool,
};
NameContent.defaultProps = {
  autoFocus: false,
};
