import { useEffect } from "react";
import { useRouteError, useNavigate } from "react-router-dom";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as logger } from "@shared/services/logger";

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  logger.error("react-router", "ErrorBoundary", { error });

  useEffect(() => {
    navigate(APP_ROUTES.ERROR.build(error));
  }, [navigate, error]);

  return null;
};

export default ErrorBoundary;
