import PropTypes from "prop-types";
import { useMemo } from "react";

import { useTheme } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";
import FileCardResizer from "@workflow/components/FileCardResizer/FileCardResizer";
import { FileCardResizerProvider } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import useProjectSections from "@workflow/hooks/useProjectSections";
import useProjectSteps from "@workflow/hooks/useProjectSteps";

import { useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";

import Swimlane from "../Swimlane/Swimlane";

function Project({ team, project, isTeamMember, $element }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const currentUser = useActiveUser();

  const [currentSteps, isLoadingSteps] = useProjectSteps(project.id);

  const visibleSteps = useMemo(
    () => currentSteps.filter((step) => step.permissions.viewOnDashboard),
    [currentSteps]
  );

  const { sections, hasMore, isLoadingFiles, fetchMoreFiles } =
    useProjectSections(project, $element);

  return (
    <FileCardResizerProvider>
      {!isMobile && (
        <FileCardResizer size={currentUser.settings?.fileColumnSize} />
      )}
      <Swimlane
        sections={sections}
        hasMore={hasMore}
        steps={visibleSteps}
        isTeamMember={isTeamMember}
        project={project}
        team={team}
        isLoadingFiles={isLoadingFiles}
        isLoadingSteps={isLoadingSteps}
        fetchMoreFiles={fetchMoreFiles}
      />
    </FileCardResizerProvider>
  );
}

Project.propTypes = {
  team: teamProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  isTeamMember: PropTypes.bool.isRequired,
  $element: PropTypes.any.isRequired,
};

export default Project;
