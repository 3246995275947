import { useTranslation } from "react-i18next";
import {
  FiFileText,
  FiBookOpen,
  FiMessageCircle,
  FiArrowRight,
} from "react-icons/fi";

import { Box, Card, CardContent, Button, SvgIcon } from "@mui/material";

import { Text } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

const FOOTER_CARDS = [
  {
    id: "guide",
    icon: FiFileText,
    title: "GUIDE_CARD.BROKEN_LINK.TITLE",
    description: "GUIDE_CARD.BROKEN_LINK.BODY",
    linkText: "GUIDE_CARD.BROKEN_LINK.LEARNING",
    href: "https://help.filestage.io/en/",
  },
  {
    id: "blog",
    icon: FiBookOpen,
    title: "BLOG_CARD.BROKEN_LINK.TITLE",
    description: "BLOG_CARD.BROKEN_LINK.BODY",
    linkText: "BLOG_CARD.BROKEN_LINK.LEARNING",
    href: "https://filestage.io/blog/",
  },
  {
    id: "chat",
    icon: FiMessageCircle,
    title: "CHAT_CARD.BROKEN_LINK.TITLE",
    description: "CHAT_CARD.BROKEN_LINK.BODY",
    linkText: "CHAT_CARD.BROKEN_LINK.LEARNING",
    href: "https://help.filestage.io/en/",
  },
];

const HelpResources = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Box
      display="flex"
      gap={4}
      alignSelf={{ xs: "inherit", sm: "stretch" }}
      flexDirection={{ xs: "column", md: "row" }}
    >
      {FOOTER_CARDS.map((helpCard) => (
        <Card
          key={helpCard.id}
          elevation={0}
          sx={{
            flex: { xs: 1, md: "1 0 0" },
            bgcolor: "grey.50",
          }}
        >
          <CardContent
            sx={{
              flex: "1 0 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: { xs: 2, sm: 6 },
              // eslint-disable-next-line id-length
              p: { xs: 2, sm: 3 },
            }}
          >
            <SvgIcon component={helpCard.icon} color="default-light" />
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="flex-start"
            >
              <Box display="flex" flexDirection="column" gap={1}>
                <Text
                  variant={isMobile ? "textSm" : "textXl"}
                  fontWeight="fontWeight.bold"
                  translate={helpCard.title}
                />
                <Text
                  variant={isMobile ? "textSm" : "textMd"}
                  color="text.secondary"
                  translate={helpCard.description}
                />
              </Box>
              <Button
                href={helpCard.href}
                color="secondary"
                size={isMobile ? "small" : "medium"}
                sx={{
                  pl: 0,
                }}
              >
                {t(helpCard.linkText)}
                <SvgIcon component={FiArrowRight} />
              </Button>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default HelpResources;
