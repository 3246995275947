import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, TextField } from "@mui/material";
import OtpDialog from "@supporting/components/OtpDialog/OtpDialog";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { Box, Dialog, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { useDialogState } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import FSTGTypography from "@shared/theme/typography";

const ChangeEmailDialog = ({ closeDialog, open }) => {
  const { t } = useTranslation();

  const initialState = { email: "", password: "" };

  const [inputs, setInputs] = useState(initialState);
  const [errors, setErrors] = useState(initialState);
  const [isTouched, setIsTouched] = useState(false);
  const {
    isOpen: isOtpDialogOpen,
    openDialog: openOtpDialog,
    closeDialog: closeOtpDialog,
  } = useDialogState();

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // TODO : Move this to a generic place in shared folder of react
  /* istanbul ignore next */
  const selectType = (type) => {
    if (type === "email") {
      return validateEmail;
    }
    /* istanbul ignore else */
    if (type === "password") {
      return () => true;
    }
  };

  const handleInputChange = (event, touch) => {
    const { value, name } = event.target;
    let isDirty = touch;

    if (isTouched) {
      isDirty = isTouched;
    }

    setInputs({ ...inputs, [name]: value });

    if (value.length === 0) {
      setErrors({ ...errors, [name]: "" });
      return;
    }

    if (!isDirty) {
      return;
    }
    const validateInput = selectType(name);

    if (validateInput(value)) {
      setErrors({ ...errors, [name]: "" });
    } else {
      setErrors({
        ...errors,
        [name]: t(`VALIDATIONS.${name.toUpperCase()}.FORMAT`),
      });
    }
  };

  const showOTPDialog = () => {
    openOtpDialog();
  };

  const onOtpSubmitted = () => {
    analytics.track(
      analytics.ACTION.UPDATED_ACCOUNT_USER_EMAIL,
      analytics.CATEGORY.USER
    );

    const user = authenticationService.fetchUser();
    const updatedUser = Object.assign(user, { email: inputs.email });
    eventService.emitEvent({
      eventName: EVENT_NAMES.USER.UPDATED,
      eventData: { user: updatedUser },
    });

    toastService.sendToast({
      title: "SETTINGS.PROFILE.UPDATE.SUCCESS.TITLE",
      body: "SETTINGS.PROFILE.UPDATE.SUCCESS.BODY",
      preset: toastService.PRESETS().SUCCESS,
    });
    closeDialog();
  };

  const onOtpError = (error) => {
    analytics.track(
      analytics.ACTION.UPDATED_ACCOUNT_USER_EMAIL_FAILED,
      analytics.CATEGORY.USER
    );
    errorHandlerService.handleError(error);
  };

  const submitDialog = async () => {
    try {
      await authenticationService.sendOTPForEmailChange(
        inputs.email,
        inputs.password
      );
      toastService.sendToast({
        title: "SETTINGS.PROFILE.UPDATE.EMAIL_SENT.TITLE",
        body: "SETTINGS.PROFILE.UPDATE.EMAIL_SENT.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
      showOTPDialog();
    } catch (error) {
      if (error.status === 403) {
        setErrors({
          ...errors,
          password: t("AUTHENTICATION.DIALOGS.EMAIL_CHANGE.INCORRECT_PASSWORD"),
        });
      } else {
        errorHandlerService.handleError(error);
      }
    }
  };

  const cancelDialog = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const checkIfDisabled = () => {
    if (inputs?.email.length && inputs?.password.length) {
      if (errors?.email === "" && errors?.password === "") {
        return false;
      }
    }
    return true;
  };

  return (
    <Dialog
      cancel={cancelDialog}
      open={open}
      maxWidth="md"
      actions={
        <>
          <Box mr={3}>
            <Button
              variant="text"
              color="primaryV2"
              component={Link}
              to={APP_ROUTES.PASSWORD_FORGOT.path}
              target="_blank"
            >
              {t("AUTHENTICATION.LOGIN.FORGOTPASSWORD")}
            </Button>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Button
              onClick={cancelDialog}
              variant="text"
              color="primaryV2"
              size="large"
              data-testid="change-email-dialog-cancel"
            >
              {t("CORE.CANCEL")}
            </Button>
            <Button
              onClick={submitDialog}
              color="primary"
              variant="contained"
              size="large"
              disabled={checkIfDisabled()}
              data-testid="change-email-dialog-confirm"
            >
              {t("CORE.CONFIRM")}
            </Button>
          </Box>
        </>
      }
      title={t("AUTHENTICATION.DIALOGS.EMAIL_CHANGE.TITLE")}
      hideBackdrop={false}
    >
      <form>
        <Box p={1} mb={4}>
          <Text
            color="text.secondary"
            variant="body2"
            letterSpacing={FSTGTypography.letterSpacing_0_1}
          >
            {t("AUTHENTICATION.DIALOGS.EMAIL_CHANGE.DESCRIPTION")}
          </Text>
        </Box>
        <Box py={1} px={6} display="flex" flexDirection="column" gap={1}>
          <TextField
            autoComplete="username"
            error={Boolean(errors?.email)}
            type="email"
            name="email"
            required
            onChange={handleInputChange}
            onBlur={(event) => {
              setIsTouched(true);
              handleInputChange(event, true);
            }}
            label={t("AUTHENTICATION.DIALOGS.EMAIL_CHANGE.INPUT_LABEL_EMAIL")}
            inputProps={{
              "data-testid": "new-email-input",
            }}
            helperText={errors?.email}
            variant="outlined"
          />
          <TextField
            autoComplete="current-password"
            error={Boolean(errors?.password)}
            type="password"
            name="password"
            required
            onChange={handleInputChange}
            onBlur={(event) => {
              setIsTouched(true);
              handleInputChange(event, true);
            }}
            label={t(
              "AUTHENTICATION.DIALOGS.EMAIL_CHANGE.INPUT_LABEL_PASSWORD"
            )}
            inputProps={{
              "data-testid": "current-password-input",
            }}
            helperText={errors?.password}
            variant="outlined"
          />
        </Box>
        <OtpDialog
          isOpen={isOtpDialogOpen}
          answer={onOtpSubmitted}
          cancel={closeOtpDialog}
          email={inputs.email}
          onError={onOtpError}
        />
      </form>
    </Dialog>
  );
};

ChangeEmailDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
};

ChangeEmailDialog.defaultProps = {
  open: false,
};

export default ChangeEmailDialog;
