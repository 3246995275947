import PropTypes from "prop-types";
import { ReactElement } from "react";

/**
 * UIKit Picture component
 * @description Renders a picture element with a default image
 * @param {{
 * sources: Array<{
 * srcSet: string,
 * media: string,
 * }>,
 * defaultImgSrc: string,
 * alt: string,
 * }} props Component props
 * @param {Array} props.sources Array of objects with srcSet and media properties
 * @param {string} props.defaultImgSrc Default image src
 * @param {string} props.alt Alt text
 * @returns {ReactElement} React component
 */
const Picture = ({ sources, defaultImgSrc, alt, ...restProps }) => {
  return (
    <picture>
      {sources.map((source, index) => (
        <source
          key={index}
          srcSet={source.srcSet}
          media={source.media}
          height={source.height}
        />
      ))}
      {/* eslint-disable-next-line react/forbid-elements */}
      <img
        src={defaultImgSrc}
        alt={alt}
        style={{ display: "block" }}
        {...restProps}
      />
    </picture>
  );
};

Picture.propTypes = {
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      srcSet: PropTypes.string.isRequired,
      media: PropTypes.string.isRequired,
    })
  ),
  defaultImgSrc: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

Picture.defaultProps = {
  sources: [],
};
export default Picture;
