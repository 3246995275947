import { PropTypes } from "prop-types";
import { useState, useEffect, useCallback } from "react";

import { makeStyles } from "@mui/styles";
import userService from "@supporting/services/userService";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import cx from "classnames";

import { Box } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    backgroundColor: "transparent",
    width: 3,
    height: "100%",
    top: 0,
    zIndex: 150,
    "&:hover": {
      cursor: "ew-resize",
      backgroundColor: theme.color.green[400],
      transition: "background-color 0.5s ease-in-out",
    },
    left: ({ position }) => position,
  },
  isDragActive: {
    backgroundColor: theme.color.green[400],
  },
}));

export default function FileCardResizer({ size }) {
  const { handleResize, minWidth, maxWidth } = useFileCardResized();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(size || minWidth);
  const [dragOffset, setDragOffset] = useState(0);

  const classes = useStyles({ position });

  const handleUpdateFileColumnSizeSetting = useCallback(() => {
    analytics.track(analytics.ACTION.RESIZED, analytics.CATEGORY.FILE_COLUMN, {
      page: "project-dashboard",
    });
    userService.update({
      settings: { fileColumnSize: position },
    });
  }, [position]);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragOffset(event.clientX - position);
  };

  const handleMouseMove = useCallback(
    (event) => {
      if (!isDragging) {
        return;
      }

      const newPosition = event.clientX - dragOffset;
      const resultPosition = Math.min(
        Math.max(newPosition, minWidth),
        maxWidth
      );

      window.requestAnimationFrame(() => {
        handleResize(resultPosition);
        setPosition(resultPosition);
      });
    },
    [isDragging, dragOffset, minWidth, maxWidth, handleResize]
  );

  useEffect(() => {
    const onMouseUp = () => {
      if (isDragging) {
        setIsDragging(false);
        handleUpdateFileColumnSizeSetting();
      }
    };

    window.addEventListener("mouseup", onMouseUp);
    return () => {
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [handleUpdateFileColumnSizeSetting, isDragging]);

  useEffect(() => {
    const onMouseMove = (event) => handleMouseMove(event);

    isDragging
      ? window.addEventListener("mousemove", onMouseMove)
      : window.removeEventListener("mousemove", onMouseMove);

    return () => {
      window.removeEventListener("mousemove", onMouseMove);
    };
  }, [isDragging, handleMouseMove]);

  useEffect(() => {
    handleResize(position);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      data-testid="file-card-resizer"
      data-position={position}
      className={cx(classes.root, {
        [classes.isDragActive]: isDragging,
      })}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
    />
  );
}

FileCardResizer.propTypes = {
  size: PropTypes.number,
};

FileCardResizer.defaultProps = {
  size: null,
};
