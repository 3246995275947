import Pusher from "pusher-js";

import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as logger } from "@shared/services/logger";

import WEBSOCKET_EVENT_NAMES from "./events";

function authorizer(channel) {
  return {
    async authorize(socketId, callback) {
      try {
        callback(
          null,
          await backend.post(`/auth/authorize/pusher`, {
            socket_id: socketId,
            channel_name: channel.name,
          })
        );
      } catch (error) {
        callback(error);
      }
    },
  };
}

export let instance;

export function initialize() {
  logger.info("websocket", "initializing");
  const pusher = new Pusher(window.fs.config.pusher.key, {
    cluster: "eu",
    authorizer,
  });

  pusher.connection.bind("state_change", (states) => {
    logger.info("websocket", "websocket connection state is", states);
  });

  pusher.connection.bind("error", (error) =>
    logger.warn("websocket", "pusher error", { error })
  );

  pusher.bind_global((name, data) =>
    logger.info("websocket", "received message", { name, data })
  );

  backend.interceptors.push({
    //eslint-disable-next-line no-unused-vars
    request: (method, route, params, headers) => {
      if (pusher.connection.socket_id) {
        headers["Sender-Websocket-Id"] = pusher.connection.socket_id;
      }
    },
  });

  eventService.addListener(
    EVENT_NAMES.USER.AUTHENTICATED,
    function connect({
      eventData: {
        session: { userId },
      },
    }) {
      logger.info("websocket", "connecting to user channel", { userId });
      pusher
        .allChannels()
        .forEach((channel) => pusher.unsubscribe(channel.name));
      pusher.subscribe(`private-${userId}`);
    }
  );

  eventService.addListener(
    EVENT_NAMES.USER.PLUGIN_AUTHENTICATION,
    function connectToPluginAuthenticationWebsocket({
      eventData: { registeredPluginSessionId },
    }) {
      logger.info(
        "websocket",
        "connecting to plugin guest user authentication channel",
        { registeredPluginSessionId }
      );

      pusher.subscribe(`private-plugin-${registeredPluginSessionId}`);
    }
  );

  const listenerCallbacks = [];

  instance = {
    EVENT_NAMES: WEBSOCKET_EVENT_NAMES,
    isConnected() {
      logger.info("websocket", "current connection state", {
        state: pusher.connection.state,
      });
      return pusher.connection.state === "connected";
    },
    addListener(name, listener) {
      function callback(...args) {
        try {
          listener(...args);
        } catch (error) {
          logger.warn("websocket", "error in listener", {
            name,
            listener: listener.toString(),
            error,
          });
        }
      }

      listenerCallbacks.push({ callback, listener });
      pusher.bind(name, callback);
    },
    removeListeners(name) {
      pusher.unbind(name);
    },
    removeListener(name, listener) {
      const callbackIndex = listenerCallbacks.findIndex(
        (listenerCallback) => listenerCallback.listener === listener
      );
      if (callbackIndex === -1) {
        logger.warn("websocket", "error removing listener", {
          name,
        });
        return;
      }
      pusher.unbind(name, listenerCallbacks[callbackIndex].callback);
      listenerCallbacks.splice(callbackIndex, 1);
    },
  };
}
