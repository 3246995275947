import { useMemo } from "react";

const useSearchInput = ({
  goToSelectedSearchResult,
  navigateUp,
  navigateDown,
}) => {
  return useMemo(
    () => ({
      onKeyDown: (event) => {
        switch (event.keyCode) {
          case 13:
            goToSelectedSearchResult(event);
            break;
          case 38:
            navigateUp();
            break;
          case 40:
            navigateDown();
            break;
          default:
            break;
        }
      },
    }),
    [navigateUp, navigateDown, goToSelectedSearchResult]
  );
};

export default useSearchInput;
