import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { Box, Text } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  inReview: {
    backgroundColor: theme.palette.inReview.light,
    color: theme.palette.inReview.main,
  },
  needsChanges: {
    backgroundColor: theme.palette.needsChanges.light,
    color: theme.palette.needsChanges.main,
  },
  approved: {
    backgroundColor: theme.palette.approved.light,
    color: theme.palette.approved.main,
  },
  statusText: {
    padding: "4px 6px",
    whiteSpace: "nowrap",
  },
}));

function ReviewState({ reviewState, className }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      data-testid="review-state"
      className={classNames(className, {
        [classes.approved]: reviewState === REVIEW_STATUS.APPROVED,
        [classes.inReview]: reviewState === REVIEW_STATUS.IN_REVIEW,
        [classes.needsChanges]: reviewState === REVIEW_STATUS.NEEDS_CHANGES,
      })}
      borderRadius="6px"
      height={22}
      width="fit-content"
    >
      <Text
        className={classes.statusText}
        variant="subtitle2"
        fontWeight={FSTGTypography.fontWeightMedium}
        lineHeight={FSTGTypography.lineHeight_1_2}
      >
        {t(`REVIEWER.SETTINGS.${reviewState}`)}
      </Text>
    </Box>
  );
}

ReviewState.propTypes = {
  reviewState: PropTypes.string.isRequired,
  className: PropTypes.string,
};
ReviewState.defaultProps = {
  className: "",
};
export default ReviewState;
