import { darken } from "@mui/material/styles";

import typography from "./typography";

export const SPACING = 8;

export const Button = {
  styleOverrides: {
    root: {
      textTransform: "inherit",
      letterSpacing: "0.1px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
      fontWeight: typography.fontWeight.semiBold,
      flexShrink: 0,
    },
    outlined: ({ theme, ownerState }) => ({
      backgroundColor: "white",
      ...(ownerState.color === "primaryV2" && {
        borderColor: theme.palette.primaryV2.light,
      }),
      "&:hover": {
        backgroundColor: "white",
        ...(ownerState.color === "primaryV2" && {
          borderColor: theme.palette.primaryV2.main,
          backgroundColor: theme.palette.grey[50],
        }),
      },
    }),
    endIcon: ({ ownerState }) => ({
      ...(ownerState.size === "extraSmall" && {
        marginLeft: 0,
      }),
    }),
    iconSizeSmall: {
      fontSize: typography.fontSize_1_2,
    },
    sizeSmall: {
      padding: `0.5rem 1rem 0.5rem 0.75rem`,
    },
  },
  variants: [
    {
      props: { size: "extraSmall" },
      style: {
        minWidth: 46,
        padding: `${SPACING / 2}px ${SPACING * 1.5}px`,
        fontSize: typography.fontSize_1_2,
        lineHeight: typography.lineHeight_1_3,
        "& .MuiButton-startIcon": {
          marginRight: 4,
        },
      },
    },
    {
      // remove 2px padding from the default button to adjust added height by border
      props: { size: "extraSmall", variant: "outlined" },
      style: {
        padding: `calc(${SPACING / 2}px - 1px) ${SPACING * 1.5}px`,
      },
    },
    {
      props: { variant: "highlight" },
      style: ({ ownerState, theme }) => ({
        color: theme.palette[ownerState.color].main,
        backgroundColor: theme.palette[ownerState.color].light,
        fontWeight: typography.fontWeight.semiBold,
      }),
    },
    {
      props: { shape: "rounded" },
      style: {
        borderRadius: 24,
      },
    },
    {
      props: { shape: "round" },
      style: {
        borderRadius: "50%",
        padding: 4,
        aspectRatio: "1/1",
        minWidth: 35,
      },
    },
    {
      props: { shape: "round", size: "small" },
      style: {
        minWidth: 32,
      },
    },
    {
      props: { variant: "text" },
      style: ({ ownerState, theme }) => ({
        "&:hover": {
          backgroundColor: "unset",
          ...(ownerState.color !== "inherit" && {
            color: darken(theme.palette[ownerState.color].main, 0.5),
          }),
        },
      }),
    },
  ],
};

export const Typography = {
  defaultProps: {
    variantMapping: {
      text5xl: "p",
      text4xl: "p",
      text3xl: "p",
      text2xl: "p",
      textXl: "p",
      textLg: "p",
      textMd: "p",
      textSm: "p",
      textXs: "p",
    },
    variant: "textMd",
  },
};
