/* eslint-disable max-lines */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable import-helpers/order-imports */

import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter } from "react-router-dom";

import "./shared/helpers/initializeUppy";

import initializeServices from "./shared/helpers/initializeServices";
import "./shared/helpers/initializeDialogs.js";
import { routes } from "./routes";

import App from "./App";

// Support for legacy hashbang urls
if (window.location.hash.indexOf("#!") > -1) {
  window.history.replaceState(
    {},
    document.title,
    window.location.href.replace("/#!", "")
  );
}

(async () => {
  await initializeServices();
  const router = createBrowserRouter(routes, {
    basename: new URL(window.fs.config.appBase).pathname,
  });
  const root = createRoot(document.getElementById("reactRoot"));
  root.render(React.createElement(App, { router }));
})();
