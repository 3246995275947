import PropTypes from "prop-types";
import { useState } from "react";

import { makeStyles, useTheme } from "@mui/styles";
import PasswordField from "@supporting/components/PasswordField/PasswordField";

import { Tooltip } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

import PasswordTooltipContent from "./PasswordTooltipContent/PasswordTooltipContent";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.color.black,
    boxShadow: theme.shadows[1],
  },
  tooltipArrow: {
    "&::before": {
      border: `solid 1px ${theme.color.gray[200]}`,
    },
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.color.gray[300],
  },
}));

const PasswordFieldWithTooltip = ({
  id,
  labelTranslate,
  name,
  value,
  onChange,
  error,
  isMinimum,
  isOneUpperCase,
  isOneLowerCase,
  isOneNumberOrSpecial,
  testId,
  ...restProps
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const hideError =
    isMinimum && isOneLowerCase && isOneUpperCase && isOneNumberOrSpecial;

  return (
    <Tooltip
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      enterTouchDelay={0}
      data-testid="password-filed-with-tooltip"
      placement={isMobile ? "top" : "bottom-start"}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -24],
              },
            },
          ],
        },
      }}
      title={
        <PasswordTooltipContent
          error={error}
          isMinimum={isMinimum}
          isOneLowerCase={isOneLowerCase}
          isOneNumberOrSpecial={isOneNumberOrSpecial}
          isOneUpperCase={isOneUpperCase}
        />
      }
    >
      <PasswordField
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onClick={handleOpen}
        error={!hideError && error}
        errorTranslate="PASSWORD_FIELD_TOOLTIP.ERROR_TEXT"
        labelTranslate={labelTranslate}
        testId={testId}
        {...restProps}
      />
    </Tooltip>
  );
};

export default PasswordFieldWithTooltip;
PasswordFieldWithTooltip.defaultProps = {
  testId: "password-with-tooltip",
};
PasswordFieldWithTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  labelTranslate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isMinimum: PropTypes.bool.isRequired,
  isOneUpperCase: PropTypes.bool.isRequired,
  isOneLowerCase: PropTypes.bool.isRequired,
  isOneNumberOrSpecial: PropTypes.bool.isRequired,
  testId: PropTypes.string,
};
