import PropTypes from "prop-types";
import { useCallback, useState, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

import {
  MoreVertOutlined,
  CancelOutlined,
  ArchiveOutlined,
  UnarchiveOutlined,
  EditOutlined,
  FlashOnRounded,
} from "@mui/icons-material";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import LimitReachedDialog from "@supporting/components/LimitReachedDialog/LimitReachedDialog";
import toastService from "@supporting/services/toast";
import RenameProjectDialog from "@workflow/components/RenameProjectDialog/RenameProjectDialog";
import { AUTOMATION_KEY_VALUES } from "@workflow/constants/automation";
import useProjectSteps from "@workflow/hooks/useProjectSteps";
import projectService from "@workflow/services/projectService";
import projectSwitcherService from "@workflow/services/projectSwitcherService";

import { ConfirmationDialog, InlineBadge } from "@shared/UIKit";

import { useNavigation, useDialogState } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

function ProjectMenu({ project, team, openAutomationsDialog, ...restProps }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const showMenu = team.isTeamMember && project.permissions.delete;
  const { goTo } = useNavigation();

  const {
    isOpen: isReachedLimitDialogOpen,
    openDialog: openReachedLimitDialog,
    closeDialog: closeReachedLimitDialog,
  } = useDialogState();

  const {
    isOpen: isRenameProjectDialogOpen,
    openDialog: openRenameProjectDialog,
    closeDialog: closeRenameProjectDialog,
  } = useDialogState();

  const {
    isOpen: isRemoveProjectDialogOpen,
    openDialog: openRemoveProjectDialog,
    closeDialog: closeRemoveProjectDialog,
  } = useDialogState();

  const [steps] = useProjectSteps(project.id);

  const automationsCount = useMemo(() => {
    return project.automations.filter(
      (automation) =>
        automation.enabled &&
        (automation.conditions.step === AUTOMATION_KEY_VALUES.ANY ||
          steps.some((step) => step.id === automation.conditions.step))
    ).length;
  }, [project.automations, steps]);

  const handleFavoriteProject = async () => {
    try {
      await projectService.favoriteProject(project.id, !project.isFavorite);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const openMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const archiveProject = useCallback(() => {
    projectService.archiveProject(project.id);
    closeMenu();
  }, [closeMenu, project.id]);

  const unarchiveProject = useCallback(async () => {
    const resp = await projectService.unarchiveProject(project);
    if (!resp) {
      openReachedLimitDialog();
    }
    closeMenu();
  }, [closeMenu, openReachedLimitDialog, project]);

  const renameProjectHandler = useCallback(
    async (projectName) => {
      try {
        await projectService.updateProjectName(project.id, projectName);
        analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.PROJECT);
        closeRenameProjectDialog();
      } catch (error) {
        errorHandlerService.handleError(error);
      } finally {
        closeMenu();
      }
    },
    [closeMenu, project.id, closeRenameProjectDialog]
  );

  const handleProjectRemoval = async () => {
    try {
      await projectService.removeProject(project);
      toastService.sendToast({
        title: "PROJECT.PROJECT.DELETE_SUCCESS.TITLE",
        body: "PROJECT.PROJECT.DELETE_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });

      analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.PROJECT);

      const selectedProject =
        await projectSwitcherService.determineSelectedProject(project.teamId);
      if (selectedProject) {
        goTo("PROJECT_ID", { params: { projectId: selectedProject.id } });
      } else {
        goTo("DASHBOARD");
      }
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const removeProject = useCallback(() => {
    openRemoveProjectDialog();
    closeMenu();
  }, [closeMenu, openRemoveProjectDialog]);

  return (
    showMenu && (
      <Box
        marginLeft={{ xs: 0.5, lg: -1.875 }}
        marginRight={-1.875}
        data-testid="project-menu"
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={openMenu}
          data-testid="project-menu-button"
          size="large"
        >
          <MoreVertOutlined />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          {...restProps}
        >
          <MenuItem
            sx={{ display: { md: "none" } }}
            data-testid="project-favorite-button"
            onClick={handleFavoriteProject}
          >
            <ListItemIcon>
              {project.isFavorite ? (
                <StarIcon fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                project.isFavorite
                  ? t("FAVORITE_PROJECTS.REMOVE.TOOLTIP")
                  : t(
                      "FAVORITE_PROJECTS.REMOVE.TOOLTIPFAVORITE_PROJECTS.ADD.TOOLTIP"
                    )
              }
            />
          </MenuItem>
          <MenuItem
            sx={{ display: { md: "none" } }}
            data-testid="project-rename-button"
            onClick={openRenameProjectDialog}
          >
            <ListItemIcon>
              <EditOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("PROJECT.RENAME")}</ListItemText>
          </MenuItem>
          {!project.isArchived ? (
            <MenuItem
              data-testid="project-archive-button"
              onClick={archiveProject}
            >
              <ListItemIcon>
                <ArchiveOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("PROJECT.ARCHIVE")}</ListItemText>
            </MenuItem>
          ) : (
            <MenuItem
              data-testid="project-unarchive-button"
              onClick={unarchiveProject}
            >
              <ListItemIcon>
                <UnarchiveOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t("PROJECT.UNARCHIVE")}</ListItemText>
            </MenuItem>
          )}
          <Divider />
          <MenuItem
            data-testid="project-automations-button"
            sx={{ display: { md: "none" } }}
            onClick={openAutomationsDialog}
          >
            <ListItemIcon>
              <FlashOnRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ mr: 1 }}>
              {t("AUTOMATIONS.BUTTON.TITLE")}
            </ListItemText>
            <InlineBadge
              data-testid="project-automations-button-count"
              variant="contained"
              color="primaryV2"
              size="xs"
            >
              {automationsCount}
            </InlineBadge>
          </MenuItem>

          <MenuItem
            data-testid="project-delete-button"
            onClick={removeProject}
            data-accent-color="error"
          >
            <ListItemIcon>
              <CancelOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t("PROJECT.DELETE_PROJECT")}</ListItemText>
          </MenuItem>
        </Menu>
        {isReachedLimitDialogOpen && (
          <LimitReachedDialog
            selectedLimit="project"
            whileUnarchiving
            cancel={closeReachedLimitDialog}
          />
        )}
        {isRemoveProjectDialogOpen && (
          <ConfirmationDialog
            isOpen={isRemoveProjectDialogOpen}
            data-testid="remove-project-dialog"
            title={
              <Trans>
                {t("PROJECT.DIALOGS.DELETEPROJECT.HEADLINE", {
                  projectName: project.name,
                })}
              </Trans>
            }
            description=""
            checkMessage={t("PROJECT.DIALOGS.DELETEPROJECT.DESCRIPTION-2")}
            checkHint={t("PROJECT.DIALOGS.DELETEPROJECT.HINT")}
            cancelLabel={t("CORE.CANCEL")}
            confirmLabel={t("PROJECT.DELETE_PROJECT")}
            closeDialog={closeRemoveProjectDialog}
            answer={handleProjectRemoval}
          />
        )}
        {isRenameProjectDialogOpen && (
          <RenameProjectDialog
            projectName={project.name}
            cancel={closeRenameProjectDialog}
            answer={renameProjectHandler}
          />
        )}
      </Box>
    )
  );
}

ProjectMenu.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  team: teamProp.isRequired,
  openAutomationsDialog: PropTypes.func.isRequired,
};

export default ProjectMenu;
