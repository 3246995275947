import { useState } from "react";

import { instance as logger } from "@shared/services/logger";

const TAG = "session-storage";

export default function useSessionStorage(key, initialValue = null) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      logger.error(TAG, "unable to get value from session storage", { error });
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      setStoredValue(value);
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      logger.error(TAG, "unable to set value to session storage", { error });
    }
  };

  return [storedValue, setValue];
}
