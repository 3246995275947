import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextField } from "@mui/material";

import { Box } from "@shared/UIKit";

const FolderForm = ({ formId, control, errors, handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <Box
      component="form"
      id={formId}
      data-testid="folder-form"
      onSubmit={handleSubmit}
    >
      <Controller
        control={control}
        name="folderName"
        render={({ field }) => (
          <TextField
            label={t("PROJECT.FOLDER.ADD.LABEL")}
            {...field}
            autoFocus
            fullWidth
            error={Boolean(errors.folderName)}
            helperText={t(errors.folderName?.message)}
            inputProps={{
              "data-testid": "add-project-folder-dialog-input",
            }}
          />
        )}
      />
    </Box>
  );
};

FolderForm.propTypes = {
  formId: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.shape({
    folderName: PropTypes.shape({
      message: PropTypes.string,
    }),
  }),
  handleSubmit: PropTypes.func.isRequired,
};

FolderForm.defaultProps = {
  errors: {},
};

export default FolderForm;
