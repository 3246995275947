import { DRAWING_CONFIG } from "@feedback/constants/drawingConfig";
import userService from "@supporting/services/userService";
import concat from "lodash/concat";
import initial from "lodash/initial";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

let handledIssueId = null;
const drawingState = {};
const drawings = {};
const canvasContext = {
  lineWidth: DRAWING_CONFIG.CANVAS.LINE_WIDTH,
  lineJoin: DRAWING_CONFIG.CANVAS.LINE_JOIN,
  lineCap: DRAWING_CONFIG.CANVAS.LINE_CAP,
  color: DRAWING_CONFIG.CANVAS.COLORS.GREEN,
  tool: DRAWING_CONFIG.TOOLS.FREEHAND,
};

const init = () => {
  const setUserDefaultDrawingPreferences = ({ eventData: { user } }) => {
    if (user && user.settings.drawingPreferences) {
      if (user.settings.drawingPreferences.selectedColor) {
        canvasContext.color = user.settings.drawingPreferences.selectedColor;
      }

      if (user.settings.drawingPreferences.selectedTool) {
        canvasContext.tool = user.settings.drawingPreferences.selectedTool;
      }
    }
  };
  eventService.addListener(
    EVENT_NAMES.USER.AUTHENTICATED,
    setUserDefaultDrawingPreferences
  );
};

const getTool = function () {
  return canvasContext.tool;
};

const setTool = function (newTool) {
  canvasContext.tool = newTool;
  if (newTool) {
    userService.update({
      settings: { drawingPreferences: { selectedTool: newTool } },
    });
  }
};

const getColor = function () {
  return canvasContext.color;
};

const setColor = function (newColor = DRAWING_CONFIG.CANVAS.COLORS.GREEN) {
  canvasContext.color = newColor;

  userService.update({
    settings: { drawingPreferences: { selectedColor: newColor } },
  });

  eventService.emitEvent({
    eventName: EVENT_NAMES.ANNOTATION.COLOR.CHANGED,
    eventData: {},
  });
};

const getLineWidth = function () {
  return canvasContext.lineWidth;
};

const setLineWidth = function (value) {
  canvasContext.lineWidth = value;
};

const scaleLineWidth = function (zoomLevel) {
  const minLineWidth = DRAWING_CONFIG.CANVAS.MIN_LINE_WIDTH;
  const defaultLineWidth = DRAWING_CONFIG.CANVAS.LINE_WIDTH;
  let newLineWidth = defaultLineWidth / zoomLevel;

  if (newLineWidth < minLineWidth) {
    newLineWidth = minLineWidth;
  }

  setLineWidth(newLineWidth);
};

const isCommentHandled = function (issueId) {
  return issueId === handledIssueId;
};

const getDrawingState = function (reviewId) {
  return drawingState[reviewId] || DRAWING_CONFIG.STATES.HIDDEN;
};

const setDrawingState = function (state, reviewId) {
  drawingState[reviewId] = state;
};

const getHandledIssueId = function () {
  return handledIssueId;
};
const setHandledIssueId = function (issueId) {
  handledIssueId = issueId;
};

const updateCreatedDrawings = function (newDrawAnnotations, reviewId) {
  drawings[reviewId] = {
    ...drawings[reviewId],
    created: concat(
      drawings[reviewId] && drawings[reviewId].created
        ? drawings[reviewId].created
        : [],
      newDrawAnnotations
    ),
  };
};

const setDrawings = function (newDrawAnnotations = [], reviewId) {
  drawings[reviewId] = {
    ...drawings[reviewId],
    existed: newDrawAnnotations,
  };
};

const dropUnDoneDrawings = function (reviewId) {
  drawings[reviewId] = { ...drawings[reviewId], unDone: [] };
};

const dropCreatedDrawings = function (reviewId) {
  drawings[reviewId] = { ...drawings[reviewId], created: [] };
};

const dropDrawings = function (reviewId) {
  drawings[reviewId] = { ...drawings[reviewId], existed: [] };
};

const initializeNewDrawingSession = function (issueId, drawingObj, reviewId) {
  setHandledIssueId(issueId);
  setDrawings(drawingObj, reviewId);
};

const getAllDrawings = function (reviewId) {
  return concat(
    drawings[reviewId]?.existed ? drawings[reviewId].existed : [],
    drawings[reviewId]?.created ? drawings[reviewId].created : []
  );
};

const getCreatedDrawings = function (reviewId) {
  return drawings[reviewId] && drawings[reviewId].created
    ? drawings[reviewId].created
    : [];
};

const getUnDoneDrawings = function (reviewId) {
  return drawings[reviewId] && drawings[reviewId].unDone
    ? drawings[reviewId].unDone
    : [];
};

const redo = function (reviewId) {
  if (!isEmpty(drawings[reviewId] && drawings[reviewId].unDone)) {
    drawings[reviewId] = {
      ...drawings[reviewId],
      created: concat(
        drawings[reviewId].created,
        last(drawings[reviewId].unDone)
      ),
    };

    drawings[reviewId] = {
      ...drawings[reviewId],
      unDone: initial(drawings[reviewId].unDone),
    };
  }

  eventService.emitEvent({
    eventName: EVENT_NAMES.ISSUE.DRAWINGS.UNDONE_REDONE,
    eventData: {},
  });

  return getUnDoneDrawings();
};

const undo = function (reviewId) {
  if (isEmpty(drawings[reviewId] && drawings[reviewId].created)) {
    return drawings[reviewId] && drawings[reviewId].existed
      ? drawings[reviewId].existed
      : [];
  }

  drawings[reviewId] = {
    ...drawings[reviewId],
    unDone: concat(
      drawings[reviewId].unDone ? drawings[reviewId].unDone : [],
      last(drawings[reviewId].created)
    ),
  };

  drawings[reviewId] = {
    ...drawings[reviewId],
    created: initial(drawings[reviewId].created),
  };

  eventService.emitEvent({
    eventName: EVENT_NAMES.ISSUE.DRAWINGS.UNDONE_REDONE,
    eventData: {},
  });

  return getCreatedDrawings();
};

const onDrawingsCreated = function (newDrawAnnotations, reviewId) {
  updateCreatedDrawings(newDrawAnnotations, reviewId);
  dropUnDoneDrawings(reviewId);

  return getAllDrawings(reviewId);
};

const removeDrawingsForReview = function (reviewId) {
  delete drawings[reviewId];
};

const terminateDrawing = function (reviewId) {
  setHandledIssueId(null);
  removeDrawingsForReview(reviewId);
};

const closeDrawingMode = function (reviewId) {
  setDrawingState(DRAWING_CONFIG.STATES.HIDDEN, reviewId);
  terminateDrawing(reviewId);

  eventService.emitEvent({
    eventName: EVENT_NAMES.ISSUE.DRAW_ANNOTATION_STATE.CHANGED,
    eventData: {},
  });
};

const clearCanvas = function (canvas) {
  const canvasContext = canvas.getContext("2d");
  canvasContext.clearRect(0, 0, canvas.width, canvas.height);
};

const convertPointToPixel = function (point, canvas) {
  return {
    x: point.x * canvas.width,
    y: point.y * canvas.height,
  };
};

const convertPointToPercentage = function (point, canvas) {
  const x = point.x / canvas.width;
  const y = point.y / canvas.height;
  return { x, y };
};

const convertStartEndPointsToPixel = function (annotation, canvas) {
  const start = convertPointToPixel(annotation.points[0], canvas);
  const end = convertPointToPixel(annotation.points[1], canvas);

  return {
    start,
    end,
  };
};

const extractRGBValues = function (hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result.slice(1, 4).map((color) => parseInt(color, 16));
};

const hexToRgb = function (hex, opacity) {
  const [red, green, blue] = extractRGBValues(hex);

  if (opacity) {
    return `rgba(${red},${green},${blue},${opacity})`;
  }

  return `rgb(${red},${green},${blue})`;
};

const updateIssueDrawAnnotationState = function (issue) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.ISSUE.DRAW_ANNOTATION_STATE.CHANGED,
    eventData: {
      issue,
    },
  });
};

const hideAllDrawings = function ({ issue, review }) {
  setDrawingState(DRAWING_CONFIG.STATES.HIDDEN, review.id);
  terminateDrawing(review.id);
  updateIssueDrawAnnotationState(issue);
};

const showIssueDrawings = function ({ issue, review }) {
  if (!issue.annotation || issue.annotation.length === 0) {
    return;
  }

  setDrawingState(DRAWING_CONFIG.STATES.SHOWN, review.id);

  initializeNewDrawingSession(issue.id, issue.annotation, review.id);

  eventService.emitEvent({
    eventName: EVENT_NAMES.ISSUE.DRAW_ANNOTATION_STATE.CHANGED,
    eventData: { issue },
  });

  eventService.emitEvent({
    eventName: EVENT_NAMES.ISSUE.STATES.FOCUS_ON_MARKER,
    eventData: {
      marker: issue.marker,
      reviewId: review.id,
    },
  });
};

export default {
  init,
  getTool,
  setTool,
  getColor,
  setColor,
  getLineWidth,
  setLineWidth,
  scaleLineWidth,
  isCommentHandled,
  getHandledIssueId,
  setHandledIssueId,
  getDrawingState,
  setDrawingState,
  setDrawings,
  dropCreatedDrawings,
  dropUnDoneDrawings,
  initializeNewDrawingSession,
  getAllDrawings,
  getCreatedDrawings,
  getUnDoneDrawings,
  redo,
  undo,
  onDrawingsCreated,
  updateCreatedDrawings,
  updateIssueDrawAnnotationState,
  closeDrawingMode,
  dropDrawings,
  clearCanvas,
  convertPointToPixel,
  convertPointToPercentage,
  convertStartEndPointsToPixel,
  extractRGBValues,
  hexToRgb,
  hideAllDrawings,
  showIssueDrawings,
  terminateDrawing,
};
