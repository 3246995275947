import PropTypes from "prop-types";
import { createElement } from "react";

import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LinkifyIt from "linkify-it";
import tldsList from "tlds";

import { EMAIL_MENTION_REGEX } from "@shared/constants/validation";

import Highlight from "../../Highlight/Highlight";

const linkifyIt = LinkifyIt();
linkifyIt.tlds(tldsList, true);

const useStyles = makeStyles({
  linkText: {
    wordWrap: "break-word",
  },
});

export default function HighlightAndLinkify({
  searchTerms,
  highlightProps,
  children,
}) {
  const styles = useStyles();

  const emailMentionMatches = children.match(EMAIL_MENTION_REGEX);
  if (emailMentionMatches) {
    for (const match of emailMentionMatches) {
      const emailAddress = match.replace("@", "+");
      children = children.replace(match, emailAddress);
    }
  }

  const matches = linkifyIt.match(children);
  if (!matches) {
    return createElement(
      Highlight,
      { ...highlightProps, searchTerms },
      children
    );
  }

  const elements = [];
  let lastIndex = 0;
  for (const match of matches) {
    if (match.index > lastIndex) {
      elements.push(
        createElement(
          Highlight,
          { ...highlightProps, searchTerms, key: elements.length },
          children.substring(lastIndex, match.index)
        )
      );
    }

    const isEmailMatch = match.schema === "mailto:";
    const matchElementText =
      isEmailMatch && match.text.startsWith("+")
        ? match.text.replace("+", "@")
        : match.text;

    const matchElementChildren = createElement(
      Highlight,
      { ...highlightProps, searchTerms, key: elements.length },
      matchElementText
    );

    const element = createElement(
      Link,
      {
        href: isEmailMatch ? `${match.schema}${match.raw.slice(1)}` : match.url,
        key: elements.length,
        title: matchElementText,
        rel: "noreferrer nofollow",
        target: "_blank",
        className: styles.linkText,
      },
      matchElementChildren
    );

    elements.push(element);
    lastIndex = match.lastIndex;
  }

  if (children.length > lastIndex) {
    elements.push(
      createElement(
        Highlight,
        { ...highlightProps, searchTerms, key: elements.length },
        children.substring(lastIndex)
      )
    );
  }

  return elements;
}

HighlightAndLinkify.propTypes = {
  children: PropTypes.node.isRequired,
  highlightProps: PropTypes.shape({
    highlightClass: PropTypes.string,
    caseSensitive: PropTypes.bool,
  }),
  searchTerms: PropTypes.string,
};

HighlightAndLinkify.defaultProps = {
  searchTerms: "",
  highlightProps: {
    highlightClass: "highlight",
    caseSensitive: false,
  },
};
