import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FiRepeat } from "react-icons/fi";

import {
  Close as CloseIcon,
  OpenInNew as OpenInNewIcon,
  Search as SearchIcon,
  SchoolOutlined,
  KeyboardOutlined,
  ForumOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  Box,
  List,
  ListItemIcon,
  ListItemText,
  Backdrop,
  Divider,
  Badge,
  SvgIcon,
  ListItemButton,
} from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as teamService } from "@supporting/services/team";
import userService from "@supporting/services/userService";
import QuickSearchDialog from "@workflow/components/QuickSearchDialog/QuickSearchDialog";
import { useSelectedFiles } from "@workflow/components/SelectFilesContext";
import classNames from "classnames";

import { Text } from "@shared/UIKit";

import ChangeLogButton from "@shared/components/DashboardNavBar/ChangeLogButton/ChangeLogButton";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName, useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import Checklist from "./Checklist";
import HelpButton from "./HelpButton";
import {
  getProgress,
  isTeamOnTrial,
  getGuideStepId,
  canManageBilling,
  determineChecklistVisibility,
  determineExtendTrialVisibility,
} from "./helper";
import ResponsiveMenu from "./ResponsiveMenu";
import useUnreadMessagesCount from "./useUnreadMessagesCount";
import VideoTutorials from "./VideoTutorials";

function HelpMenu({ showSearch, isFileViewPage }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const currentRouteName = useCurrentRouteName();
  const user = useActiveUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const helpMenuButtonRef = useRef();
  const isMenuOpen = Boolean(anchorEl);
  const selectedTeam = useSelectedTeam();
  const { isSelectionState } = useSelectedFiles();
  const [onboardingChecklistHistory, setOnboardingChecklistHistory] = useState(
    user.settings.onboardingChecklistHistory || []
  );
  const [checklistProgress, setChecklistProgress] = useState(
    getProgress(onboardingChecklistHistory)
  );
  const [showConfetti, setShowConfetti] = useState(false);
  const [trialingTeam, setTrialingTeam] = useState();
  const [hasFinishedChecklist, setHasFinishedChecklist] = useState(
    user.settings.hasFinishedChecklist
  );
  const [shouldShowProgressBar, setShouldShowProgressBar] = useState(false);
  const unreadMessagesCount = useUnreadMessagesCount();

  const hasRestartedOnboardingChecklist =
    user.settings.hasRestartedOnboardingChecklist;
  const { showChecklist, showRestartButton } = determineChecklistVisibility(
    user.created,
    hasFinishedChecklist,
    isFileViewPage,
    isMobile,
    showConfetti,
    isSelectionState
  );
  const {
    canExtendTrial,
    shouldShowExtendTrialHeader,
    shouldShowOnCompletedTooltip,
  } = determineExtendTrialVisibility(
    isMenuOpen,
    showConfetti,
    Boolean(trialingTeam),
    trialingTeam?.billing?.extendTrialCount,
    hasRestartedOnboardingChecklist
  );

  useEffect(() => {
    const handleChecklistDisplay = async () => {
      setAnchorEl(helpMenuButtonRef.current);
      await userService.update({
        settings: {
          hasSeenOnboardingChecklist: true,
        },
      });
    };

    if (showChecklist && !user.settings.hasSeenOnboardingChecklist) {
      handleChecklistDisplay();
    }
  }, [showChecklist, user.settings.hasSeenOnboardingChecklist]);

  useEffect(() => {
    const getTrialingTeam = async () => {
      if (isTeamOnTrial(selectedTeam)) {
        setShouldShowProgressBar(true);

        if (canManageBilling(selectedTeam)) {
          setTrialingTeam(selectedTeam);
          return;
        }
      }

      const allTeams = await teamService.getTeams();
      const trialingTeam = allTeams.find(
        (team) => isTeamOnTrial(team) && canManageBilling(team)
      );
      setTrialingTeam(trialingTeam);
      setShouldShowProgressBar(
        Boolean(trialingTeam || allTeams.some(isTeamOnTrial))
      );
    };

    if (
      showChecklist &&
      !hasFinishedChecklist &&
      !hasRestartedOnboardingChecklist
    ) {
      getTrialingTeam();
    }
  }, [
    selectedTeam,
    showChecklist,
    hasFinishedChecklist,
    hasRestartedOnboardingChecklist,
  ]);

  const handleHelpMenuClick = ({ currentTarget }) => {
    if (showConfetti) {
      setShowConfetti(false);
    }
    setAnchorEl(currentTarget);
    if (isFileViewPage) {
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.HELP_MENU.ROOT,
        {
          page: getCurrentPage(currentRouteName),
        }
      );
    } else {
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.ONBOARDING_CHECKLIST
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  const handleChecklistUpdate = useCallback(
    async (guideId) => {
      const stepId = getGuideStepId(guideId);
      if (!stepId || onboardingChecklistHistory.includes(stepId)) {
        return;
      }

      const updatedOnboardingChecklistHistory = [
        ...onboardingChecklistHistory,
        stepId,
      ];

      const currentProgress = getProgress(updatedOnboardingChecklistHistory);
      const hasFinishedChecklist = currentProgress === 100;

      const updatedUser = await userService.update({
        settings: {
          onboardingChecklistHistory: updatedOnboardingChecklistHistory,
          hasFinishedChecklist,
        },
      });
      if (updatedUser) {
        setOnboardingChecklistHistory(updatedOnboardingChecklistHistory);

        if (hasFinishedChecklist) {
          setShouldShowProgressBar(false);

          if (canExtendTrial) {
            teamService.extendTeamTrialPeriod(trialingTeam._id);
          }
          setShowConfetti(true);
          analytics.track(
            analytics.ACTION.COMPLETED,
            analytics.CATEGORY.ONBOARDING_CHECKLIST
          );
        }
        setHasFinishedChecklist(hasFinishedChecklist);
        setChecklistProgress(currentProgress);
      }
    },
    [canExtendTrial, onboardingChecklistHistory, trialingTeam?._id]
  );

  useEffect(() => {
    if (window.userGuidingLayer) {
      window.userGuidingLayer[0] = {
        event: "onPreviewEnd",
        fn: ({ completed, guideId }) => {
          if (completed) {
            analytics.track(
              analytics.ACTION.COMPLETED,
              analytics.CATEGORY.ONBOARDING_CHECKLIST_GUIDE,
              { guideName: guideId }
            );
            handleChecklistUpdate(guideId);
          }
          setAnchorEl(helpMenuButtonRef.current);
        },
      };
    }

    return () => {
      window.userGuidingLayer = [];
    };
  }, [handleChecklistUpdate]);

  const handleHelpCenterClick = () => {
    openUrl("https://help.filestage.io/");
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.HELP_MENU.HELP_CENTER,
      {
        page: getCurrentPage(currentRouteName),
      }
    );
  };

  const handleKeyboardShortcutsClick = () => {
    openUrl(t("CORE.KEYBOARD_SHORTCUTS_ARTICLE_LINK"));
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.HELP_MENU.KEYBOARD_SHORTCUTS,
      {
        page: getCurrentPage(currentRouteName),
      }
    );
  };

  const handleSupportChatClick = () => {
    handleClose();
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.HELP_MENU.SUPPORT_CHAT,
      {
        page: getCurrentPage(currentRouteName),
      }
    );
  };

  const handleRestartChecklist = async () => {
    const updatedUser = await userService.update({
      settings: {
        hasFinishedChecklist: false,
        hasRestartedOnboardingChecklist: true,
        onboardingChecklistHistory: [],
      },
    });

    if (updatedUser) {
      setShouldShowProgressBar(false);
      setHasFinishedChecklist(false);
      setOnboardingChecklistHistory([]);
      setChecklistProgress(getProgress([]));
    }
  };

  const triggerSearch = () => {
    NiceModal.show(QuickSearchDialog);
    handleClose();
  };

  return (
    <Box>
      <HelpButton
        ref={helpMenuButtonRef}
        isMenuOpen={isMenuOpen}
        showChecklist={showChecklist}
        canExtendTrial={canExtendTrial}
        isFileViewPage={isFileViewPage}
        checklistProgress={checklistProgress}
        unreadMessagesCount={unreadMessagesCount}
        handleHelpMenuClick={handleHelpMenuClick}
        shouldShowProgressBar={shouldShowProgressBar}
        shouldShowOnCompletedTooltip={shouldShowOnCompletedTooltip}
      />
      <ResponsiveMenu
        id="help-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        showChecklist={showChecklist}
        isFileViewPage={isFileViewPage}
      >
        <Box position="sticky" top={16} zIndex={3}>
          <IconButton
            onClick={handleClose}
            data-testid="help-menu-close-button"
            size="small"
            sx={{ position: "absolute", top: 0, right: 24 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {showChecklist && (
          <Checklist
            handleClose={handleClose}
            onboardingChecklistHistory={onboardingChecklistHistory}
            checklistProgress={checklistProgress}
            showConfetti={showConfetti}
            canExtendTrial={canExtendTrial}
            shouldShowExtendTrialHeader={shouldShowExtendTrialHeader}
            shouldShowGiftIcon={shouldShowProgressBar}
          />
        )}
        <Box
          px={3}
          pt={2}
          top={0}
          zIndex={1}
          display="flex"
          position="sticky"
          backgroundColor={{ xs: "grey.100", md: "white" }}
        >
          <Text
            variant="textLg"
            translate="CHECKLIST.ONBOARDING_SECTION.HELP.TITLE"
            fontWeight="fontWeight.bold"
          />
        </Box>
        <VideoTutorials />
        <List>
          {showSearch && (
            <ListItemButton
              onClick={triggerSearch}
              data-testid="help-menu-search-icon"
            >
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText>{t("SEARCH.BTN_TOOLTIP")}</ListItemText>
            </ListItemButton>
          )}
          <ListItemButton
            onClick={handleHelpCenterClick}
            data-testid="help-menu-help-center"
            sx={{ "&:hover .MuiSvgIcon-root": { visibility: "visible" } }}
          >
            <ListItemIcon>
              <SchoolOutlined />
            </ListItemIcon>
            <ListItemText>{t("CORE.OPEN_HELP")}</ListItemText>
            <OpenInNewIcon fontSize="small" sx={{ visibility: "hidden" }} />
          </ListItemButton>

          <ListItemButton
            onClick={handleKeyboardShortcutsClick}
            data-testid="help-menu-keyboard-shortcuts"
            sx={{ "&:hover .MuiSvgIcon-root": { visibility: "visible" } }}
          >
            <ListItemIcon>
              <KeyboardOutlined />
            </ListItemIcon>
            <ListItemText>{t("CORE.OPEN_KEYBOARD_SHORTCUTS")}</ListItemText>
            <OpenInNewIcon fontSize="small" sx={{ visibility: "hidden" }} />
          </ListItemButton>
          {showRestartButton && (
            <ListItemButton
              onClick={handleRestartChecklist}
              data-testid="help-menu-restart-checklist"
            >
              <ListItemIcon>
                <SvgIcon component={FiRepeat} />
              </ListItemIcon>
              <ListItemText>{t("CORE.RESTART_CHECKLIST")}</ListItemText>
            </ListItemButton>
          )}
          <ChangeLogButton />
          <Divider
            sx={{
              position: "sticky",
              bottom: 44,
            }}
          />
          <ListItemButton
            onClick={handleSupportChatClick}
            className={classNames(
              "launch-intercom-chat",
              "fs-tracking__open-support-chat"
            )}
            data-testid="help-menu-support-and-feedback"
            sx={{
              position: "sticky",
              bottom: 0,
              bgcolor: { xs: "grey.100", md: "white" },
              "&:hover": { bgcolor: { xs: "grey.200", md: "grey.100" } },
            }}
          >
            <ListItemIcon>
              <Badge color="accent" badgeContent={unreadMessagesCount}>
                <ForumOutlined />
              </Badge>
            </ListItemIcon>
            <ListItemText>{t("CORE.CONTACT_SUPPORT")}</ListItemText>
          </ListItemButton>
        </List>
      </ResponsiveMenu>
      <Backdrop
        open={isMenuOpen && showChecklist}
        onClick={handleClose}
        sx={{ zIndex: 25 }}
      />
    </Box>
  );
}

HelpMenu.propTypes = {
  showSearch: PropTypes.bool,
  isFileViewPage: PropTypes.bool,
};

HelpMenu.defaultProps = {
  showSearch: false,
  isFileViewPage: false,
};
export default HelpMenu;
