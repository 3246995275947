/* eslint react/display-name:0 */
/* istanbul ignore file */
import { lazy, Suspense, ReactElement } from "react";

import { Box, LoadingSpinner } from "@shared/UIKit";

const DefaultFallback = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    width="100vw"
  >
    <LoadingSpinner />
  </Box>
);

/**
 * UIKit DynamicLoad Component
 * @description Renders a component with Reacy.lazy and React.Suspense
 * @param {Function} func - [Required] Function that imports the react component
 * @param {ReactElement} Fallback - [Optional] Fallback component to render while the component is loading
 * @example
 * const Component = DynamicLoad(() =>
    import("./path/of/component")
  );
 * @returns {ReactElement} - React component
 */
const LazyLoadPage = (func, Fallback = DefaultFallback) => {
  const Component = lazy(func);

  return (props) => (
    <Suspense fallback={<Fallback />}>
      <Component {...props} />
    </Suspense>
  );
};

export default LazyLoadPage;
