import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import StepSelection from "@workflow/components/UploadFileButton/step-selection/StepSelection";

import { Box, Text } from "@shared/UIKit";

import { fileProp } from "@shared/props/file";
import { stepProps } from "@shared/props/step";

const useClasses = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },
  selectLabel: {
    marginTop: -8,
    marginLeft: 12,
  },
}));

const UploadAfterEffectsComposition = ({
  steps,
  sectionId,
  closePanel,
  handleChange,
  selectedSteps,
  selectedFile,
}) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const theme = useTheme();
  const [isUploading, setUploading] = useState(false);

  const [templates, setTemplates] = useState({
    renderSettingsTemplates: [],
    outputModuleTemplates: [],
  });
  const [compositionData, setCompositionData] = useState({
    renderSettingsTemplate: "",
    outputModuleTemplate: "",
    name: "",
  });

  const handleRenderSettingsChange = (event) => {
    const { value } = event.target;
    setCompositionData((compositionObj) => ({
      ...compositionObj,
      renderSettingsTemplate: value,
    }));
  };

  const handleOutputModuleChange = (event) => {
    const { value } = event.target;
    setCompositionData((compositionObj) => ({
      ...compositionObj,
      outputModuleTemplate: value,
    }));
  };

  const handleInputChange = (event) => {
    setCompositionData((compositionObj) => ({
      ...compositionObj,
      name: event.target.value,
    }));
  };

  const handleCompositionUpload = useCallback(() => {
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PLUGIN_UPLOAD,
        data: {
          exportData: {
            sectionId,
            selectedSteps: Object.keys(selectedSteps),
            ...(selectedFile ? { fileId: selectedFile._id } : {}),
            ...compositionData,
          },
        },
      },
      "*"
    );
  }, [sectionId, selectedFile, compositionData, selectedSteps]);

  useEffect(() => {
    // eslint-disable-next-line complexity
    const handleAfterEffectsEvents = (event) => {
      if (event.origin === window.location.origin || !event?.data?.eventType) {
        return;
      }

      switch (event.data.eventType) {
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_FILE_DETAILS:
          if (event.data?.data?.name) {
            setCompositionData((compositionObj) => ({
              ...compositionObj,
              name: event.data.data.name,
            }));
          }
          break;
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_AEFT_TEMPLATES: {
          const templates = event.data.data;
          setTemplates(templates);
          break;
        }
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_EXPORT_START:
          setUploading(true);
          break;
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_SUCCESS:
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_TO_S3_START:
          closePanel();
          break;
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_FAILED:
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_EXPORT_FAILED:
          setUploading(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleAfterEffectsEvents);
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PLUGIN_GET_FILE,
        data: {},
      },
      "*"
    );

    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PLUGIN_GET_AEFT_TEMPLATES,
        data: {},
      },
      "*"
    );

    return () => {
      window.removeEventListener("message", handleAfterEffectsEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={5}
        data-testid="upload-aftereffects-composition-fields"
      >
        <Box display="flex" alignItems="center" mb={2.5} mt={2.5}>
          <Text
            variant="h6"
            translate="FILE.MENU.UPLOAD_COMPOSITION.TITLE"
            fontWeight={theme.typography.fontWeight.semiBold}
          />
        </Box>
        <Box maxWidth={280} width="100%">
          <Box className={classes.inputWrapper}>
            <TextField
              fullWidth
              type="text"
              name="name"
              variant="outlined"
              id="compositionNameInput"
              onChange={handleInputChange}
              value={compositionData.name}
              label={t(
                "FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.ENTER_FILE_NAME"
              )}
              inputProps={{
                "data-testid": "composition-name-input",
              }}
            />
          </Box>
          <Box className={classes.inputWrapper}>
            <FormControl fullWidth>
              <InputLabel
                id="renderSettingsSelectInput"
                className={classes.selectLabel}
              >
                {t("FILE.UPLOAD_PANEL.UPLOAD_COMPOSITION_INPUT_DIALOG.RENDER")}
              </InputLabel>
              <Select
                id="renderSettingsSelectInput"
                name="renderSettings"
                variant="outlined"
                labelId="renderSettingsSelectLabel"
                onChange={handleRenderSettingsChange}
                value={compositionData.renderSettingsTemplate}
                label={t(
                  "FILE.UPLOAD_PANEL.UPLOAD_COMPOSITION_INPUT_DIALOG.RENDER"
                )}
                inputProps={{
                  "data-testid": "upload-composition-renderSettings-input",
                }}
              >
                {templates.renderSettingsTemplates.map(
                  (renderSettingsName, index) => (
                    <MenuItem
                      key={index}
                      value={renderSettingsName}
                      data-testid={`upload-composition-renderSettings-${index}`}
                    >
                      {renderSettingsName}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.inputWrapper}>
            <FormControl fullWidth>
              <InputLabel
                id="outputModuleSelectInput"
                className={classes.selectLabel}
              >
                {t("FILE.UPLOAD_PANEL.UPLOAD_COMPOSITION_INPUT_DIALOG.OUTPUT")}
              </InputLabel>
              <Select
                id="outputModuleSelectInput"
                name="outputModule"
                variant="outlined"
                labelId="outputModuleSelectLabel"
                onChange={handleOutputModuleChange}
                value={compositionData.outputModuleTemplate}
                label={t(
                  "FILE.UPLOAD_PANEL.UPLOAD_COMPOSITION_INPUT_DIALOG.OUTPUT"
                )}
                inputProps={{
                  "data-testid": "upload-composition-outputModule-input",
                }}
              >
                {templates.outputModuleTemplates.map(
                  (outputModuleName, index) => (
                    <MenuItem
                      key={index}
                      value={outputModuleName}
                      data-testid={`upload-composition-outputModule-${index}`}
                    >
                      {outputModuleName}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      {steps.length > 0 && (
        <StepSelection
          steps={steps}
          handleChange={handleChange}
          selectedSteps={selectedSteps}
          isSelectedFileAvailable={Boolean(selectedFile)}
        />
      )}

      <Box
        pt={8.75}
        pb={2.5}
        px={2}
        display="flex"
        justifyContent="flex-end"
        gap={2.5}
        data-testid="upload-aftereffects-composition-buttons"
      >
        <Button
          variant="text"
          color="primaryV2"
          shape="rounded"
          onClick={closePanel}
          data-testid="cancel-button"
        >
          {t("FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.CANCEL")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          shape="rounded"
          data-testid="upload-button"
          onClick={handleCompositionUpload}
          disabled={!compositionData.name || isUploading}
        >
          {t(
            isUploading
              ? "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.UPLOADING"
              : "FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.UPLOAD"
          )}
        </Button>
      </Box>
    </>
  );
};

UploadAfterEffectsComposition.propTypes = {
  closePanel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
  steps: PropTypes.arrayOf(stepProps),
  sectionId: PropTypes.string,
  selectedFile: fileProp,
};

UploadAfterEffectsComposition.defaultProps = {
  steps: [],
  sectionId: null,
  selectedFile: null,
};

export default UploadAfterEffectsComposition;
