import COMPARE_MODES from "@feedback/constants/compareModes";
import viewerTypes from "@feedback/constants/viewerTypes";

import MEDIA_TYPES from "@shared/constants/mediaTypes";
import { APP_ROUTES } from "@shared/constants/routes";

const COMPARABLE_MEDIA_TYPES = {
  [COMPARE_MODES.AUTO_COMPARE_TEXT]: [
    MEDIA_TYPES.DOCUMENT,
    MEDIA_TYPES.DOCUMENT_XOD,
  ],
  [COMPARE_MODES.OVERLAY]: [
    MEDIA_TYPES.DOCUMENT,
    MEDIA_TYPES.DOCUMENT_XOD,
    MEDIA_TYPES.IMAGE,
    MEDIA_TYPES.IMAGE_XOD,
  ],
  [COMPARE_MODES.SIDE_BY_SIDE]: [
    MEDIA_TYPES.DOCUMENT,
    MEDIA_TYPES.DOCUMENT_XOD,
    MEDIA_TYPES.IMAGE,
    MEDIA_TYPES.IMAGE_XOD,
    MEDIA_TYPES.INTERACTIVE_HTML,
    MEDIA_TYPES.VIDEO,
  ],
};

const SYNCABLE_DOCUMENT_VIEWER_MEDIA_TYPES = [
  MEDIA_TYPES.DOCUMENT,
  MEDIA_TYPES.DOCUMENT_XOD,
  MEDIA_TYPES.IMAGE,
  MEDIA_TYPES.IMAGE_XOD,
];

function isCompareReviewUrl() {
  return location.pathname === APP_ROUTES.COMPARE_REVIEWS.path;
}

function withPageMetadata(properties = {}) {
  if (isCompareReviewUrl()) {
    properties.page = "compare-reviews";
  }

  return properties;
}

function isReviewComparable(review, compareMode) {
  let isReviewComparable = COMPARABLE_MEDIA_TYPES[compareMode].includes(
    review.mediaType
  );

  if (compareMode === COMPARE_MODES.OVERLAY) {
    const isMicrosoftOfficeFile = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    ].includes(review.original.mimeType);

    const isMultiPageDocument = review.linearized?.metaData?.pageCount > 1;

    isReviewComparable =
      isReviewComparable && !isMicrosoftOfficeFile && !isMultiPageDocument;
  }

  return isReviewComparable;
}

function areReviewsComparable(reviews, compareMode) {
  return !reviews.some((review) => !isReviewComparable(review, compareMode));
}

function getSyncableViewerType(versions) {
  let syncableViewerType = null;

  const isSyncingVideos = versions.every(
    (version) => version.mediaType === MEDIA_TYPES.VIDEO
  );
  const isSyncingDocumentsOrImages = versions.every((version) =>
    SYNCABLE_DOCUMENT_VIEWER_MEDIA_TYPES.includes(version.mediaType)
  );

  if (isSyncingVideos) {
    syncableViewerType = viewerTypes.VIDEO;
  } else if (isSyncingDocumentsOrImages) {
    syncableViewerType = viewerTypes.DOCUMENT;
  }

  return syncableViewerType;
}

export default {
  areReviewsComparable,
  isCompareReviewUrl,
  withPageMetadata,
  getSyncableViewerType,
};
