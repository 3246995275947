import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

class VideoViewerService {
  constructor(reviewId) {
    this.viewer = null;
    this.version = null;
    this.reviewId = reviewId;
  }

  getFrameRate() {
    if (!this.version) {
      return null;
    }

    const fileData = this.version.transcoded;
    if (!fileData?.metaData) {
      return null;
    }

    return fileData.metaData.frameRate;
  }

  isViewerReady() {
    return Boolean(this.viewer);
  }

  setViewerInstance(inViewerInstance) {
    this.viewer = inViewerInstance;

    /* istanbul ignore else */
    if (this.viewer) {
      eventService.emitEvent({
        eventName: EVENT_NAMES.VIDEO.PLAYER_READY,
        eventData: { reviewId: this.reviewId },
      });
    }
  }

  getViewerInstance() {
    return this.viewer;
  }

  setCurrentTime(currentTime) {
    this.viewer?.currentTime(currentTime);

    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_SEND_KEY_FRAME,
        data: {
          keyframe: currentTime,
        },
      },
      "*"
    );
  }

  getCurrentTime() {
    return this.viewer?.currentTime() || 0;
  }

  getCurrentBuffer() {
    if (!this.viewer) {
      return;
    }

    return this.viewer.buffered();
  }

  /**
   * @returns {Number} - duration in s
   */
  getDuration() {
    if (!this.isViewerReady()) {
      return 0;
    }

    return this.viewer.duration() || 0;
  }

  isPaused() {
    if (!this.isViewerReady()) {
      return true;
    }
    return this.viewer.paused();
  }

  // Report user activity (e.g. mouse events) to the viewer instance
  reportUserActivity(isActive) {
    if (!this.isViewerReady()) {
      return;
    }

    return this.viewer.userActive(isActive);
  }

  /**
   * Pauses the video and sends an event
   * @returns {Number} - time in ms at which the video has paused
   */
  pause() {
    if (!this.isPaused()) {
      window.postMessage(
        {
          eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_PAUSE,
          data: {
            keyframe: this.getCurrentTime(),
          },
        },
        "*"
      );
    }

    this.viewer.pause();
  }

  play() {
    if (this.isPaused()) {
      window.postMessage(
        {
          eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_PLAY,
          data: {
            keyframe: this.getCurrentTime(),
          },
        },
        "*"
      );
    }

    this.viewer.play();
  }

  setLoop(isLoop) {
    this.viewer.loop(isLoop);

    setTimeout(() => {
      eventService.emitEvent({
        eventName: EVENT_NAMES.VIDEO.LOOP_CHANGED,
        eventData: { reviewId: this.reviewId },
      });
    }, 0);
  }

  isLoopEnabled() {
    return this.viewer.loop();
  }

  isMuted() {
    return this.viewer.muted();
  }

  setMute(isMuted) {
    this.viewer.muted(isMuted);
  }

  setVolume(volume) {
    this.viewer.volume(volume);
  }

  setVersion(version) {
    this.version = version;
  }

  setPlaybackRate(playbackSpeed) {
    this.viewer.playbackRate(playbackSpeed);
  }

  getPlaybackRate() {
    return this.viewer.playbackRate();
  }

  hasEnded() {
    return this.viewer.ended();
  }

  getFrameDurationInSec() {
    const frameRate = this.getFrameRate();

    if (frameRate === null) {
      return 0;
    }

    const frameTime = 1.0 / frameRate;

    return frameTime;
  }

  /**
   * Jumps a provided number of frames in the video
   * @param  {Number} numberOfFrames - If positiv jump forwards, else backwards
   * @return {[type]} [description]
   */
  jumpFrames(numberOfFrames) {
    if (!this.isViewerReady()) {
      return;
    }

    // Stop viewer
    this.pause();

    // calculate new time
    const currentTime = this.getCurrentTime();
    const dist = this.getFrameDurationInSec() * numberOfFrames;
    let frameTime = currentTime + dist;

    if (frameTime < 0) {
      frameTime = 0.0;
    }

    const duration = this.getDuration();
    if (frameTime > duration) {
      frameTime = duration;
    }

    this.setCurrentTime(frameTime);
  }

  destroyViewer() {
    if (!this.viewer) {
      return;
    }

    this.viewer.dispose();
    this.viewer = null;
  }
}

const instances = {};
const getInstanceForReviewId = function (reviewId) {
  if (!instances[reviewId]) {
    instances[reviewId] = new VideoViewerService(reviewId);
  }

  return instances[reviewId];
};

export default {
  getInstanceForReviewId,
};
