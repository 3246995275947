import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Publish } from "@mui/icons-material";
import { Button } from "@mui/material";
import noop from "lodash/noop";

import { Tooltip } from "@shared/UIKit";

function WorkspaceButton({ onClick }) {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t("COMPARE_VERSIONS.UPLOAD_NEW_VERSION")}
      placement="bottom"
    >
      <Button
        data-testid="file-upload-button-version"
        size="extraSmall"
        color="primaryV2"
        variant="outlined"
        startIcon={<Publish fontSize="small" />}
        onClick={onClick}
        className="tour__version-picker-upload-button"
        sx={{ fontWeight: "regular" }}
      >
        {t("CORE.UPLOAD")}
      </Button>
    </Tooltip>
  );
}

WorkspaceButton.propTypes = {
  onClick: PropTypes.func,
};

WorkspaceButton.defaultProps = {
  onClick: noop,
};

export default WorkspaceButton;
