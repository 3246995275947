import PropTypes from "prop-types";
import { BiSolidCrown as Crown } from "react-icons/bi";

import { SvgIcon } from "@mui/material";

function PremiumIcon({ sx }) {
  return (
    <SvgIcon
      data-testid="premium-icon"
      fontSize="small"
      component={Crown}
      sx={{
        "&.react-icons": { fill: "#FFD700" },
        ...sx,
      }}
    />
  );
}

PremiumIcon.propTypes = {
  sx: PropTypes.object,
};

PremiumIcon.defaultProps = {
  sx: {},
};

export default PremiumIcon;
