import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import EditOutlined from "@mui/icons-material/EditOutlined";
import { IconButton } from "@mui/material";
import ChangeTeamMemberRoleDialog from "@supporting/components/ChangeTeamMemberRoleDiaolg/ChangeTeamMemberRoleDialog";
import RejectRevokeAdminRoleDialog from "@supporting/components/RejectRevokeAdminRoleDialog/RejectRevokeAdminRoleDialog";

import { Tooltip } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { userProp } from "@shared/props/authentication";
import { roleProp, teamProp } from "@shared/props/team";

function ChangeRoleButton({ isTeamMemberLastAdmin, member, team }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const {
    isOpen: isRejectRevokeAdminRoleDialogOpen,
    openDialog,
    closeDialog,
  } = useDialogState();

  const changeTeamMemberRole = () => {
    if (isTeamMemberLastAdmin) {
      return openDialog();
    }
    setIsOpen(true);
  };

  return (
    <>
      <ChangeTeamMemberRoleDialog
        teamMember={member}
        team={team}
        isOpen={isOpen}
        onClose={/* istanbul ignore next */ () => setIsOpen(false)}
      />
      <RejectRevokeAdminRoleDialog
        cancel={closeDialog}
        isOpen={isRejectRevokeAdminRoleDialogOpen}
      />

      <Tooltip title={t("TEAM.BUTTON.CHANGE_ROLE")}>
        <IconButton
          aria-label="delete"
          onClick={changeTeamMemberRole}
          data-testid="team-members-change-role-button"
          size="large"
        >
          <EditOutlined fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
}

ChangeRoleButton.propTypes = {
  team: teamProp.isRequired,
  member: PropTypes.shape({
    role: roleProp,
    user: userProp,
  }).isRequired,
  isTeamMemberLastAdmin: PropTypes.bool.isRequired,
};
export default ChangeRoleButton;
