import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { HomeOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser/useActiveUser";

import { Text, Box } from "@shared/UIKit";

import HelpResources from "@shared/components/HelpResources/HelpResources";
import { APP_ROUTES } from "@shared/constants/routes";
import { useMediaQuery } from "@shared/hooks";
import { usePageTitle } from "@shared/services/pageTitleService";

import SharedLayout from "../SharedLayout";
const selectPageTitleSetter = (state) => ({ setPageTitle: state.setTitle });

function LinkInvalid() {
  const { t } = useTranslation();
  const session = useActiveUser((user) => user?.session);
  const { setPageTitle } = usePageTitle(selectPageTitleSetter);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const isRegisteredUser = session && session.type === "Registered";

  useEffect(() => {
    setPageTitle(t("BROKEN_LINK.TITLE"));
  }, [setPageTitle, t]);

  return (
    <SharedLayout>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap={4}
        data-testid="link-invalid"
        mb={4}
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          gap={2}
          mt={{ xs: 0, md: 11 }}
        >
          <Text
            translate="BROKEN_LINK.TITLE"
            variant="text4xl"
            fontSize="3.75rem"
            lineHeight="1.3"
            fontWeight="fontWeight.bold"
            data-testid="link-invalid-headline"
          />
          <Text
            translate="BROKEN_LINK.CAPTION"
            variant="textXl"
            color="text.secondary"
          />
        </Box>
        {isRegisteredUser && (
          <Button
            component={RouterLink}
            to={APP_ROUTES.DASHBOARD.path}
            color="primary"
            variant="contained"
            size="large"
            startIcon={<HomeOutlined />}
            fullWidth={isMobile}
          >
            {t("BROKEN_LINK.BUTTON_DASHBOARD")}
          </Button>
        )}
      </Box>
      <HelpResources />
    </SharedLayout>
  );
}

export default LinkInvalid;
