import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";

import { Box, Dialog, Text } from "@shared/UIKit";

import ConfirmationCheckbox from "../ConfirmationCheckbox/ConfirmationCheckbox";

export default function RegenerateAPIKeyDialog({
  isOpen,
  closeDialog,
  regenerateAPIKey,
}) {
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isCheckboxError, setIsCheckboxError] = useState(false);

  const handleContinue = useCallback(async () => {
    if (!isConfirmed) {
      setIsCheckboxError(true);
      return;
    }
    setIsFetching(true);
    await regenerateAPIKey();
  }, [regenerateAPIKey, isConfirmed]);

  const toggleCheckbox = useCallback(() => {
    if (isCheckboxError) {
      setIsCheckboxError(false);
    }
    setIsConfirmed((value) => !value);
  }, [isCheckboxError]);

  return (
    <Dialog
      hideBackdrop={false}
      maxWidth="sm"
      title={t("INTEGRATIONS.API.REGENERATE_POP_OVER.TITLE")}
      isOpen={isOpen}
      cancel={closeDialog}
      actions={
        <>
          <Button
            size="large"
            variant="text"
            color="primaryV2"
            onClick={closeDialog}
            data-testid="regenerate-key-cancel"
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            loading={isFetching}
            onClick={handleContinue}
            data-testid="continue-button"
          >
            {t("CORE.CONTINUE")}
          </Button>
        </>
      }
      useDefaultBackground={false}
    >
      <Box boxSizing="border-box" textAlign="left">
        <Box display="flex" flexDirection="column" gap={2}>
          <Text
            variant="textXs"
            translate="INTEGRATIONS.API.REGENERATE_POP_OVER.DESCRIPTION"
          />
          <ConfirmationCheckbox
            isError={isCheckboxError}
            isConfirmed={isConfirmed}
            toggleCheckbox={toggleCheckbox}
            errorMessageText={t("INTEGRATIONS.API.ERROR_MESSAGE")}
            label={t("INTEGRATIONS.API.REGENERATE_POP_OVER.WARNING_MESSAGE")}
          />
        </Box>
      </Box>
    </Dialog>
  );
}

RegenerateAPIKeyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  regenerateAPIKey: PropTypes.func.isRequired,
};
