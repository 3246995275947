import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { NotificationsNone } from "@mui/icons-material";
import { Badge, IconButton } from "@mui/material";
import notificationCenterService from "@supporting/services/notificationCenterService";

import { Tooltip } from "@shared/UIKit";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

function NotificationBellIcon() {
  const { t } = useTranslation();

  const [showNotificationIndicator, setShowNotificationIndicator] =
    useState(false);

  const currentRouteName = useCurrentRouteName();

  useEffect(() => {
    const enableIndicator = () => {
      setShowNotificationIndicator(true);
    };

    const disableIndicator = () => {
      setShowNotificationIndicator(false);
    };
    eventService.addListener(
      EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.ENABLED,
      enableIndicator
    );
    eventService.addListener(
      EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.DISABLED,
      disableIndicator
    );
    notificationCenterService.updateIndicator();

    return () => {
      eventService.removeListener(
        EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.ENABLED,
        enableIndicator
      );
      eventService.removeListener(
        EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.DISABLED,
        disableIndicator
      );
    };
  }, []);

  const openNotificationPanel = () => {
    eventService.emitEvent({
      eventName: EVENT_NAMES.NOTIFICATION_CENTER.ICON.CLICKED,
      eventData: {},
    });

    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.NOTIFICATION_CENTER,
      { page: getCurrentPage(currentRouteName) }
    );
  };

  return (
    <Tooltip title={t("NOTIFICATION_CENTER.TITLE")}>
      <IconButton
        aria-label={t("NOTIFICATION_CENTER.TITLE")}
        onClick={openNotificationPanel}
        className="fs-tracking__open-notification-center"
        data-testid="notification-center-bell-icon"
        size="medium"
      >
        <Badge
          color="upgrade"
          overlap="circular"
          variant="dot"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          invisible={!showNotificationIndicator}
          badgeContent=" "
          sx={{
            "& .MuiBadge-badge": {
              bgcolor: "var(--mui-color-blue-300)",
              transform: "scale(150%)",
              top: 2,
              right: 3,
            },
          }}
          data-testid="notification-center-icon-blue-dot"
        >
          <NotificationsNone fontSize="inherit" />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

export default NotificationBellIcon;
