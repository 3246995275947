/* eslint react/forbid-elements:0 */
/* eslint react/forbid-component-props:0 */
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";
import classnames from "classnames";

import { ReviewDecisionIndicator, Tooltip, Box } from "@shared/UIKit";

import { reviewProp } from "@shared/props/review";

import ReviewDecisionOverview from "../ReviewDecisionOverview/ReviewDecisionOverview";

const useStyles = makeStyles((theme) => ({
  IsApproved: {
    "&::before": {
      backgroundImage:
        "linear-gradient(to right, rgba(236,252,240,0) , rgba(236,252,240,1)) !important",
    },
    "&::after": {
      backgroundImage:
        "linear-gradient(to right, rgba(236,252,240,1), rgba(236,252,240,0)) !important",
    },
  },
  Indicator: {
    cursor: "pointer",
  },
  IndicatorList: {
    zIndex: 15,
    width: "120px",
    overflow: "hidden",
  },
  PendingReview: {
    overflow: "unset",
  },
  IndicatorListLeft: {
    "&::after": {
      content: '""',
      zIndex: 20,
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: 30,
      backgroundImage:
        "linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))",
    },
  },
  IndicatorListRight: {
    "&::before": {
      content: '""',
      zIndex: 20,
      position: "absolute",
      right: 0,
      top: 0,
      height: "100%",
      width: 30,
      backgroundImage:
        "linear-gradient(to right, rgba(255,255,255,0) , rgba(255,255,255,1))",
    },
  },
  DecisionLabel: {
    marginLeft: 10,
    overflow: "hidden",
  },
  Workspace: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: -11,
    overflow: "unset",
  },
  ReviewersWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    "&:hover > $Indicator": {
      transform: "scale(1.2) !important",
    },
  },
  ReviewersWrapperWorkspace: {
    padding: `0px 7px`,
  },
  ReviewersWrapperDashboard: {
    padding: 0,
    paddingRight: 7,
  },
  IndicatorOverlap: {
    marginLeft: -10,
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
  IndicatorOverlapRedesign: {
    width: 10,
    height: 10,
    border: `0.5px solid ${theme.color.white}`,
    marginLeft: -4,
    "&:first-of-type": {
      marginLeft: 0,
    },
  },
  reviewRedesign: {
    width: "fit-content",
  },
  reviewOverview: {
    pointerEvents: "none",
    "&:hover > $Indicator": {
      transform: "none",
    },
  },
}));

const MAX_INDICATORS = 8;

function getWrapperClasses(classes, variant) {
  return classnames(classes.ReviewersWrapper, {
    [classes.ReviewersWrapperDashboard]: variant === "dashboard",
    [classes.ReviewersWrapperWorkspace]: variant === "workspace",
    [classes.reviewOverview]: variant === "reviewOverview",
  });
}

const IndicatorWrapper = ({ children, variant, ...props }) =>
  variant === "workspace" ? (
    <div {...props}>{children}</div>
  ) : (
    <span {...props}>{children}</span>
  );

function ReviewDecisionIndicatorList({
  review,
  variant,
  indicatorPosition,
  isApproved,
  ...restProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useActiveUser();

  const isNewOverviewEnabled =
    user?.settings?.earlyAccessFeatures?.fileViewRedesign;

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const openPopover = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  let Indicators;

  if (isNewOverviewEnabled) {
    const stateOrder = [
      "APPROVED",
      "APPROVED_WITH_CHANGES",
      "NEEDS_CHANGES",
      "REJECTED",
      "PENDING",
    ];

    review.reviews.sort((reva, revb) => {
      return stateOrder.indexOf(reva.state) - stateOrder.indexOf(revb.state);
    });
    const isOverflowing = review.reviews.length > MAX_INDICATORS;
    Indicators = review.reviews
      .slice(0, MAX_INDICATORS)
      .map((reviewDecision, index) => (
        <ReviewDecisionIndicator
          key={reviewDecision.reviewer._id}
          state={reviewDecision.state}
          className={classnames(
            classes.Indicator,
            classes.IndicatorOverlapRedesign
          )}
          style={{
            zIndex: `${review.reviews.length + index}`,
          }}
          isApproved={isApproved}
          isOverflowing={isOverflowing}
        />
      ));
  } else {
    Indicators = review.reviews.map((reviewDecision, index) => (
      <ReviewDecisionIndicator
        key={reviewDecision.reviewer._id}
        state={reviewDecision.state}
        className={classnames(classes.Indicator, {
          [classes.IndicatorOverlap]: variant !== "workspace",
        })}
        style={{
          zIndex: `${review.reviews.length - index}`,
        }}
        isApproved={isApproved}
      />
    ));
  }

  if (variant === "workspace") {
    restProps.style = { width: 28 * review.reviews.length + 7 };
  }

  return (
    <>
      <Box
        data-testid="review-decision-indicator-list"
        display="flex"
        alignItems="start center"
        flexDirection="row"
        position="relative"
        className={classnames(classes.IndicatorList, {
          [classes.IsApproved]: isApproved,
          [classes.Workspace]: variant === "workspace",
          [classes.PendingReview]:
            variant === "workspace" && review.isPendingYourReview,
          [classes.IndicatorListLeft]:
            variant !== "workspace" &&
            !review.isPendingYourReview &&
            indicatorPosition === "left",
          [classes.IndicatorListRight]:
            variant !== "workspace" &&
            indicatorPosition === "right" &&
            variant !== "reviewOverview",
          [classes.reviewRedesign]: isNewOverviewEnabled,
        })}
        aria-describedby={`review-summary-${review.id}`}
        data-open-review-link="true"
        {...restProps}
      >
        <Tooltip
          title={t("FILE_REVIEW.VIEW_REVIEWS_BUTTON")}
          aria-label={t("FILE_REVIEW.VIEW_REVIEWS_BUTTON")}
        >
          <Box>
            <IndicatorWrapper
              className={getWrapperClasses(classes, variant)}
              variant={variant}
              data-testid="review-decision-indicator-wrapper"
              onClick={openPopover}
            >
              {Indicators}
            </IndicatorWrapper>
          </Box>
        </Tooltip>
      </Box>

      {Boolean(anchorEl) && (
        <ReviewDecisionOverview
          review={review}
          onClose={closePopover}
          open
          anchorEl={anchorEl}
          id={`review-decision-overview-${review.id}`}
        />
      )}
    </>
  );
}

IndicatorWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  variant: PropTypes.oneOf(["workspace", "dashboard", "reviewOverview"])
    .isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ReviewDecisionIndicatorList.propTypes = {
  review: reviewProp.isRequired,
  variant: PropTypes.oneOf(["workspace", "dashboard", "reviewOverview"]),
  indicatorPosition: PropTypes.oneOf(["left", "right"]),
  isApproved: PropTypes.bool,
};

ReviewDecisionIndicatorList.defaultProps = {
  variant: "dashboard",
  indicatorPosition: "left",
  isApproved: false,
};

export default ReviewDecisionIndicatorList;
