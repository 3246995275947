import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import toastService from "@supporting/services/toast";

import { Box, Dialog, Text } from "@shared/UIKit";

import { apiKeyProps } from "@shared/props/apiKey";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

export default function DeleteAPIKeyDialog({
  isOpen,
  closeDialog,
  onDelete,
  apiKey,
}) {
  const { t } = useTranslation();
  const selectedTeam = useSelectedTeam();
  const [isFetching, setIsFetching] = useState(false);

  const deleteAPIKey = useCallback(async () => {
    const teamId = selectedTeam._id;
    setIsFetching(true);
    try {
      await backend.delete(`/api-token/${apiKey.id}?team=${teamId}`);
      analytics.track(
        analytics.ACTION.DELETED,
        analytics.CATEGORY.INTEGRATIONS.API_KEY,
        {
          teamId,
          token: `****${apiKey.token.last}`,
        }
      );
      onDelete();
      closeDialog();
      toastService.sendToast({
        title: "INTEGRATIONS.API.CONNECTION.SUCCESS_TOAST.TITLE",
        preset: toastService.PRESETS().SUCCESS,
        preventOpenDuplicatesId: "INTEGRATIONS.API.CONNECTION.SUCCESS_TOAST",
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    } finally {
      setIsFetching(false);
    }
  }, [selectedTeam, apiKey]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      title={t("INTEGRATIONS.API.CONNECTION.DELETE_KEY_TITLE")}
      hideBackdrop={false}
      maxWidth="sm"
      isOpen={isOpen}
      cancel={closeDialog}
      content={
        <Box
          display="flex"
          alignItems="center"
          boxSizing="border-box"
          flexDirection="column"
          justifyContent="center"
        >
          <Box>
            <Text
              variant="subtitle2"
              fontSize="textSm.fontSize"
              translate="INTEGRATIONS.API.CONNECTION.DELETE_KEY_DESC"
            />
          </Box>
        </Box>
      }
      actions={
        <>
          <Button
            variant="text"
            color="primaryV2"
            onClick={closeDialog}
            data-testid="delete-key-cancel"
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            color="error"
            variant="contained"
            loading={isFetching}
            onClick={deleteAPIKey}
            data-testid="delete-api-key-button"
          >
            {t("INTEGRATIONS.API.CONNECTION.DELETE_KEY_BUTTON")}
          </Button>
        </>
      }
      useDefaultBackground={false}
    />
  );
}

DeleteAPIKeyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  apiKey: apiKeyProps.isRequired,
};
