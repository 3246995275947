import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { InfoOutlined } from "@mui/icons-material";
import { Button, Card, CardHeader, CardContent } from "@mui/material";
import { instance as teamService } from "@supporting/services/team";

import { Box, Image } from "@shared/UIKit";

import Invoices from "@assets/img/sprite-source/sprite--invoices.png";
import Invoices2x from "@assets/img/sprite-source/sprite--invoices@2x.png";

const BillingInvoices = () => {
  const { t } = useTranslation();

  const team = teamService.getSelectedTeam();
  const quadernoButton = useRef();

  useEffect(() => {
    quadernoButton.current = window.QuadernoBilling.configure({
      key: window.fs.config.quaderno.publishableKey,
      customer_id: team.billing.customerId,
    });
  }, [team.billing.customerId]);

  const openInvoices = () => {
    quadernoButton?.current.open();
  };

  return (
    <Card data-testid="team-billing-invoices-overview">
      <CardHeader title={t("BILLING.INVOICES")} />
      <CardContent>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection="column"
          gap={3}
        >
          <Image src={Invoices} srcSet={`${Invoices2x} 2x`} alt="invoices" />
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={openInvoices}
            data-testid="quaderno-billing-invoices-button"
          >
            {t("BILLING.SHOW_INVOICES")}
          </Button>
          <Box display="flex" justifyContent="center">
            <Button
              href={t("URLS.FAQ_CHANGE_INVOICE_DATA")}
              color="primaryV2"
              target="_blank"
              startIcon={<InfoOutlined fontSize="small" />}
            >
              {t("BILLING.EDIT_INVOICE_DATA.HELP")}
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BillingInvoices;
