import PropTypes from "prop-types";
import { forwardRef } from "react";

import { ListSubheader } from "@mui/material";
import clsx from "clsx";

import classes from "./ProjectListGroupHeader.module.css";

const ProjectListGroupHeader = forwardRef(({ children, variant }, ref) => {
  return (
    <ListSubheader
      ref={ref}
      data-testid="group-header-menu-item"
      className={clsx(classes[variant])}
    >
      {children}
    </ListSubheader>
  );
});
ProjectListGroupHeader.displayName = "ProjectListGroupHeader";

ProjectListGroupHeader.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(["folder", "template", "favorite"]),
};

ProjectListGroupHeader.defaultProps = {
  variant: "folder",
};

ProjectListGroupHeader.muiSkipListHighlight = true;

export default ProjectListGroupHeader;
