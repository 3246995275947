import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import ReviewDecisionIndicator from "@feedback/components/ReviewDecisionIndicatorList/ReviewDecisionIndicator";
import ReviewDecisionOverview from "@feedback/components/ReviewDecisionOverview/ReviewDecisionOverview";
import { makeStyles } from "@mui/styles";
import authenticationService from "@supporting/services/authentication";
import classnames from "classnames";
import differenceWith from "lodash/differenceWith";
import keyBy from "lodash/keyBy";

import { Box } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { automationProps } from "@shared/props/automations";
import { fileProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import StepReviewer from "./StepReviewer/StepReviewer";

const useStyles = makeStyles(() => ({
  Indicator: {
    marginBottom: -7,
    zIndex: 10,
  },
  IndicatorHover: {
    transform: "scale(1.2) !important",
  },
}));

function StepReviewersList({
  users,
  teamId,
  automations,
  step,
  canManageProjectMembers,
  isProjectArchived,
  review,
  isPendingYourReview,
  showStatus,
  variant,
  usersIndexFrom,
  usersIndexTo,
  canManageProjectTemplates,
  isProjectTemplate,
  team,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(false);

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const openPopover = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const hoverStatus = useCallback(
    (status) => {
      if (status !== hover) {
        setHover(status);
      }
    },
    [hover]
  );

  const reviews = review ? review.reviews : [];
  const mappedReviews = keyBy(reviews, (review) => review.reviewer._id);
  const unMappedReviews = differenceWith(
    Object.keys(mappedReviews),
    users.slice(usersIndexFrom, usersIndexTo).map((user) => user._id)
  );

  const currentUser = authenticationService.fetchUser();
  const avatarCount = usersIndexTo - usersIndexFrom;

  return (
    <>
      {users.slice(usersIndexFrom, usersIndexTo).map((user, index) => (
        <Box
          key={user._id}
          ml={-0.75}
          align="center"
          zIndex={variant === "workspace" ? 1 : avatarCount - index}
          data-testid={`review-decision-indicator-hover-${hover}`}
        >
          {showStatus && (
            <ReviewDecisionIndicator
              isPendingYourReview={
                isPendingYourReview && currentUser._id === user._id
              }
              state={mappedReviews[user._id]?.state}
              variant={variant}
              className={classnames(classes.Indicator, {
                [classes.IndicatorHover]: hover,
              })}
              onClick={openPopover}
              disabled={!user.reviewDecisionRequested}
              onHover={hoverStatus}
            />
          )}
          <StepReviewer
            user={user}
            teamId={teamId}
            step={step}
            automations={automations}
            team={team}
            canManageProjectMembers={canManageProjectMembers}
            isProjectArchived={isProjectArchived}
            canManageProjectTemplates={canManageProjectTemplates}
            isProjectTemplate={isProjectTemplate}
          />
        </Box>
      ))}

      {unMappedReviews.map((userId, index) => (
        <Box
          key={userId}
          ml={-0.75}
          align="center"
          zIndex={variant === "workspace" ? 1 : unMappedReviews.length - index}
        >
          {showStatus && (
            <ReviewDecisionIndicator
              isPendingYourReview={
                isPendingYourReview && currentUser._id === userId
              }
              state={mappedReviews[userId].state}
              variant={variant}
              className={classnames(classes.Indicator, {
                [classes.IndicatorHover]: hover,
              })}
              onClick={openPopover}
              onHover={hoverStatus}
            />
          )}

          <StepReviewer
            user={mappedReviews[userId].reviewer}
            teamId={teamId}
            step={step}
            team={team}
            canManageProjectMembers={canManageProjectMembers}
            canManageProjectTemplates={canManageProjectTemplates}
            isProjectArchived={isProjectArchived}
            isProjectTemplate={isProjectTemplate}
            showEmpty
          />
        </Box>
      ))}

      {showStatus && review && (
        <ReviewDecisionOverview
          review={review}
          onClose={closePopover}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          id={`review-decision-overview-${review.id}`}
        />
      )}
    </>
  );
}

StepReviewersList.propTypes = {
  users: PropTypes.arrayOf(userProp).isRequired,
  teamId: PropTypes.string.isRequired,
  step: stepProps,
  canManageProjectMembers: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  automations: PropTypes.arrayOf(automationProps),
  showStatus: PropTypes.bool,
  variant: PropTypes.oneOf(["workspace", "dashboard"]),
  file: fileProp,
  review: reviewProp,
  isPendingYourReview: PropTypes.bool,
  usersIndexFrom: PropTypes.number.isRequired,
  usersIndexTo: PropTypes.number.isRequired,
  canManageProjectTemplates: PropTypes.bool,
  isProjectTemplate: PropTypes.bool,
  team: teamProp.isRequired,
};

StepReviewersList.defaultProps = {
  isProjectArchived: false,
  showStatus: false,
  variant: "workspace",
  file: null,
  automations: [],
  review: null,
  isPendingYourReview: false,
  step: null,
  canManageProjectTemplates: false,
  isProjectTemplate: false,
  canManageProjectMembers: false,
};

export default StepReviewersList;
