import { generatePath } from "react-router-dom";

import { APP_ROUTES } from "@shared/constants/routes";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

const teamConnectionCache = new Map();

const getTeamConnection = async (teamId) => {
  const cachedTeamConnection = teamConnectionCache.get(teamId);

  if (cachedTeamConnection) {
    return cachedTeamConnection;
  }

  try {
    const teamConnection = await backend.get(`/integrations/microsoft-teams`, {
      teamId,
    });

    teamConnectionCache.set(teamId, teamConnection);
  } catch {
    teamConnectionCache.set(teamId, null);
  }

  return teamConnectionCache.get(teamId);
};

const generateOauthUrl = (userId, teamId) => {
  const { config } = window.fs;

  const { clientId, tenantId } = config.microsoftTeams;
  const redirectUrl = `${config.appBase}${generatePath(
    APP_ROUTES.INTEGRATIONS_AUTHORIZATION_CALLBACK.path,
    {
      appId: "microsoft-teams",
    }
  )}`;

  const params = {
    client_id: clientId,
    redirect_uri: redirectUrl,
    response_type: "code",
    scope: [
      "offline_access",
      "https://graph.microsoft.com/Group.ReadWrite.All",
      "https://graph.microsoft.com/User.Read",
      "https://graph.microsoft.com/Chat.ReadWrite.All",
      "https://graph.microsoft.com/TeamSettings.Read.All",
      "https://graph.microsoft.com/TeamsTab.Read.All",
    ].join(" "),
    response_mode: "query",
    state: JSON.stringify({ userId, teamId }),
  };

  const urlParams = new URLSearchParams(params).toString();

  return `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?${urlParams}`;
};

const disconnectApp = async (teamId) => {
  await backend.delete(`/integrations/microsoft-teams`, {
    teamId,
  });

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.DISCONNECTED,
    eventData: {
      teamId,
      type: "microsoft-teams",
    },
  });

  teamConnectionCache.delete(teamId);
};

export default {
  getTeamConnection,
  generateOauthUrl,
  disconnectApp,
};
