import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box, SvgIcon } from "@mui/material";
import PasswordFieldWithTooltip from "@supporting/components/PasswordFieldWithTooltip/PasswordFieldWithTooltip";
import authenticationService from "@supporting/services/authentication";

import { Text } from "@shared/UIKit";

import routeStates from "@shared/constants/routeStates";
import { INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX } from "@shared/constants/validation";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useMediaQuery, useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import { instance as logger } from "@shared/services/logger";

import EnforcePasswordChangeIcon from "@assets/img/icons/enforce_password_icon.svg";

const MINIMUM_PASSWORD_LENGTH = 8;

const ONE_NUMBER_OR_SPECIAL_REGEX = new RegExp(
  INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX
);

function EnforcePasswordChangeForm() {
  const { t } = useTranslation();

  const { goTo } = useNavigation();
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });
  const TAG = "authentication-controller";
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const [validation, setValidation] = useState({
    isMinimum: false,
    isOneUpperCase: false,
    isOneLowerCase: false,
    isOneNumberOrSpecial: false,
  });
  useEffect(() => {
    setValidation({
      isMinimum: newPassword.length >= MINIMUM_PASSWORD_LENGTH,
      isOneUpperCase: newPassword.toLowerCase() !== newPassword,
      isOneLowerCase: newPassword.toUpperCase() !== newPassword,
      isOneNumberOrSpecial: ONE_NUMBER_OR_SPECIAL_REGEX.test(newPassword),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);
  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.ENFORCE_PASSWORD_CHANGE_PAGE
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async () => {
    if (
      validation.isMinimum &&
      validation.isOneLowerCase &&
      validation.isOneNumberOrSpecial &&
      validation.isOneUpperCase
    ) {
      //sucess code
      try {
        setLoading(true);
        await authenticationService.enforcePasswordChange(newPassword);
        analytics.track(
          analytics.ACTION.SAVED,
          analytics.CATEGORY.ENFORCE_PASSWORD_CHANGE_PAGE_ACCOUNT_USER_PASSWORD
        );
        goTo(routeStates.DASHBOARD);
        setLoading(false);
      } catch (err) {
        logger.error(TAG, "failed to change password", {
          error,
        });
        setLoading(false);
        analytics.track(
          analytics.ACTION.SAVED,
          analytics.CATEGORY
            .ENFORCE_PASSWORD_CHANGE_PAGE_ACCOUNT_USER_PASSWORD_FAILED
        );
      }
    } else {
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.ACCOUNT_USER_PASSWORD_FAILED,
        { page: getCurrentPage(routeStates.ENFORCE_PASSWORD_CHANGE) }
      );
      setError(true);
    }
  };
  return (
    <Box
      flexDirection="column"
      display="flex"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
      flex={1}
      maxWidth={400}
      alignSelf="center"
      gap={2}
    >
      <Box
        width={85}
        height={85}
        borderRadius="100%"
        bgcolor="grey.200"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <SvgIcon
          component={EnforcePasswordChangeIcon}
          viewBox="0 0 39 47"
          fontSize="large"
        />
      </Box>
      <Text
        variant="text3xl"
        fontWeight="fontWeight.medium"
        translate="ENFORCE_PASSWORD.HEADING"
      />
      <Text
        color="grey.600"
        variant="textMd"
        textAlign="center"
        translate="ENFORCE_PASSWORD.SUBTITLE"
      />
      <PasswordFieldWithTooltip
        isMinimum={validation.isMinimum}
        isOneLowerCase={validation.isOneLowerCase}
        isOneNumberOrSpecial={validation.isOneNumberOrSpecial}
        isOneUpperCase={validation.isOneUpperCase}
        value={newPassword}
        onChange={handlePasswordChange}
        name="enforce-password-field"
        id="enforce-password"
        labelTranslate="PASSWORD_INPUT_FIELD.LABEL"
        error={error}
        autoFocus={!isMobile}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        loading={loading}
        width="inherit"
      >
        {t("CORE.SAVE")}
      </Button>
    </Box>
  );
}

export default EnforcePasswordChangeForm;
