(function() {
  var e;
  var t;
  var i;
  var n;
  var r;
  var a;
  var o;
  var l;
  var d;
  var s;
  (t = void 0),
    (r = void 0),
    (i = void 0),
    (n = void 0),
    (d = void 0),
    (s = "https://billing.quaderno.io"),
    (o = function() {
      var e;
      return (
        (e =
          document.currentScript ||
          (function() {
            var e;
            return (
              (e = document.getElementsByTagName("script")), e[e.length - 1]
            );
          })()),
        (e.id += "q" + new Date().getTime()),
        e
      );
    }),
    (a = function() {
      var e;
      var t;
      return (
        (t = window.navigator.userAgent),
        (e = t.indexOf("MSIE ")),
        e > 0 || navigator.userAgent.match(/Trident.*rv\:11\./) ? !0 : !1
      );
    }),
    (l = function(e, t) {
      var i;
      var n;
      return (
        (n = document.querySelector(".quaderno_billing_app")),
        void 0 !== window.jQuery
          ? ((i = jQuery.Event(e)), (i.detail = t), $(n).trigger(i))
          : (a()
              ? ((i = document.createEvent("CustomEvent")),
                i.initCustomEvent(e, !0, !0, t))
              : (i = new CustomEvent(e, {
                  detail: t,
                  bubbles: !0,
                  cancelable: !0
                })),
            n.dispatchEvent(i))
      );
    }),
    (e = function() {
      this.iframeLoaded = !1;
    }),
    (e.prototype = {
      bind: function(e, t, i) {
        return e.addEventListener
          ? e.addEventListener(t, i, !1)
          : e.attachEvent("on" + t, i);
      },
      unbind: function(e, t, i) {
        return e.removeEventListener
          ? e.removeEventListener(t, i, !1)
          : e.detachEvent("on" + t, i);
      },
      toURIparams: function(e) {
        return (
          "?" +
          Object.keys(e)
            .reduce(function(t, i) {
              return t.push(i + "=" + encodeURIComponent(e[i])), t;
            }, [])
            .join("&")
        );
      },
      scriptAttributes: function(e) {
        var t;
        return (t = {
          key: d.scriptData("key", e),
          customer_id: d.scriptData("customer-id".script),
          label: d.scriptData("label", e),
          editable: d.scriptData("editable", e),
          locale: d.scriptData("locale", e) || "en",
          script_id: e.id
        });
      },
      scriptData: function(e, t) {
        return (
          t || (t = QuadernoBilling.currentScript()),
          t.getAttribute("data-" + e)
        );
      },
      iframeHasLoaded: function(e) {
        return void 0 !== e && (this.iframeLoaded = e), this.iframeLoaded;
      }
    }),
    (i = function(e) {
      null == e && (e = "View billing"),
        (this._iframe = QuadernoBilling.iframe()),
        (this._label = e);
    }),
    (i.prototype = {
      render: function() {
        var e;
        var t;
        var i;
        var n;
        0 ===
          document.querySelectorAll(".quaderno-billing-button-style").length &&
          ((t = document.head),
          (n = document.createElement("link")),
          (n.type = "text/css"),
          (n.rel = "stylesheet"),
          (n.href = s + "/quaderno-billing-button.css"),
          (n.className = "quaderno-billing-button-style"),
          t.appendChild(n)),
          (e = void 0),
          (i = void 0),
          (e = document.createElement("button")),
          (i = document.createTextNode(this._label)),
          e.appendChild(i),
          window.QuadernoBilling.currentScript().parentNode.insertBefore(
            e,
            window.QuadernoBilling.currentScript().nextSibling
          ),
          e.addEventListener("click", function(e) {
            return (
              e.preventDefault(),
              d.iframeHasLoaded()
                ? QuadernoBilling.iframe().toggle()
                : ((document.querySelector(
                    ".quaderno-billing-loader"
                  ).style.display = "block"),
                  document
                    .querySelector(".quaderno_billing_app")
                    .addEventListener(
                      "iframeLoaded.QuadernoBilling",
                      function() {
                        return QuadernoBilling.iframe().toggle({}, !0);
                      }
                    )),
              !1
            );
          });
      }
    }),
    (r = function(e) {
      var t;
      this.url = window.location.protocol + "//" + window.location.host + "/";
      for (t in e) this[t] = e[t];
    }),
    (r.prototype = {
      _host: function() {
        return s + "/list/";
      },
      _attributes: function() {
        var e;
        var t;
        t = {};
        for (e in this)
          this.hasOwnProperty(e) && null !== this[e] && (t[e] = this[e]);
        return t;
      },
      appendIframe: function() {
        var e;
        var t;
        return (
          0 === document.querySelectorAll(".quaderno_billing_app").length
            ? ((e = void 0),
              (t = void 0),
              (t = document.createElement("iframe")),
              t.setAttribute("frameBorder", "0"),
              t.setAttribute("allowtransparency", "true"),
              (e =
                "z-index: 9999;\nbackground: transparent;\nbackground: rgba(0,0,0,0.005);\nvisibility: hidden;\nborder: 0px none transparent;\noverflow-x: hidden;\noverflow-y: auto;\nmargin: 0;\npadding: 0;\n-webkit-tap-highlight-color: transparent;\n-webkit-touch-callout: none;"),
              (e +=
                "position: fixed;\nleft: 0;\ntop: 0;\nwidth: 0%;\nheight: 0%;"),
              (t.style.cssText = e),
              (t.src = this._host() + d.toURIparams(this._attributes())),
              (t.className = t.name = "quaderno_billing_app"),
              document.body.appendChild(t),
              d.bind(t, "load", function() {
                var e;
                var t;
                return (
                  d.iframeHasLoaded(!0),
                  (t = "iframeLoaded.QuadernoBilling"),
                  (e = document.createEvent("Event")),
                  e.initEvent(t, !0, !0),
                  QuadernoBilling.iframe()._el.dispatchEvent(e)
                );
              }))
            : (t = document.querySelector(".quaderno_billing_app")),
          (this._el = t),
          t
        );
      },
      toggle: function(e) {
        var t;
        var i;
        var n;
        var r;
        null == e && (e = !1),
          (t = document.querySelector("body")),
          e || ("0%" === this._el.style.height && d.iframeHasLoaded())
            ? ((t.style.overflow = "hidden"),
              (this._el.style.width = "100%"),
              (this._el.style.height = "100%"),
              (this._el.style.visibility = "visible"),
              (n = document.querySelector(".quaderno-billing-loader")),
              (n.style.display = "none"),
              this._el.contentWindow.postMessage({}, s),
              (r = document.createElement("meta")),
              (r.name = "viewport"),
              (r.content = "width=device-width, initial-scale=1.0"),
              document.getElementsByTagName("head")[0].appendChild(r))
            : ((t.style.overflow = ""),
              (this._el.style.width = "0%"),
              (this._el.style.height = "0%"),
              (this._el.style.visibility = "hidden"),
              (i = document.getElementsByTagName("head")[0]),
              (r = i.lastChild),
              "viewport" === r.name &&
                "width=device-width, initial-scale=1.0" === r.content &&
                i.removeChild(r));
      }
    }),
    (t = function(t) {
      (this.app = new e()),
        (this.currentScriptEl = t || o()),
        (this.apiHost = null),
        (this._key = ""),
        (this._locale = "en"),
        (this._customer = ""),
        (this.callback = {}),
        (this._editable = !0),
        (this._iframe = null),
        (this._button = null),
        (this._loader = null);
    }),
    (t.prototype = {
      app: function() {
        return this.app;
      },
      configure: function(e) {
        var t;
        return (
          (this._key = e.key),
          (this._customer = e.customer_id),
          (this._locale = e.locale || "en"),
          (e.editable === !1 || e.editable === !0) &&
            (this._editable = e.editable),
          (this.apiHost = /\bpk_test/.test(e.key)
            ? "https://sandbox-quadernoapp.com"
            : "https://quadernoapp.com"),
          (this.callback = e.callback),
          0 === document.querySelectorAll(".quaderno-billing-loader").length &&
            ((this._loader = document.createElement("div")),
            (this._loader.className = "quaderno-billing-loader"),
            (this._loader.style.cssText =
              "position:fixed;left:0;top:0;width:100%;height:100%;overflow-x:hidden;overflow-y:auto;z-index:9999;background:rgba(0,0,0,0.6);"),
            (this._loader.style.display = "none"),
            document.body.appendChild(this._loader),
            (t = document.createElement("img")),
            (t.src = s + "/loading.gif"),
            (t.style.cssText =
              "margin:auto;position:absolute;top:0;left:0;bottom:0;right:0;box-shadow:0 0 25px 0 rgba(51,51,51,0.8)"),
            this._loader.appendChild(t)),
          this
        );
      },
      currentScript: function(e) {
        return void 0 !== e && (this.currentScriptEl = e), this.currentScriptEl;
      },
      iframe: function() {
        return this._iframe;
      },
      key: function() {
        return this._key;
      },
      open: function(e) {
        null === this._iframe &&
          ((this._iframe = new r({
            key: this._key,
            locale: this._locale,
            customer_id: this._customer,
            editable: this._editable
          })),
          this._iframe.appendIframe()),
          d.iframeHasLoaded()
            ? this._iframe.toggle()
            : ((document.querySelector(
                ".quaderno-billing-loader"
              ).style.display = "block"),
              this._iframe._el.addEventListener(
                "iframeLoaded.QuadernoBilling",
                function() {
                  return QuadernoBilling._iframe.toggle();
                }
              ));
      },
      close: function() {
        this._iframe instanceof r &&
          (this._iframe._el.remove(),
          (this._iframe = null),
          d.iframeHasLoaded(!1));
      },
      initApp: function() {
        var e;
        return (
          (e = {
            key: this._key,
            customer_id: this._customer,
            locale: d.scriptData("locale") || "en",
            editable: d.scriptData("editable") || "true"
          }),
          (this._iframe = new r(e)),
          this._iframe.appendIframe(),
          (this._button = new i(d.scriptData("label"))),
          this._button.render()
        );
      }
    }),
    void 0 === this.QuadernoBilling &&
      ((this.QuadernoBilling = new t()),
      window.addEventListener("message", function(e) {
        if (e.origin === s)
          switch (e.data.type) {
            case "billing.closed":
              "hidden" !==
                document.querySelector("iframe.quaderno_billing_app").style
                  .visibility && QuadernoBilling.iframe().toggle();
              break;
            case "billing.error":
              l("error.QuadernoBilling", e.data);
          }
      })),
    this.QuadernoBilling.currentScript(o()),
    (d = this.QuadernoBilling.app),
    document.addEventListener("DOMContentLoaded", function(e) {
      document.querySelectorAll(".quaderno-billing-button").length > 0 &&
        (QuadernoBilling.configure({
          key: d.scriptData("key"),
          customer_id: d.scriptData("customer-id"),
          callback: function(e) {
            return console.log(e.details);
          }
        }),
        QuadernoBilling.initApp());
    });
}.call(window));
