import PropTypes from "prop-types";
import { createElement } from "react";

import { emojiMartConfig } from "@feedback/helpers/getEmojiMartConfig";
import { init, SearchIndex } from "emoji-mart";

import { Text, Tooltip } from "@shared/UIKit";

import emojiRegex from "./emojiRegex";
import HighlightAndLinkify from "./HighlightAndLinkify/HighlightAndLinkify";

init({ ...emojiMartConfig() });
export default function EmojiTooltipsHighlightAndLinkify({
  searchTerms,
  highlightProps,
  children,
}) {
  const getEmoji = (searchInput) => SearchIndex.get(searchInput);

  const simpleEmojiRegex = /\p{Extended_Pictographic}\s?/gu;

  const bodyHasAtLeastOneEmoji = simpleEmojiRegex.test(children);

  let parts = [];

  if (bodyHasAtLeastOneEmoji) {
    const extractEmojisRegex = new RegExp(`(${emojiRegex})`, "g");
    const splitTest = children.split(extractEmojisRegex);

    parts = splitTest.map((part, index) => {
      if (simpleEmojiRegex.test(part)) {
        const matchElementChildren = <Text component="span">{part}</Text>;
        const emoji = getEmoji(part);

        if (emoji) {
          return createElement(
            Tooltip,
            {
              key: index,
              title: emoji.skins[0].shortcodes,
              enterDelay: 500,
              placement: "top",
            },
            matchElementChildren
          );
        }
        return createElement(
          HighlightAndLinkify,
          {
            searchTerms,
            highlightProps,
            key: index,
          },
          part
        );
      }
      return createElement(
        HighlightAndLinkify,
        {
          searchTerms,
          highlightProps,
          key: index,
        },
        part
      );
    });
    return parts;
  }

  return createElement(
    HighlightAndLinkify,
    {
      searchTerms,
      highlightProps,
    },
    children
  );
}

EmojiTooltipsHighlightAndLinkify.propTypes = {
  children: PropTypes.node.isRequired,
  highlightProps: PropTypes.shape({
    highlightClass: PropTypes.string,
    caseSensitive: PropTypes.bool,
  }),
  searchTerms: PropTypes.string,
};

EmojiTooltipsHighlightAndLinkify.defaultProps = {
  searchTerms: "",
  highlightProps: {
    highlightClass: "highlight",
    caseSensitive: false,
  },
};
