import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  Button,
  CardContent,
  CardActions,
} from "@mui/material";

import { Text, Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

import DeleteTeamDialog from "./DeleteTeamDialog";
import TeamNotDeletableDialog from "./TeamNotDeletableDialog";

function DeleteTeamCard({ team }) {
  const { t } = useTranslation();

  const [isNotDeletableDialogOpen, setIsNotDeletableDialogOpen] =
    useState(false);
  const [isDeleteTeamDialogOpen, setDeleteTeamDialogOpen] = useState(false);

  function openCannotDeleteDialog() {
    setIsNotDeletableDialogOpen(true);
  }

  function closeCannotDeleteDialog() {
    setIsNotDeletableDialogOpen(false);
  }

  function openDialogDeleteTeam() {
    setDeleteTeamDialogOpen(true);
  }

  function closeDeleteTeamDialog() {
    setDeleteTeamDialogOpen(false);
  }

  const deleteTeam = function () {
    if (!team.subscription.isPaying) {
      if (!team.permissions.canManageMembers) {
        openCannotDeleteDialog();
        return;
      }
      openDialogDeleteTeam();
      return;
    }
    openCannotDeleteDialog();
  };

  return (
    <>
      <Card>
        <CardHeader title={t("TEAM.SETTINGS.DELETE_TEAM.TITLE")} />
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Text
            color="text.secondary"
            translate="TEAM.SETTINGS.DELETE_TEAM.DESCRIPTION-1"
          />
          <Text
            color="text.secondary"
            translate="TEAM.SETTINGS.DELETE_TEAM.DESCRIPTION-2"
          />
          <Text
            color="text.secondary"
            translate="TEAM.SETTINGS.DELETE_TEAM.DESCRIPTION-3"
          />
        </CardContent>
        <CardActions>
          <Box p={2} mb={1} ml="auto">
            <Button
              color="error"
              variant="text"
              onClick={deleteTeam}
              data-testid="team-setting-delete"
              aria-label={t("SETTINGS.PROFILE.SUBMIT")}
              disableRipple
              size="large"
            >
              {t("TEAM.SETTINGS.DELETE_TEAM.BUTTON")}
            </Button>
          </Box>
        </CardActions>
      </Card>
      <TeamNotDeletableDialog
        isOpen={isNotDeletableDialogOpen}
        closeDialog={closeCannotDeleteDialog}
        canManageMembers={team.permissions.canManageMembers}
      />
      <DeleteTeamDialog
        isOpen={isDeleteTeamDialogOpen}
        closeDialog={closeDeleteTeamDialog}
        team={team}
      />
    </>
  );
}

DeleteTeamCard.propTypes = {
  team: teamProp.isRequired,
};

export default DeleteTeamCard;
