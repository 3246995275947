import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text, Tooltip } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles({
  sectionName: {
    transform: "rotate(-90deg)",
    minWidth: 128,
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
  },
});

const OldSectionItem = ({ name, isFirst }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box
      bgcolor={theme.color.white}
      minWidth={28}
      width={28}
      height={140}
      p={0.5}
      marginLeft={isFirst ? 0 : 1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      position="sticky"
      left={0}
      zIndex={10}
      boxShadow="2px 0px 6px 0 rgb(0 0 0 / 5%)"
      data-testid="section-name-container"
    >
      <Tooltip title={name}>
        <Text
          className={classes.sectionName}
          textAlign="center"
          fontSize={FSTGTypography.fontSize_1_2}
          lineHeight={FSTGTypography.lineHeight_1_2}
        >
          {name}
        </Text>
      </Tooltip>
    </Box>
  );
};

OldSectionItem.propTypes = {
  name: PropTypes.string.isRequired,
  isFirst: PropTypes.bool.isRequired,
};
export default OldSectionItem;
