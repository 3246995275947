import { useState } from "react";

import { GroupAdd } from "@mui/icons-material";
import { IconButton, Box } from "@mui/material";

import CreateTeamDialog from "./CreateTeamDialog";

export default function CreateTeamButton() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box
      position="absolute"
      top={0}
      right={0}
      sx={{
        display: { sm: "none", md: "block" },
        opacity: 0,
        "&:hover": {
          opacity: 0.3,
        },
      }}
    >
      <IconButton
        data-testid="create-team-button"
        onClick={openDialog}
        size="large"
      >
        <GroupAdd />
      </IconButton>
      <CreateTeamDialog close={closeDialog} isOpen={isDialogOpen} />
    </Box>
  );
}
