import { memo } from "react";

import { Lock } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  lockIcon: {
    position: "absolute",
    right: 20,
    top: 22,
    color: theme.color.blue[500],
    fontSize: FSTGTypography.fontSize_2_6,
  },
}));

function LockedFileState() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      data-testid="file-locked-state"
      bgcolor={theme.color["white-translucent-85"]}
      width="100%"
      position="absolute"
      height="100%"
      overflow="hidden"
      zIndex={1}
      top={0}
      right={0}
    >
      <SvgIcon component={Lock} className={classes.lockIcon} />
    </Box>
  );
}

export default memo(LockedFileState);
