import PropTypes from "prop-types";

import { userProp } from "./authentication";
import { fileProp } from "./file";

export const stepProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  createdTime: PropTypes.string,
  reviewers: PropTypes.arrayOf(userProp),
  settings: PropTypes.shape({
    downloadInReview: PropTypes.bool,
    downloadApproved: PropTypes.bool,
    commentInReview: PropTypes.bool,
    commentApproved: PropTypes.bool,
    emailNotifications: PropTypes.bool,
    passwordProtection: PropTypes.bool,
    uploadingEnabled: PropTypes.bool,
    anonymousReviewers: PropTypes.bool,
  }),
  files: PropTypes.arrayOf(fileProp),
  shouldAskForPassword: PropTypes.bool,
  permissions: PropTypes.shape({
    access: PropTypes.bool,
    uploadFiles: PropTypes.bool,
    viewOnDashboard: PropTypes.bool,
    areReviewersAnonymous: PropTypes.bool,
  }),
});
