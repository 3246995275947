/* eslint max-lines: off */
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import reject from "lodash/reject";
import remove from "lodash/remove";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as websocket } from "@shared/services/websocket";

const foldersCache = new Map();
const projectCache = new Map();
let favoriteProjectCache = [];

function initialize() {
  clearCache();

  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);

  eventService.addListener(
    EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
    (event) => {
      const { collapsed, teamId } = event.eventData;
      const folders = foldersCache.get(teamId);
      if (folders) {
        updateFoldersCache(
          teamId,
          folders.map((folder) => ({ ...folder, isCollapsed: collapsed }))
        );
      }
    }
  );

  eventService.addListener(EVENT_NAMES.PROJECT.CACHE.UPDATE, (event) =>
    updateProjectInCache(event.eventData.project)
  );

  websocket.addListener(websocket.EVENT_NAMES.PROJECT.CREATED, onProjectAdd);

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.ACCESS.GAINED,
    onProjectAdd
  );

  websocket.addListener(websocket.EVENT_NAMES.PROJECT.UPDATED, async (data) => {
    const project = await backend.get(`/projects/${data.projectId}`);
    updateProjectInCache(project);
  });

  websocket.addListener(websocket.EVENT_NAMES.PROJECT.REMOVED, onProjectRemove);

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.ACCESS.LOST,
    onProjectRemove
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.COLLABORATOR.ADDED,
    onCollaboratorAdded
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.COLLABORATOR.REMOVED,
    onCollaboratorRemoved
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.SECTION.CREATED,
    (event) => onSectionEventHandler(event, EVENT_NAMES.PROJECT.SECTION.CREATED)
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.SECTION.REMOVED,
    (event) => onSectionEventHandler(event, EVENT_NAMES.PROJECT.SECTION.REMOVED)
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.SECTION.RENAMED,
    (event) => onSectionEventHandler(event, EVENT_NAMES.PROJECT.SECTION.RENAMED)
  );

  websocket.addListener(websocket.EVENT_NAMES.PROJECT.SECTION.MOVED, (event) =>
    onSectionEventHandler(event, EVENT_NAMES.PROJECT.SECTION.MOVED)
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.SECTION.COLLAPSED,
    (event) =>
      onSectionEventHandler(
        event,
        EVENT_NAMES.PROJECT.SECTION.COLLAPSED,
        ({ isCollapsed }) => ({ isCollapsed })
      )
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.AUTOMATION.CREATED,
    onProjectUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.AUTOMATION.REMOVED,
    onProjectUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.AUTOMATION.UPDATED,
    onProjectUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.AUTOMATION.PAUSED,
    onProjectUpdated
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.AUTOMATION.UNPAUSED,
    onProjectUpdated
  );

  websocket.addListener(websocket.EVENT_NAMES.FOLDER.CREATED, async (data) => {
    const newFolder = data.folder;
    const folders = await fetchProjectsByTeamId(newFolder.teamId);
    const foundFolder = folders.find(
      (cachedFolder) => cachedFolder.id === newFolder.id
    );

    if (!foundFolder) {
      newFolder.isNew = true;
      const currentFolders = foldersCache.get(newFolder.teamId);
      updateFoldersCache(newFolder.teamId, [...currentFolders, newFolder]);
    }
  });

  websocket.addListener(websocket.EVENT_NAMES.FOLDER.UPDATED, ({ folder }) => {
    if (foldersCache.has(folder.teamId)) {
      updateFoldersCache(
        folder.teamId,
        foldersCache
          .get(folder.teamId)
          .map((fol) => (fol.id === folder.id ? { ...fol, ...folder } : fol))
      );
    }
  });

  websocket.addListener(websocket.EVENT_NAMES.FOLDER.REMOVED, ({ folder }) => {
    if (foldersCache.has(folder.teamId)) {
      const folders = foldersCache.get(folder.teamId);
      remove(folders, { id: folder.id });
      updateFoldersCache(folder.teamId, folders);
    }
  });

  websocket.addListener(
    websocket.EVENT_NAMES.FOLDER.PROJECT.MOVED,
    async ({ teamId, projectId, sourceFolderId, targetFolderId }) => {
      if (foldersCache.has(teamId)) {
        const project = await backend.get(`/projects/${projectId}`);
        updateFoldersCache(
          teamId,
          changeProjectFolder(project, sourceFolderId, targetFolderId, [
            ...foldersCache.get(teamId),
          ])
        );
      }
    }
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT_CLONE.SUCCEEDED,
    ({ projectId }) => {
      setCloneProjectStatus({ status: "succeeded", projectId });
    }
  );

  websocket.addListener(websocket.EVENT_NAMES.PROJECT_CLONE.FAILED, () => {
    setCloneProjectStatus({ status: "failed", projectId: "" });
  });

  websocket.addListener(
    websocket.EVENT_NAMES.FAVORITE_PROJECTS.UPDATED,
    fetchFavoriteProjectsAndUpdateCache
  );

  websocket.addListener(
    websocket.EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
    ({ collapsed, teamId }) => {
      const folders = foldersCache.get(teamId);
      if (folders) {
        updateFoldersCache(
          teamId,
          folders.map((folder) => ({ ...folder, isCollapsed: collapsed }))
        );
      }

      const user = authenticationService.fetchUser();
      const newUser = {
        ...user,
        settings: { ...user.settings, collapsedFavoriteProjects: collapsed },
      };

      eventService.emitEvent({
        eventName: EVENT_NAMES.USER.UPDATED,
        eventData: { user: newUser },
      });
    }
  );

  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.SECTIONS.COLLAPSED,
    (event) =>
      onSectionEventHandler(
        event,
        EVENT_NAMES.PROJECT.SECTIONS.COLLAPSED,
        ({ isCollapsed }) => ({ isCollapsed })
      )
  );
}

function clearCache() {
  foldersCache.clear();
  projectCache.clear();
  favoriteProjectCache = [];
}

async function fetchFavoriteProjects() {
  return favoriteProjectCache.length > 0
    ? favoriteProjectCache
    : await fetchFavoriteProjectsAndUpdateCache();
}

async function fetchFavoriteProjectsAndUpdateCache() {
  const response = await backend.get(`/projects/favorite-projects`);
  return updateFavoriteProjectCache([
    ...response.projects,
    ...response.externalProjects.map((project) => ({
      ...project,
      isExternal: true,
    })),
  ]);
}

function updateProjectInFavoriteCache(project) {
  const index = favoriteProjectCache.findIndex(
    (proj) => proj.id === project.id
  );
  if (index >= 0) {
    favoriteProjectCache[index] = project;
    updateFavoriteProjectCache(favoriteProjectCache);
  }
}

function removeProjectFromFavoriteCache(projectId) {
  if (
    favoriteProjectCache.some(
      (favoriteProject) => favoriteProject.id === projectId
    )
  ) {
    updateFavoriteProjectCache(
      favoriteProjectCache.filter(
        (favoriteProject) => favoriteProject.id !== projectId
      )
    );
  }
}

function updateFavoriteProjectCache(projects) {
  favoriteProjectCache = projects;
  eventService.emitEvent({
    eventName: EVENT_NAMES.FAVORITE_PROJECTS.UPDATED,
    eventData: { projects },
  });
  return favoriteProjectCache;
}

async function onProjectAdd({ projectId }) {
  const project = await backend.get(`/projects/${projectId}`);
  addProjectToCache(project);
}

function onProjectRemove({ projectId }) {
  const project = fetchProjectByIdFromCache(projectId);
  if (project) {
    removeProjectFromCache(project);
  }
}

function getCloneProjectStatus() {
  const cloneProjectStatus = sessionStorage.getItem("clone-project-status");
  return JSON.parse(cloneProjectStatus);
}

function setCloneProjectStatus(cloneProjectStatus) {
  sessionStorage.setItem(
    "clone-project-status",
    JSON.stringify(cloneProjectStatus)
  );
  // TODO: Move this once Segment to Userguiding integration issue fixed
  if (cloneProjectStatus.status === "succeeded") {
    const user = authenticationService.fetchUser();
    analytics.identify(user._id, {
      demoProjectId: cloneProjectStatus.projectId,
    });
  }
}

async function onCollaboratorAdded(data) {
  const foundProject = fetchProjectByIdFromCache(data.projectId);
  const currentUser = authenticationService.fetchUser();
  const isCurrentUserAffected = data.collaboratorIds.includes(currentUser._id);
  let project = await backend.get(`/projects/${data.projectId}`);
  project = foundProject
    ? updateProjectInCache(project)
    : addProjectToCache(project);

  /* istanbul ignore next */
  if (foundProject && isCurrentUserAffected) {
    eventService.emitEvent({
      eventName: EVENT_NAMES.PROJECT.COLLABORATOR.ADDED,
      eventData: {
        project,
        collaboratorIds: data.collaboratorIds,
      },
    });
  }
}

async function onCollaboratorRemoved(data) {
  const fetchedProject = await backend.get(`/projects/${data.projectId}`);

  updateProjectInCache(fetchedProject);
  const currentUser = authenticationService.fetchUser();
  const isCurrentUserAffected = currentUser._id === data.collaboratorId;

  if (isCurrentUserAffected) {
    eventService.emitEvent({
      eventName: EVENT_NAMES.PROJECT.COLLABORATOR.REMOVED,
      eventData: {
        project: fetchedProject,
        collaboratorId: data.collaboratorId,
      },
    });
  }
}

async function onSectionEventHandler(websocketEvent, eventName, callback) {
  if (projectCache.has(websocketEvent.projectId)) {
    const project = await backend.get(`/projects/${websocketEvent.projectId}`);
    updateProjectInCache(project);
    eventService.emitEvent({
      eventName,
      eventData: {
        project,
        sectionId: websocketEvent.sectionId,
        ...(callback ? callback(websocketEvent) : {}),
      },
    });
  }
}

async function onProjectUpdated({ projectId }) {
  const project = await backend.get(`/projects/${projectId}`);
  updateProjectInCache(project);
}

function updateProjectInCache(updatedProject) {
  updateProjectInFavoriteCache(updatedProject);

  projectCache.set(updatedProject.id, updatedProject);
  if (foldersCache.has(updatedProject.teamId)) {
    updateFoldersCache(
      updatedProject.teamId,
      updateProjectInTeamFolders(
        foldersCache.get(updatedProject.teamId),
        updatedProject
      )
    );
  }
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.UPDATED,
    eventData: {
      project: updatedProject,
    },
  });
  return updatedProject;
}

function addProjectToCache(newProject) {
  projectCache.set(newProject.id, newProject);
  if (foldersCache.has(newProject.teamId)) {
    updateFoldersCache(
      newProject.teamId,
      addProjectToTeamFolders(foldersCache.get(newProject.teamId), newProject)
    );

    eventService.emitEvent({
      eventName: EVENT_NAMES.PROJECT.ADDED,
      eventData: { project: newProject },
    });
  }
  return newProject;
}

function removeProjectFromCache(project) {
  removeProjectFromFavoriteCache(project.id);

  projectCache.delete(project.id);

  const folders = foldersCache.get(project.teamId);
  if (folders) {
    const folder = folders.find((folder) => folder.id === project.folderId);
    if (folder) {
      remove(folder.projects, { id: project.id });
      updateFoldersCache(project.teamId, folders);
    }
  }

  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.REMOVED,
    eventData: { project },
  });
}

function updateProjectInTeamFolders(folders, updatedProject) {
  const folder = getOrAddTeamFolder(
    folders,
    updatedProject.teamId,
    updatedProject.folderId
  );

  const projectIndex = findIndex(folder.projects, {
    id: updatedProject.id,
  });

  folder.projects[projectIndex] = updatedProject;

  return folders;
}

function addProjectToTeamFolders(folders, newProject) {
  const folder = getOrAddTeamFolder(
    folders,
    newProject.teamId,
    newProject.folderId
  );

  const existingProject = find(folder.projects, { id: newProject.id });
  if (!existingProject) {
    folder.projects = [...folder.projects, newProject];
  }

  return folders;
}

function getOrAddTeamFolder(folders, teamId, folderId) {
  let folder = folders.find((folder) => folder.id === folderId);
  if (!folder) {
    folder = {
      id: folderId,
      name: folderId,
      projects: [],
      teamId,
    };
    folders.push(folder);
  }
  return folder;
}

function changeProjectFolder(project, sourceFolderId, targetFolderId, folders) {
  const sourceFolder = find(folders, { id: sourceFolderId });
  const targetFolder = find(folders, { id: targetFolderId });

  sourceFolder.projects = reject(sourceFolder.projects, {
    id: project.id,
  });

  const projectExists = targetFolder.projects.find(
    (targetProjects) => targetProjects.id === project.id
  );

  if (!projectExists) {
    targetFolder.projects.push(project);
  }

  return folders;
}

async function fetchByTeamId(teamId) {
  const folders = await backend.get("/projects", { team_id: teamId });
  updateFoldersCache(teamId, folders);
  folders.forEach((folder) => {
    folder.projects.forEach((project) => projectCache.set(project.id, project));
  });
  return folders;
}

async function fetchProjectsByTeamId(teamId) {
  return foldersCache.get(teamId) || (await fetchByTeamId(teamId));
}

async function fetchProjectById(projectId, refreshCache = false) {
  if (projectCache.has(projectId) && !refreshCache) {
    return projectCache.get(projectId);
  }
  const project = await backend.get(`/projects/${projectId}`);
  projectCache.set(project.id, project);
  return project;
}

async function updateProjectName(projectId, updatedName) {
  const project = await backend.put(`/projects/${projectId}/name`, {
    name: updatedName,
  });
  updateProjectInCache(project);
  return project;
}

async function archiveProject(projectId) {
  const updatedProject = await backend.put(`/projects/${projectId}/archive`);
  await teamService.updateUsedBillingLimitsOfSelectedTeam();

  analytics.track(analytics.ACTION.ARCHIVED, analytics.CATEGORY.PROJECT);

  return updateProjectInCache(updatedProject);
}

function fetchProjectByIdFromCache(projectId) {
  return projectCache.get(projectId);
}

async function favoriteProject(projectId, isFavorite) {
  const project = await backend.post(`/projects/${projectId}/isFavorite`, {
    isFavorite,
  });

  updateProjectInCache(project);
  await fetchFavoriteProjectsAndUpdateCache();

  analytics.track(
    isFavorite ? analytics.ACTION.ADDED_TO : analytics.ACTION.REMOVED_FROM,
    analytics.CATEGORY.PROJECT_FAVORITES,
    {
      projectId,
    }
  );

  return project;
}

async function unarchiveProject(project) {
  const projectTeam = await teamService.fetchTeamByTeamId(project.teamId);

  const maxProjectNumber = projectTeam.subscriptionLimits.maxProjectNumber;
  const numberOfActiveProjects =
    projectTeam.usedBillingLimits.numberOfActiveProjects;

  if (maxProjectNumber !== -1 && numberOfActiveProjects >= maxProjectNumber) {
    analytics.track(
      analytics.ACTION.REACHED,
      analytics.CATEGORY.SUBSCRIPTION_LIMIT,
      {},
      {},
      analytics.STATE.PROJECT_QUANTITY
    );

    return false;
  }

  const updatedProject = await backend.put(`/projects/${project.id}/unarchive`);
  teamService.updateUsedBillingLimitsOfSelectedTeam();

  analytics.track(analytics.ACTION.UNARCHIVED, analytics.CATEGORY.PROJECT);

  return updateProjectInCache(updatedProject);
}

async function joinProject(projectId) {
  const project = await backend.post(`/projects/${projectId}/join`);
  updateProjectInCache(project);
  return project;
}

async function addCollaborators(
  projectId,
  emails,
  message = "",
  notifyEmail = false
) {
  const project = await backend.post(
    `/projects/${projectId}/collaborators/add`,
    {
      emails,
      message,
      notifyEmail,
    }
  );
  emails.forEach((email) => {
    analytics.track(analytics.ACTION.INVITED, analytics.CATEGORY.COLLABORATOR, {
      addedPersonalMessage: Boolean(message && message !== ""),
      invitedEmail: email,
      ...(notifyEmail
        ? {
            emailNotificationEnabled: true,
          }
        : {
            emailNotificationDisabled: true,
          }),
    });
  });

  updateProjectInCache(project);
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.COLLABORATOR.ADDED,
    eventData: { project },
  });
  return project;
}

async function removeCollaborator(projectId, collaboratorId) {
  const project = await backend.delete(
    `/projects/${projectId}/collaborators/${collaboratorId}`
  );
  analytics.track(analytics.ACTION.REMOVED, analytics.CATEGORY.COLLABORATOR);

  if (project) {
    updateProjectInCache(project);
  } else {
    const cachedProject = projectCache.get(projectId);
    if (cachedProject) {
      removeProjectFromCache(cachedProject);
    }
  }
}

async function addProject(projectData) {
  healthMetrics.trackStart("workflow.create-project");

  try {
    const resProject = await backend.post("/projects", projectData);
    teamService.updateUsedBillingLimitsOfSelectedTeam();
    addProjectToCache(resProject);
    healthMetrics.trackSuccess("workflow.create-project");
    return resProject;
  } catch (error) {
    healthMetrics.trackFailure("workflow.create-project", error);
    throw error;
  }
}

async function removeProject(project) {
  await backend.delete(`/projects/${project.id}`);
  teamService.updateUsedBillingLimitsOfSelectedTeam();
  removeProjectFromCache(project);
  return true;
}

async function addFolder(folderData) {
  const resFolder = await backend.post("/folders", folderData);
  resFolder.isNew = true;
  const currentFolders = foldersCache.get(folderData.teamId);
  updateFoldersCache(folderData.teamId, [...currentFolders, resFolder]);
  analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.FOLDER);
  eventService.emitEvent({
    eventName: EVENT_NAMES.FOLDERS.CREATED,
    eventData: { folder: resFolder },
  });
  return resFolder;
}

async function removeFolder(folder) {
  try {
    await backend.delete(`/folders/${folder.id}`);

    const folders = foldersCache.get(folder.teamId);
    remove(folders, { id: folder.id });
    updateFoldersCache(folder.teamId, folders);

    analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.FOLDER);
    toastService.sendToast({
      title: "PROJECT.FOLDER.SUCCESS.DELETE.TITLE",
      body: "PROJECT.FOLDER.SUCCESS.DELETE.BODY",
      preset: toastService.PRESETS().SUCCESS,
    });
    return true;
  } catch (err) {
    if (err.status === 403) {
      toastService.sendToast({
        title: "PROJECT.FOLDER.ERROR.DELETE.ONLY_EMPTY.TITLE",
        body: "PROJECT.FOLDER.ERROR.DELETE.ONLY_EMPTY.BODY",
        preset: toastService.PRESETS().WARNING,
      });
    } else {
      errorHandlerService.handleError(err);
    }
    return false;
  }
}

async function moveProjectToFolder(project, targetFolderId) {
  const updatedProject = await backend.put(`/projects/${project.id}/folderId`, {
    folderId: targetFolderId,
  });
  updateFoldersCache(
    project.teamId,
    changeProjectFolder(
      project,
      project.folderId,
      targetFolderId,
      foldersCache.get(project.teamId)
    )
  );
  updateProjectInCache(updatedProject);
  analytics.track(analytics.ACTION.MOVED_PROJECT, analytics.CATEGORY.FOLDER);
  return updatedProject;
}

async function updateFolderName(folderId, name) {
  const resFolder = await backend.put(`/folders/${folderId}/name`, { name });
  updateFoldersCache(
    resFolder.teamId,
    foldersCache
      .get(resFolder.teamId)
      .map((folder) =>
        folder.id === folderId ? { ...folder, ...resFolder } : folder
      )
  );
  analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.FOLDER);
  return resFolder;
}

function fetchTriggers(teamId) {
  return backend.get("/automations/triggers", { teamId });
}

async function createAutomation(projectId, { triggerId, conditions, actions }) {
  const project = await fetchProjectById(projectId);
  const automations = await backend.post(`/projects/${projectId}/automations`, {
    triggerId,
    conditions,
    actions,
  });

  return updateProjectInCache({ ...project, automations });
}

async function removeAutomation(projectId, automationId) {
  await backend.delete(`/projects/${projectId}/automations/${automationId}`);

  const project = await fetchProjectById(projectId, true);
  return updateProjectInCache(project);
}

async function editAutomation(
  projectId,
  { automationId, triggerId, conditions, actions }
) {
  const project = fetchProjectByIdFromCache(projectId);
  const automations = await backend.put(
    `/projects/${projectId}/automations/${automationId}`,
    {
      triggerId,
      conditions,
      actions,
    }
  );

  return updateProjectInCache({ ...project, automations });
}

async function enableProjectAutomations(projectId, automationId, enabled) {
  const project = fetchProjectByIdFromCache(projectId);
  const updatedAutomation = await backend.put(
    `/projects/${projectId}/automations/${automationId}/enabled`,
    {
      enabled,
    }
  );
  const updatedAutomations = project.automations.map((automation) =>
    automation.automationId === updatedAutomation.automationId
      ? updatedAutomation
      : automation
  );

  updateProjectInCache({
    ...project,
    automations: updatedAutomations,
  });
}

function updateFoldersCache(teamId, folders) {
  foldersCache.set(teamId, folders);
  eventService.emitEvent({
    eventName: EVENT_NAMES.FOLDERS.UPDATED,
    eventData: { teamId, folders },
  });
}

async function bulkDownloadProjectFiles({
  projectId,
  includedFileIds,
  excludedFileIds,
  allFiles,
}) {
  try {
    await backend.post(`/projects/${projectId}/bulk-download`, {
      includedFileIds,
      excludedFileIds,
      allFiles,
    });
    toastService.sendToast({
      title: "BULK_DOWNLOAD.SUCCESS_TOAST.TITLE",
      body: "BULK_DOWNLOAD.SUCCESS_TOAST.BODY",
      preset: toastService.PRESETS().SUCCESS,
    });
    return true;
  } catch {
    toastService.sendToast({
      title: "BULK_DOWNLOAD.ERROR_TOAST.TITLE",
      body: "BULK_DOWNLOAD.ERROR_TOAST.BODY",
      preset: toastService.PRESETS().WARNING,
    });
  }
}

function hasAutomationsByProjectIdStepId(id, stepId) {
  const project = fetchProjectByIdFromCache(id);
  return (
    project &&
    project.automations.some(
      (automation) => automation.conditions.step === stepId
    )
  );
}

async function updateFolderCollapsed(folderId, teamId) {
  const folders = foldersCache.get(teamId);
  const item = folders.find((folder) => folder.id === folderId);

  const response = await backend.put(`/folders/${folderId}/isCollapsed`, {
    isCollapsed: !item.isCollapsed,
  });

  updateFoldersCache(
    teamId,
    foldersCache
      .get(teamId)
      .map((folder) =>
        folder.id === folderId ? { ...item, ...response } : folder
      )
  );
}

export default {
  initialize,
  fetchProjectsByTeamId,
  fetchProjectById,
  updateProjectName,
  archiveProject,
  fetchProjectByIdFromCache,
  unarchiveProject,
  joinProject,
  addCollaborators,
  removeCollaborator,
  addProject,
  removeProject,
  addFolder,
  removeFolder,
  moveProjectToFolder,
  updateFolderName,
  fetchTriggers,
  favoriteProject,
  createAutomation,
  removeAutomation,
  editAutomation,
  fetchFavoriteProjects,
  enableProjectAutomations,
  bulkDownloadProjectFiles,
  hasAutomationsByProjectIdStepId,
  updateFolderCollapsed,
  getCloneProjectStatus,
  setCloneProjectStatus,
};
