import PropTypes from "prop-types";
import { ComponentProps, ReactElement } from "react";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@mui/material";

/**
 * UIKit Toggle Component
 * {@link https://www.figma.com/file/prD5GYdqZVr5XEV3UrzsWd/2.0-Bubble-Design-System?type=design&node-id=393-26054&mode=dev Figma}
 * @description A toggle component that can be used to switch between two states.
 * This component extends {@link https://mui.com/material-ui/react-switch/ Material UI Switch} component.
 * @param {ComponentProps} props - Component props
 * @param {string?} props.label [Optional] - The label of the toggle.
 * @param {string?} props.helpText [Optional] - The help text of the toggle.
 * @param {{
 *  switch: import("@mui/material/Switch").SwitchProps,
 *  formControlLabel: import("@mui/material/FormControlLabel").FormControlLabelProps
 * }} props.slotProps [Optional] - props passed to teh underlying MUI components
 * @param {string} props.size [Optional] - The size of the toggle.
 * @returns {ReactElement} - React component
 */
const Toggle = ({ label, helpText, slotProps, size }) => {
  if (label) {
    const { switch: switchProps, formControlLabel: formControlLabelProps } =
      slotProps;

    const { typography: typographyProps } =
      formControlLabelProps?.slotProps || {};
    return (
      <FormControl>
        <FormControlLabel
          control={<Switch color="primary" size={size} {...switchProps} />}
          label={label}
          slotProps={{
            typography: {
              fontWeight: "fontWeight.medium",
              fontSize: `${size === "medium" ? "textMd" : "textSm"}.fontSize`,
              ...typographyProps,
            },
          }}
          {...formControlLabelProps}
        />
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
  return <Switch color="primary" {...slotProps?.switch} />;
};

Toggle.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  slotProps: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    switch: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    formControlLabel: PropTypes.object,
  }),
  size: PropTypes.string,
};

Toggle.defaultProps = {
  label: null,
  helpText: null,
  slotProps: {},
  size: "medium",
};

export default Toggle;
