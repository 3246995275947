import PropTypes from "prop-types";
import { useState } from "react";

import { Menu } from "@mui/material";

import { useMediaQuery } from "@shared/hooks";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import ReviewCardDeleteMenuItem from "./ReviewCardDeleteMenuItem/ReviewCardDeleteMenuItem";
import ReviewCardExportFileReportMenu from "./ReviewCardExportFileReportMenu/ReviewCardExportFileReportMenu";
import ReviewCardMenuButton from "./ReviewCardMenuButton/ReviewCardMenuButton";
import ReviewCardShareLinkMenuItem from "./ReviewCardShareLinkMenuItem/ReviewCardShareLinkMenuItem";

function ReviewCardMenu({
  className,
  team,
  project,
  step,
  file,
  version,
  review,
  ...restProps
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isDeleteAllowed = review.permissions.canDelete && !project.isArchived;

  const openMenu = ({ currentTarget }) => setAnchorEl(currentTarget);

  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <ReviewCardMenuButton openMenu={openMenu} className={className} />

      {anchorEl && (
        <Menu
          id="review-card-menu"
          data-testid="review-card-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          {...restProps}
        >
          {isMobile && (
            <ReviewCardShareLinkMenuItem
              step={step}
              file={file}
              review={review}
              closeMenu={closeMenu}
            />
          )}

          <ReviewCardExportFileReportMenu
            team={team}
            step={step}
            file={file}
            version={version}
            review={review}
            closeMenu={closeMenu}
          />

          {isDeleteAllowed && (
            <ReviewCardDeleteMenuItem
              step={step}
              version={version}
              review={review}
              closeMenu={closeMenu}
            />
          )}
        </Menu>
      )}
    </>
  );
}

ReviewCardMenu.propTypes = {
  team: teamProp.isRequired,
  step: stepProps.isRequired,
  file: fileProp.isRequired,
  version: versionProp.isRequired,
  review: reviewProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  className: PropTypes.string,
};

ReviewCardMenu.defaultProps = {
  className: null,
};

export default ReviewCardMenu;
