import { useCallback, useState } from "react";

import noop from "lodash/noop";

function useDialogState(onOpenDialog = noop) {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = useCallback(
    (event) => {
      setIsOpen(true);
      onOpenDialog(event);
    },
    [onOpenDialog]
  );

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { isOpen, openDialog, closeDialog };
}

export default useDialogState;
