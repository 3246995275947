import {
  CloudWatchClient,
  PutMetricDataCommand,
} from "@aws-sdk/client-cloudwatch";
import isNil from "lodash/isNil";
import isNumber from "lodash/isNumber";
import throttle from "lodash/throttle";

import { instance as logger } from "@shared/services/logger";

export let instance;

const TAG = "reliability:health-metrics";

export function initialize() {
  const config = window.fs.config;

  let send;
  if (config.healthMetrics) {
    logger.info(TAG, "health metrics are enabled");
    const { awsConfig } = config.cloudWatch;
    const cloudWatchClient = new CloudWatchClient({
      region: awsConfig.region,
      credentials: {
        accessKeyId: awsConfig.accessKeyId,
        secretAccessKey: awsConfig.secretAccessKey,
      },
    });

    send = async function (MetricData) {
      try {
        await cloudWatchClient.send(
          new PutMetricDataCommand({ Namespace: "App Health", MetricData })
        );
        logger.debug(TAG, "sent metrics to cloudwatch");
      } catch (error) {
        logger.warn(TAG, "failed to send metrics to cloudwatch", {
          error,
        });
      }
    };
  } else {
    logger.info(TAG, "health metrics are disabled");
    send = () => {};
  }

  let buffer = [];
  let bufferSize = 0;

  const throttledSend = throttle(
    () => {
      const metrics = buffer;
      buffer = [];
      bufferSize = 0;
      send(metrics);
    },
    5000,
    {
      leading: false,
      trailing: true,
    }
  );

  function CloudWatchMetric(
    MetricName,
    qualifiedAction,
    Value = 1,
    Unit = "Count"
  ) {
    const [domain, action] = qualifiedAction.split(".");
    return {
      MetricName,
      Value,
      Unit,
      Dimensions: [
        { Name: "env", Value: config.appEnv },
        { Name: "domain", Value: domain },
        { Name: "action", Value: action },
      ],
    };
  }

  function scheduleSend(...metricData) {
    const metric = CloudWatchMetric(...metricData);
    buffer.push(metric);
    bufferSize += JSON.stringify(metric).length;
    if (buffer.length < 20 && bufferSize < 35000) {
      throttledSend();
    } else {
      throttledSend.flush();
    }
  }

  let startTimes = sessionStorage.getItem("health.times");
  if (startTimes) {
    sessionStorage.removeItem("health.times");
    startTimes = JSON.parse(startTimes);
  } else {
    startTimes = {};
  }

  addEventListener("beforeunload", () => {
    throttledSend.flush();
    if (Object.keys(startTimes).length > 0) {
      sessionStorage.setItem("health.times", JSON.stringify(startTimes));
    }
  });

  function setStartTime(qualifiedAction) {
    if (!(qualifiedAction in startTimes)) {
      startTimes[qualifiedAction] = [];
    }
    startTimes[qualifiedAction].push(Date.now());
  }

  function removeStartTime(qualifiedAction) {
    if (qualifiedAction in startTimes) {
      const startTime = startTimes[qualifiedAction].shift();
      if (startTimes[qualifiedAction].length === 0) {
        delete startTimes[qualifiedAction];
      }
      return startTime;
    }
  }

  function trackAttempt(qualifiedAction) {
    scheduleSend("attempt", qualifiedAction);
    logger.debug(TAG, "action attempt", { qualifiedAction });
  }

  function trackStart(qualifiedAction) {
    setStartTime(qualifiedAction);
    scheduleSend("start", qualifiedAction);
    logger.debug(TAG, "action start", { qualifiedAction });
  }

  function trackSuccess(qualifiedAction, duration) {
    const startTime = removeStartTime(qualifiedAction);

    if (isNil(startTime)) {
      logger.warn(TAG, "ignoring success because start is missing for action", {
        qualifiedAction,
      });
      return;
    }

    scheduleSend("success", qualifiedAction);

    if (!isNumber(duration)) {
      duration = Date.now() - Number(startTime);
    }

    if (duration < 0) {
      logger.warn(TAG, "duration is negative", {
        qualifiedAction,
        duration,
      });
    }

    // larger than 10 minutes
    if (duration > 600000) {
      logger.warn(TAG, "duration is too high", {
        qualifiedAction,
        duration,
      });
    }

    logger.debug(TAG, "action success", { qualifiedAction, duration });
    scheduleSend("duration", qualifiedAction, duration, "Milliseconds");
  }

  function trackFailure(qualifiedAction, error) {
    removeStartTime(qualifiedAction);
    scheduleSend("fail", qualifiedAction);
    logger.warn(TAG, "action failure", { qualifiedAction, error });
  }

  function trackCancellation(qualifiedAction) {
    removeStartTime(qualifiedAction);
    scheduleSend("cancel", qualifiedAction);
    logger.debug(TAG, "action cancellation", {
      qualifiedAction,
    });
  }

  instance = {
    trackAttempt,
    trackStart,
    trackSuccess,
    trackFailure,
    trackCancellation,
  };
}
