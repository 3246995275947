import { useTranslation } from "react-i18next";

import { Box, Text, Image } from "@shared/UIKit";

import noAccessImage from "@assets/img/images/noaccess.png";
import noAccessImage2X from "@assets/img/images/noaccess@2x.png";

export default function TabAccessDenied() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      data-testid="tab-access-denied"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        pt={1}
        mb={2.5}
      >
        <Image
          src={noAccessImage}
          srcSet={`${noAccessImage} 1x, ${noAccessImage2X} 2x`}
          alt={t("TEAM.ACCESS_DENIED_TITLE")}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mb={1.25}>
        <Text variant="textLg">{t("TEAM.ACCESS_DENIED_TITLE")}</Text>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Text color="text.secondary">
          {t("TEAM.ACCESS_DENIED_DESCRIPTION")}
        </Text>
      </Box>
    </Box>
  );
}
