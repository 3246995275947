import PropTypes from "prop-types";
import { useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, Popper } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

import { EMAIL_REGEX } from "@shared/constants/validation";
import fstgId from "@shared/helpers/fstgId";
import { userProp } from "@shared/props/authentication";
import { instance as analytics } from "@shared/services/analytics";

import AddReviewerDialogAutocompleteInput from "./AddReviewerDialogAutocompleteInput/AddReviewerDialogAutocompleteInput";
import AddReviewerDialogAutocompleteOption from "./AddReviewerDialogAutocompleteOption/AddReviewerDialogAutocompleteOption";
import AddReviewerDialogAutocompleteTag from "./AddReviewerDialogAutocompleteTag/AddReviewerDialogAutocompleteTag";

const useStyles = makeStyles((theme) => ({
  formControl: {
    [theme.breakpoints.down("md")]: {
      margin: 0,
    },
  },
  formInput: {
    display: "flex",
    alignItems: "flex-end",
  },
  popper: {
    maxHeight: 200,
    zIndex: 2000,
    width: 250,
    "& > div > ul > li": {
      marginTop: 0,
      marginBottom: 0,
      paddingLeft: 4,
      paddingRight: 4,
    },
  },
  noPaddingLastElement: {
    "& > div > ul > li:last-child": {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

const filter = createFilterOptions();

const AddReviewerDialogAutocomplete = ({
  preparedReviewers,
  reviewers,
  existingReviewers,
  onChange,
  trackPasteEmailEvents,
  trackAddedEmailEvents,
  inputLabel,
  inputHelperText,
  inputIcon,
  validation,
  isLoadingSuggestions,
  isDisplayingSuggestions,
  maxInviteCount,
  openAIMarketplaceDialog,
  openedAIMarketplaceDialogAt,
  emptyText,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [selectOnBlurTab, setSelectOnBlurTab] = useState(false);
  const [validationOptionError, setValidationOptionError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [reviewersEmails, setReviewersEmails] = useState(reviewers);
  const [autocompleteInputValue, setAutocompleteInputValue] = useState("");
  const autocompleteRef = useRef(null);
  const suggestions = preparedReviewers.filter(
    (reviewer) => !reviewer.prepared
  );
  /* istanbul ignore next */
  const defaultSuggestions =
    preparedReviewers.filter((reviewer) => reviewer.prepared) || "";

  const handleFilter = (options, params) => {
    let currentOptions = options;
    let aiButton;
    if (options[options.length - 1]?.optionType === "AIMarketplaceButton") {
      aiButton = options[options.length - 1];
      currentOptions = options.slice(0, -1);
    }
    const filterOptions = filter(currentOptions, params);
    const filtered = filterOptions.filter(
      (option) =>
        !reviewers.some((reviewer) => reviewer.inputValue === option.inputValue)
    );

    const inputValue = params.inputValue.toLowerCase();
    const existsInList = options.some((option) => {
      return (
        (option.inputValue && option.inputValue.includes(inputValue)) ||
        (option.label && option.label.includes(inputValue))
      );
    });
    const existsInResultAndReviewersList = [
      ...reviewers,
      ...existingReviewers,
    ].some((option) => {
      return option.inputValue === inputValue || option.label === inputValue;
    });

    const isValidEmail = EMAIL_REGEX.test(inputValue);
    if (!existsInList && !existsInResultAndReviewersList) {
      filtered.push({
        inputValue,
        label: inputValue,
        hasValidEmail: isValidEmail,
      });
      return filtered;
    }

    if (filtered.length > 0 && aiButton) {
      return [...filtered, aiButton];
    }

    return filtered;
  };

  const handleOnChange = (event, value) => {
    const emails = value.filter((email) => email.inputValue);
    onChange(event, emails);
    setReviewersEmails(emails);
  };

  const handleAutocompleteChip = (event, value, reason) => {
    handleOnChange(event, value);
    if (reason !== "removeOption") {
      setAutocompleteInputValue("");
    }
  };

  const handleSelectOption = (
    event,
    value,
    reason,
    details,
    validInputEmail
  ) => {
    if (
      validation &&
      autocompleteInputValue &&
      validInputEmail &&
      validation(autocompleteInputValue)
    ) {
      setAutocompleteInputValue(autocompleteInputValue);
      setErrorText(validation(autocompleteInputValue));
      return;
    }

    if (!details.option.hasValidEmail) {
      setAutocompleteInputValue(autocompleteInputValue);
      setValidationOptionError(true);
      return;
    }

    handleAutocompleteChip(event, value, reason, details);
  };

  const handleAutcompleteOnBlur = (
    event,
    value,
    reason,
    details,
    validInputEmail
  ) => {
    if (autocompleteInputValue === "" && selectOnBlurTab) {
      handleAutocompleteChip(event, value, reason, details);
      return;
    }

    if (!validInputEmail) {
      setAutocompleteInputValue(autocompleteInputValue);
      return;
    }

    if (validInputEmail && validation && validation(autocompleteInputValue)) {
      setAutocompleteInputValue(autocompleteInputValue);
      setErrorText(validation(autocompleteInputValue));
      return;
    }

    if (
      reviewers.some(
        (reviewer) => reviewer.inputValue === autocompleteInputValue
      )
    ) {
      setAutocompleteInputValue("");
      return;
    }

    handleAutocompleteChip(event, value, reason, details);
  };

  const handleAutocompleteCreateOption = () => setAutocompleteInputValue("");

  const handleAutocompleteOnChange = (event, value, reason, details) => {
    setSelectOnBlurTab(false);
    const validInputEmail = EMAIL_REGEX.test(autocompleteInputValue);

    switch (reason) {
      case "createOption":
        handleAutocompleteCreateOption(event, value, reason, details);
        break;
      case "removeOption":
        handleAutocompleteChip(event, value, reason, details);
        break;
      case "selectOption":
        handleSelectOption(event, value, reason, details, validInputEmail);
        break;
      case "blur":
        handleAutcompleteOnBlur(event, value, reason, details, validInputEmail);
        break;
    }
  };

  const handleOnKeyDown = (event) => {
    const { code, target } = event;
    const email = target.value.toLowerCase();
    let isValidEmail = EMAIL_REGEX.test(email);

    if (code === "Space" && !isValidEmail) {
      event.preventDefault();
      setAutocompleteInputValue(autocompleteInputValue);
      setValidationOptionError(true);
      return;
    }

    if (["Enter", "Space"].includes(code) && email) {
      const emailExist = [...reviewers, ...existingReviewers].some(
        (reviewer) => reviewer.inputValue === email
      );

      if (emailExist) {
        setAutocompleteInputValue("");
        return;
      }

      if (validation && validation(autocompleteInputValue)) {
        isValidEmail = false;
      }

      if (isValidEmail && !emailExist) {
        onChange(event, [
          ...reviewers,
          {
            inputValue: email,
            label: email,
          },
        ]);
        setReviewersEmails([
          ...reviewersEmails,
          {
            inputValue: email,
            label: email,
          },
        ]);
        trackAddedEmailEvents(1);
        setAutocompleteInputValue("");
      } else {
        setAutocompleteInputValue(email);
      }

      autocompleteRef.current.focus();
      setOpen(true);
    }
  };

  const handleInputChange = (event, value) => {
    /* istanbul ignore else */
    if (event && event.type !== "reset") {
      setAutocompleteInputValue(value.trim());
      setValidationOptionError(false);
      setErrorText("");
    }
  };

  const openAIMarketplaceHandler = () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AI_MARKETPLACE.DIALOG
    );
    openAIMarketplaceDialog();
  };

  const handleAutocompleteOnKeyDown = (event) =>
    setSelectOnBlurTab(event.key === "Tab");
  const handleOnFocus = () => setOpen(true);
  const handleOnBlur = () => setOpen(false);

  const extractEmails = (text) =>
    text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
  const handlePaste = useCallback(
    (event) => {
      event.preventDefault();
      const data = event.clipboardData.getData("text");
      const result = [
        ...new Set(extractEmails(data)?.map((email) => email.toLowerCase())),
      ];
      const emails = result?.filter(
        (email) =>
          ![...reviewers, ...existingReviewers].some(
            (reviewer) => reviewer.inputValue === email
          )
      );

      let isValid = true;
      emails.map((email) => {
        if (validation && validation(email)) {
          setAutocompleteInputValue(email);
          setErrorText(validation(email));
          setValidationOptionError(true);
          isValid = false;
        }
      });

      if (!isValid) {
        return;
      }

      setAutocompleteInputValue("");
      setValidationOptionError(false);
      setErrorText("");

      trackPasteEmailEvents(emails.length, result.length, data);
      trackAddedEmailEvents(emails.length);

      if (!result.length) {
        return;
      }

      const listEmails = emails.map((email) => ({
        inputValue: email,
        label: email,
      }));

      const reviewerList = [...reviewers, ...listEmails];
      onChange(event, reviewerList);
      setReviewersEmails(reviewerList);
    },
    [onChange, reviewers] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const getDisplayValue = (
    isLoadingSuggestions,
    isDisplayingSuggestions,
    validationOptionError
  ) => {
    if (
      isLoadingSuggestions ||
      (!isDisplayingSuggestions && !validationOptionError)
    ) {
      return "none";
    }
    return "block";
  };

  return (
    <Box
      pb={2}
      width={1}
      className={classes.formControl}
      data-testid="add-reviewers-dialog-autocomplete-box"
    >
      <Autocomplete
        ref={autocompleteRef}
        multiple
        id="add-reviewers-autocomplete"
        data-testid="add-reviewers-dialog-emails"
        ListboxProps={{
          "data-testid": "reviewers-list",
          style: {
            maxHeight: "10rem",
            padding: 0,
            display: getDisplayValue(
              isLoadingSuggestions,
              isDisplayingSuggestions,
              validationOptionError
            ),
          },
        }}
        autoHighlight
        freeSolo
        disableClearable
        autoSelect
        fullWidth
        options={suggestions}
        defaultValue={defaultSuggestions}
        inputValue={autocompleteInputValue}
        PopperComponent={(params) => (
          <Popper
            data-testid="add-reviewer-dialog-popper"
            {...params}
            placement="bottom-end"
            className={classNames(classes.popper, {
              [classes.noPaddingLastElement]: openedAIMarketplaceDialogAt,
            })}
          />
        )}
        getOptionLabel={(option) => `${option.inputValue}||${option.label}`}
        onChange={handleAutocompleteOnChange}
        filterOptions={handleFilter}
        onInputChange={handleInputChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onKeyDown={handleAutocompleteOnKeyDown}
        onPaste={handlePaste}
        value={reviewersEmails}
        open={open}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => {
            if (!option.inputValue && !EMAIL_REGEX.test(option.inputValue)) {
              return null;
            }

            return (
              <AddReviewerDialogAutocompleteTag
                {...getTagProps({ index })}
                key={option.inputValue}
                user={option}
              />
            );
          })
        }
        renderInput={(params) => (
          <AddReviewerDialogAutocompleteInput
            {...params}
            className={classes.formInput}
            onKeyDown={handleOnKeyDown}
            inputLabel={inputLabel}
            inputIcon={inputIcon}
            inputHelperText={t(inputHelperText)}
            error={Boolean(errorText)}
            reviewersEmailsLength={reviewersEmails.length}
            maxInviteCount={maxInviteCount}
          />
        )}
        renderOption={(props, option) => (
          <AddReviewerDialogAutocompleteOption
            key={fstgId.generate()}
            renderOptionProps={props}
            {...option}
            emptyText={emptyText}
            validationOptionError={validationOptionError}
            errorText={errorText}
            openAIMarketplaceDialog={openAIMarketplaceHandler}
          />
        )}
      />
    </Box>
  );
};

AddReviewerDialogAutocomplete.propTypes = {
  preparedReviewers: PropTypes.arrayOf(userProp).isRequired,
  reviewers: PropTypes.arrayOf(userProp),
  existingReviewers: PropTypes.arrayOf(userProp),
  onChange: PropTypes.func.isRequired,
  trackPasteEmailEvents: PropTypes.func,
  trackAddedEmailEvents: PropTypes.func,
  inputLabel: PropTypes.string,
  emptyText: PropTypes.string.isRequired,
  inputHelperText: PropTypes.string,
  validation: PropTypes.func,
  isLoadingSuggestions: PropTypes.bool,
  isDisplayingSuggestions: PropTypes.bool,
  inputIcon: PropTypes.elementType,
  maxInviteCount: PropTypes.number,
  openAIMarketplaceDialog: PropTypes.func,
  openedAIMarketplaceDialogAt: PropTypes.string,
};

AddReviewerDialogAutocomplete.defaultProps = {
  reviewers: [],
  existingReviewers: [],
  inputLabel: "REVIEWER.ADD.ENTER_REVIEWER",
  inputHelperText: "",
  validation: undefined,
  trackPasteEmailEvents: () => {},
  trackAddedEmailEvents: () => {},
  isLoadingSuggestions: false,
  isDisplayingSuggestions: true,
  inputIcon: null,
  maxInviteCount: undefined,
  openAIMarketplaceDialog: undefined,
  openedAIMarketplaceDialogAt: "",
};

export default AddReviewerDialogAutocomplete;
