import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Info from "@mui/icons-material/Info";
import {
  MenuItem,
  Button,
  Menu,
  ListItemIcon,
  Radio,
  ListItemText,
} from "@mui/material";
import ReviewState from "@workflow/components/ReviewState/ReviewState";

import { Tooltip } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";

const REVIEW_STATUS_COLORS = {
  [REVIEW_STATUS.IN_REVIEW]: "inReview",
  [REVIEW_STATUS.APPROVED]: "approved",
  [REVIEW_STATUS.NEEDS_CHANGES]: "needsChanges",
};

function ReviewStatusMenu({ isReadOnly, reviewState, onSelect }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const onButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlight"
        size="extraSmall"
        color={REVIEW_STATUS_COLORS[reviewState]}
        endIcon={!isReadOnly && <ArrowDropDownIcon fontSize="small" />}
        onClick={onButtonClick}
        data-testid="review-status-menu"
        sx={isReadOnly ? { pointerEvents: "none" } : {}}
      >
        {t(`REVIEWER.SETTINGS.${reviewState}`)}
      </Button>
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        {Object.values(REVIEW_STATUS).map((status) => (
          <MenuItem
            key={status}
            onClick={() => {
              onSelect(status);
              handleClose();
            }}
            data-testid={`review-status-${status}`}
          >
            <ListItemIcon>
              <Radio checked={reviewState === status} />
            </ListItemIcon>
            <ListItemText>
              <ReviewState reviewState={status} />
            </ListItemText>
            <Tooltip
              key={status}
              placement="right"
              title={t(`REVIEW_STATUS.${status}`)}
              description={t(`REVIEWER.SETTINGS.${status}_STATUS`)}
            >
              <Info fontSize="small" color="disabled" />
            </Tooltip>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

ReviewStatusMenu.propTypes = {
  reviewState: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

ReviewStatusMenu.defaultProps = {
  onSelect: null,
  isReadOnly: false,
};

export default ReviewStatusMenu;
