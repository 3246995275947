export const DRAWING_CONFIG = Object.freeze({
  STATES: {
    HIDDEN: 0,
    SHOWN: 1,
    EDIT: 2,
  },
  CANVAS: {
    LINE_WIDTH: 3,
    MIN_LINE_WIDTH: 1,
    LINE_JOIN: "round",
    LINE_CAP: "round",
    COLORS: {
      GREEN: "#00C050",
      RED: "#FF0000",
      BLUE: "#0099FF",
      PURPLE: "#9900FF",
      YELLOW: "#FFFF00",
      ORANGE: "#FF7700",
    },
    LENGTH_OF_ARROW_TOP: 40,
    HIGHLIGHT_OPACITY: 0.2,
    SHADOW_OFFSET_X: 1.5,
    SHADOW_OFFSET_Y: 1.5,
    SHADOW_BLUR: 1.5,
    SHADOW_COLOR: "rgba(0,0,0, 0.2)",
  },
  TOOLS: {
    FREEHAND: "FREEHAND",
    RECTANGLE: "RECTANGLE",
    ARROW: "ARROW",
    LINE: "LINE",
  },
  TOOLS_ICONS: {
    FREEHAND: "assets/img/icons/ic_gesture_24px.svg",
    RECTANGLE: "assets/img/icons/ic_annotation_rectangle_24px.svg",
    ARROW: "assets/img/icons/ic_annotation_arrow_24px.svg",
    LINE: "assets/img/icons/ic_annotation_line_24px.svg",
  },
});
