import { useState, Fragment, useEffect, useMemo, useRef } from "react";
import { useLoaderData, useParams, useSearchParams } from "react-router-dom";
import { Waypoint } from "react-waypoint";

import NavigationWorkspaceWrapper from "@feedback/components/NavigationWorkspace/NavigationWorkspaceWrapper";
import ReviewDecisionNotificationPopup from "@feedback/components/ReviewDecisionNotificationPopup/ReviewDecisionNotificationPopup";
import { HARD_CODED_TEAMS } from "@feedback/constants/hardCodedTeams";
import useReviewsPagination from "@feedback/hooks/useReviewsPagination";
import { makeStyles } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { Box, Text } from "@shared/UIKit";

import { useFirstMountState, useSessionStorage } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

import BatchReviewDecisionBar from "./BatchReviewDecisionBar/BatchReviewDecisionBar";
import BatchReviewItem from "./BatchReviewItem/BatchReviewItem";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    width: "100%",
    fontSize: FSTGTypography.fontSize_1_6,
    color: theme.color.gray[600],
    marginTop: 16,
    marginBottom: 8,
    marginLeft: 14,
  },
}));

const selectUser = (user) => ({
  currentUserId: user._id,
  sessionType: user.session?.type,
  isInfoComplete: user.isInfoComplete,
  email: user.email,
  isEmailVerified: user.isEmailVerified,
});

const BatchReview = () => {
  const { step, project, team } = useLoaderData();
  const { currentUserId, sessionType, isInfoComplete, email, isEmailVerified } =
    useActiveUser(selectUser);
  const classes = useStyles();
  const { stepId } = useParams();
  const [searchParams] = useSearchParams();
  const [selectedReviewsInSession, setSelectedReviewsInSession] =
    useSessionStorage("selectedReviews", []);

  const fromReviewId = searchParams.get("reviewId");

  const [selectedReviews, setSelectedReviews] = useState({});
  const isFirstMount = useFirstMountState();
  const isWTTJEventFired = useRef(false);

  const { sections, reviews, loadMore, keepScrolledAt } = useReviewsPagination({
    projectId: project.id,
    stepId,
    reviewId: fromReviewId,
    initialSections: project.sections,
  });

  useEffect(() => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.BATCH_REVIEW);
  }, []);

  useEffect(() => {
    if (
      !isWTTJEventFired.current &&
      reviews &&
      reviews.length &&
      HARD_CODED_TEAMS.WTTJ_TEAMS_IDS.includes(reviews[0].teamId)
    ) {
      analytics.track(
        analytics.ACTION.VIEWED,
        analytics.CATEGORY.GRIDVIEW_WTTJ,
        {
          url: location.href,
          teamId: reviews[0].teamId,
        }
      );
      isWTTJEventFired.current = true;
    }
  }, [reviews]);

  const pendingDigitalSignature = searchParams.get("pendingDigitalSignature");

  useEffect(() => {
    if (
      reviews.length &&
      selectedReviewsInSession.length &&
      pendingDigitalSignature === "true"
    ) {
      const newSelectedReviews = {};

      for (const reviewId of selectedReviewsInSession) {
        newSelectedReviews[reviewId] = true;
      }

      setSelectedReviews(newSelectedReviews);
      setSelectedReviewsInSession([]);
    }
  }, [
    reviews,
    selectedReviewsInSession,
    pendingDigitalSignature,
    setSelectedReviewsInSession,
  ]);

  const toggleSelect = (reviewId) => {
    const newSelectedReviews = { ...selectedReviews };

    if (newSelectedReviews[reviewId]) {
      delete newSelectedReviews[reviewId];
    } else {
      newSelectedReviews[reviewId] = true;
    }

    setSelectedReviews(newSelectedReviews);
  };

  const clearSelection = () => {
    setSelectedReviews({});
    analytics.track(
      analytics.ACTION.CLOSED,
      analytics.CATEGORY.BULK_ACTION_TOOLBAR,
      { page: "grid-view" }
    );
  };

  const isCollaborator = useMemo(() => {
    return project.collaborators.some(
      (collaborator) => collaborator._id === currentUserId
    );
  }, [project.collaborators, currentUserId]);

  const isReviewDecisionRequested = useMemo(() => {
    const reviewer = step.reviewers.find(
      (reviewer) => reviewer._id === currentUserId
    );

    return reviewer ? reviewer.reviewDecisionRequested : !isCollaborator;
  }, [step.reviewers, currentUserId, isCollaborator]);

  const selectedReviewIds = Object.keys(selectedReviews);
  const inSelectMode = selectedReviewIds.length > 0;
  const isGuestSession = sessionType === "Guest";

  return (
    <Box height="100vh" overflow="auto">
      <NavigationWorkspaceWrapper
        wasDataLoaded={Boolean(step && project && team)}
        isGuestSession={isGuestSession}
        step={step}
        project={project}
        team={team}
        reviews={reviews}
        isBatchReviewPage
      />

      <Box marginLeft={1} data-testid="batch-review">
        <Box
          display="flex"
          flexWrap="wrap"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-start"
        >
          {sections.length > 0 && reviews.length > 0 && (
            <>
              <Waypoint fireOnRapidScroll onEnter={loadMore(true)} />
              {reviews.map((review, index) => (
                <Fragment key={review.id}>
                  {(index === 0 ||
                    reviews[index - 1].file.sectionId !==
                      review.file.sectionId) && (
                    <Text className={classes.sectionTitle}>
                      {
                        sections.find(({ id }) => review.file.sectionId === id)
                          ?.name
                      }
                    </Text>
                  )}
                  <BatchReviewItem
                    key={review.id}
                    review={review}
                    currentUserId={currentUserId}
                    isSelected={selectedReviews[review.id]}
                    inSelectMode={inSelectMode}
                    toggleSelect={toggleSelect}
                    keepInView={
                      /* istanbul ignore next */
                      !isFirstMount && keepScrolledAt.current?.id === review.id
                    }
                    scrollOnMount={fromReviewId === review.id}
                    isGuestSession={isGuestSession}
                    isReviewDecisionRequested={isReviewDecisionRequested}
                  />
                </Fragment>
              ))}
              <Waypoint fireOnRapidScroll onEnter={loadMore()} />
            </>
          )}
        </Box>

        {inSelectMode && (
          <BatchReviewDecisionBar
            selectedReviews={selectedReviewIds}
            setSelectedReviews={setSelectedReviews}
            clearSelection={clearSelection}
            isDemoProject={project.isDemoProject}
            currentUser={{
              email: email || "",
              isInfoComplete,
              isEmailVerified,
            }}
            authDialogContext={{
              stepId: step.id,
              teamId: team._id,
              isDemoProject: project.isDemoProject,
              digitalSignatureRequired:
                step.settings.digitalSignatureRequired || false,
            }}
            team={team}
          />
        )}

        <ReviewDecisionNotificationPopup />
      </Box>
    </Box>
  );
};

export default BatchReview;
