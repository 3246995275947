import events from "@shared/constants/events";
import { instance as logger } from "@shared/services/logger";

let listeners = {};

function isRegistered(eventName, listener) {
  return (listeners[eventName] || []).some((le) => le.listener === listener);
}

function addListener(eventName, listener) {
  if (!isRegistered(eventName, listener)) {
    _addListener({
      eventName,
      listener,
      options: {
        once: false,
      },
    });
  }
}

/**
 * Registers a listener to listen to an event and automatically gets deregistered
 * when the event has emitted once.
 * @param eventName
 * @param listener
 */
function addListenerOnce(eventName, listener) {
  _addListener({
    eventName,
    listener,
    options: {
      once: true,
    },
  });
}

/**
 * De-registers a listener from listening to an event.
 * @param eventName
 * @param listener
 */
function removeListener(eventName, listener) {
  if (listeners[eventName]) {
    listeners[eventName] = listeners[eventName].filter(
      (le) => le.listener !== listener
    );
  }
}

/**
 * Removes all listeners, useful for testing
 */
function removeAllListeners() {
  listeners = {};
}

function getListeners(eventName) {
  return (listeners[eventName] || []).map((le) => le.listener);
}

/**
 * Emits an event to all listeners.
 * @param event
 * @returns {boolean}
 */
function emitEvent(event) {
  if (!_isEventValid(event)) {
    throw new Error("Event Emitter: Invalid event");
  }
  const isDebugMode = window.fs ? window.fs.config?.debug : true;

  if (listeners[event.eventName]) {
    const listenerElems = listeners[event.eventName];
    listeners[event.eventName] = listeners[event.eventName].filter(
      (le) => !le.options.once
    );

    isDebugMode &&
      // eslint-disable-next-line no-console
      console.groupCollapsed(
        `eventService emitting event to listeners:`,
        event.eventName,
        event.eventData
      );
    // eslint-disable-next-line no-console
    isDebugMode && console.trace();

    listenerElems.forEach((le) => {
      try {
        logger.debug("eventService", "listener", le);
        le.listener(event);
      } catch (error) {
        logger.error("eventService", "error calling listener", {
          event,
          error,
        });
      }
    });

    // eslint-disable-next-line no-console
    isDebugMode && console.groupEnd();

    return true;
  }

  return false;
}

/**
 * Creates and subscribes a listener to an event
 * @param params
 * @private
 */
function _addListener({ eventName, listener, options }) {
  listeners[eventName] = listeners[eventName] || [];
  listeners[eventName].push({
    listener,
    options,
  });
}

/**
 * Validates whether an event is well-defined.
 * @param event
 * @returns {boolean}
 * @private
 */
function _isEventValid(event) {
  return Boolean(event.eventName && event.eventData);
}

export const EVENT_NAMES = events;

export default {
  EVENT_NAMES: events,
  addListener,
  addListenerOnce,
  removeListener,
  removeAllListeners,
  getListeners,
  emitEvent,
  isRegistered,
};
