import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { Check, Replay, PriorityHigh, Close } from "@mui/icons-material";
import { SvgIcon } from "@mui/material";

import { Box, Tooltip } from "@shared/UIKit";

import SignatureIcon from "@assets/img/icons/signature.svg";

const TOOLTIPS = {
  APPROVED: "REVIEW_BAR.APPROVED_MESSAGE",
  NEEDS_CHANGES: "REVIEW_BAR.DECLINED_MESSAGE",
  APPROVED_WITH_CHANGES: "REVIEW_BAR.APPROVED_WITH_CHANGES_MESSAGE",
  REJECTED: "REVIEW_BAR.REJECTED_MESSAGE",
  PENDING_REVIEW: "FILE_GALLERY.AWAITING_REVIEW_BADGE",
  VERIFIED_APPROVAL: "BATCH_REVIEW.VERIFIED_APPROVAL.TOOLTIP",
};

const ICONS = {
  APPROVED: <Check fontSize="inherit" data-testid="approved-badge" />,
  APPROVED_WITH_CHANGES: (
    <Check fontSize="inherit" data-testid="approved-changes-badge" />
  ),
  NEEDS_CHANGES: (
    <Replay
      fontSize="inherit"
      data-testid="requested-changes-badge"
      sx={{
        transform: "rotate(-136deg)",
      }}
    />
  ),
  REJECTED: <Close fontSize="inherit" data-testid="rejected-badge" />,
  PENDING_REVIEW: (
    <PriorityHigh fontSize="inherit" data-testid="pending-review-badge" />
  ),
  VERIFIED_APPROVAL: (
    <SvgIcon
      component={SignatureIcon}
      inheritViewBox
      fontSize="inherit"
      data-testid="verified-approval-badge"
    />
  ),
};

const COLORS = {
  APPROVED: "approved.main",
  APPROVED_WITH_CHANGES: "approved.main",
  NEEDS_CHANGES: "needsChanges.main",
  REJECTED: "rejected.main",
  PENDING_REVIEW: "pending.main",
  VERIFIED_APPROVAL: "approved.main",
};

const BatchReviewItemBadge = ({ state }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(TOOLTIPS[state])}>
      <Box
        width={20}
        height={20}
        color="white"
        borderRadius={0.75}
        border="1px solid white"
        fontSize="textXs"
        bgcolor={COLORS[state]}
        display="flex"
        alignItems="center"
        justifyContent="center"
        data-testid="badge-container"
      >
        {ICONS[state]}
      </Box>
    </Tooltip>
  );
};

BatchReviewItemBadge.propTypes = {
  state: PropTypes.oneOf([
    "PENDING_REVIEW",
    "VERIFIED_APPROVAL",
    ...Object.keys(REVIEW_DECISION_STATES),
  ]).isRequired,
};

export default BatchReviewItemBadge;
