import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Card, CardHeader, CardContent, Link } from "@mui/material";

import { Text } from "@shared/UIKit";

function TermsAndPoliciesLink({ description, url }) {
  return (
    <Link variant="body2" href={url} target="_blank">
      {description}
    </Link>
  );
}

TermsAndPoliciesLink.propTypes = {
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const termsAndPoliciesDetails = [
  {
    description: "URLS.GDPR_INFORMATION_LABEL",
    url: "URLS.GDPR_INFORMATION",
  },
  { description: "URLS.PRIVACY_LABEL", url: "URLS.PRIVACY" },
  { description: "URLS.TERMS_LABEL", url: "URLS.TERMS" },
  { description: "URLS.IMPRINT_LABEL", url: "URLS.IMPRINT" },
];

function TermsAndPolicies() {
  const { t } = useTranslation();

  return (
    <Card data-testid="user-settings-account-tab-terms-and-policies">
      <CardHeader title={t("SETTINGS.ACCOUNT.TERMS_POLICIES.TITLE")} />
      <CardContent>
        <Text variant="body2" color="text.secondary">
          {t("SETTINGS.ACCOUNT.TERMS_POLICIES.DESCRIPTION-1")}
        </Text>
        <ul>
          {termsAndPoliciesDetails.map((item, index) => (
            <li key={index}>
              <TermsAndPoliciesLink
                url={t(item.url)}
                description={t(item.description)}
              />
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
}

export default TermsAndPolicies;
