import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Box, LinearProgress } from "@mui/material";

import { Text, InfoToolTip } from "@shared/UIKit";

function UsageBar({
  name,
  tooltip,
  used,
  limit,
  percentage,
  isLinearProgressVisible,
  isHelperVisible,
  helperTooltip,
  helperText,
  helperIcon,
  ...props
}) {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Box
        display="flex"
        flexDirection="row"
        flex={1}
        justifyContent="space-between"
      >
        <Box
          component="span"
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={2}
        >
          <Text fontWeight="fontWeight.medium" variant="textSm">
            {t(name)}
          </Text>
          <InfoToolTip title={tooltip}>
            <InfoIcon
              color="default-light"
              fontSize="small"
              data-testid={`usage-name-${name}`}
            />
          </InfoToolTip>
        </Box>

        <Box
          component="span"
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Text
            fontWeight="fontWeight.medium"
            variant="subtitle1"
            data-testid={`usage-used-${name}`}
          >
            {used}
          </Text>
          {limit !== null && (
            <Text
              color="text.secondary"
              variant="subtitle1"
            >{`/ ${limit}`}</Text>
          )}
        </Box>
      </Box>
      {isLinearProgressVisible && (
        <Box mt={0.5}>
          <LinearProgress
            variant="determinate"
            value={percentage}
            color={percentage > 80 ? "error" : "success"}
          />
        </Box>
      )}
      {isHelperVisible && (
        <Box display="flex" justifyContent="flex-end">
          <InfoToolTip
            title={helperTooltip}
            display="flex"
            placement="bottom"
            alignItems="center"
          >
            <Box
              color="upgrade.main"
              bgcolor="var(--mui-color-blue-50)"
              p={0.5}
              borderRadius={0.5}
            >
              {helperIcon || (
                <HelpOutlineOutlined
                  fontSize="small"
                  data-testid={`usage-${name}`}
                />
              )}

              <Text variant="subtitle2" fontWeight="fontWeight.medium">
                {t(helperText)}
              </Text>
            </Box>
          </InfoToolTip>
        </Box>
      )}
    </Box>
  );
}

UsageBar.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.object.isRequired,
  used: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  limit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.number,
  isLinearProgressVisible: PropTypes.bool,
  isHelperVisible: PropTypes.bool,
  helperTooltip: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helperText: PropTypes.string,
  helperIcon: PropTypes.node,
};

UsageBar.defaultProps = {
  isLinearProgressVisible: true,
  isHelperVisible: false,
  helperTooltip: {},
  limit: null,
  percentage: 0,
  helperText: "",
  helperIcon: null,
};
export default UsageBar;
