import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Search as SearchIcon,
  SchoolOutlined,
  ForumOutlined,
} from "@mui/icons-material";
import GridView from "@mui/icons-material/GridView";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Badge,
  Menu,
} from "@mui/material";
import useUnreadMessagesCount from "@supporting/components/HelpMenu/useUnreadMessagesCount";
import QuickSearchDialog from "@workflow/components/QuickSearchDialog/QuickSearchDialog";
import classNames from "classnames";

import pagesEnum from "@shared/constants/pages";
import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

function FileViewMoreMenu({ reviewId, stepId }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const unreadMessagesCount = useUnreadMessagesCount();
  const { goTo } = useNavigation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUrl = (url) => {
    window.open(url, "_blank");
  };

  const goToBatchReview = () => {
    goTo(ROUTE_STATES.BATCH_REVIEW, {
      params: {
        stepId,
      },
      search: new URLSearchParams({
        reviewId,
      }).toString(),
    });
  };

  const handleHelpCenterClick = () => {
    openUrl("https://help.filestage.io/");
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.HELP_MENU.HELP_CENTER,
      {
        page: pagesEnum.WORKSPACE,
      }
    );
  };

  const handleSupportChatClick = () => {
    handleClose();
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.HELP_MENU.SUPPORT_CHAT,
      {
        page: pagesEnum.WORKSPACE,
      }
    );
  };

  const triggerSearch = () => {
    NiceModal.show(QuickSearchDialog);
    handleClose();
  };

  return (
    <>
      <IconButton
        id="more-menu-button"
        aria-controls={open ? "file-view-more-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        data-testid="file-view-more-menu-button"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="file-view-more-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-menu-button",
        }}
      >
        <MenuItem onClick={goToBatchReview}>
          <ListItemIcon>
            <GridView />
          </ListItemIcon>
          <ListItemText>{t("BATCH_REVIEW.TITLE")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={triggerSearch} data-testid="help-menu-search-icon">
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText>{t("SEARCH.BTN_TOOLTIP")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleHelpCenterClick}
          data-testid="help-menu-help-center"
        >
          <ListItemIcon>
            <SchoolOutlined />
          </ListItemIcon>
          <ListItemText>{t("CORE.OPEN_HELP")}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={handleSupportChatClick}
          className={classNames(
            "launch-intercom-chat",
            "fs-tracking__open-support-chat"
          )}
          data-testid="help-menu-support-and-feedback"
        >
          <ListItemIcon>
            <Badge color="accent" badgeContent={unreadMessagesCount}>
              <ForumOutlined />
            </Badge>
          </ListItemIcon>
          <ListItemText>{t("CORE.CONTACT_SUPPORT")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

FileViewMoreMenu.propTypes = {
  reviewId: PropTypes.string.isRequired,
  stepId: PropTypes.string.isRequired,
};

export default FileViewMoreMenu;
