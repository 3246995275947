import PropTypes from "prop-types";

import { useTheme, makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  lastVersion: {
    "&:before": {
      bottom: "50% !important",
    },
  },
  borderContainer: {
    "&:before, &:after": {
      position: "absolute",
      content: "''",
      border: `1px dashed ${theme.color.gray[400]}`,
    },
    "&:before": {
      bottom: 2,
      top: 0,
      left: "50%",
    },
    "&:after": {
      top: "51%",
      left: "50%",
      right: 0,
    },
  },
}));

function FileVersionBorders({ isLastVersion }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      position="relative"
      width={99.5}
      height={40}
      bgcolor={theme.color.gray[50]}
      className={classnames(classes.borderContainer, {
        [classes.lastVersion]: isLastVersion,
      })}
    />
  );
}

FileVersionBorders.propTypes = {
  isLastVersion: PropTypes.bool.isRequired,
};

export default FileVersionBorders;
