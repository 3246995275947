import { useState, useEffect, useCallback, memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PersonOutline, Menu as MenuIcon } from "@mui/icons-material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {
  Box,
  IconButton,
  Menu,
  Divider,
  MenuItem,
  ListItemIcon,
  ListSubheader,
  ListItemText,
} from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser/useActiveUser";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as teamService } from "@supporting/services/team";
import PendingReviewsCounter from "@workflow/components/PendingReviewsCounter/PendingReviewsCounter";
import PendingReviewsContainer from "@workflow/components/PendingReviewsNavButton/PendingReviewsContainer";

import { Avatar } from "@shared/UIKit";

import RouterNavLink from "@shared/components/RouterNavLink";
import { APP_ROUTES } from "@shared/constants/routes";
import { useMediaQuery, useQueryParams } from "@shared/hooks";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import WaitingApprovalDialog from "./WaitingApprovalDialog";

function UserMenu() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [teams, setTeams] = useState([]);
  const user = useActiveUser();
  const selectedTeam = useSelectedTeam();
  const [searchParams, updateSearchParams] = useQueryParams();
  const isOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const openUserMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeUserMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const updateTeam = useCallback(
    (team) => async () => {
      closeUserMenu();
      if (team.pendingApproval) {
        updateSearchParams({ pendingApprovalId: team._id });
      } else if (team._id !== selectedTeam?._id) {
        await teamService.switchTeam(team);
        navigate(APP_ROUTES.DASHBOARD.path);
      }
    },
    [closeUserMenu, navigate, selectedTeam?._id, updateSearchParams]
  );

  useEffect(() => {
    const updateTeams = ({ eventData: { teams } }) => {
      setTeams(teams);
    };

    async function getTeams() {
      const teams = await teamService.getTeams();
      setTeams(teams);
    }

    getTeams();
    eventService.addListener(EVENT_NAMES.TEAM.TEAMS.UPDATED, updateTeams);

    return () => {
      eventService.removeListener(EVENT_NAMES.TEAM.TEAMS.UPDATED, updateTeams);
    };
  }, []);

  if (!user) {
    return null;
  }

  const queryPendingApprovalId = searchParams.get("pendingApprovalId");

  const pendingTeam = teams.find(({ _id }) => _id === queryPendingApprovalId);

  const closeWaitingDialog = () => {
    updateSearchParams({ pendingApprovalId: null });
  };

  const mobileMenu = [
    <MenuItem
      key={0}
      component={RouterNavLink}
      to={APP_ROUTES.DASHBOARD.path}
      onClick={closeUserMenu}
      data-testid="user-menu-projects"
    >
      {t("CORE.PROJECTS")}
    </MenuItem>,

    <MenuItem
      key={1}
      component={RouterNavLink}
      to={APP_ROUTES.TEAMS_GENERAL.path}
      onClick={closeUserMenu}
      data-testid="user-menu-teams"
    >
      {t("CORE.OPEN_TEAMS")}
      {selectedTeam?.pendingMembers?.length > 0 && (
        <Box data-testid="pending-member-bubble" />
      )}
    </MenuItem>,

    ...(selectedTeam?.permissions?.canViewInsights
      ? [
          <MenuItem
            key="insights"
            component={RouterNavLink}
            onClick={closeUserMenu}
            to={APP_ROUTES.INSIGHTS_GENERAL.path}
            data-testid="user-menu-insights"
          >
            {t("CORE.INSIGHTS")}
          </MenuItem>,
        ]
      : []),
    <PendingReviewsContainer key={2} onClick={closeUserMenu}>
      {({ pendingReviewsCount, ref, onClick }) => (
        <MenuItem ref={ref} onClick={onClick}>
          <ListItemText>{t("PENDING_REVIEWS.NAVIGATION_BUTTON")}</ListItemText>
          <PendingReviewsCounter pendingReviewsCount={pendingReviewsCount} />
        </MenuItem>
      )}
    </PendingReviewsContainer>,
    <Divider key={3} />,
  ];

  return (
    <>
      <IconButton
        className="tour__user-avatar"
        data-testid="user-avatar-menu-button"
        onClick={openUserMenu}
        disableRipple={!isMobile}
        disableFocusRipple={!isMobile}
        size="large"
      >
        {isMobile ? <MenuIcon /> : <Avatar user={user} />}
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closeUserMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        MenuListProps={{
          disablePadding: false,
          dense: false,
          size: "medium",
        }}
      >
        {isMobile && mobileMenu}

        <MenuItem
          component={RouterNavLink}
          to={APP_ROUTES.PROFILE_HOME.path}
          data-testid="user-menu-settings"
          onClick={closeUserMenu}
        >
          <ListItemIcon>
            <PersonOutline />
          </ListItemIcon>
          <ListItemText>{t("CORE.SETTINGS")}</ListItemText>
        </MenuItem>
        <Divider />
        <ListSubheader
          disableSticky
          sx={{ textTransform: "uppercase", fontWeight: "fontWeight.bold" }}
        >
          {t("CORE.CHOOSE_TEAM")}
        </ListSubheader>
        {teams?.map((team) => (
          <MenuItem
            key={team._id}
            onClick={updateTeam(team)}
            data-testid={`team-select-button${
              selectedTeam._id === team._id ? "-active" : ""
            }`}
            selected={selectedTeam._id === team._id}
          >
            <ListItemText inset>{team.name}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          to={APP_ROUTES.LOGOUT.path}
          component={RouterNavLink}
          data-testid="user-menu-signout"
        >
          <ListItemIcon>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          <ListItemText>{t("CORE.SIGNOUT")}</ListItemText>
        </MenuItem>
      </Menu>
      {pendingTeam && (
        <WaitingApprovalDialog
          pendingTeam={pendingTeam}
          closeDialog={closeWaitingDialog}
        />
      )}
    </>
  );
}

export default memo(UserMenu);
