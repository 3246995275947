import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { instance as analytics } from "@shared/services/analytics";

import OneDrive from "@assets/img/icons/onedrive_icon.svg";

import RemoteButton from "../../RemoteButton";
import useOneDrive from "./useOneDrive";

function UploadFromOneDrive({
  teamId,
  handleUploadFiles,
  handleUpgradeAction,
  areFilePickerPluginsEnabled,
  isDisabled,
  enableMultiSelect,
}) {
  const { t } = useTranslation();
  const { launchPicker } = useOneDrive();

  const handleOneDriveClick = (event) => {
    if (areFilePickerPluginsEnabled) {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.ONE_DRIVE_BUTTON
      );
      launchPicker(event, handleUploadFiles, teamId, enableMultiSelect);
    } else {
      analytics.track(
        analytics.ACTION.REACHED,
        analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        {},
        {},
        analytics.STATE.ONEDRIVE_FEATURE
      );
    }
  };

  return (
    <RemoteButton
      testId="upload-panel-from-onedrive"
      onClick={handleOneDriveClick}
      viewBox="0 0 17 10"
      icon={OneDrive}
      disabled={isDisabled}
      label={t("UPLOAD_DIALOG.ONEDRIVE")}
      handleUpgradeAction={handleUpgradeAction}
      isFeatureEnabled={areFilePickerPluginsEnabled}
    />
  );
}

UploadFromOneDrive.propTypes = {
  teamId: PropTypes.string.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
  handleUpgradeAction: PropTypes.func.isRequired,
  areFilePickerPluginsEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  enableMultiSelect: PropTypes.bool.isRequired,
};

export default UploadFromOneDrive;
