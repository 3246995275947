import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton as Button } from "@mui/lab";
import { TextField } from "@mui/material";
import { z } from "zod";

import { Box } from "@shared/UIKit";

const invoiceDataSchema = z.object({
  invoiceEmail: z
    .string()
    .min(1, "VALIDATIONS.INVOICE_EMAIL.REQUIRED")
    .email("VALIDATIONS.INVOICE_EMAIL.FORMAT"),
});

const InvoiceDataForm = ({ onSubmit, defaultValues }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm({
    resolver: zodResolver(invoiceDataSchema),
    defaultValues,
    mode: "onChange",
  });

  return (
    <Box
      component="form"
      id="invoice-data-form"
      onSubmit={handleSubmit(onSubmit)}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Controller
        name="invoiceEmail"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            required
            label={t("BILLING.EDIT_INVOICE_DATA.EMAIL_LABEL")}
            inputProps={{
              "data-testid": "team-billing-invoice-email",
            }}
            error={Boolean(errors.invoiceEmail)}
            helperText={t(errors.invoiceEmail?.message)}
          />
        )}
      />
      <Button
        disabled={!isValid}
        loading={isSubmitting}
        variant="contained"
        color="primary"
        type="submit"
        data-testid="team-billing-invoice-email-submit"
        sx={{ alignSelf: "flex-end" }}
      >
        {t("BILLING.EDIT_INVOICE_DATA.SUBMIT")}
      </Button>
    </Box>
  );
};

InvoiceDataForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    invoiceEmail: PropTypes.string,
  }),
};

InvoiceDataForm.defaultProps = {
  defaultValues: {
    invoiceEmail: "",
  },
};

export default InvoiceDataForm;
