import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { ListItemIcon, ListItemText, MenuItem, Radio } from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import userService from "@supporting/services/userService";

import {
  useSidebarState,
  PROJECT_SORT_OPTIONS,
  PROJECT_LIST_ACTION_TYPES,
} from "../../../sidebarState";

const ProjectListHeaderMenuItem = forwardRef(
  ({ sortOption, closeMenu }, ref) => {
    const { dispatch, state } = useSidebarState();
    const { t } = useTranslation();

    const handleMenuItemClick = () => {
      if (sortOption !== state.sortOption) {
        const { settings } = authenticationService.fetchUser();
        userService.update({
          settings: { ...settings, selectedProjectSortingOption: sortOption },
        });
        dispatch({
          type: PROJECT_LIST_ACTION_TYPES.CHANGE_SORT_OPTION,
          payload: {
            sortOption,
          },
        });
      }
      closeMenu();
    };

    return (
      <MenuItem
        onClick={handleMenuItemClick}
        data-testid={`project-list-header-menu-item-${sortOption}`}
        ref={ref}
      >
        <ListItemIcon>
          <Radio color="primary" checked={state.sortOption === sortOption} />
        </ListItemIcon>
        <ListItemText>{t(`PROJECT.SORT_OPTIONS.${sortOption}`)}</ListItemText>
      </MenuItem>
    );
  }
);

ProjectListHeaderMenuItem.displayName = "ProjectListHeaderItem";
export default ProjectListHeaderMenuItem;

ProjectListHeaderMenuItem.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  sortOption: PropTypes.oneOf(PROJECT_SORT_OPTIONS).isRequired,
};
