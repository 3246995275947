import PropTypes from "prop-types";

import { fileDataProp } from "./fileData";

export const pendingReviewFileProp = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  version: PropTypes.shape({
    id: PropTypes.string,
    thumbnail: fileDataProp,
    number: PropTypes.number,
  }),
});

export const pendingReviewProp = PropTypes.shape({
  id: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  step: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  file: pendingReviewFileProp,
  number: PropTypes.number,
  dueDate: PropTypes.string,
});
