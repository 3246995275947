import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";

import { Text } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import { useNavigation } from "@shared/hooks";
import { reviewProp } from "@shared/props/review";

import classes from "./SharedCell.module.css";

function SharedCell({
  review,
  activeReviewId,
  reviewPanePosition,
  onOpenReview,
}) {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const leftReviewId = searchParams.get("leftReviewId");
  const rightReviewId = searchParams.get("rightReviewId");

  const isCurrentReview =
    review.id === activeReviewId ||
    review.id === leftReviewId ||
    review.id === rightReviewId;

  const openReview = (event) => {
    if (!isCurrentReview) {
      if (reviewPanePosition) {
        setSearchParams(
          {
            leftReviewId,
            rightReviewId,
            [`${reviewPanePosition}ReviewId`]: review.id,
          },
          { state }
        );
      } else {
        goTo("REVIEW_LINK", {
          params: {
            stepId: review.stepId,
            reviewId: review.id,
          },
        });
      }

      onOpenReview(event);
    }
  };

  const getLabel = useCallback(() => {
    let label = "VERSION_REVIEW.OPEN_REVIEW";
    if (isCurrentReview) {
      label = "VERSION_REVIEW.VIEWING";

      if (leftReviewId) {
        label = `COMPARE_VERSIONS.VERSION_PICKER.COMPARING_${
          review.id === leftReviewId ? "LEFT" : "RIGHT"
        }_SIDE`;
      }
    }

    return label;
  }, [isCurrentReview, leftReviewId, review.id]);

  return (
    <Box
      width={200}
      minWidth={200}
      height={50}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={classes.sharedCell}
      onClick={openReview}
      data-testid="shared-version-reviews-cell-react"
      borderRight="1px solid var(--mui-palette-grey-100)"
      sx={{
        cursor: isCurrentReview ? "default" : "pointer",
      }}
    >
      <Text
        variant="textSm"
        fontWeight={isCurrentReview ? "fontWeight.bold" : "fontWeight.regular"}
        color={isCurrentReview ? "primary" : "grey.700"}
        data-testid="open-review-label"
      >
        {t(getLabel())}
      </Text>

      <Text
        className={classes.reviewCreatedAt}
        variant="textXs"
        color={isCurrentReview ? "grey.600" : "grey.400"}
      >
        {formatWithLocale(new Date(review.created), "PP p")}
      </Text>
    </Box>
  );
}

SharedCell.propTypes = {
  review: reviewProp.isRequired,
  activeReviewId: PropTypes.string.isRequired,
  reviewPanePosition: PropTypes.oneOf(["left", "right"]),
  onOpenReview: PropTypes.func.isRequired,
};

SharedCell.defaultProps = {
  reviewPanePosition: null,
};

export default SharedCell;
