import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { TableHead, TableRow, TableCell } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import classnames from "classnames";

import { Box, Text } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: theme.color.gray[50],
  },
  header: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover $sortingLabel": {
      opacity: 1,
    },
  },
  sortingLabel: {
    display: "flex",
    transition: `0.3s all ${theme.animation.easeOutQuint}`,
    opacity: 0,
    "& > svg": {
      width: 20,
      height: 20,
      marginLeft: theme.spacing(1),
      color: theme.color["black-translucent-35"],
    },
  },
  showSortingLabel: {
    opacity: 1,
    "& > svg": {
      color: theme.color.black,
    },
  },
}));

function MembersTableHeader({ headers, canManageMembers, onSortOrderChange }) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [sortDesc, setSortDesc] = useState(null);

  const changeSorting = (header) => {
    onSortOrderChange({
      order: sortDesc === null || sortDesc ? "asc" : "desc",
      orderBy: header.id,
      sortBy: header.sortBy,
    });
    setSortDesc((prev) => {
      if (prev === null) {
        return false;
      }
      return !prev;
    });
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header.id}>
            {header.label && (
              <Box
                className={classes.header}
                data-testid={`team-members-header-${header.id}`}
                onClick={() => changeSorting(header)}
              >
                <Text
                  color={theme.color["black-translucent-60"]}
                  variant="subtitle2"
                  fontWeight={theme.typography.fontWeightBold}
                >
                  {t(header.label)}
                </Text>
                <Box
                  className={classnames(classes.sortingLabel, {
                    [classes.showSortingLabel]: header.id === headers.orderBy,
                  })}
                >
                  {sortDesc ? (
                    <ArrowDownward
                      data-testid={`team-members-sorting-${header.id}-desc`}
                    />
                  ) : (
                    <ArrowUpward
                      data-testid={`team-members-sorting-${header.id}-asc`}
                    />
                  )}
                </Box>
              </Box>
            )}
          </TableCell>
        ))}

        {canManageMembers && <TableCell />}
      </TableRow>
    </TableHead>
  );
}

MembersTableHeader.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      sortBy: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  canManageMembers: PropTypes.bool.isRequired,
  onSortOrderChange: PropTypes.func.isRequired,
};

export default MembersTableHeader;
