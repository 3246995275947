import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { TableRow, TableCell, IconButton } from "@mui/material";
import { format } from "date-fns";

import { Text, Tooltip } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { apiKeyProps } from "@shared/props/apiKey";

import DeleteAPIKeyDialog from "../DeleteAPIKeyDialog/DeleteAPIKeyDialog";

export default function APIKeyListItem({ apiKey, onDelete }) {
  const { t } = useTranslation();
  const { isOpen, openDialog, closeDialog } = useDialogState();

  return (
    <>
      <TableRow data-testid="api-key-list-item">
        <TableCell>{format(new Date(apiKey.createdAt), "PP")}</TableCell>
        <TableCell>
          {apiKey.token.first}
          <Text component="span" sx={{ verticalAlign: "sub" }}>
            {new Array(20).fill("*")}
          </Text>
          {apiKey.token.last}
        </TableCell>
        <TableCell align="right">
          <Tooltip title={t("CORE.DELETE")} placement="bottom">
            <IconButton
              size="small"
              onClick={openDialog}
              data-testid="api-key-delete-button"
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <DeleteAPIKeyDialog
        apiKey={apiKey}
        onDelete={onDelete}
        isOpen={isOpen}
        closeDialog={closeDialog}
      />
    </>
  );
}

APIKeyListItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  apiKey: apiKeyProps.isRequired,
};
