import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { TableRow, TableCell } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { TEAM_ROLES } from "@supporting/constants/team";
import authenticationService from "@supporting/services/authentication";
import some from "lodash/some";

import { Avatar, Text } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { roleProp, teamProp } from "@shared/props/team";

import ChangeRoleButton from "./ChangeRoleButton/ChangeRoleButton";
import RemoveMemberMenu from "./RemoveMemberMenu/RemoveMemberMenu";

const useStyles = makeStyles({
  avatar: {
    width: 40,
    height: 40,
    margin: "0 auto",
    marginRight: 0,
  },
  menuCell: {
    minWidth: 100,
  },
});

function TeamMemberRow({ team, member, canManageMembers }) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const user = authenticationService.fetchUser();

  const isTeamMemberLastAdmin =
    member.role.isDefault &&
    member.role.roleName === TEAM_ROLES.ADMIN &&
    !some(team.members, (member) => {
      return (
        member.user._id !== user._id &&
        /* istanbul ignore next */
        member.role.roleName === TEAM_ROLES.ADMIN
      );
    });

  return (
    <TableRow data-testid="team-member-row">
      <TableCell>
        <Avatar user={member.user} className={classes.avatar} />
      </TableCell>
      <TableCell>
        <Text variant="h6" fontWeight={theme.typography.fontWeightBold}>
          {member.user.displayName}
        </Text>
      </TableCell>
      <TableCell>{member.user.email}</TableCell>
      <TableCell>
        {TEAM_ROLES[member.role.roleName]
          ? t(`TEAM.ROLES.${member.role.roleName}`)
          : member.role.roleName}
      </TableCell>
      {canManageMembers && (
        <TableCell className={classes.menuCell} align="right">
          <ChangeRoleButton
            isTeamMemberLastAdmin={isTeamMemberLastAdmin}
            team={team}
            member={member}
          />
          <RemoveMemberMenu
            isTeamMemberLastAdmin={isTeamMemberLastAdmin}
            team={team}
            member={member}
          />
        </TableCell>
      )}
    </TableRow>
  );
}

TeamMemberRow.propTypes = {
  team: teamProp.isRequired,
  member: PropTypes.shape({
    role: roleProp,
    user: userProp,
  }).isRequired,
  canManageMembers: PropTypes.bool.isRequired,
};
export default TeamMemberRow;
