import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Card, CardContent, Link } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Text } from "@shared/UIKit";

import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

import APIHeader from "./APIHeader/APIHeader";
import APIKeysList from "./APIKeysList/APIKeysList";

export default function APITab() {
  const { t } = useTranslation();
  const selectedTeam = useSelectedTeam();
  const [apiKeys, setAPIKeys] = useState([]);

  const fetchAPIKeys = useCallback(async () => {
    try {
      const fetchedApiKeys = await backend.get(
        `/api-token?team=${selectedTeam._id}`
      );
      setAPIKeys(fetchedApiKeys);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [selectedTeam]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchAPIKeys();
  }, [fetchAPIKeys]);

  return (
    <Card data-testid="api-tab-card">
      <APIHeader hasApiKeys={apiKeys.length > 0} fetchAPIKeys={fetchAPIKeys} />
      <CardContent>
        <Text variant="textSm">
          <Trans
            components={{
              anchor: (
                <Link
                  target="_blank"
                  color="primary"
                  href={t("API.HELP_CENTER.LEARN_MORE")}
                />
              ),
            }}
          >
            {t("INTEGRATIONS.API.CONNECTION.DESCRIPTION")}
          </Trans>
        </Text>
        <APIKeysList apiKeys={apiKeys} fetchAPIKeys={fetchAPIKeys} />
      </CardContent>
    </Card>
  );
}
