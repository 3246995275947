import { APP_ROUTES } from "@shared/constants/routes";
import { history } from "@shared/services/navigation";

export const handleRedirectToPendingVerifiedApproval = () => {
  const item = sessionStorage.getItem("pendingVerifiedApprovalContext");
  const pendingVerifiedApprovalContext = item ? JSON.parse(item) : {};

  const {
    pathBeforeRedirect,
    queryParamsBeforeRedirect,
    digitalSignatureRequired,
  } = pendingVerifiedApprovalContext;

  if (digitalSignatureRequired) {
    sessionStorage.setItem(
      "pendingVerifiedApprovalContext",
      JSON.stringify({})
    );
    history.navigate(
      `${pathBeforeRedirect}${queryParamsBeforeRedirect}${
        queryParamsBeforeRedirect ? "&" : "?"
      }pendingDigitalSignature=true`
    );
  } else {
    history.navigate(APP_ROUTES.DASHBOARD.path);
  }
};
