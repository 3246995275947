import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { PublishOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import noop from "lodash/noop";

import classes from "./VersionButton.module.css";

function VersionButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <Button
      variant="text"
      color="default-light"
      size="extraSmall"
      shape="rounded"
      className="tour__upload-new-version"
      classes={{
        root: classes.uploadVersionButton,
        startIcon: classes.uploadStartIcon,
      }}
      data-testid="file-upload-button-version"
      onClick={onClick}
      startIcon={<PublishOutlined fontSize="small" />}
    >
      {t("VERSION.UPLOAD")}
    </Button>
  );
}

VersionButton.propTypes = {
  onClick: PropTypes.func,
};

VersionButton.defaultProps = {
  onClick: noop,
};

export default VersionButton;
