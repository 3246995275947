const URL_VALIDATION_RESPONSES = {
  VALID: "VALID",
  INVALID_URL:
    "FILE.UPLOAD_PANEL.IMPORT_WEBSITE_INPUT_DIALOG.INVALID_URL_MESSAGE_KEY",
  INVALID_PROTOCOL:
    "FILE.UPLOAD_PANEL.IMPORT_WEBSITE_INPUT_DIALOG.INVALID_PROTOCOL_MESSAGE_KEY",
};

/**
 * URL Validation helper function
 *
 * @description This function is used for validating the URLs.
 * If the url doesn't have https protocol or invalid it'll return error.
 * Otherwise it'll return valid.
 * @param {string} url - [Required] URL string.
 * @returns {string} - Returns message key for validation return.
 */
const validateURL = (url) => {
  try {
    const urlData = new URL(url);
    if (urlData.protocol !== "https:") {
      return URL_VALIDATION_RESPONSES.INVALID_PROTOCOL;
    }
    return URL_VALIDATION_RESPONSES.VALID;
  } catch (error) {
    return URL_VALIDATION_RESPONSES.INVALID_URL;
  }
};

function isDefaultPort(protocol, port) {
  if (!port) {
    return true;
  }
  return (
    (protocol === "http:" && port === 80) ||
    (protocol === "https:" && port === 443)
  );
}

const getBaseURI = () => {
  let baseURI = `${window.location.protocol}//${window.location.hostname}`;
  if (!isDefaultPort(window.location.protocol, window.location.port)) {
    baseURI += `:${window.location.port}`;
  }
  return baseURI;
};

export default { validateURL, getBaseURI, URL_VALIDATION_RESPONSES };
