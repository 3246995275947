import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import browserTabId from "@shared/services/browserTabId";
import { instance as localisationService } from "@shared/services/localisationService";
import { instance as websocket } from "@shared/services/websocket";

const downloadFile = (url) => {
  const downloadIframe =
    document.getElementById("download-iframe") ||
    document.createElement("iframe");
  downloadIframe.width = 0;
  downloadIframe.height = 0;
  downloadIframe.id = "download-iframe";
  downloadIframe.src = null;
  document.body.appendChild(downloadIframe);
  downloadIframe.src = url;
};

const exportProgressStateById = {};

const isExportReportInProgress = (resourceId) => {
  return Boolean(exportProgressStateById[resourceId]);
};

const initialize = () => {
  const updateProgressState = (data) => {
    const resourceId = data.reviewId || data.fileId;
    const exportProgressState = exportProgressStateById[resourceId];

    if (exportProgressState) {
      exportProgressState.resolve();
      delete exportProgressStateById[resourceId];
    }
  };

  const onExportReportSuccess = (data) => {
    if (data.browserTabId !== browserTabId.get()) {
      return;
    }

    downloadFile(data.finalExportUrl);
    updateProgressState(data);
  };
  const onExportReportFail = (data) => {
    if (data.browserTabId !== browserTabId.get()) {
      return;
    }

    toastService.sendToast({
      title: "FILE_REPORT.ERROR_TOAST.TITLE",
      body: "FILE_REPORT.ERROR_TOAST.BODY",
      preset: toastService.PRESETS().ERROR_DELAYED,
    });

    updateProgressState(data);
  };

  websocket.addListener(
    websocket.EVENT_NAMES.EXPORT_REPORT.SUCCEEDED,
    onExportReportSuccess
  );
  websocket.addListener(
    websocket.EVENT_NAMES.EXPORT_REPORT.FAILED,
    onExportReportFail
  );
};

const exportReport = async (requestReport, resourceId) => {
  const user = authenticationService.fetchUser();
  const locale = localisationService.getLocale(user.language);

  toastService.sendToast({
    title: "FILE_REPORT.SUCCESS_TOAST.TITLE",
    body: "FILE_REPORT.SUCCESS_TOAST.BODY",
    preset: toastService.PRESETS().SUCCESS_DELAYED,
  });

  const exportPromise = new Promise((resolve) => {
    exportProgressStateById[resourceId] = { resolve };
  });

  try {
    await requestReport(locale);
  } catch (error) {
    delete exportProgressStateById[resourceId];
    toastService.sendToast({
      title: "FILE_REPORT.ERROR_TOAST.TITLE",
      body: "FILE_REPORT.ERROR_TOAST.BODY",
      preset: toastService.PRESETS().ERROR_DELAYED,
    });

    return;
  }

  return exportPromise;
};

const exportReviewReport = async (team, review, page, step) => {
  const isAnonymousReviewersEnabled =
    team.subscriptionLimits.isAnonymousReviewersEnabled &&
    step.settings.anonymousReviewers;

  const requestReport = (locale) =>
    backend.get(`/reviews/${review.id}/report`, {
      browserTabId: browserTabId.get(),
      locale,
    });

  await exportReport(requestReport, review.id);

  analytics.track(analytics.ACTION.EXPORTED, analytics.CATEGORY.REVIEW_REPORT, {
    page,
    anonymousReviewers: isAnonymousReviewersEnabled,
  });
};

const exportFileReport = async (fileId, page) => {
  analytics.track(analytics.ACTION.EXPORTED, analytics.CATEGORY.FILE_REPORT, {
    page,
  });

  const requestReport = (locale) =>
    backend.post(`/files/${fileId}/report`, {
      browserTabId: browserTabId.get(),
      locale,
    });

  await exportReport(requestReport, fileId);

  analytics.track(analytics.ACTION.DOWNLOADED, analytics.CATEGORY.FILE_REPORT);
};

export default {
  exportReviewReport,
  exportFileReport,
  initialize,
  isExportReportInProgress,
};
