export default Object.freeze({
  ANNOTATION: {
    ON_TOUCH_DEVICES: {
      ENABLED: "event.annotation.on-touch-devices.enabled",
      DISABLED: "event.annotation.on-touch-devices.disabled",
    },
    ON_MOBILE_DEVICES: {
      ENABLED: "event.annotation.on-mobile-devices.enabled",
      DISABLED: "event.annotation.on-mobile-devices.disabled",
    },
    TOOL: {
      CHANGED: "event.annotation.tool-changed",
    },
    COLOR: {
      CHANGED: "event.annotation.color.changed",
    },
  },
  BACKDROP: {
    SHOWED: "event.backdrop.showed",
    HID: "event.backdrop.hid",
    CLICKED: "event.backdrop.clicked",
  },
  COMMENT_LIST: {
    SORT_OPTION: {
      SELECTED: "event.comment-list.sort-option.selected",
    },
    HIDE_RESOLVED_FILTER: {
      SELECTED: "event.comment-list.hide-resolved-filter.selected",
    },
  },
  DRAG_AND_DROP: {
    STARTED: "event.dnd.started",
    CANCELED: "event.dnd.canceled",
    ENDED: "event.dnd.ended",
    DROPPED_TO_LIST: "event.dnd.dropped-to-list",
    WAIT_FOR_RESPONSE: "event.dnd.wait-for-response",
    DROPPED_TO_LIST_CANCELED: "event.dnd.dropped-to-list-canceled",
  },
  DRAG_SCROLL: {
    DISABLED: "event.drag-scroll.disabled",
    ENABLED: "event.drag-scroll.enabled",
  },
  FILE_SERVICE: {
    PROJECT_FILES: {
      UPDATED: "event.file-service.project-files.updated",
    },
  },
  FILE: {
    UPDATED: "event.file.updated",
    REMOVED: "event.file.removed",
    UPLOAD: {
      STARTED: "event.file.upload.started",
      FINISHED: "event.file.upload.finished",
      CANCELLED: "event.file.upload.cancelled",
    },
  },
  REVIEW: {
    CREATED: "event.review.created",
    REMOVED: "event.review.removed",
    UPDATED: "event.review.updated",
    REMINDER: {
      SHOW: "event.review.reminder.show",
    },
    DECISIONS: {
      SUBMITTED: "event.review.decisions.submitted",
      UNDO: "event.review.decisions.undo",
      UPDATED: "event.review.decisions.updated",
    },
  },
  REVIEWS: {
    UPDATED: "event.reviews.updated",
  },
  WORKSPACE: {
    FILE_SWITCHED: "event.workspace.file-switched",
    SIDEBAR: {
      OPENED: "event.workspace.sidebar.opened",
      CLOSED: "event.workspace.sidebar.closed",
    },
  },
  ISSUE: {
    CREATED: "event.issue.created",
    UPDATED: "event.issue.updated",
    PINNED_OR_UNPINNED: "event.issue.pinned-or-unpinned",
    DELETED: "event.issue.deleted",
    EDITED: "event.issue.edited",
    HOVERED: "event.issue.hovered",
    LEFT: "event.issue.left",
    ACTIVATED: "event.issue.activated",
    CLICKED: "event.issue.clicked",
    GENERAL_COMMENT: {
      CREATED: "event.issue.general-comment.created",
    },
    CREATING_OR_EDITING: {
      STARTED: "event.issue.creating-or-editing.started",
      FINISHED: "event.issue.creating-or-editing.finished",
    },
    DRAW_ANNOTATION_STATE: {
      CHANGED: "event.issue.draw-annotation-state.changed",
    },
    DRAWINGS: {
      CREATED: "event.issue.drawings.created",
      DELETED: "event.issue.drawings.deleted",
      UNDONE_REDONE: "event.issue.drawings.undone-redone",
    },
    STATES: {
      ON_ZOOM: "event.issue.states.on-zoom",
      VIDEO_ACTION: "event.issue.states.video_action",
      FOCUS_ON_MARKER: "event.issue.states.focus-on-marker",
    },
    CONSOLIDATED_ISSUE: {
      CLICKED: "event.issue.consolidated-comment.clicked",
    },
  },
  COMMENT: {
    LIST: {
      UPDATED: "event.comment.list.updated",
    },
    PRIVACY_FLAG: {
      CHANGED: "event.comment.privacy-flag.changed",
    },
    FOCUS_INPUT_FORM: "event.comment.focus-input-form",
  },
  LANGUAGE: {
    CHANGED: "event.language.changed",
  },
  MARKER: {
    CLICKED: "event.marker.clicked",
    CLICKED_DISABLED: "event.marker.clicked-disabled",
    HOVERED: "event.marker.hovered",
    LEFT: "event.marker.left",
    MOVED: "event.marker.moved",
    CREATED: "event.marker.created",
    FOCUSED: "event.marker.focused",
    HIGHLIGHTED: "event.marker.highlighted",
    UNHIGHLIGHTED: "event.marker.unhighlighted",
  },
  MARKERS: {
    VISIBILITY_CHANGED: "event.markers.visibility-changed",
  },
  FOLDERS: {
    UPDATED: "event.folders.updated",
    CREATED: "event.folders.created",
  },
  PROJECT: {
    ADDED: "event.project.added",
    UPDATED: "event.project.updated",
    REMOVED: "event.project.removed",
    SELECTED: "event.project.selected",
    OPENED: "event.project.opened",
    COLLABORATOR: {
      ADDED: "event.project.collaborator.added",
      REMOVED: "event.project.collaborator.removed",
    },
    SECTION: {
      CREATED: "event.project.section.created",
      MOVED: "event.project.section.moved",
      RENAMED: "event.project.section.renamed",
      REMOVED: "event.project.section.removed",
      COLLAPSED: "event.project.section.collapsed",
    },
    CACHE: {
      UPDATE: "event.project.cache.update",
    },
    SECTION_FILES: {
      UPDATED: "event.project.section-files.updated",
    },
    SECTIONS: {
      UPDATED: "event.project.sections.updated",
      COLLAPSED: "event.project.sections.collapsed",
    },
  },
  REVIEWER_PROJECTS: {
    UPDATED: "event.reviewer-projects.updated",
  },
  FAVORITE_PROJECTS: {
    UPDATED: "event.favorite-projects.updated",
  },
  DASHBOARD: {
    SIDEBAR: {
      COLLAPSE_FOLDERS: "event.dashboard.sidebar.collapse-folders",
    },
  },
  TEAM: {
    COUPON_CHANGED: "event.team.coupon-changed",
    ROLES: {
      UPDATED: "event.team.roles.updated",
    },
    SELECTED: {
      ADDED: "event.team.selected.added",
      UPDATED: "event.team.selected.updated",
    },
    TEAMS: {
      UPDATED: "event.teams.updated",
    },
  },
  TRANSCODING: {
    PROGRESS: "event.transcoding.progress",
  },
  UPLOAD: {
    PROGRESS: "event.upload.progress",
    ERROR: "event.upload.error",
  },
  USER: {
    AUTHENTICATED: "event.user.authenticated",
    PLUGIN_AUTHENTICATION: "event.user.plugin-authentication",
    LOGGED_IN: "event.user.logged-in",
    LOGGED_OUT: "event.user.logged-out",
    SIGNED_UP: "event.user.signed-up",
    UPDATED: "event.user.updated",
  },
  VERSION: {
    ADDED: "event.version.added",
    REMOVED: "event.version.removed",
    COMMENT: {
      UPDATED: "event.version.comment.updated",
    },
  },
  VERSION_TREE: {
    EDGES: {
      UPDATED: "event.version-tree.edges.updated",
    },
  },
  VIDEO: {
    RESIZED: "event.video.resized",
    PLAYER_READY: "event.video.player-ready",
    LOOP_CHANGED: "event.video.loop-changed",
  },
  VIEWER: {
    MARKER: {
      CREATED: "event.viewer.marker.created",
    },
    DISPLAY_FORMAT: {
      CHANGED: "event.viewer.display-format.changed",
    },
    STEP_FILES: {
      DISPLAY: "event.viewer.step-files.display",
    },
  },
  WEBSITE_REVIEW: {
    REVIEW_DIMENSION_CHANGED: "event.website-review.review-dimension-changed",
  },
  NAVIGATION_BAR: {
    BACK_BUTTON: {
      UPDATED: "event.navigation-bar.back-button.updated",
    },
  },
  PROJECT_STEPS: {
    UPDATED: "event.project-steps.updated",
  },
  PROJECT_STEPS_WITHOUT_FILES: {
    UPDATED: "event.project-without-steps.updated",
  },
  STEP: {
    CREATED: "event.step.created",
    REMOVED: "event.step.removed",
    UPDATED: "event.step.updated",
    REVIEWERS: {
      UPDATED: "event.step.reviewers-updated",
    },
  },
  STEP_TEMPLATE: {
    REMOVED: "event.step-template.removed",
    SETTINGS: {
      UPDATED: "event.step-template.settings.updated",
    },
  },
  PENDING_REVIEWS: {
    NAV_BUTTON: {
      CLICKED: "event.pending-reviews.navButton.clicked",
    },
    DRAWER: {
      CLOSE_TRIGGERED: "event.pending-reviews.drawer.close-triggered",
    },
    UPDATED: "event.pending-reviews.updated",
  },
  PROJECT_TEMPLATES: {
    UPDATED: "event.project-templates.updated",
  },
  PROJECT_TEMPLATE: {
    UPDATED: "event.project-template.updated",
    REMOVED: "event.project-template.removed",
  },
  NOTIFICATION_CENTER: {
    ICON: {
      CLICKED: "event.notification-center.icon.clicked",
    },
    DRAWER: {
      CLOSE_TRIGGERED: "event.notification-center.drawer.close-triggered",
    },
    INDICATOR: {
      ENABLED: "event.notification-center.indicator.enabled",
      DISABLED: "event.notification-center.indicator.disabled",
    },
    NOTIFICATIONS: {
      FETCHED: "event.notification-center.notifications.fetched",
      ADDED: "event.notification-center.notifications.added",
    },
    ISSUE: {
      CLICKED: "event.notification-center.issue.clicked",
    },
  },
  COMPARE_REVIEWS: {
    CHANGES_SIDEBAR_OPENED: "event.compare-reviews.changes-sidebar-opened",
    SIDEBAR_TOGGLED: "event.compare-reviews.sidebar-toggled",
  },
  PDFTRON_VIEWER: {
    LOADED: "event.pdftron-viewer.loaded",
    ANNOTATION: {
      CHANGED: "event.pdftron-viewer.annotation.changed",
      HOVER_STATE_CHANGED:
        "event.pdftron-viewer.annotation.hover-state-changed",
      CLICKED: "event.pdftron-viewer.annotation.clicked",
    },
    DOCUMENT: {
      LOADING_FAILED: "event.pdftron-viewer.document.loading-failed",
    },
  },
  INTEGRATIONS: {
    DISCONNECTED: "event.integrations.disconnected",
    SLACK_APP: {
      PROJECT_CONNECTED: "event.integrations.slack-app.project-connected",
      PROJECT_DISCONNECTED: "event.integrations.slack-app.project-disconnected",
    },
    GOOGLE_DRIVE: {
      PROJECT_CONNECTED: "event.integrations.google-drive.project-connected",
      PROJECT_DISCONNECTED:
        "event.integrations.google-drive.project-disconnected",
    },
  },
  INTERCOM: {
    ON_UNREAD_COUNT_CHANGE: "event.intercom.on-unread-count-change",
  },
});
