import PropTypes from "prop-types";
import { useCallback, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, IconButton, Box } from "@mui/material";
import copy from "copy-to-clipboard";

import { Text, Popper } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

import ConfirmationCheckbox from "../ConfirmationCheckbox/ConfirmationCheckbox";

export default function DisplayAPIKeyDialogContent({
  apiKey,
  onContinue,
  isConfirmed,
  isCheckboxError,
  toggleConfirmationCheckbox,
}) {
  const { t } = useTranslation();
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const copiedTimeout = useRef(null);

  const onPopperOpen = useCallback(() => {
    setIsPopperOpen(true);
  }, []);

  const handleCopyApiKey = useCallback(() => {
    copy(apiKey, { format: "text/plain" });

    if (copiedTimeout.current) {
      clearTimeout(copiedTimeout.current);
    }

    copiedTimeout.current = setTimeout(() => {
      setIsPopperOpen(false);
    }, 2000);

    analytics.track(
      analytics.ACTION.COPIED,
      analytics.CATEGORY.INTEGRATIONS.API_KEY,
      {
        token: `****${apiKey.substring(apiKey.length - 6)}`,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey]);

  return (
    <Box maxWidth={578} boxSizing="border-box">
      <Box
        gap={1}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Text
          variant="textXs"
          fontWeight="fontWeight.medium"
          translate="INTEGRATIONS.API.GENERATE_POP_OVER.TITLE.INPUT_TITLE"
        />
        <Box display="flex" alignItems="center" mt={1.5} mb={1.5}>
          <Box
            mr={0.75}
            padding="18px 10px"
            borderRadius={1}
            border="1px solid var(--mui-palette-grey-400)"
          >
            <Text variant="textSm" data-testid="api-key-text">
              {apiKey}
            </Text>
          </Box>
          <Popper
            sx={{
              "& > div > div > div": {
                maxWidth: 68,
                textAlign: "center",
                boxSizing: "border-box",
                padding: "6px 12px",
                marginTop: "8px",
              },
            }}
            size="small"
            actionVariant="click"
            onOpen={onPopperOpen}
            isClosed={!isPopperOpen}
            content={
              <Text
                variant="textSm"
                component="span"
                data-testid="copied-popper-text"
                color="success.main"
                fontWeight="fontWeight.medium"
              >
                {t("INTEGRATIONS.API.KEY_COPIED_MESSAGE")}
              </Text>
            }
            disablePortal
          >
            <IconButton
              onClick={handleCopyApiKey}
              data-testid="api-key-copy-button"
              size="large"
            >
              <ContentCopyIcon fontSize="inherit" />
            </IconButton>
          </Popper>
        </Box>
        <Box mb={2}>
          <Text
            variant="body2"
            fontSize={FSTGTypography.fontSize_1_5}
            translate="INTEGRATIONS.API.GENERATE_POP_OVER.DESCRIPTION"
          />
        </Box>
        <ConfirmationCheckbox
          isError={isCheckboxError}
          isConfirmed={isConfirmed}
          toggleCheckbox={toggleConfirmationCheckbox}
          errorMessageText={t("INTEGRATIONS.API.ERROR_MESSAGE")}
          label={t("INTEGRATIONS.API.SAVE_KEY.WARNING_MESSAGE")}
        />
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={onContinue}
          data-testid="continue-button"
        >
          {t("CORE.CONTINUE")}
        </Button>
      </Box>
    </Box>
  );
}

DisplayAPIKeyDialogContent.propTypes = {
  apiKey: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
  isCheckboxError: PropTypes.bool.isRequired,
  toggleConfirmationCheckbox: PropTypes.func.isRequired,
};
