const getShareLinkUrl = ({ stepId, fileId, reviewId }) => {
  let url = `${fs.config.appBase}/step/${stepId}`;

  if (reviewId) {
    url += `/review/${reviewId}`;
    return url;
  }

  if (fileId) {
    url += `/file/${fileId}`;
    return url;
  }

  return url;
};

const getBatchReviewLinkUrl = (stepId, fromReviewId) => {
  let url = `${fs.config.appBase}/step/${stepId}/batch-review`;

  if (fromReviewId) {
    url += `?reviewId=${fromReviewId}`;
  }

  return url;
};

export default { getShareLinkUrl, getBatchReviewLinkUrl };
