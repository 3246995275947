import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { LoadingSpinner } from "@shared/UIKit";

function PendingReviewsPaginationLoading({ isPaginationLoading }) {
  return (
    isPaginationLoading && (
      <Box
        position="absolute"
        bottom={0}
        height={75}
        zIndex={9999}
        width="95%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        data-testid="pending-reviews-pagination-loading"
        sx={{
          background:
            "linear-gradient(171.68deg, #FFFFFF 6.38%, rgba(255, 255, 255, 0) 321.29%);",
        }}
      >
        <LoadingSpinner />
      </Box>
    )
  );
}

PendingReviewsPaginationLoading.propTypes = {
  isPaginationLoading: PropTypes.bool.isRequired,
};

export default PendingReviewsPaginationLoading;
