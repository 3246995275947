import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { TextField } from "@mui/material";
import { z } from "zod";

import { Box } from "@shared/UIKit";

const createProjectTemplateSchema = z.object({
  projectTemplateName: z
    .string()
    .min(1, "VALIDATIONS.PROJECT_TEMPLATE_NAME.REQUIRED"),
});

const CreateProjectTemplateForm = ({ saveProjectTemplate }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(createProjectTemplateSchema),
    defaultValues: {
      projectTemplateName: "",
    },
    mode: "onChange",
  });

  return (
    <Box
      component="form"
      id="create-project-template-form"
      onSubmit={handleSubmit(saveProjectTemplate)}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      flex={1}
    >
      <Controller
        control={control}
        name="projectTemplateName"
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            inputProps={{
              "data-testid": "project-template-name-input",
            }}
            label={t("PROJECT_TEMPLATES.ADD.LABEL_NAME")}
            error={Boolean(errors.projectTemplateName)}
            helperText={t(errors.projectTemplateName?.message)}
          />
        )}
      />
    </Box>
  );
};

CreateProjectTemplateForm.propTypes = {
  saveProjectTemplate: PropTypes.func.isRequired,
};

export default CreateProjectTemplateForm;
