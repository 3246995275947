import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box, Link } from "@mui/material";
import EmailInput from "@supporting/components/EmailInput/EmailInput";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";
import PasswordField from "@supporting/components/PasswordField/PasswordField";
import authenticationService from "@supporting/services/authentication";

import { Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import nativeWarningHandlerService from "@shared/services/nativeWarningHandlerService";
import FSTGTypography from "@shared/theme/typography";

export default function ReviewerPasswordContent({
  email,
  onSubmit,
  authRouteState,
  isAwaitingReview,
}) {
  const { t } = useTranslation();
  const [passwordErrorText, setPasswordErrorText] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const forgotPasswordUrl = `${APP_ROUTES.PASSWORD_FORGOT.path}?email=${email}`;
  const [authState, setAuthState] = useState({
    email,
    isEmailValid: true,
  });
  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  async function onSubmitPassword(event) {
    event.preventDefault();
    healthMetrics.trackStart("supporting.authentication");
    setIsLoading(true);
    healthMetrics.trackStart("supporting.authentication-pwd");

    try {
      if (!authState.isEmailValid) {
        return;
      }
      nativeWarningHandlerService.unregisterUnloadWarning();
      await authenticationService.login(
        {
          email: authState.email,
          password,
        },
        authRouteState,
        true
      );
      healthMetrics.trackSuccess("supporting.authentication");
      healthMetrics.trackSuccess("supporting.authentication-pwd");
      onSubmit("CLOSE_DIALOG");
    } catch (error) {
      if (error.status === 403 || error.status === 400) {
        healthMetrics.trackSuccess("supporting.authentication");
        healthMetrics.trackSuccess("supporting.authentication-pwd");
        setPasswordErrorText("LOGIN.REVIEWER.PASSWORD.ERROR");
      } else {
        healthMetrics.trackFailure("supporting.authentication", error);
        healthMetrics.trackFailure("supporting.authentication-pwd", error);
        setPasswordErrorText("ERROR.SERVER_NOT_AVAILABLE");
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Box component="form" noValidate onSubmit={onSubmitPassword}>
      <Text
        sx={{ mb: 6 }}
        variant="body2"
        translate="REVIEWER.ENTER_PASSWORD.DESCRIPTION"
        textAlign="center"
      />
      {isAwaitingReview && (
        <EmailInput
          updateAuthState={setAuthState}
          defaultErrorMessage="FORM.EMAIL_CURRENT.MESSAGE_REQUIRED"
          label="FORM.EMAIL.LABEL"
          defaultValue={email}
          autoFocusEmail={email === ""}
        />
      )}
      <PasswordField
        id="password"
        name="password"
        testId="password"
        value={password}
        onChange={handlePassword}
        errorTranslate={passwordErrorText}
        error={Boolean(passwordErrorText)}
        labelTranslate="FORM.PASSWORD.LABEL"
        autoFocus={email !== ""}
        autoComplete="current-password"
      />
      <Box
        display="flex"
        alignItems="end"
        justifyContent="end"
        fontSize={FSTGTypography.fontSize_1_3}
        mb={1.9}
      >
        <Link
          href={forgotPasswordUrl}
          target="_blank"
          data-testid="navigate-forgot-password"
        >
          {t("LOGIN.FORGOTPASSWORD")}
        </Link>
      </Box>
      <Button
        sx={{ mt: 2 }}
        fullWidth
        variant="contained"
        disabled={!password.length}
        type="submit"
        loading={isLoading}
        data-testid="workspace-auth-dialog-password-button"
      >
        {t("LOGIN.SUBMIT")}
      </Button>
      {isAwaitingReview && (
        <>
          <GoogleAuth
            testId="sign-in-google"
            text="signin_with"
            authState={authRouteState}
            shareSessionStepId={authRouteState.shareSessionStepId}
          />
          <Text color="text.secondary" textAlign="center" mt={2}>
            {t("SUBTEXT.TOSIGNUP_TEXT")}
            <Link
              onClick={() => onSubmit("SHOW_CREATE_ACCOUNT")}
              sx={{ pl: 1 }}
            >
              {t("SUBTEXT.TOSIGNUP_LINK")}
            </Link>
          </Text>
        </>
      )}
    </Box>
  );
}
ReviewerPasswordContent.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  authRouteState: PropTypes.object.isRequired,
  isAwaitingReview: PropTypes.bool,
};
ReviewerPasswordContent.defaultProps = {
  isAwaitingReview: false,
};
