import { useQuery } from "@tanstack/react-query";

import { BULK_DOWNLOAD_KEYS } from "@shared/query-keys";
import backend from "@shared/services/backendClient";

export const useBulkDownload = (processId) => {
  return useQuery({
    queryKey: BULK_DOWNLOAD_KEYS.detail(processId),
    queryFn: async () => {
      const response = await backend.get(`/bulk-download/${processId}`);
      return response;
    },
    enabled: Boolean(processId),
  });
};
