import PropTypes from "prop-types";

import { Popper, Fade, ClickAwayListener, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import StepReviewersList from "../StepReviewersList/StepReviewersList";

const useStyles = makeStyles(() => ({
  Popper: {
    zIndex: 79,
  },
  Paper: {
    marginTop: 5,
  },
}));

function ExtraReviewersPopper({
  users,
  userListAnchorEl,
  closeUsersPopper,
  teamId,
  step,
  canManageProjectMembers,
  isProjectArchived,
  usersIndexFrom,
  usersIndexTo,
  team,
  canManageProjectTemplates,
  isProjectTemplate,
}) {
  const classes = useStyles();

  const isUserListOpen = Boolean(userListAnchorEl);

  return (
    <Popper
      className={classes.Popper}
      open={isUserListOpen}
      anchorEl={userListAnchorEl}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box>
            <ClickAwayListener
              touchEvent="onTouchStart"
              onClickAway={closeUsersPopper}
            >
              <Paper className={classes.Paper} elevation={4}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="flex-start"
                  pt={1.625}
                  pb={1.625}
                  pl={2}
                  pr={2}
                  maxWidth={330}
                  ml={0.625}
                  data-testid="step-reviewers-extra-avatars"
                >
                  <StepReviewersList
                    users={users}
                    teamId={teamId}
                    step={step}
                    canManageProjectMembers={canManageProjectMembers}
                    isProjectArchived={isProjectArchived}
                    usersIndexFrom={usersIndexFrom}
                    usersIndexTo={usersIndexTo}
                    team={team}
                    canManageProjectTemplates={canManageProjectTemplates}
                    isProjectTemplate={isProjectTemplate}
                  />
                </Box>
              </Paper>
            </ClickAwayListener>
          </Box>
        </Fade>
      )}
    </Popper>
  );
}

ExtraReviewersPopper.propTypes = {
  users: PropTypes.arrayOf(userProp).isRequired,
  closeUsersPopper: PropTypes.func.isRequired,
  userListAnchorEl: PropTypes.object,
  teamId: PropTypes.string.isRequired,
  step: stepProps.isRequired,
  canManageProjectMembers: PropTypes.bool.isRequired,
  isProjectArchived: PropTypes.bool,
  usersIndexFrom: PropTypes.number.isRequired,
  usersIndexTo: PropTypes.number.isRequired,
  team: teamProp.isRequired,
  canManageProjectTemplates: PropTypes.bool,
  isProjectTemplate: PropTypes.bool,
};

ExtraReviewersPopper.defaultProps = {
  userListAnchorEl: null,
  isProjectArchived: false,
  canManageProjectTemplates: false,
  isProjectTemplate: false,
};

export default ExtraReviewersPopper;
