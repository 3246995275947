import PropTypes from "prop-types";
import { ReactElement } from "react";

import cx from "clsx";

import classes from "./InlineBadge.module.css";

/**
 * UIKit Inline Badge Component
 * @description Renders a small badge that can hold a number or string.
 * It is inline with the text and can be positioned on the any side of the text.
 * @param {object} props - Component props
 * @param {string|number} [props.children] - [Required] Children components to render. Can be a string or number.
 * @param {string} [props.className] - [Optional] Additional class name for styling
 * @param {"contained" | "outlined" | "text"} props.variant = "contained" - [Optional] Variant of the badge. Can be "contained" or "outlined" or "text".
 * @param {"primary" | "secondary" | "info" | "success" | "error" | "primaryV2" | "accent" | "warning"} props.color = "primary" - [Optional] Color of the badge. Can be any themed color.
 * @param {"rounded" | "circle" | "square"} props.shape = "rounded" - [Optional] Shape of the badge. Can be "rounded" or "squared" or "round".
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} props.size = "sm" - [Optional] Size of the badge. Can be "xs or "sm" or "md" or "lg" or "xl".
 * @returns {ReactElement} - React component
 */
function InlineBadge({
  children,
  color,
  variant,
  shape,
  size,
  className,
  ...restProps
}) {
  return (
    // eslint-disable-next-line react/forbid-elements
    <div
      className={cx(
        classes.inlineBadge,
        classes[variant],
        classes[shape],
        className
      )}
      data-accent-color={color}
      data-typography-size={size}
      {...restProps}
    >
      {children}
    </div>
  );
}

InlineBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  shape: PropTypes.oneOf(["rounded", "square", "circle"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "error",
    "accent",
    "primaryV2",
    "default-light",
  ]),
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  className: PropTypes.string,
};

InlineBadge.defaultProps = {
  variant: "contained",
  shape: "rounded",
  color: "primary",
  size: "md",
  className: "",
};

export default InlineBadge;
