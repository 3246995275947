import { useState } from "react";

import { LoadingButton as Button } from "@mui/lab";
import { Grid, Paper, FormControl, TextField } from "@mui/material";
import Title from "@supporting/components/Admin/Title";

import { Text, Box } from "@shared/UIKit";

import backendClient from "@shared/services/backendClient";

function ChangeUserEmail() {
  const [newEmail, setNewEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentEmail, setCurrentEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      await backendClient.put("/admin/change-user-email", {
        newEmail,
        currentEmail,
      });
      setSuccessMessage("Email changed");
      setIsLoading(false);
    } catch (response) {
      setIsLoading(false);
      if (response.status === 404) {
        setErrorMessage("Couldn't find an account with `Current Email`");
        return;
      }
      if (response.message === "operation contains duplicate key") {
        setErrorMessage("An account already exists for `New Email`");
        return;
      }
      setErrorMessage(response.message);
    }
  };

  return (
    <>
      <Title>Change user email</Title>
      <Text variant="h6" gutterBottom>
        This utility is mainly to change emails of customers who has Google
        login or SSO login. All other users can change their emails by their own
        from profile tab. Still this utility can used to change any
        customer&apos;s email.
      </Text>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            padding: 5,
            height: 240,
            display: "flex",
            overflow: "auto",
            flexDirection: "column",
          }}
        >
          <Box display="flex" flexDirection="column" gap={2.5}>
            <FormControl sx={{ flex: 1, maxWidth: 400, gap: 1 }}>
              <TextField
                required
                type="email"
                value={currentEmail}
                label="Current Email"
                onChange={(event) => setCurrentEmail(event.target.value)}
              />
              <TextField
                required
                type="email"
                name="newEmail"
                value={newEmail}
                label="New Email"
                onChange={(event) => setNewEmail(event.target.value)}
              />
            </FormControl>
            <Button
              type="success"
              loading={isLoading}
              variant="contained"
              onClick={handleClick}
              sx={{ maxWidth: 400 }}
            >
              Change email
            </Button>
            {errorMessage && (
              <Box my={2} ml={5} data-testid="form-error">
                <Text color="error.main">{errorMessage}</Text>
              </Box>
            )}
            {successMessage && (
              <Box my={2} ml={1} data-testid="form-success">
                <Text color="success.main">{successMessage}</Text>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
    </>
  );
}

export default ChangeUserEmail;
