import { ReactElement } from "react";

import classNames from "classnames";

import { Box } from "@shared/UIKit";

import classes from "./LoadingSpinner.module.css";

/**
 * UIKit Loading Spinner Component
 * @description Renders the official loading spinner.
 * @returns {ReactElement} React component
 */
const LoadingSpinner = () => {
  return (
    <Box
      className="loading-spinner"
      flexDirection="column"
      alignContent="center center"
      textAlign="center"
      width={114}
      data-testid="loading-spinner"
    >
      <Box className={classNames(classes.circle, classes.circle_1)} />
      <Box className={classNames(classes.circle, classes.circle_2)} />
      <Box className={classNames(classes.circle, classes.circle_3)} />
    </Box>
  );
};

export default LoadingSpinner;
