import { useTranslation } from "react-i18next";

import VIEWER_DISPLAY_FORMATS from "@feedback/constants/viewerDisplayFormats";
import { getFileExtension } from "@feedback/helpers/getVersionFileExtension";
import { fsMarkerVideoDataFormatFilter } from "@feedback/services/marker";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import {
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { format } from "date-fns";

import SubMenuItem from "@shared/UIKit/SubMenu/SubMenu";

import * as dateutils from "@shared/helpers/dateutils";
import { versionProp } from "@shared/props/file";
import { instance as analytics } from "@shared/services/analytics";

const getMetaDataText = (version, t) => {
  const { original } = version;
  const metaData = [];

  metaData.push(getFileExtension(version));

  if (original && original.metaData) {
    if (original.metaData.width && original.metaData.height) {
      metaData.push(`${original.metaData.width}x${original.metaData.height}`);
    }

    if (original.metaData.duration && original.metaData.frameRate) {
      metaData.push(
        ` ${fsMarkerVideoDataFormatFilter(
          {
            time: original.metaData.duration / 1000,
            frameRate: original.metaData.frameRate,
          },
          VIEWER_DISPLAY_FORMATS.VIDEO.STANDARD,
          version.id
        )} ${t("VIDEO.TIME.MINUTE_ABBREVIATION")}`
      );
    }

    if (original.metaData.frameRate) {
      metaData.push(`${original.metaData.frameRate} fps`);
    }
  }

  return metaData.join(", ");
};

const MetadataTable = ({ version }) => {
  const { t } = useTranslation();

  const onInfoTableOpen = () => {
    analytics.track(
      analytics.ACTION.VIEWED,
      analytics.CATEGORY.FILE_INFORMATION
    );
  };

  const tableInfo = [
    {
      label: t("CORE.VERSION"),
      value: version.number,
    },
    {
      label: t("FILE.MENU.ORIGINAL_NAME"),
      value: version.name,
    },
    {
      label: t("FILE.MENU.FILE_TYPE"),
      value: `${t(`FILE.FILETYPE.${version.mediaType}`)} (${getMetaDataText(
        version,
        t
      )})`,
    },
    {
      label: t("FILE.MENU.UPLOADED_BY"),
      value: version.uploadedByUser.displayName,
    },
    {
      label: t("FILE.MENU.UPLOADED_ON"),
      value: format(
        new Date(version.createdAt),
        "ccc, LLL d, yyyy 'at' p",
        dateutils.getCurrentLocaleForDateFns()
      ),
    },
  ];

  return (
    <SubMenuItem
      menuItem={
        <>
          <ListItemIcon>
            <InfoOutlined />
          </ListItemIcon>
          <ListItemText>{t("FILE.MENU.MORE_INFORMATION")}</ListItemText>
        </>
      }
      menuItemProps={{
        "data-testid": "project-file-menu-information",
      }}
      onOpen={onInfoTableOpen}
    >
      <Table
        size="small"
        data-testid="project-file-menu-information-content"
        sx={{
          border: "none",
          "& .MuiTableRow-root": {
            "& .MuiTableCell-root": {
              borderBottom: "none",
              fontSize: "textXs.fontSize",
              color: "text.secondary",
            },
            "& .MuiTableCell-root:last-child": {
              fontWeight: "fontWeight.medium",
            },
          },
        }}
      >
        <TableBody>
          {tableInfo.map((info) => (
            <TableRow key={info.label}>
              <TableCell>{info.label}:</TableCell>
              <TableCell>{info.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SubMenuItem>
  );
};
MetadataTable.displayName = "MetadataTable";

MetadataTable.propTypes = {
  version: versionProp.isRequired,
};

export default MetadataTable;
