import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Close } from "@mui/icons-material";
import { IconButton, CircularProgress } from "@mui/material";
import projectService from "@workflow/services/projectService";

import { Tooltip } from "@shared/UIKit";

import { folderProps } from "@shared/props/project";

export default function DeleteFolder({ folder }) {
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const deleteFolder = async () => {
    setIsDeleting(true);
    const response = await projectService.removeFolder(folder);
    if (!response) {
      setIsDeleting(false);
    }
  };

  return (
    <Tooltip title={t("PROJECT.TOOLTIPS.DELETE_FOLDER")} placement="top">
      <IconButton
        data-testid="folder-header-action-delete"
        size="extraSmall"
        onClick={deleteFolder}
      >
        {isDeleting ? (
          <CircularProgress size={16} />
        ) : (
          <Close fontSize="inherit" />
        )}
      </IconButton>
    </Tooltip>
  );
}

DeleteFolder.propTypes = {
  folder: PropTypes.shape(folderProps).isRequired,
};
