export default Object.freeze({
  VIDEO: {
    DISPLAY_FORMAT_KEY: "VIEWER_VIDEO:FS_VIDEO_DISPLAY_FORMAT",
    VOLUME_LEVEL_KEY: "VIEWER_VIDEO:FS_VOLUME_LEVEL",
  },
  AUDIO: {
    DISPLAY_FORMAT_KEY: "VIEWER_AUDIO:FS_VIDEO_DISPLAY_FORMAT",
    VOLUME_LEVEL_KEY: "VIEWER_AUDIO:FS_VOLUME_LEVEL",
  },
  DOCUMENT: {
    ZOOM_MODE_KEY: "VIEWER_DOCUMENT:FS_DOCUMENT_ZOOM_MODE",
  },
  IMAGE: {
    ZOOM_MODE_KEY: "VIEWER_IMAGE:FS_IMAGE_ZOOM_MODE",
  },
});
