/**
 * Constants describing the dfferent  types of marker.
 * Dependent on marker type, attribute data holds different
 *
 * Every class can contain an attribute SORTER of type String or Array, such
 * that compare
 *
 * values:
 * VIDEO: {time: Number}, SORTER: {Array|String}
 * IMAGE: {}, SORTER: {Array|String}
 * AUDIO: {}, SORTER: {Array|String}
 * DOCUMENT: {page: Number}, SORTER: {Array|String}
 * UNKNOWN : {}
 * @type {Object}
 */

export default Object.freeze({
  VIDEO: {
    NAME: "VIDEO",
    DATA: {
      TIME: "time",
      FRAME_RATE: "frameRate",
    },
    SORTER: ["time", "posY", "created"],
  },
  AUDIO: {
    NAME: "AUDIO",
    DATA: {
      TIME: "time", // The time information in seconds, e.g. 2.43234 s
    },
    SORTER: ["time", "created"],
  },
  IMAGE: {
    NAME: "IMAGE",
    SORTER: ["posY", "created"],
  },
  DOCUMENT: {
    NAME: "DOCUMENT",
    DATA: {
      PAGE: "page",
    },
    SORTER: ["page", "posY", "created"],
  },
  UNKNOWN: {
    NAME: "UNKNOWN",
    SORTER: "created",
  },
});
