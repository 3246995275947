/* eslint react/forbid-elements:0 */
/* eslint react/forbid-component-props:0 */
import PropTypes from "prop-types";
import {
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";

import { ClickAwayListener, Grow, Popper as MuiPopper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import mergeClasses from "@shared/helpers/mergeClasses";
import { useMediaQuery } from "@shared/hooks";
import FSTGTypography from "@shared/theme/typography";

import HelpTextPopperChildren from "./HelpDeskPopperChildren/HelpTextPopperChildren";
import HelpTextPopperContent from "./HelpTextPopperContent/HelpTextPopperContent";

const useStyles = makeStyles((theme) => ({
  Popover: {
    fontSize: FSTGTypography.fontSize_1_5,
    lineHeight: FSTGTypography.lineHeight_1_5,
    backgroundColor: theme.color.white,
    borderRadius: 5,
    color: theme.color.gray[800],
    boxShadow: theme.shadow["box-shadow-z5"],
    width: 500,
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      maxWidth: 300,
    },
  },
  Small: {
    width: 400,
    maxHeight: "100%",
  },
  Medium: {
    maxWidth: 550,
    width: "95%",
    maxHeight: "100%",
  },
}));

/**
 * UIKit Popper Component
 * @description A Popper component that will be used for standardization. It contains
 * multiple options and extends the Material UI Popper component.
 * @param {object} props - Component props
 * @param {ReactElement} [props.content] [Required] - The content of the popper.
 * @param {ReactElement} [props.children] [Optional] - Children of the popper component if the variant is `normal`.
 * @param {string} [props.variant] [Optional] - The variant of the popper. Options are `normal` and `helpText`. Default is `normal`.
 * @param {string} [props.size] [Optional] - The size of the popper. Options are `small`, `medium` and `default`. Default is `default`.
 * @param {string} [props.actionVariant] [Optional] - The action variant of the popper. Options are `hover` and `click`. Default is `hover`.
 * @param {Function} [props.onOpen] [Optional] - Callback function to be called when the popper is opened.
 * @param {Function} [props.onClose] [Optional] - Callback function to be called when the popper is closed.
 * @param {boolean} [props.isClosed] [Optional] - If the popper is closed or opened
 * @param {{
 *  Root: string,
 *  Popover: string,
 *  Medium: string,
 *  Small: string
 * }} [props.classes] [Optional] - Override or extend the styles applied to the component.
 * @returns {ReactElement} - React component
 */
const Popper = forwardRef(
  (
    {
      children,
      classes,
      content,
      size,
      actionVariant,
      onOpen,
      onClose,
      isClosed,
      variant,
      ...restProps
    },
    ref
  ) => {
    const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const [anchorEl, setAnchorEl] = useState(null);

    classes = mergeClasses(useStyles(), classes);

    const openPopper = useCallback(
      (event) => {
        setAnchorEl(event.currentTarget);
        if (onOpen) {
          onOpen(event.currentTarget);
        }

        event.stopPropagation();
      },
      [onOpen]
    );

    const mobileOpenPopper = useCallback(
      (event) => {
        /* istanbul ignore else */
        if (matches) {
          openPopper(event);
        }
      },
      [matches, openPopper]
    );

    const closePopper = useCallback(() => {
      setAnchorEl(null);
      if (onClose) {
        onClose();
      }
    }, [onClose]);

    useEffect(() => {
      if (isClosed) {
        closePopper();
      }
    }, [closePopper, isClosed]);

    return (
      <Box
        aria-haspopup="true"
        onMouseEnter={actionVariant !== "click" ? openPopper : undefined}
        onMouseLeave={actionVariant !== "click" ? closePopper : undefined}
        onClick={actionVariant !== "click" ? mobileOpenPopper : openPopper}
        display="flex"
        className={classes.Root}
      >
        {variant === "normal" && children}
        {variant === "helpText" && <HelpTextPopperChildren />}
        <MuiPopper
          onClick={(event) => event.stopPropagation()}
          open={!isClosed && Boolean(anchorEl)}
          style={{ zIndex: 1900 }}
          anchorEl={anchorEl}
          transition
          ref={ref}
          {...restProps}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <div>
                <ClickAwayListener
                  touchEvent={
                    actionVariant === "click" && matches && anchorEl
                      ? false
                      : "onTouchStart"
                  }
                  mouseEvent="onMouseDown"
                  onClickAway={closePopper}
                >
                  <div>
                    <Box
                      className={classnames(
                        classes.Popover,
                        { [classes.Medium]: size === "medium" },
                        { [classes.Small]: size === "small" }
                      )}
                      display="flex"
                    >
                      {variant === "normal" && content}
                      {variant === "helpText" && (
                        <HelpTextPopperContent helpText={content} />
                      )}
                    </Box>
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </MuiPopper>
      </Box>
    );
  }
);
Popper.displayName = "Popper";

Popper.propTypes = {
  variant: PropTypes.oneOf(["normal", "helpText"]),
  children: PropTypes.node,
  classes: PropTypes.shape({
    Root: PropTypes.string,
    Popover: PropTypes.string,
    Medium: PropTypes.string,
    Small: PropTypes.string,
  }),
  content: PropTypes.node.isRequired,
  size: PropTypes.oneOf(["small", "medium", "default"]),
  actionVariant: PropTypes.oneOf(["hover", "click"]),
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isClosed: PropTypes.bool,
  disablePortal: PropTypes.bool,
};

Popper.defaultProps = {
  variant: "normal",
  children: null,
  size: "default",
  classes: {},
  actionVariant: "hover",
  onOpen: null,
  onClose: null,
  isClosed: false,
  disablePortal: false,
};

export default Popper;
