/* eslint-disable max-lines */
import propTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import slackAppService from "@integrations/services/slackAppService";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControlLabel,
  FormGroup,
  TextField,
  Checkbox,
  SvgIcon,
  IconButton,
  Link,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import projectSwitcherService from "@workflow/services/projectSwitcherService";
import keyBy from "lodash/keyBy";

import { Box, Text, Tooltip } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import CloseIcon from "@assets/img/icons/ic_close_24px.svg";

import IntegrationsSettingsPopperFooter from "../IntegrationsSettingsPopperFooter";
import IntegrationsSettingsPopperHeader from "../IntegrationsSettingsPopperHeader";

const useStyles = makeStyles({
  AutocompleteConnected: {
    flex: 1,
  },
  AutocompleteNotConnected: {
    flex: "auto",
  },
});

const notificationSettings = [
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.REVIEW_DECISIONS.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.REVIEW_DECISIONS.DESC",
    key: "reviewDecisions",
  },
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.STATUS.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.STATUS.DESC",
    key: "statusUpdates",
  },
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.COMMENTS.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.COMMENTS.DESC",
    key: "newComments",
  },
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.COMMENT_REPORT.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.COMMENT_REPORT.DESC",
    key: "commentReport",
  },
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.FILE.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.FILE.DESC",
    key: "newUploads",
  },
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.DUE_DATE.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.DUE_DATE.DESC",
    key: "dueDates",
  },
  {
    title: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.NEW_REVIEW.TITLE",
    description: "PROJECT.SLACK.DIALOG.NOTIFICATIONS.NEW_REVIEW.DESC",
    key: "newReview",
  },
];

const defaultNotificationSettings = {
  reviewDecisions: true,
  newUploads: true,
  newReview: true,
};

function SlackAppSettingsPopper({ onClose, connected }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [notificationState, setNotificationState] = useState({
    teamChannels: {},
    showNotificationSettings: false,
    selectedChannel: "",
    notificationSettingsValues: { ...defaultNotificationSettings },
    savingNotificationSettings: false,
    selectedProject: null,
    loadingTeamChannels: false,
    autoCompleteInputValue: "",
    isProjectConnected: false,
    disconnectingProject: false,
    teamChannelsNextCursor: null,
  });
  const selectedTeam = useSelectedTeam();

  const handleShowNotificationSettings = (event) => {
    event.stopPropagation();

    setNotificationState((previousNotificationState) => ({
      ...previousNotificationState,
      showNotificationSettings:
        !previousNotificationState.showNotificationSettings,
    }));
  };

  const handleNotificationValueChange = (notificationKey, newValue) => {
    setNotificationState((previousValue) => ({
      ...previousValue,
      notificationSettingsValues: {
        ...previousValue.notificationSettingsValues,
        [notificationKey]: newValue,
      },
    }));
  };

  const handleSelectedChannelChange = (event, inputValue) => {
    setNotificationState((previousValue) => ({
      ...previousValue,
      selectedChannel: inputValue.id,
      autoCompleteInputValue: inputValue.name,
    }));
  };

  const handleAutoCompleteValueChange = (event, newInputValue) => {
    if (event?.type === "change") {
      setNotificationState((previousValue) => ({
        ...previousValue,
        autoCompleteInputValue: newInputValue,
      }));
    }
  };

  const handleSettingsSave = async () => {
    const selectedProject = notificationState.selectedProject;
    const getErrorTitleAndBody = (errorCode) => {
      switch (errorCode) {
        case "ERROR_1":
          return {
            title: "SLACK.ERROR_TOAST.TITLE-1",
            body: "SLACK.ERROR_TOAST.DESCRIPTION-1",
          };
        case "ERROR_2":
          return {
            title: "SLACK.ERROR_TOAST.TITLE-1",
            body: "SLACK.ERROR_TOAST.DESCRIPTION-2",
          };
        default:
          return {
            title: "SLACK.ERROR_TOAST.TITLE-2",
            body: "SLACK.ERROR_TOAST.DESCRIPTION-3",
          };
      }
    };

    setNotificationState((previousNotificationState) => ({
      ...previousNotificationState,
      savingNotificationSettings: false,
    }));

    try {
      await slackAppService.connectChannelsToProject(
        selectedTeam._id,
        selectedProject.id,
        notificationState.selectedChannel,
        notificationState.notificationSettingsValues
      );

      setNotificationState((previousNotificationState) => ({
        ...previousNotificationState,
        savingNotificationSettings: true,
        isProjectConnected: true,
      }));

      analytics.track(
        analytics.ACTION.UPDATED,
        analytics.CATEGORY.SLACK_APP.SETTINGS
      );

      toastService.sendToast({
        title: "SLACK.SUCCESS_TOAST.TITLE",
        body: "SLACK.SUCCESS_TOAST.DESCRIPTION",
        preset: toastService.PRESETS().SUCCESS,
      });

      onClose();
    } catch (error) {
      const toastBodyAndTitle = getErrorTitleAndBody(error.message);

      toastService.sendToast({
        preset: toastService.PRESETS().ERROR,
        ...toastBodyAndTitle,
      });
    }
  };

  const handleDisconnect = async () => {
    setNotificationState((previousNotificationState) => ({
      ...previousNotificationState,
      disconnectingProject: true,
    }));

    await slackAppService.disconnectProject(
      selectedTeam._id,
      notificationState.selectedProject.id
    );

    setNotificationState((previousNotificationState) => ({
      ...previousNotificationState,
      disconnectingProject: false,
    }));
  };

  const handleProjectDisconnected = () => {
    setNotificationState((previousNotificationState) => ({
      ...previousNotificationState,
      selectedChannel: "",
      isProjectConnected: false,
      autoCompleteInputValue: "",
    }));
  };

  useEffect(() => {
    const populateTeamChannelsState = async (channelCursor) => {
      const sortChannelsByName = (channels) => {
        const userLanguage = authenticationService.fetchUser().language;

        const collator = new Intl.Collator(userLanguage, {
          numeric: true,
          sensitivity: "base",
        });

        return channels.sort((channel1, channel2) =>
          collator.compare(channel1.name, channel2.name)
        );
      };

      setNotificationState((previousNotificationState) => ({
        ...previousNotificationState,
        loadingTeamChannels: true,
      }));

      try {
        const { channels: teamChannels, nextCursor } =
          await slackAppService.getTeamChannels(
            selectedTeam._id,
            channelCursor
          );

        /* istanbul ignore next */
        setNotificationState((previousNotificationState) => {
          const nonExistingTeamChannels = teamChannels.filter(
            ({ id }) => !previousNotificationState.teamChannels[id]
          );

          const sortedTeamChannels = sortChannelsByName(
            nonExistingTeamChannels
          );

          const mappedTeamChannels = keyBy(sortedTeamChannels, "id");

          return {
            ...previousNotificationState,
            teamChannels: {
              ...previousNotificationState.teamChannels,
              ...mappedTeamChannels,
            },
            teamChannelsNextCursor: nextCursor,
            loadingTeamChannels: false,
            autoCompleteInputValue: mappedTeamChannels[
              previousNotificationState.selectedChannel
            ]
              ? mappedTeamChannels[previousNotificationState.selectedChannel]
                  .name
              : previousNotificationState.autoCompleteInputValue,
          };
        });
      } catch {
        setNotificationState((previousNotificationState) => ({
          ...previousNotificationState,
          loadingTeamChannels: false,
        }));
      }
    };

    if (selectedTeam) {
      const timeout = notificationState.teamChannelsNextCursor ? 30000 : 0;

      setTimeout(() => {
        populateTeamChannelsState(notificationState.teamChannelsNextCursor);
      }, timeout);
    }
  }, [selectedTeam, notificationState.teamChannelsNextCursor]);

  useEffect(() => {
    const populateSelectedProject = () => {
      const selectedProject = projectSwitcherService.getSelectedProject();

      setNotificationState((previousNotificationState) => ({
        ...previousNotificationState,
        selectedProject,
      }));
    };

    populateSelectedProject();

    eventService.addListener(
      EVENT_NAMES.PROJECT.SELECTED,
      populateSelectedProject
    );
    eventService.addListener(
      EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_DISCONNECTED,
      handleProjectDisconnected
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT.SELECTED,
        populateSelectedProject
      );
      eventService.removeListener(
        EVENT_NAMES.INTEGRATIONS.SLACK_APP.PROJECT_DISCONNECTED,
        handleProjectDisconnected
      );
    };
  }, []);

  useEffect(() => {
    const fetchProjectChannel = async () => {
      setNotificationState((previousNotificationState) => ({
        ...previousNotificationState,
        isProjectConnected: false,
      }));

      const selectedProject = notificationState.selectedProject;
      const projectChannel = await slackAppService.getProjectChannel(
        selectedTeam._id,
        selectedProject.id
      );

      const {
        slackChannel: { notificationSettings, channelId },
      } = projectChannel;

      setNotificationState((previousNotificationState) => ({
        ...previousNotificationState,
        selectedChannel: channelId,
        notificationSettingsValues: notificationSettings,
        isProjectConnected: true,
      }));
    };

    if (notificationState.selectedProject) {
      fetchProjectChannel();
    }
  }, [selectedTeam, notificationState.selectedProject]);

  return (
    <>
      <IntegrationsSettingsPopperHeader
        isProjectConnected={notificationState.isProjectConnected}
        appId="slack"
      />

      <Box display="flex" flexDirection="column" gap={2} textAlign="left">
        <Box
          px={1.5}
          py={1.875}
          bgcolor="grey.50"
          display="flex"
          flexDirection="column"
          gap={1.5}
        >
          <Box display="flex" flexDirection="row" gap={1}>
            <Text
              translate="PROJECT.SLACK.DIALOG.TITLE"
              fontWeight="fontWeight.bold"
              variant="body2"
            />
            <Tooltip
              describeChild
              title={
                <Trans
                  components={{
                    anchor: (
                      <Link target="_blank" href={t("SLACK.LEARN_MORE.LINK")} />
                    ),
                  }}
                >
                  SLACK.CHANNEL_HELP.TOOLTIP
                </Trans>
              }
              disableInteractive={false}
            >
              <HelpOutlineOutlinedIcon fontSize="small" />
            </Tooltip>
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <Autocomplete
              className={
                notificationState.selectedChannel
                  ? classes.AutocompleteConnected
                  : classes.AutocompleteNotConnected
              }
              disablePortal
              id="select-slack-channel-combo-box"
              data-testid="select-slack-channel-combo-box"
              getOptionLabel={(option) => option.name || ""}
              options={Object.values(notificationState.teamChannels)}
              onChange={handleSelectedChannelChange}
              value={notificationState.selectedChannel}
              inputValue={notificationState.autoCompleteInputValue}
              onInputChange={handleAutoCompleteValueChange}
              isOptionEqualToValue={(option, value) => option.id === value}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    id="select-slack-channel"
                    variant="outlined"
                    label={t("PROJECT.SLACK.DIALOG.INPUT_SELECT")}
                    data-testid="select-slack-channel"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {notificationState.loadingTeamChannels ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                );
              }}
              loading={notificationState.loadingTeamChannels}
              disableClearable
            />
            {(connected || notificationState.isProjectConnected) && (
              <Tooltip
                placement="right"
                title={t("PROJECT.SLACK.DIALOG.DELETE_TTOOLTIP")}
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  onClick={handleDisconnect}
                  data-testid="disconnect-icon"
                  size="small"
                >
                  <SvgIcon component={CloseIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>

        <Box>
          <Accordion
            disableGutters
            expanded={notificationState.showNotificationSettings}
            onChange={handleShowNotificationSettings}
            sx={{ bgcolor: "grey.50" }}
          >
            <AccordionSummary
              expandIcon={
                notificationState.showNotificationSettings ? (
                  <ExpandMoreIcon />
                ) : (
                  <ChevronRightIcon />
                )
              }
              aria-controls="notification-settings-content"
              id="notification-settings-header"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                data-testid="show-notification-settings"
              >
                <Text
                  translate="PROJECT.SLACK.DIALOG.NOTIFICATIONS.TITLE"
                  fontWeight="fontWeight.bold"
                  variant="body2"
                />
                <Text
                  translate="PROJECT.SLACK.DIALOG.NOTIFICATIONS.DESCRIPTION"
                  variant="subtitle1"
                  color="text.secondary"
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup data-testid="notification-settings" sx={{ gap: 1.5 }}>
                {notificationSettings
                  .filter(({ key }) => Boolean(key))
                  .map((notificationSetting) => (
                    <FormControlLabel
                      key={notificationSetting.title}
                      sx={{ alignItems: "flex-start" }}
                      control={
                        <Checkbox
                          disableRipple
                          sx={{ pt: 0 }}
                          color="primaryV2"
                          checked={Boolean(
                            notificationState.notificationSettingsValues[
                              notificationSetting.key
                            ]
                          )}
                          data-testid="notification-settings-checkbox"
                          onChange={(event) =>
                            handleNotificationValueChange(
                              notificationSetting.key,
                              event.target.checked
                            )
                          }
                        />
                      }
                      disableTypography
                      label={
                        <Box>
                          <Text
                            translate={notificationSetting.title}
                            fontWeight="fontWeight.medium"
                            variant="body2"
                          />
                          <Text
                            translate={notificationSetting.description}
                            variant="body2"
                            color="text.secondary"
                          />
                        </Box>
                      }
                    />
                  ))}
              </FormGroup>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      <IntegrationsSettingsPopperFooter
        appId="slack"
        disabled={
          !notificationState.selectedChannel ||
          !Object.values(notificationState.notificationSettingsValues).find(
            (settingValue) => settingValue === true
          )
        }
        handleSave={handleSettingsSave}
        handleClose={onClose}
      />
    </>
  );
}

SlackAppSettingsPopper.propTypes = {
  onClose: propTypes.func.isRequired,
  connected: propTypes.bool.isRequired,
};

export default SlackAppSettingsPopper;
