import PropTypes from "prop-types";

import { Link } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

function UsageTooltip({
  usageMessage1,
  hrefText,
  openBillingDialog,
  usageMessage2,
}) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Text variant="subtitle1" color="text.secondary">
        {usageMessage1}
      </Text>
      {hrefText && (
        <Text variant="subtitle1" color="text.secondary">
          <Link
            onClick={openBillingDialog}
            color="upgrade"
            fontSize="textSm.fontSize"
            fontWeight="fontWeight.medium"
            data-testid="billing-change-plan"
            sx={{ cursor: "pointer" }}
          >
            {hrefText}
          </Link>
          {usageMessage2}
        </Text>
      )}
    </Box>
  );
}

UsageTooltip.propTypes = {
  usageMessage1: PropTypes.string.isRequired,
  hrefText: PropTypes.string,
  openBillingDialog: PropTypes.func,
  usageMessage2: PropTypes.string,
};

UsageTooltip.defaultProps = {
  hrefText: null,
  openBillingDialog: null,
  usageMessage2: null,
};
export default UsageTooltip;
