import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import FSTGTypography from "@shared/theme/typography";

import AIDialogCategoryCard from "./AIDialogCategoryCard";
import AI_CATEGORIES from "./constants";

const useStyles = makeStyles(() => ({
  categoryTitle: {
    textAlign: "left",
    marginBottom: "20px",
    marginTop: "20px",
  },
  aiCategoryWrapper: {
    columnGap: "5%",
    rowGap: 30,
  },
}));

function AIDialogCategoryList() {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      data-testid="ai-category-list"
      display="flex"
      flexDirection="column"
      width="100%"
    >
      {AI_CATEGORIES.map((aiCategory) => (
        <Box id="ai-list" key={aiCategory.category}>
          <Text
            variant="body2"
            fontWeight={FSTGTypography.fontWeight.bold}
            fontSize={FSTGTypography.fontSize_1_6}
            lineHeight={FSTGTypography.lineHeight_1_8}
            className={classes.categoryTitle}
          >
            {t(aiCategory.category)}
          </Text>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            flexWrap="wrap"
            className={classes.aiCategoryWrapper}
          >
            {aiCategory.list.map((ai) => (
              <AIDialogCategoryCard
                key={ai.title}
                aiDescription={ai.description}
                aiTitle={ai.title}
                eventMeta={ai.eventMeta}
              />
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
}

export default AIDialogCategoryList;
