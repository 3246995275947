import PropTypes from "prop-types";
import { useState, useCallback } from "react";

import { makeStyles, useTheme } from "@mui/styles";
import projectTemplateService from "@workflow/services/projectTemplateService";
import sectionService from "@workflow/services/sectionService";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";

import SectionName from "../SectionName/SectionName";
import AddSectionButton from "./AddSectionButton/AddSectionButton";

const useStyles = makeStyles((theme) => ({
  addSection: {
    cursor: "default",
    position: "sticky",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
}));

function AddSection({ project, team, isTemplate, ...restProps }) {
  const [isEditing, setIsEditing] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const onAfterSave = useCallback(
    async (sectionName) => {
      try {
        if (isTemplate) {
          await projectTemplateService.addSection(project.id, sectionName);
        } else {
          await sectionService.addSection(project.id, sectionName);
        }
      } catch (err) {
        errorHandlerService.handleError(err);
      }
      setIsEditing(false);
    },
    [project, isTemplate]
  );

  const onChangeMode = useCallback(() => {
    setIsEditing(false);
  }, []);

  return (
    <Box
      display="inline-flex"
      justifyContent="space-between"
      minWidth={isTemplate ? 250 : 360}
      overflow="hidden"
      zIndex={40}
      flexDirection="row"
      alignItems="center"
      height={40}
      position="sticky"
      left={0}
      pt={0.625}
      pb={2}
      data-testid="project-add-section"
      {...restProps}
      className={classes.addSection}
      bgcolor={theme.color.gray[50]}
    >
      {isEditing ? (
        <Box data-testid="add-section-label" pl={1} width="100%">
          <SectionName onChange={onChangeMode} onSave={onAfterSave} isEditing />
        </Box>
      ) : (
        <AddSectionButton
          setIsEditing={setIsEditing}
          project={project}
          team={team}
          isTemplate={isTemplate}
        />
      )}
    </Box>
  );
}

AddSection.defaultProps = {
  isTemplate: false,
};

AddSection.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  team: teamProp.isRequired,
  isTemplate: PropTypes.bool,
};

export default AddSection;
