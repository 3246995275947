import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { LoadingButton as Button } from "@mui/lab";
import { FormControl, Input, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme, makeStyles } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";
import authenticationService from "@supporting/services/authentication";
import clsx from "clsx";

import { Text, Box } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { EMAIL_REGEX } from "@shared/constants/validation";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
}));

function Impersonate() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [impersonationData, setImpersonationData] = useState({
    isLoading: false,
    error: null,
    isValid: false,
  });

  const handleImpersonate = useCallback(async () => {
    setImpersonationData({
      isLoading: true,
      error: null,
      isValid: true,
    });

    try {
      await authenticationService.impersonate(email);
      navigate(APP_ROUTES.DASHBOARD.path);
    } catch (response) {
      setImpersonationData({
        isLoading: false,
        error:
          response.status === 404 ? "user doesn't exist" : response.message,
        isValid: true,
      });
    }
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEmailChange = useCallback((event) => {
    const { value } = event.currentTarget;
    setEmail(value);

    setImpersonationData((prevState) => ({
      ...prevState,
      isValid: EMAIL_REGEX.test(value),
    }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Title>Impersonate</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Box className={classes.formContainer}>
              <Box
                display="flex"
                mt={2}
                maxWidth={400}
                alignItems="center"
                data-testid="impersonate-form"
              >
                <Box
                  mt={2.5}
                  ml={0.5}
                  mr={2}
                  lineHeight={FSTGTypography.lineHeight_1_0}
                >
                  <EmailOutlined />
                </Box>
                <FormControl
                  variant="standard"
                  classes={{ root: classes.form }}
                >
                  <InputLabel htmlFor="email">
                    {t("FORM.EMAIL.WORK_LABEL")}
                  </InputLabel>
                  <Input
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    data-testid="impersonation-form-input"
                  />
                </FormControl>
              </Box>

              {impersonationData.error && (
                <Box my={2} ml={5} data-testid="impersonation-form-error">
                  <Text color={theme.color.red[500]}>
                    {impersonationData.error}
                  </Text>
                </Box>
              )}

              <Box mt={2} maxWidth={400} width="100%">
                <Button
                  loading={impersonationData.isLoading}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullwidth
                  onClick={handleImpersonate}
                  disabled={!impersonationData.isValid}
                  data-testid="impersonation-form-submit"
                >
                  {impersonationData.isLoading
                    ? "Impersonating..."
                    : "Impersonate"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default Impersonate;
