import PropTypes from "prop-types";

import { Box } from "@mui/material";

import InsightsMiniBgUpsell from "@assets/img/images/insights_mini_bg_upsell.png";

import InsightsUpsellPaper from "./InsightsUpsellPaper";
import MetricBox from "./MetricBox";

const OVERVIEW_CARDS = [
  {
    key: "PROJECTS_CREATED",
    name: "INSIGHTS.METRICS.PROJECTS.TITLE",
  },
  {
    key: "AVERAGE_VERSIONS_PER_FILE",
    name: "INSIGHTS.METRICS.PROJECTS.TITLE",
  },
];

const PROJECTS_CARDS = [
  {
    key: "REVIEWS_STARTED",
    name: "INSIGHTS.METRICS.REVIEWS.TITLE",
  },
  {
    key: "VERSIONS_UPLOADED",
    name: "INSIGHTS.METRICS.VERSIONS.TITLE",
  },
];

const REVIEWS_CARDS = [
  {
    key: "REVIEW_DECISION_SUBMITTED",
    name: "INSIGHTS.METRICS.REVIEW_DECISION.TITLE",
  },
  {
    key: "COMMENTS_AVERAGE",
    name: "INSIGHTS.METRICS.COMMENTS.TITLE",
  },
];

const REVIEWERS_CARDS = [
  {
    key: "ALL_REVIEWERS",
    name: "ADVANCED_INSIGHTS.REVIEWERS.METRICS_ALL_REVIEWERS",
  },
];

const TABS = {
  overview: OVERVIEW_CARDS,
  projects: PROJECTS_CARDS,
  reviews: REVIEWS_CARDS,
  reviewers: REVIEWERS_CARDS,
};

function InsightsMiniDashboard({ teamId, selectedTab }) {
  return (
    <Box
      data-testid="insights-mini-dashboard"
      minHeight="100%"
      display="flex"
      sx={{
        ml: {
          xs: 0,
          sm: 6,
        },
        mr: {
          xs: 0,
          sm: 6,
        },
        flexDirection: "row",
      }}
    >
      <Box
        alignItems="center"
        flexWrap="wrap"
        py={4}
        sx={{
          backgroundColor: "transparent",
          flexDirection: "column",
          pt: 20,
          px: 0,
          justifyContent: "flex-start",
          gap: 1.5,
          zIndex: 1,
          display: {
            xs: "none",
            sm: "flex",
          },
        }}
      >
        {TABS[selectedTab].map((metric) => (
          <MetricBox key={metric.key} teamId={teamId} metricType={metric.key} />
        ))}
      </Box>
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        position="relative"
        zIndex={2}
        sx={{
          backgroundImage: `url(${InsightsMiniBgUpsell})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          ml: 0,
        }}
      >
        <InsightsUpsellPaper />
      </Box>
    </Box>
  );
}

InsightsMiniDashboard.propTypes = {
  selectedTab: PropTypes.string,
  teamId: PropTypes.string.isRequired,
};

InsightsMiniDashboard.defaultProps = {
  selectedTab: "overview",
};

export default InsightsMiniDashboard;
