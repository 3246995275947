import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import reviewService from "@feedback/services/reviewService";
import viewerService from "@feedback/services/viewer";
import { Info } from "@mui/icons-material";
import { Divider, List, Popover } from "@mui/material";
import toastService from "@supporting/services/toast";
import AddReviewerDialog from "@workflow/components/AddReviewerDialog/AddReviewerDialog";
import * as stepService from "@workflow/services/stepService";
import noop from "lodash/noop";

import { Box, Text, Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { reviewProp } from "@shared/props/review";
import { instance as analytics } from "@shared/services/analytics";

import ReviewDecisionList from "./ReviewDecisionList";
import ReviewStatusMenu from "./ReviewStatusMenu";

const reviewStates = [
  REVIEW_DECISION_STATES.APPROVED,
  REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES,
  REVIEW_DECISION_STATES.NEEDS_CHANGES,
  REVIEW_DECISION_STATES.REJECTED,
  "PENDING",
];

function ReviewOverview({
  project,
  review,
  setReviewState,
  anchorEl,
  closeMenu,
  reviewState,
  page,
}) {
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const getReviews = (state) => {
    return review.reviews.filter((review) => review.state === state);
  };

  const isReadOnly = project.isArchived || !review.permissions.canSetState;

  async function openAddReviewerDialog(email) {
    const step = await stepService.getStep(review.stepId);
    return NiceModal.show(AddReviewerDialog, {
      teamId: review.teamId,
      step,
      preparedReviewers: [{ email }],
    });
  }

  const updateReviewStatus = async (state) => {
    setReviewState(state);
    try {
      await reviewService.updateStatus(review, state);
      viewerService.trackFileEvent(
        review.id,
        analytics.ACTION.CHANGED,
        analytics.CATEGORY.REVIEW_STATUS,
        {
          label: state,
          state,
          page,
        }
      );
    } catch (error) {
      setReviewState(review.status.state);
      toastService.sendToast({
        title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
        body: "ERROR.ACTION_NOT_POSSIBLE.BODY",
        preset: toastService.PRESETS().WARNING,
      });
    }
  };

  return (
    <Popover
      id="review-overview"
      open={open}
      data-testid="review-overview"
      disableScrollLock
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      slotProps={{
        paper: {
          sx: {
            maxWidth: "300px",
            ...(isMobile && {
              position: "sticky",
              width: "auto",
            }),
          },
        },
      }}
    >
      <Box display="flex" p={2} flexDirection="column">
        <Box display="flex" flexDirection="column" gap={1.5} mb={2} pl={0.5}>
          <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
            <ReviewStatusMenu
              reviewState={reviewState}
              onSelect={updateReviewStatus}
              isReadOnly={isReadOnly}
            />
            <Tooltip
              placement="right"
              title={t("REVIEW_COMPONENT.REVIEW_STATUS.TOOLTIP.TITLE")}
              description={t("REVIEW_COMPONENT.REVIEW_STATUS.TOOLTIP.DESC")}
            >
              <Info fontSize="small" color="disabled" />
            </Tooltip>
          </Box>
          <Text
            variant="textXs"
            translate={`REVIEWER.SETTINGS.${reviewState}_STATUS`}
            color="text.secondary"
          />
        </Box>
        <Divider />

        <List dense>
          {reviewStates.map((state) => (
            <ReviewDecisionList
              key={state}
              reviews={getReviews(state)}
              variant={state}
              openAddReviewerDialog={openAddReviewerDialog}
              isReadOnly={isReadOnly}
            />
          ))}
        </List>
      </Box>
    </Popover>
  );
}

ReviewOverview.propTypes = {
  project: projectProps.isRequired,
  anchorEl: PropTypes.any,
  closeMenu: PropTypes.func.isRequired,
  setReviewState: PropTypes.func,
  reviewState: PropTypes.string.isRequired,
  review: reviewProp.isRequired,
  page: PropTypes.oneOf([pagesEnum.PROJECT_DASHBOARD, pagesEnum.WORKSPACE])
    .isRequired,
};

ReviewOverview.defaultProps = {
  anchorEl: null,
  setReviewState: noop,
};

export default ReviewOverview;
