import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { memo } from "react";
import { useTranslation, Trans } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";

import { Text, Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles(() => ({
  lockDescription: {
    marginLeft: 16,
    maxWidth: 600,
  },
  lockAction: {
    cursor: "pointer",
  },
}));

function LockedReviewsState({ isTeamMember, teamId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const openBillingDialog = (event) => {
    event.stopPropagation();
    NiceModal.show(SelectPlanDialog, { teamId });
  };

  return (
    <Box
      display="flex"
      data-testid="reviews-locked-state"
      width="100%"
      position="absolute"
      height="100%"
      overflow="hidden"
      zIndex={21}
      top={0}
      right={0}
      bgcolor={theme.color["white-translucent-85"]}
      alignItems="center"
    >
      <Box margin="0px auto">
        <Text
          variant="subtitle2"
          fontWeight={FSTGTypography.fontWeightMedium}
          lineHeight={FSTGTypography.lineHeight_1_5}
          className={classes.lockDescription}
        >
          {isTeamMember
            ? t("FILE.DISABLED.TEAM_MEMBER")
            : t("FILE.DISABLED.NON_TEAM_MEMBER")}
        </Text>

        <Text
          variant="subtitle2"
          fontWeight={FSTGTypography.fontWeightMedium}
          lineHeight={FSTGTypography.lineHeight_1_5}
          className={classes.LockDescription}
        >
          <Trans
            components={{
              anchor: (
                <Text
                  component="span"
                  color={theme.color.blue[500]}
                  variant="subtitle2"
                  lineHeight={FSTGTypography.lineHeight_1_5}
                  fontWeight={FSTGTypography.fontWeightMedium}
                  className={classes.lockAction}
                  onClick={openBillingDialog}
                  data-testid="reviews-locked-state-action"
                />
              ),
            }}
          >
            {isTeamMember
              ? t("FILE.DISABLED.TEAM_MEMBER_CTA")
              : t("FILE.DISABLED.NON_TEAM_MEMBER_CTA")}
          </Trans>
        </Text>
      </Box>
    </Box>
  );
}

LockedReviewsState.propTypes = {
  isTeamMember: PropTypes.bool.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default memo(LockedReviewsState);
