import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import BILLING_COUNTRIES from "./billingCountries";

function CountryField({ onChange, label, name }) {
  // const classes = useStyles();
  const { t } = useTranslation();

  const countryOptions = useMemo(
    () =>
      BILLING_COUNTRIES.map((key) => ({
        isoCode: key,
        value: t(`BILLING.COUNTRY_LIST.${key}`),
      })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  //eslint-disable-next-line no-unused-vars
  const handleOnChange = (event, newValue) => {
    onChange(newValue?.isoCode || "");
  };

  return (
    <Autocomplete
      id="country-select-demo"
      options={countryOptions}
      data-testid="country-autocomplete"
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={handleOnChange}
      clearText=""
      popupIcon={null}
      clearIcon={null}
      autoHighlight
      getOptionLabel={(option) => option.value}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            id={name}
            label={label}
            inputProps={{
              ...params.inputProps,
              "data-testid": "country-input",
              "aria-label": t("BILLING.CHECKOUT.COUNTRY"),
            }}
          />
        );
      }}
    />
  );
}

CountryField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default CountryField;
