import { useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

const NotSharedVersionReviewCard = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      height="100%"
      px={1.25}
      bgcolor={theme.color.gray[50]}
      data-testid="not-shared-version-review-card"
    >
      <Text
        variant="subtitle2"
        color={theme.color.gray[500]}
        fontWeight={theme.typography.fontWeight.semiBold}
        fontStyle="italic"
        translate="VERSION_REVIEW.NOT_SHARED"
      />
    </Box>
  );
};

export default NotSharedVersionReviewCard;
