import { useRef, useCallback, useLayoutEffect } from "react";

export default function useLazyRenderChunks(scrollContainerRef) {
  const chunks = useRef(new Map());
  const intersectionObserverRef = useRef(null);

  const addChunk = useCallback((id, targetEl, notifyTarget) => {
    intersectionObserverRef.current?.observe(targetEl);
    chunks.current.set(id, [false, notifyTarget]);
  }, []);

  const removeChunk = useCallback((id, targetEl) => {
    intersectionObserverRef.current.unobserve(targetEl);
    chunks.current.delete(id);
  }, []);

  const observeIntersections = (entries) => {
    for (const entry of entries) {
      const chunkId = entry.target.dataset.chunkId;
      if (chunks.current.has(chunkId)) {
        const [isVisible, notifyTarget] = chunks.current.get(chunkId);

        if (isVisible !== entry.isIntersecting) {
          chunks.current.set(chunkId, [entry.isIntersecting, notifyTarget]);
          notifyTarget();
        }
      }
    }
  };

  useLayoutEffect(() => {
    const options = {
      root: scrollContainerRef.current,
      threshold: 0.1,
    };
    intersectionObserverRef.current = new IntersectionObserver(
      observeIntersections,
      options
    );
  }, [scrollContainerRef]);

  return { addChunk, removeChunk, chunks: chunks.current };
}
