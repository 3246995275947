import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { SIDE_PANEL_CONTENTS } from "./constants";
import CustomerFeedbackCard from "./CustomerFeedbackCard";

function SidePanelContent({ variant }) {
  const content = SIDE_PANEL_CONTENTS[variant];

  if (variant === "INVITE_TEAM_MEMBERS") {
    return (
      <Box
        display="flex"
        minHeight={540}
        flexDirection="column"
        justifyContent="space-between"
      >
        <CustomerFeedbackCard
          content={content.customer1}
          contentProps={{ borderRadius: 3 }}
          cardStyles={{ transform: "rotate(-4deg)" }}
        />
        <CustomerFeedbackCard
          content={content.customer2}
          contentProps={{ borderRadius: 3 }}
        />
        <CustomerFeedbackCard
          content={content.customer3}
          contentProps={{ borderRadius: 3 }}
          cardStyles={{ transform: "rotate(4deg)" }}
        />
      </Box>
    );
  }
  return <CustomerFeedbackCard content={content} />;
}

SidePanelContent.propTypes = {
  variant: PropTypes.oneOf([
    "LOGIN",
    "VERIFY_OTP",
    "COMPANY_SIZE",
    "INVITE_TEAM_MEMBERS",
  ]).isRequired,
};
export default SidePanelContent;
