import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import { Publish } from "@mui/icons-material";
import { Button } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";
import noop from "lodash/noop";

import { FILE_UPLOAD_STATES } from "@shared/constants/upload";
import getPlugin from "@shared/helpers/getPlugin";

function FileButton({ projectId, sectionId, stepIds, onClick }) {
  const { t } = useTranslation();
  const isPremiere = getPlugin(PLUGIN_TYPES.PREMIERE);
  const isAfterEffects = getPlugin(PLUGIN_TYPES.AFTER_EFFECTS);
  const isInDesign = getPlugin(PLUGIN_TYPES.INDESIGN);

  let buttonTranslationKey = "FILE.UPLOAD";
  if (isPremiere) {
    buttonTranslationKey = "FILE.UPLOAD_SEQUENCE";
  }
  if (isAfterEffects) {
    buttonTranslationKey = "FILE.MENU.UPLOAD_COMPOSITION.TITLE";
  }

  useEffect(() => {
    // eslint-disable-next-line complexity
    const handlePluginUploadEvents = (event) => {
      if (event.origin === window.location.origin || !event.data?.data) {
        return;
      }

      switch (event.data?.eventType) {
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_TO_S3_START: {
          const {
            data: {
              name,
              pregeneratedFileId,
              sectionId: documentSectionId,
              fileId,
            },
          } = event.data;

          if (sectionId === documentSectionId) {
            fileService.uploadSequence({
              fileId,
              projectId,
              sectionId,
              stepIds,
              videoName: name,
              uploadId: pregeneratedFileId,
              state: FILE_UPLOAD_STATES.UPLOADING_FROM_PLUGIN,
            });
          }
          break;
        }
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_UPLOAD_TO_S3_START: {
          const {
            data: {
              sequenceName,
              pregeneratedFileId,
              sectionId: sequenceSectionId,
              fileId,
            },
          } = event.data;

          if (sectionId === sequenceSectionId) {
            fileService.uploadSequence({
              fileId,
              projectId,
              sectionId,
              stepIds,
              videoName: sequenceName,
              uploadId: pregeneratedFileId,
              state: FILE_UPLOAD_STATES.UPLOADING_FROM_PLUGIN,
            });
          }
          break;
        }
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_SUCCESS: {
          const {
            data: {
              name,
              details: { id: uploadId, pregeneratedFileId, fileId },
            },
          } = event.data;

          fileService.updateSequenceUpload({
            pregeneratedFileId,
            uploadId,
            videoName: name,
            state: FILE_UPLOAD_STATES.TRANSCODING,
            ...(fileId && fileId !== pregeneratedFileId && { fileId }),
          });
          break;
        }
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_UPLOAD_SUCCESS: {
          const {
            data: {
              sequenceName,
              details: { id: uploadId, pregeneratedFileId, fileId },
            },
          } = event.data;

          fileService.updateSequenceUpload({
            pregeneratedFileId,
            uploadId,
            videoName: sequenceName,
            state: FILE_UPLOAD_STATES.TRANSCODING,
            ...(fileId && fileId !== pregeneratedFileId && { fileId }),
          });
          break;
        }
      }
    };

    if (isPremiere || isInDesign || isAfterEffects) {
      window.addEventListener("message", handlePluginUploadEvents);

      return () => {
        window.removeEventListener("message", handlePluginUploadEvents);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Button
      variant="contained"
      size="extraSmall"
      shape="rounded"
      startIcon={<Publish fontSize="small" />}
      onClick={onClick}
      data-testid="upload-button"
      className="tour__upload-file"
      sx={{ height: 28 }}
    >
      {t(buttonTranslationKey)}
    </Button>
  );
}

FileButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  projectId: PropTypes.string,
  sectionId: PropTypes.string,
  stepIds: PropTypes.arrayOf(PropTypes.string),
};

FileButton.defaultProps = {
  className: "",
  onClick: noop,
  projectId: "",
  sectionId: "",
  stepIds: [],
};
export default FileButton;
