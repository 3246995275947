import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { Text, Image } from "@shared/UIKit";

function CustomerFeedbackCard({ content, contentProps, cardStyles }) {
  const { t } = useTranslation();
  const { name, text, profile, position, companyLogo, backgroundImage } =
    content;

  return (
    <Box maxHeight={backgroundImage ? 430 : "unset"}>
      {backgroundImage && (
        <Image
          width="100%"
          height="auto"
          src={backgroundImage}
          sx={{
            boxShadow: "var(--mui-shadow-box-shadow-xlarge)",
            maxWidth: 525,
          }}
          alt="Filestage Background Image"
        />
      )}
      <Box
        top={backgroundImage ? -60 : "unset"}
        width="100%"
        display="flex"
        position="relative"
        justifyContent="center"
      >
        <Box
          display="flex"
          maxWidth={450}
          position="relative"
          sx={{ boxSizing: "border-box", ...cardStyles }}
        >
          <Box position="absolute" top={0} left={0}>
            <Image width={65} src={profile} alt={t(name)} />
          </Box>
          <Box
            ml={3}
            mt={1}
            py={1}
            pl={6}
            pr={2}
            boxShadow="var(--mui-shadow-box-shadow-xlarge)"
            maxWidth={400}
            bgcolor="white"
            display="flex"
            borderRadius={1}
            flexDirection="column"
            {...contentProps}
          >
            <Text
              variant="textXs"
              fontWeight="fontWeight.medium"
              translate={name}
            />
            <Text fontSize="0.625rem" translate={position} />
            <Text pt={2} variant="textSm" translate={text} />
            <Box
              height={25}
              width="100%"
              display="flex"
              justifyContent="flex-end"
            >
              <Box>
                <Image
                  src={companyLogo}
                  sx={{ maxWidth: 75, maxHeight: 25 }}
                  alt="Company Logo"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CustomerFeedbackCard.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    companyLogo: PropTypes.elementType.isRequired,
    backgroundImage: PropTypes.elementType,
  }).isRequired,
  contentProps: PropTypes.object,
  cardStyles: PropTypes.object,
};

CustomerFeedbackCard.defaultProps = {
  contentProps: {},
  cardStyles: {},
};

export default CustomerFeedbackCard;
