import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import HighlightOff from "@mui/icons-material/HighlightOff";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import RemoveLastTeamAdminDialog from "@supporting/components/RemoveLastTeamAdminDialog/RemoveLastTeamAdminDialog";
import RemoveTeamMemberDialog from "@supporting/components/RemoveTeamMemberDialog/RemoveTeamMemberDialog";
import authenticationService from "@supporting/services/authentication";

import { useDialogState } from "@shared/hooks";
import { userProp } from "@shared/props/authentication";
import { roleProp, teamProp } from "@shared/props/team";

function RemoveMemberMenu({ isTeamMemberLastAdmin, member, team }) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);

  const user = authenticationService.fetchUser();

  const openMenu = useCallback((event) => setAnchorEl(event.currentTarget), []);
  const closeMenu = useCallback(() => setAnchorEl(null), []);

  const {
    isOpen: isRemoveTeamMemberDialogOpen,
    openDialog: openRemoveTeamMemberDialog,
    closeDialog: closeRemoveTeamMemberDialog,
  } = useDialogState();

  const {
    isOpen: isRemoveLastTeamAdminDialogOpen,
    openDialog: openRemoveLastTeamAdminDialog,
    closeDialog: closeRemoveLastTeamAdminDialog,
  } = useDialogState();

  const changeTeamMemberRole = useCallback(() => {
    closeMenu();
    if (isTeamMemberLastAdmin) {
      return openRemoveLastTeamAdminDialog();
    }
    return openRemoveTeamMemberDialog();
  }, [isTeamMemberLastAdmin]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <IconButton
        aria-label="remove-member"
        onClick={openMenu}
        data-testid="team-members-member-remove-button"
        size="large"
      >
        <MoreVertOutlined fontSize="inherit" />
      </IconButton>
      {anchorEl && (
        <Menu
          id="team-members-remove-member-menu"
          anchorEl={anchorEl}
          open
          onClose={closeMenu}
        >
          <MenuItem
            data-testid="team-members-remove-member"
            onClick={changeTeamMemberRole}
          >
            <ListItemIcon>
              <HighlightOff fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {t(
                user._id === member.user._id
                  ? "TEAM.BUTTON.LEAVE_TEAM"
                  : "TEAM.BUTTON.REMOVE_MEMBER"
              )}
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
      <RemoveTeamMemberDialog
        cancel={closeRemoveTeamMemberDialog}
        isOpen={isRemoveTeamMemberDialogOpen}
        teamMember={member.user}
        hasApiKeyOrWebhook={member.hasApiKeyOrWebhook}
        isSelf={member.user._id === user._id}
        teamId={team._id}
        teamName={team.name}
      />
      <RemoveLastTeamAdminDialog
        cancel={closeRemoveLastTeamAdminDialog}
        isOpen={isRemoveLastTeamAdminDialogOpen}
      />
    </>
  );
}

RemoveMemberMenu.propTypes = {
  team: teamProp.isRequired,
  member: PropTypes.shape({
    role: roleProp,
    user: userProp,
    hasApiKeyOrWebhook: PropTypes.bool.isRequired,
  }).isRequired,
  isTeamMemberLastAdmin: PropTypes.bool.isRequired,
};
export default RemoveMemberMenu;
