import { Box } from "@mui/material";

import { Text, Tooltip } from "@shared/UIKit";

import MEDIA_TYPES from "@shared/constants/mediaTypes";
import { versionProp } from "@shared/props/file";

function FileCell({ version }) {
  const fileName =
    version.mediaType === MEDIA_TYPES.WEBSITE
      ? version.websiteUrl
      : version.original.name;

  return (
    <Box
      width={270}
      minWidth={270}
      height={50}
      borderRight="1px solid var(--mui-palette-grey-100)"
      px={2}
      boxSizing="border-box"
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      data-testid="version-reviews-file-cell"
    >
      <Tooltip title={fileName}>
        <Text
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          variant="textSm"
          color="grey.600"
        >
          {fileName}
        </Text>
      </Tooltip>
      <Text fontWeight="fontWeight.bold" variant="textSm" color="grey.600">
        v{version.number}
      </Text>
    </Box>
  );
}

FileCell.propTypes = {
  version: versionProp.isRequired,
};

export default FileCell;
