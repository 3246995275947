import { handleRedirectToPendingVerifiedApproval } from "@feedback/helpers/handleRedirectToPendingVerifiedApproval";
import { instance as teamService } from "@supporting/services/team";
import projectService from "@workflow/services/projectService";
import Cookies from "js-cookie";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as analytics } from "@shared/services/analytics";
import ipapi from "@shared/services/ipapi";
import { instance as logger } from "@shared/services/logger";
import { history } from "@shared/services/navigation";

export const processSignup = async (
  signupReason,
  isReviewerPasswordSignUp = false
) => {
  let location;
  try {
    location = await ipapi.fetchLocationInformation();
  } catch (error) {
    logger.error(
      "supporting.process-signup",
      "failed to fetch location information",
      { error }
    );
  }

  const selectedPlanId = Cookies.get("authentication:signup-form:planId");
  if (selectedPlanId) {
    Cookies.remove("authentication:signup-form:planId");
  }

  const insightsIntegrationId = Cookies.get(
    "authentication:signup-form:insightsIntegrationId"
  );
  if (insightsIntegrationId) {
    Cookies.remove("authentication:signup-form:insightsIntegrationId");
  }
  const selectedUserType =
    signupReason === "review-files" ? "reviewer" : "collaborator";

  const team = await teamService.createTeam({
    country: location?.country_code,
    planId: selectedPlanId,
    createdAsReviewer: selectedUserType === "reviewer",
    insightsIntegrationId,
  });
  await teamService.switchTeam(team);

  analytics.track(
    analytics.ACTION.SUBMITTED,
    analytics.CATEGORY.SIGNUP_REASON,
    {
      teamId: team._id,
      reason:
        selectedUserType === "reviewer"
          ? "Review files"
          : "Manage own projects",
      skipped: true,
    }
  );

  if (selectedUserType === "reviewer") {
    analytics.track(
      analytics.ACTION.COMPLETED,
      analytics.CATEGORY.ONBOARDING_WIZARD
    );
    await teamService.wizardProgressTrack(team._id, {
      currentStep: "completed",
      reason: selectedUserType,
    });
    Cookies.remove("authentication:signup-form:reason");
    if (isReviewerPasswordSignUp) {
      return;
    }
    handleRedirectToPendingVerifiedApproval();
  } else {
    const sourceProjectId = Cookies.get(
      "authentication:signup-form:sourceProjectId"
    );
    const sourceProjectTemplateId = Cookies.get(
      "authentication:signup-form:sourceProjectTemplateId"
    );
    const [folder] = await projectService.fetchProjectsByTeamId(team._id);

    projectService.addProject({
      teamId: team._id,
      folderId: folder.id,
      shouldCloneProject: true,
      sourceProjectId,
      sourceProjectTemplateId,
    });
    projectService.setCloneProjectStatus({
      status: "cloning",
    });

    await teamService.wizardProgressTrack(team._id, {
      currentStep: "companySize",
      reason: selectedUserType,
    });
    history.navigate(APP_ROUTES.ONBOARDING_WIZARD_COMPANY_SIZE.path);
  }
};
