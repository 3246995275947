import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import reviewService from "@feedback/services/reviewService";
import viewerService from "@feedback/services/viewer";
import CheckIcon from "@mui/icons-material/Check";
import { Menu, MenuItem } from "@mui/material";
import { makeStyles } from "@mui/styles";
import toastService from "@supporting/services/toast";
import ReviewState from "@workflow/components/ReviewState/ReviewState";
import classNames from "classnames";

import { Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { reviewProp } from "@shared/props/review";
import { instance as analytics } from "@shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  statusText: {
    marginTop: "0px !important",
  },
  menu: {
    marginLeft: "0%",
    width: "auto",
    padding: "10px 10px",
    "& div": {
      marginTop: 10,
    },
    "& ul": {
      minWidth: 0,
      padding: "8px  0px !important",
    },
  },
  currentStatus: {
    paddingRight: 5,
    color: theme.color.green[500],
  },
  menuItem: {
    padding: "4px 21px 4px 37px",
    minHeight: "auto",
  },
  activeMenu: {
    paddingLeft: 12,
  },
}));

function ReviewStatus({
  review,
  setReviewState,
  anchorEl,
  closeMenu,
  reviewState,
  isFileViewPage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const updateReviewStatus = useCallback(
    async (state) => {
      setReviewState(state);
      try {
        await reviewService.updateStatus(review, state);
        viewerService.trackFileEvent(
          review.id,
          analytics.ACTION.CHANGED,
          analytics.CATEGORY.REVIEW_STATUS,
          {
            label: state,
            state,
            page: isFileViewPage
              ? pagesEnum.FILE_VIEW
              : pagesEnum.PROJECT_DASHBOARD,
          }
        );
      } catch (error) {
        setReviewState(review.status.state);
        toastService.sendToast({
          title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
          body: "ERROR.ACTION_NOT_POSSIBLE.BODY",
          preset: toastService.PRESETS().WARNING,
        });
      }
    },
    [review] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Menu
      data-testid="review-status-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      className={classes.menu}
      onClick={closeMenu}
      onClose={closeMenu}
    >
      {Object.values(REVIEW_STATUS).map((status, index) => {
        const isActive = reviewState === status;
        return (
          <Tooltip
            key={status}
            placement="right"
            title={t(`REVIEW_STATUS.${status}`)}
            description={t(`REVIEWER.SETTINGS.${status}_STATUS`)}
          >
            <MenuItem
              className={classNames(classes.menuItem, {
                [classes.activeMenu]: isActive,
              })}
              data-testid={`review-status-${index}`}
              onClick={() => updateReviewStatus(status)}
            >
              {isActive && (
                <CheckIcon fontSize="small" className={classes.currentStatus} />
              )}

              <ReviewState
                reviewState={status}
                className={classes.statusText}
              />
            </MenuItem>
          </Tooltip>
        );
      })}
    </Menu>
  );
}

ReviewStatus.propTypes = {
  anchorEl: PropTypes.any,
  closeMenu: PropTypes.func.isRequired,
  setReviewState: PropTypes.func.isRequired,
  reviewState: PropTypes.string.isRequired,
  review: reviewProp.isRequired,
  isFileViewPage: PropTypes.bool,
};

ReviewStatus.defaultProps = {
  anchorEl: null,
  isFileViewPage: false,
};

export default ReviewStatus;
