import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { makeStyles, useTheme } from "@mui/styles";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import projectTemplateService from "@workflow/services/projectTemplateService";
import classNames from "classnames";

import { Box, Text } from "@shared/UIKit";

import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import FSTGTypography from "@shared/theme/typography";

import {
  useSidebarState,
  PROJECT_TEMPLATES_ACTION_TYPES,
  PROJECT_LIST_ACTION_TYPES,
} from "../sidebarState";
import TemplateListItem from "./TemplateListItem/TemplateListItem";

const useStyles = makeStyles((theme) => ({
  projectTemplatesContainer: {
    margin: 0,
    borderRadius: 2,
    borderTop: `1px solid ${theme.color.gray[200]}`,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: "transparent",
    "&:hover": {
      borderColor: theme.color.gray[100],
    },
    maxHeight: "30vh",
    overflow: "auto",
  },
  projectTemplatesTitle: {
    userSelect: "none",
    marginLeft: 16,
    paddingBottom: 8,
  },
  projectTemplatesEmptyState: {
    padding: "7px 33px 0 28px",
  },
  projectTemplatesEmptyStateIcon: {
    marginLeft: 10,
    minWidth: 27,
  },
  projectTemplateItemSelected: {
    backgroundColor: theme.color.gray[100],
  },
}));

export default function Templates() {
  const { state, dispatch } = useSidebarState();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles();

  const [areTemplatesFetched, setAreTemplatesFetched] = useState(false);
  const { projectTemplateId } = useParams();
  const team = useSelectedTeam();

  const onTemplateRemoved = ({ eventData }) => {
    const { templateId } = eventData;
    dispatch({
      type: PROJECT_TEMPLATES_ACTION_TYPES.REMOVE_PROJECT_TEMPLATE,
      payload: { templateId },
    });
  };

  const onTemplatesUpdated = ({ eventData }) => {
    const { templates } = eventData;
    dispatch({
      type: PROJECT_TEMPLATES_ACTION_TYPES.UPDATE_TEMPLATES,
      payload: { templates },
    });
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const templates =
          await projectTemplateService.fetchProjectTemplatesByTeamId(team._id);
        dispatch({
          type: PROJECT_TEMPLATES_ACTION_TYPES.UPDATE_TEMPLATES,
          payload: { templates },
        });
      } catch (error) {
        errorHandlerService.handleError(error);
      } finally {
        setAreTemplatesFetched(true);
      }
    };

    eventService.addListener(
      EVENT_NAMES.PROJECT_TEMPLATE.REMOVED,
      onTemplateRemoved
    );

    eventService.addListener(
      EVENT_NAMES.PROJECT_TEMPLATES.UPDATED,
      onTemplatesUpdated
    );

    if (team.permissions.canManageTemplates) {
      fetchTemplates();
    }
    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT_TEMPLATE.REMOVED,
        onTemplateRemoved
      );

      eventService.removeListener(
        EVENT_NAMES.PROJECT_TEMPLATES.UPDATED,
        onTemplatesUpdated
      );
    };
  }, [team._id]); // eslint-disable-line react-hooks/exhaustive-deps

  const areTemplatesEmpty = areTemplatesFetched && state.templates.length === 0;

  if (!team.permissions.canManageTemplates || !state.areProjectTemplatesShown) {
    return null;
  }

  return (
    <Box
      className={classNames(
        styles.projectTemplatesContainer,
        "tour__project-template"
      )}
      data-testid="project-templates-container"
    >
      <Text
        variant="body2"
        color={theme.color.gray[500]}
        className={styles.projectTemplatesTitle}
        data-testid="project-templates-title"
      >
        {t("PROJECT.TEMPLATES")}
      </Text>

      {state.sortedTemplates.map((template) => {
        const isSelected = projectTemplateId === template.id;
        return (
          <Box
            key={template.id}
            className={classNames({
              [styles.projectTemplateItemSelected]: isSelected,
            })}
          >
            <TemplateListItem
              key={template.id}
              {...template}
              isSelected={isSelected}
              isArchived={false}
              onClick={() => {
                dispatch({
                  type: PROJECT_LIST_ACTION_TYPES.CLEAR_SELECTED_PROJECT,
                });
              }}
            />
          </Box>
        );
      })}
      {areTemplatesEmpty && (
        <Box
          display="flex"
          alignItems="flex-end"
          justifyContent="space-between"
          className={styles.projectTemplatesEmptyState}
          data-testid="project-templates-empty-state"
        >
          <Text
            component="span"
            fontSize={FSTGTypography.fontSize_1_4}
            fontStyle="italic"
            color={theme.color.gray[500]}
            lineHeight={FSTGTypography.lineHeight_1_3}
          >
            {t("PROJECT.TEMPLATES_EMPTY_STATE")}
          </Text>
        </Box>
      )}
    </Box>
  );
}
