import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES_TO_ACTIONS } from "@feedback/constants/reviewDecisionState";
import CloseIcon from "@mui/icons-material/Close";
import { Grow, IconButton } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import ReviewDecisionNotificationPopupButton from "./ReviewDecisionNotificationPopupButton/ReviewDecisionNotificationPopupButton";

const TRANS_KEY_PREFIXES = {
  [REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED]:
    "UNDO_REVIEW_DECISION.TOAST.APPROVED",
  [REVIEW_DECISION_STATES_TO_ACTIONS.NEEDS_CHANGES]:
    "UNDO_REVIEW_DECISION.TOAST.REQUESTED_CHANGES",
  [REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED_WITH_CHANGES]:
    "UNDO_REVIEW_DECISION.TOAST.APPROVED_WITH_CHANGES",
  [REVIEW_DECISION_STATES_TO_ACTIONS.REJECTED]:
    "UNDO_REVIEW_DECISION.TOAST.REJECTED",
};

const ReviewDecisionNotificationPopup = () => {
  const { t } = useTranslation();

  const [display, setDisplay] = useState(false);
  const [decision, setDecision] = useState({});
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { reviewIds, action } = decision;

  const hidePopup = useCallback(() => {
    setDisplay(false);
  }, []);

  useEffect(() => {
    const showPopup = (event) => {
      setDecision(event.eventData);
      setDisplay(true);
    };
    eventService.addListener(EVENT_NAMES.REVIEW.DECISIONS.SUBMITTED, showPopup);
    eventService.addListener(EVENT_NAMES.WORKSPACE.FILE_SWITCHED, hidePopup);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.REVIEW.DECISIONS.SUBMITTED,
        showPopup
      );
      eventService.removeListener(
        EVENT_NAMES.WORKSPACE.FILE_SWITCHED,
        hidePopup
      );
    };
  }, [hidePopup]);

  const transKeyPrefix = TRANS_KEY_PREFIXES[action];

  return (
    <Grow in={display}>
      <Box
        top={isMobile ? "" : 100}
        bottom={isMobile ? 126 : ""}
        position="absolute"
        right={isMobile ? 10 : 11}
        zIndex={31}
        data-testid={`review-decision-notification-${
          display ? "shown" : "hidden"
        }`}
      >
        <Box
          maxWidth={300}
          display="flex"
          flexDirection="column"
          bgcolor="white"
          pt={1.2}
          pr={1.0}
          pb={1.3}
          pl={1.4}
          gap={2}
          borderRadius={1}
          boxShadow={1}
          borderLeft="5px solid var(--mui-palette-success-main)"
          position="relative"
        >
          <Text
            color="success.main"
            variant="body2"
            fontWeight="fontWeight.medium"
          >
            {reviewIds?.length > 1
              ? t(`${transKeyPrefix}_2.TITLE`, {
                  fileCount: reviewIds?.length,
                })
              : t(`${transKeyPrefix}.TITLE`)}
          </Text>
          <IconButton
            disableFocusRipple
            disableRipple
            data-testid="review-decision-notification-close-icon"
            size="small"
            onClick={hidePopup}
            sx={{
              position: "absolute",
              right: 10,
              top: 9,
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={2}
          >
            <Text color="text.secondary" variant="subtitle1">
              {t(`${transKeyPrefix}.DESCRIPTION`)}
            </Text>
            {display && (
              <ReviewDecisionNotificationPopupButton
                reviewIds={reviewIds}
                hidePopup={hidePopup}
                transKeyPrefix={transKeyPrefix}
                data-testid="review-decision-notification-button"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Grow>
  );
};

export default ReviewDecisionNotificationPopup;
