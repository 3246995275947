import PropTypes from "prop-types";

import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import noop from "lodash/noop";

const DialogCloseButton = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      aria-label="close"
      size="small"
      sx={{ position: "absolute", right: 8, top: 8 }}
      data-testid="dialog-cancel-icon"
    >
      <CloseIcon />
    </IconButton>
  );
};

DialogCloseButton.propTypes = {
  onClick: PropTypes.func,
};

DialogCloseButton.defaultProps = {
  onClick: noop,
};

export default DialogCloseButton;
