import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { MailOutlined, SmsOutlined } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";
import AIMarketplaceDialog from "@workflow/components/AIMarketplaceDialog/AIMarketplaceDialog";
import reviewerService from "@workflow/services/reviewer";
import classnames from "classnames";

import { Box, Dialog, Text } from "@shared/UIKit";

import { useDialogState, useMediaQuery } from "@shared/hooks";
import { userProp } from "@shared/props/authentication";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import FSTGTypography from "@shared/theme/typography";

import ExternalLinkIcon from "@assets/img/icons/external_link_icon.svg";

import AddReviewerDialogAutocomplete from "./AddReviewerDialogAutocomplete/AddReviewerDialogAutocomplete";
import AddReviewerDialogRequestDecisionToggle from "./AddReviewerDialogRequestDecisionToggle/AddReviewerDialogRequestDecisionToggle";

const useStyles = makeStyles((theme) => ({
  controlIcon: {
    paddingRight: 10,
    marginBottom: 1,
    color: theme.color.gray[300],
  },
  iconFocus: {
    color: theme.color.green[500],
  },
}));

const mappingUsers =
  (prepared = true) =>
  ({ email, displayName, ...props }) => ({
    ...props,
    inputValue: email,
    label: displayName || email,
    prepared,
    hasValidEmail: true,
  });

function AddReviewerDialog({
  teamId,
  step,
  preparedReviewers,
  isProjectTemplate,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const modal = useModal();
  const [reviewers, setReviewers] = useState(
    preparedReviewers.map(mappingUsers())
  );
  const user = useActiveUser();
  const [notifyEmail, setNotifyEmail] = useState(true);
  const [message, setMessage] = useState("");
  const [requestDecision, setRequestDecision] = useState(true);
  const [focusElement, setFocusElement] = useState("");
  const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [isSubmitted, setIsSubmitted] = useState(false);

  const aiMarketplaceDialog = useDialogState();

  const [suggestedReviewers, setSuggestedReviewers] = useState(() =>
    preparedReviewers.map(mappingUsers())
  );

  useEffect(() => {
    if (modal.visible) {
      analytics.track(
        analytics.ACTION.OPENED,
        !isProjectTemplate
          ? analytics.CATEGORY.INVITE_REVIEWER_DIALOG
          : analytics.CATEGORY.ADD_REVIEWER_TO_TEMPLATE_DIALOG
      );
    }
  }, [isProjectTemplate, modal.visible]);

  const trackPasteEmailEvents = useCallback(
    (pastedEmailNum) => {
      analytics.track(
        analytics.ACTION.PASTED,
        analytics.CATEGORY.REVIEWER_EMAIL_ADDRESS,
        {
          page: isProjectTemplate
            ? analytics.CATEGORY.ADD_REVIEWER_TO_TEMPLATE_DIALOG
            : analytics.CATEGORY.INVITE_REVIEWER_DIALOG,
          label:
            pastedEmailNum === 1
              ? analytics.LABEL.SINGLE_EMAIL
              : analytics.LABEL.MULTIPLE_EMAILS,
        }
      );
    },
    [isProjectTemplate]
  );

  useEffect(() => {
    const fetchReviewers = async () => {
      setIsLoadingSuggestions(true);
      try {
        const suggestReviewersList = await backend.get(
          `/steps/${step.id}/reviewer-invite-suggestions`,
          { teamId }
        );
        const reviewerList = suggestReviewersList.map(mappingUsers(false));

        const preparedReviewerList = preparedReviewers.map(mappingUsers());
        let suggestedList = reviewerList.concat(preparedReviewerList);
        if (!user.settings.openedAIMarketplaceDialogAt) {
          suggestedList = [
            { optionType: "AIMarketplaceLink" },
            ...suggestedList,
          ];
        }
        if (user.settings.openedAIMarketplaceDialogAt) {
          suggestedList.push({ optionType: "AIMarketplaceButton" });
        }
        setSuggestedReviewers(suggestedList);
      } finally {
        setIsLoadingSuggestions(false);
      }
    };
    fetchReviewers();
  }, [
    user.settings.openedAIMarketplaceDialogAt,
    preparedReviewers,
    step.id,
    teamId,
  ]);

  const cancelDialog = useCallback(() => {
    modal.hide();
    modal.remove();
    healthMetrics.trackCancellation("workflow.invite-reviewers");
  }, [modal]);

  const confirmDialog = useCallback(async () => {
    modal.hide();
    modal.remove();
    const emails = reviewers.map((reviewer) => ({
      email: reviewer.inputValue,
    }));
    try {
      setIsSubmitted(true);
      await reviewerService.addReviewers({
        preparedData: {
          reviewers: emails,
          message,
          notifyEmail,
          requestDecision,
        },
        isProjectTemplate,
        step,
        teamId,
      });
    } finally {
      setIsSubmitted(false);
    }
  }, [
    modal,
    reviewers,
    message,
    notifyEmail,
    requestDecision,
    isProjectTemplate,
    step,
    teamId,
  ]);

  const handleReviewerChange = (_, emails) => setReviewers(emails);

  const handleTextArea = ({ target }) => setMessage(target.value);

  const handleNotifyEmail = useCallback(() => {
    setNotifyEmail(!notifyEmail);
  }, [setNotifyEmail, notifyEmail]);

  const handleRequestDecision = useCallback(() => {
    setRequestDecision((decisionRequested) => {
      analytics.track(
        !decisionRequested
          ? analytics.ACTION.ENABLED
          : analytics.ACTION.DISABLED,
        analytics.CATEGORY.REQUEST_REVIEWER_DECISION,
        {
          page: isProjectTemplate
            ? analytics.CATEGORY.ADD_REVIEWER_TO_TEMPLATE_DIALOG
            : analytics.CATEGORY.INVITE_REVIEWER_DIALOG,
        }
      );
      return !decisionRequested;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRequestDecision]);

  const handleOnBlur = useCallback(() => setFocusElement(null), []);
  const handleOnFocus = useCallback(
    ({ target }) => setFocusElement(target.id),
    []
  );

  const actions = (
    <>
      {!isProjectTemplate && (
        <FormControlLabel
          label={t("FILEMEMBER.ADD.NOTIFY.TITLE")}
          control={
            <Checkbox
              color="primary"
              checked={notifyEmail}
              onChange={handleNotifyEmail}
              inputProps={{
                "data-testid": "notify-via-email",
              }}
            />
          }
        />
      )}
      <Button
        onClick={confirmDialog}
        color="primary"
        data-testid="add-reviewer-dialog-invite-button"
        variant="contained"
        disabled={reviewers.length === 0 || isSubmitted}
      >
        {!isProjectTemplate
          ? t("REVIEWER.ADD.CONFIRM")
          : t("REVIEWER.INVITE.PROJECT-TEMPLATE.BUTTON")}
      </Button>
    </>
  );

  return (
    <Dialog
      hideBackdrop={false}
      data-testid="add-reviewer-dialog"
      title={
        <Trans
          t={t}
          i18nKey={
            isProjectTemplate
              ? "REVIEWER.INVITE.PROJECT-TEMPLATE.TITLE"
              : "REVIEWER.ADD.HEADLINE"
          }
          values={{ stepName: step.name }}
        />
      }
      open={modal.visible}
      cancel={cancelDialog}
      actions={actions}
      disableEnforceFocus
      disableBackdropClick
      customPaperClasses={classes.dialogMargin}
      content={
        <>
          <Button
            variant="text"
            color="default-light"
            size="small"
            href={t("URLS.FAQ_DIFFERENCE_REVIEWER_TEAM")}
            target="_blank"
            startIcon={<ExternalLinkIcon />}
            sx={{
              display: { xs: "none", md: "flex" },
              cursor: "help",
              position: "absolute",
              top: 7,
              left: 3,
            }}
          >
            {t(`REVIEWER.ADD.HELP_TOP_BUTTON`)}
          </Button>
          <Box textAlign="left" mx={isMobile ? 0 : 2} maxWidth={480}>
            <Box mb={2.5}>
              <Text
                variant="body2"
                fontSize={FSTGTypography.fontSize_1_4}
                color="text.secondary"
                whiteSpace="pre-line"
              >
                {isProjectTemplate
                  ? t("REVIEWER.INVITE.PROJECT-TEMPLATE.DESCRIPTION")
                  : t("REVIEWER.ADD.DESCRIPTION")}
              </Text>
            </Box>
            <AddReviewerDialogAutocomplete
              id="add-reviewer-dialog-autocomplete"
              data-testid="add-reviewer-dialog-autocomplete"
              preparedReviewers={suggestedReviewers}
              reviewers={reviewers}
              onChange={handleReviewerChange}
              trackPasteEmailEvents={trackPasteEmailEvents}
              isLoadingSuggestions={isLoadingSuggestions}
              emptyText="REVIEWERS.ADD.ERROR"
              inputIcon={MailOutlined}
              openAIMarketplaceDialog={aiMarketplaceDialog.openDialog}
              openedAIMarketplaceDialogAt={
                user.settings.openedAIMarketplaceDialogAt
              }
            />
            {!isProjectTemplate && (
              <>
                <Box pb={1} display="flex" alignItems="flex-end">
                  <SmsOutlined
                    className={classnames(classes.controlIcon, {
                      [classes.iconFocus]:
                        focusElement === "add-reviewer-dialog-message",
                    })}
                  />
                  <TextField
                    id="add-reviewer-dialog-message"
                    inputProps={{
                      "data-testid": "add-reviewer-dialog-message",
                    }}
                    fullWidth
                    disabled={!notifyEmail}
                    multiline
                    maxRows={4}
                    aria-label={t("FILEMEMBER.ADD.MESSAGE")}
                    label={t("FILEMEMBER.ADD.MESSAGE")}
                    onChange={handleTextArea}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                  />
                </Box>
                {!notifyEmail && message.length > 0 && (
                  <Box
                    ml={4.375}
                    textAlign="left"
                    data-testid="add-reviewer-dialog-personal-message-info"
                  >
                    <Text color={theme.color.purple[500]} variant="subtitle1">
                      {t("REVIEWER.ADD.PERSONAL-MESSAGE.NOT-SENT")}
                    </Text>
                  </Box>
                )}
              </>
            )}
            <AddReviewerDialogRequestDecisionToggle
              teamId={teamId}
              requestDecision={requestDecision}
              handleRequestDecision={handleRequestDecision}
            />
          </Box>
          <AIMarketplaceDialog
            closeDialog={aiMarketplaceDialog.closeDialog}
            isOpen={aiMarketplaceDialog.isOpen}
          />
        </>
      }
    />
  );
}

AddReviewerDialog.propTypes = {
  teamId: PropTypes.string.isRequired,
  step: stepProps.isRequired,
  preparedReviewers: PropTypes.arrayOf(userProp),
  isProjectTemplate: PropTypes.bool,
};
AddReviewerDialog.defaultProps = {
  preparedReviewers: [],
  isProjectTemplate: false,
};
export default NiceModal.create(AddReviewerDialog);
