import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";
export const instance = {};

export function initialize() {
  const teamPaymentMethodsCache = {};
  const teamCouponsCache = {};

  instance.fetchDefaultPlans = (teamId) =>
    backend.get(`/billing/default-plans/${teamId}`);

  instance.getPaymentMethods = function (teamId) {
    if (teamId in teamPaymentMethodsCache) {
      return teamPaymentMethodsCache[teamId];
    }

    teamPaymentMethodsCache[teamId] = backend.get(
      `/teams/${teamId}/billing/get_payment_methods`
    );
    return teamPaymentMethodsCache[teamId];
  };

  instance.addPaymentMethod = async function (teamId, paymentMethodId) {
    const teamInfo = await backend.post(
      `/teams/${teamId}/billing/add_payment_method`,
      {
        paymentMethodId,
      }
    );
    teamPaymentMethodsCache[teamId] = backend.get(
      `/teams/${teamId}/billing/get_payment_methods`
    );
    return teamInfo;
  };
  instance.handleRestartTrial = async function () {
    try {
      const { _id } = teamService.getSelectedTeam();

      const team = await backend.post(`/teams/${_id}/billing/restart-trial`);
      teamService.updateSelectedTeam(team);
      toastService.sendToast({
        title: "RESTART_TRIAL.TOAST.TITLE",
        body: "RESTART_TRIAL.TOAST.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };
  instance.activateSubscription = async function (teamId, planId, taxData) {
    const { subscription } = teamService.getSelectedTeam();

    const team = await backend.post(
      `/teams/${teamId}/billing/activate_subscription`,
      {
        planId,
        taxData,
      }
    );
    teamService.updateSelectedTeam(team);

    const numberOfMembers = team.members.length;
    analytics.track(
      analytics.ACTION.SUBSCRIBED_TO_PAID_PLAN,
      analytics.CATEGORY.SUBSCRIPTION,
      {
        planId,
        numberOfMembers,
        previousPlan: subscription.basePrice.description,
        subscriptionStatus: subscription.status,
        newPlan: team.subscription.basePrice.description,
      }
    );

    return team;
  };

  instance.cancelSubscription = async function (teamId) {
    const team = await backend.post(
      `/teams/${teamId}/billing/cancel_subscription`
    );
    teamService.updateSelectedTeam(team);
  };

  instance.resumeSubscription = async function (teamId) {
    const team = await backend.post(
      `/teams/${teamId}/billing/resume_subscription`
    );
    teamService.updateSelectedTeam(team);
    return team;
  };

  instance.updateProjectCount = async function (teamId, projectPlanCount) {
    const team = await backend.put(`/teams/${teamId}/projects`, {
      projectPlanCount,
    });
    teamService.updateSelectedTeam(team);
    const toastTranslation =
      projectPlanCount > 0
        ? "PROJECT_LIMIT.SUBSCRIPTION.INCREASED.TOAST"
        : "PROJECT_LIMIT.SUBSCRIPTION.DECREASED.TOAST";
    toastService.sendToast({
      title: `${toastTranslation}.TITLE`,
      body: `${toastTranslation}.BODY`,
      preset: toastService.PRESETS().SUCCESS,
      translationVariables: {
        body: { limitNumber: team.subscriptionLimits.maxProjectNumber },
      },
    });
    return team;
  };

  instance.updateSeatCount = async function (teamId, seatCount) {
    const team = await backend.put(`/teams/${teamId}/seats`, { seatCount });
    teamService.updateSelectedTeam(team);

    const toastTranslation =
      seatCount > 0 ? "SEATS.ADD.TOAST" : "SEATS.REMOVE.TOAST";
    toastService.sendToast({
      title: `${toastTranslation}.TITLE`,
      body: `${toastTranslation}.BODY`,
      preset: toastService.PRESETS().SUCCESS,
    });
    return team;
  };

  instance.validateTaxId = function (country, taxId) {
    const team = teamService.getSelectedTeam();
    return backend.get(`/billing/validate-tax-id/${team._id}`, {
      country,
      tax_id: taxId,
    });
  };

  instance.calculateTaxes = (country) =>
    backend.get("/billing/calculate_taxes", { country });

  instance.calculateTotalPrice = function (priceParams) {
    let totalPrice = parseFloat(priceParams.base) / 100;

    // add taxes
    if (priceParams.taxRate) {
      totalPrice += (totalPrice * parseFloat(priceParams.taxRate)) / 100;
    }

    return totalPrice;
  };

  instance.redeemCoupon = function (teamId, couponId) {
    teamCouponsCache[teamId] = backend.post(
      `/teams/${teamId}/billing/redeem_coupon`,
      { couponId }
    );
    return teamCouponsCache[teamId];
  };

  instance.getCoupon = function (teamId) {
    if (teamId in teamCouponsCache) {
      return teamCouponsCache[teamId];
    }

    teamCouponsCache[teamId] = backend.get(`/teams/${teamId}/billing/coupon`);
    return teamCouponsCache[teamId];
  };

  instance.getInvoiceData = (teamId) =>
    backend.get(`/teams/${teamId}/billing/invoice_data`);

  instance.updateInvoiceData = (teamId, { invoiceEmail }) =>
    backend.post(`/teams/${teamId}/billing/update_invoice_data`, {
      invoiceEmail,
    });

  /**
   *
   * @param currency
   * @returns currency symbol or undefined
   */
  instance.getCurrencySymbol = (currency) => {
    switch (currency) {
      case "usd":
        return "$";
      case "eur":
        return "€";
      case "gbp":
        return "£";
      default:
        break;
    }
  };

  instance.createSetupIntent = () => backend.post(`/billing/setupintent`);
}
