/* eslint-disable complexity */
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import COMPARE_MODES from "@feedback/constants/compareModes";
import compareReviewsService from "@feedback/services/compareReviewsService";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Button } from "@mui/material";
import Popover from "@mui/material/Popover";
import useActiveUser from "@supporting/hooks/useActiveUser";
import WorkspaceReviewButton from "@workflow/components/UploadFileButton/VariantButton/WorkspaceReviewButton/WorkspaceReviewButton";
import useUploadPanelDialog from "@workflow/components/UploadPanelDialog/useUploadPanelDialog";
import projectService from "@workflow/services/projectService";
import clsx from "clsx";
import last from "lodash/last";

import { Box, Text, Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import findRight from "@shared/helpers/findRight";
import mapRight from "@shared/helpers/mapRight";
import { useMediaQuery } from "@shared/hooks";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";

import DisabledCell from "./DisabledCell";
import FileCell from "./FileCell";
import MobilePanel from "./MobilePanel";
import PanelHeader from "./PanelHeader";
import ReviewCell from "./ReviewCell";

const selectUserEarlyAccessFlag = (user) =>
  user?.settings?.earlyAccessFeatures?.fileViewRedesign;

const VersionReviewsPanel = ({
  project,
  file,
  steps,
  activeReview,
  reviewPanePosition,
  compareMode,
  canNavigateToDashboard,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isfileViewRedesignEnabled = useActiveUser(selectUserEarlyAccessFlag);
  const downSm = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [folderName, setFolderName] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "version-reviews-popover" : undefined;

  const isProjectArchived = !project.isLocked && project.isArchived;
  const isCompareView = Boolean(reviewPanePosition);
  const isUploadButtonVisible =
    !isCompareView && !isProjectArchived && project.permissions.uploadFiles;

  const defaultUploadStepIds = useMemo(() => {
    const lastVersion = last(file.versions);
    const lastReviewStep = findRight(steps, (step) =>
      lastVersion.reviews.some((review) => review.stepId === step.id)
    );
    const firstStepId = steps.length > 0 ? steps[0].id : null;
    const stepId = lastReviewStep ? lastReviewStep.id : firstStepId;
    return stepId ? [stepId] : [];
  }, [file.versions, steps]);

  const { openUploadPanelDialog } = useUploadPanelDialog({
    projectId: project.id,
    stepIds: defaultUploadStepIds,
    selectedFile: file,
    teamId: project.teamId,
    page: pagesEnum.FILE_VIEW,
    component: UPLOAD_COMPONENT_TYPE.POPOVER,
  });

  useEffect(() => {
    const fetchFolderName = async () => {
      const folders = await projectService.fetchProjectsByTeamId(
        project.teamId
      );

      const folder = folders.find((item) => item.id === steps[0].folderId);

      setFolderName(folder?.name);
    };

    if (steps.length) {
      fetchFolderName();
    }
  }, [steps, project.teamId]);

  const borderStyle = "1px solid var(--mui-palette-grey-100)";

  const Footer = (
    <Box
      maxWidth={270 + steps.length * 200}
      data-testid="version-reviews-footer"
    >
      {project.permissions.manageSteps && (
        <Box display="flex" flexDirection="row" alignItems="center" gap={0.5}>
          <InfoIcon htmlColor="grey.500" fontSize="extraSmall" />
          <Text variant="textXs" color="grey.500">
            {t("FILE.VERSION.REVIEWER_NOTE-1")}{" "}
            {t("FILE.VERSION.REVIEWER_NOTE-2")}
          </Text>
        </Box>
      )}

      {isUploadButtonVisible && (downMd || isfileViewRedesignEnabled) && (
        <Box pt={2} display="flex" justifyContent="center">
          <WorkspaceReviewButton
            onClick={() => {
              handleClose();
              openUploadPanelDialog();
            }}
          />
        </Box>
      )}
    </Box>
  );

  const Content = (
    <Box
      data-testid="version-reviews-panel"
      maxWidth={{ xs: "calc(100vw - 15px)", md: "unset" }}
    >
      <PanelHeader
        canNavigateToDashboard={canNavigateToDashboard}
        project={project}
        folderName={folderName}
      />
      <Box
        px={{ xs: 2, md: 3.5 }}
        pt={0.5}
        pb={3}
        display="flex"
        flexDirection="column"
      >
        <Box data-testid="version-reviews-content" overflow="auto" pb={1}>
          <Box borderBottom={borderStyle} display="flex" flexDirection="row">
            <Box width={270} minWidth={270} height={50} />
            {steps.map((step) => (
              <Box
                width={200}
                minWidth={200}
                height={50}
                display="flex"
                justifyContent="center"
                alignItems="center"
                data-testid="version-reviews-header-cell"
                key={step.id}
              >
                <Text
                  variant="textSm"
                  fontWeight="fontWeight.bold"
                  textAlign="center"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  color="grey.700"
                >
                  {step.name}
                </Text>
              </Box>
            ))}
          </Box>
          {mapRight(file.versions, (version) => (
            <Box
              key={version.id}
              borderBottom={borderStyle}
              borderLeft={borderStyle}
              display="flex"
              flexDirection="row"
              data-testid="version-reviews-item-cell"
            >
              <FileCell version={version} />
              {!isCompareView ||
              compareReviewsService.areReviewsComparable(
                [version],
                compareMode
              ) ? (
                steps.map((step) => (
                  <ReviewCell
                    key={step.id}
                    step={step}
                    version={version}
                    activeReviewId={activeReview.id}
                    reviewPanePosition={reviewPanePosition}
                    onOpenReview={handleClose}
                  />
                ))
              ) : (
                <DisabledCell width="100%">
                  {t("AUTO_TEXT_COMPARE.FILE_NOT_SUPPORTED")}
                </DisabledCell>
              )}
            </Box>
          ))}
        </Box>
        {Footer}
      </Box>
    </Box>
  );

  const versionNumber = useMemo(
    () =>
      file.versions.find((item) => item.id === activeReview.versionId).number,
    [activeReview.versionId, file.versions]
  );

  return (
    <>
      <Tooltip title={t("WORKSPACE.SIDEBAR.SEE_ALL_VERSIONS")}>
        <Button
          data-testid="version-list-drop-down-button"
          variant={isfileViewRedesignEnabled ? "text" : "highlight"}
          color="default-light"
          size="extraSmall"
          className={clsx(
            "tour__workspace-versions-dropdown",
            `tour_version-picker-button-${reviewPanePosition}`
          )}
          onClick={handleClick}
          endIcon={
            open ? (
              <ArrowDropUp fontSize="small" />
            ) : (
              <ArrowDropDown fontSize="small" />
            )
          }
        >
          v{versionNumber}
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!downSm || isCompareView || !isfileViewRedesignEnabled ? (
          Content
        ) : (
          <>
            <PanelHeader
              canNavigateToDashboard={canNavigateToDashboard}
              project={project}
              folderName={folderName}
            />
            <MobilePanel
              file={file}
              activeReview={activeReview}
              handleClose={handleClose}
            />
          </>
        )}
      </Popover>
    </>
  );
};

VersionReviewsPanel.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  file: fileProp.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  activeReview: versionProp.isRequired,
  reviewPanePosition: PropTypes.oneOf(["left", "right"]),
  compareMode: PropTypes.oneOf([
    COMPARE_MODES.SIDE_BY_SIDE,
    COMPARE_MODES.AUTO_COMPARE_TEXT,
    COMPARE_MODES.OVERLAY,
  ]),
  className: PropTypes.string,
  canNavigateToDashboard: PropTypes.bool.isRequired,
};

VersionReviewsPanel.defaultProps = {
  reviewPanePosition: null,
  compareMode: null,
  className: "",
};

export default VersionReviewsPanel;
