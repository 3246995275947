/* eslint jsdoc/require-jsdoc:0 */
/* eslint jsdoc/no-missing-syntax:0 */
import PropTypes from "prop-types";
import { useCallback } from "react";

import { TextField } from "@mui/material";

function EditableLabelTextField({
  defaultValue,
  handleSave,
  handleCancel,
  className,
  InputProps,
  disabled,
  placeholder,
}) {
  const handleKeypress = useCallback(
    (event) => {
      switch (event.key) {
        case "Enter":
          handleSave(event.target.value);
          break;
        case "Escape":
          handleCancel();
          break;
      }
    },
    [handleSave, handleCancel]
  );

  const handleBlur = (event) => {
    handleSave(event.target.value);
  };

  // Generate a unique key based on the defaultValue prop to force a rerender when default value changes
  const key = `editable-label-text-field-${defaultValue}`;

  return (
    <TextField
      key={key}
      name="label"
      autoComplete="off"
      autoFocus
      defaultValue={defaultValue}
      margin="none"
      onBlur={handleBlur}
      className={className}
      disabled={disabled}
      variant="outlined"
      InputProps={InputProps}
      onKeyDown={handleKeypress}
      placeholder={placeholder}
    />
  );
}

EditableLabelTextField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  InputProps: PropTypes.shape({
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
      notchedOutline: PropTypes.string.isRequired,
      input: PropTypes.string.isRequired,
    }).isRequired,
  }),
  disabled: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
  textProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  placeholder: PropTypes.string,
};

EditableLabelTextField.defaultProps = {
  textProps: {},
  isEditing: false,
  placeholder: "",
  InputProps: {
    classes: {
      root: "",
      notchedOutline: "",
      input: "",
    },
  },
};

export default EditableLabelTextField;
