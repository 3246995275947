/* istanbul ignore file */
/**
 * Load script helper function
 *
 * @description This function is used for dynamically loading scripts
 * in the browser without having to worry about the React.
 * @param {string} scriptUrl - [Required] The url of the script to load.
 * @returns {Promise<void>} Returns the promise function that executes
 * the whole flow of loading the script.
 */
export default function loadScript(scriptUrl) {
  return new Promise((resolve, reject) => {
    const scripts = Array.from(document.getElementsByTagName("script"));
    const oldScript = scripts.find(({ src }) => src === scriptUrl);
    let parentNode;

    if (oldScript) {
      parentNode = oldScript.parentNode;
      parentNode.removeChild(oldScript);
    } else {
      parentNode = document.getElementsByTagName("body")[0];
    }

    const newScript = document.createElement("script");
    newScript.type = "text/javascript";
    newScript.src = scriptUrl;
    newScript.onload = resolve;
    newScript.onerror = reject;
    parentNode.appendChild(newScript);
  });
}
