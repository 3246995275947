import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ReviewerAuthenticationFlowContent from "@supporting/components/ReviewersAuthenticationFlow/ReviewerAuthenticationFlowContent";
import noop from "lodash/noop";

import { Dialog } from "@shared/UIKit";

function ReviewerAuthenticationFlowDialog({ action, context, hide, onCancel }) {
  const modal = useModal();
  const { t } = useTranslation();
  const [title, setTitle] = useState("REVIEWER.ENTER_USERDATA.HEADLINE");
  const onClose = () => {
    modal.hide();
    onCancel();
  };
  const onAuthComplete = async () => {
    await hide();
    modal.hide();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            maxWidth: 600,
            pt: { sm: 5 },
            px: { sm: 4 },
            pb: { sm: 1 },
          },
        },
      }}
      fullWidth
      data-testid="reviewer-authentication-flow-dialog"
      hideBackdrop={false}
      isOpen={modal.visible}
      cancel={onClose}
      useDefaultBackground={false}
      title={t(title)}
    >
      <ReviewerAuthenticationFlowContent
        hide={onAuthComplete}
        action={action}
        context={context}
        setTitle={setTitle}
      />
    </Dialog>
  );
}
ReviewerAuthenticationFlowDialog.propTypes = {
  hide: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
};
ReviewerAuthenticationFlowDialog.defaultProps = {
  onCancel: noop,
};

export default NiceModal.create(ReviewerAuthenticationFlowDialog);
