import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import IntegrationDetailsSidebar from "@integrations/components/Integrations/IntegrationDetailsSidebar/IntegrationDetailsSidebar";
import { INTEGRATION_APPS } from "@integrations/constants";
import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import authenticationService from "@supporting/services/authentication";

import { Box, Text, Image } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import Dashboard from "@workflow/pages/Dashboard/Dashboard";

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    overflowY: "auto",
    boxSizing: "border-box",
    background: theme.color.white,
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      backgroundColor: theme.color.gray[50],
    },
  },
  integrationImage: {
    maxWidth: "100%",
  },
  textWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    paddingTop: 23,
    paddingLeft: 27,
    paddingRight: 65,
    paddingBottom: 8,
    gap: 8,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 23,
      paddingLeft: 38,
      paddingRight: 54,
    },
  },
  zapierLearnMore: {
    paddingBottom: 16,
  },
}));

function IntegrationDetails() {
  const classes = useStyles();

  const params = useParams();
  const { appId } = params;
  const integrations = INTEGRATION_APPS;
  const integration = integrations.find(
    (integration) => integration.id === appId
  );

  const theme = useTheme();
  const selectedTeam = useSelectedTeam();
  const [slackConnection, setSlackConnection] = useState(null);

  const integrationsService = integration.appService;

  useEffect(() => {
    const getTeamConnection = async () => {
      const slackConnection = await integrationsService.getTeamConnection(
        selectedTeam._id
      );

      setSlackConnection(slackConnection);
    };

    const handleIntegrationDisconnected = (event) => {
      const { type: integrationType } = event.eventData;

      setSlackConnection(null);

      analytics.track(analytics.ACTION.DISCONNECTED, integrationType, {
        appTitle: integrationType,
      });

      const userIntegrationIdentificationList = {
        "slack-app": "integrationsAppsSlack",
      };

      const integrationIdentificationLabel =
        userIntegrationIdentificationList[integrationType];

      if (integrationIdentificationLabel) {
        const userId = authenticationService.fetchSession().userId;

        analytics.identify(userId, {
          [integrationIdentificationLabel]: false,
        });
      }
    };

    if (integrationsService && selectedTeam) {
      getTeamConnection();
    }

    eventService.addListener(
      EVENT_NAMES.INTEGRATIONS.DISCONNECTED,
      handleIntegrationDisconnected
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.INTEGRATIONS.DISCONNECTED,
        handleIntegrationDisconnected
      );
    };
  }, [selectedTeam, integrationsService]);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const learnMoreClickHandler = () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.ZAPIER_TEMPLATES.LEARN_MORE_LINK
    );
  };

  const { t } = useTranslation();

  return (
    <Dashboard isIntegrated={isMobile}>
      <Box
        className={classes.container}
        data-testid="integration-details"
        display="flex"
      >
        <IntegrationDetailsSidebar
          integration={integration}
          connected={Boolean(slackConnection)}
        />
        {integration.id === "zapier" ? (
          <Box className={classes.textWrapper}>
            <Text
              variant="body2"
              fontWeight={theme.typography.fontWeight.bold}
              translate="APPS.OVERVIEW.TITLE"
              color={theme.color.gray[800]}
            />
            <Box className={classes.zapierLearnMore}>
              <Trans
                components={{
                  anchor: (
                    <Link
                      target="_blank"
                      color="primary"
                      href={t("ZAPIER.LEARN_MORE.LINK")}
                      fontWeight="fontWeight.medium"
                      onClick={learnMoreClickHandler}
                      data-testid="learn-more-link"
                    />
                  ),
                }}
              >
                {t("APPS.ZAPIER_TEMPLATE.DESCRIPTION")}
              </Trans>
            </Box>
            <iframe
              data-testid="zapier-frame"
              width="100%"
              height="100%"
              src="/apps/zapier-embed"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Box>
        ) : (
          <Box className={classes.textWrapper}>
            <Text
              variant="body2"
              fontWeight={theme.typography.fontWeight.bold}
              translate="APPS.OVERVIEW.TITLE"
              color={theme.color.gray[800]}
            />

            <Text variant="body2" color="grey.700">
              <Trans
                components={{
                  anchor: (
                    <Link
                      target="_blank"
                      color="primary"
                      href={t(`APPS.${integration.localizationKey}.LEARN_MORE`)}
                    />
                  ),
                }}
              >
                {`APPS.${integration.localizationKey}.DESCRIPTION`}
              </Trans>
            </Text>
            <Box mt={2.625}>
              <Image
                width="auto"
                height="auto"
                src={integration.descriptionPage.bannerImage}
                className={classes.integrationImage}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Dashboard>
  );
}

export default IntegrationDetails;
