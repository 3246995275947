import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Box } from "@mui/material";
import ActivateSubscriptionDialog from "@supporting/components/ActivateSubscriptionDialog/ActivateSubscriptionDialog";
import ConfirmSubscriptionDialog from "@supporting/components/ConfirmSubscriptionDialog/ConfirmSubscriptionDialog";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as billingService } from "@supporting/services/billingService";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import pick from "lodash/pick";

import { Dialog, Text } from "@shared/UIKit";

import { useDialogState, useMediaQuery } from "@shared/hooks";
import { useQueryParams } from "@shared/hooks/useQueryParams";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import PlanCard from "../SelectPlanDialog/PlanDialog/PlanCard";
import {
  isPlanDowngrade,
  billingPlans,
} from "../SelectPlanDialog/PlanDialog/selectplan.helpers";

const TrialEndedDialog = ({ page }) => {
  const modal = useModal();
  const { t } = useTranslation();
  const team = useSelectedTeam();
  const [, setSearchParams] = useQueryParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const currentPlan = team.subscription.basePrice;
  const currency = currentPlan.currency;
  const canUpgradePlan = team.permissions.canManageBilling;

  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const [billingPlanList, setBillingPlanList] = useState([]);
  const [couponData, setCouponData] = useState({});

  const {
    isOpen: isActivateSubscriptionDialogOpen,
    closeDialog: closeActivateSubscriptionDialog,
    openDialog: openActivateSubscriptionDialog,
  } = useDialogState();

  const {
    isOpen: isConfirmSubscriptionDialogOpen,
    closeDialog: closeConfirmSubscriptionDialog,
    openDialog: openConfirmSubscriptionDialog,
  } = useDialogState();

  const planOptions = ["basic", "professional"];
  const billingPlansDetails = pick(cloneDeep(billingPlans), planOptions);

  billingPlansDetails["basic"].featureHeader = null;
  billingPlansDetails["basic"].features.main = merge(
    {},
    billingPlans["free"].features.main,
    billingPlans["basic"].features.main
  );

  if (billingPlanList.length) {
    Object.keys(billingPlansDetails).forEach((planName) => {
      billingPlansDetails[planName].data = billingPlanList.reduce(
        (data, plan) => {
          if (
            plan.name.toLowerCase().includes(planName) &&
            plan.currency === currency
          ) {
            if (plan.interval === "year") {
              data = { ...data, ...plan };
            } else {
              data = { ...data, monthlyPayment: plan };
            }
          }
          return data;
        },
        {}
      );
    });
  }

  useEffect(() => {
    async function fetchDefaultPlans() {
      const plans = await billingService.fetchDefaultPlans(team._id);
      setBillingPlanList(plans);
    }

    async function fetchCoupon() {
      const coupon = await billingService.getCoupon(team._id);
      setCouponData(coupon);
    }
    fetchDefaultPlans();
    if (team.permissions.canManageBilling) {
      fetchCoupon();
    }
  }, [team._id, team.permissions.canManageBilling]);

  useEffect(() => {
    analytics.track(
      analytics.ACTION.DISPLAYED,
      analytics.CATEGORY.TRIAL_END_MODAL.COMPONENT,
      {
        plan: team.subscription.basePrice.name,
        teamId: team._id,
        teamName: team.name,
      }
    );
    analytics.track(
      analytics.ACTION.OPENED_PRICING_PAGE,
      analytics.CATEGORY.SUBSCRIPTION,
      {
        subscriptionStatus: team.subscriptionStatus,
        subscriptionPlan: team.subscription.basePrice.name,
        page,
      }
    );
    healthMetrics.trackAttempt("supporting.billing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateSubscription = async ({ plan }) => {
    setSelectedPlan(plan);
    const creditCards = await billingService.getPaymentMethods(team._id);
    if (creditCards?.length === 0) {
      return openActivateSubscriptionDialog();
    }
    openConfirmSubscriptionDialog();
  };

  const cancel = () => {
    modal.hide();
  };

  const cancelActivateSubscriptionDialog = () => {
    closeActivateSubscriptionDialog();
    cancel();
  };

  const cancelConfirmSubscriptionDialog = () => {
    closeConfirmSubscriptionDialog();
    cancel();
  };

  const setDialogShownStatus = () => {
    localStorage.setItem(`trial-ended-${team._id}`, "completed");
  };

  const selectPlan = (plan) => {
    setDialogShownStatus();
    analytics.track(
      analytics.ACTION.SELECTED,
      analytics.CATEGORY.TRIAL_END_MODAL.PAID_PLAN,
      { plan: plan.name.toLowerCase(), teamId: team._id, teamName: team.name }
    );

    activateSubscription({ plan });
    setSearchParams({ action: null }, true);
    cancel();
  };

  const handleContinueWithFreePlan = () => {
    cancel();
    setDialogShownStatus();
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.TRIAL_END_MODAL.FREE_PLAN_BUTTON,
      {
        plan: team.subscription.basePrice.name,
        teamId: team._id,
        teamName: team.name,
      }
    );
  };

  return (
    Boolean(billingPlanList.length) && (
      <>
        <Dialog
          cancel={cancel}
          open={modal.visible}
          maxWidth="md"
          fullScreen={isMobile}
          title={t("OPEN_TRIAL.MODAL.HEADER.TRIAL_HAS_ENDED")}
          actions={
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={handleContinueWithFreePlan}
            >
              {t("OPEN_TRIAL.MODAL.TERTIARY_CTA")}
            </Button>
          }
          hideBackdrop={false}
          showCloseButton={false}
          disableBackdropClick
          useDefaultBackground={false}
          PaperProps={{ sx: { backgroundColor: "grey.50" } }}
        >
          <Box
            gap={2}
            height="100%"
            display="flex"
            minWidth={300}
            flexDirection="column"
          >
            <Box
              pb={1}
              top={0}
              zIndex={1}
              position="sticky"
              backgroundColor={{ md: "grey.50" }}
            >
              <Text
                variant="body1"
                color="grey.500"
                translate="OPEN_TRIAL.MODAL.BODY.TRIAL_HAS_ENDED"
              />
            </Box>
            <Box
              pb={1}
              gap={2}
              px={1.5}
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              alignItems="stretch"
              alignContent="flex-start"
              justifyContent={{
                xs: "space-evenly",
                md: "space-between",
              }}
              sx={{ overflowY: "auto" }}
            >
              {Object.keys(billingPlansDetails).map((plan) => {
                return (
                  <Box key={plan} display="flex" justifyContent="center">
                    <PlanCard
                      plan={plan}
                      selectPlan={selectPlan}
                      currentPlan={currentPlan}
                      currentPeriodEnd={team.subscription.currentPeriodEnd}
                      isSubscriptionOnTrail={false}
                      canUpgradePlan={canUpgradePlan}
                      billingPlans={billingPlansDetails}
                      showTrialEndedDialog
                      team={team}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Dialog>
        {isActivateSubscriptionDialogOpen && (
          <ActivateSubscriptionDialog
            team={team}
            cancel={cancelActivateSubscriptionDialog}
            currentPlan={selectedPlan}
            billingPlanList={billingPlanList}
            couponData={couponData}
          />
        )}
        {isConfirmSubscriptionDialogOpen && (
          <ConfirmSubscriptionDialog
            team={team}
            cancel={cancelConfirmSubscriptionDialog}
            couponData={couponData}
            currentPlan={selectedPlan}
            isPlanDowngrade={isPlanDowngrade(currentPlan, selectedPlan)}
          />
        )}
      </>
    )
  );
};

TrialEndedDialog.propTypes = {
  page: PropTypes.string,
};

TrialEndedDialog.defaultProps = {
  page: undefined,
};

export default NiceModal.create(TrialEndedDialog);
