import { redirect } from "react-router-dom";

import AdminLayout from "@supporting/components/Admin/AdminLayout";
import authenticationService from "@supporting/services/authentication";

import { APP_ROUTES } from "@shared/constants/routes";
import routeStates from "@shared/constants/routeStates";
import {
  fetchDefault,
  fetchToSignUpTeamLink,
  fetchAdminLoader,
  getEmbedStateParam,
} from "@shared/services/dataLoaders";
import { instance as logger } from "@shared/services/logger";

import UploadFiles from "./pages/onboardingWizard/UploadFiles/UploadFiles";
import AdminDashboard from "@supporting/pages/AdminDashboard/AdminDashboard";
import ChangeTeamCurrency from "@supporting/pages/ChangeTeamCurrency/ChangeTeamCurrency";
import ChangeUserEmail from "@supporting/pages/ChangeUserEmail/ChangeUserEmail";
import DisableSSO from "@supporting/pages/DisableSSO/DisableSSO";
import DisconnectGoogle from "@supporting/pages/DisconnectGoogle/DisconnectGoogle";
import EnforcePasswordChange from "@supporting/pages/EnforcePasswordChange/EnforcePasswordChange";
import ExternalLoginCallback from "@supporting/pages/ExternalLoginCallback/ExternalLoginCallback";
import ForgotPassword from "@supporting/pages/ForgotPassword/ForgotPassword";
import Impersonate from "@supporting/pages/Impersonate/Impersonate";
import Login from "@supporting/pages/Login/Login";
import Logout from "@supporting/pages/Logout/Logout";
import MergeAccounts from "@supporting/pages/MergeAccounts/MergeAccounts";
import CaptureSignupCredentials from "@supporting/pages/onboardingWizard/CaptureSignupCredentials/CaptureSignupCredentials";
import CompanySize from "@supporting/pages/onboardingWizard/CompanySize/CompanySize";
import InviteTeamMembers from "@supporting/pages/onboardingWizard/InviteTeamMembers/InviteTeamMembers";
import JoinTeams from "@supporting/pages/onboardingWizard/JoinTeams/JoinTeams";
import ProfitWell from "@supporting/pages/ProfitWell/ProfitWell";
import RemoveSubscriptionCache from "@supporting/pages/RemoveSubscriptionCache/RemoveSubscriptionCache";
import RepairPermissions from "@supporting/pages/RepairPermissions/RepairPermissions";
import SecurityWhitelistUser from "@supporting/pages/SecurityWhitelistUser/SecurityWhitelistUser";
import AccountTab from "@supporting/pages/Settings/account/AccountTab";
import DebugTab from "@supporting/pages/Settings/debug/DebugTab";
import EarlyAccess from "@supporting/pages/Settings/early-access/EarlyAccess";
import EditPassword from "@supporting/pages/Settings/EditPassword/EditPassword";
import EditProfile from "@supporting/pages/Settings/EditProfile/EditProfile";
import NotificationsTab from "@supporting/pages/Settings/notifications/NotificationsTab";
import Settings from "@supporting/pages/Settings/Settings";
import SignUp from "@supporting/pages/SignUp/SignUp";
import SignUpOtp from "@supporting/pages/SignUpOtp/SignUpOtp";
import SignUpValidate from "@supporting/pages/SignUpValidate/SignUpValidate";
import TeamJoinLink from "@supporting/pages/TeamJoinLink/TeamJoinLink";
import Billing from "@supporting/pages/Teams/BillingTab/BillingTab";
import Branding from "@supporting/pages/Teams/branding/TeamBranding";
import Members from "@supporting/pages/Teams/members/TeamMembersSettingsTab";
import Roles from "@supporting/pages/Teams/roles/TeamRolesTab";
import Api from "@supporting/pages/Teams/settings/api/APITab";
import TeamSettings from "@supporting/pages/Teams/settings/TeamSettings";
import Subscription from "@supporting/pages/Teams/subscription/SubscriptionTab";
import TabAccessDenied from "@supporting/pages/Teams/TabAccessDenied";
import Teams from "@supporting/pages/Teams/Teams";
import TestConsolidatedComments from "@supporting/pages/TestConsolidatedComments/TestConsolidatedComments";
import VerifyDomain from "@supporting/pages/VerifyDomain/VerifyDomain";
import Dashboard from "@workflow/pages/Dashboard/Dashboard";

export const redirectIfRegistered = async () => {
  const isInIframe = Boolean(getEmbedStateParam("auth_triggered_from_iframe"));
  if (!isInIframe) {
    try {
      const { session } = await authenticationService.authenticate();

      if (session.type === "Registered") {
        return redirect(APP_ROUTES.DASHBOARD.path);
      }
    } catch (error) {
      logger.info("routes:redirect-if-registered", "no session found", {
        error,
      });
    }
  }
  return null;
};
export const redirectToAccessDenied =
  (tab) =>
  // eslint-disable-next-line complexity
  async ({ params, request = {} }) => {
    const { team } = await fetchDefault(routeStates.TEAMS)({
      params,
      request,
    });
    if (!team.isTeamMember) {
      return redirect(APP_ROUTES.ACCESS_DENIED.path);
    }
    /* istanbul ignore next */
    switch (tab) {
      case "roles":
      case "settings":
        return team.permissions.canManageMembers
          ? null
          : redirect(`/teams/${team._id}/access-denied`);
      case "branding":
        return team.permissions.canManageLogo
          ? null
          : redirect(`/teams/${team._id}/access-denied`);
      case "subscription":
      case "billing":
        return team.permissions.canManageBilling
          ? null
          : redirect(`/teams/${team._id}/access-denied`);
      case "api":
        return team.permissions.canManageBilling &&
          team.subscriptionLimits.isApiEnabled
          ? null
          : redirect(`/teams/${team._id}/access-denied`);
      default:
        return null;
    }
  };
export const redirectToSelectedTeamTab = async ({ params, request }) => {
  const { user, team } = await fetchDefault(routeStates.TEAMS)({
    params,
    request,
  });
  if (user && team) {
    const url = new URL(request.url);

    const tabName = /^[a-zA-Z]+$/.test(params.tabName)
      ? params.tabName
      : "members";

    return redirect(`/teams/${team._id}/${tabName}${url.search}`);
  }
  return null;
};

export const redirectToDefaultTeamMembersTab = async ({ params, request }) => {
  const { user, team } = await fetchDefault(routeStates.TEAMS_GENERAL)({
    params,
    request,
  });
  if (user && team) {
    return redirect(`/teams/${team._id}/members`);
  }
  return null;
};

export const routes = [
  {
    path: APP_ROUTES.PROFILE_HOME.path,
    element: (
      <Dashboard>
        <Settings />
      </Dashboard>
    ),
    loader: fetchDefault(routeStates.PROFILE_HOME),
    id: "PROFILE_HOME",
    children: [
      {
        index: true,
        element: <EditProfile />,
        id: "EDIT_PROFILE",
      },
      {
        path: "notifications",
        element: <NotificationsTab />,
        id: "NOTIFICATIONS",
      },
      {
        path: "password",
        element: <EditPassword />,
        id: "EDIT_PASSWORD",
      },
      {
        path: "account",
        element: <AccountTab />,
        id: "ACCOUNT",
      },
      {
        path: "early-access",
        element: <EarlyAccess />,
        id: "EARLY_ACCESS",
      },
      {
        path: "debug",
        element: <DebugTab />,
        id: "DEBUG_TAB",
      },
    ],
  },
  {
    path: APP_ROUTES.PROFITWELL.path,
    element: <ProfitWell />,
    id: "PROFITWELL",
  },

  {
    path: APP_ROUTES.TEAMS.path,
    loader: redirectToSelectedTeamTab,
    id: "TEAMS",
  },
  {
    path: APP_ROUTES.TEAM_SPECIFIC.path,
    element: (
      <Dashboard>
        <Teams />
      </Dashboard>
    ),
    loader: fetchDefault(routeStates.TEAM_SPECIFIC),
    id: "TEAM_SPECIFIC",
    children: [
      {
        path: "members",
        loader: redirectToAccessDenied("members"),
        element: <Members />,
        id: "MEMBERS",
      },
      {
        path: "access-denied",

        element: <TabAccessDenied />,
        id: "MEMBERS_ACCESS_DENIED",
      },
      {
        path: "roles",
        loader: redirectToAccessDenied("roles"),
        element: <Roles />,
        id: "ROLES",
      },
      {
        path: "branding",
        loader: redirectToAccessDenied("branding"),
        element: <Branding />,
        id: "BRANDING",
      },
      {
        path: "settings",
        loader: redirectToAccessDenied("settings"),
        element: <TeamSettings />,
        id: "TEAM_SETTINGS",
      },
      {
        path: "subscription",
        loader: redirectToAccessDenied("subscription"),
        element: <Subscription />,
        id: "SUBSCRIPTION",
      },
      {
        path: "billing",
        loader: redirectToAccessDenied("billing"),
        element: <Billing />,
        id: "BILLING",
      },
      {
        path: "api",
        loader: redirectToAccessDenied("api"),
        element: <Api />,
        id: "API",
      },
    ],
  },
  {
    path: APP_ROUTES.TEAMS_GENERAL.path,
    loader: redirectToDefaultTeamMembersTab,
    id: "TEAMS_GENERAL",
  },

  {
    path: "/onboarding-wizard",
    children: [
      {
        path: "setup-user-credentials",
        element: <CaptureSignupCredentials />,
      },
      {
        path: "join-teams",
        element: <JoinTeams />,
        loader: fetchDefault(routeStates.ONBOARDING_WIZARD_JOIN_TEAMS),
      },
      {
        path: "company-size",
        element: <CompanySize />,
        loader: fetchDefault(routeStates.ONBOARDING_WIZARD_COMPANY_SIZE),
      },
      {
        path: "invite-team-members",
        element: <InviteTeamMembers />,
        loader: fetchDefault(routeStates.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS),
      },
      {
        path: "upload-files",
        element: <UploadFiles />,
        loader: fetchDefault(routeStates.ONBOARDING_WIZARD_UPLOAD_FILES),
      },
    ],
  },
  {
    path: APP_ROUTES.SIGNUP.path,
    element: <SignUp />,
    loader: redirectIfRegistered,
    id: "SIGNUP",
  },
  {
    path: APP_ROUTES.SIGNUP_VERIFY_OTP.path,
    element: <SignUpOtp />,
    id: "SIGNUP_VERIFY_OTP",
  },
  {
    path: APP_ROUTES.SIGNUP_VALIDATE.path,
    element: <SignUpValidate />,
    id: "SIGNUP_VALIDATE",
  },
  {
    path: APP_ROUTES.PASSWORD_FORGOT.path,
    element: <ForgotPassword />,
    id: "PASSWORD_FORGOT",
  },
  {
    path: APP_ROUTES.ENFORCE_PASSWORD_CHANGE.path,
    element: <EnforcePasswordChange />,
    id: "ENFORCE_PASSWORD_CHANGE",
  },
  {
    path: APP_ROUTES.LOGOUT.path,
    element: <Logout />,
    id: "LOGOUT",
  },

  {
    path: APP_ROUTES.LOGIN.path,
    element: <Login />,
    loader: redirectIfRegistered,
    id: "LOGIN",
  },
  {
    path: "/external-login-callback",
    element: <ExternalLoginCallback />,
  },
  {
    path: APP_ROUTES.TEAM_JOIN_LINK.path,
    element: <TeamJoinLink />,
    id: "TEAM_JOIN_LINK",
    loader: fetchToSignUpTeamLink(),
  },
  {
    path: APP_ROUTES.VERIFY_DOMAIN.path,
    element: <VerifyDomain />,
    id: "VERIFY_DOMAIN",
  },
  {
    path: "/admin",
    element: <AdminLayout />,
    loader: fetchAdminLoader(routeStates.ADMIN),
    id: "ADMIN",
    children: [
      {
        index: true,
        element: <AdminDashboard />,
      },
      {
        path: "impersonate",
        element: <Impersonate />,
        id: "IMPERSONATE",
      },
      {
        path: "repair-permissions",
        element: <RepairPermissions />,
        id: "REPAIR_PERMISSIONS",
      },
      {
        path: "disconnect-google",
        element: <DisconnectGoogle />,
        id: "DISCONNECT_GOOGLE",
      },
      {
        path: "merge-accounts",
        element: <MergeAccounts />,
        id: "MERGE_ACCOUNTS",
      },
      {
        path: "security-whitelist-user",
        element: <SecurityWhitelistUser />,
        id: "SECURITY_WHITELIST_USER",
      },
      {
        path: "disable-sso",
        element: <DisableSSO />,
        id: "DISABLE_SSO",
      },
      {
        path: "remove-subscription-cache",
        element: <RemoveSubscriptionCache />,
        id: "REMOVE_SUBSCRIPTION_CACHE",
      },
      {
        path: "change-currency",
        element: <ChangeTeamCurrency />,
        id: "CHANGE_TEAM_CURRENCY",
      },
      {
        path: "change-user-email",
        element: <ChangeUserEmail />,
        id: "CHANGE_USER_EMAIL",
      },
      {
        path: "test-consolidated-comments",
        element: <TestConsolidatedComments />,
        id: "TEST_CONSOLIDATED_COMMENTS",
      },
    ],
  },
];

export const unsupportedRoutes = [];
