import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { Text } from "@shared/UIKit";

import { integrationProps } from "@shared/props/integration";

import IntegrationCard from "./IntegrationCard";

function IntegrationsList({ apps }) {
  const { t } = useTranslation();

  return (
    <Box data-testid="integrations-list">
      {Boolean(apps.length) && (
        <Box
          id="integrations-list-all"
          display="grid"
          gap={1.5}
          data-testid="list-category-all"
        >
          <Text fontWeight="fontWeight.medium">
            {t("INTEGRATIONS.SIDEBAR.ALL_APPS")}
          </Text>
          <Box
            display="grid"
            gap={3}
            gridTemplateColumns={{ xs: "repeat(2, 1fr)", sm: "repeat(3, 1fr)" }}
          >
            {apps.map((app) => (
              <IntegrationCard key={app.id} integration={app} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

IntegrationsList.propTypes = {
  apps: PropTypes.arrayOf(integrationProps).isRequired,
};

export default IntegrationsList;
