import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DeleteForeverOutlined } from "@mui/icons-material";
import { Divider, MenuItem, ListItemText, ListItemIcon } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";

import { ConfirmationDialog } from "@shared/UIKit";

import { fileProp, versionProp } from "@shared/props/file";

function DeleteFileMenuItem({ file, version, closeMenu }) {
  const { t } = useTranslation();
  const [isFileRemoveDialogOpen, setIsFileRemoveDialogOpen] = useState(false);

  const openFileRemoveDialog = () => {
    setIsFileRemoveDialogOpen(true);
  };

  const closeFileRemoveDialog = () => {
    setIsFileRemoveDialogOpen(false);
    closeMenu();
  };

  const removeFile = async () => {
    await fileService.removeFile(file);
  };

  return (
    <>
      <Divider />
      <MenuItem
        data-testid="project-file-menu-delete-file"
        onClick={openFileRemoveDialog}
        data-accent-color="error"
      >
        <ListItemIcon>
          <DeleteForeverOutlined />
        </ListItemIcon>
        <ListItemText
          primary={
            file.versions.length === 1
              ? t("FILE.MENU.DELETE_VERSION", {
                  versionNumber: version.number,
                })
              : t("FILE.MENU.DELETE_ALL_VERSIONS")
          }
        />
      </MenuItem>

      {isFileRemoveDialogOpen && (
        <ConfirmationDialog
          isOpen={isFileRemoveDialogOpen}
          title={t("PROJECT.DIALOGS.DELETEFILE.HEADLINE")}
          description={t("PROJECT.DIALOGS.DELETEFILE.DESCRIPTION")}
          confirmLabel={t("PROJECT.FILE.DELETE")}
          closeDialog={closeFileRemoveDialog}
          answer={removeFile}
        />
      )}
    </>
  );
}

DeleteFileMenuItem.propTypes = {
  file: fileProp.isRequired,
  version: versionProp.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default DeleteFileMenuItem;
