import PropTypes from "prop-types";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigation } from "react-router-dom";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton as Button } from "@mui/lab";
import { TextField, Box } from "@mui/material";
import authentication from "@supporting/services/authentication";
import { z } from "zod";

import Text from "@shared/UIKit/Text";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

const emailFormSchema = z.object({
  userEmail: z.string().email("FORM.EMAIL.MESSAGE_FORMAT"),
});

const passwordFormSchema = z.object({
  userPassword: z.string().min(1, "FORM.PASSWORD_LOGIN.MESSAGE_REQUIRED"),
});

const fullNameFormSchema = z.object({
  userFullName: z.string().min(1, "FORM.FULL_NAME.MESSAGE_REQUIRED"),
});

const defaultEmailFormValues = {
  userEmail: "",
};
const EmailForm = ({ onSubmit, ...restProps }) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(emailFormSchema),
    mode: "onChange",
    defaultValues: defaultEmailFormValues,
  });

  return (
    <StepForm
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      {...restProps}
    >
      <Controller
        name="userEmail"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("FORM.EMAIL.WORK_LABEL")}
            {...field}
            fullWidth
            error={Boolean(errors.userEmail)}
            helperText={t(errors.userEmail?.message || "")}
          />
        )}
      />
    </StepForm>
  );
};
EmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const PasswordForm = ({ onSubmit, ...restProps }) => {
  const { t } = useTranslation();
  const currentRouteName = useCurrentRouteName();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(passwordFormSchema),
    mode: "onChange",
    defaultValues: {
      userPassword: "",
    },
  });

  useEffect(() => {
    analytics.trackV2({
      category: analytics.CATEGORY.USER_CREDENTIALS_PAGE,
      action: analytics.ACTION.OPENED,
      metaData: {
        loginType: "password",
        page: getCurrentPage(currentRouteName),
      },
    });
  }, [currentRouteName]);

  return (
    <StepForm
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      {...restProps}
    >
      <Controller
        name="userPassword"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("FORM.PASSWORD.LABEL")}
            type="password"
            {...field}
            fullWidth
            error={Boolean(errors.userPassword)}
            helperText={t(errors.userPassword?.message || "")}
          />
        )}
      />
    </StepForm>
  );
};
PasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const FullNameForm = ({ email, onSubmit, ...restProps }) => {
  const { t } = useTranslation();
  const currentRouteName = useCurrentRouteName();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: zodResolver(fullNameFormSchema),
    mode: "onChange",
    defaultValues: {
      userFullName: authentication.nameFrom(email),
    },
  });
  useEffect(() => {
    analytics.trackV2({
      category: analytics.CATEGORY.REVIEW_LINK_ACCESS_PAGE,
      action: analytics.ACTION.OPENED,
      metaData: {
        page: getCurrentPage(currentRouteName),
      },
    });
  }, [currentRouteName]);
  return (
    <StepForm
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      {...restProps}
    >
      <Controller
        name="userFullName"
        control={control}
        render={({ field }) => (
          <TextField
            label={t("FORM.NAME.LABEL")}
            {...field}
            fullWidth
            error={Boolean(errors.userFullName)}
            helperText={t(errors.userFullName?.message || "")}
          />
        )}
      />
    </StepForm>
  );
};
FullNameForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
};

const StepForm = ({ onSubmit, children, loading, submitError }) => {
  const { t } = useTranslation();
  const { state } = useNavigation();

  const isNavigating = state === "loading";
  return (
    <Box
      component="form"
      id="email-form"
      onSubmit={onSubmit}
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap={6.75}
      width="100%"
      maxWidth={358}
    >
      {children}
      {submitError && (
        <Box>
          <Text color="error" translate={submitError} />
        </Box>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        loading={loading || isNavigating}
      >
        {t("ACCESS_CONTROL.REGISTERED.BUTTON_NEXT")}
      </Button>
    </Box>
  );
};
StepForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  submitError: PropTypes.string,
};
StepForm.defaultProps = {
  submitError: null,
};

const StepFormComponent = {
  email: EmailForm,
  password: PasswordForm,
  fullName: FullNameForm,
};

const StepLoginAuthenticationForm = ({ step, ...restProps }) => {
  const FormComponent = StepFormComponent[step];
  return <FormComponent {...restProps} />;
};

StepLoginAuthenticationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  step: PropTypes.oneOf(["email", "password", "fullName"]).isRequired,
};
export default StepLoginAuthenticationForm;
