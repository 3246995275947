import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { Link } from "@mui/material";

import { Box } from "@shared/UIKit";

function Toast({ title, body }) {
  return (
    <Box data-testid="toast-body">
      <Box className="toast-title">{title}</Box>
      {body && (
        <Box className="toast-message">
          {/* eslint-disable-next-line id-length */}
          <Trans components={{ a: <Link /> }}>{body}</Trans>
        </Box>
      )}
    </Box>
  );
}

Toast.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
};

Toast.defaultProps = { body: null };
export default Toast;
