import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { PersonAddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import AddReviewerDialog from "@workflow/components/AddReviewerDialog/AddReviewerDialog";

import { Tooltip } from "@shared/UIKit";

import { stepProps } from "@shared/props/step";

function AddReviewer({ step, teamId, disabled, isProjectTemplate }) {
  const { t } = useTranslation();

  function showAddReviewerDialog() {
    return NiceModal.show(AddReviewerDialog, {
      teamId,
      step,
      isProjectTemplate,
    });
  }

  if (disabled) {
    return null;
  }

  return (
    <Tooltip title={t("REVIEWER.INVITE.TITLE")} placement="top">
      <Button
        data-testid="step-reviewers-add-reviewers"
        onClick={showAddReviewerDialog}
        className="tour__invite-reviewer"
        color="primary"
        variant="outlined"
        shape="round"
        size="small"
        disableRipple
        sx={{ zIndex: 99 }}
      >
        <PersonAddOutlined fontSize="small" />
      </Button>
    </Tooltip>
  );
}

AddReviewer.propTypes = {
  teamId: PropTypes.string.isRequired,
  step: stepProps,
  disabled: PropTypes.bool,
  isProjectTemplate: PropTypes.bool,
};

AddReviewer.defaultProps = {
  disabled: false,
  step: null,
  isProjectTemplate: false,
};

export default AddReviewer;
