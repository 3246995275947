import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";

import { Tooltip } from "@shared/UIKit";

function CollapseButton({
  isCollapsed,
  isDisabled,
  onCollapse,
  textCollapsed,
  textExpanded,
}) {
  const { t } = useTranslation();

  return (
    <Tooltip
      disabled={isDisabled}
      title={t(isCollapsed ? textCollapsed : textExpanded)}
    >
      <IconButton
        disabled={isDisabled}
        onClick={onCollapse}
        data-testid="collapse-button"
        data-collapsed={isCollapsed}
      >
        <ExpandMoreIcon
          fontSize="inherit"
          sx={{ ...(isCollapsed ? { transform: "rotate(-90deg)" } : {}) }}
        />
      </IconButton>
    </Tooltip>
  );
}

CollapseButton.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool,
  isDisabled: PropTypes.bool,
  textCollapsed: PropTypes.string,
  textExpanded: PropTypes.string,
};

CollapseButton.defaultProps = {
  isCollapsed: false,
  isDisabled: false,
  textCollapsed: "Show",
  textExpanded: "Hide",
};

export default CollapseButton;
