import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import NameContent from "@supporting/components/ReviewersAuthenticationFlow/NameContent";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import userService from "@supporting/services/userService";
import jstz from "jstz";

import { instance as analytics } from "@shared/services/analytics";
import errorHandler from "@shared/services/errorHandler";
import { instance as localisationService } from "@shared/services/localisationService";
export default function ReviewerNameContent({
  fullName,
  setFullName,
  onSubmit,
  email,
  context,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const invalidName = !fullName || !fullName.trim();
  const onNameSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      await userService.update({
        fullName,
        email,
        language: localisationService.getLanguage(),
        timeZone: jstz.determine().name(),
      });
      const selectedTeam = await teamService.fetchTeamByTeamId(context.teamId);
      if (selectedTeam.settings?.verifyReviewerEmailSetting) {
        await authenticationService.sendOTP(email);
        analytics.track(
          analytics.ACTION.REQUESTED,
          analytics.CATEGORY.EMAIL_VERIFICATION,
          {
            source: "reviewer-login",
          }
        );
        setIsLoading(false);
        onSubmit("SHOW_VERIFY_EMAIL");
        return;
      }
      setIsLoading(false);
      onSubmit("CLOSE_DIALOG");
    } catch (error) {
      setIsLoading(false);
      errorHandler.handleError(error);
    }
  };
  const onCreateAccount = () => {
    onSubmit("SHOW_CREATE_ACCOUNT");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
      component="form"
      onSubmit={onNameSubmit}
    >
      <NameContent
        fullName={fullName}
        setFullName={setFullName}
        title="MODAL.REVIEWER_FLOW.SUBTITLE_2"
        invalidName={invalidName}
        autoFocus
      />
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={2}
      >
        <Button
          sx={{ width: { md: 238.5 } }}
          size="large"
          variant="outlined"
          onClick={onCreateAccount}
          disabled={invalidName || isLoading}
        >
          {t("SUBTEXT.CREATE_FREE_ACCOUNT")}
        </Button>
        <Button
          sx={{ width: { md: 238.5 } }}
          size="large"
          variant="contained"
          type="submit"
          disabled={invalidName}
          loading={isLoading}
          data-testid="workspace-auth-dialog-name-button"
        >
          {t("MODAL_NAME.REVIEWER_FLOW.PRIMARY_CTA")}
        </Button>
      </Box>
    </Box>
  );
}
ReviewerNameContent.propTypes = {
  fullName: PropTypes.string.isRequired,
  setFullName: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  context: PropTypes.object.isRequired,
};
