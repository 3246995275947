import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, Text } from "@shared/UIKit";

function TeamNotDeletableDialog({ isOpen, closeDialog, canManageMembers }) {
  const { t } = useTranslation();
  const cancelDialog = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  return (
    <Dialog
      title={t("TEAM.SETTINGS.DELETE_TEAM.NOT_DELETABLE.HEADLINE")}
      cancel={cancelDialog}
      open={isOpen}
      hideBackdrop={false}
    >
      <Text variant="body2">
        {canManageMembers
          ? t("TEAM.SETTINGS.DELETE_TEAM.NOT_DELETABLE.DESCRIPTION-1")
          : t("TEAM.SETTINGS.DELETE_TEAM.NOT_DELETABLE.DESCRIPTION-2")}
      </Text>
    </Dialog>
  );
}

TeamNotDeletableDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  canManageMembers: PropTypes.bool.isRequired,
};

export default TeamNotDeletableDialog;
