/* eslint-disable max-lines */
export const CATEGORY = {
  ADD_SEATS_DIALOG: "add-seats-dialog",
  REMOVE_SEATS_DIALOG: "remove-seats-dialog",
  RESTART_TRIAL_DIALOG: {
    UPGRADE: "restart-trial-dialog-upgrade-button",
    TRY_PROFESSIONAL: "restart-trial-dialog-try-professional",
  },
  RETRY_TRANSCODING: "retry-transcoding",
  ANNOTATION: "annotation",
  FILE_TRANSCODING: "file-transcoding",
  CUSTOM_BRANDING: "custom-branding",
  CUSTOM_ROLES: "custom-roles",
  MEMBER_ROLE: "member-role",
  ATTACHMENT: "attachment",
  ACCOUNT_USER_PASSWORD: "account-user-password",
  ACCOUNT_USER_PASSWORD_FAILED: "account-user-password-failed",
  ATTACHMENT_PREVIEW: "attachment-preview",
  ACCOUNT: "account",
  ARCHIVED_PROJECTS: "archived-projects",
  AVATAR: "avatar",
  FILE_GALLERY: "file-gallery",
  FILE_GALLERY_FILE: "file-gallery-file",
  COMMENT: "comment",
  COLLABORATOR: "collaborator",
  COMMENT_LIST: "comment-list",
  COMPARE_REVIEWS: {
    COMPARE_REVIEWS: "compare-reviews",
    COMPARE_SIDE_BY_SIDE: "compare-reviews-compare-side-by-side",
    AUTO_COMPARE_TEXT: "compare-reviews-auto-compare-text",
    VISUAL_AUTO_COMPARE: "compare-reviews-visual-auto-compare",
    VISUAL_AUTO_COMPARE_SLIDER: "compare-reviews-visual-auto-compare-slider",
    AUTO_COMPARE_TEXT_SIDEBAR: "compare-reviews-auto-compare-text-sidebar",
    AUTO_COMPARE_TEXT_COMMENTS_SIDEBAR:
      "compare-reviews-auto-compare-text-comments-sidebar",
  },
  MONDAY_APP: {
    FILESTAGE_LOGIN: "monday-com-filestage-login",
    FILESTAGE_SIGNUP: "monday-com-filestage-signup",
    FILESTAGE_ITEM_VIEWER: "item-view-to-monday",
    FILESTAGE_BOARD_VIEWER: "board-view-to-monday",
  },
  SLACK_APP: {
    CHANNEL: "slack-channel",
    SETTINGS: "slack-settings",
  },
  CONFIRM_SEAT_UPGRADE_POPOVER: "confirm-seat-upgrade-popover",
  COPY_COMMENTS_MODE: "copy-comments-mode",
  COPY_COMMENTS_MODE_COMMENT: "copy-comments-mode-comment",
  COPY_COMMENTS_MODE_COMMENTS: "copy-comments-mode-comments",
  COPY_COMMENTS_MODE_REVIEW_STEP: "copy-comments-mode-review-step",
  COPY_COMMENTS_MODE_ALL_COMMENTS: "copy-comments-mode-all-comments",
  COPY_COMMENTS_HELP_BUTTON: "copy-comments-help-button",
  COPY_COMMENTS_MENU: "copy-comments-menu",
  COPY_COMMENTS_MODE_VERSION: "copy-comments-mode-version",
  COPY_COMMENTS_MODE_COPY_TO_VERSION: "copy-comments-mode-copy-to-version",

  ENFORCE_PASSWORD_CHANGE_PAGE: "enforce-password-change-page",
  ENFORCE_PASSWORD_CHANGE_PAGE_ACCOUNT_USER_PASSWORD:
    "enforce-password-change-page-account-user-password",
  ENFORCE_PASSWORD_CHANGE_PAGE_ACCOUNT_USER_PASSWORD_FAILED:
    "enforce-password-change-page-account-user-password-failed",
  FEEDBACK: "feedback",
  FEEDBACK_EXPORT: "feedback-export",
  FILE: "file",
  FILE_WTTJ: "file-wttj",
  GRIDVIEW_WTTJ: "gridview-wttj",
  FILES: "files",
  FILE_COLUMN: "file-column",
  FILESTORAGE: "filestorage",
  ISSUE: "issue",
  REPLY: "reply",
  TEAM_ONLY_COMMENT: "team-only-comment",
  TEAM_ONLY_REPLY: "team-only-reply",
  PROJECT: "project",
  PROJECT_TEMPLATE: "project-template",
  REVIEW: "review",
  REVIEWER: "reviewer",
  REVIEWER_LIST: "reviewer-list",
  REQUEST_CUSTOM_METRICS_BUTTON: "request-custom-metrics-button",
  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_PLAN_SELECTION_BUTTON: "subscription-pricing-page-select-button",
  SUBSCRIPTION_LIMIT: "subscription-limit",
  SUBSCRIPTION_CANCELLATION_DIALOG: "subscription-cancellation-dialog",
  TEAM: "team",
  USER: "user",
  VERSION: "version",
  PROJECT_FILES: "project-files",
  REVIEW_STEP: "review-step",
  REVIEW_STEP_SETTINGS: "review-step-settings",
  TEAM_SETTINGS: {
    VERIFY_REVIEWER: "team-settings-verify-reviewer",
    API: "team-settings-api",
  },
  EMAIL_VERIFICATION: "email-verification",
  REVIEW_DECISIONS: "review-decisions",
  STEP_REVIEW_LINK: "step-review-link",
  FILE_REVIEW_LINK: "file-review-link",
  BATCH_REVIEW_LINK: "batch-review-link",
  FOLDER: "folder",
  DUE_DATE: "due-date",
  DROPBOX: "dropbox",
  GOOGLE_DRIVE: "google-drive",
  PENDING_REVIEW: "pending-review",
  UPGRADE_TEAM_ONLY_PLAN: "upgrade-team-only-plan",
  REVIEW_STEP_SETTINGS_UPGRADE:
    "review-step-settings-upload-files-upgrade-button",
  SEARCH: "search",
  SEARCH_RESULT: "search-result",
  SEARCH_RESULT_FILTER: "search-result-filter",
  SCHEDULE_DEMO: "schedule-demo",
  ONBOARDING_WIZARD: "onboarding-wizard",
  ONBOARDING_WIZARD_DEMO_OR_UPLOAD_FILES:
    "onboarding-wizard-demo-or-upload-files",
  ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS:
    "onboarding-wizard-invite-team-members",
  ONBOARDING_CHECKLIST: "onboarding-checklist",
  ONBOARDING_CHECKLIST_GUIDE: "onboarding-checklist-guide",
  SIGNUP_REASON: "signup-reason",
  COMPANY_SIZE: "company-size",
  QUALITY: "quality",
  RESOLVED_COMMENTS: "resolved-comments",
  NEW_DOCUMENT_VIEWER: "new-document-viewer",
  IMAGE_VIEWER: "image-viewer",
  CHANGELOG: "changelog",
  HELP_MENU: {
    ROOT: "help-menu",
    HELP_CENTER: "help-menu-help-center",
    VIDEO_TUTORIALS: "help-menu-video-tutorials",
    SUPPORT_CHAT: "help-menu-support-chat",
    KEYBOARD_SHORTCUTS: "help-menu-keyboard-shortcuts",
  },

  NOTIFICATION_CENTER: "notification-center",
  NOTIFICATION_CENTER_TAB: "notification-center-tab",
  NOTIFICATION_CENTER_NOTIFICATIONS: "notification-center-notifications",
  NOTIFICATION_CENTER_NOTIFICATION: "notification-center-notification",
  NOTIFICATIONS: "notifications",
  EMAIL_PREFERENCES: {
    PROJECT_ACTIVITY: "email-preferences-project-activity",
    PROJECT_ACTIVITY_TIME_PERIOD:
      "email-preferences-project-activity-time-period",
    MENTION: "email-preferences-mention",
    DUE_DATE: "email-preferences-due-date",
    SUBMITTED_REVIEWS: "email-preferences-submitted-reviews",
  },
  VERSION_ROWS: "version-rows",
  FILE_INFORMATION: "file-information",
  VIEWER: "viewer",
  REVIEW_STATUS: "review-status",
  REVIEW_REPORT: "review-report",
  PREVIEW_REVIEW_REPORT: "preview-review-report",
  EARLY_ACCESS: "early-access",
  APP_MARKETPLACE: {
    APPS: "app-marketplace",
    AUTHENTICATION: "app-marketplace-authentication",
    SETTINGS: "app-marketplace-settings",
    TEAM: "team-app-marketplace",
    SUBSCRIPTION_LIMIT_FEATURE: "subscription-limit-app-marketplace-feature",
    REQUEST_INTEGRATION_FORM: "app-marketplace-request-integration-form",
    REGISTER_INTEREST_DIALOG: "app-marketplace-register-interest-dialog",
    REGISTER_INTEREST: "app-marketplace-register-interest",
  },
  UPLOAD_PANEL: "upload-panel",
  UPLOAD_PANEL_FILES_TO_UPLOAD: "upload-panel-files-to-upload",
  UPLOAD_PANEL_UPLOAD_FILES_BUTTON: "upload-panel-upload-files-button",
  UPLOAD_PANEL_REVIEWER_GROUPS: "upload-panel-reviewer-groups",
  IMPORT_WEBSITE_BUTTON: "import-website-button",
  DROPBOX_BUTTON: "dropbox-button",
  GOOGLE_DRIVE_BUTTON: "google-drive-button",
  ONE_DRIVE_BUTTON: "one-drive-button",
  IMPORT_WEBSITE_NON_SUPPORTED_PROTOCOL:
    "import-website-non-supported-url-protocol",
  WEBSITE_REVIEW_MODE: "website-review-mode",
  INSTALL_EXTENSION_BUTTON: "install-extension-button",
  INSTALL_BROWSER_BUTTON: "install-browser-button",
  SUBSCRIPTION_LIMIT_REQUEST_REVIEW_DECISION:
    "subscription-limit-request-review-decision-feature",
  REQUEST_REVIEWER_DECISION: "request-reviewer-decision",
  GUEST_REVIEWER_REVIEW_DECISION: "guest-reviewer-review-decision",
  INVITE_REVIEWER_DIALOG: "invite-reviewer-dialog",
  REVIEWER_MENU_REQUEST_REVIEWER_DECISION:
    "reviewer-menu-request-reviewer-decision",
  VIDEO_VIEWER: "video-viewer",
  VIDEO_VIEWER_PLAY_BUTTON: "video-viewer-play-button",
  VIDEO_VIEWER_PAUSE_BUTTON: "video-viewer-pause-button",
  VIDEO_VIEWER_VOLUME: "video-viewer-volume",
  VIDEO_VIEWER_MARKER: "video-viewer-markers",
  VIDEO_VIEWER_FULL_SCREEN_MODE: "video-viewer-full-screen-mode",
  VIDEO_VIEWER_TIME_FORMAT: "video-viewer-time-format",
  VIDEO_VIEWER_PLAYBACK_SPEED: "video-viewer-playback-speed",
  VIDEO_VIEWER_LOOP_MODE: "video-viewer-loop-mode",
  SECTION: "section",
  SECTION_SETTINGS: {
    COLLAPSE_ALL_SECTIONS: "section-settings-collapse-all-sections",
    EXPAND_ALL_SECTIONS: "section-settings-expand-all-sections",
  },
  UNDO_REVIEW_DECISION: "undo-review-decision-button",
  FILE_REPORT: "file-report",
  PROJECTS_SIDEBAR: "projects-sidebar",
  COMMENTS_SIDEBAR: "comments-sidebar",
  PREVIEWED_SCREEN_SIZE: "previewed-screen-size",
  ADD_REVIEWER_TO_TEMPLATE_DIALOG: "add-reviewer-to-template-dialog",
  STEP_TEMPLATE_REVIEWER: "step-template-reviewer",
  SEATS: "seats",
  REVIEWER_EMAIL_ADDRESS: "reviewer-email-address",
  COPY_COMMENT_LINK: "copy-comment-link",
  REFERRAL_DIALOG: "referral-dialog",
  TRUNCATED_COLLABORATORS: "truncated-collaborators",
  REVIEWER_IDENTIFICATION_DIALOG_EMAIL: "reviewer-identification-dialog-email",
  REVIEWER_IDENTIFICATION_DIALOG_NAME: "reviewer-identification-dialog-name",
  REVIEWER_IDENTIFICATION_DIALOG_PASSWORD:
    "reviewer-identification-dialog-password",
  EMOJI: "emoji",
  AUTOMATION: "automation",
  AUTOMATION_CREATION: "automation-creation",
  AUTOMATION_OVERVIEW: "automations-overview",
  AUTOMATION_TRIGGER: "automation-trigger",
  AUTOMATION_ACTION: "automation-action",
  AUTOMATION_REQUEST_BUTTON: "request-automation-button",
  AUTOMATION_UPGRADE_BUTTON: "automation-upgrade-button",
  FILE_NAME: "file-name",
  PROJECT_FAVORITES: "favorite-projects",
  INSIGHTS_PAGE: "insights-page",
  INSIGHTS: {
    NAME: "insights",
    OVERVIEW_DASHBOARD: "insights-overview-dashboard",
    PROJECTS_DASHBOARD: "insights-projects-dashboard",
    REVIEWS_DASHBOARD: "insights-reviews-dashboard",
    REVIEWERS_DASHBOARD: "insights-reviewers-dashboard",
  },
  INSIGHTS_INTEREST_BUTTON: "insights-interest-button",
  INTEGRATIONS: {
    API_KEY: "api-key",
    REGENERATE_API_KEY: "regenerate-api-key",
    GENERATE_API_KEY: "generate-api-key",
  },
  COMMENT_MENTION: "comment-mention",
  COMMENT_MENTION_BUTTON: "comment-mention-button",
  TEAM_MEMBER_EMAIL: "team-member-email",
  TEAM_MEMBER_EMAIL_FAILED: "team-member-email-failed",
  INVITE_TEAM_MEMBER_DIALOG: "invite-team-member-dialog",
  AUDIO_VIEWER_VOLUME: "audio-viewer-volume",
  AUDIO_VIEWER_PLAYBACK_SPEED: "audio-viewer-playback-speed",
  SIGNUP_FLOW: "signup-flow",
  SIGNUP_PAGE: "signup-page",
  SIGNUP_FORM: "signup-form",
  SIGNUP_REASON_PAGE: "signup-reason-page",
  EMAIL_VERIFICATION_PAGE: "email-verification-page",
  EMAIL_VERIFICATION_FORM: "email-verification-form",
  USER_CREDENTIALS_PAGE: "user-credentials-page",
  COMPANY_SIZE_PAGE: "company-size-page",
  AI_MARKETPLACE: {
    DIALOG: "ai-marketplace-dialog",
    INTEREST_BUTTON: "ai-marketplace-interest-button",
    LEARN_MORE_LINK: "ai-marketplace-learn-more-link",
    REQUEST_BOT_BUTTON: "ai-marketplace-request-bot-button",
    EXPLAINER_VIDEO_WATCH_VIDEO_BUTTON:
      "ai-marketplace-explainer-video-watch-video-button",
    EXPLAINER_VIDEO_DISMISS_BUTTON:
      "ai-marketplace-explainer-video-dismiss-button",
  },
  LOGIN_UNREGISTERED_EMAIL: "login-unregistered-email",
  LOGIN_REGISTERED_EMAIL: "login-registered-email",
  BULK_ACTION_TOOLBAR: "bulk-action-toolbar",
  BULK_DOWNLOAD_FILES_BUTTON: "bulk-download-files-button",
  DASHBOARD_BULK_DELETE_FILES_BUTTON: "dashboard-bulk-delete-files-button",
  DELETE_LATEST_FILE_VERSIONS: "delete-latest-file-versions",
  BATCH_REVIEW: "grid-view",
  REVIEW_STEP_PASSWORD_PAGE: "review-step-password-page",
  REVIEW_STEP_PASSWORD: "review-step-password",
  REVIEW_STEP_PASSWORD_FAILED: "review-step-password-failed",
  SUBSCRIPTION_PLAN_CHANGE: "subscription-plan-change",
  SUBSCRIPTION_INVALID_TAX_ID: "subscription-invalid-tax-id",
  TRIAL_END_MODAL: {
    COMPONENT: "trial-end-modal",
    PAID_PLAN: "trial-end-modal-paid-plan",
    FREE_PLAN_BUTTON: "trial-end-modal-free-plan-button",
    COMPARE_PLANS_LINK: "trial-end-modal-compare-plans-link",
  },
  SECURITY: "security",
  TEAM_MEMBER: "team-member",
  TEAM_DISCOVERABILITY: "team-discoverability",
  TEAM_SETTINGS_DOMAIN: "team-settings-domain",
  TEAM_SETTINGS_INVITE_LINK: "team-settings-invite-link",
  TEAM_INVITE_LINK: "team-invite-link",
  TEAM_MEMBER_JOIN_REQUEST: "team-member-join-request",
  ZAPIER_TEMPLATES: {
    LEARN_MORE_LINK: "zapier-templates-learn-more-link",
    CRETE_FROM_SCRATCH: "zapier-templates-create-from-scratch",
    GET_UPWORK_FREELANCER: "zapier-templates-get-upwork-freelancer",
  },
  PINNED_COMMENT: "pinned-comment",
  PLAN_LIMITS_COMPONENT: "plan-limits-component",
  PROJECT_SETTINGS_COLLAPSE_ALL_FOLDERS:
    "project-settings-collapse-all-folders",
  PROJECT_SETTINGS_EXPAND_ALL_FOLDERS: "project-settings-expand-all-folders",
  VERIFY_REVIEWER: "verify-reviewer",
  SSO: "sso",
  ANONYMOUS_REVIEWERS: "anonymous-reviewers",
  CUSTOM_INTEGRATIONS: "custom-integrations",
  NAME_UNREGISTERED_EMAIL: "name-unregistered-email",
  REVIEW_LINK_ACCESS: "review-link-access",
  ACCESS_DENIED_PAGE: "access-denied-page",
  REVIEW_LINK_ACCESS_PAGE: "review-link-access-page",
  RESTRICTED_REVIEW_LINK: "restricted-review-link",
};

export const SUB_CATEGORY = {
  CUSTOM_BRANDING: "custom-branding",
  CUSTOM_ROLES: "custom-roles",
  INSIGHTS: "insights",
  SEATS: "seats",
  UPGRADE_BUTTON: "upgrade-button",
  UPGRADE_INTEREST_BUTTON: "upgrade-interest-button",
  VERIFY_REVIEWER: "verify-reviewer",
  ONBOARDING_WIZARD: "onboarding-wizard",
  SIGN_INTO_ANOTHER_ACCOUNT: "sign-into-another-account",
  AUTOMATION_QUANTITY: "automation-quantity",
  PROJECT_QUANTITY: "project-quantity",
  STORAGE_QUANTITY: "storage-quantity",
  REQUEST_REVIEWER_DECISION: "request-reviewer-decision",
  REVIEWER_UPLOAD: "reviewer-upload",
  VERIFIED_APPROVAL: "verified-approval",
  REVIEW_ACCESS_CONTROL: "review-access-control",
  ANONYMOUS_REVIEWERS: "anonymous-reviewers",
  COPY_COMMENTS: "copy-comments",
};

export const TYPE = {
  FEATURE: "feature",
  QUANTITY: "quantity",
};

export const ACTION = {
  ACCOUNT_USER_PASSWORD_FAILED: "account-user-password-failed",
  UNLOCKED: "unlocked",
  CANCELLED: "cancelled",
  FAILED: "failed",
  CREATED: "created",
  COPIED: "copied",
  CHANGED: "changed",
  CLICKED: "clicked",
  CLOSED: "closed",
  DELETED: "deleted",
  DESELECTED: "deselected",
  DISPLAYED: "displayed",
  ENTERED: "entered",
  EXITED: "exited",
  EXPORTED: "exported",
  HOVERED_OVER: "hovered-over",
  REJECTED: "rejected",
  RESET: "reset",
  MASKED_PASSWORD: "masked",
  UMASKED_PASSWORD: "unmasked",
  EXCEEDED_USER_LIMIT: "exceeded-user-limit",
  EXCEEDED_REVIEW_STEP_LIMIT: "exceeded-review-step-limit",
  RE_INVITED: "re-invited",
  INVITED: "invited",
  INVITED_MEMBER: "invited-member",
  MENTIONED: "mentioned",
  OPENED: "opened",
  OPENED_PRICING_PAGE: "opened-pricing-page",
  OPENED_FILE: "opened-file",
  OPENED_PROJECT: "opened-project",
  OPENED_COMMENT: "opened-comment",
  PROJECT_CREATED: "project-created",
  RESOLVED: "resolved",
  REMOVED: "removed",
  RENAMED: "renamed",
  SUBSCRIBED_TO_PAID_PLAN: "subscribed-to-paid-plan",
  SWITCHED: "switched",
  UPLOADED: "uploaded",
  UPLOADED_LOGO: "uploaded-logo",
  UPDATED_CREDIT_CARD: "updated-credit-card",
  UNRESOLVED: "unresolved",
  VIEWED: "viewed",
  MARKED_AS_APPROVED: "marked-as-approved",
  MOVED: "moved",
  MOVED_FILE: "moved-file",
  MOVED_PROJECT: "moved-project",
  ARCHIVED: "archived",
  UNARCHIVED: "un-archived",
  SHOWED: "showed",
  SHOW: "show",
  HID: "hid",
  HIDE: "hide",
  SUBMITTED: "submitted",
  CONFIRMED: "confirmed",
  REQUESTED: "requested",
  UPLOADED_FROM: "uploaded-from",
  CHANGED_SORTING: "changed-sorting",
  OPENED_OVERVIEW: "opened-overview",
  OPENED_TEASER: "opened-teaser",
  STARTED: "started",
  COMPLETED: "completed",
  ZOOMED_IN: "zoomed-in",
  ZOOMED_OUT: "zoomed-out",
  ZOOM_CHANGE: "changed-zoom",
  ZOOM_RESET: "reset-zoom",
  ADDED_TO: "added-to",
  REMOVED_FROM: "removed-from",
  MUTED: "muted",
  UNMUTED: "unmuted",
  VIEWED_THUMBNAILS: "viewed-thumbnails",
  VIEWED_OUTLINES: "viewed-outlines",
  FIT_TO_WIDTH: "selected-fit-to-width",
  FIT_TO_PAGE: "selected-fit-to-page",
  SELECTED_STANDARD_QUALITY: "selected-standard-quality",
  SELECTED_HIGH_QUALITY: "selected-high-quality",
  MARQUEE_ZOOM_SELECTED: "selected-marquee-zoom",
  CHANGED_ZOOM_LEVEL: "selected-zoom-level",
  SEARCHED_DOCUMENT: "searched-document",
  CHANGED_PAGE_TRANSITION: "changed-page-transition",
  CHANGED_PAGE_LAYOUT: "changed-page-layout",
  ROTATED_PAGE: "rotated-page",
  COPIED_TEXT: "copied-text",
  HIGHLIGHTED_TEXT: "highlighted-text",
  STRUCKOUT_TEXT: "struckout-text",
  MARKED_AS_READ: "marked-as-read",
  MARKED_AS_UNREAD: "marked-as-unread",
  MARKED_ALL_AS_READ: "marked-all-as-read",
  VIEWED_MORE: "viewed-more",
  VIEWED_MORE_ACTIVITIES: "viewed-more-activities",
  ALL_SELECTED: "selected-all",
  UNREAD_SELECTED: "selected-unread",
  SHOWED_FULL_COMMENT: "showed-full-comment",
  COMPLETED_VIDEO_STEP: "completed-video-step",
  WATCHED_VIDEO: "watched-video",
  ENABLED: "enabled",
  DISABLED: "disabled",
  REACHED: "reached",
  EXPANDED: "expanded",
  COLLAPSED: "collapsed",
  RESET_PASSWORD: "clicked-account-user-password-reset",
  UPDATED_ACCOUNT_USER_EMAIL: "updated-account-user-email",
  UPDATED_ACCOUNT_USER_EMAIL_FAILED: "updated-account-user-email-failed",
  UPDATED_ACCOUNT_USER_PASSWORD: "updated-account-user-password",
  UPDATED_ACCOUNT_USER_PASSWORD_FAILED: "updated-account-user-password-failed",
  SAVED: "saved",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  REORDERED: "reordered",
  MOVED_TO_SECTION: "moved-to-section",
  DOWNLOADED: "downloaded",
  PASTED: "pasted",
  REFRESHED: "refreshed",
  SELECTED: "selected",
  UNSELECTED: "unselected",
  ADDED: "added",
  INSTALLED: "installed",
  PAUSED: "paused",
  ACTIVATED: "activated",
  REGENERATED: "regenerated",
  LIKED: "liked",
  UNLIKED: "unliked",
  SKIPPED: "skipped",
  SUBMITTED_IN_10_MINS: "submitted-in-10-mins",
  RESIZED: "resized",
  BLOCKED_BY: "blocked-by",
  JOINED: "joined",
  APPROVED: "approved",
  UPDATED: "updated",
  PINNED: "pinned",
  UNPINNED: "unpinned",
};

export const SESSION_TYPE_TO_USER_ROLE = {
  Registered: "registered-user",
  Guest: "guest-user",
};

export const LABEL = {
  PROJECT_IS_ARCHIVED: "project-is-archived",
  FROM_TEMPLATE: "from-template",
  FILE_TYPE: "file-type",
  FILE_NAME: "file-name",
  EMAIL_NOTIFICATION_ENABLED: "email-notification-enabled",
  EMAIL_NOTIFICATION_DISABLED: "email-notification-disabled",
  BROWSE_MODE: "browse-mode",
  SINGLE_EMAIL: "single-email",
  MULTIPLE_EMAILS: "multiple-emails",
  ANONYMOUS_REVIEWERS: "anonymous-reviewers",
  RESPONSIVE: "responsive",
  TABLET: "tablet",
  OPENED: "opened",
  MOBILE: "mobile",
  ADD_SEATS_DIALOG: "add-seats-dialog",
  INVITE_TEAM_MEMBER_DIALOG: "invite-team-member-dialog",
  INVITE_COLLABORATOR_DIALOG: "invite-collaborator-dialog",
  VIA_BUTTON: "via-button",
  VIA_SHORTCUT: "via-shortcut",
  CHAT_TO_UPGRADE: "chat-to-upgrade",
};

export const STATE = {
  FAILED: "failed",
  // TODO: Only FAILED is consider as State in analytics.
  //  Migrate all remaining things to category or subcategory.
  GOOGLE_DRIVE_FEATURE: "google-drive-feature",
  DROPBOX_FEATURE: "dropbox-feature",
  ONEDRIVE_FEATURE: "onedrive-feature",
  REVIEW_STEP_QUANTITY: "review-step-quantity",
  FILE_QUANTITY: "file-quantity",
  PROJECT_QUANTITY: "project-quantity",
  COMPARE_REVIEWS_FEATURE: "compare-reviews-feature",
  PROJECT_TEMPLATE_QUANTITY: "project-template-quantity",
  REVIEW_LIVE_WEBSITES_FEATURE: "review-live-websites-feature",
  REVIEW_DECISION_REMINDER: "review-decision-reminder",
  SECTION_QUANTITY: "section-quantity",
  REVIEW_INTERACTIVE_HTML_FEATURE: "review-interactive-html-feature",
  INVITE_TEAM_MEMBER_DIALOG: "invite-team-member-dialog",
  INVITE_COLLABORATOR_DIALOG: "invite-collaborator-dialog",
};
