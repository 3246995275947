import MARKER_CLAZZES from "@feedback/constants/markerClazzes";
import VIEWER_DISPLAY_FORMATS from "@feedback/constants/viewerDisplayFormats";

import viewerVideoService from "../viewerVideoService";
import markerService from "./marker";
import videoMarkerService from "./videoMarker";

function fsMarkerAudioDataTimeFormatFilter(markerData) {
  if (markerData.time === undefined || markerData.time === null) {
    return null;
  }

  // use videoMarkerService
  return videoMarkerService.calculateStandardFormat(markerData.time);
}
export function fsMarkerVideoDataFormatFilter(markerData, format, reviewId) {
  const videoViewerService =
    viewerVideoService.getInstanceForReviewId(reviewId);
  let formatValue = null;

  if (videoMarkerService.isMarkerDataValid(markerData)) {
    const markerTime = markerData.time;

    switch (format) {
      case VIEWER_DISPLAY_FORMATS.VIDEO.STANDARD:
        formatValue = videoMarkerService.calculateStandardFormat(markerTime);
        break;
      case VIEWER_DISPLAY_FORMATS.VIDEO.TIMECODE:
        formatValue = videoMarkerService.calculateTimecodeFormat(
          markerTime,
          videoViewerService.getFrameRate()
        );
        break;
      case VIEWER_DISPLAY_FORMATS.VIDEO.FRAME:
        formatValue = videoMarkerService.calculateFrame(
          markerTime,
          videoViewerService.getFrameRate()
        );
        break;
    }
  }
  return formatValue;
}

function determineValue(marker, format, reviewId) {
  switch (marker.clazz) {
    case MARKER_CLAZZES.VIDEO.NAME: {
      return fsMarkerVideoDataFormatFilter(marker.data, format, reviewId);
    }
    case MARKER_CLAZZES.DOCUMENT.NAME: {
      return markerService.getMarkerDataByName(
        marker,
        MARKER_CLAZZES.DOCUMENT.DATA.PAGE
      );
    }
    case MARKER_CLAZZES.AUDIO.NAME: {
      return fsMarkerAudioDataTimeFormatFilter(marker.data);
    }
    // No default
  }
}

export const markerDataFormatString = function (marker, format, reviewId) {
  if (!marker) {
    return null;
  }

  const displayString = determineValue(marker, format, reviewId);
  if (displayString === null) {
    return "";
  }

  return displayString;
};
