import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { EmailOutlined } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";

function OtpField({ code, onChange, errorText, ...restProps }) {
  const { t } = useTranslation();

  const [validationErrorText, setValidationErrorText] = useState("");

  const handleInputChange = (event) => {
    const { value } = event.target;
    const regex = new RegExp(/^[0-9]*$/);

    if (regex.test(value)) {
      setValidationErrorText("");
      onChange(value);
    }
  };

  const handleOnBlur = () => {
    if (code.length !== 6 && errorText.length === 0) {
      setValidationErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCOMPLETE_CODE"));
    }
  };

  const hasError = validationErrorText.length > 0 || errorText.length > 0;

  return (
    <TextField
      error={hasError}
      type="text"
      fullWidth
      onChange={handleInputChange}
      onBlur={handleOnBlur}
      label={t("AUTHENTICATION.DIALOGS.OTP.INPUT")}
      inputProps={{
        "data-testid": "otp-input",
        inputMode: "numeric",
      }}
      helperText={errorText.length ? errorText : validationErrorText}
      value={code}
      autoFocus
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailOutlined
              sx={{ color: hasError ? "error.main" : "primary" }}
            />
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
}

OtpField.propTypes = {
  code: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired,
};

export default OtpField;
