import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import FeatureName from "./FeatureName";
import Features from "./Features";
import FeatureTooltip from "./FeatureTooltip";

function SecurityFeatures({ team }) {
  const { t } = useTranslation();

  return (
    <Features title={t("BILLING.FEATURE.SECURITY")}>
      <FeatureName
        name="BILLING.FEATURE.SINGLE_SIGN_ON.LABEL"
        isEnabled={team.subscriptionLimits.isSSOEnabled}
        tooltip={
          <FeatureTooltip
            description={t("BILLING.FEATURE.SINGLE_SIGN_ON.HELP_MESSAGE.PART1")}
            linkHref={t("URLS.FAQ_SSO")}
            linkText={t("BILLING.FEATURE.LEARN_MORE")}
            isEnabled={team.subscriptionLimits.isSSOEnabled}
            getInTouchUpgrade={t("BILLING.FEATURE.GET_IN_TOUCH_WITH_US")}
            upgradePlanMessage2={t(
              "BILLING.FEATURE.SINGLE_SIGN_ON.HELP_MESSAGE.PART2"
            )}
            analyticsCategory="SSO"
          />
        }
      />
    </Features>
  );
}

SecurityFeatures.propTypes = {
  team: teamProp.isRequired,
};

export default SecurityFeatures;
