import { useCallback, useState } from "react";

import { LoadingButton as Button } from "@mui/lab";
import { FormControl, Input, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";
import { instance as teamService } from "@supporting/services/team";
import clsx from "clsx";

import { Text, Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
}));

function RemoveSubscriptionCache() {
  const classes = useStyles();

  const [teamId, setTeamId] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      await teamService.removeTeamSubscriptionCache(teamId);
      setSuccessMessage(`Removed subscription cache for team: ${teamId}`);
      setTeamId("");
      setIsLoading(false);
    } catch (response) {
      setIsLoading(false);
      setErrorMessage(
        response.status === 404 ? "Team doesn't exist" : response.message
      );
    }
  }, [teamId]);

  const handleSubscriptionIdChange = (event) => {
    setTeamId(event.target.value);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Title>Remove Subscription Cache for Team</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Box className={classes.formContainer}>
              <Box
                display="flex"
                mt={2}
                maxWidth={400}
                alignItems="center"
                data-testid="form"
              >
                <FormControl classes={{ root: classes.form }}>
                  <InputLabel>Team ID</InputLabel>
                  <Input
                    value={teamId}
                    onChange={handleSubscriptionIdChange}
                    data-testid="subscription-id-form-input"
                  />
                </FormControl>
              </Box>

              <Box mt={2} maxWidth={400} width="100%">
                <Button
                  size="large"
                  color="primary"
                  loading={isLoading}
                  variant="contained"
                  onClick={handleClick}
                  fullWidth
                  data-testid="form-submit"
                >
                  {isLoading
                    ? "Removing cache..."
                    : "Remove Subscription Cache"}
                </Button>
              </Box>
              {errorMessage && (
                <Box my={2} ml={5} data-testid="form-error">
                  <Text color="error.main">{errorMessage}</Text>
                </Box>
              )}
              {successMessage && (
                <Box my={2} ml={1} data-testid="form-success">
                  <Text color="success.main">{successMessage}</Text>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default RemoveSubscriptionCache;
