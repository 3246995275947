import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Button, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import RestartTrialButton from "@supporting/components/RestartTrialButton/RestartTrialButton";
import RestartTrialPlanDialog from "@supporting/components/RestartTrialPlanDialog/RestartTrialPlanDialog";
import { instance as teamService } from "@supporting/services/team";
import classNames from "classnames";

import { Box, Text, Image } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

import CompareReviews from "@assets/img/images/compare-reviews.svg";
import CustomReviewDecisions from "@assets/img/images/custom-review-decisions.svg";
import InteractiveHtml from "@assets/img/images/interactive-html.gif";
import VerifyReviewerEmailSetting from "@assets/img/images/verify-reviewer-email.svg";

import UpgradeDialogContentDescription from "./UpgradeDialogContentDescription";
import UpgradeDialogContentSubDescription from "./UpgradeDialogContentSubDescription";

const useStyles = makeStyles((theme) => ({
  featureImageWrapperWithShadow: {
    boxShadow: theme.shadow["box-shadow-z2"],
    margin: "30px auto",
    width: "80%",
    paddingTop: 10,
  },
  featureImageWrapper: {
    margin: "30px auto",
  },
  featureImage: {
    display: "block",
    height: "auto",
    width: "100%",
  },
  featuredVideo: {
    padding: "15px 0",
    maxWidth: "100%",
    [theme.breakpoints.down(595)]: {
      maxWidth: "110%",
      marginLeft: -20,
    },
    [theme.breakpoints.down(490)]: {
      maxWidth: "113%",
      marginLeft: -20,
    },
    [theme.breakpoints.down(400)]: {
      maxWidth: "118%",
      marginLeft: -20,
    },
  },
}));

const getFeatureImage = (variant, classes) => {
  switch (variant) {
    case "compareVersions":
      return CompareReviews;
    case "verifyReviewerEmailSetting":
      return VerifyReviewerEmailSetting;
    case "customReviewDecisions":
      return CustomReviewDecisions;
    case "addSection":
      return function SectionVideo() {
        return (
          <video //eslint-disable-line jsx-a11y/media-has-caption
            src="../../assets/img/images/Sections.mp4"
            autoPlay
            loop
            width={500}
            className={classes.featuredVideo}
            data-testid="upgrade-dialog-featured-video"
          />
        );
      };
    case "interactiveHtml":
      return function InteractiveHtmlFeatureImage() {
        return (
          <Image
            className={classes.featureImage}
            src={InteractiveHtml}
            alt="review-interactive-html"
          />
        );
      };
  }
};

function UpgradeDialogContent({
  isAdminView,
  onCancel,
  variant,
  openBillingDialog,
  teamId,
}) {
  const [currentTeam, setCurrentTeam] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTeam = async () => {
      const data = await teamService.fetchTeamByTeamId(teamId);
      setCurrentTeam(data);
    };
    fetchTeam();
  }, [teamId]);

  const handleTryProfessional = async () => {
    onCancel();
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.RESTART_TRIAL_DIALOG.TRY_PROFESSIONAL,
      { teamId: currentTeam?._id, teamName: currentTeam?.name }
    );
    if (
      await teamService.triggerTrialSubscription(
        teamId,
        t("RESTART_TRIAL.INTERCOM")
      )
    ) {
      NiceModal.show(RestartTrialPlanDialog, { teamId });
    }
  };

  const classes = useStyles();
  const onUpgradeButtonClick = useCallback(() => {
    onCancel();
    openBillingDialog();
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.RESTART_TRIAL_DIALOG.UPGRADE,
      { teamId: currentTeam?._id, teamName: currentTeam?.name }
    );
  }, [onCancel, openBillingDialog, currentTeam]);

  const FeatureImage = getFeatureImage(variant, classes);

  return (
    <>
      <UpgradeDialogContentDescription
        isAdminView={isAdminView}
        variant={variant}
      />
      <UpgradeDialogContentSubDescription
        isAdminView={isAdminView}
        variant={variant}
      />
      <Box
        className={classNames({
          [classes.featureImageWrapperWithShadow]:
            variant === "verifyReviewerEmailSetting",
          [classes.featureImageWrapper]:
            variant === "compareVersions" ||
            variant === "interactiveHtml" ||
            variant === "customReviewDecisions",
        })}
      >
        <FeatureImage className={classes.featureImage} alt="feature-image" />
      </Box>
      {isAdminView && (
        <Box>
          {currentTeam?.subscription?.basePrice.name === "Free" && (
            <RestartTrialButton
              testId="restart-trial-section-upgrade-button"
              onClick={handleTryProfessional}
            />
          )}
          <Button
            data-testid="cta-upgrade-button"
            onClick={onUpgradeButtonClick}
            color="primary"
            variant="contained"
            size="large"
          >
            {t("RESTART_TRIAL.MODAL_PRIMARY_CTA")}
          </Button>
        </Box>
      )}
      {isAdminView && variant === "compareVersions" && (
        <Box mt={2}>
          <Text variant="body2">
            <Trans
              components={{
                anchor: (
                  <Link
                    className="launch-intercom-chat"
                    href={t("URLS.HELLO_MAIL")}
                    target="_blank"
                  />
                ),
              }}
            >
              {` ${t("COMPARE_VERSIONS.UPGRADE_CTA.REQUEST_TRIAL")}`}
            </Trans>
          </Text>
        </Box>
      )}
    </>
  );
}

UpgradeDialogContent.propTypes = {
  isAdminView: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    "compareVersions",
    "verifyReviewerEmailSetting",
    "addSection",
    "interactiveHtml",
    "customReviewDecisions",
  ]).isRequired,
  openBillingDialog: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default UpgradeDialogContent;
