/**
 * Map Right helper function
 *
 * @description This function takes a list and a function and applies
 * the function to each element in the list starting from the end.
 * @param {Array} array - [Required] The array to map over.
 * @param {Function} callback - [Required] The mapper callback function
 * to run on each of the objects in the list.
 * @returns {Array} Returns the mapped array.
 */
export default function mapRight(array, callback) {
  const result = [];
  for (let index = array.length - 1; index >= 0; index--) {
    result.push(callback(array[index], index));
  }

  return result;
}
