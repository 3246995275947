import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import noop from "lodash/noop";

import { EditableLabel } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: FSTGTypography.fontSize_1_4,
    fontWeight: theme.typography.fontWeight.semiBold,
    letterSpacing: "0.1px",
  },
}));

function SectionName({
  text,
  onChange,
  className,
  isDisabled,
  isEditing,
  onSave,
  tooltipContainerDisabledClassName,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <EditableLabel
      text={text}
      onBeforeSave={noop}
      onAfterSave={onSave}
      onChangeEditMode={onChange}
      isEditing={isEditing}
      disabled={isDisabled}
      classes={{
        input: classes.input,
        text: classes.input,
      }}
      tooltipProps={{
        title: isDisabled ? text : t("PROJECT.SECTION.MENU.RENAME"),
        text: !isDisabled ? text : "",
      }}
      placeholder={t("PROJECT.SECTION.PLACEHOLDER")}
      className={className}
      tooltipContainerDisabledClassName={tooltipContainerDisabledClassName}
      textVariant="h3"
    />
  );
}

SectionName.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  tooltipContainerDisabledClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isEditing: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

SectionName.defaultProps = {
  text: "",
  className: "",
  tooltipContainerDisabledClassName: "",
  isDisabled: false,
};

export default SectionName;
