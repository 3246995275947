let count = 0;

const registerUnloadWarning = function () {
  //Cross Browser Smashing
  try {
    history.navigationMode = "compatible";
    // eslint-disable-next-line no-empty
  } catch (error) {}

  window.onbeforeunload = function (unloadEvent) {
    unloadEvent = unloadEvent || window.event;
    // For IE and Firefox
    if (unloadEvent) {
      unloadEvent.returnValue = "";
    }
    // For Safari, Chrome
    return "";
  };
  count++;
};

const unregisterUnloadWarning = function () {
  if (count > 0) {
    count--;
  }

  if (count === 0) {
    window.onbeforeunload = null;
  }
};

const resetUnloadWarningToNull = function () {
  count = 0;
  window.onbeforeunload = null;
};

export default {
  registerUnloadWarning,
  unregisterUnloadWarning,
  resetUnloadWarningToNull,
};
