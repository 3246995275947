import { CloudWatchLogs } from "@aws-sdk/client-cloudwatch-logs";
import throttle from "lodash/throttle";

import fstgId from "@shared/helpers/fstgId";
import backend from "@shared/services/backendClient";

export let instance;

export async function initialize() {
  const { awsConfig, logGroupName } = window.fs.config.cloudWatch;
  const cloudWatchClient = new CloudWatchLogs({
    region: awsConfig.region,
    credentials: {
      accessKeyId: awsConfig.accessKeyId,
      secretAccessKey: awsConfig.secretAccessKey,
    },
  });

  const logStreamName = fstgId.generate();
  let buffer = [];
  let bufferSize = 0;

  await cloudWatchClient.createLogStream({ logGroupName, logStreamName });

  async function send(logEvents) {
    try {
      await cloudWatchClient.putLogEvents({
        // eslint-disable-next-line id-length
        logEvents: logEvents.sort((a, b) => a.timestamp - b.timestamp),
        logGroupName,
        logStreamName,
      });
    } catch (error) {
      console.warn("failed to send logs to cloudwatch", error);
    }
  }

  const scheduleSend = throttle(
    () => {
      const logs = buffer;
      buffer = [];
      bufferSize = 0;
      send(logs);
    },
    5000,
    {
      leading: false,
      trailing: true,
    }
  );

  async function stringifyError(error) {
    const stringifiedError = {
      message: error.message,
    };
    if (error.stack) {
      stringifiedError.stack = error.stack;
      try {
        stringifiedError.stack = await backend.post(
          "/frontend/map-stack-trace",
          error.stack
        );
      } catch {
        // eslint-disable-line no-empty
      }
    }
    return stringifiedError;
  }

  async function log(level, tag, message, meta) {
    const log = {
      timestamp: new Date().getTime(),
      message: {
        level,
        tag,
        message,
        meta,
      },
    };
    if (meta?.error instanceof Error) {
      meta.error = await stringifyError(meta.error);
    }
    log.message = JSON.stringify(log.message);
    buffer.push(log);
    bufferSize += log.message.length + 38; // 38 is the size of the timestamp
    if (buffer.length < 1000 && bufferSize < 1000000) {
      scheduleSend();
    } else {
      scheduleSend.flush();
    }
  }

  instance = { log };
}
