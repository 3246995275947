import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { LoadingButton as Button } from "@mui/lab";
import { FormControl, Input, InputLabel } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme, makeStyles } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";
import userService from "@supporting/services/userService";
import clsx from "clsx";

import { Text, Box } from "@shared/UIKit";

import { EMAIL_REGEX } from "@shared/constants/validation";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  form: {
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
}));

function RepairPermissions() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      await userService.repairPermissions(email);

      setSuccessMessage(`Permissions repaired for ${email}`);
      setEmail("");
      setIsValid(false);
      setIsLoading(false);
    } catch (response) {
      setIsLoading(false);
      if (response.status === 404) {
        setErrorMessage("User doesn't exist");
      } else {
        setErrorMessage(response.data.message);
      }
    }
  }, [email, setIsLoading]);

  const handleEmailChange = (event) => {
    const { value } = event.currentTarget;
    setEmail(event.target.value);
    setSuccessMessage("");

    if (!EMAIL_REGEX.test(value)) {
      setFormErrorMessage("Not a valid email");
      return;
    }

    setFormErrorMessage("");
    setIsValid(true);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Title>Repair Permissions</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Box className={classes.formContainer}>
              <Box
                display="flex"
                mt={2}
                maxWidth={400}
                alignItems="center"
                data-testid="form"
              >
                <Box
                  mt={2.5}
                  ml={0.5}
                  mr={2}
                  lineHeight={FSTGTypography.lineHeight_1_0}
                >
                  <EmailOutlined />
                </Box>
                <FormControl
                  variant="standard"
                  classes={{ root: classes.form }}
                >
                  <InputLabel htmlFor="email">
                    {t("FORM.EMAIL.WORK_LABEL")}
                  </InputLabel>
                  <Input
                    id="email"
                    value={email}
                    onChange={handleEmailChange}
                    data-testid="email-form-input"
                  />
                </FormControl>
              </Box>

              {formErrorMessage && (
                <Box ml={5} data-testid="form-error">
                  <Text color={theme.color.red[500]}>{formErrorMessage}</Text>
                </Box>
              )}

              <Box mt={2} maxWidth={400} width="100%">
                <Button
                  loading={isLoading}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClick}
                  disabled={!isValid}
                  data-testid="form-submit"
                >
                  {isLoading ? "Repairing..." : "Repair Permissions"}
                </Button>
              </Box>
              {errorMessage && (
                <Box my={2} ml={5} data-testid="form-error">
                  <Text color={theme.color.red[500]}>{errorMessage}</Text>
                </Box>
              )}
              {successMessage && (
                <Box my={2} ml={1} data-testid="form-success">
                  <Text color={theme.color.green[500]}>{successMessage}</Text>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default RepairPermissions;
