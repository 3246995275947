import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Chip } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const OptionShape = PropTypes.shape({
  testId: PropTypes.string.isRequired,
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
});

function AutomationMenuList({ options, onSelect, menuId, label }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const isOpen = Boolean(anchorEl);
  const handleOpen = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOnSelected = useCallback(
    (itemSelected) => () => {
      onSelect(itemSelected);
      closeMenu();
    },
    [closeMenu, onSelect]
  );

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<AddCircleOutlineIcon />}
        color="success"
        variant="outlined"
        data-testid={`add-${menuId}-button`}
        disabled={options.length === 0}
      >
        {t(label)}
      </Button>
      {options.length ? (
        <Menu
          anchorEl={anchorEl}
          open={isOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={closeMenu}
          data-testid={`add-${menuId}-menu`}
          sx={{ "& .MuiPaper-root": { minWidth: "375px" } }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.key}
              onClick={handleOnSelected(option.item)}
              data-testid={option.testId}
              sx={{ fontSize: "textSm.fontSize" }}
            >
              {t(option.name)}
              {option.item.isEarlyAccess && (
                <Chip
                  color="accent"
                  size="small"
                  sx={{ ml: 2 }}
                  label={t("SETTINGS.TABS.EARLY_ACCESS")}
                />
              )}
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </>
  );
}

AutomationMenuList.propTypes = {
  menuId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(OptionShape),
};

AutomationMenuList.defaultProps = {
  options: [],
};

export default AutomationMenuList;
