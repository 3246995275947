/**
 * Validation Utility functions
 *
 * @description This function is used for checking if the Name String is empty
 * If its empty, it will return " ", if its not, it will trim whitespaces
 * @param {string} name - [Required] name string.
 * @returns {string} - Returns the string " " otherwise returns undefined.
 */
const checkName = (name) => {
  if (name.length === 0 || !name.trim()) {
    return " ";
  }
  return undefined;
};

export default { checkName };
