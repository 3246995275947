import pagesEnum from "@shared/constants/pages";
import { APP_ROUTES } from "@shared/constants/routes";
const pagesDictionary = {
  TEAM_SPECIFIC: pagesEnum.TEAM,
  PROJECT_ID: pagesEnum.PROJECT_DASHBOARD,
  PROFILE: pagesEnum.PROFILE,
  WORKSPACE: pagesEnum.WORKSPACE,
  STEP_LOGIN: pagesEnum.STEP_LOGIN,
};

// TODO: Refactor once routes completely migrated to react
const getPageName = (url) => {
  if (pagesDictionary[url]) {
    return pagesDictionary[url];
  }
  if (url.startsWith("/teams")) {
    return pagesEnum.TEAM;
  }
  if (url.startsWith("/projects")) {
    return pagesEnum.PROJECT_DASHBOARD;
  }
  if (url.startsWith("/profile")) {
    return pagesEnum.PROFILE;
  }
  if (url.startsWith("/workspace")) {
    return pagesEnum.WORKSPACE;
  }
};

const getCurrentPage = (pageName) => {
  const page = getPageName(pageName);
  const analyticsName = APP_ROUTES[pageName]?.analyticsName;
  return page ? page : analyticsName || pageName;
};
export default getCurrentPage;
