export default Object.freeze({
  ACCESS_DENIED: "ACCESS_DENIED",
  BILLING: "BILLING",
  BILLING_ACTION: "BILLING_ACTION",
  DASHBOARD: "DASHBOARD",
  PROJECT_ID: "PROJECT_ID",
  ISSUE_LINK: "ISSUE_LINK",
  LOGIN: "LOGIN",
  EMBED: "EMBED",
  LOGOUT: "LOGOUT",
  LOGIN_REVIEWER: "LOGIN_REVIEWER",
  PASSWORD_FORGOT: "PASSWORD_FORGOT",
  PASSWORD_RESET: "PASSWORD_RESET",
  PASSWORD_RESET_EXPIRED: "PASSWORD_RESET_EXPIRED",
  ENFORCE_PASSWORD_CHANGE: "ENFORCE_PASSWORD_CHANGE",
  EXTERNAL_LOGIN_CALLBACK: "EXTERNAL_LOGIN_CALLBACK",
  PLUGIN_GOOGLE_LOGIN: "PLUGIN_GOOGLE_LOGIN",
  PLUGIN_GOOGLE_LOGIN_SUCCESS: "PLUGIN_GOOGLE_LOGIN_SUCCESS",
  MONDAY_APP_AUTHORIZATION_START: "MONDAY_APP_AUTHORIZATION_START",
  INTEGRATIONS_AUTHORIZATION_FAILED: "INTEGRATIONS_AUTHORIZATION_FAILED",
  MONDAY_APP_EMPTY_REVIEWS: "MONDAY_APP_EMPTY_REVIEWS",
  MONDAY_APP_PROJECTS_LIST: "MONDAY_APP_PROJECTS_LIST",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  ROOT: "ROOT",
  SIGNUP: "SIGNUP",
  SIGNUP_VERIFY_OTP: "SIGNUP_VERIFY_OTP",
  SIGNUP_VALIDATE: "SIGNUP_VALIDATE",
  SIGNUP_EXTERNAL: "SIGNUP_EXTERNAL",
  SIGNUP_EXTERNAL_CALLBACK: "SIGNUP_EXTERNAL_CALLBACK",
  BULK_DOWNLOAD: "BULK_DOWNLOAD",
  REVIEW_LINK: "REVIEW_LINK",
  REVIEW_LINK_COMMENT: "REVIEW_LINK_COMMENT",
  STEP_LINK: "STEP_LINK",
  STEP_SHARE: "STEP_SHARE",
  EMBED_STEP_SHARE: "EMBED_STEP_SHARE",
  STEP_SHARE_WORKSPACE_FILE_VERSION_ISSUE:
    "STEP_SHARE_WORKSPACE_FILE_VERSION_ISSUE",
  EMBED_STEP_SHARE_WORKSPACE_FILE_VERSION_ISSUE:
    "EMBED_STEP_SHARE_WORKSPACE_FILE_VERSION_ISSUE",
  EMBED_STEP_SHARE_WORKSPACE_FILE_VERSION:
    "EMBED_STEP_SHARE_WORKSPACE_FILE_VERSION",
  STEP_SHARE_WORKSPACE_FILE_VERSION: "STEP_SHARE_WORKSPACE_FILE_VERSION",
  EMBED_STEP_SHARE_WORKSPACE_FILE: "EMBED_STEP_SHARE_WORKSPACE_FILE",
  STEP_SHARE_WORKSPACE_FILE: "STEP_SHARE_WORKSPACE_FILE",
  STEP_SHARE_AUTHORIZE: "STEP_SHARE_AUTHORIZE",
  LINK_INVALID: "LINK_INVALID",
  CONNECTION_INTERRUPTED: "CONNECTION_INTERRUPTED",

  OLD_SHARE_LINK: "OLD_SHARE_LINK",
  SHARELINK_WORKSPACE_FILE_VERSION_ISSUE:
    "SHARELINK_WORKSPACE_FILE_VERSION_ISSUE",
  SHARELINK_WORKSPACE_FILE_VERSION: "SHARELINK_WORKSPACE_FILE_VERSION",
  SHARELINK_WORKSPACE_FILE: "SHARELINK_WORKSPACE_FILE",

  PROFILE: "PROFILE",
  PROFILE_HOME: "PROFILE_HOME",
  TEAMS: "TEAMS",
  TEAMS_GENERAL: "TEAMS_GENERAL",
  TEAM_SPECIFIC: "TEAM_SPECIFIC",
  WORKSPACE: "WORKSPACE",
  WORKSPACE_ALIAS: "WORKSPACE_ALIAS",
  AUTH_CALLBACK: "AUTH_CALLBACK",
  IMPERSONATE: "IMPERSONATE",
  REPAIR_PERMISSIONS: "REPAIR_PERMISSIONS",
  ADMIN: "ADMIN",
  WORKSPACE_AUTH_CALLBACK: "WORKSPACE_AUTH_CALLBACK",
  BATCH_REVIEW: "BATCH_REVIEW",
  PENDING_REVIEWS: "PENDING_REVIEWS",
  TEMPLATE_ID: "TEMPLATE_ID",
  INSIGHTS: "INSIGHTS",
  INSIGHTS_GENERAL: "INSIGHTS_GENERAL",
  INSIGHTS_SPECIFIC: "INSIGHTS_SPECIFIC",
  ONBOARDING_WIZARD_CREDENTIALS: "ONBOARDING_WIZARD_CREDENTIALS",
  ONBOARDING_WIZARD_COMPANY_SIZE: "ONBOARDING_WIZARD_COMPANY_SIZE",
  ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS:
    "ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS",
  TEAM_JOIN_LINK: "TEAM_JOIN_LINK",
  ONBOARDING_WIZARD_JOIN_TEAMS: "ONBOARDING_WIZARD_JOIN_TEAMS",
  ONBOARDING_WIZARD_UPLOAD_FILES: "ONBOARDING_WIZARD_UPLOAD_FILES",
  COMPARE_REVIEWS: "COMPARE_REVIEWS",
  INTEGRATIONS: "INTEGRATIONS",
  INTEGRATIONS_DETAILS: "INTEGRATIONS_DETAILS",
  INTEGRATIONS_AUTHORIZATION_CALLBACK: "INTEGRATIONS_AUTHORIZATION_CALLBACK",
});
