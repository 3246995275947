import authenticationService from "@supporting/services/authentication";

import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";
import WEBSOCKET_EVENT_NAMES from "@shared/services/websocket/events";

let externalProjects;

const setExternalProjects = (projects) => {
  externalProjects = projects;
};

function initialize() {
  websocket.addListener(WEBSOCKET_EVENT_NAMES.STEP.ACCESS.GAINED, removeCache);
  websocket.addListener(WEBSOCKET_EVENT_NAMES.STEP.ACCESS.LOST, removeCache);
  subscribeSectionWebsocketEvent(
    WEBSOCKET_EVENT_NAMES.PROJECT.SECTION.CREATED,
    EVENT_NAMES.PROJECT.SECTION.CREATED
  );
  subscribeSectionWebsocketEvent(
    WEBSOCKET_EVENT_NAMES.PROJECT.SECTION.REMOVED,
    EVENT_NAMES.PROJECT.SECTION.REMOVED
  );
  subscribeSectionWebsocketEvent(
    WEBSOCKET_EVENT_NAMES.PROJECT.SECTION.RENAMED,
    EVENT_NAMES.PROJECT.SECTION.RENAMED
  );
  subscribeSectionWebsocketEvent(
    WEBSOCKET_EVENT_NAMES.PROJECT.SECTION.MOVED,
    EVENT_NAMES.PROJECT.SECTION.MOVED
  );
  subscribeSectionWebsocketEvent(
    WEBSOCKET_EVENT_NAMES.PROJECT.SECTION.COLLAPSED,
    EVENT_NAMES.PROJECT.SECTION.COLLAPSED,
    ({ isCollapsed }) => ({ isCollapsed })
  );
  subscribeSectionWebsocketEvent(
    WEBSOCKET_EVENT_NAMES.PROJECT.SECTIONS.COLLAPSED,
    EVENT_NAMES.PROJECT.SECTIONS.COLLAPSED,
    ({ isCollapsed }) => ({ isCollapsed })
  );
  eventService.addListener(EVENT_NAMES.PROJECT.UPDATED, onProjectUpdated);
  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, removeCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, removeCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, removeCache);
  eventService.addListener(
    EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
    (event) => {
      const { collapsed } = event.eventData;
      externalProjects = externalProjects.map((team) => ({
        ...team,
        folders: team.folders.map((folder) => ({
          ...folder,
          isCollapsed: collapsed,
        })),
      }));
    }
  );

  websocket.addListener(
    websocket.EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
    ({ collapsed }) => {
      externalProjects = externalProjects.map((team) => ({
        ...team,
        folders: team.folders.map((folder) => ({
          ...folder,
          isCollapsed: collapsed,
        })),
      }));
    }
  );
}

function removeCache() {
  externalProjects = null;
}

function subscribeSectionWebsocketEvent(websocketEvent, eventName, callback) {
  websocket.addListener(websocketEvent, (event) =>
    onSectionEventHandler(event, eventName, callback)
  );
}

async function fetchExternalProjects() {
  if (externalProjects) {
    return externalProjects;
  }

  if (authenticationService.fetchSession().type === "Guest") {
    return [];
  }

  return await refetchExternalProjects();
}

function fetchExternalProjectByIdFromCache(projectId) {
  return (externalProjects || [])
    .flatMap((team) => team.folders)
    .flatMap((folder) => folder.projects)
    .find((project) => project.id === projectId);
}

async function refetchExternalProjects() {
  externalProjects = await fetchExternalProjectsOnce();
  emitReviewerProjectsUpdatedEvent(externalProjects);
  return externalProjects;
}

let fetchExternalProjectsPromise;

async function fetchExternalProjectsOnce() {
  if (fetchExternalProjectsPromise) {
    return fetchExternalProjectsPromise;
  }
  try {
    fetchExternalProjectsPromise = backend.get("/external-projects");
    return await fetchExternalProjectsPromise;
  } finally {
    fetchExternalProjectsPromise = null;
  }
}

function onProjectUpdated({ eventData: { project } }) {
  const team = externalProjects?.find((team) => team.teamId === project.teamId);

  if (!team) {
    return;
  }

  const externalProject = team.folders
    .flatMap((folder) => folder.projects)
    .find((teamProject) => teamProject.id === project.id);

  if (!externalProject) {
    return;
  }

  externalProjects.forEach((team) => {
    if (team.teamId === project.teamId) {
      team.folders = team.folders.map((folder) => {
        if (folder.id === project.folderId) {
          folder.projects = folder.projects.map(updateProjectList(project));
        }
        return folder;
      });
    }
  });
  emitReviewerProjectsUpdatedEvent(externalProjects);
}

function updateProjectList(project) {
  return (teamProject) =>
    teamProject.id === project.id ? project : teamProject;
}

async function updateFolderCollapsed(folderId, isCollapsed) {
  await backend.put(`/folders/${folderId}/isCollapsed`, {
    isCollapsed: !isCollapsed,
    isExternalTeam: true,
  });
}

function emitReviewerProjectsUpdatedEvent(externalProjects) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.REVIEWER_PROJECTS.UPDATED,
    eventData: { externalProjects },
  });
}

async function onSectionEventHandler(websocketEvent, eventName, callback) {
  if (hasProject(websocketEvent.projectId)) {
    await refetchExternalProjects();
    const project = fetchExternalProjectByIdFromCache(websocketEvent.projectId);
    eventService.emitEvent({
      eventName,
      eventData: {
        project,
        sectionId: websocketEvent.sectionId,
        ...(callback ? callback(websocketEvent) : {}),
      },
    });
  }
}

function hasProject(projectId) {
  return (externalProjects || [])
    .flatMap((team) => team.folders)
    .flatMap((folder) => folder.projects)
    .some((project) => project.id === projectId);
}

export default {
  initialize,
  setExternalProjects,
  fetchExternalProjects,
  fetchExternalProjectByIdFromCache,
  updateFolderCollapsed,
};
