import projectService from "@workflow/services/projectService";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

function emitProjectUpdateEvent(project) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.CACHE.UPDATE,
    eventData: {
      project,
    },
  });
}

async function addSection(projectId, sectionName) {
  analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.SECTION);
  const project = await backend.post(`/projects/${projectId}/sections`, {
    name: sectionName,
  });
  emitProjectUpdateEvent(project);
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTION.CREATED,
    eventData: {
      project,
      sectionId: project.sections[project.sections.length - 1].id,
    },
  });
}

async function renameProjectSection(projectId, sectionId, name) {
  analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.SECTION);
  const project = await backend.put(
    `/projects/${projectId}/sections/${sectionId}/name`,
    { name }
  );
  emitProjectUpdateEvent(project);
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTION.RENAMED,
    eventData: {
      project,
      sectionId,
    },
  });
}

async function moveProjectSection(projectId, sectionId, position) {
  analytics.track(analytics.ACTION.REORDERED, analytics.CATEGORY.SECTION);
  const project = await backend.put(
    `/projects/${projectId}/sections/${sectionId}/position`,
    {
      position,
    }
  );

  emitProjectUpdateEvent(project);
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTION.MOVED,
    eventData: {
      project,
      sectionId,
    },
  });
}

async function removeProjectSection(projectId, sectionId) {
  analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.SECTION);
  await backend.delete(`/projects/${projectId}/sections/${sectionId}`);
  const project = await projectService.fetchProjectById(projectId, true);
  emitProjectUpdateEvent(project);
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTION.REMOVED,
    eventData: {
      project,
      sectionId,
    },
  });
}

async function collapseAllSections(projectId, isCollapsed) {
  const project = await backend.put(
    `/projects/${projectId}/sections/is-all-collapsed`,
    {
      isCollapsed,
    }
  );
  emitProjectUpdateEvent(project);
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTIONS.COLLAPSED,
    eventData: {
      project,
      isCollapsed,
    },
  });
}

async function collapseSection(projectId, sectionId, isCollapsed) {
  const project = await backend.put(
    `/projects/${projectId}/sections/${sectionId}/is-collapsed`,
    {
      isCollapsed,
    }
  );
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTION.COLLAPSED,
    eventData: {
      project,
      sectionId,
      isCollapsed,
    },
  });
}

export default {
  addSection,
  renameProjectSection,
  moveProjectSection,
  removeProjectSection,
  collapseSection,
  collapseAllSections,
};
