import { ToastContainer as ToastifyContainer } from "react-toastify";

import { makeStyles } from "@mui/styles";
import { TOAST_CONFIG } from "@supporting/services/toast/constants";

import { Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles((theme) => ({
  toastContainer: {
    "& .Toastify__toast-container": {
      "&--top-left": {
        top: "1em",
        left: "1em",
      },

      "&--top-center": {
        top: "1em",
        left: "50%",
        transform: "translateX(-50%)",
      },

      "&--top-right": {
        top: 124,
      },

      "&--bottom-left": {
        left: "1em",
        bottom: "1em",
      },

      "&--bottom-center": {
        left: "50%",
        bottom: "1em",
        transform: "translateX(-50%)",
      },

      "&--bottom-right": {
        right: -4,
        bottom: "1em",
      },

      "--toastify-font-family": theme.typography.fontFamily,

      "& .Toastify__toast": {
        width: 300,
        opacity: 1,
        marginBottom: 6,
        borderLeftWidth: 5,
        position: "relative",
        borderLeftStyle: "solid",
        padding: "15px 15px 15px 20px",
        backgroundColor: theme.color.white,
        boxShadow: theme.shadow["box-shadow-z3"],

        "&:hover": {
          cursor: "auto",
          boxShadow: theme.shadow["box-shadow-z3"],
        },

        "&:focus": {
          outline: "none",
        },

        "&--info": {
          borderColor: theme.color.blue[300],

          "& .toast-title": {
            color: theme.color.blue[300],
          },
        },

        "&--success": {
          backgroundImage: "none !important",
          borderColor: theme.color.green[500],

          "& .toast-title": {
            color: theme.color.green[500],
          },
        },

        "&--warning": {
          borderColor: theme.color.orange[600],

          "& .toast-title": {
            color: theme.color.orange[600],
          },
        },

        "&--error": {
          borderColor: theme.color.red[600],

          "& .toast-title": {
            color: theme.color.red[600],
          },
        },

        "&-icon": {
          display: "none",
        },

        "&-body": {
          padding: 0,
        },

        "&--large": {
          right: 50,
          width: 350,

          "& .toast-title": {
            fontSize: FSTGTypography.fontSize_2_0,
          },

          "& .toast-message": {
            fontSize: FSTGTypography.fontSize_1_7,
          },
        },

        "& .toast-title": {
          fontSize: FSTGTypography.fontSize_1_7,
          lineHeight: FSTGTypography.lineHeight_1_3,
          fontWeight: theme.typography.fontWeight.semiBold,
        },

        "& .toast-message": {
          marginTop: 5,
          color: theme.color.gray[500],
          fontSize: FSTGTypography.fontSize_1_3,
          lineHeight: FSTGTypography.lineHeight_1_3,
        },

        "@media (max-width: 599px)": {
          width: "100%",
        },
      },
    },
    "& .Toastify__close-button": {
      top: 5,
      width: 50,
      right: -8,
      height: 50,
      opacity: 1,
      position: "absolute",
      color: theme.color.gray[300],

      "&:hover": {
        color: theme.color.gray[400],
      },

      "&:focus": {
        outline: "none",
      },
    },
  },
}));

function ToastContainer() {
  const classes = useStyles();

  return (
    <Box className={classes.toastContainer}>
      <ToastifyContainer
        position={TOAST_CONFIG.positionClass}
        autoClose={TOAST_CONFIG.timeOut}
        hideProgressBar={TOAST_CONFIG.hideProgressBar}
        closeOnClick={TOAST_CONFIG.tapToDismiss}
        newestOnTop={TOAST_CONFIG.newestOnTop}
        pauseOnFocusLoss={TOAST_CONFIG.pauseOnFocusLoss}
        pauseOnHover={TOAST_CONFIG.pauseOnHover}
        draggable={TOAST_CONFIG.draggable}
        theme={TOAST_CONFIG.theme}
        limit={4}
      />
    </Box>
  );
}

export default ToastContainer;
