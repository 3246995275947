import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import projectService from "@workflow/services/projectService";
import projectSwitcherService from "@workflow/services/projectSwitcherService";
import projectTemplateService from "@workflow/services/projectTemplateService";
import orderBy from "lodash/orderBy";

import { Dialog } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import CreateProjectForm from "./CreateProjectForm/CreateProjectForm";

function CreateProjectDialog({ cancel, selectedFolder }) {
  const { t } = useTranslation();
  const [createProjectState, setCreateProjectState] = useState({
    canCreateProject: false,
    templates: [],
    projectTemplate: null,
    hasTemplateReviewers: false,
    folders: [],
    selectedFolder: null,
  });
  const team = useSelectedTeam();
  const { goTo } = useNavigation();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const user = useActiveUser();

  const sortTemplates = (templates) => {
    if (user.settings.selectedProjectSortingOption === "NEWEST") {
      return orderBy(
        templates,
        ({ createdTime }) => new Date(createdTime),
        "desc"
      );
    }
    return templates.sort((temp1, temp2) =>
      temp1.name.localeCompare(temp2.name)
    );
  };

  useEffect(() => {
    const createProject = async () => {
      try {
        const [folders, templates] = await Promise.all([
          projectService.fetchProjectsByTeamId(team._id),
          projectTemplateService.fetchProjectTemplatesByTeamId(team._id),
        ]);

        const foldersSortedByCreatedTime = orderBy(
          folders,
          (folder) => new Date(folder.createdTime),
          "desc"
        );

        setCreateProjectState((prevState) => ({
          ...prevState,
          canCreateProject: true,
          folders: foldersSortedByCreatedTime,
          templates: sortTemplates(templates),
          selectedFolder:
            foldersSortedByCreatedTime.length === 1
              ? foldersSortedByCreatedTime[0]
              : selectedFolder,
          maxProjectTemplateNumber:
            team.subscriptionLimits.maxProjectTemplateNumber,
          canManageBilling: team.permissions.canManageBilling,
        }));
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    };
    createProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveProject = async (projectFormData) => {
    try {
      setIsSubmitted(true);
      const folderResolved = projectFormData.folder.id
        ? projectFormData.folder
        : await projectService.addFolder({
            teamId: team._id,
            name: projectFormData.folder.name,
          });
      const projectPayload = {
        name: projectFormData.projectName,
        teamId: team._id,
        folderId: folderResolved.id,
        projectTemplateId: projectFormData.template,
      };
      const project = await projectService.addProject(projectPayload);
      analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.PROJECT, {
        fromTemplate: Boolean(projectPayload.projectTemplateId),
        teamId: team._id,
        teamName: team.name,
        projectId: project.id,
      });
      projectSwitcherService.setSelectedProject(project);
      goTo("PROJECT_ID", { params: { projectId: project.id } });
      cancel();
    } catch (error) {
      errorHandlerService.handleError(error);
    } finally {
      setIsSubmitted(false);
    }
  };

  const handleTemplateChange = (templateId) => {
    const template = createProjectState.templates.find(
      (temp) => temp.id === templateId
    );
    const isReviewerExist = template?.steps?.some(
      (step) => step.reviewers.length > 0
    );
    setCreateProjectState((prevState) => ({
      ...prevState,
      hasTemplateReviewers: isReviewerExist,
    }));
  };

  const actions = (
    <Button
      type="submit"
      form="create-project-form"
      color="primary"
      variant="contained"
      data-testid="create-project-button"
      disabled={isSubmitted}
    >
      {t("PROJECT.NEWPROJECT.CREATE")}
    </Button>
  );
  return (
    <Dialog
      cancel={cancel}
      title={t("PROJECT.NEWPROJECT.HEADLINE")}
      open={createProjectState.canCreateProject}
      actions={actions}
      maxWidth="sm"
      hideBackdrop={false}
      disableBackdropClick
      data-testid="create-project-dialog"
    >
      {createProjectState.folders && createProjectState.templates && (
        <CreateProjectForm
          folders={createProjectState.folders}
          templates={createProjectState.templates}
          onTemplateChange={handleTemplateChange}
          defaultFolder={createProjectState.selectedFolder}
          saveProject={saveProject}
          onFormSubmitted={setIsSubmitted}
          hasTemplateReviewers={createProjectState.hasTemplateReviewers}
        />
      )}
    </Dialog>
  );
}

CreateProjectDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  selectedFolder: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};
CreateProjectDialog.defaultProps = {
  selectedFolder: null,
};
export default CreateProjectDialog;
