import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";

import EmptyProjectFiles from "./EmptyProjectFiles/EmptyProjectFiles";

const useStyles = makeStyles((theme) => ({
  dragActive: {
    width: ({ width, steps }) => width + 290 * steps - 4,
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: ({ maxWidth, steps }) => (steps + 1) * maxWidth,
    [theme.breakpoints.down("md")]: {
      width: "98vw",
    },
  },
}));

function EmptySectionItem({ project, isDragActive, steps }) {
  const { width, minWidth, maxWidth } = useFileCardResized();
  const classes = useStyles({
    width,
    minWidth,
    maxWidth,
    steps: steps.length,
  });
  const theme = useTheme();

  return (
    <Box position="relative" mr={14.5} mb={0.25} mt={0.35}>
      {isDragActive && (
        <Box
          data-testid="empty-section-row-item-drag-active"
          display="flex"
          minHeight={250}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          bgcolor={theme.color["green-translucent-90"]}
          borderLeft={`2px dashed ${theme.color.green[500]}`}
          borderRight={`2px dashed ${theme.color.green[500]}`}
          borderBottom={`2px dashed ${theme.color.green[500]}`}
          position="absolute"
          left={0}
          bottom={0}
          top={-5}
          zIndex={40}
          color={theme.color.green[500]}
          marginLeft={{ sm: 0.5, md: 0 }}
          className={classes.dragActive}
        />
      )}
      <Box display="flex" data-testid="empty-section-row-item">
        <Box display="flex">
          <EmptyProjectFiles project={project} isDragActive={isDragActive} />
          <Box width={steps.length * 295} minHeight={37} />
        </Box>
      </Box>
    </Box>
  );
}

EmptySectionItem.propTypes = {
  project: PropTypes.shape(projectProps),
  isDragActive: PropTypes.bool,
  steps: PropTypes.arrayOf(stepProps),
};

EmptySectionItem.defaultProps = {
  project: {},
  isDragActive: false,
  steps: [],
};

export default EmptySectionItem;
