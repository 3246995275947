import { useState, useEffect, useRef } from "react";

import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import EVENT_NAMES from "@shared/constants/events";
import eventService from "@shared/services/eventService";

/**
 * Use Project Steps hook
 *
 * @description This hook returns the project steps for the given projectId.
 * It also contains the event listener to listen the changes on the project
 * steps.
 * @typedef StepObject
 * @typedef FSTGId
 * @param {FSTGId} projectId - [Required] The projectId to get the steps for.
 * @param {boolean} isTemplate - Is this project template.
 * @returns {[Array.<StepObject>, boolean]} - Returns an array with the steps and a boolean
 * to indicate if the steps are loading.
 */
export default function useProjectSteps(projectId, isTemplate) {
  const visibleProjectRef = useRef(projectId);

  const [stepsPagination, setStepsPagination] = useState({
    steps: [],
    isLoading: false,
  });

  useEffect(() => {
    visibleProjectRef.current = projectId;

    async function fetchSteps(projectId) {
      let steps = [];
      if (isTemplate) {
        const project = await projectTemplateService.fetchProjectTemplateById(
          projectId
        );
        steps = project.steps;
      } else {
        steps = await stepService.getProjectSteps(projectId);
      }

      if (projectId === visibleProjectRef.current) {
        setStepsPagination({
          steps,
          isLoading: false,
        });
      }
    }

    /* istanbul ignore next */
    const onStepUpdate = (event) => {
      if (event.eventData.projectId === projectId) {
        setStepsPagination((prevState) => ({
          ...prevState,
          steps: event.eventData.steps,
        }));
      }
    };

    setStepsPagination({
      steps: [],
      isLoading: true,
    });

    if (projectId) {
      fetchSteps(projectId);
    }

    eventService.addListener(
      EVENT_NAMES.PROJECT_STEPS_WITHOUT_FILES.UPDATED,
      onStepUpdate
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT_STEPS_WITHOUT_FILES.UPDATED,
        onStepUpdate
      );
    };
  }, [projectId]); // eslint-disable-line react-hooks/exhaustive-deps

  return [stepsPagination.steps, stepsPagination.isLoading];
}
