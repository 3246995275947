import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link, useHref } from "react-router-dom";

import { Tabs, Tab } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Box, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import pageTitleService from "@shared/services/pageTitleService";

const VIRTUAL_ROUTES = [
  "members",
  "roles",
  "branding",
  "settings",
  "subscription",
  "billing",
  "api",
];

function Teams() {
  const { t } = useTranslation();

  const url = useHref();
  const tabName = url.split("/").pop();
  const team = useSelectedTeam();
  const [tabIndex, setTabIndex] = useState(
    Math.max(VIRTUAL_ROUTES.indexOf(tabName || "members"), 0)
  );

  useEffect(() => {
    pageTitleService.setTitle(t("TEAM.PAGE-TITLE"));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const tabs = [
    {
      label: t("TEAM.TAB_MEMBERS"),
      to: "members",
    },
    {
      label: t("TEAM.TAB_ROLES"),
      to: "roles",
    },
    {
      label: t("TEAM.TAB_BRANDING"),
      to: "branding",
    },
    {
      label: t("TEAM.TAB_SETTINGS"),
      to: "settings",
    },
    {
      label: t("TEAM.TAB_SUBSCRIPTION"),
      to: "subscription",
    },
    { label: t("TEAM.TAB_BILLING"), to: "billing" },

    ...(team.subscriptionLimits.isApiEnabled
      ? [
          {
            label: t("TEAM.TAB_API"),
            to: "api",
          },
        ]
      : []),
  ];

  const handleChange = (event, newValue) => {
    const newTabName = VIRTUAL_ROUTES[newValue];
    setTabIndex(newValue);
    if (newTabName === "api") {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.TEAM_SETTINGS.API,
        {
          teamId: team._id,
        }
      );
    }
  };

  return (
    <Box
      minHeight={0}
      display="flex"
      flexDirection="column"
      px={6.25}
      gap={4}
      overflow="auto"
      position="relative"
    >
      <Text component="h2" variant="h3" sx={{ mt: 2 }}>
        {team.name}
      </Text>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {tabs.map((tab) => (
          <Tab key={tab.label} label={tab.label} component={Link} to={tab.to} />
        ))}
      </Tabs>
      <Box
        width="100%"
        maxWidth={1000}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        alignSelf="center"
        mb={6}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default Teams;
