import { DRAWING_CONFIG } from "@feedback/constants/drawingConfig";
import { markerService } from "@feedback/services/marker";
import viewerService from "@feedback/services/viewer";
import { compareAsc } from "date-fns";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";

import fstgId from "@shared/helpers/fstgId";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

function serializeComment(comment) {
  const serializedComment = cloneDeep(comment);
  if (comment.marker && comment.marker.annotationId) {
    serializedComment.annotation = [
      { id: comment.marker.annotationId, xfdf: comment.marker.xfdf },
    ].concat(comment.annotation || []);
  }
  if (comment.attachments && comment.attachments.length) {
    serializedComment.attachments = comment.attachments.map(
      ({ original, thumbnail, transcoded }) => ({
        originalId: original.id,
        ...(Boolean(thumbnail) && { thumbnailId: thumbnail.id }),
        ...(Boolean(transcoded) && { transcodedId: transcoded.id }),
      })
    );
  }

  return serializedComment;
}

const getCommentsByReviewId = async (reviewId, forceAnonymise) => {
  const comments = await backend.get(`/reviews/${reviewId}/comments`, {
    forceAnonymise,
  });
  return comments.map((comment) => {
    if (comment.annotation) {
      for (const annotation of comment.annotation) {
        if (!annotation.id) {
          annotation.id = fstgId.generate();
        }
      }
    }

    return comment;
  });
};

const create = async (comment) => {
  healthMetrics.trackStart("feedback.create-comment");
  try {
    const response = await backend.post("/comments", serializeComment(comment));
    healthMetrics.trackSuccess("feedback.create-comment");
    return response;
  } catch (err) {
    healthMetrics.trackFailure("feedback.create-comment", err);
    throw err;
  }
};

const update = function (commentId, comment) {
  const serializedComment = serializeComment(comment);
  const { teamOnly, body, mentionedIds, attachments, annotation, marker } =
    serializedComment;

  return backend.put(`/comments/${commentId}`, {
    teamOnly,
    body,
    mentionedIds,
    attachments,
    annotation,
    marker,
  });
};

const updateCommentCompletionState = function (commentId, isResolved) {
  return backend.post(`/comments/${commentId}/resolution/update`, {
    isResolved,
  });
};
const updateMarker = function (commentId, marker) {
  return backend.post(`/comments/${commentId}/marker/update`, {
    marker,
  });
};
const remove = function (commentId) {
  return backend.delete(`/comments/${commentId}`);
};
const createReply = function (commentId, commentReply) {
  const { body, teamOnly, mentionedIds, attachments } =
    serializeComment(commentReply);
  return backend.post(`/comments/${commentId}/replies`, {
    body,
    teamOnly,
    mentionedIds,
    attachments,
  });
};
const updateReply = function (commentId, replyId, commentReply) {
  const { body, teamOnly, mentionedIds, attachments } =
    serializeComment(commentReply);
  return backend.put(`/comments/${commentId}/replies/${replyId}`, {
    body,
    teamOnly,
    mentionedIds,
    attachments,
  });
};

const removeReply = function (commentId, replyId) {
  return backend.delete(`/comments/${commentId}/replies/${replyId}`);
};

const compareByTimeStamp = (firstComment, secondComment) => {
  const currentDate = new Date(firstComment.createdTime);
  const otherDate = new Date(secondComment.createdTime);
  return compareAsc(currentDate, otherDate);
};

const compareComments = function (firstComment, secondComment) {
  if (firstComment === secondComment) {
    return 0;
  }

  if (firstComment.marker && secondComment.marker) {
    const compareValue = markerService.compareMarker(
      firstComment.marker,
      secondComment.marker
    );
    // if the two objects could be compared by their markers, then return
    // the compared result value
    if (!(compareValue === null || compareValue === 0)) {
      return compareValue;
    }
  }

  // if both (firstComment and secondComment) have no marker (e.g. two global comments),
  // compare the timestamps
  if (!firstComment.marker && !secondComment.marker) {
    return compareByTimeStamp(firstComment, secondComment);
  }

  // if only firstComment contains a marker, it should be before other
  if (!firstComment.marker) {
    return -1;
  }

  // if only secondComment contains a marker it should be before this
  return 1;
};

const trackCommentEvents = function (comment, versionMediaType) {
  trackDrawingEvents(comment.reviewId, comment.annotation, versionMediaType);
  if (comment.mentioned.length > 0) {
    viewerService.trackFileEvent(
      comment.reviewId,
      analytics.ACTION.MENTIONED,
      analytics.CATEGORY.COMMENT
    );
  }
};

function trackDrawingEvents(reviewId, drawings, versionMediaType) {
  if (isEmpty(drawings)) {
    return;
  }

  const trackingEvent = {
    category: analytics.CATEGORY.ANNOTATION,
    action: analytics.ACTION.CREATED,
    metaData: {
      fileType: versionMediaType,
    },
  };

  const lastDrawAnnotation = last(drawings);

  for (const colorName in DRAWING_CONFIG.CANVAS.COLORS) {
    if (
      DRAWING_CONFIG.CANVAS.COLORS[colorName].toUpperCase() ===
      lastDrawAnnotation.color.toUpperCase()
    ) {
      trackingEvent.metaData.ANNOTATION_COLOR = colorName;

      break;
    }
  }

  trackingEvent.metaData.ANNOTATION_TOOL = lastDrawAnnotation.tool;

  viewerService.trackFileEvent(
    reviewId,
    analytics.ACTION.CREATED,
    analytics.CATEGORY.ANNOTATION,
    {
      annotationColor: trackingEvent.metaData.ANNOTATION_COLOR,
      annotationTool: trackingEvent.metaData.ANNOTATION_TOOL,
      fileType: trackingEvent.metaData.FILE_TYPE,
    }
  );
}
const searchComments = function (comments, query) {
  function isTextMatch(text, query) {
    return text.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  }

  function isCommentMatch(comment) {
    return (
      comment.isNewComment ||
      isTextMatch(comment.body, query) ||
      isTextMatch(comment.author.displayName, query) ||
      comment.attachments.some((attachment) =>
        isTextMatch(attachment.original.name, query)
      )
    );
  }

  return comments.filter(
    (thread) => isCommentMatch(thread) || thread.replies.some(isCommentMatch)
  );
};

const getTextAnnotationType = function ({ xfdf }) {
  if (!xfdf) {
    return null;
  }

  const regex = /<\/(highlight|strikeout)>/;
  const found = xfdf.match(regex);

  return found && found[1];
};

const copy = async (
  fromReviewId,
  targetReviewId,
  selectedCommentIds,
  shouldCopyAll,
  isBetweenVersions
) => {
  healthMetrics.trackStart("feedback.copy-comment");
  try {
    const response = await backend.post(`/reviews/${targetReviewId}/comments`, {
      fromReviewId,
      shouldCopyAll,
      selectedCommentIds,
      isBetweenVersions,
    });
    healthMetrics.trackSuccess("feedback.copy-comment");
    return response;
  } catch (error) {
    healthMetrics.trackFailure("feedback.copy-comment", error);
    return error;
  }
};

const like = function (commentId) {
  return backend.post(`/comments/${commentId}/like`);
};

const unlike = function (commentId) {
  return backend.delete(`/comments/${commentId}/like`);
};

const updateCommentIsPinned = function (commentId, isPinned) {
  return backend.post(`/comments/${commentId}/isPinned`, {
    isPinned,
  });
};

export default {
  create,
  createReply,
  update,
  updateReply,
  remove,
  removeReply,
  updateMarker,
  copy,
  like,
  unlike,
  updateCommentCompletionState,
  getCommentsByReviewId,
  searchComments,
  trackCommentEvents,
  getTextAnnotationType,
  compareComments,
  updateCommentIsPinned,
};
