import {
  string,
  number,
  shape,
  bool,
  any,
  arrayOf,
  objectOf,
  oneOfType,
  oneOf,
  func,
} from "prop-types";

import { userProp } from "./authentication";
import { billingProp } from "./billing";

export const teamServiceProp = shape({
  getTeams: func,
  determineSelectedTeamByDefault: func,
  determineSelectedTeamByTeamId: func,
  determineSelectedTeamByProjectId: func,
  determineSelectedTeamByFileId: func,
  updateSelectedTeam: func,
  updateUsedBillingLimitsOfSelectedTeam: func,
  switchTeam: func,
  createTeam: func,
  updateTeamSettings: func,
  wizardProgressTrack: func,
  addTeamMember: func,
  removeTeamMember: func,
  assignRoleToTeamMember: func,
  removeTeam: func,
  updateTeamLogo: func,
  fetchTeamByTeamId: func,
  checkStorageSizeExceeded: func,
});

export const imageProp = shape({
  id: string,
  name: string,
  key: string,
  url: string,
  label: string,
  sizeInBytes: number,
  mimeType: string,
  metaData: shape({ width: number, height: number }),
  extension: string,
});

const priceProp = shape({
  id: string,
  displayName: string,
  amount: number,
  currency: string,
  interval: string,
  isFree: bool,
  description: string,
  name: string,
  limits: shape({
    includedUsers: number,
    maxFileNumber: number,
    isBrandingEnabled: bool,
    isExportEnabled: bool,
    maxStorageSizeTeamMB: number,
    maxStorageSizeMemberMB: number,
    isApiEnabled: bool,
    isSSOEnabled: bool,
    isSubdomainEnabled: bool,
    isCustomRolesEnabled: bool,
    isReviewerDashboardEnabled: bool,
    isReviewerUploadEnabled: bool,
    maxProjectNumber: number,
    isMemberRoleEnabled: bool,
    maxReviewStepsNumber: number,
    isDueDateFeatureEnabled: bool,
    areFilePickerPluginsEnabled: bool,
    isTeamOnlyFeatureEnabled: bool,
    maxProjectTemplateNumber: number,
    areFeatureUpdatesIncluded: bool,
    isAnonymousReviewersEnabled: bool,
    isReviewWebsitesEnabled: bool,
    isStandardAppsEnabled: bool,
    isPremiumAppsEnabled: bool,
  }),
});

export const limitsProps = shape({
  includedUsers: number,
  maxFileNumber: number,
  isBrandingEnabled: bool,
  isExportEnabled: bool,
  maxStorageSizeTeamMB: number,
  maxStorageSizeMemberMB: number,
  isApiEnabled: bool,
  isSSOEnabled: bool,
  isSubdomainEnabled: bool,
  isCustomRolesEnabled: bool,
  isReviewerDashboardEnabled: bool,
  isReviewerUploadEnabled: bool,
  maxProjectNumber: number,
  isMemberRoleEnabled: bool,
  maxReviewStepsNumber: number,
  isDueDateFeatureEnabled: bool,
  areFilePickerPluginsEnabled: bool,
  isTeamOnlyFeatureEnabled: bool,
  maxProjectTemplateNumber: number,
  areFeatureUpdatesIncluded: bool,
  isAnonymousReviewersEnabled: bool,
  maxStorageSizeBytes: number,
  isReviewWebsitesEnabled: bool,
  isStandardAppsEnabled: bool,
  isPremiumAppsEnabled: bool,
});

export const subscriptionProp = shape({
  id: string,
  status: string,
  currentPeriodStart: number,
  currentPeriodEnd: number,
  cancelAtPeriodEnd: bool,
  canceledAt: any,
  taxRate: number,
  items: arrayOf(
    shape({
      id: string,
      price: priceProp,
      quantity: number,
    })
  ),
  limits: limitsProps,
  basePrice: priceProp,
  additionalPrice: priceProp,
  additionalProjectsPrice: priceProp,
  isPaying: bool,
  ipAddress: string,
  monthlySpend: number,
});

export const roleProp = shape({
  _id: string,
  roleName: string,
  teamId: string,
  isDefault: bool,
  permissions: objectOf(bool),
});

export const teamMembersProp = arrayOf(
  shape({
    role: roleProp,
    user: userProp,
  })
);
export const teamMemberProp = shape({
  role: roleProp,
  user: userProp,
});
export const teamProp = shape({
  _id: string,
  name: string,
  billing: billingProp,
  isTeamMember: bool,
  permissions: shape({
    canManageBilling: bool,
    canManageLogo: bool,
    canCreateProjects: bool,
    canManageMembers: bool,
    canManageTemplates: bool,
    canRename: bool,
  }),
  branding: shape({
    logo: oneOfType([
      shape({
        heightPx: number,
        original: imageProp,
        retina: imageProp,
      }),
      bool,
    ]),
  }),
  subscription: subscriptionProp,
  subscriptionLimits: limitsProps,
  usedBillingLimits: shape({
    numberOfActiveProjects: number,
    numberOfProjectTemplates: number,
    usedStorageSizeByte: number,
    lockedProjectIds: oneOfType([arrayOf(string), oneOf(["all"])]),
    lockedProjectTemplateIds: oneOfType([arrayOf(string), oneOf(["all"])]),
  }),
  members: teamMembersProp,
});
