import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
} from "@mui/icons-material";
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { useTheme } from "@mui/styles";

import { Text, Box } from "@shared/UIKit";

import getPlugin from "@shared/helpers/getPlugin";
import { stepProps } from "@shared/props/step";

function StepSelection({
  steps,
  handleChange,
  selectedSteps,
  isSelectedFileAvailable,
  isBlurred,
}) {
  const { t } = useTranslation();
  const currentPlugin = getPlugin();
  const theme = useTheme();
  const [blurred, setBlurred] = useState(false);

  useEffect(() => {
    setBlurred(isBlurred);
  }, [isBlurred]);
  return (
    <Box
      onMouseOver={() => setBlurred(false)}
      onMouseOut={() => setBlurred(isBlurred)}
      data-testid="upload-panel-section"
      display="flex"
      flexDirection="column"
      justifyContent="stretch"
      flexGrow={1}
      {...(currentPlugin === PLUGIN_TYPES.PREMIERE
        ? {
            paddingLeft: "12px",
            paddingRight: "12px",
          }
        : {})}
      {...(currentPlugin === PLUGIN_TYPES.INDESIGN
        ? {
            backgroundColor: "grey.50",
            display: "flex",
            alignItems: "center",
            padding: theme.spacing(2.75),
          }
        : {})}
      bgcolor="grey.100"
      gap={0}
      pt={1}
      borderRadius="6px"
      mt={1.5}
      mb={3.5}
      padding={theme.spacing(1.5)}
      color={blurred ? "text.disabled" : "text.primary"}
    >
      <Text
        variant="textXs"
        fontWeight="fontWeight.medium"
        sx={{
          opacity: blurred ? 0.5 : 1,
        }}
      >
        {isSelectedFileAvailable
          ? t("FILE.UPLOAD_PANEL.SELECT_REVIEW_STEP_VERSION")
          : t("UPLOAD_DIALOG.START_REVIEW")}
      </Text>

      <FormGroup
        row
        sx={{
          gap: 0,
          whiteSpace: "nowrap",
          mt: 1,
          opacity: blurred ? 0.5 : 1,
        }}
      >
        {steps.map((step) => (
          <FormControlLabel
            sx={{
              minWidth: "30%",
            }}
            slotProps={{
              typography: {
                variant: "textSm",
                color: "text.secondary",
              },
            }}
            key={step.id}
            control={
              <MuiCheckbox
                name={step.id}
                size="small"
                color="primaryV2"
                checked={Boolean(selectedSteps[step.id])}
                onChange={handleChange}
                data-testid="upload-panel-step"
                inputProps={{
                  "data-testid": "upload-panel-step-input",
                }}
                icon={<CheckBoxOutlineBlank />}
                checkedIcon={<CheckBoxIcon />}
                disableRipple
              />
            }
            label={step.name}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

StepSelection.propTypes = {
  isSelectedFileAvailable: PropTypes.bool,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  selectedSteps: PropTypes.objectOf(PropTypes.bool),
  handleChange: PropTypes.func.isRequired,
  isBlurred: PropTypes.bool,
};

StepSelection.defaultProps = {
  isSelectedFileAvailable: false,
  selectedSteps: {},
  isBlurred: false,
};

export default StepSelection;
