import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { Text, Tooltip } from "@shared/UIKit";

function HelpButtonTooltip({
  children,
  isFileViewPage,
  canExtendTrial,
  shouldShowOnCompletedTooltip,
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  if (shouldShowOnCompletedTooltip && !isFileViewPage) {
    return (
      <Tooltip
        disableHoverListener
        title={
          <Box textAlign="left">
            <Text
              variant="textMd"
              fontWeight="fontWeight.bold"
              color="grey.700"
              translate={
                canExtendTrial
                  ? "CHECKLIST.ONBOARDING_SECTION.ICON.TOOLTIP.TITLE"
                  : "CHECKLIST.ONBOARDING_SECTION.ICON_COMPLETED.TOOLTIP.TITLE"
              }
            />
            <Text
              color="grey.700"
              variant="textSm"
              translate={
                canExtendTrial
                  ? "CHECKLIST.ONBOARDING_SECTION.ICON.TOOLTIP.BODY"
                  : "CHECKLIST.ONBOARDING_SECTION.ICON_COMPLETED.TOOLTIP.BODY"
              }
            />
          </Box>
        }
        variant="white"
        open={shouldShowOnCompletedTooltip}
      >
        {children}
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={t("REVIEWER.SETTINGS.HELP")}
      open={shouldShowOnCompletedTooltip || open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {children}
    </Tooltip>
  );
}

HelpButtonTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  isFileViewPage: PropTypes.bool.isRequired,
  canExtendTrial: PropTypes.bool.isRequired,
  shouldShowOnCompletedTooltip: PropTypes.bool.isRequired,
};

export default HelpButtonTooltip;
