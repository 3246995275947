import { useTranslation } from "react-i18next";
import { useParams, Link as RouterLink } from "react-router-dom";

import {
  GetAppOutlined,
  FolderOpenOutlined,
  LinkOff,
} from "@mui/icons-material";
import { Button, IconButton, Icon, SvgIcon } from "@mui/material";
import { useBulkDownload } from "@workflow/data/bulk-download";

import { Box, LoadingSpinner, Text } from "@shared/UIKit";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import { APP_ROUTES } from "@shared/constants/routes";

const SIZES = ["Bytes", "KB", "MB", "GB", "TB"];

const convertBytes = (bytes) => {
  if (bytes === 0) {
    return "0 Byte";
  }
  const index = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (index === 0) {
    return `${bytes} ${SIZES[index]}`;
  }
  return `${(bytes / 1024 ** index).toFixed(1)} ${SIZES[index]}`;
};

const BulkDownload = () => {
  const { t } = useTranslation();

  const { processId } = useParams();
  const { data, isLoading, error } = useBulkDownload(processId);

  if (isLoading) {
    return (
      <Box
        flex={1}
        pt={8}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <LoadingSpinner />
      </Box>
    );
  }

  // istanbul ignore next
  const downloadAll = () => {
    data.zips.forEach((file, index) => {
      setTimeout(() => {
        window.location = file.url;
      }, 1500 * index);
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="fill-available"
      width="fill-available"
    >
      <Box height={60} bgcolor="white">
        <Box
          max-width={1200}
          margin="auto"
          display="flex"
          height="100%"
          justifyContent="start"
          alignItems="center"
          px={3}
        >
          <FilestageResponsiveLogo />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="fill-available"
        alignItems="center"
        justifyContent="center"
        maxWidth={400}
        width="80%"
        minWidth={250}
        margin="auto"
        marginTop={-7.5}
        data-testid="bulk-download"
      >
        {error ? (
          <Box display="flex" data-testid="bulk-download-link-expired" gap={2}>
            <Icon fontSize="large">
              <SvgIcon color="error" component={LinkOff} />
            </Icon>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              alignItems="flex-start"
            >
              <Text variant="textXl" fontWeight="fontWeight.bold">
                {t("BULK_DOWNLOAD.LINK_EXPIRED.TITLE")}
              </Text>
              <Text variant="h6" color="text.secondary">
                {t("BULK_DOWNLOAD.LINK_EXPIRED.DESCRIPTION")}
              </Text>
              <Button
                variant="contained"
                color="primary"
                to={APP_ROUTES.DASHBOARD.path}
                component={RouterLink}
              >
                {t("BULK_DOWNLOAD.LINK_EXPIRED.CTA")}
              </Button>
            </Box>
          </Box>
        ) : (
          data?.zips && (
            <>
              <Box my={3}>
                <Text variant="h6" fontWeight="fontWeight.medium">
                  {t("BULK_DOWNLOAD.FILES.HEADLINE", {
                    projectName: data.projectName,
                  })}
                </Text>
              </Box>
              {data.zips.map((file) => (
                <Box
                  key={file.url}
                  width="100%"
                  paddingY={1.25}
                  marginBottom={0.5}
                  borderRadius="4px"
                  bgcolor="white"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  data-testid="bulk-download-file"
                >
                  <Box display="flex" marginLeft={1.5} width="65%">
                    <Icon pt={0.5}>
                      <SvgIcon component={FolderOpenOutlined} />
                    </Icon>
                    <Box
                      display="flex"
                      flexDirection="column"
                      marginLeft={2}
                      width="100%"
                    >
                      <Text
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {file.name}
                      </Text>
                      <Text variant="body2">{convertBytes(file.size)}</Text>
                    </Box>
                  </Box>
                  <IconButton
                    size="medium"
                    color="primary"
                    data-testid="file-download-button"
                    href={file.url}
                    download
                  >
                    <GetAppOutlined />
                  </IconButton>
                </Box>
              ))}
              {data.zips.length > 1 && (
                <Box display="flex" alignSelf="end" mt={1}>
                  <Button
                    onClick={downloadAll}
                    variant="text"
                    color="primary"
                    size="large"
                    data-testid="bulk-download-all-files"
                  >
                    {t("BULK_DOWNLOAD.FILES.DOWNLOAD_ALL")}
                  </Button>
                </Box>
              )}
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default BulkDownload;
