import PropTypes from "prop-types";
import { useState } from "react";

import ReviewDecisionIndicatorList from "@feedback/components/ReviewDecisionIndicatorList/ReviewDecisionIndicatorList";
import { Box } from "@mui/material";

import pagesEnum from "@shared/constants/pages";
import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";

import ReviewOverview from "../ReviewOverview/ReviewOverview";
import ReviewStatusBadge from "../ReviewStatusBadge/ReviewStatusBadge";

function ReviewOverviewButton({ project, review, page }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const isApproved = review.status.state === REVIEW_STATUS.APPROVED;
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const openReviewOverviewPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        minHeight={30}
        gap={0.5}
        data-testid="review-overview-button"
        onClick={openReviewOverviewPopover}
        px={0.5}
        borderRadius={1}
        sx={{
          cursor: "pointer",
          width: "fit-content",
          maxWidth: "180px",
          "&:hover": {
            backgroundColor: "grey.50",
          },
        }}
      >
        <ReviewStatusBadge reviewState={review.status.state} isReadOnly />
        <ReviewDecisionIndicatorList
          review={review}
          variant="reviewOverview"
          indicatorPosition="right"
          isApproved={isApproved}
        />
      </Box>
      {Boolean(anchorEl) && (
        <ReviewOverview
          review={review}
          closeMenu={closeMenu}
          anchorEl={anchorEl}
          id={`review-overview-${review.id}`}
          reviewState={review.status.state}
          page={page}
          project={project}
        />
      )}
    </>
  );
}

ReviewOverviewButton.propTypes = {
  review: versionProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  page: PropTypes.oneOf([pagesEnum.PROJECT_DASHBOARD, pagesEnum.WORKSPACE])
    .isRequired,
};

export default ReviewOverviewButton;
