export const EMAIL_REGEX = new RegExp(
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  "i"
);

export const INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX =
  /[\d!@#$%^&*()_+\-=[\]{};':"\\|,~.<>/?]+/;

export const EMAIL_MENTION_REGEX =
  /(@[a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
