import PropTypes from "prop-types";

import { Skeleton } from "@mui/material";

import { InlineBadge } from "@shared/UIKit";

function PendingReviewsCounter({
  maxPendingReviews,
  pendingReviewsCount,
  isLoading,
}) {
  if (isLoading) {
    return <Skeleton variant="text" width={28} height={40} />;
  }

  return (
    <InlineBadge
      variant="contained"
      color={pendingReviewsCount ? "error" : "default-light"}
      size="sm"
      data-testid="pending-review-nav-button-reviews-count"
    >
      {pendingReviewsCount === maxPendingReviews
        ? `${maxPendingReviews - 1}+`
        : pendingReviewsCount}
    </InlineBadge>
  );
}

PendingReviewsCounter.propTypes = {
  maxPendingReviews: PropTypes.number,
  pendingReviewsCount: PropTypes.number,
  squared: PropTypes.bool,
  isLoading: PropTypes.bool,
};

PendingReviewsCounter.defaultProps = {
  maxPendingReviews: 100,
  squared: false,
  isLoading: false,
  pendingReviewsCount: 0,
};

export default PendingReviewsCounter;
