import PropTypes from "prop-types";
import { memo } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { FILE_VERSION_UPLOAD_STATUS } from "@shared/constants/upload";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import ActiveReviewCard from "./ActiveReviewCard/ActiveReviewCard";
import NotSharedState from "./NotSharedState/NotSharedState";
import ProcessingReviewCard from "./ProcessingReviewCard/ProcessingReviewCard";

const useStyles = makeStyles((theme) => ({
  reviewCard: {
    cursor: "default",
    position: "relative",
    "&:last-child": {
      "&::after": {
        display: "none",
      },
      width: "289px !important",
      borderRight: `1px ${theme.color.gray[200]} solid`,
    },
    borderBottom: `1px ${theme.color.gray[200]} solid`,
    backgroundColor: theme.color.white,
    "&::after": {
      content: "''",
      width: 1,
      height: 50,
      backgroundColor: theme.color.gray[200],
      display: "block",
      position: "absolute",
      right: 0,
      bottom: 10,
    },
    "&:hover": {
      backgroundColor: theme.color.white,
    },
  },
  clearSeparator: {
    "&::after": {
      display: "none !important",
    },
  },
  topReview: {
    borderTop: `1px ${theme.color.gray[200]} solid`,
  },
  topReviewShared: {
    [theme.breakpoints.up("md")]: {
      "&:not(:first-child:last-child):hover": {
        borderTop: 0,
      },
      "&:not(:first-child:last-child):hover > div": {
        paddingTop: "1px !important",
      },
    },
  },
  notShared: {
    backgroundColor: theme.color.gray[50],
  },
  isApproved: {
    backgroundColor: theme.color.green[50],
  },
  root: {
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        zIndex: 22,
        "&::after": {
          display: "none",
        },
      },
      '&:hover [data-testid="review-decision-indicator-list"]': {
        "&::before": {
          backgroundImage: `linear-gradient(to right, rgba(255,255,255,0) , rgba(255,255,255,1)) !important`,
        },
      },
      "&:hover $cardContent": {
        paddingBottom: "0 !important",
      },
      "&:first-child:not(:last-child)": {
        marginLeft: -1,
      },
      "&:not(:first-child):hover $cardContainer": {
        marginLeft: -1,
        paddingLeft: 1,
      },
      "&:not($pendingReview):first-child:last-child $cardContainer": {
        marginLeft: -1,
      },
      "&:last-child $cardContainer": {
        marginRight: -1,
      },
    },
  },
  pendingReview: {
    outline: `1px ${theme.color["vermilion-translucent-50"]} solid`,
    outlineOffset: -1,
    zIndex: 21,
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        outline: "none",
      },
    },
    "&:first-child $cardContent": {
      paddingLeft: "9px !important",
    },
    "&:first-child:not(:last-child)": {
      marginLeft: -1,
    },
    "&:first-child:last-child": {
      marginLeft: -1,
    },
    "&::after": {
      display: "none",
    },
  },
  alternativeColumn: {
    marginLeft: -1,
    paddingLeft: 1,
  },
  alternativeColumnSpacing: {
    width: "290px !important",
  },
  borderedAlternativeCard: {
    height: 68,
    marginTop: -1,
    borderTop: `1px ${theme.color.gray[200]} solid`,
    [theme.breakpoints.up("md")]: {
      "&:hover $cardContainer": {
        marginTop: -1,
        paddingTop: 1,
      },
    },
  },
  bottomFrame: {
    display: "none",
  },
  cardContainer: {},
  cardContent: {},
}));

function getReviewCardClasses(
  classes,
  {
    isProjectArchived,
    isFileActive,
    isPendingReview,
    isAlternativeColumn,
    isAlternativeRow,
    isSharedState,
  }
) {
  const isActiveSharedFile = isFileActive && isSharedState;
  const enableInteractions = isFileActive && !isProjectArchived;
  const topmostRow = !isAlternativeRow;
  return {
    [classes.topReview]: topmostRow,
    [classes.topReviewShared]: topmostRow && isSharedState,
    [classes.notShared]: !isSharedState,
    [classes.root]: isActiveSharedFile,
    [classes.pendingReview]: enableInteractions && isPendingReview,
    [classes.alternativeRow]:
      enableInteractions && isAlternativeRow && isPendingReview,
    [classes.alternativeColumn]:
      enableInteractions && isAlternativeColumn && isPendingReview,
  };
}

function getIsNextReviewShared(version, steps, index) {
  const nextStepId = index + 1 >= steps.length ? null : steps[index + 1].id;
  return Boolean(
    nextStepId
      ? version.reviews.find((review) => review.stepId === nextStepId)
      : false
  );
}

function ReviewCard({
  team,
  file,
  step,
  steps,
  version,
  project,
  className,
  rowIndex,
  columnIndex,
}) {
  const classes = useStyles();

  const review = version.reviews.find((review) => review.stepId === step.id);
  const isNextReviewShared = getIsNextReviewShared(version, steps, columnIndex);
  const isPendingReview = Boolean(review?.isPendingYourReview);
  const isFileActive = !file.isLocked;
  const isAlternativeRow = rowIndex > 0;
  const isAlternativeColumn = columnIndex > 0;
  const isApproved = Boolean(review?.status.state === REVIEW_STATUS.APPROVED);
  const uploadStatus = file.versions[file.versions.length - 1].uploadStatus;
  return file.isProcessing ||
    uploadStatus === FILE_VERSION_UPLOAD_STATUS.PROCESSING ? (
    <ProcessingReviewCard />
  ) : (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width={290}
      height={68}
      data-testid="file-review-card"
      data-review-card-row-index={rowIndex}
      data-review-card-row-alternative={isAlternativeRow}
      data-review-card-default={Boolean(review) && isFileActive}
      className={classnames(
        classes.reviewCard,
        {
          [classes.isApproved]: isApproved,
          [classes.alternativeColumnSpacing]: isAlternativeColumn,
          [classes.borderedAlternativeCard]: isAlternativeRow,
          [classes.clearSeparator]: Boolean(review) && !isNextReviewShared,
          ...getReviewCardClasses(classes, {
            isProjectArchived: project.isArchived,
            isSharedState: Boolean(review),
            isFileActive,
            isAlternativeColumn,
            isAlternativeRow,
            isPendingReview,
          }),
        },
        className
      )}
    >
      {review ? (
        <ActiveReviewCard
          team={team}
          project={project}
          file={file}
          step={step}
          review={review}
          version={version}
          cardContentClass={classes.cardContent}
          cardContainerClass={classes.cardContainer}
        />
      ) : (
        <NotSharedState
          file={file}
          version={version}
          step={step}
          project={project}
        />
      )}
    </Box>
  );
}
ReviewCard.propTypes = {
  team: teamProp.isRequired,
  file: fileProp.isRequired,
  step: stepProps.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  version: versionProp.isRequired,
  className: PropTypes.string,
  project: PropTypes.shape(projectProps).isRequired,
  rowIndex: PropTypes.number,
  columnIndex: PropTypes.number,
};

ReviewCard.defaultProps = {
  rowIndex: 0,
  columnIndex: 0,
  className: "",
};

export default memo(ReviewCard);
