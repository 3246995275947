import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { SvgIcon, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  controlIcon: {
    paddingRight: 10,
    marginBottom: 2,
    color: theme.color.gray[300],
  },
  iconFocus: {
    color: theme.color.green[500],
  },
  autocompleteInput: {
    "& > div > input": {
      marginBottom: -4,
    },
  },
  autocompleteInputSection: {
    display: "flex",
    alignItems: "flex-end",
  },
  helperText: {
    color: theme.color.green[500],
  },
}));

const AddReviewerDialogAutocompleteOption = ({
  onKeyDown,
  inputLabel,
  inputIcon,
  inputHelperText,
  reviewersEmailsLength,
  maxInviteCount,
  ...restProps
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [focusElement, setFocusElement] = useState(null);

  /* istanbul ignore next */
  const handleOnBlur = useCallback(() => setFocusElement(null), []);
  const handleOnFocus = useCallback(
    ({ target }) => setFocusElement(target.id),
    []
  );
  const excessInviteCount =
    Math.max(reviewersEmailsLength - maxInviteCount, 0) || 0;

  const getHelperText = () => {
    if (excessInviteCount) {
      return t("INTEGRATIONS.API.INVITES_EXCEEDED", {
        count: excessInviteCount,
      });
    }

    if (inputHelperText) {
      if (focusElement === "add-reviewers-autocomplete") {
        return inputHelperText;
      }
      return " ";
    }
    return "";
  };

  return (
    <Box
      className={classes.autocompleteInputSection}
      data-testid="add-reviewers-autocomplete-option-section"
    >
      {inputIcon && (
        <SvgIcon
          component={inputIcon}
          className={classnames({
            [classes.controlIcon]: true,
            [classes.iconFocus]: focusElement === "add-reviewers-autocomplete",
          })}
        />
      )}
      <TextField
        {...restProps}
        data-testid="add-reviewers-dialog-autocomplete-input"
        label={t(inputLabel)}
        className={classes.autocompleteInput}
        onKeyDown={onKeyDown}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        autoFocus
        helperText={t(getHelperText())}
        FormHelperTextProps={{
          className: classes.helperText,
          error: Boolean(excessInviteCount),
        }}
      />
    </Box>
  );
};

AddReviewerDialogAutocompleteOption.propTypes = {
  onKeyDown: PropTypes.func.isRequired,
  inputLabel: PropTypes.string,
  inputHelperText: PropTypes.string,
  inputIcon: PropTypes.elementType,
  reviewersEmailsLength: PropTypes.number.isRequired,
  maxInviteCount: PropTypes.number,
};

AddReviewerDialogAutocompleteOption.defaultProps = {
  inputLabel: "REVIEWER.ADD.ENTER_REVIEWER",
  inputHelperText: "",
  inputIcon: null,
  maxInviteCount: undefined,
};

export default AddReviewerDialogAutocompleteOption;
