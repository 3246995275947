import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CardContent, Card, CardHeader } from "@mui/material";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Toggle } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";

function PreviewQualitySetting({ team }) {
  const { t } = useTranslation();
  const [checked] = useState(team.settings?.disablePreviewQualityFallback);

  const handleChange = async ({ currentTarget }) => {
    try {
      await teamService.updateTeamSettings({
        settings: {
          disablePreviewQualityFallback: currentTarget.checked,
        },
      });

      toastService.sendToast({
        title: "TEAM.EDIT.SETTING_SUCCESS.TITLE",
        body: "TEAM.EDIT.SETTING_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (err) {
      errorHandlerService.handleError(err);
    }
  };

  return (
    <Card>
      <CardHeader title={t("PREVIEW_QUALITY_CARD.HEADER")} />
      <CardContent sx={{ px: 6 }}>
        <Toggle
          label={t("PREVIEW_QUALITY_CARD.TITLE")}
          helpText={t("PREVIEW_QUALITY_CARD.BODY")}
          slotProps={{
            switch: {
              defaultChecked: checked,
              onChange: handleChange,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

PreviewQualitySetting.propTypes = {
  team: teamProp.isRequired,
};

export default PreviewQualitySetting;
