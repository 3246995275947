import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import ReviewState from "@workflow/components/ReviewState/ReviewState";
import classnames from "classnames";

import { Box, Tooltip } from "@shared/UIKit";

const useStyles = makeStyles(() => ({
  noBackground: {
    backgroundColor: "transparent",
  },
}));

const ReviewStatusBadge = ({ isReadOnly, reviewState, className }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip
      title={t(`REVIEW_STATUS.${reviewState}`)}
      description={t(`REVIEWER.SETTINGS.${reviewState}_STATUS`)}
    >
      <Box data-testid="review-status-badge" data-open-review-link="true">
        <ReviewState
          reviewState={reviewState}
          className={classnames(className, {
            [classes.noBackground]: !isReadOnly,
          })}
        />
      </Box>
    </Tooltip>
  );
};

ReviewStatusBadge.propTypes = {
  isReadOnly: PropTypes.bool,
  reviewState: PropTypes.string.isRequired,
  className: PropTypes.string,
};
ReviewStatusBadge.defaultProps = {
  isReadOnly: false,
  className: "",
};

export default ReviewStatusBadge;
