import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";

import { Text } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  subDescription: {
    marginTop: 30,
    color: theme.color.blue[500],
    fontWeight: theme.typography.fontWeight.semiBold,
  },
}));

function UpgradeDialogContentSubDescription({ isAdminView, variant }) {
  const { t } = useTranslation();
  const classes = useStyles();
  let subDescription;

  if (variant === "compareVersions" && !isAdminView) {
    subDescription = t(
      "COMPARE_VERSIONS.UPGRADE_CTA.NON_ADMIN_SUB_DESCRIPTION"
    );
  }

  if (variant.startsWith("copyComments") && !isAdminView) {
    subDescription = t("COPY_COMMENTS.UPGRADE_POP_OVER.SUB_DESCRIPTION");
  }

  return subDescription ? (
    <Text variant="body2" className={classes.subDescription}>
      {subDescription}
    </Text>
  ) : null;
}

UpgradeDialogContentSubDescription.propTypes = {
  isAdminView: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default UpgradeDialogContentSubDescription;
