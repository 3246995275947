import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  reviewHeader: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 12,
    paddingRight: 12,
    borderBottom: `1px dashed ${theme.color.gray[200]}`,
  },
  stepDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

function PendingReviewsCardHeader({ project, step }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.reviewHeader}>
      <Box>
        <Text
          wordBreak="break-word"
          fontWeight={theme.typography.fontWeight.semiBold}
          fontSize={FSTGTypography.fontSize_1_3}
          color={theme.color.gray[800]}
          component="span"
        >
          {project.name}
        </Text>

        {step && (
          <>
            <Text
              color={theme.color.gray[800]}
              fontSize={FSTGTypography.fontSize_1_3}
              component="span"
              className={classes.stepDivider}
            >
              {">"}
            </Text>

            <Text
              color={theme.color.gray[600]}
              fontSize={FSTGTypography.fontSize_1_2}
              wordBreak="break-word"
              component="span"
            >
              {step.name}
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
}

PendingReviewsCardHeader.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  step: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

PendingReviewsCardHeader.defaultProps = {
  step: null,
};

export default PendingReviewsCardHeader;
