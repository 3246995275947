import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import HelpIcon from "@mui/icons-material/Help";
import {
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Box,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import {
  AnonymousReviewers,
  ReviewAccessControl,
  VerifiedApproval,
} from "@supporting/hooks/useGatedFeature/featureGates";

import { Text, Tooltip } from "@shared/UIKit";

const ReviewerGroupSecurityTab = ({ teamId, settings, onChange }) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [isPasswordSet, setIsPasswordSet] = useState(
    settings.passwordProtection
  );

  const {
    checkAndProceed: checkVerifiedApproval,
    isLimitReached: isVerifiedApprovalLimitReached,
    IconAsset,
  } = useGatedFeature({
    featureGate: VerifiedApproval,
    teamId,
  });

  const {
    checkAndProceed: checkAnonymousReviewers,
    isLimitReached: isAnonymousReviewersLimitReached,
  } = useGatedFeature({
    featureGate: AnonymousReviewers,
    teamId,
  });

  const {
    checkAndProceed: checkReviewAccessControl,
    isLimitReached: isReviewAccessControlLimitReached,
  } = useGatedFeature({
    featureGate: ReviewAccessControl,
    teamId,
  });

  const securitySettings = [
    {
      key: "SECURITY_PASSWORD",
      tooltip: "SECURITY_PASSWORD_TOOLTIP",
      field: "passwordProtection",
    },
    {
      key: "SECURITY_VERIFIED_APPROVAL",
      tooltip: "SECURITY_VERIFIED_APPROVAL_TOOLTIP",
      field: "digitalSignatureRequired",
      isLimitReached: isVerifiedApprovalLimitReached,
      check: checkVerifiedApproval,
    },
    {
      key: "SECURITY_LIMIT_ACCESS",
      tooltip: "SECURITY_LIMIT_ACCESS_TOOLTIP",
      field: "reviewAccessControl",
      isLimitReached: isReviewAccessControlLimitReached,
      check: checkReviewAccessControl,
    },
    {
      key: "SECURITY_ANONYMIZE",
      tooltip: "SECURITY_ANONYMIZE_TOOLTIP",
      field: "anonymousReviewers",
      isLimitReached: isAnonymousReviewersLimitReached,
      check: checkAnonymousReviewers,
    },
  ];

  return (
    <TableContainer data-testid="security-notifications-tab">
      <Table>
        <TableBody
          sx={{
            "& .MuiTableRow-root:last-child": {
              "& .MuiTableCell-root": {
                borderBottom: 0,
              },
            },
          }}
        >
          {securitySettings.map(
            ({ key, tooltip, field, isLimitReached, check }) => (
              <TableRow key={key}>
                <TableCell
                  sx={{
                    width: "40%",
                  }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Text fontWeight="fontWeight.semiBold">
                      {t(`REVIEWER_SETTINGS.${key}`)}
                    </Text>
                    <Tooltip title={t(`REVIEWER_SETTINGS.${tooltip}`)}>
                      <HelpIcon fontSize="small" sx={{ color: "grey.300" }} />
                    </Tooltip>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    width: "50%",
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Switch
                      checked={settings[field]}
                      disabled={isLimitReached}
                      onChange={({ currentTarget }) => {
                        onChange({
                          field,
                          value: currentTarget.checked,
                        });
                      }}
                      inputProps={{
                        "data-testid": `reviewer-settings-${field}-switch`,
                      }}
                    />
                    <IconButton
                      size="small"
                      disableRipple
                      onClick={check && check()}
                      data-testid={`reviewer-settings-uploading-crown-icon-${field}`}
                      sx={{
                        visibility: isLimitReached ? "visible" : "hidden",
                      }}
                    >
                      <IconAsset />
                    </IconButton>
                  </Box>

                  {key === "SECURITY_PASSWORD" && settings[field] && (
                    <Box
                      mt={2}
                      minWidth={220}
                      display="flex"
                      gap={2}
                      p={2.5}
                      sx={{ backgroundColor: "grey.50", borderRadius: 2 }}
                      alignItems="flex-start"
                    >
                      <TextField
                        label={
                          isPasswordSet ? "*******" : t("FORM.PASSWORD.LABEL")
                        }
                        helperText={t("REVIEWER.SETTINGS.ENABLE_PASSWORD_INFO")}
                        disabled={isPasswordSet}
                        variant="outlined"
                        autoFocus
                        size="small"
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                        inputProps={{
                          "data-testid": "password-field",
                        }}
                      />
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (isPasswordSet) {
                            return setIsPasswordSet(false);
                          }
                          onChange({ field: "password", value: password });
                          setPassword("");
                          setIsPasswordSet(true);
                        }}
                        data-testid="password-button"
                      >
                        {isPasswordSet ? t("CORE.RESET") : t("CORE.SAVE")}
                      </Button>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReviewerGroupSecurityTab.propTypes = {
  teamId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    digitalSignatureRequired: PropTypes.bool.isRequired,
    anonymousReviewers: PropTypes.bool.isRequired,
    passwordProtection: PropTypes.bool.isRequired,
    reviewAccessControl: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReviewerGroupSecurityTab;
