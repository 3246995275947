import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { instance as billingService } from "@supporting/services/billingService";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const durationMap = {
  once: "once",
  forever: "forever",
  repeating: "per month ",
};

const durationMonthMaps = {
  1: "for one month",
  0: "",
};

const getCouponDescriptionData = (couponData) => ({
  percentOff: couponData.coupon.percent_off,
  duration: durationMap[couponData.coupon.duration],
  currency: billingService.getCurrencySymbol(couponData.coupon.currency) || "%",
  amountOff: couponData.coupon.amount_off && couponData.coupon.amount_off / 100,
  durationInMonths:
    couponData.coupon.duration_in_months &&
    (durationMonthMaps[couponData.coupon.duration_in_months] ||
      `for ${couponData.coupon.duration_in_months} months`),
});

function CouponDetails({ couponData }) {
  const { t } = useTranslation();

  if (couponData?.isValid) {
    return (
      <Box
        gap={0.5}
        display="flex"
        justifyContent="center"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <Text fontSize={FSTGTypography.fontSize_1_3}>
          {t("BILLING.CURRENT_COUPON.ACTIVE")}:
        </Text>
        <Text fontSize={FSTGTypography.fontSize_1_3}>
          {couponData.coupon.id}
        </Text>
        <Text fontSize={FSTGTypography.fontSize_1_3}>
          (
          {t(
            "BILLING.CURRENT_COUPON.DESCRIPTION_NEW",
            getCouponDescriptionData(couponData)
          )}
          )
        </Text>
      </Box>
    );
  }

  return null;
}

CouponDetails.propTypes = {
  couponData: PropTypes.shape({
    isValid: PropTypes.bool,
    coupon: PropTypes.shape({
      id: PropTypes.string,
      currency: PropTypes.string,
      duration: PropTypes.string,
      amount_off: PropTypes.number,
      percent_off: PropTypes.number,
      duration_in_months: PropTypes.number,
    }),
  }),
};

CouponDetails.defaultProps = {
  couponData: {},
};

export default CouponDetails;
