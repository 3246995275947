import { Trans, useTranslation } from "react-i18next";
import { generatePath, useSearchParams } from "react-router-dom";

import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import { APP_ROUTES } from "@shared/constants/routes";

import AlertIcon from "@assets/img/icons/alert-circle-outline.svg";

const useStyles = makeStyles(() => ({
  descriptionOuterBox: {
    gap: 10,
  },
  actionLinkOutBox: {
    gap: 8,
  },
}));

export default function MondayAppNoFileReview() {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("projectId");

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      bgcolor={theme.color.white}
      alignItems="flex-start"
      p={1.25}
    >
      <Box display="flex" justifyContent="space-between">
        <FilestageResponsiveLogo />
      </Box>
      <Box
        mt={3.5}
        overflow="auto"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <Box minWidth="50%" display="flex" width="100%" justifyContent="center">
          <Box pt={2.875} pr={2.5} pl={2.5} minWidth="50%" bgcolor="#F9FAFB">
            <Box>
              <Box
                pt={2}
                className={classes.descriptionOuterBox}
                display="flex"
                flexDirection="row"
              >
                <Box>
                  <AlertIcon />
                </Box>
                <Box>
                  <Box>
                    <Text
                      variant="body1"
                      fontWeight="bold"
                      translate="MONDAY.NO_REVIEWS.TITLE"
                      color="#1F2937"
                    />
                  </Box>
                  <Box mt={0.5}>
                    <Text variant="subtitle2" color="#4B5563">
                      <Trans
                        components={{
                          anchor: (
                            <Text
                              component="span"
                              fontWeight="bold"
                              variant="subtitle2"
                            />
                          ),
                        }}
                      >
                        {t("MONDAY.NO_REVIEWS.DESCRIPTION")}
                      </Trans>
                    </Text>
                  </Box>
                  <Box
                    mt={2.625}
                    className={classes.actionLinkOutBox}
                    display="flex"
                    flexDirection="column"
                  >
                    <Text variant="body2" fontWeight={700} color="#4B5563">
                      <Link
                        target="_blank"
                        href={generatePath(APP_ROUTES.PROJECT_ID.path, {
                          projectId,
                        })}
                      >
                        {t("MONDAY.NO_REVIEWS.CTA_1")}
                      </Link>
                    </Text>
                    <Text variant="body2" fontWeight={700} color="#4B5563">
                      {t("MONDAY.NO_REVIEWS.OR")}
                    </Text>
                    <Text variant="body2" color="#4B5563">
                      {t("MONDAY.NO_REVIEWS.CTA_2")}
                    </Text>
                  </Box>
                </Box>
              </Box>

              <Box pt={2} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
