import PropTypes from "prop-types";
import { forwardRef } from "react";

import {
  QuestionMark,
  HelpOutlineOutlined as HelpOutlined,
  MoreVertOutlined as MoreOutlined,
} from "@mui/icons-material";
import { IconButton, Fab, Badge, Box } from "@mui/material";

import { Text, Tooltip } from "@shared/UIKit";

import HelpButtonTooltip from "./HelpButtonTooltip";
import ProgressBar from "./ProgressBar";

const HelpButton = forwardRef(
  (
    {
      isMenuOpen,
      showChecklist,
      canExtendTrial,
      isFileViewPage,
      checklistProgress,
      unreadMessagesCount,
      handleHelpMenuClick,
      shouldShowProgressBar,
      shouldShowOnCompletedTooltip,
    },
    ref
  ) => {
    return (
      <>
        {shouldShowProgressBar && showChecklist && !isMenuOpen && (
          <Tooltip
            variant="white"
            title={
              <Box textAlign="left">
                <Text
                  color="grey.700"
                  variant="textSm"
                  fontWeight="fontWeight.bold"
                  translate="CHECKLIST.ONBOARDING_SECTION.LEARN.TITLE"
                />
                <Text
                  color="grey.700"
                  variant="textSm"
                  translate="CHECKLIST.ONBOARDING_SECTION.LEARN.BODY"
                />
              </Box>
            }
          >
            <Box
              py={1}
              px={2}
              mx={1}
              right={80}
              bottom={20}
              zIndex={24}
              width={300}
              boxShadow={2}
              bgcolor="white"
              position="fixed"
              borderRadius={2}
              boxSizing="border-box"
              data-testid="progress-bar"
              onClick={() =>
                handleHelpMenuClick({ currentTarget: ref.current })
              }
            >
              <ProgressBar checklistProgress={checklistProgress} />
            </Box>
          </Tooltip>
        )}
        <HelpButtonTooltip
          isFileViewPage={isFileViewPage}
          canExtendTrial={canExtendTrial}
          shouldShowOnCompletedTooltip={shouldShowOnCompletedTooltip}
        >
          {isFileViewPage ? (
            <IconButton
              onClick={handleHelpMenuClick}
              className="tour__open-help-menu"
              data-testid="help-menu-trigger-button"
              size="medium"
            >
              <>
                <MoreOutlined
                  sx={{ display: { xs: "block", lg: "none" } }}
                  fontSize="inherit"
                />
                <HelpOutlined
                  sx={{ display: { xs: "none", lg: "block" } }}
                  fontSize="inherit"
                  data-testid="help-menu-icon"
                />
              </>
            </IconButton>
          ) : (
            <Fab
              ref={ref}
              onClick={handleHelpMenuClick}
              className="tour__open-help-menu"
              data-testid="help-menu-trigger-button"
              color="primary"
              size="medium"
              sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 24 }}
            >
              <Badge
                color="accent"
                badgeContent={
                  showChecklist && !shouldShowProgressBar
                    ? `${checklistProgress}%`
                    : unreadMessagesCount
                }
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                sx={{
                  ".MuiBadge-badge":
                    showChecklist && !shouldShowProgressBar
                      ? {
                          border: "1px solid white",
                          backgroundColor: "accent.light",
                          backgroundImage: `linear-gradient(to right, #D5B5FF 0 ${checklistProgress}%, transparent ${checklistProgress}% 100%)`,
                          color: "grey.700",
                        }
                      : {},
                }}
              >
                <QuestionMark sx={{ padding: 1 }} />
              </Badge>
            </Fab>
          )}
        </HelpButtonTooltip>
      </>
    );
  }
);

HelpButton.displayName = "HelpButton";

HelpButton.propTypes = {
  canExtendTrial: PropTypes.bool.isRequired,
  showChecklist: PropTypes.bool.isRequired,
  isFileViewPage: PropTypes.bool.isRequired,
  checklistProgress: PropTypes.number.isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
  handleHelpMenuClick: PropTypes.func.isRequired,
  shouldShowOnCompletedTooltip: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  shouldShowProgressBar: PropTypes.bool.isRequired,
};

export default HelpButton;
