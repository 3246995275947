import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  Typography,
} from "@mui/material";

import { Avatar, Text, Tooltip } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";

import SignatureIcon from "@assets/img/icons/signature.svg";

function ReviewDecision({
  reviewer,
  reviewDate,
  digitallySigned,
  openAddReviewerDialog,
  isReadOnly,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(false);
  const openMenu = ({ currentTarget }) => setAnchorEl(currentTarget);

  const closeMenu = () => setAnchorEl(null);

  return (
    <>
      <ListItem
        sx={{
          pl: 0.5,
          "& .MuiListItemSecondaryAction-root": {
            visibility: "hidden",
          },
          "&:hover": {
            backgroundColor: "grey.50",
            borderRadius: 1,
            "& .MuiListItemSecondaryAction-root": {
              visibility: "visible",
            },
          },
          ...(isReadOnly && {
            pointerEvents: "none",
          }),
        }}
        secondaryAction={
          <IconButton
            data-testid="reviewer-menu-button"
            edge="end"
            aria-label="more"
            onClick={openMenu}
          >
            <MoreVertIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <Avatar user={reviewer} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="inherit" component="span" noWrap>
                {reviewer.displayName}
              </Typography>
              {reviewer.youTagEnabled && (
                <Text
                  variant="textXs"
                  color="text.secondary"
                  component="span"
                  data-testid="review-decision-you-tag"
                >
                  {t("CORE.YOU")}
                </Text>
              )}
              {digitallySigned && (
                <Tooltip
                  title={t("VERIFIED_APPROVAL.PROJECT_DASHBOARD_TOOLTIP")}
                >
                  <SvgIcon
                    component={SignatureIcon}
                    inheritViewBox
                    color="approved"
                    fontSize="extraSmall"
                    data-testid="signature-icon"
                  />
                </Tooltip>
              )}
            </Box>
          }
          {...(reviewDate && {
            secondary: formatWithLocale(new Date(reviewDate), "PP p"),
            secondaryTypographyProps: {
              variant: "caption",
              color: "text.secondary",
            },
          })}
        />
      </ListItem>
      {anchorEl && (
        <Menu
          id="reviewer-menu"
          data-testid="reviewer-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            data-testid="resend-invitation"
            onClick={() => {
              closeMenu();
              openAddReviewerDialog(reviewer.email);
            }}
          >
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText>{t("REVIEWER.MENU.RESEND")}</ListItemText>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

ReviewDecision.propTypes = {
  reviewer: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
    youTagEnabled: PropTypes.bool,
    email: PropTypes.string.isRequired,
  }).isRequired,
  reviewDate: PropTypes.string,
  digitallySigned: PropTypes.bool,
  openAddReviewerDialog: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
};

ReviewDecision.defaultProps = {
  reviewDate: null,
  digitallySigned: false,
  isReadOnly: false,
};

export default ReviewDecision;
