import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link as ReactLink, generatePath } from "react-router-dom";

import useKeepInView from "@feedback/hooks/useKeepInView";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, Link } from "@mui/material";
import clsx from "clsx";

import { Image, Text, Tooltip } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { reviewProp } from "@shared/props/review";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import classes from "./FileGalleryReviewItem.module.css";

const FileGalleryReviewItem = ({
  review,
  selected,
  keepInView,
  ...restProps
}) => {
  const { t } = useTranslation();

  const url = generatePath(APP_ROUTES["REVIEW_LINK"].path, {
    stepId: review.stepId,
    reviewId: review.id,
  });

  const handleClick = (event) => {
    if (!event.metaKey && event.button !== 1) {
      if (!selected) {
        eventService.emitEvent({
          eventName: EVENT_NAMES.WORKSPACE.FILE_SWITCHED,
          eventData: {},
        });
        analytics.track(
          analytics.ACTION.CLICKED,
          analytics.CATEGORY.FILE_GALLERY_FILE
        );
      }
    }
  };

  const ref = useKeepInView({
    selected,
    keepInView,
  });

  return (
    <Link to={url} onClick={handleClick} component={ReactLink}>
      <Box
        id={`file-gallery-review-item-${review.id}`}
        className={clsx(classes.container, {
          [classes.selected]: selected,
        })}
        ref={ref}
        bgcolor="white"
        minWidth={136}
        width={136}
        height={116}
        borderRadius="4px"
        p={0.5}
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        data-testid="file-gallery-item"
        {...restProps}
      >
        <Box overflow="hidden" width="100%" height={72} bgcolor="grey.200">
          {review.thumbnail ? (
            <Image
              src={review.thumbnail.url}
              alt={review.thumbnail.name}
              width="100%"
              height={72}
              className={classes.thumbnailImage}
            />
          ) : (
            <Box
              width="100%"
              height={72}
              className={classes.thumbnailImage}
              data-testid="empty-review-thumbnail"
            />
          )}
        </Box>

        <Box
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <Tooltip title={review.file.name}>
            <Text
              variant="textXs"
              textAlign="center"
              color="grey.700"
              className={classes.fileName}
            >
              {review.file.name}
            </Text>
          </Tooltip>
        </Box>
        {review.isPendingYourReview && (
          <Tooltip title={t("FILE_GALLERY.AWAITING_REVIEW_BADGE")}>
            <Box
              bgcolor="var(--mui-color-orange-900)"
              color="white"
              display="flex"
              alignItems="center"
              justifyContent="center"
              data-testid="pending-review-badge"
              position="absolute"
              left={10}
              top={-9}
              borderRadius="4px"
              border="1px solid white"
            >
              <PriorityHighIcon fontSize="inherit" />
            </Box>
          </Tooltip>
        )}
      </Box>
    </Link>
  );
};

FileGalleryReviewItem.propTypes = {
  review: reviewProp.isRequired,
  keepInView: PropTypes.bool,
  selected: PropTypes.bool,
};

FileGalleryReviewItem.defaultProps = {
  selected: false,
  keepInView: false,
};

export default FileGalleryReviewItem;
