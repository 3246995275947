import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Box, LoadingSpinner } from "@shared/UIKit";

import { fileProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import FSTGTypography from "@shared/theme/typography";

import Activity from "../activity/Activity";
import NotificationGroupHeader from "./header/NotificationGroupHeader";

const useStyles = makeStyles((theme) => ({
  group: {
    "&:hover": {
      boxShadow: theme.shadow["box-shadow-z3"],
    },
  },
}));

function NotificationGroup({
  id,
  project,
  step,
  file,
  fileVersion: review,
  notifications,
  hasMoreNotifications,
  isRead,
  updateNotificationGroupIsRead,
  unread,
  beforeDate,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation();
  const [allNotifications, setAllNotifications] = useState(notifications);
  const [hasMore, setHasMore] = useState(hasMoreNotifications);
  const [isLoading, setIsLoading] = useState(false);

  const viewMoreHandler = useCallback(async () => {
    setIsLoading(true);
    const { notifications, hasMoreNotifications } = await backend.get(
      `/in-app-notifications/${id}/notifications`,
      {
        unread,
        beforeDate,
        skip: allNotifications.length,
        limit: 3,
      }
    );
    setAllNotifications([...notifications, ...allNotifications]);
    setHasMore(hasMoreNotifications);
    setIsLoading(false);
    analytics.track(
      analytics.ACTION.VIEWED_MORE_ACTIVITIES,
      analytics.CATEGORY.NOTIFICATION_CENTER_NOTIFICATIONS
    );
  }, [allNotifications, beforeDate, id, unread]);

  return (
    <Box
      px={2.25}
      py={1.5}
      borderRadius="3px"
      bgcolor="white"
      m={0.5}
      mb={0.75}
      className={classes.group}
      boxShadow={theme.shadow["box-shadow-notification-group"]}
      data-testid="notification-group"
    >
      <NotificationGroupHeader
        id={id}
        project={project}
        step={step}
        file={file}
        review={review}
        isRead={isRead}
        updateNotificationGroupIsRead={updateNotificationGroupIsRead}
      />

      {hasMore && !isLoading && (
        <Box mb={1.5}>
          <Link
            fontSize={FSTGTypography.fontSize_1_2}
            onClick={viewMoreHandler}
            data-testid="notification-group-view-more"
          >
            {t("CORE.VIEW_MORE")}
          </Link>
        </Box>
      )}
      {isLoading && (
        <Box mb={1.5}>
          <LoadingSpinner />
        </Box>
      )}

      {allNotifications.map((notification) => (
        <Activity
          {...notification}
          key={notification.id}
          projectId={project.id}
          stepId={step && step.id}
          fileId={file && file.id}
          reviewId={review && review.id}
          groupId={id}
        />
      ))}
    </Box>
  );
}

NotificationGroup.propTypes = {
  id: PropTypes.string.isRequired,
  team: teamProp.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  step: stepProps,
  file: fileProp,
  fileVersion: reviewProp,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMoreNotifications: PropTypes.bool.isRequired,
  isRead: PropTypes.bool.isRequired,
  updateNotificationGroupIsRead: PropTypes.func.isRequired,
  unread: PropTypes.bool.isRequired,
  beforeDate: PropTypes.string.isRequired,
};

NotificationGroup.defaultProps = {
  step: null,
  file: null,
  fileVersion: null,
};

export default NotificationGroup;
