import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import SearchSharp from "@mui/icons-material/SearchSharp";
import { Icon, SvgIcon } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import classNames from "classnames";

import { Box, Text } from "@shared/UIKit";

import { useDialogState, useMediaQuery } from "@shared/hooks";
import FSTGTypography from "@shared/theme/typography";

import RegisterInterestDialog from "./RegisterInterestDialog/RegisterInterestDialog";

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      border: `1px solid ${theme.color.gray[400]}`,
    },
  },
  badgeIconWrapper: {
    width: 15,
  },
  badgeIcon: {
    width: 12,
  },
  badge: {
    height: 24,
    top: -12,
    right: 8,
    borderRadius: 50,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1.5),
  },
  interest: {
    background: theme.color.blue[50],
    color: theme.color.blue[500],
  },
}));

function AIDialogCategoryCard({ aiTitle, aiDescription, eventMeta }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { isOpen, closeDialog, openDialog } = useDialogState();

  return (
    <>
      <Box
        onClick={openDialog}
        data-testid={`ai-card-${aiTitle}`}
        minHeight={98}
        width={isMobile ? "100%" : "30%"}
        maxWidth={isMobile ? "100%" : "30%"}
        borderRadius="4px"
        boxSizing="border-box"
        minWidth={200}
        display="flex"
        background={theme.color.white}
        border={`1px solid ${theme.color.gray[200]}`}
        padding={theme.spacing(2, 1.5)}
        flexDirection="column"
        alignItems="flex-start"
        color={theme.color.black}
        position="relative"
        className={classes.container}
      >
        <Box
          data-testid="ai-card-badge"
          className={classNames(classes.badge, classes.interest)}
        >
          <Icon size="small" className={classes.badgeIconWrapper}>
            <SvgIcon component={SearchSharp} className={classes.badgeIcon} />
          </Icon>
          <Text fontSize={FSTGTypography.fontSize_1_2}>
            {t("CORE.REGISTER_INTEREST")}
          </Text>
        </Box>

        <Box textAlign="left" data-testid="ai-title">
          <Text
            variant="body2"
            fontWeight={FSTGTypography.fontWeight.semiBold}
            fontSize={FSTGTypography.fontSize_1_5}
            lineHeight={FSTGTypography.lineHeight_1_5}
          >
            {t(aiTitle)}
          </Text>
        </Box>
        <Box textAlign="left" data-testid="ai-description" mt={1}>
          <Text
            variant="subtitle1"
            color={theme.color.gray[700]}
            fontSize={FSTGTypography.fontSize_1_2}
            lineHeight={FSTGTypography.lineHeight_1_5}
          >
            {t(aiDescription)}
          </Text>
        </Box>
      </Box>
      {isOpen && (
        <RegisterInterestDialog
          eventMeta={eventMeta}
          closeDialog={closeDialog}
        />
      )}
    </>
  );
}

AIDialogCategoryCard.propTypes = {
  aiTitle: PropTypes.string.isRequired,
  aiDescription: PropTypes.string.isRequired,
  eventMeta: PropTypes.string.isRequired,
};

export default AIDialogCategoryCard;
