// istanbul ignore file
export const FOLDER_KEYS = {
  all: ["FOLDER"],
  lists: (teamId) => [...FOLDER_KEYS.all, "list", teamId],
  list: (filters) => [...FOLDER_KEYS.lists(), { filters }],
  details: () => [...FOLDER_KEYS.all, "detail"],
  detail: (id) => [...FOLDER_KEYS.details(), id],
};

export const TEAM_KEYS = {
  all: ["TEAM"],
  detail: (teamId) => [...TEAM_KEYS.all, teamId],
  metric: (teamId, type) => [...TEAM_KEYS.detail(teamId), "METRIC", type],
};

export const BULK_DOWNLOAD_KEYS = {
  all: ["BULK_DOWNLOAD"],
  detail: (processId) => [...BULK_DOWNLOAD_KEYS.all, processId],
};

export const SEARCH_KEYS = {
  all: ["SEARCH"],
  recent: () => ["RECENT_SEARCHES"],
  detail: ({ query, resource }) => [
    ...SEARCH_KEYS.all,
    { query, resource, type: "DETAIL" },
  ],
  overview: ({ query, resource }) => [
    ...SEARCH_KEYS.all,
    { query, resource, type: "OVERVIEW" },
  ],
};

export const PENDING_REVIEWS_KEYS = {
  all: ["PENDING_REVIEWS"],
  count: () => [...PENDING_REVIEWS_KEYS.all, "count"],
  list: () => [...PENDING_REVIEWS_KEYS.all, "list"],
  sort: (sort) => [...PENDING_REVIEWS_KEYS.list(), { sort }],
};
