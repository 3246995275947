import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ReviewDecisionButtonMenu from "@feedback/components/ReviewDecisionButtons/ReviewDecisionButtonMenu";
import { REVIEW_DECISION_STATES_TO_ACTIONS } from "@feedback/constants/reviewDecisionState";
import { getEnabledReviewDecisionOptions } from "@feedback/helpers/getEnabledReviewDecisionOptions";
import reviewService from "@feedback/services/reviewService";
import { Check, Close, Replay } from "@mui/icons-material";
import { Button, IconButton } from "@mui/material";
import ReviewerAuthenticationFlowDialog from "@supporting/components/ReviewersAuthenticationFlow/ReviewerAuthenticationFlowDialog";
import { instance as teamService } from "@supporting/services/team";

import { Box, LoadingSpinner, Text } from "@shared/UIKit";

import { useDialogState, useQueryParams } from "@shared/hooks";
import { teamProp } from "@shared/props/team";
import errorHandler from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import DigitalSignatureDialog from "@feedback/pages/DigitalSignatureDialog/DigitalSignatureDialog";

import VerifyApprovalIcon from "@assets/img/icons/verify-approval.svg";

const BatchReviewDecisionBar = ({
  selectedReviews,
  clearSelection,
  currentUser,
  authDialogContext,
  isDemoProject,
  team,
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [otpErrorText, setOtpErrorText] = useState("");
  const {
    isOpen: isOtpDialogOpen,
    openDialog: openOtpDialog,
    closeDialog: closeOtpDialog,
  } = useDialogState();
  const [searchParams, setSearchParams] = useQueryParams();

  const pendingDigitalSignatureParam = searchParams.get(
    "pendingDigitalSignature"
  );

  useEffect(() => {
    if (pendingDigitalSignatureParam === "true") {
      openOtpDialog();
    }
  }, [pendingDigitalSignatureParam, openOtpDialog]);

  const disablePendingSignatureParam = () => {
    setSearchParams({ pendingDigitalSignature: null });
  };

  const submitBatchDecision = async (action, code) => {
    try {
      await reviewService.submitReviewDecision(
        selectedReviews,
        action,
        isDemoProject,
        code
      );
      setIsSubmitting(false);
      clearSelection();
      eventService.emitEvent({
        eventName: EVENT_NAMES.REVIEW.DECISIONS.SUBMITTED,
        eventData: {
          reviewIds: selectedReviews,
          action,
        },
      });
    } catch (error) {
      if (code && error?.message === "Invalid OTP") {
        setOtpErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCORRECT_CODE"));
      } else {
        if (isOtpDialogOpen) {
          closeOtpDialog();
        }

        errorHandler.handleError(error);
      }
    }
  };

  const onAuthCanceled = () => {
    setIsSubmitting(false);
  };

  const finalizeReview = (action) => async () => {
    const selectedTeam = await teamService.fetchTeamByTeamId(
      authDialogContext.teamId
    );
    setIsSubmitting(true);

    const onAuthComplete = async () => {
      if (
        action === REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED &&
        authDialogContext.digitalSignatureRequired
      ) {
        openOtpDialog();
      } else {
        await submitBatchDecision(action);
      }
    };

    if (
      !currentUser.isInfoComplete ||
      (selectedTeam.settings?.verifyReviewerEmailSetting &&
        !currentUser.isEmailVerified)
    ) {
      NiceModal.show(ReviewerAuthenticationFlowDialog, {
        action,
        onCancel: onAuthCanceled,
        context: {
          ...authDialogContext,
          reviewIds: selectedReviews,
        },
        hide: onAuthComplete,
      });
      return;
    }

    if (
      action === REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED &&
      authDialogContext.digitalSignatureRequired
    ) {
      openOtpDialog();
      return;
    }

    return await submitBatchDecision(action);
  };

  const submitBatchVerifiedApprovals = async (code) => {
    await submitBatchDecision(REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED, code);
    disablePendingSignatureParam();
  };

  const reviewDecisionOptions = getEnabledReviewDecisionOptions(team);

  return (
    <>
      <Box
        width="fit-content"
        py={1}
        px={2}
        mx="auto"
        borderRadius="8px"
        display="flex"
        alignItems="center"
        bgcolor="grey.800"
        position="absolute"
        left={0}
        right={0}
        bottom={24}
        zIndex={1}
        gap={1}
        data-testid="review-decision-bar"
      >
        {reviewDecisionOptions.length > 2 ? (
          <ReviewDecisionButtonMenu
            reviewDecisionOptions={reviewDecisionOptions}
            finalizeReviewDecision={finalizeReview}
            isSubmitting={isSubmitting}
            isDigitalSignatureRequired={
              authDialogContext.digitalSignatureRequired
            }
            isBatchReview
          />
        ) : (
          <>
            <Button
              color="needsChanges"
              variant="contained"
              size="small"
              data-testid="decline-button"
              startIcon={<Replay />}
              onClick={finalizeReview(
                REVIEW_DECISION_STATES_TO_ACTIONS.NEEDS_CHANGES
              )}
              disabled={isSubmitting}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "var(--mui-palette-needsChanges-light)",
                },
              }}
            >
              {t("REVIEW_BAR.REQUEST_CHANGES_BUTTON")}
            </Button>

            <Text variant="textSm" color="white">
              {t("CORE.OR")}
            </Text>

            <Button
              data-testid="approve-button"
              color="approved"
              variant="contained"
              size="small"
              startIcon={
                authDialogContext.digitalSignatureRequired ? (
                  <VerifyApprovalIcon />
                ) : (
                  <Check />
                )
              }
              onClick={finalizeReview(
                REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED
              )}
              disabled={isSubmitting}
              sx={{
                "&.Mui-disabled": {
                  backgroundColor: "var(--mui-palette-approved-light)",
                },
              }}
            >
              {t(
                authDialogContext.digitalSignatureRequired
                  ? "VERIFIED_APPROVAL.CTA_PRIMARY"
                  : "CORE.APPROVE"
              )}
            </Button>
          </>
        )}

        <Text
          variant="textSm"
          color="white"
          fontWeight="medium"
          data-testid="selected-reviews"
        >
          {selectedReviews.length > 1
            ? t("BATCH_REVIEW.FILES_SELECTED", {
                fileCount: selectedReviews.length,
              })
            : t("BATCH_REVIEW.FILE_SELECTED_SINGLE")}
        </Text>

        {isSubmitting ? (
          <LoadingSpinner />
        ) : (
          <IconButton
            onClick={clearSelection}
            data-testid="clear-selection-button"
            size="large"
            color="light"
          >
            <Close />
          </IconButton>
        )}
      </Box>

      <DigitalSignatureDialog
        isOpen={isOtpDialogOpen}
        email={currentUser.email || ""}
        errorText={otpErrorText}
        setErrorText={setOtpErrorText}
        cancel={() => {
          closeOtpDialog();
          disablePendingSignatureParam();
        }}
        submitVerifiedApproval={submitBatchVerifiedApprovals}
      />
    </>
  );
};

BatchReviewDecisionBar.propTypes = {
  selectedReviews: PropTypes.arrayOf(PropTypes.string).isRequired,
  isDemoProject: PropTypes.bool.isRequired,
  clearSelection: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    email: PropTypes.string.isRequired,
    isInfoComplete: PropTypes.bool.isRequired,
    isEmailVerified: PropTypes.bool.isRequired,
  }).isRequired,
  authDialogContext: PropTypes.shape({
    stepId: PropTypes.string.isRequired,
    teamId: PropTypes.string.isRequired,
    digitalSignatureRequired: PropTypes.bool.isRequired,
  }).isRequired,
  team: teamProp.isRequired,
};

export default BatchReviewDecisionBar;
