import PropTypes from "prop-types";
import { useCallback, useRef, useImperativeHandle, forwardRef } from "react";

import { useTheme } from "@mui/material/styles";

import FSTGTypography from "@shared/theme/typography";

const StripeInput = forwardRef(({ component: Component, ...props }, ref) => {
  const elementRef = useRef();
  const theme = useTheme();

  const style = {
    base: {
      fontFamily: FSTGTypography.fontFamily,
      fontSize: "17px",
      color: theme.color.gray[900],
      border: `1px solid ${theme.color.gray[500]}`,
      "::placeholder": {
        color: "transparent",
      },
      ":focus::placeholder": {
        color: theme.color.gray[50],
      },
    },
  };

  const StripeElementsStyles = {
    iconStyle: "solid",
    style,
  };

  /* istanbul ignore next */
  const focusCallback = useCallback(
    () => ({
      focus: () => elementRef.current && elementRef.current.focus,
    }),
    []
  );
  /* istanbul ignore next */
  const onComponentReady = useCallback(
    (element) => (elementRef.current = element),
    []
  );
  useImperativeHandle(ref, focusCallback);

  return (
    <Component
      onReady={onComponentReady}
      options={StripeElementsStyles}
      {...props}
    />
  );
});
StripeInput.displayName = "StripeInput";
StripeInput.propTypes = {
  component: PropTypes.elementType.isRequired,
};
export default StripeInput;
