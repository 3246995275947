import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Fade } from "@mui/material";
import maxBy from "lodash/maxBy";

import routeStates from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { fileProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";

function NewReviewAlert({ review, reviews, file }) {
  const { t } = useTranslation();
  const { buildUrl } = useNavigation();
  const [isHovered, setIsHovered] = useState(false);

  const latestReview = maxBy(
    reviews.filter((filteredReview) => filteredReview.stepId === review.stepId),
    (review) => {
      const version = file.versions.find(
        (fileVersion) => fileVersion.id === review.versionId
      );
      return version.number;
    }
  );

  const isNewerReviewAvailable = review.id !== latestReview?.id;

  if (!isNewerReviewAvailable) {
    return null;
  }

  return (
    <Fade in>
      <Button
        color="accent"
        variant="highlight"
        size="extraSmall"
        component={Link}
        to={buildUrl(routeStates.REVIEW_LINK, {
          params: {
            stepId: latestReview.stepId,
            reviewId: latestReview.id,
            comment: null,
          },
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{ minWidth: 222, textWrap: "nowrap" }}
      >
        {t(isHovered ? "FILE.VERSION.CLICK_TO_OPEN" : "FILE.VERSION.ALERT")}
      </Button>
    </Fade>
  );
}

NewReviewAlert.propTypes = {
  review: reviewProp.isRequired,
  reviews: PropTypes.arrayOf(reviewProp).isRequired,
  file: fileProp.isRequired,
};

export default NewReviewAlert;
