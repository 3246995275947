import { useTranslation } from "react-i18next";

import { SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import NotifyIcon from "@assets/img/icons/notifications_off_black_24dp.svg";

const useStyles = makeStyles((theme) => ({
  projectTemplateHelpText: {
    display: "flex",
    color: theme.color.gray["600"],
    backgroundColor: theme.color["white-smoke-translucent"],
    padding: 5,
    textAlign: "left",
  },
  notifyIcon: {
    marginTop: 3,
    paddingRight: 10,
    height: 24,
    width: 24,
    minHeight: 24,
    minWidth: 24,
  },
}));

const ProjectTemplateHelper = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box
      data-testid="create-project-dialog-template-helper"
      placement="bottom-end"
      className={classes.projectTemplateHelpText}
    >
      <SvgIcon
        component={NotifyIcon}
        className={classes.notifyIcon}
        fontSize="small"
        data-testid="notify-icon"
      />
      <Text>{t("PROJECT.ADD.NOTIFY-MESSAGE")}</Text>
    </Box>
  );
};

export default ProjectTemplateHelper;
