import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Dialog, Text, Image } from "@shared/UIKit";

import SpriteMain from "@assets/img/sprite-source/sprite--collaborator.png";

function ResumeSubscriptionDialog({ isOpen, cancel, answer }) {
  const { t } = useTranslation();

  return (
    <Dialog
      cancel={cancel}
      open={isOpen}
      maxWidth="md"
      actions={
        <Button
          onClick={answer}
          color="primary"
          variant="contained"
          size="large"
          data-testid="resume-subscription-confirm"
        >
          {t("BILLING.RESUME_SUBSCRIPTION.CONFIRM")}
        </Button>
      }
      hideBackdrop={false}
      title={t("BILLING.RESUME_SUBSCRIPTION.HEADLINE")}
      data-testid="resume-subscription-dialog"
      disableBackdropClick
    >
      <Image sx={{ width: "20%" }} src={SpriteMain} alt="subscription" />
      <Text sx={{ mt: 2 }}>{t("BILLING.RESUME_SUBSCRIPTION.DESCRIPTION")}</Text>
    </Dialog>
  );
}

ResumeSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
};

export default ResumeSubscriptionDialog;
