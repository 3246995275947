import PropTypes from "prop-types";

import TeamLogo from "@supporting/components/TeamLogo/TeamLogo";

import NavBar from "./NavBar";
import NavigationWorkspace from "./NavigationWorkspace";

function NavigationWorkspaceWrapper({ wasDataLoaded, ...restProps }) {
  return wasDataLoaded ? (
    <NavigationWorkspace {...restProps} />
  ) : (
    <NavBar>
      <TeamLogo
        logoUrl={
          restProps.team?.subscriptionLimits.isBrandingEnabled
            ? restProps.team?.branding.logo.retina?.url
            : null
        }
      />
    </NavBar>
  );
}

NavigationWorkspaceWrapper.propTypes = {
  wasDataLoaded: PropTypes.bool,
};

NavigationWorkspaceWrapper.defaultProps = {
  wasDataLoaded: false,
};

export default NavigationWorkspaceWrapper;
