import { useTranslation } from "react-i18next";

import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import WizardBase from "@supporting/components/WizardBase/WizardBase";

import { Box, Text } from "@shared/UIKit";

import getPlugin from "@shared/helpers/getPlugin";

import SuccussIcon from "@assets/img/icons/success-icon.svg";

const PLUGIN_NAMES = {
  [PLUGIN_TYPES.PREMIERE]: "Adobe Premiere Pro",
  [PLUGIN_TYPES.INDESIGN]: "Adobe InDesign",
  [PLUGIN_TYPES.AFTER_EFFECTS]: "Adobe After Effects",
};

export default function AdobePluginLoginSuccessful() {
  const { t } = useTranslation();

  const plugin = getPlugin();

  return (
    <WizardBase variant="LOGIN">
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="row"
        data-testid="adobe-plugin-login-success-page"
      >
        <Box flex="1 1 100%" display="flex" flexDirection="column">
          <Box
            gap={8}
            display="flex"
            flex="1 0 auto"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <SuccussIcon />
            <Box
              gap={2}
              display="flex"
              alignItems="center"
              flexDirection="column"
            >
              <Text
                variant="text3xl"
                fontWeight="fontWeight.medium"
                translate="AUTH.LOGIN.SUCCESS.TITLE"
              />
              <Text color="grey.600" variant="textMd">
                {t("AUTH.LOGIN.SUCCESS.DESCRIPTION", {
                  pluginName: PLUGIN_NAMES[plugin] ?? "plugin",
                })}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </WizardBase>
  );
}
