import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import ArrowLeftIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  Link,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";

import { Box, Text, Image } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";
import useMediaQuery from "@shared/hooks/useMediaQuery";
import useNavigation from "@shared/hooks/useNavigation";
import { instance as analytics } from "@shared/services/analytics";

import classes from "./IntegrationDetailsSidebar.module.css";
import IntegrationDisconnectDialog from "./IntegrationDisconnectDialog";
import IntegrationOauthEnabledStatus from "./IntegrationsOauthenabledStatus";

function IntegrationDetailsSidebar({ integration, connected }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { goTo } = useNavigation();

  const { userId } = authenticationService.fetchSession();
  const { _id: teamId } = teamService.getSelectedTeam();

  const integrationsService = integration.appService;

  const handleShowMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleHideMenu = () => {
    setMenuAnchorEl(null);
  };
  const navigateToAppList = () => {
    goTo(ROUTE_STATES.INTEGRATIONS);
  };

  const handleOpenDisconnectDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDisconnectDialog = () => {
    handleHideMenu();
    setOpenDialog(false);
  };
  const handleInitializeOauth = useCallback(() => {
    const oauthUrl = integrationsService.generateOauthUrl(userId, teamId);

    window.location.assign(oauthUrl);
  }, [userId, teamId, integrationsService]);

  const handleFreelancersClick = () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.ZAPIER_TEMPLATES.GET_UPWORK_FREELANCER
    );
    window.open(t("ZAPIER.UPWORK.LINK"), "_blank");
  };

  const menuLinks = [
    {
      href: "learnMore",
      label: "CORE.LEARN_MORE",
    },
    ...(connected
      ? [
          {
            label: "APPS.DISCONNECT",
            handleClick: handleOpenDisconnectDialog,
            otherProps: { "data-accent-color": "error", color: "error" },
          },
        ]
      : []),
    {
      href: "privacyPolicy",
      label: "INTEGRATIONS.ACTIONS.PRIVACY_POLICY",
    },
    {
      href: "https://help.filestage.io/en/articles/6348843-create-automations-for-your-projects",
      label: "INTEGRATIONS.ACTIONS.AUTOMATIONS",
    },
  ];

  return (
    <Box className={classes.sidebar}>
      <Box
        display="flex"
        alignItems={{ xs: "center", sm: "stretch" }}
        justifyContent="space-between"
        flexDirection={{ xs: "row", sm: "column" }}
        flexGrow={{ xs: 1, sm: "unset" }}
        gap={3}
      >
        {!isMobile && (
          <Box>
            <Button
              startIcon={<ArrowLeftIcon />}
              color="default-light"
              to={APP_ROUTES.INTEGRATIONS.path}
              component={RouterLink}
            >
              {t("APPS.BROWSE_APPS")}
            </Button>
          </Box>
        )}

        <Box display="flex" alignItems="center">
          {isMobile && (
            <>
              <IconButton
                onClick={navigateToAppList}
                data-testid="integrations-list-link"
              >
                <ArrowLeftIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={handleHideMenu}
                data-testid={`integrations-menu-items${
                  menuAnchorEl ? "-show" : ""
                }`}
              >
                {menuLinks.map((menuLink) => (
                  <MenuItem
                    key={menuLink.label}
                    href={menuLink.href}
                    onClick={menuLink.handleClick}
                    component={Link}
                    data-testid={`integration-menu-item-${menuLink.label}`}
                    {...(menuLink.otherProps && menuLink.otherProps)}
                  >
                    {t(menuLink.label)}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}

          <Box display="flex" alignItems="center" gap={1}>
            <Image
              alt={integration.title}
              src={integration.logoUrl}
              sx={{
                height: { xs: 20, sm: 40 },
                width: { xs: 20, sm: 40 },
              }}
            />
            <Text variant="body1" fontWeight="fontWeight.medium">
              {integration.title}
            </Text>
          </Box>
        </Box>

        <IntegrationOauthEnabledStatus
          connected={connected}
          integration={integration}
          handleInitializeOauth={handleInitializeOauth}
        />

        {integration.id === "zapier" && !isMobile && (
          <Paper
            elevation={1}
            borderRadius={1}
            sx={{ px: 2.5, py: 2, mt: 2 }}
            data-testid="integration-zapier-box"
          >
            <Text
              color="text.secondary"
              translate="APPS.ZAPIER_TEMPLATE.NOTE_1"
              fontWeight="fontWeight.medium"
            />
            <ul>
              <li key={0}>
                <Link
                  target="_blank"
                  onClick={handleFreelancersClick}
                  color="primary"
                  fontWeight="fontWeight.medium"
                  data-testid="freelancer-link"
                >
                  {t("APPS.ZAPIER_TEMPLATE.NOTE_3")}
                </Link>{" "}
                <Chip
                  color="primary"
                  variant="filled"
                  label={t("CORE.NEW")}
                  size="small"
                />
              </li>
            </ul>
          </Paper>
        )}
      </Box>
      {isMobile && (
        <IconButton
          data-testid="integration-show-menu"
          onClick={handleShowMenu}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      {!isMobile && (
        <Box
          display="flex"
          flexDirection="column"
          mb={15}
          gap={1}
          data-testid="integrations-menu-items"
        >
          {menuLinks.map((menuLink) => (
            <Link
              onClick={menuLink.handleClick}
              href={
                integration.descriptionPage?.links[menuLink.href] ||
                menuLink.href
              }
              color="default-light"
              fontWeight="fontWeight.medium"
              variant="textSm"
              key={menuLink.label}
              {...(menuLink.otherProps && menuLink.otherProps)}
            >
              {t(menuLink.label)}
            </Link>
          ))}
        </Box>
      )}
      {connected && (
        <IntegrationDisconnectDialog
          open={openDialog}
          onClose={handleCloseDisconnectDialog}
          integration={integration}
        />
      )}
    </Box>
  );
}

IntegrationDetailsSidebar.propTypes = {
  connected: PropTypes.bool,
  integration: PropTypes.object.isRequired,
};

IntegrationDetailsSidebar.defaultProps = {
  connected: false,
};

export default IntegrationDetailsSidebar;
