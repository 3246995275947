import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  MenuItem,
  TextField,
} from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import userService from "@supporting/services/userService";
import capitalize from "lodash/capitalize";
import { z } from "zod";

import { Box, Text } from "@shared/UIKit";

import LANGUAGES from "@shared/constants/languages";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as localisationService } from "@shared/services/localisationService";

import ChangeEmailDialog from "./ChangeEmailDialog/ChangeEmailDialog";

const profileSchema = z.object({
  profileName: z.string().min(1, "VALIDATIONS.PROFILE_NAME.REQUIRED"),
  language: z.enum(Object.values(LANGUAGES)),
});

const EditProfile = () => {
  const { t } = useTranslation();

  const user = authenticationService.fetchUser();

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    reset,
  } = useForm({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      profileName: user.fullName,
      language: user.language || localisationService.getLanguage(),
    },
    mode: "onChange",
  });

  const identity = authenticationService.fetchSession().identity;

  const identityName = capitalize(identity?.name);

  const [isOpen, setIsOpen] = useState(false);

  const submitProfile = async ({ profileName, language }) => {
    try {
      await userService.update({ fullName: profileName, language });
      await localisationService.setLanguage(language);
      toastService.sendToast({
        title: "SETTINGS.PROFILE.UPDATE.SUCCESS.TITLE",
        body: "SETTINGS.PROFILE.UPDATE.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
      reset({ profileName, language });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const updateEmail = () => setIsOpen(true);

  /* istanbul ignore next */
  const closeDialog = () => setIsOpen(false);

  return (
    <>
      <Card data-testid="user-settings-profile-tab">
        <CardHeader title={t("SETTINGS.PROFILE.TITLE")} />
        <CardContent>
          <Box
            component="form"
            id="profile-form"
            display="flex"
            flexDirection="column"
            gap={2}
            onSubmit={handleSubmit(submitProfile)}
          >
            <Controller
              control={control}
              name="profileName"
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  label={t("FORM.NAME.LABEL")}
                  type="text"
                  inputProps={{
                    "data-testid": "settings-edit-profile-name",
                  }}
                  error={Boolean(errors.profileName)}
                  helperText={t(errors.profileName?.message)}
                />
              )}
            />
            <Controller
              control={control}
              name="language"
              render={({ field }) => (
                <TextField
                  select
                  {...field}
                  label={t("SETTINGS.LANGUAGE.TITLE")}
                  inputProps={{
                    "data-testid": "settings-edit-profile-language",
                  }}
                  error={Boolean(errors.language)}
                  helperText={t(errors.language?.message)}
                >
                  <MenuItem value="en">
                    {t("SETTINGS.LANGUAGE.ENGLISH")}
                  </MenuItem>
                  <MenuItem value="de">
                    {t("SETTINGS.LANGUAGE.GERMAN")}
                  </MenuItem>
                  <MenuItem value="fr">
                    {t("SETTINGS.LANGUAGE.FRENCH")}
                  </MenuItem>
                  <MenuItem value="es">
                    {t("SETTINGS.LANGUAGE.SPANISH")}
                  </MenuItem>
                </TextField>
              )}
            />
          </Box>
          <Box display="flex" mt={4} flexDirection="row-reverse">
            <Button
              disabled={!isDirty || !isValid}
              variant="contained"
              color="primary"
              type="submit"
              form="profile-form"
              data-testid="settings-edit-profile-submit"
            >
              {t("SETTINGS.PROFILE.SUBMIT")}
            </Button>
          </Box>
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <Text color="primary" translate="FORM.EMAIL.LABEL" />
              <Text
                id="settings-email"
                data-testid="settings-edit-profile-email"
                variant="textMd"
              >
                {user.email}
              </Text>
            </Box>
            {!identity?.isExternal && (
              <Button
                variant="text"
                color="primary"
                onClick={updateEmail}
                underline="none"
              >
                {t("SETTINGS.PROFILE.CHANGE_EMAIL_ADDRESS")}
              </Button>
            )}
          </Box>
          {identity?.isExternal && (
            <Text
              variant="textSm"
              color="text.secondary"
              data-testid="settings-edit-email-disabled"
            >
              {t("SETTINGS.PROFILE.CANNOT_EDIT_EMAIL_OAUTH", {
                identityName,
              })}
            </Text>
          )}
        </CardContent>
      </Card>
      <ChangeEmailDialog open={isOpen} closeDialog={closeDialog} />
    </>
  );
};

export default EditProfile;
