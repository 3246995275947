import { useTranslation } from "react-i18next";

import {
  adaptPlanName,
  getLimitObject,
} from "@supporting/components/PlanLimitCard/planLimit.helper";
import useProjectSteps from "@workflow/hooks/useProjectSteps";

import { RelativeDate, Text } from "@shared/UIKit";

const MILLISECONDS_PER_SECOND = 1000;

function useLimitComponents({ team, project }) {
  const { t } = useTranslation();
  const [steps] = useProjectSteps(project?.id, false);
  const adaptedPlanName = adaptPlanName(
    team.subscription.basePrice.name,
    team.subscription.basePrice.description
  );
  let limitComponents;
  if (team.subscription.status === "trialing") {
    limitComponents = [
      <Text
        className="limit-component"
        variant="subtitle2"
        key="BILLING.UPGRADE_BAR.TRIAL_ENDS"
      >
        {t("BILLING.UPGRADE_BAR.TRIAL_ENDS")}{" "}
        <RelativeDate
          component="span"
          fontSize="subtitle2.fontSize"
          color="inherit"
          date={
            new Date(team.subscription.trialEndDate * MILLISECONDS_PER_SECOND)
          }
        />
      </Text>,
    ];
  } else {
    limitComponents = getLimitObject(
      adaptedPlanName.toLowerCase(),
      team.subscriptionLimits,
      {
        ...team.usedBillingLimits,
        numberOfProjectStep: steps?.length,
        members: team.members.length,
        projectAutomation: project?.automations?.length,
      }
    ).reduce((limitComponents, limit) => {
      if (!limit) {
        return limitComponents;
      }
      const { translationKey, currentLimit, maxLimit } = limit;
      limitComponents.push(
        <Text
          className="limit-component"
          variant="subtitle2"
          key={translationKey}
        >
          {t(translationKey, { currentLimit, maxLimit })}
        </Text>
      );
      return limitComponents;
    }, []);
  }

  return {
    limitComponents,
  };
}

export default useLimitComponents;
