import PropTypes from "prop-types";
import { memo, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import useKeepInView from "@feedback/hooks/useKeepInView";
import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Checkbox } from "@mui/material";

import { Image, Text, Tooltip } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { APP_ROUTES } from "@shared/constants/routes";
import { reviewProp } from "@shared/props/review";
import { instance as analytics } from "@shared/services/analytics";

import BatchReviewItemBadge from "../BatchReviewItemBadge";

// eslint-disable-next-line complexity
const BatchReviewItem = ({
  review,
  currentUserId,
  isSelected,
  inSelectMode,
  toggleSelect,
  keepInView,
  scrollOnMount,
  isGuestSession,
  isReviewDecisionRequested,
}) => {
  const [pendingReviewItemHovered, setPendingReviewItemHovered] =
    useState(false);

  const { t } = useTranslation();

  const url = generatePath(APP_ROUTES["REVIEW_LINK"].path, {
    stepId: review.stepId,
    reviewId: review.id,
    comment: null,
  });

  const toggleReviewItemSelection = () => {
    toggleSelect(review.id);

    analytics.track(
      analytics.ACTION[isSelected ? "DESELECTED" : "SELECTED"],
      analytics.CATEGORY.FILES,
      { page: "grid-view", fileSelection: "individual_files" }
    );
  };

  const currentReviewDecision = useMemo(
    () =>
      review.reviews.find(({ reviewerId, state }) => {
        const userReview = reviewerId === currentUserId;

        return (
          userReview && Object.keys(REVIEW_DECISION_STATES).includes(state)
        );
      }),
    [currentUserId, review.reviews]
  );

  // Logic of using isPendingYourReview here is complicated
  // and must be refactored and the logic is to be moved to the backend
  // and logic must be in same as used for review decision button
  const pendingReview =
    (review.isPendingYourReview ||
      isGuestSession ||
      isReviewDecisionRequested) &&
    review.status.state === REVIEW_STATUS.IN_REVIEW &&
    !currentReviewDecision;

  const handleClick = () => {
    if (pendingReview) {
      toggleReviewItemSelection();
    }
  };

  const toggleHoverState = () => {
    if (pendingReview) {
      setPendingReviewItemHovered(!pendingReviewItemHovered);
    }
  };

  const ref = useKeepInView({
    keepInView,
    scrollOnMount,
  });

  const openReviewInNewTab = (event) => {
    event.preventDefault();
    event.stopPropagation();
    window.open(url, "_blank");
  };

  let itemState;

  if (pendingReview) {
    itemState = "PENDING_REVIEW";
  } else if (currentReviewDecision?.digitallySigned) {
    itemState = "VERIFIED_APPROVAL";
  } else {
    itemState = currentReviewDecision?.state;
  }

  const isCheckboxHoverChecked = inSelectMode && pendingReviewItemHovered;
  const hasBadge = currentReviewDecision || pendingReview;

  return (
    <Box
      ref={ref}
      bgcolor="white"
      borderRadius={1}
      border="2px solid transparent"
      p={1.5}
      marginLeft={1.5}
      marginBottom={2}
      cursor={pendingReview ? "pointer" : "default"}
      className="tour__file-batch-review"
      onClick={handleClick}
      onMouseEnter={toggleHoverState}
      onMouseLeave={toggleHoverState}
      data-testid="batch-review-item"
      sx={{
        cursor: pendingReview ? "pointer" : "default",
        borderColor: isSelected ? "grey.800" : "transparent",
        "&:hover": {
          "& .MuiButton-root": {
            display: "flex",
          },
          "& img + .MuiBox-root": {
            display: "block",
          },
        },
      }}
    >
      <Box
        width={317}
        height={178}
        bgcolor="grey.200"
        borderRadius="4px"
        overflow="hidden"
        position="relative"
      >
        {review.thumbnail && (
          <>
            <Image
              src={review.thumbnail.url}
              alt={review.thumbnail.name}
              width="100%"
              height={178}
              sx={{
                objectFit: "contain",
              }}
            />
            <Box
              sx={{
                display: "none",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                backgroundColor: "rgba(255, 255, 255, .3)",
              }}
            />
          </>
        )}

        <Tooltip
          title={t(
            isReviewDecisionRequested || currentReviewDecision
              ? "BATCH_REVIEW.ACTION.TOOLTIP"
              : "BATCH_REVIEW.NON_REVIEWER_CHECKBOX.TOOLTIP"
          )}
          disabled={pendingReview}
        >
          <Box position="absolute" top={2} left={6} zIndex={1}>
            <Checkbox
              checked={isSelected || isCheckboxHoverChecked}
              disabled={!pendingReview}
              onChange={toggleReviewItemSelection}
              onClick={(event) => event.stopPropagation()}
              color={isCheckboxHoverChecked ? "default-light" : "default"}
              inputProps={{
                "data-testid": "review-item-checkbox",
              }}
            />
          </Box>
        </Tooltip>

        <Button
          // className={classes.openReviewButton}
          variant="outlined"
          color="primaryV2"
          startIcon={<OpenInNew />}
          onClick={openReviewInNewTab}
          data-testid="open-review-button"
          sx={{
            position: "absolute",
            bottom: 10,
            right: 12,
            zIndex: 1,
            display: "none",
          }}
        >
          {t("CORE.OPEN")}
        </Button>
      </Box>

      <Box
        width={317}
        marginTop={0.5}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Tooltip title={review.file.name}>
          <Text
            wordBreak="break-word"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
          >
            {review.file.name}
          </Text>
        </Tooltip>

        {hasBadge && <BatchReviewItemBadge state={itemState} />}
      </Box>
    </Box>
  );
};

BatchReviewItem.propTypes = {
  review: reviewProp.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  inSelectMode: PropTypes.bool.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  keepInView: PropTypes.bool.isRequired,
  scrollOnMount: PropTypes.bool.isRequired,
  isGuestSession: PropTypes.bool.isRequired,
  isReviewDecisionRequested: PropTypes.bool.isRequired,
};

BatchReviewItem.defaultProps = {
  isSelected: false,
};

export default memo(BatchReviewItem);
