import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import DoneIcon from "@mui/icons-material/Done";
import { Button } from "@mui/material";

import { Box, Dialog, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

function RegisterInterestDialog({ eventMeta, closeDialog }) {
  const { t } = useTranslation();

  const [isInterested, setIsInterested] = useState(false);

  const handleInterestClick = useCallback(() => {
    setIsInterested(true);
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AI_MARKETPLACE.INTEREST_BUTTON,
      {
        aiMarketplaceInterest: eventMeta,
      }
    );
  }, [eventMeta]);

  return (
    <Dialog
      useDefaultBackground={false}
      isOpen
      title={t(
        isInterested
          ? "BOTS.DIALOG.INTERESTED_SUCCESS_MODAL.TITLE"
          : "BOTS.DIALOG.INTERESTED_MODAL.TITLE"
      )}
      maxWidth="xs"
      actions={
        !isInterested && (
          <Button
            variant="contained"
            onClick={handleInterestClick}
            data-testid="register-interest-button"
            sx={{ margin: "auto" }}
          >
            {t("BOTS.DIALOG.INTERESTED_MODAL.BUTTON")}
          </Button>
        )
      }
      cancel={closeDialog}
      data-testid="register-interest-dialog"
      hideBackdrop={false}
      disableBackdropClick
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={2}
      >
        {isInterested && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="success.light"
            width={40}
            height={40}
            borderRadius={5}
          >
            <DoneIcon color="success.main" />
          </Box>
        )}

        <Text variant="textSm" color="text.secondary">
          {isInterested
            ? t("BOTS.DIALOG.INTERESTED_SUCCESS_MODAL.DESCRIPTION")
            : t("BOTS.DIALOG.INTERESTED_MODAL.DESCRIPTION")}
        </Text>
      </Box>
    </Dialog>
  );
}

RegisterInterestDialog.propTypes = {
  eventMeta: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default RegisterInterestDialog;
