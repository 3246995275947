/* eslint-disable complexity */
import { useCallback, useEffect, useRef } from "react";

import { Box, Skeleton } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import {
  ProjectTemplateGate,
  AddProjectGate,
} from "@supporting/hooks/useGatedFeature/featureGates";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import CreateProjectDialog from "@workflow/components/CreateProjectDialog/CreateProjectDialog";
import { useTeamProjects } from "@workflow/data/dashboard-sidebar";
import projectReviewService from "@workflow/services/projectReviewService";
import projectService from "@workflow/services/projectService";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation, useDialogState } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import AddProjectTemplateDialog from "@workflow/pages/ProjectTemplate/AddProjectTemplateDialog/AddProjectTemplateDialog";

import {
  PROJECT_LIST_ACTION_TYPES,
  SIDEBAR_ACTION_TYPES,
  FAVORITE_PROJECT_LIST_ACTION_TYPES,
  useSidebarState,
} from "../../sidebarState";
import AddFolderDialog from "./ProjectListHeader/AddFolderDialog/AddFolderDialog";
import ProjectSection from "./ProjectSection";
import SidebarHeader from "./SidebarHeader";

const ProjectSectionSkeleton = () => {
  return (
    <Box py={1} pl={2} pr={1} data-testid="sidebar-skeleton-loader">
      <Skeleton
        variant="rounded"
        fullWidth
        height={25}
        animation="wave"
        sx={{ mb: 1 }}
      />
      <Box display="flex" flexDirection="column" gap={1} pl={2}>
        {[...Array(15)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            fullWidth
            height={25}
            animation="wave"
          />
        ))}
      </Box>
    </Box>
  );
};

export default function ProjectList() {
  const { dispatch } = useSidebarState();
  const { goTo } = useNavigation();
  const createProjectDialog = useDialogState();
  const createFolderDialogState = useDialogState();
  const createTemplateDialogState = useDialogState();

  const team = useSelectedTeam();
  const hasPassedInitialRender = useRef(false);
  const user = useActiveUser();

  const { checkAndProceed: checkTemplateAndProceed } = useGatedFeature({
    featureGate: ProjectTemplateGate,
    currentValue: team.usedBillingLimits?.numberOfProjectTemplates,
    teamId: team._id,
  });

  const { checkAndProceed: checkProjectAndProceed } = useGatedFeature({
    featureGate: AddProjectGate,
    currentValue: team.usedBillingLimits?.numberOfActiveProjects,
    teamId: team._id,
  });

  const newLayoutEnabled =
    user.settings?.earlyAccessFeatures?.newSidebarStateManagement;

  const { data: folderTeamProjects, isLoading } = useTeamProjects(
    team._id,
    user.settings?.selectedProjectSortingOption,
    newLayoutEnabled
  );

  useEffect(() => {
    async function fetchProjectsByTeamId() {
      try {
        const folders = await projectService.fetchProjectsByTeamId(team._id);
        dispatch({
          type: PROJECT_LIST_ACTION_TYPES.UPDATE_FOLDERS,
          payload: { folders },
        });
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    }

    async function fetchFavoriteProjects() {
      try {
        const projects = await projectService.fetchFavoriteProjects();
        dispatch({
          type: FAVORITE_PROJECT_LIST_ACTION_TYPES.UPDATE_PROJECTS,
          payload: { projects },
        });
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    }
    fetchProjectsByTeamId();
    fetchFavoriteProjects();
  }, [team._id]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateFolders = useCallback(
    ({ eventData }) => {
      const { folders, teamId, isExternalTeam } = eventData;

      if (teamId !== team._id) {
        return;
      }

      dispatch({
        type: isExternalTeam
          ? PROJECT_LIST_ACTION_TYPES.UPDATE_REVIEWER_FOLDERS
          : PROJECT_LIST_ACTION_TYPES.UPDATE_FOLDERS,
        payload: { folders },
      });
    },
    [dispatch, team._id]
  );

  const updateReviewerProjects = ({ eventData }) => {
    const { externalProjects } = eventData;
    dispatch({
      type: PROJECT_LIST_ACTION_TYPES.UPDATE_REVIEWER_PROJECTS,
      payload: { externalProjects },
    });
  };

  const updateSelectedProject = ({ eventData }) => {
    const { project } = eventData;
    dispatch({
      type: PROJECT_LIST_ACTION_TYPES.UPDATE_SELECTED_PROJECT,
      payload: { project },
    });
  };

  const onUserUpdated = ({ eventData: { user } }) => {
    dispatch({
      type: SIDEBAR_ACTION_TYPES.UPDATE_USER,
      payload: {
        viewArchivedProjects: user.settings.viewArchivedProjects,
        viewProjectTemplates: user.settings.viewProjectTemplates,
      },
    });
  };

  const updateFavoriteProjects = useCallback(
    ({ eventData }) => {
      const { projects } = eventData;

      dispatch({
        type: FAVORITE_PROJECT_LIST_ACTION_TYPES.UPDATE_PROJECTS,
        payload: { projects },
      });
    },
    [dispatch]
  );

  useEffect(() => {
    hasPassedInitialRender.current = true;

    async function fetchExternalProjects() {
      const externalProjects =
        await projectReviewService.fetchExternalProjects();
      dispatch({
        type: PROJECT_LIST_ACTION_TYPES.UPDATE_REVIEWER_PROJECTS,
        payload: { externalProjects },
      });
    }
    fetchExternalProjects();

    eventService.addListener(EVENT_NAMES.FOLDERS.UPDATED, updateFolders);
    eventService.addListener(
      EVENT_NAMES.REVIEWER_PROJECTS.UPDATED,
      updateReviewerProjects
    );
    eventService.addListener(
      EVENT_NAMES.PROJECT.SELECTED,
      updateSelectedProject
    );
    eventService.addListener(EVENT_NAMES.USER.UPDATED, onUserUpdated);
    eventService.addListener(
      EVENT_NAMES.FAVORITE_PROJECTS.UPDATED,
      updateFavoriteProjects
    );

    return () => {
      eventService.removeListener(EVENT_NAMES.FOLDERS.UPDATED, updateFolders);
      eventService.removeListener(
        EVENT_NAMES.FAVORITE_PROJECTS.UPDATED,
        updateFavoriteProjects
      );

      eventService.removeListener(
        EVENT_NAMES.REVIEWER_PROJECTS.UPDATED,
        updateReviewerProjects
      );
      eventService.removeListener(
        EVENT_NAMES.PROJECT.SELECTED,
        updateSelectedProject
      );
      eventService.removeListener(EVENT_NAMES.USER.UPDATED, onUserUpdated);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCollapse = async (
    { id, isCollapsed },
    isExternalTeam = false
  ) => {
    try {
      dispatch({
        type: isExternalTeam
          ? PROJECT_LIST_ACTION_TYPES.TOGGLE_REVIEWER_FOLDER_COLLAPSE
          : PROJECT_LIST_ACTION_TYPES.TOGGLE_FOLDER_COLLAPSE,
        payload: { id },
      });

      analytics.track(
        isCollapsed ? analytics.ACTION.EXPANDED : analytics.ACTION.COLLAPSED,
        analytics.CATEGORY.FOLDER
      );

      if (isExternalTeam) {
        await projectReviewService.updateFolderCollapsed(id, isCollapsed);
        return;
      }

      await projectService.updateFolderCollapsed(id, team._id, isCollapsed);
    } catch (error) {
      errorHandlerService.handleError(error);
      dispatch({
        type: PROJECT_LIST_ACTION_TYPES.TOGGLE_FOLDER_COLLAPSE,
        payload: { id },
      });
    }
  };

  const handleCreateFolder = useCallback(() => {
    createFolderDialogState.openDialog();
  }, [createFolderDialogState]);

  const addProjectTemplateHandler = useCallback(
    (template) => {
      analytics.track(
        analytics.ACTION.CREATED,
        analytics.CATEGORY.PROJECT_TEMPLATE
      );

      goTo(ROUTE_STATES.TEMPLATE_ID, {
        params: {
          projectTemplateId: template.id,
        },
      });

      createTemplateDialogState.closeDialog();
    },
    [createTemplateDialogState, goTo]
  );

  const createFolderHandler = useCallback(
    async (newFolder) => {
      try {
        healthMetrics.trackStart("workflow.create-folder");
        await projectService.addFolder(newFolder);
        healthMetrics.trackSuccess("workflow.create-folder");
        createFolderDialogState.closeDialog();
      } catch (error) {
        healthMetrics.trackFailure("workflow.create-folder", error);
        return errorHandlerService.handleError(error);
      }
    },
    [createFolderDialogState]
  );

  return (
    <>
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <SidebarHeader
          canCreateProjects={team.permissions.canCreateProjects}
          canManageTemplates={team.permissions.canManageTemplates}
          onClick={checkProjectAndProceed(createProjectDialog.openDialog)}
          onClickFolder={handleCreateFolder}
          onClickTemplate={checkTemplateAndProceed(
            createTemplateDialogState.openDialog
          )}
        />
        {newLayoutEnabled && isLoading && <ProjectSectionSkeleton />}
        {newLayoutEnabled && !isLoading && (
          <ProjectSection
            onCollapse={handleCollapse}
            folderTeamProjects={folderTeamProjects}
          />
        )}
        {!newLayoutEnabled && <ProjectSection onCollapse={handleCollapse} />}
      </Box>
      {createProjectDialog.isOpen && (
        <CreateProjectDialog cancel={createProjectDialog.closeDialog} />
      )}
      {createFolderDialogState.isOpen && (
        <AddFolderDialog
          teamId={team._id}
          cancel={createFolderDialogState.closeDialog}
          answer={createFolderHandler}
        />
      )}
      {createTemplateDialogState.isOpen && (
        <AddProjectTemplateDialog
          cancel={createTemplateDialogState.closeDialog}
          answer={addProjectTemplateHandler}
        />
      )}
    </>
  );
}
