import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import { processSignup } from "@supporting/helpers/processSignup";
import useActiveUser from "@supporting/hooks/useActiveUser";
import { instance as teamService } from "@supporting/services/team";
import Cookies from "js-cookie";

import { Text } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import errorHandlerService from "@shared/services/errorHandler";

import TeamItem from "./TeamItem";

export default function JoinTeams() {
  const { t } = useTranslation();
  const user = useActiveUser();
  const userDomain = user.email.split("@")[1].toLowerCase();
  const signupReason = Cookies.get("authentication:signup-form:reason");
  const [teams, setTeams] = useState([]);
  const [shouldCreateNewTeam, setShouldCreateNewTeam] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { routeNames, goTo } = useNavigation();

  useEffect(() => {
    async function fetchTeams() {
      const teamsWithSameDomain = await teamService.fetchTeamsWithSameDomain();

      setTeams(teamsWithSameDomain);
      setShouldCreateNewTeam(
        !teamsWithSameDomain.some((team) => team.isTeamMember)
      );
    }
    fetchTeams();
  }, []);

  const handleCreateNewTeam = async () => {
    setIsSubmitting(true);
    try {
      await processSignup(signupReason);
    } catch (error) {
      errorHandlerService.handleError(error);
      setIsSubmitting(false);
    }
  };

  const handleContinueToDashboard = () => {
    if (signupReason) {
      Cookies.remove("authentication:signup-form:reason");
    }
    goTo(routeNames.DASHBOARD);
  };

  const enableContinueToDashboard = async (updatedTeam) => {
    if (shouldCreateNewTeam) {
      await teamService.switchTeam(updatedTeam);
      setShouldCreateNewTeam(false);
    }
  };

  return (
    <WizardBase variant="INVITE_TEAM_MEMBERS">
      <Box
        gap={5}
        flexGrow={1}
        maxWidth={400}
        display="flex"
        alignSelf="center"
        flexDirection="column"
      >
        <Box gap={2} display="flex" flexDirection="column">
          <Text
            variant="text3xl"
            fontWeight="fontWeight.medium"
            translate="JOIN_WITH_SAME_DOMAIN.HEADER"
          />
          <Text
            color="grey.600"
            variant="textMd"
            translate="JOIN_WITH_SAME_DOMAIN.BODY"
          />
        </Box>
        <Box gap={2} display="flex" flexDirection="column">
          <Box
            border={1}
            overflow="auto"
            maxHeight={300}
            borderRadius={2}
            borderColor="grey.100"
          >
            {teams.map((team) => (
              <TeamItem
                key={team.id}
                team={team}
                domain={userDomain}
                enableContinue={enableContinueToDashboard}
              />
            ))}
          </Box>
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            size="large"
            loading={isSubmitting}
            disabled={isSubmitting}
            onClick={
              shouldCreateNewTeam
                ? handleCreateNewTeam
                : handleContinueToDashboard
            }
          >
            {t(
              shouldCreateNewTeam
                ? "TEAM.ONBOARDING.CONTINUE.BUTTON"
                : "EMBED_PAGE.CONTINUE_BUTTON"
            )}
          </Button>
        </Box>
      </Box>
    </WizardBase>
  );
}
