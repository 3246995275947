/* eslint react/forbid-component-props:0 */
import PropTypes from "prop-types";
import { ReactElement, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { CalendarToday } from "@mui/icons-material";
import { Button } from "@mui/material";
import {
  addSeconds,
  differenceInDays,
  endOfDay,
  endOfToday,
  isPast,
} from "date-fns";
import noop from "lodash/noop";

import { Tooltip } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import {
  formatWithLocale,
  isTimeEndOfTheDay,
  isTimeEndOfTheLocalDay,
} from "@shared/helpers/dateutils";
import { reviewProp } from "@shared/props/review";

import DueDatePopover from "./DueDatePopover/DueDatePopover";

const getIsDueDatePassed = (reviewDueDate) => {
  return reviewDueDate && isPast(new Date(reviewDueDate));
};

const getIsDueDateNextWeek = (reviewDueDate) => {
  const diffDays = differenceInDays(new Date(reviewDueDate), new Date());
  return reviewDueDate && diffDays < 7 && diffDays >= 0;
};

const compareDueDateToToday = (reviewDueDate) => {
  if (getIsDueDatePassed(reviewDueDate)) {
    return "passed";
  }
  if (getIsDueDateNextWeek(reviewDueDate)) {
    return "soon";
  }
  return "later";
};

const DUE_DATE_COLOR = {
  passed: "error",
  soon: "success",
  later: "primaryV2",
};

/**
 * @param {string} dueDate the due date.
 * @returns {string} the label for the date (Today, Tomorrow, etc).
 */
const getDateOnlyLabel = (dueDate) => {
  const diffInDays = differenceInDays(
    endOfToday(),
    endOfDay(new Date(dueDate))
  );
  switch (diffInDays) {
    case 0:
      return "DUE_DATE.TODAY";
    case -1:
      return "DUE_DATE.TOMORROW";
    case 1:
      return "DUE_DATE.YESTERDAY";
    default: {
      return formatWithLocale(
        new Date(dueDate),
        diffInDays >= -6 && diffInDays <= -1 ? "EEEE" : "MMM dd"
      );
    }
  }
};

const getRoundedTime = (dueDate) => {
  return isTimeEndOfTheDay(dueDate) && !isTimeEndOfTheLocalDay(dueDate)
    ? addSeconds(dueDate, 1)
    : dueDate;
};

const getLabel = (reviewDueDate) => {
  if (!reviewDueDate) {
    return { dateLabel: "DUE_DATE.NOT_SET_TEXT" };
  }

  const isEndOfDay = isTimeEndOfTheLocalDay(reviewDueDate);
  const dateLabel = getDateOnlyLabel(reviewDueDate);

  return {
    localizedTime: isEndOfDay
      ? null
      : formatWithLocale(getRoundedTime(new Date(reviewDueDate)), "p"),
    dateLabel,
  };
};

const getTooltipLabel = (isDueDateFeatureEnabled, canSetDueDate) => {
  if (!isDueDateFeatureEnabled) {
    return "";
  }
  if (canSetDueDate) {
    return "DUE_DATE.CHANGE_DUE_DATE";
  }
  return "DUE_DATE.UNDERSTAND_DUE_DATE";
};

/**
 * UIKit Due Date Component
 * @description Renders the due date of the review.
 * Depending on the provided options, it can render a due date picker, a due date reviewer help, or a due date unlock text.
 * @typedef ReviewObject
 * @param {object} props - Component props
 * @param {ReviewObject} props.review - [Required] Review object that will be used to render/set the due date.
 * @param {Function} [props.onSave] - [Optional] Callback function that will be called when the due date is changed.
 * @param {Function} [props.onRemove] - [Optional] Callback function that will be called when the due date is removed.
 * @param {boolean} [props.isReadOnly] - [Optional] Boolean that will be used to determine if the due date is read only.
 * @param {boolean} [props.canSetDueDate] - [Optional] User permission attribute that will be used to determine if the due date can be set.
 * @param {boolean} [props.isDueDateFeatureEnabled] - [Optional] Billing tier attribute that will be used to determine if the due date feature is enabled.
 * @param {boolean} [props.useColors] - [Optional] This will be used to determine if colors will be visible even it is readonly.
 * @param {boolean} [props.canManageBilling] - [Optional] User permission attribute that will be used to determine if the user can manage billing.
 * @param {string} [props.popoverPosition] - [Optional] Popover position for the due date picker and the other poppers.
 * @param {string} [props.openBillingDialog] - [Optional] Function to open SelectPlanDialog.
 * @returns {ReactElement} - React component
 */
function DueDate({
  review,
  onSave,
  onRemove,
  isReadOnly,
  canSetDueDate,
  isDueDateFeatureEnabled,
  canManageBilling,
  popoverPosition,
  useColors,
  openBillingDialog,
  ...restProps
}) {
  const { reviewDueDate, isPendingYourReview, status } = review;

  const isReviewed = status && status.state !== REVIEW_STATUS.IN_REVIEW;

  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  let label = "";
  const { dateLabel, localizedTime } = useMemo(
    () => getLabel(reviewDueDate),
    [reviewDueDate]
  );

  if (localizedTime) {
    label = `${t(dateLabel)}, ${localizedTime}`;
  } else {
    label = t(dateLabel);
  }

  const tooltipLabel = getTooltipLabel(isDueDateFeatureEnabled, canSetDueDate);

  let labelColor = "default-light";

  if (reviewDueDate) {
    if (useColors || (!isReadOnly && (!isReviewed || isPendingYourReview))) {
      labelColor = DUE_DATE_COLOR[compareDueDateToToday(reviewDueDate)];
    } else {
      labelColor = "default-light";
    }
  }
  const openDatePicker = (event) => {
    /* istanbul ignore else */
    if (!isReadOnly) {
      setAnchorEl(event.target);
    }
  };

  const closeDatePicker = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t(tooltipLabel)}>
        <Button
          startIcon={<CalendarToday fontSize="inherit" />}
          classes={{
            root: "tour__due-date",
          }}
          size="extraSmall"
          disableRipple={isReadOnly}
          data-testid="due-date"
          onClick={openDatePicker}
          color={labelColor}
          {...restProps}
        >
          {label}
        </Button>
      </Tooltip>

      {!isReadOnly && anchorEl && (
        <DueDatePopover
          dueDate={reviewDueDate}
          onClose={closeDatePicker}
          anchorEl={anchorEl}
          onSave={onSave}
          onRemove={onRemove}
          canSetDueDate={canSetDueDate}
          isDueDateFeatureEnabled={isDueDateFeatureEnabled}
          canManageBilling={canManageBilling}
          popoverPosition={popoverPosition}
          openBillingDialog={openBillingDialog}
        />
      )}
    </>
  );
}

DueDate.propTypes = {
  review: reviewProp.isRequired,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  isReadOnly: PropTypes.bool,
  canSetDueDate: PropTypes.bool,
  isDueDateFeatureEnabled: PropTypes.bool,
  canManageBilling: PropTypes.bool,
  useColors: PropTypes.bool,
  popoverPosition: PropTypes.string,
  openBillingDialog: PropTypes.func,
};

DueDate.defaultProps = {
  onSave: noop,
  onRemove: noop,
  isReadOnly: false,
  canSetDueDate: false,
  isDueDateFeatureEnabled: false,
  canManageBilling: false,
  useColors: false,
  popoverPosition: "right",
  openBillingDialog: noop,
};

export default DueDate;
