import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";
import ReviewerGroupSettings from "@workflow/components/ReviewerGroupSettings/ReviewerGroupSettings";
import StepReviewers from "@workflow/components/StepReviewers/StepReviewers";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";
import classnames from "classnames";

import { ShareLink, EditableLabel, Box } from "@shared/UIKit";

import validationUtils from "@shared/helpers/validationUtils";
import { useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import FSTGTypography from "@shared/theme/typography";

import StepHeaderMenu from "./StepHeaderMenu/StepHeaderMenu";

const useStyles = makeStyles((theme) => ({
  stepName: {
    variant: "h4",
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: FSTGTypography.fontSize_1_4,
    letterSpacing: 0,
  },
  editableLabel: { flexGrow: 1 },
}));

function disableEditableLabel(isTemplate, project, isMobile, editMode) {
  return isTemplate
    ? !project.permissions.canmanageTemplates
    : !project.permissions.rename ||
        project.isArchived ||
        (isMobile && !editMode);
}

function StepHeader({ project, step, numberOfSteps, team, isTemplate }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(step.isNew || false);
  const [currentStep, setCurrentStep] = useState(step);

  const { manageSteps, canmanageTemplates } = project.permissions;
  const isEditableLabelDisabled = disableEditableLabel(
    isTemplate,
    project,
    isMobile,
    editMode
  );

  const checkName = (newValue) => validationUtils.checkName(newValue);

  const changeStepFocusMode = useCallback(
    () => {
      if (step.isNew) {
        if (isTemplate) {
          projectTemplateService.updateStepFocusMode(step);
        } else {
          stepService.updateStepFocusMode(step.id);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [step]
  );

  const handleChangeEditMode = useCallback(
    (value) => {
      setEditMode(value);
      changeStepFocusMode();
    },
    [changeStepFocusMode]
  );

  const updateStepName = useCallback(
    async (newValue) => {
      if (isTemplate) {
        await projectTemplateService.updateStepName(currentStep.id, newValue);
      } else {
        await stepService.updateName(currentStep.id, newValue);
      }
      setCurrentStep((currentStep) => ({
        ...currentStep,
        name: newValue,
      }));

      analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.REVIEW_STEP);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep]
  );

  const renameStep = useCallback((event) => {
    event.stopPropagation();
    setEditMode(true);
  }, []);

  const handleStepSettingsChanged = useCallback(
    (settings) =>
      setCurrentStep((currentStep) => ({ ...currentStep, settings })),
    []
  );

  useEffect(() => {
    setCurrentStep(step);

    return () => {
      changeStepFocusMode();
    };
  }, [changeStepFocusMode, step]);

  useEffect(() => {
    const onStepUpdate = ({ eventData }) => {
      const updatedStep = eventData.step;
      if (updatedStep.id === currentStep.id) {
        setCurrentStep((currentStep) => ({
          ...currentStep,
          ...updatedStep,
        }));
      }
    };
    eventService.addListener(EVENT_NAMES.STEP.UPDATED, onStepUpdate);

    return () => {
      eventService.removeListener(EVENT_NAMES.STEP.UPDATED, onStepUpdate);
    };
  }, [step, currentStep.id]);

  return (
    <Box
      data-testid="step-header-root-element"
      pt={0.625}
      pb={0.375}
      pr={2.25}
      pl={2.125}
      bgcolor="grey.100"
      alignItems="space-between"
      display="flex"
      flexDirection="column"
      boxShadow="unset"
      flex={1}
    >
      <Box
        display="flex"
        alignItems="center"
        py={0.5}
        justifyContent="space-between"
      >
        <EditableLabel
          text={currentStep.name}
          onBeforeSave={checkName}
          onAfterSave={updateStepName}
          onChangeEditMode={handleChangeEditMode}
          disabled={isEditableLabelDisabled}
          isEditing={editMode}
          classes={{
            input: classes.stepName,
            text: classes.stepName,
          }}
          className={classnames(classes.editableLabel, {
            "tour__review-step-one": step.position === 0,
          })}
          tooltipProps={{
            title: isEditableLabelDisabled
              ? currentStep.name
              : t("PROJECT.STEP.RENAME"),
            placement: "bottom",
            text: !isEditableLabelDisabled ? currentStep.name : "",
          }}
        />

        {!editMode && (
          <StepHeaderMenu
            project={project}
            step={currentStep}
            numberOfSteps={numberOfSteps}
            onRename={renameStep}
            isTemplate={isTemplate}
          />
        )}
      </Box>
      <Box width="120%" ml={-2} height="1px" bgcolor="grey.200" />
      <Box
        display="flex"
        alignItems="center"
        justifyItems="space-between"
        py={0.5}
      >
        <Box display="flex" flexGrow={1}>
          <StepReviewers
            project={project}
            step={currentStep}
            team={team}
            max={5}
            variant="dashboard"
            isProjectTemplate={isTemplate}
          />
        </Box>
        <Box alignItems="flex-end">
          {!isTemplate && (
            <ShareLink stepId={currentStep.id} small tooltipDirection="left" />
          )}

          {(isTemplate ? canmanageTemplates : manageSteps) && (
            <ReviewerGroupSettings
              teamId={team._id}
              settings={currentStep.settings}
              onSettingsChanged={handleStepSettingsChanged}
              stepId={currentStep.id}
              isStepTemplate={isTemplate}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

StepHeader.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  step: stepProps.isRequired,
  team: teamProp.isRequired,
  numberOfSteps: PropTypes.number.isRequired,
  isTemplate: PropTypes.bool,
};

StepHeader.defaultProps = {
  isTemplate: false,
};

export default StepHeader;
