import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";
import Cookies from "js-cookie";

import { Box, LoadingSpinner } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import { instance as logger } from "@shared/services/logger";

const TAG = "TeamJoinLink";

const RESPONSE_STATUS = {
  PENDING_APPROVAL: 200,
  SUCCESS: 201,
  ALREADY_JOINED: 400,
  INVALID_LINK: 404,
};

function TeamJoinLink() {
  const { goTo } = useNavigation();
  const { inviteLinkId } = useParams();

  useEffect(() => {
    const joinTeam = async () => {
      let search;
      let response;
      try {
        Cookies.remove("teamlink_inviteLinkId");
        response = await backend.post(`/teams/${inviteLinkId}/join-link`);
        if (response.status === RESPONSE_STATUS.SUCCESS) {
          toastService.sendToast({
            title: "JOIN_WITH_SAME_DOMAIN.TOAST.TITLE",
            body: "JOIN_WITH_SAME_DOMAIN.TOAST.BODY",
            preset: toastService.PRESETS().SUCCESS_DELAYED,
            translationVariables: { body: { team: response.data.team.name } },
          });
          await teamService.switchTeam(response.data.team);
        }
        if (response.status === RESPONSE_STATUS.PENDING_APPROVAL) {
          search = new URLSearchParams({
            pendingApprovalId: response.data.team._id,
          }).toString();
        }
      } catch (error) {
        logger.error(TAG, "error while joining team by invite link", { error });
      } finally {
        if (
          response?.status === RESPONSE_STATUS.SUCCESS ||
          response?.status === RESPONSE_STATUS.PENDING_APPROVAL
        ) {
          analytics.track(
            analytics.ACTION.OPENED,
            analytics.CATEGORY.TEAM_INVITE_LINK,
            {
              teamId: response.data.team._id,
              defaultRole: response.data.roleName,
              isApprovalRequired: Boolean(search),
            }
          );
        }
        await teamService.clearCache();
        goTo("DASHBOARD", {
          options: { reload: true },
          search,
        });
      }
    };
    joinTeam();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      width="100%"
      height="100%"
    >
      <LoadingSpinner />
    </Box>
  );
}

export default TeamJoinLink;
