import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import PasswordFieldWithTooltip from "@supporting/components/PasswordFieldWithTooltip/PasswordFieldWithTooltip";
import NameContent from "@supporting/components/ReviewersAuthenticationFlow/NameContent";
import { processSignup } from "@supporting/helpers/processSignup";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";

import { INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX } from "@shared/constants/validation";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
const MINIMUM_PASSWORD_LENGTH = 8;
export default function SignupPasswordContent({
  fullName,
  onSubmit,
  email,
  code,
  authRouteState,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const [name, setName] = useState(
    fullName || authenticationService.nameFrom(email)
  );
  const invalidName = !name || !name.trim();
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [validation, setValidation] = useState({
    isMinimum: false,
    isOneUpperCase: false,
    isOneLowerCase: false,
    isOneNumberOrSpecial: false,
  });
  const onPasswordSubmit = async (event) => {
    event.preventDefault();
    // istanbul ignore else
    if (
      validation.isMinimum &&
      validation.isOneLowerCase &&
      validation.isOneNumberOrSpecial &&
      validation.isOneUpperCase &&
      !invalidName
    ) {
      setIsLoading(true);
      try {
        analytics.track(
          analytics.ACTION.SUBMITTED,
          analytics.CATEGORY.USER_CREDENTIALS_PAGE
        );
        await authenticationService.signup(
          {
            fullName: name,
            email,
            password,
            code,
          },
          authRouteState
        );
        const teams = await teamService.getTeams();
        /* istanbul ignore else*/
        if (teams.length === 1) {
          await teamService.switchTeam(teams[0]);
        } else if (teams.length === 0) {
          await processSignup("review-files", true);
        }
        setIsLoading(false);
        onSubmit("CLOSE_DIALOG");
      } catch (error) {
        setIsLoading(false);
        errorHandlerService.handleError(error);
      }
    } else {
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.ACCOUNT_USER_PASSWORD_FAILED,
        { page: getCurrentPage(pathname) }
      );
      setShowError(true);
    }
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    setValidation({
      isMinimum: password.length >= MINIMUM_PASSWORD_LENGTH,
      isOneUpperCase: password.toLowerCase() !== password,
      isOneLowerCase: password.toUpperCase() !== password,
      isOneNumberOrSpecial: new RegExp(
        INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX
      ).test(password),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      component="form"
      onSubmit={onPasswordSubmit}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <NameContent
          fullName={name}
          setFullName={setName}
          title="PASSWORD_MODAL.REVIEWER_FLOW.SUBTITLE"
          invalidName={invalidName}
        />
      </Box>
      <PasswordFieldWithTooltip
        isMinimum={validation.isMinimum}
        isOneNumberOrSpecial={validation.isOneNumberOrSpecial}
        onChange={handlePasswordChange}
        isOneLowerCase={validation.isOneLowerCase}
        testId="reviewer-authentication-capture-signup-credentials-password-input"
        id="reviewer-authentication-capture-signup-password-input"
        name="reviewer-authentication-capture-signup-password-input"
        labelTranslate="ONBOARDING_WIZARD.CREDENTIALS.INPUT_PASSWORD_TITLE"
        error={showError}
        autoComplete="current-password"
        isOneUpperCase={validation.isOneUpperCase}
        value={password}
        autoFocus
      />
      <Button
        sx={{ mt: 3 }}
        variant="contained"
        type="submit"
        loading={isLoading}
        data-testid="workspace-auth-dialog-action-button"
      >
        {t("EMAIL_NOTIFICATION.CREATE_ACCOUNT_BUTTON")}
      </Button>{" "}
    </Box>
  );
}
SignupPasswordContent.propTypes = {
  fullName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  authRouteState: PropTypes.object.isRequired,
};
