import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { Box, Button } from "@mui/material";
import authentication from "@supporting/services/authentication";

import { Image, Text } from "@shared/UIKit";

import HelpResources from "@shared/components/HelpResources/HelpResources";
import SharedLayout from "@shared/pages/SharedLayout";

import EmptyStateFiles from "@assets/img/sprite-source/sprite--emptystate-files.png";
import EmptyStateFiles2x from "@assets/img/sprite-source/sprite--emptystate-files@2x.png";

const EmptySharedStep = () => {
  const { t } = useTranslation();

  return (
    <SharedLayout>
      <Box display="flex" alignItems="center" flexDirection="column" gap={4}>
        <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
          <Image
            height={80}
            width={142}
            src={EmptyStateFiles}
            srcSet={`${EmptyStateFiles2x} 2x`}
            alt="Empty Reviewer Group"
          />
          <Text
            translate="REVIEWER.SHARELINK.EMPTY_STATE.TITLE"
            variant="text5xl"
            fontWeight="fontWeight.bold"
          />
          <Text
            translate="REVIEWER.SHARELINK.EMPTY_STATE.TEXT"
            variant="textXl"
            color="text.secondary"
            textAlign="center"
          />
        </Box>
        {authentication.fetchSession()?.type === "Registered" ? (
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            size="large"
            startIcon={<HomeOutlinedIcon />}
          >
            {t("BROKEN_LINK.BUTTON_DASHBOARD")}
          </Button>
        ) : null}
      </Box>
      <HelpResources />
    </SharedLayout>
  );
};
export default EmptySharedStep;
