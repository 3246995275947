import de from "@emoji-mart/data/i18n/de.json";
import en from "@emoji-mart/data/i18n/en.json";
import fr from "@emoji-mart/data/i18n/fr.json";

import errorHandlerService from "@shared/services/errorHandler";

const SMALL_WINDOW_HEIGHT = 705;
const EMOJI_PICKER_TRANSLATIONS = { de, en, fr };

export const emojiMartConfig = (language = "en") => {
  return {
    previewPosition:
      document.body.offsetHeight > SMALL_WINDOW_HEIGHT ? "bottom" : "none",
    native: true,
    autoFocus: true,
    i18n: EMOJI_PICKER_TRANSLATIONS[language],
    locale: language,
    data: async () => {
      try {
        const response = await fetch("assets/data/emojis-data.json");

        const result = response.json();

        return result;
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
  };
};
