import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";

import { Box } from "@shared/UIKit";

function AIMarketplaceDialogTitle({ closeDialog }) {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      borderBottom="1px solid var(--mui-palette-grey-200)"
      px={1.5}
      py={1}
      justifyContent="space-between"
    >
      <Button
        variant="text"
        color="primaryV2"
        startIcon={<ArrowBackIcon />}
        onClick={closeDialog}
        data-testid="back-button"
      >
        {t("BOTS.BACK")}
      </Button>

      <IconButton
        size="small"
        onClick={closeDialog}
        data-testid="ai-marketplace-close-button"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
}

AIMarketplaceDialogTitle.propTypes = {
  closeDialog: PropTypes.func.isRequired,
};

export default AIMarketplaceDialogTitle;
