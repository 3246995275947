import propTypes from "prop-types";

import { SvgIcon, IconButton } from "@mui/material";

import MicrosoftTeamsDisabledLogo from "@assets/img/icons/microsoft-teams-icon-disabled.svg";
import MicrosoftTeamsLogo from "@assets/img/icons/microsoft-teams-icon.svg";

function MicrosoftTeamsSettingsButton({ onClick, connected }) {
  return (
    <IconButton
      data-testid="microsoft-teams-settings-button"
      onClick={onClick}
      size="small"
    >
      <SvgIcon
        component={connected ? MicrosoftTeamsLogo : MicrosoftTeamsDisabledLogo}
        inheritViewBox
      />
    </IconButton>
  );
}

MicrosoftTeamsSettingsButton.propTypes = {
  onClick: propTypes.func.isRequired,
  connected: propTypes.bool,
};

MicrosoftTeamsSettingsButton.defaultProps = {
  connected: false,
};

export default MicrosoftTeamsSettingsButton;
