import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import { ChevronLeft } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Tooltip } from "@shared/UIKit";

import { KEYBOARD_SHORTCUTS } from "@shared/constants/shortcutKey";
import { instance as analytics } from "@shared/services/analytics";

import { useSidebarState, SIDEBAR_ACTION_TYPES } from "./sidebarState";

const useStyles = makeStyles((theme) => ({
  toggleSidebarButton: {
    position: "absolute",
    left: "100%",
    transform: "translateX(-50%)",
    zIndex: 10,
    backgroundColor: theme.color.white,
    color: theme.color.gray[500],
    transformOrigin: "center",
    transition: ".4s ease-out",

    width: 32,
    height: 32,

    borderRadius: 8,
    boxShadow: theme.shadow["box-shadow-z0"],
    top: 45,
    border: `1px solid ${theme.color.gray[200]}`,

    "&:hover": {
      backgroundColor: theme.color.gray[300],
      borderColor: theme.color.gray[400],
    },
  },
  toggleSidebarButtonCollapsed: {
    transform: "translateX(-50%) rotate(180deg)",
  },
}));

export default function ToggleSidebarButton() {
  const { t } = useTranslation();
  const { dispatch, state } = useSidebarState();
  const classes = useStyles();
  const toggleSidebar = (type) => {
    analytics.track(
      analytics.ACTION[state.isCollapsed ? "EXPANDED" : "COLLAPSED"],
      analytics.CATEGORY.PROJECTS_SIDEBAR,
      {
        [analytics.LABEL.OPENED]:
          type === "shortcut"
            ? analytics.LABEL.VIA_SHORTCUT
            : analytics.LABEL.VIA_BUTTON,
      }
    );
    dispatch({ type: SIDEBAR_ACTION_TYPES.TOGGLE_SIDEBAR });
  };
  const tooltipTitle = state.isCollapsed
    ? t("PROJECT.COLLAPSE_PROJECTS.TOOLTIP_MESSAGE.SHOW")
    : t("PROJECT.COLLAPSE_PROJECTS.TOOLTIP_MESSAGE.HIDE");
  useHotkeys(KEYBOARD_SHORTCUTS.O, () => toggleSidebar("shortcut"), [
    state.isCollapsed,
  ]);

  return (
    <Tooltip
      shortcutKeys={[KEYBOARD_SHORTCUTS.O.toUpperCase()]}
      title={tooltipTitle}
    >
      <IconButton
        onClick={toggleSidebar}
        data-testid="toggle-sidebar-button"
        classes={{
          root: classnames(classes.toggleSidebarButton, {
            [classes.toggleSidebarButtonCollapsed]: state.isCollapsed,
          }),
        }}
      >
        <ChevronLeft fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}
