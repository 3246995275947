/* eslint jsdoc/require-jsdoc:0 */
/* eslint jsdoc/no-missing-syntax:0 */
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Done } from "@mui/icons-material";
import {
  Button,
  SvgIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    margin: "5px 0",
    "& span": {
      fontSize: FSTGTypography.fontSize_1_5,
      color: theme.color.gray[800],
      marginLeft: 5,
    },
  },
  unlockCTA: {
    marginBottom: 10,
  },
}));

function UnlockDueDate({ canManageBilling, onClose, openBillingDialog }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const initiateNewPlan = useCallback(() => {
    openBillingDialog();
    onClose();
  }, [onClose, openBillingDialog]);

  return (
    <Box py={3} px={4} data-testid="due-date-unlock">
      <Text
        variant="h6"
        textAlign="center"
        fontWeight={FSTGTypography.fontWeightMedium}
      >
        {t("DUE_DATE.UNLOCK_HEADER")}
      </Text>

      <List>
        <ListItem disableGutters className={classes.listItem}>
          <ListItemIcon>
            <SvgIcon component={Done} color="primary" />
          </ListItemIcon>
          <ListItemText>{t("DUE_DATE.UNLOCK_LINE1")}</ListItemText>
        </ListItem>
        <ListItem disableGutters className={classes.listItem}>
          <ListItemIcon>
            <SvgIcon component={Done} color="primary" />
          </ListItemIcon>
          <ListItemText>{t("DUE_DATE.UNLOCK_LINE2")}</ListItemText>
        </ListItem>
      </List>

      <Box textAlign="center" mt={3}>
        <Text
          variant="subtitle1"
          color={theme.color.gray[800]}
          className={classes.unlockCTA}
        >
          {t("DUE_DATE.UNLOCK_LINE3")}
        </Text>

        {!canManageBilling && (
          <Text
            variant="subtitle1"
            color={theme.color.gray[800]}
            className={classes.unlockCTA}
            data-testid="due-date-unlock-reach-out"
          >
            {t("DUE_DATE.REACH_OUT")}
          </Text>
        )}

        <Button
          onClick={initiateNewPlan}
          color="primary"
          variant="contained"
          data-testid="due-date-unlock-action"
        >
          {t("DUE_DATE.NEW_PLAN")}
        </Button>
      </Box>
    </Box>
  );
}

UnlockDueDate.propTypes = {
  canManageBilling: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

UnlockDueDate.defaultProps = {
  canManageBilling: false,
};

export default UnlockDueDate;
