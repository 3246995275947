import { AUTOMATION_KEY_VALUES } from "@workflow/constants/automation";

export function createAutomationObject(automation, steps, sections) {
  let resultAutomation = automation;
  if (
    Boolean(steps.length) &&
    automation.conditions.step &&
    automation.conditions.step !== AUTOMATION_KEY_VALUES.ANY
  ) {
    const selectedStep = steps.find(
      (step) => step.id === automation.conditions.step
    );

    resultAutomation = {
      ...resultAutomation,
      conditions: {
        ...resultAutomation.conditions,
        step: selectedStep?.name,
      },
    };
  }

  if (automation.actions?.startReviewStep) {
    const selectedStep = steps.find(
      (step) => step.id === automation.actions.startReviewStep
    );

    resultAutomation = {
      ...resultAutomation,
      actions: {
        ...resultAutomation.actions,
        startReviewStep:
          selectedStep?.name || AUTOMATION_KEY_VALUES.REVIEW_NEXT_STEP,
      },
    };
  }

  if (Boolean(sections.length) && automation.actions?.moveFileToSection) {
    const selectedSection = sections.find(
      (section) => section.id === automation.actions.moveFileToSection
    );
    resultAutomation = {
      ...resultAutomation,
      actions: {
        ...resultAutomation.actions,
        moveFileToSection: selectedSection?.name,
      },
    };
  }

  return resultAutomation;
}

export function createOptionsObject(
  conditions,
  steps,
  sections,
  selectedCondition
) {
  const sectionOptions = sections?.map((section) => {
    return {
      text: section.name,
      value: section.id,
      skipTranslation: true,
    };
  });

  const reviewerOptions = [];
  (selectedCondition?.step && selectedCondition?.step !== "ANY"
    ? steps.filter((step) => step.id === selectedCondition.step)
    : steps
  )?.forEach((step) => {
    step.reviewers?.forEach((reviewer) => {
      if (!reviewerOptions.find((option) => option.value === reviewer._id)) {
        reviewerOptions.push({
          text: reviewer.displayName,
          value: reviewer._id,
          skipTranslation: true,
        });
      }
    });
  });

  const stepOptions = steps?.map((step) => {
    return {
      text: step.name,
      value: step.id,
      skipTranslation: true,
    };
  });

  const modifyStepCondition = (condition) => {
    return {
      ...condition,
      options: [...(condition?.options || []), ...stepOptions],
    };
  };

  const modifySectionCondition = (condition) => {
    return {
      ...condition,
      options: [...(condition?.options || []), ...sectionOptions],
    };
  };

  const modifyReviewerCondition = (condition) => {
    return {
      ...condition,
      options: [
        ...(condition?.options || []),
        { divider: true, key: "reviewer-divider" },
        { categoryName: "PROJECT.MEMBERS.REVIEWER" },
        ...(reviewerOptions.length
          ? reviewerOptions
          : [
              {
                key: "no-reviewers",
                text: "AUTOMATIONS.CONDITION.IF.INPUT_OPTIONS.EMPTY_STATE",
                value: "no-reviewers",
                disabled: true,
              },
            ]),
      ],
    };
  };

  const modifyCondition = (condition) => {
    const isStep = condition.id === "step" || condition.key === "STEP";
    const isSection = condition.id === "section" || condition.key === "SECTION";
    const isReviewer =
      condition.id === "reviewer" || condition.key === "REVIEWER";

    if (isStep) {
      return modifyStepCondition(condition);
    }

    if (isSection) {
      return modifySectionCondition(condition);
    }

    if (isReviewer) {
      return modifyReviewerCondition(condition);
    }

    return condition;
  };

  const modifiedConditions = conditions?.map(modifyCondition);
  return modifiedConditions;
}
