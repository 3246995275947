import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";

import { Box, Link } from "@mui/material";

import { Tooltip } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";

import FileVersionInfoItem from "./FileVersionInfoItem";

function FileVersionInfo({ canNavigateToDashboard, project, stepName }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const projectToolTip = (projectName) => {
    return `${t("PROJECT.NAME", { projectName })}`;
  };
  const stepToolTip = (stepName) => {
    return `${t("REVIEW_STEP.NAME", { stepName })}`;
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ overflow: "hidden" }}>
      <Box display="flex" alignItems="center">
        {canNavigateToDashboard ? (
          <Tooltip title={projectToolTip(project.name)} placement="bottom">
            <Link
              variant="textSm"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              component={ReactLink}
              to={`/projects/${project.id}`}
              underline="none"
            >
              {project.name}
            </Link>
          </Tooltip>
        ) : (
          <FileVersionInfoItem toolTipTitle={projectToolTip(project.name)}>
            {project.name}
          </FileVersionInfoItem>
        )}
        {!isMobile && (
          <>
            <Box mx={0.5}>
              <FileVersionInfoItem noTooltip>&gt;</FileVersionInfoItem>
            </Box>
            <FileVersionInfoItem toolTipTitle={stepToolTip(stepName)}>
              {stepName}
            </FileVersionInfoItem>
          </>
        )}
      </Box>
    </Box>
  );
}

FileVersionInfo.propTypes = {
  canNavigateToDashboard: PropTypes.bool.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  stepName: PropTypes.string.isRequired,
};

export default FileVersionInfo;
