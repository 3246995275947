import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, FormControlLabel, Checkbox } from "@mui/material";
import StepIndicator from "@supporting/components/WizardBase/StepIndicator";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import useActiveUser from "@supporting/hooks/useActiveUser";
import { instance as teamService } from "@supporting/services/team";
import teamRoleService from "@supporting/services/teamRoleService";
import freeEmailDomains from "free-email-domains";
import Cookies from "js-cookie";

import { Text, Box } from "@shared/UIKit";
import ShareLinkPopper from "@shared/UIKit/ShareLink/ShareLinkPopper/ShareLinkPopper";

import pagesEnum from "@shared/constants/pages";
import fstgId from "@shared/helpers/fstgId";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import EmailChip from "@supporting/pages/onboardingWizard/InviteTeamMembers/EmailChip";

const CURRENT_STEP = 2;

export default function InviteTeamMembers() {
  const copyIconRef = useRef(null);
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [team] = useState(teamService.getSelectedTeam());
  const [emailAddresses, setEmailAddresses] = useState([]);

  const { buildUrl, routeNames, goTo } = useNavigation();

  const [enableSameDomainJoin, setEnableSameDomainJoin] = useState(true);

  const user = useActiveUser();
  const userDomain = user.email.split("@")[1].toLowerCase();

  const showEnableSameDomainJoinSetting =
    !freeEmailDomains.includes(userDomain);

  useEffect(() => {
    healthMetrics.trackAttempt("supporting.onboarding");
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS
    );
  }, []);
  const handleOnChange = () => {
    setEnableSameDomainJoin(!enableSameDomainJoin);
  };
  const handleCopyLink = async () => {
    try {
      analytics.track(
        analytics.ACTION.COPIED,
        analytics.CATEGORY.TEAM_INVITE_LINK,
        {
          page: analytics.CATEGORY.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS,
        }
      );
      const teamRoles = await teamRoleService.fetchTeamRoles(team._id);
      const defaultRole = teamRoles.find(
        (teamRole) => teamRole.roleName === "FULL_MEMBER"
      )?._id;
      const inviteLink = team.settings?.inviteLink || {
        defaultRole,
        requestApproval: false,
        linkId: fstgId.generate(),
      };
      const updateTeam = { settings: { inviteLink } };
      await teamService.updateTeamSettings(updateTeam);
      const completeLink = `${window.fs.config.appBase}${buildUrl(
        "TEAM_JOIN_LINK",
        {
          params: { inviteLinkId: inviteLink.linkId },
        }
      )}`;
      navigator.clipboard.writeText(completeLink);
      setShowCopySuccess(true);
      /* istanbul ignore next */
      setTimeout(() => {
        setShowCopySuccess(false);
      }, 2000);
    } catch (err) {
      errorHandlerService.handleError(err);
    }
  };
  const enableSameDomainJoinSetting = async () => {
    if (
      !enableSameDomainJoin ||
      !showEnableSameDomainJoinSetting ||
      team.domains?.some((domain) => domain.domainName === userDomain)
    ) {
      return;
    }

    try {
      const teamRoles = await teamRoleService.fetchTeamRoles(team._id);
      const defaultRole = teamRoles.find(
        (teamRole) => teamRole.roleName === "FULL_MEMBER"
      )?._id;

      await Promise.all([
        teamService.updateTeamSettings({
          settings: { enableSameDomainJoin },
        }),
        teamService.addTeamDomain(userDomain, defaultRole),
      ]);
    } catch (err) {
      errorHandlerService.handleError(err);
    }
  };

  const navigateToNextPage = async (invitedEmails = []) => {
    analytics.track(
      analytics.ACTION.SUBMITTED,
      analytics.CATEGORY.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS,
      {
        invitedEmails,
        isInvitedTeamMatesChecked: enableSameDomainJoin,
      }
    );
    invitedEmails.forEach((email) => {
      analytics.track(
        analytics.ACTION.INVITED_MEMBER,
        analytics.CATEGORY.TEAM,
        {
          teamId: team._id,
          teamName: team.name,
          invitedEmail: email,
          numberOfMembers: team.members.length + 1, // Members count after adding new member
          page: pagesEnum.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS,
        }
      );
    });

    await enableSameDomainJoinSetting();

    Cookies.set("onboarding:invited-emails", invitedEmails.join());

    await teamService.wizardProgressTrack(team._id, {
      currentStep: "uploadFiles",
    });
    goTo(routeNames.ONBOARDING_WIZARD_UPLOAD_FILES);
  };

  const inviteTeamMembersAndGoNext = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    await navigateToNextPage(emailAddresses);
  };

  const handleSkipClick = async () => {
    try {
      setIsSubmitting(true);
      healthMetrics.trackStart("supporting.onboarding");
      await navigateToNextPage();
      healthMetrics.trackSuccess("supporting.onboarding");
    } catch (error) {
      healthMetrics.trackFailure("supporting.onboarding", error);
      errorHandlerService.handleError(error);
      setIsSubmitting(false);
    }
  };
  const isDisabled = emailAddresses.length === 0;

  return (
    <WizardBase variant="INVITE_TEAM_MEMBERS">
      <Box gap={5} display="flex" flexGrow={1} flexDirection="column">
        <Box gap={2} display="flex" flexDirection="column">
          <StepIndicator currentStep={CURRENT_STEP} />
          <Text
            variant="text3xl"
            fontWeight="fontWeight.medium"
            translate="ONBOARDING_WIZARD_IMPROVEMENT.ADD_TEAMMATES.TITLE"
          />
          <Text
            color="grey.600"
            variant="textSm"
            translate="ONBOARDING_WIZARD_IMPROVEMENT.ADD_TEAMMATES.BODY"
          />
        </Box>
        <form onSubmit={inviteTeamMembersAndGoNext}>
          <Box display="flex" flexDirection="column" gap={2}>
            <EmailChip
              emailAddresses={emailAddresses}
              setEmailAddresses={setEmailAddresses}
            />
            <Box gap={1} display="flex" justifyContent="space-between">
              <Button
                size="small"
                type="submit"
                color="primary"
                variant="contained"
                disabled={isDisabled || isSubmitting}
                data-testid="onboarding-wizard-invite-team-members-submit"
              >
                {t("CORE.CONTINUE")}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                ref={copyIconRef}
                onClick={handleCopyLink}
              >
                {t("TEAM_INVITE_LINK.MEMBER_COPY_LINK")}
              </Button>
              <Button
                size="small"
                color="primaryV2"
                variant="text"
                disabled={isSubmitting}
                onClick={handleSkipClick}
                data-testid="onboarding-wizard-invite-team-member-skip"
              >
                {t("ONBOARDING_WIZARD.ADD_REVIEWERS.SKIP")}
              </Button>
            </Box>
          </Box>
        </form>
        {showEnableSameDomainJoinSetting && (
          <FormControlLabel
            label={t("JOIN_WITH_SAME_DOMAIN.CHECKBOX", {
              domain: userDomain,
            })}
            sx={{ backgroundColor: "grey.50", borderRadius: 1, margin: 0 }}
            control={
              <Checkbox
                checked={enableSameDomainJoin}
                onChange={handleOnChange}
                inputProps={{
                  "data-testid": "onboarding-wizard-enable-same-domain-join",
                }}
              />
            }
          />
        )}
        <ShareLinkPopper
          placement="bottom"
          wasCopiedSuccessful
          open={showCopySuccess}
          anchorEl={copyIconRef?.current}
        />
      </Box>
    </WizardBase>
  );
}
