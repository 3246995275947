import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import FeatureName from "./FeatureName";
import Features from "./Features";
import FeatureTooltip from "./FeatureTooltip";

function BrandingFeatures({ team, openBillingDialog }) {
  const { t } = useTranslation();

  return (
    <Features title={t("BILLING.FEATURE.BRANDING")}>
      <FeatureName
        name="BILLING.FEATURE.CUSTOM_LOGO.LABEL"
        isEnabled={team.subscriptionLimits.isBrandingEnabled}
        tooltip={
          <FeatureTooltip
            description={t("BILLING.FEATURE.CUSTOM_LOGO.HELP_MESSAGE.PART1")}
            linkHref={t("URLS.FAQ_CUSTOM_LOGO")}
            linkText={t("BILLING.FEATURE.LEARN_MORE")}
            isEnabled={team.subscriptionLimits.isBrandingEnabled}
            upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
            upgradePlanMessage2={t(
              "BILLING.FEATURE.CUSTOM_LOGO.HELP_MESSAGE.PART2"
            )}
            openBillingDialog={openBillingDialog}
          />
        }
      />
    </Features>
  );
}

BrandingFeatures.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default BrandingFeatures;
