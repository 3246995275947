/* eslint react/forbid-component-props:0 */
import PropTypes from "prop-types";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Grow, Popper } from "@mui/material";
import { useTheme } from "@mui/styles";

import { Text, Box } from "@shared/UIKit";

import HTMLElementType from "@shared/helpers/HTMLElementType";

/**
 * UIKit Share Link Popper Component
 * @description Renders a Popper component which prints out the copy operation state.
 * This is used in the Share Link component.
 * @typedef FstgId
 * @param {{
 *  open: boolean,
 *  wasCopiedSuccessful: boolean,
 *  anchorEl: any,
 *  tooltipDirection: string
 * }} props - Component props
 * @param {boolean} props.open [Required] Open state of the popper.
 * @param {boolean} props.wasCopiedSuccessful [Required] Copy operation state. It renders different text based on the state.
 * @param {any} props.anchorEl [Optional] Anchor element for the popper.
 * @param {string} props.tooltipDirection [Optional] Tooltip direction. Can be `top`, `bottom`, `left`, `right`. Default is `top`.
 * @returns {ReactElement} React component
 */
function ShareLinkPopper({
  open,
  anchorEl,
  tooltipDirection,
  wasCopiedSuccessful,
  ...restProps
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Popper
      style={{ zIndex: 10000 }}
      open={open}
      anchorEl={anchorEl}
      transition
      {...restProps}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={350}>
          <Box
            bgcolor={theme.color.white}
            textAlign="center"
            borderRadius="5px"
            boxShadow={theme.shadow["box-shadow-z5"]}
            padding="5px 10px"
            mt={tooltipDirection === "bottom" ? 2.5 : 0}
            mb={tooltipDirection === "top" ? 2.5 : 0}
          >
            <Text
              variant="subtitle1"
              fontWeight={theme.typography.fontWeight.semiBold}
              color={
                wasCopiedSuccessful
                  ? theme.color.green[400]
                  : theme.color.red[500]
              }
              data-testid="share-link-popper-text"
            >
              {t(
                wasCopiedSuccessful
                  ? "PROJECT.FILE.GET_SHARELINK.COPY_SUCCESS"
                  : "PROJECT.FILE.GET_SHARELINK.COPY_ERROR"
              )}
            </Text>
          </Box>
        </Grow>
      )}
    </Popper>
  );
}

ShareLinkPopper.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: HTMLElementType,
  tooltipDirection: PropTypes.oneOf(["bottom", "left", "right", "top"]),
  wasCopiedSuccessful: PropTypes.bool.isRequired,
};

ShareLinkPopper.defaultProps = {
  tooltipDirection: "top",
  anchorEl: null,
};

export default ShareLinkPopper;
