import PropTypes from "prop-types";

export const authenticationServiceProp = PropTypes.shape({
  nameFrom: PropTypes.func,
  fetchUser: PropTypes.func,
  fetchSession: PropTypes.func,
  authenticate: PropTypes.func,
  login: PropTypes.func,
  signup: PropTypes.func,
  finishRegisteredAuthentication: PropTypes.func,
  logout: PropTypes.func,
  googleSignIn: PropTypes.func,
  startExternalLogin: PropTypes.func,
  impersonate: PropTypes.func,
  startStepShareSession: PropTypes.func,
  guestLogin: PropTypes.func,
  fetchIdentity: PropTypes.func,
  onSuccessfulAuthentication: PropTypes.func,
  forgotPassword: PropTypes.func,
}).isRequired;

export const avatarProp = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  key: PropTypes.string,
  url: PropTypes.string,
  label: PropTypes.string,
  sizeInBytes: PropTypes.number,
  mimeType: PropTypes.string,
  metaData: PropTypes.any,
});

export const userProp = PropTypes.shape({
  _id: PropTypes.string,
  fullName: PropTypes.string,
  language: PropTypes.string,
  initials: PropTypes.string,
  avatars: PropTypes.arrayOf(avatarProp),
  email: PropTypes.string,
  created: PropTypes.string,
  displayName: PropTypes.string,
});
