import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import reviewService from "@feedback/services/reviewService";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import ConfirmationDialog from "@shared/UIKit/ConfirmationDialog";

import { useDialogState } from "@shared/hooks";
import { versionProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import errorHandlerService from "@shared/services/errorHandler";

function ReviewCardMenuDeleteMenuItem({ step, version, review, closeMenu }) {
  const { t } = useTranslation();

  const { isOpen: isDialogOpen, openDialog, closeDialog } = useDialogState();

  const deleteReview = async () => {
    try {
      closeMenu();
      await reviewService.removeReview(review);
    } catch (error) {
      /* istanbul ignore next */
      errorHandlerService.handleError(error);
    }
  };

  return (
    <>
      <MenuItem
        data-accent-color="error"
        data-testid="review-card-menu-item-delete"
        onClick={openDialog}
      >
        <ListItemIcon>
          <DeleteOutline />
        </ListItemIcon>
        <ListItemText>{t("FILE_REVIEW.MENU.DELETE_REVIEW")}</ListItemText>
      </MenuItem>

      {isDialogOpen && (
        <ConfirmationDialog
          isOpen={isDialogOpen}
          title={t("PROJECT.DIALOGS.DELETE_REVIEW_VERSION.HEADLINE", {
            number: version.number,
            stepName: step.name,
          })}
          description={t("PROJECT.DIALOGS.DELETE_REVIEW_VERSION.DESCRIPTION")}
          cancelLabel={t("CORE.CANCEL")}
          confirmLabel={t("CORE.CONFIRM")}
          closeDialog={closeDialog}
          answer={deleteReview}
        />
      )}
    </>
  );
}

ReviewCardMenuDeleteMenuItem.propTypes = {
  step: stepProps.isRequired,
  version: versionProp.isRequired,
  review: reviewProp.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default ReviewCardMenuDeleteMenuItem;
