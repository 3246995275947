const COMPANY_SIZES = [
  {
    min: 1,
    format: "JUST_ME",
  },
  {
    min: 2,
    max: 20,
    format: "2_20",
  },
  {
    min: 21,
    max: 50,
    format: "21_50",
  },
  {
    min: 51,
    max: 100,
    format: "51_100",
  },
  {
    min: 101,
    max: 1000,
    format: "101_1000",
  },
  {
    min: 1001,
    format: "1000+",
  },
];

export { COMPANY_SIZES };
