import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import OtpField from "@supporting/components/OtpField/OtpField";
import PasswordFieldWithTooltip from "@supporting/components/PasswordFieldWithTooltip/PasswordFieldWithTooltip";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { Text } from "@shared/UIKit";

import { INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX } from "@shared/constants/validation";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

const MINIMUM_PASSWORD_LENGTH = 8;
const OTP_LENGTH = 6;

const ForgotPasswordOtp = ({ email, passwordUpdateSuccess }) => {
  const { t } = useTranslation();
  const currentRouteName = useCurrentRouteName();
  const [code, setCode] = useState("");
  const [errorText, setErrorText] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [validation, setValidation] = useState({
    isMinimum: false,
    isOneUpperCase: false,
    isOneLowerCase: false,
    isOneNumberOrSpecial: false,
  });
  useEffect(() => {
    if (!code && showError) {
      setErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCOMPLETE_CODE"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showError]);
  const INVALID_OTP = code.length > 0 && code.length !== OTP_LENGTH;
  const handleInputChange = (value) => {
    setCode(value);
    setErrorText("");
  };
  useEffect(() => {
    setValidation({
      isMinimum: newPassword.length >= MINIMUM_PASSWORD_LENGTH,
      isOneUpperCase: newPassword.toLowerCase() !== newPassword,
      isOneLowerCase: newPassword.toUpperCase() !== newPassword,
      isOneNumberOrSpecial: new RegExp(
        INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX
      ).test(newPassword),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);
  const handlePasswordChange = (event) => {
    setNewPassword(event.target.value);
  };
  const submitOtpWithPassword = async () => {
    if (
      validation.isMinimum &&
      validation.isOneLowerCase &&
      validation.isOneNumberOrSpecial &&
      validation.isOneUpperCase &&
      !INVALID_OTP &&
      code
    ) {
      setLoading(true);

      try {
        await authenticationService.updatePasswordWithOtp(
          email,
          code,
          newPassword
        );

        toastService.sendToast({
          title: "SETTINGS.EDITPASSWORD.SUCCESS.TITLE",
          body: "SETTINGS.EDITPASSWORD.SUCCESS.BODY",
          preset: toastService.PRESETS().SUCCESS,
        });

        analytics.track(
          analytics.ACTION.UPDATED_ACCOUNT_USER_PASSWORD,
          analytics.CATEGORY.USER
        );
        passwordUpdateSuccess();
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCORRECT_CODE"));
        analytics.track(
          analytics.ACTION.UPDATED_ACCOUNT_USER_PASSWORD_FAILED,
          analytics.CATEGORY.USER
        );
        errorHandlerService.handleError(error);
      }
    } else {
      setShowError(true);
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.ACCOUNT_USER_PASSWORD_FAILED,
        { page: getCurrentPage(currentRouteName) }
      );
    }
  };

  return (
    <Box
      gap={2}
      maxWidth={400}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      data-testid="box-otp-password"
    >
      <Text
        variant="text3xl"
        fontWeight="fontWeight.medium"
        translate="FORGOT_PW.CREATE_NEW_PW.HEADLINE"
      />
      <Text color="grey.600" variant="textMd">
        {t("FORGOT_PW.CREATE_NEW_PW.INSTRUCTIONS", { email })}
      </Text>
      <Box gap={4} width="100%" display="flex" flexDirection="column">
        <OtpField
          code={code}
          onChange={handleInputChange}
          errorText={errorText}
          fullWidth
        />
        <PasswordFieldWithTooltip
          isMinimum={validation.isMinimum}
          isOneLowerCase={validation.isOneLowerCase}
          isOneNumberOrSpecial={validation.isOneNumberOrSpecial}
          isOneUpperCase={validation.isOneUpperCase}
          value={newPassword}
          onChange={handlePasswordChange}
          testId="otp-new-password"
          name="new_password"
          id="newPassword"
          labelTranslate="FORGOT_PW.CREATE_NEW_PW.LABEL-2"
          error={showError}
        />
        <Button
          fullWidth
          size="large"
          color="primary"
          loading={loading}
          variant="contained"
          onClick={submitOtpWithPassword}
          data-testid="submit-otp-password"
        >
          {t("FORGOT_PW.CTA.CONTINUE")}
        </Button>
      </Box>
    </Box>
  );
};

ForgotPasswordOtp.propTypes = {
  email: PropTypes.string.isRequired,
  passwordUpdateSuccess: PropTypes.func.isRequired,
};

export default ForgotPasswordOtp;
