import PropTypes from "prop-types";

import { Box, Skeleton } from "@mui/material";
import { useGetMetric } from "@workflow/data/insights";

import { Text } from "@shared/UIKit";

import { METRIC_TYPES } from "./constants";

function MetricBox({ teamId, metricType }) {
  const { isLoading, data } = useGetMetric(teamId, metricType);

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={1.25}
      flex="0 0 auto"
      flexShrink={1}
      bgcolor="white"
      data-testid={`insights-${metricType}-metric`}
      sx={{
        border: `1px solid var(--mui-palette-grey-200)`,
        borderRadius: 1.75,
        px: 2,
        py: 4,
        minWidth: 135,
        width: 135,
      }}
    >
      <Text
        variant="body2"
        fontWeight="fontWeight.medium"
        translate={METRIC_TYPES[metricType].name}
        color="text.secondary"
        sx={{ textAlign: "center" }}
      />
      {isLoading ? (
        <Skeleton
          variant="text"
          width={65}
          height={16}
          data-testid={`insights-${metricType}-metric-loading`}
        />
      ) : (
        <Text
          variant="h4"
          fontWeight="fontWeight.medium"
          data-testid={`insights-${metricType}-metric-value`}
        >
          {data}
        </Text>
      )}
    </Box>
  );
}

MetricBox.propTypes = {
  teamId: PropTypes.string.isRequired,
  metricType: PropTypes.oneOf(Object.keys(METRIC_TYPES)).isRequired,
};

export default MetricBox;
