import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";

import { RemoveCircleOutline } from "@mui/icons-material";
import { Link, ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import noop from "lodash/noop";

import { Avatar, Text, Box } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { projectProps } from "@shared/props/project";
import FSTGTypography from "@shared/theme/typography";

import RemoveCollaboratorDialog from "./RemoveCollaboratorDialog/RemoveCollaboratorDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 5,
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 10,
  },
  email: {
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%",
    },
  },
  menuItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.color["white-smoke-translucent"],
      color: theme.color.red[500],
    },
  },
  menuItemIcon: {
    width: 20,
    marginRight: 10,
  },
}));

function CollaboratorMenu({ project, collaborator, closeMenu, menuAnchorEl }) {
  const theme = useTheme();
  const classes = useStyles();

  function showRemoveCollaboratorDialog() {
    return NiceModal.show(RemoveCollaboratorDialog, {
      project,
      collaborator,
      cancel: closeMenu,
      answer: noop,
    });
  }

  return (
    <Popper className={classes.popper} open anchorEl={menuAnchorEl} transition>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div>
            <ClickAwayListener
              touchEvent="onTouchStart"
              mouseEvent="onMouseDown"
              onClickAway={closeMenu}
            >
              <Paper className={classes.paper} elevation={4} square>
                <Box
                  display="flex"
                  flexDirection="column"
                  data-testid="project-collaborator-context-menu"
                >
                  <Box
                    display="flex"
                    p={1.875}
                    pt={3.75}
                    pb={2.5}
                    boxShadow={theme.shadow["box-shadow-step-reviewer-menu"]}
                  >
                    <Avatar user={collaborator} className={classes.avatar} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      alignContent="flex-start"
                      justifyContent="center"
                      overflow="hidden"
                    >
                      <Text
                        variant="h6"
                        fontWeight={theme.typography.fontWeight.semiBold}
                        color={theme.color["black-translucent-85"]}
                      >
                        {collaborator.displayName}
                      </Text>
                      {collaborator.email && (
                        <Link
                          href={`mailto:${collaborator.email}`}
                          className={classes.email}
                          fontSize={FSTGTypography.fontSize_1_5}
                          lineHeight={FSTGTypography.lineHeight_1_6}
                          color="success"
                        >
                          {collaborator.email}
                        </Link>
                      )}
                    </Box>
                  </Box>
                  {project.permissions.manageMembers && (
                    <Box
                      display="flex"
                      alignItems="center"
                      minHeight={36}
                      data-testid="project-collaborator-remove-collaborator"
                      onClick={showRemoveCollaboratorDialog}
                      className={classes.menuItem}
                      px={2.1}
                      py={1}
                    >
                      <RemoveCircleOutline className={classes.menuItemIcon} />
                      <Text
                        variant="body2"
                        fontWeight={theme.typography.fontWeight.semiBold}
                        translate="COLLABORATORS.REMOVE.BUTTON"
                      />
                    </Box>
                  )}
                </Box>
              </Paper>
            </ClickAwayListener>
          </div>
        </Fade>
      )}
    </Popper>
  );
}

CollaboratorMenu.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  collaborator: userProp.isRequired,
  closeMenu: PropTypes.func.isRequired,
  menuAnchorEl: PropTypes.object,
};

CollaboratorMenu.defaultProps = {
  menuAnchorEl: null,
};

export default CollaboratorMenu;
