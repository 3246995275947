import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { TextField } from "@mui/material";
import { instance as teamService } from "@supporting/services/team";

import { Dialog, Box } from "@shared/UIKit";

import { useField, useNavigation } from "@shared/hooks";
import ipapi from "@shared/services/ipapi";

export default function CreateTeamDialog({ close, isOpen }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { goTo } = useNavigation();

  const notEmpty = (value) => {
    if (!value) {
      throw new Error(t("TEAM.NEWTEAM.MESSAGE_REQUIRED"));
    }
  };
  const { value, error, change, isPristine, reset } = useField("", [notEmpty]);
  const handleInput = ({ target }) => {
    change(target.value);
  };

  const submit = async () => {
    setIsSubmitting(true);
    const { country } = await ipapi.fetchLocationInformation();
    const team = await teamService.createTeam({
      name: value,
      country,
      createdAsReviewer: false,
    });
    await teamService.switchTeam(team);
    goTo("DASHBOARD", { options: { reload: true } });
    close();
  };

  useEffect(() => {
    if (isOpen && !isPristine) {
      reset();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const isDisabled = (isPristine && !value) || Boolean(error) || isSubmitting;

  return (
    <Dialog
      title={t("TEAM.NEWTEAM.HEADLINE")}
      cancel={close}
      isOpen={isOpen}
      hideBackdrop={false}
      disableBackdropClick
      actions={
        <Button
          onClick={submit}
          color="primary"
          size="large"
          variant="contained"
          disabled={isDisabled}
          loading={isSubmitting}
        >
          {t("TEAM.NEWTEAM.SUBMIT")}
        </Button>
      }
    >
      <Box minWidth={280}>
        <TextField
          label={t("TEAM.NEWTEAM.LABEL")}
          name="teamName"
          id="teamName"
          type="text"
          onChange={handleInput}
          onBlur={handleInput}
          value={value}
          error={Boolean(error)}
          helperText={error}
          fullWidth
          autoFocus
        />
      </Box>
    </Dialog>
  );
}

CreateTeamDialog.propTypes = {
  close: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
