/* eslint jsdoc/require-jsdoc:0 */
/* eslint jsdoc/no-missing-syntax:0 */
import { HelpOutline } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  helpIcon: {
    color: theme.color.gray[400],
    marginLeft: 8,
  },
}));

function HelpTextPopperChildren() {
  const classes = useStyles();

  return (
    <Icon
      data-testid="help-text-popper-children"
      size="small"
      className={classes.helpIcon}
    >
      <HelpOutline fontSize="small" />
    </Icon>
  );
}

export default HelpTextPopperChildren;
