import { Box } from "@mui/material";

import classes from "./SharedLayout.module.css";

const colors = [
  "var(--mui-palette-accent-main)",
  "var(--mui-palette-success-main)",
  "var(--mui-palette-upgrade-main)",
  "var(--mui-palette-warning-main)",
];
const Blobs = () => {
  return (
    <>
      {colors.map((color) => (
        <Box
          key={color}
          className={classes.blob}
          sx={{
            "--color": color,
          }}
        />
      ))}
    </>
  );
};

export default Blobs;
