import PropTypes from "prop-types";

import useAutomationsState from "@workflow/hooks/useAutomationsState";

import WizardDialog from "@shared/UIKit/WizardDialog/WizardDialog";
import WizardStep from "@shared/UIKit/WizardDialog/WizardStep";

import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";

import AddAutomationHeader from "./AddAutomationForm/AddAutomationHeader";
import AutomationForm from "./AddAutomationForm/AutomationForm";
import AutomationListHeader from "./ProjectAutomationsList/AutomationListHeader";
import ProjectAutomationsList from "./ProjectAutomationsList/ProjectAutomationsList";

function AutomationsDialog({
  project,
  closeDialog,
  canManageAutomations,
  steps,
  isTemplate,
}) {
  const [automationsState] = useAutomationsState(project, steps);

  return (
    <WizardDialog
      handleClose={closeDialog}
      initialState={{
        triggers: null,
        trigger: null,
        isAutomationEnabled: true,
        selectedActions: [],
        canManageAutomations,
      }}
      dialogProps={{
        "data-testid": "automations-dialog",
        sx: {
          "& .MuiDialog-paper": {
            padding: 0,
            textAlign: "left",
          },
        },
      }}
      isOpen
    >
      <WizardStep
        stepKey="automations-list"
        title={<AutomationListHeader automationsState={automationsState} />}
        content={ProjectAutomationsList}
        contentProps={{ project, steps }}
      />

      <WizardStep
        stepKey="add-edit-automation"
        title={
          <AddAutomationHeader project={project} isTemplate={isTemplate} />
        }
        content={AutomationForm}
        contentProps={{ project, isTemplate, steps }}
      />
    </WizardDialog>
  );
}

AutomationsDialog.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  closeDialog: PropTypes.func.isRequired,
  canManageAutomations: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  isTemplate: PropTypes.bool,
};

AutomationsDialog.defaultProps = {
  isTemplate: false,
};

export default AutomationsDialog;
