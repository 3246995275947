/* eslint max-lines:0 */
import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

import CallMadeIcon from "@mui/icons-material/CallMade";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import HelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { LoadingButton as Button } from "@mui/lab";
import { Box, IconButton, Link, Chip } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import { AUTOMATION_CONDITIONS_PROPERTIES } from "@workflow/constants/automation";
import projectService from "@workflow/services/projectService";
import projectTemplateService from "@workflow/services/projectTemplateService";
import omit from "lodash/omit";

import { Text, Tooltip, ConfirmationDialog, InfoToolTip } from "@shared/UIKit";
import { useWizardDialog } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import { useDialogState } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import { createOptionsObject } from "../ProjectAutomationsList/utils";
import AutomationCardForm from "./AutomationCardForm/AutomationCardForm";
import AutomationLinkHelpIcon from "./AutomationLinkHelpIcon";
import AutomationMenuList from "./AutomationMenuList/AutomationMenuList";

const buildItem = (testId, id, name, item) => ({
  testId,
  key: id,
  name,
  item,
});

const buildTriggerItems = (triggers) =>
  triggers.map((trigger) =>
    buildItem(
      `trigger-menu-item-${trigger.id}`,
      trigger.id,
      trigger.name,
      trigger
    )
  );

const calculateMinHeight = (state) => {
  if (!state.trigger) {
    return 270;
  }

  const conditions = state.trigger.conditions?.length;

  if (conditions > 0) {
    return conditions * 60 + 100;
  }

  return 100;
};

const buildActionItems = (state, enabledEarlyAccess) =>
  state?.trigger?.actions
    .filter((action) => !state.selectedActions.includes(action))
    .filter(
      (action) =>
        !action.isEarlyAccess || (action.isEarlyAccess && enabledEarlyAccess)
    )
    .map((action) =>
      buildItem(`action-menu-item-${action.id}`, action.id, action.name, action)
    ) ?? [];

/* eslint complexity: off */
function AutomationForm({ project, isTemplate, steps }) {
  const { movePreviousStep, state, setDialogState, onClose } =
    useWizardDialog();
  const { t } = useTranslation();
  const service = isTemplate ? projectTemplateService : projectService;
  const user = authenticationService.fetchUser();

  const selectedAutomation = state.automation;
  const [loading, setLoading] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(
    selectedAutomation ? selectedAutomation.conditions : {}
  );
  const [actionValues, setActionValues] = useState(
    selectedAutomation ? selectedAutomation.actions : {}
  );

  const deleteDialog = useDialogState();
  const [isFormValid, setFormValidStatus] = useState(false);

  useEffect(() => {
    if (!selectedAutomation) {
      analytics.track(
        analytics.ACTION.STARTED,
        analytics.CATEGORY.AUTOMATION_CREATION
      );
      return;
    }

    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.AUTOMATION);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showToastMessage = (message, type, isBodyDisabled) => {
    toastService.sendToast({
      title: `${message}.TITLE`,
      body: isBodyDisabled ? undefined : `${message}.BODY`,
      preset: { ...toastService.PRESETS()[type], position: "bottom-right" },
      preventOpenDuplicatesId: `${message}.TITLE`,
    });
  };

  useEffect(() => {
    if (
      selectedCondition.step &&
      selectedCondition.reviewer &&
      selectedCondition.step !== "ANY" &&
      selectedCondition.reviewer !== "ALL_REVIEWERS" &&
      selectedCondition.reviewer !== "AT_LEAST_ONE_REVIEWER" &&
      !steps
        .find((step) => step.id === selectedCondition.step)
        .reviewers?.find(
          (reviewer) => reviewer._id === selectedCondition.reviewer
        )
    ) {
      setSelectedCondition((prevState) => omit(prevState, "reviewer"));
    }
  }, [selectedCondition, steps]);

  const handleDeleteDialogConfirm = useCallback(async () => {
    try {
      await service.removeAutomation(project.id, state.automation.automationId);

      analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.AUTOMATION);

      showToastMessage("AUTOMATIONS.DELETE_TOAST", "SUCCESS", true);
      onClose();
    } catch (err) {
      errorHandlerService.handleError(err);
    }
  }, [project.id, state?.automation?.automationId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedAutomation) {
      const currentTrigger = state.triggers.find(
        (trigger) => trigger.id === selectedAutomation.triggerId
      );
      const stateActions = Object.keys(selectedAutomation.actions);

      setDialogState((prevState) => ({
        ...prevState,
        selectedActions: currentTrigger?.actions.filter((trigger) =>
          stateActions.includes(trigger.id)
        ),
      }));
    }
  }, [selectedAutomation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const hasConditionKeys =
      state?.trigger &&
      AUTOMATION_CONDITIONS_PROPERTIES[state.trigger.id]?.reduce(
        (result, current) => result && current in selectedCondition,
        true
      );

    setFormValidStatus(
      Boolean(Object.keys(actionValues).length) &&
        (hasConditionKeys || !state.trigger?.conditions)
    );
  }, [actionValues, selectedCondition, state.trigger]);

  const handleOptionChange = useCallback((key, value) => {
    setSelectedCondition((previousState) => ({
      ...previousState,
      [key]: value,
    }));
  }, []);

  const handleActionOptionChange = (key, value) => {
    setActionValues((previousSate) => ({ ...previousSate, [key]: value }));
  };

  const handleTriggerChange = () => {
    setDialogState((prevState) => ({
      ...prevState,
      trigger: null,
      selectedActions: [],
    }));
    setSelectedCondition({});
    setActionValues({});
  };

  const handleTriggerSelected = useCallback(
    (trigger) => {
      setDialogState((prevState) => ({ ...prevState, trigger }));
      analytics.track(
        analytics.ACTION.ADDED,
        analytics.CATEGORY.AUTOMATION_TRIGGER,
        {
          label: trigger.name,
        }
      );
    },
    [setDialogState] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleActionSelected = useCallback(
    (action) => {
      const { selectedActions } = state;
      const newSelectedActions = [...selectedActions, action];
      setDialogState((prevState) => ({
        ...prevState,
        selectedActions: newSelectedActions,
      }));

      if (action.key && !action.type) {
        setActionValues((prevState) => ({ ...prevState, [action.id]: true }));
      }

      analytics.track(
        analytics.ACTION.ADDED,
        analytics.CATEGORY.AUTOMATION_ACTION,
        {
          label: action.name,
        }
      );
    },
    [setDialogState, state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleActionRemoved = useCallback(
    (action) => () => {
      const { selectedActions } = state;
      const newSelectedActions = selectedActions.filter(
        (selectedAction) => selectedAction !== action
      );

      setDialogState((prevState) => ({
        ...prevState,
        selectedActions: newSelectedActions,
      }));

      const newActionValues = { ...actionValues };
      delete newActionValues[action.id];
      setActionValues(newActionValues);
    },
    [actionValues, setDialogState, state]
  );

  const handleSaveAutomation = useCallback(async () => {
    try {
      setLoading(true);
      const automationPayload = {
        triggerId: state.trigger.id,
        conditions: selectedCondition,
        actions: actionValues,
      };

      if (selectedAutomation) {
        await service.editAutomation(project.id, {
          automationId: selectedAutomation.automationId,
          ...automationPayload,
        });
        showToastMessage("AUTOMATIONS.SUCCESS_TOAST", "SUCCESS", false);
      } else {
        await service.createAutomation(project.id, automationPayload);
        showToastMessage("AUTOMATIONS.SUCCESS_TOAST", "SUCCESS", false);
      }

      analytics.track(analytics.ACTION.SAVED, analytics.CATEGORY.AUTOMATION);
      movePreviousStep();
      onClose();
    } catch (err) {
      errorHandlerService.handleError(err);
      setLoading(false);
    }
  }, [state.trigger, selectedCondition, actionValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const triggersList = buildTriggerItems(state.triggers);
  const actionsList = buildActionItems(
    state,
    user.settings?.earlyAccessFeatures?.automations
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        data-testid="automations-form-root"
        minHeight="60vh"
        p={2}
        sx={{
          minWidth: { xs: 1, sm: 370 },
          ml: { xs: -4, sm: 0 },
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          minHeight={calculateMinHeight(state)}
          gap={2}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="space-between"
            position="relative"
            height="inherit"
            sx={{
              minWidth: {
                xs: 20,
                sm: 80,
              },
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <AutomationLinkHelpIcon translation="AUTOMATIONS.TRIGGER.HELP" />

              <Box
                borderRadius="64px"
                marginLeft={1}
                paddingX={1.25}
                bgcolor="grey.300"
              >
                <Text
                  fontSize="textXs.fontSize"
                  translate="AUTOMATIONS.CREATE.CONDITION.IF"
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: 0,
                top: 20,
                bottom: 20,
                left: 42,
                position: "absolute",
                border: `1px dashed var(--mui-palette-grey-300)`,
                "&::before": {
                  content: "''",
                  position: "absolute",
                  width: 0,
                  height: 0,
                  border: "4px solid transparent",
                  left: -4,
                  bottom: -6,
                  borderTop: `7px solid var(--mui-palette-grey-300)`,
                },
              }}
            />
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <AutomationLinkHelpIcon translation="AUTOMATIONS.ACTION.HELP" />

              <Box
                borderRadius="64px"
                marginLeft={1}
                paddingX={1.25}
                bgcolor="grey.300"
              >
                <Text
                  fontSize="textXs.fontSize"
                  translate="AUTOMATIONS.CREATE.CONDITION.THEN"
                />
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="stretch"
            mt={2}
            mb={4}
            sx={{ minWidth: { xs: "75%", sm: 375 } }}
          >
            {!state.trigger ? (
              <AutomationMenuList
                options={triggersList}
                onSelect={handleTriggerSelected}
                menuId="trigger"
                label="AUTOMATIONS.CREATE.ADD-TRIGGER"
              />
            ) : (
              <AutomationCardForm
                name="trigger"
                title={
                  <Box display="flex" alignItems="center" gap={2}>
                    {t(state.trigger.name)}
                    {state.trigger.isEarlyAccess && (
                      <Chip
                        color="accent"
                        size="small"
                        label={t("SETTINGS.TABS.EARLY_ACCESS")}
                      />
                    )}
                    {state.trigger.help && (
                      <InfoToolTip
                        data-testid="automations-trigger-help"
                        title={
                          <Trans
                            components={{
                              anchor: (
                                <Link
                                  target="_blank"
                                  color="white"
                                  fontSize="textSm.fontSize"
                                  className="launch-intercom-chat"
                                  href={t("URLS.HELLO_MAIL")}
                                />
                              ),
                            }}
                          >
                            {t(state.trigger.help)}
                          </Trans>
                        }
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <HelpOutlineOutlined fontSize="small" />
                      </InfoToolTip>
                    )}
                  </Box>
                }
                icon={<MyLocationIcon fontSize="small" color="success" />}
                action={
                  state.canManageAutomations && (
                    <Tooltip
                      placement="top"
                      title={t("AUTOMATIONS.CREATE.TOOLTIP.DELETE_TRIGGER")}
                    >
                      <IconButton
                        onClick={handleTriggerChange}
                        data-testid="automations-change-trigger"
                        aria-haspopup="true"
                        size="small"
                      >
                        <DeleteOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  )
                }
                conditions={
                  state.trigger.conditions &&
                  createOptionsObject(
                    state.trigger.conditions,
                    steps,
                    project.sections,
                    selectedCondition
                  )
                }
                selectedOption={selectedCondition}
                handleChange={handleOptionChange}
                isEditable={state.canManageAutomations}
                delay={state.trigger.delay}
              />
            )}
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          flex={1}
        >
          <Box data-testid="action-section" minWidth={{ xs: "25%", sm: 95 }} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            gap={2}
            sx={{
              minWidth: {
                xs: "75%",
                sm: 375,
              },
            }}
          >
            {state?.selectedActions?.length > 0 &&
              state.selectedActions.map((action) => (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="baseline"
                  key={action.id}
                >
                  <AutomationCardForm
                    name="action"
                    title={t(action.name)}
                    showDeleteIcon
                    icon={<CallMadeIcon fontSize="small" color="success" />}
                    action={
                      state.canManageAutomations && (
                        <Tooltip
                          placement="top"
                          title={t("AUTOMATIONS.CREATE.TOOLTIP.DELETE_ACTION")}
                        >
                          <IconButton
                            onClick={handleActionRemoved(action)}
                            data-testid="automations-action-remove"
                            size="small"
                          >
                            <DeleteOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )
                    }
                    conditions={createOptionsObject(
                      [action],
                      steps,
                      project.sections,
                      selectedCondition
                    )}
                    selectedOption={actionValues}
                    handleChange={handleActionOptionChange}
                    isEditable={state.canManageAutomations}
                  />
                </Box>
              ))}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="stretch"
              sx={{
                minWidth: {
                  xs: "10%",
                  sm: 375,
                },
              }}
            >
              {(!state.trigger ||
                state.selectedActions.length !==
                  state.trigger.actions.length) &&
              state.canManageAutomations ? (
                <AutomationMenuList
                  options={actionsList}
                  onSelect={handleActionSelected}
                  menuId="actions"
                  label="AUTOMATIONS.CREATE.ADD-ACTION.TEXT"
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      {state.canManageAutomations && (
        <DialogActions
          sx={{
            flexDirection: "row",
            justifyContent: state?.automation?.automationId
              ? "space-between"
              : "flex-end",
            alignItems: "center",
          }}
        >
          {state?.automation?.automationId && (
            <Button
              color="error"
              variant="text"
              data-testid="delete-automation-btn"
              onClick={deleteDialog.openDialog}
            >
              {t("AUTOMATIONS.CTA_BUTTON.DELETE_AUTOMATION")}
            </Button>
          )}

          <Button
            variant="contained"
            color="primary"
            disabled={!isFormValid}
            loading={loading}
            onClick={handleSaveAutomation}
            data-testid="automation-form-submit"
          >
            {t("AUTOMATIONS.CREATE.CTA-BUTTON.SAVE")}
          </Button>
        </DialogActions>
      )}
      {state.canManageAutomations && state?.automation?.automationId && (
        <ConfirmationDialog
          isOpen={deleteDialog.isOpen}
          title={t("AUTOMATIONS.DELETE_DIALOG.TITLE", {
            project: project.name,
          })}
          description={t("AUTOMATIONS.DELETE_DIALOG.DESCRIPTION")}
          confirmLabel={t("AUTOMATIONS.CTA_BUTTON.DELETE_AUTOMATION")}
          closeDialog={deleteDialog.closeDialog}
          answer={handleDeleteDialogConfirm}
        />
      )}
    </>
  );
}

AutomationForm.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  isTemplate: PropTypes.bool,
};

AutomationForm.defaultProps = {
  isTemplate: false,
};

export default AutomationForm;
