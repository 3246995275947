import NiceModal from "@ebay/nice-modal-react";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { InfoOutlined } from "@mui/icons-material";
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import { instance as billingService } from "@supporting/services/billingService";
import { instance as teamService } from "@supporting/services/team";

import { Box, LoadingSpinner, Text } from "@shared/UIKit";

import errorHandlerService from "@shared/services/errorHandler";

const BillingOverview = () => {
  const { t } = useTranslation();

  const [couponData, setCouponData] = useState("");
  const [loading, setIsLoading] = useState(true);

  const team = teamService.getSelectedTeam();
  const teamId = team._id;

  useEffect(() => {
    const getCoupon = async (id) => {
      try {
        setIsLoading(true);
        const response = await billingService.getCoupon(id);
        setCouponData(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        errorHandlerService.handleError(error);
      }
    };
    getCoupon(teamId);
  }, [teamId]);

  let subTotal = 0;

  team.subscription.items.forEach((item) => {
    subTotal += item.price.amount * item.quantity;
  });

  const openDialog = () => NiceModal.show(SelectPlanDialog, { teamId });

  const processDiscount = (subTotal) => {
    return couponData?.isValid
      ? (subTotal * couponData?.coupon.percent_off) / 100
      : 0;
  };

  const processTax = (subTotal) => {
    const taxAmount =
      ((subTotal - processDiscount(subTotal)) * team.subscription.taxRate) /
      100;

    return taxAmount;
  };

  const processTotalAmount = (subTotal) => {
    const totalAmount =
      subTotal - processDiscount(subTotal) + processTax(subTotal);

    return totalAmount;
  };

  if (team?.subscription?.status === "trialing") {
    return null;
  }

  const currencySymbol = billingService.getCurrencySymbol(
    team.subscription.basePrice.currency
  );

  return (
    <Card data-testid="team-billing-payment-overview">
      <CardHeader
        title={t("BILLING.BILLING_DETAILS.TITLE")}
        action={
          <Text>
            {t(
              `BILLING.BILLING_DETAILS.${team.subscription.basePrice?.interval.toUpperCase()}LY_PAYMENT`
            )}
          </Text>
        }
        sx={{
          "& .MuiCardHeader-action": {
            alignSelf: "center",
          },
        }}
      />
      <CardContent sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <>
          {team.subscription.basePrice?.interval === "month" && (
            <Box display="flex" flexDirection="row-reverse">
              <Button
                variant="text"
                color="primary"
                onClick={openDialog}
                data-testid="team-billing-select-button"
              >
                {t("BILLING.BILLING_DETAILS.TIP")}
              </Button>
            </Box>
          )}
          <Table>
            <TableBody>
              {team.subscription.items.map((item) => (
                <TableRow key={item.price.name}>
                  <TableCell data-testid="team-billing-payment-name">
                    {item.price.name}
                  </TableCell>
                  <TableCell
                    data-testid="team-billing-payment-currency"
                    align="right"
                    variant="head"
                  >
                    {currencySymbol}
                    {((item.price.amount * item.quantity) / 100).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>
                  <Table size="small">
                    <TableBody sx={{ "& .MuiTableRow-root td": { border: 0 } }}>
                      <TableRow>
                        <TableCell>
                          {t("BILLING.BILLING_DETAILS.SUBTOTAL")}
                        </TableCell>
                        <TableCell align="right" variant="head">
                          {currencySymbol}
                          {(subTotal / 100).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      {!loading ? (
                        <>
                          {couponData?.isValid && (
                            <TableRow data-testid="team-billing-payment-coupon">
                              <TableCell>
                                {t("BILLING.BILLING_DETAILS.DISCOUNT")}&nbsp; (
                                {couponData?.coupon.percent_off}%)&nbsp;:&nbsp;
                                ({t("BILLING.COUPON")}&nbsp;
                                {couponData?.coupon.id})
                              </TableCell>
                              <TableCell align="right" variant="head">
                                -{currencySymbol}
                                {(processDiscount(subTotal) / 100).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          {team.subscription.taxRate > 0 && (
                            <TableRow data-testid="team-billing-payment-tax">
                              <TableCell>
                                {t("BILLING.BILLING_DETAILS.TAX")}&nbsp;(
                                {team.subscription.taxRate}%)
                              </TableCell>
                              <TableCell align="right" variant="head">
                                {currencySymbol}
                                {(processTax(subTotal) / 100).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell>
                              {t("BILLING.BILLING_DETAILS.TOTAL")}
                            </TableCell>
                            <TableCell align="right" variant="head">
                              {currencySymbol}
                              {(processTotalAmount(subTotal) / 100).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        <TableRow>
                          <TableCell>
                            <LoadingSpinner />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box display="flex" justifyContent="center">
            <Button
              variant="text"
              color="primaryV2"
              href={t("URLS.FAQ_PRORATION")}
              target="_blank"
              startIcon={<InfoOutlined />}
            >
              {t("BILLING.BILLING_DETAILS.PRORATION_HELP")}
            </Button>
          </Box>
        </>
      </CardContent>
    </Card>
  );
};

export default BillingOverview;
