import {
  REVIEW_DECISION_STATES,
  REVIEW_DECISION_STATES_TO_SETTING_KEYS,
} from "@feedback/constants/reviewDecisionState";

export const getEnabledReviewDecisionOptions = (team) => {
  const enabledOptions = [];

  if (!team) {
    return enabledOptions;
  }

  for (const key in REVIEW_DECISION_STATES) {
    const state = REVIEW_DECISION_STATES[key];
    if (
      team.settings &&
      team.settings.reviewDecisionSetting[
        REVIEW_DECISION_STATES_TO_SETTING_KEYS[state]
      ]
    ) {
      enabledOptions.push(state);
    }
  }

  return enabledOptions;
};
