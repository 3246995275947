import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import StepSelection from "@workflow/components/UploadFileButton/step-selection/StepSelection";

import { Box, Text } from "@shared/UIKit";

import { fileProp } from "@shared/props/file";
import { stepProps } from "@shared/props/step";

const useClasses = makeStyles((theme) => ({
  inputWrapper: {
    marginBottom: theme.spacing(2.5),
  },
  selectLabel: {
    marginTop: -8,
    marginLeft: 12,
  },
}));

const INDESIGN_DOCUMENT_EXPORT_FORMATS = {
  PDF: "pdf",
  PNG: "png",
  JPG: "jpg",
};

const UploadInDesignDocument = ({
  steps,
  sectionId,
  closePanel,
  handleChange,
  selectedSteps,
  selectedFile,
}) => {
  const { t } = useTranslation();
  const classes = useClasses();
  const theme = useTheme();
  const [isUploading, setUploading] = useState(false);
  const [documentData, setDocumentData] = useState({
    format: INDESIGN_DOCUMENT_EXPORT_FORMATS.JPG,
    name: "",
  });

  const handleFormatChange = (event) => {
    const { value } = event.target;
    setDocumentData((documentObj) => ({ ...documentObj, format: value }));
  };

  const handleInputChange = (event) => {
    setDocumentData((documentObj) => ({
      ...documentObj,
      name: event.target.value,
    }));
  };

  const handleDocumentUpload = useCallback(() => {
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PLUGIN_UPLOAD,
        data: {
          exportData: {
            sectionId,
            selectedSteps: Object.keys(selectedSteps),
            ...(selectedFile ? { fileId: selectedFile._id } : {}),
            ...documentData,
          },
        },
      },
      "*"
    );
  }, [sectionId, selectedFile, documentData, selectedSteps]);

  useEffect(() => {
    const handleInDesignEvents = (event) => {
      if (event.origin === window.location.origin || !event?.data?.eventType) {
        return;
      }

      switch (event.data.eventType) {
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_FILE_DETAILS:
          if (event.data?.data?.name) {
            setDocumentData((documentObj) => ({
              ...documentObj,
              name: event.data.data.name,
            }));
          }
          break;
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_EXPORT_START:
          setUploading(true);
          break;
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_SUCCESS:
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_TO_S3_START:
          closePanel();
          break;
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_UPLOAD_FAILED:
        case ADOBE_PLUGIN_EVENTS.PLUGIN_FILESTAGE_EXPORT_FAILED:
          setUploading(false);
          break;
        default:
          break;
      }
    };

    window.addEventListener("message", handleInDesignEvents);
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PLUGIN_GET_FILE,
        data: {},
      },
      "*"
    );

    return () => {
      window.removeEventListener("message", handleInDesignEvents);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={5}
        data-testid="upload-indesign-document-fields"
      >
        <Box display="flex" alignItems="center" mb={2.5}>
          <Text
            variant="h6"
            translate="FILE.MENU.UPLOAD_FILE.TITLE"
            fontWeight={theme.typography.fontWeight.semiBold}
          />
        </Box>
        <Box maxWidth={280} width="100%">
          <Box className={classes.inputWrapper}>
            <TextField
              fullWidth
              type="text"
              name="name"
              variant="outlined"
              id="documentNameInput"
              onChange={handleInputChange}
              value={documentData.name}
              label={t(
                "FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.ENTER_FILE_NAME"
              )}
              inputProps={{
                "data-testid": "document-name-input",
              }}
            />
          </Box>
          <FormControl className={classes.inputWrapper} fullWidth>
            <InputLabel id="formatSelectInput" className={classes.selectLabel}>
              {t("FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.FORMAT")}
            </InputLabel>
            <Select
              id="formatSelectInput"
              name="format"
              variant="outlined"
              labelId="formatSelectLabel"
              onChange={handleFormatChange}
              value={documentData.format}
              label={t("FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.FORMAT")}
              inputProps={{
                "data-testid": "upload-document-format-input",
              }}
            >
              {Object.entries(INDESIGN_DOCUMENT_EXPORT_FORMATS).map(
                ([formatName, formatValue]) => (
                  <MenuItem
                    key={formatValue}
                    value={formatValue}
                    data-testid={`upload-document-format-${formatValue}`}
                  >
                    {formatName}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>

      {steps.length > 0 && (
        <StepSelection
          steps={steps}
          handleChange={handleChange}
          selectedSteps={selectedSteps}
          isSelectedFileAvailable={Boolean(selectedFile)}
        />
      )}

      <Box
        pt={8.75}
        pb={2.5}
        px={2}
        gap={2.5}
        display="flex"
        justifyContent="flex-end"
        data-testid="upload-indesign-document-buttons"
      >
        <Button
          variant="text"
          color="primaryV2"
          shape="rounded"
          onClick={closePanel}
          data-testid="cancel-button"
        >
          {t("FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.CANCEL")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          shape="rounded"
          data-testid="upload-button"
          onClick={handleDocumentUpload}
          disabled={!documentData.name || isUploading}
        >
          {t(
            isUploading
              ? "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.UPLOADING"
              : "FILE.UPLOAD_PANEL.UPLOAD_FILE_INPUT_DIALOG.UPLOAD"
          )}
        </Button>
      </Box>
    </>
  );
};

UploadInDesignDocument.propTypes = {
  closePanel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
  steps: PropTypes.arrayOf(stepProps),
  sectionId: PropTypes.string,
  selectedFile: fileProp,
};

UploadInDesignDocument.defaultProps = {
  steps: [],
  sectionId: null,
  selectedFile: null,
};

export default UploadInDesignDocument;
