import PropTypes from "prop-types";
import { forwardRef } from "react";
import { IMaskInput } from "react-imask";

const VerifyDomainEmailInput = forwardRef(
  ({ onChange, domainName, value, ...restProps }, ref) => {
    const formattedDomainName = domainName.replace(/[a0*[\]{}`]/g, "\\$&");
    return (
      <IMaskInput
        {...restProps}
        lazy={Boolean(!value.length)}
        mask={`username@${formattedDomainName}`}
        blocks={{
          username: {
            mask: String,
          },
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { value } })}
      />
    );
  }
);

VerifyDomainEmailInput.displayName = "VerifyDomainEmailInput";

VerifyDomainEmailInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  domainName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default VerifyDomainEmailInput;
