import { queryClient } from "@shared/react-query";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardContent, CardHeader } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import userService from "@supporting/services/userService";
import stubFalse from "lodash/stubFalse";
import upperFirst from "lodash/upperFirst";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import fileViewRedesignFeatureImage from "@assets/img/images/early-access-file-view-redesign.png";

import { FeaturePropType } from "./EarlyAccessPropTypes";
import EmptySection from "./EmptySection";
import EarlyAccessFeatureItem from "./feature-section/EarlyAccessFeatureItem";

export const EARLY_ACCESS_FEATURES = [
  {
    id: "consolidatedComments",
    titleKey: "Consolidated Comments",
    descriptionKey: "Consolidated Comments",
  },

  {
    id: "newSidebarStateManagement",
    titleKey: "New Sidebar With State Management",
    descriptionKey: "Now the sidebar has the state management",
    isBeta: true,
  },
  {
    id: "fileViewRedesign",
    titleKey: "EARLY_ACCESS.FILEVIEWER.TITLE",
    descriptionKey: "EARLY_ACCESS.FILEVIEWER.BODY",
    image: fileViewRedesignFeatureImage,
    isBeta: false,
  },
];

function EarlyAccess({ earlyAccessFeatures }) {
  const { t } = useTranslation();
  const team = useSelectedTeam();
  const [user, setUser] = useState(authenticationService.fetchUser());

  const filteredFeatures = earlyAccessFeatures
    .filter((feature) => !feature.isBeta || user.settings.isBeta)
    .filter(
      ({ hideEarlyAccessItem = stubFalse, ...feature }) =>
        !hideEarlyAccessItem({ user, team, feature })
    );

  const updateUserSettings = async (userSettings) => {
    try {
      await userService.update({ settings: userSettings });
      toastService.sendToast({
        title: "SETTINGS.PROFILE.UPDATE.SUCCESS.TITLE",
        body: "SETTINGS.PROFILE.UPDATE.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
      queryClient.clear();
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const updateEarlyAccessFeature = useCallback(
    (event) => {
      const { id, checked } = event.target;
      const featureInfo = earlyAccessFeatures.find(
        (feature) => feature.id === id
      );
      const { earlyAccessFeatures: existingEarlyAccessFeatures = {} } =
        user.settings;
      const updatedUserSettings = {
        ...user.settings,
        earlyAccessFeatures: { ...existingEarlyAccessFeatures, [id]: checked },
      };

      setUser({
        ...user,
        settings: updatedUserSettings,
      });

      updateUserSettings(updatedUserSettings);

      analytics.track(
        checked ? analytics.ACTION.ENABLED : analytics.ACTION.DISABLED,
        `${analytics.CATEGORY.EARLY_ACCESS}-${upperFirst(featureInfo.id)}`
      );
    },
    [user, earlyAccessFeatures] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Card data-testid="user-settings-early-access-tab">
      <CardHeader title={t("SETTINGS.EARLY_ACCESS.TITLE")} />
      <CardContent>
        {filteredFeatures.length === 0 ? (
          <EmptySection />
        ) : (
          filteredFeatures.map((feature) => (
            <EarlyAccessFeatureItem
              key={feature.id}
              feature={feature}
              featureToggleValue={Boolean(
                user.settings.earlyAccessFeatures &&
                  user.settings.earlyAccessFeatures[feature.id]
              )}
              onChangeFeatureToggle={updateEarlyAccessFeature}
              description={feature.description}
            />
          ))
        )}
      </CardContent>
    </Card>
  );
}

EarlyAccess.propTypes = {
  earlyAccessFeatures: PropTypes.arrayOf(PropTypes.shape(FeaturePropType)),
};

EarlyAccess.defaultProps = {
  earlyAccessFeatures: EARLY_ACCESS_FEATURES,
};

export default EarlyAccess;
