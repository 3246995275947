import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

import AddProjectsButton from "./AddProjectsButton";
import UsageBar from "./UsageBar";
import UsageTooltip from "./UsageTooltip";

const determineUsagePercentage = (maxProjectNumber, numberOfActiveProjects) => {
  return maxProjectNumber === 0
    ? 100
    : Math.max((numberOfActiveProjects / maxProjectNumber) * 100, 0);
};

function ActiveProjectUsage({ team, openBillingDialog }) {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <UsageBar
        mb={3.125}
        name="BILLING.FEATURE.ACTIVE_PROJECTS.LABEL"
        tooltip={
          <UsageTooltip
            usageMessage1={t(
              "BILLING.FEATURE.ACTIVE_PROJECTS.HELP_MESSAGE.PART1"
            )}
            hrefText={t("BILLING.UPGRADE_YOUR_PLAN")}
            openBillingDialog={openBillingDialog}
            usageMessage2={t(
              "BILLING.FEATURE.ACTIVE_PROJECTS.HELP_MESSAGE.PART2"
            )}
          />
        }
        used={team.usedBillingLimits?.numberOfActiveProjects}
        limit={team.subscriptionLimits.maxProjectNumber}
        percentage={determineUsagePercentage(
          team.subscriptionLimits.maxProjectNumber,
          team.usedBillingLimits?.numberOfActiveProjects
        )}
        isHelperVisible={false}
        helperTooltip=""
        helperText=""
      />
      <AddProjectsButton team={team} />
    </Box>
  );
}

ActiveProjectUsage.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default ActiveProjectUsage;
