import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PendingReviewsCounter from "@workflow/components/PendingReviewsCounter/PendingReviewsCounter";

import { Box, Text } from "@shared/UIKit";

import PendingReviewsSortPanel from "./PendingReviewsSortPanel";

function PendingReviewsHeader({
  pendingReviewsCount,
  sortOption,
  onSortOptionChanged,
}) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      data-testid="pending-reviews-header"
      px={2}
      py={1.5}
    >
      <Box display="flex" alignItems="center" gap={1}>
        <Text variant="textMd" fontWeight="fontWeight.medium">
          {t("PENDING_REVIEWS.NAVIGATION_BUTTON")}
        </Text>
        <PendingReviewsCounter pendingReviewsCount={pendingReviewsCount} />
      </Box>
      {pendingReviewsCount > 0 && (
        <Box
          display="flex"
          justifyContent="flex-end"
          data-testid="pending-reviews-sort-panel"
        >
          <PendingReviewsSortPanel
            sortOption={sortOption}
            onSortOptionChanged={onSortOptionChanged}
          />
        </Box>
      )}
    </Box>
  );
}

PendingReviewsHeader.propTypes = {
  pendingReviewsCount: PropTypes.number,
  sortOption: PropTypes.string.isRequired,
  onSortOptionChanged: PropTypes.func.isRequired,
};

PendingReviewsHeader.defaultProps = {
  pendingReviewsCount: 0,
};

export default PendingReviewsHeader;
