import PropTypes from "prop-types";

import { FEATURE_STATUS_ENUM } from "@integrations/constants";

export const integrationProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  featureId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  localizationKey: PropTypes.string.isRequired,
  featureStatus: PropTypes.oneOf(Object.values(FEATURE_STATUS_ENUM)).isRequired,
  href: PropTypes.string,
  appColor: PropTypes.string,
  isFeatured: PropTypes.bool,
  imageUrl: PropTypes.string,
  privacyUrlKey: PropTypes.string,
  learnMoreUrlKey: PropTypes.string,
  isNew: PropTypes.bool,
});
