import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import authenticationService from "@supporting/services/authentication";
import userService from "@supporting/services/userService";

import SortPanel from "@shared/UIKit/SortPanel";

import { instance as analytics } from "@shared/services/analytics";

const SORT_OPTIONS = { NEWEST: "NEWEST", OLDEST: "OLDEST" };

function NotificationSortPanel() {
  const { t } = useTranslation();

  const authenticatedUser = authenticationService.fetchUser();
  const sortOption =
    SORT_OPTIONS[
      authenticatedUser?.settings.selectedNotificationSortingOption
    ] || SORT_OPTIONS.NEWEST;

  const onSelectClick = useCallback(
    (sortKey) => {
      userService.update({
        settings: {
          ...authenticatedUser.settings,
          selectedNotificationSortingOption: sortKey,
        },
      });
      analytics.track(
        analytics.ACTION.CHANGED_SORTING,
        analytics.CATEGORY.NOTIFICATIONS,
        {
          label: sortKey,
        }
      );
    },
    [authenticatedUser.settings]
  );

  return (
    <SortPanel
      selectTitle={t("NOTIFICATION-CENTER.SORT-BY")}
      options={{
        NEWEST: t(`NOTIFICATION-CENTER.NEWEST-FIRST`),
        OLDEST: t(`NOTIFICATION-CENTER.OLDEST-FIRST`),
      }}
      selectedOption={sortOption}
      onSelect={onSelectClick}
    />
  );
}

export default NotificationSortPanel;
