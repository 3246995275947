import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Box } from "@mui/material";
import AutomationsHeader from "@workflow/components/AutomationsDialog/AutomationsHeader";
import projectService from "@workflow/services/projectService";
import projectTemplateService from "@workflow/services/projectTemplateService";

import { useWizardDialog } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import { useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import { instance as analytics } from "@shared/services/analytics";

import AutomationRequestFormButton from "../AutomationRequestFormButton/AutomationRequestFormButton";
import AutomationStatusButton from "./AutomationStatusButton";

function AddAutomationHeader({ project, isTemplate }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const service = isTemplate ? projectTemplateService : projectService;

  const { movePreviousStep, state, setDialogState, onClose } =
    useWizardDialog();

  const automationId = state?.automation?.automationId;
  const automation = project.automations.find(
    (automation) => automation.automationId === automationId
  );

  const navigateToAutomationList = () => {
    setDialogState((prevState) => ({
      ...prevState,
      automation: null,
      trigger: null,
      selectedActions: [],
    }));
    movePreviousStep();
  };

  const handleStatusChanged = useCallback(
    (evt) => {
      setDialogState((prevState) => ({
        ...prevState,
        automation: {
          ...prevState.automation,
          enabled: evt.target.checked,
        },
      }));

      service.enableProjectAutomations(
        project.id,
        state.automation.automationId,
        evt.target.checked
      );

      analytics.track(
        evt.target.checked
          ? analytics.ACTION.ACTIVATED
          : analytics.ACTION.PAUSED,
        analytics.CATEGORY.AUTOMATION
      );
    },
    [project, state, setDialogState] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <AutomationsHeader
      startContent={
        <Button
          variant="text"
          color="primaryV2"
          size="small"
          startIcon={<ArrowBackIcon />}
          onClick={navigateToAutomationList}
          data-testid="automations-back-button"
        >
          {!isMobile && t("AUTOMATIONS.DIALOG.BACK")}
        </Button>
      }
      endContent={
        <Box display="flex" gap={1} alignItems="center">
          <AutomationRequestFormButton />
          <AutomationStatusButton
            automationId={state?.automation?.automationId}
            automation={automation}
            onChange={handleStatusChanged}
            canManageAutomations={state?.canManageAutomations}
          />
        </Box>
      }
      closeDialog={onClose}
    />
  );
}

AddAutomationHeader.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  isTemplate: PropTypes.bool,
};

AddAutomationHeader.defaultProps = {
  isTemplate: false,
};

export default AddAutomationHeader;
