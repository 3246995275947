import { PropTypes } from "prop-types";

import { Box } from "@shared/UIKit";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";

import Blobs from "./Blobs";
import classes from "./SharedLayout.module.css";

const SharedLayout = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      px={10}
      py={7}
      flex={1}
      gap={9}
      bgcolor="transparent"
    >
      <Box alignSelf="center">
        <FilestageResponsiveLogo />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        flex="1 0 0"
      >
        {children}
      </Box>
      <Box className={classes.blobs}>
        <Blobs />
      </Box>
    </Box>
  );
};
SharedLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default SharedLayout;
