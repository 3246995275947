import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

function NavBar({ children }) {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="end"
      alignSelf="stretch"
      minHeight={{ xs: 70, md: 48 }}
      height={{ xs: 70, md: 48 }}
      mb="1px"
      bgcolor={theme.color.white}
      boxShadow={theme.shadow["box-shadow-navbar"]}
      px={2}
    >
      {children}
    </Box>
  );
}

NavBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavBar;
