import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import STORAGE from "@shared/constants/storage";
import { instance as analytics } from "@shared/services/analytics";

import DropBox from "@assets/img/icons/dropbox_icon.svg";

import RemoteButton from "../../RemoteButton";

function UploadFromDropbox({
  teamId,
  handleUploadFiles,
  enableMultiSelect,
  handleUpgradeAction,
  areFilePickerPluginsEnabled,
  isDisabled,
}) {
  const { t } = useTranslation();

  const handleDropboxClick = () => {
    if (areFilePickerPluginsEnabled) {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.DROPBOX_BUTTON
      );
      window.Dropbox.cancelChooser();
      window.Dropbox.choose({
        success: async (dropboxFiles) => {
          const files = dropboxFiles.map((dropboxFile) => ({
            isRemote: true,
            name: dropboxFile.name,
            size: dropboxFile.bytes,
            url: dropboxFile.link,
            storage: STORAGE.DROPBOX,
          }));
          await handleUploadFiles(files, teamId, STORAGE.DROPBOX);
        },
        linkType: "direct",
        multiselect: enableMultiSelect,
        folderselect: false,
      });
    } else {
      analytics.track(
        analytics.ACTION.REACHED,
        analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        {},
        {},
        analytics.STATE.DROPBOX_FEATURE
      );
    }
  };

  return (
    <RemoteButton
      testId="upload-panel-from-dropbox"
      onClick={handleDropboxClick}
      disabled={isDisabled}
      viewBox="0 0 235.45 200"
      icon={DropBox}
      label={t("FILE.UPLOAD_PANEL.DROP_BOX")}
      handleUpgradeAction={handleUpgradeAction}
      isFeatureEnabled={areFilePickerPluginsEnabled}
    />
  );
}

UploadFromDropbox.propTypes = {
  teamId: PropTypes.string.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
  enableMultiSelect: PropTypes.bool.isRequired,
  handleUpgradeAction: PropTypes.func.isRequired,
  areFilePickerPluginsEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

UploadFromDropbox.defaultProps = {
  isDisabled: false,
};

export default UploadFromDropbox;
