import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import { Box, Image } from "@shared/UIKit";

import { apiKeyProps } from "@shared/props/apiKey";

import ApiSprite from "@assets/img/sprite-source/sprite--api.png";
import ApiSprite2x from "@assets/img/sprite-source/sprite--api@2x.png";

import APIKeyListItem from "./APIKeyListItem";

const TABLE_HEADERS = [
  {
    id: "created",
    label: "INTEGRATIONS.API.CONNECTION.TABLE_CELL_CREATED",
  },
  {
    id: "apiKey",
    label: "INTEGRATIONS.API.CONNECTION.TABLE_CELL_API_KEY",
  },
];

export default function APIKeysList({ apiKeys, fetchAPIKeys }) {
  const { t } = useTranslation();

  if (apiKeys.length === 0) {
    return (
      <Box
        data-testid="no-api-keys-sprite"
        mt={5.5}
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Image
          src={ApiSprite}
          srcSet={`${ApiSprite2x} 2x`}
          alt="API illustration"
        />
      </Box>
    );
  }

  return (
    <Box mt={3.5}>
      <Table data-testid="api-keys-table">
        <TableHead sx={{ bgcolor: "grey.50" }}>
          <TableRow>
            {TABLE_HEADERS.map((header) => (
              <TableCell
                key={header.id}
                data-testid={`api-keys-list-header-${header.id}`}
              >
                {t(header.label)}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {apiKeys.map((apiKey) => (
            <APIKeyListItem
              key={apiKey.id}
              apiKey={apiKey}
              onDelete={fetchAPIKeys}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

APIKeysList.propTypes = {
  fetchAPIKeys: PropTypes.func.isRequired,
  apiKeys: PropTypes.arrayOf(apiKeyProps).isRequired,
};
