import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { LoadingButton as Button } from "@mui/lab";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

import DisplayAPIKeyDialog from "../DisplayAPIKeyDialog/DisplayAPIKeyDialog";

export default function GenerateAPIKey({ onDialogClose }) {
  const { t } = useTranslation();
  const selectedTeam = useSelectedTeam();
  const [isFetching, setIsFetching] = useState(false);
  const [generatedAPIKey, setGeneratedAPIKey] = useState("");

  const handleCloseDialog = useCallback(() => {
    setGeneratedAPIKey("");
    onDialogClose();
  }, [onDialogClose]);

  const generateAPIKey = useCallback(async () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.INTEGRATIONS.GENERATE_API_KEY
    );

    setIsFetching(true);
    try {
      const teamId = selectedTeam._id;
      const { token } = await backend.post("/api-token", { teamId });
      setGeneratedAPIKey(token);
      analytics.track(
        analytics.ACTION.CREATED,
        analytics.CATEGORY.INTEGRATIONS.API_KEY,
        {
          teamId,
          token: `****${token.substring(token.length - 6)}`,
        }
      );
    } catch (error) {
      errorHandlerService.handleError(error);
    } finally {
      setIsFetching(false);
    }
  }, [selectedTeam]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant="contained"
        loading={isFetching}
        onClick={generateAPIKey}
        startIcon={<AddCircleOutlineIcon />}
        data-testid="generate-api-new-key"
      >
        {t("INTEGRATIONS.API.CONNECTION.BUTTON.GENERATE_NEW_KEY_BUTTON")}
      </Button>

      {generatedAPIKey && (
        <DisplayAPIKeyDialog
          isOpen
          closeDialog={handleCloseDialog}
          generatedAPIKey={generatedAPIKey}
        />
      )}
    </>
  );
}

GenerateAPIKey.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
};
