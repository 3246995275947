/* eslint-disable max-lines */
import { queryClient } from "@shared/react-query";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import freeEmailDomains from "free-email-domains";
import Cookies from "js-cookie";
import jstz from "jstz";

import { APP_ROUTES } from "@shared/constants/routes";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as localisationService } from "@shared/services/localisationService";
import { instance as logger } from "@shared/services/logger";
import { history } from "@shared/services/navigation";

const TAG = "authentication:authentication-service-v2";
const SIGNUP_TAG = "authentication:signup-form";
let user = null;
let session = null;

eventService.addListener(EVENT_NAMES.USER.UPDATED, ({ eventData }) => {
  user = extendUserAttributes(eventData.user);
  return user;
});

function extendUserAttributes(user) {
  user.isInfoComplete = Boolean(user.fullName && user.email);
  user.isEmailVerified = Boolean(
    session?.verified || session?.type === "Registered"
  );
  if (user.email) {
    user.userEmailType = freeEmailDomains.includes(
      user.email.split("@")[1].toLowerCase()
    )
      ? "free"
      : "company";
  }
  return user;
}

function redirectToLogout() {
  const redirect = { state: "DASHBOARD" };
  const currentRouteMatch = history.matches[history.matches.length - 1];
  if (currentRouteMatch) {
    redirect.state = currentRouteMatch.id;
    redirect.params = currentRouteMatch.params;
    if (history.location.search) {
      redirect.search = history.location.search;
    }
  }
  history.navigate(APP_ROUTES.LOGOUT.path, { state: { redirect } });
}

function fetchUser() {
  return user;
}

function fetchSession() {
  return session;
}

function nameFrom(email) {
  const username = email.split("@")[0];
  return username
    .split(/[.\-_]/)
    .map(function capitalize(word) {
      return word.charAt(0).toUpperCase() + word.substring(1);
    })
    .join(" ");
}

function createCsrfSessionInEmbed(response) {
  if (response.csrf) {
    window.opener?.setEmbedCsrfToken(response);
    localStorage.setItem("csrf", response.csrf);
  }
}

function onSuccessfulAuthentication(response) {
  session = response.session;
  user = extendUserAttributes(response.user);

  try {
    if (
      window.opener.onSuccessfulAuthentication &&
      session.type === "Registered"
    ) {
      window.opener.onSuccessfulAuthentication(response);
    }
    // eslint-disable-next-line no-empty
  } catch {}
  logger.info(TAG, "authenticated user", { session });
  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.AUTHENTICATED,
    eventData: { user, session },
  });

  createCsrfSessionInEmbed(response);

  return { session, user };
}

async function validateUser() {
  try {
    const existingUser = fetchUser();
    const { user: currentUser } = await authenticate({
      skipSessionCheck: true,
    });
    if (currentUser._id !== existingUser._id) {
      window.location.reload();
    }
  } catch {
    redirectToLogout();
  }
}

async function validateSessionAndUser() {
  const session = fetchSession();
  if (session) {
    if (new Date(session.expires).getTime() < Date.now()) {
      redirectToLogout();
    } else {
      await validateUser();
    }
  }
}

/**
 * Authenticate the user
 * @param options
 * @param {boolean} [options.skipSessionCheck] - To skip the session check
 * @return {Promise<{ session: *, user: * }>}
 */
async function authenticate(options = {}) {
  if (
    !options.skipSessionCheck &&
    session &&
    new Date(session.expires).getTime() > Date.now()
  ) {
    return Promise.resolve({ session, user });
  }
  try {
    healthMetrics.trackStart("supporting.authentication-authorize");
    const response = await backend.get("/auth/authorize");
    healthMetrics.trackSuccess("supporting.authentication-authorize");

    return onSuccessfulAuthentication(response);
  } catch (error) {
    healthMetrics.trackFailure("supporting.authentication-authorize", error);
    logger.warn(TAG, "failed to authorize user", { error });
    throw error;
  }
}

function fetchIdentity(email, idpUrl) {
  return backend.get(`/auth/identity`, {
    email,
    idpUrl,
  });
}

function setAuthenticationState(authenticationState) {
  if (authenticationState) {
    try {
      localStorage.setItem(
        `${TAG}:authenticationState`,
        JSON.stringify(authenticationState)
      );
    } catch (error) {
      logger.warn(TAG, "failed to set authentication state", { error });
    }
  }
}

function getAuthenticationState() {
  const key = `${TAG}:authenticationState`;
  let authenticationState;
  if (localStorage.getItem(key)) {
    authenticationState = JSON.parse(localStorage.getItem(key));
    localStorage.removeItem(key);
  }

  return authenticationState;
}

function setSignupCookiesAndGetAuthState(authenticationState) {
  if (authenticationState) {
    if (authenticationState.planId) {
      Cookies.set(`${SIGNUP_TAG}:planId`, authenticationState.planId);
    }
    if (authenticationState.reason) {
      Cookies.set(`${SIGNUP_TAG}:reason`, authenticationState.reason);
    }
    if (
      authenticationState.sourceProjectId &&
      authenticationState.sourceProjectTemplateId
    ) {
      Cookies.set(
        `${SIGNUP_TAG}:sourceProjectId`,
        authenticationState.sourceProjectId
      );
      Cookies.set(
        `${SIGNUP_TAG}:sourceProjectTemplateId`,
        authenticationState.sourceProjectTemplateId
      );
    }
    if (authenticationState.insightsIntegrationId) {
      Cookies.set(
        `${SIGNUP_TAG}:insightsIntegrationId`,
        authenticationState.insightsIntegrationId
      );
    }

    return authenticationState;
  }
}

async function login(
  credentials,
  authenticationState,
  isAuthDialogPasswordLogin = false
) {
  logger.info(TAG, "started password login", {
    email: credentials.email,
  });
  const response = await backend.post(`/auth/login`, {
    ...credentials,
    ...authenticationState,
    language: localisationService.getLanguage(),
    timeZone: jstz.determine().name(),
  });

  onSuccessfulAuthentication(response);
  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.LOGGED_IN,
    eventData: { user: response.user },
  });
  queryClient.clear();
  if (!isAuthDialogPasswordLogin) {
    return setSignupCookiesAndGetAuthState(authenticationState);
  }
}

async function generatePluginSessionId() {
  const response = await backend.post(`/auth/adobe-plugin/registered`);
  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.PLUGIN_AUTHENTICATION,
    eventData: {
      registeredPluginSessionId: response.registeredPluginSessionId,
    },
  });

  return response.registeredPluginSessionId;
}

async function checkEmailIsNotRegistered(email) {
  try {
    await fetchIdentity(email);
    logger.warn(TAG, "email is already registered", { email });
    return Promise.reject({ code: "user_exists" });
  } catch (error) {
    if (error.status === 404) {
      return;
    }
    throw error;
  }
}

async function sendOTPForSignup(email, authenticationState) {
  await checkEmailIsNotRegistered(email);
  setAuthenticationState(authenticationState);
  await backend.post(`/auth/otp/send`, {
    email,
    language: localisationService.getLanguage(),
  });
}

async function signup(credentials, authenticationState) {
  logger.info(TAG, "started password sign up", {
    email: credentials.email,
  });
  if (!authenticationState) {
    authenticationState = getAuthenticationState();
  }

  const pluginId = getBasePlugin();
  const isPlugin = Object.values(PLUGIN_TYPES).includes(pluginId);
  let isInIframe = false;
  try {
    isInIframe = Boolean(isPlugin || window.opener?.onSuccessfulAuthentication);
    // eslint-disable-next-line no-empty
  } catch {}
  const response = await backend.post(`/auth/signup`, {
    ...credentials,
    ...authenticationState,
    iframe: isInIframe,
    language: localisationService.getLanguage(),
    locale: localisationService.getLocale(),
    timeZone: jstz.determine().name(),
  });

  onSuccessfulAuthentication(response);
  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.SIGNED_UP,
    eventData: { user: response.user },
  });
  queryClient.clear();
  return setSignupCookiesAndGetAuthState(authenticationState);
}

async function logout() {
  logger.info(TAG, "logout", { redirect: "/login" });
  queryClient.clear();
  try {
    await backend.get("/auth/logout");
    // eslint-disable-next-line no-empty
  } catch {}
  session = null;
  user = null;
  localStorage.removeItem("csrf");
  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.LOGGED_OUT,
    eventData: {},
  });
}

async function impersonate(email) {
  await backend.post(`/admin/impersonate`, { email });
  await logout();
  history.navigate(APP_ROUTES.DASHBOARD.path);
}

async function checkIsAdmin() {
  await backend.get("/auth/check-admin");
}

async function startStepShareSession(params) {
  healthMetrics.trackStart("supporting.authentication-step-share");
  logger.info(TAG, "start step share session", params);
  try {
    const response = await backend.post(`/auth/step`, {
      ...params,
      language: localisationService.getLanguage(),
    });
    healthMetrics.trackSuccess("supporting.authentication-step-share");
    return onSuccessfulAuthentication(response);
  } catch (err) {
    healthMetrics.trackFailure("supporting.authentication-step-share", err);
    throw err;
  }
}

async function guestSignup({ email, fullName, stepId }) {
  logger.info(TAG, "started guest sign up", { email, fullName });
  const response = await backend.post(`/auth/guest/signup`, {
    email,
    fullName,
    language: localisationService.getLanguage(),
    shareSessionStepId: stepId,
  });
  return onSuccessfulAuthentication(response);
}

async function checkStepPassword(params) {
  const response = await backend.post(`/auth/step`, {
    ...params,
    language: localisationService.getLanguage(),
  });
  user = extendUserAttributes(response.user);
  session = response.session;
  return response;
}

async function guestLogin(email, shareSessionStepId) {
  logger.info(TAG, "started guest login", { email, shareSessionStepId });
  const response = await backend.get(`/auth/guest/authorize`, {
    email,
    shareSessionStepId,
  });
  return await onSuccessfulAuthentication(response);
}

function updatePasswordWithOtp(email, code, newPassword) {
  return backend.put(`/auth/user/update-forgot-password`, {
    email,
    code,
    newPassword,
  });
}

function sendOTPForEmailChange(newEmail, password) {
  return backend.post(`/auth/otp/send/email-change`, {
    newEmail,
    password,
    language: localisationService.getLanguage(),
  });
}

function sendOTP(email, isVerifiedApproval) {
  return backend.post(`/auth/otp/send`, {
    email,
    language: localisationService.getLanguage(),
    ...(isVerifiedApproval ? { isVerifiedApproval } : {}),
  });
}

function verifyOTPForSignup(email, code) {
  return backend.post(`/auth/otp/verify`, { email, code });
}

async function verifyOTP(email, code, isReviewer = false) {
  const url = isReviewer ? "/auth/otp/verify" : "/auth/otp/verify/email-change";
  const response = await backend.post(url, { email, code });
  if (response.session) {
    session = response.session;
    eventService.emitEvent({
      eventName: EVENT_NAMES.USER.AUTHENTICATED,
      eventData: { user, session },
    });
  }
  return response;
}

async function enforcePasswordChange(newPassword) {
  logger.info(TAG, "started enforce password change");
  await backend.put(`/users/me/weak-password`, { newPassword });
  session.weakPassword = false;
}

function forgotPassword(email) {
  return backend.post(`/auth/users/password/forgot`, {
    email,
    language: localisationService.getLanguage(),
  });
}

function setBasePlugin(plugin) {
  localStorage.setItem("plugin", plugin);
}

function getBasePlugin() {
  return localStorage.getItem("plugin");
}

async function generatePluginApiKey(teamId) {
  const pluginId = getBasePlugin();

  if (pluginId) {
    const { token, userId } = await backend.post(`/plugins/api-token`, {
      type: pluginId,
      teamId,
    });
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_API_TOKEN,
        data: {
          apiToken: token,
          userId,
          teamId,
        },
      },
      "*"
    );
  }
}

function startExternalLogin(connection, authenticationState) {
  logger.info(TAG, "start external sign in", { connection });
  healthMetrics.trackStart("integrations.enterprise-sso");
  setAuthenticationState(authenticationState);
  const redirect = new URL("/auth/enterprise", window.fs.apiEndpoint);
  redirect.searchParams.append("connection", connection);
  if (authenticationState.iframe) {
    redirect.searchParams.append("iframe", authenticationState.iframe);
  }
  if (authenticationState.shareSessionStepId) {
    redirect.searchParams.append(
      "shareSessionStepId",
      authenticationState.shareSessionStepId
    );
  }
  window.location = redirect;
}

async function finishExternalLogin(csrf) {
  createCsrfSessionInEmbed({ csrf });

  await authenticate();

  healthMetrics.trackSuccess("supporting.authentication");
  if (session.identity.isEnterpriseSSO) {
    healthMetrics.trackSuccess("integrations.enterprise-sso");
  }
  return setSignupCookiesAndGetAuthState(getAuthenticationState());
}

// window.addEventListener("focus", validateSessionAndUser);

export default {
  fetchUser,
  fetchSession,
  nameFrom,
  authenticate,
  login,
  sendOTPForSignup,
  signup,
  logout,
  startExternalLogin,
  impersonate,
  checkIsAdmin,
  startStepShareSession,
  checkStepPassword,
  guestLogin,
  updatePasswordWithOtp,
  sendOTPForEmailChange,
  sendOTP,
  verifyOTPForSignup,
  verifyOTP,
  enforcePasswordChange,
  forgotPassword,
  setBasePlugin,
  generatePluginApiKey,
  setAuthenticationState,
  getBasePlugin,
  fetchIdentity,
  finishExternalLogin,
  generatePluginSessionId,
  onSuccessfulAuthentication,
  getAuthenticationState,
  validateSessionAndUser,
  redirectToLogout,
  guestSignup,
};
