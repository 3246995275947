import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import UpdateCreditCardDialog from "@supporting/components/UpdateCreditCardDialog/UpdateCreditCardDialog";

import { Tooltip } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { useQueryParams } from "@shared/hooks/useQueryParams";
import FSTGTypography from "@shared/theme/typography";

import AddCreditCardIcon from "@assets/img/icons/ic_add_24px.svg";
import UpdateCreditCardIcon from "@assets/img/icons/ic_swap_vert_24px.svg";

const useStyles = makeStyles((theme) => ({
  actionButtonTooltip: {
    fontWeight: theme.typography.fontWeight.bold,
    fontSize: FSTGTypography.fontSize_1_7,
    padding: "10px 20px 8px 20px",
    margin: "8px 0 8px 8px",
    "&:disabled": {
      border: 0,
      color: theme.color["black-translucent-38"],
      backgroundColor: "transparent",
    },
  },
}));

function OpenCreditCardDialog({ creditCards, updatePaymentList, action }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const creditCardDialog = useDialogState();
  const [, setQueryParams] = useQueryParams();
  useEffect(() => {
    if (action === "update-credit-card") {
      creditCardDialog.openDialog();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDialogClose = () => {
    setQueryParams({ action: null });
    creditCardDialog.closeDialog();
  };

  return (
    <>
      <Tooltip
        data-testid={
          creditCards.length ? "update-credit-card" : "add-credit-card"
        }
        title={
          creditCards.length
            ? t("BILLING.CHANGE_PAYMENT_DETAILS.REPLACE_CARD")
            : t("BILLING.CHANGE_PAYMENT_DETAILS.ADD_CARD")
        }
        className={classes.actionButtonTooltip}
      >
        <Button
          size="large"
          onClick={creditCardDialog.openDialog}
          variant="contained"
          sx={{
            borderRadius: "50%",
            padding: 2,
            aspectRatio: "1/1",
            position: "absolute",
            right: 20,
            bottom: -20,
          }}
          data-testid="open-credit-card-dialog"
        >
          <SvgIcon
            component={
              creditCards.length ? UpdateCreditCardIcon : AddCreditCardIcon
            }
            sx={{ root: { padding: 5 } }}
          />
        </Button>
      </Tooltip>
      <UpdateCreditCardDialog
        closeDialog={onDialogClose}
        isOpen={creditCardDialog.isOpen}
        creditCards={creditCards}
        updatePaymentList={updatePaymentList}
      />
    </>
  );
}

OpenCreditCardDialog.propTypes = {
  creditCards: PropTypes.array.isRequired,
  updatePaymentList: PropTypes.func.isRequired,
  action: PropTypes.string,
};
OpenCreditCardDialog.defaultProps = {
  action: null,
};

export default OpenCreditCardDialog;
