import PropTypes from "prop-types";
import { forwardRef } from "react";
import { NavLink } from "react-router-dom";

import classNames from "classnames";

const RouterNavLink = forwardRef(
  ({ to, activeClassName, className, children, ...props }, ref) => (
    <NavLink
      ref={ref}
      to={to}
      className={({ isActive }) =>
        classNames(className, { [activeClassName]: isActive })
      }
      {...props}
    >
      {children}
    </NavLink>
  )
);

RouterNavLink.displayName = "MyNavLink";
RouterNavLink.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
RouterNavLink.defaultProps = {
  activeClassName: "",
  className: "",
};

export default RouterNavLink;
