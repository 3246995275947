import { ReactElement } from "react";
import { FiUser } from "react-icons/fi";

import { Avatar as MUIAvatar, SvgIcon } from "@mui/material";

import { userProp } from "@shared/props/authentication";

/**
 * UIKit Avatar Component
 * @description Renders an avatar using user's fullName or email and generates initials.
 * If user doesn't have a fullName or email, it'll show the default avatar. It extends
 * Material UI Avatar component.
 * @typedef UserObject
 * @param {{ user: UserObject }} props - Component props
 * @param {UserObject} props.user - [Required] User object.
 * @returns {ReactElement} - React component
 */
function Avatar({ user, ...restProps }) {
  const avatarSrc = (user.avatars && user.avatars[0]?.url) || "";

  return (
    <MUIAvatar
      alt={avatarSrc ? `${user.fullName} avatar` : ""}
      src={avatarSrc}
      {...restProps}
    >
      {user.initials || <SvgIcon component={FiUser} />}
    </MUIAvatar>
  );
}

Avatar.propTypes = {
  user: userProp.isRequired,
};

export default Avatar;
