import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Close,
  ExpandLess,
  ExpandMore,
  ErrorOutline,
} from "@mui/icons-material";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";
import classNames from "classnames";

import { Box, Text, Tooltip } from "@shared/UIKit";

import useDialogState from "@shared/hooks/useDialogState";
import errorHandlerService from "@shared/services/errorHandler";
import FSTGTypography from "@shared/theme/typography";

import RemoveTeamDomainDialog from "./RemoveTeamDomainDialog";
import VerifyTeamDomainDialog from "./VerifyTeamDomainDialog";

const useStyles = makeStyles((theme) => ({
  domain: {
    borderRadius: 6,
    backgroundColor: theme.color.gray[50],
  },
  domainName: {
    width: 140,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start",
    paddingLeft: 10,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 4,
    },
  },
  label: {
    width: 70,
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "start",
  },
  removeButton: {
    color: theme.color.gray[400],
  },
  unverifiedDomainContainer: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.color.red[500],
  },
  menuList: {
    maxWidth: 140,
  },
}));

const APPROVAL_OPTIONS = ["YES", "NO"];

function TeamDomainItem({
  domain,
  teamRoles,
  teamName,
  updateTeamDomain,
  removeTeamDomain,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [anchorRoleMenu, setAnchorRoleMenu] = useState(null);
  const [anchorRequestApprovalMenu, setAnchorRequestApprovalMenu] =
    useState(null);

  const getRoleName = (selectedRoleId) => {
    const role = teamRoles.find((role) => role._id === selectedRoleId);
    return role?.isDefault ? t(`TEAM.ROLES.${role.roleName}`) : role?.roleName;
  };

  const { isOpen, openDialog, closeDialog } = useDialogState();
  const {
    isOpen: isVerifyTeamDomainDialogOpen,
    openDialog: openVerifyTeamDomainDialog,
    closeDialog: closeVerifyTeamDomainDialog,
  } = useDialogState();

  const handleOpenRoleMenu = (event) => {
    setAnchorRoleMenu(event.currentTarget);
  };

  const handleCloseRoleMenu = () => {
    setAnchorRoleMenu(null);
  };

  const handleRoleMenuSelection = (event) => {
    updateTeamDomain(domain.id, { defaultRole: event.target.id });
    handleCloseRoleMenu();
  };

  const handleOpenRequestApprovalMenu = (event) => {
    setAnchorRequestApprovalMenu(event.currentTarget);
  };

  const handleCloseRequestApprovalMenu = () => {
    setAnchorRequestApprovalMenu(null);
  };

  const handleRequestApprovalMenuSelection = (event) => {
    updateTeamDomain(domain.id, {
      requestApproval: event.target.id === APPROVAL_OPTIONS[0],
    });
    handleCloseRequestApprovalMenu();
  };

  const handleRemoveTeamDomain = async (domainId) => {
    try {
      await teamService.removeTeamDomain(domainId);
      toastService.sendToast({
        title: "JOIN_WITH_SAME_DOMAIN.REMOVE_TOAST.TITLE",
        body: "JOIN_WITH_SAME_DOMAIN.REMOVE_TOAST.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            teamName,
          },
        },
      });
      removeTeamDomain(domainId);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const handleResendVerifyDomainMail = async (
    domainName,
    defaultRole,
    recipientEmail
  ) => {
    await teamService.addTeamDomain(domainName, defaultRole, recipientEmail);
  };

  return (
    <Box display="flex" mt={1}>
      <Box flex={3} display="flex" minWidth={120}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="80%"
          height={30}
          title={domain.domainName}
          className={classNames(classes.domain, {
            [classes.unverifiedDomainContainer]: !domain.isVerified,
          })}
        >
          <Text
            fontSize={FSTGTypography.fontSize_1_4}
            fontWeight={FSTGTypography.fontWeightRegular}
            color={theme.color.gray[400]}
            className={classes.domainName}
          >
            {domain.domainName}
          </Text>
          {!domain.isVerified && (
            <Tooltip placement="bottom" title={t("TEAM_LINK.VERIFY.TOOLTIP")}>
              <IconButton
                size="small"
                data-testid="open-verify-domain-dialog-button"
                onClick={openVerifyTeamDomainDialog}
              >
                <ErrorOutline fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box flex={2} display="flex" minWidth={120}>
        <Box>
          <Button
            data-testid="open-role-menu"
            variant="outlined"
            color="default-light"
            size="small"
            onClick={handleOpenRoleMenu}
            endIcon={anchorRoleMenu ? <ExpandLess /> : <ExpandMore />}
          >
            {getRoleName(domain.defaultRole)}
          </Button>
          <Menu
            keepMounted
            id="team-role"
            anchorEl={anchorRoleMenu}
            open={Boolean(anchorRoleMenu)}
            onClose={handleCloseRoleMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className={classes.menuList}
          >
            {teamRoles.map((teamRole) => {
              return (
                <MenuItem
                  id={teamRole._id}
                  key={teamRole._id}
                  onClick={handleRoleMenuSelection}
                >
                  <Text
                    textAlign="left"
                    id={teamRole._id}
                    variant="subtitle1"
                    color={theme.color.gray[600]}
                  >
                    {getRoleName(teamRole._id)}
                  </Text>
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
      </Box>
      <Box flex={3} display="flex" minWidth={120}>
        <Box>
          <Button
            data-testid="open-request-approval-menu"
            variant="outlined"
            color="default-light"
            size="small"
            onClick={handleOpenRequestApprovalMenu}
            endIcon={
              anchorRequestApprovalMenu ? <ExpandLess /> : <ExpandMore />
            }
          >
            {t(`CORE.${domain.requestApproval ? "YES" : "NO"}`)}
          </Button>
          <Menu
            keepMounted
            id="team-role"
            anchorEl={anchorRequestApprovalMenu}
            open={Boolean(anchorRequestApprovalMenu)}
            onClose={handleCloseRequestApprovalMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            className={classes.menuList}
          >
            {APPROVAL_OPTIONS.map((option) => {
              return (
                <MenuItem
                  id={option}
                  key={option}
                  onClick={handleRequestApprovalMenuSelection}
                >
                  <Text
                    textAlign="left"
                    id={option}
                    variant="subtitle1"
                    color={theme.color.gray[600]}
                  >
                    {t(`CORE.${option}`)}
                  </Text>
                </MenuItem>
              );
            })}
          </Menu>
        </Box>
        <Tooltip
          placement="bottom"
          title={t("JOIN_WITH_SAME_DOMAIN.REMOVE_MODAL.TITLE")}
        >
          <IconButton
            size="small"
            data-testid="open-remove-domain-dialog-button"
            onClick={openDialog}
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <RemoveTeamDomainDialog
        cancel={closeDialog}
        isOpen={isOpen}
        domainId={domain.id}
        answer={handleRemoveTeamDomain}
      />
      <VerifyTeamDomainDialog
        isOpen={isVerifyTeamDomainDialogOpen}
        cancel={closeVerifyTeamDomainDialog}
        domainName={domain.domainName}
        defaultRole={domain.defaultRole}
        answer={handleResendVerifyDomainMail}
      />
    </Box>
  );
}

TeamDomainItem.propTypes = {
  domain: PropTypes.object.isRequired,
  teamRoles: PropTypes.array.isRequired,
  teamName: PropTypes.string.isRequired,
  updateTeamDomain: PropTypes.func.isRequired,
  removeTeamDomain: PropTypes.func.isRequired,
};

export default TeamDomainItem;
