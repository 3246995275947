import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { PersonAddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  warningWrapper: {
    color: theme.color.blue[500],
    textAlign: "left",
    backgroundColor: "#F3FBFF",
    position: "absolute",
    zIndex: 99,
    right: 15,
    borderRadius: "3px",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    maxWidth: "90%",
    "@media (max-width: 450px)": {
      minWidth: "fit-content",
      bottom: "20px",
      display: "flex !important",
      flexDirection: "column !important",
      left: 0,
      minHeight: "140px",
      margin: "10px",
      textAlign: "center",

      "& svg": {
        display: "none",
      },
    },
  },
  warningIcon: {
    marginRight: "5px",
  },
}));

function SeatUpsellPopup({ onSubmit, text, isMultiple, isDisabled }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box
      className={classes.warningWrapper}
      display="flex"
      flexDirection="row"
      alignItems="center"
      data-testid="seat-upsell-popup"
      gap={2}
    >
      <PersonAddOutlined fontSize="small" className={classes.warningIcon} />
      <Text fontSize={FSTGTypography.fontSize_1_3}>
        <Trans>{text}</Trans>
      </Text>
      <Button
        size="large"
        color="upgrade"
        shape="rounded"
        variant="contained"
        onClick={onSubmit}
        data-testid="seat-upsell-popup-confirm"
        disabled={isDisabled}
      >
        {isMultiple
          ? t("PROJECT-COLLABORATOR.DIALOG.SEAT-LIMIT-REACHED.CONFIRM.CTA-MORE")
          : t(
              "PROJECT-COLLABORATOR.DIALOG.SEAT-LIMIT-REACHED.CONFIRM.CTA-1-SEAT"
            )}
      </Button>
    </Box>
  );
}

SeatUpsellPopup.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

SeatUpsellPopup.defaultProps = {
  isMultiple: false,
  isDisabled: false,
};

export default SeatUpsellPopup;
