import NiceModal from "@ebay/nice-modal-react";
import { useParams, useSearchParams } from "react-router-dom";

import getUsedTeamStoragePercentage from "@supporting/helpers/getUsedTeamStoragePercentage";
import useActiveUser from "@supporting/hooks/useActiveUser";
import { AddSecureStorageGate } from "@supporting/hooks/useGatedFeature/featureGates";
import { instance as teamService } from "@supporting/services/team";

import { useCurrentRouteName, useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import UploadPanelDialog from "./UploadPanelDialog";

/**
 * Use Upload Panel Dialog hook
 *
 * @description This hook defines the `openUploadPanelDialog` function that opens the Upload Panel Dialog.
 * It checks if the user is a guest and redirects to the login page if so.
 * It also checks if the team is out of storage and shows the `UpgradeStoragePlanDialog` if needed.
 * @returns {{
 *  openUploadPanelDialog: Function,
 * }} Returns the `openUploadPanelDialog` Function values.
 */
export default function useUploadPanelDialog(props) {
  const { goTo } = useNavigation();
  const currentRouteName = useCurrentRouteName();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const user = useActiveUser();

  const isOutOfStorage = (team) => {
    return getUsedTeamStoragePercentage(team) >= 100;
  };

  const openUploadPanelDialog = async (dialogProps) => {
    if (user.session?.type === "Guest") {
      return goTo("LOGIN", {
        options: {
          state: {
            redirect: {
              state: currentRouteName,
              params,
              search: searchParams.toString(),
            },
          },
        },
      });
    }
    const currentTeam = await teamService.fetchTeamByTeamId(props.teamId);
    if (isOutOfStorage(currentTeam)) {
      analytics.trackV2({
        action: analytics.ACTION.REACHED,
        category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        subcategory: analytics.SUB_CATEGORY.STORAGE_QUANTITY,
      });
      return NiceModal.show("UpgradeStoragePlanDialog", {
        ...AddSecureStorageGate.upgradeDialogsProps,
        teamId: props.teamId,
        teamName: currentTeam.name,
        isFreePlan: currentTeam.subscription?.basePrice.name
          .toLowerCase()
          .includes("free"),
      });
    }

    return NiceModal.show(UploadPanelDialog, {
      ...props,
      ...dialogProps,
    });
  };

  return {
    openUploadPanelDialog,
  };
}
