import { useState, useEffect, useCallback, useMemo } from "react";

import { Box, Paper, Table, TableBody, TableContainer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { instance as teamService } from "@supporting/services/team";
import teamRoleService from "@supporting/services/teamRoleService";
import orderBy from "lodash/orderBy";

import pagesEnum from "@shared/constants/pages";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import MembersTableHeader from "@supporting/pages/Teams/members/MembersTableHeader";
import PendingMembersRow from "@supporting/pages/Teams/members/PendingMembersRow";

import CreateTeamButton from "../CreateTeamButton";
import InviteTeamMemberButton from "./InviteTeamMemberButton/InviteTeamMemberButton";
import TeamMemberRow from "./TeamMemberRow/TeamMemberRow";

const getTableHeaders = (isPendingMember) => [
  { id: "avatar" },
  {
    id: "name",
    sortBy: isPendingMember ? "displayName" : "user.displayName",
    label: "TEAM.CARD_MEMBERS_NAME",
  },
  {
    id: "email",
    sortBy: isPendingMember ? "email" : "user.email",
    label: "TEAM.CARD_MEMBERS_EMAIL",
  },
  {
    id: "role",
    sortBy: isPendingMember ? "roleName" : "role.roleName",
    label: "TEAM.CARD_MEMBERS_ROLE",
  },
];

const useStyles = makeStyles((theme) => ({
  pendingMembersTable: {
    borderBottom: `solid 12px ${theme.color.gray[250]}`,
  },
}));

const extractEmailDomain = (email) => email?.split("@")[1].toLowerCase();

const getDefaultRoleByDomain = (domains, teamRoles) => {
  const rolesByDomain = new Map();
  domains.forEach((domain) => {
    rolesByDomain.set(
      domain.domainName,
      teamRoles.find((roles) => roles._id === domain.defaultRole)
    );
  });
  return rolesByDomain;
};

function TeamMembersSettingsTab() {
  const classes = useStyles();
  const [team, setTeam] = useState(teamService.getSelectedTeam());
  const [teamRoles, setTeamRoles] = useState([]);
  const teamMembers = team.members;
  const canManageMembers = team.permissions.canManageMembers;

  const domainDefaultRole = useMemo(() => {
    if (
      !teamRoles.length ||
      !team.domains?.length ||
      !team.pendingMembers.length
    ) {
      return;
    }
    return getDefaultRoleByDomain(team.domains, teamRoles);
  }, [teamRoles, team.domains, team.pendingMembers.length]);

  const teamMembersSortingUpdate = useCallback((sorting) => {
    setTeam((prevProps) => ({
      ...prevProps,
      members: orderBy(prevProps.members, sorting.sortBy, sorting.order),
    }));
  }, []);

  const pendingMembersSortingUpdate = useCallback((sorting) => {
    setTeam((prevProps) => ({
      ...prevProps,
      pendingMembers: orderBy(
        prevProps.pendingMembers,
        sorting.sortBy,
        sorting.order
      ),
    }));
  }, []);

  useEffect(() => {
    const onTeamChange = function (event) {
      const team = event.eventData.team;
      setTeam(team);
    };

    const getTeamRoles = async () => {
      const newRoles = await teamRoleService.fetchTeamRoles(team._id);
      setTeamRoles(newRoles);
    };
    getTeamRoles();

    eventService.addListener(EVENT_NAMES.TEAM.SELECTED.UPDATED, onTeamChange);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.TEAM.SELECTED.UPDATED,
        onTeamChange
      );
    };
  }, [team._id]);

  return (
    <>
      <CreateTeamButton />
      <Box display="flex" justifyContent="end" py={2}>
        {canManageMembers && (
          <InviteTeamMemberButton team={team} page={pagesEnum.TEAM_SETTINGS} />
        )}
      </Box>
      <TableContainer component={Paper}>
        <Table>
          {team.pendingMembers.length > 0 && canManageMembers && (
            <>
              <MembersTableHeader
                canManageMembers
                headers={getTableHeaders(true)}
                onSortOrderChange={pendingMembersSortingUpdate}
              />
              <TableBody className={classes.pendingMembersTable}>
                {Boolean(teamRoles.length) &&
                  team.pendingMembers.map((member) => (
                    <PendingMembersRow
                      teamId={team._id}
                      member={member}
                      key={member._id}
                      defaultRole={
                        domainDefaultRole?.get(
                          extractEmailDomain(member.email)
                        ) ||
                        (team.settings.inviteLink &&
                          team.inviteLinkRoles[
                            team.settings.inviteLink.defaultRole
                          ])
                      }
                    />
                  ))}
              </TableBody>
            </>
          )}
          <MembersTableHeader
            canManageMembers={canManageMembers}
            headers={getTableHeaders(false)}
            onSortOrderChange={teamMembersSortingUpdate}
          />
          <TableBody>
            {teamMembers.map((member) => (
              <TeamMemberRow
                team={team}
                member={member}
                key={member.user._id}
                canManageMembers={canManageMembers}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TeamMembersSettingsTab;
