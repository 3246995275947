import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";

export default function EditTeamButton({
  saveTeamSettings,
  disabled,
  loading,
}) {
  const { t } = useTranslation();

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={saveTeamSettings}
      data-testid="edit-team-settings"
      aria-label={t("SETTINGS.PROFILE.SUBMIT")}
      disabled={disabled}
      loading={loading}
      disableRipple
      size="large"
    >
      {t("SETTINGS.PROFILE.SUBMIT")}
    </Button>
  );
}

EditTeamButton.propTypes = {
  saveTeamSettings: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
};

EditTeamButton.defaultProps = {
  disabled: true,
};
