import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";

import { Dialog, Text } from "@shared/UIKit";

const DisableReviewDecisionDialog = ({
  isOpen,
  cancel,
  disableReviewDecision,
}) => {
  const { t } = useTranslation();

  const actions = (
    <>
      <Button variant="outlined" onClick={cancel}>
        {t("CORE.CANCEL")}
      </Button>

      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          disableReviewDecision();
          cancel();
        }}
      >
        {t("DISABLE_MODAL.CUSTOM_REVIEW_DECISION.DISABLE.BUTTON")}
      </Button>
    </>
  );

  return (
    <Dialog
      maxWidth="xs"
      hideBackdrop={false}
      disableBackdropClick
      open={isOpen}
      cancel={cancel}
      title={t("DISABLE_MODAL.CUSTOM_REVIEW_DECISION.TITLE")}
      actions={actions}
    >
      <Text variant="textSm" color="primary.500">
        {t("DISABLE_MODAL.CUSTOM_REVIEW_DECISION.BODY")}
      </Text>
    </Dialog>
  );
};

DisableReviewDecisionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  disableReviewDecision: PropTypes.func.isRequired,
};

export default DisableReviewDecisionDialog;
