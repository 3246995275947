import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import Features from "./Features";
import WorkflowFeaturesDisplay from "./WorkflowFeaturesDisplay";

function WorkflowFeatures({ team, openBillingDialog }) {
  const { t } = useTranslation();

  return (
    <Features title={t("BILLING.FEATURE.WORKFLOW")}>
      <WorkflowFeaturesDisplay
        team={team}
        openBillingDialog={openBillingDialog}
      />
    </Features>
  );
}

WorkflowFeatures.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default WorkflowFeatures;
