import { useTranslation } from "react-i18next";

import { GetAppOutlined } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

import { versionProp } from "@shared/props/file";

function DownloadVersionMenuItem({ version }) {
  const { t } = useTranslation();

  return (
    <MenuItem
      data-testid="project-file-menu-download-version"
      component="a"
      href={version.downloadURL}
      download
    >
      <ListItemIcon>
        <GetAppOutlined />
      </ListItemIcon>
      <ListItemText>
        {t("FILE.MENU.DOWNLOAD_VERSION", {
          versionNumber: version.number,
        })}
      </ListItemText>
    </MenuItem>
  );
}

DownloadVersionMenuItem.propTypes = {
  version: versionProp.isRequired,
};

export default DownloadVersionMenuItem;
