import PropTypes from "prop-types";

import { Popover } from "@mui/material";
import { Box } from "@mui/system";

import { Text } from "@shared/UIKit";

function UploadPanelFileList({
  fileListOpen,
  anchorEl,
  handleFileListClose,
  files,
}) {
  return (
    <Popover
      data-testid="file-list-popover"
      id="file-list-popover"
      open={fileListOpen}
      anchorEl={anchorEl}
      onClose={handleFileListClose}
      sx={{
        px: 1,
        py: 0.5,
        borderRadius: 1,
        mt: 0.5,
        maxHeight: "260px",
        maxWidth: "220px",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Box component="ul" pr={2} pl={3.5} mt={0.5} mb={0.25} maxWidth="100%">
          {files.map((file) => (
            <Box component="li" key={file.name} width="100%">
              <Text
                variant="textSm"
                component="p"
                sx={{
                  wordWrap: "break-word",
                }}
              >
                {file.name}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </Popover>
  );
}

UploadPanelFileList.propTypes = {
  fileListOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  handleFileListClose: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
};

UploadPanelFileList.defaultProps = {
  anchorEl: null,
};

export default UploadPanelFileList;
