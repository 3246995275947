import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, SvgIcon } from "@mui/material";
import noop from "lodash/noop";

import { UpsellPopup } from "@shared/UIKit";

import Upgrade from "@assets/img/icons/ic_upgrade_24px.svg";

import { useUploadPanelStyles } from "./UploadPanel.styles";

function RemoteButton({
  testId,
  onClick,
  viewBox,
  icon,
  label,
  disabled,
  handleUpgradeAction,
  isFeatureEnabled,
  noUpsellPopup,
}) {
  const classes = useUploadPanelStyles();
  const { t } = useTranslation();

  const content = (
    <Button
      variant="outlined"
      color="primaryV2"
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      size="small"
      startIcon={<SvgIcon viewBox={viewBox} component={icon} />}
      endIcon={
        !isFeatureEnabled && (
          <SvgIcon
            data-testid="upload-panel-upgrade-icon"
            className={classes.UpgradeIcon}
            component={Upgrade}
          />
        )
      }
      sx={{
        flex: { xs: "0 0 45%", md: "1" },
        whiteSpace: "nowrap",
        minWidth: "fit-content",
      }}
    >
      {label}
    </Button>
  );

  return noUpsellPopup || isFeatureEnabled ? (
    content
  ) : (
    <UpsellPopup
      primaryText={t("FILE.UPLOAD_PANEL.UPGRADE")}
      buttonAction={handleUpgradeAction}
      buttonText={t("BILLING.UPGRADE")}
      placement="bottom"
    >
      {content}
    </UpsellPopup>
  );
}

RemoteButton.propTypes = {
  testId: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  viewBox: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  handleUpgradeAction: PropTypes.func,
  isFeatureEnabled: PropTypes.bool.isRequired,
  noUpsellPopup: PropTypes.bool,
};

RemoteButton.defaultProps = {
  disabled: false,
  noUpsellPopup: false,
  handleUpgradeAction: noop,
};

export default RemoteButton;
