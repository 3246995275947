import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Grid } from "@mui/material";
import StepIndicator from "@supporting/components/WizardBase/StepIndicator";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import Cookies from "js-cookie";

import { Text, Box } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import ipapi from "@shared/services/ipapi";

import { COMPANY_SIZES } from "./constants";

const CURRENT_STEP = 1;

function CompanySize() {
  const { t } = useTranslation();
  const { routeNames, goTo } = useNavigation();

  const [location, setLocation] = useState();
  const [submitIsInProgress, setSubmitIsInProgress] = useState(false);
  const [companySize, setCompanySize] = useState(null);
  const team = teamService.getSelectedTeam();

  const companySizes = COMPANY_SIZES.map((item) => {
    return {
      min: item.min,
      max: item.max,
      format: item.format,
      value:
        item.min === 1
          ? "ONBOARDING_WIZARD.COMPANY_SIZE.JUST_ME"
          : `ONBOARDING_WIZARD.COMPANY_SIZE.FORMAT-${item.format.replace(
              "_",
              "-"
            )}`,
    };
  });

  const signupReason = Cookies.get("authentication:signup-form:reason");
  if (signupReason) {
    Cookies.remove("authentication:signup-form:reason");

    analytics.track(
      analytics.ACTION.STARTED,
      analytics.CATEGORY.ONBOARDING_WIZARD
    );
  }

  let selectedUserType = Cookies.get("onboarding:signup-reason");
  if (!selectedUserType) {
    selectedUserType = "collaborator";
    Cookies.set("onboarding:signup-reason", selectedUserType);
  }

  useEffect(() => {
    async function fetchLocation() {
      healthMetrics.trackAttempt("supporting.onboarding");
      const foundLocation = await ipapi.fetchLocationInformation();
      setLocation(foundLocation);
    }
    fetchLocation();
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.COMPANY_SIZE_PAGE
    );
  }, []);

  const formatLocation = (location) => {
    if (!location) {
      return {};
    }

    return {
      "Continent Code": location.continent_code,
      "Country Code": location.country_code,
      "Country Name": location.country_name,
      "Region Code": location.region_code,
      "Region Name": location.region,
      City: location.city,
      "Time Zone Id": location.timezone,
    };
  };

  const calculateSize = (companySize) => {
    return companySize.max || companySize.min;
  };

  const submit = async (companySize) => {
    /* istanbul ignore next */
    if (submitIsInProgress) {
      return;
    }
    setCompanySize(companySize);

    const cookieCompanySizeKey = "onboarding:company-size";
    Cookies.set(cookieCompanySizeKey, companySize.format);

    const formattedLocation = formatLocation(location);

    setSubmitIsInProgress(true);

    healthMetrics.trackStart("supporting.onboarding");

    const userId = authenticationService.fetchSession().userId;

    const size = calculateSize(companySize);

    try {
      const analyticsSignupReason = "Manage own projects";

      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.COMPANY_SIZE,
        {
          teamId: team._id,
          companySizeRange: companySize.format,
          companySize: size,
        }
      );

      analytics.identify(userId, {
        companySize: size,
        companyId: team._id,
        companyName: team.name,
        companySignupReason: analyticsSignupReason,
        companyCreatedAt: new Date().toISOString(),
        company: {
          id: team._id,
          "Signup Reason": analyticsSignupReason,
          company_size_range: companySize.format,
          size,
        },
        ...formattedLocation,
      });

      analytics.group(team._id, {
        "Signup Reason": analyticsSignupReason,
        company_size_range: companySize.format,
        size,
        ...formattedLocation,
      });
      healthMetrics.trackSuccess("supporting.onboarding");

      await teamService.wizardProgressTrack(team._id, {
        currentStep: "inviteTeamMembers",
        reason: selectedUserType,
        companySize: companySize.format,
      });
      goTo(routeNames.ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS);
    } catch (error) {
      healthMetrics.trackFailure("supporting.onboarding", error);
      setSubmitIsInProgress(false);
    }
  };

  return (
    <WizardBase variant="COMPANY_SIZE">
      <Box
        gap={5}
        flexGrow={1}
        flexDirection="column"
        flexWrap="unset"
        display="flex"
      >
        <Box gap={2} display="flex" flexDirection="column">
          <StepIndicator currentStep={CURRENT_STEP} />
          <Text
            variant="text3xl"
            fontWeight="fontWeight.medium"
            translate="COMPANY_INFO.ONBOARDING.HEADER"
          />
          <Text
            color="grey.600"
            variant="textSm"
            translate="ONBOARDING_WIZARD.COMPANY_SIZE.TITLE"
          />
        </Box>
        <Grid container spacing={2}>
          {companySizes.map((companySizeOption) => (
            <Grid item xs={4} key={companySizeOption.value}>
              <Button
                color="primaryV2"
                variant={
                  companySizeOption.value === companySize?.value
                    ? "contained"
                    : "outlined"
                }
                fullWidth
                size="large"
                sx={{ textWrap: "nowrap" }}
                onClick={() => submit(companySizeOption)}
                disabled={submitIsInProgress}
                data-testid="company-size"
                loading={
                  submitIsInProgress &&
                  companySizeOption.value === companySize?.value
                }
              >
                {t(companySizeOption.value)}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Box>
    </WizardBase>
  );
}

export default CompanySize;
