import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import { Typography } from "@mui/material";

import { Box, InfoToolTip } from "@shared/UIKit";

const Feature = ({ text, tooltipText, amount }) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <CheckIcon sx={{ color: "grey.500" }} />
      <InfoToolTip placement="right" title={t(tooltipText, { amount })}>
        <Typography
          ml={1}
          mb={1.5}
          display="table"
          variant="body2"
          color="grey.800"
        >
          <Trans values={{ amount }}>{text}</Trans>
        </Typography>
      </InfoToolTip>
    </Box>
  );
};

Feature.propTypes = {
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  amount: PropTypes.number,
};

Feature.defaultProps = {
  amount: 0,
};

export default Feature;
