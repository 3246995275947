import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Link } from "@mui/material";

import { InfoToolTip } from "@shared/UIKit";

export default function AutomationLinkHelpIcon({ translation }) {
  return (
    <InfoToolTip
      data-testid="automations-help-tooltip"
      title={
        <Trans
          i18nKey={translation}
          components={{ anchor: <Link color="accent" target="_blank" /> }}
        />
      }
      placement="bottom"
    >
      <HelpOutlineIcon
        data-testid="automations-help-icon"
        fontSize="small"
        color="default-light"
      />
    </InfoToolTip>
  );
}

AutomationLinkHelpIcon.propTypes = {
  translation: PropTypes.string.isRequired,
};
