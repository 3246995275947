import { instance as logger } from "@shared/services/logger";

import { getCurrentLocaleForDateFns } from "./dateutils";

const getFormattedPrice = (price, count = 1) => {
  try {
    return Intl.NumberFormat(getCurrentLocaleForDateFns(), {
      style: "currency",
      currency: price.currency,
      currencyDisplay: "narrowSymbol",
    })
      .format((price.amount * count) / 100)
      .replace(/(\.|,)00/g, "");
  } catch (err) {
    /* istanbul ignore next */
    logger.error("supporting:getFormattedPrice", "failed to format price", {
      err,
    });
    /* istanbul ignore next */
    return Intl.NumberFormat(getCurrentLocaleForDateFns(), {
      style: "currency",
      currency: price.currency,
    })
      .format((price.amount * count) / 100)
      .replace(/(\.|,)00/g, "");
  }
};

export default getFormattedPrice;
