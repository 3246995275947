import PropTypes from "prop-types";
import { useEffect, useCallback, useState } from "react";

import { Box } from "@mui/material";
import ActivateSubscriptionDialog from "@supporting/components/ActivateSubscriptionDialog/ActivateSubscriptionDialog";
import ConfirmSubscriptionDialog from "@supporting/components/ConfirmSubscriptionDialog/ConfirmSubscriptionDialog";
import { instance as billingService } from "@supporting/services/billingService";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import omit from "lodash/omit";

import { Dialog } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { useQueryParams } from "@shared/hooks/useQueryParams";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import BillingRateOption from "./BillingRateOption";
import HelpButton from "./HelpButton";
import PlanCard from "./PlanCard";
import { isPlanDowngrade, billingPlans } from "./selectplan.helpers";

const PlanDialog = ({ page, cancel, isOpen, hideFree, team }) => {
  const [, setSearchParams] = useQueryParams();

  const currentPlan = team.subscription.basePrice;
  const currency = currentPlan.currency;
  const currentBillingRate = currentPlan.interval;
  const isSubscriptionOnTrail = team.subscription.status === "trialing";
  const canUpgradePlan = team.permissions.canManageBilling;

  const [billingRate, setBillingRate] = useState(currentBillingRate);
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const [billingPlanList, setBillingPlanList] = useState([]);
  const [couponData, setCouponData] = useState({});

  const {
    isOpen: isActivateSubscriptionDialogOpen,
    closeDialog: closeActivateSubscriptionDialog,
    openDialog: openActivateSubscriptionDialog,
  } = useDialogState();

  const {
    isOpen: isConfirmSubscriptionDialogOpen,
    closeDialog: closeConfirmSubscriptionDialog,
    openDialog: openConfirmSubscriptionDialog,
  } = useDialogState();

  let billingPlansDetails = cloneDeep(billingPlans);

  if (hideFree || team.subscription.isPaying) {
    billingPlansDetails = omit(billingPlansDetails, "free");
    billingPlansDetails["basic"].featureHeader = null;
    billingPlansDetails["basic"].features.main = merge(
      {},
      billingPlans["free"].features.main,
      billingPlans["basic"].features.main
    );
  }

  if (billingPlanList.length) {
    const alignedBillingPlanList = billingPlanList.concat(
      billingPlanList
        .filter((plan) => plan.name.toLowerCase().startsWith("free"))
        .map((plan) => ({ ...plan, interval: "year" }))
    );

    Object.keys(billingPlansDetails)
      .filter((planKey) => planKey !== "enterprise")
      .forEach((planName) => {
        billingPlansDetails[planName].data = alignedBillingPlanList.find(
          (plan) =>
            plan.name.toLowerCase().includes(planName) &&
            plan.currency === currency &&
            plan.interval === billingRate
        );
      });
  }

  useEffect(() => {
    async function fetchDefaultPlans() {
      const plans = await billingService.fetchDefaultPlans(team._id);
      setBillingPlanList(plans);
    }

    async function fetchCoupon() {
      const coupon = await billingService.getCoupon(team._id);
      setCouponData(coupon);
    }
    fetchDefaultPlans();
    if (team.permissions.canManageBilling) {
      fetchCoupon();
    }
  }, [team._id, team.permissions.canManageBilling]);

  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED_PRICING_PAGE,
      analytics.CATEGORY.SUBSCRIPTION,
      {
        subscriptionStatus: team.subscriptionStatus,
        subscriptionPlan: team.subscription.basePrice.name,
        page,
      }
    );
    healthMetrics.trackAttempt("supporting.billing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activateSubscription = async ({ plan }) => {
    setSelectedPlan(plan);
    const creditCards = await billingService.getPaymentMethods(team._id);
    if (creditCards?.length === 0) {
      return openActivateSubscriptionDialog();
    }
    openConfirmSubscriptionDialog();
  };

  const cancelActivateSubscriptionDialog = useCallback(() => {
    closeActivateSubscriptionDialog();
    cancel();
  }, [closeActivateSubscriptionDialog, cancel]);

  const cancelConfirmSubscriptionDialog = useCallback(() => {
    closeConfirmSubscriptionDialog();
    cancel();
  }, [closeConfirmSubscriptionDialog, cancel]);

  const selectPlan = useCallback(
    (plan) => {
      activateSubscription({ plan });
      setSearchParams({ action: null }, true);
      cancel();
    },
    [activateSubscription] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const cancelDialog = useCallback(() => {
    cancel();
    setSearchParams({ action: null }, true);
    healthMetrics.trackCancellation("supporting.billing");
  }, [cancel, setSearchParams]);

  return (
    Boolean(billingPlanList.length) && (
      <>
        <Dialog
          cancel={cancelDialog}
          open={isOpen}
          maxWidth={false}
          fullScreen
          hideBackdrop={false}
          showCloseButton
          disableBackdropClick={false}
          useDefaultBackground
        >
          <Box
            gap={2}
            height="100%"
            display="flex"
            minWidth={300}
            flexDirection="column"
          >
            <Box pb={2}>
              <Box position={{ md: "absolute" }}>
                <HelpButton href="mailto:support@filestage.io" />
              </Box>
              <BillingRateOption
                billingRate={billingRate}
                setBillingRate={setBillingRate}
              />
            </Box>
            <Box
              pb={1}
              gap={2}
              display="flex"
              flexWrap="wrap"
              maxHeight="auto"
              flexDirection="row"
              alignItems="stretch"
              alignContent="flex-start"
              justifyContent="space-evenly"
              sx={{ overflowY: "auto" }}
            >
              {Object.keys(billingPlansDetails).map((plan) => {
                return (
                  <Box key={plan} display="flex" justifyContent="center">
                    <PlanCard
                      plan={plan}
                      selectPlan={selectPlan}
                      currentPlan={currentPlan}
                      currentPeriodEnd={team.subscription.currentPeriodEnd}
                      isSubscriptionOnTrail={isSubscriptionOnTrail}
                      canUpgradePlan={canUpgradePlan}
                      billingPlans={billingPlansDetails}
                      showTrialEndedDialog={false}
                      team={team}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Dialog>
        {isActivateSubscriptionDialogOpen && (
          <ActivateSubscriptionDialog
            team={team}
            cancel={cancelActivateSubscriptionDialog}
            currentPlan={selectedPlan}
            billingPlanList={billingPlanList}
            couponData={couponData}
          />
        )}
        {isConfirmSubscriptionDialogOpen && (
          <ConfirmSubscriptionDialog
            team={team}
            cancel={cancelConfirmSubscriptionDialog}
            couponData={couponData}
            currentPlan={selectedPlan}
            isPlanDowngrade={isPlanDowngrade(currentPlan, selectedPlan)}
          />
        )}
      </>
    )
  );
};

PlanDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  hideFree: PropTypes.bool,
  page: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  team: teamProp.isRequired,
};

PlanDialog.defaultProps = {
  hideFree: false,
  page: undefined,
};

export default PlanDialog;
