import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";

import { Box, Text } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";

export default function GoogleAuthLogin({
  googleAuthState,
  handleClickToEmailPassword,
  isAdobePlugin,
  adobePluginSessionId,
  disabledInFirefox,
}) {
  const { routeNames } = useNavigation();
  const { t } = useTranslation();
  return (
    <Box
      gap={10}
      display="flex"
      alignItems="center"
      flexDirection="column"
      flex=".4 0 auto"
      justifyContent="center"
    >
      <Text
        variant="text3xl"
        fontWeight="fontWeight.medium"
        translate="LOGIN.HEADLINE"
      />
      <Box
        gap={2}
        display="flex"
        justifyContent="center"
        flexDirection="column"
        maxWidth={400}
      >
        <Text variant="body1" textAlign="center" color="grey.800">
          {t(
            disabledInFirefox
              ? "SIGN_IN.ALREADY_CONNECTED_WITH_GOOGLE.DISABLED"
              : "SIGN_IN.ALREADY_CONNECTED_WITH_GOOGLE.MESSAGE"
          )}
        </Text>
        {!disabledInFirefox && (
          <GoogleAuth
            showButtonOnly
            text="continue_with"
            testId="continue-with-google"
            authState={googleAuthState}
            isAdobePlugin={isAdobePlugin}
            adobePluginSessionId={adobePluginSessionId}
          />
        )}
        <Text textAlign="center" variant="subtitle1">
          <Link
            color="secondary"
            href={t(routeNames.LOGIN)}
            onClick={handleClickToEmailPassword}
          >
            {t("SIGN_IN.ALREADY_CONNECTED_WITH_GOOGLE.BUTTON")}
          </Link>
        </Text>
      </Box>
    </Box>
  );
}
GoogleAuthLogin.propTypes = {
  handleClickToEmailPassword: PropTypes.func.isRequired,
  googleAuthState: PropTypes.object.isRequired,
  isAdobePlugin: PropTypes.bool,
  adobePluginSessionId: PropTypes.string,
  disabledInFirefox: PropTypes.bool,
};

GoogleAuthLogin.defaultProps = {
  isAdobePlugin: false,
  adobePluginSessionId: null,
  disabledInFirefox: false,
};
