import PropTypes from "prop-types";
import { PureComponent } from "react";

//TODO: add default fallback component https://app.asana.com/0/0/1202644154713852/f
class ErrorBoundary extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { error: null };
    this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    if (this.props.onError) {
      this.props.onError(error);
    }
  }

  resetErrorBoundary(...args) {
    this.props.onReset?.(...args);
    this.setState({ error: null });
  }

  render() {
    if (this.state.error) {
      const { FallbackComponent, fallbackRender } = this.props;
      const propsForFallback = {
        resetErrorBoundary: this.resetErrorBoundary,
        error: this.state.error,
      };
      if (typeof fallbackRender === "function") {
        return fallbackRender(propsForFallback);
      }
      if (FallbackComponent) {
        return <FallbackComponent {...propsForFallback} />;
      }
      return null;
    }

    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  FallbackComponent: PropTypes.elementType,
  fallbackComponent: PropTypes.node,
  fallbackRender: PropTypes.func,
  onError: PropTypes.func,
  onReset: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  FallbackComponent: null,
  fallbackComponent: null,
  fallbackRender: null,
  onError: null,
  onReset: null,
};

export default ErrorBoundary;
