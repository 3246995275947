import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";

import { makeStyles } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import classnames from "classnames";
import last from "lodash/last";

import { Box } from "@shared/UIKit";
import MaybeLazyLoad from "@shared/UIKit/MaybeLazyLoad/MaybeLazyLoad";

import mapRight from "@shared/helpers/mapRight";
import { fileProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import FileCard from "./FileCard/FileCard";
import FileVersion from "./FileVersion/FileVersion";
import LockedReviewsState from "./LockedReviewsState/LockedReviewsState";
import ReviewCard from "./ReviewCard/ReviewCard";

const STEP_WIDTH = 290;

const useStyles = makeStyles((theme) => ({
  projectFile: {
    "&:last-child": {
      marginBottom: 0,
    },
  },
  projectFileCard: {
    '&:hover ~ div > [data-review-card-default="true"]': {
      outline: `1px ${theme.color["malachite1-translucent-50"]} solid`,
      outlineOffset: -1,
      zIndex: 22,
      "&::after": {
        display: "none",
      },
    },
    '&:hover + div [data-review-card-default="false"] [data-testid="start-review-link"]':
      {
        backgroundColor: theme.palette.primary.main,
        color: theme.color.white,
      },
    '&:hover ~ div > [data-review-card-default="false"] [data-testid="start-review-link-text"]':
      {
        color: theme.color.white,
      },
    '&:hover ~ div > [data-review-card-default="true"]:first-child:not(:last-child)':
      {
        marginLeft: -1,
      },
    '&:hover ~ div > [data-review-card-default="true"]:not(:first-child)': {
      marginLeft: -1,
      paddingLeft: 1,
    },
  },
  projectFileCardAlternative: {
    '&:hover ~ div > [data-review-card-default="true"]': {
      marginTop: -1,
    },
  },
  projectFileDragActive: {
    [theme.breakpoints.down("md")]: {
      width: "98vw",
    },
    maxWidth: ({ maxWidth, steps }) => maxWidth + steps * STEP_WIDTH,
    minWidth: ({ minWidth, steps }) => minWidth + steps * STEP_WIDTH,
    width: ({ width, steps }) => width + steps * STEP_WIDTH,
  },
}));

function ProjectFile({
  team,
  file,
  steps,
  isTeamMember,
  project,
  reviewCardClassName,
  fileCardClassName,
  rowIndex,
  onVersionOpen,
  isOpenedVersionSection,
  isDragActive,
  isLastItemInSection,
}) {
  const { minWidth, maxWidth, width } = useFileCardResized();
  const classes = useStyles({
    steps: steps.length,
    minWidth,
    maxWidth,
    width,
  });
  const [isVersionsOpen, setIsVersionsOpen] = useState(isOpenedVersionSection);

  const lastVersion = last(file.versions);

  const toggleVersionsSection = useCallback(() => {
    setIsVersionsOpen(!isVersionsOpen);
    onVersionOpen(!isVersionsOpen, file.versions.length - 1, file.id);
    analytics.track(
      isVersionsOpen ? analytics.ACTION.COLLAPSED : analytics.ACTION.EXPANDED,
      analytics.CATEGORY.VERSION_ROWS
    );
  }, [isVersionsOpen, file.versions.length, onVersionOpen, file.id]);

  return (
    <Box
      position="relative"
      mr={14.5}
      className={classes.projectFile}
      mb={0.25}
    >
      {isDragActive && (
        <Box
          data-testid="drag-file-row-active"
          display="flex"
          minHeight={80}
          bgcolor="var(--mui-color-green-translucent-90)"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          borderLeft="2px dashed var(--mui-palette-success-main)"
          borderRight="2px dashed var(--mui-palette-success-main)"
          borderBottom={
            isLastItemInSection
              ? `2px dashed var(--mui-palette-success-main)`
              : 0
          }
          position="absolute"
          left={0}
          bottom={0}
          zIndex={40}
          top={0}
          color="success.main"
          marginLeft={{ sm: 0.5, md: 0 }}
          className={classes.projectFileDragActive}
        />
      )}
      <Box display="flex" data-testid="fstg-file">
        <Box display="flex" alignItems="center">
          <FileCard
            file={file}
            lastVersion={lastVersion}
            toggleVersionsSection={toggleVersionsSection}
            isVersionsOpen={!file.isLocked && isVersionsOpen}
            project={project}
            steps={steps}
            className={classnames(fileCardClassName, classes.projectFileCard, {
              [classes.projectFileCardAlternative]: rowIndex > 0,
            })}
            rowIndex={rowIndex}
          />

          <Box display="flex" position="relative" maxHeight={70}>
            {file.isLocked && (
              <LockedReviewsState
                isTeamMember={isTeamMember}
                teamId={team._id}
              />
            )}

            {steps.map((step, index) => (
              <MaybeLazyLoad
                key={step.id}
                lazyLoad={steps.length > 10}
                width={290}
                height={70}
              >
                <ReviewCard
                  team={team}
                  file={file}
                  className={reviewCardClassName}
                  step={step}
                  steps={steps}
                  version={lastVersion}
                  project={project}
                  columnIndex={index}
                  rowIndex={rowIndex}
                />
              </MaybeLazyLoad>
            ))}
          </Box>
        </Box>
      </Box>
      <Box>
        {!file.isLocked &&
          isVersionsOpen &&
          mapRight(file.versions.slice(0, -1), (version, index) => (
            <FileVersion
              key={version.id}
              data-testid="file-version"
              team={team}
              file={file}
              version={version}
              steps={steps}
              project={project}
              isLastVersion={index === 0}
            />
          ))}
      </Box>
    </Box>
  );
}

ProjectFile.propTypes = {
  team: teamProp.isRequired,
  file: fileProp.isRequired,
  isTeamMember: PropTypes.bool.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  reviewCardClassName: PropTypes.string,
  fileCardClassName: PropTypes.string,
  rowIndex: PropTypes.number,
  onVersionOpen: PropTypes.func.isRequired,
  isOpenedVersionSection: PropTypes.bool,
  isDragActive: PropTypes.bool,
  isLastItemInSection: PropTypes.bool,
};

ProjectFile.defaultProps = {
  reviewCardClassName: undefined,
  fileCardClassName: undefined,
  rowIndex: 0,
  isOpenedVersionSection: false,
  isDragActive: false,
  isLastItemInSection: false,
};

export default memo(ProjectFile);
