import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import {
  ErrorOutline,
  Check,
  CheckBoxOutlineBlankRounded,
} from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";

export default function TooltipListItem({ check, translate, error }) {
  const { t } = useTranslation();

  const PasswordCriteriaIcon = ({ error, check }) => {
    if (error) {
      return <ErrorOutline color="error" fontSize="small" />;
    }
    if (check) {
      return <Check color="primary" fontSize="small" />;
    }

    return <CheckBoxOutlineBlankRounded color="primary" fontSize="small" />;
  };
  return (
    <ListItem>
      <ListItemIcon>
        <PasswordCriteriaIcon error={error} check={check} />
      </ListItemIcon>
      <ListItemText
        primary={t(translate)}
        primaryTypographyProps={{
          variant: "textSm",
          color: error ? "error" : "grey.700",
        }}
      />
    </ListItem>
  );
}

TooltipListItem.propTypes = {
  check: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  translate: PropTypes.string.isRequired,
};
