import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { INTEGRATION_APPS } from "@integrations/constants";
import {
  Button,
  ClickAwayListener,
  Divider,
  Menu,
  MenuItem,
  SvgIcon,
} from "@mui/material";
import HeaderSeparator from "@workflow/components/HeaderSeparator/HeaderSeparator";
import projectService from "@workflow/services/projectService";

import { Box, Text } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

import VectorLinkIcon from "@assets/img/icons/vector-link.svg";
import AppListIcon from "@assets/img/icons/widgets-outline.svg";

import GoogleDriveSettingsButton from "./GoogleDrive/GoogleDriveSettingsButton";
import IntegrationsSettingsPoppers from "./IntegrationsSettingsPoppers";
import MicrosoftTeamsSettingsButton from "./MicrosoftTeams/MicrosoftTeamsSettingsButton";
import SlackAppSettingsButton from "./Slack/SlackAppSettingsButton";

const availableApps = ["slack", "google-drive", "microsoft-teams"];

function IntegrationsSettingsButtons() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { projectId } = useParams();

  const integrations = INTEGRATION_APPS;

  const [settingsState, setSettingsState] = useState({
    connectedInstances: {},
    unconnectedInstances: [],
  });
  const [settingsButtonAnchorEl, setSettingsButtonAnchorEl] = useState(null);
  const open = Boolean(settingsButtonAnchorEl);

  const handleSettingsButtonClick = (
    event,
    eventIntegrationType,
    connected
  ) => {
    setSettingsState((prevState) => ({
      ...prevState,
      eventTarget: event.currentTarget,
      integrationType: eventIntegrationType,
      connected,
    }));
  };

  const handleCloseDialog = () => {
    setSettingsState((prevState) => ({
      ...prevState,
      eventTarget: null,
    }));
  };

  useEffect(() => {
    const loadConnections = async () => {
      const { teamId } = await projectService.fetchProjectById(projectId);

      const selectedIntegrations = availableApps.map((appId) => {
        return integrations.find(({ id }) => id === appId);
      });

      const integrationsConnections = await Promise.all(
        selectedIntegrations.map(({ appService }) =>
          appService.getTeamConnection(teamId)
        )
      );

      const connectedInstances = {};
      const unconnectedInstances = [];

      for (const integrationIndex in selectedIntegrations) {
        const integrationConnection = integrationsConnections[integrationIndex];
        const currentIntegration = selectedIntegrations[integrationIndex];

        if (integrationConnection) {
          connectedInstances[currentIntegration.id] = integrationConnection;
        } else {
          unconnectedInstances.push(currentIntegration.id);
        }
      }

      setSettingsState((prevState) => ({
        ...prevState,
        connectedInstances,
        unconnectedInstances,
      }));
    };

    loadConnections();
  }, [projectId, integrations]);

  const showSettingsModal = Boolean(settingsState.eventTarget);

  const teamConnectionsCount = Object.keys(
    settingsState.connectedInstances
  ).length;

  const handleClick = (event) => {
    setSettingsButtonAnchorEl(event.currentTarget);
  };

  const buttonComponents = {
    slack: SlackAppSettingsButton,
    "google-drive": GoogleDriveSettingsButton,
    "microsoft-teams": MicrosoftTeamsSettingsButton,
  };

  return (
    <>
      {teamConnectionsCount > 0 && (
        <>
          <HeaderSeparator />
          <Button
            variant="outlined"
            size="small"
            color="primaryV2"
            sx={{ padding: 1 }}
            onClick={handleClick}
            data-testid="apps-list-button"
          >
            <Box display="flex" flexDirection="row" gap={0.25}>
              <SvgIcon component={AppListIcon} inheritViewBox />
              <Text
                color="primaryV2"
                fontSize="medium"
                translate={teamConnectionsCount}
              />
            </Box>
          </Button>
        </>
      )}
      <Menu
        anchorEl={settingsButtonAnchorEl}
        id="integrations-settings-menu"
        data-testid="integrations-settings-menu"
        open={open}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box display="flex" flexDirection="row" gap={1} px={1.5} py={1.5}>
          <Text translate="PROJECT.APPS.MENU.TITLE_1" />
          <SvgIcon component={VectorLinkIcon} fontSize="small" inheritViewBox />
        </Box>
        <Divider />
        {Object.keys(settingsState.connectedInstances).map((appId) => {
          const ButtonComponent = buttonComponents[appId];

          return (
            <MenuItem key={appId}>
              <ButtonComponent onClick={handleSettingsButtonClick} />
            </MenuItem>
          );
        })}
      </Menu>
      {showSettingsModal && (
        <ClickAwayListener onClickAway={handleCloseDialog}>
          <Box display="flex" gap={1.5}>
            <IntegrationsSettingsPoppers
              show={showSettingsModal}
              handleCloseDialog={handleCloseDialog}
              buttonAnchorEl={settingsState.eventTarget}
              connected={settingsState.connected}
              integrationType={settingsState.integrationType}
            />
          </Box>
        </ClickAwayListener>
      )}
      {isMobile && teamConnectionsCount > 0 && <HeaderSeparator />}
    </>
  );
}

export default IntegrationsSettingsButtons;
