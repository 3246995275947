import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";

import { Box, Text, Image } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { projectProps } from "@shared/props/project";
import FSTGTypography from "@shared/theme/typography";

import ArrowIcon from "@assets/img/images/swimlane-upload-file.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "sticky",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    minHeight: ({ canUpload }) => (canUpload ? 230 : 110),
    width: ({ width }) => width,
    maxWidth: ({ maxWidth }) => maxWidth,
    minWidth: ({ minWidth }) => minWidth,
  },
  description: {
    marginTop: 9,
  },
  image: {
    transform: "rotate(52deg)",
    marginLeft: ({ width }) => width - 200,
    pointerEvents: "none",
    userSelect: "none",
  },
}));

function EmptyProjectFiles({ project }) {
  const { width, minWidth, maxWidth } = useFileCardResized();
  const canUpload = !project.isArchived && project.permissions.uploadFiles;
  const classes = useStyles({
    width,
    minWidth,
    maxWidth,
    canUpload,
  });
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      left={0}
      bgcolor={theme.color.gray[50]}
      className={classes.root}
      data-testid={`empty-project-${canUpload ? "upload" : "no-upload"}-access`}
    >
      <Box
        ml={1.5}
        mt={0.75}
        width="97%"
        textAlign="center"
        border={`2px dashed ${theme.color.gray[300]}`}
        bgcolor={theme.color.white}
        borderRadius="9px"
        display="flex"
        mb={1.75}
        pt={0}
        data-testid="project-files-empty-state"
        height="100%"
      >
        <Box
          mx={2.75}
          mb={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          width="100%"
        >
          {canUpload && (
            <Image
              width={95}
              className={classes.image}
              alt="Upload File Arrow"
              src={ArrowIcon}
            />
          )}
          <Text
            color={theme.color.gray[600]}
            variant="body2"
            fontSize={FSTGTypography.fontSize_1_6}
            lineHeight={FSTGTypography.lineHeight_1_3}
            fontWeight={theme.typography.fontWeight.semiBold}
            data-testid="project-files-empty-state-title"
          >
            {canUpload
              ? t(
                  isMobile
                    ? "FILE.EMPTYSTATE.TITLE_MOBILE"
                    : "FILE.EMPTYSTATE.TITLE"
                )
              : t("FILE.EMPTYSTATE.TITLE_NO_UPLOAD_PERMISSION")}
          </Text>
          <Text
            color={theme.color.gray[500]}
            className={classes.description}
            fontSize={FSTGTypography.fontSize_1_3}
            lineHeight={FSTGTypography.lineHeight_1_6}
            data-testid="project-files-empty-state-description"
          >
            {canUpload
              ? t("FILE.EMPTYSTATE.DESCRIPTION")
              : t("FILE.EMPTYSTATE.DESCRIPTION_NO_UPLOAD_PERMISSION")}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

EmptyProjectFiles.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
};

export default EmptyProjectFiles;
