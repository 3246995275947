/* eslint jsdoc/no-missing-syntax:0 */
import PropTypes from "prop-types";
import { ReactElement, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Button, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DateCalendar } from "@mui/x-date-pickers";
import {
  isSameDay,
  startOfDay,
  format,
  parse,
  parseISO,
  formatISO,
} from "date-fns";

import { Box } from "@shared/UIKit";

import {
  isTimeEndOfTheLocalDay,
  getCurrentLocaleForDateFns,
  formatWithLocale,
} from "@shared/helpers/dateutils";
import scrollIntoView from "@shared/helpers/scrollIntoView";
import { instance as analytics } from "@shared/services/analytics";

/**
 * Formats date and time in ISO 8601 format.
 * @param {Date} dueDate the due date
 * @param {string} dueTime the due time
 * @returns {string} ISO 8601 formatted date and time.
 */
function getISODueDate(dueDate, dueTime = "") {
  const optionsWithLocale = getCurrentLocaleForDateFns();
  const dateTime = dueTime
    ? parse(
        `${formatWithLocale(dueDate, "P")} ${dueTime}`,
        "P p",
        dueDate,
        optionsWithLocale
      )
    : parseISO(`${formatWithLocale(dueDate, "yyyy-MM-dd")}T23:59:59`);
  return formatISO(dateTime);
}

/**
 * Check if the due date and time are valid.
 * @param {Date} dueDate the due date.
 * @param {string} dueTime the time.
 * @returns {boolean} true if the due date and time are valid.
 */
const timeIsValid = (dueDate, dueTime) => {
  try {
    getISODueDate(dueDate, dueTime);
    return true;
  } catch {
    return false;
  }
};

/**
 * Get the time for the time picker by formatting it according to locale or
 * setting it to null if it's the end of the day.
 * @param {Date} dueDate the due date.
 * @returns {string|null} the due date time localized or null if there's no due date time.
 */
function getCurrentDueDateTime(dueDate) {
  const currentDueDate = dueDate ? new Date(dueDate) : new Date();
  if (dueDate && !isTimeEndOfTheLocalDay(currentDueDate)) {
    return format(currentDueDate, "p", getCurrentLocaleForDateFns());
  }
  return null;
}

/**
 * Get the date for the date picker by setting the time to the start of the day
 * when the time is the end of the day.
 * @param {Date} dueDate the due date
 * @returns {Date} the due date.
 */
function getCurrentDueDate(dueDate) {
  const currentDueDate = dueDate ? new Date(dueDate) : new Date();
  if (isTimeEndOfTheLocalDay(currentDueDate)) {
    return startOfDay(currentDueDate);
  }
  return currentDueDate;
}

/**
 * Due date picker component.
 * @param {object} props the component props.
 * @param {Function} props.onClose callback when the popover is closed.
 * @param {Function} props.onSave callback when the due date is saved.
 * @param {Function} props.onRemove callback when the due date is removed.
 * @param {string | null} props.dueDate initial due date.
 * @returns {ReactElement} the due date picker component element.
 */
function DueDatePicker({ onClose, onSave, onRemove, dueDate }) {
  const { t } = useTranslation();

  const [currentDueDate, setCurrentDueDate] = useState(() =>
    getCurrentDueDate(dueDate)
  );

  const [currentDueTime, setCurrentDueTime] = useState(() =>
    getCurrentDueDateTime(dueDate)
  );

  const TIME_OPTIONS = useMemo(
    () =>
      [...Array(24).keys()].flatMap((hour) => {
        const hourStr = hour.toString().padStart(2, "0");
        return [
          formatWithLocale(new Date(`2023-01-01T${hourStr}:00`), "p"),
          formatWithLocale(new Date(`2023-01-01T${hourStr}:30`), "p"),
        ];
      }),
    []
  );

  const changeDueDate = (newDueDate) => {
    if (!dueDate) {
      analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.DUE_DATE);
    } else if (!isSameDay(parseISO(dueDate), newDueDate)) {
      analytics.track(analytics.ACTION.CHANGED, analytics.CATEGORY.DUE_DATE);
    }

    setCurrentDueDate(newDueDate);
    onSave(getISODueDate(newDueDate, currentDueTime));
  };
  const removeDueTime = () => {
    setCurrentDueTime(null);
    onSave(getISODueDate(currentDueDate));
  };

  const changeDueTime = (event, newDueTime, reason) => {
    if (reason === "clear") {
      removeDueTime();
      return;
    }
    if (currentDueTime !== newDueTime && newDueTime) {
      if (timeIsValid(currentDueDate, newDueTime)) {
        setCurrentDueTime(newDueTime);
        onSave(getISODueDate(currentDueDate, newDueTime));
      }
    }
  };

  const removeDueDate = () => {
    onRemove();
    onClose();
    analytics.track(analytics.ACTION.REMOVED, analytics.CATEGORY.DUE_DATE);
  };

  /* istanbul ignore next */
  const scrollTo8AM = () => {
    if (currentDueTime) {
      return;
    }
    const checkExist = setInterval(() => {
      const element = document.querySelector(
        `.MuiAutocomplete-popper [role="option"][data-option-index="16"]`
      );
      if (element) {
        scrollIntoView(element);
        clearInterval(checkExist);
      }
    }, 1);
  };

  return (
    <Box data-testid="due-date-picker">
      <DateCalendar
        value={currentDueDate}
        onChange={changeDueDate}
        openTo="day"
        views={["year", "month", "day"]}
      />

      <Box bgcolor="grey.100" py={1.25} px={2.5}>
        <Autocomplete
          data-testid="due-date-time-picker"
          freeSolo
          size="small"
          value={currentDueTime}
          onInputChange={changeDueTime}
          options={TIME_OPTIONS}
          onOpen={scrollTo8AM}
          componentsProps={{
            clearIndicator: {
              "data-testid": "due-date-picker-remove-due-time",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              color="primary"
              placeholder={t("DUE_DATE.TIME_PICKER_LABEL")}
              data-testid="due-date-picker-due-time"
            />
          )}
        />
      </Box>

      <Box
        display="flex"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        py={1}
      >
        <Button
          variant="text"
          size="large"
          onClick={removeDueDate}
          color="primaryV2"
          data-testid="due-date-picker-remove-button"
        >
          {t("DUE_DATE.REMOVE_BUTTON_TEXT")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClose}
          data-testid="due-date-picker-set-button"
        >
          {t("DUE_DATE.SET_BUTTON_TEXT")}
        </Button>
      </Box>
    </Box>
  );
}

DueDatePicker.propTypes = {
  dueDate: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

DueDatePicker.defaultProps = {
  dueDate: null,
};

export default DueDatePicker;
