import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";

import { Box } from "@shared/UIKit";
import MaybeLazyLoad from "@shared/UIKit/MaybeLazyLoad/MaybeLazyLoad";

import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import FileVersionBorders from "./FileVersionBorders/FileVersionBorders";
import FileVersionCard from "./FileVersionCard/FileVersionCard";
import VersionReviewCard from "./VersionReviewCard/VersionReviewCard";

const useStyle = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    position: "sticky",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
}));

function FileVersion({ team, file, version, project, steps, isLastVersion }) {
  const classes = useStyle();
  return (
    <Box data-testid="file-version" display="flex" maxHeight={40}>
      <Box display="flex" left={0} zIndex={30} className={classes.root}>
        <FileVersionBorders isLastVersion={isLastVersion} />
        <FileVersionCard project={project} file={file} version={version} />
      </Box>

      <Box display="flex" flexDirection="row">
        {steps.map((step) => (
          <MaybeLazyLoad
            key={step.id}
            lazyLoad={steps.length > 10}
            width={290}
            height={40}
          >
            <VersionReviewCard
              key={step.id}
              team={team}
              step={step}
              version={version}
              project={project}
              file={file}
            />
          </MaybeLazyLoad>
        ))}
      </Box>
    </Box>
  );
}

FileVersion.propTypes = {
  team: teamProp.isRequired,
  file: fileProp.isRequired,
  version: versionProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  isLastVersion: PropTypes.bool.isRequired,
};

export default FileVersion;
