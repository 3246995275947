import { useEffect } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";

import OnboardingLoadingScreen from "@supporting/components/WizardBase/OnboardingLoadingScreen";
import authenticationService from "@supporting/services/authentication";
import Cookies from "js-cookie";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";

export default function SignUpValidate() {
  const { goTo } = useNavigation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const planId = searchParams.get("plan_id");
  const reason = searchParams.get("reason");
  const sourceProjectId = searchParams.get("sourceProjectId");
  const sourceProjectTemplateId = searchParams.get("sourceProjectTemplateId");
  const insightsIntegrationId = searchParams.get("insightsIntegrationId");

  const authenticationState = omitBy(
    {
      email,
      planId,
      reason,
      sourceProjectId,
      sourceProjectTemplateId,
      insightsIntegrationId,
    },
    isNil
  );

  async function sendOtp() {
    try {
      healthMetrics.trackStart("supporting.authentication");
      await authenticationService.sendOTPForSignup(email, authenticationState);
      Cookies.set("authentication:signup-flow", "started");
      analytics.track(
        analytics.ACTION.STARTED,
        analytics.CATEGORY.SIGNUP_FLOW,
        reason && { signupReason: reason }
      );
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.SIGNUP_FORM,
        reason && { signupReason: reason }
      );

      goTo(
        ROUTE_STATES.SIGNUP_VERIFY_OTP,
        {
          search: createSearchParams(authenticationState).toString(),
        },
        "react"
      );
    } catch (err) {
      const TAG = "authentication:signup-form";

      healthMetrics.trackFailure("supporting.authentication", err);
      logger.error(TAG, "failed to signup user", {
        email,
        err,
      });
      goTo(
        ROUTE_STATES.SIGNUP,
        {
          search: createSearchParams({
            ...authenticationState,
            alreadyRegistered: err.code === "user_exists",
          }).toString(),
        },
        "react"
      );
    }
  }
  useEffect(() => {
    sendOtp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <OnboardingLoadingScreen />;
}
