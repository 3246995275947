import PropTypes from "prop-types";

export const fileDataProp = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  key: PropTypes.string,
  url: PropTypes.string,
  label: PropTypes.string,
  sizeInBytes: PropTypes.number,
  mimeType: PropTypes.string,
  metaData: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    pageNumber: PropTypes.number,
    pageCount: PropTypes.number,
  }),
  extension: PropTypes.string,
});
