import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Link } from "@mui/material";
import OtpField from "@supporting/components/OtpField/OtpField";

import { Box, Text } from "@shared/UIKit";

const OTPContent = ({
  email,
  onSubmit,
  errorText,
  setErrorText,
  handleResendClick,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const handleInputChange = (value) => {
    setCode(value);
    setErrorText("");
  };
  const submitOTP = (event) => {
    event.preventDefault();
    onSubmit(code);
  };
  const isDisabled = code.length !== 6;
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      component="form"
      onSubmit={submitOTP}
    >
      <Text color="text.secondary" variant="body2" textAlign="center">
        {t("AUTHENTICATION.DIALOGS.OTP.DESCRIPTION", { email })}
      </Text>

      <Box p={1}>
        <OtpField
          code={code}
          onChange={handleInputChange}
          errorText={t(errorText)}
          variant="outlined"
          required
        />
      </Box>
      <Button
        sx={{ padding: 1 }}
        type="submit"
        color="primary"
        variant="contained"
        fullWidth
        disabled={isDisabled}
        data-testid="dialog-confirm"
      >
        {t("CORE.CONFIRM")}
      </Button>
      <Box>
        <Text
          color="text.secondary"
          variant="body2"
          component="span"
          textAlign="center"
        >
          {t("AUTHENTICATION.DIALOGS.OTP.HELP_TEXT")}
        </Text>
        <Link
          color="accent"
          onClick={handleResendClick}
          whiteSpace="nowrap"
          data-testid="resend-otp"
          sx={{ pl: 1 }}
        >
          {t("AUTHENTICATION.SIGNUP_OTP.RESEND")}
        </Link>
      </Box>
    </Box>
  );
};
OTPContent.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired,
  setErrorText: PropTypes.func.isRequired,
  handleResendClick: PropTypes.func.isRequired,
};
export default OTPContent;
