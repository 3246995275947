import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import projectService from "@workflow/services/projectService";

import { Dialog, ConfirmationDialog } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { projectProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";

function RemoveCollaboratorDialog({ project, collaborator, cancel, answer }) {
  const { t } = useTranslation();
  const modal = useModal();

  const isLastCollaborator = project.collaborators.length === 1;
  const isCurrentUser =
    authenticationService.fetchUser()._id === collaborator._id;

  async function removeCollaborator() {
    try {
      await projectService.removeCollaborator(project.id, collaborator._id);

      toastService.sendToast({
        title: `COLLABORATORS.REMOVE.${
          isCurrentUser ? "SUCCESS_ME" : "SUCCESS"
        }.TITLE`,
        body: `COLLABORATORS.REMOVE.${
          isCurrentUser ? "SUCCESS_ME" : "SUCCESS"
        }.BODY`,
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: !isCurrentUser
            ? { member: collaborator.displayName }
            : undefined,
        },
      });

      answer();
    } catch (error) {
      errorHandlerService.handleError(error);
      cancel();
    }
  }

  function cancelDialog() {
    modal.hide();
    cancel();
  }

  return isLastCollaborator ? (
    <Dialog
      open={modal.visible}
      hideBackdrop={false}
      cancel={cancelDialog}
      title={t("COLLABORATORS.REMOVE.REJECTED.HEADLINE")}
    >
      {t("COLLABORATORS.REMOVE.REJECTED.DESCRIPTION")}
    </Dialog>
  ) : (
    <ConfirmationDialog
      isOpen={modal.visible}
      answer={removeCollaborator}
      closeDialog={cancelDialog}
      title={
        isCurrentUser
          ? t("COLLABORATORS.REMOVE.HEADLINE_ME")
          : t("COLLABORATORS.REMOVE.HEADLINE", {
              member: collaborator.displayName,
            })
      }
      description={
        isCurrentUser
          ? t("COLLABORATORS.REMOVE.DESCRIPTION_ME")
          : t("COLLABORATORS.REMOVE.DESCRIPTION", {
              member: collaborator.displayName,
            })
      }
      confirmLabel={
        isCurrentUser
          ? t("COLLABORATORS.REMOVE.CONFIRM_ME")
          : t("COLLABORATORS.REMOVE.CONFIRM")
      }
    />
  );
}

RemoveCollaboratorDialog.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  collaborator: userProp.isRequired,
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
};

export default NiceModal.create(RemoveCollaboratorDialog);
