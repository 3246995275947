import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import CommentCount from "@feedback/components/CommentCount/CommentCount";
import ReviewDecisionIndicatorList from "@feedback/components/ReviewDecisionIndicatorList/ReviewDecisionIndicatorList";
import reviewService from "@feedback/services/reviewService";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import useActiveUser from "@supporting/hooks/useActiveUser";
import cx from "classnames";

import { Box, DueDate, ShareLink } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { useMediaQuery, useNavigation } from "@shared/hooks";
import useSessionStorage from "@shared/hooks/useSessionStorage";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import ReviewOverviewButton from "../../ReviewStatus/ReviewOverviewButton/ReviewOverviewButton";
import ReviewStatus from "../../ReviewStatus/ReviewStatus";
import PendingReviewLabel from "./PendingReviewLabel/PendingReviewLabel";
import ReviewCardMenu from "./ReviewCardMenu/ReviewCardMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "&:hover $topContainer": {
      justifyContent: "space-between",
    },
    "&:hover $toggleVisibilityOn": {
      visibility: "visible",
    },
  },
  cardContainer: {
    [theme.breakpoints.up("md")]: {
      border: `1px transparent solid`,
      "&:hover": {
        marginBottom: "-1px",
        border: `1px ${theme.color["malachite1-translucent-50"]} solid`,
        backgroundColor: theme.color.green[50],
      },
    },
  },
  pendingReviewLabel: {},
  topContainer: {},
  toggleVisibilityOn: {},
}));

function ActiveReviewCard({
  team,
  file,
  step,
  version,
  project,
  review,
  cardContainerClass,
  cardContentClass,
}) {
  const classes = useStyles();
  const [, setSelectedFileId] = useSessionStorage("selectedFileId");
  const { goTo, routeNames } = useNavigation();

  const isApproved = review.status.state === REVIEW_STATUS.APPROVED;
  const isPendingReview = Boolean(review?.isPendingYourReview);
  const isFileActive = !file.isLocked;
  const enableInteractions = isFileActive && !project.isArchived;
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { teamId: team._id });
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const user = useActiveUser();

  const isNewOverviewEnabled =
    user?.settings?.earlyAccessFeatures?.fileViewRedesign;

  const { t } = useTranslation();

  const updateDueDate = useCallback(
    (dueDate) => {
      reviewService.updateReviewDueDate(review, dueDate);
    },
    [review]
  );

  const removeDueDate = useCallback(() => {
    reviewService.removeDueDateOfReview(review);
  }, [review]);

  const openReview = useCallback(
    (event) => {
      event.stopPropagation();

      analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.REVIEW);

      goTo(routeNames.REVIEW_LINK, {
        params: {
          stepId: review.stepId,
          reviewId: review.id,
        },
      });
    },
    [goTo, routeNames.REVIEW_LINK, review.stepId, review.id]
  );

  const handleReviewClicked = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.target?.getAttribute("data-open-review-link")) {
        setSelectedFileId(file.id);
        openReview(event);
      }
    },
    [openReview, setSelectedFileId, file.id]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      className={cx("tour__open-file", classes.root, {
        [classes.cardContainer]: isFileActive,
        [cardContainerClass]: isFileActive,
      })}
      onClick={handleReviewClicked}
      data-testid="active-review-card"
    >
      <Box
        pt={0.375}
        px={1.25}
        pb={0}
        className={cardContentClass}
        data-testid="card-default-content"
        data-open-review-link="true"
      >
        {isNewOverviewEnabled ? (
          <ReviewOverviewButton
            review={review}
            page={pagesEnum.PROJECT_DASHBOARD}
            project={project}
          />
        ) : (
          <Box
            display="flex"
            alignItems="center"
            minHeight={30}
            justifyContent={isApproved ? "flex-end" : "space-between"}
            data-open-review-link="true"
            className={cx({
              [classes.topContainer]: isApproved,
            })}
          >
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              minWidth="0%"
              visibility={isApproved ? "hidden" : "visible"}
              data-open-review-link="true"
              className={cx({
                [classes.toggleVisibilityOn]: isApproved,
              })}
            >
              <ReviewDecisionIndicatorList
                review={review}
                indicatorPosition="right"
                isApproved={isApproved}
              />
            </Box>
            <Box>
              <ReviewStatus review={review} project={project} />
            </Box>
          </Box>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          visibility={isApproved ? "hidden" : "visible"}
          data-open-review-link="true"
          className={cx({
            [classes.toggleVisibilityOn]: isApproved,
          })}
        >
          <Box display="flex" alignItems="flex-end" mb={-0.25} ml={-0.375}>
            <DueDate
              isDueDateFeatureEnabled={
                team.subscriptionLimits.isDueDateFeatureEnabled &&
                !project.isArchived
              }
              canManageBilling={team.permissions.canManageBilling}
              review={review}
              onSave={updateDueDate}
              onRemove={removeDueDate}
              canSetDueDate={review.permissions.canSetDueDate}
              isReadOnly={project.isArchived}
              popoverPosition="left"
              openBillingDialog={openDialog}
            />
          </Box>
          <Box display="flex" alignItems="center">
            {isMobile ? (
              <Button
                variant="text"
                size="extraSmall"
                color="success"
                onClick={openReview}
                data-testid="review-card-open-review-link"
              >
                {t("FILE_REVIEW.OPEN_REVIEW")}
              </Button>
            ) : (
              <>
                <CommentCount review={review} isReviewCard />

                <ShareLink
                  fileId={file._id}
                  stepId={step.id}
                  tooltipDirection="top"
                  isReviewCard
                  small
                  iconButtonProps={{
                    edge: "end",
                  }}
                />
              </>
            )}

            <ReviewCardMenu
              project={project}
              team={team}
              step={step}
              file={file}
              version={version}
              review={review}
            />
          </Box>
        </Box>
      </Box>

      {enableInteractions && isPendingReview && <PendingReviewLabel />}
    </Box>
  );
}

ActiveReviewCard.propTypes = {
  team: teamProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  review: versionProp.isRequired,
  file: fileProp.isRequired,
  step: stepProps.isRequired,
  version: versionProp.isRequired,
  cardContentClass: PropTypes.string.isRequired,
  cardContainerClass: PropTypes.string.isRequired,
};

export default memo(ActiveReviewCard);
