/* eslint-disable complexity */
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { Button, Paper } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";
import classNames from "classnames";
import noop from "lodash/noop";

import { Box } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import STORAGE from "@shared/constants/storage";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import getPlugin from "@shared/helpers/getPlugin";
import urlUtils from "@shared/helpers/urlUtils";
import { fileProp } from "@shared/props/file";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import { instance as logger } from "@shared/services/logger";

import StepSelection from "./step-selection/StepSelection";
import UploadButtons from "./UploadButtons/UploadButtons";
import UploadFromDevice from "./UploadButtons/UploadFromDevice/UploadFromDevice";
import UploadAfterEffectsComposition from "./UploadButtons/UploadFromPlugin/UploadAfterEffectsComposition/UploadAfterEffectsComposition";
import UploadInDesignDocument from "./UploadButtons/UploadFromPlugin/UploadInDesignDocument/UploadInDesignDocument";
import UploadPremiereSequence from "./UploadButtons/UploadFromPlugin/UploadPremiereSequence/UploadPremiereSequence";
import InputWebsiteUrlView from "./UploadButtons/UploadLiveWebsite/InputWebsiteUrlView";
import { useUploadPanelStyles } from "./UploadPanel.styles";
import UploadPanelFileList from "./UploadPanelFileList";

const uploadVersion = (
  file,
  selectedStepIds,
  selectedFile,
  page,
  component,
  fileService,
  onShowStepSelection
) => {
  onShowStepSelection();

  return fileService.uploadVersion({
    file: selectedFile,
    stepIds: selectedStepIds,
    fileHandle: file,
    page,
    component,
    message: "",
  });
};

function UploadPanel({
  page,
  component,
  closePanel,
  projectId,
  sectionId,
  steps,
  stepIds,
  selectedFile,
  teamId,
  onShowStepSelection,
  openBillingDialog,
  droppedFiles,
}) {
  sectionId = selectedFile ? selectedFile.sectionId : sectionId;
  const classes = useUploadPanelStyles();
  const [sourceStorage, setSourceStorage] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const fileListOpen = Boolean(anchorEl);

  const handleFileListOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleFileListClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (droppedFiles.length > 0) {
      setSourceStorage(STORAGE.LOCAL);
      analytics.track(
        analytics.ACTION.SELECTED,
        analytics.CATEGORY.UPLOAD_PANEL_FILES_TO_UPLOAD,
        {
          storage: STORAGE.LOCAL,
        }
      );
    }
  }, [droppedFiles]);

  const { t } = useTranslation();
  const [selectedSteps, setSelectedSteps] = useState(
    steps
      .filter((step) => stepIds.includes(step.id))
      .reduce((acc, step) => {
        acc[step.id] = true;
        return acc;
      }, {})
  );

  const [isURLInputDialogOpen, setIsURLInputDialogOpen] = useState(false);
  const [files, setFiles] = useState(droppedFiles);
  const [url, setUrl] = useState("");
  const [urlValidationError, setUrlValidationError] = useState(null);

  const currentPlugin = getPlugin();
  const inPlugin = Object.values(PLUGIN_TYPES).some(
    (pluginType) => pluginType === currentPlugin
  );

  const inPremierePlugin = currentPlugin === PLUGIN_TYPES.PREMIERE;
  const inInDesignPlugin = currentPlugin === PLUGIN_TYPES.INDESIGN;
  const inAfterEffectsPlugin = currentPlugin === PLUGIN_TYPES.AFTER_EFFECTS;

  const handleProcessUpload = useCallback(
    (files, projectId) => {
      const isVersionUpload = Boolean(selectedFile);
      closePanel();
      const stepIds = Object.keys(selectedSteps);
      return isVersionUpload
        ? uploadVersion(
            files[0],
            stepIds,
            selectedFile,
            page,
            component,
            fileService,
            onShowStepSelection
          )
        : fileService.uploadFiles({
            files,
            projectId,
            stepIds,
            sectionId,
            page,
            component,
            steps,
          });
    },
    [
      selectedFile,
      selectedSteps,
      component,
      page,
      steps,
      onShowStepSelection,
      sectionId,
      closePanel,
    ]
  );

  const handleWebsiteUpload = async (url) => {
    const file = {
      name: url,
      url,
      websiteUrl: url,
      isRemote: true,
      storage: "WEBSITE",
      type: "text/html",
    };
    try {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.UPLOAD_PANEL_UPLOAD_FILES_BUTTON,
        {
          storage: STORAGE.WEBSITE,
        }
      );
      await handleProcessUpload([file], projectId);
    } catch (error) {
      logger.warn("UploadPanel", "upload failed for website", {
        url,
        teamId,
        error,
      });
    }
  };

  const importWebsite = async () => {
    const urlWithProtocol =
      url.startsWith("http://") || url.startsWith("https://")
        ? url
        : `https://${url}`;
    const urlValidationStatus = urlUtils.validateURL(urlWithProtocol);
    switch (urlValidationStatus) {
      case urlUtils.URL_VALIDATION_RESPONSES.VALID:
        analytics.track(
          analytics.ACTION.SELECTED,
          analytics.CATEGORY.UPLOAD_PANEL_FILES_TO_UPLOAD,
          {
            storage: STORAGE.WEBSITE,
          }
        );
        setSourceStorage(STORAGE.WEBSITE);
        await handleWebsiteUpload(urlWithProtocol);
        break;
      case urlUtils.URL_VALIDATION_RESPONSES.INVALID_PROTOCOL:
        analytics.track(
          analytics.ACTION.SUBMITTED,
          analytics.CATEGORY.IMPORT_WEBSITE_NON_SUPPORTED_PROTOCOL,
          {
            url,
          }
        );
        setUrlValidationError(urlValidationStatus);
        break;
      default:
        setUrlValidationError(urlValidationStatus);
    }
  };

  function handleCancel(event) {
    closePanel(event, "cancel");
  }

  const handleUpload = async () => {
    try {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.UPLOAD_PANEL_UPLOAD_FILES_BUTTON,
        {
          storage: sourceStorage,
          fileCount: files.length,
        }
      );
      await handleProcessUpload(files, projectId);
    } catch (error) {
      logger.warn("UploadPanel", "upload failed", {
        files,
        teamId,
        error,
      });
    }
  };

  const handleUploadFiles = useCallback(async (files, teamId, storage) => {
    try {
      const validFiles = await fileService.validateFileHandles(files, teamId);
      setFiles(validFiles);
      analytics.track(
        analytics.ACTION.SELECTED,
        analytics.CATEGORY.UPLOAD_PANEL_FILES_TO_UPLOAD,
        {
          storage,
        }
      );
      setSourceStorage(storage);
    } catch (error) {
      logger.warn("UploadPanel", "upload failed", {
        files,
        teamId,
        error,
      });
    }
  }, []);

  const onDrop = useCallback(
    async (attachedFiles) => {
      const files = selectedFile ? [attachedFiles[0]] : attachedFiles;
      await handleUploadFiles(files, teamId, STORAGE.LOCAL);
    },
    [handleUploadFiles, selectedFile, teamId]
  );
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    noDragEventsBubbling: true,
    disabled: files.length !== 0,
    useFsAccessApi: false,
  });
  const handleChange = useCallback(
    ({ target }) => {
      const { checked, name } = target;
      analytics.track(
        checked ? analytics.ACTION.SELECTED : analytics.ACTION.UNSELECTED,
        analytics.CATEGORY.UPLOAD_PANEL_REVIEWER_GROUPS
      );
      setSelectedSteps((currentSelected) => {
        const newSteps = { ...currentSelected };
        if (checked) {
          newSteps[name] = true;
        } else {
          delete newSteps[name];
        }
        return newSteps;
      });
    },
    [setSelectedSteps]
  );
  const showImportWebsiteForm = useCallback(() => {
    setIsURLInputDialogOpen(true);
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.IMPORT_WEBSITE_BUTTON
    );
  }, []);
  useEffect(() => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.UPLOAD_PANEL);
  }, []);

  const onClickOfBackButton = useCallback(() => {
    setUrlValidationError(null);
    setUrl("");
    setIsURLInputDialogOpen(false);
  }, []);

  return (
    <Paper
      square
      elevation={0}
      className={classNames(classes.UploadPanelWrapper, {
        [classes.InDesignUploadPanelWrapper]: inInDesignPlugin,
      })}
    >
      {/* eslint-disable-next-line react/forbid-elements */}
      <input
        data-testid="upload-panel-from-local-machine-input"
        accept="*/*"
        {...getInputProps()}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        bg="grey.50"
        pt={1}
        pb={1}
        pr={1.5}
        pl={1.5}
        {...getRootProps()}
      >
        {!isURLInputDialogOpen && !inPlugin && (
          <>
            <Box data-testid="uploadWrapper" display="flex" height="105px">
              <UploadFromDevice
                open={open}
                isDragActive={isDragActive}
                isNew
                isDisabled={files.length !== 0}
              />
            </Box>
            <UploadButtons
              isDisabled={files.length !== 0}
              teamId={teamId}
              showImportWebsiteForm={showImportWebsiteForm}
              selectedFile={selectedFile}
              handleUploadFiles={handleUploadFiles}
              handleUpgradeAction={openBillingDialog}
              page={page}
              component={component}
            />
          </>
        )}
        {isURLInputDialogOpen && (
          <InputWebsiteUrlView
            urlValidationError={urlValidationError}
            onUrlChange={(value) => {
              setUrl(value);
              setUrlValidationError("");
            }}
            onClickOfBackButton={onClickOfBackButton}
          />
        )}

        {steps?.length > 0 && !inPlugin && (
          <StepSelection
            steps={steps}
            isSelectedFileAvailable={Boolean(selectedFile)}
            handleChange={handleChange}
            selectedSteps={selectedSteps}
            isBlurred={files.length === 0 && !isURLInputDialogOpen}
          />
        )}
        {inPremierePlugin && (
          <UploadPremiereSequence
            steps={steps}
            sectionId={sectionId}
            closePanel={closePanel}
            handleChange={handleChange}
            selectedFile={selectedFile}
            selectedSteps={selectedSteps}
          />
        )}

        {inInDesignPlugin && (
          <UploadInDesignDocument
            steps={steps}
            sectionId={sectionId}
            closePanel={closePanel}
            handleChange={handleChange}
            selectedFile={selectedFile}
            selectedSteps={selectedSteps}
          />
        )}

        {inAfterEffectsPlugin && (
          <UploadAfterEffectsComposition
            steps={steps}
            sectionId={sectionId}
            closePanel={closePanel}
            handleChange={handleChange}
            selectedFile={selectedFile}
            selectedSteps={selectedSteps}
          />
        )}
        <Box display="flex" alignItems="center">
          {files.length > 0 && (
            <>
              <Button
                variant="text"
                color="accent"
                disableRipple
                disableFocusRipple
                onClick={handleFileListOpen}
                startIcon={<InsertDriveFileOutlinedIcon fontSize="small" />}
                data-testid="file-list-button"
              >
                {t("UPLOAD_DIALOG.FILES_ADDED", {
                  fileCount: files.length,
                })}
              </Button>
              <UploadPanelFileList
                fileListOpen={fileListOpen}
                anchorEl={anchorEl}
                handleFileListClose={handleFileListClose}
                files={files}
              />
            </>
          )}

          {!inPlugin && (
            <Box flexGrow="1" display="flex" justifyContent="flex-end">
              <Button
                data-testid="upload-panel-cancel-button"
                variant="text"
                onClick={handleCancel}
              >
                {t("CORE.CANCEL")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={files.length === 0 && !isURLInputDialogOpen}
                data-testid="upload-panel-upload-button"
                onClick={isURLInputDialogOpen ? importWebsite : handleUpload}
              >
                {selectedSteps && Object.keys(selectedSteps).length > 0
                  ? t("UPLOAD_DIALOG.CTA-1")
                  : t("UPLOAD_DIALOG.CTA-2")}
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
}

UploadPanel.propTypes = {
  page: PropTypes.oneOf([
    pagesEnum.PROJECT_DASHBOARD,
    pagesEnum.ONBOARDING_WIZARD,
    pagesEnum.FILE_VIEW,
  ]).isRequired,
  component: PropTypes.oneOf([
    UPLOAD_COMPONENT_TYPE.POPOVER,
    UPLOAD_COMPONENT_TYPE.NAVIGATION_WORKSPACE,
    UPLOAD_COMPONENT_TYPE.WORKSPACE_SIDEBAR,
  ]).isRequired,
  closePanel: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  selectedFile: fileProp,
  steps: PropTypes.arrayOf(stepProps),
  stepIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamId: PropTypes.string.isRequired,
  onShowStepSelection: PropTypes.func,
  openBillingDialog: PropTypes.func,
  droppedFiles: PropTypes.arrayOf(PropTypes.object),
};

UploadPanel.defaultProps = {
  closePanel: noop,
  sectionId: null,
  selectedFile: null,
  steps: [],
  droppedFiles: [],
  onShowStepSelection: noop,
  openBillingDialog: noop,
};

export default UploadPanel;
