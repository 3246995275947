/* eslint-disable react/forbid-component-props */
/* eslint-disable react/forbid-elements */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import { makeStyles } from "@mui/styles";
import useUploadPanelDialog from "@workflow/components/UploadPanelDialog/useUploadPanelDialog";
import { instance as fileService } from "@workflow/services/fileService";
import cx from "classnames";

import { Box } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import { projectProps, sectionProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import RenderRow from "./RenderRow";

const COMPENSATION_ROW_FILE_SECTION = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    height: `100vh`,
    paddingTop: 85,
    overflowY: "auto",
    overflowX: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
  },
  rootWithHorizontalScroll: {
    overflowX: "auto",
  },
  projectFilesContainer: {
    display: "flex",
    flex: 1,
  },
  listAutosizer: {
    flex: 1,
  },
  virtualList: {
    position: "relative",
    zIndex: 50,
  },
  virtualRow: {
    top: 0,
    left: 0,
    zIndex: 50,
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      minWidth: "100vw",
    },
  },
  stickyRow: {
    zIndex: 150,
  },
  activeStickyRow: {
    position: "sticky",
    zIndex: 100,
  },
}));

function RenderRowFiles({
  rows,
  data,
  toggleOpenVersions,
  openedFileIds,
  section,
  steps,
  isLoadingSteps,
  projectId,
  teamId,
  onDragIsActive,
  canUploadFiles,
}) {
  const classes = useStyles();
  const { openUploadPanelDialog } = useUploadPanelDialog({
    projectId,
    sectionId: section.id,
    stepIds: steps.length > 0 ? [steps[0].id] : [],
    teamId,
    page: pagesEnum.PROJECT_DASHBOARD,
    component: UPLOAD_COMPONENT_TYPE.POPOVER,
  });

  const { pointerEvents, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      onDrop: async (attachment) => {
        const validFiles = await fileService.validateFileHandles(
          attachment,
          teamId
        );
        return openUploadPanelDialog({ droppedFiles: validFiles });
      },
      noClick: true,
      noKeyboard: true,
      disabled: !canUploadFiles,
      useFsAccessApi: false,
    });

  useEffect(() => {
    onDragIsActive(isDragActive, section.id);
  }, [isDragActive, onDragIsActive, section]);

  return (
    <Box
      {...getRootProps()}
      data-testid="section-drop-file-upload"
      style={{
        pointerEvents,
      }}
    >
      <input
        {...getInputProps()}
        data-testid="section-drop-file-upload-input"
      />
      {rows.map((virtualRow, index) => {
        const isLastItemInSection = index === rows.length - 1;
        return (
          <Box
            key={virtualRow.index}
            ref={virtualRow.measureRef}
            data-index={virtualRow.index}
            id={`virtual-row-${virtualRow.index}`}
            className={cx({
              [classes.virtualRow]: true,
            })}
            minHeight={`${virtualRow.size}px`}
            style={{
              transform: `translateY(${
                virtualRow.start + COMPENSATION_ROW_FILE_SECTION
              }px)`,
              zIndex: -virtualRow.index,
            }}
          >
            {RenderRow({
              data,
              index: virtualRow.index,
              toggleOpenVersions,
              openedFileIds,
              isLoadingSteps,
              isDragActive,
              isLastItemInSection,
            })}
          </Box>
        );
      })}
    </Box>
  );
}

RenderRowFiles.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
      start: PropTypes.number.isRequired,
    })
  ).isRequired,
  data: PropTypes.shape({
    // @TODO: create type variants instead object (https://app.asana.com/0/948797479489416/1203012362327955/f)
    items: PropTypes.arrayOf(PropTypes.object),
    steps: PropTypes.arrayOf(stepProps),
    project: PropTypes.shape(projectProps),
    team: teamProp.isRequired,
    isTeamMember: PropTypes.bool,
  }).isRequired,
  section: sectionProps.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  isLoadingSteps: PropTypes.bool.isRequired,
  onDragIsActive: PropTypes.func.isRequired,
  toggleOpenVersions: PropTypes.func.isRequired,
  openedFileIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  canUploadFiles: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};

RenderRowFiles.defaultProps = {
  canUploadFiles: true,
};

export default RenderRowFiles;
