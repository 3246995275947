import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";
import toastService from "@supporting/services/toast";

import { Box, Text } from "@shared/UIKit";

import errorHandlerService from "@shared/services/errorHandler";

const ResendOTP = ({ sentOTP }) => {
  const { t } = useTranslation();

  const handleResendClick = async (event) => {
    event.preventDefault();
    try {
      await sentOTP();
      toastService.sendToast({
        title: "SIGNUP.OTP_SENT.TITLE",
        body: "SIGNUP.OTP_SENT.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  return (
    <Box py={1} mb={1} textAlign="center">
      <Text color="text.secondary" variant="textSm">
        {t("AUTHENTICATION.DIALOGS.OTP.HELP_TEXT")}{" "}
        <Link
          href="#"
          onClick={handleResendClick}
          whiteSpace="nowrap"
          color="secondary"
          fontWeight="fontWeight.medium"
        >
          {t("AUTHENTICATION.SIGNUP_OTP.RESEND")}
        </Link>
      </Text>
    </Box>
  );
};

ResendOTP.propTypes = {
  sentOTP: PropTypes.func.isRequired,
};
export default ResendOTP;
