/* istanbul ignore file */
export default function HTMLElementType(
  props,
  propName,
  componentName,
  location,
  propFullName
) {
  if (process.env.NODE_ENV === "production") {
    return null;
  }

  const propValue = props[propName];
  const safePropName = propFullName || propName;

  // eslint-disable-next-line eqeqeq
  if (propValue == null) {
    return null;
  }

  if (propValue && propValue.nodeType !== 1) {
    return new Error(
      `Invalid ${location} \`${safePropName}\` supplied to \`${componentName}\`. ` +
        `Expected an HTMLElement.`
    );
  }

  return null;
}
