import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ArrowForward from "@mui/icons-material/ArrowForward";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";

import SubMenuItem from "@shared/UIKit/SubMenu/SubMenu";

import { sectionProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";

const MoveFileToSectionMenuItem = ({ otherSections, fileId, closeMenu }) => {
  const { t } = useTranslation();

  const moveFileToSection = async (event, sectionId) => {
    event.stopPropagation();
    closeMenu();
    try {
      await fileService.moveToSection(fileId, sectionId);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  return (
    <SubMenuItem
      menuItemProps={{
        "data-testid": "project-file-menu-move-file-to-section",
      }}
      menuItem={
        <>
          <ListItemIcon>
            <ArrowForward />
          </ListItemIcon>
          <ListItemText>{t("FILE.MENU.MOVE_TO_SECTION")}</ListItemText>
        </>
      }
    >
      {otherSections.map((section, index) => (
        <MenuItem
          key={section.id}
          data-testid={`${index}-project-section`}
          onClick={(event) => moveFileToSection(event, section.id)}
        >
          {section.name}
        </MenuItem>
      ))}
    </SubMenuItem>
  );
};
MoveFileToSectionMenuItem.displayName = "MoveFileToSectionMenuItem";

MoveFileToSectionMenuItem.propTypes = {
  otherSections: PropTypes.arrayOf(sectionProps).isRequired,
  closeMenu: PropTypes.func.isRequired,
  fileId: PropTypes.string.isRequired,
};

export default MoveFileToSectionMenuItem;
