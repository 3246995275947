/* istanbul ignore file */

import { default as defaultFunc } from "@uppy/core";
import * as Core from "@uppy/core";

window.Uppy = {
  Core: {
    ...Core,
    default: defaultFunc,
  },
};
