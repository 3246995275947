import PropTypes from "prop-types";
import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";

import useReviewsPagination from "@feedback/hooks/useReviewsPagination";
import { ClickAwayListener, IconButton, Popper, SvgIcon } from "@mui/material";

import { Box, Tooltip } from "@shared/UIKit";

import useFirstMountState from "@shared/hooks/useFirstMountState";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";

import SliderVerticalIcon from "@assets/img/icons/slider-vertical.svg";

import FileGalleryReviewItem from "./FileGalleryReviewItem/FileGalleryReviewItem";
import OldSectionItem from "./FileGallerySectionItem/OldSectionItem";

// eslint-disable-next-line complexity
const FileGallery = ({ projectId, initialSections, step, reviewId }) => {
  const { t } = useTranslation();
  const scrollContainer = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const isFirstMount = useFirstMountState();

  const { sections, reviews, loadMore, keepScrolledAt } = useReviewsPagination({
    projectId,
    stepId: step.id,
    reviewId,
    initialSections,
  });

  const handleWheel = (event) => {
    /* istanbul ignore next */
    if (scrollContainer.current) {
      scrollContainer.current.scrollLeft += event.deltaY + event.deltaX;
    }
  };

  const toggleFileGallery = (event) => {
    const newAnchorEl = anchorEl ? null : event.currentTarget;
    setAnchorEl(newAnchorEl);

    if (newAnchorEl) {
      analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.FILE_GALLERY);
    }
  };

  return (
    <>
      <Tooltip title={t("BROWSE_FILES.FILEVIEWER.FEATURE")} placement="top">
        <IconButton
          aria-label={t("BROWSE_FILES.FILEVIEWER.FEATURE")}
          size="small"
          onClick={toggleFileGallery}
          data-testid="file-gallery-button"
          className="tour__file-gallery"
        >
          <SvgIcon component={SliderVerticalIcon} fontSize="small" />
        </IconButton>
      </Tooltip>

      <Popper
        open={isMenuOpen}
        anchorEl={anchorEl}
        sx={{
          zIndex: 23,
          boxShadow: `var(--mui-shadow-box-shadow-xlarge)`,
        }}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={toggleFileGallery}>
          <Box
            id="file-gallery"
            data-testid="file-gallery-container"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            bgcolor="grey.100"
            maxWidth={{ md: `calc(100vw - 350px)`, xl: `calc(100vw - 500px)` }}
          >
            <Box
              data-testid="file-gallery-scroll-container"
              ref={scrollContainer}
              position="relative"
              width="100%"
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-start"
              overflow="auto"
              onWheel={handleWheel}
            >
              {sections.length > 0 && reviews.length > 0 && (
                <>
                  <Waypoint
                    fireOnRapidScroll
                    horizontal
                    onEnter={loadMore(true)}
                  />
                  {reviews.map((review, index) => (
                    <Fragment key={review.id}>
                      {(index === 0 ||
                        reviews[index - 1].file.sectionId !==
                          review.file.sectionId) && (
                        <OldSectionItem
                          isFirst={index === 0}
                          name={
                            sections.find(
                              ({ id }) => review.file.sectionId === id
                            )?.name
                          }
                        />
                      )}
                      <FileGalleryReviewItem
                        review={review}
                        selected={reviewId === review.id}
                        keepInView={
                          /* istanbul ignore next */
                          !isFirstMount &&
                          keepScrolledAt.current?.id === review.id
                        }
                        ml={1}
                      />
                    </Fragment>
                  ))}
                  <Waypoint fireOnRapidScroll horizontal onEnter={loadMore()} />
                </>
              )}
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

FileGallery.propTypes = {
  projectId: PropTypes.string.isRequired,
  initialSections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  step: stepProps.isRequired,
  reviewId: PropTypes.string,
};

FileGallery.defaultProps = {
  reviewId: null,
};
export default FileGallery;
