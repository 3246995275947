import BILLING_INTERVAL from "@supporting/constants/billingIntervals";
import { instance as billingService } from "@supporting/services/billingService";
import { create } from "zustand";

export const fetchTaxesAndReCalculateTotal = async ({
  countryCode,
  selectedPlan,
}) => {
  const taxData = await billingService.calculateTaxes(countryCode);
  const newPrice = billingService.calculateTotalPrice({
    base: parseFloat(selectedPlan.amount),
    taxRate: taxData.rate,
  });
  let taxTranslationData = {};
  if (taxData.rate && taxData.rate !== 0) {
    taxTranslationData = {
      taxRate: taxData.rate,
      taxName: "BILLING.BREAKDOWN.TAX_NAME",
    };
  }

  return {
    taxTranslationData,
    price: newPrice,
  };
};

export const useTaxPaymentInfo = create(() => ({
  isCalculatingTaxes: false,
  taxInfo: null,
  taxError: null,
  price: null,
  plan: null,
  countryCode: null,
  billingPlanList: null,
}));

export const initializeTaxPaymentInfo = (selectedPlan, billingPlanList) => {
  useTaxPaymentInfo.setState((state) => ({
    ...state,
    plan: selectedPlan,
    billingPlanList,
    price: billingService.calculateTotalPrice({
      base: parseFloat(selectedPlan.amount),
    }),
  }));
};

export const resetTaxPaymentInfo = () => {
  useTaxPaymentInfo.setState((state) => ({
    ...state,
    isCalculatingTaxes: false,
    taxInfo: null,
    taxError: null,
    price: null,
    plan: null,
    countryCode: null,
  }));
};

export const updateTaxCountryCode = async (countryCode) => {
  if (!countryCode) {
    useTaxPaymentInfo.setState((state) => ({
      ...state,
      price: billingService.calculateTotalPrice({
        base: parseFloat(useTaxPaymentInfo.getState().plan.amount),
      }),
      taxInfo: null,
      isCalculatingTaxes: false,
    }));
    return;
  }
  useTaxPaymentInfo.setState((state) => ({
    ...state,
    isCalculatingTaxes: true,
  }));
  const infos = await fetchTaxesAndReCalculateTotal({
    countryCode,
    selectedPlan: useTaxPaymentInfo.getState().plan,
  });
  useTaxPaymentInfo.setState((state) => ({
    ...state,
    countryCode,
    price: infos.price,
    taxInfo: infos.taxTranslationData,
    isCalculatingTaxes: false,
  }));
};

export const switchBillingRate = () => {
  const { billingPlanList, countryCode, plan } = useTaxPaymentInfo.getState();
  const newInterval =
    plan.interval === BILLING_INTERVAL.MONTHLY
      ? BILLING_INTERVAL.YEARLY
      : BILLING_INTERVAL.MONTHLY;
  const switchedPlan = billingPlanList.find(
    (billingPlan) =>
      billingPlan.name === plan.name &&
      billingPlan.currency === plan.currency &&
      billingPlan.interval === newInterval
  );
  useTaxPaymentInfo.setState((state) => ({
    ...state,
    countryCode,
    price: billingService.calculateTotalPrice({
      base: parseFloat(switchedPlan.amount),
      taxRate: state.taxInfo?.taxRate || null,
    }),
    plan: switchedPlan,
  }));
};
