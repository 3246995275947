import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@mui/material";

import { Box } from "@shared/UIKit";
import Text from "@shared/UIKit/Text";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import { APP_ROUTES } from "@shared/constants/routes";
import backend from "@shared/services/backendClient";
import { instance as logger } from "@shared/services/logger";

function VerifyDomain() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { verificationId } = useParams();
  const [isVerified, setIsVerified] = useState(false);

  const goToDashboard = () => {
    navigate(APP_ROUTES.DASHBOARD.path);
  };

  useEffect(() => {
    const verifyDomain = async () => {
      try {
        await backend.post(`/verify-domain/${verificationId}`);
        setIsVerified(true);
      } catch (error) {
        logger.warn("supporting:verify-domain", "unable to verify domain", {
          verificationId,
          error,
        });
        navigate(APP_ROUTES.LINK_INVALID.path);
      }
    };
    verifyDomain();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={4}
      gap={2}
    >
      {isVerified && (
        <>
          <FilestageResponsiveLogo />
          <Box pb={5} />
          <Text
            textAlign="center"
            translate="JOIN_WITH_SAME_DOMAIN.VERIFICATION.TEXT"
          />
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={goToDashboard}
          >
            {t("EMBED_PAGE.CONTINUE_BUTTON")}
          </Button>
        </>
      )}
    </Box>
  );
}

export default VerifyDomain;
