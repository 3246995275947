import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import LoadingButton from "@mui/lab/LoadingButton";
import { TextField, Button } from "@mui/material";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Dialog, Text, Box } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { teamProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";

function DeleteTeamDialog({ isOpen, closeDialog, team }) {
  const { t } = useTranslation();

  const [errorState, setErrorState] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { goTo } = useNavigation();

  const handleInputChange = (event) => {
    const { value } = event.target;
    if (value === team.name) {
      setErrorState(false);
      setIsDisabled(false);
      return;
    }

    setIsDisabled(true);
    setErrorState(true);
  };

  const cancelDialog = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const deleteTeam = async function () {
    try {
      setIsLoading(true);
      await teamService.removeTeam(team._id);
      toastService.sendToast({
        title: "TEAM.DELETE.SUCCESS.TITLE",
        body: "TEAM.DELETE.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            teamName: team.name,
          },
        },
      });
      goTo("DASHBOARD", { options: { reload: true } });
    } catch (err) {
      errorHandlerService.handleError(err);
      setIsLoading(false);
    }
  };

  const actions = (
    <>
      <Button
        color="primaryV2"
        onClick={cancelDialog}
        variant="text"
        size="large"
        data-testid="dialog-cancel"
      >
        {t("CORE.CANCEL")}
      </Button>
      <LoadingButton
        loading={isLoading}
        onClick={deleteTeam}
        type="danger"
        variant="text"
        size="large"
        disabled={isDisabled}
        disableRipple
        data-testid="delete-team-confirm"
        color="error"
      >
        {t("TEAM.SETTINGS.DELETE_TEAM.DELETE_DIALOG.CONFIRM")}
      </LoadingButton>
    </>
  );

  return (
    <Dialog
      cancel={cancelDialog}
      open={isOpen}
      title={t("TEAM.SETTINGS.DELETE_TEAM.DELETE_DIALOG.HEADLINE")}
      actions={actions}
      hideBackdrop={false}
    >
      <Box mb={5}>
        <Text variant="body2">
          {t("TEAM.SETTINGS.DELETE_TEAM.DELETE_DIALOG.DESCRIPTION-1")}
        </Text>
        <Text variant="body2">
          {t("TEAM.SETTINGS.DELETE_TEAM.DELETE_DIALOG.DESCRIPTION-2")}
        </Text>
      </Box>
      <Box py={3} px={5} boxShadow={3} mb={3}>
        <Text variant="body2">
          {t("TEAM.SETTINGS.DELETE_TEAM.DELETE_DIALOG.DESCRIPTION-3")}
        </Text>

        <Box my={2}>
          <TextField
            error={errorState}
            type="text"
            onChange={handleInputChange}
            onBlur={handleInputChange}
            inputProps={{
              "data-testid": "delete-team-name-input",
            }}
            fullWidth
          />
        </Box>
      </Box>
    </Dialog>
  );
}

DeleteTeamDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  team: teamProp.isRequired,
};

export default DeleteTeamDialog;
