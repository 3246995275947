import PropTypes from "prop-types";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";

import { userProp } from "./authentication";
import { fileDataProp } from "./fileData";

const reviewStatusProp = PropTypes.shape({
  state: PropTypes.oneOf(Object.keys(REVIEW_STATUS)),
  userId: PropTypes.string,
  updatedAt: PropTypes.string,
});

export const reviewDecisionProp = PropTypes.shape({
  reviewer: userProp,
  state: PropTypes.string,
  date: PropTypes.string,
});

export const reviewProp = PropTypes.shape({
  id: PropTypes.string,
  fileId: PropTypes.string,
  versionId: PropTypes.string,
  teamId: PropTypes.string,
  stepId: PropTypes.string,
  status: reviewStatusProp,
  // TODO next 2 properties will be rename to dueDate and decisions
  //  https://app.asana.com/0/1199668161863275/1199939761024928/f
  reviewDueDate: PropTypes.string,
  reviews: PropTypes.arrayOf(reviewDecisionProp),
  created: PropTypes.string,
  role: PropTypes.shape({
    isReviewer: PropTypes.bool,
  }),
  isPendingYourReview: PropTypes.bool,
  permissions: PropTypes.shape({
    canManageIssueResolutions: PropTypes.bool,
  }),
  commentCount: PropTypes.shape({
    resolved: PropTypes.number.isRequired,
    unresolved: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }),
  // TODO remove these properties once the old dashboard is removed
  //  https://app.asana.com/0/1199668161863275/1199939761024934/f
  parentId: PropTypes.string,
  number: PropTypes.number,
  mediaType: PropTypes.string,
  uploadedBy: PropTypes.string,
  original: fileDataProp,
  thumbnail: fileDataProp,
  pages: PropTypes.arrayOf(fileDataProp),
});
