/* eslint-disable max-lines */
import { useContext, createContext } from "react";

import mondayAppService from "@integrations/services/mondayAppService";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";

export const SidebarContext = createContext(null);
export const useSidebarState = () => useContext(SidebarContext);

export const PROJECT_SORT_OPTIONS = ["NEWEST", "BY_NAME"];

let collator;

export const initState = ({ user, selectedProject }) => {
  collator = new Intl.Collator(user.language.locale, {
    numeric: true,
    sensitivity: "base",
  });
  const isInMondayApp = mondayAppService.checkIfInMondayApp();

  return {
    rawFolders: [],
    rawReviewerTeams: [],
    favoriteProjects: [],
    sortedFavoriteProjects: [],
    rawReviewerProjects: [],
    areArchivedProjectsShown: user.settings.viewArchivedProjects,
    areProjectTemplatesShown: user.settings.viewProjectTemplates,
    sortedFolders: [],
    sortedReviewerTeams: [],
    templates: [],
    sortedTemplates: [],
    dndSourceId: "",
    sortOption: user.settings.selectedProjectSortingOption,
    selectedProject,
    isCollapsed: isInMondayApp,
    allFoldersCollapsed: false,
  };
};

export const PROJECT_LIST_ACTION_TYPES = Object.freeze({
  TOGGLE_ARCHIVE_PROJECTS: "toggle-archive-projects",
  CHANGE_SORT_OPTION: "change-sort-option",
  CHANGE_DND_SOURCE: "change-dnd-source",
  UPDATE_FOLDERS: "update-folders",
  UPDATE_REVIEWER_PROJECTS: "update-reviewer-projects",
  UPDATE_SELECTED_PROJECT: "update-selected-project",
  TOGGLE_FOLDER_COLLAPSE: "toggle-folder-collapse",
  UPDATE_REVIEWER_FOLDERS: "update-reviewer-folders",
  TOGGLE_REVIEWER_FOLDER_COLLAPSE: "toggle-folder-collapse-reviewer",
  CLEAR_SELECTED_PROJECT: "clear-selected-project",
});

export const FAVORITE_PROJECT_LIST_ACTION_TYPES = Object.freeze({
  UPDATE_PROJECTS: "update-favorite-projects",
});

export const PROJECT_TEMPLATES_ACTION_TYPES = Object.freeze({
  UPDATE_TEMPLATES: "update-templates",
  REMOVE_PROJECT_TEMPLATE: "remove-project-template",
  TOGGLE_PROJECT_TEMPLATE: "toggle-project-template",
  ADD_PROJECT_TEMPLATE: "add-project-template",
});

export const SIDEBAR_ACTION_TYPES = Object.freeze({
  TOGGLE_SIDEBAR: "toggle-sidebar",
  UPDATE_USER: "update-user",
  TOGGLE_ALL_FOLDERS_COLLAPSED: "toggle-all-folders-collapse",
  UPDATE_STATUS_COLLAPSE_ALL_FOLDERS: "update-status-collapse-all-folders",
});

const naturalSort = (param1, param2, field = "name") => {
  return collator.compare(param1[field], param2[field]);
};

const sortFoldersByName = (folders) => {
  const sortFn = (entity1, entity2) => naturalSort(entity1, entity2);
  const foldersWithSortedProjects = folders.map((folder) => {
    folder.projects = folder.projects.sort(sortFn);
    folder.archivedProjects = folder.archivedProjects.sort(sortFn);
    return folder;
  });

  return foldersWithSortedProjects.sort(sortFn);
};

const sortFoldersByDate = (folders) => {
  const foldersWithOrderedProjects = folders.map((folder) => {
    folder.projects = orderBy(
      folder.projects,
      ({ createdTime }) => new Date(createdTime),
      "desc"
    );
    folder.archivedProjects = orderBy(
      folder.archivedProjects,
      ({ createdTime }) => new Date(createdTime),
      "desc"
    );
    return folder;
  });

  return orderBy(
    foldersWithOrderedProjects,
    ({ createdTime, projects }) => {
      if (projects.length > 0) {
        return new Date(projects[0].createdTime);
      }

      return new Date(createdTime);
    },
    "desc"
  );
};

const sortFolders = (folders, sortOption) => {
  if (sortOption === PROJECT_SORT_OPTIONS[0]) {
    return sortFoldersByDate(folders);
  }
  return sortFoldersByName(folders);
};

const sortFavoriteProjects = (projects, sortOption) => {
  if (sortOption === PROJECT_SORT_OPTIONS[0]) {
    return orderBy(
      projects,
      ({ createdTime }) => new Date(createdTime),
      "desc"
    );
  }
  return projects.sort((project1, project2) => naturalSort(project1, project2));
};

const sortTemplates = (templates, sortOption) => {
  if (sortOption === PROJECT_SORT_OPTIONS[0]) {
    return orderBy(
      templates,
      ({ createdTime }) => new Date(createdTime),
      "desc"
    );
  }
  return templates.sort((template1, template2) =>
    naturalSort(template1, template2)
  );
};

const sortReviewerTeamsByDate = (externalTeams) => {
  const externalTeamsSorted = externalTeams.map((reviewerTeam) => {
    reviewerTeam.folders = reviewerTeam.folders.map((folder) => {
      folder.projects = orderBy(
        folder.projects,
        ({ createdTime }) => new Date(createdTime),
        "desc"
      );
      return folder;
    });

    const foldersSorted = orderBy(
      reviewerTeam.folders,
      ({ projects }) => new Date(projects[0].createdTime),
      "desc"
    );

    return {
      ...reviewerTeam,
      folders: foldersSorted,
    };
  });

  return orderBy(
    externalTeamsSorted,
    ({ folders }) => new Date(folders[0].projects[0].createdTime)
  );
};

const sortReviewerTeamsByName = (externalTeams) => {
  const externalTeamsSorted = externalTeams.map((reviewerTeam) => {
    reviewerTeam.folders = reviewerTeam.folders.map((folder) => {
      folder.projects = folder.projects.sort((project1, project2) =>
        naturalSort(project1, project2)
      );
      return folder;
    });

    const foldersSorted = reviewerTeam.folders.sort((folder1, folder2) =>
      naturalSort(folder1, folder2)
    );

    return {
      ...reviewerTeam,
      folders: foldersSorted,
    };
  });

  return externalTeamsSorted.sort((externalTeam1, externalTeam2) =>
    naturalSort(externalTeam1, externalTeam2, "teamName")
  );
};

const sortReviewerTeams = (externalTeams, sortOption) => {
  if (sortOption === PROJECT_SORT_OPTIONS[0]) {
    return sortReviewerTeamsByDate(externalTeams);
  }
  return sortReviewerTeamsByName(externalTeams);
};

const actions = {
  [PROJECT_LIST_ACTION_TYPES.CLEAR_SELECTED_PROJECT]: (state) => ({
    ...state,
    selectedProject: null,
  }),
  [PROJECT_LIST_ACTION_TYPES.UPDATE_SELECTED_PROJECT]: (
    state,
    { project }
  ) => ({
    ...state,
    selectedProject: project,
  }),
  [PROJECT_LIST_ACTION_TYPES.TOGGLE_ARCHIVE_PROJECTS]: (state) => ({
    ...state,
    areArchivedProjectsShown: !state.areArchivedProjectsShown,
  }),
  [PROJECT_LIST_ACTION_TYPES.CHANGE_SORT_OPTION]: (state, { sortOption }) => ({
    ...state,
    sortOption,
    sortedFavoriteProjects: sortFavoriteProjects(
      state.favoriteProjects,
      sortOption
    ),
    sortedFolders: sortFolders(state.sortedFolders, sortOption),
    sortedReviewerTeams: sortReviewerTeams(
      state.sortedReviewerTeams,
      sortOption
    ),
    sortedTemplates: sortTemplates(state.templates, sortOption),
  }),
  [PROJECT_LIST_ACTION_TYPES.CHANGE_DND_SOURCE]: (state, { dndSourceId }) => ({
    ...state,
    dndSourceId,
  }),
  [FAVORITE_PROJECT_LIST_ACTION_TYPES.UPDATE_PROJECTS]: (
    state,
    { projects }
  ) => {
    return {
      ...state,
      favoriteProjects: projects,
      sortedFavoriteProjects: sortFavoriteProjects(projects, state.sortOption),
      sortedTemplates: sortTemplates(state.templates, state.sortOption),
    };
  },
  [PROJECT_LIST_ACTION_TYPES.UPDATE_FOLDERS]: (state, { folders }) => {
    const nextSortedFolders = sortFolders(
      folders.map(({ projects, ...restFolder }) => {
        const [archivedProjects, activeProjects] = partition(
          projects,
          "isArchived"
        );

        return {
          ...restFolder,
          isCollapsed: restFolder.isCollapsed || false,
          projects: activeProjects,
          archivedProjects,
        };
      }),
      state.sortOption
    );

    return {
      ...state,
      rawFolders: folders,
      sortedFolders: nextSortedFolders,
    };
  },
  [PROJECT_LIST_ACTION_TYPES.UPDATE_REVIEWER_PROJECTS]: (
    state,
    { externalProjects }
  ) => ({
    ...state,
    rawReviewerProjects: externalProjects,
    rawReviewerTeams: externalProjects,
    sortedReviewerTeams: sortReviewerTeams(externalProjects, state.sortOption),
  }),
  [PROJECT_TEMPLATES_ACTION_TYPES.UPDATE_TEMPLATES]: (
    state,
    { templates }
  ) => ({
    ...state,
    templates,
    sortedTemplates: sortTemplates(templates, state.sortOption),
  }),
  [PROJECT_TEMPLATES_ACTION_TYPES.REMOVE_PROJECT_TEMPLATE]: (
    state,
    { templateId }
  ) => ({
    ...state,
    templates: state.templates.filter(
      (projectTemplate) => projectTemplate.id !== templateId
    ),
    sortedTemplates: state.sortedTemplates.filter(
      (projectTemplate) => projectTemplate.id !== templateId
    ),
  }),
  [PROJECT_TEMPLATES_ACTION_TYPES.ADD_PROJECT_TEMPLATE]: (
    state,
    { template }
  ) => ({
    ...state,
    templates: [...state.templates, template],
    sortedTemplates: sortTemplates(
      [...state.templates, template],
      state.sortOption
    ),
  }),
  [SIDEBAR_ACTION_TYPES.TOGGLE_SIDEBAR]: (state) => ({
    ...state,
    isCollapsed: !state.isCollapsed,
  }),
  [PROJECT_LIST_ACTION_TYPES.TOGGLE_FOLDER_COLLAPSE]: (state, { id }) => {
    const folders = state.rawFolders.map((folder) => {
      if (folder.id === id) {
        return {
          ...folder,
          isCollapsed: !folder.isCollapsed,
        };
      }
      return folder;
    });

    const nextSortedFolders = sortFolders(
      folders.map(({ projects, ...restFolder }) => {
        const [archivedProjects, activeProjects] = partition(
          projects,
          "isArchived"
        );

        return {
          ...restFolder,
          projects: activeProjects,
          archivedProjects,
        };
      }),
      state.sortOption
    );

    return {
      ...state,
      rawFolders: folders,
      sortedFolders: nextSortedFolders,
    };
  },
  [PROJECT_LIST_ACTION_TYPES.TOGGLE_REVIEWER_FOLDER_COLLAPSE]: (
    state,
    { id }
  ) => {
    const externalTeams = state.rawReviewerTeams.map((team) => {
      return {
        ...team,
        folders: team.folders.map((folder) => {
          if (folder.id === id) {
            return {
              ...folder,
              isCollapsed: !folder.isCollapsed,
            };
          }
          return folder;
        }),
      };
    });

    const nextSortedReviewerTeams = sortReviewerTeams(
      externalTeams,
      state.sortOption
    );

    return {
      ...state,
      rawReviewerTeams: externalTeams,
      sortedReviewerTeams: nextSortedReviewerTeams,
    };
  },
  [PROJECT_TEMPLATES_ACTION_TYPES.TOGGLE_PROJECT_TEMPLATE]: (state) => ({
    ...state,
    areProjectTemplatesShown: !state.areProjectTemplatesShown,
  }),
  [SIDEBAR_ACTION_TYPES.UPDATE_USER]: (state, payload) => ({
    ...state,
    areArchivedProjectsShown: payload.viewArchivedProjects,
    areProjectTemplatesShown: payload.viewProjectTemplates,
  }),
  [SIDEBAR_ACTION_TYPES.TOGGLE_ALL_FOLDERS_COLLAPSED]: (state, payload) => {
    const { isCollapsed } = payload;
    const updateFolder = (folder) => ({
      ...folder,
      isCollapsed,
    });

    const teamUpdateFolder = (team) => ({
      ...team,
      folders: team.folders.map(updateFolder),
    });

    const newRawFolders = state.rawFolders.map(updateFolder);
    const newSortedFolders = state.sortedFolders.map(updateFolder);
    const newRawReviewerTeams = state.rawReviewerTeams.map(teamUpdateFolder);
    const newSortedReviewerTeams =
      state.sortedReviewerTeams.map(teamUpdateFolder);

    return {
      ...state,
      rawFolders: newRawFolders,
      sortedFolders: newSortedFolders,
      rawReviewerTeams: newRawReviewerTeams,
      sortedReviewerTeams: newSortedReviewerTeams,
      allFoldersCollapsed: isCollapsed,
    };
  },
  [SIDEBAR_ACTION_TYPES.UPDATE_STATUS_COLLAPSE_ALL_FOLDERS]: (
    state,
    payload
  ) => {
    const { isCollapsed } = payload;
    return {
      ...state,
      allFoldersCollapsed: isCollapsed,
    };
  },
};

export const sidebarReducer = (state, action) =>
  actions[action.type](state, action.payload);
