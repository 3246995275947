import { useEffect } from "react";

const SignupExternalCallback = () => {
  useEffect(() => {
    window.top.location.href = window.fs.config.appBase;
  }, []);

  return null;
};

export default SignupExternalCallback;
