/* istanbul ignore file */
import { useEffect } from "react";

import { Box } from "@shared/UIKit";

import PageTitle from "@shared/components/PageTitle/PageTitle";

const NoMatch = () => {
  useEffect(() => {
    document.getElementById("reactRoot").style.height = null;

    return () => {
      document.getElementById("reactRoot").style.height = "100%";
    };
  }, []);
  return (
    <>
      <PageTitle />
      <Box />
    </>
  );
};

export default NoMatch;
