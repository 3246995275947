import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import InteractiveHTMLSvg from "@assets/img/images/interactive-html.gif";
import LiveWebsiteSvg from "@assets/img/images/review_live_website.gif";

import FeatureName from "./FeatureName";
import FeatureTooltip from "./FeatureTooltip";

function WorkflowFeaturesDisplay({ team, openBillingDialog }) {
  const { t } = useTranslation();

  const features = [
    {
      name: "COPY_COMMENTS.FEATURE_NAME",
      isEnabled: team.subscriptionLimits.isCopyCommentsEnabled,
      tooltip: (
        <FeatureTooltip
          linkHref={t("URLS.FAQ_COPY_COMMENTS")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isCopyCommentsEnabled}
          description={t("BILLING.FEATURE.COPY_COMMENTS.HELP_MESSAGE")}
          imageSrc="../../assets/img/images/earlyaccess_copy_comments.svg"
          getInTouchUpgradeText={t(
            "BILLING.FEATURE.COPY_COMMENTS.UPGRADE_PLAN_MESSAGE"
          )}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.REVIEW_INTERACTIVE_HTML.TITLE",
      isEnabled: team.subscriptionLimits.isReviewInteractiveHtmlEnabled,
      tooltip: (
        <FeatureTooltip
          description={t("BILLING.FEATURE.LIVE_WEBSITE.HELP_MESSAGE.PART1")}
          linkHref={t("URLS.FAQ_LIVE_WEBSITE")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          imageSrc={InteractiveHTMLSvg}
          isEnabled={team.subscriptionLimits.isReviewInteractiveHtmlEnabled}
          upgradePlanMessage1={t(
            "BILLING.FEATURE.REVIEW_INTERACTIVE_HTML.UPGRADE_PLAN_MESSAGE"
          )}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.LIVE_WEBSITE.LABEL",
      isEnabled: team.subscriptionLimits.isReviewWebsitesEnabled,
      tooltip: (
        <FeatureTooltip
          description={t(
            "BILLING.FEATURE.REVIEW_INTERACTIVE_HTML.HELP_MESSAGE"
          )}
          linkHref={t("URLS.FAQ_INTERACTIVE_HTML")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          imageSrc={LiveWebsiteSvg}
          isEnabled={team.subscriptionLimits.isReviewWebsitesEnabled}
          upgradePlanMessage1={t(
            "BILLING.FEATURE.REVIEW_INTERACTIVE_HTML.UPGRADE_PLAN_MESSAGE"
          )}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.LIVE_WEBSITE.HELP_MESSAGE.PART2"
          )}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.AUTO_TEXT_COMPARE",
      isEnabled: team.subscriptionLimits.isCompareVersionsEnabled,
      tooltip: (
        <FeatureTooltip
          description={t("AUTO_TEXT_COMPARE.HELP_TOOLTIP")}
          linkHref={t("AUTO_TEXT_COMPARE.GUIDE_LINK")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isCompareVersionsEnabled}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.COMPARE_VERSIONS.LABEL",
      isEnabled: team.subscriptionLimits.isCompareVersionsEnabled,
      tooltip: (
        <FeatureTooltip
          description={t("BILLING.FEATURE.COMPARE_VERSIONS.HELP_MESSAGE.PART1")}
          linkHref={t("URLS.FAQ_COMPARE_VERSIONS")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          imageSrc="../../assets/img/images/compare-reviews.svg"
          isEnabled={team.subscriptionLimits.isCompareVersionsEnabled}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.COMPARE_VERSIONS.HELP_MESSAGE.PART2"
          )}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.REVIEW_REQUEST_DECISION.LABEL",
      isEnabled: team.subscriptionLimits.isRequestReviewerDecisionEnabled,
      tooltip: (
        <FeatureTooltip
          description={t(
            "BILLING.FEATURE.REVIEW_REQUEST_DECISION.HELP_MESSAGE.PART1"
          )}
          getInTouchDescription={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isRequestReviewerDecisionEnabled}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.TEAM_ONLY_COMMENT.LABEL",
      isEnabled: team.subscriptionLimits.isTeamOnlyFeatureEnabled,
      tooltip: (
        <FeatureTooltip
          description={t(
            "BILLING.FEATURE.TEAM_ONLY_COMMENT.HELP_MESSAGE.PART1"
          )}
          linkHref={t("URLS.FAQ_TEAM_ONLY_COMMENT")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isTeamOnlyFeatureEnabled}
          upgradePlanMessage1={t(
            "BILLING.FEATURE.REVIEW_INTERACTIVE_HTML.UPGRADE_PLAN_MESSAGE"
          )}
          upgradePlanLinkText={t("BILLING.SWITCH_TO_NEW_PLAN")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.TEAM_ONLY_COMMENT.HELP_MESSAGE.PART2"
          )}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.DUE_DATES.LABEL",
      isEnabled: team.subscriptionLimits.isDueDateFeatureEnabled,
      tooltip: (
        <FeatureTooltip
          description={t("BILLING.FEATURE.DUE_DATES.HELP_MESSAGE.PART1")}
          linkHref={t("URLS.FAQ_DUE_DATES")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isDueDateFeatureEnabled}
          upgradePlanLinkText={t("BILLING.SWITCH_TO_NEW_PLAN")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.DUE_DATES.HELP_MESSAGE.PART2"
          )}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.EXTERNAL_UPLOAD.LABEL",
      isEnabled: team.subscriptionLimits.isReviewerUploadEnabled,
      tooltip: (
        <FeatureTooltip
          description={t("BILLING.FEATURE.EXTERNAL_UPLOAD.HELP_MESSAGE.PART1")}
          linkHref={t("URLS.FAQ_DIFFERENCE_REVIEWER_UPLOAD")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isReviewerUploadEnabled}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.EXTERNAL_UPLOAD.HELP_MESSAGE.PART2"
          )}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
    {
      name: "BILLING.FEATURE.ANONYMOUS_REVIEWERS.LABEL",
      isEnabled: team.subscriptionLimits.isAnonymousReviewersEnabled,
      tooltip: (
        <FeatureTooltip
          description={t(
            "BILLING.FEATURE.ANONYMOUS_REVIEWERS.HELP_MESSAGE.PART1"
          )}
          linkHref={t("URLS.FAQ_DIFFERENCE_REVIEWER_ANONYMOUS_REVIEWERS")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isAnonymousReviewersEnabled}
          getInTouchUpgrade={t("BILLING.FEATURE.GET_IN_TOUCH_WITH_US")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.ANONYMOUS_REVIEWERS.HELP_MESSAGE.PART2"
          )}
          analyticsCategory="ANONYMOUS_REVIEWERS"
        />
      ),
    },
    {
      name: "BILLING.FEATURE.REVIEW_REPORTS.LABEL",
      isEnabled: team.subscriptionLimits.isExportEnabled,
      tooltip: (
        <FeatureTooltip
          description={t("BILLING.FEATURE.REVIEW_REPORTS.HELP_MESSAGE.PART1")}
          linkHref={t("URLS.FAQ_EXPORT_FEEDBACK")}
          linkText={t("BILLING.FEATURE.LEARN_MORE")}
          isEnabled={team.subscriptionLimits.isExportEnabled}
          upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
          upgradePlanMessage2={t(
            "BILLING.FEATURE.REVIEW_REPORTS.HELP_MESSAGE.PART2"
          )}
          openBillingDialog={openBillingDialog}
        />
      ),
    },
  ];

  return features.map((feature) => (
    <FeatureName
      key={feature.name}
      name={feature.name}
      isEnabled={feature.isEnabled}
      tooltip={feature.tooltip}
    />
  ));
}

WorkflowFeaturesDisplay.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default WorkflowFeaturesDisplay;
