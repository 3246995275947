import { Box, Skeleton } from "@mui/material";

function StepHeaderLoadingState() {
  return (
    <Box
      width={266}
      p={1.875}
      display="flex"
      justifyContent="space-between"
      bgcolor="grey.200"
      data-testid="step-header-loading-state"
    >
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <Skeleton variant="text" width={130} height={20} />
        <Box display="flex" mt={0.5}>
          <Skeleton variant="circular" width={26} height={26} />
          <Skeleton variant="circular" width={26} height={26} />
          <Skeleton variant="circular" width={26} height={26} />
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Skeleton variant="text" width={20} height={20} />
        <Box mt={0.5}>
          <Skeleton variant="text" width={40} height={20} />
        </Box>
      </Box>
    </Box>
  );
}

export default StepHeaderLoadingState;
