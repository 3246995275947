import PropTypes from "prop-types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  Radio,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useNavigation } from "@shared/hooks";
import { fileProp, versionProp } from "@shared/props/file";

const MobilePanel = ({ file, activeReview, handleClose }) => {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const versionsWithReviewInCurrentStep = useMemo(() => {
    const versions = [];

    for (const version of file.versions) {
      const review = version.reviews.find(
        (review) => review.stepId === activeReview.stepId
      );
      if (review) {
        versions.push(version);
      }
    }

    return versions;
  }, [activeReview.stepId, file.versions]);

  const handleMenuItemClick = (versionId) => {
    handleClose();
    const version = file.versions.find((item) => item.id === versionId);
    const review = version.reviews.find(
      (item) => item.stepId === activeReview.stepId
    );
    goTo("REVIEW_LINK", {
      params: {
        stepId: review.stepId,
        reviewId: review.id,
      },
    });
  };

  return (
    <MenuList data-testid="version-reviews-radio-group">
      {versionsWithReviewInCurrentStep.map((version) => (
        <MenuItem
          key={version.id}
          onClick={() => handleMenuItemClick(version.id)}
          selected={version.id === activeReview.versionId}
        >
          <ListItemIcon>
            <Radio
              color="primary"
              checked={version.id === activeReview.versionId}
            />
          </ListItemIcon>
          <ListItemText>
            {t("CORE.VERSION")} {version.number}
          </ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};

MobilePanel.propTypes = {
  file: fileProp.isRequired,
  activeReview: versionProp.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default MobilePanel;
