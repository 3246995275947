/* istanbul ignore file */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link, TextField } from "@mui/material";

import StripeInput from "./StripeInput";

const CardField = ({ name, label, component, onChange, fieldState }) => {
  const { t } = useTranslation();

  return (
    <>
      <TextField
        fullWidth
        id={name}
        onChange={onChange}
        label={label}
        InputProps={{
          inputComponent: StripeInput,
          inputProps: {
            component,
          },
          "data-testid": `${name}-credit-card`,
        }}
        InputLabelProps={
          fieldState.empty
            ? null
            : {
                shrink: true,
              }
        }
        error={Boolean(fieldState.error)}
        helperText={fieldState.error?.message}
      />
      {fieldState.error && fieldState.error.message && name === "cardNumber" && (
        <Link
          data-testid="support-link"
          className="launch-intercom-chat"
          href={t("URLS.HELLO_MAIL")}
          variant="textXs"
          textAlign="left"
        >
          {t("BILLING.CHECKOUT.CONTACT_SUPPORT")}
        </Link>
      )}
    </>
  );
};
CardField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldState: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    empty: PropTypes.bool,
  }).isRequired,
};

export default CardField;
