import { useState, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import { instance as billingService } from "@supporting/services/billingService";
import toastService from "@supporting/services/toast";

import { Box, Text, RelativeDate } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import { teamProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";

import ResumeSubscriptionDialog from "./ResumeSubscriptionDialog";

function SubscriptionState({ team }) {
  const { t } = useTranslation();
  const [isResumeSubscriptionDialogOpen, setIsResumeSubscriptionDialogOpen] =
    useState(false);

  const isTrial = useCallback(() => {
    return team.subscription.status === "trialing";
  }, [team.subscription]);

  const resumeSubscriptionDialogOpen = useCallback(() => {
    setIsResumeSubscriptionDialogOpen(true);
  }, []);

  const resumeSubscriptionDialogClose = useCallback(() => {
    setIsResumeSubscriptionDialogOpen(false);
  }, []);

  const resumeSubscription = useCallback(async () => {
    setIsResumeSubscriptionDialogOpen(false);
    try {
      await billingService.resumeSubscription(team._id);

      toastService.sendToast({
        title: "BILLING.RESUME_SUBSCRIPTION.SUCCESS.TITLE",
        body: "BILLING.RESUME_SUBSCRIPTION.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            teamName: team.name,
          },
        },
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team._id, team.name]);

  const showResumeMessage = !(team.billing.scheduledPriceId || isTrial());

  return (
    <>
      {isTrial() && (
        <Box
          p={2.5}
          borderBottom="1px solid var(--mui-palette-grey-100)"
          data-testid="trial-message"
        >
          <Trans
            components={{
              date: (
                <RelativeDate
                  component="span"
                  date={new Date(team.subscription.trialEndDate * 1000)}
                  variant="textMd"
                  color="inherit"
                />
              ),
            }}
          >
            BILLING.CURRENT_SUBSCRIPTION.TRIAL.TIME_LEFT
          </Trans>
        </Box>
      )}

      {team.subscription.canceledAt && showResumeMessage && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p={2.5}
          borderBottom="1px solid var(--mui-palette-grey-100)"
        >
          <Text variant="subtitle1" component="p" color="text.secondary">
            {t("BILLING.RESUME_SUBSCRIPTION.STATUS_MESSAGE", {
              endDate: formatWithLocale(
                team.subscription.currentPeriodEnd * 1000
              ),
            })}
          </Text>
          <Button
            color="primary"
            variant="contained"
            onClick={resumeSubscriptionDialogOpen}
            data-testid="resume-subscription-button"
            sx={{ flex: "0 0 auto" }}
          >
            {t("BILLING.RESUME_SUBSCRIPTION.CONFIRM")}
          </Button>
        </Box>
      )}
      <ResumeSubscriptionDialog
        isOpen={isResumeSubscriptionDialogOpen}
        cancel={resumeSubscriptionDialogClose}
        answer={resumeSubscription}
      />
    </>
  );
}

SubscriptionState.propTypes = {
  team: teamProp.isRequired,
};

export default SubscriptionState;
