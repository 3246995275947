import PropTypes from "prop-types";

import { Box, LinearProgress, SvgIcon } from "@mui/material";

import { Text } from "@shared/UIKit";

import PartyPopperIcon from "@assets/img/icons/party-popper.svg";
import GiftIcon from "@assets/img/icons/wrapped-gift.svg";

function ProgressBar({ checklistProgress, shouldShowGiftIcon }) {
  return (
    <Box py={1} display="flex" alignItems="center">
      <Text variant="textMd" color="grey.500">
        {checklistProgress}%
      </Text>
      <LinearProgress
        variant="determinate"
        color="success"
        value={checklistProgress}
        sx={{
          mx: 2,
          flex: 1,
          height: 8,
          borderRadius: 2,
        }}
      />
      {shouldShowGiftIcon ? (
        <SvgIcon component={GiftIcon} fontSize="medium" />
      ) : (
        <SvgIcon
          component={PartyPopperIcon}
          fontSize="medium"
          sx={{
            filter: checklistProgress === 100 ? "none" : "grayscale(1)",
          }}
        />
      )}
    </Box>
  );
}

ProgressBar.propTypes = {
  checklistProgress: PropTypes.number.isRequired,
  shouldShowGiftIcon: PropTypes.bool,
};
ProgressBar.defaultProps = {
  shouldShowGiftIcon: true,
};

export default ProgressBar;
