/* istanbul ignore file */
import NiceModal from "@ebay/nice-modal-react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useMatches } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { StylesProvider, jssPreset } from "@mui/styles";
import ImpersonationNoticeBar from "@supporting/components/ImpersonationNoticeBar";
import ToastContainer from "@supporting/components/Toast";
import { create } from "jss";
import throttle from "lodash/throttle";

import { Box } from "@shared/UIKit";
import ThemeProvider from "@shared/UIKit/ThemeProvider";

import AppVersionUpdateBar from "@shared/components/AppVersionUpdateBar/AppVersionUpdateBar";
import NetworkLoadingBar from "@shared/components/NetworkLoadingBar/NetworkLoadingBar";
import PageTitle from "@shared/components/PageTitle/PageTitle";
import { APP_ROUTES } from "@shared/constants/routes";
import { instance as analytics } from "@shared/services/analytics";
import { instance as logger } from "@shared/services/logger";
import { history } from "@shared/services/navigation";

const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: "jss-insertion-point",
});

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const matches = useMatches();

  history.navigate = navigate;
  history.location = location;
  history.matches = matches;

  useEffect(() => {
    const logError = (event) =>
      logger.error("app", "unhandled rejection", { error: event.reason });
    const logUnhandledError = throttle(
      (event) =>
        logger.error("app", "unhandled error", {
          error: event.error || event.message,
        }),
      5000
    );
    window.addEventListener("unhandledrejection", logError);
    window.addEventListener("error", logUnhandledError);
    return () => {
      window.removeEventListener("unhandledrejection", logError);
      window.removeEventListener("error", logUnhandledError);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(APP_ROUTES.DASHBOARD.path);
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    analytics.onStateChange(location.pathname, window.location.href);
  }, [location]);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider>
        <CssBaseline />
        <ToastContainer />
        <NetworkLoadingBar />
        <Box
          id="route-outlet"
          display="flex"
          flexDirection="column"
          height="100%"
        >
          <PageTitle />
          <ImpersonationNoticeBar />
          <AppVersionUpdateBar />

          <NiceModal.Provider>
            <Outlet />
          </NiceModal.Provider>
        </Box>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default Layout;
