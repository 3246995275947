import PropTypes from "prop-types";

import { fileProp } from "./file";

export const sectionProps = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  files: PropTypes.arrayOf(fileProp),
  isCollapsed: PropTypes.bool,
  fileCount: PropTypes.number,
});

export const projectProps = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  teamId: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    access: PropTypes.bool,
    rename: PropTypes.bool,
    delete: PropTypes.bool,
    manageSteps: PropTypes.bool,
    manageMembers: PropTypes.bool,
    archive: PropTypes.bool,
    uploadFiles: PropTypes.bool,
  }).isRequired,
  sections: PropTypes.arrayOf(sectionProps),
};

export const projectTemplateProps = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  teamId: PropTypes.string.isRequired,
  permissions: PropTypes.shape({
    canmanageTemplates: PropTypes.bool,
  }).isRequired,
  sections: PropTypes.arrayOf(sectionProps),
};

export const folderProps = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape(projectProps)).isRequired,
  archivedProjects: PropTypes.arrayOf(PropTypes.shape(projectProps)),
};

export const automationFieldConditionProps = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        divider: PropTypes.bool.isRequired,
        key: PropTypes.string.isRequired,
      }),
      PropTypes.shape({
        categoryName: PropTypes.string.isRequired,
      }),
    ])
  ),
};

export const templateFolderProps = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape(projectProps)).isRequired,
  teamId: PropTypes.string,
  createdTime: PropTypes.string,
};
