/* eslint-disable react-hooks/rules-of-hooks */
import { matchPath } from "react-router-dom";

import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";

import { APP_ROUTES } from "@shared/constants/routes";
import isInIframe from "@shared/helpers/isInIframe";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import ipapi from "@shared/services/ipapi";
import { instance as logger } from "@shared/services/logger";

import {
  CATEGORY,
  SUB_CATEGORY,
  ACTION,
  TYPE,
  LABEL,
  SESSION_TYPE_TO_USER_ROLE,
  STATE,
} from "./constants";

/**
 * Analytics service
 * @description The instance of the analytics service
 * @typedef {Object} AnalyticsService
 * @property {CATEGORY} CATEGORY - The category of the event
 * @property {SUB_CATEGORY} SUB_CATEGORY - The sub-category of the event
 * @property {ACTION} ACTION - The action of the event
 * @property {TYPE} TYPE - The type of the event
 * @property {LABEL} LABEL - The label of the event
 * @property {STATE} STATE - The state of the event
 * @property {function} identify - Identify a user
 * @property {function} identifyUser - Identify a user
 * @property {function} group - Group a user
 * @property {function} track - Track an event
 * @property {function} trackV2 - Track an event
 * @property {function} page - Page view
 * @property {function} onStateChange - Handle state change
 */

/**
 * @type {AnalyticsService}
 */
export let instance;

export function initialize() {
  instance = {
    CATEGORY,
    SUB_CATEGORY,
    ACTION,
    TYPE,
    LABEL,
    STATE,
    identify,
    identifyUser,
    group,
    track,
    trackV2,
    page,
    onStateChange,
  };
  instance.isDisabled = false;
  instance.unreadCount = 0;

  const config = window.fs.config;
  if (config.segment.isDisabled) {
    instance.isDisabled = config.segment.isDisabled;
    logger.info("analytics", "analytics is disabled");
  } else {
    logger.info("analytics", "analytics is enabled");
    window.analytics.load(config.segment.writeKey);
    window.analytics.ready(() => {
      window.Intercom?.("onUnreadCountChange", (count) => {
        instance.unreadCount = count;

        eventService.emitEvent({
          eventName: EVENT_NAMES.INTERCOM.ON_UNREAD_COUNT_CHANGE,
          eventData: { count },
        });
      });
    });
  }

  const fetchDefaultUserProps = () => {
    const user = authenticationService.fetchUser();
    const props = {};
    if (user?.settings.selectedTeamId) {
      props.userTeamId = user.settings.selectedTeamId;
    }
    return props;
  };

  function identify(userId, customTraits, options) {
    const defaultTraits = fetchDefaultUserProps();
    const traits = { ...defaultTraits, ...customTraits };
    logger.info("analytics", "identify", { userId, traits, options });
    if (!instance.isDisabled) {
      window.analytics.identify(userId, traits, options);
      window.userGuiding.identify(userId, traits);
    }
  }

  function identifyUser(user) {
    const session = authenticationService.fetchSession();

    instance.isDisabled =
      instance.isDisabled || session.type === "Impersonated";

    if (!user.email) {
      window.analytics.page();
      return;
    }

    const options = {
      Intercom: {
        user_hash: user.metaData.intercomUserHash,
      },
    };

    const traits = {
      email: user.email,
      userEmailType: user.userEmailType,
      name: user.fullName,
      language: user.language,
      language_override: user.language,
      created_at: user.created,
      comment_notifications_enabled:
        user.settings.isCommentNotificationsEnabled,
    };
    if (user.settings?.selectedTeamId) {
      traits.userIdUserTeamId = `${user._id}_${user.settings.selectedTeamId}`;
    }

    if (session.type in SESSION_TYPE_TO_USER_ROLE) {
      traits.is_registered = SESSION_TYPE_TO_USER_ROLE[session.type];
    }

    identify(user._id, traits, options);
  }

  function identifyTeamUser(team) {
    const user = authenticationService.fetchUser();
    const teamMember = team.members.find(
      (member) => member.user._id === user._id
    );

    identify(
      user._id,
      {
        email: user.email,
        name: user.fullName,
        isPaying: team.subscription.isPaying,
        company: {
          id: team._id,
          name: team.name,
          created_at: team.created,
        },
        subscriptionPlan: team.subscription.basePrice.description,
        subscriptionStatus: team.subscription.status,
        stripeCustomerId: team.billing.customerId,
        ...(teamMember && {
          "User role": `${team.name}-${teamMember.role.roleName}`,
        }),
      },
      {
        Intercom: {
          user_hash: user.metaData.intercomUserHash,
        },
      }
    );
  }

  function page(options) {
    options = options || {};
    if (!instance.isDisabled) {
      window.analytics.page(options);
    }
  }

  const fetchTeamProperties = async () => {
    const user = authenticationService.fetchUser();
    const props = {};
    if (user?.settings?.selectedTeamId) {
      try {
        const userTeam = await teamService.fetchTeamByTeamId(
          user.settings.selectedTeamId
        );
        props.userTeamId = userTeam._id;
        props.userTeamName = userTeam.name;
        props.userCustomerId = userTeam.billing.customerId;
        props.userPlanId = userTeam.subscription.basePrice?.id;
        props.userIdUserTeamId = `${user._id}_${userTeam._id}`;
        props.userSubscriptionId = userTeam.billing.subscriptionId;
        props.userSubscriptionStatus = userTeam.subscription.status;
        props.userCompanySize = userTeam.wizardProgressState?.companySize;
        props.userSubscriptionPlan = userTeam.subscription.basePrice?.name;
      } catch (error) {
        logger.warn("analytics", "fetchTeamProperties", { error });
      }
    }
    return props;
  };

  function trackV2({
    action,
    category,
    subCategory,
    type,
    state,
    metaData,
    options,
  }) {
    const eventName = [action, category, subCategory, type, state]
      .filter(Boolean)
      .join("-")
      .toLowerCase();

    const properties = {
      trigger: "ui",
      triggerType: "filestage",
      isEmbedded: isInIframe(),
      // Event name for Google Analytics 4
      GA4EventName: eventName.replace(/-|\s/g, "_"),
    };
    // eslint-disable-next-line promise/prefer-await-to-then,promise/catch-or-return
    fetchTeamProperties().then((teamProps) => {
      Object.assign(properties, metaData, teamProps);

      logger.info("analytics", "track", {
        action,
        category,
        subCategory,
        type,
        state,
        properties,
        options,
      });

      /* istanbul ignore next */
      if (instance.isDisabled) {
        return;
      }

      window.userGuiding.track(eventName, properties);

      window.analytics.track(eventName, properties, options);
    });
  }

  function track(action, category, trackingProps = {}, options = {}, state) {
    const eventName = `${action}-${category}${
      state ? `-${state}` : ""
    }`.toLowerCase();

    const properties = {
      trigger: "ui",
      triggerType: "filestage",
      // Event name for Google Analytics 4
      GA4EventName: eventName.replace(/-|\s/g, "_"),
    };

    properties.isEmbedded = isInIframe();

    // eslint-disable-next-line promise/prefer-await-to-then,promise/catch-or-return
    fetchTeamProperties().then((teamProps) => {
      Object.assign(properties, trackingProps, teamProps);

      logger.info("analytics", "track", {
        action,
        category,
        properties,
        options,
        state,
      });

      if (instance.isDisabled) {
        return;
      }

      window.userGuiding.track(eventName, properties);

      window.analytics.track(eventName, properties, options);
    });
  }

  function group(groupId, traits = {}) {
    const user = authenticationService.fetchUser();
    if (user) {
      traits.userIdUserTeamId = `${user._id}_${groupId}`;
    }
    if (!instance.isDisabled) {
      window.analytics.group(groupId, traits);
    }
  }

  eventService.addListener(EVENT_NAMES.USER.AUTHENTICATED, (event) =>
    identifyUser(event.eventData.user)
  );

  eventService.addListener(EVENT_NAMES.USER.UPDATED, (event) =>
    identifyUser(event.eventData.user)
  );

  function reset() {
    window.analytics.reset();
  }

  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, reset);

  function onTeamChange(event) {
    const team = event.eventData.team;

    if (team.isTeamMember) {
      identifyTeamUser(team);
      group(team._id);
    }
  }

  eventService.addListener(EVENT_NAMES.TEAM.SELECTED.UPDATED, onTeamChange);

  eventService.addListener(EVENT_NAMES.TEAM.SELECTED.ADDED, onTeamChange);

  eventService.addListener(
    EVENT_NAMES.USER.LOGGED_IN,
    async ({ eventData: { user } }) => {
      track("Account", "Login", {
        userId: user._id,
        email: user.email,
      });

      const location = await ipapi.fetchLocationInformation();
      identify(user._id, {
        "Continent Code": location.continent_code,
        "Country Code": location.country_code,
        "Country Name": location.country_name,
        "Region Code": location.region_code,
        "Region Name": location.region,
        City: location.city,
        "Time Zone Id": location.timezone,
      });
    }
  );

  eventService.addListener(
    EVENT_NAMES.USER.SIGNED_UP,
    ({ eventData: { user } }) => {
      const trackingProps = {
        userId: user._id,
        email: user.email,
      };

      const registeredFromIntegrations =
        sessionStorage.getItem("integrationSource");

      if (registeredFromIntegrations) {
        trackingProps.sourceIntegration = registeredFromIntegrations;
      }

      track("Signup", "Account", trackingProps);
    }
  );

  function isAuthCallback(pathname) {
    return [APP_ROUTES.WORKSPACE_AUTH_CALLBACK.path].some((route) => {
      const match = matchPath(route, pathname);
      return Boolean(match);
    });
  }

  function onStateChange(path, url) {
    /* istanbul ignore next */
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: [
          APP_ROUTES.SIGNUP.path,
          APP_ROUTES.LOGIN.path,
        ].every((route) => !matchPath(route, path)),
      });
    }
    if (!isAuthCallback(path)) {
      page({
        path,
        url,
      });
    }
  }
}
