/* eslint import/namespace:0 */
/* istanbul ignore file */
import { format, intervalToDuration } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  fr,
  frCA,
  frCH,
  de,
  enAU,
  enCA,
  enGB,
  enIN,
  enNZ,
  enUS,
  es,
} from "date-fns/locale";

const locales = {
  fr,
  frCA,
  frCH,
  de,
  enAU,
  enCA,
  enGB,
  enIN,
  enNZ,
  enUS,
  es,
};

/**
 * Date Parser without Time helper function
 *
 * @description This function gets a string and parses it to a Date
 * object ignoring the time.
 * @param {string} date - [Required] String date to convert.
 * @returns {Date} Returns the date object without the time specified.
 */
const dateParsedIgnoringTimeComponent = (date) => {
  const [year, month, day] = date.split("T")[0].split("-");
  return new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10)
  );
};

/**
 * Get Day Difference helper function
 *
 * @description This function gets two Date objects and
 * returns the difference in days.
 * @param {Date} startDate - [Required] Start date.
 * @param {Date} endDate - [Required] End date.
 * @returns {number} Returns the number of days between the two dates.
 */
const getDayDifference = (startDate, endDate) => {
  return intervalToDuration({
    start: startDate,
    end: endDate,
  }).days;
};

/**
 * @typedef {Object} LocaleObject
 * @property {Locale} locale - The locale code
 */

/**
 * Get Current Locale For `date-fns` helper function
 *
 * @description This function gets the current locale for `date-fns`.
 * It checks the `window.__localeId__` global variable and returns
 * the locale object. If no `window.__localeId__` is set, it
 * automatically returns `enUS` or `enGB` depending on the user browser locale,
 * otherwise it returns the browser locale.
 *
 * @returns {LocaleObject} an object with the current locale.
 */
const getCurrentLocaleForDateFns = () => {
  const currentLocale = window.__localeId__ || "en";
  let calculatedLocale;

  if (currentLocale === "en") {
    calculatedLocale = locales.enUS;
  } else {
    const [lang, variation] = window.__localeId__.split(/[-_]/g);
    calculatedLocale =
      locales[
        `${lang.toLowerCase()}${variation ? variation.toUpperCase() : ""}`
      ] ||
      locales[lang.toLowerCase()] ||
      locales.enGB;
  }

  return { locale: calculatedLocale };
};

/**
 * Format in UTC helper function
 *
 * @description This function is used for formatting a date in UTC.
 * Gets date, format and locale and returns the formatted date.
 * @param {Date} date - [Required] Date to format.
 * @param {string} fmt - [Required] The format string to use.
 * @param {LocaleObject} locale - [Optional] The locale to use.
 * By default it is empty and uses the standard locale
 * @returns {string} Returns the formatted date.
 */
const formatInUTC = (date, fmt, locale = {}) =>
  formatInTimeZone(date, "UTC", fmt, { timeZone: "UTC", ...locale });

/**
 * Is Time End Of the Day helper function
 *
 * @description This function gets a Date and checks if it is the end of the day in any timezone.
 * By the business logic, end of the day time is defined as `59:59` (mm:ss).
 * @param {Date} dueDate - [Required] Date object to check.
 * @returns {boolean} Returns if the date is the end of the day.
 */
const isTimeEndOfTheDay = (dueDate) => {
  return format(new Date(dueDate), "ss") === "59";
};

/**
 * Is Time End Of the Local Day helper function
 *
 * @description This function gets a Date and checks if it is the end of the day.
 * By the business logic, end of the day is defined as `23:59:59` in local time.
 * @param {Date} dueDate - [Required] Date object to check.
 * @returns {boolean} Returns if the date is the end of the day.
 */
const isTimeEndOfTheLocalDay = (dueDate) => {
  return format(new Date(dueDate), "HH:mm:ss") === "23:59:59";
};

/**
 * Formats the given date according to the given format string and current locale.
 *
 * @param {Date} date - [Required] Date to format.
 * @param {string} formatStr - [Optional] The format string to use.
 * Defaults to `PP`. More information with formats can be found [here](https://date-fns.org/v2.28.0/docs/format)
 *
 * @returns {string} The formatted date.
 */
const formatWithLocale = (date, formatStr = "PP") => {
  const optionsWithLocale = getCurrentLocaleForDateFns();
  return format(date, formatStr, optionsWithLocale);
};

export {
  formatInUTC,
  formatWithLocale,
  isTimeEndOfTheDay,
  isTimeEndOfTheLocalDay,
  getCurrentLocaleForDateFns,
  dateParsedIgnoringTimeComponent,
  getDayDifference,
};
