import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Icon, SvgIcon, Box } from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import {
  DIALOG_STATE,
  UPGRADE_MESSAGE_TRANSLATIONS,
} from "@workflow/constants/automation";

import { Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

function AutomationsUpgradeMessage({ automationsState, teamId }) {
  const { t } = useTranslation();

  const upgradeClickHandler = () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AUTOMATION_UPGRADE_BUTTON,
      {
        component:
          automationsState === DIALOG_STATE.NOT_SUPPORTED
            ? "other-plans-automation-popover"
            : "pro-plan-automation-popover",
      }
    );
    NiceModal.show(SelectPlanDialog, { teamId });
  };

  return (
    <Box
      display="flex"
      mt={2}
      minHeight={51}
      alignSelf="center"
      data-testid="automations-upgrade-message"
      width={{ xs: "95%", sm: "630px" }}
      borderRadius={1}
      zIndex={1}
      border="1px solid var(--mui-palette-upgrade-main)"
    >
      <Box display="flex" alignItems="center" ml={1}>
        <Icon data-testid="export-disabled-icon">
          <SvgIcon component={PremiumIcon} color="upgrade" />
        </Icon>
      </Box>
      <Box flex="1" m="auto" textAlign="left" ml={1} p={1}>
        <Text
          variant="subtitle1"
          component="span"
          translate={UPGRADE_MESSAGE_TRANSLATIONS[automationsState]}
        />
        <Button
          variant="text"
          color="upgrade"
          onClick={upgradeClickHandler}
          data-testid="upgrade-link"
        >
          {t("AUTOMATIONS.UPGRADE.PROFESSIONAL.MESSAGE.CTA")}
        </Button>
      </Box>
    </Box>
  );
}

AutomationsUpgradeMessage.propTypes = {
  automationsState: PropTypes.oneOf([
    DIALOG_STATE.HAS_LIMIT,
    DIALOG_STATE.NOT_SUPPORTED,
    DIALOG_STATE.PROFESSIONAL,
    DIALOG_STATE.PROFESSIONAL_LIMIT,
  ]).isRequired,
  teamId: PropTypes.string.isRequired,
};

export default AutomationsUpgradeMessage;
