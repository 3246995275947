import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import cx from "clsx";

import { Tooltip, Text } from "@shared/UIKit";

import { reviewProp } from "@shared/props/review";

import classes from "./CommentCount.module.css";

export default function CommentCount({ review }) {
  const { t } = useTranslation();
  const allResolved =
    review.commentCount.unresolved === 0 && review.commentCount.total > 0;
  return (
    <Tooltip
      title={t("FILE.REVIEW.COMMENTS.TOOLTIP.TITLE")}
      description={
        review.permissions.canManageIssueResolutions
          ? t("FILE.REVIEW.COMMENTS.TOOLTIP.DESCRIPTION")
          : null
      }
    >
      <Text
        component="span"
        variant="subtitle2"
        fontWeight="fontWeight.medium"
        cursor="help"
        className={cx(classes.commentCount, {
          [classes.allResolved]: allResolved,
          [classes.unresolved]: review.commentCount.unresolved > 0,
        })}
      >
        {review.permissions.canManageIssueResolutions
          ? `${review.commentCount.resolved}/${review.commentCount.total}`
          : review.commentCount.total}
      </Text>
    </Tooltip>
  );
}
CommentCount.propTypes = {
  review: reviewProp.isRequired,
  isReviewCard: PropTypes.bool,
};

CommentCount.defaultProps = {
  isReviewCard: false,
};
