import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import reviewReportService from "@feedback/services/reviewReportService";
import {
  CircularProgress,
  MenuItem,
  SvgIcon,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

const ExportReportMenuItem = forwardRef(
  (
    {
      exportReport,
      resourceId,
      startIcon,
      label,
      showUpgradeIcon,
      ...restProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    const isExportReportInProgress =
      reviewReportService.isExportReportInProgress(resourceId);

    return (
      <MenuItem
        onClick={exportReport}
        disabled={isExportReportInProgress}
        {...restProps}
        ref={ref}
      >
        <ListItemIcon>
          {isExportReportInProgress ? (
            <CircularProgress size={20} />
          ) : (
            <SvgIcon component={startIcon} />
          )}
        </ListItemIcon>
        <ListItemText>{t(label)}</ListItemText>

        {showUpgradeIcon && (
          <SvgIcon
            component={PremiumIcon}
            inheritViewBox
            color="upgrade"
            size="small"
            data-testid="export-report-upgrade"
          />
        )}
      </MenuItem>
    );
  }
);

ExportReportMenuItem.displayName = "ExportReportMenuItem";

ExportReportMenuItem.propTypes = {
  exportReport: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  startIcon: PropTypes.elementType.isRequired,
  showUpgradeIcon: PropTypes.bool,
};

ExportReportMenuItem.defaultProps = {
  showUpgradeIcon: false,
};

export default ExportReportMenuItem;
