import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Text } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(9),

    "&:last-child": {
      marginBottom: theme.spacing(2),
    },
  },
}));

export default function CreditCard({
  brand,
  last4,
  expirationMonth,
  expirationYear,
}) {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid className={classes.card} container justifyContent="space-between">
      <Grid item xs={12} sm={6}>
        <Text
          color={theme.color.gray["600"]}
          translate="BILLING.CHANGE_PAYMENT_DETAILS.LABEL_CARD"
        />
        <Text variant="h6">{`${brand} ****${last4}`}</Text>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Text
          color={theme.color.gray["600"]}
          translate="BILLING.CHANGE_PAYMENT_DETAILS.LABEL_EXPIRES"
        />
        <Text variant="h6">{`${expirationMonth}/${expirationYear}`}</Text>
      </Grid>
    </Grid>
  );
}

CreditCard.propTypes = {
  brand: PropTypes.string.isRequired,
  last4: PropTypes.string.isRequired,
  expirationMonth: PropTypes.number.isRequired,
  expirationYear: PropTypes.number.isRequired,
};
