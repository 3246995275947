import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import { HelpOutline } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { DisableReviewDecisionGate } from "@supporting/hooks/useGatedFeature/featureGates";

import { Box, InfoToolTip, Toggle } from "@shared/UIKit";

const AddReviewerDialogRequestDecisionToggle = ({
  teamId,
  requestDecision,
  handleRequestDecision,
}) => {
  const { t } = useTranslation();
  const { checkAndProceed, isLimitReached, IconAsset } = useGatedFeature({
    featureGate: DisableReviewDecisionGate,
    teamId,
  });

  return (
    <Box
      mt={1.75}
      mb={2.5}
      pb={1}
      display="flex"
      alignItems="center"
      data-testid="add-reviewer-dialog-request-decision-toggle"
    >
      <Toggle
        label={
          <>
            {t("REVIEWER.REQUEST_REVIEW_DECISION")}{" "}
            <InfoToolTip
              title={
                <Trans
                  components={{
                    anchor: (
                      <Link
                        color="accent"
                        href={t("REVIEWER.REQUEST_REVIEW_DECISION_HELP")}
                        target="_blank"
                      />
                    ),
                  }}
                >
                  {requestDecision
                    ? "FILEMEMBER.ADD.REQUEST_DECISION.HELP.TEXT"
                    : "FILEMEMBER.ADD.REQUEST_DECISION.HELP.TEXT_DISABLED"}
                </Trans>
              }
            >
              <IconButton size="small" disableRipple color="default-light">
                <HelpOutline fontSize="inherit" />
              </IconButton>
            </InfoToolTip>
            {isLimitReached && (
              <IconButton
                size="small"
                disableRipple
                color="default-light"
                onClick={checkAndProceed()}
                data-testid="add-new-step-upgrade"
              >
                <IconAsset />
              </IconButton>
            )}
          </>
        }
        slotProps={{
          switch: {
            defaultChecked: true,
            "data-testid": "add-reviewer-dialog-request-decision-switch",
            onChange: handleRequestDecision,
            disabled: isLimitReached,
          },
          formControlLabel: {
            onClick: checkAndProceed(),
            sx: {
              "& .MuiFormControlLabel-label": {
                textWrap: "nowrap",
                alignItems: "center",
              },
            },
            slotProps: {
              typography: {
                fontWeight: "fontWeight.regular",
              },
            },
          },
        }}
      />
    </Box>
  );
};

AddReviewerDialogRequestDecisionToggle.propTypes = {
  requestDecision: PropTypes.bool.isRequired,
  handleRequestDecision: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default AddReviewerDialogRequestDecisionToggle;
