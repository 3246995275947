import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import projectService from "@workflow/services/projectService";
import classnames from "classnames";

import { Box, EditableLabel, Tooltip } from "@shared/UIKit";

import validationUtils from "@shared/helpers/validationUtils";
import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

const useStyles = makeStyles((theme) => ({
  projectNameContainer: {
    overflow: "hidden",
    minHeight: 48,
    paddingLeft: 1,
    paddingRight: 1,
    [theme.breakpoints.up("md")]: {
      marginRight: 5,
    },
  },
  editableLabel: { flexGrow: 1 },
  input: {
    variant: "h2",
    fontSize: 24,
  },
}));

function ProjectHeaderName({ project, team }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const handleChangeEditMode = (mode) => setIsEditing(mode);

  const isEditableLabelDisabled =
    !team.isTeamMember || !project.permissions.rename || project.isArchived;

  const handleFavoriteProject = async () => {
    try {
      await projectService.favoriteProject(project.id, !project.isFavorite);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const handleUpdateProjectName = useCallback(
    async (projectName) => {
      try {
        await projectService.updateProjectName(project.id, projectName);

        analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.PROJECT);
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [project.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setIsEditing(false);
  }, [project.name]);

  return (
    <Box
      marginTop={-0.5}
      className={classes.projectNameContainer}
      display="flex"
      flex={1}
      flexDirection="row"
      justifyContent="flex-start"
      data-testid="project-header-name-container"
    >
      <Tooltip
        title={
          project.isFavorite
            ? t("FAVORITE_PROJECTS.REMOVE.TOOLTIP")
            : t("FAVORITE_PROJECTS.REMOVE.TOOLTIPFAVORITE_PROJECTS.ADD.TOOLTIP")
        }
      >
        <IconButton
          data-testid="favorite-project-button"
          onClick={handleFavoriteProject}
          sx={{
            color: project.isFavorite ? "grey.800" : "default",
          }}
          size="small"
        >
          {project.isFavorite ? <StarIcon /> : <StarBorderIcon />}
        </IconButton>
      </Tooltip>

      <EditableLabel
        className={classnames("tour__project-name", classes.editableLabel)}
        onAfterSave={handleUpdateProjectName}
        text={project.name}
        onBeforeSave={validationUtils.checkName}
        tooltipProps={{
          title: isEditableLabelDisabled
            ? project.name
            : t("PROJECT.PROJECT.RENAME"),
          placement: "bottom",
          text: !isEditableLabelDisabled ? project.name : "",
        }}
        classes={{
          input: classes.input,
          text: classes.input,
        }}
        disabled={isEditableLabelDisabled}
        isEditing={isEditing}
        onChangeEditMode={handleChangeEditMode}
      />
    </Box>
  );
}

ProjectHeaderName.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  team: teamProp.isRequired,
};

export default ProjectHeaderName;
