import { useCallback, useEffect } from "react";

import { Drawer } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import AwaitingReviewsGuest from "@supporting/components/ReviewersAuthenticationFlow/AwaitingReviewsGuest";
import authenticationService from "@supporting/services/authentication";
import { pendingReviewsData } from "@workflow/data/reviews";
import classNames from "classnames";

import { Box } from "@shared/UIKit";

import { useDialogState, useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import PendingReviews from "./PendingReviews";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "400px",
    overflowX: "hidden",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    background: theme.color.gray[50],
    display: "flex",
  },
  backdrop: {
    opacity: "1 !important",
  },
}));

function PendingReviewsDrawer() {
  const classes = useStyles();
  const theme = useTheme();
  const isUserGuest = authenticationService.fetchSession().type === "Guest";

  const {
    isOpen,
    closeDialog: closeDrawer,
    openDialog: openDrawer,
  } = useDialogState();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseDrawer = useCallback(() => {
    closeDrawer();
    pendingReviewsData.removeQueryIfInvalidated();
  }, [closeDrawer]);

  useEffect(() => {
    const onDrawerButtonClicked = (event) => {
      openDrawer();
      analytics.track(
        isUserGuest
          ? analytics.ACTION.OPENED_TEASER
          : analytics.ACTION.OPENED_OVERVIEW,
        analytics.CATEGORY.PENDING_REVIEW,
        {
          [analytics.LABEL.OPENED_VIA]: event.eventData.label,
        }
      );
    };

    eventService.addListener(
      EVENT_NAMES.PENDING_REVIEWS.NAV_BUTTON.CLICKED,
      onDrawerButtonClicked
    );

    eventService.addListener(
      EVENT_NAMES.PENDING_REVIEWS.DRAWER.CLOSE_TRIGGERED,
      handleCloseDrawer
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.PENDING_REVIEWS.NAV_BUTTON.CLICKED,
        onDrawerButtonClicked
      );
      eventService.removeListener(
        EVENT_NAMES.PENDING_REVIEWS.DRAWER.CLOSE_TRIGGERED,
        closeDrawer
      );
    };
  }, [closeDrawer, openDrawer, isUserGuest, handleCloseDrawer]);

  return (
    <Box data-testid="pending-reviews-drawer-container">
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        slotProps={{
          backdrop: {
            className: classNames({ [classes.backdrop]: isMobile }),
          },
        }}
        open={isOpen}
        onClose={handleCloseDrawer}
        data-testid="pending-reviews-drawer"
      >
        {isUserGuest ? <AwaitingReviewsGuest /> : <PendingReviews />}
      </Drawer>
    </Box>
  );
}

export default PendingReviewsDrawer;
