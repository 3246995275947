import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Redeem, Add } from "@mui/icons-material";
import { Button, Card, CardHeader, CardContent } from "@mui/material";
import { instance as billingService } from "@supporting/services/billingService";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Box, Text, Tooltip, Image } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import { useDialogState } from "@shared/hooks";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import CouponImage from "@assets/img/images/coupon.png";

import CouponDialog from "./CouponDialog/CouponDialog";

const Coupon = () => {
  const { t } = useTranslation();
  const [couponData, setCouponData] = useState("");
  const [invalidCoupon, setInvalidCoupon] = useState([]); //This is kept as an array so it provides a fresh reference on every render
  const [refetch, setRefetch] = useState(false);

  const team = teamService.getSelectedTeam();
  const teamId = team._id;

  const getDuration = (duration) => {
    switch (duration) {
      case "forever":
        return t("BILLING.CURRENT_COUPON.DURATION.FOREVER");
      case "once":
        return t("BILLING.CURRENT_COUPON.DURATION.ONCE");
      case "repeating":
        return t("BILLING.CURRENT_COUPON.DURATION.REPEATING");
      default:
        return null;
    }
  };

  const getMonths = (months) => {
    switch (months) {
      case 1:
        return t("BILLING.CURRENT_COUPON.DURATION_IN_MONTHS.ONE");
      case "other":
        return t("BILLING.CURRENT_COUPON.DURATION_IN_MONTHS.OTHER");
      default:
        return null;
    }
  };

  const formatDate = (timeStamp) => {
    const date = new Date(timeStamp * 1000);

    return formatWithLocale(date, "d MMM y");
  };

  const { isOpen, openDialog, closeDialog } = useDialogState();

  const redeemCoupon = async (couponCode) => {
    try {
      await billingService.redeemCoupon(teamId, couponCode);

      eventService.emitEvent({
        eventName: EVENT_NAMES.TEAM.COUPON_CHANGED,
        eventData: {},
      });

      toastService.sendToast({
        title: "BILLING.ADD_COUPON.SUCCESS.TITLE",
        body: "BILLING.ADD_COUPON.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            teamName: team.name,
          },
        },
      });
      closeDialog();
      setRefetch(true);
    } catch (error) {
      if (error?.data?.message === "failed to redeem coupon for a customer") {
        setInvalidCoupon([true]);
      } else {
        errorHandlerService.handleError(error);
      }
    }
  };

  useEffect(() => {
    const getCoupon = async (id) => {
      try {
        const response = await billingService.getCoupon(id);
        setCouponData(response);
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    };
    setInvalidCoupon([]);
    getCoupon(teamId);
  }, [teamId, refetch]);

  return (
    <>
      <Card>
        <CardHeader
          title={t("BILLING.COUPON")}
          action={
            <Tooltip
              placement="left"
              title={
                couponData?.isValid
                  ? t("BILLING.ADD_COUPON.BUTTON_REPLACE")
                  : t("BILLING.ADD_COUPON.BUTTON")
              }
            >
              <Button
                onClick={openDialog}
                data-testid="team-billing-open-coupon"
                size="large"
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: "50%",
                  padding: 2,
                  aspectRatio: "1/1",
                  position: "absolute",
                  right: 20,
                  bottom: -20,
                }}
              >
                <Add />
              </Button>
            </Tooltip>
          }
          sx={{ position: "relative" }}
        />
        <CardContent>
          {couponData?.isValid ? (
            <Box
              display="flex"
              flexDirection="column"
              data-testid="team-billing-valid-coupon"
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Redeem color="default-light" />
                &nbsp;
                <Text variant="body1">{couponData.coupon.id}</Text>
              </Box>
              <Text
                color="text.secondary"
                data-testid="team-billing-coupon-info"
              >
                {couponData.coupon.amount_off &&
                  couponData.coupon.amount_off / 100}
                {couponData.coupon.percent_off}
                {billingService.getCurrencySymbol(couponData.coupon.currency) ||
                  "%"}
                &nbsp;
                {t("BILLING.CURRENT_COUPON.OFF")}&nbsp;
                {getDuration(couponData.coupon.duration)}
                {getMonths(couponData.coupon.duration_in_months)}
              </Text>
              {couponData.startAt && (
                <Text color="text.secondary">
                  {t("BILLING.CURRENT_COUPON.REDEEMED_AT")}&nbsp;
                  {formatDate(couponData.startAt)}
                </Text>
              )}
              {couponData.endAt && (
                <Text color="text.secondary">
                  {t("BILLING.CURRENT_COUPON.VALID_UNTIL")}&nbsp;
                  {formatDate(couponData.endAt)}
                </Text>
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              data-testid="team-billing-no-coupon"
            >
              <Image src={CouponImage} alt="" />
              <Text textAlign="center">
                {t("BILLING.CURRENT_COUPON.EMPTY_TEXT")}
              </Text>
            </Box>
          )}
        </CardContent>
      </Card>
      <CouponDialog
        open={isOpen}
        closeDialog={closeDialog}
        submitCoupon={redeemCoupon}
        invalidCoupon={invalidCoupon}
      />
    </>
  );
};

export default Coupon;
