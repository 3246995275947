import { useEffect } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

import { REVIEW_DECISION_STATES_TO_ACTIONS } from "@feedback/constants/reviewDecisionState";
import commentService from "@feedback/services/comment";
import compareReviewsService from "@feedback/services/compareReviewsService";
import reviewService from "@feedback/services/reviewService";
import { processSignup } from "@supporting/helpers/processSignup";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import projectService from "@workflow/services/projectService";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { LoadingSpinner, Box } from "@shared/UIKit";

import { useSessionStorage } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

const AuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [, setSelectedReviewsInSession] = useSessionStorage("selectedReviews");

  useEffect(() => {
    const { action, context } = location.state;
    const redirectBackToFileView = ({ comment, finalizedReview, userId }) => {
      const pendingDigitalSignature =
        action === REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED &&
        context.digitalSignatureRequired
          ? true
          : null;

      if (context.reviewIds) {
        setSelectedReviewsInSession(context.reviewIds);
      }

      const params = Object.fromEntries(
        new URLSearchParams(context.queryParamsBeforeRedirect).entries()
      );

      const queryParams = omitBy(
        {
          ...params,
          comment,
          pendingDigitalSignature,
          userId,
          finalizedReview,
        },
        isNil
      );

      navigate({
        pathname: context.pathBeforeRedirect,
        search: createSearchParams(queryParams).toString(),
      });
    };

    const likeComment = async () => {
      const { commentId } = context;

      await commentService.like(commentId);

      analytics.track(analytics.ACTION.LIKED, analytics.CATEGORY.COMMENT);
      redirectBackToFileView({ comment: commentId });
    };

    const finalizeReview = async () => {
      const isBatchReview = context.reviewIds?.length > 0;

      if (
        action === REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED &&
        context.digitalSignatureRequired
      ) {
        return redirectBackToFileView({ finalizedReview: false });
      }

      reviewService.clearCache();
      try {
        await reviewService.submitReviewDecision(
          isBatchReview ? context.reviewIds : [context.reviewId],
          action,
          context.isDemoProject
        );
        redirectBackToFileView({ finalizedReview: true });
      } catch (error) {
        if (error.status === 403) {
          const userId = authenticationService.fetchUser()._id;
          redirectBackToFileView({ finalizedReview: false, userId });
        } else {
          redirectBackToFileView({ finalizedReview: true });
        }
      }
    };

    async function fireEvents(isReply, comment) {
      const review = await reviewService.fetchReviewById(comment.reviewId);
      const project = await projectService.fetchProjectById(review.projectId);
      analytics.track(
        analytics.ACTION.CREATED,
        isReply ? analytics.CATEGORY.REPLY : analytics.CATEGORY.COMMENT,
        {
          viewerType: comment.marker ? comment.marker.clazz : "general",
          commentType: comment.marker ? "specific" : "general",
          ...compareReviewsService.withPageMetadata({
            page: "file-view",
            isDemoContent: project.isDemoProject,
          }),
        }
      );
      commentService.trackCommentEvents(comment);
    }

    const afterCommentAction = (comment) => {
      const isReply = Boolean(comment.parentId);
      fireEvents(isReply, comment);
      redirectBackToFileView({
        comment: isReply ? comment.parentId : comment.id,
      });
    };
    const handleAwaitingReviews = () => {
      redirectBackToFileView({});
    };
    const createReply = async () => {
      const comment = await commentService.createReply(
        context.comment.parentId,
        context.comment
      );
      afterCommentAction(comment);
    };

    const createComment = async () => {
      if (context.comment.parentId) {
        createReply();
        return;
      }

      const comment = await commentService.create(context.comment);
      afterCommentAction(comment);
    };
    const handleReviewerAuthenticationFlow = async () => {
      const teams = await teamService.getTeams();
      if (teams.length) {
        teamService.triggerJoinedTeamEventForNewUser(teams[0]);
      } else {
        await processSignup("review-files", true);
      }
    };

    handleReviewerAuthenticationFlow();
    switch (action) {
      case "create-comment":
        createComment();
        break;
      case "create-reply":
        createReply();
        break;
      case REVIEW_DECISION_STATES_TO_ACTIONS.APPROVED:
      case REVIEW_DECISION_STATES_TO_ACTIONS.NEEDS_CHANGES:
        finalizeReview();
        break;
      case "like":
        likeComment();
        break;
      case "awaiting-reviews":
        handleAwaitingReviews();
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      width="100%"
      height="100vh"
      data-testid="google-loading-spinner"
    >
      <LoadingSpinner />
    </Box>
  );
};

export default AuthCallback;
