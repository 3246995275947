import { useState, useEffect } from "react";

import { instance as teamService } from "@supporting/services/team";

/**
 * Use Billing Limits hook
 *
 * @description This hook is used to get the billing limits for the current team.
 * It gets the teamId and using the teamService it will return the billingLimits.
 * @typedef FSTGId
 * @param {FSTGId} teamId - [Required] The teamId to get the billing limits for.
 * @returns {Object} - Returns billing limits of the team
 */
export default function useBillingLimits(teamId) {
  const [billingLimits, setBillingLimits] = useState({
    areFilePickerPluginsEnabled: true,
    isReviewWebsitesEnabled: true,
    maxProjectSectionNumber: 1,
    isExportFileReportEnabled: true,
  });

  useEffect(() => {
    let mounted = true;

    async function fetchTeam() {
      const team = await teamService.fetchTeamByTeamId(teamId);
      if (mounted) {
        setBillingLimits(team.subscriptionLimits);
      }
    }
    fetchTeam();
    return () => (mounted = false);
  }, [teamId]);

  return billingLimits;
}
