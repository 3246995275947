import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useTranslation } from "react-i18next";

import { Check } from "@mui/icons-material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";

import { Image, Dialog, Text } from "@shared/UIKit";

import SecureDataImage from "@assets/img/images/securedata.png";
import SecureDataImage2X from "@assets/img/images/securedata@2x.png";

function TranscodingHelperDialog() {
  const { t } = useTranslation();
  const modal = useModal();

  return (
    <Dialog
      isOpen={modal.visible}
      cancel={modal.remove}
      data-testid="transcoding-helper-dialog"
      hideBackdrop={false}
      useDefaultBackground={false}
      title={t("TRANSCODING.HELP.HEADLINE")}
    >
      <Image
        src={SecureDataImage}
        srcSet={`${SecureDataImage} 1x, ${SecureDataImage2X} 2x`}
        alt={t("TRANSCODING.HELP.HEADLINE")}
      />

      <List>
        {[1, 2, 3].map((key) => (
          <ListItem key={`item-${key}`}>
            <ListItemIcon>
              <Check color="primary" />
            </ListItemIcon>
            <ListItemText>
              <Text variant="body2">
                {t(`TRANSCODING.HELP.LIST-ELEMENT-${key}`)}
              </Text>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default NiceModal.create(TranscodingHelperDialog);
