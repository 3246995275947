import { initReactI18next } from "react-i18next";

import i18n from "i18next";
import Backend from "i18next-http-backend";

import LANGUAGES from "@shared/constants/languages";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as logger } from "@shared/services/logger";

const TAG = "shared:utils:localisation-service";
export const LANGUAGE_KEYS = Object.freeze({
  ALTERNATIVE_DELIMITER: ["-", ":"],
  DELIMITER: "_",
  DEFAULT_LANGUAGE: "en",
  DEFAULT_BROWSER_LOCALES: {
    en: "en-gb",
    de: "de",
    fr: "fr",
    es: "es",
  },
  LANGUAGES,
});
const alternativeDelimiters = new RegExp(
  LANGUAGE_KEYS.ALTERNATIVE_DELIMITER.join("|"),
  "g"
);

const isLanguageKeySupported = (languageKey) =>
  languageKey
    ? Object.values(LANGUAGE_KEYS.LANGUAGES).includes(languageKey)
    : false;

const getBrowserLocale = () => {
  const locale = (window.navigator.language || "").replace(
    alternativeDelimiters,
    LANGUAGE_KEYS.DELIMITER
  );
  const [language, country] = locale.split(LANGUAGE_KEYS.DELIMITER);
  const browserLocale = country
    ? `${language}${LANGUAGE_KEYS.DELIMITER}${country.toUpperCase()}`
    : language;

  logger.info(TAG, "determined language", {
    determined: browserLocale,
    normalized: language,
    clientLocale: locale,
    clientLocals: window.navigator.languages,
  });

  return {
    browserLocale,
    browserLocaleLanguage: language,
  };
};

const setDateFnsLocale = (languageKey) => {
  const { browserLocale, browserLocaleLanguage } = getBrowserLocale();
  window.__localeId__ =
    browserLocaleLanguage === languageKey
      ? browserLocale
      : LANGUAGE_KEYS.DEFAULT_BROWSER_LOCALES[languageKey];
};

const determineLanguage = () => {
  const { browserLocaleLanguage } = getBrowserLocale();
  return isLanguageKeySupported(browserLocaleLanguage)
    ? browserLocaleLanguage
    : LANGUAGE_KEYS.DEFAULT_LANGUAGE;
};

const activateLanguage = (languageKey) => {
  i18n.changeLanguage(languageKey);

  logger.info(TAG, "activated language", {
    language: languageKey,
  });

  // inform all about the language activation
  eventService.emitEvent({
    eventName: EVENT_NAMES.LANGUAGE.CHANGED,
    eventData: {},
  });

  return languageKey;
};

const setLanguage = (language) => {
  const newLanguage = isLanguageKeySupported(language)
    ? language
    : determineLanguage();

  setDateFnsLocale(newLanguage);
  activateLanguage(newLanguage);
  return newLanguage;
};

const getLocale = (languageKey = determineLanguage()) => {
  const { browserLocale, browserLocaleLanguage } = getBrowserLocale();

  switch (languageKey) {
    case browserLocaleLanguage:
      return browserLocale;
    case "en":
      return "en_GB";
    default:
      return LANGUAGE_KEYS.DEFAULT_BROWSER_LOCALES[languageKey];
  }
};

export let instance;

export async function initialize() {
  await i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: `${fs.config.appBase}/locales/${fs.config.appVersion}/i18n/{{lng}}.json`,
      },
      fallbackLng: [determineLanguage(), "en"],
      interpolation: {
        escapeValue: false,
      },
      react: {
        transKeepBasicHtmlNodesFor: [
          "br",
          "strong",
          "i",
          "p",
          "b",
          "small",
          "a",
        ],
      },
      keySeparator: false,
      nsSeparator: false,
    });
  eventService.addListener(EVENT_NAMES.USER.AUTHENTICATED, ({ eventData }) =>
    setLanguage(eventData.user.language)
  );
  instance = {
    setLanguage,
    getLanguage: determineLanguage,
    getLocale,
    LANGUAGE_KEYS,
    i18n,
  };
}
