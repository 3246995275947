import {
  REVIEW_DECISION_ACTIONS_TO_ANALYTICS_PARAMS,
  REVIEW_DECISION_ACTIONS_TO_STATES,
} from "@feedback/constants/reviewDecisionState";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import flatMap from "lodash/flatMap";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as websocket } from "@shared/services/websocket";

const reviewsCache = new Map();

function clearCache() {
  reviewsCache.clear();
}

function emitReviewsUpdatedEvent(fileId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.REVIEWS.UPDATED,
    eventData: { fileId },
  });
}

async function refetchReviews(fileId) {
  const updatedReviews = await backend.get(`/files/${fileId}/reviews`);
  reviewsCache.set(fileId, updatedReviews);
  return updatedReviews;
}

function updateReviewInCache(updatedReview) {
  if (reviewsCache.has(updatedReview.fileId)) {
    const reviews = reviewsCache.get(updatedReview.fileId);
    const existingIndex = reviews.findIndex((rev) => {
      return rev.id === updatedReview.id;
    });
    if (existingIndex !== -1) {
      reviews[existingIndex] = updatedReview;
      reviewsCache.set(updatedReview.fileId, reviews);
      eventService.emitEvent({
        eventName: EVENT_NAMES.REVIEW.UPDATED,
        eventData: { review: updatedReview },
      });
      emitReviewsUpdatedEvent(updatedReview.fileId);
    }
  }
}

function updateReviewsInCache(updatedReviews) {
  for (const updatedReview of updatedReviews) {
    updateReviewInCache(updatedReview);
  }
}

async function fetchReviews(fileId) {
  return reviewsCache.has(fileId)
    ? reviewsCache.get(fileId)
    : await refetchReviews(fileId);
}

async function fetchReviewById(reviewId) {
  const review = await backend.get(`/reviews/${reviewId}`);
  updateCache(review);
  return review;
}

function invalidateReviewCacheOfStep({ stepId }) {
  reviewsCache.forEach((reviews, fileId) => {
    if (reviews.some((review) => review.stepId === stepId)) {
      reviewsCache.delete(fileId);
      emitReviewsUpdatedEvent(fileId);
    }
  });
}

function onVersionRemoved(event) {
  reviewsCache.delete(event.eventData.fileId);
  emitReviewsUpdatedEvent(event.eventData.fileId);
}

function onStepRemoved(event) {
  const stepId = event.eventData.stepId;
  for (const [fileId, reviews] of reviewsCache) {
    const updatedReviews = reviews.filter((review) => {
      return review.stepId !== stepId;
    });
    reviewsCache.set(fileId, updatedReviews);
  }
}

async function updateStatus(review, state) {
  await fetchReviews(review.fileId);
  const newReview = await backend.put(`/reviews/${review.id}/status`, {
    state,
  });
  updateReviewInCache(newReview);
}

function onReviewAdded(event) {
  const review = event.eventData.version;
  updateCache(review);
  emitReviewsUpdatedEvent(review.fileId);
  // TODO: Refactor this function to update the cahce with all the created reviews
  // The above line only updates 1 review from newly updated version which comes
  // as activeVersion in the file, but now a user can also create 2 review while uploading a version,
  // so we need to update them as well. This will need to be refactored when we make the structure of
  // review as same at all places after removing version properties from it
  if (reviewsCache.has(review.fileId)) {
    refetchReviews(review.fileId);
  }
}

async function removeDueDateOfReview(review) {
  await fetchReviews(review.fileId);
  const updatedReview = await backend.delete(`/reviews/${review.id}/due-date`);
  updateReviewInCache(updatedReview);
}

async function updateReviewDueDate(review, dueDate) {
  await fetchReviews(review.fileId);
  const updatedReview = backend.post(`/reviews/${review.id}/due-date`, {
    dueDate,
  });
  updateReviewInCache(updatedReview);
}

async function consolidateComments(reviewId) {
  const updatedReview = await backend.post(
    `/reviews/${reviewId}/consolidated-comments`
  );
  updateReviewInCache(updatedReview);
  return updatedReview;
}

async function toggleIsResolvedConsolidateComments(
  reviewId,
  sourceCommentId,
  isResolved
) {
  const updatedReview = await backend.put(
    `/reviews/${reviewId}/consolidated-comments/${sourceCommentId}`,
    {
      isResolved,
    }
  );
  updateReviewInCache(updatedReview);
  return updatedReview;
}

function updateCache(newReview) {
  if (reviewsCache.has(newReview.fileId)) {
    const reviews = reviewsCache.get(newReview.fileId);
    const existingIndex = reviews.findIndex((rev) => {
      return rev.id === newReview.id;
    });
    if (existingIndex !== -1) {
      reviews[existingIndex] = newReview;
    } else {
      reviews.unshift(newReview);
    }

    reviewsCache.set(newReview.fileId, reviews);
  }
}

function getStepReviews(stepId, fromReview, before = 0, after = 0, sortOrder) {
  return backend.get(`/steps/${stepId}/reviews`, {
    ...(fromReview ? { fromReviewId: fromReview } : {}),
    before,
    after,
    ...(sortOrder ? { sortOrder } : {}),
    withFlags: true,
  });
}

function countStepReviews(stepId) {
  return backend.get(`/steps/${stepId}/reviews-count`);
}

async function submitReviewDecision(reviewIds, action, isDemoContent, code) {
  healthMetrics.trackStart("feedback.submit-review");

  try {
    const reviews = await backend.post(`/reviews/decisions`, {
      reviewIds,
      state: REVIEW_DECISION_ACTIONS_TO_STATES[action],
      code,
    });
    const pageView = reviewIds.length > 1 ? "grid-view" : "file-view";

    let userEngagement = {};
    if (pageView === "file-view") {
      const { subscriptionStatus } = await teamService.fetchTeamByTeamId(
        reviews[0].teamId
      );
      const { _id } = authenticationService.fetchUser();
      userEngagement = {
        subscriptionStatus,
        isUploader: reviews[0].uploadedBy === _id,
        teamId: reviews[0].teamId,
      };
    }

    analytics.track(
      analytics.ACTION.SUBMITTED,
      analytics.CATEGORY.REVIEW_DECISIONS,
      {
        page: pageView,
        reviewDecision: REVIEW_DECISION_ACTIONS_TO_ANALYTICS_PARAMS[action],
        isDemoContent,
        ...userEngagement,
      }
    );
    updateReviewsInCache(reviews);
    healthMetrics.trackSuccess("feedback.submit-review");
    eventService.emitEvent({
      eventName: EVENT_NAMES.REVIEW.DECISIONS.UPDATED,
      eventData: { reviews },
    });
  } catch (error) {
    healthMetrics.trackFailure("feedback.submit-review", error);
    return Promise.reject(error);
  }
}

async function removeReview(review) {
  await backend.delete(`/reviews/${review.id}`);
  if (reviewsCache.has(review.fileId)) {
    const reviews = reviewsCache.get(review.fileId);
    const updatedReviews = reviews.filter((rev) => rev.id !== review.id);
    reviewsCache.set(review.fileId, updatedReviews);
    emitReviewsUpdatedEvent(review.fileId);
  }
  eventService.emitEvent({
    eventName: EVENT_NAMES.REVIEW.REMOVED,
    eventData: { review },
  });
}

async function create(versionId, stepId) {
  const review = await backend.post("/reviews", { versionId, stepId });
  eventService.emitEvent({
    eventName: EVENT_NAMES.REVIEW.CREATED,
    eventData: { review },
  });
  updateCache(review);
  return review;
}

function getReviewById(reviewId) {
  const allReviews = flatMap(Array.from(reviewsCache.values()));
  return allReviews.find((review) => review.id === reviewId);
}

async function undoReviewDecisions(reviewIds) {
  const reviews = await backend.put(`/reviews/decisions`, { reviewIds });
  updateReviewsInCache(reviews);
  eventService.emitEvent({
    eventName: EVENT_NAMES.REVIEW.DECISIONS.UNDO,
    eventData: { reviewIds },
  });
  eventService.emitEvent({
    eventName: EVENT_NAMES.REVIEW.DECISIONS.UPDATED,
    eventData: { reviews },
  });
}

function onReviewRemoved({ reviewId, fileId }) {
  if (reviewsCache.has(fileId)) {
    const reviews = reviewsCache.get(fileId);
    const removedReview = reviews.find((review) => review.id === reviewId);
    const updatedReviews = reviews.filter((review) => review.id !== reviewId);
    reviewsCache.set(fileId, updatedReviews);
    eventService.emitEvent({
      eventName: EVENT_NAMES.REVIEW.REMOVED,
      eventData: { review: removedReview },
    });
  }
}

function initialize() {
  eventService.addListener(EVENT_NAMES.STEP.REMOVED, onStepRemoved);
  eventService.addListener(EVENT_NAMES.VERSION.REMOVED, onVersionRemoved);
  eventService.addListener(EVENT_NAMES.VERSION.ADDED, onReviewAdded);
  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);

  websocket.addListener(
    websocket.EVENT_NAMES.REVIEW.UPDATED,
    async ({ fileId, reviewId }) => {
      if (reviewsCache.has(fileId)) {
        const review = await backend.get(`/reviews/${reviewId}`);
        updateReviewInCache(review);
      }
    }
  );

  websocket.addListener(websocket.EVENT_NAMES.REVIEW.STARTED, ({ fileId }) => {
    if (reviewsCache.has(fileId)) {
      refetchReviews(fileId);
      emitReviewsUpdatedEvent(fileId);
    }
  });

  websocket.addListener(websocket.EVENT_NAMES.STEP.REVIEWER.ADDED, (step) => {
    setTimeout(() => {
      invalidateReviewCacheOfStep(step);
    }, 10000);
  });

  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.REMOVED,
    invalidateReviewCacheOfStep
  );

  websocket.addListener(websocket.EVENT_NAMES.REVIEW.REMOVED, onReviewRemoved);

  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.DECISION_REQUESTED,
    invalidateReviewCacheOfStep
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.SETTINGS.UPDATED,
    invalidateReviewCacheOfStep
  );
}

export default {
  initialize,
  clearCache,
  fetchReviews,
  fetchReviewById,
  refetchReviews,
  updateStatus,
  removeDueDateOfReview,
  updateReviewDueDate,
  getStepReviews,
  countStepReviews,
  submitReviewDecision,
  removeReview,
  create,
  getReviewById,
  undoReviewDecisions,
  consolidateComments,
  toggleIsResolvedConsolidateComments,
};
