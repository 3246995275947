import { queryClient } from "@shared/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import { Button, Card, CardContent } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { instance as logger } from "@shared/services/logger";
import pageTitleService from "@shared/services/pageTitleService";

function ConnectionInterrupted() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    pageTitleService.setTitle(t("CONNECTION_INTERRUPTED.PAGE-TITLE"));
    logger.warn("connection-interrupted", "Connection interrupted");
  }, [t]);

  return (
    <Box
      minHeight="100vh"
      justifyContent="center"
      alignItems="center"
      display="flex"
      data-testid="connection-interrupted"
    >
      <Card>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CloudOffIcon color="disabled" fontSize="extraLarge" />

          <Text
            textAlign="center"
            variant="h6"
            fontWeight="fontWeight.bold"
            component="h1"
            translate="CONNECTION_INTERRUPTED.HEADLINE"
          />
          <Text
            variant="body2"
            translate="CONNECTION_INTERRUPTED.DESCRIPTION"
          />
          <Button
            onClick={() => {
              queryClient.clear();
              navigate(searchParams.get("url") ?? APP_ROUTES.DASHBOARD.path, {
                replace: true,
              });
            }}
            color="primary"
            variant="contained"
          >
            {t("CONNECTION_INTERRUPTED.RELOAD_THIS_PAGE")}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ConnectionInterrupted;
