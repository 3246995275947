import {
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Link, Card, CardContent, TextField } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as billingService } from "@supporting/services/billingService";
import toastService from "@supporting/services/toast";
import noop from "lodash/lodash";

import { Box, LoadingSpinner, Text } from "@shared/UIKit";

import { useDebounce } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import CardField from "../CreditCardFields/CardField";
import CountryField from "../CreditCardFields/CountryField";
import CreditCardLogos from "../CreditCardLogos/CrediCardLogos";
import { updateTaxCountryCode, useTaxPaymentInfo } from "./useTaxPaymentInfo";

const validateForm = (state) => {
  /* istanbul ignore next */
  if (state.cardNumber.error || state.cardExpiry.error || state.cardCvc.error) {
    return false;
  }
  if (state.cardName.length === 0) {
    return false;
  }
  return true;
};

const validateTaxId = async (isoCode, taxId) => {
  try {
    const isValidTaxId = await billingService.validateTaxId(isoCode, taxId);
    if (!isValidTaxId) {
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.SUBSCRIPTION_INVALID_TAX_ID
      );
    }

    return isValidTaxId;
  } catch {
    // We don't want to prevent the user from submitting just in case
    // there is an error from quaderno which can happen at times
  }
  return true;
};

const CreditCardForm = ({
  defaultValues,
  fullForm,
  submitButtonLabel,
  onSubmitStart,
  onSubmitSuccess,
  onSubmitError,
}) => {
  const { t } = useTranslation();
  const team = useSelectedTeam();
  const stripe = useStripe();
  const elements = useElements();
  const taxPaymentInfo = useTaxPaymentInfo();

  const [state, setState] = useState(() => ({
    cardName: defaultValues.cardName || "",
    cardNumber: {
      error: null,
      empty: true,
      complete: false,
    },
    cardExpiry: {
      error: null,
      empty: true,
      complete: false,
    },
    cardCvc: {
      error: null,
      empty: true,
      complete: false,
    },
    country: "",
    taxId: "",
    isTaxIdValid: true,
    submitError: null,
    isSubmitting: false,
  }));

  /* istanbul ignore next */
  const handleStripeInputChange = (fieldName) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        empty: event.empty,
        complete: event.complete,
        error: event.error,
      },
    }));
  };

  const handleCountryChange = (countryCode) => {
    setState((prevState) => ({
      ...prevState,
      country: countryCode,
    }));
    updateTaxCountryCode(countryCode);
  };

  const taxValidation = useCallback(async (country, taxId) => {
    const isTaxIdValid = await validateTaxId(country, taxId);
    setState((prevState) => ({
      ...prevState,
      isTaxIdValid,
    }));
  }, []);

  const debouncedTaxValidation = useDebounce({
    callback: taxValidation,
    delay: 1000,
  });
  const handleTaxIdChange = (event) => {
    setState((prevState) => ({
      ...prevState,
      taxId: event.target.value,
    }));
    debouncedTaxValidation(state.country, event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    /* istanbul ignore next */
    if (!stripe || !elements) {
      return;
    }
    setState((prevState) => ({
      ...prevState,
      isSubmitting: true,
      submitError: null,
    }));
    onSubmitStart();
    try {
      const setupIntent = await billingService.createSetupIntent();
      const stripeResult = await stripe.confirmCardSetup(
        setupIntent.client_secret,
        {
          payment_method: {
            card: elements.getElement("cardNumber"),
            billing_details: { name: state.cardName },
          },
        }
      );
      if (stripeResult.error) {
        setState((prevState) => ({
          ...prevState,
          isSubmitting: false,
          submitError: stripeResult.error.message,
        }));
        onSubmitError();
      } else {
        const teamInfo = await billingService.addPaymentMethod(
          team._id,
          stripeResult.setupIntent.payment_method
        );
        toastService.sendToast({
          title: "BILLING.CHANGE_PAYMENT_DETAILS.SUCCESS.TITLE",
          body: "BILLING.CHANGE_PAYMENT_DETAILS.SUCCESS.BODY",
          preset: toastService.PRESETS().SUCCESS,
          translationVariables: {
            body: {
              teamName: team.name,
            },
          },
        });
        await onSubmitSuccess({
          teamInfo,
          plan: taxPaymentInfo.plan,
          payment: state,
        });
        setState((prevState) => ({
          ...prevState,
          isSubmitting: false,
        }));
      }
    } catch (error) {
      setState((prevState) => ({
        ...prevState,
        isSubmitting: false,
      }));
      if (error.message) {
        setState((prevState) => ({
          ...prevState,
          submitError: error.message,
        }));
      } else {
        errorHandlerService.handleError(error);
      }
      onSubmitError(error);
    }
  };

  /* istanbul ignore next */
  if (!elements) {
    return null;
  }

  return (
    <Card
      sx={{
        maxWidth: 340,
        "&:hover": {
          boxShadow: 3,
        },
      }}
    >
      <CardContent data-testid="credit-card-view">
        <CreditCardLogos />
        <Box
          component="form"
          onSubmit={handleSubmit}
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <TextField
            id="cc-name"
            label={t("BILLING.CHECKOUT.CARD_NAME")}
            name="ccname"
            autoComplete="cc-name"
            fullWidth
            value={state.cardName}
            inputProps={{ "data-testid": "add-cc-name-dialog-input" }}
            onChange={(event) => {
              setState((prevState) => ({
                ...prevState,
                cardName: event.target.value,
              }));
            }}
          />
          <Box display="flex" flexDirection="column">
            <CardField
              id="cardNumber"
              name="cardNumber"
              label={t("BILLING.CHECKOUT.CARD_NUMBER")}
              component={CardNumberElement}
              onChange={handleStripeInputChange("cardNumber")}
              fieldState={state.cardNumber}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <CardField
              name="cardExpiry"
              component={CardExpiryElement}
              label={t("BILLING.CHECKOUT.CARD_EXPIRATION_DATE")}
              onChange={handleStripeInputChange("cardExpiry")}
              fieldState={state.cardExpiry}
              className="cardExpiry"
            />
            <CardField
              name="cardCvc"
              component={CardCvcElement}
              label={t("BILLING.CHECKOUT.CARD_CVC")}
              onChange={handleStripeInputChange("cardCvc")}
              fieldState={state.cardCvc}
              className="cardCvc"
            />
          </Box>
          {fullForm && (
            <CountryField
              name="country"
              label={t("BILLING.CHECKOUT.COUNTRY")}
              onChange={handleCountryChange}
              fieldState={state.cardCvc}
            />
          )}
          {taxPaymentInfo.isCalculatingTaxes && <LoadingSpinner />}
          {Boolean(taxPaymentInfo.taxInfo?.taxRate) && (
            <Box data-testid="vat-input-container">
              <TextField
                fullWidth
                id="taxId"
                label={t("BILLING.CHECKOUT.TAX_NUMBER")}
                name="taxId"
                data-testid="tax-id-input"
                inputProps={{ "data-testid": "taxId" }}
                value={state.taxId}
                onChange={handleTaxIdChange}
                error={Boolean(!state.isTaxIdValid)}
                helperText={
                  !state.isTaxIdValid
                    ? t("BILLING.CHECKOUT.TAX_ID_INVALID")
                    : " "
                }
              />
              <Text variant="textSm" textAlign="left">
                {t("BILLING.CHECKOUT.TAX_INFO", {
                  taxName: t(taxPaymentInfo.taxInfo.taxName),
                  taxRate: taxPaymentInfo.taxInfo.taxRate,
                })}
              </Text>
            </Box>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            loading={state.isSubmitting}
            disabled={!validateForm(state)}
            sx={{ alignSelf: "center" }}
            data-testid="subscription-upgrade"
          >
            {submitButtonLabel}
          </Button>
          {state.submitError && (
            <Box>
              <Text variant="textSm" color="error.main">
                {state.submitError}
              </Text>
              <Link
                href="mailto:hello@filestage.io"
                className="launch-intercom-chat"
              >
                {t("BILLING.CHECKOUT.CONTACT_SUPPORT")}
              </Link>
            </Box>
          )}
          <Text
            variant="textSm"
            color="text.secondary"
            translate="BILLING.CHECKOUT.SCA_MANDATE"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

CreditCardForm.propTypes = {
  defaultValues: PropTypes.shape({
    cardName: PropTypes.string,
  }),
  fullForm: PropTypes.bool,
  submitButtonLabel: PropTypes.string.isRequired,
  onSubmitStart: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onSubmitError: PropTypes.func,
};

CreditCardForm.defaultProps = {
  defaultValues: {
    cardName: "",
  },
  fullForm: false,
  onSubmitStart: noop,
  onSubmitSuccess: noop,
  onSubmitError: noop,
};

export default CreditCardForm;
