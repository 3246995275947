import PropTypes from "prop-types";
import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import EmojiHighlightAndLinkify from "@feedback/components/EmojiHighlightAndLinkify/EmojiHighlightAndLinkify";
import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Text, Box } from "@shared/UIKit";

import * as parseMentions from "@shared/helpers/parseMentions.js";
import { instance as analytics } from "@shared/services/analytics";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles(() => ({
  Body: {
    marginBottom: 4,
    "& a": {
      wordBreak: "break-all",
    },
  },
}));

function ActivityBody({ mentioned, body }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const updatedBody = parseMentions.decode(body, mentioned);

  const [viewMore, setViewMore] = useState(false);
  const [strippedBody, setStrippedBody] = useState(updatedBody);

  useEffect(() => {
    if (updatedBody.length > 90) {
      setViewMore(true);
      setStrippedBody(`${updatedBody.slice(0, 90 - updatedBody.length)}...`);
    }
  }, [body, updatedBody]);

  const unStripBody = useCallback(
    (event) => {
      event.stopPropagation();
      setViewMore(false);
      setStrippedBody(updatedBody);
      analytics.track(
        analytics.ACTION.SHOWED_FULL_COMMENT,
        analytics.CATEGORY.NOTIFICATION_CENTER_NOTIFICATION
      );
    },
    [updatedBody] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box display="block" lineHeight={1}>
      <Text
        component="span"
        variant="subtitle1"
        color={theme.color.gray[600]}
        className={classes.Body}
      >
        <EmojiHighlightAndLinkify>{strippedBody}</EmojiHighlightAndLinkify>
      </Text>
      {viewMore && (
        <Link
          onClick={unStripBody}
          fontStyle="italic"
          fontSize={FSTGTypography.fontSize_1_2}
          data-testid="view-more"
        >
          {t("CORE.VIEW_MORE")}
        </Link>
      )}
    </Box>
  );
}

ActivityBody.propTypes = {
  mentioned: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      email: PropTypes.string,
      displayName: PropTypes.string.isRequired,
    })
  ),
  body: PropTypes.string.isRequired,
};

ActivityBody.defaultProps = {
  mentioned: [],
};

export default ActivityBody;
