import isNil from "lodash/isNil";
import isPlainObject from "lodash/isPlainObject";
import omitBy from "lodash/omitBy";

import fstgId from "@shared/helpers/fstgId";

const interceptors = [];

export class BackendError extends Error {
  constructor(status, body) {
    super(body.message);
    this.name = body.name;
    this.status = status;
  }
}

function buildURL(method, route, params) {
  let url = `${window.fs.apiEndpoint}${route}`;
  if (isPlainObject(params) && (method === "GET" || method === "DELETE")) {
    url += `?${new URLSearchParams(omitBy(params, isNil))}`;
  }
  return url;
}

function addBody(options, method, params) {
  if (params !== null && method !== "GET" && method !== "DELETE") {
    options.headers["Content-Type"] = "application/json";
    options.body = JSON.stringify(params);
  }
}

async function request(method, route, params, requestOptions) {
  const url = buildURL(method, route, params);
  const options = {
    credentials: "include",
    method,
    headers: {
      "X-Request-ID": fstgId.generate(),
    },
    ...requestOptions,
  };
  addBody(options, method, params);

  for (const interceptor of interceptors) {
    await interceptor.request?.(method, route, params, options.headers);
  }

  try {
    const response = await fetch(url, options);

    for (const interceptor of interceptors) {
      await interceptor.response?.(response);
    }

    const responseText = await response.text();
    let responseJson;
    if (responseText.length > 0) {
      responseJson = JSON.parse(responseText);
    }
    if (response.ok) {
      return responseJson;
    }
    throw new BackendError(response.status, responseJson);
  } catch (error) {
    for (const interceptor of interceptors) {
      await interceptor.error?.(error);
    }
    throw error;
  }
}

function get(route, params, options) {
  return request("GET", route, params, options);
}

function post(route, params) {
  return request("POST", route, params);
}

function put(route, params) {
  return request("PUT", route, params);
}

function del(route, params) {
  return request("DELETE", route, params);
}

// 'delete' is a reserved keyword but adding it as an alias
// because $http named it delete

export default {
  delete: del,
  put,
  del,
  post,
  get,
  interceptors,
};
