import { Box, Image } from "@shared/UIKit";

import CCamex from "@assets/img/sprite-source/sprite--cc-amex.png";
import CCamex2x from "@assets/img/sprite-source/sprite--cc-amex@2x.png";
import CCmastercard from "@assets/img/sprite-source/sprite--cc-mastercard.png";
import CCmastercard2x from "@assets/img/sprite-source/sprite--cc-mastercard@2x.png";
import CCVisa from "@assets/img/sprite-source/sprite--cc-visa.png";
import CCVisa2x from "@assets/img/sprite-source/sprite--cc-visa@2x.png";

const CARD_BRANDS = [
  [CCVisa, CCVisa2x],
  [CCamex, CCamex2x],
  [CCmastercard, CCmastercard2x],
];

const CreditCardLogos = () => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    justifyContent="space-between"
    mb={2}
  >
    {CARD_BRANDS.map((sprite, index) => (
      <Image
        alt="logo"
        key={index}
        src={sprite[0]}
        srcSet={`${sprite[1]} 2x`}
      />
    ))}
  </Box>
);

export default CreditCardLogos;
