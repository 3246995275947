import { queryClient } from "@shared/react-query";

import authenticationService from "@supporting/services/authentication";
import merge from "lodash/merge";

import { SEARCH_KEYS } from "@shared/query-keys";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

const update = async (userToBeUpdated, userPassword) => {
  const user = authenticationService.fetchUser();
  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.UPDATED,
    eventData: { user: merge({}, { ...user }, userToBeUpdated) },
  });
  try {
    const updatedUser = await backend.put(`/users/me`, {
      userToBeUpdated,
      userPassword,
    });
    eventService.emitEvent({
      eventName: EVENT_NAMES.USER.UPDATED,
      eventData: { user: updatedUser },
    });
    return updatedUser;
  } catch (error) {
    eventService.emitEvent({
      eventName: EVENT_NAMES.USER.UPDATED,
      eventData: { user },
    });
    throw error;
  }
};

const updatePassword = (password, currentPassword) => {
  return backend.put(`/users/me/password`, {
    newPassword: password,
    currentPassword,
  });
};

const getInitials = (user) => {
  if (user.fullName) {
    const splitStrings = user.fullName.split(" ");
    const initials = splitStrings[1]
      ? splitStrings[0].substring(0, 1) + splitStrings[1].substring(0, 1)
      : splitStrings[0].substring(0, 1);
    return initials.toUpperCase();
  }

  if (user.email) {
    return user.email.charAt(0).toUpperCase();
  }

  return "";
};

const getAvatarUrl = (user) => {
  return user.avatars && user.avatars[0] ? user.avatars[0].url : null;
};

const repairPermissions = (email) => {
  return backend.post(`/admin/repair-permissions`, {
    email,
  });
};
const disconnectGoogle = (email) => {
  return backend.post(`/admin/disconnect-google`, {
    email,
  });
};

const mergeAccounts = (sourceEmail, targetEmail) => {
  return backend.post(`/admin/merge-accounts`, {
    sourceEmail,
    targetEmail,
  });
};

const collapseAllFolders = (collapsed, teamId, user) => {
  const newUser = {
    ...user,
    settings: { ...user.settings, collapsedFavoriteProjects: collapsed },
  };

  eventService.emitEvent({
    eventName: EVENT_NAMES.USER.UPDATED,
    eventData: { user: newUser },
  });

  eventService.emitEvent({
    eventName: EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
    eventData: {
      collapsed,
      teamId,
    },
  });

  return backend.post("/users/me/collapsed-folders", {
    collapsed,
    teamId,
  });
};

const updateRecentSearches = (searchItem) => {
  const data = queryClient.getQueryData(SEARCH_KEYS.recent());
  if (
    data?.some((item) => item.resource.resourceId === searchItem.resourceId)
  ) {
    return;
  }
  queryClient.invalidateQueries({
    queryKey: SEARCH_KEYS.recent(),
  });

  return backend.put("/recent-searches", {
    resourceId: searchItem.resourceId,
    resourceType: searchItem.resourceType,
  });
};

const fetchRecentSearches = () => {
  return backend.get("/recent-searches");
};

export default {
  getAvatarUrl,
  getInitials,
  update,
  updatePassword,
  repairPermissions,
  disconnectGoogle,
  mergeAccounts,
  collapseAllFolders,
  updateRecentSearches,
  fetchRecentSearches,
};
