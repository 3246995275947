import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Grid, Container } from "@mui/material";
import PlanCard from "@supporting/components/SelectPlanDialog/PlanDialog/PlanCard";
import { instance as billingService } from "@supporting/services/billingService";
import { instance as teamService } from "@supporting/services/team";

import { Dialog, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import { billingPlans } from "./selectplan.helpers";

const RestartTrialPlanDialog = ({ teamId, cancel }) => {
  const modal = useModal();
  const { t } = useTranslation();
  const [team, setTeam] = useState(null);
  const [billingPlanList, setBillingPlanList] = useState([]);

  useEffect(() => {
    async function fetchDefaultPlans() {
      const plans = await billingService.fetchDefaultPlans(teamId);
      setBillingPlanList(plans);
    }
    fetchDefaultPlans();

    async function fetchTeam() {
      const team = await teamService.fetchTeamByTeamId(teamId);
      setTeam(team);
    }
    fetchTeam();
  }, [teamId]);

  if (!team) {
    return null;
  }

  const currency = team.subscription.basePrice.currency;
  const currentBillingRate = team.subscription.basePrice.interval;
  const canUpgradePlan = team.permissions.canManageBilling;
  const currentPlan = team.subscription.basePrice;
  /* istanbul ignore else */
  if (billingPlanList.length) {
    Object.keys(billingPlans).forEach((planName) => {
      billingPlans[planName].data = billingPlanList.find(
        (plan) =>
          plan.name.toLowerCase().includes(planName) &&
          plan.currency === currency &&
          plan.interval === currentBillingRate
      );
    });
  }
  const closeDialog = () => {
    modal.remove();
    /* istanbul ignore else */
    if (cancel) {
      cancel();
    }
  };
  const handleRestartClick = async () => {
    closeDialog();
    await billingService.handleRestartTrial();
  };
  const planColor = {
    free: {
      borderColor: "grey.200",
      backgroundColor: "grey.50",
    },
    professional: {
      borderColor: "accent.main",
      backgroundColor: "white",
    },
  };

  return (
    Boolean(billingPlanList.length) && (
      <Dialog
        cancel={closeDialog}
        open={modal.visible}
        title={t("RESTART_TRIAL.MODAL_HEADER")}
        actions={
          <>
            <Button
              size="large"
              variant="text"
              onClick={closeDialog}
              color="primaryV2"
            >
              {t("CORE.CLOSE")}
            </Button>
            {canUpgradePlan && (
              <Button
                size="large"
                color="primary"
                variant="contained"
                onClick={handleRestartClick}
              >
                {t("RESTART_TRIAL.PRIMARY_CTA")}
              </Button>
            )}
          </>
        }
        fullWidth
        hideBackdrop={false}
      >
        <Container sx={{ px: { xs: 0 }, pb: 1 }}>
          <Text
            color="grey.500"
            fontSize={16}
            lineHeight={FSTGTypography.lineHeight_1_3}
          >
            {t("RESTART_TRIAL.MODAL_BODY")}
          </Text>
          <Grid
            container
            spacing={1}
            marginTop={2}
            justifyContent="center"
            alignItems="stretch"
          >
            {Object.keys(billingPlans).map((plan) => {
              return (
                <Grid item md={6} key={plan}>
                  <Text color="grey.500" fontSize={14}>
                    {plan === "free"
                      ? t("RESTART_TRIAL.MODAL.FREE_TITLE")
                      : t("RESTART_TRIAL.MODAL.PROFESSIONAL_TITLE")}
                  </Text>
                  <PlanCard
                    plan={plan}
                    currentPlan={currentPlan}
                    currentPeriodEnd={team.subscription.currentPeriodEnd}
                    isSubscriptionOnTrail={false}
                    canUpgradePlan={canUpgradePlan}
                    billingPlans={billingPlans}
                    color={planColor[plan]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Dialog>
    )
  );
};

RestartTrialPlanDialog.propTypes = {
  teamId: PropTypes.string.isRequired,
  cancel: PropTypes.func,
};

RestartTrialPlanDialog.defaultProps = {
  cancel: null,
};

export default NiceModal.create(RestartTrialPlanDialog);
