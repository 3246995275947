const TRIAL_DAYS_LEFT = 4;
const LIMIT = 1;
const ByteToGB = 1000 * 1000 * 1000;
const PLAN_NAMES = ["free", "basic", "professional"];
const ADAPTED_PLAN_NAMES = {
  professional: "Professional",
  enterprise: "Enterprise",
  enterpriseLegacy: "Enterprise-legacy",
  freeReviewer: "Free-Reviewer",
};

import { differenceInHours } from "date-fns";

import coreBytes from "@shared/helpers/coreBytes";

export const translateKeyTitle = (subscriptionName, isOnTrial) => {
  if (PLAN_NAMES.includes(subscriptionName.toLowerCase())) {
    return isOnTrial
      ? "TRIAL.STAT.TITLE"
      : `${subscriptionName.toUpperCase()}.STAT.TITLE`;
  }

  if (
    subscriptionName.toLowerCase() ===
    ADAPTED_PLAN_NAMES.enterpriseLegacy.toLowerCase()
  ) {
    return "ENTERPRISE.STAT.TITLE";
  }

  if (
    subscriptionName
      .toLowerCase()
      .includes(ADAPTED_PLAN_NAMES.freeReviewer.toLowerCase())
  ) {
    return "TEAM.ROLES.FREE_REVIEWER.TITLE";
  }

  return subscriptionName;
};

export const isTrialAboutToEnd = (trialEndDate, days = TRIAL_DAYS_LEFT) => {
  return Boolean(
    trialEndDate &&
      Math.round(differenceInHours(new Date(trialEndDate), new Date()) / 24) <
        days
  );
};

const activeProject = (
  { numberOfActiveProjects: currentLimit, maxProjectNumber: maxLimit },
  limit = LIMIT
) => {
  if (currentLimit / maxLimit >= limit) {
    return {
      translationKey: "BASIC.PROJECT_STAT.BODY",
      currentLimit,
      maxLimit,
    };
  }
};

const reviewSteps = (
  { numberOfProjectStep: currentLimit, maxReviewStepsNumber: maxLimit },
  limit = LIMIT
) => {
  if (currentLimit / maxLimit >= limit) {
    return {
      translationKey: "BASIC.GROUPS_STAT.BODY",
      currentLimit,
      maxLimit,
    };
  }
};

const storage = (
  { usedStorageSizeByte: currentLimit, maxStorageSizeBytes: maxLimit },
  /* istanbul ignore next */
  limit = LIMIT
) => {
  if (currentLimit / maxLimit >= limit) {
    return {
      translationKey: "BASIC.STORAGE_STAT.BODY",
      currentLimit: (currentLimit / ByteToGB).toFixed(1),
      maxLimit: coreBytes(maxLimit, 1),
    };
  }
};

const automations = (
  { projectAutomation: currentLimit, maxProjectAutomationNumber: maxLimit },
  limit = LIMIT
) => {
  if (currentLimit / maxLimit >= limit) {
    return {
      translationKey: "ENTERPRISE.AUTOMATION_STAT.BODY",
      currentLimit,
      maxLimit,
    };
  }
};

const projectTeamplates = (
  {
    numberOfProjectTemplates: currentLimit,
    maxProjectTemplateNumber: maxLimit,
  },
  limit = LIMIT
) => {
  if (currentLimit / maxLimit >= limit) {
    return {
      translationKey: "ENTERPRISE.TEMPLATE_STAT.BODY",
      currentLimit,
      maxLimit,
    };
  }
};

const seats = (
  { members: currentLimit, includedUsers: maxLimit },
  limit = LIMIT
) => {
  if (currentLimit / maxLimit >= limit) {
    return {
      translationKey: "ENTERPRISE.SEATS_STAT.BODY",
      currentLimit,
      maxLimit,
    };
  }
};

/**
 * @typedef LimitObject
 * @type {object}
 * @property {string} translationKey
 * @property {number} currentLimit
 * @property {number} maxLimit
 */

/**
 * This object holds all the LimitFunctions for each plan. If you need to add a new limit
 * for a plan you can add the rule to the respective plan name field. If you need to
 * change the limit for a rule you can change the second prop of the limit function.
 * All the rules must return a LimitObject if it is approaching the limit or undefined.
 *
 * @example
 * add new rule to basic:
 * basic: [activeProject, reviewSteps, storage, newRule],
 *
 * @example
 * change the storage limit for basic:
 * {...
 * basic: [activeProject, reviewSteps, storage],
 * }
 * --->
 * {...
 * basic: [activeProject, reviewSteps, (arg) => storage(arg, "new limit")],
 * }
 */
const limits = {
  free: [activeProject, reviewSteps, (arg) => storage(arg, 0.8)],
  basic: [
    (arg) => activeProject(arg, 0.8),
    reviewSteps,
    (arg) => storage(arg, 0.8),
  ],
  professional: [
    (arg) => activeProject(arg, 0.8),
    reviewSteps,
    (arg) => storage(arg, 0.8),
    automations,
    projectTeamplates,
  ],
  [ADAPTED_PLAN_NAMES.enterpriseLegacy.toLowerCase()]: [
    seats,
    automations,
    projectTeamplates,
  ],
};

/**
 * This function returns an array of limit-objects that approach the respective limit.
 *
 * @param {string} planName The plan name associated with an array of limits
 * @param {Object} subscriptionLimits The subscription's limits
 * @param {Object} usedBillingLimits The team's used limits, extended by project's numberOfProjectStep, projectAutomation and team's members
 * @return {Array.<LimitObject|undefined>}
 */
export const getLimitObject = (
  planName,
  subscriptionLimits,
  usedBillingLimits
) => {
  return (limits[planName] || []).map((limit) =>
    limit({ ...usedBillingLimits, ...subscriptionLimits })
  );
};

export const adaptPlanName = (planName, planDescription) => {
  if (
    planName.toLowerCase().includes("enterprise") &&
    !planDescription.includes("v8")
  ) {
    return ADAPTED_PLAN_NAMES.enterpriseLegacy;
  }
  return planName;
};

export const hidePlanLimitCard = ({
  adaptedPlanName,
  approachingLimit,
  isOnTrial,
}) =>
  (adaptedPlanName === ADAPTED_PLAN_NAMES.professional ||
    adaptedPlanName === ADAPTED_PLAN_NAMES.enterprise ||
    adaptedPlanName === ADAPTED_PLAN_NAMES.enterpriseLegacy) &&
  !approachingLimit &&
  !isOnTrial;

export const shouldRequestDemo = (isOnTrialOrFreePlan, businessRegion) =>
  isOnTrialOrFreePlan && businessRegion !== "other";
