import { useEffect, useState } from "react";

import authenticationService from "@supporting/services/authentication";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

const getUserOrNull = () => {
  const user = authenticationService.fetchUser();
  if (!user) {
    return null;
  }
  return {
    ...user,
    session: authenticationService.fetchSession(),
  };
};

export default function useActiveUser(
  selector = (user) => user,
  comparator = (prev, next) => prev === next
) {
  const [user, setUser] = useState(selector(getUserOrNull()));

  useEffect(() => {
    const updateUser = ({ eventData }) => {
      setUser((previousSelection) => {
        const newSelection = selector({
          ...eventData.user,
          session: authenticationService.fetchSession(),
        });
        if (comparator(previousSelection, newSelection)) {
          return previousSelection;
        }
        return newSelection;
      });
    };
    const clearUser = () => setUser(selector(null));
    eventService.addListener(EVENT_NAMES.USER.UPDATED, updateUser);
    eventService.addListener(EVENT_NAMES.USER.AUTHENTICATED, updateUser);
    eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearUser);

    return () => {
      eventService.removeListener(EVENT_NAMES.USER.UPDATED, updateUser);
      eventService.removeListener(EVENT_NAMES.USER.AUTHENTICATED, updateUser);
      eventService.removeListener(EVENT_NAMES.USER.LOGGED_OUT, clearUser);
    };
  }, [comparator, selector]);

  return user;
}
