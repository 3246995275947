import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import { TEAM_ROLES } from "@supporting/constants/team";
import teamRoleService from "@supporting/services/teamRoleService";
import classNames from "classnames";
import orderBy from "lodash/orderBy";

import { Text, InfoToolTip, Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import FSTGTypography from "@shared/theme/typography";

import RoleTooltip from "./RoleTooltip";
const useStyles = makeStyles((theme) => ({
  teamRole: {
    padding: "10px 10px",
    borderBottom: `1px solid ${theme.color["white-smoke-dark-transparent"]}`,
    cursor: "pointer",

    "@media (min-width: 960px)": {
      minHeight: "72px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },

    "&:hover": {
      backgroundColor: theme.color.gray[50],
    },

    "&:last-child": {
      borderBottom: "none",
    },

    "&:active,&:focus": {
      outline: "none",
    },
  },
  teamRoleName: {
    minWidth: "160px",
    textAlign: "left",
  },
  teamRoleDescription: {
    paddingRight: "10px",
    fontSize: FSTGTypography.fontSize_1_4,
    lineHeight: FSTGTypography.lineHeight_1_2,
    color: theme.color.gray[600],
    textAlign: "left",

    "@media (min-width: 960px)": {
      paddingLeft: "10px",
    },
  },
  disabledRole: {
    backgroundColor: "rgba(151, 151, 151, 0.1)",
  },
  disabledRoleName: {
    color: theme.color.gray[600],
  },
  disabledRoleDescription: {
    color: theme.color.gray[500],
  },
  disabledRoleUpgradeMessage: {
    color: theme.color.blue[500],
    lineHeight: FSTGTypography.fontSize_1_7,
    fontWeight: FSTGTypography.fontWeightBold,
    marginTop: "6px",
  },
}));

function TeamRolePicker({ team, onTeamRoleSelected, defaultSelected }) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const [selectedTeamRoleId, setSelectedTeamRoleId] = useState(defaultSelected);
  const [teamRoles, setTeamRoles] = useState();

  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { teamId: team._id });

  useEffect(() => {
    async function fetchRoles() {
      const teamRoles = await teamRoleService.fetchTeamRoles(team._id);
      setTeamRoles(teamRoles);
      if (!selectedTeamRoleId) {
        const preSelectedTeamRole = teamRoles.find(
          ({ roleName }) => roleName === TEAM_ROLES.FULL_MEMBER
        );

        setSelectedTeamRoleId(preSelectedTeamRole._id);
        onTeamRoleSelected(preSelectedTeamRole._id);
      }
    }

    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTeamRoleSelected, selectedTeamRoleId, team._id]);

  const isRoleDisabled = function (role) {
    return (
      role.roleName === TEAM_ROLES.LITE_MEMBER &&
      !team.subscriptionLimits.isMemberRoleEnabled
    );
  };

  const selectTeamRole = function (roleId) {
    setSelectedTeamRoleId(roleId);
    onTeamRoleSelected(roleId);
  };

  return (
    <FormControl
      variant="standard"
      component="fieldset"
      data-testid="team-role-picker"
    >
      <RadioGroup aria-label="role" name="role" value={selectedTeamRoleId}>
        {orderBy(teamRoles, ["isDefault", "roleName"], ["desc", "asc"]).map(
          (role) => {
            return (
              <Box
                alignItems="center"
                display="flex"
                key={role._id}
                onClick={() => {
                  if (!isRoleDisabled(role)) {
                    selectTeamRole(role._id);
                  }
                }}
                className={classNames(classes.teamRole, {
                  [classes.disabledRole]: isRoleDisabled(role),
                })}
                data-testid={`role-${role.roleName}`}
              >
                <Box
                  className={classNames(classes.teamRoleName, {
                    [classes.disabledRoleName]: isRoleDisabled(role),
                  })}
                >
                  <FormControlLabel
                    value={role._id}
                    control={<Radio color="primary" />}
                    label={
                      <Text
                        variant="body1"
                        fontWeight={theme.typography.fontWeight.bold}
                      >
                        {role.isDefault
                          ? t(`TEAM.ROLES.${role.roleName}`)
                          : role.roleName}
                      </Text>
                    }
                    disabled={isRoleDisabled(role)}
                    data-testid={`role-${role.roleName}-radio`}
                  />
                </Box>
                <Box
                  flex="auto"
                  className={classNames(classes.teamRoleDescription, {
                    [classes.disabledRoleDescription]: isRoleDisabled(role),
                  })}
                >
                  <Box>
                    <Text>
                      {role.isDefault
                        ? t(`TEAM.ROLES.ROLE_DESCRIPTION.${role.roleName}`)
                        : ""}
                    </Text>
                    {isRoleDisabled(role) && (
                      <Box className={classes.disabledRoleUpgradeMessage}>
                        {team.permissions.canManageBilling && (
                          <>
                            <Text> {t("TEAM.ROLES.UPGRADE_MESSAGE")} </Text>
                            <Text
                              aria-hidden="true"
                              onClick={openDialog}
                              data-testid="open-dialog-select-plan"
                              className={classes.disabledRoleUpgradeMessage}
                            >
                              {t("TEAM.ROLES.VIEW_PLANS")}
                            </Text>
                          </>
                        )}
                        {!team.permissions.canManageBilling && (
                          <Text data-testid="restricted-warning">
                            {t("TEAM.ROLES.USER_RESTRICTED")}
                          </Text>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box>
                  <InfoToolTip
                    placement="right"
                    title={<RoleTooltip role={role} />}
                  >
                    <IconButton size="small" disableRipple>
                      <InfoOutlined fontSize="small" />
                    </IconButton>
                  </InfoToolTip>
                </Box>
              </Box>
            );
          }
        )}
      </RadioGroup>
    </FormControl>
  );
}

TeamRolePicker.propTypes = {
  team: teamProp.isRequired,
  onTeamRoleSelected: PropTypes.func.isRequired,
  defaultSelected: PropTypes.string,
};
TeamRolePicker.defaultProps = {
  defaultSelected: "",
};

export default TeamRolePicker;
