import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card, CardHeader, CardContent } from "@mui/material";
import { useTheme } from "@mui/styles";
import PasswordField from "@supporting/components/PasswordField/PasswordField";
import PasswordFieldWithTooltip from "@supporting/components/PasswordFieldWithTooltip/PasswordFieldWithTooltip";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import userService from "@supporting/services/userService";
import capitalize from "lodash/capitalize";

import { Box, Text } from "@shared/UIKit";

import { INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX } from "@shared/constants/validation";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import FSTGTypography from "@shared/theme/typography";

const MINIMUM_PASSWORD_LENGTH = 8;
const EditPassword = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [validation, setValidation] = useState({
    isMinimum: false,
    isOneUpperCase: false,
    isOneLowerCase: false,
    isOneNumberOrSpecial: false,
  });
  const currentRouteName = useCurrentRouteName();
  const { t } = useTranslation();
  const theme = useTheme();
  const inputRef = useRef();
  const identity = authenticationService.fetchSession().identity;
  const isEmptyCurrentPassword = currentPassword.length === 0;
  const identityName = capitalize(identity?.name);

  const submitPassword = async () => {
    try {
      if (
        validation.isMinimum &&
        validation.isOneLowerCase &&
        validation.isOneNumberOrSpecial &&
        validation.isOneUpperCase &&
        !isEmptyCurrentPassword
      ) {
        await userService.updatePassword(newPassword, currentPassword);
        toastService.sendToast({
          title: "SETTINGS.EDITPASSWORD.SUCCESS.TITLE",
          body: "SETTINGS.EDITPASSWORD.SUCCESS.BODY",
          preset: toastService.PRESETS().SUCCESS,
        });
        analytics.track(
          analytics.ACTION.UPDATED_ACCOUNT_USER_PASSWORD,
          analytics.CATEGORY.USER
        );
      } else {
        analytics.track(
          analytics.ACTION.SUBMITTED,
          analytics.CATEGORY.ACCOUNT_USER_PASSWORD_FAILED,
          { page: getCurrentPage(currentRouteName) }
        );
        setShowError(true);
      }
    } catch (error) {
      analytics.track(
        analytics.ACTION.UPDATED_ACCOUNT_USER_PASSWORD_FAILED,
        analytics.CATEGORY.USER
      );
      errorHandlerService.handleError(error);
    }
  };
  const changeFocus = () => inputRef.current?.focus();
  const handleCurrentPassword = (event) => {
    setCurrentPassword(event.target.value);
  };
  const handleNewPassword = (event) => {
    setNewPassword(event.target.value);
  };
  useEffect(() => {
    setValidation({
      isMinimum: newPassword.length >= MINIMUM_PASSWORD_LENGTH,
      isOneUpperCase: newPassword.toLowerCase() !== newPassword,
      isOneLowerCase: newPassword.toUpperCase() !== newPassword,
      isOneNumberOrSpecial: new RegExp(
        INCLUDES_SPECIAL_CHAR_OR_NUMBER_REGEX
      ).test(newPassword),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword]);

  return (
    <Card data-testid="user-settings-edit-password-tab">
      <CardHeader title={t("SETTINGS.EDITPASSWORD.TITLE")} />
      <CardContent>
        {identity?.isExternal ? (
          <Box>
            <Text
              variant="body2"
              fontWeight={FSTGTypography.fontSize_1_5}
              lineHeight={FSTGTypography.lineHeight_1_5}
              color={theme.color.gray[800]}
              data-testid="settings-edit-password-disabled"
            >
              {t("SETTINGS.EDITPASSWORD.DISABLED", { identityName })}
            </Text>
          </Box>
        ) : (
          <>
            <Box display="flex" flexDirection="column">
              <PasswordField
                name="current_password"
                id="currentPassword"
                testId="settings-edit-password-current-password"
                errorTranslate="VALIDATIONS.CURRENT_PASSWORD.REQUIRED"
                value={currentPassword}
                onChange={handleCurrentPassword}
                error={showError && isEmptyCurrentPassword}
                labelTranslate="SETTINGS.EDITPASSWORD.FORM.CURRENT"
              />
              <PasswordFieldWithTooltip
                isMinimum={validation.isMinimum}
                isOneLowerCase={validation.isOneLowerCase}
                isOneNumberOrSpecial={validation.isOneNumberOrSpecial}
                isOneUpperCase={validation.isOneUpperCase}
                value={newPassword}
                onMouseEnter={changeFocus}
                inputRef={inputRef}
                onChange={handleNewPassword}
                testId="settings-edit-password-reenter-password"
                name="reenter_new_password"
                id="reenterNewPassword"
                labelTranslate="SETTINGS.EDITPASSWORD.FORM.NEW"
                error={showError}
              />
            </Box>
            <Box display="flex" flexDirection="row-reverse">
              <Button
                variant="contained"
                type="success"
                onClick={submitPassword}
                data-testid="settings-edit-password-submit-password"
              >
                {t("SETTINGS.EDITPASSWORD.SUBMIT")}
              </Button>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default EditPassword;
