import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

function TeamItem({ team, enableContinue, domain }) {
  const { t } = useTranslation();
  const [isDisabled, setIsDisabled] = useState(
    team.isTeamMember || team.pendingApproval
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPendingApproval, setIsPendingApproval] = useState(
    team.pendingApproval
  );

  const handleJoinTeam = async () => {
    setIsSubmitting(true);
    try {
      const newTeam = await teamService.joinTeamByDomain(team.id);
      const toastTranslation = newTeam.pendingApproval
        ? "JOIN_WITH_SAME_DOMAIN.REQUEST_TOAST"
        : "JOIN_WITH_SAME_DOMAIN.TOAST";
      toastService.sendToast({
        title: `${toastTranslation}.TITLE`,
        body: `${toastTranslation}.BODY`,
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            team: team.name,
            teamName: team.name,
          },
        },
      });
      analytics.trackV2({
        action: analytics.ACTION.JOINED,
        category: analytics.CATEGORY.TEAM,
        subCategory: analytics.SUB_CATEGORY.ONBOARDING_WIZARD,
        metaData: { domain },
      });
      if (newTeam.pendingApproval) {
        setIsPendingApproval(true);
      } else {
        await enableContinue(newTeam);
      }
      setIsDisabled(true);
    } catch (error) {
      if (error.message === "TEAM_MEMBER_LIMIT_EXCEEDED") {
        toastService.sendToast({
          title: "BILLING.FEATURE.TEAM_MEMBERS.REACHED.LIMIT.TITLE",
          body: "BILLING.FEATURE.TEAM_MEMBERS.REACHED.LIMIT.BODY",
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
        analytics.trackV2({
          action: analytics.ACTION.JOINED,
          category: analytics.CATEGORY.TEAM,
          subCategory: analytics.SUB_CATEGORY.ONBOARDING_WIZARD,
          state: analytics.STATE.FAILED,
          metaData: { domain },
        });
      } else {
        errorHandlerService.handleError(error);
      }
    }
    setIsSubmitting(false);
  };

  const getButtonText = () => {
    if (isDisabled) {
      return isPendingApproval
        ? "JOIN_WITH_SAME_DOMAIN.REQUESTED_CTA"
        : "TEAM.SAME_DOMAIN.JOINED";
    }
    return "JOIN_WITH_SAME_DOMAIN.CTA";
  };
  return (
    <Box
      p={2}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      height={50}
      sx={{
        borderStyle: "solid",
        borderWidth: "0 0 1px",
        borderColor: "grey.100",
      }}
      data-testid={`join-team-item-${team.name}`}
    >
      <Box maxWidth={250} title={team.name}>
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          fontWeight="fontWeight.bold"
        >
          {team.name}
        </Text>
        <Text color="text.secondary">
          {t(
            team.memberCount > 1
              ? "TEAM.SAME_DOMAIN.PLURAL"
              : "TEAM.SAME_DOMAIN.SINGULAR",
            { count: team.memberCount }
          )}
        </Text>
      </Box>
      <Button
        size="small"
        color="primary"
        variant={isDisabled ? "contained" : "outlined"}
        onClick={handleJoinTeam}
        disabled={isDisabled}
        loading={isSubmitting}
        sx={{ alignSelf: "flex-start" }}
      >
        {t(getButtonText())}
      </Button>
    </Box>
  );
}

TeamItem.propTypes = {
  team: PropTypes.object.isRequired,
  domain: PropTypes.string.isRequired,
  enableContinue: PropTypes.func.isRequired,
};

export default TeamItem;
