import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import Box from "@mui/material/Box";

import { Text, Dialog } from "@shared/UIKit";

function ConfirmDeletionDialog({ projectsCount, confirm }) {
  const modal = useModal();
  const { t } = useTranslation();
  const handleConfirm = () => {
    confirm();
    modal.remove();
  };

  return (
    <Dialog
      hideBackdrop={false}
      disableBackdropClick
      cancel={modal.remove}
      isOpen={modal.visible}
      useDefaultBackground={false}
      title={t("PROJECT_LIMIT.SUBSCRIPTION.CONFIRMATION_MODAL.TITLE")}
      actions={
        <Box
          gap={3}
          width="100%"
          paddingX={2}
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Button
            size="large"
            color="primary"
            variant="text"
            sx={{ flex: "auto" }}
            onClick={modal.remove}
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            sx={{ flex: "auto" }}
            onClick={handleConfirm}
          >
            {t("CORE.CONFIRM")}
          </Button>
        </Box>
      }
    >
      <Text variant="body1">
        {t("PROJECT_LIMIT.SUBSCRIPTION.CONFIRMATION_MODAL.BODY", {
          added: projectsCount,
        })}
      </Text>
    </Dialog>
  );
}

ConfirmDeletionDialog.propTypes = {
  confirm: PropTypes.func.isRequired,
  projectsCount: PropTypes.number.isRequired,
};

export default NiceModal.create(ConfirmDeletionDialog);
