import { useMatches } from "react-router-dom";

export const useCurrentRouteName = (routeIds = []) => {
  const matches = useMatches();

  if (routeIds.length === 0) {
    return matches[matches.length - 1]?.id;
  }
  // eslint-disable-next-line id-length
  for (let i = 0; i <= matches.length - 1; i++) {
    const match = matches[i];
    if (routeIds.includes(match.id)) {
      return match.id;
    }
  }
  return false;
};
