/* eslint-disable max-lines */
export default Object.freeze([
  "264",
  "669",
  "787",
  "89",
  "0cc",
  "2sf",
  "2sflib",
  "3g2",
  "3ga",
  "3gp",
  "3gp2",
  "3gpa",
  "3gpp",
  "3gpp2",
  "3mm",
  "3p2",
  "4mp",
  "5xb",
  "5xe",
  "5xs",
  "60d",
  "6cm",
  "8cm",
  "8med",
  "8svx",
  "a2b",
  "a2i",
  "a2m",
  "a2p",
  "a2t",
  "a2w",
  "a52",
  "aa",
  "aa3",
  "aac",
  "aaf",
  "aax",
  "ab",
  "abc",
  "abm",
  "ac3",
  "acb",
  "acd",
  "acd-bak",
  "acd-zip",
  "acm",
  "acp",
  "act",
  "adg",
  "adt",
  "adts",
  "adv",
  "adx",
  "aec",
  "aecap",
  "aegraphic",
  "aep",
  "aepx",
  "aet",
  "aetx",
  "afc",
  "agm",
  "agr",
  "ahx",
  "ai",
  "aif",
  "aifc",
  "aiff",
  "aimppl",
  "ais",
  "ajp",
  "akp",
  "al",
  "alac",
  "alaw",
  "alc",
  "ale",
  "all",
  "als",
  "am",
  "amc",
  "amf",
  "amr",
  "ams",
  "amv",
  "amx",
  "amxd",
  "amz",
  "ang",
  "anim",
  "anx",
  "aob",
  "ape",
  "apf",
  "apl",
  "aqt",
  "arcut",
  "arf",
  "aria",
  "ariax",
  "arw",
  "asd",
  "ase",
  "asf",
  "asx",
  "at3",
  "atrac",
  "au",
  "aud",
  "audionote",
  "aup",
  "avastsounds",
  "avb",
  "avc",
  "avchd",
  "avd",
  "avi",
  "avm",
  "avp",
  "avr",
  "avs",
  "avv",
  "awb",
  "awlive",
  "axa",
  "axm",
  "axv",
  "ay",
  "b4s",
  "band",
  "bap",
  "bcs",
  "bcstm",
  "bdd",
  "bdm",
  "bdmv",
  "bdt2",
  "bdt3",
  "bidule",
  "bik",
  "bin",
  "bix",
  "bmc",
  "bmk",
  "bmml",
  "bmp",
  "bnk",
  "bnp",
  "bonk",
  "box",
  "brr",
  "brstm",
  "bs4",
  "bsf",
  "bu",
  "bun",
  "bvr",
  "bwf",
  "bwg",
  "bww",
  "byu",
  "c01",
  "caf",
  "caff",
  "camproj",
  "camrec",
  "camv",
  "cda",
  "cdda",
  "cdlx",
  "cdo",
  "cdr",
  "ced",
  "cel",
  "cfa",
  "cfxr",
  "cgrp",
  "cidb",
  "cine",
  "cip",
  "ckb",
  "ckf",
  "clk",
  "clpi",
  "cmf",
  "cmmp",
  "cmmtpl",
  "cmproj",
  "cmrec",
  "cmv",
  "conform",
  "copy",
  "cpi",
  "cpr",
  "cpt",
  "cpvc",
  "cr2",
  "crec",
  "csh",
  "cst",
  "csv",
  "cts",
  "cvc",
  "cwb",
  "cwp",
  "cws",
  "cwt",
  "cx3",
  "d00",
  "d01",
  "d2v",
  "d3v",
  "dash",
  "dat",
  "dav",
  "db",
  "db2",
  "dce",
  "dcf",
  "dck",
  "dcm",
  "dcr",
  "dct",
  "ddat",
  "ddt",
  "dewf",
  "df2",
  "dfc",
  "dff",
  "dif",
  "dig",
  "dir",
  "divx",
  "djr",
  "dls",
  "dlx",
  "dm",
  "dmb",
  "dmc",
  "dmf",
  "dmsa",
  "dmsd",
  "dmsd3d",
  "dmse",
  "dmsm",
  "dmsm3d",
  "dmss",
  "dmx",
  "dnc",
  "doc",
  "dng",
  "docx",
  "dot",
  "dotx",
  "dpa",
  "dpg",
  "dra",
  "dream",
  "drg",
  "ds",
  "ds2",
  "dsf",
  "dsm",
  "dsp",
  "dss",
  "dsy",
  "dtm",
  "dv",
  "dv-avi",
  "dv4",
  "dvdmedia",
  "dvf",
  "dvr",
  "dvr-ms",
  "dvx",
  "dw",
  "dwa",
  "dwd",
  "dxr",
  "dzm",
  "dzp",
  "dzt",
  "ear",
  "ecf",
  "edl",
  "efa",
  "efe",
  "efk",
  "efq",
  "efs",
  "efv",
  "emd",
  "eml",
  "emp",
  "emx",
  "emy",
  "eop",
  "eps",
  "epub",
  "erb",
  "esps",
  "evo",
  "evr",
  "evrc",
  "exo",
  "expressionmap",
  "exs",
  "eye",
  "eyetv",
  "ezt",
  "f2r",
  "f32",
  "f3r",
  "f4a",
  "f4f",
  "f4m",
  "f4p",
  "f4v",
  "f64",
  "far",
  "fbr",
  "fbz",
  "fcarch",
  "fcp",
  "fcproject",
  "fda",
  "fdp",
  "fev",
  "ffd",
  "fff",
  "ffm",
  "fla",
  "flac",
  "flc",
  "flh",
  "fli",
  "flic",
  "flm",
  "flp",
  "fls",
  "flv",
  "flx",
  "fpa",
  "fpdx",
  "fpr",
  "frg",
  "fsb",
  "fsc",
  "fsm",
  "ftc",
  "fti",
  "ftm",
  "ftmx",
  "fuz",
  "fvt",
  "fzb",
  "fzf",
  "fzv",
  "g2m",
  "g721",
  "g723",
  "g726",
  "gbproj",
  "gbs",
  "gcs",
  "gfp",
  "gif",
  "gifv",
  "gig",
  "gio",
  "gl",
  "gm",
  "gmc",
  "gom",
  "gp5",
  "gpbank",
  "gpk",
  "gpx",
  "grasp",
  "gro",
  "groove",
  "gsf",
  "gsflib",
  "gsm",
  "gts",
  "gvi",
  "gvp",
  "gxf",
  "gym",
  "h0",
  "h264",
  "h3b",
  "h3e",
  "h4b",
  "h4e",
  "h5b",
  "h5e",
  "h5s",
  "hbb",
  "hbe",
  "hbs",
  "hdmov",
  "hdp",
  "hdv",
  "heic",
  "hkm",
  "hma",
  "hmi",
  "hps",
  "hsb",
  "htm",
  "html",
  "iaa",
  "ico",
  "ics",
  "idml",
  "idms",
  "iff",
  "ifo",
  "igp",
  "igr",
  "imf",
  "imovielibrary",
  "imoviemobile",
  "imovieproj",
  "imovieproject",
  "imp",
  "indd",
  "ini",
  "inp",
  "ins",
  "int",
  "ircp",
  "irf",
  "ism",
  "isma",
  "ismc",
  "ismclip",
  "ismv",
  "iso",
  "it",
  "iti",
  "itls",
  "its",
  "iva",
  "ivf",
  "ivr",
  "ivs",
  "izz",
  "izzy",
  "jam",
  "jdr",
  "jfif",
  "jmv",
  "jo",
  "jo-7z",
  "jpeg",
  "jpg",
  "jpgv1",
  "json",
  "jspf",
  "jss",
  "jts",
  "jtv",
  "k25",
  "k26",
  "k3g",
  "kar",
  "kdenlive",
  "key",
  "kfn",
  "kin",
  "kit",
  "kmp",
  "kmv",
  "koz",
  "kpl",
  "krz",
  "ksc",
  "ksd",
  "ksf",
  "ksm",
  "kt2",
  "kt3",
  "ktn",
  "ktp",
  "l",
  "la",
  "lof",
  "log",
  "logic",
  "logicx",
  "lqt",
  "lrec",
  "lrv",
  "lsf",
  "lso",
  "lst",
  "lsx",
  "lvix",
  "lvp",
  "lwv",
  "m",
  "m15",
  "m1a",
  "m1pg",
  "m1v",
  "m2",
  "m21",
  "m2a",
  "m2p",
  "m2t",
  "m2ts",
  "m2v",
  "m3u",
  "m3u8",
  "m4a",
  "m4b",
  "m4e",
  "m4p",
  "m4r",
  "m4u",
  "m4v",
  "m5p",
  "m75",
  "ma1",
  "mani",
  "mbr",
  "md",
  "mdc",
  "mdl",
  "mdr",
  "med",
  "meta",
  "mgv",
  "mhtml",
  "mid",
  "midi",
  "mini2sf",
  "minigsf",
  "minincsf",
  "minipsf",
  "minipsf2",
  "miniusf",
  "mj2",
  "mjp",
  "mjpeg",
  "mjpg",
  "mk3d",
  "mka",
  "mkv",
  "mlp",
  "mmf",
  "mmlp",
  "mmm",
  "mmp",
  "mmpz",
  "mmv",
  "mnv",
  "mo3",
  "mob",
  "mod",
  "modd",
  "moff",
  "mogg",
  "moi",
  "moov",
  "mov",
  "movie",
  "mp_",
  "mp1",
  "mp2",
  "mp21",
  "mp2v",
  "mp3",
  "mp4",
  "mp4.infovid",
  "mp4v",
  "mpa",
  "mpc",
  "mpdp",
  "mpe",
  "mpeg",
  "mpeg1",
  "mpeg2",
  "mpeg4",
  "mpf",
  "mpg",
  "mpg2",
  "mpg4",
  "mpga",
  "mpgindex",
  "mpl",
  "mpls",
  "mproj",
  "mpsub",
  "mpu",
  "mpv",
  "mpv2",
  "mqv",
  "mscx",
  "mscz",
  "msdvd",
  "mse",
  "msg",
  "msh",
  "msv",
  "mswmm",
  "mt2",
  "mt2s",
  "mt9",
  "mte",
  "mtf",
  "mti",
  "mtm",
  "mtp",
  "mts",
  "mtv",
  "mu3",
  "mui",
  "mus",
  "musa",
  "musx",
  "mux",
  "muz",
  "mvb",
  "mvc",
  "mvd",
  "mve",
  "mvex",
  "mvp",
  "mvy",
  "mwand",
  "mws",
  "mx3",
  "mx4",
  "mx5",
  "mx5template",
  "mxf",
  "mxl",
  "mxmf",
  "mxv",
  "myr",
  "mys",
  "mzp",
  "n3r",
  "nap",
  "narrative",
  "nbs",
  "ncor",
  "ncw",
  "nfv",
  "nkb",
  "nkc",
  "nki",
  "nkm",
  "nks",
  "nkx",
  "nml",
  "nmsv",
  "note",
  "npl",
  "nra",
  "nrt",
  "nsa",
  "nsf",
  "nst",
  "nsv",
  "ntn",
  "ntp",
  "nus3bank",
  "nut",
  "nuv",
  "nvc",
  "nvf",
  "nwc",
  "obw",
  "odm",
  "odp",
  "ofr",
  "oft",
  "oga",
  "ogg",
  "ogm",
  "ogv",
  "ogx",
  "okt",
  "oma",
  "omf",
  "omg",
  "omx",
  "opus",
  "orc",
  "orv",
  "osp",
  "otf",
  "otrkey",
  "ots",
  "ove",
  "ovw",
  "pac",
  "paf",
  "pages",
  "pandora",
  "par",
  "pat",
  "pbf",
  "pca",
  "pcast",
  "pcg",
  "pcm",
  "pd",
  "pdf",
  "pds",
  "peak",
  "pek",
  "pgi",
  "pho",
  "photoshow",
  "phy",
  "piv",
  "pjs",
  "pjunoxl",
  "pk",
  "pkf",
  "pla",
  "playlist",
  "plproj",
  "pls",
  "plst",
  "ply",
  "pmf",
  "pmpl",
  "pmv",
  "pna",
  "png",
  "pno",
  "pns",
  "potx",
  "ppc",
  "ppcx",
  "ppj",
  "pps",
  "ppst",
  "ppsx",
  "ppt",
  "pptm",
  "pptx",
  "prel",
  "prg",
  "pro",
  "pro4dvd",
  "pro5dvd",
  "proqc",
  "prproj",
  "prtl",
  "ps",
  "psb",
  "psd",
  "psf",
  "psf1",
  "psf2",
  "psh",
  "psm",
  "pssd",
  "psy",
  "ptcop",
  "ptf",
  "ptm",
  "pts",
  "ptt",
  "ptx",
  "ptxt",
  "pva",
  "pvc",
  "pvf",
  "pvr",
  "pxv",
  "q1",
  "q2",
  "qcp",
  "qt",
  "qtch",
  "qtindex",
  "qtl",
  "qtm",
  "qtz",
  "r",
  "r1m",
  "r3d",
  "ra",
  "rad",
  "ram",
  "rar",
  "raw",
  "rax",
  "rbs",
  "rcd",
  "rcproject",
  "rcrec",
  "rcut",
  "rcy",
  "rdb",
  "rec",
  "record",
  "rex",
  "rfl",
  "rgrp",
  "rip",
  "rm",
  "rmd",
  "rmf",
  "rmi",
  "rmj",
  "rmm",
  "rmp",
  "rms",
  "rmv",
  "rmvb",
  "rmx",
  "rng",
  "rns",
  "rol",
  "roq",
  "rp",
  "rsf",
  "rsn",
  "rso",
  "rsx",
  "rta",
  "rtf",
  "rti",
  "rtm",
  "rts",
  "rum",
  "rv",
  "rvid",
  "rvl",
  "rvt",
  "rvx",
  "rx2",
  "s16",
  "s3i",
  "s3m",
  "s3z",
  "saf",
  "sam",
  "san",
  "sap",
  "sb",
  "sbg",
  "sbi",
  "sbk",
  "sbt",
  "sbv",
  "sbz",
  "sc2",
  "scc",
  "scm",
  "scn",
  "screenflow",
  "scs11",
  "sd",
  "sd2",
  "sd2f",
  "sdat",
  "sdii",
  "sds",
  "sdt",
  "sdv",
  "sdx",
  "sec",
  "sedprj",
  "seg",
  "seq",
  "ses",
  "sesx",
  "sf",
  "sf2",
  "sfap0",
  "sfd",
  "sfera",
  "sfk",
  "sfl",
  "sfpack",
  "sfs",
  "sfvidcap",
  "sfx",
  "sfz",
  "sgp",
  "shn",
  "sib",
  "sid",
  "siv",
  "slddrw",
  "slp",
  "slx",
  "sma",
  "smf",
  "smi",
  "smil",
  "smk",
  "sml",
  "smp",
  "smpx",
  "smv",
  "snagproj",
  "snd",
  "sng",
  "sns",
  "snsf",
  "song",
  "sou",
  "sph",
  "spl",
  "sppack",
  "sprg",
  "spx",
  "sqz",
  "srt",
  "sseq",
  "ssf",
  "ssm",
  "ssnd",
  "stap",
  "sth",
  "sti",
  "stl",
  "stm",
  "stp",
  "str",
  "stw",
  "stx",
  "sty",
  "svd",
  "svg",
  "svi",
  "svq",
  "svx",
  "sw",
  "swa",
  "swav",
  "swf",
  "swi",
  "swt",
  "sxt",
  "syh",
  "syn",
  "syw",
  "syx",
  "tak",
  "td0",
  "tda3mt",
  "tdt",
  "tdx",
  "tfmx",
  "tg",
  "theater",
  "thp",
  "thx",
  "tid",
  "tif",
  "tiff",
  "tivo",
  "tix",
  "tm2",
  "tm8",
  "tmc",
  "toc",
  "tod",
  "tp",
  "tp0",
  "tpd",
  "tpr",
  "trak",
  "trec",
  "trp",
  "ts",
  "tsp",
  "tta",
  "ttc",
  "ttf",
  "ttxt",
  "tun",
  "tvlayer",
  "tvrecording",
  "tvs",
  "tvshow",
  "txt",
  "txw",
  "u",
  "u8",
  "uax",
  "ub",
  "ulaw",
  "ult",
  "ulw",
  "uni",
  "usf",
  "usflib",
  "usm",
  "ust",
  "uw",
  "uwf",
  "v264",
  "v2m",
  "vag",
  "val",
  "vap",
  "vb",
  "vbc",
  "vc1",
  "vc3",
  "vce",
  "vcpf",
  "vcr",
  "vcv",
  "vdj",
  "vdo",
  "vdr",
  "vdx",
  "veg",
  "vem",
  "vep",
  "vf",
  "vft",
  "vfw",
  "vfz",
  "vgm",
  "vgz",
  "vid",
  "video",
  "viewlet",
  "vip",
  "viv",
  "vivo",
  "vix",
  "vlab",
  "vlc",
  "vmd",
  "vmf",
  "vmlf",
  "vmlt",
  "vmo",
  "vob",
  "voc",
  "voi",
  "vox",
  "voxal",
  "vp3",
  "vp6",
  "vp7",
  "vpj",
  "vpl",
  "vpm",
  "vpw",
  "vqf",
  "vr",
  "vrf",
  "vro",
  "vs4",
  "vse",
  "vsp",
  "vsq",
  "vsqx",
  "vtt",
  "vtx",
  "vyf",
  "w01",
  "w32",
  "w64",
  "wand",
  "wav",
  "wave",
  "wax",
  "wcp",
  "webloc",
  "webm",
  "webp",
  "wem",
  "wfb",
  "wfd",
  "wfm",
  "wfp",
  "wfsp",
  "wgi",
  "wlmp",
  "wm",
  "wma",
  "wmd",
  "wmmp",
  "wmv",
  "wmx",
  "wot",
  "wow",
  "wp3",
  "wpk",
  "wpl",
  "wpp",
  "wproj",
  "wrk",
  "wsve",
  "wtpl",
  "wtpt",
  "wtv",
  "wus",
  "wut",
  "wv",
  "wvc",
  "wve",
  "wvm",
  "wvx",
  "wwu",
  "wxp",
  "wyz",
  "xa",
  "xbmml",
  "xej",
  "xel",
  "xesc",
  "xfl",
  "xfs",
  "xi",
  "xlmv",
  "xls",
  "xlsb",
  "xlsm",
  "xlsx",
  "xlt",
  "xltx",
  "xm",
  "xmf",
  "xmi",
  "xmp",
  "xms",
  "xmu",
  "xmv",
  "xmz",
  "xp",
  "xpf",
  "xrns",
  "xsb",
  "xsp",
  "xspf",
  "xt",
  "xvid",
  "xwb",
  "y4m",
  "ym",
  "yog",
  "yookoo",
  "yuv",
  "zab",
  "zeg",
  "zgr",
  "zip",
  "zm1",
  "zm2",
  "zm3",
  "zmv",
  "zpa",
  "zpl",
  "zprj",
  "zvd",
  "zvr",
  "thm",
  "jpf",
  "cube",
  "xsd",
  "rfa",
  "step",
  "jpe",
  "fbx",
  "emf",
  "glb",
  "dwg",
  "mb",
  "dxf",
  "odt",
  "sketch",
  "7z",
  "xml",
]);
