import { useEffect, useState, useRef } from "react";

import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import { makeStyles } from "@mui/styles";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import ProjectLockedDialog from "@workflow/components/ProjectLockedDialog/ProjectLockedDialog";
import UploadPremiereSequenceConfirmation from "@workflow/components/UploadFileButton/UploadButtons/UploadFromPlugin/UploadPremiereSequence/UploadPremiereSequenceConfirmation";
import projectSwitcherService from "@workflow/services/projectSwitcherService";

import { Box } from "@shared/UIKit";

import getPlugin from "@shared/helpers/getPlugin.js";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import pageTitleService from "@shared/services/pageTitleService";

import Project from "../Project/Project";
import ProjectHeader from "../ProjectHeader/ProjectHeader";
import ProjectArchiveBar from "./ProjectArchiveBar/ProjectArchiveBar";

const useStyles = makeStyles((theme) => ({
  dashboardWrapper: {
    zIndex: 0,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.color.gray[50],
  },
}));

function ProjectView() {
  const [isCurrentProjectLocked, setCurrentProjectLocked] = useState(false);
  const [selectedProject, setSelectedProject] = useState(
    projectSwitcherService.getSelectedProject()
  );
  const team = useSelectedTeam();
  const [projectTeam, setProjectTeam] = useState(team);

  const contentRef = useRef(null);
  const classes = useStyles();
  const isPremiere = getPlugin(PLUGIN_TYPES.PREMIERE);
  const { goTo } = useNavigation();

  useEffect(() => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.PROJECT, {
      projectIsArchived: selectedProject.isArchived,
      isDemoContent: selectedProject.isDemoProject,
    });
  }, [
    selectedProject.id,
    selectedProject.isArchived,
    selectedProject.isDemoProject,
  ]);

  useEffect(() => {
    pageTitleService.setTitle(selectedProject.name);
  }, [selectedProject.name]);

  useEffect(() => {
    if (selectedProject.teamId === team._id) {
      setProjectTeam(team);
      return;
    }

    async function fetchExternalTeam() {
      const projectTeamSelected = await teamService.fetchTeamByTeamId(
        selectedProject.teamId
      );
      setProjectTeam(projectTeamSelected);
    }

    fetchExternalTeam();
  }, [selectedProject, team]);

  useEffect(() => {
    function onProjectRemoved(event) {
      const {
        eventData: { project },
      } = event;
      if (project.id === selectedProject.id) {
        goTo("DASHBOARD");
      }
    }

    eventService.addListener(EVENT_NAMES.PROJECT.REMOVED, onProjectRemoved);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT.REMOVED,
        onProjectRemoved
      );
    };
  }, [goTo, selectedProject.id, team?._id]);

  useEffect(() => {
    authenticationService.generatePluginApiKey(selectedProject.teamId);
  }, [selectedProject.teamId]);

  useEffect(() => {
    const updateProjectLockedState = () => {
      setCurrentProjectLocked(
        team.usedBillingLimits.lockedProjectIds === "all" ||
          team.usedBillingLimits.lockedProjectIds?.indexOf(selectedProject.id) >
            -1
      );
    };
    const updateCurrentProject = ({ eventData: { project } }) => {
      setSelectedProject(project);
    };

    eventService.addListener(
      EVENT_NAMES.PROJECT.SELECTED,
      updateCurrentProject
    );
    updateProjectLockedState();

    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT.SELECTED,
        updateCurrentProject
      );
    };
  }, [selectedProject.id, team.usedBillingLimits.lockedProjectIds]);

  return (
    <>
      {isPremiere && (
        <UploadPremiereSequenceConfirmation project={selectedProject} />
      )}
      <Box ref={contentRef} className={classes.dashboardWrapper}>
        {isCurrentProjectLocked && (
          <ProjectLockedDialog
            team={team}
            container={contentRef.current}
            sx={{ position: "absolute" }}
            BackdropProps={{ sx: { position: "absolute" } }}
          />
        )}
        <ProjectHeader
          project={selectedProject}
          currentUserTeam={team}
          projectTeam={projectTeam}
          isCurrentProjectLocked={isCurrentProjectLocked}
        />
        {!isCurrentProjectLocked && selectedProject.isArchived && (
          <ProjectArchiveBar project={selectedProject} />
        )}
        <Project
          team={projectTeam}
          project={selectedProject}
          isTeamMember={selectedProject.teamId !== team._id}
          $element={contentRef}
        />
      </Box>
    </>
  );
}

export default ProjectView;
