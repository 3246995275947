import PropTypes from "prop-types";
import { useEffect } from "react";
import { useRouteError, useSearchParams, redirect } from "react-router-dom";

import ErrorBoundary from "@shared/components/RouterErrorBoundary";
import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { fetchDefault } from "@shared/services/dataLoaders";

import AdobePluginLogin from "./pages/AdobePluginLogin/AdobePluginLogin";
import AdobePluginLoginSuccessful from "./pages/AdobePluginLoginSuccess/AdobePluginLoginSuccessful";
import Embed from "./pages/Embed/Embed";
import IntegrationAuthorizationCallback from "./pages/IntegrationAuthorizationCallback/IntegrationAuthorizationCallback";
import IntegrationDetails from "./pages/Integrations/IntegrationDetails";
import IntegrationsAuthorizationFailed from "./pages/IntegrationsAuthorizationFailed/IntegrationsAuthorizationFailed";
import MondayAppAuthorizationStart from "./pages/MondayAppAuthorizationStart/MondayAppAuthorizationStart";
import MondayAppNoFileReview from "./pages/MondayAppNoFileReview/MondayAppNoFileReview";
import SignupExternalCallback from "./pages/SignupExternalCallback/SignupExternalCallback";
import Integrations from "@integrations/pages/Integrations/Integrations";
import ZapierEmbed from "@integrations/pages/Integrations/ZapierEmbed";
import MondayAppProjectList from "@integrations/pages/MondayAppProjectList/MondayAppProjectList";
import Dashboard from "@workflow/pages/Dashboard/Dashboard";

export const MondayAppErrorBoundary = ({
  redirectRouteName,
  redirectState,
}) => {
  const error = useRouteError();
  const { goTo } = useNavigation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (error.status === 401) {
      const stateParams = Object.fromEntries(searchParams.entries());

      goTo(redirectRouteName, {
        options: {
          state: {
            redirect: {
              state: redirectState,
              params: stateParams,
            },
          },
        },
      });
    }
  }, [error, redirectRouteName, redirectState, goTo, searchParams]);

  if (error.status === 401) {
    return null;
  }

  return <ErrorBoundary />;
};

MondayAppErrorBoundary.propTypes = {
  redirectRouteName: PropTypes.string.isRequired,
  redirectState: PropTypes.string.isRequired,
};

export const redirectIntegrationsToProjects = () => {
  sessionStorage.setItem("integratedApp", "true");
  const url = window.location.href.replace("/integrations", "");
  return redirect(new URL(url).pathname);
};

export const fetchDefaultAndRegisterUserSource = (
  routeKey,
  integrationSource
) => {
  return (...params) => {
    const fetchDefaultLoader = fetchDefault(routeKey);
    sessionStorage.setItem("integrationSource", integrationSource);

    return fetchDefaultLoader(...params);
  };
};

export const routes = [
  {
    path: APP_ROUTES.INTEGRATIONS.path,
    element: (
      <Dashboard>
        <Integrations />
      </Dashboard>
    ),
    loader: fetchDefault(ROUTE_STATES.INTEGRATIONS),
    id: "INTEGRATIONS",
  },
  {
    path: APP_ROUTES.INTEGRATIONS_DETAILS.path,
    element: <IntegrationDetails />,
    loader: fetchDefault(ROUTE_STATES.INTEGRATIONS),
    id: "INTEGRATION_DETAILS",
  },
  {
    path: APP_ROUTES.INTEGRATIONS_ZAPIER_EMBED.path,
    element: <ZapierEmbed />,
    loader: fetchDefault(ROUTE_STATES.INTEGRATIONS),
    id: "INTEGRATIONS_ZAPIER_EMBED",
  },
  {
    path: APP_ROUTES.EMBED.path,
    element: <Embed />,
    id: "EMBED",
  },
  {
    path: APP_ROUTES.PLUGIN_GOOGLE_LOGIN.path,
    element: <AdobePluginLogin />,
    id: "PLUGIN_GOOGLE_LOGIN",
  },
  {
    path: APP_ROUTES.PLUGIN_GOOGLE_LOGIN_SUCCESS.path,
    element: <AdobePluginLoginSuccessful />,
    id: "PLUGIN_GOOGLE_LOGIN_SUCCESS",
  },
  {
    path: APP_ROUTES.INTEGRATIONS_PROJECT_ID.path,
    loader: redirectIntegrationsToProjects,
  },
  {
    path: APP_ROUTES.MONDAY_APP_AUTHORIZATION_START.path,
    element: <MondayAppAuthorizationStart />,
    loader: fetchDefaultAndRegisterUserSource(
      ROUTE_STATES.MONDAY_APP_AUTHORIZATION_START,
      "monday-app"
    ),
    id: "MONDAY_APP_AUTHORIZATION_START",
    errorElement: (
      <MondayAppErrorBoundary
        redirectRouteName={ROUTE_STATES.LOGIN}
        redirectState={APP_ROUTES.MONDAY_APP_AUTHORIZATION_START.path}
      />
    ),
  },
  {
    path: APP_ROUTES.INTEGRATIONS_AUTHORIZATION_CALLBACK.path,
    element: <IntegrationAuthorizationCallback />,
    loader: fetchDefault(ROUTE_STATES.INTEGRATIONS),
    id: "INTEGRATIONS_AUTHORIZATION_CALLBACK",
    errorElement: (
      <MondayAppErrorBoundary
        redirectRouteName={ROUTE_STATES.LOGIN}
        redirectState={APP_ROUTES.INTEGRATIONS_AUTHORIZATION_CALLBACK.path}
      />
    ),
  },
  {
    path: APP_ROUTES.INTEGRATIONS_AUTHORIZATION_FAILED.path,
    element: <IntegrationsAuthorizationFailed />,
    id: "INTEGRATIONS_AUTHORIZATION_FAILED",
  },
  {
    path: APP_ROUTES.MONDAY_APP_PROJECTS_LIST.path,
    element: <MondayAppProjectList />,
    loader: fetchDefault(ROUTE_STATES.INTEGRATIONS),
    errorElement: (
      <MondayAppErrorBoundary
        redirectRouteName={ROUTE_STATES.EMBED}
        redirectState={APP_ROUTES.MONDAY_APP_PROJECTS_LIST.path}
      />
    ),
    id: "MONDAY_APP_PROJECTS_LIST",
  },
  {
    path: APP_ROUTES.MONDAY_APP_EMPTY_REVIEWS.path,
    element: <MondayAppNoFileReview />,
    loader: fetchDefault(ROUTE_STATES.INTEGRATIONS),
    errorElement: (
      <MondayAppErrorBoundary
        redirectRouteName={ROUTE_STATES.EMBED}
        redirectState={APP_ROUTES.MONDAY_APP_EMPTY_REVIEWS.path}
      />
    ),
    id: "MONDAY_APP_EMPTY_REVIEWS",
  },
  {
    path: APP_ROUTES.SIGNUP_EXTERNAL_CALLBACK.path,
    element: <SignupExternalCallback />,
    id: "SIGNUP_EXTERNAL_CALLBACK",
  },
];

export const unsupportedRoutes = [];
