import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Box } from "@mui/material";
import StepIndicator from "@supporting/components/WizardBase/StepIndicator";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as fileService } from "@workflow/services/fileService";
import projectService from "@workflow/services/projectService";
import Cookies from "js-cookie";

import { Text } from "@shared/UIKit";

import EVENT_NAMES from "@shared/constants/events";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import eventService from "@shared/services/eventService";

import WizardUploadArrow from "@assets/img/images/wizard-upload-file-arrow.svg";

import LoadingScreen from "../LoadingScreen/LoadingScreen";
import UploadOnlyFromDevice from "./UploadOnlyFromDevice";

const CUSTOM_PROJECT_NAME = "My first project";
const CURRENT_STEP = 3;

function UploadFiles() {
  const { t } = useTranslation();
  const [openLoadingScreen, setOpenLoadingScreen] = useState(false);
  const team = useSelectedTeam();
  const [project, setProject] = useState(null);
  const [filesObject, setFilesObject] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const files = Object.values(filesObject).filter((file) => Boolean(file));

  useEffect(() => {
    const fetchCustomProject = async () => {
      try {
        const folders = await projectService.fetchProjectsByTeamId(team._id);

        const selectedProject = folders[0]?.projects.find(
          (project) => project.name === CUSTOM_PROJECT_NAME
        );

        if (selectedProject) {
          setProject(selectedProject);
        }
      } catch (error) {
        errorHandlerService.handleError(error);
      } finally {
        setIsLoading(false);
      }
    };
    setIsLoading(true);
    fetchCustomProject();
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.ONBOARDING_WIZARD_DEMO_OR_UPLOAD_FILES
    );
  }, [team._id]);

  useEffect(() => {
    const fetchSectionFiles = async (sectionId) => {
      const response = await fileService.getSectionFiles(sectionId);
      setFilesObject(
        response.files.reduce((acc, file) => ({ ...acc, [file.id]: file }), {})
      );
      setIsLoading(false);
    };

    if (project) {
      setIsLoading(true);
      fetchSectionFiles(project.sections[0].id);
    }

    const onFileUploadStarted = (event) => {
      setFilesObject((previousFiles) => ({
        ...previousFiles,
        [event.eventData.dummyFile._id]: event.eventData.dummyFile,
      }));
    };

    const onFileUploadFinished = (event) => {
      /* istanbul ignore else*/
      if (event.eventData.file) {
        setFilesObject((previousFiles) => ({
          ...previousFiles,
          [event.eventData.file.id]: event.eventData.file,
          [event.eventData.processingFile._id]: null,
        }));
      }
    };

    const onFileUpdated = (event) => {
      /* istanbul ignore else*/
      if (event.eventData.file.id) {
        setFilesObject((previousFiles) => ({
          ...previousFiles,
          [event.eventData.file.id]: event.eventData.file,
        }));
      }
    };

    const onFileRemoved = (event) => {
      setFilesObject((previousFiles) => ({
        ...previousFiles,
        [event.eventData.file.id]: null,
      }));
    };

    eventService.addListener(
      EVENT_NAMES.FILE.UPLOAD.STARTED,
      onFileUploadStarted
    );

    eventService.addListener(
      EVENT_NAMES.FILE.UPLOAD.FINISHED,
      onFileUploadFinished
    );

    eventService.addListener(EVENT_NAMES.FILE.UPDATED, onFileUpdated);

    eventService.addListener(EVENT_NAMES.FILE.REMOVED, onFileRemoved);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.FILE.UPLOAD.STARTED,
        onFileUploadStarted
      );
      eventService.removeListener(
        EVENT_NAMES.FILE.UPLOAD.FINISHED,
        onFileUploadFinished
      );
      eventService.removeListener(EVENT_NAMES.FILE.UPDATED, onFileUpdated);
      eventService.removeListener(EVENT_NAMES.FILE.REMOVED, onFileRemoved);
    };
  }, [project]);

  const invitedMembers = Cookies.get("onboarding:invited-emails")
    ? Cookies.get("onboarding:invited-emails").split(",")
    : [];

  const handleContinueCustomProject = async () => {
    analytics.track(
      analytics.ACTION.SUBMITTED,
      analytics.CATEGORY.ONBOARDING_WIZARD_DEMO_OR_UPLOAD_FILES
    );

    if (invitedMembers.length) {
      await projectService.addCollaborators(
        project.id,
        invitedMembers,
        "",
        true
      );
    }
    setOpenLoadingScreen(true);
  };

  const handleContinueDemoProject = () => {
    if (project) {
      projectService.removeProject(project);
    }
    setOpenLoadingScreen(true);
  };

  if (openLoadingScreen) {
    return <LoadingScreen teamId={team._id} teamMembers={invitedMembers} />;
  }

  return (
    <WizardBase variant="INVITE_TEAM_MEMBERS">
      <Box display="flex" flexDirection="column" gap={5}>
        <Box gap={2} display="flex" flexDirection="column">
          <StepIndicator currentStep={CURRENT_STEP} />
          <Text
            variant="text3xl"
            fontWeight="fontWeight.medium"
            translate="ONBOARDING_WIZARD.DEMO_STEP.TITLE"
          />
          <Text
            color="grey.600"
            variant="textSm"
            translate="ONBOARDING_WIZARD.DEMO_STEP.BODY"
          />
        </Box>
        <Button
          variant="outlined"
          sx={{
            height: 68,
            borderColor: "primary.300",
          }}
          onClick={handleContinueDemoProject}
        >
          {t("ONBOARDING_WIZARD.DEMO.SAMPLE_FILE")}
        </Button>
        <Text alignSelf="center" translate="CORE.OR" />
        <UploadOnlyFromDevice
          teamId={team._id}
          project={project}
          setProject={setProject}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          files={files}
        />
        <Box
          flexDirection="column"
          display={files.length > 0 ? "flex" : "none"}
        >
          <Button
            fullWidth
            variant="contained"
            disabled={files.length === 0}
            onClick={handleContinueCustomProject}
          >
            {t("CORE.CONTINUE")}
          </Button>
          <Box
            display="flex"
            position="relative"
            alignSelf={{
              xs: "flex-start",
              md: "flex-end",
            }}
          >
            <WizardUploadArrow />
            <Box
              left="100%"
              minWidth={150}
              position="absolute"
              alignSelf="flex-end"
            >
              <Text
                variant="textSm"
                color="accent.main"
                translate="ONBOARDING_WIZARD.UPLOAD_FILES.CONTINUE_TOOLTIP"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </WizardBase>
  );
}

export default UploadFiles;
