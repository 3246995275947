import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { z } from "zod";

import { InfoToolTip, Text } from "@shared/UIKit";

import ProjectTemplateHelper from "../ProjectTemplateHelper/ProjectTemplateHelper";

const filter = createFilterOptions();

const createProjectFormSchema = (templates) =>
  z.object({
    projectName: z
      .string()
      .min(1, "VALIDATIONS.PROJECT_NAME.REQUIRED")
      .max(150),
    folder: z.object(
      {
        id: z.string().optional(),
        name: z.string().min(1, "VALIDATIONS.ADD_TO_THIS_FOLDER.REQUIRED"),
        inputValue: z.string().optional(),
      },
      {
        errorMap: (error) => {
          // add ignore else as this is a type check
          /* istanbul ignore else */
          if (error?.code === z.ZodIssueCode.invalid_type) {
            return { message: "VALIDATIONS.ADD_TO_THIS_FOLDER.REQUIRED" };
          }
        },
      }
    ),
    template: z
      .enum(templates.map((template) => template.id).concat(""))
      .optional(),
  });

function findFolderAndChangeField(data, field, folders) {
  const existingFolder = folders.find((folder) => folder.name === data);

  if (existingFolder) {
    field.onChange(existingFolder);
  } else {
    field.onChange({ name: data.name || data });
  }
}

function CreateProjectForm({
  folders,
  templates,
  defaultFolder,
  saveProject,
  onTemplateChange,
  hasTemplateReviewers,
}) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(createProjectFormSchema(templates)),
    defaultValues: {
      projectName: "",
      folder: defaultFolder
        ? { id: defaultFolder.id, name: defaultFolder.name, group: "" }
        : null,
      template: "",
    },
    mode: "onChange",
  });

  return (
    <Box
      component="form"
      id="create-project-form"
      onSubmit={handleSubmit(saveProject)}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        textAlign: "left",
      }}
    >
      <Box px={2}>
        <Controller
          name="projectName"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              autoFocus
              label={t("PROJECT.NEWPROJECT.LABEL_NAME")}
              error={Boolean(errors.projectName)}
              helperText={t(errors.projectName?.message)}
              inputProps={{
                "data-testid": "name-project-input",
                maxLength: 150,
              }}
              autoComplete="off"
            />
          )}
        />
      </Box>

      <Box
        backgroundColor="rgba(var(--mui-palette-default-light-mainChannel) / 0.1)"
        px={2}
        pt={2}
        pb={4}
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Controller
          control={control}
          name="folder"
          render={({ field }) => {
            return (
              <Autocomplete
                {...field}
                freeSolo
                clearIcon=""
                options={folders.map((folder) => ({
                  id: folder.id,
                  name: folder.name,
                  group: "",
                }))}
                groupBy={(option) => option.group || ""}
                getOptionLabel={(option) => option.name || ""}
                getOptionKey={(option) => option.id}
                renderInput={(params) => {
                  let helperMessage = "";
                  let color = "inherit";
                  if (errors.folder) {
                    helperMessage = errors.folder.message;
                    color = "error.main";
                  } else if (field.value && !field.value.id) {
                    helperMessage = "PROJECT.NEWPROJECT.CREATE_FOLDER_HINT";
                    color = "success.main";
                  }
                  return (
                    <TextField
                      {...params}
                      data-testid="folder-autocomplete"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      fullWidth
                      label={t("PROJECT.NEWPROJECT.LABEL_FOLDER")}
                      error={Boolean(errors.folder)}
                      helperText={t(helperMessage)}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          color,
                        },
                      }}
                    />
                  );
                }}
                onInputChange={(_, value, reason) => {
                  if (reason === "input") {
                    findFolderAndChangeField(value, field, folders);
                  }
                }}
                onChange={(_, data, reason) => {
                  switch (reason) {
                    case "selectOption":
                      field.onChange(data);
                      break;

                    case "clear":
                      field.onChange(null);
                      break;
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) =>
                      params.inputValue.toLowerCase() ===
                      option.name.toLowerCase()
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      group: t("PROJECT.NEWPROJECT.CREATE_FOLDER"),
                      inputValue,
                      name: inputValue,
                    });
                  }
                  return filtered;
                }}
              />
            );
          }}
        />
        <Text
          variant="textXs"
          color="text.secondary"
          textAlign="right"
          translate="PROJECT.NEWPROJECT.CREATE_FOLDER_HELP"
        />
        <Controller
          control={control}
          name="template"
          render={({ field }) => (
            <TextField
              select
              fullWidth
              {...field}
              onChange={(event) => {
                field.onChange(event);
                onTemplateChange(event.target.value);
              }}
              inputProps={{
                "data-testid": "project-template-list",
              }}
              label={t("PROJECT.NEWPROJECT.LABEL_TEMPLATE")}
              SelectProps={{
                renderValue: (value) => {
                  const template = templates.find((temp) => temp.id === value);
                  return template.name;
                },
              }}
            >
              {templates.map((template) => {
                const TemplateItem = (
                  <MenuItem value={template.id} key={template.id}>
                    <ListItemText
                      primary={template.name}
                      secondary={(template.steps || []).reduce(
                        (acc, step, index) =>
                          `${acc}${index + 1}. ${step.name} `,
                        ""
                      )}
                    />
                    {template.isLocked && (
                      <Box
                        position="absolute"
                        right={10}
                        top={5}
                        display="flex"
                        alignItems="center"
                      >
                        <LockOutlinedIcon fontSize="small" />
                        <Text
                          variant="textXs"
                          component="span"
                          fontWeight="fontWeight.medium"
                          color="text.secondary"
                        >
                          {t("PROJECT_TEMPLATES.DEACTIVATED_LABEL")}
                        </Text>
                      </Box>
                    )}
                  </MenuItem>
                );
                return template.isLocked ? (
                  <InfoToolTip
                    title={t("PROJECT_TEMPLATES.DEACTIVATED_DESCRIPTION")}
                    data-testid="tooltip-project-template-autocomplete"
                    placement="top"
                    key={template.id}
                  >
                    {TemplateItem}
                  </InfoToolTip>
                ) : (
                  TemplateItem
                );
              })}
            </TextField>
          )}
        />
      </Box>

      {hasTemplateReviewers && <ProjectTemplateHelper />}
    </Box>
  );
}

CreateProjectForm.propTypes = {
  folders: PropTypes.array.isRequired,
  templates: PropTypes.array.isRequired,
  defaultFolder: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  saveProject: PropTypes.func.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  hasTemplateReviewers: PropTypes.bool.isRequired,
};
CreateProjectForm.defaultProps = {
  defaultFolder: null,
};
export default CreateProjectForm;
