import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, Menu, MenuItem } from "@mui/material";

import { Box, Text, Tooltip } from "@shared/UIKit";

function SelectRoleMenu({
  enableInviteSetting,
  updateInviteLink,
  teamRoles,
  filteredDefaultRoleOptions,
  inviteLink,
}) {
  const [anchorRoleMenu, setAnchorRoleMenu] = useState(null);

  const { t } = useTranslation();

  const handleOpenRoleMenu = (event) => {
    /* istanbul ignore next */
    setAnchorRoleMenu(enableInviteSetting ? event.currentTarget : null);
  };

  const handleCloseRoleMenu = () => {
    setAnchorRoleMenu(null);
  };

  const handleRoleMenuSelection = (event) => {
    updateInviteLink({ defaultRole: event.target.id });
    handleCloseRoleMenu();
  };

  const getRoleName = (selectedRoleId) => {
    const roleName = teamRoles.find(
      (role) => role._id === selectedRoleId
    )?.roleName;
    return filteredDefaultRoleOptions.includes(roleName)
      ? t(`TEAM.ROLES.${roleName}`)
      : roleName;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      flex={1.2111}
      mr={2.5}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Text
          variant="textSm"
          translate="TEAM_INVITE_LINK.DEFAULT_ROLE"
          fontWeight="fontWeight.bold"
        />
        <Tooltip
          placement="top"
          title={t("TEAM_INVITE_LINK.DEFAULT_ROLE_TOOLTIP")}
        >
          <InfoOutlinedIcon fontSize="small" htmlColor="grey.500" />
        </Tooltip>
      </Box>
      <Button
        data-testid="open-role-menu"
        onClick={handleOpenRoleMenu}
        color="inherit"
        disabled={!enableInviteSetting}
        endIcon={anchorRoleMenu ? <ExpandLess /> : <ExpandMore />}
      >
        {getRoleName(inviteLink.defaultRole)}
      </Button>
      <Menu
        keepMounted
        id="team-role"
        anchorEl={anchorRoleMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={enableInviteSetting && Boolean(anchorRoleMenu)}
        onClose={handleCloseRoleMenu}
      >
        {teamRoles.map((teamRole) => {
          return (
            <MenuItem
              id={teamRole._id}
              key={teamRole._id}
              onClick={handleRoleMenuSelection}
            >
              {getRoleName(teamRole._id)}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

SelectRoleMenu.propTypes = {
  inviteLink: PropTypes.object.isRequired,
  enableInviteSetting: PropTypes.bool.isRequired,
  updateInviteLink: PropTypes.func.isRequired,
  teamRoles: PropTypes.array,
  filteredDefaultRoleOptions: PropTypes.array,
};
SelectRoleMenu.defaultProps = {
  teamRoles: [],
  filteredDefaultRoleOptions: [],
};

export default SelectRoleMenu;
