import NiceModal from "@ebay/nice-modal-react";
import { useCallback, useEffect, useState } from "react";

import PremiumIcon from "@supporting/hooks/useGatedFeature/PremiumIcon";
import { instance as teamService } from "@supporting/services/team";
import noop from "lodash/noop";

import { instance as analytics } from "@shared/services/analytics";

import { default as UpgradeDialog } from "./GatedModal";

/**
 * @typedef useGatedFeatureResult
 * @property {function} checkAndProceed - The function to check the limit
 * @property {asset} IconAsset - Rendered Icon for premium features
 * @property {boolean} isLimitReached - The flag to indicate if the limit is reached
 *
 */

/**
 * Use Gated Feature hook
 * @description This hook will check the billing limits for the team and if the limit is reached
 * it will show the modal with the message provided in the upgradeDialogsProps.
 * @param {Object} props
 * @param {import("./featureGates").FeatureGate} props.featureGate
 * @param {*} props.currentValue - The value to check against
 * @param {import("../useBillingLimits").FSTGId} props.teamId - The team id to get the subscription limits
 * @returns {useGatedFeatureResult}
 */
function useGatedFeature({ featureGate, currentValue, teamId }) {
  const [gateLimits, setGateLimits] = useState({
    subscriptionLimit: undefined,
    isFreePlan: false,
    teamName: "",
  });
  useEffect(() => {
    const fetchAndSetSubscriptionLimit = async () => {
      const team = await teamService.fetchTeamByTeamId(teamId);
      setGateLimits({
        subscriptionLimit:
          team.subscriptionLimits[featureGate.subscriptionLimitKey],
        isFreePlan: team.subscription?.basePrice.name
          .toLowerCase()
          .includes("free"),
        teamName: team.name,
      });
    };
    if (teamId) {
      fetchAndSetSubscriptionLimit();
    }
  }, [teamId, featureGate.subscriptionLimitKey]);

  const isLimitReached = featureGate.checkLimit(
    gateLimits.subscriptionLimit,
    currentValue
  );

  const memoizedCheckAndProceed = useCallback(
    (onSuccess = noop, onLimitReach = noop) =>
      (event) => {
        if (isLimitReached && teamId) {
          NiceModal.show(UpgradeDialog, {
            ...featureGate.upgradeDialogsProps,
            teamId,
            teamName: gateLimits.teamName,
            isFreePlan: gateLimits.isFreePlan,
            selectPlanOpenAnalytics: featureGate.selectPlanOpenAnalytics,
            restartTrialAnalytics: featureGate.restartTrialAnalytics,
          });
          analytics.trackV2(featureGate.limitReachAnalytics);
          onLimitReach(event);
        } else {
          onSuccess(event);
        }
      },
    [
      isLimitReached,
      featureGate,
      teamId,
      gateLimits.isFreePlan,
      gateLimits.teamName,
    ]
  );

  return {
    checkAndProceed: memoizedCheckAndProceed,
    IconAsset: PremiumIcon,
    isLimitReached,
  };
}

export default useGatedFeature;
