import PropTypes from "prop-types";
import { ReactElement, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";

/**
 * UIKit Text Component
 * @description Renders a component which contains text and all the typography variants.
 * It extends Material UI Typography component.
 * @param {object} props - Component props
 * @param {ReactElement|string} props.children [Required | if translate is empty] - The children of the component.
 * @param {string} props.className [Optional] Additional class name for styling
 * @param {string} props.variant [Optional] Material UI typography variant. Default is `body1`
 * Options: `h1`, `h2`, `h3`, `h4`, `h5`, `h6`, `subtitle1`, `subtitle2`, `subtitle3`, `body1`, `body2`, `body3`.
 * @param {string} props.color [Optional] Color of the text. It can hold any theme color.
 * @param {string|number} props.fontWeight [Optional] Font weight of the text. It can hold any theme font weight.
 * @param {string|number} props.letterSpacing [Optional] Letter spacing of the text. It can hold any theme letter spacing.
 * @param {string|number} props.fontSize [Optional] Font size of the text. It can hold any theme font size.
 * @param {string|number} props.lineHeight [Optional] Line height of the text. It can hold any theme line height.
 * @param {string} props.textOverflow [Optional] Text overflow of the text. It extends the options of JSS `textOverflow` property.
 * @param {string} props.whiteSpace [Optional] White space of the text. It extends the options of JSS `whiteSpace` property.
 * @param {string} props.textTransform [Optional] Text transform of the text. It extends the options of JSS `textTransform` property.
 * @param {string} props.textAlign [Optional] Text align of the text. It extends the options of JSS `textAlign` property.
 * @param {string} props.wordBreak [Optional] Word break of the text. It extends the options of JSS `wordBreak` property.
 * @param {string} props.hyphens [Optional] Hyphens of the text. It extends the options of JSS `hypens` property.
 * @param {string} props.fontStyle [Optional] Font style of the text. It extends the options of JSS `fontStyle` property.
 * @param {string} props.wordrap [Optional] Word wrap of the text. It extends the options of JSS `wordwrap` property.
 * @param {string} props.textShadow [Optional] Text shadow of the text. It extends the options of JSS `textShadow` property.
 * @param {string} props.cursor [Optional] Cursor of the text. It extends the options of JSS `cursor` property.
 * @param {string} props.translate [Optional] The translation key. It provides the translation of the given key and when provided, the children prop would not be needed.
 * @returns {ReactElement} - React component
 */
const Text = forwardRef(
  (
    {
      className,
      children,
      variant,
      textOverflow,
      whiteSpace,
      wordBreak,
      hyphens,
      wordwrap,
      textShadow,
      cursor,
      overflow,
      translate,
      textDecoration,
      sx,
      ...restProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    return (
      <Typography
        className={className}
        variant={variant}
        ref={ref}
        sx={{
          textOverflow,
          overflow,
          whiteSpace,
          wordBreak,
          hyphens,
          wordwrap,
          textShadow,
          cursor,
          textDecoration,
          ...sx,
        }}
        {...restProps}
      >
        {translate ? t(translate) : children}
      </Typography>
    );
  }
);
Text.displayName = "Text";

Text.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    "subtitle2",
    "subtitle1",
    "body2",
    "body1",
    "h6",
    "h5",
    "h4",
    "h3",
    "h2",
    "h1",
    "text5xl",
    "text4xl",
    "text3xl",
    "text2xl",
    "textXl",
    "textLg",
    "textMd",
    "textSm",
    "textXs",
  ]),
  children: PropTypes.node,
  color: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textOverflow: PropTypes.string,
  whiteSpace: PropTypes.string,
  textTransform: PropTypes.string,
  textAlign: PropTypes.string,
  wordBreak: PropTypes.string,
  hyphens: PropTypes.string,
  fontStyle: PropTypes.string,
  wordwrap: PropTypes.string,
  textShadow: PropTypes.string,
  cursor: PropTypes.string,
  overflow: PropTypes.string,
  textDecoration: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
  translate: (props, propName) => {
    if (
      // eslint-disable-next-line eqeqeq
      props.children == null &&
      (!props[propName] || typeof props[propName] !== "string")
    ) {
      return new Error(
        "The `translate` and `children` props cannot both be empty."
      );
    }
  },
};

Text.defaultProps = {
  className: null,
  children: null,
  variant: "body2",
  color: null,
  fontWeight: null,
  letterSpacing: null,
  fontSize: null,
  lineHeight: null,
  textOverflow: null,
  whiteSpace: null,
  textTransform: null,
  textAlign: null,
  wordBreak: null,
  hyphens: null,
  fontStyle: null,
  wordwrap: null,
  textShadow: null,
  cursor: null,
  overflow: null,
  translate: null,
  textDecoration: null,
  sx: {},
};

export default Text;
