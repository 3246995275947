import NiceModal from "@ebay/nice-modal-react";
import { useState, useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as billingService } from "@supporting/services/billingService";

import { Box, Text } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import { useDialogState } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import HiddenMetaDataUpdater from "@supporting/pages/Teams/subscription/HiddenMetaDataUpdater";

import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import HelpCard from "./HelpCard/HelpCard";
import SubscriptionFeatures from "./SubscriptionFeatures";
import SubscriptionState from "./SubscriptionState";
import SubscriptionUsage from "./SubscriptionUsage";
import TooltipWithLink from "./TooltipWithLink";

function SubscriptionTab() {
  const teamValue = useSelectedTeam();
  const [searchParams] = useSearchParams();

  const action = searchParams.get("action");
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [scheduledPlan, setScheduledPlan] = useState({});
  const subscriptionMenuOpen = Boolean(anchorEl);
  const {
    isOpen: isCancelSubscriptionDialogOpen,
    closeDialog,
    openDialog,
  } = useDialogState();

  const openSelectPlanDialog = useCallback(() => {
    NiceModal.show(SelectPlanDialog, { teamId: teamValue._id });
  }, [teamValue._id]);

  useEffect(() => {
    async function fetchDefaultPlans() {
      const plans = await billingService.fetchDefaultPlans(teamValue._id);
      setScheduledPlan(
        plans.find((plan) => plan.id === teamValue.billing.scheduledPriceId) ||
          {}
      );
    }

    if (teamValue.billing.scheduledPriceId) {
      fetchDefaultPlans();
    }
  }, [teamValue._id, teamValue.billing.scheduledPriceId]);

  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED_PRICING_PAGE,
      analytics.CATEGORY.SUBSCRIPTION,
      {
        subscriptionStatus: teamValue.subscriptionStatus,
        subscriptionPlan: teamValue.subscription.basePrice.name,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTrial = useCallback(() => {
    return teamValue.subscription.status === "trialing";
  }, [teamValue.subscription]);

  const currentPrice = useCallback(() => {
    return teamValue.subscription.basePrice;
  }, [teamValue.subscription]);

  const canCancelSubscription = useCallback(() => {
    return (
      !teamValue.subscription.canceledAt &&
      !isTrial() &&
      currentPrice()?.amount !== 0
    );
  }, [currentPrice, teamValue.subscription.canceledAt, isTrial]);

  const cancelSubscription = useCallback(
    () => {
      setAnchorEl(null);
      openDialog();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamValue._id]
  );

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const cancelScheduledSubscription = async () => {
    try {
      await billingService.activateSubscription(
        teamValue._id,
        teamValue.subscription.basePrice.id
      );
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  const handleClickCancelScheduled = () => {
    cancelScheduledSubscription();
  };

  useEffect(() => {
    if (action === "select-plan") {
      openSelectPlanDialog();
    }
  }, [action, openSelectPlanDialog]);

  let subHeader;
  if (teamValue.billing.scheduledPriceId) {
    subHeader = (
      <Box
        bgcolor="grey.50"
        display="flex"
        p={0.5}
        maxWidth={415}
        my={2}
        borderRadius={1}
      >
        <Text component="p" variant="textSm">
          <Trans
            components={{
              bold: <b />, //eslint-disable-line react/forbid-elements
              anchor: (
                <TooltipWithLink
                  onClick={handleClickCancelScheduled}
                  title="PREVENT_DOWNGRADE.SUBSCRIPTION_CANCEL_TOOLTIP_TEXT"
                />
              ),
            }}
            values={{
              endDate: formatWithLocale(
                teamValue.subscription.currentPeriodEnd * 1000
              ),
              plan: scheduledPlan?.name,
            }}
          >
            PREVENT_DOWNGRADE.SUBSCRIPTION_CANCEL
          </Trans>
        </Text>
      </Box>
    );
  } else if (!teamValue.subscription.cancelAtPeriodEnd) {
    subHeader = (
      <Box
        bgcolor="grey.50"
        maxWidth={415}
        borderRadius={1}
        my={2}
        p={0.5}
        display="flex"
      >
        <Text component="p" variant="textSm">
          <Trans
            components={
              // eslint-disable-next-line id-length
              { bold: <b /> } // eslint-disable-line react/forbid-elements
            }
            values={{
              endDate: formatWithLocale(
                teamValue.subscription.currentPeriodEnd * 1000
              ),
            }}
          >
            SUBSCRIPTION.PLAN.CAPTION_RENEW_AUTOMATICALLY
          </Trans>
        </Text>
      </Box>
    );
  } else {
    subHeader = null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Card data-testid="subscription-tab">
        <CardHeader
          title={teamValue.subscription.basePrice?.name}
          data-testid="current-subscription-name"
          sx={{
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            "& .MuiCardHeader-content": {
              pt: 2,
            },
          }}
          action={
            <Box display="flex">
              {teamValue.permissions.canManageBilling && (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={openSelectPlanDialog}
                    data-testid="billing-change-plan"
                    sx={{ alignSelf: "center" }}
                  >
                    {t("BILLING.CHANGE_PLAN")}
                  </Button>
                  <HiddenMetaDataUpdater
                    metadata={teamValue.subscription.limits}
                    subscriptionId={teamValue.subscription.id}
                    priceId={teamValue.subscription.basePrice?.id}
                  />
                </>
              )}
              {teamValue.permissions.canManageBilling &&
                canCancelSubscription() && (
                  <IconButton
                    aria-label="settings"
                    onClick={handleClick}
                    data-testid="subscription-menu"
                    size="large"
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              {anchorEl && (
                <Menu
                  id="subscription-menu"
                  anchorEl={anchorEl}
                  open={subscriptionMenuOpen}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={cancelSubscription}
                    data-testid="cancel-subscription"
                  >
                    <ListItemIcon>
                      <CancelOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>
                      {t("BILLING.CANCEL_SUBSCRIPTION.BUTTON")}
                    </ListItemText>
                  </MenuItem>
                </Menu>
              )}
            </Box>
          }
          subheader={subHeader}
        />
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2}>
            <SubscriptionState team={teamValue} />
            <SubscriptionUsage team={teamValue} />
            <SubscriptionFeatures team={teamValue} />
          </Box>
        </CardContent>
      </Card>
      <HelpCard />
      <CancelSubscriptionDialog
        isOpen={isCancelSubscriptionDialogOpen}
        close={closeDialog}
      />
    </Box>
  );
}

export default SubscriptionTab;
