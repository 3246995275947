import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";

import { Text, Box } from "@shared/UIKit";

import { EMAIL_REGEX } from "@shared/constants/validation";
import backendClient from "@shared/services/backendClient";

function DisableSSO() {
  const { t } = useTranslation();
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [data, setData] = useState({
    isLoading: false,
    error: null,
    isValid: false,
  });

  const handleSubmit = useCallback(async () => {
    setData({
      isLoading: true,
      error: null,
      message: null,
      isValid: true,
    });

    try {
      await backendClient.post("/admin/sso/blacklist-user", {
        email,
      });

      setData({
        isLoading: false,
        error: null,
        message: `SSO has been disabled for ${email}.`,
        isValid: true,
      });
    } catch (error) {
      setData({
        isLoading: false,
        error: error.message,
        isValid: true,
      });
    }
  }, [email]);

  const handleEmailChange = useCallback((event) => {
    const { value } = event.currentTarget;
    setEmail(value);

    setData((prevState) => ({
      ...prevState,
      isValid: EMAIL_REGEX.test(value),
    }));
  }, []);

  return (
    <>
      <Title>Disable SSO for user</Title>
      <Paper sx={{ maxWidth: "sm" }}>
        <Box p={4}>
          <Box display="flex" mt={2} alignItems="center" data-testid="form">
            <Box
              mt={2.5}
              ml={0.5}
              mr={2}
              lineHeight={theme.typography.textXs.lineHeight}
            >
              <EmailOutlined />
            </Box>
            <TextField
              id="email"
              inputProps={{ "data-testid": "form-input" }}
              label={t("FORM.EMAIL.WORK_LABEL")}
              value={email}
              onChange={handleEmailChange}
              error={!data.isValid}
              fullWidth
            />
          </Box>

          <Box mt={2} ml={5}>
            <LoadingButton
              data-testid="form-submit"
              disabled={!data.isValid}
              loading={data.isLoading}
              onClick={handleSubmit}
              size="large"
              variant="contained"
            >
              Disable SSO
            </LoadingButton>
          </Box>

          {data.error && (
            <Box my={2} ml={5} data-testid="form-error">
              <Text color="error.main">{data.error}</Text>
            </Box>
          )}
          {data.message && (
            <Box my={2} ml={5} data-testid="form-message">
              <Text color="primary.main">{data.message}</Text>
            </Box>
          )}
        </Box>
      </Paper>
    </>
  );
}

export default DisableSSO;
