import { makeStyles } from "@mui/styles";

import { Box, LoadingSpinner } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  loadingSpinnerBackdrop: {
    backgroundColor: theme.color.gray[50],
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

function PendingReviewsLoading() {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      width={400}
      zIndex={1}
      height="85%"
      alignItems="center"
      position="fixed"
      top={60}
      flexGrow={1}
      justifyContent="center"
      textAlign="center"
      flexDirection="column"
      className={classes.loadingSpinnerBackdrop}
      data-testid="pending-reviews-loading-spinner"
    >
      <LoadingSpinner />
    </Box>
  );
}

export default PendingReviewsLoading;
