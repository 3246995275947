import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import { Image, Text } from "@shared/UIKit";

import CelebrateImage from "@assets/img/images/celebrate_completed_review_1.png";

function PendingReviewsEmptyState() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="calc(100% - 38px)"
      data-testid="pending-reviews-empty-state"
    >
      <Image
        src={CelebrateImage}
        srcSet={`${CelebrateImage} 1x`}
        alt={t(`PENDING_REVIEWS.TITLE`)}
        sx={{ maxWidth: 180 }}
      />
      <Box display="flex" justifyContent="center" textAlign="center" px={2}>
        <Text fontSize="1.7rem" variant="body1" color="primaryV2.darker">
          {t("PENDING_REVIEWS.EMPTY_STATE.TITLE")}
        </Text>
      </Box>
    </Box>
  );
}

export default PendingReviewsEmptyState;
