import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import HelpIcon from "@mui/icons-material/Help";
import {
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Box,
  IconButton,
} from "@mui/material";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { ReviewerUpload } from "@supporting/hooks/useGatedFeature/featureGates";

import { Text, Tooltip } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";

import ReviewState from "../ReviewState/ReviewState";

const PERMISSION_DESCRIPTIONS = [
  {
    description: {
      title: "REVIEWER_SETTINGS.PERMISSIONS_DOWNLOADS",
      helpText: "REVIEWER_SETTINGS.PERMISSIONS_DOWNLOADS_TOOLTIP",
    },
    statusPermissions: [
      "downloadInReview",
      "downloadNeedsChanges",
      "downloadApproved",
    ],
  },
  {
    description: {
      title: "REVIEWER_SETTINGS.PERMISSIONS_COMMENTS",
      helpText: "REVIEWER_SETTINGS.COMMENTS_AND_REVIEW_DECISIONS",
    },
    statusPermissions: [
      "commentInReview",
      "commentNeedsChanges",
      "commentApproved",
    ],
  },
];

const ReviewerGroupPermissionsTab = ({ teamId, settings, onChange }) => {
  const { t } = useTranslation();
  const { checkAndProceed, isLimitReached, IconAsset } = useGatedFeature({
    featureGate: ReviewerUpload,
    teamId,
  });

  return (
    <TableContainer data-testid="reviewer-permissions-tab">
      <Table>
        <TableHead sx={{ backgroundColor: "grey.50" }}>
          <TableRow>
            <TableCell />
            {Object.values(REVIEW_STATUS).map((status) => (
              <TableCell key={status} align="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Text variant="textSm">
                    {t("REVIEWER.SETTINGS.REVIEW_IS")}
                  </Text>
                  <ReviewState reviewState={status} />
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {PERMISSION_DESCRIPTIONS.map((permission) => (
            <TableRow
              key={permission.description.title}
              sx={{
                "& .MuiTableCell-root": {
                  position: "relative",
                },
                "& .MuiTableCell-root:not(:first-of-type):not(:last-of-type):after":
                  {
                    content: "''",
                    display: "block",
                    height: "50%",
                    width: "1px",
                    backgroundColor: "grey.200",
                    position: "absolute",
                    right: 0,
                    top: "25%",
                  },
              }}
            >
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  <Text fontWeight="fontWeight.semiBold">
                    {t(permission.description.title)}{" "}
                  </Text>
                  <Tooltip
                    title={t(permission.description.helpText)}
                    sx={{ color: "grey.300" }}
                  >
                    <HelpIcon fontSize="small" />
                  </Tooltip>
                </Box>
              </TableCell>
              {permission.statusPermissions.map((statusPermission) => (
                <TableCell
                  key={statusPermission}
                  align="center"
                  sx={{ py: 2.5 }}
                >
                  <Switch
                    checked={settings[statusPermission]}
                    onChange={({ currentTarget }) => {
                      onChange({
                        field: statusPermission,
                        value: currentTarget.checked,
                      });
                    }}
                    inputProps={{
                      "data-testid": `reviewer-settings-${statusPermission}-switch`,
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow sx={{ borderTop: "solid 4px var(--mui-palette-grey-100)" }}>
            <TableCell sx={{ borderBottom: 0 }}>
              <Box display="flex" alignItems="center" gap={1}>
                <Text fontWeight="fontWeight.semiBold">
                  {t("REVIEWER_SETTINGS.PERMISSIONS_UPLOADS")}
                </Text>
                <Tooltip
                  title={t("REVIEWER_SETTINGS.PERMISSIONS_UPLOADS_TOOLTIP")}
                >
                  <HelpIcon fontSize="small" sx={{ color: "grey.300" }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell colSpan={1} sx={{ borderBottom: 0 }} />
            <TableCell
              colSpan={1}
              sx={{ borderBottom: 0, pl: 4.5 }}
              align="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <Switch
                  checked={settings["uploadingEnabled"]}
                  disabled={isLimitReached}
                  onChange={({ currentTarget }) => {
                    onChange({
                      field: "uploadingEnabled",
                      value: currentTarget.checked,
                    });
                  }}
                  inputProps={{
                    "data-testid": "reviewer-settings-uploadingEnabled-switch",
                  }}
                />
                <IconButton
                  size="small"
                  disableRipple
                  onClick={checkAndProceed()}
                  data-testid="reviewer-settings-uploading-crown-icon"
                  sx={{
                    visibility: isLimitReached ? "visible" : "hidden",
                  }}
                >
                  <IconAsset />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell colSpan={1} sx={{ borderBottom: 0 }} />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReviewerGroupPermissionsTab.propTypes = {
  teamId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    downloadInReview: PropTypes.bool.isRequired,
    downloadNeedsChanges: PropTypes.bool,
    downloadApproved: PropTypes.bool.isRequired,
    commentInReview: PropTypes.bool.isRequired,
    commentNeedsChanges: PropTypes.bool,
    commentApproved: PropTypes.bool.isRequired,
    uploadingEnabled: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReviewerGroupPermissionsTab;
