import { useState } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import ReviewerAuthenticationFlowContent from "@supporting/components/ReviewersAuthenticationFlow/ReviewerAuthenticationFlowContent";

import { Image, Text } from "@shared/UIKit";

import design from "@assets/img/images/awaiting-review-guest-session.png";

function AwaitingReviewsGuest() {
  const [title, setTitle] = useState("REVIEWER.ENTER_USERDATA.HEADLINE");

  const { stepId } = useParams();
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box m={2} boxShadow={1} borderRadius={1} bgcolor="white">
        <Box my={3}>
          <Text
            variant="h5"
            component="p"
            fontWeight="fontWeight.medium"
            translate={title}
            textAlign="center"
          />
          <Box mt={1}>
            <ReviewerAuthenticationFlowContent
              isAwaitingReview
              setTitle={setTitle}
              action="awaiting-reviews"
              context={{ stepId }}
            />
          </Box>
        </Box>
      </Box>
      <Box mt={2} display="flex" justifyContent="center">
        <Image width={370} height={358} src={design} alt="Image of a family" />
      </Box>
    </Box>
  );
}

export default AwaitingReviewsGuest;
