import PropTypes from "prop-types";
import { Link as ReactLink } from "react-router-dom";

import { LockOutlined } from "@mui/icons-material";
import { Link } from "@mui/material";
import noop from "lodash/noop";

import { Text } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";

export default function SidebarListItem({
  id,
  isSelected,
  isArchived,
  name,
  isTemplate,
  onClick,
  isLocked,
}) {
  const { buildUrl } = useNavigation();

  const itemLink = isTemplate
    ? buildUrl(ROUTE_STATES.TEMPLATE_ID, { params: { projectTemplateId: id } })
    : buildUrl(ROUTE_STATES.PROJECT_ID, { params: { projectId: id } });

  let color = "text.secondary";
  if (isArchived) {
    color = "default-light";
  } else if (isSelected) {
    color = "primary";
  }

  return (
    <Link
      color={color}
      fontStyle={isArchived ? "italic" : "inherit"}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      component={ReactLink}
      onClick={onClick}
      to={itemLink}
      title={name}
      id={`sidebar-list-item-${id}`}
      data-testid={`sidebar-list-item-${id}`}
      data-isselected={isSelected}
      sx={{
        minHeight: 26,
        px: 2,
        cursor: "pointer",
        bgcolor: isArchived && "grey.50",
        "&:hover": { bgcolor: !isSelected && "grey.50" },
        ...(isSelected && {
          borderRight: "1px solid currentColor",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      <Text
        component="span"
        variant="body2"
        ml={4}
        pr={1}
        data-testid={isArchived ? "archived-project-name" : "project-name"}
        noWrap
      >
        {name}
      </Text>
      {isLocked && (
        <LockOutlined
          color="default-light"
          fontSize="small"
          data-testid="locked-icon"
        />
      )}
    </Link>
  );
}

SidebarListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isArchived: PropTypes.bool,
  isSelected: PropTypes.bool,
  isTemplate: PropTypes.bool,
  onClick: PropTypes.func,
  isLocked: PropTypes.bool,
};

SidebarListItem.defaultProps = {
  isArchived: false,
  isSelected: false,
  isTemplate: false,
  isLocked: false,
  onClick: noop,
};
