import Cookies from "js-cookie";

import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";

const getInviteLinkId = () => {
  return Cookies.get("teamlink_inviteLinkId");
};

const updateGoogleAuthState = ({ search, buildUrl }) => {
  const googleAuthState = {
    redirect: search.get("redirect") || APP_ROUTES.DASHBOARD.path,
  };

  const inviteLinkId = getInviteLinkId();
  if (inviteLinkId) {
    googleAuthState.redirect = buildUrl(ROUTE_STATES.TEAM_JOIN_LINK, {
      params: { inviteLinkId },
    });
  }
  return googleAuthState;
};
export { getInviteLinkId, updateGoogleAuthState };
