import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generatePath, useSearchParams, useNavigate } from "react-router-dom";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import { Button } from "@mui/material";
import authenticationService from "@supporting/services/authentication";

import { Box, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import urlUtils from "@shared/helpers/urlUtils";
import backend from "@shared/services/backendClient";
import { instance as websocket } from "@shared/services/websocket";

import GoogleSigninIcon from "@assets/img/icons/ic_google_signin.svg";

export default function AdobePluginGoogleAuthButton({
  adobePluginSessionId,
  shareSessionStepId,
  adobePluginText,
}) {
  const [searchParams] = useSearchParams();
  const [browserOpened, setBrowserOpened] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authenticationState = authenticationService.getAuthenticationState();
  const redirect =
    authenticationState?.redirect || searchParams.get("redirect");

  useEffect(() => {
    websocket.addListener(
      websocket.EVENT_NAMES.COMPLETE_GOOGLE_AUTHENTICATION,
      async (event) => {
        const response = await backend.post(
          "/adobe-plugin/auth/google/callback",
          {
            shareSessionStepId,
            ...event,
          }
        );

        authenticationService.onSuccessfulAuthentication(response);

        if (redirect) {
          navigate(redirect);
        } else {
          navigate(APP_ROUTES.DASHBOARD.path);
        }
      }
    );

    return () => {
      websocket.removeListeners(
        websocket.EVENT_NAMES.COMPLETE_GOOGLE_AUTHENTICATION
      );
    };
  }, [shareSessionStepId, redirect, navigate]);

  const handleSignInWithGoogle = () => {
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_OPEN_BROWSER,
        data: {
          browserData: {
            url: `${urlUtils.getBaseURI()}${generatePath(
              APP_ROUTES.PLUGIN_GOOGLE_LOGIN.path
            )}?${new URLSearchParams({
              pluginUserId: adobePluginSessionId,
              code: adobePluginText,
            }).toString()}`,
          },
        },
      },
      "*"
    );

    setBrowserOpened(true);
  };

  const handleCancelAuthentication = () => {
    setBrowserOpened(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Button
        startIcon={!browserOpened && <GoogleSigninIcon height={24} />}
        onClick={handleSignInWithGoogle}
        variant="text"
        size="large"
        color="primaryV2"
        fullWidth
        data-testid="adobe-plugin-auth-button"
        disabled={browserOpened}
        loading={browserOpened}
      >
        {!browserOpened && t(`AUTH.${adobePluginText}.CTA`)}
      </Button>

      {browserOpened && (
        <Box mt={2} textAlign="center">
          <Text translate={`AUTH.${adobePluginText}.HELP`} />
          <Button
            onClick={handleCancelAuthentication}
            variant="text"
            size="small"
            color="primaryV2"
            data-testid="cancel-adobe-plugin-auth-button"
          >
            {t("CORE.CANCEL")}
          </Button>
        </Box>
      )}
    </Box>
  );
}

AdobePluginGoogleAuthButton.propTypes = {
  adobePluginSessionId: PropTypes.string.isRequired,
  shareSessionStepId: PropTypes.string,
  adobePluginText: PropTypes.oneOf(["SIGN_UP", "LOGIN"]),
};

AdobePluginGoogleAuthButton.defaultProps = {
  shareSessionStepId: undefined,
  adobePluginText: "LOGIN",
};
