import PropTypes from "prop-types";
import { memo } from "react";
import { Trans } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";
import NOTIFICATION_CENTER_ACTIVITIES from "@supporting/components/NotificationCenter/activities.constants";
import ReviewState from "@workflow/components/ReviewState/ReviewState";

import { Text } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { notificationCenterActivityProps } from "@shared/props/notificationCenter";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles(() => ({
  ReviewState: {
    display: "inline-block",
    margin: 3,
  },
}));
const reviewNotificationStatus = {
  REVIEW_STATUS_IN_REVIEW: REVIEW_STATUS.IN_REVIEW,
  REVIEW_STATUS_APPROVED: REVIEW_STATUS.APPROVED,
  REVIEW_STATUS_NEEDS_CHANGES: REVIEW_STATUS.NEEDS_CHANGES,
};

function ActivityTitle({ type, numberOfUsers }) {
  const classes = useStyles();
  const theme = useTheme();
  let activityTranslationKey = `NOTIFICATION_CENTER.NOTIFICATION_CONTENT.${type}`;

  if (type === NOTIFICATION_CENTER_ACTIVITIES.COMMENT.LIKED) {
    if (numberOfUsers > 1) {
      activityTranslationKey = `NOTIFICATION_CENTER.NOTIFICATION_CONTENT.${NOTIFICATION_CENTER_ACTIVITIES.COMMENT.LIKED}_PLURAL`;
    } else {
      activityTranslationKey = `NOTIFICATION_CENTER.NOTIFICATION_CONTENT.${NOTIFICATION_CENTER_ACTIVITIES.COMMENT.LIKED}_SINGULAR`;
    }
  }

  let translationComponents = [];
  if (reviewNotificationStatus[type]) {
    translationComponents = [
      <ReviewState
        reviewState={reviewNotificationStatus[type]}
        className={classes.ReviewState}
        key={reviewNotificationStatus[type]}
      />,
    ];
  }

  return (
    <Text
      variant="subtitle1"
      component="span"
      color={theme.color.gray[500]}
      fontSize={FSTGTypography.fontSize_1_4}
    >
      <Trans
        i18nKey={activityTranslationKey}
        components={translationComponents}
      />
    </Text>
  );
}

ActivityTitle.propTypes = {
  type: notificationCenterActivityProps.isRequired,
  numberOfUsers: PropTypes.number.isRequired,
};

export default memo(ActivityTitle);
