import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Card, CardHeader, CardContent } from "@mui/material";
import { AUTOMATION_INPUT_TYPES } from "@workflow/constants/automation";

import { Box, Tooltip } from "@shared/UIKit";

import { automationFieldConditionProps } from "@shared/props/project";

import TriggerConditionsDropdown from "../TriggerConditionsDropdown";
import TriggerConditionsInput from "../TriggerConditionsInput";

function AutomationCardForm({
  icon,
  action,
  conditions,
  selectedOption,
  handleChange,
  isEditable,
  title,
  name,
  delay,
}) {
  const { t } = useTranslation();

  const renderCondition = (condition) => {
    switch (condition.type) {
      case AUTOMATION_INPUT_TYPES.SELECT:
        return (
          <TriggerConditionsDropdown
            key={condition.id}
            condition={condition}
            selected={selectedOption[condition.id]}
            changeHandler={handleChange}
            isEditable={isEditable}
          />
        );
      case AUTOMATION_INPUT_TYPES.INPUT:
        return (
          <TriggerConditionsInput
            key={condition.id}
            id={condition.id}
            condition={condition}
            onChange={handleChange}
            defaultValue={selectedOption[condition.id]}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Card
      elevation={0}
      data-testid={`automations-add-${name}-form`}
      sx={{ width: { xs: 220, sm: 375 } }}
    >
      <CardHeader
        data-testid={`automations-add-${name}-form-header`}
        avatar={icon}
        title={title}
        action={action}
        sx={{
          "& .MuiCardHeader-title": { fontSize: "textSm.fontSize" },
          bgcolor: "grey.100",
          "& .MuiCardHeader-action": { visibility: "hidden" },
          "&:hover .MuiCardHeader-action": { visibility: "visible" },
          px: 2,
          py: 1.3,
        }}
      />
      {conditions &&
        conditions.length > 0 &&
        conditions.some((condition) => condition.type) && (
          <CardContent
            data-testid={`automations-add-${name}-form-content`}
            sx={{ px: 2, pt: 1 }}
          >
            {conditions.map((condition) => renderCondition(condition))}

            {delay && (
              <Box display="flex" justifyContent="flex-end">
                <Tooltip placement="top" title={t(delay)}>
                  <Box
                    display="flex"
                    data-testid="automation-delay-button"
                    bgcolor="accent.light"
                    color="accent.main"
                    borderRadius={6}
                    mt={0.75}
                  >
                    <AccessTimeIcon fontSize="small" />
                  </Box>
                </Tooltip>
              </Box>
            )}
          </CardContent>
        )}
    </Card>
  );
}

AutomationCardForm.defaultProps = {
  selectedOption: {
    text: null,
    value: null,
  },
  isEditable: true,
};

AutomationCardForm.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  icon: PropTypes.element.isRequired,
  action: PropTypes.element,
  conditions: PropTypes.arrayOf(PropTypes.shape(automationFieldConditionProps)),
  selectedOption: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  handleChange: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
  delay: PropTypes.string,
};

AutomationCardForm.defaultProps = {
  conditions: [],
  selectedOption: {},
  delay: null,
  action: null,
};

export default AutomationCardForm;
