import { differenceInDays } from "date-fns";
export let instance;

const FREE_REVIEWER_USD = "free-reviewer-usd";
const FREE_REVIEWER_EUR = "free-reviewer-eur";
const FREE_USD = "free-usd";
const FREE_EURO = "free-eur";
const FREE_GBP = "free-gbp";

const TEAM_CREATION_MAX_TIME = 14;

export function initialize() {
  const BILLING_PLAN_IDS = {
    FREE_REVIEWER_EUR,
    FREE_REVIEWER_USD,
    FREE: {
      ids: [
        FREE_EURO,
        FREE_USD,
        FREE_REVIEWER_EUR,
        FREE_REVIEWER_USD,
        FREE_GBP,
      ],
      identifier: "FREE",
    },
    ENTERPRISE: {
      ids: [
        "enterprise-monthly-usd",
        "enterprise-monthly-eur",
        "enterprise-yearly-usd",
        "enterprise-yearly-eur",
      ],
      identifier: "ENTERPRISE",
    },
  };

  const isSubscriptionUnpaidTrial = (subscription) => {
    return subscription.status === "trialing";
  };

  const isPlanEnterprise = (plan) => {
    return BILLING_PLAN_IDS.ENTERPRISE.ids.includes(plan.id);
  };

  const shouldShowTrialEndedDialog = (team) => {
    const freePlans = [FREE_USD, FREE_EURO];
    const isOnFree = freePlans.includes(team.subscription?.basePrice?.id);

    const isTeamLessThanTwoWeeksOld =
      team.createdAt &&
      differenceInDays(new Date(), new Date(team.createdAt)) <=
        TEAM_CREATION_MAX_TIME;

    return (
      team.permissions.canManageBilling && isOnFree && isTeamLessThanTwoWeeksOld
    );
  };

  instance = {
    isSubscriptionUnpaidTrial,
    isPlanEnterprise,
    shouldShowTrialEndedDialog,
  };
}
