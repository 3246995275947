import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { PersonAddOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import toastService from "@supporting/services/toast";
import projectService from "@workflow/services/projectService";

import { Box, Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import InviteCollaboratorDialog from "../InviteCollaboratorDialog/InviteCollaboratorDialog";

const MAX_VISIBLE = 5;

function AddCollaboratorButton({ project, team }) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const addCollaboratorsHandler = useCallback(
    async (answer) => {
      const { emails, message, notifyEmail, newSeat, newTeamMembers } = answer;
      setIsDialogOpen(false);
      healthMetrics.trackStart("workflow.invite-collaborators");

      const updatedProject = await projectService.addCollaborators(
        project.id,
        emails,
        message,
        notifyEmail
      );

      const newCollaborators = updatedProject.collaborators.filter(
        (collaborator) =>
          emails
            .map((email) => email.toLowerCase())
            .includes(collaborator.email)
      );

      /* istanbul ignore next */
      if (newSeat) {
        analytics.track(analytics.ACTION.ADDED, analytics.CATEGORY.SEATS, {
          label: analytics.LABEL.INVITE_COLLABORATOR_DIALOG,
        });
      }

      newTeamMembers.forEach((newTeamMember) => {
        analytics.track(
          analytics.ACTION.INVITED_MEMBER,
          analytics.CATEGORY.TEAM,
          {
            teamId: team._id,
            teamName: team.name,
            invitedEmail: newTeamMember.inputValue,
            numberOfMembers: team.members.length,
            page: pagesEnum.PROJECT_DASHBOARD,
          }
        );
      });

      healthMetrics.trackSuccess("workflow.invite-collaborators");
      /* istanbul ignore next */
      toastService.sendToast({
        title: `${
          newCollaborators.length === 1
            ? "COLLABORATORS.ADD.SUCCESS-SINGULAR"
            : "COLLABORATORS.ADD.SUCCESS-PLURAL"
        }.TITLE`,
        preset: toastService.PRESETS().SUCCESS,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project.id]
  );

  const handleDialogAnswer = useCallback(
    async (answer) => {
      try {
        healthMetrics.trackAttempt("workflow.invite-collaborators");
        await addCollaboratorsHandler(answer);
      } catch (error) {
        healthMetrics.trackFailure("workflow.invite-collaborators", error);
        errorHandlerService.handleError(error);
      }
    },
    [addCollaboratorsHandler]
  );

  const handleClick = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  return (
    <Box
      zIndex={Math.min(project.collaborators.length, MAX_VISIBLE) + 1}
      ml={-1}
    >
      <Tooltip
        title={t("COLLABORATORS.INVITE.TITLE")}
        description={t("COLLABORATORS.INVITE.DESCRIPTION")}
      >
        <Button
          aria-label={t("COLLABORATORS.INVITE")}
          data-testid="invite-collaborator"
          onClick={handleClick}
          className="tour__invite-project-collaborator"
          color="primary"
          variant="outlined"
          shape="round"
          disableRipple
        >
          <PersonAddOutlined fontSize="small" />
        </Button>
      </Tooltip>
      {isDialogOpen && (
        <InviteCollaboratorDialog
          isOpen
          cancel={closeDialog}
          answer={handleDialogAnswer}
          team={team}
          project={project}
        />
      )}
    </Box>
  );
}

AddCollaboratorButton.propTypes = {
  team: teamProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
};

export default AddCollaboratorButton;
