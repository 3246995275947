import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";

import UpsellExportFileReportDialog from "@feedback/components/ExportFeedback/UpsellExportFileReportDialog/UpsellExportFileReportDialog";
import reviewReportService from "@feedback/services/reviewReportService";
import noop from "lodash/noop";

import pagesEnum from "@shared/constants/pages";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import DownloadReviewReportIcon from "@assets/img/icons/icons8-export-pdf.svg";

import ExportReportMenuItem from "../ExportReportMenuItem/ExportReportMenuItem";

function ExportReviewReportMenuItem({
  label,
  closeMenu,
  onExport,
  page,
  step,
  review,
  team,
}) {
  const { isExportEnabled: isExportReviewReportEnabled } =
    team.subscriptionLimits;
  const exportReviewReport = () => {
    if (isExportReviewReportEnabled) {
      closeMenu();
      onExport(
        reviewReportService.exportReviewReport(team, review, page, step)
      );
    } else {
      NiceModal.show(UpsellExportFileReportDialog, { teamId: team._id });
      analytics.track(
        analytics.ACTION.REACHED,
        `${analytics.CATEGORY.SUBSCRIPTION_LIMIT}-${analytics.CATEGORY.REVIEW_REPORT}`
      );
    }
  };

  return (
    <ExportReportMenuItem
      data-testid="review-card-export"
      exportReport={exportReviewReport}
      label={label}
      resourceId={review.id}
      showUpgradeIcon={!isExportReviewReportEnabled}
      startIcon={DownloadReviewReportIcon}
    />
  );
}

ExportReviewReportMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  closeMenu: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  page: PropTypes.oneOf([pagesEnum.PROJECT_DASHBOARD, pagesEnum.WORKSPACE])
    .isRequired,
  step: stepProps.isRequired,
  review: reviewProp.isRequired,
  team: teamProp.isRequired,
};
ExportReviewReportMenuItem.defaultProps = {
  onExport: noop,
};

export default ExportReviewReportMenuItem;
