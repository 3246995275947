import authenticationService from "@supporting/services/authentication";
import userService from "@supporting/services/userService";
import concat from "lodash/concat";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import orderBy from "lodash/orderBy";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import projectReviewService from "./projectReviewService";
import projectService from "./projectService";

let selectedProject = null;

function initialize() {
  clearCache();

  eventService.addListener(EVENT_NAMES.PROJECT.UPDATED, onUpdatedProject);
  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);
}

function clearCache() {
  selectedProject = null;
}

function getSelectedProject() {
  return selectedProject;
}

function setSelectedProject(project) {
  if (project !== selectedProject) {
    selectedProject = project;

    if (project?.id) {
      eventService.emitEvent({
        eventName: EVENT_NAMES.PROJECT.SELECTED,
        eventData: {
          project,
        },
      });
    }
  }
}

async function fetchAllProjects(teamId) {
  const [internalFolders, externalTeams] = await Promise.all([
    projectService.fetchProjectsByTeamId(teamId),
    projectReviewService.fetchExternalProjects(),
  ]);

  const externalFolders = flatMap(externalTeams, "folders");
  const externalProjects = flatMap(externalFolders, "projects");

  const internalProjects = flatMap(internalFolders, "projects");

  const allProjects = concat(internalProjects, externalProjects);

  return { internalProjects, externalProjects, allProjects };
}

function saveSelectedProject(selectedProject) {
  const user = authenticationService.fetchUser();

  if (selectedProject.id !== user.settings.selectedProjectId) {
    userService.update({
      settings: {
        selectedProjectId: selectedProject.id,
      },
    });
  }
}

async function determineSelectedProject(teamId) {
  const { internalProjects, externalProjects, allProjects } =
    await fetchAllProjects(teamId);
  const user = authenticationService.fetchUser();
  let project = null;

  if (user.settings.selectedProjectId) {
    project = find(allProjects, {
      id: user.settings.selectedProjectId,
    });
  }

  if (!project) {
    const filteredProjectsByDate = orderBy(
      internalProjects.length ? internalProjects : externalProjects,
      (project) => {
        return new Date(project.createdTime);
      },
      "desc"
    );
    project = filteredProjectsByDate[0];
  }

  if (project) {
    saveSelectedProject(project);
    setSelectedProject(project);
  } else {
    setSelectedProject(null);
  }
  return project;
}

async function determineSelectedProjectById(teamId, projectId) {
  const { allProjects } = await fetchAllProjects(teamId);
  const project = find(allProjects, (project) => {
    return project.id === projectId;
  });

  if (project) {
    saveSelectedProject(project);
    setSelectedProject(project);
  }

  return project;
}

function onUpdatedProject(event) {
  if (event.eventData.project.id === selectedProject?.id) {
    setSelectedProject(event.eventData.project);
  }
}

export default {
  initialize,
  getSelectedProject,
  setSelectedProject,
  determineSelectedProject,
  determineSelectedProjectById,
};
