import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField } from "@mui/material";
import { z } from "zod";

import { Dialog, Box } from "@shared/UIKit";

const projectNameFormSchema = z.object({
  projectName: z.string().min(1, "VALIDATIONS.PROJECT_NAME.REQUIRED"),
});

function RenameProjectDialog({ projectName, cancel, answer }) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    resolver: zodResolver(projectNameFormSchema),
    defaultValues: {
      projectName,
    },
  });

  const onSubmit = ({ projectName }) => {
    answer(projectName);
  };

  const actions = (
    <Button
      disabled={!isValid}
      type="submit"
      form="rename-project-form"
      color="primary"
      variant="contained"
      data-testid="confirmation-dialog-confirm"
    >
      {t("PROJECT.RENAMEPROJECT.RENAME")}
    </Button>
  );
  return (
    <Dialog
      cancel={cancel}
      open
      title={t("PROJECT.RENAMEPROJECT.HEADLINE")}
      maxWidth="xs"
      actions={actions}
      hideBackdrop={false}
      disableBackdropClick
      data-testid="rename-project-dialog"
    >
      <Box
        component="form"
        id="rename-project-form"
        m={2.5}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          control={control}
          name="projectName"
          render={({ field }) => (
            <TextField
              {...field}
              id="rename-project-input"
              inputProps={{
                "data-testid": "rename-project-input",
              }}
              autoFocus
              error={Boolean(errors.projectName)}
              helperText={t(errors.projectName?.message)}
            />
          )}
        />
      </Box>
    </Dialog>
  );
}

RenameProjectDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
};

export default RenameProjectDialog;
