import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";

import { instance as analytics } from "@shared/services/analytics";
const PAGE = "Plan limit component";

function ChangePlans({ teamId, adaptedPlanName }) {
  const { t } = useTranslation();
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { page: PAGE, teamId });
  const handleEnterpriseLink = () => {
    analytics.track(
      analytics.ACTION.OPENED,
      `${analytics.CATEGORY.PLAN_LIMITS_COMPONENT}-${analytics.LABEL.CHAT_TO_UPGRADE}`
    );
  };

  return adaptedPlanName === "Enterprise-legacy" ? (
    <Button
      variant="outlined"
      color="primaryV2"
      className="launch-intercom-chat"
      onClick={handleEnterpriseLink}
      sx={{ textOverflow: "ellipsis", flexWrap: "nowrap" }}
    >
      {t("STAT.BUTTON")}
    </Button>
  ) : (
    <Button
      variant="outlined"
      color="primaryV2"
      onClick={openDialog}
      sx={{ textOverflow: "ellipsis", flexWrap: "nowrap" }}
    >
      {t("CORE.SEE_PLANS")}
    </Button>
  );
}

ChangePlans.propTypes = {
  teamId: PropTypes.string.isRequired,
  adaptedPlanName: PropTypes.string.isRequired,
};

export default ChangePlans;
