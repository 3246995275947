/* eslint complexity:0 */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Link as ReactLink } from "react-router-dom";

import FileGallery from "@feedback/components/FileGallery/OldFileGallery";
import FileVersionInfo from "@feedback/components/FileVersionInfo/FileVersionInfo";
import CompareReviewsButton from "@feedback/components/NavigationWorkspace/CompareReviewsButton/CompareReviewsButton";
import NewReviewAlert from "@feedback/components/NavigationWorkspace/NewReviewAlert/NewReviewAlert";
import PoweredBy from "@feedback/components/NavigationWorkspace/PoweredBy/PoweredBy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ButtonGroup, Divider, IconButton, Link, Box } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import HelpMenu from "@supporting/components/HelpMenu/HelpMenu";
import NotificationBellIcon from "@supporting/components/NotificationBellIcon/NotificationBellIcon";
import NotificationCenter from "@supporting/components/NotificationCenter/NotificationCenter";
import TeamLogo from "@supporting/components/TeamLogo/TeamLogo";
import useActiveUser from "@supporting/hooks/useActiveUser";
import notificationCenterService from "@supporting/services/notificationCenterService";
import PendingReviews from "@workflow/components/PendingReviews";
import PendingReviewsNavButton from "@workflow/components/PendingReviewsNavButton/PendingReviewsNavButton";
import QuickSearchButton from "@workflow/components/QuickSearchButton/QuickSearchButton";
import WorkspaceButton from "@workflow/components/UploadFileButton/VariantButton/WorkspaceButton/WorkspaceButton";
import useUploadPanelDialog from "@workflow/components/UploadPanelDialog/useUploadPanelDialog";
import useProjectSteps from "@workflow/hooks/useProjectSteps";
import classnames from "classnames";

import { ShareLink, Text, Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import { useMediaQuery } from "@shared/hooks";
import { fileProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import VersionReviewsPanel from "../VersionReviewsPanel/VersionReviewsPanel";
import BatchReviewButton from "./BatchReviewButton/BatchReviewButton";
import FileViewMoreMenu from "./FileViewMoreMenu/FileViewMoreMenu";
import NavBar from "./NavBar";
import cls from "./NavigationWorkspace.module.css";

const useStyles = makeStyles((theme) => {
  return {
    Divider: {
      height: 20,
      borderColor: theme.color.gray[300],
    },
    MemberTeamLogo: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});

function NavBarDivider({ ...restProps }) {
  const classes = useStyles();

  return (
    <Box {...restProps}>
      <Divider orientation="vertical" className={classes.Divider} />
    </Box>
  );
}

function NavigationWorkspace({
  isGuestSession,
  project,
  team,
  file,
  step,
  review,
  reviews,
  isBatchReviewPage,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const user = useActiveUser();
  const isfileViewRedesignEnabled =
    user?.settings?.earlyAccessFeatures?.fileViewRedesign;

  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  let version;
  if (file) {
    version = file.versions.find(
      (fileVersion) => fileVersion.id === review.versionId
    );
  }

  const projectId = project.id;
  const [steps] = useProjectSteps(projectId);

  const { openUploadPanelDialog } = useUploadPanelDialog({
    projectId,
    stepIds: [step.id],
    selectedFile: file,
    teamId: project.teamId,
    page: pagesEnum.FILE_VIEW,
    component: UPLOAD_COMPONENT_TYPE.NAVIGATION_WORKSPACE,
    className: classes.uploadFileButton,
  });

  function computeIsUploadButtonVisible() {
    const isProjectArchived = !project.isLocked && project.isArchived;
    return !isProjectArchived && project.permissions.uploadFiles;
  }

  const isUploadButtonVisible = computeIsUploadButtonVisible();

  const isAdminUser = team.permissions.canManageBilling;
  const isCompareVersionsEnabledForPlan =
    team.subscriptionLimits.isCompareVersionsEnabled;
  const isNotificationCenterEnabled = notificationCenterService.isEnabled();
  const canNavigateToDashboard = !isGuestSession;

  return (
    <>
      <NavBar>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          minHeight={mdDown ? 70 : 48}
          gap={2}
          sx={{ overflow: "hidden" }}
          flexGrow={Number(isBatchReviewPage)}
          pr={{ md: 2 }}
          {...(!isfileViewRedesignEnabled && {
            flexBasis: { md: 318, xl: 468 },
          })}
        >
          {!isGuestSession && (
            <IconButton
              aria-label="back"
              size="small"
              to={`/projects/${projectId}`}
              component={ReactLink}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          {!isfileViewRedesignEnabled && (
            <Link
              className={classnames({
                [classes.MemberTeamLogo]: !isGuestSession,
              })}
              to={
                isGuestSession
                  ? t("URLS.WEBSITE_SIDEBAR_CTA_REVIEWERS")
                  : `/projects/${projectId}`
              }
              target={isGuestSession ? "_blank" : "_self"}
              component={ReactLink}
            >
              <TeamLogo
                logoUrl={
                  team.subscriptionLimits.isBrandingEnabled
                    ? team.branding.logo?.retina?.url
                    : null
                }
              />
            </Link>
          )}
          {isBatchReviewPage && (
            <FileVersionInfo
              project={project}
              stepName={step.name}
              fileName={file?.name}
              version={version}
              canNavigateToDashboard={canNavigateToDashboard}
              isBatchReviewPage={isBatchReviewPage}
            />
          )}
        </Box>
        {!isBatchReviewPage && (
          <>
            <Box display="flex" gap={1} alignItems="center">
              {!mdDown && (
                <>
                  <CompareReviewsButton
                    reviews={reviews}
                    isAdminView={isAdminUser}
                    doesPlanIncludeFeature={isCompareVersionsEnabledForPlan}
                  />
                  {!isfileViewRedesignEnabled && (
                    <>
                      <NavBarDivider />
                      <FileGallery
                        projectId={project.id}
                        initialSections={project.sections}
                        step={step}
                        reviewId={review.id}
                      />
                    </>
                  )}
                  <NavBarDivider />
                  <BatchReviewButton step={step} reviewId={review?.id} />
                </>
              )}

              {mdDown && !isfileViewRedesignEnabled && (
                <BatchReviewButton step={step} reviewId={review?.id} />
              )}
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={isfileViewRedesignEnabled ? 0 : 2}
              flexGrow={1}
            >
              <Box maxWidth={lgDown ? 125 : 250}>
                <Tooltip
                  title={`${t("FILE.NAME", { fileName: file.name })}`}
                  placement="bottom"
                >
                  {version.websiteUrl ? (
                    <Link
                      display="block"
                      variant="textSm"
                      fontWeight="fontWeight.bold"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      color="grey.800"
                      target="_blank"
                      href={version.websiteUrl}
                    >
                      {file.name}
                    </Link>
                  ) : (
                    <Text
                      variant="textSm"
                      fontWeight="fontWeight.bold"
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {file.name}
                    </Text>
                  )}
                </Tooltip>
              </Box>
              {!mdDown && (
                <NavBarDivider
                  pl={
                    isfileViewRedesignEnabled
                      ? /* istanbul ignore next */ 1.5
                      : 0
                  }
                />
              )}

              {isfileViewRedesignEnabled ? (
                <VersionReviewsPanel
                  project={project}
                  steps={steps}
                  activeReview={review}
                  file={file}
                  canNavigateToDashboard={canNavigateToDashboard}
                />
              ) : (
                <ButtonGroup size="small">
                  <VersionReviewsPanel
                    project={project}
                    steps={steps}
                    activeReview={review}
                    file={file}
                    canNavigateToDashboard={canNavigateToDashboard}
                  />
                  {!mdDown && isUploadButtonVisible && (
                    <WorkspaceButton onClick={openUploadPanelDialog} />
                  )}
                </ButtonGroup>
              )}

              {lgUp && (
                <NewReviewAlert review={review} reviews={reviews} file={file} />
              )}
            </Box>
          </>
        )}
        <Box
          display="flex"
          flexDirection="row"
          gap={0.5}
          alignItems="center"
          justifyContent="flex-end"
        >
          {!mdDown && (
            <>
              <PendingReviewsNavButton squared />
              {!isBatchReviewPage && <NavBarDivider />}
            </>
          )}

          {!isBatchReviewPage && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                {mdDown && (
                  <ShareLink
                    stepId={review.stepId}
                    fileId={file.id}
                    reviewId={review?.id}
                    versionNumber={review?.number}
                    tooltipDirection="left"
                    iconButtonProps={{ size: "medium" }}
                  />
                )}
                {!isGuestSession && !lgDown && <QuickSearchButton />}
                {isNotificationCenterEnabled && (
                  <>
                    <NotificationBellIcon />
                    <NotificationCenter />
                  </>
                )}
                {mdDown && isfileViewRedesignEnabled ? (
                  <FileViewMoreMenu stepId={step.id} reviewId={review.id} />
                ) : (
                  <HelpMenu
                    showSearch={!isGuestSession && lgDown}
                    isFileViewPage
                  />
                )}
              </Box>
              {isGuestSession && !mdDown && (
                <>
                  <NavBarDivider />
                  <PoweredBy />
                </>
              )}
            </>
          )}
        </Box>
      </NavBar>

      {!isBatchReviewPage && lgDown && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={56}
          mb="1px"
          bgcolor={theme.color.white}
          boxShadow={theme.shadow["box-shadow-navbar"]}
          className={cls.mobileAdditionalBar}
        >
          <NewReviewAlert review={review} reviews={reviews} file={file} />
        </Box>
      )}
      <PendingReviews />
    </>
  );
}

NavigationWorkspace.propTypes = {
  isGuestSession: PropTypes.bool.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  team: teamProp.isRequired,
  file: fileProp,
  step: stepProps.isRequired,
  review: reviewProp,
  reviews: PropTypes.arrayOf(reviewProp).isRequired,
  isBatchReviewPage: PropTypes.bool,
};

NavigationWorkspace.defaultProps = {
  file: null,
  review: null,
  isBatchReviewPage: false,
};

export default NavigationWorkspace;
