import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import { Button, Link, Box } from "@mui/material";
import RestartTrialPlanDialog from "@supporting/components/RestartTrialPlanDialog/RestartTrialPlanDialog";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import PremiumIcon from "@supporting/hooks/useGatedFeature/PremiumIcon";
import { instance as teamService } from "@supporting/services/team";

import { Image, Text, Dialog } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

function GatedModal({
  link,
  title,
  teamId,
  content,
  subtitle,
  teamName,
  isFreePlan,
  contentType,
  restartTrialAnalytics,
  selectPlanOpenAnalytics,
}) {
  const { remove, visible } = useModal();
  const { t } = useTranslation();

  const cancelDialog = useCallback(() => {
    remove();
  }, [remove]);
  const handleRestartTrial = async () => {
    /* istanbul ignore else */
    if (Object.keys(restartTrialAnalytics).length > 0) {
      analytics.trackV2({
        ...restartTrialAnalytics,
        name: teamName,
        teamId,
      });
    }
    if (
      await teamService.triggerTrialSubscription(
        teamId,
        t("RESTART_TRIAL.INTERCOM")
      )
    ) {
      NiceModal.show(RestartTrialPlanDialog, { teamId });
      cancelDialog();
    }
  };

  const handleSelectPlan = () => {
    /* istanbul ignore else */
    if (Object.keys(selectPlanOpenAnalytics).length > 0) {
      analytics.trackV2({
        ...selectPlanOpenAnalytics,
        name: teamName,
        teamId,
      });
    }
    NiceModal.show(SelectPlanDialog, { teamId });
  };

  let ContentComponent;
  switch (contentType) {
    case "video":
      ContentComponent = (
        <video //eslint-disable-line jsx-a11y/media-has-caption
          src={content}
          autoPlay
          loop
          width="100%"
          height="280px"
          data-testid="upgrade-steps-video"
        />
      );
      break;

    default:
      ContentComponent = (
        <Image
          src={content}
          alt="feature image"
          sx={{ maxHeight: 300, objectFit: "contain" }}
        />
      );
      break;
  }

  return (
    <Dialog
      fullWidth
      open={visible}
      title={t(title)}
      hideBackdrop={false}
      cancel={cancelDialog}
      useDefaultBackground={false}
      actions={
        <>
          {isFreePlan && (
            <Button
              size="large"
              color="primaryV2"
              variant="outlined"
              onClick={handleRestartTrial}
            >
              {t("RESTART_TRIAL.SECONDARY_CTA")}
            </Button>
          )}
          <Button
            size="large"
            color="primaryV2"
            variant="contained"
            onClick={handleSelectPlan}
          >
            {t("CORE.SEE_PLANS")}
          </Button>
        </>
      }
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <PremiumIcon
          sx={{
            padding: 0.625,
            position: "absolute",
            left: 8,
            top: 8,
          }}
        />
        {subtitle && (
          <Text mb={4}>
            <Trans
              i18nKey={subtitle}
              components={{
                anchor: <Link href={t(link)} target="_blank" color="primary" />,
              }}
            />
          </Text>
        )}
        {ContentComponent}
      </Box>
    </Dialog>
  );
}

GatedModal.propTypes = {
  link: PropTypes.string,
  subtitle: PropTypes.string,
  contentType: PropTypes.string,
  title: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  isFreePlan: PropTypes.bool.isRequired,
  restartTrialAnalytics: PropTypes.object,
  selectPlanOpenAnalytics: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
};

GatedModal.defaultProps = {
  link: "",
  subtitle: null,
  contentType: "image",
  restartTrialAnalytics: {},
  selectPlanOpenAnalytics: {},
};

export default NiceModal.create(GatedModal);
