import MARKER_CLAZZES from "@feedback/constants/markerClazzes";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

// NOTE: HOTFIX: Do not use events for instructions
const highlightMarker = function (marker, staticHighlight, reviewId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.HIGHLIGHTED,
    eventData: {
      marker,
      staticHighlight,
      reviewId,
    },
  });
};

// NOTE: HOTFIX: Do not use events for instructions
const unHighlightMarker = function (marker, staticHighlight, reviewId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.UNHIGHLIGHTED,
    eventData: {
      marker,
      staticHighlight,
      reviewId,
    },
  });
};

// NOTE: HOTFIX: Do not use events for instructions
const focusMarker = function (marker, reviewId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.FOCUSED,
    eventData: {
      marker,
      reviewId,
    },
  });
};

const onMarkerClicked = function (marker) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.CLICKED,
    eventData: {
      marker,
    },
  });
};

const onMarkerHover = function (marker) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.HOVERED,
    eventData: {
      marker,
    },
  });
};

const onMarkerLeave = function (marker) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.LEFT,
    eventData: {
      marker,
    },
  });
};

const onMarkerMoved = function (marker) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.MOVED,
    eventData: {
      marker,
    },
  });
};

/**
 * Fires a marker created event and passes the specific
 * marker data to the listeners, e.g. click of the position.
 *
 * @param  {Object} markerParams required parameter
 * @param  {String} reviewId required parameter
 */
const onMarkerCreated = function (markerParams, reviewId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.MARKER.CREATED,
    eventData: {
      reviewId,
      markerParams,
    },
  });
};

const getMarkerDataByName = function (marker, dataName) {
  if (!marker.data) {
    return null;
  }

  if (marker.data[dataName] === undefined || marker.data[dataName] === null) {
    return null;
  }

  return marker.data[dataName];
};

function getValueByPropertyName(obj, propertyName) {
  // look in all properties first.
  // eslint-disable-next-line no-prototype-builtins
  if (obj && obj.hasOwnProperty(propertyName)) {
    return obj[propertyName];
  }

  // scan deep into the object structure in order to find the property value
  let found;
  // eslint-disable-next-line id-length
  for (const k in obj) {
    if (!isObject(obj[k])) {
      continue;
    }
    found = getValueByPropertyName(obj[k], propertyName);
    if (found !== null) {
      return found;
    }
  }

  // if the value could not be found by property name, return null
  return null;
}

function compareByPropertyName(firstMarker, secondMarker, propertyName) {
  const firstCmp = getValueByPropertyName(firstMarker, propertyName);
  const secondCmp = getValueByPropertyName(secondMarker, propertyName);

  if (firstCmp === null || secondCmp === null) {
    return null;
  }

  if (firstCmp < secondCmp) {
    return -1;
  }
  if (firstCmp > secondCmp) {
    return 1;
  }
  return 0;
}

const compareMarker = function (firstMarker, secondMarker) {
  const sortCriteria = MARKER_CLAZZES[firstMarker.clazz].SORTER;

  // this and other are the same
  if (isEqual(firstMarker, secondMarker)) {
    return 0;
  }

  // sort for multiple criteria
  if (isArray(sortCriteria)) {
    let compareValue = 0;

    for (let index = 0; index < sortCriteria.length; index++) {
      compareValue = compareByPropertyName(
        firstMarker,
        secondMarker,
        sortCriteria[index]
      );
      // if objects are unequal, break, such that the result is propagated
      if (compareValue !== 0 || compareValue === null) {
        break;
      }
    }

    return compareValue;
  }

  // sort for single sort criteria
  return compareByPropertyName(firstMarker, secondMarker, sortCriteria);
};

export default {
  highlightMarker,
  unHighlightMarker,
  focusMarker,
  onMarkerClicked,
  onMarkerHover,
  onMarkerLeave,
  onMarkerMoved,
  onMarkerCreated,
  getMarkerDataByName,
  compareMarker,
  getValueByPropertyName,
  compareByPropertyName,
};
