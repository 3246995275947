import NiceModal from "@ebay/nice-modal-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import AddIcon from "@mui/icons-material/AddOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Menu,
  Button,
  Divider,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import AddOrRemoveProjectsDialog from "@supporting/components/AddOrRemoveProjects/AddOrRemoveProjectsDialog";

import { useQueryParams } from "@shared/hooks";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

const getAdditionalProjectsQuantity = (team) => {
  const priceItem = team.subscription.items.find(
    (item) => item.price.id === team.subscriptionLimits.additionalProjects
  );
  return priceItem?.quantity ?? 0;
};

function AddProjectsButton({ team }) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useQueryParams();
  const action = searchParams.get("action");
  const [anchorEl, setAnchorEl] = useState(null);
  const additionalProjectsQuantity = getAdditionalProjectsQuantity(team);

  const trackLinkClick = () => {
    window.Intercom && window.Intercom("showNewMessage");
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.PROJECT,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_INTEREST_BUTTON,
    });
  };
  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openAddProjectsDialog = () => {
    NiceModal.show(AddOrRemoveProjectsDialog, { variant: "ADD", team });
  };
  const handleProjectDelete = () => {
    NiceModal.show(AddOrRemoveProjectsDialog, { variant: "REMOVE", team });
    handleClose();
  };

  useEffect(() => {
    if (
      action === "add-projects" &&
      team.subscriptionLimits.maxProjectNumber > -1 &&
      team.subscription.additionalProjectsPrice &&
      team.subscription.status !== "trialing"
    ) {
      openAddProjectsDialog();
      setSearchParams({ action: null }, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (team.subscriptionLimits.maxProjectNumber === -1) {
    return null;
  }

  if (
    team.subscriptionLimits.maxProjectNumber > -1 &&
    team.subscription.additionalProjectsPrice &&
    team.subscription.status !== "trialing"
  ) {
    return (
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          size="small"
          variant="text"
          color="upgrade"
          startIcon={<AddIcon />}
          onClick={openAddProjectsDialog}
        >
          {t("PROJECT_LIMIT.SUBSCRIPTION.ADD_BUTTON")}
        </Button>
        {additionalProjectsQuantity > 0 && (
          <>
            <Divider orientation="vertical" flexItem py={1} />
            <IconButton
              size="small"
              onClick={handleMoreClick}
              aria-label="more-projects-options"
            >
              <MoreVertIcon />
            </IconButton>
          </>
        )}
        {anchorEl && (
          <Menu
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
          >
            <MenuItem onClick={handleProjectDelete}>
              <ListItemIcon>
                <CancelOutlinedIcon />
              </ListItemIcon>
              <ListItemText>
                {t("PROJECT_LIMIT.SUBSCRIPTION.REDUCE_PROJECT")}
              </ListItemText>
            </MenuItem>
          </Menu>
        )}
      </Box>
    );
  }
  return (
    <Button
      disableRipple
      size="small"
      variant="text"
      color="upgrade"
      onClick={trackLinkClick}
      sx={{ alignSelf: "end" }}
      startIcon={<ChatBubbleOutlineIcon />}
    >
      {t("TEAM.SUBSCRIPTION.ADD_MORE_PROJECTS.HELP")}
    </Button>
  );
}

AddProjectsButton.propTypes = {
  team: teamProp.isRequired,
};

export default AddProjectsButton;
