import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Link, Popover, SvgIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

const useStyles = makeStyles((theme) => ({
  banner: {
    marginBottom: 1.2,
    padding: theme.spacing(1, 1.9),
    background: `linear-gradient(135deg, ${theme.color.purple[100]} 25%, transparent 25%) -50px 0, linear-gradient(225deg, ${theme.color.purple[100]} 25%, transparent 25%) -50px 0, linear-gradient(315deg, ${theme.color.purple[100]} 25%, transparent 25%), linear-gradient(45deg, ${theme.color.purple[100]} 25%, transparent 25%)`,
    backgroundSize: "20px 20px",
    backgroundColor: theme.color.purple[50],
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(1.25, 2.25),
    },
  },
  bannerTitle: {
    fontSize: theme.typography.body2.fontSize,
    color: theme.color.gray[700],
    fontWeight: theme.typography.fontWeight.semiBold,
    lineHeight: theme.typography.lineHeight_1_3,
  },
  description: {
    fontSize: theme.typography.subtitle1.fontSize,
    color: theme.color.gray[500],
    lineHeight: theme.typography.body1.lineHeight,
  },
  lockedIcon: {
    width: 18,
    color: theme.color.gray[500],
    paddingLeft: 8,
    paddingRight: 3,
  },
  locked: {
    color: theme.color.gray[500],
    fontSize: theme.typography.subtitle1.fontSize,
    fontWeight: theme.typography.fontWeight.semiBold,
    cursor: "help",
  },
  lockedDescription: {
    fontSize: theme.typography.subtitle1.fontSize,
    textAlign: "center",
    lineHeight: theme.typography.subtitle1.lineHeight,
  },
  popoverAnchor: {
    pointerEvents: "none",
    width: 1000,
  },
}));

function ProjectTemplateBanner({ isLocked }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Box data-testid="project-template-banner" className={classes.banner}>
        <Box
          className={classes.bannerTitle}
          data-testid="project-template-banner-title"
          display="flex"
        >
          <Trans>PROJECT_TEMPLATES.BANNER.TITLE</Trans>

          {isLocked && (
            <Box
              className={classes.locked}
              display="flex"
              aria-owns={open ? "project-template-locked-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              data-testid="project-template-banner-locked"
            >
              <Box display="flex" classes={classes.popoverAnchor}>
                <SvgIcon
                  component={LockOutlinedIcon}
                  classes={{ root: classes.lockedIcon }}
                />
                <Trans>PROJECT_TEMPLATES.DEACTIVATED_LABEL</Trans>
              </Box>
            </Box>
          )}
        </Box>

        <Box className={classes.description}>
          {!isMobile && (
            <Box
              component="span"
              data-testid="project-template-banner-description"
            >
              <Trans>PROJECT_TEMPLATES.BANNER.DESCRIPTION</Trans>
            </Box>
          )}
          <Box component="span" px={0.25}>
            <Link
              href={t("URLS.FAQ_PROJECT_TEMPLATES")}
              target="_blank"
              color="accent"
              px={5}
            >
              <Trans>CORE.LEARN_MORE</Trans>
            </Link>
          </Box>
        </Box>
      </Box>
      <Popover
        data-testid="project-template-locked-popover"
        id="project-template-locked-popover"
        className={classes.popoverAnchor}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box p={1} m={1} className={classes.lockedDescription}>
          <Trans>PROJECT_TEMPLATES.DEACTIVATED_DESCRIPTION</Trans>
        </Box>
      </Popover>
    </>
  );
}

ProjectTemplateBanner.defaultProps = {
  isLocked: false,
};

ProjectTemplateBanner.propTypes = {
  isLocked: PropTypes.bool,
};

export default ProjectTemplateBanner;
