/* eslint react/forbid-elements:0 */
import { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Publish } from "@mui/icons-material";
import { Button, Card, CardHeader, CardContent } from "@mui/material";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { CustomBrandingGate } from "@supporting/hooks/useGatedFeature/featureGates";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Box, Text, Image, Tooltip } from "@shared/UIKit";
import UploadProgressBar from "@shared/UIKit/UploadProgressBar";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import toBytes from "@shared/helpers/converter";
import { useFileUploader } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

const LOGO_UPLOAD_CONFIG = Object.freeze({
  FILESIZE_MAX: 20,
  FILESIZE_UNIT: "MB",
});

function TeamBranding() {
  const team = useSelectedTeam();
  const { t } = useTranslation();

  const [selectedTeam, setSelectedTeam] = useState(team);
  const [logoUrl, setLogoUrl] = useState(null);
  const [uploadId, setUploadId] = useState("");
  const [showDefaultLogo, setShowDefaultLogo] = useState(true);
  const { checkAndProceed } = useGatedFeature({
    featureGate: CustomBrandingGate,
    teamId: team?._id,
    currentValue: team?.subscriptionLimits.isBrandingEnabled,
  });

  const updateTeam = useCallback(
    (newTeam) => {
      setShowDefaultLogo(
        !newTeam || !newTeam.branding || !newTeam.branding.logo
      );
      setSelectedTeam(newTeam);
      setLogoUrl(
        newTeam && newTeam.branding && newTeam.branding.logo
          ? newTeam.branding.logo.retina.url
          : null
      );
    },
    [setShowDefaultLogo, setSelectedTeam, setLogoUrl]
  );

  const onTeamChange = useCallback(
    ({ eventData }) => {
      const { team } = eventData;
      updateTeam(team);
    },
    [updateTeam]
  );

  const uploadCompleted = useCallback(
    (data) => {
      setUploadId(null);
      analytics.track(analytics.ACTION.UPLOADED_LOGO, analytics.CATEGORY.TEAM, {
        teamId: selectedTeam._id,
        teamName: selectedTeam.name,
      });
      const team = { ...selectedTeam, branding: data.event.branding };
      teamService.updateSelectedTeam(team);
    },
    [setUploadId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const uploadFailed = useCallback(() => {
    setUploadId(null);
    toastService.sendToast({
      title: "TEAM.BRANDING.SAVE_ERROR.TITLE",
      body: "TEAM.BRANDING.SAVE_ERROR.BODY",
      preset: toastService.PRESETS().WARNING,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const { addFile } = useFileUploader(
    [],
    uploadCompleted,
    uploadFailed,
    uploadFailed,
    websocket.EVENT_NAMES.TEAM.LOGO.UPDATE.SUCCEEDED,
    websocket.EVENT_NAMES.TEAM.LOGO.UPDATE.FAILED,
    EVENT_NAMES.UPLOAD.ERROR
  );

  const onDrop = useCallback(
    (attachedFiles, rejectedFiles) => {
      if (rejectedFiles.length > 0) {
        toastService.sendToast({
          title: "TEAM.BRANDING.EXCEEDS_SIZE.TITLE",
          body: "TEAM.BRANDING.EXCEEDS_SIZE.BODY",
          preset: toastService.PRESETS().WARNING,
          translationVariables: {
            body: {
              size: LOGO_UPLOAD_CONFIG.FILESIZE_MAX,
              unit: LOGO_UPLOAD_CONFIG.FILESIZE_UNIT,
            },
          },
        });
      }
      if (attachedFiles.length > 0) {
        const newFiles = addFile(attachedFiles, "TEAM_LOGO", {
          userId: authenticationService.fetchSession().userId,
          teamId: selectedTeam._id,
        });
        setUploadId(newFiles && newFiles[0] && newFiles[0].uploadId);
      }
    },
    [selectedTeam, addFile, setUploadId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: toBytes(
      LOGO_UPLOAD_CONFIG.FILESIZE_MAX,
      LOGO_UPLOAD_CONFIG.FILESIZE_UNIT
    ),
    accept: { "image/*": [] },
    useFsAccessApi: false,
  });

  useEffect(() => {
    updateTeam(teamService.getSelectedTeam());

    eventService.addListener(EVENT_NAMES.TEAM.SELECTED.UPDATED, onTeamChange);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.TEAM.SELECTED.UPDATED,
        onTeamChange
      );
    };
  }, [selectedTeam, logoUrl, updateTeam, onTeamChange]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card data-testid="team-branding-tab">
        <CardHeader title={t("TEAM.CARD_LOGO")} />
        <CardContent sx={{ position: "relative" }}>
          <Box
            data-testid="dropzone"
            direction="column"
            alignContent="center"
            alignItems="center"
            justify="center"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {uploadId && (
              <UploadProgressBar
                uploadId={uploadId}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            )}
            <Box position="absolute" right={10} top={-30}>
              <Tooltip
                placement="left"
                title={t("TEAM.BRANDING.BUTTON_UPLOAD_LOGO")}
                aria-label={t("TEAM.BRANDING.BUTTON_UPLOAD_LOGO")}
              >
                <Button
                  onClick={checkAndProceed(open)}
                  size="large"
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "50%",
                    padding: 1,
                    aspectRatio: "1/1",
                  }}
                >
                  <Publish />
                </Button>
              </Tooltip>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
            >
              {showDefaultLogo ? (
                <FilestageResponsiveLogo />
              ) : (
                <Image
                  data-testid="team-branding-logo"
                  alt="Logo"
                  src={logoUrl}
                />
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      {selectedTeam &&
        selectedTeam.subscriptionLimits &&
        selectedTeam.subscriptionLimits.isBrandingEnabled && (
          <Box pb={5} pt={2}>
            <Text color="text.secondary" textAlign="center" variant="body2">
              {t("TEAM.DESCRIPTION_BRANDING")}
            </Text>
          </Box>
        )}
    </>
  );
}

export default TeamBranding;
