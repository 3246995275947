export default Object.freeze({
  VIDEO: {
    STANDARD: "STANDARD",
    FRAME: "FRAME",
    TIMECODE: "TIMECODE",
    DEFAULT_FORMAT: "STANDARD",
  },
  DOCUMENT: {
    PAGE: "PAGE",
  },
});
