import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import useActiveUser from "@supporting/hooks/useActiveUser";
import authentication from "@supporting/services/authentication";

import { Box, Image, Text } from "@shared/UIKit";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import SharedLayout from "@shared/pages/SharedLayout";
import { instance as analytics } from "@shared/services/analytics";
import errorHandler from "@shared/services/errorHandler";

import Protected from "@assets/img/sprite-source/sprite--protected.png";

import {
  emailSubmitted,
  submitPassword,
  submitName,
} from "./authenticationActions";
import StepLoginAuthenticationForm from "./StepLoginAuthenticationForm";
import StepLoginGoogleAuth from "./StepLoginGoogleAuth";
import StepOTP from "./StepOTP";

const StepLogin = () => {
  const { stepId } = useParams();
  const navigate = useNavigate();
  const currentRouteName = useCurrentRouteName();
  const [submitError, setSubmitError] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useActiveUser();
  const email = searchParams.get("email");
  const guest = searchParams.get("guest") === "true";
  const external = searchParams.get("external") === "true";
  const otp = searchParams.get("otp") === "true";

  useEffect(() => {
    analytics.trackV2({
      category: analytics.CATEGORY.RESTRICTED_REVIEW_LINK,
      action: analytics.ACTION.OPENED,
    });
  }, []);

  const redirectToReview = () => {
    navigate(searchParams.get("redirect"));
  };

  const handleEmailNext = async (data) => {
    await emailSubmitted(
      data.userEmail,
      stepId,
      {
        onExternal: () => {
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            email: data.userEmail,
            external: true,
          });
        },
        onFullnameMissing: () => {
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            email: data.userEmail,
            guest: true,
          });
        },
        onGuestNotVerified: redirectToReview,
        onExistingUser: () => {
          setSearchParams({
            ...Object.fromEntries(searchParams.entries()),
            email: data.userEmail,
          });
        },
      },
      { checkVerified: true },
      searchParams.get("redirect"),
      getCurrentPage(currentRouteName)
    );
  };

  const handlePasswordNext = async (data) => {
    await submitPassword(
      email,
      data.userPassword,
      {
        onSuccess: redirectToReview,
        onIncorrectPassword: () => {
          setSubmitError("LOGIN.REVIEWER.PASSWORD.ERROR");
        },
        onError: () => {
          setSubmitError("ERROR.SERVER_NOT_AVAILABLE");
        },
      },
      getCurrentPage(currentRouteName)
    );
  };

  const handleNameNext = async (data) => {
    await submitName(email, stepId, data.userFullName, {
      onSuccess: redirectToReview,
    });
  };

  const handleSubmitOTP = async (otp) => {
    try {
      await authentication.verifyOTP(email, otp, true);
      analytics.trackV2({
        category: analytics.CATEGORY.EMAIL_VERIFICATION_FORM,
        action: analytics.ACTION.SUBMITTED,
        metaData: {
          page: getCurrentPage(currentRouteName),
        },
      });
      redirectToReview();
    } catch (error) {
      errorHandler.handleError(error);
    }
  };

  const handlersMap = {
    email: handleEmailNext,
    password: handlePasswordNext,
    fullName: handleNameNext,
  };

  let loginStep = "email";
  if (email) {
    loginStep = "password";
  }
  if (email && guest) {
    loginStep = "fullName";
  }

  return (
    <SharedLayout>
      <Box display="flex" alignItems="center" flexDirection="column" gap={6.75}>
        {otp ? (
          <StepOTP email={user.email} onOTPSubmit={handleSubmitOTP} />
        ) : (
          <>
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap={0.5}
            >
              <Image src={Protected} alt="Protected" />
              <Text
                component="h1"
                variant="text5xl"
                fontWeight="fontWeight.bold"
                translate="ACCESS_CONTROL.REGISTERED.TITLE"
              />
            </Box>
            {external ? (
              <StepLoginGoogleAuth
                email={email}
                redirect={searchParams.get("redirect")}
              />
            ) : (
              <StepLoginAuthenticationForm
                step={loginStep}
                email={email}
                onSubmit={handlersMap[loginStep]}
                submitError={submitError}
              />
            )}
          </>
        )}
      </Box>
    </SharedLayout>
  );
};

export default StepLogin;
