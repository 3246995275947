import PropTypes from "prop-types";

import {
  REVIEW_DECISION_STATES_TO_SETTING_KEYS,
  REVIEW_DECISION_STATES,
} from "@feedback/constants/reviewDecisionState";
import { Box } from "@mui/material";

import { Text } from "@shared/UIKit";

import ReviewDecisionOption from "./ReviewDecisionOption";

const ReviewDecisionOptionGroup = ({
  options,
  reviewDecisionSetting,
  toggleReviewDecisionOption,
  isDisabled,
  labelKey,
  icon,
}) => {
  return (
    <Box width={{ xs: "100%", sm: "50%" }}>
      <Box display="flex">
        <Text
          mb={1}
          mr={0.5}
          pl={1.35}
          variant="textXs"
          color="default-light.main"
          translate={labelKey}
        />

        {icon}
      </Box>

      <Box>
        {options.map((option) => (
          <ReviewDecisionOption
            key={option}
            state={option}
            isSelected={
              reviewDecisionSetting[
                REVIEW_DECISION_STATES_TO_SETTING_KEYS[option]
              ]
            }
            isDisabled={isDisabled}
            toggleSelect={() =>
              toggleReviewDecisionOption(
                REVIEW_DECISION_STATES_TO_SETTING_KEYS[option]
              )
            }
          />
        ))}
      </Box>
    </Box>
  );
};

ReviewDecisionOptionGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(REVIEW_DECISION_STATES))
  ).isRequired,
  reviewDecisionSetting: PropTypes.object.isRequired,
  toggleReviewDecisionOption: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  labelKey: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

ReviewDecisionOptionGroup.defaultProps = {
  isDisabled: false,
  icon: null,
};

export default ReviewDecisionOptionGroup;
