function generate() {
  const id = [];
  for (const randomNumber of window.crypto.getRandomValues(
    new Uint8Array(16)
  )) {
    id.push(randomNumber.toString(16).padStart(2, "0"));
  }
  return id.join("");
}

export default { generate };
