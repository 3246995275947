import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Box } from "@mui/material";
import OtpField from "@supporting/components/OtpField/OtpField";
import authenticationService from "@supporting/services/authentication";

import { Dialog, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

const OtpDialog = ({ email, cancel, answer, reviewer, isOpen, onError }) => {
  const { t } = useTranslation();

  const [code, setCode] = useState("");
  const [errorText, setErrorText] = useState("");
  const handleInputChange = (value) => {
    setCode(value);
    setErrorText("");
  };

  const submitDialog = async () => {
    try {
      await authenticationService.verifyOTP(email, code, reviewer);
      answer();
      if (reviewer) {
        analytics.track(
          analytics.ACTION.CONFIRMED,
          analytics.CATEGORY.EMAIL_VERIFICATION,
          {
            source: "reviewer-login",
          }
        );
      }
    } catch (error) {
      setErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCORRECT_CODE"));
      onError(error);
    }
  };

  const isDisabled = code.length !== 6;

  const cancelDialog = useCallback(() => {
    cancel();
  }, [cancel]);

  return (
    <Dialog
      cancel={cancelDialog}
      open={isOpen}
      maxWidth="xs"
      actions={
        <>
          <Button
            onClick={cancelDialog}
            variant="text"
            color="primaryV2"
            size="large"
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            onClick={submitDialog}
            color="primary"
            variant="contained"
            size="large"
            disabled={isDisabled}
          >
            {t("CORE.CONFIRM")}
          </Button>
        </>
      }
      title={t("AUTHENTICATION.DIALOGS.OTP.TITLE")}
      titleProps={{
        overflow: "hidden",
      }}
    >
      <>
        <Box px={5} py={1} mb={4}>
          <Text color="text.secondary" variant="body2">
            {t("AUTHENTICATION.DIALOGS.OTP.DESCRIPTION", { email })}
          </Text>
        </Box>
        <Box p={1} mb={1}>
          <OtpField
            code={code}
            onChange={handleInputChange}
            errorText={errorText}
            variant="outlined"
            required
          />
        </Box>
        <Box p={1} mb={1}>
          <Text color="text.secondary" variant="body2">
            {t("AUTHENTICATION.DIALOGS.OTP.HELP_TEXT")}
          </Text>
        </Box>
      </>
    </Dialog>
  );
};

OtpDialog.propTypes = {
  email: PropTypes.string,
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  reviewer: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onError: PropTypes.func,
};

OtpDialog.defaultProps = {
  email: "",
  reviewer: false,
  onError: () => {},
};

export default OtpDialog;
