/* istanbul ignore file */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Box, Fab, MenuItem, SvgIcon, TextField } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import userService from "@supporting/services/userService";

import { Dialog, Text } from "@shared/UIKit";

import { instance as localisationService } from "@shared/services/localisationService";

import EarlyAccess from "@supporting/pages/Settings/early-access/EarlyAccess";

import fsLogo from "@assets/img/icons/filestage-icon-small.svg";

const FsDevTools = () => {
  const { t } = useTranslation();
  const user = useActiveUser();
  const [isOpen, setIsOpen] = useState(false);

  const openTools = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const changeUserLanguage = (event) => {
    const lang = event.target.value;
    userService.update({ language: lang });
    localisationService.setLanguage(lang);
  };

  if (import.meta.env.MODE === "production") {
    return null;
  }
  return (
    <>
      <Fab
        color="success"
        onClick={openTools}
        sx={{ position: "absolute", bottom: 10, left: 10 }}
      >
        <SvgIcon component={fsLogo} />
      </Fab>
      <Dialog cancel={handleClose} isOpen={isOpen} title="Filestage dev tools">
        <Text>Some dev tools</Text>
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <TextField
            select
            fullWidth
            label={t("SETTINGS.LANGUAGE.TITLE")}
            onChange={changeUserLanguage}
            value={user.language}
          >
            <MenuItem value="en">{t("SETTINGS.LANGUAGE.ENGLISH")}</MenuItem>
            <MenuItem value="de">{t("SETTINGS.LANGUAGE.GERMAN")}</MenuItem>
            <MenuItem value="fr">{t("SETTINGS.LANGUAGE.FRENCH")}</MenuItem>
            <MenuItem value="es">{t("SETTINGS.LANGUAGE.SPANISH")}</MenuItem>
          </TextField>
        </Box>
        <EarlyAccess />
      </Dialog>
    </>
  );
};

export default FsDevTools;
