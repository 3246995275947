import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Check } from "@mui/icons-material";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import projectTemplateService from "@workflow/services/projectTemplateService";

import { Box, Dialog, Text } from "@shared/UIKit";

import errorHandlerService from "@shared/services/errorHandler";

import CreateProjectTemplateForm from "../CreateProjectTemplateForm/CreateProjectTemplateForm";

function AddProjectTemplateDialog({ projectId, cancel, answer }) {
  const team = useSelectedTeam();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const isCreatingFromProject = Boolean(projectId);
  const [isWaiting, setIsWaiting] = useState(false);

  const saveProjectTemplate = async (projectTemplateFormData) => {
    const { projectTemplateName } = projectTemplateFormData;
    setIsWaiting(true);

    try {
      const template = await projectTemplateService.addProjectTemplate({
        teamId: team._id,
        name: projectTemplateName,
        projectId,
      });

      answer(template);
    } catch (error) {
      errorHandlerService.handleError(error);
    } finally {
      setIsWaiting(false);
    }
  };

  const handleCancel = () => {
    cancel();
    setIsOpen(false);
  };

  return (
    <Dialog
      cancel={handleCancel}
      data-testid="add-project-template-dialog"
      title={t(
        isCreatingFromProject
          ? "PROJECT_TEMPLATES.ADD.HEADLINE_SAVE"
          : "PROJECT_TEMPLATES.ADD.HEADLINE_CREATE"
      )}
      open={isOpen}
      actions={
        <Button
          color="primary"
          type="submit"
          data-testid="add-project-template-button"
          variant="contained"
          form="create-project-template-form"
          disabled={isWaiting}
        >
          {t(
            isCreatingFromProject
              ? "PROJECT_TEMPLATES.ADD.SAVE"
              : "PROJECT_TEMPLATES.ADD.CREATE"
          )}
        </Button>
      }
      hideBackdrop={false}
      disableBackdropClick
    >
      <List>
        <ListItem>
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Text color="text.secondary">
              {t("PROJECT_TEMPLATES.ADD.DESCRIPTION-1")}
            </Text>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Check color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Text color="text.secondary">
              {t("PROJECT_TEMPLATES.ADD.DESCRIPTION-2")}
            </Text>
          </ListItemText>
        </ListItem>
      </List>
      <Box display="flex" alignSelf="stretch" pt={2}>
        <CreateProjectTemplateForm saveProjectTemplate={saveProjectTemplate} />
      </Box>
    </Dialog>
  );
}

AddProjectTemplateDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  projectId: PropTypes.string,
};

AddProjectTemplateDialog.defaultProps = {
  projectId: null,
};

export default AddProjectTemplateDialog;
