import NiceModal from "@ebay/nice-modal-react";
import { useEffect } from "react";

import TrialEndedDialog from "@supporting/components/TrialEndedDialog/TrialEndedDialog";
import { instance as billingUtilService } from "@supporting/services/billingUtilService";
import { instance as teamService } from "@supporting/services/team";

export default function useTrialEndedDialog(page) {
  const team = teamService.getSelectedTeam();

  useEffect(() => {
    if (!team) {
      return;
    }

    const showTrialEndedDialog =
      billingUtilService.shouldShowTrialEndedDialog(team);
    const isDialogAlreadyShown = localStorage.getItem(
      `trial-ended-${team._id}`
    );

    if (showTrialEndedDialog && !isDialogAlreadyShown) {
      NiceModal.show(TrialEndedDialog, { page });
    }
  }, [page, team]);
}
