import PropTypes from "prop-types";
import { memo } from "react";

import { Box } from "@mui/material";

import { Text } from "@shared/UIKit";

import CustomerLogo1 from "@assets/img/images/customer-logo-1.svg";
import CustomerLogo12 from "@assets/img/images/customer-logo-12.svg";
import CustomerLogo13 from "@assets/img/images/customer-logo-13.svg";
import CustomerLogo14 from "@assets/img/images/customer-logo-14.svg";
import CustomerLogo2 from "@assets/img/images/customer-logo-2.svg";
import CustomerLogo3 from "@assets/img/images/customer-logo-3.svg";
import CustomerLogo4 from "@assets/img/images/customer-logo-4.svg";
import CustomerLogo5 from "@assets/img/images/customer-logo-5.svg";
import CustomerLogo7 from "@assets/img/images/customer-logo-7.svg";
import CustomerLogo9 from "@assets/img/images/customer-logo-9.svg";

import SidePanelContent from "./SidePanelContent";

function SidePanel({ variant }) {
  return (
    <Box
      px={2}
      width="100%"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <Box minHeight={450}>
        <SidePanelContent variant={variant} />
      </Box>
      {variant !== "INVITE_TEAM_MEMBERS" && (
        <Box
          pb={1}
          gap={2}
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            color="grey.600"
            fontSize="textSm"
            textAlign="center"
            fontWeight="fontWeight.medium"
            translate="ONBOARDING_WIZARD.CUSTOMER_LOGOS.HEADLINE"
          />
          <Box
            gap={3}
            display="grid"
            alignItems="center"
            justifyItems="center"
            justifyContent="center"
            gridTemplateRows="repeat(2, 1fr)"
            gridTemplateColumns="repeat(5, 1fr)"
          >
            <CustomerLogo7 />
            <CustomerLogo3 />
            <CustomerLogo5 />
            <CustomerLogo4 />
            <CustomerLogo13 />
            <CustomerLogo12 />
            <CustomerLogo14 />
            <CustomerLogo9 />
            <CustomerLogo1 />
            <CustomerLogo2 />
          </Box>
        </Box>
      )}
    </Box>
  );
}

SidePanel.propTypes = {
  variant: PropTypes.string.isRequired,
};

export default memo(SidePanel);
