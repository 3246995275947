import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Dialog } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

import CreditCardForm from "../CreditCardForm/CreditCardForm";
import StripeProvider from "../CreditCardForm/StripeProvider";

function UpdateCreditCardDialog({
  updatePaymentList,
  creditCards,
  closeDialog,
  isOpen,
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      maxWidth={false}
      fullWidth
      cancel={closeDialog}
      open={isOpen}
      // required for 3D-Secure popups to work properly
      disableEnforceFocus
      hideBackdrop={false}
      disableBackdropClick
      useDefaultBackground
      PaperProps={{
        sx: {
          display: "flex",
          alignItems: "center",
          minWidth: "auto !important",
        },
      }}
    >
      <StripeProvider>
        <CreditCardForm
          defaultValues={{
            cardName: creditCards[0]?.name,
          }}
          submitButtonLabel={t("BILLING.CHANGE_PAYMENT_DETAILS.SAVE")}
          onSubmitSuccess={() => {
            analytics.track(
              analytics.ACTION.UPDATED_CREDIT_CARD,
              analytics.CATEGORY.SUBSCRIPTION
            );
            closeDialog();
            updatePaymentList();
          }}
        />
      </StripeProvider>
    </Dialog>
  );
}

UpdateCreditCardDialog.propTypes = {
  creditCards: PropTypes.array.isRequired,
  updatePaymentList: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
export default UpdateCreditCardDialog;
