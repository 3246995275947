import PropTypes from "prop-types";
import { memo } from "react";

import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Button } from "@mui/material";

import { Box, Tooltip } from "@shared/UIKit";

const VersionListDropDownButton = ({
  isOpen,
  toolTipText,
  hideDropDownIcon,
  selectedVersionNumber,
  toggleVersionsDropDown,
  shape,
}) => {
  const DropDownIcon = isOpen ? (
    <ArrowDropUp fontSize="small" data-testid="file-card-versions-open-arrow" />
  ) : (
    <ArrowDropDown
      fontSize="small"
      data-testid="file-card-versions-close-arrow"
    />
  );

  return (
    <Tooltip title={toolTipText} disabled={isOpen}>
      <Box component="span">
        <Button
          variant="highlight"
          color="default-light"
          size="extraSmall"
          shape={shape}
          className="tour__workspace-versions-dropdown"
          onClick={toggleVersionsDropDown}
          data-testid={`version-list-drop-down-button${
            hideDropDownIcon ? "-disabled" : ""
          }`}
          disabled={hideDropDownIcon}
          endIcon={DropDownIcon}
          sx={{ px: 0.5 }}
        >
          v{selectedVersionNumber}
        </Button>
      </Box>
    </Tooltip>
  );
};

VersionListDropDownButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toolTipText: PropTypes.string.isRequired,
  hideDropDownIcon: PropTypes.bool,
  toggleVersionsDropDown: PropTypes.func,
  selectedVersionNumber: PropTypes.number.isRequired,
  shape: PropTypes.oneOf(["rounded", "default"]),
};

VersionListDropDownButton.defaultProps = {
  hideDropDownIcon: false,
  toggleVersionsDropDown: null,
  shape: null,
};

export default memo(VersionListDropDownButton);
