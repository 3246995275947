import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import FeatureName from "./FeatureName";
import Features from "./Features";
import FeatureTooltip from "./FeatureTooltip";

function IntegrationFeatures({ team, isAppEnabled, openBillingDialog }) {
  const { t } = useTranslation();

  return (
    <Features title={t("BILLING.FEATURE.INTEGRATIONS")}>
      {isAppEnabled && (
        <FeatureName
          name="BILLING.FEATURE.ASANA.LABEL"
          isEnabled={team.subscriptionLimits.isStandardAppsEnabled}
          tooltip={
            <FeatureTooltip
              description={t("BILLING.FEATURE.ASANA.HELP_MESSAGE")}
              isEnabled={team.subscriptionLimits.isStandardAppsEnabled}
              getInTouchUpgrade="BILLING.FEATURE.GET_IN_TOUCH_WITH_US"
            />
          }
        />
      )}
      <FeatureName
        name="BILLING.FEATURE.CLOUD_STORAGE.LABEL"
        isEnabled={team.subscriptionLimits.areFilePickerPluginsEnabled}
        tooltip={
          <FeatureTooltip
            description={t("BILLING.FEATURE.CLOUD_STORAGE.HELP_MESSAGE.PART1")}
            linkHref={t("URLS.FAQ_UPLOAD_FILES")}
            linkText={t("BILLING.FEATURE.LEARN_MORE")}
            isEnabled={team.subscriptionLimits.isMemberRoleEnabled}
            upgradePlanLinkText={t("BILLING.SWITCH_TO_NEW_PLAN")}
            upgradePlanMessage2={t(
              "BILLING.FEATURE.CLOUD_STORAGE.HELP_MESSAGE.PART2"
            )}
            openBillingDialog={openBillingDialog}
          />
        }
      />
      <FeatureName
        name="BILLING.FEATURE.API_ACCESS.LABEL"
        isEnabled={team.subscriptionLimits.isApiEnabled}
        tooltip={
          <FeatureTooltip
            description={t("BILLING.FEATURE.API_ACCESS.HELP_MESSAGE.PART1")}
            isEnabled={team.subscriptionLimits.isApiEnabled}
            getInTouchUpgrade={t("BILLING.FEATURE.GET_IN_TOUCH_WITH_US")}
            upgradePlanMessage2={t(
              "BILLING.FEATURE.API_ACCESS.HELP_MESSAGE.PART2"
            )}
          />
        }
      />
    </Features>
  );
}

IntegrationFeatures.propTypes = {
  team: teamProp.isRequired,
  isAppEnabled: PropTypes.bool,
  openBillingDialog: PropTypes.func.isRequired,
};

IntegrationFeatures.defaultProps = {
  isAppEnabled: false,
};

export default IntegrationFeatures;
