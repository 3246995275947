import PropTypes from "prop-types";

import DashboardNavBar from "@shared/components/DashboardNavBar/DashboardNavBar";

// eslint-disable-next-line react/prop-types
const Dashboard = ({ children, isIntegrated }) => {
  return (
    <>
      {!isIntegrated && <DashboardNavBar className="navigation" />}
      {children}
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  isIntegrated: PropTypes.bool,
};

Dashboard.defaultProps = {
  isIntegrated: false,
};

export default Dashboard;
