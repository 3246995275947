import { useCallback, useState } from "react";

import {
  Button,
  Grid,
  Paper,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Title from "@supporting/components/Admin/Title";
import { EXTENDED_CURRENCIES } from "@supporting/constants/currencies";
import { instance as teamService } from "@supporting/services/team";

import { Text, Box } from "@shared/UIKit";

function ChangeTeamCurrency() {
  const [teamId, setTeamId] = useState("");
  const [currency, setCurrency] = useState(EXTENDED_CURRENCIES[0]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      await teamService.changeCurrency({ teamId, currency });
      setSuccessMessage(
        `Update currency ${currency} metadata for the team: ${teamId}`
      );
      setTeamId("");
      setIsLoading(false);
    } catch (response) {
      setIsLoading(false);
      setErrorMessage(
        response.status === 404 ? "Team doesn't exist" : response.message
      );
    }
  }, [teamId, currency]);

  const handleSubscriptionIdChange = (event) => {
    setTeamId(event.target.value);
    setSuccessMessage("");
    setErrorMessage("");
  };

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <>
      <Title>Change currency for team</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            sx={{
              display: "flex",
              overflow: "auto",
              flexDirection: "column",
              height: 240,
              padding: 5,
            }}
          >
            <Box display="flex" flexDirection="column" gap={2.5}>
              <Box
                display="flex"
                mt={2}
                maxWidth={400}
                alignItems="center"
                data-testid="form"
              >
                <FormControl sx={{ flex: 1 }}>
                  <InputLabel>Team ID</InputLabel>
                  <Input
                    value={teamId}
                    onChange={handleSubscriptionIdChange}
                    data-testid="subscription-id-form-input"
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, maxWidth: 100 }}>
                  <InputLabel id="currency">Currency</InputLabel>
                  <Select
                    labelId="currency"
                    id="currency-select"
                    data-testid="currency-select"
                    value={currency}
                    label="Currency"
                    onChange={handleCurrencyChange}
                  >
                    {EXTENDED_CURRENCIES.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box mt={2} maxWidth={400} width="100%">
                <Button
                  size="large"
                  type="success"
                  loading={isLoading}
                  variant="contained"
                  onClick={handleClick}
                  data-testid="form-submit"
                >
                  {isLoading ? "Updating currency..." : "Change currency"}
                </Button>
              </Box>
              {errorMessage && (
                <Box my={2} ml={5} data-testid="form-error">
                  <Text color="error.main">{errorMessage}</Text>
                </Box>
              )}
              {successMessage && (
                <Box my={2} ml={1} data-testid="form-success">
                  <Text color="success.main">{successMessage}</Text>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ChangeTeamCurrency;
