import { useCallback, useEffect, useState } from "react";

import GOOGLE_NATIVE_TYPES from "@supporting/constants/googleNativeFileTypes";
import toastService from "@supporting/services/toast";

import STORAGE from "@shared/constants/storage";
import { instance as logger } from "@shared/services/logger";

const constructDownloadUrl = (fileId, mimeType) => {
  const url = `https://www.googleapis.com/drive/v3/files/${fileId}`;
  return GOOGLE_NATIVE_TYPES.includes(mimeType)
    ? `${url}/export?mimeType=application/pdf`
    : `${url}?alt=media`;
};

/**
 * Use Google Drive hook
 *
 * @description This hook loads the Google Drive API into DOM and returns
 * the boolean values of the API loaded and the showing the file picker.
 * Usually being used on Upload Panel component.
 * @returns {{
 *  loaded: boolean,
 *  showFilePicker: boolean,
 * }} Returns the `loaded` and `showFilePicker` boolean values.
 */
export default function useGoogleDrive() {
  const { config } = window.fs;
  const [loaded, setLoaded] = useState(false);

  const buildGoogleFile = useCallback((googleFile, oauthToken) => {
    const { google } = window;
    if (
      googleFile[google.picker.Document.MIME_TYPE].includes(
        "application/vnd.google-apps"
      )
    ) {
      logger.warn("shared:file", "google drive native format selected", {
        googleFile,
      });
    }
    return {
      isRemote: true,
      name: googleFile[google.picker.Document.NAME],
      type: googleFile[google.picker.Document.MIME_TYPE],
      size: parseInt(googleFile.size, 10),
      url: constructDownloadUrl(
        googleFile[google.picker.Document.ID],
        googleFile[google.picker.Document.MIME_TYPE]
      ),
      headers: { Authorization: `Bearer ${oauthToken}` },
      storage: STORAGE.GOOGLE_DRIVE,
    };
  }, []);

  function handleGoogleError(error) {
    logger.error("helpers:google", "unable to load google", { error });
    toastService.sendToast({
      title: "ERROR.GOOGLE_DRIVE_UNAVAILABLE.TITLE",
      body: "ERROR.GOOGLE_DRIVE_UNAVAILABLE.BODY",
      preset: toastService.PRESETS().ERROR,
    });
    setLoaded(false);
  }

  const showFilePicker = useCallback(
    (callback, enableMultiSelect) => {
      const { gapi, google } = window;

      function authorizeAndShowPicker() {
        google.tokenClient.callback = (response) => {
          if (response.error !== undefined) {
            handleGoogleError(response.error);
          } else {
            google.accessToken = response.access_token;
          }
          showPicker();
        };

        if (google.accessToken === null) {
          google.tokenClient.requestAccessToken({ prompt: "consent" });
        } else {
          google.tokenClient.requestAccessToken({ prompt: "" });
        }
      }

      async function pickerCallback(data) {
        if (
          data[google.picker.Response.ACTION] === google.picker.Action.PICKED
        ) {
          const batch = gapi.client.newBatch();

          data[google.picker.Response.DOCUMENTS].forEach((googleFile) =>
            batch.add(
              gapi.client.drive.files.get({
                fileId: googleFile[google.picker.Document.ID],
                fields: "size",
                supportsAllDrives: true,
              }),
              {
                id: googleFile[google.picker.Document.ID],
              }
            )
          );

          const sizeRequests = await batch;

          const files = data[google.picker.Response.DOCUMENTS].map(
            (googleFile) => {
              googleFile.size =
                sizeRequests.result[
                  googleFile[google.picker.Document.ID]
                ].result.size;
              return buildGoogleFile(googleFile, google.accessToken);
            }
          );

          callback(files);
        }
      }

      function showPicker() {
        const docsView = new google.picker.DocsView();
        docsView.setIncludeFolders(true);

        const pickerBuilder = new google.picker.PickerBuilder();
        pickerBuilder.addView(docsView);
        pickerBuilder.enableFeature(google.picker.Feature.SUPPORT_DRIVES);
        pickerBuilder.addView(google.picker.ViewId.RECENTLY_PICKED);
        pickerBuilder.setDeveloperKey(config.google.driveApiKey);
        pickerBuilder.setOAuthToken(google.accessToken);
        pickerBuilder.setCallback(pickerCallback);

        if (enableMultiSelect) {
          pickerBuilder.enableFeature(
            google.picker.Feature.MULTISELECT_ENABLED
          );
        }

        pickerBuilder.build().setVisible(true);
        // Set z-index to make sure the picker is on top of the dialog
        const elements = document.getElementsByClassName("picker-dialog");
        for (let el = 0; el < elements.length; el++) {
          /* istanbul ignore next */
          elements[el].style.zIndex = "9999";
        }
      }

      authorizeAndShowPicker();
    },
    [buildGoogleFile, config]
  );

  useEffect(() => {
    async function init() {
      try {
        await window.gapi.client.load(
          "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
        );
        window.google.tokenClient =
          window.google.accounts.oauth2.initTokenClient({
            client_id: config.google.clientID,
            scope: "https://www.googleapis.com/auth/drive.readonly",
          });
        setLoaded(true);
      } catch (error) {
        handleGoogleError(error);
      }
    }

    function loadGoogle() {
      try {
        window.gapi.load("client:picker", init);
      } catch (error) {
        handleGoogleError(error);
      }
    }

    loadGoogle();
  }, [config, setLoaded]);

  return {
    loaded,
    showFilePicker,
  };
}
