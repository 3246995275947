import MEDIA_TYPES from "@shared/constants/mediaTypes";

const mediaTypeToExtensionMap = {
  [MEDIA_TYPES.WEBSITE]: "url",
  [MEDIA_TYPES.INTERACTIVE_HTML]: "html",
};

export const getFileExtension = function (version) {
  return (
    mediaTypeToExtensionMap[version.mediaType] || version.original.extension
  );
};
