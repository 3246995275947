import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";

import HomeIcon from "@mui/icons-material/Home";
import RedoIcon from "@mui/icons-material/Redo";
import { Box, Button } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { Text } from "@shared/UIKit";

import HelpResources from "@shared/components/HelpResources/HelpResources";
import { APP_ROUTES } from "@shared/constants/routes";

import AccessRequestHeading from "./AccessRequestHeading";

const RequestSent = ({ resendAccessRequest }) => {
  const { t } = useTranslation();
  const { stepId } = useParams();
  const [searchParams] = useSearchParams();
  const { session } = useActiveUser();
  const [isRequestResent, setIsRequestResent] = useState(false);

  const isGuest = session?.type === "Guest";

  const handleAccessRequest = async () => {
    const success = await resendAccessRequest(
      stepId,
      searchParams.get("reviewId")
    );
    if (success) {
      setIsRequestResent(true);
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        gap={6.75}
        mb={4}
      >
        <AccessRequestHeading
          title="ACCESS_CONTROL.REQUESTED.TITLE"
          subtitle="ACCESS_CONTROL.REQUESTED.CAPTION"
        />
        {!isGuest && (
          <Box display="flex" flexDirection="row" gap={3}>
            <Box position="relative">
              <Button
                color="primaryV2"
                variant="outlined"
                size="large"
                startIcon={<RedoIcon />}
                onClick={handleAccessRequest}
                sx={{ display: ["none", "flex"] }}
              >
                {t("Re-send request")}
              </Button>
              {isRequestResent && (
                <Text
                  variant="textMd"
                  color="success.main"
                  sx={{ position: "absolute", top: 50 }}
                >
                  {`${t("ACCESS_CONTROL.REQUESTED.TITLE")}!`}
                </Text>
              )}
            </Box>
            <Button
              color="primary"
              variant="contained"
              size="large"
              startIcon={<HomeIcon />}
              component={RouterLink}
              to={APP_ROUTES.DASHBOARD.path}
            >
              {t("ACCESS_CONTROL.REQUESTED.BUTTON_DASHBOARD")}
            </Button>
          </Box>
        )}
      </Box>
      <HelpResources />
    </>
  );
};

RequestSent.propTypes = {
  resendAccessRequest: PropTypes.func.isRequired,
};

export default RequestSent;
