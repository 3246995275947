import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import PendingReviewsCounter from "@workflow/components/PendingReviewsCounter/PendingReviewsCounter";

import { Box } from "@shared/UIKit";

import PendingReviewsContainer from "./PendingReviewsContainer";

function PendingReviewsNavButton() {
  const { t } = useTranslation();

  return (
    <PendingReviewsContainer>
      {({ pendingReviewsCount, ref, isLoading, onClick }) => (
        <Button
          m={0}
          p={6}
          color="default-light"
          variant="text"
          size="large"
          className="tour__pending-reviews-button"
          ref={ref}
          onClick={onClick}
          data-testid="open-pending-reviews-button"
          sx={{
            textWrap: "nowrap",
            ...(pendingReviewsCount > 0
              ? {
                  "&:hover": {
                    color: "var(--mui-color-orange-900)",
                    backgroundColor:
                      "rgba(var(--mui-palette-pending-darkChannel) / 0.1)",
                  },
                }
              : {}),
          }}
        >
          {t("PENDING_REVIEWS.NAVIGATION_BUTTON")}{" "}
          <Box ml={0.5}>
            <PendingReviewsCounter
              pendingReviewsCount={pendingReviewsCount}
              squared
              isLoading={isLoading}
            />
          </Box>
        </Button>
      )}
    </PendingReviewsContainer>
  );
}

export default PendingReviewsNavButton;
