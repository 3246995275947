import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { TEAM_KEYS } from "@shared/query-keys";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

const FAKE_NUMBERS = {
  AVERAGE_VERSIONS_PER_FILE: 7,
  ALL_REVIEWERS: 333,
};

export const useGetMetric = (teamId, metricType) => {
  const metricData = useQuery({
    queryKey: TEAM_KEYS.metric(teamId, metricType),
    queryFn: async () => {
      const response =
        FAKE_NUMBERS[metricType] ??
        (await backend.get(`/insights/${teamId}/${metricType}`));
      return response;
    },
  });

  useEffect(() => {
    if (metricData.error) {
      errorHandlerService.handleError(metricData.error);
    }
  }, [metricData.error]);

  return metricData;
};
