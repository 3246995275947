import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { AutomationFeatureGate } from "@supporting/hooks/useGatedFeature/featureGates";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import AutomationsButton from "@workflow/components/AutomationsButton/AutomationsButton";
import AutomationsDialog from "@workflow/components/AutomationsDialog/AutomationsDialog";
import HeaderSeparator from "@workflow/components/HeaderSeparator/HeaderSeparator";
import ProjectListMobile from "@workflow/components/ProjectListMobile/ProjectListMobile";
import projectTemplateService from "@workflow/services/projectTemplateService";

import { Box, ConfirmationDialog, EditableLabel } from "@shared/UIKit";

import validationUtils from "@shared/helpers/validationUtils";
import { useDialogState, useMediaQuery, useNavigation } from "@shared/hooks";
import { projectTemplateProps } from "@shared/props/project";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import ProjectTemplateMenu from "../ProjectTemplateMenu/ProjectTemplateMenu";

const useStyles = makeStyles(() => ({
  projectHeader: {
    padding: "12px 10px 10px 17px",
    minHeight: 60,
  },
  templateName: {
    variant: "h2",
    fontSize: 26,
  },
  editableLabel: { flexGrow: 1 },
}));

function ProjectTemplateHeader({
  projectTemplateId,
  projectTemplateName,
  isDisabled,
  projectTemplate,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { goTo, routeNames } = useNavigation();
  const { isOpen, openDialog, closeDialog } = useDialogState();
  const [isEditing, setIsEditing] = useState(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleChangeEditMode = (mode) => setIsEditing(mode);

  const automationsDialog = useDialogState();

  const currentUserTeam = useSelectedTeam();

  const { checkAndProceed, isLimitReached } = useGatedFeature({
    featureGate: AutomationFeatureGate,
    currentValue: projectTemplate.automations.length,
    teamId: currentUserTeam._id,
  });

  const handleUpdateProjectName = useCallback(
    async (projectName) => {
      try {
        await projectTemplateService.updateProjectTemplateName(
          projectTemplateId,
          projectName
        );

        analytics.track(
          analytics.ACTION.RENAMED,
          analytics.CATEGORY.PROJECT_TEMPLATE
        );
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [projectTemplateId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleTemplateDelete = useCallback(async () => {
    try {
      await projectTemplateService.removeProjectTemplate(projectTemplateId);
      goTo(routeNames.DASHBOARD);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [goTo, projectTemplateId, routeNames.DASHBOARD]);

  const automationsClickHandler = useCallback(() => {
    if (projectTemplate.automations.length === 0 && isLimitReached) {
      checkAndProceed()();
    } else {
      automationsDialog.openDialog();
    }
  }, [
    checkAndProceed,
    automationsDialog,
    projectTemplate.automations,
    isLimitReached,
  ]);

  useEffect(() => {
    setIsEditing(false);
  }, [projectTemplateName]);

  return (
    <Box component="header">
      <Box
        className={classes.projectHeader}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        boxSizing="border-box"
        data-testid="project-template-header"
      >
        {isMobile && <ProjectListMobile />}

        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "flex-start",
              marginTop: -0.5,
            }}
            data-testid="project-header-name-container"
          >
            <EditableLabel
              onAfterSave={handleUpdateProjectName}
              text={projectTemplateName}
              onBeforeSave={validationUtils.checkName}
              tooltipProps={{
                title: t("PROJECT.PROJECT.RENAME"),
                placement: "bottom",
                text: projectTemplateName,
              }}
              className={classes.editableLabel}
              classes={{
                input: classes.templateName,
                text: classes.templateName,
              }}
              disabled={isDisabled}
              isEditing={isEditing}
              onChangeEditMode={handleChangeEditMode}
            />
          </Box>
        )}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          {!isMobile && (
            <>
              <HeaderSeparator />
              <AutomationsButton
                project={projectTemplate}
                onClick={automationsClickHandler}
                steps={projectTemplate.steps}
              />
            </>
          )}

          {automationsDialog.isOpen && (
            <AutomationsDialog
              project={projectTemplate}
              closeDialog={automationsDialog.closeDialog}
              isOpen={automationsDialog.isOpen}
              canManageAutomations={
                currentUserTeam.permissions.canManageAutomations
              }
              steps={projectTemplate.steps}
              isTemplate
            />
          )}

          <ProjectTemplateMenu
            onDelete={openDialog}
            projectTemplate={projectTemplate}
            openAutomationsDialog={automationsClickHandler}
          />
        </Box>
      </Box>
      {isOpen && (
        <ConfirmationDialog
          isOpen
          title={t("PROJECT_TEMPLATES.REMOVE.HEADLINE")}
          description={t("PROJECT_TEMPLATES.REMOVE.DESCRIPTION")}
          cancelLabel={t("CORE.BACK")}
          confirmLabel={t("CORE.DELETE")}
          closeDialog={closeDialog}
          answer={handleTemplateDelete}
        />
      )}
    </Box>
  );
}

ProjectTemplateHeader.propTypes = {
  projectTemplateId: PropTypes.string.isRequired,
  projectTemplateName: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  projectTemplate: PropTypes.shape(projectTemplateProps).isRequired,
};

export default ProjectTemplateHeader;
