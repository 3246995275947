import { useCallback, useEffect, useRef, useState } from "react";

import reviewService from "@feedback/services/reviewService";
import useActiveUser from "@supporting/hooks/useActiveUser";
import { instance as fileService } from "@workflow/services/fileService";
import flatten from "lodash/flatten";

import shallowEqual from "@shared/helpers/shallowEqual";
import { useIsMounted } from "@shared/hooks";
import errorHandlerService from "@shared/services/errorHandler";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as logger } from "@shared/services/logger";
import { instance as websocket } from "@shared/services/websocket";

const selectUser = (user) => ({
  _id: user._id,
  selectedFileSortingOption: user.settings?.selectedFileSortingOption,
});
const useReviewsPagination = ({
  stepId: currentStepId,
  reviewId,
  initialSections,
  projectId,
}) => {
  const [reviews, setReviews] = useState([]);
  const [hasMore, setHasMore] = useState({
    previous: true,
    next: true,
  });
  const [sections, setSections] = useState(initialSections);
  const [pagesFetched, setPagesFetched] = useState({
    previous: 1,
    next: 1,
  });
  const [totalReviews, setTotalReviews] = useState(0);
  const activeUser = useActiveUser(selectUser, shallowEqual);
  const keepScrolledAt = useRef(null);
  const isMounted = useIsMounted();

  const loadMore = useCallback(
    (previous = false) =>
      async ({ event, previousPosition }) => {
        // ignoring next line because it would require to mock scrolling events in jest
        /* istanbul ignore next */
        const hasMoreItems = previous ? hasMore.previous : hasMore.next;
        const fromReview = reviews[previous ? 0 : reviews.length - 1];
        if (
          (!event && previousPosition) ||
          !hasMoreItems ||
          fromReview.stepId !== currentStepId
        ) {
          return;
        }
        try {
          const {
            reviews: loadedReviews,
            hasPrevious,
            hasNext,
          } = await reviewService.getStepReviews(
            currentStepId,
            fromReview.id,
            Number(previous),
            Number(!previous),
            activeUser.selectedFileSortingOption
          );
          if (loadedReviews.length > 1) {
            /* istanbul ignore next */
            if (previous) {
              keepScrolledAt.current = reviews[0];
            } else {
              keepScrolledAt.current = null;
            }
            setReviews((previousStateReviews) => {
              if (previous) {
                return loadedReviews.concat(previousStateReviews.slice(1));
              }
              return previousStateReviews.concat(loadedReviews.slice(1));
            });
            setPagesFetched((previousState) => ({
              ...previousState,
              ...(previous
                ? { previous: previousState.previous + 1 }
                : { next: previousState.next + 1 }),
            }));
            setHasMore((previousState) => ({
              ...previousState,
              ...(previous ? { previous: hasPrevious } : { next: hasNext }),
            }));
          }
        } catch (error) {
          errorHandlerService.handleError(error);
        }
      },
    [
      hasMore.previous,
      hasMore.next,
      currentStepId,
      reviews,
      activeUser.selectedFileSortingOption,
    ]
  );

  const fetchInitialReviews = useCallback(async () => {
    try {
      const [initialReviews, _totalReviews] = await Promise.all([
        reviewService.getStepReviews(
          currentStepId,
          reviewId,
          pagesFetched.previous,
          pagesFetched.next,
          activeUser.selectedFileSortingOption
        ),
        reviewService.countStepReviews(currentStepId),
      ]);
      if (isMounted()) {
        setReviews(initialReviews.reviews);
        setPagesFetched({
          previous: 1,
          next: 1,
        });
        setHasMore({
          previous: initialReviews.hasPrevious,
          next: initialReviews.hasNext,
        });
        setTotalReviews(_totalReviews);
      }
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [
    activeUser.selectedFileSortingOption,
    isMounted,
    pagesFetched.next,
    pagesFetched.previous,
    reviewId,
    currentStepId,
  ]);

  useEffect(() => {
    fetchInitialReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepId, activeUser._id]);

  useEffect(() => {
    const onFileRemoved = ({ fileIds }) => {
      setReviews((previousReviews) => {
        let currentReviews = previousReviews;
        fileIds.forEach((fileId) => {
          const updatedReviewIndex = previousReviews.findIndex(
            (review) => review.fileId === fileId
          );
          if (updatedReviewIndex > -1) {
            currentReviews = [
              // all reviews before the removed review
              ...previousReviews.slice(0, updatedReviewIndex),
              // all reviews after the removed review
              ...previousReviews.slice(updatedReviewIndex + 1),
            ];
          }
        });
        return currentReviews;
      });
    };

    const onProjectSectionRenamed = ({ name, sectionId }) => {
      setSections((previousSections) =>
        previousSections.map((section) =>
          section.id === sectionId ? { ...section, name } : section
        )
      );
    };
    const onProjectSectionCreated = ({
      projectId: websocketProjectId,
      sectionId,
      name,
    }) => {
      if (projectId === websocketProjectId) {
        setSections((previousSections) => [
          ...previousSections,
          { name, id: sectionId },
        ]);
      }
    };
    const onReviewRemoved = ({ reviewId }) => {
      setReviews((previousReviews) => {
        const updatedReviewIndex = previousReviews.findIndex(
          (review) => review.id === reviewId
        );
        if (updatedReviewIndex > -1) {
          return [
            // all reviews before the removed review
            ...previousReviews.slice(0, updatedReviewIndex),
            // all reviews after the removed review
            ...previousReviews.slice(updatedReviewIndex + 1),
          ];
        }
        return previousReviews;
      });
    };
    const onReviewDecisionsUpdated = ({ eventData }) => {
      setReviews((previousReviews) => {
        eventData.reviews.forEach(
          ({ id: reviewId, reviews: reviewDecisions, isPendingYourReview }) => {
            const updatedReviewIndex = previousReviews.findIndex(
              (review) => review.id === reviewId
            );
            if (updatedReviewIndex > -1) {
              previousReviews[updatedReviewIndex].reviews = reviewDecisions.map(
                ({ state, reviewer, date, digitallySigned }) => ({
                  state,
                  date,
                  digitallySigned,
                  reviewerId: reviewer?._id,
                })
              );
              previousReviews[updatedReviewIndex].isPendingYourReview =
                isPendingYourReview;
            }
          }
        );
        return [...previousReviews];
      });
    };

    websocket.addListener(websocket.EVENT_NAMES.FILE.REMOVED, onFileRemoved);
    websocket.addListener(
      websocket.EVENT_NAMES.PROJECT.SECTION.RENAMED,
      onProjectSectionRenamed
    );
    websocket.addListener(
      websocket.EVENT_NAMES.PROJECT.SECTION.CREATED,
      onProjectSectionCreated
    );
    websocket.addListener(
      websocket.EVENT_NAMES.REVIEW.REMOVED,
      onReviewRemoved
    );
    eventService.addListener(
      EVENT_NAMES.REVIEW.DECISIONS.UPDATED,
      onReviewDecisionsUpdated
    );

    return () => {
      websocket.removeListener(
        websocket.EVENT_NAMES.PROJECT.SECTION.RENAMED,
        onProjectSectionRenamed
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.FILE.REMOVED,
        onFileRemoved
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.PROJECT.SECTION.CREATED,
        onProjectSectionCreated
      );
      eventService.removeListener(
        EVENT_NAMES.REVIEW.DECISIONS.UPDATED,
        onReviewDecisionsUpdated
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.REVIEW.REMOVED,
        onReviewRemoved
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    const onReviewUpdated = async ({ fileId }) => {
      const loadedReviewIndex = reviews.findIndex(
        (review) => review.fileId === fileId
      );
      if (
        loadedReviewIndex > -1 &&
        reviews[loadedReviewIndex].stepId === currentStepId
      ) {
        const { reviews: updatedReview } = await reviewService.getStepReviews(
          currentStepId,
          reviews[loadedReviewIndex].id
        );
        setReviews((previousReviews) => [
          ...previousReviews.slice(0, loadedReviewIndex),
          updatedReview[0],
          ...previousReviews.slice(loadedReviewIndex + 1),
        ]);
      }
    };
    const onStepReviewerRemoved = ({ stepId, reviewerId }) => {
      if (stepId === currentStepId && reviewerId === activeUser._id) {
        fetchInitialReviews();
      }
    };
    const onStepReviewerAdded = ({ stepId, reviewerIds }) => {
      if (stepId === currentStepId) {
        const userIsReviewer = reviewerIds.find(
          (reviewerId) => reviewerId === activeUser._id
        );
        if (userIsReviewer) {
          fetchInitialReviews();
        }
      }
    };
    const onFileEvent = async ({ fileId }) => {
      const file = await fileService.getFile(fileId);
      /* istanbul ignore else */
      if (file) {
        const fileReviews = flatten(
          file.versions.map((version) => version.reviews)
        );
        const currentStepReviews = fileReviews.filter(
          (review) => review.stepId === currentStepId
        );
        if (currentStepReviews.length) {
          fetchInitialReviews();
        }
      } else {
        logger.error("file:gallery", "Websocket listener: File not found", {
          fileId,
        });
      }
    };

    const onSectionMoved = ({ sectionId }) => {
      const section = sections.find((section) => section.id === sectionId);
      if (section) {
        fetchInitialReviews();
      }
    };

    const onReviewStarted = ({ stepId }) => {
      if (stepId === currentStepId) {
        fetchInitialReviews();
      }
    };

    websocket.addListener(
      websocket.EVENT_NAMES.REVIEW.UPDATED,
      onReviewUpdated
    );
    websocket.addListener(
      websocket.EVENT_NAMES.STEP.REVIEWER.ADDED,
      onStepReviewerAdded
    );
    websocket.addListener(
      websocket.EVENT_NAMES.STEP.REVIEWER.REMOVED,
      onStepReviewerRemoved
    );
    websocket.addListener(websocket.EVENT_NAMES.FILE.UPDATED, onFileEvent);

    websocket.addListener(
      websocket.EVENT_NAMES.VERSION.UPLOAD.SUCCEEDED,
      onFileEvent
    );
    websocket.addListener(
      websocket.EVENT_NAMES.PROJECT.SECTION.MOVED,
      onSectionMoved
    );

    websocket.addListener(
      websocket.EVENT_NAMES.REVIEW.STARTED,
      onReviewStarted
    );

    return () => {
      websocket.removeListener(
        websocket.EVENT_NAMES.REVIEW.UPDATED,
        onReviewUpdated
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.STEP.REVIEWER.ADDED,
        onStepReviewerAdded
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.STEP.REVIEWER.REMOVED,
        onStepReviewerRemoved
      );
      websocket.removeListener(websocket.EVENT_NAMES.FILE.UPDATED, onFileEvent);
      websocket.removeListener(
        websocket.EVENT_NAMES.VERSION.UPLOAD.SUCCEEDED,
        onFileEvent
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.PROJECT.SECTION.MOVED,
        onSectionMoved
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.REVIEW.STARTED,
        onReviewStarted
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, reviews, activeUser._id, currentStepId, fetchInitialReviews]);

  return {
    sections,
    reviews,
    loadMore,
    keepScrolledAt,
    totalReviews,
  };
};

export default useReviewsPagination;
