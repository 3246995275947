import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { makeStyles } from "@mui/styles";
import { TEAM_PERMISSIONS, TEAM_ROLES } from "@supporting/constants/team";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const defaultRolePermissionsMap = {
  "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_PROJECTS": {
    admin: true,
    fullMember: true,
    liteMember: true,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.UPLOAD_NEW_FILES": {
    admin: true,
    fullMember: true,
    liteMember: true,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_TEAM": {
    admin: true,
    fullMember: false,
    liteMember: false,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_SUBSCRIPTION": {
    admin: true,
    fullMember: false,
    liteMember: false,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_BRANDING": {
    admin: true,
    fullMember: false,
    liteMember: false,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_TEMPLATES": {
    admin: true,
    fullMember: true,
    liteMember: false,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.USE_APPS": {
    admin: true,
    fullMember: false,
    liteMember: false,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_AUTOMATIONS": {
    admin: true,
    fullMember: true,
    liteMember: true,
  },
  "TEAM.ROLES.ROLE_TOOLTIP.VIEW_INSIGHTS": {
    admin: true,
    fullMember: false,
    liteMember: false,
  },
};

const getCustomPermissionIcon = (permissionCheck, classes) => {
  if (permissionCheck) {
    return <DoneIcon fontSize="small" className={classes.accessEnabled} />;
  }
  return <CloseIcon fontSize="small" className={classes.accessDisabled} />;
};

const customPermissions = {
  MANAGE_PROJECTS: "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_PROJECTS",
  UPLOAD_FILES: "TEAM.ROLES.ROLE_TOOLTIP.UPLOAD_NEW_FILES",
  MANAGE_TEAM: "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_TEAM",
  MANAGE_BILLING: "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_SUBSCRIPTION",
  MANAGE_BRANDING: "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_BRANDING",
  MANAGE_TEMPLATES: "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_TEMPLATES",
  USE_APPS: "TEAM.ROLES.ROLE_TOOLTIP.USE_APPS",
  MANAGE_AUTOMATIONS: "TEAM.ROLES.ROLE_TOOLTIP.MANAGE_AUTOMATIONS",
  VIEW_INSIGHTS: "TEAM.ROLES.ROLE_TOOLTIP.VIEW_INSIGHTS",
};

const useStyles = makeStyles((theme) => ({
  tooltipWrapper: {
    padding: "10px",
    "& div": {
      display: "flex",
      alignContent: "center",
    },
    "& svg": {
      marginTop: 1.5,
    },
  },
  accessEnabled: {
    color: theme.color.green[500],
    marginRight: "5px",
  },
  accessDisabled: {
    color: theme.color.red[300],
    marginRight: "5px",
  },
}));

const RoleTooltip = ({ role }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  switch (role.roleName) {
    case TEAM_ROLES.ADMIN:
      return (
        <Box className={classes.tooltipWrapper} data-testid="admin-tooltip">
          <Box>
            <DoneIcon fontSize="small" className={classes.accessEnabled} />
            <Text fontSize={FSTGTypography.fontSize_1_4}>
              {t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ALL_PROJECTS")}
            </Text>
          </Box>
          {Object.keys(defaultRolePermissionsMap).map((key) => (
            <Box key={key}>
              <DoneIcon fontSize="small" className={classes.accessEnabled} />
              <Text fontSize={FSTGTypography.fontSize_1_4}>{t(key)}</Text>
            </Box>
          ))}
        </Box>
      );
    case TEAM_ROLES.FULL_MEMBER:
      return (
        <Box
          className={classes.tooltipWrapper}
          data-testid="full-member-tooltip"
        >
          <Box>
            <DoneIcon fontSize="small" className={classes.accessEnabled} />
            <Text fontSize={FSTGTypography.fontSize_1_4}>
              {t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ALL_PROJECTS")}
            </Text>
          </Box>
          {Object.keys(defaultRolePermissionsMap).map((key) => (
            <Box key={key}>
              {defaultRolePermissionsMap[key].fullMember ? (
                <DoneIcon fontSize="small" className={classes.accessEnabled} />
              ) : (
                <CloseIcon
                  fontSize="small"
                  className={classes.accessDisabled}
                />
              )}
              <Text fontSize={FSTGTypography.fontSize_1_4}>{t(key)}</Text>
            </Box>
          ))}
        </Box>
      );
    case TEAM_ROLES.LITE_MEMBER:
      return (
        <Box
          className={classes.tooltipWrapper}
          data-testid="lite-member-tooltip"
        >
          <Box>
            <DoneIcon fontSize="small" className={classes.accessEnabled} />
            <Text fontSize={FSTGTypography.fontSize_1_4}>
              {t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ONLY_PROJECTS")}
            </Text>
          </Box>
          {Object.keys(defaultRolePermissionsMap).map((key) => (
            <Box key={key}>
              {defaultRolePermissionsMap[key].liteMember ? (
                <DoneIcon fontSize="small" className={classes.accessEnabled} />
              ) : (
                <CloseIcon
                  fontSize="small"
                  className={classes.accessDisabled}
                />
              )}
              <Text fontSize={FSTGTypography.fontSize_1_4}>{t(key)}</Text>
            </Box>
          ))}
        </Box>
      );
    default:
      return (
        <Box
          className={classes.tooltipWrapper}
          data-testid="custom-role-tooltip"
        >
          <Box>
            <DoneIcon fontSize="small" className={classes.accessEnabled} />
            <Text fontSize={FSTGTypography.fontSize_1_4}>
              {role.permissions[TEAM_PERMISSIONS.SEE_ALL_PROJECTS]
                ? t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ALL_PROJECTS")
                : t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ONLY_PROJECTS")}
            </Text>
          </Box>
          {Object.keys(customPermissions).map((permission) => (
            <Box key={permission}>
              {getCustomPermissionIcon(
                role.permissions[TEAM_PERMISSIONS[permission]],
                classes
              )}
              <Text fontSize={FSTGTypography.fontSize_1_4}>
                {t(customPermissions[permission])}
              </Text>
            </Box>
          ))}
        </Box>
      );
  }
};

RoleTooltip.propTypes = {
  role: PropTypes.object.isRequired,
};

export default RoleTooltip;
