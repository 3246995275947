import PropTypes from "prop-types";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import CouponDetails from "@supporting/components/CouponDetails/CouponDetails";
import BILLING_INTERVAL from "@supporting/constants/billingIntervals";
import { instance as billingService } from "@supporting/services/billingService";
import toastService from "@supporting/services/toast";

import { Dialog, Text, Tooltip } from "@shared/UIKit";

import { formatWithLocale } from "@shared/helpers/dateutils";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

export default function ConfirmSubscriptionDialog({
  team,
  cancel,
  couponData,
  currentPlan,
  isPlanDowngrade,
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const totalPrice = billingService.calculateTotalPrice({
    taxRate: team.subscription.taxRate,
    base: parseFloat(currentPlan.amount),
  });

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      healthMetrics.trackStart("supporting.billing");
      const updatedTeam = await billingService.activateSubscription(
        team._id,
        currentPlan.id
      );
      analytics.track(
        analytics.ACTION.CONFIRMED,
        analytics.CATEGORY.SUBSCRIPTION_PLAN_CHANGE,
        { selectedPlan: currentPlan.name }
      );
      toastService.sendToast({
        title: "BILLING.CONFIRM_SUBSCRIPTION.SUCCESS.TITLE",
        body: "BILLING.CONFIRM_SUBSCRIPTION.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            teamName: updatedTeam.name,
          },
        },
      });
      healthMetrics.trackSuccess("supporting.billing");
    } catch (error) {
      setIsLoading(false);
      healthMetrics.trackFailure("supporting.billing");
      if (error.status === 400 && error.message === "Card Error") {
        toastService.sendToast({
          title: "BILLING.CONFIRM_SUBSCRIPTION.ERROR.INVALID_CARD.TITLE",
          body: "BILLING.CONFIRM_SUBSCRIPTION.ERROR.INVALID_CARD.BODY",
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
      } else {
        errorHandlerService.handleError(error);
      }
    }
    cancel();
  };

  return (
    <Dialog
      isOpen
      hideBackdrop={false}
      disableBackdropClick
      cancel={cancel}
      actions={
        <>
          <Button
            size="large"
            variant="text"
            color="primaryV2"
            onClick={cancel}
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={onSubmit}
            loading={isLoading}
          >
            {t("BILLING.CONFIRM_SUBSCRIPTION.CONFIRM")}
          </Button>
        </>
      }
      title={t("BILLING.CONFIRM_SUBSCRIPTION.HEADLINE")}
    >
      <Box gap={2} display="flex" flexDirection="column">
        {isPlanDowngrade ? (
          <Text
            variant="h5"
            component="p"
            color="text.secondary"
            fontSize="subtitle1.fontSize"
            fontWeight="fontWeight.semiBold"
          >
            <Trans
              components={{
                bold: <b />, //eslint-disable-line react/forbid-elements
              }}
              values={{
                date: formatWithLocale(
                  team.subscription.currentPeriodEnd * 1000
                ),
              }}
            >
              PREVENT_DOWNGRADE.MODAL_BODY
            </Trans>
          </Text>
        ) : (
          <>
            <Text
              variant="h5"
              component="p"
              color="text.secondary"
              fontSize="subtitle1.fontSize"
              fontWeight="fontWeight.semiBold"
              translate="BILLING.CONFIRM_SUBSCRIPTION.DESCRIPTION_1"
            />
            <Text
              variant="h5"
              component="p"
              color="text.secondary"
              fontSize="subtitle1.fontSize"
              fontWeight="fontWeight.semiBold"
              translate="BILLING.CONFIRM_SUBSCRIPTION.DESCRIPTION_2"
            />
          </>
        )}
        <Box
          p={1}
          gap={2}
          display="flex"
          bgcolor="light.dark"
          flexDirection="column"
        >
          <Text
            variant="h5"
            fontSize="h5.fontSize"
            fontWeight="fontWeight.semiBold"
          >
            {currentPlan.name}
          </Text>
          <Text
            variant="h5"
            component="p"
            color="text.secondary"
            fontSize="subtitle1.fontSize"
            fontWeight="fontWeight.semiBold"
          >
            {t("BILLING.CHECKOUT.PAYING")}{" "}
            {`${billingService.getCurrencySymbol(
              currentPlan.currency
            )}${Math.round(totalPrice)} ${t(
              currentPlan.interval === BILLING_INTERVAL.MONTHLY
                ? "BILLING.CHECKOUT.PER_MONTH"
                : "BILLING.CHECKOUT.PER_YEAR"
            )}`}
          </Text>
          <Tooltip
            placement="top"
            aria-label="plan-details"
            title={
              <Box p={1} gap={1} display="flex" flexDirection="column">
                <Text
                  color="text.secondary"
                  sx={{
                    "&::before": {
                      content: '"✔"',
                      marginRight: 1,
                      color: "primary.main",
                    },
                  }}
                  fontSize="subtitle1.fontSize"
                  fontWeight="fontWeight.semiBold"
                >
                  <Trans
                    values={{
                      planName: currentPlan.name,
                      amount: currentPlan.amount / 100,
                      currency: billingService.getCurrencySymbol(
                        currentPlan.currency
                      ),
                    }}
                  >
                    BILLING.CONFIRM_SUBSCRIPTION.PLAN_PRICE
                  </Trans>
                </Text>
                {team.subscription.taxRate > 0 && (
                  <Text
                    color="text.secondary"
                    fontSize="subtitle1.fontSize"
                    fontWeight="fontWeight.semiBold"
                  >
                    <Trans
                      values={{
                        taxRate: team.subscription.taxRate,
                        taxName: t("BILLING.BREAKDOWN.TAX_NAME"),
                      }}
                    >
                      BILLING.BREAKDOWN.TAX
                    </Trans>
                  </Text>
                )}
              </Box>
            }
            variant="white"
          >
            <Box
              gap={0.5}
              display="flex"
              color="primary.main"
              alignItems="center"
              flexDirection="row"
              alignContent="center"
              justifyContent="center"
              sx={{
                textTransform: "uppercase",
                "&:hover": {
                  cursor: "help",
                },
              }}
            >
              <Text
                translate="BILLING.BREAKDOWN.LABEL"
                fontSize="subtitle2.fontSize"
                fontWeight="fontWeight.semiBold"
              />
              <InfoOutlined fontSize="small" />
            </Box>
          </Tooltip>
          <CouponDetails couponData={couponData} />
        </Box>
      </Box>
    </Dialog>
  );
}

ConfirmSubscriptionDialog.propTypes = {
  isPlanDowngrade: PropTypes.bool,
  cancel: PropTypes.func.isRequired,
  team: teamProp.isRequired,
  currentPlan: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    amount: PropTypes.number,
    interval: PropTypes.string,
    currency: PropTypes.string,
  }).isRequired,
  couponData: PropTypes.shape({
    isValid: PropTypes.bool,
    coupon: PropTypes.shape({
      id: PropTypes.string,
      currency: PropTypes.string,
      duration: PropTypes.string,
      amount_off: PropTypes.number,
      percent_off: PropTypes.number,
      duration_in_months: PropTypes.number,
    }),
  }),
};

ConfirmSubscriptionDialog.defaultProps = {
  couponData: {},
  isPlanDowngrade: false,
};
