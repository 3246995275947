/* jscpd:ignore-start */
const billingPlans = {
  free: {
    data: {},
    features: {
      main: {
        "SELECT_PLAN_DIALOG.UNLIMITED_FILES_REVIEWERS_VERSIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.UNLIMITED_FILES",
        },
        "SELECT_PLAN_DIALOG.PROJECTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.ACTIVE_PROJECTS",
          amount: 2,
        },
        "SELECT_PLAN_DIALOG.STORAGE.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.SECURE_STORAGE",
          amount: 2,
        },
        "SELECT_PLAN_DIALOG.REVIEW_STEPS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.REVIEW_STEPS",
        },
      },
      additional: {},
    },
    subtitleKey: "SELECT_PLAN_SELECT_PLAN_DIALOG.FREE.DESCRIPTION",
    pricingDescriptionKey: "SELECT_PLAN_DIALOG.UNLIMITED_TEAM_MEMBERS",
    title: "SELECT_PLAN_DIALOG.FREE.TITLE",
  },
  professional: {
    data: {},
    features: {
      main: {},
      additional: {
        "SELECT_PLAN_DIALOG.PROJECTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.ACTIVE_PROJECTS",
          amount: 25,
        },
        "SELECT_PLAN_DIALOG.STORAGE.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.SECURE_STORAGE",
          amount: 250,
        },
        "SELECT_PLAN_DIALOG.REVIEW_STEPS.TOOLTIP": {
          text: "PRICING.PRO.REVEIW_STEPS",
        },
        "SELECT_PLAN_DIALOG.SECTIONS.HELP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.SECTIONS",
          amount: 6,
        },
        "SELECT_PLAN_DIALOG.COMPARE_VERSIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.COMPARE_VERSIONS",
        },
        "SELECT_PLAN_DIALOG.CUSTOM_BRANDING.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.CUSTOM_BRANDING",
        },
        "SELECT_PLAN_DIALOG.EXTERNAL_UPLOAD.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.EXTERNAL_UPLOADS",
        },
        "SELECT_PLAN_DIALOG.REVIEW_REPORT.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.REVIEW_REPORT",
        },

        "SELECT_PLAN_DIALOG.COPY_COMMENTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.COPY_COMMENTS",
        },
        "SELECT_PLAN_DIALOG.MEMBER_ROLE.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.MEMBER_ROLE",
        },
        "SELECT_PLAN_DIALOG.AUTOMATIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.AUTOMATION",
        },
        "SELECT_PLAN_DIALOG.PROJECT_TEMPLATES.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.PROJECT_TEMPLATES",
        },
        "SELECT_PLAN_DIALOG.FEATURES.TEAM_ONLY_COMMENT_HELP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.TEAM_ONLY_COMMENTS",
        },
      },
    },
    subtitleKey: "SELECT_PLAN_DIALOG.PROFESSIONAL.DESCRIPTION",
    featureHeader: "SELECT_PLAN_DIALOG.EVERYTHING_IN_FREE",
    pricingDescriptionKey: "SELECT_PLAN_DIALOG.UNLIMITED_TEAM_MEMBERS",
    title: "PRICING.PRO.TITLE",
  },
};

export { billingPlans };
/* jscpd:ignore-end */
