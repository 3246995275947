import { useTranslation } from "react-i18next";
import { generatePath, Link as ReactLink } from "react-router-dom";

import { FEATURE_STATUS_ENUM } from "@integrations/constants";
import { OpenInNew } from "@mui/icons-material";
import SearchSharp from "@mui/icons-material/SearchSharp";
import { ButtonBase, SvgIcon } from "@mui/material";
import Chip from "@mui/material/Chip";

import { Box, Image, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { useDialogState } from "@shared/hooks";
import { integrationProps } from "@shared/props/integration";
import { instance as analytics } from "@shared/services/analytics";

import FireOutlinedIcon from "@assets/img/icons/fire_outlined_16px.svg";

import RegisterInterest from "./RegisterInterest/RegisterInterest";

function IntegrationCard({ integration }) {
  const { t } = useTranslation();
  const { isOpen, closeDialog, openDialog } = useDialogState();
  const integrationLinkProps = integration.hasDescriptionPage
    ? {
        to: generatePath(APP_ROUTES.INTEGRATIONS_DETAILS.path, {
          appId: integration.id,
        }),
        component: ReactLink,
      }
    : integration.href && { target: "_blank", href: integration.href };

  const openRegisterInterestDialog = () => {
    openDialog();
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.APP_MARKETPLACE.REGISTER_INTEREST_DIALOG,
      {
        appTitle: integration.title,
        page: "overview",
      }
    );
  };

  const badgeContent = {
    [FEATURE_STATUS_ENUM.UPCOMING]: {
      title: "CORE.REGISTER_INTEREST",
      icon: <SearchSharp />,
    },
    [FEATURE_STATUS_ENUM.COMING_SOON]: {
      title: "CORE.COMING_SOON",
      icon: <SvgIcon component={FireOutlinedIcon} viewBox="0 0 16 16" />,
    },
  };

  const badge = badgeContent[integration.featureStatus];

  const isIntegrationAvailable = [
    FEATURE_STATUS_ENUM.PUBLIC,
    FEATURE_STATUS_ENUM.EARLY,
  ].includes(integration.featureStatus);

  const handleClick = () => {
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.APP_MARKETPLACE.APPS,
      {
        appTitle: integration.title,
        page: "overview",
      }
    );

    if (integration.href) {
      return;
    }

    if (!isIntegrationAvailable) {
      openRegisterInterestDialog();
      return;
    }
  };

  return (
    <>
      <ButtonBase
        {...integrationLinkProps}
        onClick={handleClick}
        data-testid="integration-card"
        sx={{
          border: "1px solid var(--mui-palette-grey-200)",
          px: 1.5,
          py: 2,
          borderRadius: 1,
          maxHeight: 64,
          "&:hover": {
            border: "1px solid var(--mui-palette-grey-500)",
          },
        }}
      >
        {badge && (
          <Chip
            size="small"
            variant="filled-light"
            color={
              integration.featureStatus === FEATURE_STATUS_ENUM.UPCOMING
                ? "upgrade"
                : "error"
            }
            label={t(badge.title)}
            icon={badge.icon}
            sx={{
              "& .MuiChip-label": {
                fontWeight: "fontWeight.regular",
              },
              top: -12,
              right: 8,
              position: "absolute",
            }}
          />
        )}

        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" gap={1} alignItems="center">
            <Image
              width={32}
              height={32}
              alt={integration.title}
              src={integration.logoUrl}
            />
            <Box display="flex" alignItems="center">
              <Text variant="textSm" fontWeight="fontWeight.medium">
                {t("APPS.DIALOG.LETS_CONNECT", {
                  integrationName: integration.title,
                })}
              </Text>
              {integration.isNew && (
                <Chip
                  data-testid="new-badge"
                  color="accent"
                  variant="filled-light"
                  label={t("CORE.NEW")}
                  size="small"
                  sx={{
                    display: { xs: "none", md: "inherit" },
                    mx: 1,
                    fontWeight: "fontWeight.medium",
                  }}
                />
              )}
            </Box>
          </Box>
          {integration.href && (
            <OpenInNew data-testid="open-in-new-icon" color="primaryV2" />
          )}
        </Box>
      </ButtonBase>

      {isOpen && (
        <RegisterInterest integration={integration} closeDialog={closeDialog} />
      )}
    </>
  );
}

IntegrationCard.propTypes = {
  integration: integrationProps.isRequired,
};

export default IntegrationCard;
