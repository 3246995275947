import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import mondayAppService from "@integrations/services/mondayAppService";
import { Button } from "@mui/material";
import authenticationService from "@supporting/services/authentication";

import { Box, Text } from "@shared/UIKit";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import { APP_ROUTES } from "@shared/constants/routes";
import { useNavigation } from "@shared/hooks";
import pageTitleService from "@shared/services/pageTitleService";

function Embed() {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const [redirectParams, setRedirectParams] = useState(null);

  const location = useLocation();

  const isInFireFox = navigator.userAgent.includes("Firefox");

  const redirectUser = () => {
    let redirectUrl = window.fs.config.appBase;

    if (redirectParams?.state) {
      redirectUrl += redirectParams.state;
    }
    if (redirectParams?.params) {
      redirectUrl += `?${new URLSearchParams(redirectParams.params)}`;
    }

    window.location.assign(redirectUrl);
  };

  useEffect(() => {
    pageTitleService.setTitle(t("LOGIN.PAGE-TITLE"));

    const mondayFireFoxRedirectParams = sessionStorage.getItem(
      "monday-redirect-params"
    );

    const embedRedirectParams =
      location.state?.redirect ||
      (mondayFireFoxRedirectParams
        ? JSON.parse(mondayFireFoxRedirectParams)
        : false);

    if (embedRedirectParams) {
      sessionStorage.setItem(
        "monday-redirect-params",
        JSON.stringify(embedRedirectParams)
      );
    }
    setRedirectParams(embedRedirectParams);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchUser = async () => {
      try {
        sessionStorage.removeItem("monday-in-firefox");

        const { user } = await authenticationService.authenticate();
        if (user) {
          redirectUser();
        }
      } catch {
        // don't show errors to the user
      }
    };

    if (redirectParams !== null) {
      fetchUser();

      mondayAppService.addInstalledAnalytics(redirectParams?.params);
    }
  }, [redirectParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = useCallback(() => {
    const isInMondayApp = mondayAppService.checkIfInMondayApp();

    if (isInFireFox) {
      sessionStorage.setItem("monday-in-firefox", "yes");

      goTo("LOGIN");

      return;
    }

    window.setEmbedCsrfToken = (response) => {
      const csrfToken = response?.csrf;

      if (csrfToken) {
        localStorage.setItem("csrf", csrfToken);
      }
    };

    const loginWindow = window.open(
      `${window.fs.config.appBase}${APP_ROUTES.LOGIN.path}`,
      "_blank",
      "width=850,height=650"
    );

    window.onEmbedSuccessfulAuthentication = () => {
      redirectUser();

      loginWindow.close();

      window.onEmbedSuccessfulAuthentication = undefined;
    };

    window.customEmbedState = {
      auth_triggered_from_iframe: isInMondayApp ? "monday-app" : "true",
    };
  }, [redirectParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box display="flex" flexDirection="row" height="100vh" bgcolor="white">
      <Box width="100%">
        <Box alignItems="center" display="flex" flexDirection="column" mt={5}>
          <FilestageResponsiveLogo />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          marginTop={25}
          gap={1}
        >
          <Text variant="text3xl" fontWeight="fontWeight.medium">
            {t("EMBED_PAGE.WELCOME_TITLE")}
          </Text>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullwidth
            size="large"
            data-testid="embed-button"
            onClick={handleLogin}
          >
            {t("EMBED_PAGE.CONTINUE_BUTTON")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Embed;
