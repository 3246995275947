/**
/**
 * Gets the plugin that's currently the Filestage App.
 *
 * @description This function checks the localStorage API to detect the plugin
 * on which the app is running.
 * @param {string} pluginName - [optional] The name of the plugin to be checked for existence.
 * @returns {boolean|string} Returns a bool that check if the given pluginName exists or
 * returns the plugin that is currently runing the app.
 */

const getPlugin = (pluginName) => {
  const plugin = localStorage.getItem("plugin");

  if (pluginName) {
    return plugin === pluginName;
  }

  return plugin;
};

export default getPlugin;
