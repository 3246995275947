import { instance as logger } from "@shared/services/logger";

let location;

const licenceKey = window.fs.config.ipapi.token
  ? `?key=${window.fs.config.ipapi.token}`
  : "";

async function fetchLocationInformation() {
  const trace = new Error().stack;

  if (location) {
    logger.info("ipapi", "Successfully fetched location", {
      location,
      trace,
    });
    return Promise.resolve(location);
  }

  try {
    const response = await fetch(`https://ipapi.co/json/${licenceKey}`);
    location = await response.json();
    logger.info("ipapi", "Successfully fetched location", {
      location,
      trace,
    });
    return location;
  } catch (error) {
    logger.warn("ipapi", "failed to location information", {
      error,
    });
    return Promise.reject();
  }
}

export default { fetchLocationInformation };
