import PropTypes from "prop-types";
import { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Radio,
  SvgIcon,
} from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import userService from "@supporting/services/userService";
import { PROJECT_FILE_SORTING } from "@workflow/constants/project";
import sectionService from "@workflow/services/sectionService";

import { Box, Text } from "@shared/UIKit";

import { KEYBOARD_SHORTCUTS } from "@shared/constants/shortcutKey";
import { projectProps } from "@shared/props/project";
import { instance as analytics } from "@shared/services/analytics";

import CollapseIcon from "@assets/img/icons/collapse.svg";
import ExpandIcon from "@assets/img/icons/expand.svg";
import TunerIcon from "@assets/img/icons/tuner.svg";

const selectUserSortOption = (user) =>
  user?.settings?.selectedFileSortingOption ||
  PROJECT_FILE_SORTING.OPTIONS.NEWEST;

function StepHeadersMenu({ project }) {
  const sortOption = useActiveUser(selectUserSortOption);
  const { t } = useTranslation();
  const isAllExpand = project.sections.every((section) => !section.isCollapsed);

  const sortOptions = [
    PROJECT_FILE_SORTING.OPTIONS.NEWEST,
    PROJECT_FILE_SORTING.OPTIONS.FILE_NAME,
  ];
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCollapse = async () => {
    const isCollapsed = !project.sections.some(
      (section) => section.isCollapsed
    );
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.SECTION_SETTINGS[
        isCollapsed ? "COLLAPSE_ALL_SECTIONS" : "EXPAND_ALL_SECTIONS"
      ]
    );
    await sectionService.collapseAllSections(project.id, isCollapsed);
  };

  useHotkeys(KEYBOARD_SHORTCUTS.A, handleCollapse, [project.sections]);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSortOptionChange = async (option) => {
    if (option !== sortOption) {
      await userService.update({
        settings: { selectedFileSortingOption: option },
      });
      analytics.track(
        analytics.ACTION.CHANGED_SORTING,
        analytics.CATEGORY.PROJECT_FILES,
        {
          option,
        }
      );
      closeMenu();
    }
  };

  return (
    <Box>
      <IconButton
        size="small"
        aria-controls="sortMenu"
        aria-haspopup="true"
        onClick={openMenu}
        data-testid="sections-header-menu"
      >
        <SvgIcon component={TunerIcon} inheritViewBox fontSize="inherit" />
      </IconButton>
      <Menu
        id="sortMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        data-testid="sections-header-menu-options"
        MenuListProps={{
          subheader: <ListSubheader>{t("CORE.SORT_BY")}</ListSubheader>,
        }}
      >
        {sortOptions.map((value) => (
          <MenuItem
            key={value}
            onClick={() => handleSortOptionChange(value)}
            data-testid={`sections-header-menu-item-${value}`}
          >
            <ListItemIcon>
              <Radio color="primary" checked={sortOption === value} />
            </ListItemIcon>
            <ListItemText>
              {t(`PROJECT.FILE.SORTING_PANEL.SORTING_OPTIONS.${value}`)}
            </ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <MenuItem
          data-testid="sections-expand-collapse-button"
          onClick={() => {
            handleCollapse();
            closeMenu();
          }}
        >
          <ListItemIcon>
            {isAllExpand ? <CollapseIcon /> : <ExpandIcon />}
          </ListItemIcon>
          <ListItemText
            primary={t(
              isAllExpand
                ? "PROJECT.SORT_OPTIONS.COLLAPSE_ALL_SECTIONS"
                : "PROJECT.SORT_OPTIONS.EXPAND_ALL_SECTIONS"
            )}
          />
          <Text
            bgcolor="grey.200"
            borderRadius={1}
            fontSize="textSm.fontSize"
            fontWeight="fontWeight.semiBold"
            sx={{
              px: 1,
              mx: 0.5,
            }}
          >
            A
          </Text>
        </MenuItem>
      </Menu>
    </Box>
  );
}

StepHeadersMenu.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
};

export default StepHeadersMenu;
