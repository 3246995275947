import NiceModal from "@ebay/nice-modal-react";
import { useTranslation } from "react-i18next";

import { Check } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
} from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import noop from "lodash/noop";

import { Dialog, Text, Image } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { teamProp } from "@shared/props/team";

import StorageImage from "@assets/img/images/project-storage-limit.png";

function ProjectLockedDialog({ team, ...props }) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { teamId: team._id });

  const upgradeBenefits = [
    "BILLING.LIMIT_REACHED_DIALOG.PROJECT.BENEFIT1",
    "BILLING.LIMIT_REACHED_DIALOG.PROJECT.BENEFIT2",
  ];

  return (
    <Dialog
      cancel={noop}
      open
      maxWidth="lg"
      PaperProps={{ sx: { maxHeight: "70%" } }}
      data-testid="project-locked-dialog"
      title={t("BILLING.LIMIT_REACHED_DIALOG.PROJECT.HEADING")}
      content={
        <>
          <Image
            width={isMobile ? "100%" : "auto"}
            src={StorageImage}
            alt={t("BILLING.LIMIT_REACHED_DIALOG.PROJECT.HEADING")}
          />
          <List sx={{ overflowY: "visible" }}>
            {upgradeBenefits.map((benefit) => (
              <ListItem key={benefit} sx={{ marginTop: 0, marginRight: 0 }}>
                <ListItemIcon>
                  <Check color="primary" sx={{ marginRight: 5 }} />
                </ListItemIcon>
                <ListItemText>
                  <Text>{t(benefit)}</Text>
                </ListItemText>
              </ListItem>
            ))}
          </List>

          {!team.permissions.canManageBilling && (
            <Box
              bgcolor="grey.100"
              p={1.875}
              data-testid="project-locked-restricted-message"
            >
              <Text
                fontWeight="fontWeight.semiBold"
                color="grey.800"
                translate="BILLING.RESTRICTED_USER"
              />
            </Box>
          )}
        </>
      }
      actions={
        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={openDialog}
          data-testid="project-change-plan-button"
        >
          {t("BILLING.CHOOSE_PLAN")}
        </Button>
      }
      hideBackdrop={false}
      showCloseButton={false}
      disableBackdropClick
      {...props}
    />
  );
}

ProjectLockedDialog.propTypes = {
  team: teamProp,
};

ProjectLockedDialog.defaultProps = {
  team: null,
};

export default ProjectLockedDialog;
