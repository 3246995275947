import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";

import KeyIcon from "@mui/icons-material/Key";
import { Button, Chip } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { Avatar, Box, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import AccessRequestHeading from "./AccessRequestHeading";

const RequestAccess = ({ requestAccess }) => {
  const { t } = useTranslation();
  const { stepId } = useParams();
  const [searchParams] = useSearchParams();
  const user = useActiveUser();
  const currentRouteName = useCurrentRouteName();

  const handleAccessRequest = () => {
    requestAccess(
      stepId,
      searchParams.get("reviewId"),
      searchParams.get("fileId")
    );
  };

  useEffect(() => {
    analytics.trackV2({
      category: analytics.CATEGORY.ACCESS_DENIED_PAGE,
      action: analytics.ACTION.OPENED,
      metaData: {
        page: getCurrentPage(currentRouteName),
      },
    });
  }, [currentRouteName]);

  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={6.75}>
      <AccessRequestHeading
        title="ACCESS_CONTROL.REGISTERED_NO_ACCESS.TITLE"
        subtitle="ACCESS_CONTROL.EMAIL.CAPTION"
      />
      <Button
        color="primary"
        variant="contained"
        size="large"
        startIcon={<KeyIcon />}
        onClick={handleAccessRequest}
      >
        {t("ACCESS_CONTROL.EMAIL.BUTTON_REQUEST")}
      </Button>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={1}
      >
        <Text variant="textSm" color="text.secondary">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          You're signed in as
        </Text>
        <Chip
          label={user.email}
          variant="outlined"
          avatar={<Avatar user={user} />}
        />
        <Button
          color="upgrade"
          variant="text"
          size="small"
          to={APP_ROUTES.LOGOUT.path}
          component={RouterLink}
          onClick={() => {
            analytics.trackV2({
              category: analytics.CATEGORY.ACCESS_DENIED_PAGE,
              subCategory: analytics.SUB_CATEGORY.SIGN_INTO_ANOTHER_ACCOUNT,
              action: analytics.ACTION.CLICKED,
              metaData: {
                page: getCurrentPage(currentRouteName),
              },
            });
          }}
        >
          {t("ACCESS_CONTROL.EMAIL.BUTTON_SWITCH")}
        </Button>
      </Box>
    </Box>
  );
};

RequestAccess.propTypes = {
  requestAccess: PropTypes.func.isRequired,
};

export default RequestAccess;
