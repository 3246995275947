import NiceModal from "@ebay/nice-modal-react";
import { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import {
  CardContent,
  IconButton,
  Link,
  SvgIcon,
  Card,
  CardHeader,
} from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import UpgradeDialogContent from "@supporting/components/UpgradePlanDialog/UpgradeDialogContent";
import UpgradePlanDialog from "@supporting/components/UpgradePlanDialog/UpgradePlanDialog";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Toggle } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

function VerifyReviewerEmailSetting({ team }) {
  const { t } = useTranslation();

  const [checked] = useState(
    team.settings && team.settings.verifyReviewerEmailSetting
      ? team.settings.verifyReviewerEmailSetting
      : false
  );

  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const handleChange = async ({ currentTarget }) => {
    try {
      await teamService.updateTeamSettings({
        settings: {
          verifyReviewerEmailSetting: currentTarget.checked,
        },
      });

      const { ENABLED, DISABLED } = analytics.ACTION;
      const action = currentTarget.checked ? ENABLED : DISABLED;
      analytics.track(action, analytics.CATEGORY.TEAM_SETTINGS.VERIFY_REVIEWER);

      toastService.sendToast({
        title: "TEAM.EDIT.SETTING_SUCCESS.TITLE",
        body: "TEAM.EDIT.SETTING_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (err) {
      errorHandlerService.handleError(err);
    }
  };

  const onCancel = () => setIsUpgradePlanDialogOpen(false);
  const onUpgradePlanDialogOpen = () => {
    analytics.trackV2({
      action: analytics.ACTION.REACHED,
      category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
      subCategory: analytics.SUB_CATEGORY.VERIFY_REVIEWER,
      type: analytics.TYPE.FEATURE,
    });
    setIsUpgradePlanDialogOpen(true);
  };
  const onOpenSelectPlanDialog = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.VERIFY_REVIEWER,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_BUTTON,
    });
    NiceModal.show(SelectPlanDialog, { hideFree: true, teamId: team._id });
  };

  const isFeatureEnabled =
    team?.subscriptionLimits?.isVerifyReviewerEmailSettingEnabled;

  return (
    <>
      <Card>
        <CardHeader title={t("TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.TITLE")} />
        <CardContent sx={{ px: 6 }}>
          <Toggle
            label={
              <>
                {t("TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.SETTINGS_TOGGLE.TITLE")}{" "}
                {!isFeatureEnabled && (
                  <IconButton size="small" onClick={onUpgradePlanDialogOpen}>
                    <SvgIcon component={PremiumIcon} color="upgrade" />
                  </IconButton>
                )}
              </>
            }
            helpText={
              <Trans
                components={{
                  anchor: (
                    <Link
                      fontWeight="fontWeight.medium"
                      color="accent"
                      href={t(
                        "TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.SETTINGS_TOGGLE.DESCRIPTION.LINK"
                      )}
                      target="_blank"
                    />
                  ),
                }}
              >
                TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.SETTINGS_TOGGLE.DESCRIPTION.TEXT
              </Trans>
            }
            slotProps={{
              switch: {
                defaultChecked: checked,
                onChange: handleChange,
                disabled: !isFeatureEnabled,
              },
              formControlLabel: {
                onClick: () =>
                  !team?.subscriptionLimits
                    ?.isVerifyReviewerEmailSettingEnabled &&
                  onUpgradePlanDialogOpen(),
              },
            }}
          />
        </CardContent>
      </Card>
      <UpgradePlanDialog
        dialogTitle="TEAM.SETTINGS.VERIFY_REVIEWER_EMAIL.UPGRADE_CTA.HEADING"
        isUpgradePlanDialogOpen={isUpgradePlanDialogOpen}
        onCancel={onCancel}
      >
        <UpgradeDialogContent
          isAdminView={team.permissions.canManageBilling}
          variant="verifyReviewerEmailSetting"
          onCancel={onCancel}
          teamId={team._id}
          openBillingDialog={onOpenSelectPlanDialog}
        />
      </UpgradePlanDialog>
    </>
  );
}

VerifyReviewerEmailSetting.propTypes = {
  team: teamProp.isRequired,
};

export default VerifyReviewerEmailSetting;
