import { useState, useEffect } from "react";

import { TEAM_PERMISSIONS } from "@supporting/constants/team";
import { instance as teamService } from "@supporting/services/team";
import teamRoleService from "@supporting/services/teamRoleService";

import { Box } from "@shared/UIKit";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import CreateTeamRoleButton from "./CreateTeamRoleButton/CreateTeamRoleButton";
import TeamRolesTable from "./TeamRolesTable/TeamRolesTable";

function TeamRolesTab() {
  const [team, setTeam] = useState(teamService.getSelectedTeam());
  const [teamRoles, setTeamRoles] = useState([]);

  const ORDERED_PERMISSIONS = [
    TEAM_PERMISSIONS.SEE_ALL_PROJECTS,
    TEAM_PERMISSIONS.MANAGE_PROJECTS,
    TEAM_PERMISSIONS.UPLOAD_FILES,
    TEAM_PERMISSIONS.MANAGE_TEAM,
    TEAM_PERMISSIONS.MANAGE_BILLING,
    TEAM_PERMISSIONS.MANAGE_BRANDING,
    TEAM_PERMISSIONS.MANAGE_TEMPLATES,
    TEAM_PERMISSIONS.USE_APPS,
    TEAM_PERMISSIONS.MANAGE_AUTOMATIONS,
    TEAM_PERMISSIONS.VIEW_INSIGHTS,
  ];

  useEffect(() => {
    const updateTeamRoles = async (teamId) => {
      const newRoles = await teamRoleService.fetchTeamRoles(teamId);
      setTeamRoles(newRoles);
    };

    const onTeamRolesChanged = ({ eventData }) => {
      const newRoles = eventData.teamRoles;
      setTeamRoles(newRoles);
    };

    const onTeamChange = ({ eventData }) => {
      const newTeam = eventData.team;
      setTeam(newTeam);

      return updateTeamRoles(newTeam._id);
    };

    eventService.addListener(EVENT_NAMES.TEAM.SELECTED.UPDATED, onTeamChange);

    eventService.addListener(
      EVENT_NAMES.TEAM.ROLES.UPDATED,
      onTeamRolesChanged
    );

    updateTeamRoles(team._id);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.TEAM.SELECTED.UPDATED,
        onTeamChange
      );

      eventService.removeListener(
        EVENT_NAMES.TEAM.ROLES.UPDATED,
        onTeamRolesChanged
      );
    };
  }, [team]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        mb={2}
        data-testid="team-roles-tab"
      >
        {team.permissions.canRename && (
          <CreateTeamRoleButton
            team={team}
            permissions={ORDERED_PERMISSIONS}
            teamRoles={teamRoles}
          />
        )}
      </Box>

      <TeamRolesTable
        permissions={ORDERED_PERMISSIONS}
        teamRoles={teamRoles}
        team={team}
      />
    </>
  );
}

export default TeamRolesTab;
