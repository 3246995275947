export default Object.freeze({
  VIDEO: "VIDEO",
  IMAGE: "IMAGE",
  AUDIO: "AUDIO",
  DOCUMENT: "DOCUMENT",
  DOCUMENT_XOD: "DOCUMENT_XOD",
  IMAGE_XOD: "IMAGE_XOD",
  UNKNOWN: "UNKNOWN",
  WEBSITE: "WEBSITE",
  INTERACTIVE_HTML: "INTERACTIVE_HTML",
});
