import { Box } from "@mui/material";

import DeleteUserAccount from "./DeleteUserAccount";
import TermsAndPolicies from "./TermsAndPolicies";

function AccountTab() {
  return (
    <Box display="flex" flexDirection="column" gap={2} flex={1}>
      <TermsAndPolicies />
      <DeleteUserAccount />
    </Box>
  );
}

export default AccountTab;
