import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import freeEmailDomains from "free-email-domains";

import { Dialog, Box, Text } from "@shared/UIKit";

import useDialogState from "@shared/hooks/useDialogState";

import VerifyTeamDomainDialog from "./VerifyTeamDomainDialog";

function AddTeamDomainDialog({ isOpen, cancel, roles, domainNames, answer }) {
  const { t } = useTranslation();
  const user = authenticationService.fetchUser();
  const userDomain = user.email.split("@")[1].toLowerCase();
  const [addDomainDialogOpen, setAddDomainDialogOpen] = useState(isOpen);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [domainName, setDomainName] = useState(
    domainNames.length || freeEmailDomains.includes(userDomain)
      ? ""
      : userDomain
  );
  const [defaultRole, setDefaultRole] = useState(
    roles.find((role) => role.roleName === "FULL_MEMBER")?._id
  );
  const [errorText, setErrorText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isOpen: isVerifyTeamDomainDialogOpen,
    openDialog: openVerifyTeamDomainDialog,
    closeDialog,
  } = useDialogState();

  const cancelDialog = useCallback(() => {
    setAddDomainDialogOpen(false);
    cancel();
  }, [cancel]);

  useEffect(() => {
    setAddDomainDialogOpen(isOpen);
    setIsSubmitting(false);
  }, [isOpen]);

  const cancelVerifyTeamDomainDialog = useCallback(() => {
    closeDialog();
    cancelDialog();
  }, [closeDialog, cancelDialog]);

  const confirmDialog = () => {
    const newDomain = domainName.toLowerCase();
    setDomainName(newDomain);
    setIsSubmitting(true);
    if (userDomain !== newDomain) {
      openVerifyTeamDomainDialog();
      setAddDomainDialogOpen(false);

      return;
    }
    answer(newDomain, defaultRole);

    cancelDialog();
  };

  useEffect(() => {
    setDefaultRole(roles.find((role) => role.roleName === "FULL_MEMBER")?._id);
  }, [roles]);

  const getErrorMessage = (domainName) => {
    if (domainNames.includes(domainName)) {
      return "JOIN_WITH_SAME_DOMAIN.HINT_2";
    }

    if (freeEmailDomains.includes(domainName)) {
      return "JOIN_WITH_SAME_DOMAIN.MODAL_HELPER_TEXT";
    }

    return "";
  };

  const handleDefaultRoleChange = useCallback((event) => {
    setDefaultRole(event.target.value);
  }, []);

  const handleDomainInputChange = useCallback((event) => {
    const domainName = event.target.value;
    setDomainName(domainName);
    setErrorText(getErrorMessage(domainName.toLowerCase()));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOpenDefaultRoleMenu = () => {
    setIsMenuOpen(true);
  };

  const handleCloseDefaultRoleMenu = () => {
    setIsMenuOpen(false);
  };

  const isDisabled = Boolean(
    errorText.length || !domainName.length || isSubmitting
  );

  return (
    <>
      <Dialog
        isOpen={addDomainDialogOpen}
        title={t("JOIN_WITH_SAME_DOMAIN.ADD_DOMAIN")}
        actions={
          <Button
            color="primary"
            variant="contained"
            disabled={isDisabled}
            onClick={confirmDialog}
          >
            {t("CORE.CONTINUE")}
          </Button>
        }
        cancel={cancelDialog}
        hideBackdrop={false}
        disableBackdropClick
      >
        <Box gap={4} display="flex" textAlign="left" flexDirection="column">
          <Text
            variant="body2"
            color="text.secondary"
            translate="JOIN_WITH_SAME_DOMAIN.MODAL_BODY"
          />
          <TextField
            required
            autoFocus
            type="text"
            value={domainName}
            label={t("JOIN_WITH_SAME_DOMAIN.DOMAIN")}
            placeholder={t("JOIN_WITH_SAME_DOMAIN.PLACEHOLDER_TEXT")}
            onChange={handleDomainInputChange}
            InputLabelProps={{ required: false }}
            helperText={t(errorText)}
            error={Boolean(errorText.length)}
          />
          <FormControl>
            <InputLabel>{t("TEAM_INVITE_LINK.DEFAULT_ROLE")}</InputLabel>
            <Select
              value={defaultRole}
              onOpen={handleOpenDefaultRoleMenu}
              onClose={handleCloseDefaultRoleMenu}
              onChange={handleDefaultRoleChange}
              label={t("TEAM_INVITE_LINK.DEFAULT_ROLE")}
              IconComponent={() =>
                isMenuOpen ? <ExpandLess /> : <ExpandMore />
              }
            >
              {roles.map((role) => (
                <MenuItem key={role._id} value={role._id}>
                  {role.isDefault
                    ? t(`TEAM.ROLES.${role.roleName}`)
                    : role.roleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Dialog>
      <VerifyTeamDomainDialog
        isOpen={isVerifyTeamDomainDialogOpen}
        cancel={cancelVerifyTeamDomainDialog}
        domainName={domainName}
        defaultRole={defaultRole}
        answer={answer}
      />
    </>
  );
}

AddTeamDomainDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  domainNames: PropTypes.array.isRequired,
  answer: PropTypes.func.isRequired,
};

export default AddTeamDomainDialog;
