import { makeStyles, useTheme } from "@mui/styles";
import ReviewCardLoadingState from "@workflow/components/ReviewCardLoadingState/ReviewCardLoadingState";

import { Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  reviewCard: {
    "&::after": {
      content: "''",
      width: 1,
      height: 50,
      backgroundColor: theme.color.gray[200],
      display: "block",
      position: "absolute",
      right: 0,
      bottom: 10,
    },
  },
}));

function ProcessingReviewCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      width={284}
      height={68}
      display="flex"
      flexDirection="column"
      position="relative"
      borderBottom={`1px ${theme.color.gray[200]} solid`}
      backgroundColor={theme.color.white}
      className={classes.reviewCard}
      data-testid="review-card-processing"
    >
      <Box pt={1} pb={1.25} px={1.25} height="100%">
        <ReviewCardLoadingState />
      </Box>
    </Box>
  );
}

export default ProcessingReviewCard;
