import { generatePath } from "react-router-dom";

const GLOBAL_ROUTES = {
  ROOT: { analyticsName: "root", path: "/" },
  ACCESS_DENIED: {
    path: "/access-denied",
    analyticsName: "access-denied",
  },
  LINK_INVALID: {
    analyticsName: "link-invalid",
    path: "/link-invalid",
  },
  CONNECTION_INTERRUPTED: {
    analyticsName: "connection-interrupted",
    path: "/connection-interrupted",
    build() {
      return `${this.path}?${new URLSearchParams({
        url: location.href.replace(location.origin, ""),
      }).toString()}`;
    },
  },
};

const WORKFLOW_ROUTES = {
  DASHBOARD: {
    analyticsName: "dashboard_general",
    path: "/projects",
  },
  PROJECT_ID: {
    analyticsName: "dashboard_project_id",
    path: "/projects/:projectId",
  },
  BULK_DOWNLOAD: {
    analyticsName: "bulk-download",
    path: "/bulk-download/:processId",
  },
  TEMPLATE_ID: {
    analyticsName: "project-templates",
    path: "/projects/templates/:projectTemplateId",
  },
  LEGACY_TEMPLATE_ID: {
    analyticsName: "project-templates",
    path: "/project-templates/:projectTemplateId",
  },
  INSIGHTS_GENERAL: {
    analyticsName: "insights-general",
    path: "/insights",
  },
  INSIGHTS_SPECIFIC: {
    analyticsName: "insights-specific",
    path: "/insights/:teamId",
  },
};

const INTEGRATION_ROUTES = {
  INTEGRATIONS_PROJECT_ID: {
    analyticsName: "integrations_dashboard_project_id",
    path: "/integrations/projects/:projectId",
  },
  EMBED: { analyticsName: "embed", path: "/embed" },
  PLUGIN_GOOGLE_LOGIN: {
    analyticsName: "plugin-google-login",
    path: "/plugin/login",
  },
  PLUGIN_GOOGLE_LOGIN_SUCCESS: {
    analyticsName: "plugin-google-login-success",
    path: "/plugin/login/success",
  },
  MONDAY_APP_AUTHORIZATION_START: {
    analyticsName: "monday-app-authorization-start",
    path: "/integrations/monday-app/auth",
  },
  INTEGRATIONS_AUTHORIZATION_FAILED: {
    analyticsName: "integrations-authorization-failed",
    path: "/integrations/:appId/failed",
  },
  MONDAY_APP_PROJECTS_LIST: {
    analyticsName: "monday-app-projects-list",
    path: "/integrations/monday-app/projects",
  },
  MONDAY_APP_EMPTY_REVIEWS: {
    analyticsName: "monday-app-empty-reviews",
    path: "/integrations/monday-app/reviews/empty",
  },
  INTEGRATIONS: {
    analyticsName: "integrations",
    path: "/apps",
  },
  INTEGRATIONS_DETAILS: {
    analyticsName: "integrations-details",
    path: "/apps/:appId",
  },
  INTEGRATIONS_ZAPIER_EMBED: {
    analyticsName: "zapier-embed",
    path: "/apps/zapier-embed",
  },
  INTEGRATIONS_AUTHORIZATION_CALLBACK: {
    analyticsName: "integrations-authorization-callback",
    path: "/integrations/:appId/auth/callback",
  },
};

const SUPPORTING_ROUTES = {
  LOGIN: {
    analyticsName: "login",
    path: "/login",
    /**
     * @param {Object} params
     * @param {string} params.redirect redirect after login
     * @return {string} path
     */
    build(params) {
      return `${this.path}?${new URLSearchParams(params)}`;
    },
  },
  ADMIN: { analyticsName: "admin", path: "/admin" },
  LOGOUT: { analyticsName: "logout", path: "/logout" },
  PASSWORD_FORGOT: {
    analyticsName: "password-forgot",
    path: "/password/forgot",
  },
  ENFORCE_PASSWORD_CHANGE: {
    analyticsName: "enforce-password-change",
    path: "/enforce-password-change",
  },
  EXTERNAL_LOGIN_CALLBACK: {
    analyticsName: "external-login-callback",
    path: "/external-login-callback",
  },
  SIGNUP: { analyticsName: "signup", path: "/signup" },
  SIGNUP_VERIFY_OTP: {
    analyticsName: "signup-verify-otp",
    path: "/signup-verify-otp",
  },
  SIGNUP_EXTERNAL: {
    analyticsName: "signup-external",
    path: "/signup-external-callback",
  },
  SIGNUP_VALIDATE: {
    analyticsName: "signup-validate",
    path: "/signup-validate",
  },
  SIGNUP_EXTERNAL_CALLBACK: {
    analyticsName: "signup-external-callback",
    path: "/signup-external-callback",
  },
  TEAM_JOIN_LINK: {
    analyticsName: "team-join-link",
    path: "/teams/:inviteLinkId/join-link",
  },
  PROFILE: {
    analyticsName: "profile",
    path: "/profile/:tabName",
  },
  PROFILE_HOME: {
    analyticsName: "profile-home",
    path: "/profile",
  },
  TEAMS: { analyticsName: "teams", path: "/teams/:tabName" },
  TEAMS_GENERAL: {
    analyticsName: "teams_general",
    path: "/teams",
  },
  TEAM_SPECIFIC: {
    analyticsName: "team_specific",
    path: "/teams/:teamId",
  },
  IMPERSONATE: {
    analyticsName: "impersonate",
    path: "/impersonate",
  },
  ONBOARDING_WIZARD_CREDENTIALS: {
    analyticsName: "onboarding-wizard_setup-user-credentials",
    path: "/onboarding-wizard/setup-user-credentials",
  },
  ONBOARDING_WIZARD_COMPANY_SIZE: {
    analyticsName: "onboarding-wizard_company-size",
    path: "/onboarding-wizard/company-size",
  },
  ONBOARDING_WIZARD_INVITE_TEAM_MEMBERS: {
    analyticsName: "onboarding-wizard_invite-team-members",
    path: "/onboarding-wizard/invite-team-members",
  },
  ONBOARDING_WIZARD_JOIN_TEAMS: {
    analyticsName: "onboarding-wizard_join-teams",
    path: "/onboarding-wizard/join-teams",
  },
  ONBOARDING_WIZARD_UPLOAD_FILES: {
    analyticsName: "onboarding-wizard_upload-files",
    path: "/onboarding-wizard/upload-files",
  },
  PROFITWELL: {
    analyticsName: "profitwell",
    path: "/profitwell",
  },
  VERIFY_DOMAIN: {
    analyticsName: "verify-domain",
    path: "/verify-domain/:verificationId",
  },
};

const FEEDBACK_ROUTES = {
  STEP_LINK: {
    analyticsName: "step-link",
    path: "/step/:stepId",
  },
  STEP_SHARE: {
    analyticsName: "step-share",
    path: "/review/:stepId",
  },
  WORKSPACE_ALIAS: {
    analyticsName: "invite-workspace-alias",
    path: "/:fileId",
  },
  REVIEW_LINK_COMMENT: {
    analyticsName: "review-link-comment",
    path: "/step/:stepId/review/:reviewId/comment/:commentId",
  },
  REVIEW_LINK: {
    analyticsName: "review-link",
    path: "/step/:stepId/review/:reviewId",
    /**
     * @param {Object} review
     * @param {string} review.id
     * @param {string} review.stepId
     */
    build({ id, stepId }) {
      return generatePath(this.path, {
        stepId,
        reviewId: id,
      });
    },
  },
  ISSUE_LINK: {
    analyticsName: "issue-link",
    path: "/step/:stepId/file/:fileId/:versionNumber/comment/:commentId",
  },
  STEP_SHARE_WORKSPACE_FILE_VERSION_ISSUE: {
    analyticsName: "step-share-file-workspace-version-issue",
    path: "/review/:stepId/file/:fileId/:versionNumber/comment/:commentId",
  },
  WORKSPACE: {
    analyticsName: "workspace",
    path: "/step/:stepId/file/:fileId/:versionNumber",
  },
  STEP_SHARE_WORKSPACE_FILE_VERSION: {
    analyticsName: "step-share-file-workspace-version",
    path: "/review/:stepId/file/:fileId/:versionNumber",
  },
  FILE_LINK: {
    analyticsName: "file-link",
    path: "/step/:stepId/file/:fileId",
  },
  STEP_SHARE_WORKSPACE_FILE: {
    analyticsName: "step-share-file-workspace",
    path: "/review/:stepId/file/:fileId",
  },
  STEP_SHARE_AUTHORIZE: {
    analyticsName: "step-share-authorize",
    path: "/validate-step-password",
  },
  WORKSPACE_AUTH_CALLBACK: {
    analyticsName: "workspace-authorize-callback",
    path: "/workspace/auth/callback",
  },
  BATCH_REVIEW: {
    analyticsName: "batch-review",
    path: "/step/:stepId/batch-review",
  },
  COMPARE_REVIEWS: {
    analyticsName: "compare-reviews",
    path: "/compare",
  },
  STEP_LOGIN: {
    analyticsName: "step-login",
    path: "/step/:stepId/login",
  },
  STEP_ACCESS_REQUEST: {
    analyticsName: "restricted-review-link",
    path: "/step/:stepId/access-request",
  },
};

export const APP_ROUTES = Object.freeze({
  ...GLOBAL_ROUTES,
  ...WORKFLOW_ROUTES,
  ...INTEGRATION_ROUTES,
  ...SUPPORTING_ROUTES,
  ...FEEDBACK_ROUTES,
  ERROR: {
    /**
     * @param {Object} error
     * @param {number} status backend error code
     * @returns {string} path
     */
    build({ status }) {
      switch (status) {
        case 400:
        case 404:
          return GLOBAL_ROUTES.LINK_INVALID.path;
        case 401:
          return SUPPORTING_ROUTES.LOGIN.build({
            redirect: location.href.replace(location.origin, ""),
          });
        case 403:
          return GLOBAL_ROUTES.ACCESS_DENIED.path;
        default:
          return GLOBAL_ROUTES.CONNECTION_INTERRUPTED.build();
      }
    },
  },
});
