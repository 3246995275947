export const doesBaseSubscriptionUserLimitReached = (
  subscription,
  currentNoOfTeamMembers,
  noOfNewTeamMembers
) => {
  if (!subscription.limits.includedUsers) {
    return subscription.basePrice.amount > 0;
  }
  if (subscription.limits.includedUsers === -1) {
    return false;
  }
  return (
    currentNoOfTeamMembers + noOfNewTeamMembers >
    subscription.limits.includedUsers
  );
};

export const canAddMoreUsersToSubscription = (
  subscription,
  currentNoOfTeamMembers,
  noOfNewTeamMembers
) => {
  const hasUnlimitedMembers =
    subscription.limits.includedUsers === undefined ||
    subscription.limits.includedUsers === -1;
  const hasAdditionalPlan = Boolean(subscription.limits["additional-plan"]);
  const hasRoomForNewMembers =
    currentNoOfTeamMembers + noOfNewTeamMembers <=
    subscription.limits.includedUsers;
  return hasUnlimitedMembers || hasAdditionalPlan || hasRoomForNewMembers;
};
