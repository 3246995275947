import PropTypes from "prop-types";
import { ReactElement } from "react";

import { formatDistanceToNow } from "date-fns";

import { Text, Tooltip } from "@shared/UIKit";

import {
  getCurrentLocaleForDateFns,
  formatWithLocale,
} from "@shared/helpers/dateutils";

/**
 * UIKit Relative Date Component
 * @description Renders an UIKit Text element and prints out the distance from now to the given date.
 * It gets the current locale from the browser then formats the date with the locale.
 * @param {{date: Date}} props Component props
 * @param {Date} props.date [Required] Date which we want to calculate the relative distance.
 * @returns {ReactElement} React component
 */
function RelativeDate({ date, ...restProps }) {
  const currentLocaleObject = getCurrentLocaleForDateFns();

  return (
    <Tooltip placement="bottom" title={formatWithLocale(date, "PPpp")}>
      <Text
        data-testid="relative-date"
        variant="subtitle2"
        color="text.secondary"
        {...restProps}
      >
        {formatDistanceToNow(date, {
          ...currentLocaleObject,
          addSuffix: true,
        })}
      </Text>
    </Tooltip>
  );
}

RelativeDate.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
};

export default RelativeDate;
