import NiceModal from "@ebay/nice-modal-react";

import Divider from "@mui/material/Divider";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";

import { Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

import ActiveProjectUsage from "./usage/ActiveProjectsUsage";
import AutomationsUsage from "./usage/AutomationsUsage";
import MaxReviewStep from "./usage/MaxReviewStep";
import ProjectTemplatesUsage from "./usage/ProjectTemplatesUsage";
import SeatUsage from "./usage/SeatUsage";
import SecureStorageUsage from "./usage/SecureStorageUsage";

function SubscriptionUsage({ team }) {
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { teamId: team._id });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {team.subscriptionLimits.maxProjectNumber > -1 && (
        <>
          <ActiveProjectUsage team={team} openBillingDialog={openDialog} />
          <Divider />
        </>
      )}
      <SeatUsage team={team} openBillingDialog={openDialog} />
      <Divider />
      <SecureStorageUsage team={team} openBillingDialog={openDialog} />
      <Divider />
      <ProjectTemplatesUsage team={team} openBillingDialog={openDialog} />
      <Divider />
      <AutomationsUsage team={team} openBillingDialog={openDialog} />
      <Divider />
      {team.subscriptionLimits.maxReviewStepsNumber > -1 && (
        <>
          <MaxReviewStep team={team} openBillingDialog={openDialog} />
          <Divider />
        </>
      )}
    </Box>
  );
}

SubscriptionUsage.propTypes = {
  team: teamProp.isRequired,
};

export default SubscriptionUsage;
