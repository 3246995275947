import NiceModal from "@ebay/nice-modal-react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";

import { Tooltip } from "@shared/UIKit";

import QuickSearchDialog from "../QuickSearchDialog/QuickSearchDialog";

function QuickSearchButton() {
  const { t } = useTranslation();
  const openSearchDialog = (event) => {
    NiceModal.show(QuickSearchDialog, {
      openViaHotKey: Boolean(event.key),
    });
  };

  useHotkeys("f", openSearchDialog);

  return (
    <Tooltip
      shortcutKeys={["F"]}
      title={t("SEARCH.BTN_TOOLTIP")}
      placement="bottom"
    >
      <IconButton
        id="quick-search-button"
        onClick={openSearchDialog}
        data-testid="search-button"
        size="medium"
      >
        <SearchIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}

export default QuickSearchButton;
