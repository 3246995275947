import { useTheme } from "@mui/styles";

import Picture from "@shared/UIKit/Picture/Picture";

import LogoMobile from "@assets/img/sprite-source/sprite--logo-mobile.png";
import LogoMobilex2 from "@assets/img/sprite-source/sprite--logo-mobile@2x.png";
import Logo from "@assets/img/sprite-source/sprite--logo.png";
import Logox2 from "@assets/img/sprite-source/sprite--logo@2x.png";

function FilestageResponsiveLogo() {
  const theme = useTheme();
  return (
    <Picture
      sources={[
        {
          srcSet: `${LogoMobile} 1x, ${LogoMobilex2} 2x`,
          media: `(max-width: ${theme.breakpoints.values.sm}px)`,
        },
        {
          srcSet: `${Logo} 1x, ${Logox2} 2x`,
          media: `(min-width: ${theme.breakpoints.values.sm}px)`,
        },
      ]}
      defaultImgSrc={Logo}
      alt="Filestage logo"
    />
  );
}

export default FilestageResponsiveLogo;
