import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import * as stepService from "@workflow/services/stepService";

import SharedLayout from "@shared/pages/SharedLayout";
import { instance as analytics } from "@shared/services/analytics";
import errorHandler from "@shared/services/errorHandler";

import RequestAccess from "./RequestAccess";
import RequestSent from "./RequestSent";

const StepAccessRequest = () => {
  const { accessRequest: defaultAccessRequest } = useLoaderData();
  const [{ data }, setState] = useState({
    data: defaultAccessRequest,
  });

  const requestAccess = async (stepId, reviewId, fileId) => {
    try {
      const accessRequest = await stepService.createAccessRequest(
        stepId,
        reviewId,
        fileId
      );
      analytics.trackV2({
        category: analytics.CATEGORY.REVIEW_LINK_ACCESS,
        action: analytics.ACTION.REQUESTED,
      });
      setState((prev) => ({
        ...prev,
        data: accessRequest,
      }));
      return true;
    } catch (error) {
      errorHandler.handleError(error);
    }
  };

  return (
    <SharedLayout>
      {data ? (
        <RequestSent resendAccessRequest={requestAccess} />
      ) : (
        <RequestAccess requestAccess={requestAccess} />
      )}
    </SharedLayout>
  );
};

export default StepAccessRequest;
