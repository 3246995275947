import { makeStyles } from "@mui/styles";

import FSTGTypography from "@shared/theme/typography";
export const usePresenterStyles = makeStyles((theme) => ({
  SearchArchivedLabel: {
    padding: 5,
    backgroundColor: theme.color.gray[200],
    borderRadius: 2,
    color: theme.color.gray[800],
    fontSize: FSTGTypography.fontSize_1_2,
    fontWeight: FSTGTypography.fontWeightRegular,
    lineHeight: FSTGTypography.lineHeight_1_3,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      marginLeft: 15,
    },
    "&:hover": {
      backgroundColor: theme.color.gray[50],
      color: theme.color.green[600],
    },
  },
  HighlightText: {
    color: theme.color.black,
    backgroundColor: "transparent",
    fontWeight: FSTGTypography.fontWeightMedium,
  },
  Selected: {
    backgroundColor: theme.color.gray[200],
    borderLeft: `1px solid ${theme.color.gray[900]}`,
    cursor: "pointer",
    "& $SearchArchivedLabel": {
      backgroundColor: "#E0E1E0",
    },
  },
  SearchResultItem: {
    padding: "5px 15px",
    wordBreak: "break-all",
    borderBottom: `1px solid ${theme.color.gray[100]}`,
    [theme.breakpoints.up("md")]: {
      padding: "7px 20px",
      borderRight: "none",
      borderTop: "none",
      borderBottom: "none",
    },
    "&:hover:not($Selected), &:focus:not($Selected)": {
      backgroundColor: theme.color.gray[50],
      cursor: "pointer",
      outline: "none",
    },
    "&:hover $SearchArchivedLabel": {
      backgroundColor: theme.color.gray[50],
      color: theme.color.green[600],
    },
    "&:hover $SearchResultText": {
      color: theme.color.black,
    },
  },
  SearchResultText: {
    color: theme.color.gray[700],
    textAlign: "left",
    fontSize: FSTGTypography.fontSize_1_4,
    lineHeight: FSTGTypography.lineHeight_1_3,
    [theme.breakpoints.up("md")]: {
      marginRight: 10,
    },
    "&:hover": {
      color: theme.color.black,
    },
  },
}));
