import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiChevronDown } from "react-icons/fi";

import {
  REVIEW_DECISION_STATES_TO_ACTIONS,
  REVIEW_DECISION_STATES,
} from "@feedback/constants/reviewDecisionState";
import { Check, Close, Replay } from "@mui/icons-material";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
} from "@mui/material";

import VerifyApprovalIcon from "@assets/img/icons/verify-approval.svg";

import ReviewDecisionTooltip from "./ReviewDecisionTooltip";

const REVIEW_DECISION_MENU_ITEMS = {
  [REVIEW_DECISION_STATES.APPROVED]: {
    label: "REVIEW_BAR.APPROVE_BUTTON",
    tooltipTitle: "REVIEW_BAR.APPROVE.TOOLTIP.TITLE",
    tooltipBody: "REVIEW_BAR.APPROVE.TOOLTIP.BODY",
    icon: <Check fontSize="inherit" color="approved" />,
  },
  [REVIEW_DECISION_STATES.NEEDS_CHANGES]: {
    label: "REVIEW_BAR.REQUEST_CHANGES_BUTTON",
    tooltipTitle: "REVIEW_BAR.REQ_CHANGES.TOOLTIP.TITLE",
    tooltipBody: "REVIEW_BAR.REQ_CHANGES.TOOLTIP.BODY",
    icon: (
      <Replay
        sx={{
          transform: "rotate(-45deg)",
        }}
        fontSize="inherit"
        color="needsChanges"
      />
    ),
  },
  [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]: {
    label: "REVIEW_BAR.APPROVE_WITH_CHANGES_BUTTON",
    tooltipTitle: "REVIEW_BAR.APPROVED_WITH_CHANGES.TOOLTIP.TITLE",
    tooltipBody: "REVIEW_BAR.APPROVED_WITH_CHANGES.TOOLTIP.BODY",
    icon: <Check fontSize="inherit" color="approved" />,
  },
  [REVIEW_DECISION_STATES.REJECTED]: {
    label: "REVIEW_BAR.REJECT_BUTTON",
    tooltipTitle: "REVIEW_BAR.REJECT.TOOLTIP.TITLE",
    tooltipBody: "REVIEW_BAR.REJECT.TOOLTIP.BODY",
    icon: <Close fontSize="inherit" color="rejected" />,
  },
};

const menuListStyles = {
  "& .MuiList-root": {
    py: "0.5rem !important",
  },
};

const menuItemStyles = {
  "& .MuiListItemText-primary": { fontSize: "0.875rem" },
  "& .MuiListItemIcon-root": {
    "& .MuiSvgIcon-root": { fontSize: "1.25rem" },
  },
};

const batchReviewStyles = {
  button: {
    color: "white",
    borderColor: "grey.300",
    backgroundColor: "grey.700",

    "&:hover": {
      borderColor: "grey.100",
      backgroundColor: "grey.700",
    },

    "&:disabled": {
      color: "grey.400",
    },
  },
  menu: {
    ...menuListStyles,
    "& .MuiMenu-paper": { backgroundColor: "grey.800" },
  },
  menuItem: {
    ...menuItemStyles,
    "& .MuiListItemText-root": { color: "white" },
    "& .MuiListItemText-primary": { color: "white", fontSize: "0.875rem" },
    "&:hover": { backgroundColor: "grey.900" },
  },
};

const ReviewDecisionButtonMenu = ({
  reviewDecisionOptions,
  finalizeReviewDecision,
  isSubmitting,
  isDigitalSignatureRequired,
  isBatchReview,
}) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(null);

  const handleMenuOpen = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuOpen(null);
  };

  const submitReviewDecision = (state) => {
    handleMenuClose();
    finalizeReviewDecision(REVIEW_DECISION_STATES_TO_ACTIONS[state])();
  };

  const menuItems = reviewDecisionOptions.map((option) => ({
    state: option,
    ...REVIEW_DECISION_MENU_ITEMS[option],
  }));

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleMenuOpen}
        endIcon={<FiChevronDown />}
        disabled={isSubmitting}
        sx={isBatchReview ? batchReviewStyles.button : {}}
      >
        {t("REVIEW_BAR.SUBMIT_REVIEW_DECISION")}
      </Button>

      <Menu
        id="review-decision-menu"
        color="primary"
        anchorEl={menuOpen}
        keepMounted
        open={Boolean(menuOpen)}
        onClose={handleMenuClose}
        sx={
          isBatchReview
            ? batchReviewStyles.menu
            : {
                "& .MuiMenu-paper": {
                  ml: { xs: 0, md: 0.9 },
                },
                ...menuListStyles,
              }
        }
        anchorOrigin={{
          vertical: isBatchReview ? "top" : "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: isBatchReview ? "bottom" : "top",
          horizontal: "center",
        }}
      >
        {menuItems.map(
          ({ state, label, tooltipTitle, tooltipBody, icon: Icon }) => {
            const isVerifiedApproval =
              state === REVIEW_DECISION_STATES.APPROVED &&
              isDigitalSignatureRequired;

            return (
              <ReviewDecisionTooltip
                title={tooltipTitle}
                body={tooltipBody}
                key={tooltipTitle}
                showVerifiedApprovalTitle={isVerifiedApproval}
              >
                <MenuItem
                  onClick={() => submitReviewDecision(state)}
                  disabled={isSubmitting}
                  sx={
                    isBatchReview ? batchReviewStyles.menuItem : menuItemStyles
                  }
                >
                  <ListItemIcon>
                    {isVerifiedApproval ? (
                      <SvgIcon
                        component={VerifyApprovalIcon}
                        fontSize="inherit"
                        color="approved"
                        inheritViewBox
                      />
                    ) : (
                      Icon
                    )}
                  </ListItemIcon>

                  <ListItemText>
                    {t(
                      isVerifiedApproval
                        ? "VERIFIED_APPROVAL.CTA_PRIMARY"
                        : label
                    )}
                  </ListItemText>
                </MenuItem>
              </ReviewDecisionTooltip>
            );
          }
        )}
      </Menu>
    </>
  );
};

ReviewDecisionButtonMenu.propTypes = {
  reviewDecisionOptions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.keys(REVIEW_DECISION_STATES))
  ).isRequired,
  finalizeReviewDecision: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isDigitalSignatureRequired: PropTypes.bool.isRequired,
  isBatchReview: PropTypes.bool,
};

ReviewDecisionButtonMenu.defaultProps = {
  isBatchReview: false,
};

export default ReviewDecisionButtonMenu;
