/* eslint jsdoc/require-jsdoc:0 */
/* eslint jsdoc/no-missing-syntax:0 */
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import { Link } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Text } from "@shared/UIKit";

const useStyles = makeStyles({
  helpText: {
    padding: "13px 10px 13px 10px",
  },
});

function HelpTextPopperContent({ helpText }) {
  const classes = useStyles();

  return (
    <Text
      data-testid="help-text-popper-content"
      textAlign="center"
      variant="subtitle1"
      className={classes.helpText}
    >
      <Trans
        components={{
          anchor: <Link color="accent" />,
        }}
      >
        {helpText}
      </Trans>
    </Text>
  );
}

HelpTextPopperContent.propTypes = {
  helpText: PropTypes.string.isRequired,
};

export default HelpTextPopperContent;
