import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { SvgIcon } from "@mui/material";

import { Dialog } from "@shared/UIKit";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

function UpgradePlanDialog({
  dialogTitle,
  isUpgradePlanDialogOpen,
  onCancel,
  children,
  ...restProps
}) {
  const { t } = useTranslation();

  return (
    <Dialog
      data-testid="upgrade-plan-dialog"
      hideBackdrop={false}
      isOpen={isUpgradePlanDialogOpen}
      cancel={onCancel}
      useDefaultBackground={false}
      title={t(dialogTitle)}
      {...restProps}
    >
      {children}
      <SvgIcon
        component={PremiumIcon}
        color="upgrade"
        sx={{
          position: "absolute",
          top: 20,
          left: 20,
        }}
      />
    </Dialog>
  );
}

UpgradePlanDialog.propTypes = {
  dialogTitle: PropTypes.string.isRequired,
  isUpgradePlanDialogOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default UpgradePlanDialog;
