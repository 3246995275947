import PropTypes from "prop-types";

import NOTIFICATION_CENTER_ACTIVITIES from "@supporting/components/NotificationCenter/activities.constants";

export const notificationCenterActivityProps = PropTypes.oneOf([
  NOTIFICATION_CENTER_ACTIVITIES.COMMENT.TEAM_ONLY_COMMENT_MENTION,
  NOTIFICATION_CENTER_ACTIVITIES.COMMENT.CREATED,
  NOTIFICATION_CENTER_ACTIVITIES.COMMENT.REPLIED,
  NOTIFICATION_CENTER_ACTIVITIES.COMMENT.LIKED,
  NOTIFICATION_CENTER_ACTIVITIES.COMMENT.MENTIONED,
  NOTIFICATION_CENTER_ACTIVITIES.COMMENT.TEAM_ONLY,
  NOTIFICATION_CENTER_ACTIVITIES.PROJECT.COLLABORATOR_INVITED,
  NOTIFICATION_CENTER_ACTIVITIES.STEP.REVIEWER_INVITED,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STARTED,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STATUS_NEEDS_CHANGES,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STATUS_APPROVED,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STATUS_IN_REVIEW,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_APPROVED,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_REQUESTED_CHANGES,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_APPROVED_WITH_CHANGES,
  NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_REJECTED,
]);
