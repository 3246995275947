import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import { makeStyles } from "@mui/styles";

import { Box } from "@shared/UIKit";

import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import DefaultVersionReviewCard from "./DefaultVersionReviewCard/DefaultVersionReviewCard";
import NotSharedVersionReviewCard from "./NotSharedVersionReviewCard/NotSharedVersionReviewCard";

const useStyles = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    position: "relative",
    width: "290px",
    height: "40px",
    backgroundColor: theme.color.white,
    boxSizing: "border-box",
    borderBottom: `1px ${theme.color.gray[200]} solid`,
    "&:last-child": {
      "&::after": {
        display: "none",
      },
      borderRight: `1px ${theme.color.gray[200]} solid`,
    },
    "&::after": {
      content: "''",
      width: 1,
      height: 23,
      backgroundColor: theme.color.gray[200],
      display: "block",
      position: "absolute",
      right: 0,
      bottom: 8,
    },
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        zIndex: 23,
        backgroundColor: theme.color.white,
        "&::after": {
          display: "none",
        },
      },
      "&:hover:last-child": {
        borderRight: "none",
      },
      '&:hover:first-child:last-child [data-testid="version-review"]': {
        paddingLeft: 10,
        paddingRight: "11px !important",
      },
      '&:hover [data-testid="review-decision-indicator-list"]': {
        "&::before": {
          backgroundImage:
            "linear-gradient(to right, rgba(255,255,255,0) , rgba(255,255,255,1)) !important",
        },
      },
    },
  },
  cardContainer: {
    height: "40px",
    border: "1px transparent solid",
    boxSizing: "border-box",
    [theme.breakpoints.up("md")]: {
      "&:hover": {
        backgroundColor: theme.color.green[50],
        border: `1px ${theme.color["malachite1-translucent-50"]} solid`,
      },
    },
  },
  bottomFrame: {
    display: "none",
  },
}));

function VersionReviewCard({ team, file, step, version, project }) {
  const classes = useStyles();

  const review = version.reviews.find((review) => review.stepId === step.id);
  const [showOpenReviewLink, setShowOpenReviewLink] = useState(false);

  const toggleShowOpenReviewLink = useCallback(
    () => setShowOpenReviewLink(!showOpenReviewLink),
    [showOpenReviewLink]
  );

  return review ? (
    <Box
      onMouseEnter={toggleShowOpenReviewLink}
      onMouseLeave={toggleShowOpenReviewLink}
      data-testid="version-review-card"
      className={classes.root}
    >
      <Box
        display="flex"
        flexDirection="column"
        data-testid="default-version-review-root"
        className={classes.cardContainer}
      >
        <DefaultVersionReviewCard
          team={team}
          project={project}
          file={file}
          version={version}
          review={review}
          step={step}
        />
      </Box>
    </Box>
  ) : (
    <Box
      width={290}
      height={40}
      className={classes.container}
      data-testid="version-review-card"
    >
      <NotSharedVersionReviewCard />
    </Box>
  );
}

VersionReviewCard.propTypes = {
  team: teamProp.isRequired,
  file: fileProp.isRequired,
  step: stepProps.isRequired,
  version: versionProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
};

export default VersionReviewCard;
