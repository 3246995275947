import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { MailOutline, RemoveCircleOutline } from "@mui/icons-material";
import { Link, ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import AddReviewerDialog from "@workflow/components/AddReviewerDialog/AddReviewerDialog";
import {
  AUTOMATION_CHECK_TYPE,
  checkIsUsedInAutomation,
} from "@workflow/helpers/checkIsUsedInAutomation";
import useProjectSteps from "@workflow/hooks/useProjectSteps";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import { Box, Avatar, Text, ConfirmationDialog } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { userProp } from "@shared/props/authentication";
import { automationProps } from "@shared/props/automations";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";
import FSTGTypography from "@shared/theme/typography";

import RequestReviewDecisionSwitch from "./RequestReviewDecisionSwitch/RequestReviewDecisionSwitch";

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: 80,
  },
  paper: {
    borderRadius: 5,
  },
  avatar: {
    width: 60,
    height: 60,
    marginRight: 10,
    fontSize: FSTGTypography.fontSize_2_8,
    fontWeight: FSTGTypography.fontWeightMedium,
    backgroundColor: theme.color.blue[400],
    color: "white",
  },
  youTag: {
    marginRight: 3,
    marginLeft: 3,
  },
  email: {
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%",
    },
  },
  menuItem: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.color["white-smoke-translucent"],
    },
  },
  menuItemIcon: {
    width: 20,
    marginRight: 10,
  },
}));

function StepReviewerMenu({
  user,
  teamId,
  automations,
  step,
  canManageProjectMembers,
  isProjectArchived,
  closeMenu,
  menuAnchorEl,
  canManageProjectTemplates,
  isProjectTemplate,
  team,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const { isOpen, openDialog, closeDialog } = useDialogState();

  const [steps] = useProjectSteps(
    step.projectId || step.projectTemplateId,
    isProjectTemplate
  );

  const currentUser = authenticationService.fetchUser();
  const isCurrentUser = user._id === currentUser._id;
  const isMenuOpen = Boolean(menuAnchorEl);
  const isReviewDecisionFeatureEnabled =
    team.subscriptionLimits.isRequestReviewerDecisionEnabled;

  const canViewDetailCard =
    isCurrentUser ||
    canManageProjectMembers ||
    (isProjectTemplate && canManageProjectTemplates);

  function openAddReviewerDialog() {
    return NiceModal.show(AddReviewerDialog, {
      teamId,
      step,
      preparedReviewers: [{ email: user.email }],
      isProjectTemplate,
    });
  }

  const removeStepHandler = () => {
    if (
      checkIsUsedInAutomation(
        automations,
        user._id,
        AUTOMATION_CHECK_TYPE.REVIEWER,
        step.id,
        steps
      )
    ) {
      toastService.sendToast({
        title: "DELETE_REVIEWER.AUTOMATION.TOAST.TITLE",
        body: "DELETE_REVIEWER.AUTOMATION.TOAST.BODY",
        preset: toastService.PRESETS().ERROR_DELAYED,
      });
      closeMenu();
      return;
    }
    openDialog();
  };

  const removeReviewerHandler = async () => {
    try {
      if (isProjectTemplate) {
        await projectTemplateService.removeReviewer(step.id, user._id);
      } else {
        await stepService.removeReviewer(step.id, user._id);
      }

      closeDialog();
      toastService.sendToast({
        title: "REVIEWER.REMOVE.SUCCESS.TITLE",
        body: "REVIEWER.REMOVE.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: { body: { member: user.displayName } },
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };

  return (
    <>
      <Popper
        className={classes.popper}
        open={isMenuOpen}
        anchorEl={menuAnchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <ClickAwayListener
                touchEvent="onTouchStart"
                mouseEvent="onMouseDown"
                onClickAway={closeMenu}
              >
                <Paper className={classes.paper} elevation={4} square>
                  <Box
                    display="flex"
                    flexDirection="column"
                    data-testid="step-reviewer-context-menu"
                  >
                    <Box
                      display="flex"
                      p={1.875}
                      pt={3.75}
                      pb={2.5}
                      boxShadow={theme.shadow["box-shadow-step-reviewer-menu"]}
                    >
                      <Avatar user={user} className={classes.avatar} />
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        alignContent="flex-start"
                        justifyContent="center"
                        overflow="hidden"
                      >
                        <Text
                          variant="h6"
                          fontWeight={FSTGTypography.fontWeightMedium}
                          color={theme.color["black-translucent-85"]}
                          data-testid="step-reviewer-name"
                        >
                          {user.displayName}
                          {user.youTagEnabled && (
                            <Text
                              component="span"
                              className={classes.youTag}
                              fontSize={FSTGTypography.fontSize_1_2}
                              color={theme.color.gray[500]}
                            >
                              {t("CORE.YOU")}
                            </Text>
                          )}
                        </Text>
                        {user.email && (
                          <Link
                            href={`mailto:${user.email}`}
                            className={classes.email}
                            textDecoration="none"
                            fontSize={FSTGTypography.fontSize_1_5}
                            lineHeight={FSTGTypography.lineHeight_1_6}
                            color="success"
                            data-testid="step-reviewer-email-link"
                          >
                            {user.email}
                          </Link>
                        )}
                      </Box>
                    </Box>
                    {!isProjectArchived && canViewDetailCard && (
                      <Box>
                        {isReviewDecisionFeatureEnabled && (
                          <RequestReviewDecisionSwitch
                            reviewDecisionRequested={
                              user.reviewDecisionRequested
                            }
                            step={step}
                            reviewer={user}
                            isProjectTemplate={isProjectTemplate}
                          />
                        )}

                        {!isProjectTemplate && user.email && (
                          <Box
                            display="flex"
                            alignItems="center"
                            minHeight={36}
                            data-testid="step-reviewer-resend-invite"
                            onClick={openAddReviewerDialog}
                            className={classes.menuItem}
                            px={2.1}
                            py={1}
                          >
                            <MailOutline className={classes.menuItemIcon} />
                            <Text
                              variant="body2"
                              fontWeight={FSTGTypography.fontWeightMedium}
                            >
                              {t("REVIEWER.MENU.RESEND")}
                            </Text>
                          </Box>
                        )}
                        <Box
                          display="flex"
                          alignItems="center"
                          minHeight={36}
                          data-testid="step-reviewer-remove-reviewer"
                          onClick={removeStepHandler}
                          className={classes.menuItem}
                          px={2.1}
                          py={1}
                        >
                          <RemoveCircleOutline
                            className={classes.menuItemIcon}
                          />
                          <Text
                            variant="body2"
                            fontWeight={FSTGTypography.fontWeightMedium}
                          >
                            {t(
                              isCurrentUser
                                ? "REVIEWER.MENU.REMOVE_ME"
                                : "REVIEWER.MENU.REMOVE"
                            )}
                          </Text>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>

      {isOpen && (
        <ConfirmationDialog
          isOpen={isOpen}
          title={t("REVIEWER.REMOVE.HEADLINE", {
            member: user.displayName,
          })}
          description={t("REVIEWER.REMOVE.DESCRIPTION", {
            member: user.displayName,
          })}
          cancelLabel={t("CORE.CANCEL")}
          confirmLabel={t("REVIEWER.REMOVE.CONFIRM")}
          closeDialog={closeDialog}
          answer={removeReviewerHandler}
        />
      )}
    </>
  );
}

StepReviewerMenu.propTypes = {
  user: userProp.isRequired,
  teamId: PropTypes.string.isRequired,
  step: stepProps.isRequired,
  canManageProjectMembers: PropTypes.bool,
  isProjectArchived: PropTypes.bool,
  closeMenu: PropTypes.func.isRequired,
  menuAnchorEl: PropTypes.object,
  automations: PropTypes.arrayOf(automationProps),
  canManageProjectTemplates: PropTypes.bool,
  isProjectTemplate: PropTypes.bool,
  team: teamProp.isRequired,
};

StepReviewerMenu.defaultProps = {
  menuAnchorEl: null,
  isProjectArchived: false,
  automations: [],
  canManageProjectMembers: false,
  canManageProjectTemplates: false,
  isProjectTemplate: false,
};

export default StepReviewerMenu;
