/* eslint-disable id-length */
export const KEYBOARD_SHORTCUTS = Object.freeze({
  O: "o",
  SHIFT_O: ["Shift", "O"],
  SHIFT_UP: ["Shift", "ArrowUp"],
  SHIFT_DOWN: ["Shift", "ArrowDown"],
  COMMENT_MODE: ["Alt", "C"],
  P: "p",
  Q: "q",
  A: "a",
});
