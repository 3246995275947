(function(exports) {
  // APIs specific to compare-docs
  const PublicAPIsForCompareDocs = {
    /**
     * changes the opacity of a page of a document being compared
     * @param {number} documentIndex the document to alter
     * @param {number} pageNumber the page number of the document
     * @param {number} opacityValue a number between 0-1 (inclusive)
     */
    setDocumentPageOpacity: function(documentIndex, pageNumber, opacityValue) {
      if (this.filesToCompare[documentIndex] && pageNumber <= this.filesToCompare[documentIndex].document.getPageCount()) {
        if (!this.filesToCompare[documentIndex].pageOpacities) {
          this.filesToCompare[documentIndex].pageOpacities = [];
        }
        this.filesToCompare[documentIndex].pageOpacities[pageNumber - 1] = opacityValue;
        this.updatePageSizeFromTransformationState(pageNumber, pageNumber, this.shouldResetInitialPageRotations() ? 0 : this.getPage(pageNumber).rotation);
      }
    },
    /**
     * Sets the diffing mode. If true, differences in documents will be shown using certain colors
     * @param {boolean} shouldDiff boolean to denote if document differences should be shown in certain colors
     */
    shouldDiff: function(shouldDiff) {
      if (!this.options) {
        this.options = {};
      }
      this.options.shouldDiff = shouldDiff;
    },
    /**
     * Gets the comparison options. See CompareDocumentManager.initialize for more information.
     */
    getOptions: function() {
      return this.options;
    },
  };

  exports.PublicAPIsForCompareDocs = PublicAPIsForCompareDocs;
})(window);
