export default {
  // Globals
  fontFamily: ["'Lato'", "sans-serif"].join(","),
  htmlFontSize: 16,

  // Font weights
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,

  fontWeight: {
    regular: 400,
    semiBold: 600,
    bold: 700,
  },

  // Font sizes
  fontSize_1_0: "0.625rem",
  fontSize_1_1: "0.6875rem", // miniXsFontSize
  fontSize_1_2: "0.75rem", // miniFontSize
  fontSize_1_3: "0.8125rem", // smallerFontSize
  fontSize_1_4: "0.875rem", // smallerXsFontSize
  fontSize_1_5: "0.9375rem", // smallFontSize
  fontSize_1_6: "1rem", // biggerFontSize
  fontSize_1_7: "1.0625rem", // normalFontSize
  fontSize_2_0: "1.25rem", // largeFontSize
  fontSize_2_2: "1.375rem",
  fontSize_2_4: "1.5rem",
  fontSize_2_6: "1.625rem", // largerFontSize
  fontSize_2_8: "1.75rem",
  fontSize_2_9: "1.8125rem",
  fontSize_3_1: "1.9375rem", // extralargeFontSize
  fontSize_3_8: "2.375rem",
  fontSize_10_8: "6.75rem",

  fontSize5xl: "2.5rem",
  fontSize4xl: "2rem",
  fontSize3xl: "1.75rem",
  fontSize2xl: "1.5rem",
  fontSizeXl: "1.25rem",
  fontSizeLg: "1.125rem",
  fontSizeMd: "1rem",
  fontSizeSm: "0.875rem",
  fontSizeXs: "0.75rem",

  lineHeight5xl: "2.93rem",
  lineHeight4xl: "2.6rem",
  lineHeight3xl: "2.28rem",
  lineHeight2xl: "2.25rem",
  lineHeightXl: "1.86rem",
  lineHeightLg: "1.69rem",
  lineHeightMd: "1.5rem",
  lineHeightSm: "1.31rem",
  lineHeightXs: "1.13rem",

  // Line Heights
  lineHeight_1_0: 1.0,
  lineHeight_1_1: 1.1, // smallerLineHeight
  lineHeight_1_2: 1.2, // smallerXsLineHeight
  lineHeight_1_3: 1.3, // smallLineHeight
  lineHeight_1_5: 1.5, // normalLineHeight
  lineHeight_1_6: 1.6,
  lineHeight_1_7: 1.7, // largeLineHeight
  lineHeight_1_8: 1.8,
  lineHeight_1_9: 1.9, // largerLineHeight
  lineHeight_2_1: 2.1, // largerLineHeight
  lineHeight_2_2: 2.2,
  lineHeight_2_4: 2.4, // extraLargeLineHeight
  lineHeight_2_5: 2.5,
  lineHeight_2_8: 2.8,
  lineHeight_2_9: 2.9,
  lineHeight_3_6: 3.6,

  letterSpacing_0_1: ".01em",

  // Print font sizes
  printSmallerFontSize: "7pt",
  printSmallFontSize: "8pt",
  printNormalFontSize: "10pt",
  printLargeFontSize: "12pt",

  // Text shadows
  textShadowBlack: "0 2px 4px rgba(0, 0, 0, 0.43)",
};
