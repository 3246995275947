import PropTypes from "prop-types";
import { useMemo } from "react";

import { makeStyles } from "@mui/styles";
import AddReviewerDialogAutocomplete from "@workflow/components/AddReviewerDialog/AddReviewerDialogAutocomplete/AddReviewerDialogAutocomplete";

import { Box } from "@shared/UIKit";

import AUTOCOMPLETE from "@shared/constants/autoComplete";
import { userProp } from "@shared/props/authentication";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import TeamRolePickerV2 from "./TeamRolePickerV2/TeamRolePickerV2";

const useStyles = makeStyles((theme) => ({
  addMemberWrapper: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  teamRole: {
    cursor: "pointer",
    backgroundColor: theme.color.white,
    minWidth: 140,
    display: "flex",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "unset",
    },
  },
}));

function AddEmailInvite({
  team,
  setSelectedRoleId,
  newTeamMembersEmails,
  setNewTeamMembersEmails,
}) {
  const classes = useStyles();
  const inputHelperText =
    newTeamMembersEmails.length === 0 ? "TEAM.ADD_MEMBER.HELP_MESSAGE" : " ";

  const existingTeamMembers = useMemo(() => {
    return team.members?.map(({ user }) => ({
      inputValue: user.email,
      label: user.displayName || user.email,
    }));
  }, [team.members]);

  const handleOnChange = (_, emails) => {
    setNewTeamMembersEmails(emails);
  };

  const trackAddedEmailEvents = (addedEmailLength) => {
    analytics.track(
      analytics.ACTION.ADDED,
      analytics.CATEGORY.TEAM_MEMBER_EMAIL,
      {
        label:
          addedEmailLength === 1
            ? analytics.LABEL.SINGLE_EMAIL
            : analytics.LABEL.MULTIPLE_EMAILS,
        page: analytics.CATEGORY.INVITE_TEAM_MEMBER_DIALOG,
      }
    );
  };

  const trackPasteEmailEvents = (
    //eslint-disable-next-line no-unused-vars
    pastedEmailLength,
    extractedEmailLength,
    pastedString
  ) => {
    if (!extractedEmailLength) {
      analytics.track(
        analytics.ACTION.ADDED,
        analytics.CATEGORY.TEAM_MEMBER_EMAIL_FAILED,
        {
          pastedString,
        }
      );
    }
  };

  return (
    <Box className={classes.addMemberWrapper}>
      <Box minWidth={340} pt={newTeamMembersEmails.length > 0 ? 0 : 0.5}>
        <AddReviewerDialogAutocomplete
          id="invite-team-member-dialog-autocomplete"
          data-testid="invite-team-member-dialog-autocomplete"
          preparedReviewers={[]}
          reviewers={newTeamMembersEmails}
          existingReviewers={existingTeamMembers}
          onChange={handleOnChange}
          inputLabel="FORM.EMAIL.LABEL"
          isDisplayingSuggestions={false}
          inputHelperText={inputHelperText}
          maxInviteCount={AUTOCOMPLETE.MAX_INVITE_COUNT}
          trackAddedEmailEvents={trackAddedEmailEvents}
          trackPasteEmailEvents={trackPasteEmailEvents}
        />
      </Box>
      <Box
        className={classes.teamRole}
        pt={newTeamMembersEmails.length > 0 ? 0.875 : 0}
      >
        <TeamRolePickerV2
          team={team}
          onTeamRoleSelected={(value) => {
            setSelectedRoleId(value);
          }}
        />
      </Box>
    </Box>
  );
}

AddEmailInvite.propTypes = {
  setSelectedRoleId: PropTypes.func.isRequired,
  team: teamProp.isRequired,
  newTeamMembersEmails: PropTypes.arrayOf(userProp),
  setNewTeamMembersEmails: PropTypes.func.isRequired,
};

AddEmailInvite.defaultProps = {
  newTeamMembersEmails: [],
};
export default AddEmailInvite;
