import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams, createSearchParams } from "react-router-dom";

import { Box, Button, Link } from "@mui/material";
import EmailInput from "@supporting/components/EmailInput/EmailInput";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import authenticationService from "@supporting/services/authentication";
import Cookies from "js-cookie";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";

import { Text } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import isInIframe from "@shared/helpers/isInIframe";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";
import pageTitleService from "@shared/services/pageTitleService";

function SignUp() {
  const { t } = useTranslation();
  const insideIframe = isInIframe();

  const { goTo } = useNavigation();
  const [searchParams] = useSearchParams();
  const alreadyRegistered = searchParams.get("alreadyRegistered");
  const reason = searchParams.get("reason");
  const googleAuthState = {
    planId: searchParams.get("plan_id"),
    reason,
    insightsIntegrationId: searchParams.get("insightsIntegrationId"),
    redirect: insideIframe
      ? ROUTE_STATES.SIGNUP_EXTERNAL_CALLBACK
      : searchParams.get("redirect"),
  };
  const [signUpState, setSignUpState] = useState({
    signUpError: false,
    isSignUpInProgress: false,
    isEmailValid: false,
    email: searchParams.get("email"),
    isUserExist: false,
    registeredPluginSessionId: "",
  });
  const isAdobePlugin = Boolean(authenticationService.getBasePlugin());

  const appendSignUpState = (newState) => {
    setSignUpState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  useEffect(() => {
    pageTitleService.setTitle(t("SIGNUP.PAGE-TITLE"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (alreadyRegistered === "true") {
      appendSignUpState({
        isUserExist: true,
        isSignUpInProgress: false,
        inputErrorMessage: true,
      });
    }
  }, [alreadyRegistered]);

  useEffect(() => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.SIGNUP_PAGE);

    if (isAdobePlugin) {
      (async () => {
        const registeredPluginSessionId =
          await authenticationService.generatePluginSessionId();

        appendSignUpState({
          registeredPluginSessionId,
        });
      })();
    }
  }, [isAdobePlugin]);

  const { email } = signUpState;

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (!signUpState.isEmailValid) {
        return;
      }
      appendSignUpState({
        general: false,
        userExist: false,
        isSignUpInProgress: true,
        isUserExist: false,
        inputErrorMessage: "",
      });

      const planId = searchParams.get("plan_id");
      const sourceProjectId = searchParams.get("sourceProjectId");
      const sourceProjectTemplateId = searchParams.get(
        "sourceProjectTemplateId"
      );
      const insightsIntegrationId = searchParams.get("insightsIntegrationId");

      const authenticationState = omitBy(
        {
          email,
          planId,
          reason,
          sourceProjectId,
          sourceProjectTemplateId,
          insightsIntegrationId,
        },
        isNil
      );

      if (insideIframe && !isAdobePlugin) {
        authenticationState.redirect = {
          state: ROUTE_STATES.SIGNUP_EXTERNAL_CALLBACK,
        };
      }
      healthMetrics.trackStart("supporting.authentication");
      try {
        await authenticationService.sendOTPForSignup(
          email,
          authenticationState
        );
        Cookies.set("authentication:signup-flow", "started");
        analytics.track(
          analytics.ACTION.STARTED,
          analytics.CATEGORY.SIGNUP_FLOW,
          reason && { signupReason: reason }
        );
        analytics.track(
          analytics.ACTION.SUBMITTED,
          analytics.CATEGORY.SIGNUP_FORM,
          reason && { signupReason: reason }
        );
        goTo(ROUTE_STATES.SIGNUP_VERIFY_OTP, {
          search: createSearchParams({
            email,
            ...(planId && { planId }),
            ...(reason && { reason }),
          }).toString(),
        });
      } catch (error) {
        switch (error.code) {
          case "user_exists":
            appendSignUpState({
              isUserExist: true,
              isSignUpInProgress: false,
              inputErrorMessage: true,
            });
            break;
          default:
            appendSignUpState({
              isSignUpInProgress: false,
              general: true,
            });
        }
        const TAG = "authentication:signup-form";

        healthMetrics.trackFailure("supporting.authentication", error);
        logger.error(TAG, "failed to signup user", {
          email,
          error,
        });
      }
    },
    [
      signUpState.isEmailValid,
      email,
      searchParams,
      insideIframe,
      isAdobePlugin,
      goTo,
      reason,
    ]
  );

  const updateAuthState = useCallback((field) => {
    appendSignUpState({
      ...field,
      isUserExist: false,
      general: false,
    });
  }, []);

  const redirectUrl = searchParams.get("redirect");

  const onClickOfLink = (event) => {
    event.preventDefault();
    goTo(ROUTE_STATES.LOGIN, {
      search: createSearchParams({
        ...(redirectUrl && { redirect: redirectUrl }),
      }).toString(),
    });
  };

  return (
    <WizardBase variant="LOGIN">
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          gap={5}
          flexGrow={1}
          display="flex"
          alignSelf="center"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box gap={2} display="flex" flexDirection="column">
            <Text
              variant="text3xl"
              fontWeight="fontWeight.medium"
              translate="SIGNUP.HEADLINE"
            />
            <Text
              color="grey.600"
              variant="textMd"
              translate="SIGNUP.ONBOARDING.BODY"
            />
          </Box>
          <Box
            gap={2}
            noValidate
            width="100%"
            display="flex"
            maxWidth="400px"
            component="form"
            flexDirection="column"
            onSubmit={handleSubmit}
          >
            <EmailInput
              updateAuthState={updateAuthState}
              defaultErrorMessage={
                signUpState.isUserExist
                  ? "user_exists"
                  : "FORM.EMAIL.MESSAGE_REQUIRED"
              }
              label="FORM.EMAIL.WORK_LABEL"
              defaultValue={searchParams.get("email") || ""}
            />
            {signUpState.general && (
              <Box
                p={1.5}
                borderLeft={3}
                borderColor="error.main"
                bgcolor="rgba(255, 250, 243, 100)"
              >
                <Text translate="ERROR.GENERAL" />
              </Box>
            )}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              data-testid="signup"
              fullWidth
              size="large"
            >
              {signUpState.isSignUpInProgress
                ? t("SIGNUP.IN_PROGRESS")
                : t("SIGNUP.SUBMIT")}
            </Button>
            <GoogleAuth
              text="signup_with"
              testId="sign-up-google"
              authState={googleAuthState}
              isAdobePlugin={isAdobePlugin}
              adobePluginSessionId={signUpState.registeredPluginSessionId}
              adobePluginText="SIGN_UP"
            />
            <Text variant="textSm" textAlign="center" color="text.secondary">
              {t("SUBTEXT.TOLOGIN_TEXT")}
              <Link onClick={onClickOfLink} href="login" color="secondary">
                {t("SUBTEXT.TOLOGIN_LINK")}
              </Link>
            </Text>
            <Text color="text.secondary" variant="textSm" textAlign="center">
              {t("SIGNUP.LEGAL_TEXTBLOCK1")}{" "}
              <Link target="_blank" color="secondary" href={t("URLS.TERMS")}>
                {t("SIGNUP.LEGAL_TERMSOFSERVICE")}{" "}
              </Link>
              {t("SIGNUP.LEGAL_TEXTBLOCK2")}{" "}
              <Link target="_blank" color="secondary" href={t("URLS.PRIVACY")}>
                {t("SIGNUP.LEGAL_PRIVACYAGREEMENT")}
                {t("SIGNUP.LEGAL_TEXTBLOCK3")}
              </Link>
            </Text>
          </Box>
        </Box>
      </Box>
    </WizardBase>
  );
}

export default SignUp;
