import PropTypes from "prop-types";

import useBillingLimits from "@supporting/hooks/useBillingLimits";

import { Box } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

import UploadFromDropbox from "./UploadFromDropbox/UploadFromDropbox";
import UploadFromGoogleDrive from "./UploadFromGoogleDrive/UploadFromGoogleDrive";
import UploadFromOneDrive from "./UploadFromOneDrive/UploadFromOneDrive";
import UploadLiveWebsite from "./UploadLiveWebsite/UploadLiveWebsite";

function UploadButtons({
  teamId,
  showImportWebsiteForm,
  selectedFile,
  handleUploadFiles,
  handleUpgradeAction,
  page,
  component,
  isDisabled,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const billingLimits = useBillingLimits(teamId);

  return (
    <Box
      display="flex"
      justifyContent={isMobile ? "center" : "space-between"}
      pt={1}
      gap={1}
      flexWrap="wrap"
      textWrap="nowrap"
    >
      <UploadLiveWebsite
        isDisabled={isDisabled}
        showImportWebsiteForm={showImportWebsiteForm}
        isReviewWebsitesEnabled={billingLimits.isReviewWebsitesEnabled}
      />
      <UploadFromDropbox
        isDisabled={isDisabled}
        enableMultiSelect={!selectedFile}
        handleUploadFiles={handleUploadFiles}
        handleUpgradeAction={handleUpgradeAction}
        areFilePickerPluginsEnabled={billingLimits.areFilePickerPluginsEnabled}
        teamId={teamId}
      />
      <UploadFromGoogleDrive
        isDisabled={isDisabled}
        page={page}
        teamId={teamId}
        component={component}
        handleUploadFiles={handleUploadFiles}
        enableMultiSelect={!selectedFile}
        areFilePickerPluginsEnabled={billingLimits.areFilePickerPluginsEnabled}
        handleUpgradeAction={handleUpgradeAction}
      />

      <UploadFromOneDrive
        isDisabled={isDisabled}
        teamId={teamId}
        handleUploadFiles={handleUploadFiles}
        handleUpgradeAction={handleUpgradeAction}
        areFilePickerPluginsEnabled={billingLimits.areFilePickerPluginsEnabled}
        enableMultiSelect={!selectedFile}
      />
    </Box>
  );
}

UploadButtons.propTypes = {
  teamId: PropTypes.string.isRequired,
  showImportWebsiteForm: PropTypes.func.isRequired,
  selectedFile: PropTypes.object,
  handleUploadFiles: PropTypes.func.isRequired,
  handleUpgradeAction: PropTypes.func.isRequired,
  page: PropTypes.string,
  component: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
};

UploadButtons.defaultProps = {
  selectedFile: null,
  page: "",
  component: "",
};

export default UploadButtons;
