/* istanbul ignore file */
/* jscpd:ignore-start */
import { darken } from "@mui/material/styles";

import typography from "./typography";

export const SPACING = 8;

export const Button = {
  styleOverrides: {
    root: {
      textTransform: "inherit",
      letterSpacing: "0.1px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
      },
      fontWeight: typography.fontWeight.semiBold,
      fontSize: typography.fontSizeSm,
      lineHeight: "1.25rem",
      padding: "0.625rem 1rem",
      flexShrink: 0,
    },
    outlined: ({ theme, ownerState }) => ({
      backgroundColor: "white",
      ...(["primary", "primaryV2"].includes(ownerState.color) && {
        borderColor: theme.palette.primary.light,
      }),
      "&:hover": {
        backgroundColor: "white",
        ...(["primary", "primaryV2"].includes(ownerState.color) && {
          borderColor: theme.palette.primary.main,
          backgroundColor: theme.palette.grey[50],
        }),
      },
    }),
    endIcon: ({ ownerState }) => ({
      ...(ownerState.size === "extraSmall" && {
        marginLeft: 0,
      }),
    }),
    iconSizeSmall: {
      fontSize: typography.fontSize_1_2,
    },
    sizeSmall: {
      padding: `0.5rem 0.875rem`,
    },
    sizeLarge: {
      fontSize: typography.fontSizeMd,
      lineHeight: "1.5rem",
      padding: "0.625rem 1.125rem",
    },
  },
  variants: [
    {
      props: { size: "extraSmall" },
      style: {
        minWidth: 46,
        padding: `${SPACING / 2}px ${SPACING * 1.5}px`,
        fontSize: typography.fontSize_1_2,
        lineHeight: typography.lineHeight_1_3,
        "& .MuiButton-startIcon": {
          marginRight: 4,
        },
      },
    },
    {
      // remove 2px padding from the default button to adjust added height by border
      props: { size: "extraSmall", variant: "outlined" },
      style: {
        padding: `calc(${SPACING / 2}px - 1px) ${SPACING * 1.5}px`,
      },
    },
    {
      props: { variant: "highlight" },
      style: ({ ownerState, theme }) => ({
        color: theme.palette[ownerState.color].main,
        backgroundColor: theme.palette[ownerState.color].light,
        fontWeight: typography.fontWeight.semiBold,
      }),
    },
    {
      props: { variant: "outlight" },
      style: ({ ownerState, theme }) => ({
        color: theme.palette[ownerState.color].main,
        backgroundColor: theme.palette[ownerState.color].lighter,
        fontWeight: typography.fontWeight.semiBold,
        border: `1px solid ${theme.palette[ownerState.color].light}`,
      }),
    },
    {
      props: { shape: "round" },
      style: {
        borderRadius: "50%",
        padding: 4,
        aspectRatio: "1/1",
        minWidth: 35,
      },
    },
    {
      props: { shape: "round", size: "small" },
      style: {
        minWidth: 32,
      },
    },
    {
      props: { variant: "text" },
      style: ({ ownerState, theme }) => ({
        "&:hover": {
          backgroundColor: "unset",
          ...(ownerState.color !== "inherit" && {
            color: darken(theme.palette[ownerState.color].main, 0.5),
          }),
        },
      }),
    },
  ],
};

export const ListItem = {
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  paddingLeft: "1rem",
  paddingRight: "1rem",
  gap: "0.5rem",
  borderRadius: 8,
  fontSize: typography.fontSizeSm,
  color: "var(--mui-palette-primary-main)",
  "&.Mui-selected": {
    color: "var(--mui-palette-primary-darker)",
    fontWeight: "var(--mui-typography-fontWeight-bold)",
    "& .MuiListItemText-primary": {
      color: "var(--mui-palette-primary-darker)",
      fontWeight: "var(--mui-typography-fontWeight-bold)",
    },
  },
  "& .MuiListItemIcon-root": {
    minWidth: "unset",

    "& > .MuiSvgIcon-root": {
      fontSize: "var(--mui-typography-textXl-fontSize)",
    },
    "& > .MuiRadio-root": {
      padding: 0,
      paddingRight: "0.5625rem",
    },
    "& > .MuiCheckbox-root": {
      marginLeft: 0,
      marginTop: 0,
      marginBottom: 0,
    },
  },
  "& .MuiListItemText-primary": {
    fontSize: "var(--mui-typography-textSm-fontSize)",
  },
  "& .MuiListItemButton-root": {
    borderRadius: 8,
    padding: 0,
  },
  "&:hover": {
    color: "var(--mui-palette-primary-darker)",
    "& .MuiListItemText-primary": {
      color: "var(--mui-palette-primary-darker)",
    },
  },
  "&:has(> .MuiIconButton-root)": {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
  },
};

export const Typography = {
  defaultProps: {
    variantMapping: {
      text5xl: "p",
      text4xl: "p",
      text3xl: "p",
      text2xl: "p",
      textXl: "p",
      textLg: "p",
      textMd: "p",
      textSm: "p",
      textXs: "p",
    },
    variant: "textMd",
  },
};
/* jscpd:ignore-end */
