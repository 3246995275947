import { redirect, generatePath } from "react-router-dom";

import { APP_ROUTES } from "@shared/constants/routes";

export const history = {
  navigate: null,
  location: null,
  matches: null,
};

export const stripBasename = (path) => {
  const basename = new URL(window.fs.config.appBase).pathname;
  return basename.length > 1 ? path.replace(basename, "") : path;
};

export const redirectToRouteName = (
  routeName,
  { params, options, search } = {}
) => {
  const route = APP_ROUTES[routeName];
  if (!route) {
    throw new Error(`Route ${routeName} not found`);
  }
  const generatedPath = search
    ? `${generatePath(route.path, params, options)}?${search}`
    : generatePath(route.path, params, options);

  return redirect(generatedPath);
};
