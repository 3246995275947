import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Settings } from "@mui/icons-material";
import { IconButton, Popover } from "@mui/material";
import noop from "lodash/noop";

import { Tooltip } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import ReviewerGroupSettingsPanel from "./ReviewerGroupSettingsPanel";

function ReviewerGroupSettings({
  teamId,
  settings,
  stepId,
  isStepTemplate,
  onSettingsChanged,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleClick = (event) => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.REVIEW_STEP_SETTINGS
    );
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Tooltip title={t("REVIEWER.SETTINGS.BUTTON_TOOLTIP")} placement="top">
        <IconButton
          data-testid="reviewer-group-settings-open-settings-icon-button"
          disableFocusRipple
          classes={{
            root: "tour__reviewer-settings-button",
          }}
          onClick={handleClick}
          size="small"
        >
          <Settings fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <Popover
        id="step-settings-popover"
        open={open}
        disableScrollLock
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: "8px",
              px: "16px",
              minWidth: "810px",
              minHeight: "350px",
              ...(isMobile && {
                position: "sticky",
                maxWidth: "95%",
                minWidth: "95%",
              }),
            },
          },
        }}
      >
        <ReviewerGroupSettingsPanel
          teamId={teamId}
          onClose={handleClose}
          settings={settings}
          stepId={stepId}
          isStepTemplate={isStepTemplate}
          onSettingsChanged={onSettingsChanged}
        />
      </Popover>
    </>
  );
}

ReviewerGroupSettings.propTypes = {
  teamId: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    downloadInReview: PropTypes.bool.isRequired,
    downloadNeedsChanges: PropTypes.bool,
    downloadApproved: PropTypes.bool.isRequired,
    commentInReview: PropTypes.bool.isRequired,
    commentNeedsChanges: PropTypes.bool,
    commentApproved: PropTypes.bool.isRequired,
    emailNotifications: PropTypes.bool.isRequired,
    uploadingEnabled: PropTypes.bool.isRequired,
    anonymousReviewers: PropTypes.bool.isRequired,
    digitalSignatureRequired: PropTypes.bool.isRequired,
    passwordProtection: PropTypes.bool.isRequired,
    password: PropTypes.string,
  }).isRequired,
  stepId: PropTypes.string.isRequired,
  isStepTemplate: PropTypes.bool,
  onSettingsChanged: PropTypes.func,
  classes: PropTypes.objectOf(PropTypes.string),
};

ReviewerGroupSettings.defaultProps = {
  isStepTemplate: false,
  onSettingsChanged: noop,
  classes: {},
};

export default ReviewerGroupSettings;
