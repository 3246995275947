/* eslint-disable complexity */
import { redirect } from "react-router-dom";

import reviewService from "@feedback/services/reviewService";
import authenticationService from "@supporting/services/authentication";
import { instance as teamService } from "@supporting/services/team";
import { instance as fileService } from "@workflow/services/fileService";
import projectService from "@workflow/services/projectService";
import * as stepService from "@workflow/services/stepService";

import { LazyLoadPage } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { validateIsRegistered } from "@shared/services/dataLoaders";
import {
  redirectToRouteName,
  stripBasename,
} from "@shared/services/navigation";

import AuthCallback from "./pages/AuthCallback/AuthCallback";
import BatchReview from "./pages/BatchReview/BatchReview";
import EmptySharedStep from "./pages/EmptySharedStep/EmptySharedStep";
import StepAccessRequest from "./pages/StepAccessRequest/StepAccessRequest";
import StepLogin from "./pages/StepLogin/StepLogin";
import ValidateStepPassword from "@feedback/pages/ValidateStepPassword/ValidateStepPassword";

const Workspace = LazyLoadPage(() => import("./pages/Workspace/Workspace"));
const CompareReviews = LazyLoadPage(() =>
  import("@feedback/pages/CompareReviews/CompareReviews")
);

export const startStepShareSession = async ({ request, params }) => {
  try {
    await authenticationService.startStepShareSession({
      ...(params.stepId ? { stepId: params.stepId } : null),
      ...(params.reviewId ? { reviewId: params.reviewId } : null),
    });
  } catch (error) {
    const currentURL = new URL(request.url);
    const search = new URLSearchParams({
      redirect: `${stripBasename(currentURL.pathname)}${currentURL.search}`,
      ...(params.stepId ? { stepId: params.stepId } : null),
      ...(params.reviewId ? { reviewId: params.reviewId } : null),
      ...(params.fileId ? { fileId: params.fileId } : null),
    });
    switch (error.status) {
      case 401: {
        const user = authenticationService.fetchUser();
        const session = authenticationService.fetchSession();
        if (user && !session.verified) {
          search.set("otp", true);
          search.set("email", user.email);
        }
        throw redirectToRouteName("STEP_LOGIN", {
          params: { stepId: params.stepId },
          search: search.toString(),
        });
      }
      case 403:
        throw redirectToRouteName("STEP_SHARE_AUTHORIZE", {
          search: search.toString(),
        });
      case 423:
        throw redirectToRouteName("STEP_ACCESS_REQUEST", {
          params: {
            stepId: params.stepId,
          },
          search: search.toString(),
        });
      default:
        throw redirect(APP_ROUTES.LINK_INVALID.path);
    }
  }
};

async function fetchStepAndResource(stepId, fetchResource) {
  const step = await stepService.getStep(stepId);
  const resource = fetchResource ? await fetchResource() : null;
  return [step, resource];
}

const checkSessionAndFetchResources = async (
  request,
  params,
  fetchResource
) => {
  try {
    await authenticationService.authenticate();
  } catch {
    await startStepShareSession({ request, params });
  }

  try {
    return await fetchStepAndResource(params.stepId, fetchResource);
  } catch (error) {
    await startStepShareSession({ request, params });
    return await fetchStepAndResource(params.stepId, fetchResource);
  }
};

export const startStepShareSessionWithLeftReview = async ({ request }) => {
  const currentURL = new URL(request.url);
  const searchParams = new URLSearchParams(currentURL.search);
  await startStepShareSession({
    request,
    params: {
      reviewId: searchParams.get("leftReviewId"),
    },
  });
  return null;
};

export const loadWorkspaceUsingReview =
  () =>
  async ({ params, request }) => {
    const [step, review] = await checkSessionAndFetchResources(
      request,
      params,
      () => reviewService.fetchReviewById(params.reviewId)
    );

    return { step, review };
  };

function getLatestReview(file, stepId) {
  return file.versions[file.versions.length - 1].reviews.find(
    (review) => review.stepId === stepId
  );
}

export const redirectToReviewFromComment =
  (redirectTo) =>
  ({ params, request }) => {
    const currentURL = new URL(request.url);
    const searchParams = new URLSearchParams(currentURL.search);
    return redirectToRouteName(redirectTo, {
      params,
      search: new URLSearchParams({
        comment: params.commentId,
        ...Object.fromEntries(searchParams),
      }).toString(),
    });
  };

export const loadBatchReviewData = async ({ params, request }) => {
  const [step] = await checkSessionAndFetchResources(request, params);
  const project = await projectService.fetchProjectById(step.projectId);
  const team = await teamService.fetchTeamByTeamId(project.teamId);

  return { step, project, team };
};

export const redirectToWorkspaceFromFile = async ({ params }) => {
  await validateIsRegistered({ routeKey: "WORKSPACE_ALIAS", params });
  const reviews = await reviewService.fetchReviews(params.fileId);
  const latestReview = reviews[0];
  return redirectToRouteName("REVIEW_LINK", {
    params: {
      ...params,
      stepId: latestReview.stepId,
      reviewId: latestReview.id,
    },
  });
};

export const loadAuthCallbackData = async () => {
  return await validateIsRegistered({ routeKey: "WORKSPACE_AUTH_CALLBACK" });
};

// New Redirect Routes
export const startStepShareSessionAndRedirectToNewReviewLink =
  (redirectTo) =>
  async ({ params, request }) => {
    let review;
    const url = new URL(request.url);
    await startStepShareSession({
      request,
      params,
    });

    if (params.fileId) {
      const file = await fileService.getFile(params.fileId);
      review = getLatestReview(file, params.stepId);
    } else {
      const stepReviews = await reviewService.getStepReviews(params.stepId);
      review = stepReviews.reviews[0];
    }

    if (review) {
      return redirectToRouteName(redirectTo, {
        params: {
          stepId: review.stepId,
          reviewId: review.id,
        },
        search: url.search?.substring(1),
      });
    }
    return null;
  };

export const redirectWithSameParams =
  (redirectTo) =>
  ({ params, request }) => {
    const url = new URL(request.url);
    return redirectToRouteName(redirectTo, {
      params,
      search: url.search?.substring(1),
    });
  };

export const redirectToReviewLinkFromLegacyLinksWithVersion =
  (redirectTo) =>
  async ({ params, request }) => {
    const url = new URL(request.url);
    if (url.pathname.startsWith("/review/")) {
      await startStepShareSession({
        request,
        params,
      });
    }

    const fileReviews = await reviewService.fetchReviews(params.fileId);
    const stepReviews = fileReviews.filter(
      (review) => review.stepId === params.stepId
    );

    const versionNumber = parseInt(params.versionNumber.slice(1), 10);
    const review = stepReviews[stepReviews.length - versionNumber];

    if (review) {
      return redirectToRouteName(redirectTo, {
        params: {
          stepId: review.stepId,
          reviewId: review.id,
          commentId: params.commentId,
        },
        search: url.search?.substring(1),
      });
    }
    return null;
  };

const redirectToStepLink = ({ params, request }) => {
  const currentURL = new URL(request.url);
  const redirectParam = new URLSearchParams(currentURL.search).get("redirect");
  if (redirectParam) {
    throw redirect(redirectParam);
  } else {
    throw redirectToRouteName("STEP_LINK", {
      params: {
        stepId: params.stepId,
      },
    });
  }
};

export const loadAccessRequest = async ({ params, request }) => {
  try {
    await authenticationService.authenticate();
    // if the user has access to the step, redirect to the workspace
    await authenticationService.startStepShareSession(params);
    redirectToStepLink({ params, request });
  } catch (error) {
    if (error.status === 423) {
      const { accessRequests } = await stepService.fetchStepAccessRequests(
        params.stepId
      );
      return { accessRequest: accessRequests[0] };
    }
    redirectToStepLink({ params, request });
  }
};

export const redirectIfStepIsAccessible = async ({ params, request }) => {
  try {
    await authenticationService.authenticate();
    // if the user has access to the step, redirect to the workspace
    await authenticationService.startStepShareSession(params);
    redirectToStepLink({ params, request });
  } catch (error) {
    if (error.status === 401) {
      return {};
    }
    redirectToStepLink({ params, request });
  }
};

export const routes = [
  {
    path: APP_ROUTES.STEP_SHARE_AUTHORIZE.path,
    element: <ValidateStepPassword />,
  },
  {
    path: APP_ROUTES.COMPARE_REVIEWS.path,
    element: <CompareReviews />,
    id: "COMPARE_REVIEWS",
    loader: startStepShareSessionWithLeftReview,
  },
  {
    path: APP_ROUTES.STEP_SHARE.path,
    element: <EmptySharedStep />,
    loader: redirectWithSameParams("STEP_LINK"),
  },
  {
    path: APP_ROUTES.STEP_SHARE_WORKSPACE_FILE.path,
    loader: redirectWithSameParams("FILE_LINK"),
  },
  {
    path: APP_ROUTES.FILE_LINK.path,
    loader: startStepShareSessionAndRedirectToNewReviewLink("REVIEW_LINK"),
  },
  {
    path: APP_ROUTES.STEP_LINK.path,
    element: <EmptySharedStep />,
    loader: startStepShareSessionAndRedirectToNewReviewLink("REVIEW_LINK"),
  },
  {
    path: APP_ROUTES.REVIEW_LINK_COMMENT.path,
    loader: redirectToReviewFromComment("REVIEW_LINK"),
  },
  {
    path: APP_ROUTES.REVIEW_LINK.path,
    element: <Workspace />,
    loader: loadWorkspaceUsingReview(),
    id: "REVIEW_LINK",
  },
  {
    path: APP_ROUTES.STEP_SHARE_WORKSPACE_FILE_VERSION.path,
    loader: redirectToReviewLinkFromLegacyLinksWithVersion("REVIEW_LINK"),
  },
  {
    path: APP_ROUTES.STEP_SHARE_WORKSPACE_FILE_VERSION_ISSUE.path,
    loader: redirectToReviewLinkFromLegacyLinksWithVersion(
      "REVIEW_LINK_COMMENT"
    ),
  },
  {
    path: APP_ROUTES.WORKSPACE.path,
    loader: redirectToReviewLinkFromLegacyLinksWithVersion("REVIEW_LINK"),
  },
  {
    path: APP_ROUTES.ISSUE_LINK.path,
    loader: redirectToReviewLinkFromLegacyLinksWithVersion(
      "REVIEW_LINK_COMMENT"
    ),
  },
  {
    path: APP_ROUTES.WORKSPACE_ALIAS.path,
    loader: redirectToWorkspaceFromFile,
  },
  {
    path: APP_ROUTES.WORKSPACE_AUTH_CALLBACK.path,
    element: <AuthCallback />,
    loader: loadAuthCallbackData,
    id: "WORKSPACE_AUTH_CALLBACK",
  },
  {
    path: APP_ROUTES.BATCH_REVIEW.path,
    element: <BatchReview />,
    loader: loadBatchReviewData,
    id: "BATCH_REVIEW",
  },
  {
    path: APP_ROUTES.STEP_ACCESS_REQUEST.path,
    element: <StepAccessRequest />,
    id: "STEP_ACCESS_REQUEST",
    loader: loadAccessRequest,
  },
  {
    path: APP_ROUTES.STEP_LOGIN.path,
    element: <StepLogin />,
    id: "STEP_LOGIN",
    loader: redirectIfStepIsAccessible,
    shouldRevalidate: /* istanbul ignore next */ ({ currentUrl, nextUrl }) => {
      /* istanbul ignore next */
      return currentUrl.pathname !== nextUrl.pathname;
    },
  },
];
