import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";
import CheckoutSummary from "@supporting/components/ActivateSubscriptionDialog/CheckoutSummary";
import authenticationService from "@supporting/services/authentication";
import { instance as billingService } from "@supporting/services/billingService";

import { Dialog } from "@shared/UIKit";

import { billingPrice } from "@shared/props/billing";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import CreditCardForm from "../CreditCardForm/CreditCardForm";
import StripeProvider from "../CreditCardForm/StripeProvider";
import {
  initializeTaxPaymentInfo,
  resetTaxPaymentInfo,
} from "../CreditCardForm/useTaxPaymentInfo";

function ActivateSubscriptionDialog({
  cancel,
  couponData,
  currentPlan,
  billingPlanList,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    initializeTaxPaymentInfo(currentPlan, billingPlanList);
    return () => {
      resetTaxPaymentInfo();
    };
  }, [billingPlanList, currentPlan]);

  const [isOpen, setIsOpen] = useState(true);

  const closeDialog = () => {
    setIsOpen(false);
    healthMetrics.trackCancellation("supporting.billing");
    cancel();
  };

  return (
    <Dialog
      cancel={closeDialog}
      open={isOpen}
      disableEnforceFocus // required for 3D-Secure popups to work properly
      fullScreen
      fullWidth
      maxWidth={false}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection={{ xs: "column", md: "row" }}
      >
        <StripeProvider>
          <CreditCardForm
            fullForm
            defaultValues={{
              cardName: authenticationService.fetchUser()?.fullName,
            }}
            submitButtonLabel={t("BILLING.CHECKOUT.CONFIRM")}
            onSubmitStart={() => {
              healthMetrics.trackStart("supporting.billing");
            }}
            onSubmitError={(error) => {
              healthMetrics.trackFailure("supporting.billing", error);
            }}
            onSubmitSuccess={async ({ teamInfo, plan, payment }) => {
              await billingService.activateSubscription(teamInfo._id, plan.id, {
                country: payment.country,
                vatNumber: payment.taxId,
              });
              healthMetrics.trackSuccess("supporting.billing");

              setIsOpen(false);
              cancel();
            }}
          />
        </StripeProvider>
        <CheckoutSummary couponData={couponData} />
      </Box>
    </Dialog>
  );
}

ActivateSubscriptionDialog.propTypes = {
  couponData: PropTypes.object,
  cancel: PropTypes.func.isRequired,
  currentPlan: PropTypes.object.isRequired,
  billingPlanList: PropTypes.arrayOf(billingPrice).isRequired,
};

ActivateSubscriptionDialog.defaultProps = {
  couponData: {},
};

export default ActivateSubscriptionDialog;
