import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
} from "@mui/material";
import TeamInviteLink from "@supporting/components/TeamInviteLink/TeamInviteLink";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";
import differenceWith from "lodash/differenceWith";
import isEqual from "lodash/isEqual";

import { Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import EditTeamButton from "./editTeamButton/EditTeamButton";
import SameDomainJoin from "./SameDomainJoin";

function EditTeamNameCard({ team }) {
  const { t } = useTranslation();
  const [teamName, setTeamName] = useState(team.name);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [inviteLink, setInviteLink] = useState(
    team.settings?.inviteLink || {
      defaultRole: "",
      requestApproval: false,
      linkId: "",
    }
  );
  const [enableInviteSetting, setEnableInviteSetting] = useState(
    Boolean(team.settings?.inviteLink) || false
  );
  const [enableSameDomainJoin, setEnableSameDomainJoin] = useState(
    Boolean(team.settings?.enableSameDomainJoin)
  );

  const [domains, setDomains] = useState(team.domains);

  const onTeamDomainsChanged = (domains) => {
    setDomains(domains);
  };

  const updateSameDomainJoinSetting = (data) => {
    setEnableSameDomainJoin(data);
  };

  const updateInviteLink = (linkData) =>
    setInviteLink((prev) => ({ ...prev, ...linkData }));

  const handleInputChange = (event) => {
    const { value } = event.target;
    setTeamName(value);
    setErrorText(
      value.length > 0 ? "" : t("TEAM.SETTINGS.TEAM_NAME.MESSAGE_REQUIRED")
    );
  };

  const updateTeamSettings = async function () {
    setIsLoading(true);
    setIsSubmitted(false);
    const updateTeam = {};
    if (teamName !== team.name) {
      updateTeam.name = teamName;
    }

    if (enableInviteSetting) {
      updateTeam.settings = { inviteLink };
      analytics.track(
        !team.settings.inviteLink
          ? analytics.ACTION.ENABLED
          : analytics.ACTION.UPDATED,
        analytics.CATEGORY.TEAM_SETTINGS_INVITE_LINK,
        {
          defaultRole: inviteLink.defaultRole,
          isApprovalRequired: inviteLink.requestApproval,
        }
      );
    } else {
      analytics.track(
        analytics.ACTION.DISABLED,
        analytics.CATEGORY.TEAM_SETTINGS_INVITE_LINK
      );
      updateTeam.settings = { inviteLink: null };
    }

    if (
      !isEqual(
        enableSameDomainJoin,
        Boolean(team.settings?.enableSameDomainJoin)
      )
    ) {
      updateTeam.settings = {
        ...updateTeam.settings,
        enableSameDomainJoin,
      };

      analytics.track(
        analytics.ACTION[enableSameDomainJoin ? "ENABLED" : "DISABLED"],
        analytics.CATEGORY.TEAM_DISCOVERABILITY
      );
    }

    if (!isEqual(domains, team.domains)) {
      updateTeam.domains = differenceWith(domains, team.domains, isEqual);
    }

    try {
      await teamService.updateTeamSettings(updateTeam);
      setIsDisabled(true);
      setIsLoading(false);
      setIsSubmitted(true);
      toastService.sendToast({
        title: "TEAM.EDIT.SETTING_SUCCESS.TITLE",
        body: "TEAM.EDIT.SETTING_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
      analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.TEAM, {
        teamId: team._id,
        teamName: team.name,
      });
    } catch (err) {
      setIsLoading(false);
      setIsSubmitted(false);
      errorHandlerService.handleError(err);
    }
  };

  useEffect(() => {
    const teamInviteLinkChanged =
      !isEqual(Boolean(team?.settings?.inviteLink), enableInviteSetting) ||
      (enableInviteSetting && !isEqual(inviteLink, team?.settings?.inviteLink));
    const isTeamUpdated =
      teamInviteLinkChanged ||
      teamName !== team.name ||
      !isEqual(
        enableSameDomainJoin,
        Boolean(team.settings?.enableSameDomainJoin)
      ) ||
      !isEqual(domains, team.domains);

    if (errorText.length === 0 && isTeamUpdated) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [
    errorText,
    inviteLink,
    team.settings?.inviteLink,
    team.name,
    teamName,
    enableSameDomainJoin,
    enableInviteSetting,
    team.settings?.enableSameDomainJoin,
    team.domains,
    domains,
  ]);

  return (
    <Card data-testid="team-settings-tab">
      <CardHeader title={t("TEAM.SETTINGS.TITLE")} />
      <CardContent>
        <Box m={2}>
          <TextField
            error={Boolean(errorText)}
            type="text"
            required
            onChange={handleInputChange}
            onBlur={handleInputChange}
            label={t("TEAM.SETTINGS.TEAM_NAME.LABEL")}
            inputProps={{
              "data-testid": "team-name-input",
            }}
            fullWidth
            helperText={errorText}
            value={teamName}
          />
        </Box>
        <SameDomainJoin
          teamId={team._id}
          teamName={team.name}
          isMemberRoleEnabled={team.subscriptionLimits.isMemberRoleEnabled}
          domains={domains}
          onTeamDomainsChanged={onTeamDomainsChanged}
          sameDomainJoinSetting={enableSameDomainJoin}
          updateSameDomainJoinSetting={updateSameDomainJoinSetting}
        />
        <Box mr={1} mt={3}>
          <TeamInviteLink
            page={analytics.CATEGORY.TEAM_SETTINGS_INVITE_LINK}
            teamId={team._id}
            inviteLink={inviteLink}
            updateInviteLink={updateInviteLink}
            isMemberRoleEnabled={team.subscriptionLimits.isMemberRoleEnabled}
            enableInviteSetting={enableInviteSetting}
            setEnableInviteSetting={setEnableInviteSetting}
          />
        </Box>
      </CardContent>
      <CardActions>
        <Box p={2} mb={1} ml="auto">
          <EditTeamButton
            saveTeamSettings={updateTeamSettings}
            loading={isLoading}
            disabled={isDisabled}
            submitted={isSubmitted}
          />
        </Box>
      </CardActions>
    </Card>
  );
}

EditTeamNameCard.propTypes = {
  team: teamProp.isRequired,
};

export default EditTeamNameCard;
