import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Waypoint } from "react-waypoint";

import { SettingsSharp } from "@mui/icons-material";
import { Button, Drawer, Box } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";

import { LoadingSpinner } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

import NotificationCenterEmptyState from "./empty-states/NotificationCenterEmptyState";
import NotificationGroup from "./group/NotificationGroup";
import NotificationCenterHeader from "./header/NotificationCenterHeader";

const useStyles = makeStyles((theme) => ({
  Drawer: {
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    background: theme.color.gray[100],
    display: "flex",
  },
  LoadingSpinnerBackdrop: {
    backgroundColor: theme.color.gray[100],
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const selectUserNotificationSortingOption = (user) =>
  user.settings.selectedNotificationSortingOption;

function NotificationCenter() {
  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  const [isOpened, setIsOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [notificationGroups, setNotificationGroups] = useState([]);
  const [showNewNotificationButton, setShowNewNotificationButton] =
    useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [requestedAt, setRequestedAt] = useState(new Date().toISOString());
  const [notificationsChanged, setNotificationsChanged] = useState(false);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [isFirstOpen, setIsFirstOpen] = useState(true);

  const { goTo } = useNavigation();

  const selectedNotificationSortingOption = useActiveUser(
    selectUserNotificationSortingOption
  );

  const fetchNewData = useCallback((value) => {
    setIsLoading(true);
    setTabValue(value);
    setRequestedAt(new Date().toISOString());
    setNotificationsChanged(false);
    setShowNewNotificationButton(false);
    setShouldFetchData(true);
    setIsFirstOpen(false);
    setHasNextPage(false);
    setNotificationGroups([]);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsOpened(false);
  }, []);

  const onNotificationsUpdatedOrDeleted = useCallback(() => {
    setNotificationsChanged(true);
  }, []);

  // Base functionality
  useEffect(() => {
    // Drawer functions
    const openDrawer = () => {
      setIsOpened(true);
      if (notificationsChanged || isFirstOpen) {
        fetchNewData(tabValue);
      }
    };

    const onNotificationsAdded = () => {
      setNotificationsChanged(true);
      if (isOpened) {
        setShowNewNotificationButton(true);
      }
    };

    const onUserUpdated = ({ eventData: { user: updatedUser } }) => {
      if (
        updatedUser.settings.selectedNotificationSortingOption !==
        selectedNotificationSortingOption
      ) {
        fetchNewData(tabValue);
      }
    };

    eventService.addListener(
      EVENT_NAMES.NOTIFICATION_CENTER.ICON.CLICKED,
      openDrawer
    );

    eventService.addListener(
      EVENT_NAMES.NOTIFICATION_CENTER.DRAWER.CLOSE_TRIGGERED,
      closeDrawer
    );

    eventService.addListener(
      EVENT_NAMES.NOTIFICATION_CENTER.NOTIFICATIONS.ADDED,
      onNotificationsAdded
    );

    eventService.addListener(EVENT_NAMES.USER.UPDATED, onUserUpdated);

    websocket.addListener(
      websocket.EVENT_NAMES.IN_APP_NOTIFICATION.UPDATED,
      onNotificationsUpdatedOrDeleted
    );

    websocket.addListener(
      websocket.EVENT_NAMES.IN_APP_NOTIFICATION.DELETED,
      onNotificationsUpdatedOrDeleted
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.NOTIFICATION_CENTER.ICON.CLICKED,
        openDrawer
      );
      eventService.removeListener(
        EVENT_NAMES.NOTIFICATION_CENTER.DRAWER.CLOSE_TRIGGERED,
        closeDrawer
      );
      eventService.removeListener(
        EVENT_NAMES.NOTIFICATION_CENTER.NOTIFICATIONS.ADDED,
        onNotificationsAdded
      );
      eventService.removeListener(EVENT_NAMES.USER.UPDATED, onUserUpdated);
      websocket.removeListeners(
        websocket.EVENT_NAMES.IN_APP_NOTIFICATION.UPDATED
      );
      websocket.removeListeners(
        websocket.EVENT_NAMES.IN_APP_NOTIFICATION.DELETED
      );
    };
  }, [
    isOpened,
    closeDrawer,
    onNotificationsUpdatedOrDeleted,
    notificationsChanged,
    isFirstOpen,
    fetchNewData,
    tabValue,
    selectedNotificationSortingOption,
  ]);

  const redirectToEmailSettings = useCallback(() => {
    closeDrawer();
    goTo(ROUTE_STATES.PROFILE, {
      params: {
        tabName: "notifications",
      },
    });
  }, [closeDrawer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchData = async () => {
      setShowNewNotificationButton(false);
      setNotificationsChanged(false);
      const data = await backend.get("/in-app-notifications", {
        skip: notificationGroups.length,
        limit: 10,
        unread: tabValue === 0,
        requestedAt,
        sort: selectedNotificationSortingOption,
      });

      setNotificationGroups([...notificationGroups, ...data.groups]);
      setHasNextPage(data.hasMore);
      setIsLoading(false);
      setIsPaginationLoading(false);
      setShouldFetchData(false);
    };
    if (shouldFetchData) {
      fetchData();
    }
  }, [shouldFetchData, tabValue, requestedAt]); //eslint-disable-line react-hooks/exhaustive-deps

  const fetchNextPage = () => {
    if (isPaginationLoading) {
      return;
    }

    setIsPaginationLoading(true);
    setShouldFetchData(true);
  };

  const fetchDataInRealTime = useCallback(() => {
    fetchNewData(tabValue);
  }, [fetchNewData, tabValue]);

  const updateNotificationGroupIsRead = useCallback(
    async (groupId, isRead) => {
      if (isPaginationLoading) {
        return;
      }

      await backend.put("/in-app-notifications", { isRead, ids: [groupId] });

      setNotificationGroups(
        tabValue === 0
          ? notificationGroups.filter(
              (notificationGroup) => notificationGroup.id !== groupId
            )
          : notificationGroups.map((group) =>
              group.id === groupId
                ? /* istanbul ignore next */
                  updateGroupIsRead(group, isRead)
                : group
            )
      );
    },
    [isPaginationLoading, notificationGroups, tabValue]
  );

  /* istanbul ignore next */
  function updateGroupIsRead(group, isRead) {
    return {
      ...group,
      isRead,
      notifications: group.notifications.map((notification) => ({
        ...notification,
        isRead,
      })),
    };
  }

  const updateIsReadForAll = useCallback(async () => {
    await backend.put("/in-app-notifications", { isRead: true, all: true });
    fetchNewData(tabValue);
  }, [fetchNewData, tabValue]);

  const hasUnreadNotifications =
    notificationGroups.filter((notificationGroup) => !notificationGroup.isRead)
      .length > 0;

  return (
    <Box data-testid="notification-center">
      <Drawer
        anchor="right"
        classes={{
          paper: classes.Drawer,
        }}
        open={isOpened}
        onClose={closeDrawer}
        data-testid="notification-center-drawer"
      >
        <NotificationCenterHeader
          closeDrawer={closeDrawer}
          selectedTab={tabValue}
          setTab={fetchNewData}
          updateIsReadForAll={updateIsReadForAll}
          hasUnreadNotifications={hasUnreadNotifications}
        />

        <Box flex="1 1 auto" overflow="auto">
          {showNewNotificationButton && (
            <Box
              top={8}
              display="inline-flex"
              justifyContent="center"
              width="100%"
              position="sticky"
            >
              <Button
                size="extraSmall"
                color="accent"
                variant="highlight"
                shape="rounded"
                onClick={fetchDataInRealTime}
                data-testid="notification-center-new-notifications"
              >
                {t("NOTIFICATION_CENTER.NEW_NOTIFICATIONS")}
              </Button>
            </Box>
          )}

          {!isLoading &&
            notificationGroups.map((group) => (
              <NotificationGroup
                {...group}
                beforeDate={requestedAt}
                unread={tabValue === 0}
                key={group.id}
                updateNotificationGroupIsRead={updateNotificationGroupIsRead}
              />
            ))}

          {hasNextPage && (
            <Box height="10px">
              <Waypoint fireOnRapidScroll onEnter={fetchNextPage} />
            </Box>
          )}

          {!isLoading && notificationGroups.length === 0 && (
            <NotificationCenterEmptyState
              type={
                tabValue === 0
                  ? "UNREAD_NOTIFICATIONS_EMPTY_STATE"
                  : "ALL_NOTIFICATIONS_EMPTY_STATE"
              }
            />
          )}

          {isLoading && (
            <Box
              display="flex"
              width={400}
              zIndex={1}
              height="85%"
              alignItems="center"
              position="fixed"
              top={60}
              flexGrow={1}
              justifyContent="center"
              textAlign="center"
              flexDirection="column"
              className={classes.LoadingSpinnerBackdrop}
              data-testid="notification-center-loading-spinner"
            >
              <LoadingSpinner />
            </Box>
          )}

          {isPaginationLoading && (
            <Box display="flex" justifyContent="center" mt={1}>
              <LoadingSpinner />
            </Box>
          )}
        </Box>

        <Box
          display="flex"
          flexGrow={1}
          alignItems="flex-end"
          py={0.25}
          px={1.25}
          justifyContent="space-between"
          boxShadow={theme.shadow["box-shadow-notification-center"]}
          maxHeight={32}
          minHeight={32}
          zIndex={2}
        >
          <Button
            variant="text"
            color="default-light"
            size="small"
            startIcon={<SettingsSharp />}
            onClick={redirectToEmailSettings}
          >
            {t("NOTIFICATION_CENTER.EMAIL_NOTIFICATION_SETTINGS")}
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
}

export default NotificationCenter;
