/* eslint-disable react-hooks/rules-of-hooks,max-lines */

import { getFileExtension } from "@feedback/helpers/getVersionFileExtension";
import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import ACCEPTED_FILE_EXTENSION from "@supporting/constants/acceptedFileExtensions";
import GOOGLE_NATIVE_TYPES from "@supporting/constants/googleNativeFileTypes";
import authenticationService from "@supporting/services/authentication";
import { instance as fileStorageService } from "@supporting/services/fileStorageService";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";
import find from "lodash/find";
import findLast from "lodash/findLast";
import partition from "lodash/partition";

import MEDIA_TYPES from "@shared/constants/mediaTypes";
import pagesEnum from "@shared/constants/pages";
import STORAGE from "@shared/constants/storage";
import {
  FILE_UPLOAD_CONFIG,
  FILE_UPLOAD_STATES,
  FILE_VERSION_UPLOAD_STATUS,
} from "@shared/constants/upload";
import fstgId from "@shared/helpers/fstgId.js";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import browserTabId from "@shared/services/browserTabId";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import filesUploadStatusService from "@shared/services/filesUploadStatusService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";
import { instance as websocket } from "@shared/services/websocket";

const MAX_ERROR_FILE_NAME_LENGTH = 80;
const TAG = "shared:file-service";

const fileCache = new Map();

export let instance;

export function initialize() {
  clearCache();

  websocket.addListener(websocket.EVENT_NAMES.FILE.REMOVED, onFileRemoved);
  websocket.addListener(websocket.EVENT_NAMES.FILE.UPDATED, onFileUpdate);
  websocket.addListener(websocket.EVENT_NAMES.REVIEW.STARTED, onFileUpdate);
  websocket.addListener(
    websocket.EVENT_NAMES.VERSION.UPLOAD.SUCCEEDED,
    onUploadSuccess
  );
  websocket.addListener(
    websocket.EVENT_NAMES.VERSION.UPLOAD.FAILED,
    onUploadFailed
  );
  websocket.addListener(websocket.EVENT_NAMES.REVIEW.UPDATED, onFileUpdate);
  websocket.addListener(websocket.EVENT_NAMES.FILE.BULK_DELETED, onBulkDelete);
  websocket.addListener(
    websocket.EVENT_NAMES.REVIEW.COMMENTS.UPDATED,
    onCommentsUpdated
  );
  websocket.addListener(websocket.EVENT_NAMES.REVIEW.REMOVED, onReviewRemoved);
  eventService.addListener(EVENT_NAMES.ISSUE.UPDATED, onIssueUpdated);
  eventService.addListener(EVENT_NAMES.UPLOAD.PROGRESS, onUploadProgress);
  eventService.addListener(EVENT_NAMES.UPLOAD.ERROR, onUploadError);
  eventService.addListener(
    EVENT_NAMES.TRANSCODING.PROGRESS,
    onTranscodingStarted
  );
  eventService.addListener(EVENT_NAMES.STEP.REMOVED, onStepRemoved);
  eventService.addListener(
    EVENT_NAMES.REVIEW.DECISIONS.UPDATED,
    onReviewDecisionsUpdated
  );
  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);

  instance = {
    getFile,
    removeFile,
    uploadFiles,
    retryTranscoding,
    cancelUpload,
    removeVersion,
    uploadVersion,
    moveToSection,
    resetFileName,
    updateFileName,
    uploadSequence,
    getSectionFiles,
    isFileSupported,
    validateFileHandles,
    updateSequenceUpload,
    convertToAnalyticsFileType,
    bulkRemoveFiles,
  };
}

function emitFileRemovedEvent(file, isBulk = false) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.FILE.REMOVED,
    eventData: { file, isBulk },
  });
}

function emitFileUploadFinishedEvent({
  fileId,
  sectionId,
  processingFile,
  file,
}) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.FILE.UPLOAD.FINISHED,
    eventData: { fileId, sectionId, processingFile, file },
  });
}

function emitVersionRemovedEvent(fileId) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.VERSION.REMOVED,
    eventData: { fileId },
  });
}

function emitFileUpdatedEvent(file, oldFile) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.FILE.UPDATED,
    eventData: { file, oldFile },
  });
}

function emitFileUploadStartedEvent(dummyFile) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.FILE.UPLOAD.STARTED,
    eventData: { dummyFile },
  });
}

function removeFilesFromCacheAndNotify(fileIds) {
  fileIds.forEach((fileId) => {
    const file = fileCache.get(fileId);
    fileCache.delete(fileId);
    if (file) {
      emitFileRemovedEvent(file, fileIds.length > 1);
    }
  });
}

function onFileRemoved(event) {
  removeFilesFromCacheAndNotify(event.fileIds);
}

function emitVersionAddedEvent(file) {
  /* istanbul ignore else */
  if (file.activeVersion) {
    eventService.emitEvent({
      eventName: EVENT_NAMES.VERSION.ADDED,
      eventData: {
        version: file.activeVersion,
        file,
      },
    });
  }
}

function updateFileInCache(file) {
  const fileInCache = fileCache.get(file._id);
  fileCache.set(file._id, file);
  if (fileInCache) {
    file.isProcessing = fileInCache.isProcessing;
    file.progress = fileInCache.progress;
    emitFileUpdatedEvent(file, fileInCache);
  }
  return file;
}

function handleForbiddenError(error, fileId) {
  const file = fileCache.get(fileId);
  if (error.status === 403 && file) {
    fileCache.delete(fileId);
    emitFileRemovedEvent(file);
  }
}

async function fetchFileAndUpdateCache(fileId) {
  return updateFileInCache(await fetchFile(fileId));
}

async function fetchFileAndUpdateCacheNoThrow(fileId) {
  try {
    return updateFileInCache(await fetchFile(fileId));
    // eslint-disable-next-line no-empty
  } catch {}
}

function onFileUpdate(event) {
  return fetchFileAndUpdateCacheNoThrow(event.fileId);
}

async function retryTranscoding(file) {
  const version = file.versions[file.versions.length - 1];
  const user = authenticationService.fetchUser();

  const meta = {
    userId: authenticationService.fetchSession().userId,
    projectId: file.projectId,
    sectionId: file.sectionId,
    stepIds: JSON.stringify([]),
    fileId: file._id,
    template: "FILE_VERSION",
    fileDataId: version.fileDataIds[0],
    retryTranscodingFlow: "true",
    fileName: file.name,
    versionId: version.id,
    key: version.original.key,
    browserTabId: browserTabId.get(),
  };

  analytics.track(
    analytics.ACTION.CLICKED,
    analytics.CATEGORY.RETRY_TRANSCODING,
    {
      fileId: file._id,
      versionId: version.id,
      versionName: version.name,
      versionNumber: version.number,
      versionExtension: version.original.extension,
      versionSize: version.original.sizeInBytes,
      versionType: convertToAnalyticsFileType(version.original.mediaType),
      isUploader: user._id === version.uploadedBy,
      projectId: file.projectId,
    }
  );

  try {
    await backend.post("/file/retry-transcoding", {
      meta,
      projectId: meta.projectId,
    });
    await fetchFileAndUpdateCache(file._id);
  } catch (err) {
    logger.error(TAG, "error retry transcoding file", {
      file,
      err,
    });
  }
}

function startUpload(file, stepIds, fileHandle, page, message) {
  const meta = {
    userId: authenticationService.fetchSession().userId,
    projectId: file.projectId,
    sectionId: file.sectionId,
    stepIds: JSON.stringify(stepIds),
  };

  if (fileHandle.websiteUrl) {
    meta.websiteUrl = fileHandle.websiteUrl;
  }
  if (page === pagesEnum.ONBOARDING_WIZARD) {
    meta.reviewDueDate = new Date(
      new Date().setHours(47, 59, 59, 0)
    ).toISOString();
  }
  if (!file.isDummy) {
    meta.fileId = file._id;
  }
  if (message) {
    meta.message = message;
  }
  return fileStorageService.processFile(
    fileHandle,
    fileHandle.websiteUrl ? "WEBSITE_THUMBNAIL" : "FILE_VERSION",
    meta
  );
}

function uploadVersion({
  file,
  stepIds,
  fileHandle,
  page,
  component,
  message,
  filesUploadStatusId,
}) {
  const uploadStatus = file.versions[file.versions.length - 1].uploadStatus;
  if (
    uploadStatus === FILE_VERSION_UPLOAD_STATUS.PROCESSING ||
    uploadStatus === FILE_VERSION_UPLOAD_STATUS.FAILED
  ) {
    logger.warn(TAG, "version is already processing or failed", {
      file,
    });
    toastService.sendToast({
      title: "TEAM.BRANDING.UPLOAD_ERROR.TITLE",
      body: "UPLOADING_ERROR.TOAST_BODY",
      preset: toastService.PRESETS().ERROR,
    });
    return;
  }

  healthMetrics.trackStart("workflow.upload-file");
  const uploadId = startUpload(file, stepIds, fileHandle, page, message);
  if (filesUploadStatusId) {
    filesUploadStatusService.updateFileUploadStatus({
      filesUploadStatusId,
      uploadId,
      isInProgress: true,
      fileId: file.id,
    });
  }
  file.isProcessing = true;
  /* istanbul ignore next */
  file.progress = {
    state: FILE_UPLOAD_STATES.PENDING,
    uploadId,
    versionNumber:
      file.isDummy || !file.activeVersion ? 1 : file.activeVersion.number + 1,
    mimeType: fileHandle.type,
    storage: fileHandle.isRemote ? fileHandle.storage : STORAGE.LOCAL,
    page,
    component,
  };
  file.message = message;

  fileCache.set(file._id, file);
  if (file.isDummy) {
    emitFileUploadStartedEvent(file);
  } else {
    emitFileUpdatedEvent(file);
  }
}

function clearFileProcessingProgress(processingFile) {
  if (processingFile.isDummy) {
    fileCache.delete(processingFile._id);
    emitFileRemovedEvent(processingFile);
    return null;
  }

  // eslint-disable-next-line no-unused-vars
  const { isProcessing, progress, ...updatedFile } = processingFile;
  fileCache.set(updatedFile._id, updatedFile);
  emitFileUpdatedEvent(updatedFile);
  return updatedFile;
}

function handleNextFileUpload(file, uploadId, onSuccess = false) {
  const { uploadFile } = filesUploadStatusService.handleNextFileUpload(
    file,
    uploadId
  );
  if (!onSuccess) {
    filesUploadStatusService.removeFileUploadStatus(uploadId);
  }
  if (uploadFile.id) {
    uploadVersion({
      file,
      stepIds: uploadFile.stepIds,
      fileHandle: uploadFile.file,
      filesUploadStatusId: uploadFile.id,
    });
  } else {
    /* istanbul ignore else */
    if (!onSuccess) {
      return clearFileProcessingProgress(file);
    }
  }
}

function convertToAnalyticsFileType(mediaType) {
  if (mediaType === MEDIA_TYPES.INTERACTIVE_HTML) {
    return mediaType;
  }
  return mediaType ? mediaType.split("_")[0] : MEDIA_TYPES.UNKNOWN;
}

function trackFileUpload(processingFile, newFile) {
  const team = teamService.getSelectedTeam();
  const lastVersion = newFile.versions[newFile.versions.length - 1];
  /* istanbul ignore next */
  analytics.track(analytics.ACTION.UPLOADED, analytics.CATEGORY.FILE, {
    fileId: newFile._id,
    fileExtension: getFileExtension(lastVersion),
    fileType: convertToAnalyticsFileType(lastVersion.mediaType),
    fileName: newFile.name,
    versionNumber: lastVersion.number.toString(),
    size: lastVersion.websiteUrl
      ? "0"
      : lastVersion.original.sizeInBytes.toString(),
    storage: processingFile.progress.storage,
    page: processingFile.progress.page,
    component: processingFile.progress.component,
    teamId: team._id,
    teamName: team.name,
    ...(filesUploadStatusService.wasVersionUploaded(processingFile)
      ? { label: "automatic-version-stacking" }
      : {}),
  });

  /* istanbul ignore else */
  if (lastVersion.reviews?.length > 0) {
    for (let index = 0; index < lastVersion.reviews.length; index++) {
      analytics.track(analytics.ACTION.STARTED, analytics.CATEGORY.REVIEW, {
        fileId: lastVersion.reviews[index].fileId,
        STEP_ID: lastVersion.reviews[index].stepId,
        versionNumber: lastVersion.reviews[index].number,
      });
    }
  }

  /* istanbul ignore else */
  if (newFile.isLocked) {
    analytics.track(
      analytics.ACTION.REACHED,
      analytics.CATEGORY.SUBSCRIPTION_LIMIT,
      {},
      {},
      analytics.STATE.FILE_QUANTITY
    );
  }
}

function onUploadSuccessSwimlane(processingFile, file) {
  const isUpdate = fileCache.has(file._id);
  file.isNew = processingFile && !isUpdate;

  if (isUpdate) {
    const hasSameFileInProgress = filesUploadStatusService.hasSameFileUploaded({
      processingFile: file,
      isUpdate: false,
    });

    /* istanbul ignore else */
    if (!hasSameFileInProgress) {
      fileCache.set(file._id, file);
      emitFileUpdatedEvent(file);
      emitVersionAddedEvent(file);
    }
  } else {
    file.isNew = false;
    fileCache.delete(processingFile?._id);
    fileCache.set(file._id, file);

    emitFileUploadFinishedEvent({
      fileId: file.id,
      sectionId: file.sectionId,
      processingFile,
      file,
    });
    /* istanbul ignore else */
    if (file.activeVersion) {
      emitVersionAddedEvent(file);
    }
  }
  trackFileUpload(processingFile, file);
}

async function onUploadSuccess(event) {
  const processingFile = getFileByUploadId(event.resourceId);
  filesUploadStatusService.hasSameFileUploaded({
    processingFile,
    isUpdate: true,
  });

  const uploadedFromThisTab = event.browserTabId === browserTabId.get();

  if (uploadedFromThisTab && event.isComplete) {
    healthMetrics.trackSuccess("workflow.upload-file");
  }

  if (uploadedFromThisTab || fileCache.has(event.fileId)) {
    const file = await fetchFile(event.fileId);

    handleNextFileUpload(file, event.resourceId, true);
    filesUploadStatusService.updateFileUploadStatus({
      uploadId: event.resourceId,
      isInProgress: false,
    });

    onUploadSuccessSwimlane(processingFile, file, event);
  } else {
    emitFileUploadFinishedEvent({
      fileId: event.fileId,
      sectionId: event.sectionId,
    });
  }
}

async function handleTranscodingFailed(event, file) {
  let isUpdated = true;
  if (file) {
    isUpdated = fileCache.has(event.fileId);
  }
  if (file && !isUpdated) {
    const newFile = await fetchFile(event.fileId);
    fileCache.delete(file._id);
    fileCache.set(newFile._id, newFile);
    emitFileUploadFinishedEvent({
      fileId: newFile.id,
      sectionId: newFile.sectionId,
      processingFile: file,
      file: newFile,
    });

    handleNextFileUpload(file, event.resourceId, true);
  } else {
    await fetchFileAndUpdateCacheNoThrow(event.fileId);
  }
  analytics.track(
    analytics.ACTION.FAILED,
    analytics.CATEGORY.FILE_TRANSCODING,
    {
      fileId: event.fileId,
    }
  );
  if (event.transcodedBy === authenticationService.fetchSession().userId) {
    toastService.sendToast({
      title: "TRANSCODING_ERROR.TOAST.TITLE",
      body: "TRANSCODING_ERROR.TOAST.BODY",
      preset: toastService.PRESETS().ERROR,
    });
  }
}

async function onUploadFailed(event) {
  const file = getFileByUploadId(event.resourceId);
  if (event.retryTranscodingFlow) {
    await handleTranscodingFailed(event, file);
  } else {
    /* istanbul ignore else */
    if (file) {
      handleNextFileUpload(file, event.resourceId);
      /* istanbul ignore next */
      const toastMessage =
        event.error?.name === "ASSEMBLY_EMPTY"
          ? "TRANSCODING.EMPTY"
          : "TRANSCODING.ERROR";
      toastService.sendToast({
        title: `${toastMessage}.TITLE`,
        body: `${toastMessage}.BODY`,
        preset: toastService.PRESETS().ERROR,
        translationVariables: {
          body: {
            fileName: file.name?.substring(0, MAX_ERROR_FILE_NAME_LENGTH),
          },
        },
      });
    }
  }
  if (
    event.transcodedBy === authenticationService.fetchUser()._id &&
    event.browserTabId === browserTabId.get()
  ) {
    healthMetrics.trackFailure("workflow.upload-file", event.error);
  }
}

async function onCommentsUpdated(event) {
  const file = getFileByReviewId(event.reviewId);
  /* istanbul ignore else */
  if (file) {
    await fetchFileAndUpdateCacheNoThrow(file._id);
    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_SYNC_COMMENTS,
        data: { versionId: event.reviewId },
      },
      "*"
    );
  }
}

async function onIssueUpdated({ eventData: { issue } }) {
  const file = getFileByReviewId(issue.fileVersionId);
  if (file) {
    await fetchFileAndUpdateCacheNoThrow(file._id);
  }
}

async function onReviewRemoved({ fileId }) {
  return await fetchFileAndUpdateCacheNoThrow(fileId);
}

function onUploadProgress({ eventData }) {
  const file = getFileByUploadId(eventData.uploadId);
  /* istanbul ignore else */
  if (file) {
    const updatedFile = {
      ...file,
      progress: {
        ...file.progress,
        state: FILE_UPLOAD_STATES.UPLOADING,
        total: eventData.total,
        loaded: eventData.loaded,
        percentage: eventData.percentage,
      },
    };
    fileCache.set(updatedFile._id, updatedFile);
    emitFileUpdatedEvent(updatedFile);
  }
}

function onUploadError({ eventData: { uploadId, error } }) {
  const file = getFileByUploadId(uploadId);
  /* istanbul ignore else */
  if (file) {
    handleNextFileUpload(file, uploadId);
  }
  healthMetrics.trackFailure("workflow.upload-file", error);
}

function onTranscodingStarted({ eventData }) {
  const file = getFileByUploadId(eventData.uploadId);
  /* istanbul ignore else */
  if (file) {
    const updatedFile = {
      ...file,
      progress: {
        ...file.progress,
        state:
          file.progress.mimeType === "text/html"
            ? FILE_UPLOAD_STATES.IMPORTING
            : FILE_UPLOAD_STATES.TRANSCODING,
      },
    };
    fileCache.set(updatedFile._id, updatedFile);
    emitFileUpdatedEvent(updatedFile);
  }
}

function getFileWithUpdatedReview(fileInCache, updatedReview) {
  return {
    ...fileInCache,
    versions: fileInCache.versions.map((version) => ({
      ...version,
      reviews: version.reviews.map((review) =>
        review.id === updatedReview.id ? updatedReview : review
      ),
    })),
  };
}

function updateReviewInProjectFilesCache(review) {
  const fileInCache = fileCache.get(review.fileId);

  /* istanbul ignore else */
  if (fileInCache) {
    const updatedFile = getFileWithUpdatedReview(fileInCache, review);
    fileCache.set(updatedFile._id, updatedFile);
    emitFileUpdatedEvent(updatedFile);
  }
}

function onStepRemoved({ eventData }) {
  const { stepId, projectId } = eventData;
  for (const file of fileCache.values()) {
    const fileHasReviewInStep =
      file.projectId === projectId &&
      file.versions.some((version) =>
        version.reviews.some((review) => review.stepId === stepId)
      );
    if (fileHasReviewInStep) {
      updateFileInCache({
        ...file,
        versions: file.versions.map((version) => ({
          ...version,
          reviews: version.reviews.filter((review) => review.stepId !== stepId),
        })),
      });
    }
  }
}

function onReviewDecisionsUpdated({ eventData }) {
  for (const review of eventData.reviews) {
    updateReviewInProjectFilesCache(review);
  }
}

async function getSectionFiles(sectionId, skip, limit) {
  const { files, hasMore } = await backend.get(
    `/files/sections/${sectionId}/files`,
    {
      skip,
      limit,
    }
  );
  files.forEach((file) => {
    fileCache.set(file._id, file);
  });
  return { files, hasMore };
}

async function getFile(fileId) {
  return fileCache.has(fileId)
    ? fileCache.get(fileId)
    : await fetchFileAndUpdateCache(fileId);
}

async function updateFileName(fileId, name) {
  const newFile = await backend.put(`/files/${fileId}/name`, { name });
  analytics.track(analytics.ACTION.RENAMED, analytics.CATEGORY.FILE);
  return updateFileInCache(newFile);
}

async function resetFileName(fileId) {
  const file = await backend.delete(`/files/${fileId}/name`);
  return updateFileInCache(file);
}

async function removeFile(file) {
  /* istanbul ignore else */
  if (file.isProcessing) {
    healthMetrics.trackCancellation("workflow.upload-file");
    fileStorageService.cancel(file.progress.uploadId);
  }
  analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.FILE, {
    fileId: file._id,
  });
  await backend.delete(`/files/${file._id}`);
}

async function removeVersion(file, versionId) {
  const updatedFile = await backend.delete(
    `/files/${file._id}/versions/${versionId}`
  );
  emitVersionRemovedEvent(file._id);
  analytics.track(analytics.ACTION.DELETED, analytics.CATEGORY.VERSION, {
    fileId: file._id,
    versionId,
  });
  return updateFileInCache(updatedFile);
}

async function onBulkDelete(event) {
  const versionRemoves = event.versionRemoves;
  versionRemoves.forEach((versionRemove) => {
    const file = fileCache.get(versionRemove.id);
    if (file) {
      const lastVersion = findLast(
        file.versions,
        (version) => version.id !== versionRemove.versionId
      );
      const updatedFile = {
        ...file,
        versions: file.versions.filter(
          (version) => version.id !== versionRemove.versionId
        ),
        latestVersionCreatedAt: lastVersion.createdAt,
        name: file.hasModifiedName ? file.name : lastVersion.name,
      };
      updateFileInCache(updatedFile);
      emitVersionRemovedEvent(versionRemove.id);
    }
  });
  await teamService.updateUsedBillingLimitsOfSelectedTeam();
}

async function bulkRemoveFiles(
  projectId,
  { includedFileIds, excludedFileIds, allFiles }
) {
  await backend.post(`/projects/${projectId}/bulkDelete`, {
    includedFileIds,
    excludedFileIds,
    allFiles,
  });
}

function generateDummyFile({ stepIds, projectId, sectionId, fileHandle }) {
  const user = authenticationService.fetchUser();
  const dummyFile = {
    projectId,
    sectionId,
    isDummy: true,
    isLocked: false,
    name: fileHandle.name,
    role: { isOwner: true },
    created: new Date().toISOString(),
    _id: fileHandle.id || fstgId.generate(),
    versions: [
      {
        number: 1,
        reviews: [],
        mediaType: "",
        fileDataIds: [],
        uploadedBy: user.id,
        uploadedByUser: user,
        id: fstgId.generate(),
        name: fileHandle.name,
        allReviewsApproved: false,
        createdAt: new Date().toISOString(),
        original: {
          mimeType: "",
        },
      },
    ],
  };

  /* istanbul ignore else */
  if (stepIds.length > 0) {
    dummyFile.activeVersion = {
      number: 1,
      reviews: [],
      stepId: stepIds[0],
      reviewDueDate: null,
      thumbnail: { url: "" },
      original: { mimeType: "" },
      role: { isReviewer: false },
      commentCount: {
        total: 0,
        resolved: 0,
        unresolved: 0,
      },
      permissions: {
        canSetDueDate: false,
        canManageIssueResolutions: true,
      },
    };
  }
  return dummyFile;
}

function uploadFile({
  page,
  message,
  stepIds,
  projectId,
  component,
  sectionId,
  fileHandle,
  filesUploadStatusId,
}) {
  const dummyFile = generateDummyFile({
    projectId,
    sectionId,
    stepIds,
    fileHandle,
  });
  return uploadVersion({
    file: dummyFile,
    stepIds,
    fileHandle,
    page,
    component,
    message,
    filesUploadStatusId,
  });
}

async function uploadFiles({
  page,
  files,
  steps,
  stepIds,
  component,
  projectId,
  sectionId,
  isDragAndDrop,
}) {
  const { allFiles, filesUploadStatusId } =
    await filesUploadStatusService.setFilesUploadStatus(files, sectionId);
  return Promise.all(
    allFiles.map((uploadingFile) => {
      const { isNewVersion, existingFile, updatedStepIds } =
        filesUploadStatusService.validateVersionFile(
          uploadingFile,
          isDragAndDrop,
          stepIds,
          filesUploadStatusId,
          steps
        );
      if (isNewVersion) {
        return uploadVersion({
          file: existingFile,
          filesUploadStatusId,
          stepIds: updatedStepIds,
          fileHandle: uploadingFile.file,
        });
      }
      return uploadFile({
        page,
        stepIds,
        component,
        sectionId,
        projectId,
        filesUploadStatusId,
        fileHandle: uploadingFile.file,
      });
    })
  );
}

function updateSequenceVersion({ fileId, ...data }) {
  const file = fileCache.get(fileId);
  /* istanbul ignore else */
  if (file) {
    const updatedFile = { ...file };
    updatedFile.isProcessing = true;
    updatedFile.progress = updatedFile.progress || {};
    updatedFile.progress.uploadId = data.uploadId;
    updatedFile.progress.state = data.state;
    updatedFile.name = data.videoName;

    fileCache.set(updatedFile._id, updatedFile);
    emitFileUpdatedEvent(updatedFile);
  }
}

function uploadSequence({
  state,
  fileId,
  stepIds,
  uploadId,
  projectId,
  videoName,
  sectionId,
  pregeneratedFileId,
}) {
  if (fileId) {
    return updateSequenceVersion({
      state,
      fileId,
      uploadId,
      videoName,
    });
  }
  const fileHandle = {
    name: videoName,
    id: pregeneratedFileId,
  };
  const dummyFile = generateDummyFile({
    projectId,
    sectionId,
    stepIds,
    fileHandle,
  });

  dummyFile.isProcessing = true;
  dummyFile.progress = {
    state,
    uploadId,
    component: null,
    versionNumber: 1,
    pregeneratedFileId,
    storage: STORAGE.HARDWARE,
    page: pagesEnum.PROJECT_DASHBOARD,
  };
  dummyFile.message = "message";

  fileCache.set(dummyFile._id, dummyFile);
  emitFileUploadStartedEvent(dummyFile);
}

function updateSequenceUpload({ pregeneratedFileId, fileId, ...data }) {
  if (fileId) {
    return updateSequenceVersion({ fileId, ...data });
  }
  const file = getFileByUploadId(pregeneratedFileId);
  /* istanbul ignore else */
  if (file) {
    const updatedFile = { ...file };
    updatedFile.name = data.videoName;
    updatedFile.progress.state = data.state;
    updatedFile.progress.uploadId = data.uploadId;

    fileCache.set(updatedFile._id, updatedFile);
    emitFileUpdatedEvent(updatedFile);
  }
}

function isFileSupported(file) {
  if (GOOGLE_NATIVE_TYPES.includes(file.type)) {
    return true;
  }
  const pointIndex = file.name.lastIndexOf(".");
  if (pointIndex === -1) {
    return false;
  }
  const extension = file.name.slice(pointIndex + 1);
  return ACCEPTED_FILE_EXTENSION.includes(extension.toLowerCase());
}

function hasExtension(fileName) {
  const extension = fileName.split(".").pop();
  return fileName !== extension;
}

function toBytes(size, unit) {
  const number = ["bytes", "kB", "MB", "GB", "TB", "PB"].indexOf(unit);
  return size * Math.pow(1000, number);
}

const fileSizeLimit = toBytes(
  FILE_UPLOAD_CONFIG.FILESIZE_MAX,
  FILE_UPLOAD_CONFIG.FILESIZE_UNIT
);

async function validateFileHandles(files, teamId) {
  const [allowedFiles, filesWithWrongExtensions] = partition(files, (file) =>
    isFileSupported(file)
  );

  filesWithWrongExtensions.forEach((fileWithWrongExtension) => {
    const fileHasExtension = hasExtension(fileWithWrongExtension.name);
    const tagTitle = fileHasExtension
      ? "Upload tried for file with wrong extension"
      : "File has no extension";
    logger.warn(TAG, tagTitle, {
      fileName: fileWithWrongExtension.name,
    });
    const toastTranslation = fileHasExtension
      ? "UPLOAD.UNSUPPORTED_FORMAT"
      : "UPLOAD.FILE_WIHOUT_EXTENSION";
    toastService.sendToast({
      title: `${toastTranslation}.TITLE`,
      body: `${toastTranslation}.BODY`,
      preset: toastService.PRESETS().ERROR_DELAYED,
    });
  });
  const [validFiles, rejectedFiles] = partition(
    allowedFiles,
    (file) => file.size <= fileSizeLimit && file.size > 0
  );
  rejectedFiles.forEach((rejectedFile) =>
    toastService.sendToast({
      title: `${
        rejectedFile.size
          ? "FILE.VERSION.EXCEEDS_SIZE"
          : "FILE.VERSION.ZERO_SIZE"
      }.TITLE`,
      body: `${
        rejectedFile.size
          ? "FILE.VERSION.EXCEEDS_SIZE"
          : "FILE.VERSION.ZERO_SIZE"
      }.BODY`,
      preset: toastService.PRESETS().WARNING,
      translationVariables: {
        body: {
          fileName: rejectedFile.name,
          size: FILE_UPLOAD_CONFIG.FILESIZE_MAX,
          unit: FILE_UPLOAD_CONFIG.FILESIZE_UNIT,
        },
      },
    })
  );
  if (validFiles.length === 0) {
    return Promise.reject();
  }
  if (validFiles.length > FILE_UPLOAD_CONFIG.MAX_FILES_BULK_UPLOADABLE) {
    logger.warn(TAG, "bulk file upload limit exceeded");
    toastService.sendToast({
      title: "UPLOAD.MAX_FILES_LIMIT_EXCEEDED.TITLE",
      body: "UPLOAD.MAX_FILES_LIMIT_EXCEEDED.BODY",
      preset: toastService.PRESETS().WARNING,
      translationVariables: {
        body: {
          maxFiles: FILE_UPLOAD_CONFIG.MAX_FILES_BULK_UPLOADABLE,
        },
      },
    });
    return Promise.reject();
  }
  await teamService.checkStorageSizeExceeded(
    teamId,
    validFiles.reduce((sum, file) => sum + file.size, 0)
  );
  return validFiles;
}

async function cancelUpload(file) {
  healthMetrics.trackCancellation("workflow.upload-file");
  try {
    if (file.progress) {
      await fileStorageService.cancel(file.progress.uploadId);
    }
    // check if flow is new
    const uploadStatus = file.versions[file.versions.length - 1].uploadStatus;
    if (
      [
        FILE_VERSION_UPLOAD_STATUS.FAILED,
        FILE_VERSION_UPLOAD_STATUS.PROCESSING,
      ].includes(uploadStatus)
    ) {
      if (file.versions.length > 1) {
        await removeVersion(file, file.versions[file.versions.length - 1].id);
      } else {
        await removeFile(file);
      }
    }

    logger.info(TAG, "canceled file upload", {
      file,
    });
  } catch (error) {
    logger.error(TAG, "error canceling file upload", {
      file,
      error,
    });
  }
  if (file.progress) {
    return handleNextFileUpload(file, file.progress.uploadId);
  }
}

async function moveToSection(fileId, sectionId, source) {
  analytics.track(analytics.ACTION.MOVED_TO_SECTION, analytics.CATEGORY.FILE, {
    action: source,
  });
  const file = await backend.put(`/files/${fileId}/section`, { sectionId });
  return updateFileInCache(file);
}

async function fetchFile(fileId) {
  try {
    return await backend.get(`/files/${fileId}`);
  } catch (error) {
    handleForbiddenError(error, fileId);
    throw error;
  }
}

function getFileByUploadId(uploadId) {
  return find(Array.from(fileCache.values()), {
    isProcessing: true,
    progress: { uploadId },
  });
}

function getFileByReviewId(reviewId) {
  return Array.from(fileCache.values()).find((file) =>
    fileContainsReview(file, reviewId)
  );
}

function fileContainsReview(file, reviewId) {
  return file.versions
    .flatMap((version) => version.reviews)
    .some((review) => review.id === reviewId);
}

function clearCache() {
  fileCache.clear();
}
