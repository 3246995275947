import { redirect } from "react-router-dom";

import { processStepAccessControlDecision } from "@feedback/services/stepAccessControl";
import { instance as teamService } from "@supporting/services/team";
import EmptyProject from "@workflow/components/EmptyProject/EmptyProject";
import projectSwitcherService from "@workflow/services/projectSwitcherService";

import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";
import {
  fetchDefault,
  validateIsRegistered,
  validateIsQualificationComplete,
} from "@shared/services/dataLoaders";

import ProjectTemplate from "./pages/ProjectTemplate/ProjectTemplate";
import ProjectView from "./pages/ProjectView/ProjectView/ProjectView";
import BulkDownload from "@workflow/pages/BulkDownload/BulkDownload";
import Dashboard from "@workflow/pages/Dashboard/Dashboard";
import Insights from "@workflow/pages/Insights/Insights";
import ProjectLayout from "@workflow/pages/ProjectLayout/ProjectLayout";

export async function redirectToDefaultTeamInsights({ params }) {
  const { user, team } = await fetchDefault(ROUTE_STATES.INSIGHTS_GENERAL)({
    params,
  });
  if (user && team) {
    return redirect(`/insights/${team._id}`);
  }
  return null;
}

export async function redirectToSelectedProjectIfPossible({ params, request }) {
  const { team } = await fetchDefault(
    ROUTE_STATES.DASHBOARD,
    true
  )({
    params,
    request,
  });
  const project = await projectSwitcherService.determineSelectedProject(
    team._id
  );
  const url = request?.url && new URL(request.url);

  if (project) {
    return redirect(`/projects/${project.id}${url?.search || ""}`);
  }
  return null;
}

export function projectTemplateLoader(routeKey) {
  return async function ({ params, request }) {
    const url = new URL(request.url);

    const user = await validateIsRegistered({ routeKey, url });
    const team = await teamService.determineSelectedTeamByProjectId(
      params.projectTemplateId,
      true
    );

    if (user && team) {
      validateIsQualificationComplete(team);
      return { user, team };
    }
    return null;
  };
}

export function projectIdLoader(routeKey) {
  return async function ({ params, request }) {
    const url = new URL(request.url);

    const user = await validateIsRegistered({ routeKey, url });
    const team = await teamService.determineSelectedTeamByProjectId(
      params.projectId
    );

    await validateIsQualificationComplete(team);
    const project = await projectSwitcherService.determineSelectedProjectById(
      team._id,
      params.projectId
    );
    if (!project) {
      return redirect(APP_ROUTES.LINK_INVALID.path);
    }
    processStepAccessControlDecision(request, project);
    return { user, team, project };
  };
}

export const redirectToProjectTemplateRoute = ({ params }) => {
  return redirect(`/projects/templates/${params.projectTemplateId}`);
};

export const routes = [
  {
    path: APP_ROUTES.INSIGHTS_SPECIFIC.path,
    element: (
      <Dashboard>
        <Insights />
      </Dashboard>
    ),
    loader: fetchDefault(ROUTE_STATES.INSIGHTS_SPECIFIC),
    id: "INSIGHTS_SPECIFIC",
  },
  {
    path: APP_ROUTES.INSIGHTS_GENERAL.path,
    loader: redirectToDefaultTeamInsights,
  },
  {
    path: APP_ROUTES.BULK_DOWNLOAD.path,
    element: <BulkDownload />,
    id: "BULK_DOWNLOAD",
  },
  {
    id: "DASHBOARD",
    path: APP_ROUTES.DASHBOARD.path,
    element: <ProjectLayout />,
    children: [
      {
        index: true,
        id: "EMPTY_PROJECT",
        element: <EmptyProject />,
        loader: redirectToSelectedProjectIfPossible,
      },
      {
        id: "PROJECT_ID",
        path: APP_ROUTES.PROJECT_ID.path,
        element: <ProjectView />,
        loader: projectIdLoader(ROUTE_STATES.PROJECT_ID),
      },
      {
        path: APP_ROUTES.TEMPLATE_ID.path,
        element: <ProjectTemplate />,
        loader: projectTemplateLoader("TEMPLATE_ID"),
        id: "TEMPLATE_ID",
      },
    ],
  },
  {
    path: APP_ROUTES.LEGACY_TEMPLATE_ID.path,
    loader: redirectToProjectTemplateRoute,
    id: "LEGACY_TEMPLATE_ID",
  },
];

export const unsupportedRoutes = [];
