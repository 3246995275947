import PropTypes from "prop-types";
import { ReactElement, forwardRef } from "react";

import { Box } from "@mui/material";
import MUITooltip from "@mui/material/Tooltip";

import { Text } from "@shared/UIKit";

/**
 * UIKit Tooltip Component
 * @description A tooltip component that can be used to display information to the user.
 * This component extends Material UI Tooltip component. It can hold a reference.
 * @param {object} props - Component props
 * @param {ReactElement} [props.children] [Required] - The content of the tooltip.
 * @param {boolean?} [props.disabled] [Optional] - If true, the tooltip will be disabled and will be render the children component without the tooltip.
 * @param {string?} [props.className] [Optional] - Additional class name
 * @param {object?} [props.classes] [Optional] - Additional classes that will be passed to MUI Tooltip component
 * @returns {ReactElement} - React component
 */
const Tooltip = forwardRef(
  (
    {
      children,
      disabled,
      className,
      classes,
      title,
      description,
      variant,
      shortcutKeys,
      ...restProps
    },
    ref
  ) => {
    let tooltipTitle = title;

    if (shortcutKeys.length > 0 || description) {
      tooltipTitle = (
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Text variant="textSm" fontWeight="fontWeight.medium">
              {title}
              {shortcutKeys.map((keyName) => (
                <Box
                  key={keyName}
                  component="span"
                  display="inline-flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  p={0.5}
                  data-testid="short-key-code"
                  sx={{
                    bgcolor: "grey.700",
                    borderRadius: "2px",
                    width: "auto",
                    height: "10px",
                    ml: 0.5,
                    fontSize: "textXs.fontSize",
                    fontWeight: "fontWeight.semiBold",
                  }}
                >
                  {keyName}
                </Box>
              ))}
            </Text>
          </Box>

          {description && (
            <>
              <Box my={0.5} width="100%" height="1px" bgcolor="grey.700" />
              <Text variant="textXs">{description}</Text>
            </>
          )}
        </Box>
      );
    }
    return disabled ? (
      <Box ref={ref} className={className} data-testid="tooltip-disabled">
        {children}
      </Box>
    ) : (
      <MUITooltip
        ref={ref}
        data-testid="tooltip-enabled"
        disableInteractive
        classes={{
          ...classes,
        }}
        arrow
        enterDelay={500}
        enterNextDelay={500}
        title={tooltipTitle}
        slotProps={{
          tooltip: {
            "data-tooltip-variant": variant,
          },
        }}
        {...restProps}
      >
        {children}
      </MUITooltip>
    );
  }
);
Tooltip.displayName = "Tooltip";

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
  disabled: PropTypes.bool,
  title: PropTypes.node.isRequired,
  description: PropTypes.string,
  variant: PropTypes.string,
  shortcutKeys: PropTypes.arrayOf(PropTypes.string),
};

Tooltip.defaultProps = {
  className: "",
  disabled: false,
  classes: {},
  description: "",
  variant: "default",
  shortcutKeys: [],
};

export default Tooltip;
