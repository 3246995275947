import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Tooltip, Box } from "@shared/UIKit";

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 2001,
    marginBottom: 4,
    maxWidth: 194,
  },
}));

function RestartTrialButton({ onClick, size, testId }) {
  const team = useSelectedTeam();
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Tooltip
      classes={{ popper: classes.popper }}
      data-testid="restart-trial-button-tooltip"
      title={
        !team.permissions.canManageBilling
          ? t("RESTART_TRIAL.SECONDARY_TOOLTIP_DISABLED")
          : t("RESTART_TRIAL.SECONDARY_CTA.TOOLTIP")
      }
      placement="top"
    >
      <Box component="span">
        <Button
          data-testid={testId}
          variant="outlined"
          color="primary"
          size={size}
          onClick={onClick}
          disabled={!team.permissions.canManageBilling}
        >
          {t("RESTART_TRIAL.SECONDARY_CTA")}
        </Button>
      </Box>
    </Tooltip>
  );
}
RestartTrialButton.defaultProps = {
  size: "large",
  testId: "restart-trial-button",
};

RestartTrialButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.string,
  testId: PropTypes.string,
};
export default RestartTrialButton;
