import PropTypes from "prop-types";
import { useState, useCallback, memo } from "react";

import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, Divider } from "@mui/material";

import { useMediaQuery } from "@shared/hooks";
import { projectProps, sectionProps } from "@shared/props/project";

import SectionHeaderDeleteMenuItem from "./SectionHeaderDeleteMenuItem/SectionHeaderDeleteMenuItem";
import SectionHeaderMoveMenuItem from "./SectionHeaderMoveMenuItem/SectionHeaderMoveMenuItem";
import SectionHeaderRenameMenuItem from "./SectionHeaderRenameMenuItem/SectionHeaderRenameMenuItem";

function SectionHeaderMenu({
  onRename,
  project,
  section,
  isTemplate,
  ...restProps
}) {
  const [anchorEl, setAnchorEl] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const sectionPosition = project.sections.findIndex(
    ({ id }) => id === section.id
  );

  const canMove = {
    up: sectionPosition > 0,
    down: sectionPosition < project.sections.length - 1,
  };
  const hasMenuItems = isMobile || canMove.up || canMove.down;

  const openMenu = useCallback(({ currentTarget }) => {
    setAnchorEl(currentTarget);
  }, []);

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    hasMenuItems && (
      <>
        <IconButton
          aria-controls="section-header-menu"
          aria-haspopup="true"
          data-testid="section-header-menu-open-icon"
          onClick={openMenu}
          size="small"
        >
          <MoreVert fontSize="small" />
        </IconButton>

        {anchorEl && (
          <Menu
            id="section-header-menu"
            data-testid="section-header-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            {...restProps}
            onClick={closeMenu}
          >
            {isMobile && <SectionHeaderRenameMenuItem onRename={onRename} />}

            {canMove.up && (
              <SectionHeaderMoveMenuItem
                project={project}
                section={section}
                sectionPosition={sectionPosition}
                moveUp
                isTemplate={isTemplate}
              />
            )}

            {canMove.down && (
              <SectionHeaderMoveMenuItem
                project={project}
                section={section}
                sectionPosition={sectionPosition}
                isTemplate={isTemplate}
              />
            )}
            <Divider />
            <SectionHeaderDeleteMenuItem
              section={section}
              project={project}
              closeMenu={closeMenu}
              isTemplate={isTemplate}
            />
          </Menu>
        )}
      </>
    )
  );
}

SectionHeaderMenu.defaultProps = {
  isTemplate: false,
};

SectionHeaderMenu.propTypes = {
  onRename: PropTypes.func.isRequired,
  section: sectionProps.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  isTemplate: PropTypes.bool,
};

export default memo(SectionHeaderMenu);
