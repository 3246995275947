import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import UsageBar from "./UsageBar";
import UsageTooltip from "./UsageTooltip";

function MaxReviewStep({ team, openBillingDialog }) {
  const { t } = useTranslation();

  return (
    <UsageBar
      name="BILLING.FEATURE.REVIEW_STEPS.LABEL"
      tooltip={
        <UsageTooltip
          usageMessage1={t("BILLING.FEATURE.REVIEW_STEPS.HELP_MESSAGE.PART1")}
          hrefText={t("BILLING.UPGRADE_YOUR_PLAN")}
          openBillingDialog={openBillingDialog}
          usageMessage2={t("BILLING.FEATURE.REVIEW_STEPS.HELP_MESSAGE.PART2")}
        />
      }
      used={team.subscriptionLimits.maxReviewStepsNumber}
      isLinearProgressVisible={false}
    />
  );
}

MaxReviewStep.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default MaxReviewStep;
