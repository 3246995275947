import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";
import getUsedTeamStoragePercentage from "@supporting/helpers/getUsedTeamStoragePercentage";

import { Text } from "@shared/UIKit";

import coreBytes from "@shared/helpers/coreBytes";
import { teamProp } from "@shared/props/team";

import UsageBar from "./UsageBar";
import UsageTooltip from "./UsageTooltip";

function SecureStorageUsage({ team, openBillingDialog }) {
  const { t } = useTranslation();

  const getStorageLimit = () => {
    if (team.subscriptionLimits.maxStorageSizeBytes > -1) {
      return coreBytes(team.subscriptionLimits.maxStorageSizeBytes, 1);
    }
    return t("BILLING.UNLIMITED");
  };

  const usedStoragePercentage = getUsedTeamStoragePercentage(team);

  return (
    <UsageBar
      name="BILLING.FEATURE.SECURE_STORAGE.LABEL"
      tooltip={
        <UsageTooltip
          usageMessage1={t("BILLING.FEATURE.SECURE_STORAGE.HELP_MESSAGE.PART1")}
          hrefText={
            team.subscriptionLimits.maxStorageSizeBytes > -1
              ? t("BILLING.UPGRADE_YOUR_PLAN")
              : null
          }
          openBillingDialog={openBillingDialog}
          usageMessage2={t("BILLING.FEATURE.SECURE_STORAGE.HELP_MESSAGE.PART2")}
        />
      }
      used={coreBytes(team.usedBillingLimits?.usedStorageSizeByte, 1)}
      limit={getStorageLimit()}
      percentage={usedStoragePercentage}
      isHelperVisible={usedStoragePercentage > 80}
      helperTooltip={
        <>
          <Text variant="subtitle1" color="text.secondary">
            {t("BILLING.FEATURE.SECURE_STORAGE.LIMIT_HELP.DESCRIPTION-1")}
          </Text>
          <Text variant="subtitle1" color="text.secondary">
            <Link
              onClick={openBillingDialog}
              color="upgrade"
              variant="textSm"
              fontWeight="fontWeight.medium"
            >
              {t("BILLING.FEATURE.SECURE_STORAGE.LIMIT_HELP.DESCRIPTION_CTA")}
            </Link>{" "}
            {t("BILLING.FEATURE.SECURE_STORAGE.LIMIT_HELP.DESCRIPTION-2")}
          </Text>
        </>
      }
      helperText="BILLING.FEATURE.SECURE_STORAGE.LIMIT_HELP.BUTTON"
    />
  );
}

SecureStorageUsage.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default SecureStorageUsage;
