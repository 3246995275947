import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { instance as analytics } from "@shared/services/analytics";

import WebsiteImportIcon from "@assets/img/icons/website_icon.svg";

import RemoteButton from "../../RemoteButton";
import UpsellReviewLiveWebsitesDialog from "./UpsellReviewLiveWebsitesDialog";

function UploadLiveWebsite({
  showImportWebsiteForm,
  isReviewWebsitesEnabled,
  isDisabled,
}) {
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const { t } = useTranslation();

  const onClick = useCallback(
    () => {
      if (isReviewWebsitesEnabled) {
        showImportWebsiteForm();
        return;
      }
      setIsUpgradePlanDialogOpen(true);
      analytics.track(
        analytics.ACTION.REACHED,
        analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        {},
        {},
        analytics.STATE.REVIEW_LIVE_WEBSITES_FEATURE
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isReviewWebsitesEnabled, showImportWebsiteForm]
  );

  return (
    <>
      <RemoteButton
        testId="upload-panel-live-website"
        onClick={onClick}
        disabled={isDisabled}
        viewBox="0 0 16 15"
        isFeatureEnabled={isReviewWebsitesEnabled}
        icon={WebsiteImportIcon}
        label={t("FILE.UPLOAD_PANEL.IMPORT_WEBSITE")}
        noUpsellPopup
      />
      <UpsellReviewLiveWebsitesDialog
        isUpgradePlanDialogOpen={isUpgradePlanDialogOpen}
        onCancel={() => setIsUpgradePlanDialogOpen(false)}
      />
    </>
  );
}

UploadLiveWebsite.propTypes = {
  showImportWebsiteForm: PropTypes.func.isRequired,
  isReviewWebsitesEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default UploadLiveWebsite;
