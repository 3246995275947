/* eslint-disable complexity */
/* eslint-disable max-lines */
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { AddCircleOutline, EditOutlined } from "@mui/icons-material";
import { IconButton, Fade } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { AddProjectGate } from "@supporting/hooks/useGatedFeature/featureGates";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import CollapseButton from "@workflow/components/CollapseButton/CollapseButton";
import CreateProjectDialog from "@workflow/components/CreateProjectDialog/CreateProjectDialog";
import projectService from "@workflow/services/projectService";
import classNames from "classnames";
import chunk from "lodash/chunk";

import { Box, Text, Tooltip } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { folderProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";
import FSTGTypography from "@shared/theme/typography";

import { useSidebarState } from "../../sidebarState";
import LazyRenderChunk from "../ProjectList/LazyRenderChunk/LazyRenderChunk";
import ProjectListItem from "../ProjectList/ProjectListItem/ProjectListItem";
import DeleteFolder from "./DeleteFolder/DeleteFolder";
import RenameFolderDialog from "./RenameFolderDialog/RenameFolderDialog";

const useStyles = makeStyles((theme) => ({
  folder: {
    position: "relative",
    border: `1px solid ${theme.color.gray[200]}`,
    borderRadius: 2,
    overflow: "hidden",
    transition: ".4s ease-out",
    "&:hover": {
      borderColor: theme.color.gray[50],
    },
  },
  folderHighlighted: {
    borderColor: theme.color.gray[300],
  },
  folderHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover $headerActions": {
      display: "flex",
    },
    marginLeft: 16,
    marginRight: 0,
  },
  folderHeader: {
    minHeight: 32,
    paddingLeft: 0,
    paddingRight: 5,
    maxWidth: "calc(100% - 22px)",
    "&:hover $headerActions": {
      display: "flex",
    },
  },
  createButton: {
    marginLeft: 6,
    marginRight: 6,
    color: theme.color.gray[600],
    "&:hover": {
      color: theme.color.gray[500],
    },
  },
  createButtonIcon: {
    width: 16,
    height: 16,
  },
  archivedSection: {
    backgroundColor: theme.color.gray[50],
  },
  archivedSectionHeading: {
    position: "relative",
    marginLeft: 15,
    marginRight: 15,
    paddingTop: 5,

    "&::after": {
      content: "''",
      backgroundColor: theme.color.gray[300],
      position: "absolute",
      top: 13.5,
      left: 0,
      right: 0,
      height: 1,
      zIndex: 1,
    },
  },
  archivedSectionTitle: {
    position: "relative",
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: theme.color.gray[50],
    zIndex: 2,
  },
  emptyStateContainer: {
    position: "relative",
  },
  emptyState: {
    width: 210,
    marginLeft: 32,
  },
  emptyStateTitle: {
    padding: "4px 17px",
  },
  dragTooltip: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "calc(100% - 16px)",
    height: `calc(100% - 4px)`,
    border: `2px dashed ${theme.color.gray[400]}`,
    borderRadius: 2,
    marginLeft: 8,
  },
  dragTooltipHighlighted: {
    backgroundColor: theme.color.gray[50],
    opacity: 0.5,
    width: "100%",
    height: "100%",
  },
  dragTooltipText: {
    position: "absolute",
    top: 6,
    right: 16,
  },
  headerActions: {
    display: "none",
    paddingRight: 7,
  },
  actionButton: {
    marginLeft: 6,
    marginRight: 6,
    color: theme.color.gray[600],
    "&:hover": {
      color: theme.color.gray[500],
    },
  },
  actionButtonIcon: {
    width: 16,
    height: 16,
  },
  folderContainer: {
    marginRight: 0,
    marginLeft: 0,
    marginBottom: 10,
    border: ({ isHighlighted }) => !isHighlighted && "none",
  },
}));

const DEFAULT_CHUNK_SIZE = 15;
const HIGHLIGHT_TIMEOUT = 2500;

export default function Folder({
  isProjectRoute,
  onCollapse,
  isDragDisabled,
  isExternalTeam,
  newLayoutEnabled,
  ...folder
}) {
  const { id, name, projects, archivedProjects, isNew } = folder;

  const [isHighlighted, setIsHighlighted] = useState(Boolean(isNew));
  const { state } = useSidebarState();
  const { t } = useTranslation();
  const styles = useStyles({
    isExternalTeam,
    isCollapsed: folder.isCollapsed,
    projectsCount: projects.length,
    isHighlighted,
  });

  const createProjectDialog = useDialogState();
  const {
    isOpen: isRenameFolderDialogOpen,
    openDialog: openRenameFolderDialog,
    closeDialog: closeRenameFolderDialog,
  } = useDialogState();
  const theme = useTheme();
  const team = useSelectedTeam();

  const { checkAndProceed } = useGatedFeature({
    featureGate: AddProjectGate,
    currentValue: team.usedBillingLimits?.numberOfActiveProjects,
    teamId: team._id,
  });

  const isSameFolder = id === state.dndSourceId;
  const showProjects = !folder.isCollapsed;
  const isFolderEmpty =
    showProjects && projects.length === 0 && archivedProjects?.length === 0;
  const firstArchivedProject = projects.find((project) => project.isArchived);

  const folderHasSelectedProject =
    folder.id === state.selectedProject?.folderId;

  const projectIndex = projects.findIndex(
    (project) => project.id === state.selectedProject?.id
  );
  const archivedProjectIndex = archivedProjects?.findIndex(
    (project) => project.id === state.selectedProject?.id
  );
  const projectSelectedIndex =
    archivedProjectIndex > -1 ? archivedProjectIndex : projectIndex;

  const renameFolderHandler = useCallback(
    async (updatedName) => {
      try {
        await projectService.updateFolderName(folder.id, updatedName);
        closeRenameFolderDialog();
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [closeRenameFolderDialog, folder.id]
  );

  const addProject = useCallback(() => {
    createProjectDialog.openDialog();
  }, [createProjectDialog]);

  useEffect(() => {
    if (!isHighlighted) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setIsHighlighted(false);
    }, HIGHLIGHT_TIMEOUT);

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* istanbul ignore next */
  const projectList = !newLayoutEnabled
    ? projects
    : [
        ...projects.filter((project) => !project.isArchived),
        ...(state.areArchivedProjectsShown
          ? projects.filter((project) => project.isArchived)
          : []),
      ];

  /* istanbul ignore next */
  const renderClone = useCallback(
    (provided, snapshot, rubric) => {
      const projectId = rubric.draggableId;
      const project = projects.find(({ id }) => id === projectId);
      return (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ProjectListItem
            {...project}
            isSelected={
              isProjectRoute &&
              Boolean(state.selectedProject) &&
              projectId === state.selectedProject.id
            }
          />
        </Box>
      );
    },
    [isProjectRoute, projects, state.selectedProject]
  );

  if (
    !state.areArchivedProjectsShown &&
    projects.length === 0 &&
    archivedProjects?.length
  ) {
    return null;
  }

  return (
    <Box
      className={classNames(styles.folderContainer, {
        [styles.folder]: !isExternalTeam,
        [styles.folderHighlighted]: isHighlighted,
      })}
      data-testid={`folder-${id}`}
      id={`folder-${id}`}
    >
      {createProjectDialog.isOpen && (
        <CreateProjectDialog
          cancel={createProjectDialog.closeDialog}
          selectedFolder={folder}
        />
      )}
      {isRenameFolderDialogOpen && (
        <RenameFolderDialog
          name={folder.name}
          answer={renameFolderHandler}
          cancel={closeRenameFolderDialog}
        />
      )}
      <Droppable
        droppableId={id}
        isDropDisabled={isSameFolder}
        mode="virtual"
        renderClone={renderClone}
      >
        {(provided, snapshot) => (
          <>
            <Box ref={provided.innerRef}>
              <Box className={styles.folderHeaderContainer}>
                <CollapseButton
                  isCollapsed={folder?.isCollapsed}
                  onCollapse={onCollapse}
                  textCollapsed="FOLDERS.COLLAPSE.TOOLTIP_SHOW"
                  textExpanded="FOLDERS.EXPAND.TOOLTIP_HIDE"
                />
                <Box
                  flexGrow={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.folderHeader}
                  minWidth={0}
                  title={folder.name}
                  data-testid="folder-name"
                >
                  <Text
                    fontWeight={FSTGTypography.fontWeightMedium}
                    fontSize={FSTGTypography.fontSize_1_5}
                    lineHeight={FSTGTypography.lineHeight_1_3}
                    noWrap
                    color={theme.color.gray[800]}
                  >
                    {name}
                  </Text>
                  {!isExternalTeam && (
                    <Box
                      className={classNames(
                        styles.headerActions,
                        "animated",
                        "fadeIn"
                      )}
                    >
                      <Tooltip
                        title={t("PROJECT.TOOLTIPS.RENAME_FOLDER")}
                        placement="top"
                      >
                        <IconButton
                          data-testid="folder-header-action-rename"
                          size="extraSmall"
                          onClick={openRenameFolderDialog}
                        >
                          <EditOutlined fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <DeleteFolder folder={folder} />
                      {team.permissions.canCreateProjects && !isExternalTeam && (
                        <Tooltip
                          title={t("PROJECT.TOOLTIPS.CREATE_PROJECT")}
                          placement="top"
                        >
                          <IconButton
                            size="extraSmall"
                            onClick={checkAndProceed(addProject)}
                            data-testid="folder-header-action-create-project"
                          >
                            <AddCircleOutline fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
              {isFolderEmpty && (
                <Box display="flex" className={styles.emptyStateContainer}>
                  <Box
                    data-testid="project-list-empty-state"
                    className={styles.emptyState}
                    display="flex"
                  >
                    <Text
                      color={theme.color.gray[400]}
                      fontStyle="italic"
                      fontSize={FSTGTypography.fontSize_1_2}
                      className={styles.emptyStateTitle}
                    >
                      {t("PROJECT.FOLDER.EMPTY_STATE")}
                    </Text>
                  </Box>
                </Box>
              )}

              {!showProjects &&
                folderHasSelectedProject &&
                chunk([state.selectedProject], DEFAULT_CHUNK_SIZE).map(
                  (projectsChunk, chunkIndex) => (
                    <LazyRenderChunk
                      key={`${id}-${projectSelectedIndex}`}
                      id={`${id}-${projectSelectedIndex}`}
                      chunk={projectsChunk}
                    >
                      {projectsChunk.map((project) => (
                        <Draggable
                          key={project.id}
                          draggableId={project.id}
                          index={
                            chunkIndex * DEFAULT_CHUNK_SIZE +
                            projectSelectedIndex
                          }
                          isDragDisabled={
                            state.selectedProject?.isArchived ||
                            team._id !== state.selectedProject?.teamId
                          }
                        >
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ProjectListItem {...project} isSelected />
                            </Box>
                          )}
                        </Draggable>
                      ))}
                    </LazyRenderChunk>
                  )
                )}

              {showProjects &&
                chunk(projectList, DEFAULT_CHUNK_SIZE).map(
                  (projectsChunk, chunkIndex) => (
                    <LazyRenderChunk
                      key={`${id}-${chunkIndex}`}
                      id={`${id}-${chunkIndex}`}
                      chunk={projectsChunk}
                    >
                      {projectsChunk.map((project, index) => {
                        const isFirstArchivedProject =
                          firstArchivedProject?.id === project.id;
                        return (
                          <Draggable
                            key={project.id}
                            draggableId={project.id}
                            index={chunkIndex * DEFAULT_CHUNK_SIZE + index}
                            isDragDisabled={
                              project.isArchived || isDragDisabled
                            }
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {
                                  /* istanbul ignore next */
                                  isFirstArchivedProject && (
                                    <Box
                                      data-testid="project-list-archived-section"
                                      className={styles.archivedSection}
                                    >
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        className={
                                          styles.archivedSectionHeading
                                        }
                                      >
                                        <Text
                                          color={theme.color.gray[400]}
                                          fontWeight={
                                            FSTGTypography.fontWeightMedium
                                          }
                                          variant="subtitle2"
                                          className={
                                            styles.archivedSectionTitle
                                          }
                                          component="span"
                                        >
                                          {t("PROJECT.ARCHIVED_LABEL")}
                                        </Text>
                                      </Box>
                                    </Box>
                                  )
                                }
                                <ProjectListItem
                                  {...project}
                                  isSelected={
                                    isProjectRoute &&
                                    Boolean(state.selectedProject) &&
                                    project.id === state.selectedProject.id
                                  }
                                />
                              </Box>
                            )}
                          </Draggable>
                        );
                      })}
                    </LazyRenderChunk>
                  )
                )}
            </Box>
            {showProjects &&
              state.areArchivedProjectsShown &&
              archivedProjects?.length > 0 && (
                <Box
                  data-testid="project-list-archived-section"
                  className={styles.archivedSection}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={styles.archivedSectionHeading}
                  >
                    <Text
                      color={theme.color.gray[400]}
                      fontWeight={FSTGTypography.fontWeightMedium}
                      variant="subtitle2"
                      className={styles.archivedSectionTitle}
                      component="span"
                    >
                      {t("PROJECT.ARCHIVED_LABEL")}
                    </Text>
                  </Box>
                  {chunk(archivedProjects, DEFAULT_CHUNK_SIZE).map(
                    (archivedProjectsChunk, chunkIndex) => (
                      <LazyRenderChunk
                        key={`${id}-archived-${chunkIndex}`}
                        id={`${id}-archived-${chunkIndex}`}
                        chunk={archivedProjectsChunk}
                      >
                        {archivedProjectsChunk.map((archivedProject) => (
                          <ProjectListItem
                            key={archivedProject.id}
                            isSelected={
                              isProjectRoute &&
                              Boolean(state.selectedProject) &&
                              archivedProject.id === state.selectedProject.id
                            }
                            {...archivedProject}
                          />
                        ))}
                      </LazyRenderChunk>
                    )
                  )}
                </Box>
              )}
            {!isDragDisabled && (
              <Fade
                in={Boolean(state.dndSourceId) && !isSameFolder}
                timeout={{ enter: 200, exit: 100 }}
              >
                <Box>
                  <Box
                    className={styles.dragTooltip}
                    data-testid="folder-dnd-hint"
                  >
                    <Box
                      className={classNames({
                        [styles.dragTooltipHighlighted]:
                          snapshot.isDraggingOver,
                      })}
                      data-testid="folder-dnd-inner"
                    />
                  </Box>
                  <Text
                    color={theme.color.black}
                    textTransform="none"
                    variant="subtitle1"
                    fontWeight={FSTGTypography.fontWeightMedium}
                    className={styles.dragTooltipText}
                  >
                    {t("PROJECT.FOLDER.DROP")}
                  </Text>
                </Box>
              </Fade>
            )}
          </>
        )}
      </Droppable>
    </Box>
  );
}

Folder.propTypes = {
  ...folderProps,
  isProjectRoute: PropTypes.bool.isRequired,
  isDragDisabled: PropTypes.bool,
  isExternalTeam: PropTypes.bool,
  newLayoutEnabled: PropTypes.bool,
};

Folder.defaultProps = {
  isDragDisabled: false,
  isExternalTeam: false,
  newLayoutEnabled: false,
};
