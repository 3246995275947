import PropTypes from "prop-types";

import { Table, TableBody, TableContainer, Paper } from "@mui/material";
import orderBy from "lodash/orderBy";

import { roleProp, teamProp } from "@shared/props/team";

import TeamRolesTableHead from "./TeamRolesTableHead/TeamRolesTableHead";
import TeamRolesTableRow from "./TeamRolesTableRow/TeamRolesTableRow";

function TeamRolesTable({ permissions, teamRoles, team }) {
  return (
    <TableContainer component={Paper} data-testid="team-roles-table">
      <Table>
        <TeamRolesTableHead permissions={permissions} />
        <TableBody>
          {orderBy(teamRoles, ["isDefault", "roleName"], ["desc", "asc"]).map(
            (teamRole) => (
              <TeamRolesTableRow
                key={teamRole._id}
                team={team}
                teamRole={teamRole}
                teamRoles={teamRoles}
                permissions={permissions}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TeamRolesTable.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamRoles: PropTypes.arrayOf(roleProp).isRequired,
  team: teamProp.isRequired,
};

export default TeamRolesTable;
