import { useEffect, useState } from "react";

import { instance as teamService } from "@supporting/services/team";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

/**
 * Use Selected Team hook
 *
 * @description This hook is being used for returning the selected team.
 * It also contains the event listener for the selected team and updates
 * it in the state.
 * @typedef TeamObject
 * @returns {TeamObject} - Returns the selected team.
 */
export default function useSelectedTeam() {
  const [team, setTeam] = useState(teamService.getSelectedTeam());

  useEffect(() => {
    const updateTeam = () => {
      setTeam(teamService.getSelectedTeam());
    };
    eventService.addListener(EVENT_NAMES.TEAM.SELECTED.UPDATED, updateTeam);
    eventService.addListener(EVENT_NAMES.TEAM.SELECTED.ADDED, updateTeam);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.TEAM.SELECTED.UPDATED,
        updateTeam
      );
      eventService.removeListener(EVENT_NAMES.TEAM.SELECTED.ADDED, updateTeam);
    };
  }, []);

  return team;
}
