import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  INTEGRATION_APPS,
  INTEGRATION_APPS_ALIAS,
} from "@integrations/constants";
import { Button } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import FilestageIcon from "@assets/img/icons/filestage-icon.svg";
import SwapHorizontal from "@assets/img/icons/swap-horizontal.svg";

export default function IntegrationsAuthorizationFailed() {
  const { t } = useTranslation();
  const { appId } = useParams();

  const integrationDetails = INTEGRATION_APPS.find(
    ({ id }) => id === appId || INTEGRATION_APPS_ALIAS[appId] === id
  );

  const handleRetryButtonClicked = () => {
    window.location.replace(
      `https://monday.com/marketplace/${window.fs.config.mondayApp.appId}`
    );
  };

  const { authorizationFailedPage } = integrationDetails;
  const IntegrationLogo = authorizationFailedPage.logo;

  return (
    <Box
      display="flex"
      flexDirection="row"
      data-testid="integrations-authorization-failed-screen"
      flexGrow="1"
      bgcolor="white"
    >
      <Box flex="1 1 100%" display="flex" flexDirection="column">
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          flex="1 0 auto"
          justifyContent="center"
        >
          <Box display="flex" flexDirection="row" alignItems="center">
            <IntegrationLogo height={79} />
            <SwapHorizontal height={46} />
            <FilestageIcon height={79} />
          </Box>

          <Box
            mt={6}
            display="flex"
            flexDirection="column"
            gap={8}
            alignItems="center"
          >
            {authorizationFailedPage.pageTitle && (
              <Text
                component="h1"
                variant="text2xl"
                textAlign="center"
                fontWeight="fontWeight.medium"
              >
                {t(authorizationFailedPage.pageTitle)}
              </Text>
            )}

            {authorizationFailedPage.pageErrorTitle && (
              <Text
                component="h1"
                variant="textXl"
                textAlign="center"
                fontWeight="fontWeight.medium"
                color="error.main"
              >
                {t(authorizationFailedPage.pageErrorTitle)}
              </Text>
            )}

            {authorizationFailedPage.pageBody && (
              <Text variant="textMd" textAlign="center">
                {t(authorizationFailedPage.pageBody)}
              </Text>
            )}

            {authorizationFailedPage.pageStepsTitle && (
              <Box
                variant="textMd"
                textAlign="left"
                bgcolor="light.light"
                py={1.5}
                px={2.5625}
                borderRadius={1}
              >
                <Text variant="textMd" color="text.secondary" pb={2}>
                  {t(authorizationFailedPage.pageStepsTitle)}
                </Text>
                <Box display="flex" flexDirection="column" gap={1}>
                  {authorizationFailedPage.pageStepsActions.map(
                    ({ text }, index) => (
                      <Text key={text} variant="textMd" color="text.primary">
                        {`${index + 1}.`} {t(text)}
                      </Text>
                    )
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box display="flex" flexDirection="row" mt={2}>
            {authorizationFailedPage.ctaButton && (
              <Button
                color="primary"
                variant="contained"
                onClick={handleRetryButtonClicked}
                data-testid="retry-button"
              >
                {t(authorizationFailedPage.ctaButton)}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
