import { useCallback, useEffect, useRef } from "react";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

export default function useScrollToProjectListItem() {
  const projectToChunk = useRef(new Map());

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      requestAnimationFrame(() => {
        element.scrollIntoView({ behavior: "smooth", block: "nearest" });
      });
    }
  };

  const scrollToFolder = useCallback(({ eventData }) => {
    const { folder } = eventData;
    scrollToElement(`folder-${folder.id}`);
  }, []);

  const mapProjectsToChunks = (projectsIds, chunkId) => {
    for (const projectId of projectsIds) {
      projectToChunk.current.set(projectId, chunkId);
    }
  };

  const scrollToProject = useCallback(({ eventData }) => {
    const { project } = eventData;
    if (!projectToChunk.current.has(project.id) || project.isFavorite) {
      return;
    }
    scrollToElement(projectToChunk.current.get(project.id));
  }, []);

  useEffect(() => {
    eventService.addListener(EVENT_NAMES.FOLDERS.CREATED, scrollToFolder);
    eventService.addListener(EVENT_NAMES.PROJECT.OPENED, scrollToProject);

    return () => {
      eventService.removeListener(EVENT_NAMES.FOLDERS.CREATED, scrollToFolder);
      eventService.removeListener(EVENT_NAMES.PROJECT.OPENED, scrollToProject);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { mapProjectsToChunks, scrollToProject, projectToChunk };
}
