export const ADOBE_PLUGIN_EVENTS = Object.freeze({
  PPRO_FILESTAGE_PLAY: "ppro.filestage.play",
  PPRO_FILESTAGE_PAUSE: "ppro.filestage.pause",
  PPRO_FILESTAGE_SEND_KEY_FRAME: "ppro.filestage.sendKeyframe",
  PPRO_FILESTAGE_UPLOAD_START: "ppro.filestage.uploadStart",
  PPRO_FILESTAGE_SEQUENCE_EXPORT_START: "ppro.filestage.sequenceExportStart",
  PPRO_FILESTAGE_SEQUENCE_EXPORT_FAILED: "ppro.filestage.sequenceExportFailed",
  PPRO_FILESTAGE_SEND_FILESTAGE_ID: "ppro.filestage.sendFileStageId",
  PPRO_FILESTAGE_SEQUENCE_UPLOAD_SUCCESS:
    "ppro.filestage.sequenceUploadSuccess",
  PPRO_FILESTAGE_SEQUENCE_UPLOAD_FAILED: "ppro.filestage.sequenceUploadFailed",
  PPRO_FILESTAGE_SEQUENCE_UPLOAD_START: "ppro.filestage.sequenceUploadStart",
  PPRO_FILESTAGE_SEQUENCE_UPLOAD_TO_S3_START:
    "ppro.filestage.sequenceUploadToS3Start",
  PPRO_FILESTAGE_UPLOAD_SEQUENCE_LIST: "ppro.filestage.sequenceList",
  PPRO_FILESTAGE_KEY_FRAME: "ppro.filestage.keyframe",
  FILESTAGE_PPRO_SEND_KEY_FRAME: "filestage.ppro.sendKeyframe",
  FILESTAGE_PPRO_PAUSE: "filestage.ppro.pause",
  FILESTAGE_PPRO_PLAY: "filestage.ppro.play",
  FILESTAGE_PPRO_EXPORT_AND_UPLOAD: "filestage.ppro.exportAndUpload",
  FILESTAGE_PPRO_API_TOKEN: "filestage.ppro.apiToken",
  FILESTAGE_PPRO_SYNC_COMMENTS: "filestage.ppro.syncComments",
  FILESTAGE_PPRO_GET_SEQUENCES: "filestage.ppro.getSequences",
  FILESTAGE_PPRO_GET_KEY_FRAME: "filestage.ppro.getKeyframe",
  FILESTAGE_PPRO_OPEN_BROWSER: "filestage.ppro.openBrowser",
  FILESTAGE_PLUGIN_GET_FILE: "filestage.plugin.getFile",
  PLUGIN_FILESTAGE_FILE_DETAILS: "plugin.filestage.fileDetails",
  FILESTAGE_PLUGIN_UPLOAD: "filestage.plugin.upload",
  PLUGIN_FILESTAGE_UPLOAD_SUCCESS: "plugin.filestage.uploadSuccess",
  PLUGIN_FILESTAGE_UPLOAD_FAILED: "plugin.filestage.uploadFailed",
  PLUGIN_FILESTAGE_EXPORT_START: "plugin.filestage.exportStart",
  PLUGIN_FILESTAGE_EXPORT_SUCCESS: "plugin.filestage.exportSuccess",
  PLUGIN_FILESTAGE_EXPORT_FAILED: "plugin.filestage.exportFailed",
  FILESTAGE_PLUGIN_GET_AEFT_TEMPLATES: "filestage.plugin.getAEFTTemplates",
  PLUGIN_FILESTAGE_AEFT_TEMPLATES: "plugin.filestage.aeftTemplates",
  PLUGIN_FILESTAGE_UPLOAD_TO_S3_START: "plugin.filestage.uploadToS3Start",
});
