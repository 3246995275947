import * as analytics from "@shared/services/analytics/constants";

import AddProjectsGateContent from "@assets/img/images/add-projects-gate-content.png";
import AnanymizeReviewers from "@assets/img/images/anonymizeReviewers.png";
import customBrandingGateContent from "@assets/img/images/branding.gif";
import CopyComments from "@assets/img/images/earlyaccess_copy_comments.png";
import AddAutomationContent from "@assets/img/images/gate-add-automation-content.png";
import AddProjectTemplateContent from "@assets/img/images/gate-add-project-template-content.png";
import LimitAccessToReviewers from "@assets/img/images/limitAccess.png";
import AddStorageContent from "@assets/img/images/project-storage-limit.png";
import RequestReviewDecision from "@assets/img/images/request-review-decision.png";
import UploadFiles from "@assets/img/images/uploadFiles.png";
import VerifiedApprovals from "@assets/img/images/verifiedApproval.png";

const defaultLimitChecker = (limit, currentValue) =>
  limit !== -1 && currentValue >= limit;

const defaultBooleanLimitChecker = (limit) => !limit;

/**
 * @typedef FeatureGate
 * @property {string} subscriptionLimitKey - The limit of the feature gate
 * @property {object} limitReachAnalytics - The analytics on limit reach
 * @property {object} selectPlanOpenAnalytics - The analytics when user opens select Plan dialog
 * @property {object} restartTrialAnalytics - The analytics when user opens restart trial dialog
 * @property {object} upgradeDialogsProps - The dialog props
 * @property {function} checkLimit - The function which checks the limit
 *
 */

/** @type {FeatureGate} */
export const StepFeatureGate = {
  subscriptionLimitKey: "maxReviewStepsNumber",

  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.STATE.REVIEW_STEP_QUANTITY,
  },
  selectPlanOpenAnalytics: {
    action: analytics.ACTION.CLICKED,
    category: analytics.CATEGORY.RESTART_TRIAL_DIALOG.UPGRADE,
  },
  restartTrialAnalytics: {
    action: analytics.ACTION.CLICKED,
    category: analytics.CATEGORY.RESTART_TRIAL_DIALOG.TRY_PROFESSIONAL,
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.REVIEW_STEPS.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.REVIEW_STEPS.BODY",
    content:
      "https://filestage.io/wp-content/uploads/2022/10/Review-steps-1.mp4",
    contentType: "video",
  },
  checkLimit: defaultLimitChecker,
};

/** @type {FeatureGate} */
export const AutomationFeatureGate = {
  subscriptionLimitKey: "maxProjectAutomationNumber",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.AUTOMATION_QUANTITY,
  },
  selectPlanOpenAnalytics: {
    action: analytics.ACTION.CLICKED,
    category: analytics.CATEGORY.AUTOMATION_UPGRADE_BUTTON,
    properties: { component: "other-plans-automation-popover" },
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.FREE.AUTOMATION.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.FREE.AUTOMATION.BODY",
    content: AddAutomationContent,
  },
  checkLimit: defaultLimitChecker,
};

/** @type {FeatureGate} */
export const ProjectTemplateGate = {
  subscriptionLimitKey: "maxProjectTemplateNumber",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.STATE.PROJECT_TEMPLATE_QUANTITY,
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.TEMPLATE.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.TEMPLATE.BODY",
    content: AddProjectTemplateContent,
    link: "https://help.filestage.io/en/articles/3848967-create-and-use-project-templates",
  },
  checkLimit: defaultLimitChecker,
};

/** @type {FeatureGate} */
export const AddProjectGate = {
  subscriptionLimitKey: "maxProjectNumber",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.PROJECT_QUANTITY,
  },
  selectPlanOpenAnalytics: {
    action: analytics.ACTION.CLICKED,
    category: analytics.CATEGORY.RESTART_TRIAL_DIALOG.UPGRADE,
  },
  restartTrialAnalytics: {
    action: analytics.ACTION.CLICKED,
    category: analytics.CATEGORY.RESTART_TRIAL_DIALOG.TRY_PROFESSIONAL,
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.GET_PROJECT_GENERAL.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.GET_PROJECT_GENERAL.BODY",
    content: AddProjectsGateContent,
  },
  checkLimit: defaultLimitChecker,
};

/** @type {FeatureGate} */
export const CustomBrandingGate = {
  subscriptionLimitKey: "isBrandingEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.CUSTOM_BRANDING,
    type: analytics.TYPE.FEATURE,
  },
  selectPlanOpenAnalytics: {
    action: analytics.ACTION.CLICKED,
    category: analytics.CATEGORY.CUSTOM_BRANDING,
    subCategory: analytics.SUB_CATEGORY.UPGRADE_BUTTON,
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.CUSTOM_BRANDING.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.CUSTOM_BRANDING.BODY",
    content: customBrandingGateContent,
    link: "https://help.filestage.io/en/articles/1482247-customize-your-team-s-branding",
  },
  checkLimit: defaultBooleanLimitChecker,
};

/** @type {FeatureGate} */
export const AddSecureStorageGate = {
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.STORAGE_QUANTITY,
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.GET_PROJECT_GENERAL.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.FREE.STORAGE.BODY",
    content: AddStorageContent,
  },
};

export const DisableReviewDecisionGate = {
  subscriptionLimitKey: "isRequestReviewerDecisionEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.REQUEST_REVIEWER_DECISION,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "STANDARDIZE_DIALOGUE.REVIEW_DECISION.TITLE",
    subtitle: "STANDARDIZE_DIALOGUE.REVIEW_DECISION.BODY",
    content: RequestReviewDecision,
    link: "URLS.HELLO_MAIL",
  },
  checkLimit: defaultBooleanLimitChecker,
};

export const ReviewerUpload = {
  subscriptionLimitKey: "isReviewerUploadEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.REVIEWER_UPLOAD,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "UPGRADE.DIALOG.UPLOAD_FILES.TITLE",
    subtitle: "UPGRADE.DIALOG.UPLOAD_FILES.DESC",
    content: UploadFiles,
    link: "URLS.HELLO_MAIL",
  },
  checkLimit: defaultBooleanLimitChecker,
};

export const VerifiedApproval = {
  subscriptionLimitKey: "isVerifiedApprovalsEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.VERIFIED_APPROVAL,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "UPGRADE.DIALOG.VERIFIED_APPROVALS.TITLE",
    subtitle: "UPGRADE.DIALOG.VERIFIED_APPROVALS.DESC",
    content: VerifiedApprovals,
  },
  checkLimit: defaultBooleanLimitChecker,
};

export const ReviewAccessControl = {
  subscriptionLimitKey: "isReviewAccessControlEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.REVIEW_ACCESS_CONTROL,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "UPGRADE.DIALOG.LIMIT_ACCESS.TITLE",
    subtitle: "UPGRADE.DIALOG.LIMIT_ACCESS.DESC",
    content: LimitAccessToReviewers,
  },
  checkLimit: defaultBooleanLimitChecker,
};

export const AnonymousReviewers = {
  subscriptionLimitKey: "isAnonymousReviewersEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.ANONYMOUS_REVIEWERS,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "UPGRADE.DIALOG.ANONYMOUS_REVIEWER.TITLE",
    subtitle: "UPGRADE.DIALOG.ANONYMOUS_REVIEWER.DESC",
    content: AnanymizeReviewers,
  },
  checkLimit: defaultBooleanLimitChecker,
};

export const CopyCommentsToVersions = {
  subscriptionLimitKey: "isCopyCommentsEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.COPY_COMMENTS,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "GATED.COPY_COMMENT.MODAL.TITLE",
    subtitle: "GATED.COPY_COMMENT.MODAL.BODY",
    content: CopyComments,
  },
  checkLimit: defaultBooleanLimitChecker,
};

export const CopyCommentsToReviewGroups = {
  subscriptionLimitKey: "isCopyCommentsEnabled",
  limitReachAnalytics: {
    action: analytics.ACTION.REACHED,
    category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
    subCategory: analytics.SUB_CATEGORY.COPY_COMMENTS,
    type: analytics.TYPE.FEATURE,
  },
  upgradeDialogsProps: {
    title: "COPY_COMMENTS.UPGRADE_POP_OVER.TITLE",
    subtitle: "COPY_COMMENTS.UPGRADE_POP_OVER.DESCRIPTION",
    content: CopyComments,
  },
  checkLimit: defaultBooleanLimitChecker,
};
