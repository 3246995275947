import PropTypes from "prop-types";
import { memo } from "react";

import { AddBox } from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import ReviewCardLoadingState from "@workflow/components/ReviewCardLoadingState/ReviewCardLoadingState";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

const useStyles = makeStyles((theme) => ({
  file: {
    backgroundColor: theme.color.white,
    display: "flex",
    alignItems: "center",
    left: 0,
    zIndex: 2,
    position: "sticky",
    boxShadow: theme.shadow["box-shadow-file-card"],
    clipPath: "inset(0px -15px 0px 0px)",
    border: `1px solid ${theme.color.gray[200]}`,
    borderTop: 0,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  thumbnail: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    visibility: "visible",
    margin: "auto",
    opacity: 0.2,
  },
  review: {
    position: "relative",
    width: 288,
    maxWidth: 288,
    backgroundColor: theme.color.white,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    borderBottom: `1px solid ${theme.color.gray[200]}`,
    borderRight: `1px ${theme.color.gray[200]} solid`,
    "&::before": {
      content: "''",
      width: 1,
      height: 10,
      backgroundColor: theme.color.white,
      display: "block",
      position: "absolute",
      right: -1,
      top: 0,
    },
    "&::after": {
      content: "''",
      width: 1,
      height: 10,
      backgroundColor: theme.color.white,
      display: "block",
      position: "absolute",
      right: -1,
      bottom: 0,
    },
    "&:last-child::after": {
      display: "none",
    },
    "&:last-child::before": {
      display: "none",
    },
    "&:last-child": {
      width: "287px !important",
      maxWidth: "287px !important",
    },
    "&:nth-child(2):last-child": {
      width: "283px !important",
    },
  },
  cardBordered: {
    borderTop: `1px solid ${theme.color.gray[200]}`,
  },
  sectionHeader: {
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 2.1875, 0.875, 0.875),
    boxSizing: "border-box",
    boxShadow: theme.shadow["box-shadow-file-card"],
    clipPath: "inset(0px -15px 0px 0px)",
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  sectionName: {
    borderRadius: 3,
  },
  uploadButton: {
    borderRadius: theme.spacing(3),
  },
}));

function ProjectFileLoadingState({
  numberOfItems,
  numberOfSteps,
  showSectionHeader,
}) {
  const { width, minWidth, maxWidth } = useFileCardResized();
  const classes = useStyles({
    width,
    minWidth,
    maxWidth,
  });

  return (
    <>
      {showSectionHeader && (
        <Box
          height={39}
          display="flex"
          className={classes.sectionHeader}
          data-testid="project-file-loading-state-section-header"
        >
          <Skeleton
            variant="rectangular"
            width={80}
            height={16}
            className={classes.sectionName}
          />
          <Skeleton
            variant="rectangular"
            width={114}
            height={32}
            className={classes.uploadButton}
          />
        </Box>
      )}
      {Array(numberOfItems)
        .fill(1)
        .map(
          (
            val, // eslint-disable-line no-unused-vars
            index
          ) => (
            <Box
              key={index}
              display="flex"
              flexGrow={1}
              height={70}
              data-testid="project-file-loading-state"
              mt={showSectionHeader ? 0.75 : 0}
            >
              <Box display="flex">
                <Box
                  className={classnames(classes.file, {
                    [classes.cardBordered]: index === 0,
                  })}
                  flexShrink={0}
                >
                  <Box>
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={70}
                      className={classes.thumbnail}
                    >
                      <AddBox
                        data-testid="default-icon"
                        className={classes.icon}
                      />
                    </Skeleton>
                  </Box>
                  <Box pl={2} pr={2}>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={90} />
                  </Box>
                </Box>
                {Array(numberOfSteps)
                  .fill(1)
                  .map(
                    (
                      subVal, // eslint-disable-line no-unused-vars
                      subIndex
                    ) => (
                      <Box
                        key={subIndex}
                        pt={1}
                        pb={1.25}
                        className={classnames(classes.review, {
                          [classes.cardBordered]: index === 0,
                        })}
                        flexShrink={0}
                      >
                        <ReviewCardLoadingState width="100%" px={1.25} />
                      </Box>
                    )
                  )}
              </Box>
            </Box>
          )
        )}
    </>
  );
}

ProjectFileLoadingState.propTypes = {
  numberOfItems: PropTypes.number,
  numberOfSteps: PropTypes.number,
  showSectionHeader: PropTypes.bool,
};

ProjectFileLoadingState.defaultProps = {
  numberOfItems: 1,
  numberOfSteps: 1,
  showSectionHeader: false,
};

export default memo(ProjectFileLoadingState);
