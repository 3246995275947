import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";
const AddReviewerDialogAutocompleteOptionGuest = ({
  input,
  validEmail,
  validationOptionError,
  errorText,
  emptyText,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (errorText) {
    return (
      <Text fontSize={FSTGTypography.fontSize_1_3} color={theme.color.red[500]}>
        <Trans t={t} i18nKey={errorText} />
      </Text>
    );
  }

  if (validEmail !== undefined && !validEmail && !validationOptionError) {
    return (
      <Text
        data-testid="add-reviewer-dialog-guest-email"
        fontSize={FSTGTypography.fontSize_1_3}
        fontWeight={FSTGTypography.fontWeightBold}
      >
        <Trans t={t} i18nKey={emptyText} />
      </Text>
    );
  }

  if (!validEmail && validationOptionError) {
    return (
      <Text fontSize={FSTGTypography.fontSize_1_3} color={theme.color.red[500]}>
        <Trans t={t} i18nKey="COLLABORATORS.ADD.ERROR.INVALID_EMAIL" />
      </Text>
    );
  }

  return (
    <>
      <Box display="flex" m={0}>
        <Text fontSize={FSTGTypography.fontSize_1_3}>
          {t("REVIEWER.ADD.ADD_NEW")} {t("CORE.OR_BRACKET_ENCLOSED")}
        </Text>
        <KeyboardReturnIcon />
        <Text fontSize={FSTGTypography.fontSize_1_3}>{t("CORE.ENTER")}</Text>
      </Box>
      <Text
        data-testid="add-reviewer-dialog-guest-email"
        fontWeight={FSTGTypography.fontWeightBold}
        fontSize={FSTGTypography.fontSize_1_3}
      >
        {input}
      </Text>
    </>
  );
};

AddReviewerDialogAutocompleteOptionGuest.propTypes = {
  input: PropTypes.string.isRequired,
  validEmail: PropTypes.bool.isRequired,
  validationOptionError: PropTypes.bool,
  existingReviewerError: PropTypes.string,
  errorText: PropTypes.string,
  emptyText: PropTypes.string,
};

AddReviewerDialogAutocompleteOptionGuest.defaultProps = {
  validationOptionError: false,
  existingReviewerError: "",
  errorText: "",
  emptyText: "",
};

export default AddReviewerDialogAutocompleteOptionGuest;
