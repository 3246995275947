import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Box, Button, Fade } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import CreateProjectDialog from "@workflow/components/CreateProjectDialog/CreateProjectDialog";

import { Text } from "@shared/UIKit";
import Picture from "@shared/UIKit/Picture/Picture";

import { useDialogState } from "@shared/hooks";

import rocketSmallImage from "@assets/img/sprite-source/sprite--rocket--small@2x.png";
import rocketImage from "@assets/img/sprite-source/sprite--rocket@2x.png";

function EmptyProject() {
  const { t } = useTranslation();
  const createProjectDialog = useDialogState();
  const team = useSelectedTeam();
  const createProject = useCallback(() => {
    createProjectDialog.openDialog();
  }, [createProjectDialog]);

  return (
    <Box display="flex" alignItems="center" maxWidth={750}>
      <Fade in timeout={500}>
        <Box
          data-testid="empty-state-project"
          bgcolor="white"
          p={2}
          borderRadius={1}
        >
          <Box
            maxWidth="100%"
            display="flex"
            alignItems="center"
            alignContent="center"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={2}
          >
            <Picture
              sources={[
                {
                  srcSet: rocketSmallImage,
                  media: "(max-width: var(--mui-breakpoints-values-md))",
                },
                {
                  srcSet: rocketImage,
                  media: "(min-width: var(--mui-breakpoints-values-md))",
                },
              ]}
              defaultImgSrc={rocketImage}
              alt="Rocket"
              width={115}
              height={179}
            />
            {createProjectDialog.isOpen && (
              <CreateProjectDialog cancel={createProjectDialog.closeDialog} />
            )}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={2}
              p={2}
            >
              {team.permissions.canCreateProjects ? (
                <>
                  <Text component="h4" variant="h4">
                    {t("PROJECT.EMPTYSTATE_PROJECTS.DESCRIPTION")}
                  </Text>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={createProject}
                    data-testid="create-project-empty-state"
                  >
                    {t("PROJECT.CREATE_PROJECT")}
                  </Button>
                </>
              ) : (
                <Text component="h4" variant="h4" color="text.secondary">
                  {t("PROJECT.EMPTYSTATE_PROJECTS.TITLE_NO_UPLOAD_PERMISSION")}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
}

export default EmptyProject;
