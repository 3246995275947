/* eslint-disable max-lines */
import propTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import googleDriveService from "@integrations/services/googleDriveService";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  TextField,
  IconButton,
  SvgIcon,
  FormControlLabel,
  Checkbox,
  Popper,
  Paper,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@mui/styles";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import toastService from "@supporting/services/toast";
import projectSwitcherService from "@workflow/services/projectSwitcherService";

import { Box, Text, Tooltip } from "@shared/UIKit";

import { instance as logger } from "@shared/services/logger";

import CloseIcon from "@assets/img/icons/ic_close_24px.svg";

import IntegrationsSettingsPopperFooter from "../IntegrationsSettingsPopperFooter";
import IntegrationsSettingsPopperHeader from "../IntegrationsSettingsPopperHeader";

const TAG = "integrations:google-drive";

const useStyles = makeStyles({
  AutocompleteConnected: {
    flex: 1,
  },
  AutocompleteNotConnected: {
    flex: "auto",
  },
  syncApprovedFilesBox: {
    display: "flex",
    flexDirection: "row",
    placeItems: "baseline",
  },
  dialogTitleTextBody: {
    contain: "inline-size",
  },
});

function GoogleDriveSettingsPopper({ onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const textFieldAnchorElRef = useRef(null);
  const popperAnchorElRef = useRef(null);
  const selectedTeam = useSelectedTeam();

  const [settingsState, setSettingsState] = useState({
    isFolderListingPopperOpen: false,
    searchText: undefined,
    loadingFolders: false,
    folders: [],
    selectedFolderId: "",
    syncApprovedFiles: false,
    connectingFolderToProject: false,
    isProjectConnected: false,
    selectedFolderName: "",
  });

  const handleOutsideClick = () => {
    setSettingsState((prevState) => ({
      ...prevState,
      isFolderListingPopperOpen: false,
    }));
  };

  function handleInput(event) {
    const { value } = event.target;

    setSettingsState((prevState) => ({ ...prevState, searchText: value }));
  }

  const handleOpenFolderListing = () => {
    setSettingsState((prevState) => ({
      ...prevState,
      isFolderListingPopperOpen: true,
    }));
  };

  useEffect(() => {
    const getProjectFolderConnection = async function () {
      const selectedProject = projectSwitcherService.getSelectedProject();

      try {
        const googleDriveProjectConnection =
          await googleDriveService.getProjectFolderConnection(
            selectedTeam._id,
            selectedProject.id
          );

        setSettingsState((prevState) => ({
          ...prevState,
          selectedFolderId: googleDriveProjectConnection.folderId,
          selectedFolderName: googleDriveProjectConnection.folderName,
          syncApprovedFiles: googleDriveProjectConnection.syncApprovedFiles,
          isProjectConnected: true,
        }));

        // eslint-disable-next-line no-empty
      } catch {}
    };

    if (selectedTeam?._id) {
      getProjectFolderConnection();
    }
  }, [selectedTeam]);

  useEffect(() => {
    const loadTeamFolders = async function () {
      setSettingsState((prevState) => ({
        ...prevState,
        loadingFolders: true,
      }));

      try {
        const { files: folders } = await googleDriveService.getTeamFolders({
          teamId: selectedTeam._id,
          searchText: settingsState.searchText,
        });

        setSettingsState((prevState) => ({
          ...prevState,
          folders,
        }));
      } catch (error) {
        logger.warn(TAG, "unable to fetch team folders", {
          error,
        });
      } finally {
        setSettingsState((prevState) => ({
          ...prevState,
          loadingFolders: false,
        }));
      }
    };

    let loadTeamFoldersTimeout;

    if (selectedTeam?._id) {
      loadTeamFoldersTimeout = setTimeout(loadTeamFolders, 500);
    }

    return () => {
      clearTimeout(loadTeamFoldersTimeout);
    };
  }, [selectedTeam, settingsState.searchText]);

  const handleFolderSelection = (event, folderId) => {
    const selectedFolder = settingsState.folders.find(
      ({ id }) => folderId === id
    );

    setSettingsState((prevState) => ({
      ...prevState,
      selectedFolderId: folderId,
      selectedFolderName: selectedFolder.name,
      searchText: undefined,
      isFolderListingPopperOpen: false,
    }));
  };

  const handleSyncAprovedFilesCheckbox = (event) => {
    setSettingsState((prevState) => ({
      ...prevState,
      syncApprovedFiles: event.target.checked,
    }));
  };

  const handleSaveGoogleDriveSettings = async () => {
    const selectedProject = projectSwitcherService.getSelectedProject();

    try {
      setSettingsState((prevState) => ({
        ...prevState,
        connectingFolderToProject: true,
      }));

      await googleDriveService.connectGoogleDriveFolderToProject({
        teamId: selectedTeam._id,
        projectId: selectedProject.id,
        folderId: settingsState.selectedFolderId,
        syncApprovedFiles: settingsState.syncApprovedFiles,
      });

      toastService.sendToast({
        preset: toastService.PRESETS().SUCCESS,
        title: "GOOGLE_DRIVE.TOAST_SUCCESS.TITLE",
        body: "GOOGLE_DRIVE.TOAST_SUCCESS.DESCRIPTION",
      });

      setSettingsState((prevState) => ({
        ...prevState,
        connectingFolderToProject: false,
        isProjectConnected: true,
      }));

      onClose();
    } catch (error) {
      toastService.sendToast({
        preset: toastService.PRESETS().ERROR,
        title: "GOOGLE_DRIVE.TOAST_ERROR.TITLE",
        body: "GOOGLE_DRIVE.TOAST_ERROR.DESCRIPTION",
      });
    } finally {
      setSettingsState((prevState) => ({
        ...prevState,
        connectingFolderToProject: false,
      }));
    }
  };

  const handleDisconnect = async () => {
    const selectedProject = projectSwitcherService.getSelectedProject();

    setSettingsState((previousNotificationState) => ({
      ...previousNotificationState,
      disconnectingProject: true,
    }));

    await googleDriveService.disconnectProject(
      selectedTeam._id,
      selectedProject.id
    );

    toastService.sendToast({
      preset: toastService.PRESETS().SUCCESS,
      title: "GOOGLE_DRIVE.TOAST_SUCCESS_UNLINK.TITLE",
      body: "GOOGLE_DRIVE.TOAST_SUCCESS_UNLINK.DESCRIPTION",
    });

    setSettingsState((previousNotificationState) => ({
      ...previousNotificationState,
      disconnectingProject: false,
      isProjectConnected: false,
      selectedFolderId: "",
      selectedFolderName: "",
    }));
  };

  const isFolderInputed =
    settingsState.selectedFolderName || settingsState.searchText;

  return (
    <>
      <IntegrationsSettingsPopperHeader
        appId="google-drive"
        isProjectConnected={settingsState.isProjectConnected}
      />
      <Box display="flex" flexDirection="column" gap={2} textAlign="left">
        <Box
          px={1.5}
          py={1.875}
          bgcolor="grey.50"
          display="flex"
          flexDirection="column"
          gap={1.5}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            data-testid="dialog-title-text"
          >
            <Text
              translate="PROJECT.GOOGLE_DRIVE.DIALOG.TITLE"
              fontWeight="fontWeight.bold"
              variant="body2"
              color="primaryV2"
              pr={6}
            />
            <Text
              translate="PROJECT.GOOGLE_DRIVE.DIALOG.DESC"
              color="text.secondary"
              variant="subtitle1"
              className={classes.dialogTitleTextBody}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={2}>
            <ClickAwayListener onClickAway={handleOutsideClick}>
              <Box>
                <TextField
                  label={t(
                    isFolderInputed
                      ? null
                      : "PROJECT.GOOGLE_DRIVE.DIALOG.INPUT_FIELD"
                  )}
                  variant="outlined"
                  value={
                    settingsState.searchText === undefined
                      ? settingsState.selectedFolderName
                      : settingsState.searchText
                  }
                  onChange={handleInput}
                  onInput={handleInput}
                  inputRef={(el) => (textFieldAnchorElRef.current = el)}
                  data-testid="select-google-drive-colder-input"
                  InputProps={{
                    onFocus: handleOpenFolderListing,
                    endAdornment: settingsState.loadingFolders ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <Box width={20} />
                    ),
                  }}
                  inputProps={{
                    "data-testid": "google-drive-input",
                  }}
                />

                <Popper
                  anchorEl={textFieldAnchorElRef.current}
                  open={settingsState.isFolderListingPopperOpen}
                  placement="bottom-start"
                  ref={popperAnchorElRef}
                >
                  <Paper
                    sx={{
                      minWidth: 210,
                      flexGrow: 1,
                      overflowY: "auto",
                      py: 1.25,
                      maxHeight: 500,
                    }}
                  >
                    <TreeView
                      defaultCollapseIcon={<ExpandMoreIcon />}
                      defaultExpandIcon={<ChevronRightIcon />}
                      data-testid="google-drive-folders"
                      onNodeSelect={handleFolderSelection}
                      defaultSelected={settingsState.selectedFolderId}
                    >
                      {settingsState.folders.map((folder) => (
                        <TreeItem
                          key={folder.id}
                          nodeId={folder.id}
                          data-testid={`folder-${folder.id}`}
                          label={
                            <Box
                              display="flex"
                              flexDirection="row"
                              gap={0.5}
                              color="grey.600"
                              alignItems="center"
                              py={0.25}
                            >
                              <FolderOpenIcon
                                fontSize="small"
                                color="inherit"
                              />
                              <Typography
                                sx={{
                                  maxWidth: 250,
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {folder.name}
                              </Typography>
                            </Box>
                          }
                        />
                      ))}
                    </TreeView>
                  </Paper>
                </Popper>
              </Box>
            </ClickAwayListener>

            {settingsState.isProjectConnected && (
              <Tooltip
                placement="right"
                title={t("PROJECT.SLACK.DIALOG.DELETE_TTOOLTIP")}
              >
                <IconButton
                  disableFocusRipple
                  disableRipple
                  onClick={handleDisconnect}
                  data-testid="disconnect-icon"
                  disabled={settingsState.disconnectingProject}
                  size="small"
                >
                  <SvgIcon component={CloseIcon} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Box px={1.75} className={classes.syncApprovedFilesBox}>
          <FormControlLabel
            sx={{ alignItems: "flex-start" }}
            control={
              <Checkbox
                disableRipple
                sx={{ pt: 0 }}
                color="primaryV2"
                checked={settingsState.syncApprovedFiles}
                onChange={handleSyncAprovedFilesCheckbox}
                data-testid="sync-google-drive-checkbox"
                inputProps={{
                  "data-testid": "sync-google-drive-input-checkbox",
                }}
              />
            }
            disableTypography
            label={t("PROJECT.GOOGLE_DRIVE.DIALOG.SYNC")}
          />
          <Tooltip
            describeChild
            title={t("INTEGRATIONS.GOOGLE_DRIVE.TOOLTIP")}
            disableInteractive={false}
          >
            <HelpOutlineOutlinedIcon fontSize="small" />
          </Tooltip>
        </Box>
      </Box>
      <IntegrationsSettingsPopperFooter
        appId="google-drive"
        disabled={
          !settingsState.selectedFolderId ||
          settingsState.connectingFolderToProject
        }
        handleSave={handleSaveGoogleDriveSettings}
        handleClose={onClose}
      />
    </>
  );
}

GoogleDriveSettingsPopper.propTypes = {
  onClose: propTypes.func.isRequired,
};

export default GoogleDriveSettingsPopper;
