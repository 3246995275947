import { Outlet } from "react-router-dom";

import { SelectFilesProvider } from "@workflow/components/SelectFilesContext";
import Sidebar from "@workflow/components/Sidebar/Sidebar";

import { Box } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

import Dashboard from "@workflow/pages/Dashboard/Dashboard";

export default function ProjectLayout() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isIntegrated = Boolean(sessionStorage.getItem("integratedApp"));

  return (
    <SelectFilesProvider>
      <Dashboard isIntegrated={isIntegrated}>
        <Box flex="auto" display="flex" overflow="hidden">
          {!isMobile && !isIntegrated && <Sidebar />}
          <Box
            flex="auto"
            display="flex"
            justifyContent="center"
            overflow="hidden"
            position="relative"
          >
            <Outlet />
          </Box>
        </Box>
      </Dashboard>
    </SelectFilesProvider>
  );
}
