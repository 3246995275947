import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Checkbox,
  Divider,
  IconButton,
  Menu,
  SvgIcon,
  Box,
  ListSubheader,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import MenuItem from "@mui/material/MenuItem";
import useActiveUser from "@supporting/hooks/useActiveUser";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import userService from "@supporting/services/userService";

import { Tooltip, Text } from "@shared/UIKit";

import { KEYBOARD_SHORTCUTS } from "@shared/constants/shortcutKey";
import { instance as analytics } from "@shared/services/analytics";
import { instance as ws } from "@shared/services/websocket";

import ArrowCollapsedIcon from "@assets/img/icons/collapse.svg";
import ArrowExpandedIcon from "@assets/img/icons/expand.svg";
import TunerIcon from "@assets/img/icons/tuner.svg";

import {
  PROJECT_LIST_ACTION_TYPES,
  PROJECT_SORT_OPTIONS,
  PROJECT_TEMPLATES_ACTION_TYPES,
  SIDEBAR_ACTION_TYPES,
  useSidebarState,
} from "../../sidebarState";
import ProjectListHeaderMenuItem from "./ProjectListHeader/ProjectListHeaderMenuItem";

const areFoldersCollapsed = (isFavoritesCollapsed, folders, reviewerTeams) => {
  return (
    isFavoritesCollapsed &&
    folders.every((folder) => folder.isCollapsed) &&
    reviewerTeams.every((team) =>
      team.folders.every((folder) => folder.isCollapsed)
    )
  );
};

const SidebarHeader = ({
  canCreateProjects,
  canManageTemplates,
  onClick,
  onClickFolder,
  onClickTemplate,
}) => {
  const { state, dispatch } = useSidebarState();
  const { t } = useTranslation();
  const team = useSelectedTeam();
  const user = useActiveUser();

  const [sortMenuAnchor, setSortMenuAnchor] = useState(null);
  const [buttonDropdownAnchor, setButtonDropdownAnchor] = useState(null);

  const closeMenu = () => {
    setSortMenuAnchor(null);
  };

  const openMenu = (event) => {
    const isCollapsed = areFoldersCollapsed(
      user.settings.collapsedFavoriteProjects,
      state.rawFolders,
      state.rawReviewerTeams
    );

    dispatch({
      type: SIDEBAR_ACTION_TYPES.UPDATE_STATUS_COLLAPSE_ALL_FOLDERS,
      payload: { isCollapsed },
    });

    setSortMenuAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setButtonDropdownAnchor(null);
  };

  const handleMenuItemClick = (_, value) => {
    handleClose();
    if (value === "FOLDER") {
      onClickFolder();
    } else {
      onClickTemplate();
    }
  };

  const handleToggle = (event) => {
    setButtonDropdownAnchor(event.currentTarget);
  };

  const handleViewProjectTemplate = () => {
    dispatch({
      type: PROJECT_TEMPLATES_ACTION_TYPES.TOGGLE_PROJECT_TEMPLATE,
    });
    userService.update({
      settings: { viewProjectTemplates: !state.areProjectTemplatesShown },
    });
  };

  const handleViewArchivedProject = () => {
    analytics.track(
      state.areArchivedProjectsShown
        ? analytics.ACTION.HIDE
        : analytics.ACTION.SHOW,
      analytics.CATEGORY.ARCHIVED_PROJECTS
    );
    dispatch({
      type: PROJECT_LIST_ACTION_TYPES.TOGGLE_ARCHIVE_PROJECTS,
    });
    userService.update({
      settings: { viewArchivedProjects: !state.areArchivedProjectsShown },
    });
  };

  const handleExpandCollapseAll = () => {
    const isCollapsed = areFoldersCollapsed(
      user.settings.collapsedFavoriteProjects,
      state.rawFolders,
      state.rawReviewerTeams
    );

    analytics.track(
      analytics.ACTION.CLICKED,
      !isCollapsed
        ? analytics.CATEGORY.PROJECT_SETTINGS_COLLAPSE_ALL_FOLDERS
        : analytics.CATEGORY.PROJECT_SETTINGS_EXPAND_ALL_FOLDERS
    );

    userService.collapseAllFolders(!isCollapsed, team._id, user);

    dispatch({
      type: SIDEBAR_ACTION_TYPES.TOGGLE_ALL_FOLDERS_COLLAPSED,
      payload: { isCollapsed: !isCollapsed },
    });
  };

  useHotkeys(KEYBOARD_SHORTCUTS.Q, handleExpandCollapseAll, [
    user?.settings,
    state.rawFolders,
    state.rawReviewerTeams,
  ]);

  useEffect(() => {
    const updateHandleCollapseAllFolders = ({ collapsed }) => {
      dispatch({
        type: SIDEBAR_ACTION_TYPES.TOGGLE_ALL_FOLDERS_COLLAPSED,
        payload: { isCollapsed: collapsed },
      });
    };

    ws.addListener(
      ws.EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
      updateHandleCollapseAllFolders
    );

    return () => {
      ws.removeListener(
        ws.EVENT_NAMES.DASHBOARD.SIDEBAR.COLLAPSE_FOLDERS,
        updateHandleCollapseAllFolders
      );
    };
  }, [dispatch]);

  return (
    <>
      <Box
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        data-testid="sidebar-header"
        py={1.5}
        px={2}
        borderBottom="1px solid var(--mui-palette-grey-200)"
      >
        <ButtonGroup
          variant="outlined"
          disabled={!canCreateProjects}
          className="tour__create-project-group"
          color="primaryV2"
        >
          <Button
            className="tour__create-project"
            onClick={onClick}
            data-testid="project-list-header-create-project"
          >
            {t("PROJECT.CREATE_PROJECT")}
          </Button>
          <Button
            size="small"
            className="tour__create-project-dropdown"
            onClick={handleToggle}
            disabled={!canCreateProjects}
            data-testid="project-list-header-dropdown"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Tooltip title={t("SORT_FILTER.SIDEBAR.TOOLTIP")}>
          <IconButton
            size="small"
            aria-controls="sortMenu"
            aria-haspopup="true"
            className="tour__sort-project"
            onClick={openMenu}
            data-testid="project-list-header-sort-menu"
          >
            <SvgIcon component={TunerIcon} inheritViewBox fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Menu
          id="sortMenu"
          anchorEl={sortMenuAnchor}
          open={Boolean(sortMenuAnchor)}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          data-testid="project-list-header-sort-menu-options"
          MenuListProps={{
            subheader: <ListSubheader>{t("CORE.SORT_BY")}</ListSubheader>,
            sx: { maxHeight: "fit-content" },
          }}
        >
          {PROJECT_SORT_OPTIONS.map((sortOption, index) => (
            <ProjectListHeaderMenuItem
              key={index}
              sortOption={sortOption}
              closeMenu={closeMenu}
            />
          ))}
          <Divider />
          <ListSubheader>{t("PROJECT.SORT_OPTIONS.VIEW")}</ListSubheader>
          {team.permissions.canManageTemplates && (
            <MenuItem
              key="areProjectTemplatesShown"
              onClick={handleViewProjectTemplate}
            >
              <ListItemIcon>
                <Checkbox
                  size="small"
                  checked={state.areProjectTemplatesShown || false}
                  inputProps={{
                    "data-testid": "checkbox-project-template",
                  }}
                  onChange={handleViewProjectTemplate}
                />
              </ListItemIcon>
              <ListItemText>{t("PROJECT.TEMPLATES")}</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            key="areArchivedProjectsShown"
            onClick={handleViewArchivedProject}
          >
            <ListItemIcon>
              <Checkbox
                checked={state.areArchivedProjectsShown || false}
                inputProps={{
                  "data-testid": "checkbox-archive-project",
                }}
                onChange={handleViewArchivedProject}
              />
            </ListItemIcon>
            <ListItemText>{t("ARCHIVED_PROJECTS.SIDEBAR.MENU")}</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            data-testid="expand-collapse-all-folders"
            onClick={handleExpandCollapseAll}
            data-expand-collapse-status={state.allFoldersCollapsed}
          >
            <ListItemIcon>
              {state.allFoldersCollapsed ? (
                <ArrowExpandedIcon />
              ) : (
                <ArrowCollapsedIcon />
              )}
            </ListItemIcon>
            <ListItemText
              primary={t(
                state.allFoldersCollapsed
                  ? "PROJECT.SORT_OPTIONS.EXPAND_ALL_FOLDERS"
                  : "PROJECT.SORT_OPTIONS.COLLAPSE_ALL_FOLDERS"
              )}
            />
            <Text
              bgcolor="grey.200"
              borderRadius={1}
              fontSize="textSm.fontSize"
              fontWeight="fontWeight.semiBold"
              sx={{ px: 1, ml: state.allFoldersCollapsed ? 2 : 1 }}
            >
              Q
            </Text>
          </MenuItem>
        </Menu>
      </Box>
      <Menu
        open={Boolean(buttonDropdownAnchor)}
        anchorEl={buttonDropdownAnchor}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          className="tour__create-folder"
          onClick={(event) => handleMenuItemClick(event, "FOLDER")}
          data-testid="project-list-header-dropdown-FOLDER"
        >
          {t("PROJECT.CREATE_FOLDER")}
        </MenuItem>
        {canManageTemplates && (
          <MenuItem
            className="tour__create-template"
            onClick={(event) => handleMenuItemClick(event, "TEMPLATE")}
            data-testid="project-list-header-dropdown-TEMPLATE"
          >
            {t("PROJECT_TEMPLATES.ADD.CREATE")}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

SidebarHeader.propTypes = {
  canCreateProjects: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickFolder: PropTypes.func.isRequired,
  onClickTemplate: PropTypes.func.isRequired,
  canManageTemplates: PropTypes.bool,
};

SidebarHeader.defaultProps = {
  canManageTemplates: false,
};

export default SidebarHeader;
