/**
 * Mentions parsing decode helper function
 *
 * @description This function is used to decode the comment text
 * and replace the special mention syntax with user information.
 * @typedef User
 * @param {string} commentText - [Required] Comment text to be parsed.
 * @param {Array.<User>} users - [Required] Users array that will be
 * used to replace the mention syntax.
 * @returns {string} Returns parsed comment text with user information.
 */
export const decode = (commentText, users) =>
  commentText.replace(/(?:^|\s)\[~[^\]]+[a-z0-9]+\]/gim, (match) => {
    const foundUser = users.find((user) => match.search(user._id) !== -1);

    if (!foundUser) {
      return match;
    }

    if (foundUser.email) {
      return ` @${foundUser.email}`.replace(/\s+$/gim, "");
    }

    return ` @${foundUser.displayName}`.replace(/\s+$/gim, "");
  });

/**
 * Mentions parsing encode helper function
 *
 * @description This function is used to encode the comment text
 * and replace the special user id syntax with mention syntax
 * @typedef User
 * @param {string} commentText - [Required] Comment text to be parsed.
 * @param {Array.<User>} users - [Required] Users array that will be
 * used to detect the syntax and replace with mention syntax.
 * @returns {string} Returns parsed comment text with mentions.
 */
export const encode = (commentText, users) =>
  commentText.replace(/(?:^|\s)@\S+@\S+\b/gim, (match) => {
    const email = match.replace(/^\s*?@/, "");
    const foundUser = users.find((user) => user.email === email);

    return foundUser ? ` [~${foundUser._id}]`.replace(/\s+$/gim, "") : match;
  });

export default {
  encode,
  decode,
};
