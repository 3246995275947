import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import mondayAppService from "@integrations/services/mondayAppService";
import { Button, FormControl } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useGatedFeature } from "@supporting/hooks/useGatedFeature";
import { AddProjectGate } from "@supporting/hooks/useGatedFeature/featureGates";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import CreateProjectDialog from "@workflow/components/CreateProjectDialog/CreateProjectDialog";
import projectService from "@workflow/services/projectService";
import projectSwitcherService from "@workflow/services/projectSwitcherService";

import { Box, Text } from "@shared/UIKit";

import UserMenu from "@shared/components/DashboardNavBar/UserMenu/UserMenu";
import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import { useDialogState, useNavigation } from "@shared/hooks";

import DropDownIcon from "@assets/img/icons/drop-down-icon.svg";

export default function MondayAppProjectList() {
  const team = useSelectedTeam();
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const { checkAndProceed } = useGatedFeature({
    featureGate: AddProjectGate,
    currentValue: team?.usedBillingLimits?.numberOfActiveProjects,
    teamId: team?._id,
  });

  const [projectListData, setProjectListData] = useState({
    folders: [],
    makingRequest: false,
  });
  const createProjectDialog = useDialogState();

  const changeProject = async (projectId) => {
    const project = await projectService.fetchProjectById(projectId);

    projectSwitcherService.setSelectedProject(project);
    goTo("PROJECT_ID", { params: { projectId } });
  };

  useEffect(() => {
    function flattenFolders(folders) {
      const flattenedProjectsFolder = [];

      for (const folder of folders) {
        for (const project of folder.projects) {
          flattenedProjectsFolder.push({
            projectName: project.name,
            projectId: project.id,
            folderName: folder.name,
            folderId: folder.id,
          });
        }
      }

      return flattenedProjectsFolder;
    }

    async function fetchProjectsByTeamId() {
      const folders = await projectService.fetchProjectsByTeamId(team._id);

      setProjectListData((previousState) => ({
        ...previousState,
        folders: flattenFolders(folders),
      }));
    }

    async function redirectToConnectedInstance() {
      const instance = await mondayAppService.getConnectedInstance(team._id);

      if (instance) {
        const fileDeepLink = instance.fileDeepLink;
        const projectId = instance.projectId;

        if (fileDeepLink && instance.hasReviews) {
          window.location.href = fileDeepLink;
        } else if (fileDeepLink && !instance.hasReviews) {
          goTo("MONDAY_APP_EMPTY_REVIEWS", {
            search: new URLSearchParams({
              projectId,
            }).toString(),
          });
        } else {
          changeProject(projectId);
        }
      }
    }

    if (team?._id) {
      fetchProjectsByTeamId();
      redirectToConnectedInstance();
      mondayAppService.addValueCreatedEvent();
      mondayAppService.addInstalledAnalytics();
    }
  }, [team]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSelectProject = async (event, inputValue) => {
    event.preventDefault();
    setProjectListData((previousState) => ({
      ...previousState,
      makingRequest: true,
    }));

    const { projectId } = inputValue;

    await mondayAppService.linkItemOrBoardToProject(team._id, projectId);

    changeProject(projectId);
    setProjectListData((previousState) => ({
      ...previousState,
      makingRequest: false,
    }));
  };

  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      bgcolor="white"
      alignItems="flex-start"
    >
      <Box
        flex={1}
        overflow="auto"
        alignItems="center"
        display="flex"
        width="100%"
      >
        <Box
          minWidth="50%"
          display="flex"
          width="100%"
          height="100%"
          justifyContent="center"
        >
          {createProjectDialog.isOpen && (
            <CreateProjectDialog cancel={createProjectDialog.closeDialog} />
          )}

          <Box p={7.1} minWidth="50%">
            <Box flex={1}>
              <Box display="flex" justifyContent="space-between">
                <FilestageResponsiveLogo />
                <UserMenu />
              </Box>
              <Box display="flex" justifyContent="space-between" pt={4.62}>
                <Text variant="textXl">{t("MONDAY_INTEGRATION.TITLE")}</Text>
              </Box>
              <Box pt={2}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    options={projectListData.folders}
                    groupBy={(option) => option.folderName}
                    getOptionLabel={(option) => option.projectName}
                    popupIcon={<DropDownIcon />}
                    onChange={handleSelectProject}
                    disabled={projectListData.makingRequest}
                    data-testid="monday-app-select-project"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={t("MONDAY_INTEGRATION.SELECT")}
                      />
                    )}
                  />
                </FormControl>
              </Box>

              <Box pt={2}>
                {team?.permissions.canCreateProjects && (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={checkAndProceed(createProjectDialog.openDialog)}
                    data-testid="create-project"
                    disabled={projectListData.makingRequest}
                  >
                    {t("MONDAY_INTEGRATION.CREATE_PROJECT")}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
