import PropTypes from "prop-types";

import { useTheme } from "@mui/styles";

import { Text, Tooltip } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

function FileVersionInfoItem({
  children,
  color,
  noTooltip,
  toolTipTitle,
  ...restProps
}) {
  const theme = useTheme();

  return (
    <Tooltip title={toolTipTitle} disabled={noTooltip} placement="bottom">
      <Text
        variant="subtitle2"
        fontSize={FSTGTypography.fontSize_1_4}
        lineHeight={FSTGTypography.lineHeight_1_2}
        color={color || theme.color.gray[600]}
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        {...restProps}
      >
        {children}
      </Text>
    </Tooltip>
  );
}

FileVersionInfoItem.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  noTooltip: PropTypes.bool,
  toolTipTitle: PropTypes.string,
};

FileVersionInfoItem.defaultProps = {
  noTooltip: false,
  color: null,
  toolTipTitle: "",
};

export default FileVersionInfoItem;
