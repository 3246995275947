import { useCallback } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";
import { isPast } from "date-fns";

import { Box } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { pendingReviewProp } from "@shared/props/pendingReview";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import PendingReviewsCardContent from "./PendingReviewsCardContent";
import PendingReviewsCardHeader from "./PendingReviewsCardHeader";

const useStyles = makeStyles((theme) => ({
  reviewCard: {
    cursor: "pointer",
    backgroundColor: theme.color.white,
    marginBottom: 2,
    width: "95%",
    border: `1px solid ${theme.color.gray[300]}`,
    "&:hover": {
      boxShadow: theme.shadow["box-shadow-z3"],
      "& .overlay": {
        display: "flex",
      },
    },
    "& .overlay": {
      display: "none",
      backgroundColor: theme.color["black-translucent-60"],
      position: "absolute",
      width: 100,
      minWidth: 100,
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  passedReview: {
    border: `1px solid ${theme.color.orange[900]}`,
  },
}));

function PendingReviewsCard({ pendingReview }) {
  const { id: reviewId, project, step, file, dueDate } = pendingReview;
  const classes = useStyles();

  const { goTo, routeNames } = useNavigation();

  const openReview = useCallback(
    (event) => {
      event.stopPropagation();

      const params = {
        stepId: step.id,
        fileId: file.id,
        reviewId,
        comment: null,
      };

      analytics.track(
        analytics.ACTION.OPENED_FILE,
        analytics.CATEGORY.PENDING_REVIEW
      );

      eventService.emitEvent({
        eventName: EVENT_NAMES.PENDING_REVIEWS.DRAWER.CLOSE_TRIGGERED,
        eventData: {},
      });

      return goTo(routeNames.REVIEW_LINK, {
        params,
        options: {
          notify: true,
        },
      });
    },
    [step.id, file.id, reviewId, goTo, routeNames.REVIEW_LINK]
  );

  return (
    <Box
      borderRadius="3px"
      m={0.5}
      className={classnames(classes.reviewCard, {
        [classes.passedReview]:
          dueDate && isPast(new Date(dueDate), new Date()),
      })}
      onClick={openReview}
      data-testid="pending-review-card"
    >
      <PendingReviewsCardHeader project={project} step={step} />
      <PendingReviewsCardContent
        project={project}
        step={step}
        file={file}
        dueDate={dueDate}
      />
    </Box>
  );
}

PendingReviewsCard.propTypes = {
  pendingReview: pendingReviewProp.isRequired,
};

export default PendingReviewsCard;
