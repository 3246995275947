import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import userService from "@supporting/services/userService";

import SortPanel from "@shared/UIKit/SortPanel";

import { instance as analytics } from "@shared/services/analytics";

export const SORT_OPTIONS = {
  DUE_DATE: "DUE_DATE",
  NEWEST: "NEWEST",
  OLDEST: "OLDEST",
};

function PendingReviewsSortPanel({ sortOption, onSortOptionChanged }) {
  const { t } = useTranslation();

  const onSelectClick = (sortKey) => {
    onSortOptionChanged(sortKey);
    userService.update({
      settings: { selectedPendingReviewSortingOption: sortKey },
    });
    analytics.track(
      analytics.ACTION.CHANGED_SORTING,
      analytics.CATEGORY.PENDING_REVIEW,
      {
        label: sortKey,
      }
    );
  };

  return (
    <SortPanel
      selectTitle={t("PENDING_REVIEWS.SORT-BY")}
      options={{
        DUE_DATE: t(`PENDING_REVIEWS.DUE-DATE`),
        NEWEST: t(`PENDING_REVIEWS.NEWEST-FIRST`),
        OLDEST: t(`PENDING_REVIEWS.OLDEST-FIRST`),
      }}
      selectedOption={sortOption}
      onSelect={onSelectClick}
    />
  );
}

PendingReviewsSortPanel.propTypes = {
  sortOption: PropTypes.oneOf(Object.values(SORT_OPTIONS)).isRequired,
  onSortOptionChanged: PropTypes.func.isRequired,
};

export default PendingReviewsSortPanel;
