import PropTypes from "prop-types";
import { ReactElement, useEffect, useState } from "react";

import { Box, LinearProgress } from "@mui/material";

import { uploadStatesProp, UPLOAD_STATES } from "@shared/props/file";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

/**
 * UIKit Upload Progress Bar
 * @description UIKit Upload Progress Bar which is an extended version of Material UI
 * LinearProgressBar. It is being used for the file uploads and the file versions to show
 * user what is the state of the upload operation.
 * @param {object} props - Component props
 * @param {string?} [props.uploadId] [Optional] - Upload Id of the file which should be matched with the eventData.uploadId.
 * @param {string?} [props.state] [Optional] - State of the progress bar. It can be `PENDING`, `UPLOADING`, `TRANSCODING`, `IMPORTING`.
 * @returns {ReactElement} - React component
 */
function UploadProgressBar({ uploadId, state, ...restProps }) {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const updateProgress = ({ eventData }) => {
      if (uploadId === eventData.uploadId) {
        setPercentage(eventData.percentage);
      }
    };
    eventService.addListener(EVENT_NAMES.UPLOAD.PROGRESS, updateProgress);

    return () => {
      eventService.removeListener(EVENT_NAMES.UPLOAD.PROGRESS, updateProgress);
    };
  }, [uploadId]);

  return (
    <Box {...restProps}>
      <LinearProgress
        variant={
          (state === UPLOAD_STATES.UPLOADING && percentage <= 100) ||
          state === UPLOAD_STATES.FAILED
            ? "determinate"
            : "indeterminate"
        }
        color={state === UPLOAD_STATES.FAILED ? "error" : "primary"}
        value={state === UPLOAD_STATES.FAILED ? 100 : percentage}
      />
    </Box>
  );
}

UploadProgressBar.propTypes = {
  uploadId: PropTypes.string,
  state: uploadStatesProp,
};

UploadProgressBar.defaultProps = {
  uploadId: "",
  state: UPLOAD_STATES.UPLOADING,
};

export default UploadProgressBar;
