import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Avatar, Tooltip } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { automationProps } from "@shared/props/automations";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";
import FSTGTypography from "@shared/theme/typography";

import StepReviewerMenu from "./StepReviewerMenu/StepReviewerMenu";

const useStyles = makeStyles((theme) => ({
  Button: {
    padding: 0,
    opacity: 0.9,
    "&:hover": {
      opacity: 1,
    },
  },
  Avatar: {
    width: 32,
    height: 32,
    fontSize: FSTGTypography.fontSize_1_3,
    fontWeight: FSTGTypography.fontWeightMedium,
    backgroundColor: theme.color.blue[400],
    color: "white",
  },
  hiddenButton: {
    backgroundColor: "transparent",
    cursor: "default",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

function StepReviewer({
  user,
  teamId,
  automations,
  step,
  canManageProjectMembers,
  isProjectArchived,
  showEmpty,
  canManageProjectTemplates,
  isProjectTemplate,
  team,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const closeMenu = useCallback(() => setMenuAnchorEl(null), []);

  const handleClickAway = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleAvatarClick = useCallback(
    (event) => {
      if (menuAnchorEl) {
        handleClickAway(event);
      } else {
        setMenuAnchorEl(event.currentTarget);
        /* opened-review-step-settings */
      }
      event.stopPropagation();
    },
    [menuAnchorEl, setMenuAnchorEl, handleClickAway]
  );

  if (showEmpty) {
    return (
      <IconButton
        data-testid="empty-step-reviewer-button-element"
        className={classnames(classes.Button, classes.hiddenButton)}
        disableRipple
        disableFocusRipple
        size="large"
      />
    );
  }

  return (
    <>
      <Tooltip
        title={`${user.displayName} ${user.youTagEnabled ? t("CORE.YOU") : ""}`}
        placement="top"
      >
        <IconButton
          data-testid="step-reviewer-button-element"
          onClick={handleAvatarClick}
          className={classes.Button}
          size="large"
        >
          <Avatar user={user} className={classes.Avatar} />
        </IconButton>
      </Tooltip>

      <StepReviewerMenu
        closeMenu={closeMenu}
        menuAnchorEl={menuAnchorEl}
        user={user}
        automations={automations}
        teamId={teamId}
        step={step}
        canManageProjectMembers={canManageProjectMembers}
        isProjectArchived={isProjectArchived}
        canManageProjectTemplates={canManageProjectTemplates}
        isProjectTemplate={isProjectTemplate}
        team={team}
      />
    </>
  );
}

StepReviewer.propTypes = {
  user: userProp.isRequired,
  teamId: PropTypes.string.isRequired,
  step: stepProps,
  canManageProjectMembers: PropTypes.bool.isRequired,
  isProjectArchived: PropTypes.bool.isRequired,
  automations: PropTypes.arrayOf(automationProps),
  showEmpty: PropTypes.bool,
  canManageProjectTemplates: PropTypes.bool,
  isProjectTemplate: PropTypes.bool,
  team: teamProp.isRequired,
};

StepReviewer.defaultProps = {
  showEmpty: false,
  step: null,
  canManageProjectTemplates: false,
  isProjectTemplate: false,
  automations: [],
};

export default StepReviewer;
