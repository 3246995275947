import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import TypeformDialog from "@integrations/components/TypeformDialog/TypeformDialog";
import { AddBoxOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import authenticationService from "@supporting/services/authentication";

import { useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

function AutomationRequestFormButton() {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [openedTypeForm, setOpenedTypeForm] = useState(false);

  const currentUser = authenticationService.fetchUser();

  const closeTypeFormDialog = useCallback(() => {
    setOpenedTypeForm(false);
  }, []);

  const handleRequestAutomation = useCallback(() => {
    setOpenedTypeForm(true);
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.AUTOMATION_REQUEST_BUTTON
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Button
        color="accent"
        variant="highlight"
        startIcon={<AddBoxOutlined />}
        data-testid="request-automation-form-button"
        onClick={handleRequestAutomation}
        sx={{ "& .MuiButton-startIcon": { ml: { xs: 1, sm: 0 } } }}
      >
        {!isMobile && t("AUTOMATIONS.DIALOG.REQUEST_AUTOMATION")}
      </Button>
      {openedTypeForm && (
        <TypeformDialog
          formId="GovVP0c6"
          hidden={`email=${currentUser.email},firstname=${currentUser.fullName}`}
          closeDialog={closeTypeFormDialog}
        />
      )}
    </>
  );
}

AutomationRequestFormButton.propTypes = {
  showText: PropTypes.bool,
};

AutomationRequestFormButton.defaultProps = {
  showText: true,
};

export default AutomationRequestFormButton;
