import { useTranslation } from "react-i18next";

import { Cancel } from "@mui/icons-material";
import { IconButton, Box } from "@mui/material";
import { instance as fileService } from "@workflow/services/fileService";
import last from "lodash/last";

import { Tooltip, MimeTypeIcon, UploadProgressBar } from "@shared/UIKit";

import { FILE_VERSION_UPLOAD_STATUS } from "@shared/constants/upload";
import { fileProp, UPLOAD_STATES } from "@shared/props/file";

export default function FileCardThumbnail({ file }) {
  const { progress, isProcessing } = file;
  const activeVersion = last(file.versions);
  const { t } = useTranslation();

  const uploadStatus = file.versions.at(-1).uploadStatus;
  const handleCancel = () => {
    fileService[isProcessing ? "cancelUpload" : "removeFile"](file);
  };

  return (
    <Box
      display="flex"
      position="relative"
      height={78}
      width={112}
      mx={0.625}
      mt={1.5}
      alignItems="center"
      data-testid="onboarding-file-thumbnail-container"
    >
      <Box
        overflow="hidden"
        borderRadius="9px"
        bgcolor="grey.200"
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        {activeVersion?.thumbnail?.url ? (
          <Box
            sx={{
              minWidth: "100%",
              width: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "100%",
              transition:
                "filter 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s cubic-bezier(0.23, 1, 0.32, 1)",
              backgroundImage: `url(${activeVersion.thumbnail.url})`,
            }}
            data-testid="file-card-thumbnail"
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              minWidth: 0,
              transition:
                "filter 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s cubic-bezier(0.23, 1, 0.32, 1)",
            }}
          >
            <MimeTypeIcon
              sx={{ color: "light", width: 26, height: 26 }}
              version={activeVersion}
              data-testid="file-card-fallback-thumbnail"
            />
          </Box>
        )}

        {uploadStatus !== FILE_VERSION_UPLOAD_STATUS.COMPLETED && (
          <UploadProgressBar
            uploadId={progress?.uploadId || file.id}
            state={
              file.progress?.state ||
              (uploadStatus === FILE_VERSION_UPLOAD_STATUS.FAILED
                ? UPLOAD_STATES.FAILED
                : UPLOAD_STATES.TRANSCODING)
            }
            variant="fileCard"
            sx={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "100%",
              display: "block",
            }}
          />
        )}
      </Box>
      <Box
        position="absolute"
        top={-11}
        right={-11}
        display="flex"
        flexDirection="column"
      >
        <Tooltip
          title={t(
            isProcessing ? "FILE.CANCEL_UPLOAD.CONFIRM" : "PROJECT.FILE.DELETE"
          )}
        >
          <IconButton
            size="small"
            onClick={handleCancel}
            data-testid="file-card-cancel-button"
          >
            <Cancel fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
}

FileCardThumbnail.propTypes = {
  file: fileProp.isRequired,
};
