import PropTypes from "prop-types";
import { ReactElement, FC } from "react";

/**
 * UIKit Wizard Dialog Step Component
 * @param {{
 *     stepKey: {string?},
 *     title: {ReactElement?},
 *     content: {ReactElement?},
 *     children: {ReactElement?}
 * }} props - Component props
 * @description Defines a step in wizard dialog
 * @param {string?} props.stepKey [Optional] - A unique key for step. This key can be used to navigate to this step through moveToStep(...)
 * @param {ReactElement?}  props.title [Optional] - The title component for step
 * @param {ReactElement | FC} props.content [Optional] - The content of the step. Children can be used instead of this
 * @param {ReactElement | FC} props.children [Optional] - The content of the step. Content prop can be used in place of this
 * @param {object?} props.contentProps [Optional] - Additional props to be passed to functional component specified in child or content
 * @returns {ReactElement} - React component
 */
function WizardStep(props) {
  const Component = props.content || props.children;
  return typeof Component === "function" ? (
    <Component {...props.contentProps} />
  ) : (
    Component
  );
}

WizardStep.propTypes = {
  stepKey: PropTypes.string,
  title: PropTypes.node,
  contentProps: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool])
  ),
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
};

WizardStep.defaultProps = {
  stepKey: null,
  children: null,
  content: null,
  title: null,
  contentProps: null,
};

export default WizardStep;
