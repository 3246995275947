/* eslint-disable max-lines */
/**
 * This service provides, for every section, the data necessary for UI rendering.
 * The cache is a map of section ID to SectionData.
 *
 * @typedef SectionData
 * @property {string} projectId the project ID.
 * @property {Array<File>} serverFiles the server files loaded so far.
 * @property {Array<File>} files the files to show on the UI.
 * @property {number|null} fileCount the total number of files in the section or
 * <code>null</code> if it's not loaded yet.
 * @property {boolean} hasMore whether there are more files to fetch.
 */
import authenticationService from "@supporting/services/authentication";
import { instance as fileService } from "@workflow/services/fileService";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";

import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import {
  sortFiles,
  sortFilesByUserOption,
} from "./dashboardSectionFilesSorter";

/**
 * Map of section ID to SectionData.
 * @type {Map<string, SectionData>}
 */
const sectionFilesCache = new Map();
/**
 * Map of section ID to file count (or null if it was not loaded yet).
 * @type {Map<string, number|null>}
 */
const sectionFilesCountCache = new Map();
/**
 * Map of promises for currently loading section file counts per project ID.
 * @type {Map<string, Promise<Map<string, number>>>}
 */
const fetchSectionsFileCountPromises = new Map();
/**
 * The number of files to fetch at a time (20).
 */
const limit = 20;
/**
 * The file sorting option.
 * @type {string}
 */
let sort = undefined;

export default {
  initialize,
  getSectionFiles,
  getMoreSectionFiles,
  getProjectFileCount,
};

function initialize() {
  clearCache();

  const user = authenticationService.fetchUser();
  sort = user?.settings.selectedFileSortingOption;

  eventService.addListener(EVENT_NAMES.USER.UPDATED, onUserUpdated);
  eventService.addListener(EVENT_NAMES.FILE.UPDATED, onFileUpdated);
  eventService.addListener(EVENT_NAMES.FILE.REMOVED, onFileRemoved);
  eventService.addListener(
    EVENT_NAMES.FILE.UPLOAD.STARTED,
    onFileUploadStarted
  );
  eventService.addListener(
    EVENT_NAMES.FILE.UPLOAD.FINISHED,
    onFileUploadFinished
  );
  eventService.addListener(
    EVENT_NAMES.STEP.REVIEWERS.UPDATED,
    onStepReviewersUpdated
  );
  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);
}

/***
 * Get the section files from the cache.
 * If the cache does not contain the section, create an empty section data and return it.
 * @param {string} projectId The project ID.
 * @param {string} sectionId The section ID.
 * @returns {SectionData} the section data.
 */
function getSectionFiles(projectId, sectionId) {
  if (!sectionFilesCache.has(sectionId)) {
    sectionFilesCache.set(sectionId, {
      projectId,
      serverFiles: [],
      files: [],
      fileCount: getSectionFileCount(projectId, sectionId),
      hasMore: true,
    });
  }
  return sectionFilesCache.get(sectionId);
}

/**
 * Get the next batch of section files from the server and return all files for the section.
 *
 * @param {string} projectId The project ID.
 * @param {string} sectionId The section ID.
 * @returns {Promise<SectionData>} The section data.
 */
async function getMoreSectionFiles(projectId, sectionId) {
  const data = sectionFilesCache.get(sectionId);
  return data.hasMore
    ? await fetchMoreFilesAndUpdateCache(
        projectId,
        sectionId,
        data.serverFiles.length
      )
    : data;
}

/**
 * @param {string} projectId The project ID.
 * @returns {number} The total number of files for the given project.
 */
function getProjectFileCount(projectId) {
  return Array.from(sectionFilesCache.values()).reduce((count, sectionData) => {
    return sectionData.projectId === projectId
      ? count + sectionData.fileCount
      : count;
  }, 0);
}

function onUserUpdated(event) {
  const user = event.eventData.user;
  if (sort && sort !== user.settings.selectedFileSortingOption) {
    const entries = Array.from(sectionFilesCache.entries());
    sectionFilesCache.clear();
    entries.forEach(([sectionId, { projectId }]) =>
      emitSectionFilesUpdated(sectionId, {
        projectId,
        files: [],
        fileCount: null,
        hasMore: true,
      })
    );
  }
  sort = user.settings.selectedFileSortingOption;
}

function onFileUpdated(event) {
  const { file, oldFile } = event.eventData;
  if (oldFile && oldFile.sectionId !== file.sectionId) {
    replaceFileInSectionFilesCache(oldFile, file);
  } else {
    updateFileInSectionFilesCache(file);
  }
}

function onFileRemoved(event) {
  removeFileFromSectionFilesCacheAndNotify(event.eventData.file);
}

function onFileUploadStarted(event) {
  addFileToSectionFilesCache(event.eventData.dummyFile);
}

async function onFileUploadFinished(event) {
  const { fileId, sectionId, file, processingFile } = event.eventData;
  if (processingFile?.isDummy && isFileInCache(processingFile)) {
    replaceFileInSectionFilesCache(processingFile, file);
  } else if (sectionFilesCache.has(sectionId)) {
    addFileToSectionFilesCache(file || (await fileService.getFile(fileId)));
  } else {
    // Ignore file since it's not from a section in cache
  }
}

function onStepReviewersUpdated(event) {
  for (const sectionId of sectionFilesCache.keys()) {
    updateStepReviews(sectionId, event.eventData.step);
  }
}

function replaceFileInSectionFilesCache(oldFile, file) {
  removeFileFromSectionFilesCache(oldFile);
  return addFileToSectionFilesCache(file);
}

function updateStepReviews(sectionId, updatedStep) {
  if (filesHaveReviewsOnStep(sectionId, updatedStep.id)) {
    updateSectionFilesCacheAndNotify(
      updatedStep.projectId,
      sectionId,
      ({ projectId, serverFiles, files, fileCount, hasMore }) => ({
        projectId,
        serverFiles: updateFileReviews(serverFiles, updatedStep),
        files: updateFileReviews(files, updatedStep),
        fileCount,
        hasMore,
      })
    );
  }
  return updatedStep;
}

function filesHaveReviewsOnStep(sectionId, stepId) {
  const { files } = sectionFilesCache.get(sectionId);
  return files.some((file) => fileHasReviewsOnStep(file, stepId));
}

/**
 * Checks if the file has reviews on the given step.
 * @param {object} file The file object.
 * @param {string} stepId The step ID.
 * @returns {boolean} True if the file has reviews on the given step.
 */
function fileHasReviewsOnStep(file, stepId) {
  return file.versions.some((version) =>
    version.reviews.some((review) => review.stepId === stepId)
  );
}

function updateReview(review, updatedStep) {
  const decisions = updateReviewDecisions(review, updatedStep);
  const user = authenticationService.fetchUser();
  const userDecision = decisions.find(
    (decision) => decision.reviewer._id === user._id
  );
  return {
    ...review,
    reviews: decisions,
    isPendingYourReview:
      Boolean(userDecision) &&
      userDecision.state === "PENDING" &&
      review.status.state === "IN_REVIEW",
    role: {
      ...review.role,
      isReviewer: Boolean(userDecision),
    },
  };
}

function updateReviewDecisions(review, updatedStep) {
  const decisionByReviewerId = keyBy(review.reviews, "reviewer._id");
  const reviewers = [
    ...updatedStep.reviewers.filter(
      (reviewer) => reviewer.reviewDecisionRequested
    ),
    ...review.reviews
      .filter((decision) => decision.state !== "PENDING")
      .map((decision) => decision.reviewer),
  ];
  const reviewersById = keyBy(reviewers, "_id");
  const reviewerIds = uniq(reviewers.map((reviewer) => reviewer._id));

  return reviewerIds.map((reviewerId) => ({
    ...(decisionByReviewerId[reviewerId] || { state: "PENDING" }),
    reviewer: reviewersById[reviewerId],
  }));
}

/**
 * Updates the reviews decisions of the given files accordingly to the updated step reviewers.
 * @param {Array<object>} files the files to update
 * @param {object} updatedStep the step
 * @returns {Array<object>} the list of updated files.
 */
function updateFileReviews(files, updatedStep) {
  return files.map((file) =>
    fileHasReviewsOnStep(file, updatedStep.id)
      ? {
          ...file,
          versions: file.versions.map((version) => ({
            ...version,
            reviews: version.reviews.map((review) =>
              review.stepId === updatedStep.id
                ? updateReview(review, updatedStep)
                : review
            ),
          })),
        }
      : file
  );
}

function emitSectionFilesUpdated(
  sectionId,
  { projectId, files, fileCount, hasMore }
) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTION_FILES.UPDATED,
    eventData: { projectId, sectionId, files, fileCount, hasMore },
  });
}

async function fetchMoreFilesAndUpdateCache(projectId, sectionId, skip) {
  const { files, hasMore } = await fileService.getSectionFiles(
    sectionId,
    skip,
    limit
  );

  return updateSectionFilesCache(projectId, sectionId, (data) => ({
    projectId,
    serverFiles: addUniqueFiles(data.serverFiles, files),
    files: sortFiles(addUniqueFiles(data.files, files)),
    fileCount: getSectionFileCount(projectId, sectionId),
    hasMore,
  }));
}

function addUniqueFiles(currentFiles, newFiles) {
  const filesById = keyBy(currentFiles, "_id");
  return [
    ...currentFiles,
    ...newFiles.filter((_file) => !(_file._id in filesById)),
  ];
}

function removeFileFromSectionFilesCacheAndNotify(file) {
  if (sectionFilesCache.has(file.sectionId)) {
    updateSectionFilesCacheAndNotify(file.projectId, file.sectionId, (data) =>
      removeDataFile(data, file)
    );
  }
}

function removeFileFromSectionFilesCache(file) {
  if (sectionFilesCache.has(file.sectionId)) {
    updateSectionFilesCacheAndNotify(file.projectId, file.sectionId, (data) =>
      removeDataFile(data, file)
    );
  }
}

function addFileToSectionFilesCache(file) {
  if (sectionFilesCache.has(file.sectionId)) {
    updateSectionFilesCacheAndNotify(file.projectId, file.sectionId, (data) =>
      addDataFile(addDataServerFile(data, file), file)
    );
  }
}

function updateFileInSectionFilesCache(file) {
  if (sectionFilesCache.has(file.sectionId)) {
    updateSectionFilesCacheAndNotify(file.projectId, file.sectionId, (data) =>
      updateDataFile(data, file)
    );
  }
}

function addDataServerFile(
  { projectId, serverFiles, files, fileCount, hasMore },
  file
) {
  const newServerFiles = sortFilesByUserOption([...serverFiles, file]);
  const isLastFile = newServerFiles[newServerFiles.length - 1]._id === file._id;
  const canInsert = !file.isDummy && !(isLastFile && hasMore);
  return canInsert
    ? {
        projectId,
        serverFiles: newServerFiles,
        files,
        fileCount,
        hasMore,
      }
    : { projectId, serverFiles, files, fileCount, hasMore };
}

function addDataFile(
  { projectId, serverFiles, files, fileCount, hasMore },
  file
) {
  const isInServerFiles = serverFiles.some((_file) => _file._id === file._id);
  const canAdd = file.isDummy || isInServerFiles;
  sectionFilesCountCache.set(file.sectionId, fileCount + 1);
  return {
    projectId,
    serverFiles,
    files: canAdd ? sortFiles([...files, file]) : files,
    fileCount: getSectionFileCount(projectId, file.sectionId),
    hasMore,
  };
}

function removeDataFile(
  { projectId, serverFiles, files, fileCount, hasMore },
  file
) {
  sectionFilesCountCache.set(file.sectionId, Math.max(fileCount - 1, 0));
  return {
    projectId,
    serverFiles: serverFiles.filter((_file) => _file._id !== file._id),
    files: files.filter((_file) => _file._id !== file._id),
    fileCount: getSectionFileCount(projectId, file.sectionId),
    hasMore,
  };
}

function updateDataFile(
  { projectId, serverFiles, files, fileCount, hasMore },
  file
) {
  return {
    projectId,
    serverFiles: serverFiles.map((_file) =>
      _file._id === file._id ? file : _file
    ),
    files: sortFiles(
      files.map((_file) => (_file._id === file._id ? file : _file))
    ),
    fileCount,
    hasMore,
  };
}

/**
 * Updates the section files cache for the specified section using the given update function,
 * emits event "event.project.section-files.updated" and returns the updated data.
 * @param {string} projectId the project ID.
 * @param {string} sectionId the section ID.
 * @param {UpdateFunction} updateFn the update function.
 * @returns {SectionData} the updated section data.
 */
function updateSectionFilesCacheAndNotify(projectId, sectionId, updateFn) {
  const updatedData = updateSectionFilesCache(projectId, sectionId, updateFn);
  emitSectionFilesUpdated(sectionId, updatedData);
  return updatedData;
}

/**
 * @typedef UpdateFunction
 * @function
 * @param {SectionData} data The section data.
 * @returns {SectionData} The updated section data.
 */
/**
 * Updates the section files cache for the specified section using the given update function and
 * returns the updated data.
 * @param {string} projectId the project ID.
 * @param {string} sectionId the section ID.
 * @param {UpdateFunction} updateFn the update function.
 * @returns {SectionData} the updated section data.
 */
function updateSectionFilesCache(projectId, sectionId, updateFn) {
  const data = sectionFilesCache.get(sectionId) || {
    projectId,
    serverFiles: [],
    files: [],
    fileCount: null,
    hasMore: false,
  };
  const updatedData = updateFn(data);
  sectionFilesCache.set(sectionId, updatedData);
  return updatedData;
}

function isFileInCache(file) {
  const sectionData = sectionFilesCache.get(file.sectionId);
  return (
    sectionData && sectionData.files.some((_file) => _file._id === file._id)
  );
}

function getSectionFileCount(projectId, sectionId) {
  if (sectionFilesCountCache.has(sectionId)) {
    return sectionFilesCountCache.get(sectionId);
  }
  // Call the backend to get the file count for the project sections and update the cache.
  // We don't wait for the promise to resolve, so that the UI renders the sections immediately
  // without a file count. Once the promise resolves, the UI will be updated with the file counts.
  fetchProjectSectionsFileCount(projectId);
  return null;
}

async function fetchProjectSectionsFileCount(projectId) {
  if (fetchSectionsFileCountPromises[projectId]) {
    return fetchSectionsFileCountPromises[projectId];
  }
  try {
    const promise = fetchSectionsFileCountAndUpdateSections(projectId);
    fetchSectionsFileCountPromises[projectId] = promise;
    return await promise;
  } finally {
    delete fetchSectionsFileCountPromises[projectId];
  }
}

async function fetchSectionsFileCountAndUpdateSections(projectId) {
  const serverFileCountBySectionId = await backend.get(
    `/projects/${projectId}/sectionsFileCount`
  );
  for (const sectionId in serverFileCountBySectionId) {
    const files = sectionFilesCache.get(sectionId)?.files || [];
    const dummyFileCount = files.filter((file) => file.isDummy).length;
    const fileCount = dummyFileCount + serverFileCountBySectionId[sectionId];

    sectionFilesCountCache.set(sectionId, fileCount);
    updateSectionFilesCacheAndNotify(projectId, sectionId, (data) => ({
      ...data,
      fileCount,
    }));
  }
  return serverFileCountBySectionId;
}

function clearCache() {
  sort = undefined;
  sectionFilesCache.clear();
  sectionFilesCountCache.clear();
  fetchSectionsFileCountPromises.clear();
}
