import PropTypes from "prop-types";
import { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, TextField } from "@mui/material";

import { Box, Dialog } from "@shared/UIKit";

const CouponDialog = ({ open, closeDialog, submitCoupon, invalidCoupon }) => {
  const { t } = useTranslation();

  const [couponCode, setCouponCode] = useState("");
  const [errorText, setErrorText] = useState(" ");

  const cancelDialog = useCallback(() => {
    setCouponCode("");
    setErrorText(" ");
    closeDialog();
  }, [closeDialog]);

  const handleInput = (event) => {
    const { value } = event.target;
    setCouponCode(value);
    setErrorText(
      value.length === 0 ? t("VALIDATIONS.ADD_COUPON.REQUIRED") : " "
    );
  };

  const handleSubmit = () => submitCoupon(couponCode);

  useEffect(() => {
    if (invalidCoupon.length > 0) {
      setErrorText(t(`BILLING.ADD_COUPON.ERROR.INVALID`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invalidCoupon]);

  return (
    <Dialog
      disableBackdropClick
      cancel={cancelDialog}
      open={open}
      title={t("BILLING.ADD_COUPON.HEADLINE")}
      actions={
        <Button
          disabled={errorText !== " "}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          {t("BILLING.ADD_COUPON.CONFIRM")}
        </Button>
      }
      hideBackdrop={false}
    >
      <Box display="flex" minWidth={350}>
        <TextField
          label={t("BILLING.ADD_COUPON.LABEL")}
          inputProps={{
            autoFocus: true,
          }}
          type="text"
          id="couponCode"
          value={couponCode}
          name="add_coupon"
          fullWidth
          helperText={errorText}
          onChange={handleInput}
          onBlur={handleInput}
          error={errorText !== " "}
        />
      </Box>
    </Dialog>
  );
};

CouponDialog.propTypes = {
  open: PropTypes.bool,
  closeDialog: PropTypes.func.isRequired,
  submitCoupon: PropTypes.func.isRequired,
  invalidCoupon: PropTypes.array.isRequired,
};

CouponDialog.defaultProps = {
  open: false,
};

export default CouponDialog;
