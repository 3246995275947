import Skeleton from "@mui/material/Skeleton";
import { makeStyles } from "@mui/styles";

import { Box } from "@shared/UIKit";

const useStyles = makeStyles(() => ({
  IndicatorStyle: {
    border: "2px #FFF solid",
    "&:not(:first-child)": {
      marginLeft: -4,
    },
  },
}));

function ReviewCardLoadingState(props) {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignContent="space-between"
      height="100%"
      {...props}
      data-testid="review-card-loading-state"
    >
      <Box
        display="flex"
        flexDirection="row"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Box display="flex" flexDirection="row">
          <Skeleton
            variant="circular"
            className={classes.IndicatorStyle}
            width={16}
            height={16}
          />
          <Skeleton
            variant="circular"
            className={classes.IndicatorStyle}
            width={16}
            height={16}
          />
          <Skeleton
            variant="circular"
            className={classes.IndicatorStyle}
            width={16}
            height={16}
          />
        </Box>
        <Skeleton variant="text" width={65} />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexGrow={1}
        justifyContent="space-between"
        alignItems="flex-end"
        width="100%"
      >
        <Skeleton variant="text" width={80} height={12} />
        <Skeleton variant="text" width={82} height={12} />
      </Box>
    </Box>
  );
}

export default ReviewCardLoadingState;
