import { generatePath } from "react-router-dom";

import { APP_ROUTES } from "@shared/constants/routes";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

let teamConnectionCache;
const projectSettingsCache = new Map();

const generateOauthUrl = (userId, teamId) => {
  const { config } = window.fs;

  const { clientID } = config.google;
  const redirectUrl = `${config.appBase}${generatePath(
    APP_ROUTES.INTEGRATIONS_AUTHORIZATION_CALLBACK.path,
    {
      appId: "google-drive",
    }
  )}`;

  const params = {
    client_id: clientID,
    redirect_uri: redirectUrl,
    response_type: "code",
    scope: [
      "https://www.googleapis.com/auth/drive.metadata.readonly",
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/drive.readonly",
    ].join(" "),
    access_type: "offline",
    state: JSON.stringify({ userId, teamId }),
  };

  const urlParams = new URLSearchParams(params).toString();

  return `https://accounts.google.com/o/oauth2/v2/auth?${urlParams}`;
};

const getTeamConnection = async (teamId) => {
  if (teamConnectionCache?.teamId === teamId) {
    return teamConnectionCache;
  }

  try {
    const teamConnection = await backend.get(`/integrations/google-drive`, {
      teamId,
    });

    teamConnectionCache = teamConnection;
  } catch {
    teamConnectionCache = null;
  }

  return teamConnectionCache;
};

const disconnectApp = async (teamId) => {
  await backend.delete(`/integrations/google-drive`, {
    teamId,
  });

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.DISCONNECTED,
    eventData: {
      teamId,
      type: "google-drive",
    },
  });

  if (teamConnectionCache?.teamId === teamId) {
    teamConnectionCache = null;
  }
};

const getTeamFolders = async ({ teamId, searchText }) => {
  const folders = await backend.get(`/integrations/google-drive/folders`, {
    teamId,
    searchText,
  });

  return folders;
};

const connectGoogleDriveFolderToProject = async ({
  teamId,
  projectId,
  folderId,
  syncApprovedFiles,
}) => {
  const googleDriveProjectConnectionResponse = await backend.post(
    `/integrations/google-drive/project`,
    {
      teamId,
      projectId,
      folderId,
      syncApprovedFiles,
    }
  );

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.PROJECT_CONNECTED,
    eventData: {
      teamId,
      projectId,
      folderId,
      syncApprovedFiles,
    },
  });

  return googleDriveProjectConnectionResponse;
};

const getProjectFolderConnection = async (teamId, projectId) => {
  const projectChannelCacheValue = projectSettingsCache[projectId];

  if (projectChannelCacheValue) {
    return projectChannelCacheValue;
  }

  const projectChannel = await backend.get(
    `/integrations/google-drive/project`,
    {
      teamId,
      projectId,
    }
  );

  projectSettingsCache[projectId] = projectChannel;

  return projectChannel;
};

const disconnectProject = async (teamId, projectId) => {
  await backend.delete(`/integrations/google-drive/project`, {
    projectId,
    teamId,
  });

  projectSettingsCache[projectId] = null;

  eventService.emitEvent({
    eventName: EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.PROJECT_DISCONNECTED,
    eventData: {
      teamId,
      projectId,
    },
  });
};

export default {
  generateOauthUrl,
  getTeamConnection,
  disconnectApp,
  getTeamFolders,
  connectGoogleDriveFolderToProject,
  getProjectFolderConnection,
  disconnectProject,
};
