import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import OnboardingLoadingScreen from "@supporting/components/WizardBase/OnboardingLoadingScreen";
import authenticationService from "@supporting/services/authentication";

import { useNavigation } from "@shared/hooks";

function ExternalLoginCallback() {
  const { goTo } = useNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const finishLogin = async () => {
      const authState = await authenticationService.finishExternalLogin(
        searchParams.get("csrf")
      );
      if (authState?.redirect) {
        navigate(authState.redirect, { state: authState.state });
      } else {
        goTo("DASHBOARD");
      }
    };
    finishLogin();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <OnboardingLoadingScreen />;
}

export default ExternalLoginCallback;
