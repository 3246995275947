import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import GOOGLE_NATIVE_TYPES from "@supporting/constants/googleNativeFileTypes";
import toastService from "@supporting/services/toast";
import partition from "lodash/partition";

import STORAGE from "@shared/constants/storage";
import useGoogleDrive from "@shared/hooks/useGoogleDrive";
import { instance as analytics } from "@shared/services/analytics";

import GoogleDrive from "@assets/img/icons/googledrive_icon.svg";

import RemoteButton from "../../RemoteButton";

const validateGoogleNativeFormats = (files, page, component) => {
  const [validFilesByFormat, googleFormattedFiles] = partition(
    files,
    (file) =>
      file.type &&
      (GOOGLE_NATIVE_TYPES.includes(file.type) ||
        !file.type.includes("application/vnd.google-apps"))
  );
  if (googleFormattedFiles.length > 0) {
    toastService.sendToast({
      title: "FILE.VERSION.GOOGLE_FORMAT_NOT_SUPPORTED.TITLE",
      body: "FILE.VERSION.GOOGLE_FORMAT_NOT_SUPPORTED.BODY",
      preset: toastService.PRESETS().WARNING,
    });
    googleFormattedFiles.forEach((googleFormattedFile) => {
      analytics.track(
        analytics.ACTION.UPLOADED,
        analytics.CATEGORY.FILE,
        {
          fileType: googleFormattedFile.type,
          fileName: googleFormattedFile.name,
          storage: STORAGE.GOOGLE_DRIVE,
          page,
          component,
        },
        {},
        analytics.STATE.FAILED
      );
    });
  }
  return validFilesByFormat;
};

function UploadFromGoogleDrive({
  page,
  teamId,
  component,
  handleUploadFiles,
  enableMultiSelect,
  handleUpgradeAction,
  areFilePickerPluginsEnabled,
  isDisabled,
}) {
  const { t } = useTranslation();
  const { loaded, showFilePicker } = useGoogleDrive();

  const googleDrivePickerCallback = useCallback(
    async (files) => {
      const validFilesByFormat = validateGoogleNativeFormats(
        files,
        page,
        component
      );
      await handleUploadFiles(validFilesByFormat, teamId, STORAGE.GOOGLE_DRIVE);
    },
    [page, component, teamId, handleUploadFiles]
  );

  const handleGoogleDriveClick = async () => {
    if (areFilePickerPluginsEnabled) {
      analytics.track(
        analytics.ACTION.CLICKED,
        analytics.CATEGORY.GOOGLE_DRIVE_BUTTON
      );
      await showFilePicker(googleDrivePickerCallback, enableMultiSelect);
    } else {
      analytics.track(
        analytics.ACTION.REACHED,
        analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        {},
        {},
        analytics.STATE.GOOGLE_DRIVE_FEATURE
      );
    }
  };

  return (
    <RemoteButton
      testId="upload-panel-from-google"
      onClick={handleGoogleDriveClick}
      viewBox="0 0 1443.061 1249.993"
      handleUpgradeAction={handleUpgradeAction}
      isFeatureEnabled={areFilePickerPluginsEnabled}
      icon={GoogleDrive}
      label={t("FILE.UPLOAD_PANEL.GOOGLE_DRIVE")}
      disabled={!loaded || isDisabled}
    />
  );
}

UploadFromGoogleDrive.propTypes = {
  page: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  handleUploadFiles: PropTypes.func.isRequired,
  enableMultiSelect: PropTypes.bool.isRequired,
  handleUpgradeAction: PropTypes.func.isRequired,
  areFilePickerPluginsEnabled: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

UploadFromGoogleDrive.defaultProps = {
  isDisabled: false,
};

export default UploadFromGoogleDrive;
