import PropTypes from "prop-types";
import Highlighter from "react-highlight-words";
import { IoArchiveOutline } from "react-icons/io5";

import { SvgIcon } from "@mui/material";
import compact from "lodash/compact";
import escapeRegExp from "lodash/escapeRegExp";

import { Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import { usePresenterStyles } from "./styles";

export const Presenter = ({ isMobile, searchQuery, searchResult }) => {
  const presenterStyles = usePresenterStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      ml={isMobile && 0.5}
      gap={0.5}
    >
      <Box display="flex" gap={1} ml={1.8}>
        <Highlighter
          className={presenterStyles.SearchResultText}
          highlightClassName={presenterStyles.HighlightText}
          searchWords={compact([
            escapeRegExp(searchQuery.trim()),
            ...compact(
              escapeRegExp(searchQuery.trim()).split(/[^a-zA-Z\d\s:]/)
            ),
          ])}
          textToHighlight={searchResult.searchResultText}
        />
        {searchResult.isArchived && (
          <SvgIcon
            component={IoArchiveOutline}
            inheritViewBox
            fontSize="small"
            sx={{ color: "grey.400", width: 18, height: 18 }}
          />
        )}
      </Box>
      <Box
        component="span"
        color="text.secondary"
        fontSize="textXs.fontSize"
        lineHeight={FSTGTypography.lineHeight_1_3}
        ml={1.8}
      >
        {searchResult.searchPath}
      </Box>
    </Box>
  );
};

Presenter.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  searchQuery: PropTypes.string,
  searchResult: PropTypes.object.isRequired,
};

Presenter.defaultProps = {
  searchQuery: "",
};
