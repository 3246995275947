import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DoneIcon from "@mui/icons-material/Done";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Box, TableHead, TableCell, TableRow } from "@mui/material";
import { TEAM_PERMISSIONS } from "@supporting/constants/team";

import { Text, InfoToolTip } from "@shared/UIKit";

const TRANSLATIONS = {
  [TEAM_PERMISSIONS.SEE_ALL_PROJECTS]: {
    descriptions: ["TEAM.ROLES.PERMISSION_TOOLTIP.SEE_ALL_PROJECTS"],
  },
  [TEAM_PERMISSIONS.MANAGE_PROJECTS]: {
    descriptions: [
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_PROJECTS-1",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_PROJECTS-5",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_PROJECTS-2",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_PROJECTS-3",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_PROJECTS-6",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_PROJECTS-4",
    ],
  },
  [TEAM_PERMISSIONS.UPLOAD_FILES]: {
    descriptions: ["TEAM.ROLES.PERMISSION_TOOLTIP.UPLOAD_FILES"],
  },
  [TEAM_PERMISSIONS.MANAGE_TEAM]: {
    descriptions: [
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_TEAM-1",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_TEAM-2",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_TEAM-3",
    ],
  },
  [TEAM_PERMISSIONS.MANAGE_BILLING]: {
    descriptions: [
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_BILLING-2",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_BILLING-1",
    ],
  },
  [TEAM_PERMISSIONS.MANAGE_BRANDING]: {
    descriptions: ["TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_BRANDING"],
  },
  [TEAM_PERMISSIONS.MANAGE_TEMPLATES]: {
    descriptions: [
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_TEMPLATES-1",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_TEMPLATES-2",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_TEMPLATES-3",
    ],
  },
  [TEAM_PERMISSIONS.USE_APPS]: {
    descriptions: [
      "TEAM.ROLES.PERMISSION_TOOLTIP.USE_APPS-1",
      "TEAM.ROLES.PERMISSION_TOOLTIP.USE_APPS-2",
      "TEAM.ROLES.PERMISSION_TOOLTIP.USE_APPS-3",
    ],
  },
  [TEAM_PERMISSIONS.MANAGE_AUTOMATIONS]: {
    descriptions: [
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_AUTOMATIONS-1",
      "TEAM.ROLES.PERMISSION_TOOLTIP.MANAGE_AUTOMATIONS-2",
    ],
  },
  [TEAM_PERMISSIONS.VIEW_INSIGHTS]: {
    descriptions: ["TEAM.ROLES.PERMISSION_TOOLTIP.VIEW_INSIGHTS"],
  },
};

const mapTranslationKeys = (permissionName) =>
  Object.keys(TEAM_PERMISSIONS).find(
    (key) => TEAM_PERMISSIONS[key] === permissionName
  );

function TeamRolesTableHead({ permissions }) {
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {permissions.map((permissionName) => (
          <InfoToolTip
            placement="top"
            title={
              <Box p={1}>
                {TRANSLATIONS[permissionName].descriptions.map(
                  (description) => (
                    <Box key={description} display="flex" mb={0.5}>
                      {permissionName !== TEAM_PERMISSIONS.SEE_ALL_PROJECTS && (
                        <DoneIcon color="success" />
                      )}

                      <Text
                        variant={
                          permissionName === TEAM_PERMISSIONS.SEE_ALL_PROJECTS
                            ? "subtitle1"
                            : "body2"
                        }
                        textAlign={
                          permissionName === TEAM_PERMISSIONS.SEE_ALL_PROJECTS
                            ? "center"
                            : "left"
                        }
                      >
                        {t(description)}
                      </Text>
                    </Box>
                  )
                )}
              </Box>
            }
            key={permissionName}
          >
            <TableCell
              variant="body"
              size="small"
              sx={{
                borderLeft: "1px solid var(--mui-palette-grey-100)",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                data-testid="team-roles-table-head"
                sx={{ fontSize: "textSm.fontSize" }}
              >
                {t(
                  `TEAM.ROLES.PERMISSIONS.${mapTranslationKeys(permissionName)}`
                )}
                <InfoOutlined sx={{ color: "grey.300" }} fontSize="small" />
              </Box>
            </TableCell>
          </InfoToolTip>
        ))}
      </TableRow>
    </TableHead>
  );
}

TeamRolesTableHead.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TeamRolesTableHead;
