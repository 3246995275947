import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import { Card, CardContent, CardHeader, CardActions } from "@mui/material";
import toastService from "@supporting/services/toast";

import { Text, ConfirmationDialog } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

function DeleteUserAccount() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();

  const openDialog = useCallback(
    (event) => {
      event.persist();
      setIsOpen(true);
    },
    [setIsOpen]
  );

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const deleteUser = async (forceDelete) => {
    await backend.delete(`/users/me`, { forceDelete });
    navigate(APP_ROUTES.LOGOUT.path);
  };

  const deleteUserAccount = useCallback(async () => {
    try {
      setIsDeleting(true);
      await deleteUser();
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
      if (error.message) {
        let message;
        if (error.message === "team requires at least one admin") {
          message = "NON_EMPTY_TEAM";
        }

        if (
          error.message === "cannot delete teams that are on paid subscription"
        ) {
          message = "ACTIVE_SUBSCRIPTION";
        }

        if (message) {
          return toastService.sendToast({
            title: `SETTINGS.ACCOUNT.DELETE_ACCOUNT.FAILURE.${message}.TITLE`,
            body: `SETTINGS.ACCOUNT.DELETE_ACCOUNT.FAILURE.${message}.BODY`,
            preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
          });
        }
      }
      return errorHandlerService.handleError(error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Card data-testid="user-settings-account-tab-delete-account">
        <CardHeader title={t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.TITLE")} />
        <CardContent sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Text variant="textMd" color="text.secondary" component="p">
            {t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.DESCRIPTION-1")}
          </Text>
          <Text variant="textMd" color="text.secondary" component="p">
            {t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.DESCRIPTION-2")}
          </Text>
          <Text variant="textMd" color="text.secondary" component="p">
            {t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.DESCRIPTION-3")}
          </Text>
        </CardContent>
        <CardActions>
          <LoadingButton
            loading={isDeleting}
            disabled={isDeleting}
            data-testid="delete-user-account"
            color="error"
            size="large"
            aria-label={t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.BUTTON")}
            onClick={openDialog}
            sx={{ marginLeft: "auto" }}
          >
            {t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.BUTTON")}
          </LoadingButton>
        </CardActions>
      </Card>

      <ConfirmationDialog
        isOpen={isOpen}
        title={t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.DIALOG.HEADLINE")}
        description={t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.DIALOG.DESCRIPTION")}
        checkMessage={t(
          "SETTINGS.ACCOUNT.DELETE_ACCOUNT.DIALOG.CHECKBOX_CONFIRMATION"
        )}
        confirmLabel={t("SETTINGS.ACCOUNT.DELETE_ACCOUNT.DIALOG.CONFIRM")}
        answer={deleteUserAccount}
        closeDialog={closeDialog}
        data-testid="user-settings-account-tab-delete-account-dialog"
      />
    </>
  );
}

export default DeleteUserAccount;
