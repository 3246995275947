import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

const ReviewDecisionTooltip = ({
  title,
  body,
  children,
  showVerifiedApprovalTitle,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        <Box height="auto">
          {showVerifiedApprovalTitle && (
            <Text pb={0.25} variant="textSm" fontWeight="fontWeight.bold">
              {t("VERIFIED_APPROVAL.CTA_TOOLTIP")}
            </Text>
          )}

          <Text variant="textSm" fontWeight="fontWeight.bold">
            {t(title)}
          </Text>
          <Text pt={0.5} variant="textXs" color="grey.500">
            {t(body)}
          </Text>
        </Box>
      }
      placement="left"
      arrow
      disableInteractive
      enterDelay={500}
      enterNextDelay={500}
      componentsProps={{
        tooltip: {
          sx: {
            padding: 1.5,
            color: "grey.700",
            backgroundColor: "white",
            boxShadow:
              "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
          },
        },
        arrow: { sx: { color: "white" } },
      }}
    >
      <Box component="span">{children}</Box>
    </Tooltip>
  );
};

ReviewDecisionTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  showVerifiedApprovalTitle: PropTypes.bool,
};

ReviewDecisionTooltip.defaultProps = {
  showVerifiedApprovalTitle: false,
};

export default ReviewDecisionTooltip;
