import { useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";

/**
 * @description UIRouter $state.go options, we currently support only few of
 * them to reduce complexity and migrate to react router easily.
 * @typedef {Object} UIRouterStateOptions
 * @property {boolean} [notify] - broadcast UIRouter State Change events
 * @property {boolean} [reload] - reload current state and it's views
 */

/**
 * @description UIRouter $state.href options
 * @typedef {Object} UIRouterStateHrefOptions
 * @property {boolean} [absolute] - whether url absolute or relative
 */

/**
 * @typedef {Object<string, string|number>} RouteParams
 */

/**
 * @typedef {{[params]: RouteParams, [options]: UIRouterStateOptions }} RouteOptions
 */

/**
 * @typedef {{[params]: RouteParams, [options]: UIRouterStateHrefOptions}} RouteUrlOptions
 */

/**
 * @typedef {string} RouteName
 */

/**
 * @typedef {string} Url
 */

/**
 * @callback goTo
 * @param {RouteName} routeName
 * @param {RouteOptions} [routeOptions={}]
 * @returns Promise<void>
 */

/**
 * @callback getUrl
 * @param {RouteName} routeName
 * @param {RouteUrlOptions} [routeUrlOptions={}]
 * @returns Url
 */

/**
 * @callback getParams
 * @returns RouteParams
 */

/**
 * @description It returns route name of active state
 * @callback getRouteName
 * @returns RouteName
 */

/**
 * @callback isOneOf
 * @param {...RouteName} routeNames
 * @returns boolean
 */

/**
 * @typedef {Object} Navigation
 * @property {Object<string, RouteName>} routeNames
 * @property {goTo} goTo
 * @property {getParams} getParams
 * @property {getRouteName} getRouteName
 * @property {getUrl} getUrl
 * @property {isOneOf} isOneOf
 */

/**
 * @return {Navigation}
 */
export default function useNavigation() {
  const navigate = useNavigate();

  const buildUrl = useCallback((routeName, options = {}) => {
    const pathname = generatePath(APP_ROUTES[routeName].path, options.params);
    return options.search ? { pathname, search: options.search } : pathname;
  }, []);

  const goTo = useCallback(
    (routeName, routeOptions = {}) => {
      navigate(buildUrl(routeName, routeOptions), routeOptions.options);
    },
    [buildUrl, navigate]
  );

  return {
    routeNames: ROUTE_STATES,
    goTo,
    buildUrl,
  };
}
