import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { CardHeader } from "@mui/material";

import GenerateAPIKey from "./GenerateAPIKey";
import RegenerateAPIKey from "./RegenerateAPIKey";

export default function APIHeader({ hasApiKeys, fetchAPIKeys }) {
  const { t } = useTranslation();

  const action = hasApiKeys ? (
    <RegenerateAPIKey fetchAPIKeys={fetchAPIKeys} />
  ) : (
    <GenerateAPIKey onDialogClose={fetchAPIKeys} />
  );

  return (
    <CardHeader
      action={action}
      data-testid="api-header"
      title={t("INTEGRATIONS.API.CONNECTION.TITLE")}
      sx={{ "& .MuiCardHeader-action": { alignSelf: "center" } }}
    />
  );
}

APIHeader.propTypes = {
  hasApiKeys: PropTypes.bool.isRequired,
  fetchAPIKeys: PropTypes.func.isRequired,
};
