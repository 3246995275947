export default [
  {
    category: "BOTS.CATEGORY.VISUAL_MODERATION",
    list: [
      {
        title: "BOTS.BRAND_CONSISTENCY.TITLE",
        description: "BOTS.BRAND_CONSISTENCY.DESCRIPTION",
        eventMeta: "brand-consistency",
      },
      {
        title: "BOTS.EXPLICIT_CONTENT.TITLE",
        description: "BOTS.EXPLICIT_CONTENT.DESCRIPTION",
        eventMeta: "explicit-content",
      },
      {
        title: "BOTS.VISUAL_PLAGIARISM.TITLE",
        description: "BOTS.VISUAL_PLAGIARISM.DESCRIPTION",
        eventMeta: "visual-plagiarism",
      },
    ],
  },
  {
    category: "BOTS.CATEGORY.COPY_OPTIMIZATION",
    list: [
      {
        title: "BOTS.MESSAGE_TEST.TITLE",
        description: "BOTS.MESSAGE_TEST.DESCRIPTION",
        eventMeta: "message-tester",
      },
      {
        title: "BOTS.TONE_VOICE.TITLE",
        description: "BOTS.TONE_VOICE.DESCRIPTION",
        eventMeta: "tone-of-voice-consistency",
      },
      {
        title: "BOTS.COPY_PLAGIARISM.TITLE",
        description: "BOTS.COPY_PLAGIARISM.DESCRIPTION",
        eventMeta: "copy-plagiarism",
      },
      {
        title: "BOTS.COPY_COMPLAINCE.TITLE",
        description: "BOTS.COPY_COMPLAINCE.DESCRIPTION",
        eventMeta: "copy-compliance",
      },
    ],
  },
  {
    category: "BOTS.CATEGORY.INSIGHTS",
    list: [
      {
        title: "BOTS.METADATA.TITLE",
        description: "BOTS.METADATA.DESCRIPTION",
        eventMeta: "metadata-analysis",
      },
      {
        title: "BOTS.PREDICTIVE.TITLE",
        description: "BOTS.PREDICTIVE.DESCRIPTION",
        eventMeta: "mimic-review-history",
      },
    ],
  },
];
