import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { List, ListSubheader } from "@mui/material";

import { Box } from "@shared/UIKit";

import TooltipListItem from "../TooltipListItem/TooltipListItem";

export default function PasswordTooltipContent({
  error,
  isMinimum,
  isOneUpperCase,
  isOneLowerCase,
  isOneNumberOrSpecial,
}) {
  const { t } = useTranslation();
  return (
    <Box>
      <List aria-label="password tooltip" disablePadding dense>
        <ListSubheader sx={{ fontSize: "textXs.fontSize", color: "grey.500" }}>
          {t("PASSWORD_FIELD_TOOLTIP.HEADING")}
        </ListSubheader>
        <TooltipListItem
          check={isMinimum}
          error={error && !isMinimum}
          translate="PASSWORD_FIELD_TOOLTIP.MINIMUM_CHARACTERS"
        />
        <TooltipListItem
          check={isOneLowerCase}
          error={error && !isOneLowerCase}
          translate="PASSWORD_FIELD_TOOLTIP.HAS_ONE_LOWER_CASE"
        />
        <TooltipListItem
          check={isOneUpperCase}
          error={error && !isOneUpperCase}
          translate="PASSWORD_FIELD_TOOLTIP.HAS_ONE_UPPER_CASE"
        />
        <TooltipListItem
          check={isOneNumberOrSpecial}
          error={error && !isOneNumberOrSpecial}
          translate="PASSWORD_FIELD_TOOLTIP.HAS_ONE_SPECIAL_OR_NUMBER"
        />
      </List>
    </Box>
  );
}
PasswordTooltipContent.propTypes = {
  error: PropTypes.bool.isRequired,
  isMinimum: PropTypes.bool.isRequired,
  isOneUpperCase: PropTypes.bool.isRequired,
  isOneLowerCase: PropTypes.bool.isRequired,
  isOneNumberOrSpecial: PropTypes.bool.isRequired,
};
