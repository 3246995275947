import { withTheme } from "@mui/styles";

import { Box } from "@shared/UIKit";

const HeaderSeparator = withTheme(({ theme, ...rest }) => (
  <Box
    height={20}
    width="1px"
    bgcolor={theme.color.gray[300]}
    marginY={0}
    marginX={1.25}
    {...rest}
  />
));

export default HeaderSeparator;
