import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import RefreshIcon from "@mui/icons-material/Refresh";
import { Button } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { useDialogState } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";

import DisplayAPIKeyDialog from "../DisplayAPIKeyDialog/DisplayAPIKeyDialog";
import RegenerateAPIKeyDialog from "../RegenerateAPIKeyDialog/RegenerateAPIKeyDialog";

export default function RegenerateAPIKey({ fetchAPIKeys }) {
  const { t } = useTranslation();
  const selectedTeam = useSelectedTeam();
  const { isOpen, openDialog, closeDialog } = useDialogState();
  const [regeneratedAPIKey, setRegeneratedAPIKey] = useState("");

  const handleOpenDialog = useCallback(() => {
    openDialog();
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.INTEGRATIONS.REGENERATE_API_KEY
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleCloseDisplayDialog = useCallback(() => {
    setRegeneratedAPIKey("");
  }, []);

  const regenerateAPIKey = useCallback(async () => {
    try {
      const teamId = selectedTeam._id;
      const { token } = await backend.put("/api-token", { teamId });
      closeDialog();
      setRegeneratedAPIKey(token);
      await fetchAPIKeys();
      analytics.track(
        analytics.ACTION.REGENERATED,
        analytics.CATEGORY.INTEGRATIONS.API_KEY,
        {
          teamId,
          token: `****${token.substring(token.length - 6)}`,
        }
      );
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [selectedTeam, fetchAPIKeys]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Button
        size="large"
        color="primary"
        variant="contained"
        onClick={handleOpenDialog}
        startIcon={<RefreshIcon />}
        data-testid="regenerate-api-key"
      >
        {t("INTEGRATIONS.API.CONNECTION.REGENERATE_KEY_BUTTON")}
      </Button>

      {isOpen && (
        <RegenerateAPIKeyDialog
          isOpen
          closeDialog={closeDialog}
          regenerateAPIKey={regenerateAPIKey}
        />
      )}

      {regeneratedAPIKey && (
        <DisplayAPIKeyDialog
          isOpen
          generatedAPIKey={regeneratedAPIKey}
          closeDialog={handleCloseDisplayDialog}
        />
      )}
    </>
  );
}

RegenerateAPIKey.propTypes = {
  fetchAPIKeys: PropTypes.func.isRequired,
};
