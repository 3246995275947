/* istanbul ignore file: jest-dom doesn't support testing scroll events */
/**
 * Scroll Into View helper function
 *
 * @description This function is used for scrolling into
 * a specific element with options.
 * @param {DOMElement} element - [Required] Dom element to scroll into view.
 * @param {Object} options - [Required] Options for scrolling into view.
 */
const scrollIntoView = (element, options) => {
  element.scrollIntoView(options);
};

export default scrollIntoView;
