import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { DeleteOutline } from "@mui/icons-material";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import toastService from "@supporting/services/toast";
import {
  AUTOMATION_CHECK_TYPE,
  checkIsUsedInAutomation,
} from "@workflow/helpers/checkIsUsedInAutomation";
import projectTemplateService from "@workflow/services/projectTemplateService";
import sectionService from "@workflow/services/sectionService";

import { projectProps, sectionProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";

function SectionHeaderDeleteMenuItem({
  project,
  section,
  closeMenu,
  isTemplate,
}) {
  const { t } = useTranslation();

  const deleteSection = async () => {
    closeMenu();
    if (
      checkIsUsedInAutomation(
        project.automations,
        section.id,
        AUTOMATION_CHECK_TYPE.SECTION
      )
    ) {
      toastService.sendToast({
        title: "DELETE_SECTION.AUTOMATION.TOAST.TITLE",
        body: "DELETE_SECTION.AUTOMATION.TOAST.BODY",
        preset: toastService.PRESETS().ERROR_DELAYED,
      });
      return;
    }
    if (section.files?.length > 0) {
      toastService.sendToast({
        title: "PROJECT.SECTION.DELETE_TOAST.TITLE",
        body: "PROJECT.SECTION.DELETE_TOAST.BODY",
        preset: toastService.PRESETS().ERROR,
      });
    } else {
      try {
        if (isTemplate) {
          await projectTemplateService.removeProjectTemplateSection(
            project.id,
            section.id
          );
        } else {
          await sectionService.removeProjectSection(project.id, section.id);
        }
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    }
  };

  return (
    <MenuItem
      onClick={deleteSection}
      data-testid="section-header-menu-item-delete"
      data-accent-color="error"
    >
      <ListItemIcon>
        <DeleteOutline />
      </ListItemIcon>
      <ListItemText>{t("PROJECT.SECTION.MENU.DELETE")}</ListItemText>
    </MenuItem>
  );
}

SectionHeaderDeleteMenuItem.defaultProps = {
  isTemplate: false,
};

SectionHeaderDeleteMenuItem.propTypes = {
  closeMenu: PropTypes.func.isRequired,
  section: sectionProps.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  isTemplate: PropTypes.bool,
};

export default SectionHeaderDeleteMenuItem;
