/* eslint react/forbid-elements:0 */
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Button, Card, CardHeader, CardContent, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { Avatar, Text, Box } from "@shared/UIKit";

import { UPLOAD_AVATAR_SIZE } from "@shared/constants/upload";
import toBytes from "@shared/helpers/converter";

import CropAvatar from "../../CropAvatar/CropAvatar";

const useStyles = makeStyles({
  avatar: {
    width: 90,
    height: 90,
    marginRight: 25,
  },
});

const EditAvatar = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = useState({
    user: authenticationService.fetchUser(),
    isAvatarSaved: false,
    openCropDialog: false,
    file: null,
    fileName: "",
  });
  const setOpenCropDialog = useCallback(
    (state, success = false) => {
      setState((prevState) => ({
        ...prevState,
        openCropDialog: state,
        user: authenticationService.fetchUser(),
        isAvatarSaved: success,
      }));
    },
    [state] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const readFile = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.readAsDataURL(file);
    });
  };

  const uploadFile = async (event) => {
    const fileToUpload = event.target.files[0];

    const fileSize = fileToUpload.size;

    const maxSize = toBytes(
      UPLOAD_AVATAR_SIZE.FILESIZE_MAX,
      UPLOAD_AVATAR_SIZE.FILESIZE_UNIT
    );

    if (fileSize > maxSize) {
      toastService.sendToast({
        title: "SETTINGS.AVATAR.UPLOAD.EXCEEDS_SIZE.TITLE",
        body: "SETTINGS.AVATAR.UPLOAD.EXCEEDS_SIZE.BODY",
        preset: toastService.PRESETS().WARNING,
        translationVariables: {
          body: {
            size: UPLOAD_AVATAR_SIZE.FILESIZE_MAX,
            unit: UPLOAD_AVATAR_SIZE.FILESIZE_UNIT,
          },
        },
      });
      event.target.value = null;
      return;
    }

    const imageDataUrl = await readFile(fileToUpload);

    setState((prevState) => ({
      ...prevState,
      file: imageDataUrl,
      fileName: fileToUpload.name,
      openCropDialog: true,
    }));
  };

  return (
    <>
      {state.openCropDialog && (
        <CropAvatar
          setIsOpenDialog={setOpenCropDialog}
          image={state.file}
          imageName={state.fileName}
        />
      )}
      <Card>
        <CardHeader title={t("SETTINGS.AVATAR.TITLE")} />
        <CardContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Avatar user={state.user} className={classes.avatar} />
            <Box position="relative">
              <Input
                accept="image/*"
                id="avatar-upload"
                name="avatar-upload"
                type="file"
                onChange={uploadFile}
                inputProps={{
                  "data-testid": "avatar-upload-input",
                }}
                sx={{ display: "none" }}
              />
              <label htmlFor="avatar-upload">
                <Button
                  startIcon={<FileUploadIcon />}
                  color="primary"
                  variant="contained"
                  size="large"
                  component="span"
                >
                  {t("SETTINGS.AVATAR.UPDATE")}
                </Button>
              </label>

              {state.isAvatarSaved && (
                <Box
                  data-testid="avatar-saved-icon"
                  display="flex"
                  alignItems="center"
                  position="absolute"
                  top={50}
                  left={20}
                  bgcolor="success.light"
                  color="success.main"
                  px={1.25}
                  py={0.625}
                  borderRadius="15px"
                >
                  <CheckIcon fontSize="small" />
                  <Text variant="subtitle1" fontWeight="fontWeight.medium">
                    {t("SETTINGS.AVATAR.SAVED")}
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default EditAvatar;
