import { useState, useEffect } from "react";

function getStatusByType(type) {
  return type === "load" ? "ready" : "error";
}

/**
 * Use Script Hook
 *
 * @description This hook is used for injecting 3rd party external scripts.
 * It keeps track when script is loaded.
 *
 * @param {string} src - [Required] source url of script to be loaded.
 *
 * @returns {("loading" | "idle" | "ready" | "error")} Returns the current status
 */
function useScript(src) {
  const [status, setStatus] = useState(src ? "loading" : "idle");

  useEffect(() => {
    if (!src) {
      setStatus("idle");
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`);
    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");
      document.body.appendChild(script);

      const setAttributeFromEvent = (event) => {
        script.setAttribute("data-status", getStatusByType(event.type));
      };
      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute("data-status"));
    }

    const setStateFromEvent = (event) => {
      setStatus(getStatusByType(event.type));
    };
    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    return () => {
      script?.removeEventListener("load", setStateFromEvent);
      script?.removeEventListener("error", setStateFromEvent);
      script?.remove();
    };
  }, [src]);

  return status;
}

export default useScript;
