import PropTypes from "prop-types";

import { Box } from "@mui/material";

function AutomationsHeader({ startContent, endContent }) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      pt={1.25}
      pb={1}
      pr={5}
      data-testid="automations-header-root"
    >
      {startContent && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          ml={3}
          flex={1}
        >
          {startContent}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        mr={1}
        flex={1}
      >
        {endContent}
      </Box>
    </Box>
  );
}

AutomationsHeader.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  startContent: PropTypes.node,
  endContent: PropTypes.node,
};

AutomationsHeader.defaultProps = {
  startContent: null,
  endContent: null,
};

export default AutomationsHeader;
