import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardHeader, CardContent } from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import userService from "@supporting/services/userService";

import { HintBox } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import NotificationsTabPreferences from "./NotificationsTabPreferences/NotificationsTabPreferences";

function NotificationsTab() {
  const { t } = useTranslation();

  const [user, setUser] = useState(authenticationService.fetchUser());
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    async function updateUserSettings() {
      try {
        await userService.update({ settings: user.settings });
        toastService.sendToast({
          title: "SETTINGS.PROFILE.UPDATE.SUCCESS.TITLE",
          body: "SETTINGS.PROFILE.UPDATE.SUCCESS.BODY",
          preset: toastService.PRESETS().SUCCESS,
        });
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    }

    if (firstRender) {
      setFirstRender(false);
    } else {
      updateUserSettings();
    }
  }, [user, userService, toastService, errorHandlerService]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateEmailPreferences = useCallback(
    (event) => {
      const { id, name, checked, value } = event.target;

      const propertyName = id || name;
      const propertyValue = name ? value : checked;

      const currentUser = { ...user };
      currentUser.settings.emailPreferences[propertyName] = propertyValue;
      setUser(currentUser);

      analyticsTrack(
        propertyName,
        currentUser.settings.emailPreferences[propertyName]
      );

      function analyticsTrack(property, value) {
        const categories = {
          isProjectActivityEnabled:
            analytics.CATEGORY.EMAIL_PREFERENCES.PROJECT_ACTIVITY,
          isMentionsEnabled: analytics.CATEGORY.EMAIL_PREFERENCES.MENTION,
          isDueDateReminderEnabled:
            analytics.CATEGORY.EMAIL_PREFERENCES.DUE_DATE,
          projectActivityTimePeriod:
            analytics.CATEGORY.EMAIL_PREFERENCES.PROJECT_ACTIVITY_TIME_PERIOD,
          isReviewSubmissionEnabled:
            analytics.CATEGORY.EMAIL_PREFERENCES.SUBMITTED_REVIEWS,
        };

        let action = value
          ? analytics.ACTION.ENABLED
          : analytics.ACTION.DISABLED;

        if (Number.isInteger(value)) {
          action = analytics.ACTION.CHANGED;
        }

        analytics.track(
          action,
          categories[property],
          Number.isInteger(value) ? { TIME_PERIOD: value } : {}
        );
      }
    },
    [user] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Card data-testid="user-settings-notifications-tab">
      <CardHeader title={t("SETTINGS.NOTIFICATIONS.TITLE")} />
      <CardContent>
        <NotificationsTabPreferences
          type="PROJECT_ACTIVITY"
          id="isProjectActivityEnabled"
          changeEmailPreferences={updateEmailPreferences}
          preferencesValue={
            user.settings.emailPreferences.isProjectActivityEnabled
          }
          projectActivityTimePeriod={
            user.settings.emailPreferences.projectActivityTimePeriod
          }
        />

        <NotificationsTabPreferences
          type="MENTIONS"
          id="isMentionsEnabled"
          changeEmailPreferences={updateEmailPreferences}
          preferencesValue={user.settings.emailPreferences.isMentionsEnabled}
        />

        <NotificationsTabPreferences
          type="REVIEW_SUBMISSION"
          id="isReviewSubmissionEnabled"
          changeEmailPreferences={updateEmailPreferences}
          preferencesValue={
            user.settings.emailPreferences.isReviewSubmissionEnabled
          }
        />

        <NotificationsTabPreferences
          type="DUE_DATE_REMINDER"
          id="isDueDateReminderEnabled"
          changeEmailPreferences={updateEmailPreferences}
          preferencesValue={
            user.settings.emailPreferences.isDueDateReminderEnabled
          }
        />

        <HintBox>{t("SETTINGS.NOTIFICATIONS.HINT")}</HintBox>
      </CardContent>
    </Card>
  );
}

export default NotificationsTab;
