import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import { Button, SvgIcon } from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import UpgradeDialogContent from "@supporting/components/UpgradePlanDialog/UpgradeDialogContent";
import UpgradePlanDialog from "@supporting/components/UpgradePlanDialog/UpgradePlanDialog";
import useBillingLimits from "@supporting/hooks/useBillingLimits";

import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";

function AddSectionButton({ setIsEditing, project, team, isTemplate }) {
  const { t } = useTranslation();
  const [isUpgradePlanDialogOpen, setIsUpgradePlanDialogOpen] = useState(false);
  const subscriptionLimits = useBillingLimits(project.teamId);
  const isTeamAdmin = isTemplate
    ? team.permissions.canManageTemplates
    : team.permissions.canManageBilling;
  const isExceededTheLimits =
    subscriptionLimits.maxProjectSectionNumber !== -1 &&
    project.sections.length >= subscriptionLimits.maxProjectSectionNumber;

  const addButtonAction = useCallback(
    (event) => {
      event.stopPropagation();
      if (isExceededTheLimits) {
        setIsUpgradePlanDialogOpen(true);
        analytics.track(
          analytics.ACTION.REACHED,
          analytics.CATEGORY.SUBSCRIPTION_LIMIT,
          {},
          {},
          analytics.STATE.SECTION_QUANTITY
        );
      } else {
        setIsEditing(true);
      }
    },
    [isExceededTheLimits] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onCancel = () => setIsUpgradePlanDialogOpen(false);
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { hideFree: true, teamId: team._id });

  return (
    <>
      <Button
        variant="text"
        color="primaryV2"
        size="small"
        onClick={addButtonAction}
        className="tour__add-section"
        data-testid="add-new-section"
        startIcon={<AddCircleOutline />}
        endIcon={
          isExceededTheLimits ? (
            <SvgIcon
              component={PremiumIcon}
              color="upgrade"
              data-testid="add-new-section-upgrade"
            />
          ) : null
        }
      >
        {t("PROJECT.SECTION.CREATE")}
      </Button>

      {isUpgradePlanDialogOpen && (
        <UpgradePlanDialog
          dialogTitle="UPGRADE.SECTIONS.TITLE"
          isUpgradePlanDialogOpen={isUpgradePlanDialogOpen}
          onCancel={onCancel}
          maxWidth="xs"
        >
          <UpgradeDialogContent
            isAdminView={isTeamAdmin}
            variant="addSection"
            onCancel={onCancel}
            teamId={team._id}
            openBillingDialog={openDialog}
          />
        </UpgradePlanDialog>
      )}
    </>
  );
}

AddSectionButton.defaultProps = {
  isTemplate: false,
};

AddSectionButton.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  team: teamProp.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool,
};

export default AddSectionButton;
