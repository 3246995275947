import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CloseOutlined from "@mui/icons-material/CloseOutlined";
import DoneIcon from "@mui/icons-material/Done";
import { TableCell, TableRow } from "@mui/material";

import { Text } from "@shared/UIKit";

import { roleProp, teamProp } from "@shared/props/team";

import TeamRoleActions from "./TeamRoleActions/TeamRoleActions";

const processPermissionIcon = (permissionName) =>
  permissionName ? (
    <DoneIcon color="success" />
  ) : (
    <CloseOutlined color="error" />
  );

function TeamRolesTableRow({ permissions, teamRoles, teamRole, team }) {
  const { t } = useTranslation();

  return (
    <TableRow key={teamRole._id} data-testid="team-role-table-row">
      <TableCell sx={{ minWidth: 125 }}>
        <>
          <Text fontWeight="fontWeight.medium" textAlign="left">
            {teamRole.isDefault
              ? t(`TEAM.ROLES.${teamRole.roleName}`)
              : teamRole.roleName}
          </Text>
          {teamRole.isDefault && (
            <Text variant="subtitle1" color="text.secondary" textAlign="left">
              {t(`TEAM.ROLES.ROLE_DESCRIPTION.${teamRole.roleName}`)}
            </Text>
          )}
          {team.subscriptionLimits.isCustomRolesEnabled &&
            team.permissions.canRename &&
            !teamRole.isDefault && (
              <TeamRoleActions
                teamRole={teamRole}
                teamRoles={teamRoles}
                team={team}
                permissions={permissions}
              />
            )}
        </>
      </TableCell>
      {permissions.map((permissionName) => (
        <TableCell
          key={permissionName}
          align="center"
          sx={{
            minWidth: 92,
            borderLeft: "1px solid var(--mui-palette-grey-100)",
          }}
        >
          {permissionName === "seeAllProjects" ? (
            <Text
              variant="subtitle1"
              textAlign="center"
              fontWeight="fontWeight.medium"
              color={
                teamRole.permissions[permissionName] ? "success.main" : "error"
              }
            >
              {teamRole.permissions[permissionName]
                ? t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ALL_PROJECTS")
                : t("TEAM.ROLES.ROLE_TOOLTIP.SEES_ONLY_PROJECTS")}
            </Text>
          ) : (
            processPermissionIcon(teamRole.permissions[permissionName])
          )}
        </TableCell>
      ))}
    </TableRow>
  );
}

TeamRolesTableRow.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamRoles: PropTypes.arrayOf(roleProp).isRequired,
  teamRole: roleProp.isRequired,
  team: teamProp.isRequired,
};

export default TeamRolesTableRow;
