import authenticationService from "@supporting/services/authentication";
import userService from "@supporting/services/userService";

import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

let isIndicatorEnabled = undefined;

const isSessionImpersonated = () => {
  const session = authenticationService.fetchSession();
  return Boolean(session?.type === "Impersonated");
};

const isEnabled = () => {
  const session = authenticationService.fetchSession();
  return Boolean(["Registered", "Impersonated"].includes(session?.type));
};

const updateIsReadByProjectId = (projectId, isRead) => {
  if (!isSessionImpersonated()) {
    backend.put("/in-app-notifications", { projectId, isRead });
  }
};

const updateIndicatorAndEmitEvent = (updateIsIndicatorEnabled) => {
  isIndicatorEnabled = updateIsIndicatorEnabled;
  if (isEnabled()) {
    eventService.emitEvent({
      eventName: isIndicatorEnabled
        ? EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.ENABLED
        : EVENT_NAMES.NOTIFICATION_CENTER.INDICATOR.DISABLED,
      eventData: {},
    });
  }
};

const updateIndicator = async () => {
  const { settings } = authenticationService.fetchUser();
  const { lastNotificationTime } = await backend.get(
    "/in-app-notifications/last-notification-time"
  );

  updateIndicatorAndEmitEvent(
    (settings.openedNotificationCenterOn || "1970-01-01") < lastNotificationTime
  );
};

const onNotificationsAdded = () => {
  updateIndicatorAndEmitEvent(true);
  eventService.emitEvent({
    eventName: EVENT_NAMES.NOTIFICATION_CENTER.NOTIFICATIONS.ADDED,
    eventData: {},
  });
};

const onIconClicked = () => {
  if (!isSessionImpersonated()) {
    updateIndicatorAndEmitEvent(false);
    userService.update({
      settings: {
        openedNotificationCenterOn: new Date(),
      },
    });
  }
};

const initialize = () => {
  clearCache();

  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);

  websocket.addListener(
    websocket.EVENT_NAMES.IN_APP_NOTIFICATION.NEW,
    onNotificationsAdded
  );
  websocket.addListener(
    websocket.EVENT_NAMES.IN_APP_NOTIFICATION.UPDATED,
    updateIndicator
  );
  websocket.addListener(
    websocket.EVENT_NAMES.IN_APP_NOTIFICATION.DELETED,
    updateIndicator
  );
  eventService.addListener(
    EVENT_NAMES.NOTIFICATION_CENTER.ICON.CLICKED,
    onIconClicked
  );
};

function clearCache() {
  isIndicatorEnabled = undefined;
}

export default {
  initialize,
  isEnabled,
  updateIsReadByProjectId,
  updateIndicator,
};
