import { Chip } from "@mui/material";

import { Avatar } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";

const AddReviewerDialogAutocompleteTag = ({ user, ...restProps }) => {
  const { inputValue } = user;

  return (
    <Chip
      data-testid="step-reviewer-button-element"
      avatar={
        <Avatar user={{ ...user, fullName: user.fullName || inputValue }} />
      }
      label={inputValue}
      {...restProps}
      sx={{
        "& .MuiChip-avatar": {
          bgcolor: "var(--mui-color-blue-400)",
          color: "white",
        },
      }}
    />
  );
};

AddReviewerDialogAutocompleteTag.propTypes = {
  user: userProp,
};

AddReviewerDialogAutocompleteTag.defaultProps = {
  user: null,
};

export default AddReviewerDialogAutocompleteTag;
