export default {
  COMMENT: {
    CREATED: "COMMENT_CREATED",
    REPLIED: "COMMENT_REPLIED",
    LIKED: "COMMENT_LIKED",
    MENTIONED: "COMMENT_MENTION_CREATED",
    TEAM_ONLY: "TEAM_ONLY_COMMENT_CREATED",
    TEAM_ONLY_COMMENT_MENTION: "TEAM_ONLY_COMMENT_MENTION_CREATED",
  },
  PROJECT: {
    COLLABORATOR_INVITED: "PROJECT_COLLABORATOR_INVITED",
  },
  STEP: {
    REVIEWER_INVITED: "STEP_REVIEWER_INVITED",
  },
  REVIEW: {
    REVIEW_STARTED: "REVIEW_STARTED",
    REVIEW_STATUS_IN_REVIEW: "REVIEW_STATUS_IN_REVIEW",
    REVIEW_STATUS_APPROVED: "REVIEW_STATUS_APPROVED",
    REVIEW_STATUS_NEEDS_CHANGES: "REVIEW_STATUS_NEEDS_CHANGES",
    REVIEW_DECISION_APPROVED: "REVIEW_DECISION_APPROVED",
    REVIEW_DECISION_REQUESTED_CHANGES: "REVIEW_DECISION_REQUESTED_CHANGES",
    REVIEW_DECISION_APPROVED_WITH_CHANGES:
      "REVIEW_DECISION_APPROVED_WITH_CHANGES",
    REVIEW_DECISION_REJECTED: "REVIEW_DECISION_REJECTED",
  },
};
