import { useTranslation } from "react-i18next";

import { MenuItem, TextField } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Box } from "@shared/UIKit";

import useProjectListMobile from "./hooks/useProjectListMobile";
import GroupHeaderMenuItem from "./ProjectListGroupHeader";
import ProjectListMobileCreateMenu from "./ProjectListMobileCreateMenu";

function ProjectListMobile() {
  const { t } = useTranslation();
  const {
    selectedTeam,
    folders,
    externalProjects,
    selectedOption,
    handleSelectedOptionChanged,
    getOptionValue,
    templates,
    favoriteProjects,
  } = useProjectListMobile();
  const currentTeam = useSelectedTeam();

  const currentTeamProjects = favoriteProjects.filter(
    (project) => project.teamId === currentTeam._id
  );

  const externalFavoriteProjects = favoriteProjects.filter(
    (project) => project.isExternal
  );

  const displayFavorites =
    currentTeamProjects.length + externalProjects.length > 0;

  return (
    <Box
      flex={1}
      boxSizing="border-box"
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      mb={0.5}
      bgcolor="grey.50"
      zIndex={3000}
      data-testid="project-list-mobile"
    >
      <ProjectListMobileCreateMenu team={selectedTeam} />

      <TextField
        select
        fullWidth
        id="project-list-mobile-select"
        label={t("PROJECT.PROJECT.OPEN_MOBILE")}
        value={selectedOption}
        onChange={handleSelectedOptionChanged}
      >
        {displayFavorites && [
          <GroupHeaderMenuItem
            key="favorite-project-item-group"
            data-testid="favorite-project-item-group"
            variant="favorite"
          >
            {t("MOBILE.FAVORITE_PROJECT")}
          </GroupHeaderMenuItem>,
          [...currentTeamProjects, ...externalFavoriteProjects].map(
            (project) => (
              <MenuItem
                key={project.id}
                value={getOptionValue("project", project.id)}
              >
                {project.name}
              </MenuItem>
            )
          ),
        ]}

        {selectedTeam.permissions.canManageTemplates &&
          templates.length > 0 && [
            <GroupHeaderMenuItem
              key="project-templates-item-group"
              variant="template"
            >
              {t("PROJECT_TEMPLATES.LIST.TITLE")}
            </GroupHeaderMenuItem>,
            ...templates.map((template) => (
              <MenuItem
                key={template.id}
                value={getOptionValue("template", template.id)}
              >
                {template.name}
              </MenuItem>
            )),
          ]}

        {folders.map((folder) => [
          <GroupHeaderMenuItem key={folder.id}>
            {folder.name}
          </GroupHeaderMenuItem>,
          ...folder.projects.map((project) => (
            <MenuItem
              key={project.id}
              value={getOptionValue("project", project.id)}
            >
              {project.name}
            </MenuItem>
          )),
        ])}

        {externalProjects.map((reviewerProjectGroup) => [
          <GroupHeaderMenuItem key={reviewerProjectGroup.teamId}>
            {`${t("CORE.BY")} ${reviewerProjectGroup.teamName}`}
          </GroupHeaderMenuItem>,
          ...reviewerProjectGroup.folders.map((folder) => [
            ...folder.projects.map((project) => (
              <MenuItem
                key={project.id}
                value={getOptionValue("project", project.id)}
              >
                {project.name}
              </MenuItem>
            )),
          ]),
        ])}
      </TextField>
    </Box>
  );
}

export default ProjectListMobile;
