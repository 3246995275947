import PropTypes from "prop-types";

import { Box, Text } from "@shared/UIKit";

const Features = ({ title, children }) => (
  <Box
    display="flex"
    flexDirection={{ xs: "column", sm: "row" }}
    justifyContent="space-between"
  >
    <Text fontWeight="fontWeight.medium" variant="textMd">
      {title}
    </Text>
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="flex-end"
      flexDirection={{ xs: "column", sm: "row" }}
      gap={1}
    >
      {children}
    </Box>
  </Box>
);
Features.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Features;
