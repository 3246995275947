import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import NewReleasesIcon from "@mui/icons-material/NewReleases";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, Box } from "@mui/material";

import { Text } from "@shared/UIKit";

import { instance as logger } from "@shared/services/logger";

function AppVersionUpdateBar() {
  const { t } = useTranslation();
  const [isVersionOutdated, setIsVersionOutdated] = useState(false);

  useEffect(() => {
    async function fetchRemoteVersion() {
      const response = await fetch("/version.json");
      const json = await response.json();
      return json.version;
    }

    const checkIfNewVersionAvailable = async function () {
      try {
        const remoteVersion = await fetchRemoteVersion();
        const localVersion = window.fs.config.appVersion;
        setIsVersionOutdated(localVersion !== remoteVersion);
      } catch (error) {
        logger.warn("AppVersionUpdateBar", "failed to get /version.json", {
          error,
        });
      }
    };

    const twelveHours = 12 * 60 * 60 * 1000;
    const interval = window.setInterval(
      checkIfNewVersionAvailable,
      twelveHours
    );

    return () => {
      window.clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reload = function () {
    window.location.reload(true);
  };

  return (
    isVersionOutdated && (
      <Box
        onClick={reload}
        bgcolor="upgrade.main"
        transition="background-color 0.1s ease-out"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3.125}
        py={1.25}
        data-testid="app-version-upgrade-bar"
      >
        <Box display="flex" gap={1}>
          <NewReleasesIcon htmlColor="white" />

          <Text color="white" variant="body2">
            <Trans>APP_VERSION_UPDATE.MESSAGE</Trans>
          </Text>
        </Box>
        <Button
          startIcon={<RefreshIcon />}
          onClick={reload}
          color="upgrade"
          variant="outlined"
          data-testid="app-version-upgrade-bar-refresh-button"
        >
          {t("APP_VERSION_UPDATE.BUTTON")}
        </Button>
      </Box>
    )
  );
}

export default AppVersionUpdateBar;
