import React from "react";
import { toast } from "react-toastify";

import { Toast } from "@supporting/components/Toast";

import { instance as localisationService } from "@shared/services/localisationService";

import { TOAST_PRESETS, TOAST_CONFIG } from "./constants";

/**
 * @function sendToast
 *  Function to send toasts.
 * @param  {string} title the i18n title identifier
 * @param  {string} [body] the i18n body identifier
 * @param  {object} [preset=TOAST_PRESETS.SUCCESS] the preset to use for the toast
 * @param  {object} [preset.options] react toastify options
 * @param  {string} [preset.type] toast's type (eg SUCCESS)
 * @param  {string} [preset.position="bottom-right"] toast's position
 * @param  {object} [translationVariables] variables to be replaced in by i18n templates, {title,body}
 * @param  {string} [preventOpenDuplicatesId] an custom id to prevent duplicate toasts, must be a string to function
 * @return {string} Toast's id.
 */
export default function sendToast({
  title,
  body,
  preset = TOAST_PRESETS.SUCCESS,
  translationVariables = {},
  preventOpenDuplicatesId = null,
}) {
  const { i18n } = localisationService;
  if (!title) {
    return null;
  }
  const toastOptions = {
    type: preset.type,
    autoClose: preset.options?.timeOut || TOAST_CONFIG.timeOut,
    className: preset.options?.iconClass || "",
    position: preset.position || TOAST_CONFIG.position,
  };

  if (preventOpenDuplicatesId && typeof preventOpenDuplicatesId === "string") {
    toastOptions.toastId = preventOpenDuplicatesId;
  }

  return toast(
    React.createElement(Toast, {
      title: i18n.t(`${title}`, translationVariables.title),
      body: body && i18n.t(`${body}`, translationVariables.body),
    }),
    toastOptions
  );
}

export function PRESETS() {
  return TOAST_PRESETS;
}
