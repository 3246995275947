/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton as Button } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import ResendOTP from "@supporting/components/ResendOTP/ResendOTP";
import authenticationService from "@supporting/services/authentication";
import { z } from "zod";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

import AccessRequestHeading from "../StepAccessRequest/AccessRequestHeading";

const otpFormSchema = z.object({
  otp: z.string().length(6, "AUTHENTICATION.DIALOGS.OTP.INCOMPLETE_CODE"),
});

const StepOTP = ({ email, onOTPSubmit }) => {
  const { t } = useTranslation();
  const currentRouteName = useCurrentRouteName();
  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: {
      otp: "",
    },
    mode: "onBlur",
    resolver: zodResolver(otpFormSchema),
  });

  const onSubmit = (data) => {
    onOTPSubmit(data.otp);
  };

  const sendOTP = () => {
    return authenticationService.sendOTP(email, false);
  };

  useEffect(() => {
    analytics.trackV2({
      category: analytics.CATEGORY.EMAIL_VERIFICATION_PAGE,
      action: analytics.ACTION.OPENED,
      metaData: {
        page: getCurrentPage(currentRouteName),
      },
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={6}>
      <AccessRequestHeading
        title="AUTHENTICATION.SIGNUP_OTP.TITLE"
        subtitle={t("AUTHENTICATION.SIGNUP_OTP.DESCRIPTION", { email })}
      />
      <Box
        component="form"
        maxWidth="sm"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={6.75}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Controller
          name="otp"
          control={control}
          rules={{ valueAsNumber: true, pattern: /^[0-9]*$/, maxLength: 6 }}
          render={({ field }) => (
            <TextField
              {...field}
              type="number"
              fullWidth
              label={t("AUTHENTICATION.DIALOGS.OTP.INPUT")}
              error={Boolean(errors.otp)}
              helperText={t(errors.otp?.message)}
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                  { appearance: "none" },
                "& input": {
                  WebkitAppearance: "none",
                  MozAppearance: "textfield",
                  appearance: "textfield",
                },
              }}
            />
          )}
        />
        <Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={!isValid}
            loading={isSubmitting}
          >
            {t("CORE.CONFIRM")}
          </Button>
          <ResendOTP sentOTP={sendOTP} />
        </Box>
      </Box>
    </Box>
  );
};

StepOTP.propTypes = {
  email: PropTypes.string.isRequired,
  onOTPSubmit: PropTypes.func.isRequired,
};

export default StepOTP;
