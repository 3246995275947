import { createContext, useContext } from "react";

const initialState = {
  activeStepKey: null,
  activeStepIndex: 0,
  isFirstStep: false,
  isLastStep: false,
  setDialogState: null,
  state: null,
  moveNextStep: null,
  movePreviousStep: null,
  moveToStep: null,
  onClose: null,
};

export const WizardDialogContext = createContext(initialState);
WizardDialogContext.displayName = "WizardDialogContext";

export const useWizardDialog = () => useContext(WizardDialogContext);
