/**
 * Calculate the percentage of the team storage used.
 *
 * @typedef TeamObject
 *
 * @description Calculates used team storage as percentage
 * @param {TeamObject} team - [Required] The team to calculate storage usage
 * @returns {number} Returns the storage usage in percentage for specified team
 */
export default function getUsedTeamStoragePercentage(team) {
  const maxStorageSizeBytes = team.subscriptionLimits.maxStorageSizeBytes;
  const usedStorageSizeByte = team.usedBillingLimits?.usedStorageSizeByte || 0;
  return maxStorageSizeBytes === 0
    ? 100
    : Math.max((usedStorageSizeByte / maxStorageSizeBytes) * 100, 0);
}
