import { queryClient } from "@shared/react-query";
import { useInfiniteQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "@mui/material";

import * as parseMentions from "@shared/helpers/parseMentions.js";
import { useDebounceValue } from "@shared/hooks/useDebounceValue";
import { SEARCH_KEYS } from "@shared/query-keys";
import backendClient from "@shared/services/backendClient";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import EmptyStateComponent from "./EmptyStateComponent";
import LoadingSkeleton from "./LoadingSkeletons";
import SearchComponent from "./SearchComponent";

const useOverviewSearch = (query, resource) => {
  return useInfiniteQuery({
    queryKey: SEARCH_KEYS.overview({ query, resource }),
    queryFn: async ({ signal }) => {
      const response = await backendClient.get(
        "/search",
        { query, resource },
        { signal }
      );
      const results = response[`${resource.toLowerCase()}SearchResults`];

      const items = results.topResults.map((item) => {
        return {
          ...item,
          ...(resource === "COMMENT" && {
            searchResultText: parseMentions.decode(
              item.searchResultText,
              item.mentionedUsers
            ),
          }),
        };
      });

      // FOR DETAIL VIEW
      queryClient.setQueryData(SEARCH_KEYS.detail({ query, resource }), {
        pages: [items],
        pageParams: [
          results.topResults[results.topResults.length - 1]?.paginationToken,
        ],
      });

      return { ...results, topResults: items };
    },
    getNextPageParam: () => null,
    enabled: query.length >= 3,
    stateTime: 2 * 60 * 1000,
  });
};

function searchIsFetching(projectSearch, fileSearch, commentSearch) {
  return (
    projectSearch.isFetching ||
    fileSearch.isFetching ||
    commentSearch.isFetching
  );
}

function searchHasResultData(projectSearch, fileSearch, commentSearch) {
  return (
    projectSearch.data?.pages[0]?.topResults?.length > 0 ||
    fileSearch.data?.pages[0]?.topResults?.length > 0 ||
    commentSearch.data?.pages[0]?.topResults?.length > 0
  );
}

export default function OverviewSearch({
  query,
  searchDialog,
  onSearchMore,
  sectionActive,
  sectionActiveIndex,
  onUpdateState,
}) {
  const { t } = useTranslation();

  const querySearch = useDebounceValue(query, 500);
  const projectSearch = useOverviewSearch(querySearch, "PROJECT");
  const fileSearch = useOverviewSearch(querySearch, "FILE");
  const commentSearch = useOverviewSearch(querySearch, "COMMENT");

  const isFetching = searchIsFetching(projectSearch, fileSearch, commentSearch);
  const searchHasResults = searchHasResultData(
    projectSearch,
    fileSearch,
    commentSearch
  );

  const hasDataFilled =
    projectSearch.data && fileSearch.data && commentSearch.data;

  useEffect(() => {
    onUpdateState({
      projectSearchResults: projectSearch.data?.pages[0],
      fileSearchResults: fileSearch.data?.pages[0],
      commentSearchResults: commentSearch.data?.pages[0],
      isSearchEmpty: !searchHasResults,
      selectedSearchResult: { type: "input", index: 0 },
    });
  }, [
    querySearch,
    onUpdateState,
    projectSearch.data?.pages,
    fileSearch.data?.pages,
    commentSearch.data?.pages,
    searchHasResults,
  ]);

  useEffect(() => {
    if (isFetching) {
      healthMetrics.trackStart("workflow.search");
    }
  }, [isFetching]);

  useEffect(() => {
    if (searchHasResults) {
      healthMetrics.trackSuccess("workflow.search");
    } else if (hasDataFilled && !searchHasResults) {
      healthMetrics.trackFailure(
        "workflow.search",
        new Error(`Search yielded no results`)
      );
    }
  }, [hasDataFilled, searchHasResults]);

  return (
    <Box
      maxHeight={650}
      display="flex"
      flexDirection="column"
      flex={1}
      data-testid="overview-search"
    >
      {querySearch && (
        <>
          {!searchHasResults && hasDataFilled && <EmptyStateComponent />}

          {searchHasResults && (
            <Box data-testid="search-results">
              <SearchComponent
                results={projectSearch.data?.pages[0]}
                searchDialog={searchDialog}
                header={t("SEARCH.PROJECT_HEADER")}
                data-testid="project-search-results"
                type="project"
                onSearchMore={onSearchMore}
                isSectionActive={sectionActive === "project"}
                activeItemIndex={sectionActiveIndex}
              />
              <SearchComponent
                results={fileSearch.data?.pages[0]}
                searchDialog={searchDialog}
                header={t("SEARCH.FILE_HEADER")}
                data-testid="file-search-results"
                type="file"
                showDivider={
                  projectSearch.data?.pages[0]?.topResults.length > 0
                }
                onSearchMore={onSearchMore}
                isSectionActive={sectionActive === "file"}
                activeItemIndex={sectionActiveIndex}
              />
              <SearchComponent
                results={commentSearch.data?.pages[0]}
                searchDialog={searchDialog}
                header={t("SEARCH.COMMENT_HEADER")}
                type="comment"
                data-testid="comment-search-results"
                showDivider={fileSearch.data?.pages[0]?.topResults.length > 0}
                onSearchMore={onSearchMore}
                isSectionActive={sectionActive === "comment"}
                activeItemIndex={sectionActiveIndex}
              />
            </Box>
          )}

          {isFetching && (
            <Box py={1} data-testid="search-results-loading">
              <LoadingSkeleton rows={6} />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}

OverviewSearch.propTypes = {
  query: PropTypes.string.isRequired,
  hasResults: PropTypes.bool,
  searchDialog: PropTypes.object.isRequired,
  onSearchMore: PropTypes.func.isRequired,
  sectionActive: PropTypes.string.isRequired,
  sectionActiveIndex: PropTypes.number,
  onUpdateState: PropTypes.func.isRequired,
};

OverviewSearch.defaultProps = {
  hasResults: false,
  sectionActiveIndex: null,
};
