import NiceModal from "@ebay/nice-modal-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import {
  REVIEW_DECISION_ACTIONS_TO_ANALYTICS_PARAMS,
  REVIEW_DECISION_STATES,
} from "@feedback/constants/reviewDecisionState";
import { LoadingButton as Button } from "@mui/lab";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  SvgIcon,
} from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import UpgradeDialogContent from "@supporting/components/UpgradePlanDialog/UpgradeDialogContent";
import UpgradePlanDialog from "@supporting/components/UpgradePlanDialog/UpgradePlanDialog";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Text } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import CrownIcon from "@assets/img/icons/crown.svg";

import DisableReviewDecisionDialog from "./DisableReviewDecisionDialog";
import ReviewDecisionOptionGroup from "./ReviewDecisionOptionGroup";

const ReviewDecisionSetting = ({ team }) => {
  const { t } = useTranslation();
  const [reviewDecisionSetting, setReviewDecisionSetting] = useState(
    team
      ? team.settings.reviewDecisionSetting
      : {
          approve: true,
          requestChanges: true,
          approveWithChanges: false,
          reject: false,
        }
  );
  const [isLoading, setIsLoading] = useState(false);
  const [reviewDecisionToDisable, setReviewDecisionToDisable] = useState("");
  const {
    isOpen: isDisableDecisionDialogOpen,
    openDialog: openDisableDecisionDialog,
    closeDialog: closeDisableDecisionDialog,
  } = useDialogState();

  const {
    isOpen: isUpgradePlanDialogOpen,
    closeDialog: closeUpgradePlanDialog,
    openDialog: openUpgradePlanDialog,
  } = useDialogState();

  const toggleReviewDecisionOption = (settingKey) => {
    if (team?.subscriptionLimits?.isCustomReviewDecisionsEnabled) {
      if (reviewDecisionSetting[settingKey] && !isDisableDecisionDialogOpen) {
        setReviewDecisionToDisable(settingKey);
        openDisableDecisionDialog();
        return;
      }

      analytics.track(
        analytics.ACTION[
          reviewDecisionSetting[settingKey] ? "DISABLED" : "ENABLED"
        ],
        analytics.CATEGORY.REVIEW_DECISIONS,
        {
          selectedReviewDecisions: Object.keys(reviewDecisionSetting)
            .filter((key) => {
              if (key === settingKey) {
                return !reviewDecisionSetting[settingKey];
              }

              return reviewDecisionSetting[key];
            })
            .map((key) => REVIEW_DECISION_ACTIONS_TO_ANALYTICS_PARAMS[key]),
        }
      );

      setReviewDecisionSetting((prevSetting) => ({
        ...prevSetting,
        [settingKey]: !prevSetting[settingKey],
      }));
      setReviewDecisionToDisable("");
    } else {
      openUpgradePlanDialog();
    }
  };

  const updateReviewDecisionSetting = async () => {
    setIsLoading(true);

    const updateTeamBody = {
      settings: { reviewDecisionSetting },
    };

    try {
      await teamService.updateTeamSettings(updateTeamBody);
      toastService.sendToast({
        title: "TEAM.EDIT.SETTING_SUCCESS.TITLE",
        body: "TEAM.EDIT.SETTING_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (err) {
      errorHandlerService.handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getSaveButtonDisabled = () => {
    if (!team) {
      return true;
    }

    for (const key in reviewDecisionSetting) {
      if (
        reviewDecisionSetting[key] !== team.settings.reviewDecisionSetting[key]
      ) {
        return false;
      }
    }

    return true;
  };

  const isSaveButtonDisabled = getSaveButtonDisabled();
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { teamId: team?._id });

  return (
    <>
      <Card>
        <CardHeader title={t("APPS.DIALOG.SETTINGS.REVIEW_DECISIONS.TEAMS")} />

        <CardContent>
          <Text color="text.secondary" translate="REVIEW_DECISION_CARD.BODY" />

          <Box
            mt={4}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <ReviewDecisionOptionGroup
              labelKey="REVIEW_DECISION_CARD.TEXT1"
              options={[
                REVIEW_DECISION_STATES.APPROVED,
                REVIEW_DECISION_STATES.NEEDS_CHANGES,
              ]}
              reviewDecisionSetting={reviewDecisionSetting}
              toggleReviewDecisionOption={toggleReviewDecisionOption}
              isDisabled
            />
            <ReviewDecisionOptionGroup
              labelKey="REVIEW_DECISION_CARD.TEXT2"
              options={[
                REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES,
                REVIEW_DECISION_STATES.REJECTED,
              ]}
              reviewDecisionSetting={reviewDecisionSetting}
              toggleReviewDecisionOption={toggleReviewDecisionOption}
              icon={
                team?.subscriptionLimits
                  ?.isCustomReviewDecisionsEnabled ? undefined : (
                  <SvgIcon component={CrownIcon} />
                )
              }
            />
          </Box>
        </CardContent>

        <CardActions>
          <Box p={2} mb={1} ml="auto">
            <Button
              color="primary"
              variant="contained"
              aria-label={t("SETTINGS.PROFILE.SUBMIT")}
              size="large"
              loading={isLoading}
              disabled={isSaveButtonDisabled}
              onClick={updateReviewDecisionSetting}
              disableRipple
            >
              {t("SETTINGS.PROFILE.SUBMIT")}
            </Button>
          </Box>
        </CardActions>
      </Card>

      {isUpgradePlanDialogOpen && (
        <UpgradePlanDialog
          isUpgradePlanDialogOpen
          onCancel={closeUpgradePlanDialog}
          dialogTitle="UPGRADE_MODAL.CUSTOM_REVIEW_DECISION.TITLE"
        >
          <UpgradeDialogContent
            variant="customReviewDecisions"
            onCancel={closeUpgradePlanDialog}
            isAdminView={team?.permissions?.canManageBilling}
            openBillingDialog={openDialog}
            teamId={team?._id}
          />
        </UpgradePlanDialog>
      )}
      <DisableReviewDecisionDialog
        isOpen={isDisableDecisionDialogOpen}
        cancel={closeDisableDecisionDialog}
        disableReviewDecision={() => {
          closeDisableDecisionDialog();
          toggleReviewDecisionOption(reviewDecisionToDisable);
        }}
      />
    </>
  );
};

ReviewDecisionSetting.propTypes = {
  team: teamProp,
};

ReviewDecisionSetting.defaultProps = {
  team: null,
};

export default ReviewDecisionSetting;
