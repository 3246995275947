import { useMemo, useReducer } from "react";

import { makeStyles } from "@mui/styles";
import PlanLimitCard from "@supporting/components/PlanLimitCard/PlanLimitCard";
import authenticationService from "@supporting/services/authentication";
import { useDashboardSidebarWebSockets } from "@workflow/data/dashboard-sidebar";
import projectSwitcherService from "@workflow/services/projectSwitcherService";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import ProjectList from "./SidebarList/ProjectList/ProjectList";
import { initState, sidebarReducer, SidebarContext } from "./sidebarState";
import Templates from "./Templates/Templates";
import ToggleSidebarButton from "./ToggleSidebarButton";

const useStyles = makeStyles((theme) => ({
  dashboardSidebar: {
    backgroundColor: theme.color.white,

    width: 350,
    minWidth: 350,
    maxWidth: 350,
    transition: ".4s ease-out",
    borderRight: `1px solid ${theme.color.gray[200]}`,
  },
  dashboardSidebarCollapsed: {
    marginLeft: -328,

    "& $sidebarContent": {
      opacity: 0,
      transition: ".4s ease-out",
    },
  },
  sidebarContent: {
    overflow: "hidden",
  },
}));

export default function Sidebar() {
  const user = authenticationService.fetchUser();

  const newLayoutEnabled =
    user.settings?.earlyAccessFeatures?.newSidebarStateManagement;

  useDashboardSidebarWebSockets(newLayoutEnabled);

  const classes = useStyles();
  const [state, dispatch] = useReducer(
    sidebarReducer,
    {
      user,
      selectedProject: projectSwitcherService.getSelectedProject(),
    },
    initState
  );
  const store = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <SidebarContext.Provider value={store}>
      <Box
        className={classnames(classes.dashboardSidebar, {
          [classes.dashboardSidebarCollapsed]: state.isCollapsed,
        })}
        position="relative"
        display="flex"
        flexDirection="column"
      >
        <ToggleSidebarButton />
        <Box
          className={classes.sidebarContent}
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          <ProjectList />
          <Templates />
          <PlanLimitCard project={state.selectedProject} />
        </Box>
      </Box>
    </SidebarContext.Provider>
  );
}
