import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Button } from "@mui/material";

const HelpButton = ({ href }) => {
  const { t } = useTranslation();

  return (
    <Button
      startIcon={<HelpOutlineIcon />}
      className="launch-intercom-chat"
      color="primaryV2"
      sx={{
        fontSize: "subtitle1.fontSize",
        fontWeight: "fontWeight.regular",
      }}
      href={href}
    >
      {t("SELECT_PLAN_DIALOG.HELP")}
    </Button>
  );
};

HelpButton.propTypes = {
  href: PropTypes.string.isRequired,
};

export default HelpButton;
