export const TOAST_CONFIG = {
  // params from angular-toast
  position: "bottom-right",
  timeOut: 3000,
  closeButton: true,
  maxOpened: 4,
  autoDismiss: true,
  // params for react-toastify
  tapToDismiss: false,
  hideProgressBar: true,
  newestOnTop: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  theme: "light",
};

export const TOAST_PRESETS = {
  /**
   * define presets for toasts:
   * @type {String} must be success, error, warning or info (required)
   * @options {Object} TO have custom timeout and iconClass (as body styling css configuration) for presenting.
   *
   */
  SUCCESS: {
    type: "success",
    options: {},
  },
  SUCCESS_DELAYED: {
    type: "success",
    options: {
      timeOut: 15000,
    },
  },
  ERROR: {
    type: "warning",
    options: {},
  },
  ERROR_DELAYED: {
    type: "error",
    options: {
      timeOut: 60000,
    },
  },
  ERROR_LARGE_DELAYED: {
    type: "warning",
    options: {
      iconClass: "Toastify__toast--large",
      timeOut: 60000,
    },
  },
  WARNING: {
    type: "warning",
    options: {},
  },
  INFO: {
    type: "info",
    options: {},
  },
  SUCCESS_LARGE: {
    type: "success",
    options: {
      iconClass: "Toastify__toast--large",
    },
  },
};
