import { useEffect, useRef } from "react";

const useKeepInView = ({ selected, keepInView }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref.current && selected) {
      ref.current.scrollIntoView({
        behavior: "instant",
        inline: "center",
        block: "nearest",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (keepInView) {
      ref.current.scrollIntoView({
        behavior: "instant",
        inline: "start",
      });
    }
  }, [keepInView]);

  return ref;
};

export default useKeepInView;
