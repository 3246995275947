import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import { Button } from "@mui/material";
import InviteNewTeamMemberDialog from "@supporting/components/InviteTeamMemberDialog/InviteTeamMemberDialog";

import { useDialogState, useMediaQuery } from "@shared/hooks";
import { teamProp } from "@shared/props/team";

function InviteTeamMemberButton({ team, page, ...restProps }) {
  const { t } = useTranslation();
  const { isOpen, openDialog, closeDialog } = useDialogState();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      {isOpen && (
        <InviteNewTeamMemberDialog
          closeDialog={closeDialog}
          team={team}
          currentSubscription={team.subscription}
          page={page}
        />
      )}
      <Button
        startIcon={<PersonAddOutlined />}
        onClick={openDialog}
        size="large"
        color="primary"
        variant="contained"
        {...restProps}
      >
        {t(isMobile ? "TEAM.INVITE_MOBILE" : "TEAM.INVITE")}
      </Button>
    </>
  );
}

InviteTeamMemberButton.propTypes = {
  team: teamProp.isRequired,
  page: PropTypes.string.isRequired,
};
export default InviteTeamMemberButton;
