import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import { Link } from "@mui/material";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";
import EmailContent from "@supporting/components/ReviewersAuthenticationFlow/EmailContent";
import authenticationService from "@supporting/services/authentication";

import { Text } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

export default function SignupEmailContent({
  email,
  context,
  action,
  onSubmit,
  isAwaitingReview,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const isEmbedInMondayFireFox =
    sessionStorage.getItem("monday-in-firefox") === "yes";
  const [authState, setAuthState] = useState({
    email: email.current,
    isEmailValid: false,
  });
  const { buildUrl } = useNavigation();
  const authRouteState = {
    redirect: buildUrl(ROUTE_STATES.WORKSPACE_AUTH_CALLBACK),
    state: {
      action,
      context,
    },
    shareSessionStepId: context.stepId,
  };

  const checkIfShouldSetIframe = () => {
    let shouldSetIframe = false;
    const pluginId = authenticationService.getBasePlugin();
    const isIFramePlugin = Object.values(PLUGIN_TYPES).includes(pluginId);
    try {
      shouldSetIframe = Boolean(
        isIFramePlugin ||
          isEmbedInMondayFireFox ||
          window.opener?.onSuccessfulAuthentication ||
          window.opener?.onEmbedSuccessfulAuthentication ||
          window.opener?.setEmbedCsrfToken
      );
    } catch {
      /* istanbul ignore next */
    }
    return shouldSetIframe;
  };

  async function fetchIdentityAndRedirect() {
    try {
      const identity = await authenticationService.fetchIdentity(email.current);
      if (identity.isExternal) {
        healthMetrics.trackStart("supporting.authentication");
        if (identity.name === "filestage-google-oauth2") {
          onSubmit("SHOW_GOOGLE");
          return;
        }
        return authenticationService.startExternalLogin(identity.name, {
          redirect: searchParams.get("redirect"),
          iframe: checkIfShouldSetIframe(),
        });
      }
      onSubmit("SHOW_PASSWORD");
    } catch (error) {
      /* istanbul ignore next */
      errorHandlerService.handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function onEmailSubmit(event) {
    try {
      setIsLoading(true);
      event.preventDefault();
      email.current = authState.email;
      const authenticationState = {
        email: authState.email,
        reason: "review-files",
      };
      await authenticationService.sendOTPForSignup(
        authState.email,
        authenticationState
      );
      onSubmit("SHOW_VERIFY_SIGNUP_EMAIL");
      setIsLoading(false);
    } catch (error) {
      if (error.code === "user_exists") {
        email.current = authState.email;
        return fetchIdentityAndRedirect();
      }
      setIsLoading(false);
      errorHandlerService.handleError(error);
    }
  }
  return (
    <form noValidate onSubmit={onEmailSubmit} id="reviewer-signup-dialog-email">
      <EmailContent
        isLoading={isLoading}
        authState={authState}
        setAuthState={setAuthState}
        autoFocus
        buttonText="SIGNUP.SUBMIT"
        defaultValue={email.current}
        title={isAwaitingReview ? "CREATE_MODAL.REVIEWER_FLOW.SUBTITLE" : ""}
      />
      <GoogleAuth
        testId="sign-in-google"
        text="signin_with"
        authState={authRouteState}
        shareSessionStepId={authRouteState.shareSessionStepId}
      />
      {isAwaitingReview && (
        <>
          <Text color="text.secondary" textAlign="center" mt={2}>
            {t("SUBTEXT.TOLOGIN_TEXT")}
            <Link onClick={() => onSubmit("SHOW_PASSWORD")} sx={{ pl: 1 }}>
              {t("SUBTEXT.TOLOGIN_LINK")}
            </Link>
          </Text>
          <Text
            fontSize="textXs"
            color="text.secondary"
            textAlign="center"
            mt={2}
          >
            <Text component="span" fontSize="textXs" pr={1}>
              {t("SIGNUP.LEGAL_TEXTBLOCK1")}
            </Text>
            <Link target="_blank" href={t("URLS.TERMS")}>
              {t("SIGNUP.LEGAL_TERMSOFSERVICE")}
            </Link>
            <Text component="span" pl={1} pr={1} fontSize="textXs">
              {t("SIGNUP.LEGAL_TEXTBLOCK2")}
            </Text>
            <Link target="_blank" href={t("URLS.PRIVACY")}>
              {t("SIGNUP.LEGAL_PRIVACYAGREEMENT")}
              {t("SIGNUP.LEGAL_TEXTBLOCK3")}
            </Link>
          </Text>
        </>
      )}
    </form>
  );
}
SignupEmailContent.propTypes = {
  action: PropTypes.string.isRequired,
  email: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  context: PropTypes.object.isRequired,
  isAwaitingReview: PropTypes.bool,
};
SignupEmailContent.defaultProps = {
  isAwaitingReview: false,
};
