import PropTypes from "prop-types";
import { ReactElement, useCallback } from "react";

import { Done } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import EditableLabelText from "./EditableLabelText/EditableLabelText";
import EditableLabelTextField from "./EditableLabelTextField/EditableLabelTextField";

const useStyles = makeStyles((theme) => ({
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    border: "1px solid transparent",
    padding: 4,
    borderRadius: 4,
  },
  tooltipContainer: {
    width: "90%",
    padding: "4px 0",
  },
  outlinedText: {
    width: "100%",
    "&:hover": {
      borderColor: theme.color.gray[300],
    },
  },
  textField: {
    flexGrow: 1,
    "& $notchedOutline": {
      border: `1px solid ${theme.color.gray[300]} !important`,
    },
  },
  disabledText: {
    "&:hover": {
      borderColor: "transparent",
    },
  },
  textFieldRoot: {
    backgroundColor: theme.color.white,
    flexGrow: 1,
    marginbottom: -2,
    "&:before": {
      borderBottom: `2px solid ${theme.color.green[500]} !important`,
    },
  },
  notchedOutline: {},
  input: {
    padding: 4,
  },
}));

/**
 * UIKit Editable Label Component
 * @description UIKit Editable Label Component that helps you to create a
 * label that can be edited in place. On the edit mode it creates a text field
 * and on the read mode it creates a text.
 * @param {object} props - Component props
 * @param {string} [props.text] [Required] - The content of the editable label.
 * @param {Function} [props.onBeforeSave] [Required] - Callback function that is called before saving the text.
 * @param {Function} [props.onAfterSave] [Required] - Callback function that is called after saving the text.
 * @param {string} [props.placeholder] [Optional] - The placeholder text to be shown when the editable label is empty.
 * @param {Function} [props.onChangeEditMode] [Optional] - Callback function that is called when the edit mode is changed.
 * @param {boolean} [props.isEditing] [Optional] - If true, the component will be in edit mode and render the text field.
 * @param {boolean} [props.disabled] [Optional] - If true, the button will be disabled
 * @param {string} [props.className] [Optional] - Additional class name for styling.
 * @param {object} [props.classes] [Optional] - Additional props for the text component.
 * @param {string?} props.tooltipContainerDisabledClassName The tooltip container disabled class name.
 * @param {{title: string, placement: string, text: string}} [props.tooltipProps] [Optional] - Additional props for the tooltip component.
 * @returns {ReactElement} - React component
 */
function EditableLabel({
  disabled,
  text,
  onBeforeSave,
  onAfterSave,
  onChangeEditMode,
  tooltipProps,
  isEditing,
  className,
  tooltipContainerDisabledClassName,
  placeholder,
  classes: classesProps,
  textVariant,
}) {
  const classes = useStyles();

  const handleSave = useCallback(
    async (value) => {
      if (value !== text && value.trim().length !== 0) {
        onBeforeSave(value);
        await onAfterSave(value);
      }

      onChangeEditMode(false);
    },
    [text, onBeforeSave, onAfterSave, onChangeEditMode]
  );

  const handleEnterEditMode = useCallback(() => {
    if (!disabled) {
      onChangeEditMode(true);
    }
  }, [disabled, onChangeEditMode]);

  const handleCancel = useCallback(() => {
    onChangeEditMode(false);
  }, [onChangeEditMode]);

  return (
    <Box
      alignItems="center"
      justify="flex-start"
      flexWrap="nowrap"
      flexDirection="row"
      display="flex"
      py={0}
      className={className}
      data-testid="editable-label"
      minWidth={0}
    >
      {isEditing ? (
        <>
          <EditableLabelTextField
            defaultValue={text}
            handleSave={handleSave}
            handleCancel={handleCancel}
            disabled={disabled}
            className={classes.textField}
            placeholder={placeholder}
            InputProps={{
              classes: {
                root: classes.textFieldRoot,
                notchedOutline: classes.notchedOutline,
                input: classnames(classes.input, classesProps.input),
              },
              "data-testid": "editable-label-input",
            }}
          />
          <Done data-testid="editable-label-button" color="primary" />
        </>
      ) : (
        <EditableLabelText
          handleClick={handleEnterEditMode}
          editMode={isEditing}
          className={classesProps.text}
          disabled={disabled}
          text={text}
          textVariant={textVariant}
          tooltipProps={tooltipProps}
          tooltipContainerDisabledClassName={tooltipContainerDisabledClassName}
        />
      )}
    </Box>
  );
}

EditableLabel.propTypes = {
  className: PropTypes.string,
  tooltipContainerDisabledClassName: PropTypes.string,
  disabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  onBeforeSave: PropTypes.func.isRequired,
  onAfterSave: PropTypes.func.isRequired,
  onChangeEditMode: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  tooltipProps: PropTypes.shape({
    title: PropTypes.string,
    placement: PropTypes.string,
    text: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.shape({
    root: PropTypes.string,
    input: PropTypes.string,
    text: PropTypes.string,
  }),
  textVariant: PropTypes.string,
};

EditableLabel.defaultProps = {
  isEditing: false,
  tooltipProps: {
    title: "",
    placement: "top",
    text: "",
  },
  classes: {},
  className: "",
  tooltipContainerDisabledClassName: "",
  disabled: false,
  placeholder: "",
  width: null,
  textVariant: null,
};

export default EditableLabel;
