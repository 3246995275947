import PropTypes from "prop-types";
import { useState, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  VisibilityOffOutlined,
  VisibilityOutlined,
  VpnKeyOutlined,
} from "@mui/icons-material";
import { TextField, InputAdornment, IconButton } from "@mui/material";

import { Box } from "@shared/UIKit";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { instance as analytics } from "@shared/services/analytics";

const PasswordField = forwardRef(
  (
    {
      onChange,
      onClick,
      value,
      error,
      errorTranslate,
      name,
      id,
      labelTranslate,
      testId,
      isFocusIcon,
      ...restProps
    },
    ref
  ) => {
    const [showPassword, setShowPassword] = useState(false);
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const toggleShowPassword = (event) => {
      event.preventDefault();
      analytics.track(
        showPassword
          ? analytics.ACTION.MASKED_PASSWORD
          : analytics.ACTION.UMASKED_PASSWORD,
        analytics.CATEGORY.ACCOUNT_USER_PASSWORD,
        { page: getCurrentPage(pathname) }
      );
      setShowPassword(!showPassword);
    };
    const handleMouseUpandDownPassword = (event) => {
      event.preventDefault();
    };

    let iconColor = "default-light.main";
    if (isFocusIcon) {
      iconColor = "primary.main";
    }
    if (error) {
      iconColor = "error.main";
    }

    return (
      <Box
        ref={ref}
        width="100%"
        display="flex"
        flexDirection="row"
        alignItems="flex-end"
        data-testid="password-input-field"
        {...restProps}
      >
        <TextField
          onChange={onChange}
          onClick={onClick}
          value={value}
          error={error}
          helperText={error ? t(errorTranslate) : " "}
          type={showPassword ? "text" : "password"}
          name={name}
          fullWidth
          id={id}
          label={t(labelTranslate)}
          inputProps={{
            "data-testid": testId,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <VpnKeyOutlined sx={{ color: iconColor }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  data-testid="toggle-password"
                  onClick={toggleShowPassword}
                  onMouseDown={handleMouseUpandDownPassword}
                  onMouseUp={handleMouseUpandDownPassword}
                  edge="end"
                  size="large"
                >
                  {showPassword ? (
                    <VisibilityOffOutlined />
                  ) : (
                    <VisibilityOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...restProps}
        />
      </Box>
    );
  }
);
PasswordField.displayName = "PasswordField";
export default PasswordField;
PasswordField.defaultProps = {
  onClick: () => null,
  isFocusIcon: false,
  testId: "password-text-field",
};
PasswordField.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  labelTranslate: PropTypes.string.isRequired,
  errorTranslate: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  isFocusIcon: PropTypes.bool,
  testId: PropTypes.string,
};
