import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import VersionListDropDownButton from "@feedback/components/VersionListDropDownButton/VersionListDropDownButton";
import { Checkbox } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import { useSelectedFiles } from "@workflow/components/SelectFilesContext";
import VersionButton from "@workflow/components/UploadFileButton/VariantButton/VersionButton/VersionButton";
import useUploadPanelDialog from "@workflow/components/UploadPanelDialog/useUploadPanelDialog";
import classNames from "classnames";
import findLast from "lodash/findLast";

import { Box } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import {
  FILE_VERSION_UPLOAD_STATUS,
  UPLOAD_COMPONENT_TYPE,
} from "@shared/constants/upload";
import { useMediaQuery } from "@shared/hooks";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";

import ProjectFileMenu from "../ProjectFileMenu/ProjectFileMenu";
import FileCardThumbnail from "./FileCardThumbnail/FileCardThumbnail";
import FileCardUploadProgress from "./FileCardUploadProgress/FileCardUploadProgress";
import FileName from "./FileName/FileName";
import LockedFileState from "./LockedFileState/LockedFileState";

const useStyles = makeStyles((theme) => ({
  fileCard: {
    borderTop: `1px ${theme.color.gray[200]} solid`,
    borderTopLeftRadius: 2,
    borderBottomLeftRadius: 2,
    clipPath: "inset(0px -15px 0px 0px)",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    "&:hover $uploadFileButton > span > p": {
      opacity: 1,
    },
    "&:hover $fileSelectCheckbox": {
      display: "flex",
    },
    width: ({ width }) => width,
    minWidth: ({ minWidth }) => minWidth,
    maxWidth: ({ maxWidth }) => maxWidth,
  },
  alternativeFileCard: {
    marginTop: -2,
  },
  uploadFileButton: {},
  fileName: {
    marginLeft: 10,
  },
  bottomBoxContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    boxSizing: "border-box",
  },
  notProcessingBottomBoxContainer: {
    paddingBottom: 6,
    paddingLeft: 9,
    paddingRight: 12,
  },

  thumbnailOverlay: {
    backgroundColor: theme.color["black-translucent-60"],
    position: "absolute",
    width: 100,
    minWidth: 100,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noReviews: {
    cursor: "default",
  },
  fileSelectCheckbox: {
    position: "absolute",
    top: 2,
    left: 2,
    padding: 0,
    margin: 0,
    display: "none",
    color: theme.color.gray[600],
    backgroundColor: theme.color.white,
    borderRadius: 3,
    width: 21,
    height: 21,
    "&:hover": {
      color: theme.color.gray[800],
      backgroundColor: theme.color.white,
    },
    "& > span": {
      marginTop: -2,
    },
  },
  showSelection: {
    display: "block",
  },
  versionButton: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
}));

/* eslint complexity: off */
function FileCard({
  file,
  steps,
  lastVersion,
  toggleVersionsSection,
  isVersionsOpen,
  project,
  rowIndex,
  className,
}) {
  const uploadStatus = file.versions[file.versions.length - 1].uploadStatus;
  const { width, minWidth, maxWidth } = useFileCardResized();
  const classes = useStyles({
    width,
    minWidth,
    maxWidth,
  });
  const { t } = useTranslation();
  const theme = useTheme();

  const [editMode, setEditMode] = useState(false);
  const { isSelectionState, isFileSelected, toggleSelectFile } =
    useSelectedFiles();

  const canUploadFiles = !project.isArchived && project.permissions.uploadFiles;

  const lastReviewedVersion = useMemo(() => {
    return findLast(file.versions, (version) => version.reviews.length > 0);
  }, [file]);

  const defaultUploadStepIds = useMemo(() => {
    const reviewStepIds = lastVersion.reviews.map((review) => review.stepId);
    const lastReviewStepId = steps
      .map((step) => step.id)
      .reverse()
      .find((stepId) => reviewStepIds.includes(stepId));
    const firstStepId = steps.length > 0 ? steps[0].id : null;
    const stepId = lastReviewStepId || firstStepId;
    return stepId ? [stepId] : [];
  }, [lastVersion.reviews, steps]);

  const { openUploadPanelDialog } = useUploadPanelDialog({
    projectId: project.id,
    stepIds: defaultUploadStepIds,
    selectedFile: file,
    teamId: project.teamId,
    page: pagesEnum.PROJECT_DASHBOARD,
    component: UPLOAD_COMPONENT_TYPE.POPOVER,
    className: classes.uploadFileButton,
  });

  const isCurrentFileSelected = isFileSelected(file._id);

  const selectFile = () => {
    analytics.track(
      analytics.ACTION[isCurrentFileSelected ? "DESELECTED" : "SELECTED"],
      analytics.CATEGORY.FILES,
      {
        page: "project-dashboard",
        view: "grid-view",
        fileSelection: "individual-files",
      }
    );
    toggleSelectFile(file._id);
  };

  const handleChangeEditMode = useCallback((value) => {
    setEditMode(value);
  }, []);

  const onRename = useCallback(() => setEditMode(true), []);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      display="flex"
      data-testid="file-card"
      bgcolor={theme.color.white}
      mr={0.125}
      height={68}
      maxHeight={68}
      left={0}
      zIndex={36}
      position={isMobile ? "unset" : "sticky"}
      borderBottom={`1px solid ${theme.color.gray[200]}`}
      boxShadow={theme.shadow["box-shadow-file-card"]}
      borderRight={`1px solid ${theme.color.gray[200]}`}
      className={classNames("tour__file", classes.fileCard, className, {
        [classes.noReviews]: !lastReviewedVersion,
        [classes.alternativeFileCard]: rowIndex > 0,
      })}
    >
      {file.isLocked && <LockedFileState />}
      <FileCardThumbnail
        file={file}
        lastVersion={lastVersion}
        lastReviewedVersion={lastReviewedVersion}
      />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        overflow="hidden"
      >
        <Box
          justifyContent="space-between"
          display="flex"
          overflow="hidden"
          alignItems="center"
          pt={0.375}
          pl={0.5}
          pr={1}
        >
          <FileName
            fileId={file._id}
            fileName={file.name}
            isDisabled={
              file.isProcessing ||
              project.isArchived ||
              !file.permissions.canRename ||
              uploadStatus === FILE_VERSION_UPLOAD_STATUS.PROCESSING
            }
            isEditMode={editMode}
            onChangeEditMode={handleChangeEditMode}
          />
          {!editMode &&
            !file.isProcessing &&
            (uploadStatus === undefined ||
              uploadStatus === FILE_VERSION_UPLOAD_STATUS.COMPLETED) && (
              <ProjectFileMenu
                project={project}
                version={lastVersion}
                variant="file"
                file={file}
                onRename={onRename}
              />
            )}
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          width="100%"
          boxSizing="border-box"
          className={classNames({
            [classes.notProcessingBottomBoxContainer]:
              !file.isProcessing &&
              (uploadStatus === undefined ||
                uploadStatus === FILE_VERSION_UPLOAD_STATUS.COMPLETED),
          })}
        >
          {file.isProcessing ||
          (uploadStatus !== FILE_VERSION_UPLOAD_STATUS.COMPLETED &&
            uploadStatus) ? (
            <FileCardUploadProgress file={file} />
          ) : (
            <>
              <Box>
                {canUploadFiles && (
                  <VersionButton onClick={openUploadPanelDialog} />
                )}
              </Box>
              <VersionListDropDownButton
                isOpen={isVersionsOpen}
                hideDropDownIcon={file.versions.length === 1}
                selectedVersionNumber={lastVersion.number}
                toggleVersionsDropDown={toggleVersionsSection}
                toolTipText={
                  file.versions.length > 1
                    ? t("VERSION.SHOW_PREVIOUS")
                    : t("VERSION.HISTORY_TOOLTIP")
                }
                shape="rounded"
              />
            </>
          )}
        </Box>
      </Box>
      <Checkbox
        classes={{
          root: classNames(classes.fileSelectCheckbox, {
            [classes.showSelection]: isSelectionState,
          }),
        }}
        color="primaryV2"
        checked={isCurrentFileSelected}
        onChange={selectFile}
        data-testid="file-select-checkbox"
      />
    </Box>
  );
}

FileCard.propTypes = {
  file: fileProp.isRequired,
  lastVersion: versionProp.isRequired,
  toggleVersionsSection: PropTypes.func.isRequired,
  isVersionsOpen: PropTypes.bool.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
  rowIndex: PropTypes.number,
  className: PropTypes.string,
};

FileCard.defaultProps = {
  rowIndex: 0,
  className: "",
};

export default FileCard;
