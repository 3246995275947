import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

import { Badge, Chip, Tab, Tabs } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import { Box } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import { useCurrentRouteName } from "@shared/hooks";

function DashboardNavButtons() {
  const { t } = useTranslation();
  const routeMatch = useCurrentRouteName([
    "DASHBOARD",
    "TEMPLATE_ID",
    "INSIGHTS_SPECIFIC",
    "TEAM_SPECIFIC",
    "INTEGRATIONS",
  ]);
  const currentTab = routeMatch;

  const team = useSelectedTeam();

  return (
    <Tabs
      value={currentTab}
      sx={{
        position: "relative",
        "& .MuiTab-root": {
          textTransform: "none",
          fontWeight: "fontWeight.bold",
        },
      }}
    >
      <Tab
        component={RouterLink}
        value="DASHBOARD"
        to={APP_ROUTES.DASHBOARD.path}
        label={t("CORE.PROJECTS")}
        data-testid="navigate-projects-link"
      />
      {team.permissions.canViewInsights && (
        <Tab
          component={RouterLink}
          value="INSIGHTS_SPECIFIC"
          to={APP_ROUTES.INSIGHTS_GENERAL.path}
          label={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={0.5}
              className="tour__insights"
            >
              {t("CORE.INSIGHTS")}
              {team.subscriptionLimits.isAdvancedInsightsEnabled && (
                <Chip
                  data-testid="new-badge"
                  variant="outlined"
                  color="accent"
                  label={t("CORE.BETA")}
                  size="small"
                />
              )}
            </Box>
          }
          data-testid="navigate-insights-link"
        />
      )}
      <Tab
        component={RouterLink}
        value="TEAM_SPECIFIC"
        to={APP_ROUTES.TEAMS_GENERAL.path}
        label={
          team?.pendingMembers.length > 0 ? (
            <Badge
              color="upgrade"
              variant="dot"
              data-testid="pending-member-bubble"
            >
              {t("CORE.OPEN_TEAMS")}
            </Badge>
          ) : (
            t("CORE.OPEN_TEAMS")
          )
        }
        data-testid="navigate-teams-link"
      />
      <Tab
        component={RouterLink}
        value="INTEGRATIONS"
        to={APP_ROUTES.INTEGRATIONS.path}
        label={t("CORE.INTEGRATIONS")}
        data-testid="navigate-integrations-link"
      />
    </Tabs>
  );
}

export default DashboardNavButtons;
