import googleDriveService from "@integrations/services/googleDriveService";
import microsoftTeamsService from "@integrations/services/microsoftTeamsService";
import slackAppService from "@integrations/services/slackAppService";

import GoogleDriveAuthorizationFailedPageLogo from "@assets/img/icons/google-drive-error-page-icon.svg";
import GoogleDriveLogoIcon from "@assets/img/icons/google-drive-icon.svg";
// eslint-disable-next-line import/no-unresolved
import MicrosoftTeamsLogoIcon from "@assets/img/icons/microsoft-teams.svg?url";
import MondayAppAuthorizationFailedPageLogo from "@assets/img/icons/monday-app-icon.svg";
import SlackLogo from "@assets/img/icons/slack-icon.svg";
import AfterEffectsLogo from "@assets/img/images/adobe-after-effects-logo@2x.png";
import InDesignLogo from "@assets/img/images/adobe-indesign-logo@2x.png";
// eslint-disable-next-line import/no-unresolved
import AdobePhotoshopLogo from "@assets/img/images/adobe-photoshop.svg?url";
import PremiereProLogo from "@assets/img/images/adobe-premier-pro-logo@2x.png";
import AirtableLogo from "@assets/img/images/airtable-logo.png";
import AsanaLogo from "@assets/img/images/asana-logo@2x.png";
// eslint-disable-next-line import/no-unresolved
import BoxLogo from "@assets/img/images/box-logo.svg?url";
// eslint-disable-next-line import/no-unresolved
import ClickupLogo from "@assets/img/images/clickup-logo.svg?url";
// eslint-disable-next-line import/no-unresolved
import DropboxLogo from "@assets/img/images/dropbox-logo.svg?url";
import googleDriveBannerImage from "@assets/img/images/google-drive-banner.png";
// eslint-disable-next-line import/no-unresolved
import GoogleDriveLogo from "@assets/img/images/google-drive-logo.svg?url";
import microsoftTeamsBannerImage from "@assets/img/images/microsoft-teams-banner.png";
// eslint-disable-next-line import/no-unresolved
import MondayLogo from "@assets/img/images/monday-logo.png";
// eslint-disable-next-line import/no-unresolved
import NotionLogo from "@assets/img/images/notion-logo.svg?url";
// eslint-disable-next-line import/no-unresolved
import SlackAppLogo from "@assets/img/images/slack-app-logo.svg?url";
import slackBannerImage from "@assets/img/images/slack-mockup-v1.png";
import TrelloLogo from "@assets/img/images/trello-logo.png";
import ZapierLogo from "@assets/img/images/zapier-logo@2x.png";

export const FEATURE_STATUS_ENUM = Object.freeze({
  COMING_SOON: "coming-soon",
  EARLY: "early",
  PUBLIC: "public",
  TBD: "TBD",
  UPCOMING: "upcoming",
});

export const INTEGRATION_APPS_ALIAS = {
  "monday-app": "monday",
};

export const INTEGRATION_APPS = [
  {
    id: "zapier",
    title: "Zapier",
    description: "",
    logoUrl: ZapierLogo,
    localizationKey: "ZAPIER",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsZapier",
    isNew: true,
    hasDescriptionPage: true,
    descriptionPage: {
      links: {
        learnMore:
          "https://help.filestage.io/en/articles/6979348-integrate-filestage-with-zapier",
        privacyPolicy: "https://filestage.io/data-privacy/",
      },
    },
  },
  {
    id: "monday",
    title: "Monday",
    description: "",
    logoUrl: MondayLogo,
    localizationKey: "MONDAY",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsMonday",
    href: "https://monday.com/marketplace/10000285",
    isNew: true,
    hasDescriptionPage: false,
    authorizationFailedPage: {
      logo: MondayAppAuthorizationFailedPageLogo,
      pageTitle: "MONDAY.AUTH.ERROR.TITLE",
      pageBody: "MONDAY.AUTH.ERROR.BODY",
      ctaButton: "MONDAY.AUTH.ERROR.CTA",
    },
  },
  {
    id: "adobe-after-effects",
    title: "Adobe After Effects",
    description: "",
    logoUrl: AfterEffectsLogo,
    localizationKey: "ADOBEAEFT",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsAdobeAfterEffects",
    href: "https://exchange.adobe.com/apps/cc/108907/filestage",
    hasDescriptionPage: false,
  },
  {
    id: "adobe-indesign",
    title: "Adobe InDesign",
    description: "",
    logoUrl: InDesignLogo,
    localizationKey: "ADOBEIDSN",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsAdobeInDesign",
    href: "https://exchange.adobe.com/apps/cc/108907/filestage",
    hasDescriptionPage: false,
  },
  {
    id: "adobe-premiere-pro",
    title: "Adobe Premiere Pro",
    description: "",
    logoUrl: PremiereProLogo,
    localizationKey: "ADOBEPP",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsAdobePremierePro",
    href: "https://exchange.adobe.com/apps/cc/108907/filestage",
    hasDescriptionPage: false,
  },
  {
    id: "slack",
    title: "Slack",
    description: "",
    logoUrl: SlackAppLogo,
    localizationKey: "SLACK",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsSlack",
    hasDescriptionPage: true,
    descriptionPage: {
      bannerImage: slackBannerImage,
      oauthEnabled: true,
      localizationKeys: {
        integrationsConnectedState: "INTEGRATIONS.CONNECTED.CONFIRMATION",
        integrationsDisconnectDialog:
          "APPS.DIALOG.DISCONNECT_MODAL.DESCRIPTION.SLACK",
      },
      links: {
        learnMore:
          "https://help.filestage.io/en/articles/6416366-integrate-filestage-with-slack",
        privacyPolicy: "https://slack.com/trust/privacy/privacy-policy",
      },
    },
    settingsIcon: SlackLogo,
    appService: slackAppService,
  },
  {
    id: "google-drive",
    title: "Google Drive",
    description: "",
    logoUrl: GoogleDriveLogo,
    localizationKey: "GOOGLE_DRIVE",
    featureStatus: FEATURE_STATUS_ENUM.PUBLIC,
    featureId: "appsGoogleDrive",
    hasDescriptionPage: true,
    descriptionPage: {
      bannerImage: googleDriveBannerImage,
      oauthEnabled: true,
      links: {
        learnMore:
          "https://help.filestage.io/en/articles/9518914-integrate-filestage-with-google-drive",
        privacyPolicy: "https://policies.google.com/privacy",
      },
      localizationKeys: {
        integrationsConnectedState: "GOOGLE_DRIVE.CONNECTED.CONFIRMATION",
        integrationsDisconnectDialog:
          "APPS.DIALOG.DISCONNECT_MODAL.DESCRIPTION.SLACK",
      },
    },
    settingsIcon: GoogleDriveLogoIcon,
    appService: googleDriveService,
    authorizationFailedPage: {
      logo: GoogleDriveAuthorizationFailedPageLogo,
      pageErrorTitle: "GOOGLE_DRIVE.AUTH.ERROR.TITLE",
      pageStepsTitle: "GOOGLE_DRIVE.AUTH.ERROR.BODY-1",
      pageStepsActions: [
        {
          text: "GOOGLE_DRIVE.AUTH.ERROR.BODY-2",
          link: "",
        },
        { text: "GOOGLE_DRIVE.AUTH.ERROR.BODY-3" },
      ],
    },
  },
  {
    id: "microsoft-teams",
    title: "Microsoft Teams",
    description: "",
    logoUrl: MicrosoftTeamsLogoIcon,
    localizationKey: "MS_TEAMS",
    featureStatus: FEATURE_STATUS_ENUM.TBD,
    featureId: "appsMicrosoftTeams",
    hasDescriptionPage: true,
    descriptionPage: {
      bannerImage: microsoftTeamsBannerImage,
      oauthEnabled: true,
      links: {
        learnMore:
          "https://help.filestage.io/en/articles/9518914-integrate-filestage-with-google-drive",
        privacyPolicy: "https://policies.google.com/privacy",
      },
      localizationKeys: {
        integrationsConnectedState: "MS_TEAMS.CONNECTED.CONFIRMATION",
        integrationsDisconnectDialog:
          "APPS.DIALOG.DISCONNECT_MODAL.DESCRIPTION.MS_TEAMS",
      },
    },
    settingsIcon: GoogleDriveLogoIcon,
    appService: microsoftTeamsService,
    authorizationFailedPage: {
      logo: GoogleDriveAuthorizationFailedPageLogo,
      pageErrorTitle: "MS_TEAMS.AUTH.ERROR.TITLE",
      pageStepsTitle: "MS_TEAMS.AUTH.ERROR.BODY-1",
      pageStepsActions: [
        {
          text: "MS_TEAMS.AUTH.ERROR.BODY-2",
          link: "",
        },
        { text: "MS_TEAMS.AUTH.ERROR.BODY-3" },
      ],
    },
  },
  {
    id: "trello",
    title: "Trello",
    description: "",
    logoUrl: TrelloLogo,
    localizationKey: "TRELLO",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsTrello",
    hasDescriptionPage: false,
  },
  {
    id: "adobe-photoshop",
    title: "Adobe Photoshop",
    description: "",
    logoUrl: AdobePhotoshopLogo,
    localizationKey: "ADOBEPHOTOSHOP",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsAdobePhotoshop",
    hasDescriptionPage: false,
  },
  {
    id: "notion",
    title: "Notion",
    description: "",
    logoUrl: NotionLogo,
    localizationKey: "NOTION",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsNotion",
    hasDescriptionPage: false,
  },
  {
    id: "airtable",
    title: "Airtable",
    description: "",
    logoUrl: AirtableLogo,
    localizationKey: "AIRTABLE",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsAirtable",
    hasDescriptionPage: false,
  },
  {
    id: "asana",
    title: "Asana",
    description: "",
    logoUrl: AsanaLogo,
    localizationKey: "ASANA",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsAsana",
    hasDescriptionPage: false,
  },
  {
    id: "dropbox",
    title: "Dropbox",
    description: "",
    logoUrl: DropboxLogo,
    localizationKey: "DROPBOX",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsDropbox",
    hasDescriptionPage: false,
  },
  {
    id: "box",
    title: "Box",
    description: "",
    logoUrl: BoxLogo,
    localizationKey: "BOX",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsBox",
    hasDescriptionPage: false,
  },
  {
    id: "clickup",
    title: "Clickup",
    description: "",
    logoUrl: ClickupLogo,
    localizationKey: "CLICKUP",
    featureStatus: FEATURE_STATUS_ENUM.UPCOMING,
    featureId: "appsClickup",
    hasDescriptionPage: false,
  },
];
