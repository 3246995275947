export const REVIEW_DECISION_STATES = Object.freeze({
  APPROVED: "APPROVED",
  APPROVED_WITH_CHANGES: "APPROVED_WITH_CHANGES",
  NEEDS_CHANGES: "NEEDS_CHANGES",
  REJECTED: "REJECTED",
});

export const REVIEW_DECISION_STATES_TO_SETTING_KEYS = Object.freeze({
  [REVIEW_DECISION_STATES.APPROVED]: "approve",
  [REVIEW_DECISION_STATES.NEEDS_CHANGES]: "requestChanges",
  [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]: "approveWithChanges",
  [REVIEW_DECISION_STATES.REJECTED]: "reject",
});

export const REVIEW_DECISION_STATES_TO_ACTIONS =
  REVIEW_DECISION_STATES_TO_SETTING_KEYS;

export const REVIEW_DECISION_ACTIONS_TO_STATES = Object.freeze(
  Object.fromEntries(
    Object.entries(REVIEW_DECISION_STATES_TO_ACTIONS).map(([state, action]) => [
      action,
      state,
    ])
  )
);

export const REVIEW_DECISION_ACTIONS_TO_ANALYTICS_PARAMS = Object.freeze({
  [REVIEW_DECISION_STATES_TO_ACTIONS[REVIEW_DECISION_STATES.APPROVED]]:
    "approved",
  [REVIEW_DECISION_STATES_TO_ACTIONS[REVIEW_DECISION_STATES.NEEDS_CHANGES]]:
    "requested-changes",
  [REVIEW_DECISION_STATES_TO_ACTIONS[
    REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES
  ]]: "approved-with-changes",
  [REVIEW_DECISION_STATES_TO_ACTIONS[REVIEW_DECISION_STATES.REJECTED]]:
    "rejected",
});
