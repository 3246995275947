import PropTypes from "prop-types";
import { Children } from "react";

import { Menu, Popover } from "@mui/material";

const SubMenuChildrenWrapper = ({ labelledBy, children, ...props }) => {
  if (Children.count(children) === 1) {
    return (
      <Popover
        {...props}
        aria-labelledby={labelledBy}
        sx={{
          pointerEvents: "none",
        }}
        slotProps={{
          paper: {
            sx: {
              pointerEvents: "auto",
              borderRadius: 0,
            },
          },
        }}
      >
        {children}
      </Popover>
    );
  }
  return (
    <Menu
      disableRestoreFocus
      {...props}
      sx={{
        pointerEvents: "none",
        "& .MuiList-root": {
          pointerEvents: "auto",
        },
      }}
      MenuListProps={{
        "aria-labelledby": labelledBy,
      }}
    >
      {children}
    </Menu>
  );
};

SubMenuChildrenWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  labelledBy: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

SubMenuChildrenWrapper.defaultProps = {
  anchorEl: null,
};

export default SubMenuChildrenWrapper;
