import { useSearchParams } from "react-router-dom";

import { Box } from "@mui/material";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";
import WizardBase from "@supporting/components/WizardBase/WizardBase";

import { Text } from "@shared/UIKit";

export default function AdobePluginLogin() {
  const [searchParams] = useSearchParams();

  const googleAuthState = {
    redirect: searchParams.get("redirect"),
  };
  const pluginUserId = searchParams.get("pluginUserId");
  const translationCode = searchParams.get("code");

  return (
    <WizardBase variant="LOGIN">
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="row"
        data-testid="adobe-plugin-login-page"
      >
        <Box flex="1 1 100%" display="flex" flexDirection="column">
          <Box
            display="flex"
            flex="1 0 auto"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
          >
            <Text
              variant="text3xl"
              fontWeight="fontWeight.medium"
              translate={`${
                translationCode === "SIGN_UP" ? "SIGNUP" : "LOGIN"
              }.HEADLINE`}
            />
            <GoogleAuth
              testId="sign-in-google"
              text="signin_with"
              authState={googleAuthState}
              showButtonOnly
              adobePluginSessionId={pluginUserId}
              adobePluginText={translationCode}
            />
          </Box>
        </Box>
      </Box>
    </WizardBase>
  );
}
