import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import BackArrow from "@mui/icons-material/ArrowBack";
import { Box, IconButton, TextField } from "@mui/material";

import { Tooltip } from "@shared/UIKit";

function InputWebsiteUrlView({
  onUrlChange,
  onClickOfBackButton,
  urlValidationError,
}) {
  const { t } = useTranslation();

  const handleURLInputChange = ({ currentTarget: { value } }) => {
    onUrlChange(value.trim());
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="stretch"
      data-testid="live-website-input-dialog"
      height={90}
      bgcolor="white"
      borderRadius="6px"
      gap={2}
      py={2}
      px={4}
      pb={6}
    >
      <Tooltip
        title={t(
          "FILE.UPLOAD_PANEL.IMPORT_WEBSITE_INPUT_DIALOG.BACK_BUTTON_LABEL"
        )}
        placement="right"
      >
        <IconButton
          aria-label={t(
            "FILE.UPLOAD_PANEL.IMPORT_WEBSITE_INPUT_DIALOG.BACK_BUTTON_LABEL"
          )}
          data-testid="review-website-url-input-dialog-back-button"
          onClick={onClickOfBackButton}
          edge="start"
        >
          <BackArrow />
        </IconButton>
      </Tooltip>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap={2}
        flexDirection={{ xs: "column", sm: "column", md: "row", lg: "row" }}
      >
        <TextField
          autoFocus
          error={Boolean(urlValidationError)}
          helperText={urlValidationError ? t(urlValidationError) : ""}
          size="medium"
          label={t(
            "FILE.UPLOAD_PANEL.IMPORT_WEBSITE_INPUT_DIALOG.INPUT_FIELD_LABEL"
          )}
          variant="outlined"
          onChange={handleURLInputChange}
          sx={{ flex: 1, width: "100%" }}
        />
      </Box>
    </Box>
  );
}

InputWebsiteUrlView.propTypes = {
  onUrlChange: PropTypes.func.isRequired,
  onClickOfBackButton: PropTypes.func.isRequired,
  urlValidationError: PropTypes.string,
};

InputWebsiteUrlView.defaultProps = {
  urlValidationError: null,
};

export default InputWebsiteUrlView;
