import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SmsOutlined, MailOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import TeamMemberDialogWarning from "@supporting/components/InviteTeamMemberDialog/TeamMemberDialogWarning";
import SeatUpsellPopup from "@supporting/components/SeatUpsellPopup/SeatUpsellPopup";
import {
  doesBaseSubscriptionUserLimitReached,
  canAddMoreUsersToSubscription,
} from "@supporting/helpers/subscriptionUtility";
import AddReviewerDialogAutocomplete from "@workflow/components/AddReviewerDialog/AddReviewerDialogAutocomplete/AddReviewerDialogAutocomplete";
import some from "lodash/some";
import sortBy from "lodash/sortBy";

import { Dialog, Text } from "@shared/UIKit";

import AUTOCOMPLETE from "@shared/constants/autoComplete";
import getFormattedPrice from "@shared/helpers/getFormattedPrice";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import CollaboratorNewMemberMessage from "./CollaboratorNewMemberMessage";

const mappingUsers =
  (prepared = false) =>
  ({ email, displayName, ...props }) => ({
    ...props,
    inputValue: email,
    label: displayName || email,
    prepared,
    hasValidEmail: true,
  });

function InviteCollaboratorDialog({ isOpen, cancel, answer, team, project }) {
  const { t } = useTranslation();
  const [reviewers, setReviewers] = useState([]);
  const [notifyEmail, setNotifyEmail] = useState(true);
  const [message, setMessage] = useState("");
  const [newTeamMembers, setNewTeamMembers] = useState([]);
  const [subscriptionHelper, setSubscriptionHelper] = useState({});
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [focusElement, setFocusElement] = useState("");

  const prepareSuggestions = useCallback(() => {
    return sortBy(
      team.members?.map((member) => member.user),
      "displayName"
    )
      .filter((member) => {
        return !some(project.collaborators, { _id: member._id });
      })
      .map(mappingUsers());
  }, [project.collaborators, team.members]);
  const [suggestions] = useState(prepareSuggestions());

  useEffect(() => {
    setSubscriptionHelper({
      doesBaseSubscriptionUserLimitReached:
        doesBaseSubscriptionUserLimitReached(
          team.subscription,
          team.members?.length,
          1
        ),
      canAddMoreUsersToSubscription: canAddMoreUsersToSubscription(
        team.subscription,
        team.members?.length,
        1
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearTheForm = () => {
    setReviewers([]);
    setMessage("");
    setIsConfirmationOpen(false);
    setNewTeamMembers([]);
  };

  const cancelDialog = useCallback(() => {
    healthMetrics.trackCancellation("workflow.invite-collaborators");
    clearTheForm();
    cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancel]);

  const confirmDialog = useCallback(
    (newSeat) => {
      const emails = reviewers.map((reviewer) => reviewer.inputValue);
      answer({
        emails,
        newTeamMembers,
        message,
        notifyEmail,
        newSeat,
      });
      clearTheForm();
    },
    [answer, message, notifyEmail, reviewers, newTeamMembers]
  );

  const handleNewTeamMembers = useCallback(
    (emails) => {
      const newTeamMembers = emails.filter((email) => {
        return !some(team.members, { user: { email: email.inputValue } });
      });
      setNewTeamMembers(newTeamMembers);
      setSubscriptionHelper({
        doesBaseSubscriptionUserLimitReached:
          doesBaseSubscriptionUserLimitReached(
            team.subscription,
            team.members?.length,
            newTeamMembers.length
          ),
        canAddMoreUsersToSubscription: canAddMoreUsersToSubscription(
          team.subscription,
          team.members?.length,
          newTeamMembers.length
        ),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [team.members, team.subscription]
  );

  const handleReviewerChange = (_, emails) => {
    setReviewers(emails);
    handleNewTeamMembers(emails);
  };
  const handleTextArea = ({ target }) => setMessage(target.value);

  const handleNotifyEmail = useCallback(() => {
    setNotifyEmail(!notifyEmail);
  }, [setNotifyEmail, notifyEmail]);

  const handleOnBlur = () => setFocusElement(null);
  const handleOnFocus = ({ target }) => setFocusElement(target.id);

  const validation = (email) => {
    if (some(project.collaborators, { email })) {
      return "COLLABORATORS.ADD.ERROR.DUPLICATE_COLLABORATORS";
    }

    if (
      !some(team.members, { user: { email } }) &&
      !team.permissions.canManageMembers
    ) {
      return "COLLABORATORS.ADD.ERROR.NO_PERMISSIONS";
    }

    return "";
  };

  const addSeatAndCollaborator = useCallback(
    () => confirmDialog(true),
    [confirmDialog]
  );

  const getAdditionalSeatCount = useCallback(() => {
    if (team.subscription.additionalPrice) {
      return (
        newTeamMembers.length +
        team.members.length -
        team.subscriptionLimits.includedUsers
      );
    }
    return newTeamMembers.length;
  }, [newTeamMembers, team]);

  const getUpsellSeatInfo = useCallback(() => {
    if (team.subscription.additionalPrice) {
      return {
        interval: team.subscription.additionalPrice.interval,
        price: getFormattedPrice(
          team.subscription.additionalPrice,
          getAdditionalSeatCount()
        ),
      };
    }

    return {
      interval: team.subscription.basePrice.interval,
      price: getFormattedPrice(
        team.subscription.basePrice,
        newTeamMembers.length
      ),
    };
  }, [
    getAdditionalSeatCount,
    newTeamMembers.length,
    team.subscription.additionalPrice,
    team.subscription.basePrice,
  ]);

  const inviteButtonHandler = useCallback(
    () => {
      if (
        newTeamMembers.length > 0 &&
        subscriptionHelper.doesBaseSubscriptionUserLimitReached
      ) {
        setIsConfirmationOpen(true);
        analytics.track(
          analytics.ACTION.OPENED,
          analytics.CATEGORY.CONFIRM_SEAT_UPGRADE_POPOVER,
          {
            label: analytics.LABEL.INVITE_COLLABORATOR_DIALOG,
          }
        );
      } else {
        confirmDialog(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      confirmDialog,
      newTeamMembers.length,
      subscriptionHelper.doesBaseSubscriptionUserLimitReached,
    ]
  );

  const isConfirmButtonDisabled = useCallback(() => {
    if (
      reviewers.length === 0 ||
      reviewers.length > AUTOCOMPLETE.MAX_INVITE_COUNT
    ) {
      return true;
    }

    if (newTeamMembers.length) {
      if (
        subscriptionHelper.doesBaseSubscriptionUserLimitReached &&
        !team.permissions.canManageBilling
      ) {
        return true;
      }

      if (
        subscriptionHelper.doesBaseSubscriptionUserLimitReached &&
        !subscriptionHelper.canAddMoreUsersToSubscription
      ) {
        return true;
      }
    }

    return false;
  }, [
    newTeamMembers.length,
    reviewers.length,
    subscriptionHelper.canAddMoreUsersToSubscription,
    subscriptionHelper.doesBaseSubscriptionUserLimitReached,
    team.permissions.canManageBilling,
  ]);

  const actions = (
    <>
      <FormControlLabel
        label={t("FILEMEMBER.ADD.NOTIFY.TITLE")}
        control={
          <Checkbox
            checked={notifyEmail}
            onChange={handleNotifyEmail}
            inputProps={{
              "data-testid": "notify-via-email",
            }}
          />
        }
      />
      <Button
        onClick={inviteButtonHandler}
        color="primary"
        data-testid="add-reviewer-dialog-invite-button"
        variant="contained"
        disabled={isConfirmButtonDisabled()}
      >
        {t("COLLABORATORS.ADD.CONFIRM")}
      </Button>
      {isConfirmationOpen && getAdditionalSeatCount() > 0 && (
        <SeatUpsellPopup
          onSubmit={addSeatAndCollaborator}
          text={t(
            `PROJECT-COLLABORATOR.DIALOG.SEAT-LIMIT-REACHED-${getUpsellSeatInfo().interval.toUpperCase()}${
              getAdditionalSeatCount() === 1 ? "-ONE" : ""
            }.CONFIRM`,
            {
              seatNumber: getAdditionalSeatCount(),
              price: getUpsellSeatInfo().price,
            }
          )}
          isMultiple={getAdditionalSeatCount() > 1}
          isDisabled={reviewers.length > AUTOCOMPLETE.MAX_INVITE_COUNT}
        />
      )}
    </>
  );

  return (
    <Dialog
      data-testid="add-collaborator-dialog"
      open={isOpen}
      cancel={cancelDialog}
      actions={actions}
      helpLink={{
        link: t("URLS.FAQ_DIFFERENCE_REVIEWER_TEAM"),
        text: t("COLLABORATORS.ADD.HELP"),
      }}
      title={t("COLLABORATORS.ADD.HEADLINE")}
      hideBackdrop={false}
      disableBackdropClick
      contentProps={{ sx: { maxWidth: 530, pt: 0 } }}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        data-testid="new-invite-collaborator"
        sx={{ px: { xs: 0, sm: 4 } }}
      >
        <Text variant="body2" mb={7}>
          {t("COLLABORATORS.ADD.DESCRIPTION-1")}
        </Text>
        <AddReviewerDialogAutocomplete
          id="add-reviewer-dialog-autocomplete"
          data-testid="add-reviewer-dialog-autocomplete"
          emptyText="COLLABORATORS.ADD.ERROR.EMPTY"
          preparedReviewers={suggestions}
          validation={validation}
          reviewers={reviewers}
          onChange={handleReviewerChange}
          inputLabel="COLLABORATORS.ADD.ENTER_COLLABORATOR"
          inputIcon={MailOutlined}
          maxInviteCount={AUTOCOMPLETE.MAX_INVITE_COUNT}
        />
        {newTeamMembers.length > 0 && (
          <CollaboratorNewMemberMessage newTeamMembers={newTeamMembers} />
        )}
        <Box pb={1} display="flex" alignItems="flex-end" width={1}>
          <SmsOutlined
            sx={{
              pr: 1,
              mb: 0.5,
              color:
                focusElement === "add-reviewer-dialog-message"
                  ? "primary.main"
                  : "grey.300",
            }}
          />
          <TextField
            id="add-reviewer-dialog-message"
            inputProps={{
              "data-testid": "add-reviewer-dialog-message",
            }}
            multiline
            maxRows={4}
            disabled={!notifyEmail}
            aria-label={t("FILEMEMBER.ADD.MESSAGE")}
            label={t("FILEMEMBER.ADD.MESSAGE")}
            onChange={handleTextArea}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            sx={{ width: 1 }}
          />
        </Box>
        {!notifyEmail && message.length > 0 && (
          <Box
            ml={4.375}
            textAlign="left"
            data-testid="invite-collaborator-dialog-personal-message-info"
          >
            <Text color="accent.main" variant="subtitle1">
              {t("COLLABORATOR.ADD.PERSONAL-MESSAGE.NOT-SENT")}
            </Text>
          </Box>
        )}
        {team.permissions.canManageMembers && newTeamMembers.length > 0 && (
          <TeamMemberDialogWarning
            canAddMoreUsersToSubscription={
              subscriptionHelper.canAddMoreUsersToSubscription
            }
            doesBaseSubscriptionUserLimitReached={
              subscriptionHelper.doesBaseSubscriptionUserLimitReached
            }
            canManageBilling={team.permissions.canManageBilling}
            teamId={team._id}
          />
        )}
      </Box>
    </Dialog>
  );
}

InviteCollaboratorDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  team: teamProp.isRequired,
  project: teamProp.isRequired,
};
export default InviteCollaboratorDialog;
