import PropTypes from "prop-types";
import { useState } from "react";

import OtpDialog from "@supporting/components/ReviewersAuthenticationFlow/OTPContent";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

const SignupVerifyOTPContent = ({ email, onSubmit, setOtpCode }) => {
  const [errorText, setErrorText] = useState("");
  const handleResendClick = async () => {
    try {
      const authenticationState = {
        email,
        reason: "review-files",
      };
      await authenticationService.sendOTPForSignup(email, authenticationState);
      toastService.sendToast({
        title: "SIGNUP.OTP_SENT.TITLE",
        body: "SIGNUP.OTP_SENT.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    } catch (error) {
      /* istanbul ignore next */
      errorHandlerService.handleError(error);
    }
  };

  const submitOTP = async (code) => {
    try {
      await authenticationService.verifyOTPForSignup(email, code);
      setOtpCode(code);
      onSubmit("SHOW_CREATE_PASSWORD");
      analytics.track(
        analytics.ACTION.CONFIRMED,
        analytics.CATEGORY.EMAIL_VERIFICATION,
        {
          source: "reviewer-login",
        }
      );
      return;
    } catch (error) {
      /* istanbul ignore next */
      setErrorText("AUTHENTICATION.DIALOGS.OTP.INCORRECT_CODE");
    }
  };
  return (
    <OtpDialog
      email={email}
      onSubmit={submitOTP}
      errorText={errorText}
      setErrorText={setErrorText}
      handleResendClick={handleResendClick}
    />
  );
};
SignupVerifyOTPContent.propTypes = {
  email: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setOtpCode: PropTypes.func.isRequired,
};
export default SignupVerifyOTPContent;
