import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import StepSelection from "@workflow/components/UploadFileButton/step-selection/StepSelection";
import keyBy from "lodash/keyBy";

import { Box, Text } from "@shared/UIKit";

import { fileProp } from "@shared/props/file";
import { stepProps } from "@shared/props/step";

import getPresetFiles from "./presets";
import UploadPremiereSequenceButtons from "./UploadPremiereSequenceButtons";

const useClasses = makeStyles({
  uploadSequenceIcon: {
    width: 28,
    height: 19,
  },
  selectBox: {
    width: "100%",
  },
  presetLabel: {
    marginTop: -8,
    marginLeft: 12,
  },
});

function UploadPremiereSequence({
  closePanel,
  steps,
  sectionId,
  handleChange,
  selectedSteps,
  selectedFile,
}) {
  const classes = useClasses();
  const theme = useTheme();
  const { t } = useTranslation();
  const [uploadSequenceInput, setUploadSequenceInput] = useState({});

  const presets = Object.keys(getPresetFiles());

  const [sequences, setSequences] = useState([]);
  const [sequenceData, setSequenceData] = useState({
    preset: presets[0],
    sequenceId: "",
  });

  const handleInputChange = useCallback(
    (event) => {
      const { value, name } = event.target;

      setSequenceData({ ...sequenceData, [name]: value });
    },
    [sequenceData]
  );

  useEffect(() => {
    if (!sequenceData.sequenceId && sequences.length > 0) {
      handleInputChange({
        target: { name: "sequenceId", value: sequences[0].sequenceID },
      });
    }
  }, [sequences, sequenceData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const mappedSequences = keyBy(sequences, (sequence) => sequence.sequenceID);

    setUploadSequenceInput({
      preset: sequenceData.preset,
      ...(sequenceData.sequenceId
        ? {
            videoName: mappedSequences[sequenceData.sequenceId].name,
            sequenceId: sequenceData.sequenceId,
          }
        : {}),
    });
  }, [sequenceData, sequences]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleAdobePremiereProEvents = (event) => {
      if (event.origin === window.location.origin || !event?.data.data) {
        return;
      }

      switch (event.data.eventType) {
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_UPLOAD_SEQUENCE_LIST: {
          const pluginSequences = event.data.data;
          setSequences(pluginSequences);
          break;
        }
        case ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEQUENCE_UPLOAD_TO_S3_START: {
          closePanel();
          break;
        }
      }
    };

    window.addEventListener("message", handleAdobePremiereProEvents);

    window.postMessage(
      {
        eventType: ADOBE_PLUGIN_EVENTS.FILESTAGE_PPRO_GET_SEQUENCES,
        data: {},
      },
      "*"
    );

    return () => {
      window.removeEventListener("message", handleAdobePremiereProEvents);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        data-testid="upload-premiere-sequence-box"
        minHeight={140}
        bgcolor={theme.color.white}
        pr={1.5}
        pl={1.5}
        pt={2.5}
        pb={2.5}
      >
        <Box display="flex" alignItems="center" mb={1}>
          <Text variant="h6" fontWeight={theme.typography.fontWeight.semiBold}>
            {t("FILE.MENU.UPLOAD_SEQUENCE.TITLE")}
          </Text>
        </Box>
        <Box pb={1} pt={1} display="flex" flexDirection="column" gap={2}>
          <Box
            display="flex"
            justify="center"
            paddingTop={0.83}
            paddingBottom={0.83}
            alignItems="center"
            textAlign="left"
          >
            <Box mt={-1.875} maxWidth="25%" flexBasis="25%" textAlign="left">
              <Text variant="subtitle1">
                {t("FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.VIDEO_NAME")}
              </Text>
            </Box>
            <Box flex={1} textAlign="left">
              <FormControl fullWidth size="small">
                <InputLabel
                  id="preset-selector-label"
                  className={classes.presetLabel}
                >
                  {t(
                    "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.SELECT_VIDEO_NAME"
                  )}
                </InputLabel>
                <Select
                  labelId="preset-selector-label"
                  id="video-name"
                  value={sequenceData.sequenceId}
                  label={t(
                    "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.SELECT_VIDEO_NAME"
                  )}
                  name="sequenceId"
                  variant="outlined"
                  onChange={handleInputChange}
                  className={classes.selectBox}
                  inputProps={{
                    "data-testid": "upload-premier-sequence-input-video-name",
                  }}
                >
                  {sequences.map(({ name: sequenceName, sequenceID }) => (
                    <MenuItem
                      key={sequenceID}
                      value={sequenceID}
                      data-testid={`upload-sequence-video-name-${sequenceID}`}
                    >
                      {sequenceName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            display="flex"
            justify="center"
            paddingTop={0.83}
            paddingBottom={0.83}
            alignItems="center"
          >
            <Box maxWidth="25%" flexBasis="25%" textAlign="left">
              <Text variant="subtitle1">
                {t(
                  "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.RENDER_QUALITY"
                )}
              </Text>
            </Box>
            <Box flex={1} textAlign="left">
              <FormControl fullWidth size="small">
                <InputLabel
                  id="preset-selector-label"
                  className={classes.presetLabel}
                >
                  {t("FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.PRESET")}
                </InputLabel>
                <Select
                  labelId="preset-selector-label"
                  id="preset-selector"
                  value={sequenceData.preset}
                  label={t(
                    "FILE.UPLOAD_PANEL.UPLOAD_SEQUENCE_INPUT_DIALOG.PRESET"
                  )}
                  name="preset"
                  variant="outlined"
                  onChange={handleInputChange}
                  className={classes.selectBox}
                  inputProps={{
                    "data-testid": "upload-premier-sequence-input-preset",
                  }}
                >
                  {presets.map((sequencePreset, index) => (
                    <MenuItem
                      key={sequencePreset}
                      value={sequencePreset}
                      data-testid={`upload-sequence-preset-${index}`}
                    >
                      {sequencePreset}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Box>

      {steps.length > 0 && (
        <StepSelection
          steps={steps}
          handleChange={handleChange}
          selectedSteps={selectedSteps}
          isSelectedFileAvailable={Boolean(selectedFile)}
        />
      )}

      <UploadPremiereSequenceButtons
        closePanel={closePanel}
        selectedSteps={selectedSteps}
        sectionId={sectionId}
        selectedFile={selectedFile}
        uploadSequenceInput={uploadSequenceInput}
      />
    </>
  );
}

UploadPremiereSequence.propTypes = {
  closePanel: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  selectedSteps: PropTypes.objectOf(PropTypes.bool).isRequired,
  steps: PropTypes.arrayOf(stepProps),
  sectionId: PropTypes.string,
  selectedFile: fileProp,
};

UploadPremiereSequence.defaultProps = {
  steps: [],
  sectionId: null,
  selectedFile: null,
};

export default UploadPremiereSequence;
