/**
 * Converter helper function
 *
 * @description This function is used to convert a string of unit size to bytes.
 * @param {number} size - [Required] The number to convert.
 * @param {string} unit - [Required] The unit to convert to.
 * Can be `bytes`, `kB`, `MB`, `GB`, `TB`, `PB`.
 * @returns {number} - Returns the number converted to the bytes.
 */
const toBytes = (size, unit) => {
  const number = ["bytes", "kB", "MB", "GB", "TB", "PB"].indexOf(unit);
  return size * Math.pow(1000, number);
};

export default toBytes;
