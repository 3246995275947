/* istanbul ignore file */
/* eslint-disable max-lines */
/* jscpd:ignore-start */
import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { SvgIcon } from "@mui/material";

import FsCheckboxOutlineBlank from "@assets/img/icons/CheckboxOutlineBlank.svg";
import FsCheckboxOutlineChecked from "@assets/img/icons/CheckboxOutlineChecked.svg";
import FsCheckboxOutlineIndeterminate from "@assets/img/icons/CheckboxOutlineIndeterminate.svg";

import animations from "./animations";
import colors from "./colors";
import { Button, SPACING, Typography, ListItem } from "./componentsv2";
import shadows from "./shadows";
import typography from "./typography";

const checkboxIcon = React.createElement(SvgIcon, {
  component: FsCheckboxOutlineBlank,
  inheritViewBox: true,
  fontSize: "inherit",
});

const checkboxCheckedIcon = React.createElement(SvgIcon, {
  component: FsCheckboxOutlineChecked,
  inheritViewBox: true,
  fontSize: "inherit",
});

const checkboxIndeterminateIcon = React.createElement(SvgIcon, {
  component: FsCheckboxOutlineIndeterminate,
  inheritViewBox: true,
  fontSize: "inherit",
});

const theme = {
  spacing: SPACING,
  palette: {
    primary: {
      ...colors.gray,
      lighter: colors.gray[50],
      light: colors.gray[400],
      main: colors.gray[700],
      dark: colors.gray[800],
      darker: colors.gray[900],
      contrastText: colors.white,
    },
    secondary: {
      light: colors.secondary[200],
      main: colors.secondary[500],
      dark: colors.secondary[800],
      contrastText: colors.white,
    },
    success: {
      light: colors.green[50],
      main: colors.green[500],
      dark: colors.green[600],
      darker: colors.green[700],
    },
    error: {
      light: colors.red[50],
      main: colors.red[500],
      dark: colors.red[600],
      contrastText: colors.white,
    },
    warning: {
      light: colors.orange[400],
      main: colors.orange[500],
      dark: colors.orange[600],
      contrastText: colors.white,
    },
    primaryV2: {
      light: colors.gray[200],
      main: colors.gray[700],
      dark: colors.gray[900],
      contrastText: colors.white,
    },
    "default-light": {
      light: colors.gray[200],
      main: colors.gray[500],
      dark: colors.gray[600],
      contrastText: colors.white,
    },
    text: {
      primary: colors.gray[800],
      secondary: colors.gray[600],
    },
    accent: {
      light: colors.purple[100],
      main: colors.purple[500],
      dark: colors.purple[700],
      contrastText: colors.white,
    },
    light: {
      light: colors.gray[50],
      main: colors.white,
      dark: colors.gray[100],
      contrastText: colors.gray[800],
    },
    grey: { ...colors.gray },
    pending: {
      lighter: colors.gray[50],
      light: colors.gray[100],
      main: colors.gray[200],
      dark: colors.gray[600],
      contrastText: colors.white,
    },
    inReview: {
      lighter: colors.gray[50],
      light: colors.gray[100],
      main: colors.gray[600],
      dark: colors.gray[900],
      contrastText: colors.white,
    },
    approved: {
      lighter: colors.green[100],
      light: colors.green[100],
      main: colors.green[500],
      dark: colors.green[600],
      contrastText: colors.white,
    },
    needsChanges: {
      lighter: colors.secondary[50],
      light: colors.secondary[100],
      main: colors.secondary[500],
      dark: colors.secondary[800],
      contrastText: colors.white,
    },
    rejected: {
      lighter: colors.red[50],
      light: colors.red[50],
      main: colors.red[500],
      dark: colors.red[600],
      contrastText: colors.white,
    },
    upgrade: {
      light: colors.blue[50],
      main: colors.blue[500],
      dark: colors.blue[700],
      contrastText: colors.white,
    },
    everyone: {
      light: colors.green[50],
      main: colors.green[200],
      dark: colors.green[500],
      contrastText: colors.gray[600],
    },
    teamOnly: {
      light: colors.gold[100],
      main: colors.gold[400],
      dark: colors.gold[500],
      contrastText: colors.gray[600],
    },
    action: {
      disabled: colors.gray[300],
      active: colors.gray[500],
    },
  },
  typography: {
    fontFamily: typography.fontFamily,
    htmlFontSize: typography.htmlFontSize,
    fontWeightLight: typography.fontWeightLight,
    fontWeightRegular: typography.fontWeightRegular,
    fontWeightMedium: typography.fontWeightMedium,
    fontWeightBold: typography.fontWeightBold,
    fontWeight: {
      regular: typography.fontWeight.regular,
      semiBold: typography.fontWeight.semiBold,
      medium: typography.fontWeight.semiBold,
      bold: typography.fontWeight.bold,
    },
    subtitle2: {
      fontSize: typography.fontSize_1_2,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    subtitle1: {
      fontSize: typography.fontSize_1_3,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    body2: {
      fontSize: typography.fontSize_1_5,
      lineHeight: typography.lineHeight_1_5,
      fontWeight: typography.fontWeight.regular,
    },
    body1: {
      fontSize: typography.fontSize_1_6,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h6: {
      fontSize: typography.fontSize_1_7,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h5: {
      fontSize: typography.fontSize_2_0,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h4: {
      fontSize: typography.fontSize_2_6,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h3: {
      fontSize: typography.fontSize_2_9,
      lineHeight: typography.lineHeight_1_3,
      letterSpacing: 0.1,
      fontWeight: typography.fontWeight.regular,
    },
    h2: {
      fontSize: typography.fontSize_3_1,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h1: {
      fontSize: typography.fontSize_3_8,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    text5xl: {
      fontSize: typography.fontSize5xl,
      lineHeight: typography.lineHeight5xl,
      fontWeight: typography.fontWeight.regular,
    },
    text4xl: {
      fontSize: typography.fontSize4xl,
      lineHeight: typography.lineHeight4xl,
      fontWeight: typography.fontWeight.regular,
    },
    text3xl: {
      fontSize: typography.fontSize3xl,
      lineHeight: typography.lineHeight3xl,
      fontWeight: typography.fontWeight.regular,
    },
    text2xl: {
      fontSize: typography.fontSize2xl,
      lineHeight: typography.lineHeight2xl,
      fontWeight: typography.fontWeight.regular,
    },
    textXl: {
      fontSize: typography.fontSizeXl,
      lineHeight: typography.lineHeightXl,
      fontWeight: typography.fontWeight.regular,
    },
    textLg: {
      fontSize: typography.fontSizeLg,
      lineHeight: typography.lineHeightLg,
      fontWeight: typography.fontWeight.regular,
    },
    textMd: {
      fontSize: typography.fontSizeMd,
      lineHeight: typography.lineHeightMd,
      fontWeight: typography.fontWeight.regular,
    },
    textSm: {
      fontSize: typography.fontSizeSm,
      lineHeight: typography.lineHeightSm,
      fontWeight: typography.fontWeight.regular,
    },
    textXs: {
      fontSize: typography.fontSizeXs,
      lineHeight: typography.lineHeightXs,
      fontWeight: typography.fontWeight.regular,
    },
  },
  shadow: shadows,
  color: colors,
  animation: animations,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 8,
  },
};

const components = {
  MuiCssBaseline: {
    styleOverrides: (themeParam) => `
    *, *::before, *::after {
      box-sizing: revert;
    }
    html {
      line-height: ${themeParam.typography.lineHeight_2_4};
    }
    body {
      line-height: unset;
      background-color: ${themeParam.color.gray[250]};
    }
  `,
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        color: colors.gray[800],
        "& p": {
          fontWeight: typography.fontWeight.regular,
        },
      },
    },
  },
  MuiTypography: Typography,
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        "& .MuiButton-sizeSmall.MuiButtonGroup-lastButton": {
          padding: 4,
        },
      },
      grouped: ({ ownerState }) => ({
        ...(ownerState.size === "extraSmall" && {
          fontSize: typography.fontSize_1_2,
          lineHeight: typography.lineHeight_1_3,
        }),
      }),
    },
  },
  MuiButton: Button,
  MuiLoadingButton: Button,
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        "&:hover": {
          backgroundColor: "unset",
          ...(!["inherit", "default"].includes(ownerState.color) && {
            color: theme.palette[ownerState.color].dark,
          }),
          ...("default" === ownerState.color && {
            color: "var(--mui-palette-grey-700)",
          }),
        },
      }),
    },
    variants: [
      {
        props: { size: "extraLarge" },
        style: ({ theme }) => ({
          fontSize: theme.vars.typography.text3xl.fontSize,
        }),
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        fontSize: typography.fontSize_1_3,
        fontWeight: typography.fontWeight.semiBold,
        lineHeight: typography.lineHeight_1_3,
        color: colors.white,
        backgroundColor: theme.palette.grey[900],
        "&[data-tooltip-variant='white']": {
          fontWeight: typography.fontWeight.regular,
          padding: theme.spacing(1),
          textAlign: "center",
          boxShadow: theme.shadows[1],
          color: theme.color.gray[800],
          backgroundColor: theme.palette.common.white,
          "& .MuiTooltip-arrow": {
            color: theme.palette.common.white,
            "&::before": {
              boxShadow: theme.shadows[1],
            },
          },
        },
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.grey[900],
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: 8,
        "& .MuiMenuItem-root, & .MuiListItem-root:not(:has(> .MuiListItemButton-root)), & .MuiListItemButton-root":
          {
            ...ListItem,
          },
      },
    },
  },
  MuiList: {
    defaultProps: {
      dense: true,
    },
    styleOverrides: {
      root: {
        paddingLeft: SPACING,
        paddingRight: SPACING,
        "& .MuiMenuItem-root, & .MuiListItem-root, & .MuiListItemButton-root": {
          ...ListItem,
        },
        "& > .MuiMenuItem-root:first-of-type, & > .MuiListItemButton-root:first-of-type":
          {
            marginBottom: "0.25rem",
          },
        "& > .MuiMenuItem-root:last-of-type, & > .MuiListItemButton-root:last-of-type":
          {
            marginTop: "0.25rem",
          },
        "& > .MuiMenuItem-root:not(:first-of-type):not(:last-of-type), & > .MuiListItemButton-root:not(:first-of-type):not(:last-of-type)":
          {
            marginTop: "0.25rem",
            marginBottom: "0.25rem",
          },
      },
      dense: {
        maxHeight: 304,
        overflow: "auto",
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      formControl: {
        "label + &": {
          marginTop: 20,
        },
      },
      underline: {
        "&:before": {
          borderBottom: "1px solid rgba(0,0,0,0.12)",
        },
        "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
          borderBottom: "1px solid rgba(0,0,0,0.12)",
        },
      },
    },
  },
  MuiMenuItem: {
    variants: [
      {
        props: { selected: true, variant: "text" },
        style: ({ theme }) => ({
          "&.MuiMenuItem-root.Mui-selected": {
            backgroundColor: "transparent",
          },
          color: theme.palette.primary.main,
          ".MuiListItemIcon-root": {
            color: theme.palette.primary.main,
          },
          ".MuiListItemText-secondary": {
            color: theme.palette.primary.main,
          },
        }),
      },
    ],
  },
  MuiListItem: {
    variants: [
      {
        props: { selected: true, variant: "text" },
        style: ({ theme }) => ({
          "&.MuiMenuItem-root.Mui-selected": {
            backgroundColor: "transparent",
          },
          color: theme.palette.primary.main,
          ".MuiListItemIcon-root": {
            color: theme.palette.primary.main,
          },
          ".MuiListItemText-secondary": {
            color: theme.palette.primary.main,
          },
        }),
      },
    ],
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: typography.lineHeight_1_3,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
      color: "success",
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        let color = theme.palette[ownerState.color]?.main ?? ownerState.color;

        if (ownerState.color === "white") {
          color = "white";
        } else if (ownerState.color === "primary") {
          color = theme.palette.primary.dark;
        }
        return {
          cursor: "pointer",
          color,
          "&:hover": {
            color:
              ownerState.color === "white"
                ? "white"
                : theme.palette[ownerState.color]?.dark ?? ownerState.color,
          },
        };
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "outlined",
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white,
      },
      input: {
        padding: "0.5625rem 0.75rem",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      outlined: {
        "&:not(.MuiInputLabel-shrink)": {
          transform: "translate(14px, 8px) scale(1)",
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        padding: "2px 9px",
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: "outlined",
    },
    styleOverrides: {
      select: {
        "&.MuiInputBase-inputSizeSmall": {
          fontSize: typography.fontSize_1_3,
          paddingBottom: 1,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: { root: { backgroundImage: "unset" } },
  },
  MuiTabs: {
    defaultProps: {
      size: "medium",
    },
    styleOverrides: {
      root: {
        '&[data-size="small"] .MuiButtonBase-root.MuiTab-root': {
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          minHeight: "unset",
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "inherit",
        fontWeight: typography.fontWeight.regular,
        color: colors.gray[600],
        padding: `${SPACING * 1.5}px ${SPACING * 1.5}px`,
        "&.Mui-selected": {
          fontWeight: typography.fontWeight.semiBold,
          color: colors.gray[700],
        },
        "&:hover": {
          color: colors.gray[700],
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        border: "none",
        "&.Mui-expanded": {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid var(--mui-palette-grey-200)`,
        paddingRight: SPACING * 4,
        paddingLeft: SPACING * 4,
      },
      title: {
        fontWeight: typography.fontWeight.semiBold,
        fontSize: typography.fontSizeXl,
      },
      action: {
        margin: "unset",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: SPACING * 4.375,
        paddingRight: SPACING * 4,
        paddingLeft: SPACING * 4,
      },
    },
  },
  MuiChip: {
    defaultProps: {
      variant: "outlined",
      deleteIcon: React.createElement(CloseIcon, {
        fontSize: "inherit",
      }),
    },
    styleOverrides: {
      label: {
        fontWeight: typography.fontWeight.semiBold,
      },
    },
    variants: [
      {
        props: { variant: "filled-light" },
        style: ({ ownerState, theme }) => ({
          color: theme.palette[ownerState.color].main,
          backgroundColor: theme.palette[ownerState.color].light,
        }),
      },
      {
        props: { size: "small" },
        style: ({ theme }) => ({
          fontSize: theme.vars.typography.fontSizeXs,
        }),
      },
      {
        props: { color: "default" },
        style: ({ theme }) => ({
          "& .MuiChip-deleteIcon": {
            color: theme.vars.palette.primary[500],
            backgroundColor: theme.vars.palette.primary[100],
            borderRadius: "100%",
            fontSize: "1rem",
          },
        }),
      },
    ],
  },
  MuiDialog: {
    defaultProps: {
      hideBackdrop: true,
      fullWidth: true,
      maxWidth: "sm",
    },
    styleOverrides: {
      paper: ({ theme }) => ({
        textAlign: "center",
        paddingRight: SPACING,
        paddingLeft: SPACING,
        paddingTop: SPACING * 2,
        paddingBottom: SPACING * 2,
        [theme.breakpoints.up("sm")]: {
          paddingRight: SPACING * 3,
          paddingLeft: SPACING * 3,
          paddingTop: SPACING * 4,
          paddingBottom: SPACING * 4,
        },
        "&.noDefaultBackground": {
          backgroundImage: "none",
        },
        backgroundImage: `url("/assets/img/images/fs-icons--gradient.png")`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }),
      paperFullWidth: ({ ownerState }) => {
        return (
          !ownerState.maxWidth && {
            maxWidth: "calc(100% - 16px)",
            width: "calc(100% - 16px)",
            marginRight: 8,
            marginLeft: 8,
          }
        );
      },
      paperFullScreen: {
        padding: 0,
        backgroundColor: colors.gray[100],
        "&.dialogDefaultBackground": {
          backgroundRepeat: "repeat",
        },
      },
      paperWidthXm: {
        maxWidth: 600,
      },
      paperWidthSm: {
        maxWidth: 700,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        flexDirection: "column",
        alignItems: "stretch",
        gap: SPACING,
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
          justifyContent: "space-evenly",
          paddingRight: SPACING * 3,
          paddingLeft: SPACING * 3,
          "& > *": {
            flex: "1 1 auto",
            margin: 0,
          },
        },
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        "&.react-icons": {
          fill: "none",
        },
      },
    },
    variants: [
      {
        props: { fontSize: "extraLarge" },
        style: ({ theme }) => ({
          fontSize: theme.vars.typography.text5xl.fontSize,
        }),
      },
      {
        props: { fontSize: "extraSmall" },
        style: {
          fontSize: "1rem",
        },
      },
    ],
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        "& .MuiAlertTitle-root:only-child": {
          marginBottom: 0,
        },
      },
      standardError: {
        backgroundColor: colors.red[100],
      },
      standardSuccess: {
        color: colors.green[500],
        backgroundColor: colors.green[50],
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        "&:has(> .MuiSwitch-root)": {
          marginLeft: 0,
          "& .MuiSwitch-root": {
            marginRight: SPACING * 1.5,
          },
          "&:has(> .MuiSwitch-sizeSmall) + .MuiFormHelperText-root": {
            marginLeft: SPACING * 3.75,
            fontSize: typography.fontSizeXs,
          },
          "&:has(> .MuiSwitch-sizeMedium) + .MuiFormHelperText-root": {
            marginLeft: SPACING * 7,
            fontSize: typography.fontSizeSm,
          },
        },
      },
      label: {
        lineHeight: 1.25,
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: 44,
        height: 24,
        padding: 0,
        overflow: "visible",
        "&.MuiSwitch-sizeSmall": {
          width: 36,
          height: 20,
          "& .MuiSwitch-thumb": {
            width: 16,
            height: 16,
          },
          "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(15px)",
          },
        },
        "& .MuiSwitch-switchBase": {
          padding: 0,
          margin: 2,
          transitionDuration: "300ms",
          transform: "translateX(1px)",
          "&.Mui-checked": {
            transform: "translateX(19px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.grey[700],
              opacity: 1,
              border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {
              opacity: 0.5,
            },
            "& .MuiSwitch-thumb::before": {
              content: "''",
              position: "absolute",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="%23000" viewBox="0 0 24 24" ><path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" /></svg>')`,
            },
          },
          "&.Mui-focusVisible .MuiSwitch-thumb": {
            border: "6px solid #fff",
          },
          "&.Mui-disabled .MuiSwitch-thumb": {
            color: theme.palette.grey[100],
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.7,
          },
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: 20,
          height: 20,
        },
        "& .MuiSwitch-track": {
          borderRadius: 12,
          backgroundColor: theme.palette.grey[200],
          opacity: 1,
          transition: theme.transitions.create(["background-color"], {
            duration: 500,
          }),
        },
        "&:has(<.MuiFormControlLabel-root)": {
          marginRight: 16,
        },
        "&:hover": {
          "&.Mui-checked": {
            "& + .MuiSwitch-track": {
              backgroundColor: theme.palette.grey[800],
            },
          },
          "& .MuiSwitch-track": {
            backgroundColor: theme.palette.grey[300],
          },
        },
        "&:focus": {
          "& .MuiSwitch-track": {
            boxShadow: `0px 0px 0px 4px ${theme.palette.grey[100]}`,
          },
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        borderRadius: 6,
        fontSize: "1.25rem",
        padding: 0,
        margin: 9,
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },
    defaultProps: {
      icon: checkboxIcon,
      checkedIcon: checkboxCheckedIcon,
      indeterminateIcon: checkboxIndeterminateIcon,
    },
  },
  MuiAvatar: {
    styleOverrides: {
      fallback: {
        fontSize: typography.fontSize_1_3,
      },
      colorDefault: {
        color: colors.gray[700],
        fontWeight: typography.fontWeight.semiBold,
      },
      root: {
        backgroundColor: colors.gray[100],
        width: 32,
        height: 32,
        fontSize: typography.fontSize_1_3,
      },
    },
  },
};
/* jscpd:ignore-end */

function shouldSkipGeneratingVar(keys) {
  return (
    Boolean(keys[0].match(/(cssVarPrefix|mixins|direction|transitions)/)) ||
    Boolean(keys[0].match(/sxConfig$/)) || // ends with sxConfig
    (keys[0] === "palette" &&
      Boolean(keys[1]?.match(/(mode|contrastThreshold|tonalOffset)/)))
  );
}

export default { ...theme, components, shouldSkipGeneratingVar };
