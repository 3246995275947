/* eslint-disable complexity */
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import { useTheme } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import userService from "@supporting/services/userService";
import CollapseButton from "@workflow/components/CollapseButton/CollapseButton";

import { Box, Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import FSTGTypography from "@shared/theme/typography";

import { useSidebarState } from "../../sidebarState";
import ProjectListItem from "./ProjectListItem/ProjectListItem";

const FavoriteProjectSection = () => {
  const { state } = useSidebarState();
  const theme = useTheme();
  const user = useActiveUser();
  const currentTeam = useSelectedTeam();
  const showProjects = !user.settings?.collapsedFavoriteProjects;
  const [isProjectRoute, setIsProjectRoute] = useState(false);
  const location = useLocation();

  const currentTeamProjects = state.sortedFavoriteProjects.filter(
    (project) => project.teamId === currentTeam._id
  );

  const externalProjects = state.sortedFavoriteProjects.filter(
    (project) => project.isExternal
  );
  const activeCurrentTeamProjects = currentTeamProjects.filter(
    (project) => !project.isArchived
  );
  const archivedCurrentTeamProjects = currentTeamProjects.filter(
    (project) => state.areArchivedProjectsShown && project.isArchived
  );

  const hideEmptyState =
    activeCurrentTeamProjects.length +
      externalProjects.length +
      archivedCurrentTeamProjects.length >
    0;

  const favoriteProjectIsSelected = state.sortedFavoriteProjects.find(
    (project) => project.id === state.selectedProject?.id
  );

  useEffect(() => {
    const isCurrentRouteProject = location.pathname.includes(
      APP_ROUTES.DASHBOARD.path
    );
    setIsProjectRoute(isCurrentRouteProject);
  }, [location.pathname]);

  const onCollapse = async () => {
    await userService.update({
      settings: {
        collapsedFavoriteProjects: !user.settings?.collapsedFavoriteProjects,
      },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      pb={1.5}
      data-testid="favorite-project-list-scroll-container"
      borderBottom={`1px solid ${theme.color.gray[200]}`}
    >
      <Box display="flex">
        {hideEmptyState && (
          <CollapseButton
            isCollapsed={user.settings?.collapsedFavoriteProjects}
            onCollapse={onCollapse}
            textCollapsed="FOLDERS.COLLAPSE.TOOLTIP_SHOW"
            textExpanded="FOLDERS.EXPAND.TOOLTIP_HIDE"
          />
        )}

        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minWidth={0}
          title="Star Projects"
          data-testid="favorite-folder-name"
        >
          <Text
            translate="SIDEBAR.FAVORITE_PROJECTS.TITLE"
            color={theme.color.gray[500]}
            sx={!hideEmptyState ? { pl: 3.25, pb: 1 } : {}}
          />
        </Box>
      </Box>
      {!hideEmptyState && (
        <Box
          data-testid="favorite-project-list-empty-state"
          display="flex"
          pl={3.25}
        >
          <Text
            color={theme.color.gray[500]}
            fontSize={FSTGTypography.fontSize_1_2}
            fontStyle="italic"
          >
            <Trans
              components={{
                anchor: (
                  <StarBorderIcon
                    sx={{
                      fontSize: FSTGTypography.fontSize_1_6,
                      verticalAlign: "top",
                    }}
                  />
                ),
              }}
            >
              SIDEBAR.FAVORITE_PROJECTS.EMPTY_STATE
            </Trans>
          </Text>
        </Box>
      )}
      {!showProjects && favoriteProjectIsSelected && (
        <ProjectListItem {...state.selectedProject} isSelected />
      )}
      {showProjects &&
        activeCurrentTeamProjects.map((project) => (
          <ProjectListItem
            key={project.id}
            {...project}
            isSelected={
              isProjectRoute &&
              Boolean(state.selectedProject) &&
              project.id === state.selectedProject.id
            }
          />
        ))}

      {showProjects && externalProjects?.length > 0 && (
        <Box data-testid="project-list-external-section" mb={1}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt={1}
            mb={1}
          >
            <Box
              position="absolute"
              border={`0.5px solid ${theme.color.gray[200]}`}
              zIndex={2}
              mr={1}
              ml={1}
              width="90%"
              component="span"
            />
            <Text
              color={theme.color.gray[400]}
              fontWeight={FSTGTypography.fontWeightMedium}
              variant="subtitle2"
              sx={{
                position: "relative",
                paddingLeft: 1,
                paddingRight: 1,
                zIndex: 2,
                backgroundColor: theme.color.white,
              }}
              component="span"
              translate="SIDEBAR.EXTERNAL_PROJECTS.TITLE"
            />
          </Box>
          {externalProjects.map((project) => (
            <ProjectListItem
              key={project.id}
              {...project}
              isSelected={
                isProjectRoute &&
                Boolean(state.selectedProject) &&
                project.id === state.selectedProject.id
              }
            />
          ))}
        </Box>
      )}

      {showProjects &&
        state.areArchivedProjectsShown &&
        archivedCurrentTeamProjects.length > 0 && (
          <Box
            data-testid="project-list-archived-section"
            backgroundColor={theme.color.gray[50]}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mt={1}
              mb={1}
            >
              <Box
                position="absolute"
                border={`0.1px solid ${theme.color.gray[200]}`}
                zIndex={2}
                mr={1}
                ml={1}
                width="90%"
                component="span"
              />
              <Text
                color={theme.color.gray[400]}
                fontWeight={FSTGTypography.fontWeightMedium}
                variant="subtitle2"
                sx={{
                  position: "relative",
                  paddingLeft: 1,
                  paddingRight: 1,
                  backgroundColor: theme.color.gray[50],
                  zIndex: 2,
                }}
                component="span"
                translate="PROJECT.ARCHIVED_LABEL"
              />
            </Box>
            {archivedCurrentTeamProjects.map((project) => (
              <ProjectListItem
                key={project.id}
                {...project}
                isSelected={
                  isProjectRoute &&
                  Boolean(state.selectedProject) &&
                  project.id === state.selectedProject.id
                }
              />
            ))}
          </Box>
        )}
    </Box>
  );
};

export default FavoriteProjectSection;
