import PropTypes from "prop-types";
import { Controller, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { TEAM_PERMISSIONS } from "@supporting/constants/team";

import { Box } from "@shared/UIKit";

const mapTranslationKeys = (permissionName) =>
  Object.keys(TEAM_PERMISSIONS).find(
    (key) => TEAM_PERMISSIONS[key] === permissionName
  );

function TeamRoleForm({ orderedPermissions, control, handleSubmit }) {
  const { t } = useTranslation();

  const { errors } = useFormState({ control });

  return (
    <Box
      component="form"
      id="team-role-form"
      data-testid="team-role-form"
      onSubmit={handleSubmit}
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Controller
        name="roleName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            label={t("TEAM.ROLES.NAME")}
            fullWidth
            autoFocus
            error={Boolean(errors.roleName)}
            helperText={errors.roleName && t(errors.roleName.message)}
            inputProps={{
              "data-testid": "team-role-name-input",
            }}
          />
        )}
      />

      <FormGroup>
        {orderedPermissions.map((permissionName, index) => (
          <FormControlLabel
            key={permissionName}
            label={t(
              `TEAM.ROLES.PERMISSIONS.${mapTranslationKeys(permissionName)}`
            )}
            control={
              <Controller
                name={`permissions.${permissionName}`}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    data-testid={`role-permission-${index}`}
                    checked={field.value}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                    }}
                  />
                )}
              />
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
}

TeamRoleForm.propTypes = {
  orderedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  control: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default TeamRoleForm;
