import PropTypes from "prop-types";
import { forwardRef, ReactElement } from "react";

import styled from "@emotion/styled";
import {
  spacing,
  shadows,
  borders,
  palette,
  sizing,
  display,
  flexbox,
  typography,
  positions,
  grid,
} from "@mui/system";

const StyledBox = styled.div`
  ${grid}${palette}${spacing}${typography}${shadows}${borders}${sizing}${display}${flexbox}${positions}
`;

/**
 * UIKit Box Component
 * @description This component is a styled version of the Material-UI Box component. It extends all
 * the Material-UI Box props. We integrated this component to our UIKit theme because MUI v4 Box
 * component is very slow on the nested rendering. For the explanation you can check
 * this [link](https://github.com/mui/material-ui/issues/21797).
 * @param {object} props Component props
 * @param {ReactElement} [props.children] [Required] Children components that will be rendered inside the Box.
 * @param {string} props.component [Optional] Component type to be rendered. Default is `div`.
 * @param {...object} props.restProps [Optional] Rest of the props will be passed to the Box component.
 * @returns {ReactElement} React component
 */
const Box = forwardRef(({ component, children, ...restProps }, ref) => (
  <StyledBox ref={ref} as={component} {...restProps}>
    {children}
  </StyledBox>
));
Box.displayName = "Box";

Box.propTypes = {
  children: PropTypes.node,
  component: PropTypes.string,
};

Box.defaultProps = {
  children: null,
  component: "div",
};

export default Box;
