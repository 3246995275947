import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import CreateTeamRoleDialog from "@supporting/components/CreateTeamRoleDialog/CreateTeamRoleDialog";
import LimitReachedDialog from "@supporting/components/LimitReachedDialog/LimitReachedDialog";

import { useDialogState } from "@shared/hooks";
import { teamProp, roleProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

function TeamMembersSettingsTab({ team, permissions, teamRoles }) {
  const { t } = useTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const {
    isOpen: isReachedLimitDialogOpen,
    openDialog: openReachedLimitDialog,
    closeDialog: closeReachedLimitDialog,
  } = useDialogState();

  const createTeamRole = () => {
    if (!team.subscriptionLimits.isCustomRolesEnabled) {
      openReachedLimitDialog();
      analytics.trackV2({
        action: analytics.ACTION.REACHED,
        category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
        subCategory: analytics.SUB_CATEGORY.CUSTOM_ROLES,
        type: analytics.TYPE.FEATURE,
      });
    } else {
      setIsOpenDialog(true);
    }
  };

  const onOpenSelectPlanDialog = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.CUSTOM_ROLES,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_BUTTON,
    });
  };

  return (
    <>
      {isOpenDialog && (
        <CreateTeamRoleDialog
          teamId={team._id}
          orderedPermissions={permissions}
          teamRoles={teamRoles}
          setIsOpenDialog={setIsOpenDialog}
        />
      )}
      <Button
        onClick={createTeamRole}
        startIcon={<AddCircleOutlineIcon />}
        variant="contained"
        color="primary"
        size="large"
        data-testid="create-team-role-button"
      >
        {t("TEAM.ROLES.ADD_ROLE")}
      </Button>
      {isReachedLimitDialogOpen && (
        <LimitReachedDialog
          selectedLimit="customRole"
          cancel={closeReachedLimitDialog}
          onOpenSelectPlanDialog={onOpenSelectPlanDialog}
        />
      )}
    </>
  );
}

TeamMembersSettingsTab.propTypes = {
  team: teamProp.isRequired,
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamRoles: PropTypes.arrayOf(roleProp).isRequired,
};

export default TeamMembersSettingsTab;
