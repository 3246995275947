import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import { LoadingButton as Button } from "@mui/lab";
import OtpField from "@supporting/components/OtpField/OtpField";
import ResendOTP from "@supporting/components/ResendOTP/ResendOTP";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import authenticationService from "@supporting/services/authentication";

import { Box, Text } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

function SignUpOtp() {
  const { goTo } = useNavigation();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const planId = searchParams.get("planId");
  const reason = searchParams.get("reason");
  const sourceProjectId = searchParams.get("sourceProjectId");
  const sourceProjectTemplateId = searchParams.get("sourceProjectTemplateId");
  const insightsIntegrationId = searchParams.get("insightsIntegrationId");

  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () =>
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.EMAIL_VERIFICATION_PAGE
      ),
    []
  );

  const handleInputChange = (value) => {
    setCode(value);
    setErrorText("");
  };

  const submitCode = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await authenticationService.verifyOTPForSignup(email, code);
      analytics.track(
        analytics.ACTION.SUBMITTED,
        analytics.CATEGORY.EMAIL_VERIFICATION_FORM
      );
      goTo(ROUTE_STATES.ONBOARDING_WIZARD_CREDENTIALS, {
        search: new URLSearchParams({
          email,
          code,
        }).toString(),
      });
    } catch (err) {
      setIsLoading(false);
      setErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCORRECT_CODE"));
    }
  };

  const handleResendClick = () => {
    return authenticationService.sendOTPForSignup(email, {
      email,
      planId,
      reason,
      sourceProjectId,
      sourceProjectTemplateId,
      insightsIntegrationId,
    });
  };

  const isDisabled = code.length !== 6;

  return (
    <WizardBase variant="VERIFY_OTP">
      <Box flex="1 1 100%" display="flex" flexDirection="column" width="100%">
        <Box
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Box
            noValidate
            gap={5}
            maxWidth={400}
            display="flex"
            component="form"
            alignItems="flex-start"
            onSubmit={submitCode}
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box gap={2} display="flex" flexDirection="column">
              <Text
                variant="text3xl"
                fontWeight="fontWeight.medium"
                translate="AUTHENTICATION.SIGNUP_OTP.TITLE"
              />
              <Text color="grey.600" variant="textMd">
                {t("AUTHENTICATION.SIGNUP_OTP.DESCRIPTION", { email })}
              </Text>
            </Box>
            <Box width="100%">
              <OtpField
                code={code}
                onChange={handleInputChange}
                errorText={errorText}
              />
            </Box>
            <Button
              fullWidth
              size="large"
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={isDisabled}
            >
              {t("CORE.CONFIRM")}
            </Button>
            <ResendOTP sentOTP={handleResendClick} />
          </Box>
        </Box>
      </Box>
    </WizardBase>
  );
}

export default SignUpOtp;
