import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { ListItemIcon, ListItemText, ListItemButton } from "@mui/material";
import classnames from "classnames";

import getCurrentPage from "@shared/helpers/getCurrentPage";
import { useCurrentRouteName } from "@shared/hooks/useCurrentRouteName";
import { instance as analytics } from "@shared/services/analytics";

function ChangeLogButton() {
  const { t } = useTranslation();
  const currentRouteName = useCurrentRouteName();
  const handleClick = () => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.CHANGELOG, {
      page: getCurrentPage(currentRouteName),
    });
    window.open(window.fs.config.headway.url, "_blank");
  };
  useEffect(() => {
    setTimeout(() => {
      /* istanbul ignore next */
      if (window.Headway && currentRouteName) {
        window.Headway.onShowWidget = () => {
          analytics.track(
            analytics.ACTION.OPENED,
            analytics.CATEGORY.CHANGELOG,
            {
              page: getCurrentPage(currentRouteName),
            }
          );
        };
        window.Headway.init({
          selector: ".inject-headway-badge",
          trigger: ".trigger-headway-widget",
          account: window.fs.config.headway.account,
          position: { x: "left", y: "top" },
        });
      }
    });
  }, [currentRouteName]);

  return (
    <ListItemButton
      onClick={handleClick}
      data-testid="change-log-button-id"
      className={classnames(
        "trigger-headway-widget",
        "fs-tracking__open-changelog"
      )}
      sx={{
        position: "relative",
        "& .HW_badge_cont": {
          position: "absolute",
          "& .usermenu": {
            right: 3,
          },
          "& .sidebar__actions": {
            right: -12,
          },
        },
        "& .HW_badge": {
          width: 12,
          height: 12,
          left: 14,
          top: -5,
          color: "accent.main",
          backgroundColor: "accent.main",
        },
      }}
    >
      <ListItemIcon className="inject-headway-badge">
        <AutoAwesomeOutlinedIcon />
      </ListItemIcon>
      <ListItemText
        disableTypography
        variant="textSm"
        fontWeight="fontWeight.medium"
      >
        {t("CORE.OPEN_CHANGELOG")}
      </ListItemText>
    </ListItemButton>
  );
}

export default ChangeLogButton;
