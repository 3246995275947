/* istanbul ignore file */
import { routes as feedbackRoutes } from "@feedback/routes";
import { routes as integrationsRoutes } from "@integrations/routes";
import {
  routes as supportingRoutes,
  unsupportedRoutes as unsupportedSupportingRoutes,
} from "@supporting/routes";
import {
  routes as workflowRoutes,
  unsupportedRoutes as unsupportedWorkflowRoutes,
} from "@workflow/routes";

import ErrorBoundary from "@shared/components/RouterErrorBoundary";
import Layout from "@shared/pages/Layout";
import NoMatch from "@shared/pages/NoMatch";
import {
  routes as sharedRoutes,
  unsupportedRoutes as unsupportedSharedRoutes,
} from "@shared/routes";

export const routes = [
  {
    element: <Layout />,
    path: "/",
    errorElement: <ErrorBoundary />,
    children: [
      ...sharedRoutes,
      ...feedbackRoutes,
      ...integrationsRoutes,
      ...supportingRoutes,
      ...workflowRoutes,
    ],
  },
  ...unsupportedSupportingRoutes,
  ...unsupportedWorkflowRoutes,
  ...unsupportedSharedRoutes,
  {
    path: "*",
    element: <NoMatch />,
  },
];
