import PropTypes from "prop-types";
import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import ExportFileReportMenuItem from "@feedback/components/ExportFeedback/ExportFileReportMenuItem/ExportFileReportMenuItem";
import { MoreVert, EditOutlined } from "@mui/icons-material";
import {
  Menu,
  IconButton,
  SvgIcon,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import useBillingLimits from "@supporting/hooks/useBillingLimits";
import { instance as fileService } from "@workflow/services/fileService";
import noop from "lodash/noop";

import { Tooltip } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { useMediaQuery } from "@shared/hooks";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import ResetFileNameIcon from "@assets/img/icons/restart_alt.svg";

import DeleteFileMenuItem from "./DeleteFileMenuItem/DeleteFileMenuItem";
import DeleteVersionMenuItem from "./DeleteVersionMenuItem/DeleteVersionMenuItem";
import DownloadVersionMenuItem from "./DownloadVersionMenuItem/DownloadVersionMenuItem";
import MetadataTable from "./MetadataTable/MetadataTable";
import MoveFileToSectionMenuItem from "./MoveFileToSectionMenuItem/MoveFileToSectionMenuItem";

function ProjectFileMenu({
  project,
  file,
  version,
  variant,
  onRename,
  ...restProps
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isExportFileReportEnabled } = useBillingLimits(project.teamId);

  const canRenameFile = useMemo(
    () =>
      isMobile &&
      variant === "file" &&
      !project.isArchived &&
      file.permissions.canRename,
    [project, file, variant, isMobile]
  );
  const canDeleteFile = useMemo(
    () =>
      variant === "file" && !project.isArchived && file.permissions.canDelete,
    [project, file, variant]
  );
  const canResetFileName = useMemo(
    () =>
      variant === "file" &&
      !project.isArchived &&
      file.permissions.canRename &&
      file.hasModifiedName,
    [variant, project, file]
  );
  const otherSections = useMemo(
    () => project.sections.filter((item) => item.id !== file.sectionId),
    [file, project]
  );
  const canMoveFileToSection = useMemo(() => {
    return (
      otherSections.length > 0 &&
      !project.isArchived &&
      project.permissions.uploadFiles
    );
  }, [otherSections, project]);

  const canDeleteVersion = useMemo(
    () =>
      !project.isArchived &&
      version.permissions.delete &&
      file.versions.length > 1,
    [project, file, version]
  );

  const openMenu = useCallback(
    ({ currentTarget }) => {
      setAnchorEl(currentTarget);
    },
    [setAnchorEl]
  );
  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const renameFile = useCallback(
    (event) => {
      event.stopPropagation();
      setAnchorEl(null);
      if (onRename) {
        onRename();
      }
    },
    [onRename]
  );

  const handleResetFileName = useCallback(async () => {
    closeMenu();

    try {
      await fileService.resetFileName(file.id);
      analytics.track(analytics.ACTION.RESET, analytics.CATEGORY.FILE_NAME);
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [closeMenu, file.id]);

  return (
    <>
      <IconButton
        aria-controls="project-file-menu"
        aria-haspopup="true"
        data-testid="project-file-menu-open-icon"
        onClick={openMenu}
        size="small"
      >
        <MoreVert fontSize="inherit" />
      </IconButton>

      {anchorEl && (
        <Menu
          id="project-file-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={closeMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          {...restProps}
        >
          {canRenameFile && (
            <MenuItem
              data-testid="project-file-menu-rename"
              onClick={renameFile}
            >
              <ListItemIcon>
                <EditOutlined />
              </ListItemIcon>
              <ListItemText>{t("FILE.MENU.RENAME")}</ListItemText>
            </MenuItem>
          )}

          <MetadataTable version={version} />

          {canMoveFileToSection && (
            <MoveFileToSectionMenuItem
              fileId={file.id}
              otherSections={otherSections}
              closeMenu={closeMenu}
            />
          )}

          {canResetFileName && (
            <Tooltip
              title={t("FILE.MENU.UPDATE-FILE-NAME.TOOLTIP")}
              placement="top"
            >
              <MenuItem
                data-testid="project-file-menu-reset-file-name"
                onClick={handleResetFileName}
              >
                <ListItemIcon>
                  <SvgIcon component={ResetFileNameIcon} />
                </ListItemIcon>
                <ListItemText>{t("FILE.MENU.RESET_FILE_NAME")}</ListItemText>
              </MenuItem>
            </Tooltip>
          )}

          <ExportFileReportMenuItem
            isExportFileReportEnabled={isExportFileReportEnabled}
            closeMenu={closeMenu}
            fileId={file.id}
            label="WORKSPACE.EXPORT.EXPORT-FULL-FILE"
            page={pagesEnum.PROJECT_DASHBOARD}
            teamId={project.teamId}
          />

          {version.permissions.download && version.original && (
            <DownloadVersionMenuItem
              file={file}
              version={version}
              variant={variant}
              closeMenu={closeMenu}
            />
          )}

          {canDeleteVersion && (
            <DeleteVersionMenuItem
              file={file}
              version={version}
              variant={variant}
              closeMenu={closeMenu}
            />
          )}

          {canDeleteFile && (
            <DeleteFileMenuItem
              file={file}
              version={version}
              closeMenu={closeMenu}
            />
          )}
        </Menu>
      )}
    </>
  );
}

ProjectFileMenu.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  file: fileProp.isRequired,
  version: versionProp.isRequired,
  variant: PropTypes.oneOf(["file", "version"]),
  onRename: PropTypes.func,
};

ProjectFileMenu.defaultProps = {
  variant: "file",
  onRename: noop,
};

export default ProjectFileMenu;
