/* eslint jsdoc/require-jsdoc:0 */
/* eslint jsdoc/no-missing-syntax:0 */
import { useTranslation } from "react-i18next";

import { Done } from "@mui/icons-material";
import {
  SvgIcon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0,
    margin: "5px 0",
    "& span": {
      fontSize: FSTGTypography.fontSize_1_5,
      color: theme.color.gray[800],
      marginLeft: 5,
    },
  },
}));

function DueDateReviewerHelp() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box py={3} px={4} data-testid="due-date-reviewer-help">
      <Text
        variant="h6"
        textAlign="center"
        fontWeight={FSTGTypography.fontWeightMedium}
      >
        {t("DUE_DATE.REVIEWER_HEADER")}
      </Text>

      <List>
        <ListItem disableGutters className={classes.listItem}>
          <ListItemIcon>
            <SvgIcon component={Done} color="primary" />
          </ListItemIcon>
          <ListItemText>{t("DUE_DATE.REVIEWER_LINE1")}</ListItemText>
        </ListItem>
        <ListItem disableGutters className={classes.listItem}>
          <ListItemIcon>
            <SvgIcon component={Done} color="primary" />
          </ListItemIcon>
          <ListItemText>{t("DUE_DATE.REVIEWER_LINE2")}</ListItemText>
        </ListItem>
        <ListItem disableGutters className={classes.listItem}>
          <ListItemIcon>
            <SvgIcon component={Done} color="primary" />
          </ListItemIcon>
          <ListItemText>{t("DUE_DATE.REVIEWER_LINE3")}</ListItemText>
        </ListItem>
        <ListItem disableGutters className={classes.listItem}>
          <ListItemIcon>
            <SvgIcon component={Done} color="primary" />
          </ListItemIcon>
          <ListItemText>{t("DUE_DATE.REVIEWER_LINE4")}</ListItemText>
        </ListItem>
      </List>
    </Box>
  );
}

export default DueDateReviewerHelp;
