import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import AddSection from "@workflow/components/AddSection/AddSection";
import { FileCardResizerProvider } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";
import ScrollBox from "@workflow/components/ScrollBox/ScrollBox";
import StepHeaders from "@workflow/components/StepHeaders/StepHeaders";
import projectTemplateService from "@workflow/services/projectTemplateService";

import { Box } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import pageTitleService from "@shared/services/pageTitleService";

import ProjectTemplateBanner from "./ProjectTemplateBanner/ProjectTemplateBanner";
import ProjectTemplateHeader from "./ProjectTemplateHeader/ProjectTemplateHeader";
import TemplateSection from "./TemplateSection/TemplateSection";

function ProjectTemplate() {
  const { t } = useTranslation();
  const { projectTemplateId: projectId } = useParams();
  const team = useSelectedTeam();
  const [projectTemplate, setProjectTemplate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    pageTitleService.setTitle(t("PROJECT.PAGE-TITLE"));
  }, [t]);

  useEffect(() => {
    if (team && !team.permissions.canManageTemplates) {
      navigate(APP_ROUTES.DASHBOARD.path);
    }
  }, [navigate, team, team.permissions.canManageTemplates]);

  useEffect(() => {
    const fetchProjectTemplate = async () => {
      const template = await projectTemplateService.fetchProjectTemplateById(
        projectId
      );
      setProjectTemplate(template);
    };

    fetchProjectTemplate();

    const onProjectUpdated = ({ eventData }) => {
      /* istanbul ignore else */
      if (eventData.template.id === projectId) {
        setProjectTemplate({ ...eventData.template });
      }
    };

    eventService.addListener(
      EVENT_NAMES.PROJECT_TEMPLATE.UPDATED,
      onProjectUpdated
    );
    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT_TEMPLATE.UPDATED,
        onProjectUpdated
      );
    };
  }, [projectId, projectTemplate?.id]);

  if (!projectTemplate) {
    return null;
  }
  return (
    <FileCardResizerProvider>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        bgcolor="grey.50"
        overflow="auto"
      >
        <ProjectTemplateHeader
          projectTemplateId={projectTemplate.id}
          projectTemplateName={projectTemplate.name}
          isDisabled={!team?.isTeamMember}
          projectTemplate={projectTemplate}
        />
        <ProjectTemplateBanner isLocked={projectTemplate.isLocked} />

        <ScrollBox
          display="flex"
          flexDirection="row"
          minWidth={0}
          flexGrow={1}
          minHeight={0}
          enableHorizontalDrag
          enableVerticalDrag={false}
          position="relative"
          marginLeft={{ xs: 0, md: 1, lg: 1 }}
        >
          <Box data-testid="project-template">
            <StepHeaders
              project={projectTemplate}
              steps={projectTemplate.steps}
              isLoadingSteps={false}
              team={team}
              isTemplate
            />
            <Box
              display="flex"
              flexDirection="column"
              data-testid="project-template-section-header"
            >
              {projectTemplate.sections.map((section) => (
                <Box key={section.id}>
                  <Box
                    position={{ xs: "block", md: "sticky" }}
                    width={250}
                    left={0}
                    px={1}
                  >
                    <TemplateSection
                      section={section}
                      project={projectTemplate}
                      team={team}
                    />
                  </Box>
                  <Box
                    width={290 * projectTemplate.steps.length + 246}
                    height="1px"
                    borderBottom="1px dashed var(--mui-palette-grey-300)"
                  />
                </Box>
              ))}
            </Box>
            <Box position={{ xs: "block", md: "sticky" }} width={250} left={0}>
              <AddSection project={projectTemplate} team={team} isTemplate />
            </Box>
          </Box>
        </ScrollBox>
      </Box>
    </FileCardResizerProvider>
  );
}

export default ProjectTemplate;
