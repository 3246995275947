import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { Link as ReactLink } from "react-router-dom";

import { Box, useMediaQuery, Link } from "@mui/material";
import userService from "@supporting/services/userService";
import classnames from "classnames";

import { Text } from "@shared/UIKit";

import * as parseMentions from "@shared/helpers/parseMentions.js";
import { SEARCH_KEYS } from "@shared/query-keys";

import LoadingSkeleton from "./LoadingSkeletons";
import { Presenter } from "./Presenter";
import { usePresenterStyles } from "./styles";

const useRecentSearches = () => {
  return useQuery({
    queryKey: SEARCH_KEYS.recent(),
    queryFn: async () => {
      const response = await userService.fetchRecentSearches();

      const result = response.map((item) => {
        return {
          ...item,
          ...(item.resource.resourceType === "COMMENT" && {
            searchResultText: parseMentions.decode(
              item.searchResultText,
              item.mentionedUsers
            ),
          }),
        };
      });

      return result;
    },
  });
};

export default function RecentSearches({
  isSectionActive,
  sectionActiveIndex,
  onClickResult,
}) {
  const presenterStyles = usePresenterStyles();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { data, isLoading } = useRecentSearches();

  if (isLoading) {
    return <LoadingSkeleton rows={5} />;
  }

  if (!isLoading && data.length === 0) {
    return <Box data-testid="no-recent-searches" />;
  }

  const checkIfSelected = (key) => {
    if (!isSectionActive) {
      return false;
    }
    return sectionActiveIndex === key;
  };

  return (
    <Box
      maxHeight={650}
      data-testid="recent-search-results"
      display="flex"
      textAlign="left"
      flexDirection="column"
      gap={1}
      my={2}
    >
      <Box ml={4}>
        <Text
          component="span"
          data-testid="search-result-header"
          fontWeight="fontWeight.medium"
          variant="textSm"
          color="default-light"
          translate="SEARCH.RECENT_SEARCH.TITLE"
        />
      </Box>
      {data.map((item, key) => {
        const isSelected = checkIfSelected(key);
        return (
          <Link
            key={item.resourceLinkUrl}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            component={ReactLink}
            to={item.resourceLinkUrl}
            onClick={(event) => {
              onClickResult(
                item.resourceLinkUrl,
                item.resource.resourceType.toLowerCase(),
                event,
                "recent_searches",
                item.resource
              );
            }}
            className={classnames(presenterStyles.SearchResultItem, {
              [presenterStyles.Selected]: isSelected,
            })}
            data-testid={
              isSelected ? "search-result-item-selected" : "search-result-item"
            }
          >
            <Presenter searchResult={item} isMobile={isMobile} />
          </Link>
        );
      })}
    </Box>
  );
}

RecentSearches.propTypes = {
  isSectionActive: PropTypes.bool.isRequired,
  sectionActiveIndex: PropTypes.number,
  onClickResult: PropTypes.func.isRequired,
};

RecentSearches.defaultProps = {
  sectionActiveIndex: null,
};
