import PropTypes from "prop-types";

import { Grow } from "@mui/material";
import { capitalize } from "@mui/material/utils";
import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";
const useStyles = makeStyles((theme) => {
  const { warning, success } = theme.palette;

  return {
    Error: {
      borderLeft: `3px solid ${warning.main}`,
      backgroundColor: warning.light,
    },
    Success: {
      borderLeft: `3px solid ${success.main}`,
      backgroundColor: success.light,
    },
  };
});

function FormFeedback({ variant, className, children, ...restProps }) {
  const classes = useStyles();

  return (
    <Grow in>
      <Box
        fontSize={FSTGTypography.fontSize_1_4}
        textAlign="center"
        p={1}
        className={classnames(classes[`${capitalize(variant)}`], className)}
        {...restProps}
      >
        {children}
      </Box>
    </Grow>
  );
}

FormFeedback.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["success", "error"]).isRequired,
  children: PropTypes.string.isRequired,
};

FormFeedback.defaultProps = {
  className: null,
};

export default FormFeedback;
