import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ADOBE_PLUGIN_EVENTS } from "@integrations/constants/adobePluginEvents";
import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import { Button } from "@mui/material";
import UploadSequenceButton from "@workflow/components/UploadFileButton/VariantButton/UploadSequenceButton/UploadSequenceButton";
import useUploadPanelDialog from "@workflow/components/UploadPanelDialog/useUploadPanelDialog";
import * as stepService from "@workflow/services/stepService";

import { Dialog, Text } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import ROUTE_STATES from "@shared/constants/routeStates";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import getPlugin from "@shared/helpers/getPlugin";
import { useNavigation } from "@shared/hooks";
import { projectProps } from "@shared/props/project";

function UploadPremiereSequenceConfirmation({ project }) {
  const { t } = useTranslation();
  const { goTo } = useNavigation();

  const isPremiere = getPlugin(PLUGIN_TYPES.PREMIERE);

  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const defaultStep = steps[0];
  const defaultStepIds = defaultStep ? [defaultStep.id] : [];

  const { openUploadPanelDialog } = useUploadPanelDialog({
    projectId: project.id,
    onClosePanel: handleClose,
    stepIds: defaultStepIds,
    teamId: project.teamId,
    page: pagesEnum.FILE_VIEW,
    component: UPLOAD_COMPONENT_TYPE.POPOVER,
  });

  useEffect(() => {
    const handleFileNotExistEvent = (event) => {
      if (
        event.origin === window.location.origin ||
        event.data?.eventType !==
          ADOBE_PLUGIN_EVENTS.PPRO_FILESTAGE_SEND_FILESTAGE_ID
      ) {
        return;
      }

      const fileId = event.data?.data.fileId;

      if (fileId) {
        goTo(ROUTE_STATES.WORKSPACE_ALIAS, {
          params: {
            projectId: project.id,
            fileId,
          },
        });
      } else {
        setOpen(true);
      }
    };
    window.addEventListener("message", handleFileNotExistEvent);

    return () => {
      window.removeEventListener("message", handleFileNotExistEvent);
    };
  }, [goTo, project.id]);

  useEffect(() => {
    async function fetchSteps(projectId) {
      const steps = await stepService.getProjectSteps(projectId);
      setSteps(steps);
    }

    fetchSteps(project.id);
  }, [project.id]);

  if (!isPremiere || steps.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={open}
      cancel={handleClose}
      data-testid="confirm-upload-modal"
      title={t("FILE.UPLOADING_NEW_SEQUENCE_ALERT.TITLE")}
      maxWidth="sm"
      actions={
        <>
          <Button
            variant="text"
            color="primaryV2"
            onClick={handleClose}
            data-testid="cancel-button"
          >
            {t("FILE.UPLOADING_NEW_SEQUENCE_ALERT.CANCEL_BUTTON")}
          </Button>

          <UploadSequenceButton
            data-testid="upload-button"
            onClick={openUploadPanelDialog}
          />
        </>
      }
    >
      <Text variant="body2" color="text.secondary" textAlign="center">
        {t("FILE.UPLOADING_NEW_SEQUENCE_ALERT.DESC")}
      </Text>
    </Dialog>
  );
}

UploadPremiereSequenceConfirmation.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
};

export default UploadPremiereSequenceConfirmation;
