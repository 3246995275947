import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { teamProp } from "@shared/props/team";

import FeatureName from "./FeatureName";
import Features from "./Features";
import FeatureTooltip from "./FeatureTooltip";

function TeamRolesFeatures({ team, openBillingDialog }) {
  const { t } = useTranslation();

  return (
    <Features title={t("BILLING.FEATURE.TEAM_MEMBER_ROLES")}>
      <FeatureName
        name="BILLING.FEATURE.PROJECT_VISIBILITY.LABEL"
        isEnabled={team.subscriptionLimits.isMemberRoleEnabled}
        tooltip={
          <FeatureTooltip
            description={t(
              "BILLING.FEATURE.PROJECT_VISIBILITY.HELP_MESSAGE.PART1"
            )}
            linkHref={t("URLS.FAQ_TEAM_ROLES")}
            linkText={t("BILLING.FEATURE.LEARN_MORE")}
            isEnabled={team.subscriptionLimits.isMemberRoleEnabled}
            upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
            upgradePlanMessage2={t(
              "BILLING.FEATURE.PROJECT_VISIBILITY.HELP_MESSAGE.PART2"
            )}
            openBillingDialog={openBillingDialog}
          />
        }
      />
      <FeatureName
        name="BILLING.FEATURE.CUSTOM_ROLES.LABEL"
        isEnabled={team.subscriptionLimits.isCustomRolesEnabled}
        tooltip={
          <FeatureTooltip
            description={t("BILLING.FEATURE.CUSTOM_ROLES.HELP_MESSAGE.PART1")}
            isEnabled={team.subscriptionLimits.isCustomRolesEnabled}
            upgradePlanLinkText={t("BILLING.UPGRADE_YOUR_PLAN")}
            upgradePlanMessage2={t(
              "BILLING.FEATURE.CUSTOM_ROLES.HELP_MESSAGE.PART2"
            )}
            openBillingDialog={openBillingDialog}
          />
        }
      />
    </Features>
  );
}

TeamRolesFeatures.propTypes = {
  team: teamProp.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

export default TeamRolesFeatures;
