import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { Box, Tabs, Tab } from "@mui/material";

import { Text } from "@shared/UIKit";

import RouterNavLink from "@shared/components/RouterNavLink";
import { APP_ROUTES } from "@shared/constants/routes";
import { useCurrentRouteName } from "@shared/hooks";

const getUserTabs = (translate) => {
  return [
    {
      label: translate("SETTINGS.TABS.PROFILE"),
      path: APP_ROUTES.PROFILE_HOME.path,
      id: "EDIT_PROFILE",
    },
    {
      label: translate("SETTINGS.TABS.NOTIFICATIONS"),
      path: "notifications",
      id: "NOTIFICATIONS",
    },
    {
      label: translate("SETTINGS.TABS.PASSWORD"),
      path: "password",
      id: "EDIT_PASSWORD",
    },
    {
      label: translate("SETTINGS.TABS.ACCOUNT"),
      path: "account",
      id: "ACCOUNT",
    },
    {
      label: translate("SETTINGS.TABS.EARLY_ACCESS"),
      path: "early-access",
      id: "EARLY_ACCESS",
    },
    ...(window.fs.config.debug
      ? [{ label: "Debug", path: "debug", id: "DEBUG_TAB" }]
      : []),
  ];
};

function Settings() {
  const { t } = useTranslation();
  const tabs = getUserTabs(t);

  const currentRoute = useCurrentRouteName();

  return (
    <Box
      minHeight={0}
      display="flex"
      flexDirection="column"
      px={{ xs: 1, md: 6.25 }}
      gap={4}
      overflow="auto"
    >
      <Text component="h2" variant="h3" sx={{ mt: 3.75 }}>
        {t("CORE.SETTINGS")}
      </Text>
      <Tabs
        value={currentRoute}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            label={tab.label}
            value={tab.id}
            data-testid={tab.label}
            LinkComponent={RouterNavLink}
            to={tab.path}
            end
          />
        ))}
      </Tabs>
      <Box
        width="100%"
        maxWidth={700}
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        alignSelf="center"
        mb={6}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default Settings;
