import { useMemo } from "react";

import debounce from "lodash/debounce";

/**
 * Use Debounce Hook
 *
 * @description This hook is a wrapper around the lodash debounce function.
 * It allows you to debounce a function and only call it once after a certain
 * amount of time has passed.
 * @param {Object} params - [Required] Parameters to pass to the debounce function.
 * @param {Function} params.callback - [Required] The function to debounce.
 * @param {number} params.delay - [Required] The amount of time to wait
 * before calling the debounced function. Must be in milliseconds.
 * @param {Object=} params.options - [Optional] options to pass to debounce.
 * @returns {Function} - Returns the memoized debounced function.
 */
const useDebounce = ({ callback, delay, options }) => {
  return useMemo(
    () => debounce(callback, delay, options),
    [callback, delay, options]
  );
};

export default useDebounce;
