import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Box } from "@mui/material";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";
import ReviewerEmailContent from "@supporting/components/ReviewersAuthenticationFlow/ReviewerEmailContent";
import ReviewerNameContent from "@supporting/components/ReviewersAuthenticationFlow/ReviewerNameContent";
import ReviewerPasswordContent from "@supporting/components/ReviewersAuthenticationFlow/ReviewerPasswordContent";
import ReviewerVerifyOTPContent from "@supporting/components/ReviewersAuthenticationFlow/ReviewerVerifyOTPContent";
import SignupEmailContent from "@supporting/components/ReviewersAuthenticationFlow/SignupEmailContent";
import SignupPasswordContent from "@supporting/components/ReviewersAuthenticationFlow/SignupPasswordContent";
import SignupVerifyOTPContent from "@supporting/components/ReviewersAuthenticationFlow/SignupVerifyOTPContent";
import authenticationService from "@supporting/services/authentication";
import noop from "lodash/noop";

import { Text } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import nativeWarningHandlerService from "@shared/services/nativeWarningHandlerService";

// eslint-disable-next-line complexity
export default function ReviewerAuthenticationFlowContent({
  action,
  context,
  hide,
  setTitle,
}) {
  const contextWithRedirectDetails = useRef({
    ...context,
    pathBeforeRedirect: useLocation().pathname,
    queryParamsBeforeRedirect: window.location.search,
  });
  const user = authenticationService.fetchUser();
  const email = useRef(user.email || "");
  const [fullName, setFullName] = useState(user.fullName || "");
  const [otpCode, setOtpCode] = useState("");

  const [stage, setStage] = useState(
    action === "awaiting-reviews" ? "SHOW_CREATE_ACCOUNT" : "SHOW_EMAIL"
  );
  const { buildUrl } = useNavigation();

  async function guestLogin() {
    healthMetrics.trackStart("supporting.authentication");
    try {
      const response = await authenticationService.guestLogin(
        email.current,
        context.stepId
      );
      healthMetrics.trackSuccess("supporting.authentication");
      if (response.user.fullName) {
        setFullName(response.user.fullName);
      } else {
        setFullName(authenticationService.nameFrom(email.current));
      }
    } catch (error) {
      const noGuestUser = error.status === 404;
      if (noGuestUser) {
        setFullName(authenticationService.nameFrom(email.current));
        healthMetrics.trackSuccess("supporting.authentication");
      } else {
        healthMetrics.trackFailure("supporting.authentication", error);
        errorHandlerService.handleError(error);
      }
    }
  }

  const authRouteState = {
    redirect: buildUrl(ROUTE_STATES.WORKSPACE_AUTH_CALLBACK),
    state: {
      action,
      context: contextWithRedirectDetails.current,
    },
    shareSessionStepId: context.stepId,
  };

  async function onSubmit(action) {
    switch (action) {
      case "SHOW_PASSWORD":
        setStage("SHOW_PASSWORD");
        setTitle("REVIEWER.ENTER_PASSWORD.HEADLINE");
        break;
      case "SHOW_GOOGLE":
        setStage("SHOW_GOOGLE");
        break;
      case "SHOW_CREATE_ACCOUNT":
        nativeWarningHandlerService.unregisterUnloadWarning();
        setTitle("SIGNUP.HEADLINE"); //given in figma is not updated
        setStage("SHOW_CREATE_ACCOUNT");
        break;
      case "SHOW_CREATE_PASSWORD":
        setTitle("ONBOARDING_WIZARD.CREDENTIALS.TITLE");
        setStage("SHOW_CREATE_PASSWORD");
        break;
      case "SHOW_NAME":
        await guestLogin();
        setTitle("REVIEWER.ENTER_USERDATA.HEADLINE");
        setStage("SHOW_NAME");

        break;
      case "SHOW_VERIFY_EMAIL":
        setTitle("AUTHENTICATION.SIGNUP_OTP.TITLE");
        setStage("SHOW_VERIFY_EMAIL");
        break;
      case "SHOW_VERIFY_SIGNUP_EMAIL":
        setTitle("AUTHENTICATION.SIGNUP_OTP.TITLE");
        setStage("SHOW_VERIFY_SIGNUP_EMAIL");
        break;
      case "CLOSE_DIALOG":
        hide();
        break;
    }
  }
  return (
    <Box mx={{ sm: 1 }}>
      {stage === "SHOW_EMAIL" && (
        <ReviewerEmailContent
          email={email}
          onSubmit={onSubmit}
          action={action}
          context={contextWithRedirectDetails.current}
        />
      )}
      {stage === "SHOW_PASSWORD" && (
        <ReviewerPasswordContent
          isAwaitingReview={action === "awaiting-reviews"}
          email={email.current}
          onSubmit={onSubmit}
          authRouteState={authRouteState}
        />
      )}
      {stage === "SHOW_NAME" && (
        <ReviewerNameContent
          onSubmit={onSubmit}
          fullName={fullName}
          setFullName={setFullName}
          email={email.current}
          code={otpCode}
          context={contextWithRedirectDetails.current}
        />
      )}
      {stage === "SHOW_VERIFY_EMAIL" && (
        <ReviewerVerifyOTPContent
          email={email.current}
          onSubmit={onSubmit}
          otpCode={otpCode}
        />
      )}
      {stage === "SHOW_CREATE_ACCOUNT" && (
        <SignupEmailContent
          onSubmit={onSubmit}
          action={action}
          email={email}
          context={contextWithRedirectDetails.current}
          isAwaitingReview={action === "awaiting-reviews"}
        />
      )}
      {stage === "SHOW_VERIFY_SIGNUP_EMAIL" && (
        <SignupVerifyOTPContent
          setOtpCode={setOtpCode}
          onSubmit={onSubmit}
          email={email.current}
        />
      )}
      {stage === "SHOW_CREATE_PASSWORD" && (
        <SignupPasswordContent
          authRouteState={authRouteState}
          code={otpCode}
          onSubmit={onSubmit}
          fullName={fullName}
          email={email.current}
        />
      )}
      {stage === "SHOW_GOOGLE" && (
        <>
          <Box mb={5}>
            <Text
              variant="body2"
              translate="REVIEWER.ENTER_USERDATA.GOOGLE_ACCOUNT_SUBTITLE"
            />
          </Box>
          <GoogleAuth
            showButtonOnly
            text="continue_with"
            authState={authRouteState}
            shareSessionStepId={authRouteState.shareSessionStepId}
          />
        </>
      )}
    </Box>
  );
}
ReviewerAuthenticationFlowContent.propTypes = {
  hide: PropTypes.func,
  context: PropTypes.object,
  action: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
};
ReviewerAuthenticationFlowContent.defaultProps = {
  context: {},
  hide: noop,
};
