import NiceModal from "@ebay/nice-modal-react";

import { Divider } from "@mui/material";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";

import { Box } from "@shared/UIKit";

import { teamProp } from "@shared/props/team";

import BrandingFeatures from "./features/BrandingFeatures";
import IntegrationFeatures from "./features/IntegrationsFeatures";
import SecurityFeatures from "./features/SecurityFeatures";
import TeamRolesFeatures from "./features/TeamRolesFeatures";
import WorkflowFeatures from "./features/WorkflowFeatures";

function SubscriptionFeatures({ team }) {
  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, { teamId: team._id });

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <WorkflowFeatures team={team} openBillingDialog={openDialog} />
      <Divider />
      <TeamRolesFeatures team={team} openBillingDialog={openDialog} />
      <Divider />
      <BrandingFeatures team={team} openBillingDialog={openDialog} />
      <Divider />
      <IntegrationFeatures
        team={team}
        isAppEnabled={team.subscriptionLimits?.isStandardAppsEnabled}
        openBillingDialog={openDialog}
      />
      <Divider />
      <SecurityFeatures team={team} openBillingDialog={openDialog} />
      <Divider />
    </Box>
  );
}

SubscriptionFeatures.propTypes = {
  team: teamProp.isRequired,
};

export default SubscriptionFeatures;
