import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import EditOutlined from "@mui/icons-material/EditOutlined";
import { MenuItem } from "@mui/material";
import { useTheme } from "@mui/styles";

import { Text } from "@shared/UIKit";

const SectionHeaderRenameMenuItem = forwardRef(({ onRename }, ref) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <MenuItem
      data-testid="section-header-menu-item-rename"
      onClick={onRename}
      ref={ref}
    >
      <EditOutlined fontSize="small" />
      <Text
        variant="subtitle1"
        fontWeight={theme.typography.fontWeightBold}
        component="p"
      >
        {t("PROJECT.SECTION.MENU.RENAME")}
      </Text>
    </MenuItem>
  );
});

SectionHeaderRenameMenuItem.displayName = "SectionHeaderRenameMenuItem";

SectionHeaderRenameMenuItem.propTypes = {
  onRename: PropTypes.func.isRequired,
};

export default SectionHeaderRenameMenuItem;
