import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { MenuItem, TextField, Button, Box } from "@mui/material";

import { Text as Typography, Dialog } from "@shared/UIKit";

import getFormattedPrice from "@shared/helpers/getFormattedPrice";
import { teamProp } from "@shared/props/team";

function AddSeatsDialog({ isOpen, cancel, answer, team }) {
  const { t } = useTranslation();

  const [seatCount, setSeatCount] = useState("");

  const cancelDialog = () => {
    setSeatCount("");
    cancel();
  };

  const confirmDialog = () => {
    answer(seatCount);
    setSeatCount("");
  };

  const handleSeatCountChange = useCallback((event) => {
    setSeatCount(event.target.value);
  }, []);

  return (
    <Dialog
      open={isOpen}
      cancel={cancelDialog}
      showCloseButton={false}
      hideBackdrop={false}
      disableBackdropClick
      title={t("SEATS.ADD.HEADLINE")}
      actions={
        <>
          <Button
            onClick={cancelDialog}
            variant="text"
            color="primaryV2"
            data-testid="add-seats-cancel"
          >
            {t("CORE.CANCEL")}
          </Button>
          <Button
            disabled={seatCount < 1}
            onClick={confirmDialog}
            color="primary"
            variant="contained"
            data-testid="add-seats-submit"
          >
            {t("SEATS.ADD.CTA.CONFIRM")}
          </Button>
        </>
      }
    >
      <Box
        data-testid="add-seats-dialog"
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        gap={2}
      >
        <Typography color="text.secondary" variant="subtitle1">
          {t("SEATS.ADD.DESCRIPTION")}
        </Typography>
        <Box
          display="flex"
          sx={{
            flexDirection: { xs: "column", md: "row" },
          }}
          gap={2}
          justifyContent="space-between"
        >
          <TextField
            fullWidth
            select
            variant="outlined"
            label={t("SEATS.ADD.INPUT-LABEL")}
            value={seatCount}
            onChange={handleSeatCountChange}
            labelId="seat-selector-select"
            inputProps={{
              name: "seat",
              id: "open-select",
              "data-testid": "select-input",
            }}
            size="small"
          >
            {Array.from({ length: 10 }, (_, num) => (
              <MenuItem
                key={num}
                value={num + 1}
                data-testid={`seat-count-${num}`}
              >
                {num + 1}
              </MenuItem>
            ))}
          </TextField>
          {seatCount && (
            <Box
              display="flex"
              justifyContent="center"
              textAlign="left"
              flexDirection="column"
              flexShrink="0"
            >
              <Typography
                variant="body2"
                fontWeight="fontWeight.medium"
                textWrap="norwrap"
              >
                {t(
                  `SEATS.ADD.TOTAL-${team.subscription.additionalPrice?.interval.toUpperCase()}-COST`,
                  {
                    price: getFormattedPrice(
                      team.subscription.additionalPrice,
                      seatCount
                    ),
                  }
                )}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("SEATS.ADD.BREAKUP-COST", {
                  price: getFormattedPrice(team.subscription.additionalPrice),
                })}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box bgcolor="grey.100" p={1} mt={2}>
        <Typography variant="textMd">
          {t(
            `SEATS.ADD.HELP-${team.subscription.additionalPrice?.interval.toUpperCase()}`
          )}
        </Typography>
      </Box>
    </Dialog>
  );
}

AddSeatsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  answer: PropTypes.func.isRequired,
  team: teamProp.isRequired,
};

export default AddSeatsDialog;
