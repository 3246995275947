import LogoutIcon from "@mui/icons-material/Logout";
import WarningIcon from "@mui/icons-material/Warning";
import { Button, Box, Typography } from "@mui/material";
import useActiveUser from "@supporting/hooks/useActiveUser";
import authenticationService from "@supporting/services/authentication";

function ImpersonationNoticeBar() {
  const isImpersonating = useActiveUser(
    (user) => user?.session.type === "Impersonated"
  );

  function logout() {
    authenticationService.redirectToLogout();
  }
  return (
    isImpersonating && (
      <Box
        bgcolor="error.main"
        transition="background-color 0.1s ease-out"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={3.125}
        py={1.25}
      >
        <Box display="flex" gap={1}>
          <WarningIcon htmlColor="white" />
          <Typography color="white" variant="body2">
            You are currently impersonating. Be careful.
          </Typography>
        </Box>
        <Button
          color="error"
          startIcon={<LogoutIcon />}
          onClick={logout}
          variant="outlined"
        >
          Logout
        </Button>
      </Box>
    )
  );
}

export default ImpersonationNoticeBar;
