import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { PersonAddOutlined } from "@mui/icons-material";
import { Button, Box } from "@mui/material";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";
import ExtraCollaboratorsPopper from "@workflow/components/Collaborators/Collaborator/ExtraCollaboratorsPopper";
import ShowAllCollaboratorsButton from "@workflow/components/Collaborators/Collaborator/ShowAllCollaboratorsButton";
import projectService from "@workflow/services/projectService";

import { InfoToolTip, Text } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";
import { teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";

import AddCollaboratorButton from "./AddCollaboratorButton/AddCollaboratorButton";
import Collaborator from "./Collaborator/Collaborator";

function Collaborators({ project, team, maxVisible }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const currentUser = authenticationService.fetchSession();
  const isCurrentUserCollaborator = project.collaborators.find(
    (collaborator) => collaborator._id === currentUser.userId
  );
  const canUserJoinProject =
    !isCurrentUserCollaborator && project.permissions.manageMembers;

  const joinProject = useCallback(
    async (event) => {
      event.stopPropagation();
      await projectService.joinProject(project.id);
      toastService.sendToast({
        title: "COLLABORATORS.SELF.ADDED.SUCCESS.TITLE",
        body: "COLLABORATORS.SELF.ADDED.SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
      });
    },
    [project.id]
  );

  const showAllCollaborators = useCallback(
    (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);

      if (!anchorEl) {
        analytics.track(
          analytics.ACTION.VIEWED,
          analytics.CATEGORY.TRUNCATED_COLLABORATORS
        );
      }

      event.stopPropagation();
    },
    [anchorEl]
  );

  const closeCollaboratorsPopper = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box
      className="tour__collaborators"
      display="flex"
      alignItems="center"
      sx={{
        position: { xs: "absolute", sm: "relative" },
        bottom: { xs: 15, sm: "auto" },
        left: { xs: 10, sm: "auto" },
        zIndex: { xs: 1000, sm: "auto" },
      }}
    >
      {project.collaborators.length < 2 && (
        <Text
          variant="subtitle1"
          color="text.secondary"
          sx={{ display: { xs: "none", sm: "block" } }}
          translate="COLLABORATORS.TITLE"
        />
      )}
      <Box
        mr={1}
        ml={2.25}
        display="flex"
        data-testid="project-collaborator-container"
      >
        {project.collaborators.length > maxVisible && (
          <>
            <ShowAllCollaboratorsButton
              showAllCollaborators={showAllCollaborators}
              hiddenCount={project.collaborators.length - maxVisible}
            />

            <ExtraCollaboratorsPopper
              project={project}
              skipCollaborators={maxVisible}
              anchorEl={anchorEl}
              onClosePopper={closeCollaboratorsPopper}
            />
          </>
        )}

        {project.collaborators
          .slice(0, maxVisible)
          .map((collaborator, index) => (
            <Collaborator
              key={collaborator._id}
              collaborator={collaborator}
              project={project}
              index={index}
            />
          ))}

        {project.permissions.manageMembers && !project.isArchived && (
          <AddCollaboratorButton project={project} team={team} />
        )}
      </Box>

      {canUserJoinProject && (
        <InfoToolTip title={t("PROJECT.JOIN.HELP_TEXT")} placement="bottom">
          <Button
            color="primary"
            variant="contained"
            shape="rounded"
            startIcon={<PersonAddOutlined />}
            onClick={joinProject}
            data-testid="collaborator-join-project"
          >
            {t("PROJECT.JOIN.BUTTON")}
          </Button>
        </InfoToolTip>
      )}
    </Box>
  );
}

Collaborators.propTypes = {
  team: teamProp.isRequired,
  maxVisible: PropTypes.number,
  project: PropTypes.shape(projectProps).isRequired,
};

Collaborators.defaultProps = {
  maxVisible: 5,
};

export default Collaborators;
