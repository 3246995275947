/* eslint id-length:0 */
import { useRef, useCallback } from "react";

function useDragToScroll({ horizontal, vertical }) {
  const containerElement = useRef(null);
  const containerPosition = useRef({ top: 0, left: 0, x: 0, y: 0 });
  const isDraggingEnabled = useRef(false);
  const isScrollEnabled = useRef(true);

  const enableScroll = useCallback(() => {
    isScrollEnabled.current = true;
  }, []);

  const disableScroll = useCallback(() => {
    isScrollEnabled.current = false;
    isDraggingEnabled.current = false;
  }, []);

  const handleMouseUp = useCallback(() => {
    isDraggingEnabled.current = false;
    window.removeEventListener("mouseup", handleMouseUp);
  }, []);

  const updateContainerPosition = useCallback((event) => {
    if (containerElement.current && event) {
      const element = containerElement.current;
      containerPosition.current.left = element.scrollLeft;
      containerPosition.current.top = element.scrollTop;
      containerPosition.current.x = event.clientX;
      containerPosition.current.y = event.clientY;
    }
  }, []);

  const handleMouseMove = useCallback(
    (event) => {
      if (
        containerElement.current &&
        isDraggingEnabled.current &&
        isScrollEnabled.current
      ) {
        const position = containerPosition.current;
        if (horizontal) {
          const dx = event.clientX - position.x;
          containerElement.current.scrollLeft = position.left - dx;
        }

        if (vertical) {
          const dy = event.clientY - position.y;
          containerElement.current.scrollTop = position.top - dy;
        }
      }
      updateContainerPosition(event);
    },
    [horizontal, vertical, updateContainerPosition]
  );

  const handleMouseDown = useCallback(
    (event) => {
      updateContainerPosition(event);
      isDraggingEnabled.current = isScrollEnabled.current;
      window.addEventListener("mouseup", handleMouseUp);
    },
    [handleMouseUp, updateContainerPosition]
  );

  return {
    containerProps: {
      ref: containerElement,
      onMouseMove: handleMouseMove,
      onMouseDown: handleMouseDown,
    },
    position: containerPosition,
    isDragging: isDraggingEnabled,
    enableScroll,
    disableScroll,
    isScrollEnabled,
  };
}

export default useDragToScroll;
