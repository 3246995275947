export default {
  COMPLETE_GOOGLE_AUTHENTICATION: "complete-google-authentication",
  FOLDER: {
    CREATED: "folder-created",
    UPDATED: "folder-updated",
    REMOVED: "folder-removed",
    PROJECT: {
      MOVED: "folder-project-moved",
    },
  },
  PROJECT: {
    CREATED: "project-created",
    UPDATED: "project-updated",
    REMOVED: "project-removed",
    COLLABORATOR: {
      ADDED: "project-collaborator-added",
      REMOVED: "project-collaborator-removed",
    },
    ACCESS: {
      GAINED: "project-access-gained",
      LOST: "project-access-lost",
    },
    SECTION: {
      CREATED: "project-section-created",
      MOVED: "project-section-moved",
      RENAMED: "project-section-renamed",
      REMOVED: "project-section-removed",
      COLLAPSED: "project-section-collapsed",
    },
    SECTIONS: {
      COLLAPSED: "project-sections-collapsed",
    },
    AUTOMATION: {
      CREATED: "project-automation-created",
      UPDATED: "project-automation-updated",
      PAUSED: "project-automation-paused",
      UNPAUSED: "project-automation-unpaused",
      REMOVED: "project-automation-removed",
    },
  },
  FAVORITE_PROJECTS: {
    UPDATED: "project-favorites-updated",
  },
  PROJECT_TEMPLATE: {
    SECTION: {
      CREATED: "project-template-section-created",
      MOVED: "project-template-section-moved",
      RENAMED: "project-template-section-renamed",
      REMOVED: "project-template-section-removed",
    },
    AUTOMATION: {
      CREATED: "project-template-automation-created",
      UPDATED: "project-template-automation-updated",
      PAUSED: "project-template-automation-paused",
      UNPAUSED: "project-template-automation-unpaused",
      REMOVED: "project-template-automation-removed",
    },
    REMOVED: "project-template-removed",
    CREATED: "project-template-created",
  },
  STEP: {
    CREATED: "step-created",
    REMOVED: "step-removed",
    RENAMED: "step-renamed",
    MOVED: "step-moved",
    REVIEWER: {
      ADDED: "step-reviewer-added",
      REMOVED: "step-reviewer-removed",
      DECISION_REQUESTED: "step-reviewer-decision-requested",
    },
    SETTINGS: {
      UPDATED: "step-settings-updated",
    },
    ACCESS: {
      GAINED: "step-access-gained",
      LOST: "step-access-lost",
    },
  },
  STEP_TEMPLATE: {
    CREATED: "step-template-created",
    REMOVED: "step-template-removed",
    RENAMED: "step-template-renamed",
    MOVED: "step-template-moved",
    REVIEWER: {
      ADDED: "step-template-reviewer-added",
      REMOVED: "step-template-reviewer-removed",
      DECISION_REQUESTED: "step-template-reviewer-decision-requested",
    },
  },
  TEAM: {
    LOGO: {
      UPDATE: {
        SUCCEEDED: "team-logo-update-succeeded",
        FAILED: "team-logo-update-failed",
      },
    },
  },
  FILE: {
    UPDATED: "file-updated",
    REMOVED: "file-removed",
    APPROVED: "file-approved",
    BULK_DELETED: "file-bulk-deleted",
  },
  VERSION: {
    UPLOAD: {
      SUCCEEDED: "file-version-upload-succeeded",
      FAILED: "file-version-upload-failed",
    },
  },
  REVIEW: {
    STARTED: "review-started",
    UPDATED: "review-updated",
    REMOVED: "review-removed",
    COMMENTS: {
      UPDATED: "review-comments-updated",
    },
    DECISIONS: {
      UPDATED: "review-decisions-updated",
    },
  },
  USER: {
    AVATAR: {
      UPDATE: {
        SUCCEEDED: "user-avatar-update-succeeded",
        FAILED: "user-avatar-update-failed",
      },
    },
  },
  COMMENT: {
    ATTACHMENT: {
      UPLOAD: {
        SUCCEEDED: "comment-attachment-upload-succeeded",
        FAILED: "comment-attachment-upload-failed",
      },
    },
    SCREENSHOT: {
      UPLOAD: {
        SUCCEEDED: "comment-screenshot-upload-succeeded",
        FAILED: "comment-screenshot-upload-failed",
      },
    },
  },
  IN_APP_NOTIFICATION: {
    NEW: "in-app-notification-new",
    UPDATED: "in-app-notification-updated",
    DELETED: "in-app-notification-deleted",
  },
  EXPORT_REPORT: {
    SUCCEEDED: "export-report-succeeded",
    FAILED: "export-report-failed",
  },
  PROJECT_CLONE: {
    SUCCEEDED: "project-clone-succeeded",
    FAILED: "project-clone-failed",
  },
  DASHBOARD: {
    SIDEBAR: {
      COLLAPSE_FOLDERS: "dashboard-sidebar-collapse-folders",
    },
  },
  INTEGRATIONS: {
    GOOGLE_DRIVE: {
      VERSION: {
        UPLOAD: {
          SUCCEEDED: "integrations-google-drive-version-upload-succeeded",
          FAILED: "integrations-google-drive-version-upload-failed",
        },
      },
    },
  },
};
