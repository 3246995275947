/* eslint jsdoc/require-jsdoc:0 */
/* eslint jsdoc/no-missing-syntax:0 */
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { InfoOutlined } from "@mui/icons-material";
import { SvgIcon, Link, Popover } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import DueDatePicker from "./DueDatePicker/DueDatePicker";
import DueDateReviewerHelp from "./DueDateReviewerHelp/DueDateReviewerHelp";
import UnlockDueDate from "./UnlockDueDate/UnlockDueDate";

const useStyles = makeStyles((theme) => ({
  helpContainer: {
    "& > a, & > a:hover *": {
      color: theme.color.gray[600],
    },
  },
  helpIcon: {
    cursor: "help",
    "&:hover": {
      backgroundColor: theme.color.gray[50],
      color: theme.color.gray[600],
    },
    "& > p": {
      fontSize: FSTGTypography.fontSize_1_3,
      color: theme.color.gray[600],
      marginLeft: 5,
    },
  },
}));

function DueDatePopover({
  onClose,
  anchorEl,
  onSave,
  onRemove,
  dueDate,
  canSetDueDate,
  isDueDateFeatureEnabled,
  canManageBilling,
  popoverPosition,
  openBillingDialog,
  ...restProps
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Popover
      onClose={onClose}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: popoverPosition,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: popoverPosition,
      }}
      data-testid="due-date-popover"
      {...restProps}
    >
      <Box
        bgcolor={theme.color.gray[50]}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={42}
        className={classes.helpContainer}
      >
        <Link target="_blank" href={t("URLS.FAQ_DUE_DATES")}>
          <Box
            display="flex"
            justifyContent="center"
            color={theme.color.gray[300]}
            px={1}
            py={0.75}
            borderRadius="20px"
            className={classes.helpIcon}
          >
            <SvgIcon component={InfoOutlined} fontSize="small" />
            <Text>{t("DUE_DATE.LEARN_MORE_ABOUT")}</Text>
          </Box>
        </Link>
      </Box>

      {canSetDueDate && isDueDateFeatureEnabled && (
        <DueDatePicker
          onClose={onClose}
          onSave={onSave}
          onRemove={onRemove}
          dueDate={dueDate}
        />
      )}

      {!canSetDueDate && <DueDateReviewerHelp />}

      {!isDueDateFeatureEnabled && (
        <UnlockDueDate
          canManageBilling={canManageBilling}
          onClose={onClose}
          openBillingDialog={openBillingDialog}
        />
      )}
    </Popover>
  );
}

DueDatePopover.propTypes = {
  dueDate: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  anchorEl: PropTypes.object,
  canSetDueDate: PropTypes.bool,
  isDueDateFeatureEnabled: PropTypes.bool,
  canManageBilling: PropTypes.bool,
  popoverPosition: PropTypes.string.isRequired,
  openBillingDialog: PropTypes.func.isRequired,
};

DueDatePopover.defaultProps = {
  dueDate: null,
  anchorEl: null,
  canSetDueDate: false,
  isDueDateFeatureEnabled: false,
  canManageBilling: false,
};

export default DueDatePopover;
