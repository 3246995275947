/* eslint-disable complexity */
import { LuzmoDashboardComponent } from "@luzmo/react-embed";
import { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";

import { Tabs, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { differenceInMilliseconds } from "date-fns";

import { Box } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";

import InsightsMiniDashboard from "./InsightsMiniDashboard";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    overflow: "auto",
  },
  tab: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeight.semiBold,
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.6)",
  },
}));

const tabs = [
  {
    label: "ADVANCED_INSIGHTS.TAB.OVERVIEW",
    slug: "overview",
  },
  {
    label: "ADVANCED_INSIGHTS.TAB.PROJECTS",
    slug: "projects",
  },
  {
    label: "ADVANCED_INSIGHTS.TAB.REVIEWS",
    slug: "reviews",
  },
  {
    label: "ADVANCED_INSIGHTS.TAB.REVIEWERS",
    slug: "reviewers",
  },
];

function getInsightsType(hasIntegrationId, isAdvancedInsightsEnabled) {
  if (hasIntegrationId) {
    return "personalized";
  }

  if (isAdvancedInsightsEnabled) {
    return "advanced";
  }

  return "mini";
}

function Insights() {
  const { t } = useTranslation();
  const team = useSelectedTeam();
  const classes = useStyles();
  const teamId = team._id;
  const dashboardInstance = useRef(null);
  const user = useActiveUser();

  const hasIntegrationId = team.insights?.integrationId;

  const [luzmoParams, setLuzmoParams] = useState({});
  const [filters, setFilters] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState(
    window.fs.config.insights.slugMap.overview
  );
  const [tabIndex, setTabIndex] = useState(0);

  /* istanbul ignore next */
  const switchDashboard = useCallback(
    async (event, newValue) => {
      const newPage = window.fs.config.insights.slugMap[tabs[newValue].slug];

      const fetchSearchFilters = async () => {
        healthMetrics.trackStart("workflow.insights");
        try {
          const filters = (await dashboardInstance.current?.getFilters()) || [];
          const searchFilters = filters
            .filter((filter) => {
              return filter.origin === "filterFromFilterItem";
            })
            .map((filter) => {
              return {
                expression: filter.filters[0].expression,
                chartId: filter.filters[0].properties.viz,
                clause: filter.filters[0].properties.type,
                value: filter.filters[0].parameters[1],
                previousDashboard: selectedDashboard,
                newDashboard: newPage,
              };
            });
          healthMetrics.trackSuccess("workflow.insights");
          return searchFilters;
        } catch (error) {
          const TAG = "workflow:insights";
          healthMetrics.trackFailure("workflow.insights", error);
          logger.error(TAG, "failed to populate current filters", { error });
          return [];
        }
      };

      setFilters(await fetchSearchFilters());
      setSelectedDashboard(newPage);
      setTabIndex(newValue);
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.INSIGHTS[`${newPage.toUpperCase()}_DASHBOARD`]
      );
    },
    [selectedDashboard]
  );

  const fetchLuzmoToken = useCallback(async () => {
    try {
      const { id, token } = await backend.post(`/insights/luzmo-auth`, {
        teamId,
        filters,
      });
      setLuzmoParams({
        authKey: id,
        authToken: token,
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  }, [teamId, filters]);

  useEffect(() => {
    if (
      !hasIntegrationId &&
      team.subscriptionLimits.isAdvancedInsightsEnabled
    ) {
      analytics.track(
        analytics.ACTION.OPENED,
        analytics.CATEGORY.INSIGHTS.OVERVIEW_DASHBOARD
      );
    }
  }, [hasIntegrationId, team.subscriptionLimits.isAdvancedInsightsEnabled]);

  useEffect(() => {
    analytics.track(analytics.ACTION.OPENED, analytics.CATEGORY.INSIGHTS_PAGE);
  }, []);

  useEffect(() => {
    if (hasIntegrationId || team.subscriptionLimits.isAdvancedInsightsEnabled) {
      fetchLuzmoToken();
    }
  }, [
    fetchLuzmoToken,
    hasIntegrationId,
    team.subscriptionLimits.isAdvancedInsightsEnabled,
  ]);

  useEffect(() => {
    const pageOpenTime = new Date();
    return () => {
      const timeSpendOnPage = differenceInMilliseconds(
        new Date(),
        pageOpenTime
      );
      analytics.track(
        analytics.ACTION.CLOSED,
        analytics.CATEGORY.INSIGHTS_PAGE,
        {
          timeSpend: timeSpendOnPage,
        }
      );
    };
  }, []);

  const insightsType = getInsightsType(
    hasIntegrationId,
    team.subscriptionLimits.isAdvancedInsightsEnabled
  );

  return (
    <Box
      data-testid={`dashboard-type-${insightsType}`}
      className={classes.container}
    >
      {!hasIntegrationId && (
        <Box mb={3} mt={4}>
          <Tabs
            value={tabIndex}
            onChange={switchDashboard}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.label}
                data-testid={`tab-${tab.slug}`}
                label={t(tab.label)}
                classes={{ root: classes.tab }}
              />
            ))}
          </Tabs>
        </Box>
      )}

      {(hasIntegrationId ||
        team.subscriptionLimits.isAdvancedInsightsEnabled) && (
        <Box pl={2.5} pr={2.5} mt={2}>
          <LuzmoDashboardComponent
            ref={dashboardInstance}
            authKey={luzmoParams.authKey}
            authToken={luzmoParams.authToken}
            language={
              team.subscriptionLimits.isAdvancedInsightsEnabled
                ? user.language
                : "en"
            }
            {...(hasIntegrationId
              ? {
                  dashboardId: teamId,
                }
              : {
                  dashboardSlug:
                    window.fs.config.insights.slugMap[tabs[tabIndex].slug],
                })}
            switchScreenModeOnResize
          />
        </Box>
      )}
      {!hasIntegrationId &&
        !team.subscriptionLimits.isAdvancedInsightsEnabled && (
          <InsightsMiniDashboard
            teamId={teamId}
            selectedTab={tabs[tabIndex].slug}
          />
        )}
    </Box>
  );
}

export default Insights;
