import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useSearchParams,
  createSearchParams,
  useNavigate,
} from "react-router-dom";

import { PLUGIN_TYPES } from "@integrations/constants/pluginTypes";
import { Box, Button, Link } from "@mui/material";
import EmailInput from "@supporting/components/EmailInput/EmailInput";
import GoogleAuth from "@supporting/components/GoogleAuth/GoogleAuth";
import PasswordField from "@supporting/components/PasswordField/PasswordField";
import WizardBase from "@supporting/components/WizardBase/WizardBase";
import authenticationService from "@supporting/services/authentication";
import toastService from "@supporting/services/toast";

import { Text } from "@shared/UIKit";

import { APP_ROUTES } from "@shared/constants/routes";
import ROUTE_STATES from "@shared/constants/routeStates";
import { EMAIL_REGEX } from "@shared/constants/validation";
import isInIframe from "@shared/helpers/isInIframe";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";
import { instance as logger } from "@shared/services/logger";
import pageTitleService from "@shared/services/pageTitleService";

import GoogleAuthLogin from "./GoogleAuthLogin";
import { updateGoogleAuthState } from "./loginHelper";

const TAG = "authentication-controller";

export default function Login() {
  const { t } = useTranslation();
  const [passwordInput, setPasswordInput] = useState("");
  const [forceEmailInValid, setForceEmailInValid] = useState(false);
  const { goTo, buildUrl } = useNavigation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hideGoogleLoginForm, setHideGoogleLoginForm] = useState(false);
  const [buttonText, setButtonText] = useState("LOGIN.CONTINUE");
  const [loginButtonTestId, setLoginButtonTestId] = useState("continue");

  const googleAuthState = updateGoogleAuthState({
    search: searchParams,
    buildUrl,
  });
  const isEmbedInMondayFireFox =
    sessionStorage.getItem("monday-in-firefox") === "yes";

  const [loginState, setLoginState] = useState({
    signUpError: false,
    isLoginInProgress: false,
    inputErrorMessage: " ",
    isEmailValid: false,
    autoFocusEmail: true,
    autoFocusPassword: true,
    email: searchParams.get("email"),
    inputErrorMessagePassword: " ",
    focusPasswordElement: false,
    loginError: false,
    invalid: false,
    server: false,
    isAdobePlugin: false,
    registeredPluginSessionId: "",
    defaultErrorMessage: "FORM.EMAIL_CURRENT.MESSAGE_REQUIRED",
  });

  const { email } = loginState;
  const appendLoginState = (newState) => {
    setLoginState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };
  const handleOnChangePassword = (event) => {
    setPasswordInput(event.target.value);
    appendLoginState({
      inputErrorMessagePassword: event.target.value
        ? " "
        : "FORM.PASSWORD_LOGIN.MESSAGE_REQUIRED",
    });
  };
  const handleOnFocusPassword = () => {
    appendLoginState({
      autoFocusPassword: false,
      focusPasswordElement: true,
    });
  };
  const handleOnBlurPassword = () => {
    appendLoginState({
      inputErrorMessagePassword: passwordInput
        ? " "
        : t("FORM.PASSWORD_LOGIN.MESSAGE_REQUIRED"),
      focusPasswordElement: false,
    });
  };
  const handleClickForgotPassword = (event) => {
    event.preventDefault();
    goTo(ROUTE_STATES.PASSWORD_FORGOT, {
      search: createSearchParams({ email }).toString(),
    });
  };
  const handleClickToEmailPassword = (event) => {
    event.preventDefault();
    setHideGoogleLoginForm(false);
  };
  const checkIfShouldSetIframe = () => {
    let shouldSetIframe = false;
    const pluginId = authenticationService.getBasePlugin();
    const isIFramePlugin = Object.values(PLUGIN_TYPES).includes(pluginId);
    try {
      shouldSetIframe = Boolean(
        isIFramePlugin ||
          isEmbedInMondayFireFox ||
          window.opener?.onSuccessfulAuthentication ||
          window.opener?.onEmbedSuccessfulAuthentication ||
          window.opener?.setEmbedCsrfToken
      );
    } catch (error) {
      /* istanbul ignore next */
      logger.warn(TAG, "Failed to set shouldSetIframe", { error });
    }
    return shouldSetIframe;
  };
  const validateSSO = async (ssoRedirect) => {
    try {
      const identity = await authenticationService.fetchIdentity(
        null,
        ssoRedirect
      );
      healthMetrics.trackStart("supporting.authentication");
      return authenticationService.startExternalLogin(identity.name, {
        reason: searchParams.get("reason"),
        redirect: searchParams.get("redirect"),
        iframe: checkIfShouldSetIframe(),
      });
    } catch (error) {
      logger.error(TAG, "sso whr param error", {
        idpUrl: searchParams.get("whr"),
        error,
      });
      if (error.status === 404) {
        toastService.sendToast({
          title: "ERROR.SSO_WHR_NOT_FOUND.TITLE",
          body: "ERROR.SSO_WHR_NOT_FOUND.BODY",
          preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
        });
      } else {
        toastService.sendToast({
          title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
          body: "ERROR.ACTION_NOT_POSSIBLE.BODY",
          preset: toastService.PRESETS().ERROR,
        });
      }
      healthMetrics.trackFailure("supporting.authentication", error);
    }
  };
  const initialiseAuthentication = async () => {
    let currentPlugin = searchParams.get("plugin");
    if (currentPlugin) {
      authenticationService.setBasePlugin(currentPlugin);
    } else {
      currentPlugin = authenticationService.getBasePlugin();
    }
    if (isInIframe() && !currentPlugin && !isEmbedInMondayFireFox) {
      goTo(ROUTE_STATES.EMBED);
    }
    if (currentPlugin) {
      const registeredPluginSessionId =
        await authenticationService.generatePluginSessionId();
      appendLoginState({
        registeredPluginSessionId,
        isAdobePlugin: true,
      });
    }
  };
  useEffect(() => {
    healthMetrics.trackAttempt("supporting.authentication");
    const ssoRedirect =
      searchParams.get("whr") || searchParams.get("sso-redirect");
    if (ssoRedirect) {
      validateSSO(ssoRedirect);
    } else {
      initialiseAuthentication();
    }
    pageTitleService.setTitle(t("LOGIN.PAGE-TITLE"));
    const error = searchParams.get("error");
    if (error) {
      toastService.sendToast({
        title: `${error}.TITLE`,
        body: `${error}.BODY`,
        preset: toastService.PRESETS().ERROR,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLoginWithPassword = async () => {
    if (
      !loginState.isEmailValid ||
      !passwordInput ||
      loginState.isLoginInProgress
    ) {
      return;
    }

    setButtonText("LOGIN.IN_PROGRESS");
    appendLoginState({
      isLoginInProgress: true,
      loginError: false,
      invalid: false,
      server: false,
    });
    healthMetrics.trackStart("supporting.authentication");
    healthMetrics.trackStart("supporting.authentication-pwd");
    try {
      const credentials = {
        email: loginState.email,
        password: passwordInput,
      };

      await authenticationService.login(credentials, {
        iframe: checkIfShouldSetIframe(),
      });

      healthMetrics.trackSuccess("supporting.authentication");
      healthMetrics.trackSuccess("supporting.authentication-pwd");

      if (isEmbedInMondayFireFox) {
        navigate(APP_ROUTES.EMBED.path);
      } else {
        navigate(googleAuthState.redirect);
      }
    } catch (error) {
      let level = "error";
      switch (error.status) {
        case 400:
        case 403:
          level = "warn";
          appendLoginState({
            loginError: true,
            invalid: true,
            isLoginInProgress: false,
          });
          healthMetrics.trackSuccess("supporting.authentication");
          healthMetrics.trackSuccess("supporting.authentication-pwd");
          break;
        default:
          appendLoginState({
            loginError: true,
            server: true,
            isLoginInProgress: false,
          });
          healthMetrics.trackFailure("supporting.authentication", error);
          healthMetrics.trackFailure("supporting.authentication-pwd", error);
          break;
      }
      setButtonText("LOGIN.SUBMIT");
      setLoginButtonTestId("login");
      logger[level](TAG, "failed to login", {
        email,
        error,
      });
    }
  };
  const handleContinue = async (event) => {
    event.preventDefault();
    if (loginState.isShowPassword) {
      if (!EMAIL_REGEX.test(loginState.email)) {
        return setForceEmailInValid(true);
      }
      await handleLoginWithPassword();
      return;
    }
    if (!loginState.isEmailValid) {
      return;
    }
    try {
      const identity = await authenticationService.fetchIdentity(email);
      if (identity.isExternal) {
        healthMetrics.trackStart("supporting.authentication");
        if (identity.name === "filestage-google-oauth2") {
          setHideGoogleLoginForm(true);
          return;
        }
        return authenticationService.startExternalLogin(identity.name, {
          redirect: searchParams.get("redirect"),
          iframe: checkIfShouldSetIframe(),
        });
      }
      appendLoginState({
        isShowPassword: true,
      });
      setButtonText("LOGIN.SUBMIT");
      setLoginButtonTestId("login");
    } catch (error) {
      if (error.status === 404) {
        analytics.track(
          analytics.ACTION.SUBMITTED,
          analytics.CATEGORY.LOGIN_UNREGISTERED_EMAIL
        );
        appendLoginState({
          defaultErrorMessage: "unregistered_email",
        });
      } else {
        errorHandlerService.handleError(error);
      }
      healthMetrics.trackFailure("supporting.authentication", error);
    }
  };
  const updateAuthState = (field) => {
    appendLoginState({
      ...field,
      loginError: false,
      invalid: false,
      server: false,
      defaultErrorMessage: "FORM.EMAIL_CURRENT.MESSAGE_REQUIRED",
    });
  };
  const isErrorValid =
    loginState.inputErrorMessagePassword &&
    loginState.inputErrorMessagePassword !== " ";

  const redirectUrl = searchParams.get("redirect");

  const onClickOfLink = (event) => {
    event.preventDefault();
    goTo(ROUTE_STATES.SIGNUP, {
      search: createSearchParams({
        ...(redirectUrl && { redirect: redirectUrl }),
      }).toString(),
    });
  };

  return (
    <WizardBase variant="LOGIN">
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {!hideGoogleLoginForm && (
            <Box
              gap={5}
              flexGrow={1}
              maxWidth={400}
              display="flex"
              alignSelf="center"
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Box gap={2} display="flex" flexDirection="column">
                <Text
                  variant="text3xl"
                  fontWeight="fontWeight.medium"
                  translate="LOGIN.ONBOARDING.HEADER"
                />
                <Text
                  color="grey.600"
                  variant="textMd"
                  translate="LOGIN.ONBOARDING_WELCOME"
                />
              </Box>
              <Box
                gap={2}
                noValidate
                width="100%"
                display="flex"
                component="form"
                flexDirection="column"
                onSubmit={handleContinue}
              >
                <EmailInput
                  forceInValid={forceEmailInValid}
                  setForceInValid={setForceEmailInValid}
                  updateAuthState={updateAuthState}
                  defaultErrorMessage={loginState.defaultErrorMessage}
                  label="FORM.EMAIL.LABEL"
                  defaultValue={searchParams.get("email") || ""}
                />
                {loginState.isShowPassword && (
                  <Box display="flex" flexDirection="column">
                    <PasswordField
                      id="password"
                      name="password"
                      testId="password"
                      value={passwordInput}
                      autoComplete="current-password"
                      onFocus={handleOnFocusPassword}
                      onChange={handleOnChangePassword}
                      onBlur={handleOnBlurPassword}
                      error={isErrorValid}
                      isFocusIcon={loginState.focusPasswordElement}
                      errorTranslate={loginState.inputErrorMessagePassword}
                      labelTranslate="FORM.PASSWORD.LABEL"
                      autoFocus={loginState.autoFocusPassword}
                    />
                    <Link
                      href={APP_ROUTES.PASSWORD_FORGOT.path}
                      onClick={handleClickForgotPassword}
                      alignSelf="flex-end"
                      color="text.primary"
                      variant="subtitle1"
                    >
                      {t("LOGIN.FORGOTPASSWORD")}
                    </Link>
                  </Box>
                )}
                {loginState.loginError && (
                  <Box
                    p={1.5}
                    borderLeft={3}
                    borderColor="error.main"
                    bgcolor="rgba(255, 250, 243, 100)"
                  >
                    <Text component="span">
                      {loginState.invalid && t("LOGIN.INVALID_CREDENTIALS")}
                      {loginState.server && t("ERROR.SERVER_NOT_AVAILABLE")}
                    </Text>
                  </Box>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  data-testid={loginButtonTestId}
                >
                  {t(buttonText)}
                </Button>
                {!isEmbedInMondayFireFox && (
                  <GoogleAuth
                    testId="sign-in-google"
                    text="signin_with"
                    authState={googleAuthState}
                    isAdobePlugin={loginState.isAdobePlugin}
                    adobePluginSessionId={loginState.registeredPluginSessionId}
                  />
                )}
              </Box>
              <Text variant="textSm" alignSelf="center" color="text.secondary">
                {t("SUBTEXT.TOSIGNUP_TEXT")}{" "}
                <Link
                  onClick={onClickOfLink}
                  href="signup"
                  color="secondary"
                  data-testid="navigate-signup"
                >
                  {t("SUBTEXT.TOSIGNUP_LINK")}
                </Link>
              </Text>
            </Box>
          )}
          {hideGoogleLoginForm && (
            <GoogleAuthLogin
              googleAuthState={googleAuthState}
              handleClickToEmailPassword={handleClickToEmailPassword}
              isAdobePlugin={loginState.isAdobePlugin}
              adobePluginSessionId={loginState.registeredPluginSessionId}
              disabledInFirefox={isEmbedInMondayFireFox}
            />
          )}
        </Box>
      </Box>
    </WizardBase>
  );
}
