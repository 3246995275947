import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useSearchParams } from "react-router-dom";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { InputAdornment, Link, TextField } from "@mui/material";
import noop from "lodash/noop";

import { Box } from "@shared/UIKit";

import ROUTE_STATES from "@shared/constants/routeStates";
import { EMAIL_REGEX } from "@shared/constants/validation";
import { useNavigation } from "@shared/hooks";

function EmailInput({
  defaultErrorMessage,
  updateAuthState,
  label,
  defaultValue,
  autoFocusEmail,
  forceInValid,
  setForceInValid,
}) {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const [email, setEmail] = useState(defaultValue);
  const [focusElement, setFocusElement] = useState(false);
  const [authEmailState, setAuthEmailState] = useState({
    inputErrorMessage: "",
    isEmailValid: false,
    autoFocusEmail,
  });
  const [searchParams] = useSearchParams();
  const redirectUrl = searchParams.get("redirect");

  const getErrorMessage = (value) => {
    if (EMAIL_REGEX.test(value)) {
      return "";
    }
    if (value && value.length > 0) {
      return t("FORM.EMAIL.MESSAGE_FORMAT");
    }
    return t(defaultErrorMessage);
  };

  const updateState = useCallback((value) => {
    const field = {
      isEmailValid: EMAIL_REGEX.test(value),
      email: value,
    };
    updateAuthState(field);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateState(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);
  const handleOnBlur = () => {
    setFocusElement(false);
    setForceInValid(false);
    setAuthEmailState((prevState) => ({
      ...prevState,
      inputErrorMessage: getErrorMessage(email),
      autoFocusEmail: false,
    }));
  };
  const handleOnFocus = () => {
    setFocusElement(true);
  };
  const handleOnChange = (event) => {
    setEmail(event.target.value);
    setForceInValid(false);
    updateState(event.target.value);
    setAuthEmailState((prevState) => ({
      ...prevState,
      inputErrorMessage: "",
      autoFocusEmail: false,
    }));
  };
  const handleOnClick = (event, email, variant) => {
    event.preventDefault();
    if (variant === "signup") {
      goTo(ROUTE_STATES.SIGNUP, {
        search: createSearchParams({
          email,
          ...(redirectUrl && { redirect: redirectUrl }),
        }).toString(),
      });
    } else {
      goTo(ROUTE_STATES.LOGIN, {
        params: { email },
        search: createSearchParams({
          ...(redirectUrl && { redirect: redirectUrl }),
        }).toString(),
      });
    }
  };
  const errorMessages = {
    user_exists: (
      <>
        {t("FORM.EMAIL.MESSAGE_EXISTS")}
        <Link
          href="login"
          color="secondary"
          onClick={(event) => handleOnClick(event, email)}
          data-testid="navigate-login"
        >
          {t("SUBTEXT.TOLOGIN_LINK")}
        </Link>
      </>
    ),
    unregistered_email: (
      <>
        {t("LOGIN.ERROR_MESSAGE")} <br />
        <Link
          href="signup"
          color="secondary"
          onClick={(event) => handleOnClick(event, email, "signup")}
          data-testid="navigate-signup"
        >
          {t("LOGIN.ERROR_MESSAGE_SIGNUP")}
        </Link>
      </>
    ),
  };
  const isErrorValid =
    Boolean(errorMessages[defaultErrorMessage]) ||
    authEmailState.inputErrorMessage.length > 0;

  let iconColor = "default-light.main";
  if (focusElement) {
    iconColor = "primary.main";
  }
  if (isErrorValid) {
    iconColor = "error.main";
  }
  return (
    <Box display="flex" alignItems="flex-end" mt={1.5} mb={3.3} gap={1}>
      <TextField
        autoComplete="username"
        label={t(label)}
        type="email"
        name="email"
        value={email}
        inputProps={{
          "data-testid": "email",
        }}
        fullWidth
        helperText={
          (forceInValid && t("FORM.EMAIL.MESSAGE_FORMAT")) ||
          errorMessages[defaultErrorMessage] ||
          authEmailState.inputErrorMessage ||
          " "
        }
        onInput={handleOnChange}
        required
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        autoFocus={authEmailState.autoFocusEmail}
        InputLabelProps={{
          required: false,
        }}
        error={isErrorValid || forceInValid}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailOutlinedIcon sx={{ color: iconColor }} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}
EmailInput.propTypes = {
  defaultErrorMessage: PropTypes.string.isRequired,
  updateAuthState: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  autoFocusEmail: PropTypes.bool,
  forceInValid: PropTypes.bool,
  setForceInValid: PropTypes.func,
};
EmailInput.defaultProps = {
  defaultValue: "",
  autoFocusEmail: true,
  forceInValid: false,
  setForceInValid: noop,
};
export default EmailInput;
