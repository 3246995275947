import { memo } from "react";

import { Box } from "@mui/material";

import Blobs from "@shared/pages/Blobs";

function Background() {
  return (
    <Box
      top={0}
      left={0}
      gap={20}
      bottom={0}
      zIndex={-1}
      width="100%"
      height="100%"
      display="flex"
      position="fixed"
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        width="50%"
        height="100%"
        display="grid"
        flexWrap="wrap"
        alignItems="center"
        justifyItems="center"
        gridTemplateRows="repeat(2, 1fr)"
        gridTemplateColumns="repeat(2, 1fr)"
      >
        <Blobs />
      </Box>
    </Box>
  );
}

export default memo(Background);
