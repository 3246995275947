import PropTypes from "prop-types";

import {
  PersonAdd,
  ModeComment,
  AlternateEmail,
  Check,
  Replay,
  InsertDriveFile,
  Close,
} from "@mui/icons-material";
import { Badge, SvgIcon } from "@mui/material";

import { notificationCenterActivityProps } from "@shared/props/notificationCenter";

import LikeIcon from "@assets/img/icons/like.svg";

import NOTIFICATION_CENTER_ACTIVITIES from "../../activities.constants";

const iconsByType = {
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.CREATED]: {
    icon: <ModeComment fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.REPLIED]: {
    icon: <ModeComment fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.LIKED]: {
    icon: (
      <SvgIcon component={LikeIcon} fontSize="inherit" transform="scale(1.6)" />
    ),
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.MENTIONED]: {
    icon: <AlternateEmail fontSize="inherit" />,
    color: "accent.main",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.TEAM_ONLY_COMMENT_MENTION_CREATED]: {
    icon: <AlternateEmail fontSize="inherit" />,
    color: "accent.main",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.TEAM_ONLY]: {
    icon: <ModeComment fontSize="inherit" />,
    color: "var(--mui-color-yellow)",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.COMMENT.TEAM_ONLY_COMMENT_MENTION]: {
    icon: <ModeComment fontSize="inherit" />,
    color: "var(--mui-color-yellow)",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_APPROVED]: {
    icon: <Check fontSize="inherit" />,
    color: "success.main",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_APPROVED_WITH_CHANGES]:
    {
      icon: <Check fontSize="inherit" />,
      color: "success.main",
    },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_REQUESTED_CHANGES]: {
    icon: <Replay fontSize="inherit" />,
    color: "var(--mui-color-red-300)",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_DECISION_REJECTED]: {
    icon: <Close fontSize="inherit" />,
    color: "rejected.main",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.PROJECT.COLLABORATOR_INVITED]: {
    icon: <PersonAdd fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.STEP.REVIEWER_INVITED]: {
    icon: <PersonAdd fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STARTED]: {
    icon: <InsertDriveFile fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STATUS_IN_REVIEW]: {
    icon: <InsertDriveFile fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STATUS_APPROVED]: {
    icon: <InsertDriveFile fontSize="inherit" />,
    color: "grey.300",
  },
  [NOTIFICATION_CENTER_ACTIVITIES.REVIEW.REVIEW_STATUS_NEEDS_CHANGES]: {
    icon: <InsertDriveFile fontSize="inherit" />,
    color: "grey.300",
  },
};

function ActivityBadge({ children, type }) {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      sx={{
        "& .MuiBadge-badge": {
          bgcolor: iconsByType[type].color,
          color: "white",
          fontSize: "textXs.fontSize",
          width: 18,
        },
      }}
      badgeContent={iconsByType[type].icon}
      data-testid="notification-center-activity-badge"
    >
      {children}
    </Badge>
  );
}

ActivityBadge.propTypes = {
  type: notificationCenterActivityProps.isRequired,
  children: PropTypes.node.isRequired,
};
export default ActivityBadge;
