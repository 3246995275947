import backend from "@shared/services/backendClient";
import { instance as logger } from "@shared/services/logger";

function fetchActionToken(action) {
  return Promise.race([
    new Promise((resolve, reject) =>
      setTimeout(() => reject(new Error("google recaptcha timeout")), 3000)
    ),
    new Promise((resolve, reject) => {
      window.grecaptcha.ready(() =>
        window.grecaptcha
          .execute(window.fs.config.security.googleRecaptchaKeyV3, {
            action,
          })
          //eslint-disable-next-line promise/prefer-await-to-then
          .then(resolve, reject)
      );
    }),
  ]);
}

const ACTIONS = {
  POST: [
    [/^\/projects\/.+\/collaborators\/add$/, "ADD_PROJECT_COLLABORATORS"],
    [/^\/projects$/, "CREATE_PROJECT"],
    [/^\/projects\/.+\/steps$/, "ADD_REVIEW_STEP"],
    [/^\/steps\/.+\/reviewers$/, "INVITE_REVIEWERS"],
    [/^\/file-storage\/s3-multipart-create$/, "UPLOAD_FILE"],
    [/^\/comments$/, "CREATE_COMMENT"],
    [/^\/teams\/.+\/members$/, "INVITE_TEAM_MEMBER"],
    [/^\/auth\/login$/, "CREATE_REGISTERED_SESSION"],
    [/^\/auth\/signup$/, "CREATE_REGISTERED_SESSION"],
  ],
};

//eslint-disable-next-line no-unused-vars
async function addSecurityToken(method, route, params, headers) {
  try {
    for (const [regex, action] of ACTIONS[method] ?? []) {
      if (regex.test(route)) {
        headers.securityToken = await fetchActionToken(action);
        break;
      }
    }
  } catch (error) {
    logger.error("security", "failed to generate action token", {
      error,
    });
  }
}

//eslint-disable-next-line no-unused-vars
function addCsrfToken(method, route, params, headers) {
  const csrfToken = localStorage.getItem("csrf");

  if (csrfToken) {
    headers["Fstg-CSRF-Token"] = csrfToken;
  }
}

function initialize() {
  backend.interceptors.push({ request: addSecurityToken });

  backend.interceptors.push({ request: addCsrfToken });
}

export default { initialize, addSecurityToken };
