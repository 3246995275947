import PropTypes from "prop-types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Grid, Tabs, Tab, CircularProgress } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import NotificationSortPanel from "@supporting/components/NotificationCenter/header/NotificationSortPanel";

import { Box, Text, Tooltip } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";

const useStyles = makeStyles((theme) => ({
  Icon: {
    color: theme.color.gray[400],
  },
  Button: {
    borderLeft: `1px solid ${theme.color.gray[300]}`,
  },
  TabContainer: {
    padding: 10,
    boxShadow: theme.shadow["box-shadow-navbar"],
    minHeight: 56,
  },
  LeftTabContainer: {
    flexGrow: 0,
  },
  Tabs: {
    minHeight: "auto",
  },
  Tab: {
    textTransform: "none",
    minWidth: "max-content",
    minHeight: "auto",
    color: theme.color.gray[600],
    "&:hover": {
      color: theme.color.green[500],
    },
  },
  MarkAsReadDone: {
    borderLeft: `1px solid ${theme.color.gray[300]}`,
    paddingLeft: "0.5em",
    marginLeft: "0.75em",
    lineHeight: "32px !important",
  },
  ProgressIcon: {
    marginTop: 6,
  },
}));

function NotificationCenterHeader({
  closeDrawer,
  selectedTab,
  setTab,
  updateIsReadForAll,
  hasUnreadNotifications,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const setCurrentTab = useCallback(
    // eslint-disable-next-line
    (event, newValue) => {
      analytics.track(
        newValue === 0
          ? analytics.ACTION.UNREAD_SELECTED
          : analytics.ACTION.ALL_SELECTED,
        analytics.CATEGORY.NOTIFICATION_CENTER_TAB
      );
      setTab(newValue);
    },
    [setTab] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const updateIsReadForAllIndicatorHandler = useCallback(async () => {
    setIsLoading(true);
    await updateIsReadForAll();
    setIsLoading(false);
    setIsDone(true);
    setTimeout(() => {
      setIsDone(false);
    }, 3000);
    analytics.track(
      analytics.ACTION.MARKED_ALL_AS_READ,
      analytics.CATEGORY.NOTIFICATION_CENTER_NOTIFICATIONS
    );
  }, [setIsLoading, updateIsReadForAll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.TabContainer}
    >
      <Grid item xs className={classes.LeftTabContainer}>
        <Tabs
          value={selectedTab}
          onChange={setCurrentTab}
          indicatorColor="primary"
          textColor="primary"
          classes={{ root: classes.Tabs }}
          aria-label={t("NOTIFICATION_CENTER.TITLE")}
        >
          <Tab
            className={classes.Tab}
            disableRipple
            label={t("NOTIFICATION_CENTER.UNREAD")}
          />
          <Tab
            className={classes.Tab}
            disableRipple
            label={t("NOTIFICATION_CENTER.ALL")}
          />
        </Tabs>
      </Grid>

      <Grid
        item
        xs
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="stretch"
      >
        <NotificationSortPanel />
        {hasUnreadNotifications && !isLoading && !isDone && (
          <Box className={classes.Button} pl={0.5} ml={0.75}>
            <Tooltip title={t("NOTIFICATION_CENTER.MARK_ALL_AS_READ")}>
              <IconButton
                aria-label={t("NOTIFICATION_CENTER.MARK_ALL_AS_READ")}
                size="small"
                onClick={updateIsReadForAllIndicatorHandler}
                data-testid="notification-center-header-mark-all-as-read"
              >
                <CheckCircleOutlineRoundedIcon className={classes.Icon} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        {isDone && (
          <Text
            variant="body2"
            color={theme.color.green[500]}
            className={classes.MarkAsReadDone}
          >
            {t("NOTIFICATION_CENTER.MARK_ALL_AS_READ_DONE")}
          </Text>
        )}
        {isLoading && (
          <CircularProgress
            disableShrink
            size={20}
            className={classes.ProgressIcon}
          />
        )}
        {isMobile && (
          <Box className={classes.Button} pl={0.5} ml={0.75}>
            <IconButton
              aria-label={t("CORE.CLOSE")}
              size="small"
              onClick={closeDrawer}
              data-testid="notification-center-header-close"
            >
              <CloseIcon className={classes.Icon} />
            </IconButton>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

NotificationCenterHeader.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  setTab: PropTypes.func.isRequired,
  updateIsReadForAll: PropTypes.func.isRequired,
  hasUnreadNotifications: PropTypes.bool.isRequired,
};

export default NotificationCenterHeader;
