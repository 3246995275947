import PropTypes from "prop-types";

export const automationOptionProps = PropTypes.shape({
  text: PropTypes.string,
  value: PropTypes.string,
});

export const automationConditionActionProps = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  key: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(automationOptionProps),
});

export const automationTriggerProps = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  key: PropTypes.string,
  delay: PropTypes.string,
  conditions: PropTypes.string,
  actions: PropTypes.string,
});

export const automationWizardProps = PropTypes.shape({
  triggers: PropTypes.arrayOf(automationTriggerProps),
  trigger: automationTriggerProps,
  selectedActions: PropTypes.arrayOf(automationConditionActionProps),
  isAutomationEnabled: PropTypes.bool,
});

export const automationProps = PropTypes.shape({
  automationId: PropTypes.string,
  enabled: PropTypes.bool,
  triggerId: PropTypes.number,
  conditions: PropTypes.object,
  actions: PropTypes.object,
  createdBy: PropTypes.string,
  updatedBy: PropTypes.string,
  createdTime: PropTypes.string,
  updatedTime: PropTypes.string,
  name: PropTypes.string,
});
