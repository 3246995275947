import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { PersonAddOutlined } from "@mui/icons-material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";

import { Box } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

import SeatLimitWarning from "./SeatLimitWarning";

const useStyles = makeStyles(() => ({
  warningIcon: {
    marginRight: 5,
  },
}));

const TeamMemberDialogWarning = ({
  canAddMoreUsersToSubscription,
  doesBaseSubscriptionUserLimitReached,
  canManageBilling,
  teamId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  /* istanbul ignore next */
  const onClickGetInTouch = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.SEATS,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_INTEREST_BUTTON,
    });
  };
  const onClickSelectPlanLink = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.SEATS,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_BUTTON,
    });
    NiceModal.show(SelectPlanDialog, { teamId });
  };

  if (
    canAddMoreUsersToSubscription &&
    doesBaseSubscriptionUserLimitReached &&
    !canManageBilling
  ) {
    return (
      <SeatLimitWarning
        icon={<InfoOutlined fontSize="small" className={classes.warningIcon} />}
        text={t("TEAM.ADD-MEMBER.SEAT-LIMIT-REACHED.ERROR")}
        color={theme.color.red[500]}
        testId="seat-limit-without-permission-warning"
      />
    );
  }

  if (
    canAddMoreUsersToSubscription &&
    doesBaseSubscriptionUserLimitReached &&
    canManageBilling
  ) {
    return (
      <SeatLimitWarning
        icon={
          <PersonAddOutlined fontSize="small" className={classes.warningIcon} />
        }
        text={t("TEAM.ADD-MEMBER.SEAT-LIMIT-REACHED.INFO")}
        color={theme.color.blue[500]}
        testId="seat-limit-warning"
      />
    );
  }
  if (!canAddMoreUsersToSubscription && doesBaseSubscriptionUserLimitReached) {
    return (
      <SeatLimitWarning
        icon={<InfoOutlined fontSize="small" className={classes.warningIcon} />}
        text={
          <Trans
            components={{
              semiBold: <Box component="span" fontWeight="fontWeight.medium" />,
              getInTouch: (
                <Link
                  color="primary"
                  className="launch-intercom-chat"
                  fontWeight="fontWeight.medium"
                  href={t("URLS.HELLO_MAIL")}
                  target="_blank"
                  onClick={onClickGetInTouch}
                />
              ),
              anchor: (
                <Link
                  color="primary"
                  fontWeight="fontWeight.medium"
                  onClick={onClickSelectPlanLink}
                  data-testid="upgrade-billing-action-link"
                />
              ),
            }}
          >
            {t(
              "PROJECT-COLLABORATOR.DIALOG.SEAT-LIMIT-REACHED.LEGACY-PLAN.INFO"
            )}
          </Trans>
        }
        color={theme.color.blue[500]}
        testId="seat-limit-upgrade-warning"
      />
    );
  }
  return null;
};

TeamMemberDialogWarning.propTypes = {
  canAddMoreUsersToSubscription: PropTypes.bool,
  doesBaseSubscriptionUserLimitReached: PropTypes.bool,
  canManageBilling: PropTypes.bool,
  teamId: PropTypes.string.isRequired,
};

TeamMemberDialogWarning.defaultProps = {
  canAddMoreUsersToSubscription: undefined,
  doesBaseSubscriptionUserLimitReached: undefined,
  canManageBilling: undefined,
};

export default TeamMemberDialogWarning;
