import { useEffect, useCallback, useState } from "react";

import { instance as fileStorageService } from "@supporting/services/fileStorageService";
import toastService from "@supporting/services/toast";
import { instance as fileService } from "@workflow/services/fileService";
import partition from "lodash/partition";

import eventService from "@shared/services/eventService";
import { instance as logger } from "@shared/services/logger";
import { instance as websocket } from "@shared/services/websocket";
/**
 * Use File Uploader hook
 *
 * @description This hook is being used for uploading files to the server.
 * It contain the logic for uploading files to the server and handling the response
 * with the help of the dynamic websocket events which is passed to the hook itself.
 * @typedef File
 * @param {Array.<File>} initialUploads - [Required] Initial uploads array
 * that contains list of files for the upload.
 * @param {Function} uploadCompleted - [Required] Function that is called when the
 * upload is completed.
 * @param {Function} onFileUploadFailed - [Required] Function that is called when the
 * upload is failed.
 * @param {Function} onFileProcessingFailed - [Required] Function that is called when the
 * processing of the file is failed.
 * @param {string} SUCCESS_EVENT_NAME - [Required] Success event name that the websocket will
 * be listening to.
 * @param {string} FAILED_EVENT_NAME - [Required] Failed event name that the websocket will
 * be listening to.
 * @param {string} ERROR_EVENT_NAME - [Required] Error event name that the websocket will
 * be listening to.
 * @returns {{
 *  addFile: Function,
 *  removeFile: Function,
 *  uploads: Array.<File>,
 * }} - Returns an object with function of adding file, removing file
 * and uploaded files.
 */
export default function useFileUploader(
  initialUploads,
  uploadCompleted,
  onFileUploadFailed,
  onFileProcessingFailed,
  SUCCESS_EVENT_NAME,
  FAILED_EVENT_NAME,
  ERROR_EVENT_NAME
) {
  const [uploads, setUploads] = useState(initialUploads);
  const addFile = useCallback(
    (files, template, metadata) => {
      const [validFiles, filesWithInvalidExtensions] = partition(
        files,
        (file) => fileService.isFileSupported(file)
      );

      filesWithInvalidExtensions.forEach((fileWithWrongExtension) => {
        logger.warn(
          "shared:file-service",
          "Upload tried for file with wrong extension",
          {
            fileName: fileWithWrongExtension.name,
          }
        );
        toastService.sendToast({
          title: "UPLOAD.UNSUPPORTED_FORMAT.TITLE",
          body: "UPLOAD.UNSUPPORTED_FORMAT.BODY",
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
      });

      const newFiles = validFiles.map((file) => ({
        uploadId: fileStorageService.processFile(file, template, metadata),
        name: file.name,
        type: file.type,
        fileData: null,
        uploading: true,
      }));
      setUploads([...uploads, ...newFiles]);
      return newFiles;
    },
    [uploads, setUploads]
  );
  const removeFile = useCallback(
    (uploadId) => {
      const file = uploads.find((upload) => upload.uploadId === uploadId);
      fileStorageService.cancel(uploadId);
      setUploads((previousUploads) => [
        ...previousUploads.filter((upload) => upload.uploadId !== uploadId),
      ]);
      return file;
    },
    [uploads, setUploads]
  );

  const onUploaded = useCallback(
    (event) => {
      const { resourceId } = event;

      setUploads((prevState) => {
        if (
          prevState.find(
            ({ uploadId, uploading }) => uploadId === resourceId && uploading
          )
        ) {
          const newState = prevState.map((upload) => {
            if (upload.uploadId === resourceId && upload.uploading) {
              const uploaded = {
                ...upload,
                original: event.original,
                thumbnail: event.thumbnail,
                transcoded: event.transcoded,
                uploading: false,
              };
              return uploaded;
            }
            return upload;
          });

          uploadCompleted({
            event,
          });

          return newState;
        }

        return prevState;
      });
    },
    [uploadCompleted]
  );

  const onError = useCallback(
    (event) => {
      onFileProcessingFailed(removeFile(event.resourceId));
    },
    [onFileProcessingFailed, removeFile]
  );

  const onFailed = useCallback(
    (event) => {
      onFileUploadFailed(removeFile(event.resourceId));
    },
    [onFileUploadFailed, removeFile]
  );
  useEffect(() => {
    eventService.addListener(ERROR_EVENT_NAME, onError);
    websocket.addListener(SUCCESS_EVENT_NAME, onUploaded);
    websocket.addListener(FAILED_EVENT_NAME, onFailed);
    return () => {
      websocket.removeListeners(SUCCESS_EVENT_NAME);
      websocket.removeListeners(FAILED_EVENT_NAME);
      eventService.removeListener(ERROR_EVENT_NAME, onError);
    };
  }, [
    uploads,
    uploadCompleted,
    onFileUploadFailed,
    onFileProcessingFailed,
    setUploads,
    onUploaded,
    onError,
    onFailed,
    ERROR_EVENT_NAME,
    FAILED_EVENT_NAME,
    SUCCESS_EVENT_NAME,
  ]);

  return { addFile, removeFile, uploads };
}
