import escapeRegExp from "lodash/escapeRegExp";

import fstgId from "@shared/helpers/fstgId";
import backend from "@shared/services/backendClient";

const filesUploadStatus = new Map();
const mapUploadIds = {};
const sectionUploadedFiles = {};
const sectionIdFiles = {};

const getFilesUploadStatus = (filesUploadStatusId) =>
  filesUploadStatus.get(filesUploadStatusId);

const getFilenameAndExtension = (name) => {
  const index = name.lastIndexOf(".");
  const fileExtension = index < 0 ? "" : name.substr(index);
  const fileName = name.substring(0, name.lastIndexOf(".")) || name;
  let isVersionMentionedInFile = false;
  const version = fileName.split("_v").pop();
  const hasVersion = fileName.indexOf("_v") > -1;
  if (version && hasVersion) {
    isVersionMentionedInFile = /^_v[0-9]+$/.test(`_v${version}`);
  }
  const fileNameWithoutVersion = isVersionMentionedInFile
    ? `${fileName.substring(0, fileName.lastIndexOf("_v"))}${fileExtension}`
    : name;
  const fileNameWithoutVersionAndExtension = isVersionMentionedInFile
    ? `${fileName.substring(0, fileName.lastIndexOf("_v"))}`
    : fileName;
  const fileRegxFormat = new RegExp(
    `${escapeRegExp(fileNameWithoutVersionAndExtension)}_v[0-9]+${escapeRegExp(
      fileExtension
    )}`
  );
  return {
    fileNameWithoutVersion,
    fileExtension,
    fileName,
    isVersionMentionedInFile,
    fileRegxFormat,
    fileNameWithoutVersionAndExtension,
  };
};

const updateSectionIdFiles = (name, sectionId, sameFiles) => {
  if (sameFiles.length) {
    sameFiles.pop();
  }
  const otherFileNames = sectionIdFiles[sectionId].files.filter(
    (file) => file.name !== name
  );
  return {
    sameFilesCount: sameFiles.length,
    allFiles: otherFileNames.concat(sameFiles),
  };
};

const hasSameFileUploaded = ({ processingFile = {}, isUpdate }) => {
  const sectionId = processingFile.sectionId;
  if (sectionIdFiles[sectionId]?.files) {
    const { files } = sectionIdFiles[sectionId];
    const uploadedFiles = files.filter(
      (sectionFile) => sectionFile.name === processingFile.name
    );

    if (isUpdate) {
      const { allFiles } = updateSectionIdFiles(
        processingFile.name,
        sectionId,
        uploadedFiles
      );
      sectionIdFiles[sectionId].files = allFiles;
    } else {
      return uploadedFiles.length > 1;
    }
  }
  return false;
};

const setSectionIdFiles = (sectionId, file) => {
  if (sectionIdFiles[sectionId]) {
    sectionIdFiles[sectionId].files.push(file);
  } else {
    sectionIdFiles[sectionId] = {};
    sectionIdFiles[sectionId].files = [file];
  }
};

const getFileNames = (localFiles, filesUploadStatusId, sectionId) => {
  const fileNames = [];
  const groupByFiles = {};
  const allFiles = [];
  localFiles.forEach((file) => {
    const {
      fileExtension,
      fileNameWithoutVersionAndExtension,
      fileNameWithoutVersion,
      fileRegxFormat,
    } = getFilenameAndExtension(file.name);
    const fileAttributes = {
      file,
      fileExtension,
      fileRegxFormat,
      fileNameWithoutVersionAndExtension,
      fileNameWithoutVersion,
    };
    if (!fileNames.includes(fileNameWithoutVersion)) {
      fileNames.push(fileNameWithoutVersion);
      setSectionIdFiles(sectionId, {
        ...fileAttributes,
        name: file.name,
        filesUploadStatusId,
      });
    }
    if (groupByFiles[fileNameWithoutVersion]) {
      groupByFiles[fileNameWithoutVersion].files.push({
        ...fileAttributes,
        name: file.name,
      });
    } else {
      allFiles.push({
        ...fileAttributes,
      });
      groupByFiles[fileNameWithoutVersion] = {};
      groupByFiles[fileNameWithoutVersion].files = [
        { name: file.name, isProcessed: true },
      ];
    }
  });
  sectionUploadedFiles[filesUploadStatusId] = {
    allFiles,
    groupByFiles,
  };
  return fileNames;
};

const setFilesUploadStatus = async (files, sectionId) => {
  const filesUploadStatusId = fstgId.generate();
  const fileNames = getFileNames(files, filesUploadStatusId, sectionId);
  sectionUploadedFiles[filesUploadStatusId].matchedFiles = await backend.post(
    `/files/sections/${sectionId}/match-by-names`,
    {
      names: fileNames,
    }
  );
  filesUploadStatus.set(filesUploadStatusId, {
    filesUploadedIds: [],
    filesCompletedIds: [],
    groupByFiles: sectionUploadedFiles[filesUploadStatusId].groupByFiles,
    hashUploadIdsAndFileIds: {},
  });
  return {
    allFiles: sectionUploadedFiles[filesUploadStatusId].allFiles,
    filesUploadStatusId,
  };
};

const updateFileUploadStatus = ({
  filesUploadStatusId,
  uploadId,
  isInProgress,
  fileId,
}) => {
  const id = isInProgress ? filesUploadStatusId : mapUploadIds[uploadId];
  if (id) {
    const {
      filesCompletedIds,
      filesUploadedIds,
      hashUploadIdsAndFileIds,
      groupByFiles,
    } = filesUploadStatus.get(id);
    if (fileId) {
      hashUploadIdsAndFileIds[uploadId] = fileId;
    }
    if (isInProgress) {
      mapUploadIds[uploadId] = filesUploadStatusId;
      filesUploadedIds.push(uploadId);
      filesUploadStatus.set(id, {
        filesUploadedIds,
        filesCompletedIds,
        hashUploadIdsAndFileIds,
        groupByFiles,
      });
    } else {
      filesCompletedIds.push(uploadId);
      filesUploadStatus.set(id, {
        filesUploadedIds,
        filesCompletedIds,
        hashUploadIdsAndFileIds,
        groupByFiles,
      });
    }
  }
};

const removeFileUploadStatus = (uploadId) => {
  if (mapUploadIds[uploadId]) {
    const {
      filesUploadedIds,
      filesCompletedIds,
      hashUploadIdsAndFileIds,
      groupByFiles,
    } = filesUploadStatus.get(mapUploadIds[uploadId]);

    const UpdatedFilesUploadIds = filesUploadedIds.filter(
      (existingUploadId) => existingUploadId !== uploadId
    );
    delete hashUploadIdsAndFileIds[uploadId];
    filesUploadStatus.set(mapUploadIds[uploadId], {
      filesUploadedIds: UpdatedFilesUploadIds,
      filesCompletedIds,
      hashUploadIdsAndFileIds,
      groupByFiles,
    });
  }
};

const handleNextFileUpload = (file, uploadId) => {
  const {
    fileExtension,
    fileNameWithoutVersionAndExtension,
    fileNameWithoutVersion,
  } = getFilenameAndExtension(file.name);
  let uploadFile = { id: null, file: null };
  if (mapUploadIds[uploadId]) {
    const {
      groupByFiles,
      filesUploadedIds,
      filesCompletedIds,
      hashUploadIdsAndFileIds,
    } = filesUploadStatus.get(mapUploadIds[uploadId]);
    const files = groupByFiles[fileNameWithoutVersion].files.filter(
      (file) => !file.isProcessed
    );
    if (files.length > 0) {
      uploadFile = {
        id: mapUploadIds[uploadId],
        file: files[0].file,
        stepIds: groupByFiles[fileNameWithoutVersion].stepIds,
      };
      groupByFiles[fileNameWithoutVersion].files = files.slice(1, files.length);
      filesUploadStatus.set(mapUploadIds[uploadId], {
        filesUploadedIds,
        filesCompletedIds,
        hashUploadIdsAndFileIds,
        groupByFiles,
      });
    }
  }
  return {
    fileExtension,
    fileNameWithoutVersionAndExtension,
    uploadFile,
    fileNameWithoutVersion,
  };
};

const setStepIds = (id, files) => {
  const { filesUploadedIds, filesCompletedIds, hashUploadIdsAndFileIds } =
    filesUploadStatus.get(id);
  filesUploadStatus.set(id, {
    filesUploadedIds,
    filesCompletedIds,
    hashUploadIdsAndFileIds,
    groupByFiles: files,
  });
};

const wasVersionUploaded = (processingFile) => {
  for (const { hashUploadIdsAndFileIds } of filesUploadStatus.values()) {
    if (processingFile.progress.uploadId in hashUploadIdsAndFileIds) {
      return true;
    }
  }
  return false;
};

const getStepIds = (existingFile, steps) => {
  const lastVersion = existingFile.versions[existingFile.versions.length - 1];
  const reviewStepIds = lastVersion.reviews.map((review) => review.stepId);
  const lastReviewStepId = steps
    .map((step) => step.id)
    .reverse()
    .find((stepId) => reviewStepIds.includes(stepId));
  const firstStepId = steps.length > 0 ? steps[0].id : null;
  const stepId = lastReviewStepId || firstStepId;
  const updatedStepIds = stepId ? [stepId] : [];
  return updatedStepIds;
};
const validateVersionFile = (
  uploadFile,
  isDragAndDrop,
  stepIds,
  filesUploadStatusId,
  steps
) => {
  const { fileNameWithoutVersion } = uploadFile;
  const { matchedFiles, groupByFiles } =
    sectionUploadedFiles[filesUploadStatusId];

  const existingFile = matchedFiles[fileNameWithoutVersion];
  if (existingFile) {
    const updatedStepIds = getStepIds(existingFile, steps);
    groupByFiles[fileNameWithoutVersion].stepIds = isDragAndDrop
      ? updatedStepIds
      : stepIds;
    setStepIds(filesUploadStatusId, groupByFiles);
    return {
      isNewVersion: true,
      existingFile,
      updatedStepIds: isDragAndDrop ? updatedStepIds : stepIds,
    };
  }
  groupByFiles[fileNameWithoutVersion].stepIds = stepIds;
  setStepIds(filesUploadStatusId, groupByFiles);
  return {
    isNewVersion: false,
    stepIds,
  };
};

export default {
  handleNextFileUpload,
  removeFileUploadStatus,
  hasSameFileUploaded,
  updateFileUploadStatus,
  wasVersionUploaded,
  setFilesUploadStatus,
  validateVersionFile,
  getFilesUploadStatus,
};
