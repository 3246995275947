import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { fileProp } from "@shared/props/file";

import FileCardThumbnail from "@supporting/pages/onboardingWizard/UploadFiles/FileCardThumbnail";

function UploadedFilesContainer({ files }) {
  if (files.length === 0) {
    return null;
  }
  return (
    <Box display="flex" alignSelf="flex-start" flexWrap="wrap">
      {files.map((file) => (
        <FileCardThumbnail key={file._id} file={file} />
      ))}
    </Box>
  );
}

UploadedFilesContainer.propTypes = {
  files: PropTypes.arrayOf(fileProp),
};

UploadedFilesContainer.defaultProps = {
  files: [],
};

export default UploadedFilesContainer;
