import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

const teamRoles = new Map();

const onChangeTeamRoles = (teamId) => {
  eventService.emitEvent({
    eventName: EVENT_NAMES.TEAM.ROLES.UPDATED,
    eventData: { teamId, teamRoles: teamRoles.get(teamId) },
  });
};

const fetchTeamRoles = async (teamId) => {
  if (teamRoles.get(teamId)) {
    return teamRoles.get(teamId);
  }
  const newTeamRoles = await backend.get(`/teams/${teamId}/roles`);
  teamRoles.set(teamId, newTeamRoles);
  onChangeTeamRoles(teamId);
  return teamRoles.get(teamId);
};

const updateTeamRole = async (teamId, roleId, update) => {
  const newTeamRole = await backend.put(
    `/teams/${teamId}/roles/${roleId}`,
    update
  );

  const currentTeamRoles = teamRoles.get(teamId) || [];
  const updatedTeamRoles = currentTeamRoles.map((teamRole) =>
    teamRole._id === newTeamRole._id ? newTeamRole : teamRole
  );
  teamRoles.set(teamId, updatedTeamRoles);

  onChangeTeamRoles(teamId);
  return newTeamRole;
};

const removeTeamRole = async (teamId, roleId) => {
  await backend.delete(`/teams/${teamId}/roles/${roleId}`);

  const currentTeamRoles = teamRoles.get(teamId) || [];
  const updatedTeamRoles = currentTeamRoles.filter(
    (teamRole) => teamRole._id !== roleId
  );
  teamRoles.set(teamId, updatedTeamRoles);

  onChangeTeamRoles(teamId);
  return teamRoles[teamId];
};

const createTeamRole = async (teamId, properties) => {
  const newTeamRole = await backend.post(`/teams/${teamId}/roles`, {
    roleName: properties.roleName,
    permissions: properties.permissions,
  });

  teamRoles.set(teamId, (teamRoles.get(teamId) || []).concat(newTeamRole));

  onChangeTeamRoles(teamId);
  return newTeamRole;
};

export default {
  fetchTeamRoles,
  updateTeamRole,
  removeTeamRole,
  createTeamRole,
};
