import PropTypes from "prop-types";
import { useState, useId, ReactElement } from "react";

import ChevronRight from "@mui/icons-material/ChevronRight";
import { MenuItem } from "@mui/material";
import noop from "lodash/noop";

import { useMediaQuery } from "@shared/hooks";

import SubMenuChildrenWrapper from "./SubMenuChildrenWrapper";

const openCallbacks = (openMethod, open, close) => {
  switch (openMethod) {
    case "hover":
      return {
        onMouseEnter: open,
        onMouseLeave: close,
      };
    case "click":
      return {
        onClick: open,
      };
    /* istanbul ignore next */
    default:
      return {};
  }
};

/**
 * UIKit SubMenuItem Component
 * @description A standard menu item component for reuse in overflow menus.
 * @param {{
 * menuItem: ReactElement,
 * subMenuContents: ReactElement,
 * }} props - Component props
 * @param {ReactElement} [props.menuItem] [Required] - The text shown in menu item, can be a string or a fragment containing <ListItemIcon> and <ListItemText>.
 * @param {object} [props.menuItemProps] - Additional props to be passed to the MenuItem component that opens the sub menu.
 * @param {Function} [props.onOpen] - A function to be called when the submenu is opened.
 * @param {ReactElement} [props.children] [Required] - The contents of the submenu.
 *   It can either be a list of `MenuItem` components or any other component, in which case, it will rendered inside a Paper.
 * @returns {ReactElement} - React component
 * @example Simple submenu item with text
 * <SubMenuItem
 *  menuItem={"My Menu Item "}
 * >
 *   <MenuItem>My Submenu Item</MenuItem>
 * </SubMenuItem>
 * @example Submenu item with custom content
 * <SubMenuItem
 * menuItem={"My Menu Item "}
 * >
 *  <Box><p>My custom content</p></Box>
 * </SubMenuItem>
 */
const SubMenuItem = ({
  menuItem,
  onOpen,
  children,
  menuItemProps,
  openMethod,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuItemId = useId();

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const openSubmenu = (event) => {
    setAnchorEl(event.currentTarget);
    onOpen();
  };

  const closeSubmenu = () => {
    setAnchorEl(null);
  };

  const isSubmenuOpen = Boolean(anchorEl);

  return (
    <MenuItem
      id={menuItem}
      {...openCallbacks(openMethod, openSubmenu, closeSubmenu)}
      aria-haspopup="true"
      {...menuItemProps}
    >
      {menuItem}
      <ChevronRight fontSize="small" />
      <SubMenuChildrenWrapper
        open={isSubmenuOpen}
        anchorEl={anchorEl}
        onClose={closeSubmenu}
        labelledBy={menuItemId}
        anchorOrigin={{
          vertical: isMobile ? "bottom" : "top",
          horizontal: isMobile ? "center" : "right",
        }}
        transformOrigin={{
          vertical: isMobile ? "top" : "top",
          horizontal: isMobile ? "center" : "left",
        }}
      >
        {children}
      </SubMenuChildrenWrapper>
    </MenuItem>
  );
};
SubMenuItem.propTypes = {
  menuItem: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onOpen: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  menuItemProps: PropTypes.object,
  openMethod: PropTypes.oneOf(["hover", "click"]),
};

SubMenuItem.defaultProps = {
  onOpen: noop,
  menuItemProps: {},
  openMethod: "hover",
};

export default SubMenuItem;
