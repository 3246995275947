export const TEAM_PERMISSIONS = Object.freeze({
  SEE_ALL_PROJECTS: "seeAllProjects",
  MANAGE_TEAM: "manageTeam",
  MANAGE_BILLING: "manageBilling",
  MANAGE_BRANDING: "manageBranding",
  MANAGE_PROJECTS: "manageProjects",
  UPLOAD_FILES: "uploadFiles",
  MANAGE_TEMPLATES: "manageTemplates",
  USE_APPS: "useApps",
  MANAGE_AUTOMATIONS: "manageAutomations",
  VIEW_INSIGHTS: "viewInsights",
});

export const TEAM_ROLES = Object.freeze({
  ADMIN: "ADMIN",
  LITE_MEMBER: "LITE_MEMBER",
  FULL_MEMBER: "FULL_MEMBER",
});
