(function (g, u, i, d, e) {
  g[e] = g[e] || [];
  const f = u.getElementsByTagName(i)[0];
  const k = u.createElement(i);
  k.async = true;
  k.src =
    "https://static.userguiding.com/media/user-guiding-815786080ID-embedded.js";
  f.parentNode.insertBefore(k, f);
  if (g[d]) {
    return;
  }
  const ug = (g[d] = { q: [] });
  ug.c = function (n) {
    return function () {
      ug.q.push([n, arguments]);
    };
  };
  const m = [
    "previewGuide",
    "finishPreview",
    "track",
    "identify",
    "triggerNps",
    "hideChecklist",
    "launchChecklist",
  ];
  for (let j = 0; j < m.length; j += 1) {
    ug[m[j]] = ug.c(m[j]);
  }
})(window, document, "script", "userGuiding", "userGuidingLayer");
