import PropTypes from "prop-types";

import { userProp } from "./authentication";
import { fileDataProp } from "./fileData";
import { reviewProp } from "./review";

export const UPLOAD_STATES = {
  PENDING: "PENDING",
  UPLOADING: "UPLOADING",
  TRANSCODING: "TRANSCODING",
  IMPORTING: "IMPORTING",
  UPLOADING_FROM_PLUGIN: "UPLOADING_FROM_PLUGIN",
  FAILED: "FAILED",
};

export const uploadStatesProp = PropTypes.oneOf(Object.keys(UPLOAD_STATES));

export const versionProp = PropTypes.shape({
  id: PropTypes.string,
  number: PropTypes.number,
  name: PropTypes.string,
  mediaType: PropTypes.string,
  fileDataIds: PropTypes.arrayOf(PropTypes.string),
  original: fileDataProp,
  thumbnail: fileDataProp,
  uploadedBy: PropTypes.string,
  uploadedByUser: userProp,
  createdAt: PropTypes.string,
  reviews: PropTypes.arrayOf(reviewProp),
  uploadStatus: PropTypes.string,
});

export const fileProp = PropTypes.shape({
  _id: PropTypes.string,
  teamId: PropTypes.string,
  projectId: PropTypes.string,
  sectionId: PropTypes.string,
  name: PropTypes.string,
  versions: PropTypes.arrayOf(versionProp),
  activeVersion: PropTypes.oneOfType([PropTypes.string, reviewProp]),
  hasModifiedName: PropTypes.bool,
  created: PropTypes.string,
  isApproved: PropTypes.bool,
  isLocked: PropTypes.bool,
  role: PropTypes.shape({
    isOwner: PropTypes.bool,
  }),
  permissions: PropTypes.shape({
    canDelete: PropTypes.bool,
    canRename: PropTypes.bool,
    canApproveFile: PropTypes.bool,
  }),
  isProcessing: PropTypes.bool,
  progress: PropTypes.shape({
    uploadId: PropTypes.string,
    versionNumber: PropTypes.number,
    state: uploadStatesProp,
  }),
});
