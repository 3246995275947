import { useCallback, useState, useRef, useEffect } from "react";

import stepShareLinkService from "@feedback/services/stepShareLinkService";
import copy from "copy-to-clipboard";

import { instance as analytics } from "@shared/services/analytics";

/**
 * Use Share Link hook
 *
 * @description This hook is being used to control the share link feature. It can
 * be used to generate a share link for a file, copy the link or show the toastr
 * messages according to its success state.
 * @typedef FstgId
 * @param {Object} props - [Required] The props object that you need to pass.
 * @param {FstgId} props.stepId - [Required] StepId that you would like to generate the link from.
 * @param {FstgId} props.fileId - [Optional] FileId that you would like to generate the link from.
 * @param {FstgId} props.reviewId - [Optional] ReviewId that you would like to generate the link from.
 * @param {string?} props.link - [Optional] An override link that can be used directly without
 *  generating the link.
 * @param {Function} props.onClose - [Required] Function that you would like to execute the
 *  handling the copy operation.
 * @returns {{
 *  copyShareLink: Function,
 *  sharedLink: string,
 *  wasCopiedSuccessful: boolean,
 * }} Returns an object that contain the share link,
 * function to copy the share link to clipboard and a boolean value if
 * the value is copied successfully or not.
 */
function useShareLink({ stepId, fileId, reviewId, link, onClose }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const timeout = useRef(null);

  const [wasCopiedSuccessful, setWasCopiedSuccessful] = useState(false);

  const sharedLink =
    link || stepShareLinkService.getShareLinkUrl({ stepId, fileId, reviewId });

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  const copyShareLink = useCallback(
    (event, preventCloseOnSuccess) => {
      const result = copy(sharedLink, { format: "text/plain" });

      setWasCopiedSuccessful(result);
      setAnchorEl(event.currentTarget);

      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        setAnchorEl(null);
        if (onClose && !preventCloseOnSuccess) {
          onClose();
        }
      }, 2000);

      if (result) {
        if (sharedLink.indexOf("/file/") > 0) {
          analytics.track(
            analytics.ACTION.COPIED,
            analytics.CATEGORY.FILE_REVIEW_LINK
          );
        } else if (sharedLink.indexOf("/batch-review") > 0) {
          analytics.track(
            analytics.ACTION.COPIED,
            analytics.CATEGORY.BATCH_REVIEW_LINK
          );
        } else {
          analytics.track(
            analytics.ACTION.COPIED,
            analytics.CATEGORY.STEP_REVIEW_LINK
          );
        }
      }
    },
    [onClose, sharedLink]
  );

  return {
    copyShareLink,
    sharedLink,
    wasCopiedSuccessful,
    anchorEl,
  };
}

export default useShareLink;
