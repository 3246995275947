import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import { Box } from "@mui/material";
import EmailInput from "@supporting/components/EmailInput/EmailInput";

import { Text } from "@shared/UIKit";

export default function EmailContent({
  title,
  buttonText,
  autoFocus,
  isLoading,
  defaultValue,
  setAuthState,
  authState,
}) {
  const { t } = useTranslation();
  return (
    <Box display="flex" flexDirection="column">
      {title && (
        <Text
          sx={{ mb: 5 }}
          variant="body2"
          translate={title}
          textAlign="center"
        />
      )}
      <EmailInput
        updateAuthState={setAuthState}
        defaultErrorMessage="FORM.EMAIL_CURRENT.MESSAGE_REQUIRED"
        label="FORM.EMAIL.LABEL"
        autoFocusEmail={autoFocus}
        defaultValue={defaultValue}
      />
      <Button
        fullWidth
        variant="contained"
        type="submit"
        loading={isLoading}
        disabled={!authState.isEmailValid}
        data-testid="workspace-auth-dialog-email-button"
      >
        {t(buttonText)}
      </Button>
    </Box>
  );
}
EmailContent.propTypes = {
  setAuthState: PropTypes.func.isRequired,
  authState: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  defaultValue: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  autoFocus: PropTypes.bool,
};
EmailContent.defaultProps = {
  defaultValue: "",
  title: "",
  buttonText: "CORE.NEXT",
  autoFocus: true,
  isLoading: false,
};
