import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import CommentCount from "@feedback/components/CommentCount/CommentCount";
import ReviewDecisionIndicatorList from "@feedback/components/ReviewDecisionIndicatorList/ReviewDecisionIndicatorList";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Box, ShareLink } from "@shared/UIKit";

import { useMediaQuery, useNavigation } from "@shared/hooks";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import ReviewCardMenu from "../../../ReviewCard/ActiveReviewCard/ReviewCardMenu/ReviewCardMenu";

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
  },
  shareLink: {
    marginRight: 8,
  },
});

const DefaultVersionReviewCard = ({
  team,
  file,
  version,
  step,
  project,
  review,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const { routeNames, goTo } = useNavigation();

  const openVersionReview = useCallback(
    (event) => {
      event.stopPropagation();
      goTo(routeNames.REVIEW_LINK, {
        params: {
          stepId: review.stepId,
          reviewId: review.id,
        },
      });
    },
    [goTo, routeNames.REVIEW_LINK, review.stepId, review.id]
  );

  const handleReviewClicked = useCallback(
    (event) => {
      event.stopPropagation();
      if (event.target?.getAttribute("data-open-review-link")) {
        openVersionReview(event);
      }
    },
    [openVersionReview]
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      data-testid="version-review"
      alignItems="center"
      height="100%"
      px={1.25}
      className={classes.root}
      onClick={handleReviewClicked}
      data-open-review-link="true"
    >
      <Box
        flexGrow={1}
        width="100%"
        display="flex"
        alignItems="center"
        minWidth="0%"
        data-open-review-link="true"
      >
        <ReviewDecisionIndicatorList
          variant="dashboard"
          review={review}
          indicatorPosition="right"
          isApproved={false}
          data-testid="review-decision-indicator-list"
        />
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          variant="text"
          color="success"
          size="extraSmall"
          onClick={openVersionReview}
          data-testid="version-review-card-open-review-link"
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          {t("FILE_REVIEW.OPEN_REVIEW")}
        </Button>
        {!isMobile && (
          <>
            <CommentCount review={review} isReviewCard={false} />

            <ShareLink
              stepId={step.id}
              reviewId={review.id}
              tooltipDirection="top"
              isReviewCard
              small
              iconButtonProps={{
                edge: "end",
              }}
            />
          </>
        )}

        <ReviewCardMenu
          team={team}
          project={project}
          step={step}
          file={file}
          version={version}
          review={review}
        />
      </Box>
    </Box>
  );
};

DefaultVersionReviewCard.propTypes = {
  team: teamProp.isRequired,
  file: fileProp.isRequired,
  step: stepProps.isRequired,
  version: versionProp.isRequired,
  review: reviewProp.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
};

export default DefaultVersionReviewCard;
