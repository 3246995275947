import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Dialog, Text } from "@shared/UIKit";

function RejectRevokeAdminRoleDialog({ cancel, isOpen }) {
  const { t } = useTranslation();

  return (
    <Dialog
      cancel={cancel}
      open={isOpen}
      title={t("TEAM.REVOKE_ADMIN.REJECTED.HEADLINE")}
      hideBackdrop={false}
    >
      <Box mb={2.5}>
        <Text variant="body2">
          {t("TEAM.REVOKE_ADMIN.REJECTED.DESCRIPTION")}
        </Text>
      </Box>
    </Dialog>
  );
}

RejectRevokeAdminRoleDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default RejectRevokeAdminRoleDialog;
