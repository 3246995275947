import NiceModal from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Box } from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import useTrialEndedDialog from "@supporting/hooks/useTrialEndedDialog";

import { Text } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";

import ChangePlans from "./ChangePlans";
import DemoDialog from "./DemoDialog";
import {
  translateKeyTitle,
  isTrialAboutToEnd,
  adaptPlanName,
  hidePlanLimitCard,
  shouldRequestDemo,
} from "./planLimit.helper";
import useLimitComponents from "./useLimitComponents";

const PAGE = "Plan limit component";

const getBackgroundColor = (approachingLimit, isOnTrialOrFreePlan) => {
  if (approachingLimit) {
    return `linear-gradient(90.25deg, rgba(253, 71, 1, 0.18) 20.8%, rgba(253, 71, 1, 0.15) 45.8%, rgba(253, 71, 1, 0.1) 55.8%, rgba(253, 171, 76, 0.05) 74.18%, rgba(253, 171, 76, 0.1) 97.18%, rgba(191, 237, 237, 0.1) 99.895%)`;
  }
  if (isOnTrialOrFreePlan) {
    return `linear-gradient(90.25deg, rgba(151, 71, 255, 0.1) 20.8%, rgba(151, 71, 255, 0.15) 40.8%, rgba(151, 71, 255, 0.1) 54.8%, rgba(253, 171, 76, 0.05) 74.18%, rgba(253, 171, 76, 0.1) 97.18%, rgba(191, 237, 237, 0.1) 99.895%)`;
  }
  return "var(--mui-palette-grey-50)";
};

export default function PlanLimitCard({ project }) {
  useTrialEndedDialog(PAGE);
  const { t } = useTranslation();
  const team = useSelectedTeam();
  const { limitComponents } = useLimitComponents({
    team,
    project,
  });
  const adaptedPlanName = adaptPlanName(
    team.subscription.basePrice.name,
    team.subscription.basePrice.description
  );
  const isTrialAboutToEndFlag = isTrialAboutToEnd(
    team.subscription.trialEndDate * 1000
  );
  const isOnTrial = team.subscription.status === "trialing";
  const isOnTrialOrFreePlan = isOnTrial || adaptedPlanName === "Free";
  const approachingLimit = isOnTrial
    ? isTrialAboutToEndFlag
    : limitComponents.length > 0;

  if (hidePlanLimitCard({ adaptedPlanName, approachingLimit, isOnTrial })) {
    return null;
  }

  const shouldRequestDemoFlag = shouldRequestDemo(
    isOnTrialOrFreePlan,
    team.businessRegion
  );
  const handleOpenRequestDemo = () => {
    NiceModal.show(DemoDialog, { businessRegion: team.businessRegion });
  };

  return (
    <Box
      gap={1}
      display="flex"
      flexDirection={shouldRequestDemoFlag ? "column" : "row"}
      justifyContent="space-between"
      alignItems="center"
      sx={{
        background: getBackgroundColor(approachingLimit, isOnTrialOrFreePlan),
      }}
      borderRadius={1}
      padding={1}
      mx={2}
      my={1.25}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems={shouldRequestDemoFlag ? "center" : "flex-start"}
      >
        <Text
          color="text.primary"
          fontWeight="fontWeight.bold"
          variant="textSm"
          translate={translateKeyTitle(adaptedPlanName, isOnTrial)}
        />
        <Box
          color={approachingLimit ? "error.main" : "inherit"}
          textAlign={shouldRequestDemoFlag ? "center" : "left"}
        >
          {limitComponents}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignSelf={shouldRequestDemoFlag ? "stretch" : "center"}
      >
        {shouldRequestDemoFlag && (
          <Button
            className="tour__get-free-training"
            sx={{
              color: "text.primary",
              textOverflow: "ellipsis",
              flexWrap: "nowrap",
            }}
            onClick={handleOpenRequestDemo}
          >
            {t("TRIAL.STAT.BUTTON_DEMO")}
          </Button>
        )}
        <ChangePlans teamId={team._id} adaptedPlanName={adaptedPlanName} />
      </Box>
    </Box>
  );
}

PlanLimitCard.propTypes = {
  project: PropTypes.shape(projectProps),
};

PlanLimitCard.defaultProps = {
  project: null,
};
