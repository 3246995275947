import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import useShareLink from "@feedback/hooks/useShareLink";
import Link from "@mui/icons-material/Link";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";

import ShareLinkPopper from "@shared/UIKit/ShareLink/ShareLinkPopper/ShareLinkPopper";

import { fileProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";

const ReviewCardShareLinkMenuItem = forwardRef(
  ({ step, file, review, closeMenu }, ref) => {
    const { t } = useTranslation();

    const { anchorEl, copyShareLink, wasCopiedSuccessful } = useShareLink({
      fileId: file._id,
      stepId: step.id,
      reviewId: review.id,
      onClose: closeMenu,
    });

    return (
      <>
        <MenuItem
          data-testid="review-card-menu-item-copy-review-link"
          onClick={copyShareLink}
          ref={ref}
        >
          <ListItemIcon>
            <Link
              fontSize="small"
              sx={{
                transform: "rotate(-45deg)",
              }}
            />
          </ListItemIcon>
          <ListItemText>{t("FILE_REVIEW.MENU.COPY_REVIEW")}</ListItemText>
        </MenuItem>

        {anchorEl && (
          <ShareLinkPopper
            anchorEl={anchorEl}
            wasCopiedSuccessful={wasCopiedSuccessful}
            open={Boolean(anchorEl)}
          />
        )}
      </>
    );
  }
);

ReviewCardShareLinkMenuItem.displayName = "ReviewCardShareLinkMenuItem";

ReviewCardShareLinkMenuItem.propTypes = {
  step: stepProps.isRequired,
  file: fileProp.isRequired,
  review: reviewProp.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default ReviewCardShareLinkMenuItem;
