import PropTypes from "prop-types";

import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

export default function LoadingSkeleton({ rows }) {
  return (
    <Box
      height={55 * rows}
      data-testid="detail-search-skeletons"
      p={4}
      display="flex"
      flexDirection="column"
      gap={2}
    >
      {Array.from({ length: rows }).map((_, index) => (
        <Box key={index} display="flex" flexDirection="column" gap={2}>
          <Skeleton variant="rectangular" height={15} width={310} />
          <Skeleton variant="rectangular" height={10} width={140} />
        </Box>
      ))}
    </Box>
  );
}
LoadingSkeleton.propTypes = {
  rows: PropTypes.number,
};

LoadingSkeleton.defaultProps = {
  rows: 11,
};
