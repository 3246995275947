import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Box, Button } from "@mui/material";
import LimitReachedDialog from "@supporting/components/LimitReachedDialog/LimitReachedDialog";
import projectService from "@workflow/services/projectService";

import { Text } from "@shared/UIKit";

import { useDialogState } from "@shared/hooks";
import { projectProps } from "@shared/props/project";

function ProjectArchiveBar({ project }) {
  const { t } = useTranslation();
  const {
    isOpen: isReachedLimitDialogOpen,
    openDialog: openReachedLimitDialog,
    closeDialog: closeReachedLimitDialog,
  } = useDialogState();

  const unArchiveProject = async function () {
    const resp = await projectService.unarchiveProject(project);
    if (!resp) {
      openReachedLimitDialog();
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        data-testid="project-archived-bar"
        sx={{
          background: `repeating-linear-gradient(-45deg, var(--mui-color-green-50),var(--mui-color-green-50) 10px,var(--mui-color-green-100) 10px,var(--mui-color-green-100) 20px)`,
        }}
        mx={2}
        mb={1.25}
        p={{ xs: "5px 10px", md: "8px 15px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
        >
          <Text variant="textMd" fontWeight="fontWeight.medium">
            {t("PROJECT.PROJECT.ARCHIVED_BAR.HEADING")}
          </Text>
          <Text variant="textMd" color="text.secondary">
            {t("PROJECT.PROJECT.ARCHIVED_BAR.MESSAGE")}
          </Text>
        </Box>
        {project.permissions.archive && (
          <Button
            onClick={unArchiveProject}
            variant="text"
            color="primary"
            data-testid="project-unarchived-button"
          >
            {t("PROJECT.PROJECT.ARCHIVED_BAR.UNARCHIVE")}
          </Button>
        )}
      </Box>
      {isReachedLimitDialogOpen && (
        <LimitReachedDialog
          selectedLimit="project"
          whileUnarchiving
          cancel={closeReachedLimitDialog}
        />
      )}
    </>
  );
}
ProjectArchiveBar.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
};

export default ProjectArchiveBar;
