import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Link } from "@mui/material";
import noop from "lodash/noop";

import { Tooltip } from "@shared/UIKit";

function TooltipWithLink({ children, onClick, title }) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t(title)}>
      <Link
        onClick={onClick}
        data-testid="cancel-scheduled-sub-button"
        color="info"
      >
        {children}
      </Link>
    </Tooltip>
  );
}

TooltipWithLink.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};

TooltipWithLink.defaultProps = {
  onClick: noop,
  children: null,
};

export default TooltipWithLink;
