import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import AutomationAvatar from "@mui/icons-material/FlashOnRounded";
import { Grid } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Box, RelativeDate, CommentBadge, Text, Avatar } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { userProp } from "@shared/props/authentication";
import { commentShape } from "@shared/props/comment";
import { notificationCenterActivityProps } from "@shared/props/notificationCenter";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import FSTGTypography from "@shared/theme/typography";

import NOTIFICATION_CENTER_ACTIVITIES from "../activities.constants";
import ActivityBadge from "./badge/ActivityBadge";
import ActivityBody from "./body/ActivityBody";
import ActivityTitle from "./title/ActivityTitle";

const useStyles = makeStyles(() => ({
  Box: {
    cursor: "pointer",
    lineHeight: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  BadgeContainer: {
    paddingRight: 12,
  },
}));

function Activity({
  subType,
  createdAt,
  user,
  comment,
  reviewId,
  fileId,
  stepId,
  projectId,
  groupId,
  automationId,
}) {
  const { routeNames, goTo } = useNavigation();

  const classes = useStyles();
  const theme = useTheme();
  const isAutomation = Boolean(automationId);
  const { t } = useTranslation();
  let numberOfUsers = 1;

  const redirectToActivity = useCallback(() => {
    backend.put("/in-app-notifications", { ids: [groupId], isRead: true });

    const params = {
      fileId,
      stepId,
      projectId,
      reviewId,
      commentId: comment && comment.id,
    };

    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.NOTIFICATION_CENTER_NOTIFICATION
    );

    if (params.commentId) {
      goTo(routeNames.REVIEW_LINK_COMMENT, { params });
      eventService.emitEvent({
        eventName: EVENT_NAMES.NOTIFICATION_CENTER.ISSUE.CLICKED,
        eventData: {
          issue: {
            id: params.commentId,
          },
        },
      });
    } else if (reviewId) {
      goTo(routeNames.REVIEW_LINK, { params });
    } else if (fileId) {
      goTo(routeNames.WORKSPACE_ALIAS, { params });
    } else {
      goTo(routeNames.PROJECT_ID, { params });
    }

    eventService.emitEvent({
      eventName: EVENT_NAMES.NOTIFICATION_CENTER.DRAWER.CLOSE_TRIGGERED,
      eventData: {},
    });
  }, [comment, fileId, projectId, stepId, reviewId, groupId]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserNames = () => {
    if (subType === NOTIFICATION_CENTER_ACTIVITIES.COMMENT.LIKED) {
      const likes = comment.likes.filter(
        (like) => like.userId !== comment.authorId
      );

      numberOfUsers = likes.length;
      const lastUser = likes.pop().user;
      let translatedLastUserName = t("COMMENT.LIKE.PARSE_USER_NAME", {
        displayName: lastUser.displayName,
      });
      translatedLastUserName =
        translatedLastUserName.charAt(0).toUpperCase() +
        translatedLastUserName.slice(1);

      if (likes.length === 0) {
        return `${translatedLastUserName} `;
      }

      likes.reverse();
      const otherNames = likes.reduce((acc, like, index) => {
        const { displayName } = like.user;

        if (index === likes.length - 1) {
          return `${acc} ${t("CORE.AND")} ${t("COMMENT.LIKE.PARSE_USER_NAME", {
            displayName,
          })}`;
        }

        return `${acc}, ${t("COMMENT.LIKE.PARSE_USER_NAME", {
          displayName,
        })}`;
      }, "");

      return (
        <>
          {`${translatedLastUserName}`}
          <Text
            variant="subtitle1"
            component="span"
            color={theme.color.gray[500]}
            fontSize={FSTGTypography.fontSize_1_4}
          >
            {otherNames}
          </Text>{" "}
        </>
      );
    }

    return user.fullName;
  };

  return (
    <Box
      mb={2.5}
      className={classes.Box}
      onClick={redirectToActivity}
      data-testid="activity"
    >
      <Grid container>
        <Grid item xs="auto" className={classes.BadgeContainer}>
          <ActivityBadge type={subType}>
            {isAutomation ? (
              <Box
                borderRadius="50%"
                bgcolor="success.main"
                p={1}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <AutomationAvatar color="light" />
              </Box>
            ) : (
              <Avatar user={user} />
            )}
          </ActivityBadge>
        </Grid>
        <Grid item xs={10}>
          <Text
            variant="subtitle2"
            component="span"
            color={theme.color["black-translucent-85"]}
            fontSize={FSTGTypography.fontSize_1_4}
            data-testid="activity-usernames"
          >
            {isAutomation ? t("AUTOMATIONS.BUTTON.TITLE") : getUserNames()}
          </Text>
          <ActivityTitle type={subType} numberOfUsers={numberOfUsers} />
          {comment && (
            <Box pt={0.5}>
              {comment.body && (
                <ActivityBody
                  mentioned={comment.mentioned}
                  body={comment.body}
                />
              )}
              {comment.annotation && comment.annotation.length > 0 && (
                <CommentBadge type="show_annotation" />
              )}
              {comment.attachments && comment.attachments.length > 0 && (
                <CommentBadge
                  type="attachment"
                  filename={comment.attachments[0].original.name}
                />
              )}
            </Box>
          )}

          <Box pt={0.5}>
            <RelativeDate date={new Date(createdAt)} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

Activity.propTypes = {
  id: PropTypes.string.isRequired,
  subType: notificationCenterActivityProps.isRequired,
  createdAt: PropTypes.string.isRequired,
  user: userProp.isRequired,
  comment: PropTypes.shape(commentShape),
  reviewId: PropTypes.string,
  fileId: PropTypes.string,
  activeVersion: PropTypes.string,
  stepId: PropTypes.string,
  fileVersionId: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  automationId: PropTypes.string,
};

Activity.defaultProps = {
  comment: null,
  reviewId: null,
  activeVersion: null,
  fileId: null,
  stepId: null,
  fileVersionId: null,
  automationId: null,
};

export default Activity;
