import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { LoadingButton } from "@mui/lab";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme, makeStyles } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";

import { Text, Box } from "@shared/UIKit";

import { EMAIL_REGEX } from "@shared/constants/validation";
import backendClient from "@shared/services/backendClient";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    maxWidth: 400,
  },
  button: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    minHeight: 240,
  },
}));

function SecurityWhitelistUser() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [state, setState] = useState({
    email: "",
    isLoading: false,
    message: null,
    isValid: false,
    confirm: false,
  });

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (!state.confirm) {
        setState((prevState) => ({
          ...prevState,
          message: {
            type: "warning",
            body: "Make sure the account you are whitelisting is legitimate: check the app usage in Fullstory for malicious actions and verify the customer information (check the company website, try to find the person in LinkedIn, etc.).",
          },
          confirm: true,
        }));
        return;
      }

      setState((prevState) => ({
        ...prevState,
        isLoading: true,
        message: null,
      }));

      try {
        await backendClient.post("/admin/security/whitelist-user", {
          email: state.email,
        });

        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          message: {
            type: "success",
            body: `User ${state.email} was whitelisted.`,
          },
          confirm: false,
        }));
      } catch (response) {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          message: {
            type: "error",
            body:
              response.status === 404
                ? `User ${state.email} doesn't exist.`
                : response.message,
          },
          confirm: false,
        }));
      }
    },
    [state.email, state.confirm]
  );

  const handleEmailChange = useCallback((event) => {
    const { value } = event.currentTarget;
    setState((prevState) => ({
      ...prevState,
      email: value,
      isValid: EMAIL_REGEX.test(value),
    }));
  }, []);

  return (
    <>
      <Title>Security: whitelist user</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={classes.paper}>
            <form className={classes.formContainer} onSubmit={handleSubmit}>
              <Box display="flex" mt={2} alignItems="center">
                <Box
                  mt={2.5}
                  ml={0.5}
                  mr={2}
                  lineHeight={theme.typography.textXs.lineHeight}
                >
                  <EmailOutlined />
                </Box>
                <TextField
                  id="email"
                  label={t("FORM.EMAIL.WORK_LABEL")}
                  value={state.email}
                  onChange={handleEmailChange}
                  disabled={state.confirm || state.isLoading}
                  error={!state.isValid}
                  fullWidth
                />
              </Box>

              <Box mt={2} ml={5}>
                <LoadingButton
                  disabled={!state.isValid}
                  loading={state.isLoading}
                  size="large"
                  variant="contained"
                  className={classes.button}
                  type="submit"
                >
                  {state.confirm ? "Click again to confirm" : "Whitelist user"}
                </LoadingButton>
              </Box>
              {state.message && (
                <Box my={2} ml={5}>
                  <Text color={`${state.message.type}.main`}>
                    {state.message.body}
                  </Text>
                </Box>
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default SecurityWhitelistUser;
