/* istanbul ignore file */

import drawingService from "@feedback/services/drawing/drawing";
import reviewReportService from "@feedback/services/reviewReportService";
import reviewService from "@feedback/services/reviewService";
import * as BillingService from "@supporting/services/billingService";
import * as billingUtilService from "@supporting/services/billingUtilService";
import * as fileStorageService from "@supporting/services/fileStorageService";
import notificationCenterService from "@supporting/services/notificationCenterService";
import * as teamService from "@supporting/services/team";
import dashboardSectionFilesCache from "@workflow/services/dashboardSectionFilesCache";
import dashboardSectionsCache from "@workflow/services/dashboardSectionsCache";
import * as fileService from "@workflow/services/fileService";
import pendingReviewsService from "@workflow/services/pendingReviewsService";
import projectReviewService from "@workflow/services/projectReviewService";
import projectService from "@workflow/services/projectService";
import projectSwitcherService from "@workflow/services/projectSwitcherService";
import projectTemplateService from "@workflow/services/projectTemplateService";
import * as stepService from "@workflow/services/stepService";

import * as Analytics from "@shared/services/analytics";
import browserTabId from "@shared/services/browserTabId";
import * as HealthMetrics from "@shared/services/HealthMetrics";
import * as LocalisationService from "@shared/services/localisationService";
import * as Logger from "@shared/services/logger";
import securityService from "@shared/services/securityService";
import * as websocket from "@shared/services/websocket";

export default async function () {
  browserTabId.initialize();
  Logger.initialize();
  HealthMetrics.initialize();
  Analytics.initialize();
  websocket.initialize();
  securityService.initialize();
  fileStorageService.initialize();
  fileService.initialize();
  billingUtilService.initialize();
  BillingService.initialize();
  stepService.initialize();
  reviewService.initialize();
  projectTemplateService.initialize();
  projectReviewService.initialize();
  projectSwitcherService.initialize();
  teamService.initialize();
  projectService.initialize();
  pendingReviewsService.initialize();
  drawingService.init();
  notificationCenterService.initialize();
  reviewReportService.initialize();
  dashboardSectionFilesCache.initialize();
  dashboardSectionsCache.initialize();
  await LocalisationService.initialize();
}
