import { memo } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Link, SvgIcon, Container } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Text } from "@shared/UIKit";

import FSTGTypography from "@shared/theme/typography";

import EarlyAccess from "@assets/img/icons/outline-early-access-empty.svg";

const useStyles = makeStyles(() => ({
  Container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  Icon: {
    width: "100px",
    height: "100px",
  },
  NormalText: {
    margin: "15px 0",
    textAlign: "center",
  },
}));

function EmptySection() {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container
      className={classes.Container}
      data-testid="early-access-empty-section"
    >
      <SvgIcon
        color="disabled"
        component={EarlyAccess}
        className={classes.Icon}
        viewBox="0 0 73 79"
      />
      <Text
        fontSize="1.5rem"
        className={classes.NormalText}
        variant="body1"
        color={theme.color.gray[600]}
      >
        <Trans
          components={{
            anchor: (
              <Link
                className="launch-intercom-chat"
                href={t("URLS.HELLO_MAIL")}
                target="_blank"
              />
            ),
            bold: (
              <Text
                component="span"
                fontWeight={FSTGTypography.fontWeightMedium}
              />
            ),
          }}
        >
          {t("SETTINGS.EARLY_ACCESS.EMPTY_STATE.DESCRIPTION")}
        </Trans>
      </Text>
    </Container>
  );
}

export default memo(EmptySection);
