import propTypes from "prop-types";

import { INTEGRATION_APPS } from "@integrations/constants";
import { SvgIcon } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import ConnectedIcon from "@assets/img/icons/ic_connected.svg";

function IntegrationsSettingsPopperHeader({ isProjectConnected, appId }) {
  const integrations = INTEGRATION_APPS;
  const integration = integrations.find(
    (integration) => integration.id === appId
  );

  return (
    <Box display="flex" flexDirection="row" gap={1}>
      <Box display="flex" alignItems="center" gap={0.125}>
        <SvgIcon
          component={integration.settingsIcon}
          inheritViewBox
          fontSize="small"
        />
      </Box>
      <Text
        variant="textMd"
        color="text.secondary"
        translate={`APPS.${integration.localizationKey}.TITLE`}
        fontWeight="fontWeight.medium"
        flexGrow={1}
      />
      {isProjectConnected && (
        <SvgIcon
          component={ConnectedIcon}
          inheritViewBox
          fontSize="small"
          data-testid="integration-connected"
          color="success"
        />
      )}
    </Box>
  );
}

IntegrationsSettingsPopperHeader.propTypes = {
  isProjectConnected: propTypes.bool.isRequired,
  appId: propTypes.string.isRequired,
};

export default IntegrationsSettingsPopperHeader;
