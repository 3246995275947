import fstgId from "@shared/helpers/fstgId";

let id;

function initialize() {
  id = sessionStorage.getItem("browserTabId") ?? fstgId.generate();
  sessionStorage.setItem("browserTabId", id);
}

function get() {
  return id;
}

export default { initialize, get };
