import { AUTOMATION_KEY_VALUES } from "@workflow/constants/automation";

export const AUTOMATION_CHECK_TYPE = {
  STEP: "step",
  SECTION: "section",
  REVIEWER: "reviewer",
};
export const checkIsUsedInAutomation = (
  automations,
  value,
  type,
  stepId,
  steps
) => {
  const result = false;

  if (
    type === AUTOMATION_CHECK_TYPE.STEP ||
    type === AUTOMATION_CHECK_TYPE.SECTION
  ) {
    return automations.some((automation) => {
      const conditions = Object.values(automation.conditions);
      const actions = Object.values(automation.actions);
      return conditions.concat(actions).includes(value);
    });
  }

  /* istanbul ignore next */
  if (type === AUTOMATION_CHECK_TYPE.REVIEWER) {
    const reviewerInUse = automations.some(
      (automation) =>
        automation.conditions.step === stepId &&
        automation.conditions.reviewer === value
    );

    if (reviewerInUse) {
      return true;
    }

    if (
      automations.some(
        (automation) =>
          automation.conditions.step === AUTOMATION_KEY_VALUES.ANY &&
          automation.conditions.reviewer === value
      )
    ) {
      const reviewerInSteps = steps.filter((step) =>
        step.reviewers.some((reviewer) => reviewer._id === value)
      );

      if (reviewerInSteps.length === 1) {
        return true;
      }
    }
  }

  return result;
};
