import PropTypes from "prop-types";
import { useCallback } from "react";

import { makeStyles } from "@mui/styles";
import userService from "@supporting/services/userService";

import { Box, Text } from "@shared/UIKit";

import AIAvatar from "@assets/img/icons/AIAvatar.svg";
import LinkIcon from "@assets/img/icons/link.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 4,
  },
  reviewerOptionText: {
    [theme.breakpoints.down("md")]: {
      maxWidth: 190,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  linkIcon: {
    paddingLeft: 4,
  },
  AILink: {
    width: "100%",
    background:
      "linear-gradient(90.07deg, #CFA1ED -21.08%, rgba(217, 190, 235, 0) 106.1%)",
    borderRadius: 4,
    minHeight: 36,
    margin: "auto",
    cursor: "pointer",
  },
}));

const AIAutocompleteOption = ({ onClick }) => {
  const classes = useStyles();

  const openAIMarketPlace = useCallback(async () => {
    await userService.update({
      settings: { openedAIMarketplaceDialogAt: new Date() },
    });
    onClick();
  }, [onClick]);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.AILink}
      data-testid="ai-marketplace-link"
      onClick={openAIMarketPlace}
    >
      <Box ml={0.5} mr={0.5}>
        <AIAvatar />
      </Box>
      <Box ml={1} flexGrow={1}>
        <Text className={classes.reviewerOptionText} translate="BOTS.TEASER" />
      </Box>
      <Box mt={0.5} className={classes.linkIcon}>
        <LinkIcon />
      </Box>
    </Box>
  );
};

AIAutocompleteOption.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AIAutocompleteOption;
