import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";
import { instance as billingService } from "@supporting/services/billingService";
import toastService from "@supporting/services/toast";

import { Dialog, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";
import { instance as logger } from "@shared/services/logger";

const TAG = "billing:cancel-subscription";

const LIST_TEXTS = [
  "BILLING.CANCEL_SUBSCRIPTION.DESCRIPTION-2",
  "BILLING.CANCEL_SUBSCRIPTION.DESCRIPTION-3",
  "BILLING.CANCEL_SUBSCRIPTION.DESCRIPTION-4",
];

export default function CancelSubscriptionDialog({ isOpen, close }) {
  const [isSubscriptionCancelled, setIsSubscriptionCancelled] = useState(false);
  const team = useSelectedTeam();
  const { t } = useTranslation();

  const cancelSubscription = async () => {
    if (!window.profitwell) {
      logger.error(TAG, "profitwell couldn't be loaded");
      toastService.sendToast({
        title: "ERROR.BAREMETRIC_CANCEL_FAILED.TITLE",
        body: "ERROR.BAREMETRIC_CANCEL_FAILED.BODY",
        preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
      });
      return;
    }

    const result = await window.profitwell("init_cancellation_flow", {
      user_id: team.billing.customerId,
      subscription_id: team.billing.subscriptionId,
    });

    if (result.status === "error") {
      logger.error(TAG, "error cancelling subscription", result);
      toastService.sendToast({
        title: "ERROR.BAREMETRIC_CANCEL_FAILED.TITLE",
        body: "ERROR.BAREMETRIC_CANCEL_FAILED.BODY",
        preset: toastService.PRESETS().ERROR_LARGE_DELAYED,
      });
      return;
    }

    if (result.status === "retained" || result.status === "aborted") {
      logger.info(TAG, "aborted cancel subscription", result);
      close();
      return;
    }

    setIsSubscriptionCancelled(true);
    billingService.cancelSubscription(team._id);
    analytics.track(
      analytics.ACTION.CANCELLED,
      analytics.CATEGORY.SUBSCRIPTION,
      {
        teamId: team._id,
        customerId: team.billing.customerId,
        subscriptionId: team.billing.subscriptionId,
        plan: team.subscription.basePrice.description,
        mrr: team.subscription.monthlySpend,
        status: result.status,
        cancelReason: result.cancelReason,
        additionalFeedback: result.additionalFeedback,
        satisfactionInsight: result.satisfactionInsight,
        salvageOfferResult: result.salvageOfferResult,
        salvageAttemptUsed: result.salvageAttemptUsed,
        salvageAttemptIntended: result.salvageAttemptIntended,
        salvageAttemptResultResolution: result.salvageAttemptResult?.resolution,
        salvageAttemptResultDecision: result.salvageAttemptResult?.decision,
      }
    );
    logger.info(TAG, "subscription cancelled", result);
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.SUBSCRIPTION_CANCELLATION_DIALOG,
      {
        teamId: team._id,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      cancel={close}
      title={t("BILLING.CANCEL_SUBSCRIPTION.HEADLINE")}
      disableEnforceFocus // required for Profitwell popup to work properly
    >
      <Text
        variant="body2"
        translate="BILLING.CANCEL_SUBSCRIPTION.DESCRIPTION-1"
      />
      <Box
        m={2}
        p={2}
        borderRadius={1}
        bgcolor="grey.100"
        display="inline-block"
      >
        <List>
          {LIST_TEXTS.map((listText) => (
            <ListItem
              key={listText}
              disableGutters
              sx={{ padding: 0, margin: 0 }}
            >
              <ListItemIcon>
                <Close color="error" />
              </ListItemIcon>
              <ListItemText>
                <Text variant="body2" translate={listText} />
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>
      <Text
        variant="body2"
        translate="BILLING.CANCEL_SUBSCRIPTION.DESCRIPTION-5"
      />
      <Box py={4}>
        <Button
          variant="contained"
          color="primary"
          className="launch-intercom-chat"
          href="mailto:hello@filestage.io"
        >
          {t("BILLING.CANCEL_SUBSCRIPTION.CONTACT")}
        </Button>
      </Box>
      <Text
        translate="CORE.OR"
        sx={{
          position: "relative",
          "&::before": {
            left: 0,
            top: "50%",
            width: "45%",
            height: "1px",
            content: "' '",
            position: "absolute",
            backgroundColor: "grey.300",
          },
          "&::after": {
            right: 0,
            top: "50%",
            width: "45%",
            height: "1px",
            content: "' '",
            position: "absolute",
            backgroundColor: "grey.300",
          },
        }}
      />
      <Box pt={3}>
        {isSubscriptionCancelled ? (
          <Text color="primary">
            <Trans>BILLING.CANCEL_SUBSCRIPTION.CONFIRM_CANCELLATION</Trans>
          </Text>
        ) : (
          <Button variant="text" color="error" onClick={cancelSubscription}>
            {t("BILLING.CANCEL_SUBSCRIPTION.START_CANCELLATION")}
          </Button>
        )}
      </Box>
    </Dialog>
  );
}

CancelSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func.isRequired,
};

CancelSubscriptionDialog.defaultProps = {
  isOpen: false,
};
