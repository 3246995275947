import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Chip, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";

import { Avatar } from "@shared/UIKit";

import { EMAIL_REGEX } from "@shared/constants/validation";
import FSTGTypography from "@shared/theme/typography";
const useStyles = makeStyles((theme) => ({
  tag: {
    alignSelf: "baseline",
    margin: 4,
  },
  avatar: {
    fontSize: FSTGTypography.fontSize_1_3,
    lineHeight: FSTGTypography.lineHeight_1_1,
    color: `${theme.color.white} !important`,
    backgroundColor: theme.color.blue[400],
  },
}));
const extractEmails = (text) =>
  text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
export default function EmailChip({ emailAddresses, setEmailAddresses }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const isEmailAddressValid = (emailAddress) => {
    const isValidEmail = EMAIL_REGEX.test(emailAddress);
    if (!isValidEmail) {
      setErrorText(t("ONBOARDING_WIZARD.ADD_REVIEWERS.INPUT.ERROR.FORMAT"));
    }
    return (
      emailAddress.trim() !== "" &&
      emailAddresses.indexOf(emailAddress) === -1 &&
      EMAIL_REGEX.test(emailAddress)
    );
  };
  const handleOnPaste = (event) => {
    setErrorText("");
    const data = event.clipboardData.getData("text");
    setEmail("");
    const emails = [...new Set(extractEmails(data))];
    const validEmailAddresses = [];
    if (emails.length > 0) {
      emails.map(
        (email) => isEmailAddressValid(email) && validEmailAddresses.push(email)
      );
    }

    setEmailAddresses([...emailAddresses, ...validEmailAddresses]);
    event.preventDefault();
  };
  const handleOnBlur = () => {
    if (email && isEmailAddressValid(email)) {
      setEmailAddresses([...emailAddresses, email]);
      setEmail("");
    }
  };
  const handleOnChange = (event) => {
    setErrorText("");
    setEmail(event.target.value);
  };
  const handleOnChangeAutoComplete = (event, newValue) => {
    setErrorText("");
    // istanbul ignore if
    if (event.target.value) {
      if (isEmailAddressValid(event.target.value)) {
        setEmailAddresses([...emailAddresses, event.target.value]);
        setEmail("");
      }
      return;
    }
    setEmailAddresses(newValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === " " || event.key === "Enter") {
      if (isEmailAddressValid(event.target.value)) {
        setEmailAddresses([...emailAddresses, event.target.value]);
        setEmail("");
      }
      event.preventDefault();
    }
  };

  return (
    <Autocomplete
      multiple
      value={emailAddresses}
      options={[]}
      disableClearable
      autoHighlight
      freeSolo
      fullWidth
      onBlur={handleOnBlur}
      sx={{
        minHeight: 100,
        "& .MuiInputBase-input": { minWidth: 70 },
        "& .MuiOutlinedInput-notchedOutline": { minHeight: 100 },
      }}
      data-testid="onboarding-wizard-invite-team-members-email-input"
      inputValue={email}
      onInputChange={handleOnChange}
      onPaste={handleOnPaste}
      onChange={handleOnChangeAutoComplete}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((email, index) => (
          <Chip
            data-testid="onboarding-wizard-invite-team-members-email-chips"
            label={email}
            key={index}
            {...getTagProps({ index })}
            avatar={<Avatar className={classes.avatar} user={{ email }} />}
            className={classes.tag}
          />
        ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("ONBOARDING_WIZARD.ADD_REVIEWERS.INPUT.LABEL")}
          variant="outlined"
          placeholder={t("ONBOARDING_WIZARD.ADD_REVIEWERS.INPUT.PLACEHOLDER")}
          minRows={5}
          onKeyDown={handleKeyDown}
          error={Boolean(errorText)}
          helperText={errorText}
        />
      )}
    />
  );
}
EmailChip.propTypes = {
  emailAddresses: PropTypes.arrayOf(PropTypes.string).isRequired,
  setEmailAddresses: PropTypes.func.isRequired,
};
