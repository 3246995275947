import { useCallback, useEffect, useRef, useState } from "react";

import dashboardSectionsCache from "@workflow/services/dashboardSectionsCache";

import EVENT_NAMES from "@shared/constants/events";
import waitForRender from "@shared/helpers/waitForRender";
import errorHandlerService from "@shared/services/errorHandler";
import eventService from "@shared/services/eventService";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

/**
 * @typedef {object} Project
 *
 * @typedef {object} Section
 *
 * @typedef {object} CollapseSectionState
 * @property {string} sectionId - The id of the section
 * @property {boolean} isCollapsed - Indicates if the section is collapsed.
 *
 * @typedef {object} ProjectSections
 * @property {array} sections - The project sections.
 * @property {boolean} hasMore - Indicates if there are more sections to load.
 * @property {boolean} isLoadingFiles - Indicates if the files are loading.
 * @property {function} fetchMoreFiles - Function to fetch more files.
 *
 * Use Project Sections hook.
 *
 * @description This hook returns the project sections for the given project.
 * It also contains the event listeners for changes on the project
 * sections.
 *
 * @param {Project} project - [Required] The project to get the sections for.
 * @param {React.RefObject} $element - [Required] The element to wait for render.
 * @returns {ProjectSections} - Returns project sections data.
 */
export default function useProjectSections(project, $element) {
  const visibleProjectRef = useRef(project.id);

  const [data, setData] = useState({
    sections: [],
    hasMore: false,
    isLoadingFiles: false,
  });

  const fetchFiles = useCallback(
    async (projectId, nextPage) => {
      setData((prevState) => ({
        ...prevState,
        isLoadingFiles: true,
      }));

      const wait = waitForRender($element.current);
      /* istanbul ignore else */
      if (!nextPage) {
        healthMetrics.trackStart("workflow.open-project");
        // eslint-disable-next-line promise/catch-or-return,promise/prefer-await-to-then
        wait.render.then((time) =>
          healthMetrics.trackSuccess("workflow.open-project", time)
        );
      }

      try {
        const result = nextPage
          ? await dashboardSectionsCache.getMoreProjectSections(projectId)
          : await dashboardSectionsCache.getProjectSections(projectId);

        if (projectId === visibleProjectRef.current) {
          setData((prevState) => ({
            ...prevState,
            ...result,
            isLoadingFiles: false,
          }));
        }
      } catch (error) {
        errorHandlerService.handleError(error);
        wait.cancel();
        healthMetrics.trackFailure("workflow.open-project", error);
      }
    },
    [$element]
  );

  function fetchMoreFiles(projectId) {
    fetchFiles(projectId, true);
  }

  useEffect(() => {
    function onProjectSectionsUpdated(event) {
      const { projectId, sections, hasMore } = event.eventData;

      if (projectId === project.id) {
        setData((prevState) => ({
          ...prevState,
          sections,
          hasMore,
        }));
      }
    }

    eventService.addListener(
      EVENT_NAMES.PROJECT.SECTIONS.UPDATED,
      onProjectSectionsUpdated
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.PROJECT.SECTIONS.UPDATED,
        onProjectSectionsUpdated
      );
    };
  }, [fetchFiles, project.id]);

  useEffect(() => {
    visibleProjectRef.current = project.id;

    setData(() => ({
      sections: [],
      hasMore: true,
      isLoadingFiles: false,
    }));

    fetchFiles(project.id, false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.id]);

  return { ...data, fetchMoreFiles };
}
