import PropTypes from "prop-types";
import { ReactElement, useMemo } from "react";
import { IconContext } from "react-icons";

import {
  Experimental_CssVarsProvider as CssVarsProvider,
  StyledEngineProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";
import useActiveUser from "@supporting/hooks/useActiveUser";

import theme from "@shared/theme/default";
import themeV2 from "@shared/theme/themeV2";

import DateLocalizationProvider from "../DueDate/DueDatePopover/DueDatePicker/DateLocalizationProvider";

const themes = {
  theme,
  themeV2,
};

const selectTheme = (user) => ({
  userTheme: user?.settings?.earlyAccessFeatures?.fileViewRedesign
    ? "themeV2"
    : "theme",
  language: user?.language || "en",
});

/**
 * UIKit ThemeProvider Component
 * @description This is a wrapper for the Material UI ThemeProvider. It returns
 * the children component wrapped with theme.
 * @param {{children: ReactElement}} props - Component props
 * @param {ReactElement} props.children - [Required] Children component
 * @returns {ReactElement} - React component
 */
const ThemeProvider = ({ children, ...restProps }) => {
  const { userTheme, language } = useActiveUser(selectTheme);

  const theme = useMemo(() => extendTheme(themes[userTheme]), [userTheme]);
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider {...restProps} theme={theme}>
        <IconContext.Provider value={{ className: "react-icons" }}>
          <DateLocalizationProvider language={language}>
            {children}
          </DateLocalizationProvider>
        </IconContext.Provider>
      </CssVarsProvider>
    </StyledEngineProvider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeProvider;
