/* istanbul ignore file */
/* eslint-disable max-lines */
import React from "react";

import CancelIcon from "@mui/icons-material/Cancel";

import animations from "./animations";
import colors from "./colors";
import { Button, SPACING, Typography } from "./components";
import shadows from "./shadows";
import typography from "./typography";

const theme = {
  spacing: SPACING,
  palette: {
    primary: {
      ...colors.green,
      lighter: colors.green[50],
      light: colors.green[100],
      main: colors.green[500],
      dark: colors.green[600],
      darker: colors.green[700],
      contrastText: colors.white,
    },
    success: {
      light: colors.green[50],
      main: colors.green[500],
      dark: colors.green[600],
      darker: colors.green[700],
    },
    error: {
      light: colors.red[50],
      main: colors.red[500],
      dark: colors.red[600],
      contrastText: colors.white,
    },
    warning: {
      light: colors.orange[400],
      main: colors.orange[500],
      dark: colors.orange[600],
      contrastText: colors.white,
    },
    primaryV2: {
      light: colors.gray[300],
      main: colors.gray[700],
      dark: colors.gray[900],
      contrastText: colors.white,
    },
    secondary: {
      light: colors.secondary[200],
      main: colors.secondary[500],
      dark: colors.secondary[600],
    },
    "default-light": {
      light: colors.gray[200],
      main: colors.gray[500],
      dark: colors.gray[600],
      contrastText: colors.white,
    },
    text: {
      primary: colors.gray[800],
      secondary: colors.gray[600],
    },
    accent: {
      light: colors.purple[100],
      main: colors.purple[500],
      dark: colors.purple[700],
      contrastText: colors.white,
    },
    light: {
      light: colors.gray[50],
      main: colors.white,
      dark: colors.gray[100],
      contrastText: colors.gray[800],
    },
    grey: { ...colors.gray },
    pending: {
      lighter: colors.gray[50],
      light: colors.gray[50],
      main: colors.gray[200],
      dark: colors.gray[600],
      contrastText: colors.white,
    },
    inReview: {
      lighter: colors.blue[50],
      light: colors.blue[50],
      main: colors.blue[400],
      dark: colors.blue[600],
      contrastText: colors.white,
    },
    approved: {
      lighter: colors.green[50],
      light: colors.green[100],
      main: colors.green[500],
      dark: colors.green[600],
      contrastText: colors.white,
    },
    needsChanges: {
      lighter: colors.orange[50],
      light: colors.orange[50],
      main: colors.orange[400],
      dark: colors.orange[600],
      contrastText: colors.white,
    },
    rejected: {
      lighter: colors.red[50],
      light: colors.red[50],
      main: colors.red[500],
      dark: colors.red[600],
      contrastText: colors.white,
    },
    upgrade: {
      light: colors.blue[50],
      main: colors.blue[500],
      dark: colors.blue[700],
      contrastText: colors.white,
    },
    everyone: {
      light: colors.green[50],
      main: colors.green[200],
      dark: colors.green[500],
      contrastText: colors.gray[600],
    },
    teamOnly: {
      light: colors.gold[100],
      main: colors.gold[400],
      dark: colors.gold[500],
      contrastText: colors.gray[600],
    },
    action: {
      active: colors.gray[500],
    },
  },
  typography: {
    fontFamily: typography.fontFamily,
    htmlFontSize: typography.htmlFontSize,
    fontWeightLight: typography.fontWeightLight,
    fontWeightRegular: typography.fontWeightRegular,
    fontWeightMedium: typography.fontWeightMedium,
    fontWeightBold: typography.fontWeightBold,
    fontWeight: {
      regular: typography.fontWeight.regular,
      semiBold: typography.fontWeight.semiBold,
      medium: typography.fontWeight.semiBold,
      bold: typography.fontWeight.bold,
    },
    subtitle2: {
      fontSize: typography.fontSize_1_2,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    subtitle1: {
      fontSize: typography.fontSize_1_3,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    body2: {
      fontSize: typography.fontSize_1_5,
      lineHeight: typography.lineHeight_1_5,
      fontWeight: typography.fontWeight.regular,
    },
    body1: {
      fontSize: typography.fontSize_1_6,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h6: {
      fontSize: typography.fontSize_1_7,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h5: {
      fontSize: typography.fontSize_2_0,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h4: {
      fontSize: typography.fontSize_2_6,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h3: {
      fontSize: typography.fontSize_2_9,
      lineHeight: typography.lineHeight_1_3,
      letterSpacing: 0.1,
      fontWeight: typography.fontWeight.regular,
    },
    h2: {
      fontSize: typography.fontSize_3_1,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    h1: {
      fontSize: typography.fontSize_3_8,
      lineHeight: typography.lineHeight_1_3,
      fontWeight: typography.fontWeight.regular,
    },
    text5xl: {
      fontSize: typography.fontSize5xl,
      lineHeight: typography.lineHeight5xl,
      fontWeight: typography.fontWeight.regular,
    },
    text4xl: {
      fontSize: typography.fontSize4xl,
      lineHeight: typography.lineHeight4xl,
      fontWeight: typography.fontWeight.regular,
    },
    text3xl: {
      fontSize: typography.fontSize3xl,
      lineHeight: typography.lineHeight3xl,
      fontWeight: typography.fontWeight.regular,
    },
    text2xl: {
      fontSize: typography.fontSize2xl,
      lineHeight: typography.lineHeight2xl,
      fontWeight: typography.fontWeight.regular,
    },
    textXl: {
      fontSize: typography.fontSizeXl,
      lineHeight: typography.lineHeightXl,
      fontWeight: typography.fontWeight.regular,
    },
    textLg: {
      fontSize: typography.fontSizeLg,
      lineHeight: typography.lineHeightLg,
      fontWeight: typography.fontWeight.regular,
    },
    textMd: {
      fontSize: typography.fontSizeMd,
      lineHeight: typography.lineHeightMd,
      fontWeight: typography.fontWeight.regular,
    },
    textSm: {
      fontSize: typography.fontSizeSm,
      lineHeight: typography.lineHeightSm,
      fontWeight: typography.fontWeight.regular,
    },
    textXs: {
      fontSize: typography.fontSizeXs,
      lineHeight: typography.lineHeightXs,
      fontWeight: typography.fontWeight.regular,
    },
  },
  shadow: shadows,
  color: colors,
  animation: animations,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 6,
  },
};

const components = {
  MuiCssBaseline: {
    styleOverrides: (themeParam) => `
    *, *::before, *::after {
      box-sizing: revert;
    }
    html {
      line-height: ${themeParam.typography.lineHeight_2_4};
    }
    body {
      line-height: unset;
      background-color: ${themeParam.color.gray[250]};
    }
  `,
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        color: colors.gray[800],
        "& p": {
          fontWeight: typography.fontWeight.regular,
        },
      },
    },
  },
  MuiTypography: Typography,
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        "& .MuiButton-sizeSmall.MuiButtonGroup-lastButton": {
          padding: 4,
        },
      },
      grouped: ({ ownerState }) => ({
        ...(ownerState.size === "extraSmall" && {
          fontSize: typography.fontSize_1_2,
          lineHeight: typography.lineHeight_1_3,
        }),
      }),
    },
  },
  MuiButton: Button,
  MuiLoadingButton: Button,
  MuiFormControl: {
    defaultProps: {
      variant: "standard",
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        "&:hover": {
          backgroundColor: "unset",
          ...(!["inherit", "default"].includes(ownerState.color) && {
            color: theme.palette[ownerState.color].dark,
          }),
          ...("default" === ownerState.color && {
            color: "var(--mui-palette-grey-700)",
          }),
        },
      }),
    },
    variants: [
      {
        props: { size: "extraLarge" },
        style: ({ theme }) => ({
          fontSize: theme.vars.typography.text3xl.fontSize,
        }),
      },
      {
        props: { size: "extraSmall" },
        style: {
          fontSize: typography.fontSize_1_6,
        },
      },
    ],
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        fontSize: typography.fontSize_1_3,
        fontWeight: typography.fontWeight.semiBold,
        lineHeight: typography.lineHeight_1_3,
        color: colors.white,
        backgroundColor: theme.palette.grey[900],
        "&[data-tooltip-variant='white']": {
          fontWeight: typography.fontWeight.regular,
          padding: theme.spacing(1),
          textAlign: "center",
          boxShadow: theme.shadows[1],
          color: theme.color.gray[800],
          backgroundColor: theme.palette.common.white,
          "& .MuiTooltip-arrow": {
            color: theme.palette.common.white,
            "&::before": {
              boxShadow: theme.shadows[1],
            },
          },
        },
      }),
      arrow: ({ theme }) => ({
        color: theme.palette.grey[900],
      }),
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderRadius: 2,
        "& .MuiListSubheader-root": {
          // fontWeight: typography.fontWeight.regular,
          fontSize: typography.fontSize_1_3,
          lineHeight: typography.lineHeight_1_3,
        },
        "& .MuiListSubheader-root:nth-of-type(1)": {
          paddingTop: SPACING,
        },
      },
    },
  },
  MuiList: {
    defaultProps: {
      disablePadding: true,
      dense: true,
    },
    styleOverrides: {
      dense: {
        maxHeight: 304,
        overflow: "auto",
        "& .MuiMenuItem-root": {
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          paddingLeft: "1rem",
          paddingRight: "1rem",
          "&:has(> .MuiIconButton-root)": {
            paddingTop: "0.25rem",
            paddingBottom: "0.25rem",
          },
          fontSize: typography.fontSize_1_3,
          lineHeight: typography.lineHeight_1_3,
          "& .MuiListItemText-primary": {
            fontWeight: typography.fontWeight.semiBold,
            fontSize: typography.fontSize_1_3,
            lineHeight: typography.lineHeight_1_3,
          },
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      formControl: {
        "label + &": {
          marginTop: 20,
        },
      },
      underline: {
        "&:before": {
          borderBottom: "1px solid rgba(0,0,0,0.12)",
        },
        "&:hover:not(.Mui-disabled):not(.Mui-focused):not(.Mui-error):before": {
          borderBottom: "1px solid rgba(0,0,0,0.12)",
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        fontWeight: typography.fontWeight.semiBold,
        paddingTop: "1rem",
        paddingBottom: "1rem",
        fontSize: typography.fontSizeSm,
        lineHeight: typography.lineHeightSm,
        "&.Mui-selected": {
          color: theme.palette.primary.main,
          "& .MuiListItemText-primary": {
            color: theme.palette.primary.main,
            fontWeight: typography.fontWeight.semiBold,
          },
          "&:hover": {
            color: theme.palette.primary.main,
          },
        },
        "& .MuiListItemText-primary": {
          color: theme.palette.text.primary,
          fontWeight: typography.fontWeight.semiBold,
          fontSize: typography.fontSizeSm,
          lineHeight: typography.lineHeightSm,
        },
      }),
    },
    variants: [
      {
        props: { selected: true, variant: "text" },
        style: ({ theme }) => ({
          "&.MuiMenuItem-root.Mui-selected": {
            backgroundColor: "transparent",
          },
          color: theme.palette.primary.main,
          ".MuiListItemIcon-root": {
            color: theme.palette.primary.main,
          },
          ".MuiListItemText-secondary": {
            color: theme.palette.primary.main,
          },
        }),
      },
    ],
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        lineHeight: typography.lineHeight_1_3,
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "none",
      color: "success",
    },
    styleOverrides: {
      root: ({ ownerState, theme }) => ({
        cursor: "pointer",
        color:
          ownerState.color === "white"
            ? "white"
            : theme.palette[ownerState.color]?.main ?? ownerState.color,
        "&:hover": {
          color:
            ownerState.color === "white"
              ? "white"
              : theme.palette[ownerState.color]?.dark ?? ownerState.color,
        },
      }),
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: "standard",
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        backgroundColor: colors.white,
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: "standard",
    },
    styleOverrides: {
      select: {
        "&.MuiInputBase-inputSizeSmall": {
          fontSize: typography.fontSize_1_3,
          paddingBottom: 1,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: { root: { backgroundImage: "unset" } },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        '&[data-size="small"] .MuiButtonBase-root.MuiTab-root': {
          paddingTop: "0.5rem",
          paddingBottom: "0.5rem",
          minHeight: "unset",
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        fontWeight: typography.fontWeight.regular,
        color: colors.gray[800],
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        boxShadow: "none",
        border: "none",
        "&.Mui-expanded": {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
  },
  MuiCardHeader: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid var(--mui-palette-grey-200)`,
        paddingRight: SPACING * 4,
        paddingLeft: SPACING * 4,
      },
      title: {
        fontWeight: typography.fontWeight.semiBold,
        fontSize: typography.fontSizeXl,
      },
      action: {
        margin: "unset",
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: SPACING * 4.375,
        paddingRight: SPACING * 4,
        paddingLeft: SPACING * 4,
      },
    },
  },
  MuiChip: {
    defaultProps: {
      variant: "filled-light",
      deleteIcon: React.createElement(CancelIcon, {
        fontSize: "inherit",
      }),
    },
    variants: [
      {
        props: { variant: "filled-light" },
        style: ({ ownerState, theme }) =>
          ownerState.color !== "default" && {
            color: theme.palette[ownerState.color].main,
            backgroundColor: theme.palette[ownerState.color].light,
          },
      },
      {
        props: { size: "small" },
        style: ({ theme }) => ({
          fontSize: theme.vars.typography.fontSizeXs,
        }),
      },
    ],
  },
  MuiDialog: {
    defaultProps: {
      hideBackdrop: true,
      maxWidth: "sm",
    },
    styleOverrides: {
      paperFullWidth: ({ ownerState }) => {
        return (
          !ownerState.maxWidth && {
            maxWidth: "calc(100% - 16px)",
            width: "calc(100% - 16px)",
            marginRight: 8,
            marginLeft: 8,
          }
        );
      },
      paper: {
        textAlign: "center",
        padding: "40px 20px 30px",
        "&.noDefaultBackground": {
          backgroundImage: "none",
        },
        backgroundImage: `url("/assets/img/images/fs-icons--gradient.png")`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      },
      paperFullScreen: {
        padding: 0,
        backgroundColor: colors.gray[100],
        "&.dialogDefaultBackground": {
          backgroundRepeat: "repeat",
        },
      },
      paperWidthXm: {
        maxWidth: 600,
      },
      paperWidthSm: {
        maxWidth: 700,
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({
        justifyContent: "center",
        [theme.breakpoints.up("sm")]: {
          justifyContent: "flex-end",
        },
      }),
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      root: {
        "&.react-icons": {
          fill: "none",
        },
      },
    },
    variants: [
      {
        props: { fontSize: "extraLarge" },
        style: ({ theme }) => ({
          fontSize: theme.vars.typography.text5xl.fontSize,
        }),
      },
      {
        props: { fontSize: "extraSmall" },
        style: {
          fontSize: "1rem",
        },
      },
    ],
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        "& .MuiAlertTitle-root:only-child": {
          marginBottom: 0,
        },
      },
      standardError: {
        backgroundColor: colors.red[100],
      },
      standardSuccess: {
        color: colors.green[500],
        backgroundColor: colors.green[50],
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        "&:has(> .MuiSwitch-root)": {
          "&:has(> .MuiSwitch-sizeSmall) + .MuiFormHelperText-root": {
            marginLeft: SPACING * 3.75,
            fontSize: typography.fontSizeXs,
          },
          "&:has(> .MuiSwitch-sizeMedium) + .MuiFormHelperText-root": {
            marginLeft: SPACING * 6,
            fontSize: typography.fontSizeSm,
          },
        },
      },
      label: {
        display: "inline-flex",
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      fallback: {
        fontSize: typography.fontSize_1_3,
      },
      root: {
        width: 32,
        height: 32,
        fontSize: typography.fontSize_1_3,
        backgroundColor: colors.green[500],
        "&:has(.MuiSvgIcon-root)": {
          backgroundColor: colors.gray[300],
        },
      },
    },
  },
};

function shouldSkipGeneratingVar(keys) {
  return (
    Boolean(keys[0].match(/(cssVarPrefix|mixins|direction|transitions)/)) ||
    Boolean(keys[0].match(/sxConfig$/)) || // ends with sxConfig
    (keys[0] === "palette" &&
      Boolean(keys[1]?.match(/(mode|contrastThreshold|tonalOffset)/)))
  );
}

export default { ...theme, components, shouldSkipGeneratingVar };
