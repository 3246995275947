import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { Text } from "@shared/UIKit";

function DemoDialog({ businessRegion }) {
  const modal = useModal();

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      hideBackdrop={false}
      sx={{
        "& .MuiPaper-root": {
          backgroundImage: "none",
          paddingX: 4,
          overflow: "hidden",
        },
      }}
      open={modal.visible}
    >
      <Box
        sx={{
          position: "absolute",
          bottom: -40,
          left: -118,
          width: 320,
          height: 200,
          background:
            "linear-gradient(179deg, rgba(255, 153, 0, 0.20) 0%, rgba(253.49, 170.60, 76.26, 0.13) 67%, rgba(250.75, 202.69, 215.31, 0) 100%, rgba(255, 153, 0, 0) 100%)",
          borderRadius: 368,
          filter: "blur(90px)",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: 22,
          left: -83,
          width: 285,
          height: 200,
          background:
            "linear-gradient(179deg, rgba(151, 71, 255, 0.25) 0%, rgba(151, 71, 255, 0.16) 67%, rgba(250.75, 202.69, 215.31, 0) 100%, rgba(255, 153, 0, 0) 100%)",
          borderRadius: 368,
          filter: "blur(90px)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          top: 163,
          left: 331,
          width: 286,
          height: 200,
          background:
            "linear-gradient(179deg, rgba(79, 255, 191, 0.40) 0%, rgba(79, 255, 191, 0) 100%)",
          borderRadius: 426,
          filter: "blur(90px)",
        }}
      />

      <Box
        sx={{
          position: "absolute",
          bottom: -26,
          left: 314,
          width: 279,
          height: 200,
          background:
            "linear-gradient(179deg, #A5D9FF 0%, rgba(190.54, 236.94, 236.94, 0) 100%)",

          borderRadius: 426,
          filter: "blur(90px)",
        }}
      />

      <Box display="flex" gap={3} overflow="auto">
        <IconButton
          aria-label="close"
          onClick={() => modal.remove()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box flexBasis={373} flexGrow={1} alignContent="center">
          <Text
            variant="text2xl"
            fontWeight="fontWeight.bold"
            translate="TRAINING_MODAL.STAT.TITLE"
            textAlign="start"
            sx={{ marginBottom: 6 }}
          />
          {[
            "TRAINING_MODAL.STAT.BENEFIT_1",
            "TRAINING_MODAL.STAT.BENEFIT_2",
            "TRAINING_MODAL.STAT.BENEFIT_3",
          ].map((translationKey) => (
            <Box display="flex" key={translationKey} gap={1.5} marginBottom={2}>
              <CheckBoxIcon />
              <Text
                textAlign="start"
                translate={translationKey}
                sx={{
                  variant: "body1",
                  wordWrap: "break-word",
                }}
              />
            </Box>
          ))}
        </Box>
        <Box flexBasis={875} flexGrow={1} zIndex="+1">
          <Helmet>
            <script
              type="text/javascript"
              src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
            />
          </Helmet>
          <div
            data-testId="meetings-iframe"
            className="meetings-iframe-container"
            data-src={
              businessRegion === "AMER"
                ? "https://meetings.hubspot.com/ejoonsar/trial-demo?embed=true"
                : "https://meetings.hubspot.com/gabriele-pletaite/trial-demo?embed=true"
            }
          />
        </Box>
      </Box>
    </Dialog>
  );
}

DemoDialog.propTypes = {
  businessRegion: PropTypes.string.isRequired,
};

export default NiceModal.create(DemoDialog);
