import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Grid, Tooltip } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import classnames from "classnames";

import { Box, DueDate, Text } from "@shared/UIKit";

import { useNavigation } from "@shared/hooks";
import { fileProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  GroupHeader: {
    borderBottom: `1px solid ${theme.color.gray[50]}`,
  },
  Project: {
    cursor: "pointer",
  },
  StepDivider: {
    marginLeft: 8,
    marginRight: 8,
  },
  File: {
    cursor: "pointer",
  },
  FileVersion: {
    marginLeft: 8,
    cursor: "pointer",
  },
  MarkAsRead: {
    cursor: "pointer",
  },
  IsRead: {
    backgroundColor: theme.color.gray[300],
  },
  IsUnread: {
    backgroundColor: theme.color.red[500],
  },
  MarkAsReadContainer: {
    flexGrow: 0,
  },
  Tooltip: {
    zIndex: 2147483005,
  },
}));

function NotificationGroupHeader({
  project,
  step,
  file,
  review,
  isRead,
  id,
  updateNotificationGroupIsRead,
}) {
  const { routeNames, goTo } = useNavigation();

  const { t } = useTranslation();

  const classes = useStyles();
  const theme = useTheme();

  const markAsRead = useCallback(() => {
    updateNotificationGroupIsRead(id, true);
    analytics.track(
      analytics.ACTION.MARKED_AS_READ,
      analytics.CATEGORY.NOTIFICATION_CENTER_NOTIFICATIONS
    );
  }, [updateNotificationGroupIsRead, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const markAsUnread = useCallback(() => {
    updateNotificationGroupIsRead(id, false);
    analytics.track(
      analytics.ACTION.MARKED_AS_UNREAD,
      analytics.CATEGORY.NOTIFICATION_CENTER_NOTIFICATIONS
    );
  }, [updateNotificationGroupIsRead, id]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToObject = useCallback(() => {
    backend.put("/in-app-notifications", { ids: [id], isRead: true });

    const params = {
      projectId: project.id,
      stepId: step ? step.id : null,
      fileId: file ? file.id : null,
      reviewId: review?.id ?? null,
    };

    if (params.reviewId) {
      goTo(routeNames.REVIEW_LINK, { params });
    } else if (params.fileId) {
      goTo(routeNames.WORKSPACE_ALIAS, { params });
    } else {
      goTo(routeNames.PROJECT_ID, { params });
    }

    eventService.emitEvent({
      eventName: EVENT_NAMES.NOTIFICATION_CENTER.DRAWER.CLOSE_TRIGGERED,
      eventData: {},
    });
  }, [file, review, id, project.id, step]); // eslint-disable-line react-hooks/exhaustive-deps

  const redirect = useCallback(redirectToObject, [redirectToObject]);

  return (
    <Box mb={1.5} pb={1.5} className={classes.GroupHeader}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid xs item>
          <Box mb={1} lineHeight="17px">
            <Text
              variant="subtitle2"
              wordBreak="break-word"
              lineHeight={FSTGTypography.lineHeight_1_0}
              color={theme.color.gray[800]}
              className={classes.Project}
              component="span"
              onClick={redirect}
              data-testid="notification-group-header-project-name"
            >
              {project.name}
            </Text>

            {step && (
              <>
                <Text
                  color={theme.color.gray[500]}
                  variant="subtitle2"
                  component="span"
                  className={classes.StepDivider}
                >
                  {">"}
                </Text>

                <Text
                  color={theme.color.gray[600]}
                  variant="subtitle2"
                  wordBreak="break-word"
                  lineHeight={FSTGTypography.lineHeight_1_0}
                  component="span"
                >
                  {step.name}
                </Text>
              </>
            )}
          </Box>

          <Box
            onClick={redirect}
            data-testid="notification-group-header-file-name"
            lineHeight="20px"
          >
            {file && (
              <Text
                fontSize={FSTGTypography.fontSize_1_6}
                fontWeight={FSTGTypography.fontWeightMedium}
                wordBreak="break-word"
                lineHeight={FSTGTypography.lineHeight_1_0}
                component="span"
                className={classes.File}
              >
                {file.name}
              </Text>
            )}

            {review && (
              <Text
                color={theme.color.gray[600]}
                variant="subtitle2"
                className={classes.FileVersion}
                component="span"
              >
                v{review.number}
              </Text>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-end"
          classes={{ root: classes.MarkAsReadContainer }}
        >
          {isRead ? (
            <Tooltip
              title={t("NOTIFICATION_CENTER.TOOLTIPS.MARK_AS_UNREAD")}
              classes={{
                popper: classes.Tooltip,
              }}
            >
              <Box
                width={14}
                height={14}
                borderRadius="50%"
                className={classnames(classes.MarkAsRead, classes.IsRead)}
                onClick={markAsUnread}
                data-testid="notification-center-group-header-marked-as-unread"
              />
            </Tooltip>
          ) : (
            <Tooltip
              title={t("NOTIFICATION_CENTER.TOOLTIPS.MARK_AS_READ")}
              classes={{
                popper: classes.Tooltip,
              }}
            >
              <Box
                width={14}
                height={14}
                borderRadius="50%"
                className={classnames(classes.MarkAsRead, classes.IsUnread)}
                onClick={markAsRead}
                data-testid="notification-center-group-header-mark-as-read"
              />
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid>
        {review && review.reviewDueDate && (
          <Box>
            <Tooltip title={t("NOTIFICATION_CENTER.TOOLTIPS.DUE_DATE")}>
              <Box display="inline-flex" ml={-0.5}>
                <DueDate review={review} isReadOnly />
              </Box>
            </Tooltip>
          </Box>
        )}
      </Grid>
    </Box>
  );
}

NotificationGroupHeader.propTypes = {
  id: PropTypes.string.isRequired,
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  step: stepProps,
  file: fileProp,
  review: reviewProp,
  isRead: PropTypes.bool.isRequired,
  updateNotificationGroupIsRead: PropTypes.func.isRequired,
};

NotificationGroupHeader.defaultProps = {
  step: null,
  file: null,
  review: null,
};

export default NotificationGroupHeader;
