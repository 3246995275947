import NiceModal from "@ebay/nice-modal-react";

import { AddSecureStorageGate } from "@supporting/hooks/useGatedFeature/featureGates";
import authenticationService from "@supporting/services/authentication";
import userService from "@supporting/services/userService";
import reject from "lodash/reject";

import { instance as analytics } from "@shared/services/analytics";
import backend from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

let teamsCache;
let selectedTeam;
let teamsWithSameDomain;
const teamByIdCache = new Map();
const fetchTeamCalls = new Map();

const fetchTeamById = async (teamId) => {
  try {
    if (!fetchTeamCalls.has(teamId)) {
      fetchTeamCalls.set(teamId, backend.get(`/teams/${teamId}`));
    }
    const team = await fetchTeamCalls.get(teamId);
    fetchTeamCalls.delete(teamId);
    return team;
  } finally {
    fetchTeamCalls.delete(teamId);
  }
};

const clearCache = () => {
  teamsCache = undefined;
  selectedTeam = null;
  teamsWithSameDomain = undefined;
  teamByIdCache.clear();
  fetchTeamCalls.clear();
};

const getTeams = async () => {
  if (teamsCache) {
    return teamsCache;
  }
  const teams = await backend.get("/teams");
  teamsCache = teams;
  teams.forEach((team) => {
    if (!team.pendingApproval) {
      teamByIdCache.set(team._id, team);
    }
  });
  eventService.emitEvent({
    eventName: EVENT_NAMES.TEAM.TEAMS.UPDATED,
    eventData: { teams: teamsCache },
  });
  return teamsCache;
};

const hasTeam = async (teamId) => {
  const teams = await getTeams();
  return teams.some(({ _id }) => _id === teamId);
};

const setSelectedTeam = (newSelectedTeam) => {
  if (selectedTeam?._id === newSelectedTeam._id) {
    return selectedTeam;
  }
  selectedTeam = newSelectedTeam;
  eventService.emitEvent({
    eventName: EVENT_NAMES.TEAM.SELECTED.ADDED,
    eventData: { team: selectedTeam },
  });
  return selectedTeam;
};

const determineSelectedTeamByDefault = async () => {
  const teams = await getTeams();
  if (teams.length === 0 || teams.every((team) => team.pendingApproval)) {
    return undefined;
  }
  const { selectedTeamId } = authenticationService.fetchUser().settings;
  const newSelectedTeam = teams.find(({ _id }) => _id === selectedTeamId);
  return setSelectedTeam(
    newSelectedTeam || teams.find((team) => !team.pendingApproval)
  );
};

const fetchTeamByTeamId = async (teamId) => {
  if (!teamByIdCache.has(teamId)) {
    teamByIdCache.set(teamId, await fetchTeamById(teamId));
  }
  return teamByIdCache.get(teamId);
};

const updateUserSettingsSelectedTeam = (teamId) => {
  return userService.update({ settings: { selectedTeamId: teamId } });
};

const determineSelectedTeamByTeamId = async (teamId) => {
  const team = await fetchTeamByTeamId(teamId);

  if (!team.isTeamMember) {
    return team;
  }
  if (teamId !== selectedTeam?._id) {
    await updateUserSettingsSelectedTeam(teamId);
  }
  return setSelectedTeam(team);
};

const determineSelectedTeamByProjectId = async (
  projectId,
  isTemplate = false
) => {
  const project = await backend.get(
    `/${isTemplate ? "project-templates" : "projects"}/${projectId}`
  );
  const teams = await getTeams();
  const team = teams.find(({ _id }) => _id === project.teamId);
  if (team && team.isTeamMember) {
    return setSelectedTeam(team);
  }
  return selectedTeam || determineSelectedTeamByDefault();
};

const updateSelectedTeam = (updatedTeam) => {
  selectedTeam = updatedTeam;
  teamByIdCache.set(updatedTeam._id, updatedTeam);
  if (teamsCache) {
    const updatedCache = [...teamsCache];
    const teamIndex = updatedCache.findIndex(
      ({ _id }) => _id === updatedTeam._id
    );
    updatedCache[teamIndex] = updatedTeam;
    teamsCache = updatedCache;
  }
  eventService.emitEvent({
    eventName: EVENT_NAMES.TEAM.SELECTED.UPDATED,
    eventData: { team: updatedTeam },
  });
};

const refetchSelectedTeam = async () => {
  if (selectedTeam) {
    const team = await fetchTeamById(selectedTeam._id);
    return updateSelectedTeam(team);
  }
};

const fetchTeamsWithSameDomain = async () => {
  if (!teamsWithSameDomain) {
    const teams = await backend.get("/teams-with-same-domain");
    teamsWithSameDomain = teams;
  }
  return teamsWithSameDomain;
};

const updateUsedBillingLimitsOfSelectedTeam = async () => {
  if (!selectedTeam) {
    return Promise.resolve();
  }
  const usedBillingLimits = await backend.get(
    `/teams/${selectedTeam._id}/used_billing_limits`
  );
  updateSelectedTeam({ ...selectedTeam, usedBillingLimits });
};

const getSelectedTeam = () => selectedTeam;

const triggerJoinedTeamEventForNewUser = (team) => {
  const user = authenticationService.fetchUser();
  const teamMember = team.members.find(
    (member) => member.user._id === user._id
  );

  if (teamMember?.joined && !teamMember.firstSeen) {
    analytics.trackV2({
      action: analytics.ACTION.JOINED,
      category: analytics.CATEGORY.TEAM,
      metaData: {
        subscriptionStatus: team.subscription.status,
        userRole: teamMember.role.roleName,
        subscriptionPlan: team.subscription.basePrice.description,
        joinedTeamId: team._id,
      },
    });
  }
};

const switchTeam = async (newTeam) => {
  await updateUserSettingsSelectedTeam(newTeam._id);
  triggerJoinedTeamEventForNewUser(newTeam);
};

const createTeam = async (settings) => {
  const newTeam = await backend.post("/teams", settings);
  if (teamsCache) {
    teamsCache.push(newTeam);
  } else {
    teamsCache = [newTeam];
  }
  eventService.emitEvent({
    eventName: EVENT_NAMES.TEAM.TEAMS.UPDATED,
    eventData: { teams: teamsCache },
  });
  analytics.track(analytics.ACTION.CREATED, analytics.CATEGORY.TEAM, {
    teamId: newTeam._id,
    teamName: newTeam.name,
  });
  return newTeam;
};

const updateTeamSettings = async (settings) => {
  const updatedTeam = await backend.post(
    `/teams/${selectedTeam._id}`,
    settings
  );
  updateSelectedTeam(updatedTeam);
  return updatedTeam;
};

const updateTeamByProjectChange = async ({ eventData: { project } }) => {
  if (project?.teamId) {
    const team = await fetchTeamByTeamId(project.teamId);
    if (team.isTeamMember) {
      updateUserSettingsSelectedTeam(project.teamId);
    }
  }
};

const wizardProgressTrack = async (id, data) => {
  const updatedTeam = await backend.post(`/teams/${id}`, {
    wizardProgressState: data,
  });
  updateSelectedTeam(updatedTeam);
  return updatedTeam;
};

/**
 * Add user to the given team
 * @param {string} email email of the user
 * @param {string} roleId role id assigning to the user
 * @return {Promise<Team>} Updated team data
 */
const addTeamMember = async (email, roleId) => {
  const team = await backend.post(`/teams/${selectedTeam._id}/members`, {
    email,
    roleId,
  });

  updateSelectedTeam(team);
  return team;
};

/**
 * Add user to the given team
 * @param {string} teamId unique id of the team
 * @return {Promise<Team>} Updated team data
 */
const joinTeamByDomain = async (teamId) => {
  const team = await backend.post(`/teams/${teamId}/join`);

  if (teamsCache) {
    teamsCache.push(team);
  } else {
    teamsCache = [team];
  }
  eventService.emitEvent({
    eventName: EVENT_NAMES.TEAM.TEAMS.UPDATED,
    eventData: { teams: teamsCache },
  });
  return team;
};

/**
 * Remove pending members from the given team
 * @param {string} teamId unique id of the team
 * @param {Array<string>} pendingMemberIds array of user ids to delete
 * @return {Promise<Team>} Updated team data
 */
const removePendingMembers = async (teamId, pendingMemberIds) => {
  const team = await backend.put(`/teams/${teamId}/pending_members`, {
    pendingMemberIds,
  });

  updateSelectedTeam(team);
  return team;
};

const triggerTrialSubscription = async (teamId, message) => {
  const team = await fetchTeamByTeamId(teamId);
  if (team.billing?.restartTrialCount) {
    window.Intercom("showNewMessage", message);
    return false;
  }

  return true;
};
const removeTeamMember = async (userId) => {
  const team = await backend.delete(
    `/teams/${selectedTeam._id}/members/${userId}`
  );
  const isLeavingTeam = authenticationService.fetchSession().userId === userId;
  if (isLeavingTeam) {
    clearCache();
    return false;
  }
  updateSelectedTeam(team);

  return team;
};

const assignRoleToTeamMember = async (userId, roleId) => {
  const updatedTeam = await backend.post(
    `/teams/${selectedTeam._id}/roles/${roleId}/assign`,
    {
      userId,
    }
  );
  updateSelectedTeam(updatedTeam);
  return updatedTeam;
};

const addTeamDomain = async (domainName, defaultRole, recipientEmail) => {
  const team = await backend.post(`/teams/${selectedTeam._id}/domains`, {
    domainName,
    defaultRole,
    recipientEmail,
  });

  updateSelectedTeam(team);
  return team;
};

const removeTeamDomain = async (domainId) => {
  const team = await backend.delete(
    `/teams/${selectedTeam._id}/domains/${domainId}`
  );
  updateSelectedTeam(team);
  return team;
};

const removeTeam = async (teamId) => {
  await backend.delete(`/teams/${teamId}`);
  if (teamId === selectedTeam?._id) {
    clearCache();
  } else {
    teamsCache = reject(teamsCache, (team) => team._id === teamId);
    eventService.emitEvent({
      eventName: EVENT_NAMES.TEAM.TEAMS.UPDATED,
      eventData: { teams: teamsCache },
    });
  }
};

const handleCollaboratorAdded = async (eventData) => {
  const isTeamAvailableInCache = await hasTeam(eventData.teamId);
  if (!isTeamAvailableInCache) {
    teamsCache = undefined;
  }
};
const checkStorageSizeExceeded = async (teamId, fileSize) => {
  const team = await fetchTeamByTeamId(teamId);
  const isStorageLimitExceeded =
    team.subscriptionLimits.maxStorageSizeBytes !== -1 &&
    team.subscriptionLimits.maxStorageSizeBytes <
      team.usedBillingLimits.usedStorageSizeByte + fileSize;

  if (isStorageLimitExceeded) {
    analytics.trackV2({
      action: analytics.ACTION.REACHED,
      category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
      subcategory: analytics.SUB_CATEGORY.STORAGE_QUANTITY,
    });

    NiceModal.show("UpgradeStoragePlanDialog", {
      ...AddSecureStorageGate.upgradeDialogsProps,
      teamId,
      teamName: team.name,
      isFreePlan: team.subscription?.basePrice.name
        .toLowerCase()
        .includes("free"),
    });
    return Promise.reject("Storage limit exceeded");
  }
  return Promise.resolve();
};

const removeTeamSubscriptionCache = (teamId) => {
  return backend.delete(`/admin/remove-team-subscription-cache/${teamId}`);
};

const changeCurrency = ({ teamId, currency }) => {
  return backend.post(`/admin/change-currency`, { currency, teamId });
};

const extendTeamTrialPeriod = async (teamId) => {
  const team = await backend.post(`/teams/${teamId}/billing/extend-trial`);

  if (selectedTeam?._id === teamId) {
    updateSelectedTeam(team);
    eventService.emitEvent({
      eventName: EVENT_NAMES.TEAM.SELECTED.UPDATED,
      eventData: { team },
    });
  }
  teamByIdCache.set(teamId._id, team);

  if (teamsCache) {
    const updatedCache = [...teamsCache];
    const teamIndex = updatedCache.findIndex(({ _id }) => _id === team._id);
    updatedCache[teamIndex] = team;
    teamsCache = updatedCache;
  }

  return team;
};

export let instance;

export function initialize() {
  eventService.addListener(
    EVENT_NAMES.FILE.REMOVED,
    ({ eventData: { isBulk } }) => {
      if (!isBulk) {
        updateUsedBillingLimitsOfSelectedTeam();
      }
    }
  );
  eventService.addListener(
    EVENT_NAMES.VERSION.ADDED,
    updateUsedBillingLimitsOfSelectedTeam
  );
  eventService.addListener(
    EVENT_NAMES.REVIEW.REMOVED,
    updateUsedBillingLimitsOfSelectedTeam
  );
  eventService.addListener(
    EVENT_NAMES.PROJECT.COLLABORATOR.ADDED,
    refetchSelectedTeam
  );
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);
  eventService.addListener(
    EVENT_NAMES.PROJECT.SELECTED,
    updateTeamByProjectChange
  );
  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.COLLABORATOR.ADDED,
    handleCollaboratorAdded
  );

  instance = {
    getTeams,
    switchTeam,
    createTeam,
    removeTeam,
    clearCache,
    addTeamDomain,
    addTeamMember,
    changeCurrency,
    getSelectedTeam,
    removeTeamDomain,
    joinTeamByDomain,
    removeTeamMember,
    fetchTeamByTeamId,
    updateSelectedTeam,
    updateTeamSettings,
    wizardProgressTrack,
    removePendingMembers,
    extendTeamTrialPeriod,
    assignRoleToTeamMember,
    checkStorageSizeExceeded,
    triggerTrialSubscription,
    fetchTeamsWithSameDomain,
    removeTeamSubscriptionCache,
    determineSelectedTeamByTeamId,
    determineSelectedTeamByDefault,
    determineSelectedTeamByProjectId,
    triggerJoinedTeamEventForNewUser,
    updateUsedBillingLimitsOfSelectedTeam,
  };
}
