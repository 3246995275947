/* eslint react/forbid-component-props:0 */
import { Link } from "react-router-dom";

import { GppGood } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import FaceIcon from "@mui/icons-material/Face";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const mainListItems = (
  <Box>
    <Link to="/admin/impersonate">
      <ListItemButton>
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText primary="Impersonate" />
      </ListItemButton>
    </Link>
    <Link to="/admin/disconnect-google">
      <ListItemButton>
        <ListItemIcon>
          <GoogleIcon />
        </ListItemIcon>
        <ListItemText primary="Disconnect Google" />
      </ListItemButton>
    </Link>
    <Link to="/admin/disable-sso">
      <ListItemButton>
        <ListItemIcon>
          <PersonOffIcon />
        </ListItemIcon>
        <ListItemText primary="Disable SSO" />
      </ListItemButton>
    </Link>
    <Link to="/admin/merge-accounts">
      <ListItemButton>
        <ListItemIcon>
          <ContentCopyIcon />
        </ListItemIcon>
        <ListItemText primary="Merge Accounts" />
      </ListItemButton>
    </Link>
    <Link to="/admin/security-whitelist-user">
      <ListItemButton>
        <ListItemIcon>
          <GppGood />
        </ListItemIcon>
        <ListItemText primary="Security Whitelist" />
      </ListItemButton>
    </Link>
    <Link to="/admin/repair-permissions">
      <ListItemButton>
        <ListItemIcon>
          <SyncLockIcon />
        </ListItemIcon>
        <ListItemText primary="Repair Permissions" />
      </ListItemButton>
    </Link>
    <Link to="/admin/remove-subscription-cache">
      <ListItemButton>
        <ListItemIcon>
          <EuroSymbolIcon />
        </ListItemIcon>
        <ListItemText primary="Remove Subscription Cache" />
      </ListItemButton>
    </Link>
    <Link to="/admin/change-currency">
      <ListItemButton>
        <ListItemIcon>
          <CurrencyExchangeIcon />
        </ListItemIcon>
        <ListItemText primary="Change team currency" />
      </ListItemButton>
    </Link>
    <Link to="/admin/change-user-email">
      <ListItemButton>
        <ListItemIcon>
          <ForwardToInboxOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Change user email" />
      </ListItemButton>
    </Link>
    <Link to="/admin/test-consolidated-comments">
      <ListItemButton>
        <ListItemIcon>
          <ForwardToInboxOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Test Consolidated Comments" />
      </ListItemButton>
    </Link>
  </Box>
);
