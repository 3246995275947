import { useMemo } from "react";

import { getEnabledReviewDecisionOptions } from "@feedback/helpers/getEnabledReviewDecisionOptions";
import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

const useAutomationStatus = (automation) => {
  const team = useSelectedTeam();

  const checkIsAutomationReviewDecisionDisabled = (automation) => {
    if (
      !team ||
      !automation ||
      automation.name !== "AUTOMATIONS.TRIGGER.REVIEW_DECISION.SUBMIT"
    ) {
      return false;
    }

    const isReviewDecisionEnabled = getEnabledReviewDecisionOptions(
      team
    ).includes(automation.conditions.reviewDecision);

    return !isReviewDecisionEnabled;
  };

  const isAutomationReviewDecisionDisabled = useMemo(() => {
    return checkIsAutomationReviewDecisionDisabled(automation);
  }, [automation, team]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isAutomationReviewDecisionDisabled,
    checkIsAutomationReviewDecisionDisabled,
  };
};

export default useAutomationStatus;
