import intersection from "lodash/intersection";

import { instance as localisationService } from "@shared/services/localisationService";

import { STATIC_GUIDE } from "./constants";

const CHECKLIST_LENGTH = 5;

const checklistSteps = Array.from(
  { length: CHECKLIST_LENGTH },
  (_, index) => index + 1
);

const getUserGuideIds = () => {
  return checklistSteps.reduce((guides, stepId) => {
    const guideId = Number(
      localisationService.i18n.t(`CHECKLIST.ONBOARDING_CHECKLIST.${stepId}`)
    );
    guides[guideId] = stepId;
    return guides;
  }, {});
};

const getGuideStepId = (guideId) => getUserGuideIds()[guideId];

const getProgress = (onboardingChecklistHistory) => {
  const completedGuides = intersection(
    onboardingChecklistHistory,
    checklistSteps
  );
  return Math.floor(
    ((completedGuides.length + 1) / (CHECKLIST_LENGTH + 1)) * 100
  );
};

const getChecklistItems = (onboardingChecklistHistory) => {
  const userGuideIds = getUserGuideIds();
  return [
    { guideId: STATIC_GUIDE, completed: true },
    ...Object.entries(userGuideIds).map(([guideId, stepId]) => ({
      guideId: Number(guideId),
      completed: onboardingChecklistHistory.includes(stepId),
    })),
  ];
};

const determineChecklistVisibility = (
  userCreatedDate,
  hasFinishedChecklist,
  isFileViewPage,
  isMobile,
  showConfetti,
  isSelectionState
) => {
  if (isMobile || isFileViewPage || isSelectionState) {
    return { showChecklist: false, showRestartButton: false };
  }

  const showChecklist = !hasFinishedChecklist || showConfetti;

  return { showChecklist, showRestartButton: !showChecklist };
};

const isTeamOnTrial = (team) => team?.subscription?.status === "trialing";
const canManageBilling = (team) => team?.permissions?.canManageBilling;

const determineExtendTrialVisibility = (
  isMenuOpen,
  showConfetti,
  hasTrialingTeam,
  extendTrialCount,
  hasRestartedOnboardingChecklist
) => {
  const isNewTrialUser = hasTrialingTeam && !hasRestartedOnboardingChecklist;

  const shouldShowOnCompletedTooltip =
    !isMenuOpen && showConfetti && isNewTrialUser;

  const canExtendTrial = isNewTrialUser && !extendTrialCount;

  return {
    canExtendTrial,
    shouldShowExtendTrialHeader: isNewTrialUser,
    shouldShowOnCompletedTooltip,
  };
};

export {
  getProgress,
  isTeamOnTrial,
  getGuideStepId,
  canManageBilling,
  getChecklistItems,
  determineChecklistVisibility,
  determineExtendTrialVisibility,
};
