const billingPlans = {
  free: {
    data: {},
    features: {
      main: {
        "SELECT_PLAN_DIALOG.UNLIMITED_FILES_REVIEWERS_VERSIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.UNLIMITED_FILES",
        },
        "SELECT_PLAN_DIALOG.PROJECTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.ACTIVE_PROJECTS",
          amount: 2,
        },
        "SELECT_PLAN_DIALOG.STORAGE.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.SECURE_STORAGE",
          amount: 2,
        },
        "SELECT_PLAN_DIALOG.REVIEW_STEPS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.REVIEW_STEPS",
        },
        "SELECT_PLAN_DIALOG.API.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.API",
        },
        "SELECT_PLAN_DIALOG.INTEGRATIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.INTEGRATIONS",
        },
      },
      additional: {},
    },
    subtitleKey: "SELECT_PLAN_SELECT_PLAN_DIALOG.FREE.DESCRIPTION",
    pricingDescriptionKey: "SELECT_PLAN_DIALOG.UNLIMITED_TEAM_MEMBERS",
    title: "SELECT_PLAN_DIALOG.FREE.TITLE",
  },
  basic: {
    data: {},
    features: {
      main: {
        "SELECT_PLAN_DIALOG.PROJECTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.ACTIVE_PROJECTS",
          amount: 10,
        },
        "SELECT_PLAN_DIALOG.STORAGE.TOOLTIP": {
          text: "PRICING.BASIC_V9.STORAGE",
          amount: 250,
        },
        "SELECT_PLAN_DIALOG.REVIEW_STEPS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.REVIEW_STEPS",
        },
        "SELECT_PLAN_DIALOG.AUTOMATIONS.TOOLTIP": {
          text: "PRICING.BASIC_V9.AUTOMATION",
        },
        "SELECT_PLAN_DIALOG.PROJECT_TEMPLATES.TOOLTIP": {
          text: "PRICING.STANDARD.TEMPLATES",
        },
        "SELECT_PLAN_DIALOG.FEATURES.TEAM_ONLY_COMMENT_HELP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.TEAM_ONLY_COMMENTS",
        },
        "SELECT_PLAN_DIALOG.COPY_COMMENTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.COPY_COMMENTS",
        },
      },
      additional: {},
    },
    subtitleKey: "SELECT_PLAN_DIALOG.BASIC.DESCRIPTION",
    featureHeader: "SELECT_PLAN_DIALOG.EVERYTHING_IN_FREE",
    pricingDescriptionKey: "SELECT_PLAN_DIALOG.UNLIMITED_TEAM_MEMBERS",
    title: "SELECT_PLAN_DIALOG.BASIC.TITLE",
  },
  professional: {
    data: {},
    features: {
      main: {},
      additional: {
        "SELECT_PLAN_DIALOG.PROJECTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.ACTIVE_PROJECTS",
          amount: 25,
        },
        "SELECT_PLAN_DIALOG.STORAGE.TOOLTIP": {
          text: "PRICING.PRO_V9.STORAGE",
        },
        "SELECT_PLAN_DIALOG.REVIEW_STEPS.TOOLTIP": {
          text: "PRICING.PRO.REVEIW_STEPS",
        },
        "SELECT_PLAN_DIALOG.AUTOMATIONS.TOOLTIP": {
          text: "PRICING.PRO_V9.AUTOMATION",
        },
        "SELECT_PLAN_DIALOG.PROJECT_TEMPLATES.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.PROJECT_TEMPLATES",
        },
        "SELECT_PLAN_DIALOG.COMPARE_VERSIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.COMPARE_VERSIONS",
        },
        "SELECT_PLAN_DIALOG.CUSTOM_BRANDING.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.CUSTOM_BRANDING",
        },
        "SELECT_PLAN_DIALOG.EXTERNAL_UPLOAD.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.EXTERNAL_UPLOADS",
        },
        "SELECT_PLAN_DIALOG.REVIEW_REPORT.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.REVIEW_REPORT",
        },
        "SELECT_PLAN_DIALOG.MEMBER_ROLE.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.MEMBER_ROLE",
        },
        "SELECT_PLAN_DIALOG.EXTRA_REVIEW_DECISION.TOOLTIP": {
          text: "PRICING.PRO_V9.EXTRA_REVIEW_DECISION",
        },
      },
    },
    subtitleKey: "SELECT_PLAN_DIALOG.PROFESSIONAL.DESCRIPTION",
    featureHeader: "SELECT_PLAN_DIALOG.EVERYTHING_IN_BASIC",
    pricingDescriptionKey: "SELECT_PLAN_DIALOG.UNLIMITED_TEAM_MEMBERS",
    title: "PRICING.PRO.TITLE",
  },
  enterprise: {
    data: {
      name: "Enterprise",
    },
    features: {
      main: {},
      additional: {
        "SELECT_PLAN_DIALOG.PROJECTS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.MORE_PROJECTS",
        },
        "SELECT_PLAN_DIALOG.STORAGE.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.UNLIMITED_SECURE_STORAGE",
        },
        "SELECT_PLAN_DIALOG.REVIEW_STEPS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.UNLIMITED_REVIEW_STEPS",
        },
        "SELECT_PLAN_DIALOG.AUTOMATIONS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.UNLIMITED_AUTOMATIONS",
        },
        "SELECT_PLAN_DIALOG.UNLIMITED_PROJECT_TEMPLATES.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.UNLIMITED_PROJECT_TEMPLATES",
        },
        "SELECT_PLAN_DIALOG.SSO.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.SSO",
        },
        "SELECT_PLAN_DIALOG.ADVACNED_SECURITY_SETTINGS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.ADVANCED_SECURITY_SETTINGS",
        },
        "SELECT_PLAN_DIALOG.CUSTOM_TEAM_ROLES.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.CUSTOM_TEAM_ROLES",
        },
        "SELECT_PLAN_DIALOG.FULL_FILE_REPORT.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.FULL_FILE_REPORT",
        },
        "SELECT_PLAN_DIALOG.MULTIPLE_TEAMS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.MULTIPLE_TEAMS",
        },
        "SELECT_PLAN_DIALOG.SLA.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.SLA",
        },
        "SELECT_PLAN_DIALOG.TEAM_ONBOARDING.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.TEAM_ONBOARDING",
        },
        "SELECT_PLAN_DIALOG.CSM.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.CSM",
        },
        "SELECT_PLAN_DIALOG.APPROVALS.TOOLTIP": {
          text: "SELECT_PLAN_DIALOG.FEATURE.VERIFIED_APPROVALS",
        },
        "SELECT_PLAN_DIALOG.ACCESS_LIMIT.TOOLTIP": {
          text: "PRICING.ENT_V9.LIMIT_ACCESS",
        },
      },
    },
    subtitleKey: "PRICING.ADVANCED.SUBTITLE",
    featureHeader: "SELECT_PLAN_DIALOG.EVERYTHING_IN_PROFESSIONAL",
    pricingDescriptionKey:
      "SELECT_PLAN_DIALOG.ENTERPRISE.PERSONALIZED_CONSULTATION",
    title: "PRICING.ADVANCED.TITLE",
  },
};

const calculatePrice = (amount, tenure, seats) => {
  const perSeatPrice = amount / 100 / seats;
  return tenure === "year" ? perSeatPrice / 12 : perSeatPrice;
};

const isPlanDowngrade = (currentPlan, nextPlan) => {
  const currentCycleAmount =
    currentPlan.amount * (currentPlan.interval === "year" ? 12 : 1);
  const nextCycleAmount =
    nextPlan.amount * (nextPlan.interval === "year" ? 12 : 1);
  return currentCycleAmount > nextCycleAmount;
};

export { billingPlans, calculatePrice, isPlanDowngrade };
