import PropTypes from "prop-types";
import { useState, useLayoutEffect, useRef, useEffect } from "react";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";

import { useProjectListScrollContainer } from "../projectListScrollContainerContext";

const PROJECT_HEIGHT = 26;

export default function LazyRenderChunk({ id, chunk, children }) {
  const chunkNode = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const { addChunk, removeChunk, chunks, mapProjectsToChunks } =
    useProjectListScrollContainer();

  useEffect(() => {
    mapProjectsToChunks(
      chunk.map((project) => project.id),
      id
    );
  }, [chunk, id, mapProjectsToChunks]);

  useLayoutEffect(() => {
    const chunkEl = chunkNode.current;
    const notifyTarget = () => {
      if (!chunks.has(id)) {
        return;
      }
      const [visibilityState] = chunks.get(id);
      setIsVisible(visibilityState);
    };
    addChunk(id, chunkEl, notifyTarget);

    if (chunks.has(id)) {
      const [visibilityState] = chunks.get(id);
      setIsVisible(visibilityState);
    }

    return () => {
      removeChunk(id, chunkEl);
    };
  }, [addChunk, chunks, id, removeChunk]);

  return (
    <Box
      id={id}
      ref={chunkNode}
      data-chunk-id={id}
      data-testid="lazy-render-chunk"
    >
      {!isVisible ? (
        <Box
          height={chunk.length * PROJECT_HEIGHT}
          data-testid="lazy-render-placeholder"
        />
      ) : (
        children
      )}
    </Box>
  );
}

LazyRenderChunk.propTypes = {
  id: PropTypes.string.isRequired,
  chunk: PropTypes.arrayOf(PropTypes.shape(projectProps)).isRequired,
  children: PropTypes.node.isRequired,
};
