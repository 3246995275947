import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

import { InfoToolTip, Text } from "@shared/UIKit";

function FeatureName({ name, tooltip, isEnabled }) {
  const { t } = useTranslation();

  return (
    <InfoToolTip title={tooltip} data-testid={`tooltip-${name}`}>
      <Box
        display="flex"
        border="1px solid"
        padding={1}
        textAlign="center"
        minHeight={2}
        borderRadius={0.75}
        borderColor={isEnabled ? "success.main" : "error.main"}
        color={isEnabled ? "success.main" : "error.main"}
      >
        <Box display="flex" alignItems="center" gap={0.5}>
          {isEnabled ? (
            <CheckIcon fontSize="small" data-testid="enabled-feature" />
          ) : (
            <CloseIcon fontSize="small" data-testid="disabled-feature" />
          )}
          <Text variant="subtitle1">{t(name)}</Text>
        </Box>
      </Box>
    </InfoToolTip>
  );
}

FeatureName.propTypes = {
  name: PropTypes.string.isRequired,
  tooltip: PropTypes.element.isRequired,
  isEnabled: PropTypes.bool,
};
FeatureName.defaultProps = {
  isEnabled: false,
};

export default FeatureName;
