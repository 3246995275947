import PropTypes from "prop-types";
import { ReactElement } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { deDE, frFR, enUS, esES } from "@mui/x-date-pickers/locales";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { de } from "date-fns/locale/de";
import { enUS as en } from "date-fns/locale/en-US";
import { es } from "date-fns/locale/es";
import { fr } from "date-fns/locale/fr";

const CALENDAR_TRANSLATIONS_MAP = {
  de: deDE,
  fr: frFR,
  en: enUS,
  es: esES,
};

const LOCALE_MAP = {
  de,
  fr,
  en,
  es,
};

/**
 * DateLocalizationProvider Component
 * @description This is a wrapper for the Material UI LocalizationProvider.
 * It returns the children component wrapped with date localization.
 * @param {{children: ReactElement, language: string}} props - Component props
 * @param {ReactElement} props.children - [Required] Children component
 * @param {string} props.language - [Required] Language
 * @returns {ReactElement} - React component
 */
export default function DateLocalizationProvider({ children, language }) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={LOCALE_MAP[language]}
      localeText={
        CALENDAR_TRANSLATIONS_MAP[language].components.MuiLocalizationProvider
          .defaultProps.localeText
      }
    >
      {children}
    </LocalizationProvider>
  );
}

DateLocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
  language: PropTypes.string.isRequired,
};
