import PropTypes from "prop-types";
import Confetti from "react-confetti";

import { Box, List } from "@mui/material";

import { Text } from "@shared/UIKit";

import { instance as logger } from "@shared/services/logger";

import PartyPopperIcon from "@assets/img/icons/party-popper.svg";

import ChecklistItem from "./ChecklistItem";
import { STATIC_GUIDE } from "./constants";
import { getChecklistItems } from "./helper";
import ProgressBar from "./ProgressBar";

function Checklist({
  handleClose,
  showConfetti,
  canExtendTrial,
  checklistProgress,
  onboardingChecklistHistory,
  shouldShowExtendTrialHeader,
  shouldShowGiftIcon,
}) {
  const previewGuide = (guideId) => {
    if (guideId === STATIC_GUIDE || !window.userGuiding) {
      if (!window.userGuiding) {
        logger.warn("onboarding-checklist", "UserGuiding is not available");
      }
      return;
    }
    window.userGuiding.previewGuide(guideId);
    handleClose();
  };

  const checklistItems = getChecklistItems(onboardingChecklistHistory);

  return (
    <>
      <Box
        px={3}
        pt={2}
        top={0}
        zIndex={2}
        display="flex"
        position="sticky"
        flexDirection="column"
        backgroundColor="grey.50"
        justifyContent="space-between"
      >
        <Text
          variant="textXl"
          translate="CHECKLIST.ONBOARDING_SECTION.LEARN.TITLE"
          fontWeight="fontWeight.bold"
        />
        {shouldShowExtendTrialHeader && (
          <Text
            variant="textSm"
            color="grey.500"
            translate="CHECKLIST.ONBOARDING_SECTION.LEARN.BODY"
            pb={1}
          />
        )}
        <ProgressBar
          checklistProgress={checklistProgress}
          shouldShowGiftIcon={shouldShowGiftIcon}
        />
      </Box>
      <List sx={{ bgcolor: "grey.50" }} dense={false}>
        {checklistItems.map((checklistItem, index) => (
          <ChecklistItem
            index={index}
            key={checklistItem.guideId}
            previewGuide={previewGuide}
            checklistItem={checklistItem}
          />
        ))}
        {showConfetti && (
          <Box
            p={1}
            gap={1}
            display="flex"
            borderRadius={1}
            alignItems="center"
            alignContent="center"
            sx={{ backgroundColor: "#ba53fa" }}
          >
            <Confetti
              width={340}
              height={1000}
              colors={["#2ecc71", "#19b5fe", "#ffbf3e"]}
              opacity={0.6}
              data-testid="confetti"
            />
            <PartyPopperIcon />
            <Box>
              <Text
                color="grey.100"
                variant="textMd"
                fontWeight="fontWeight.bold"
                translate="CHECKLIST.BANNER.CONGRATULATION.TITLE"
              />
              <Text
                color="grey.100"
                variant="textMd"
                translate={
                  canExtendTrial
                    ? "CHECKLIST.BANNER.CONGRATULATION.BODY"
                    : "CHECKLIST.BANNER.COMPLETED.BODY"
                }
              />
            </Box>
          </Box>
        )}
      </List>
    </>
  );
}

Checklist.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onboardingChecklistHistory: PropTypes.array.isRequired,
  checklistProgress: PropTypes.number.isRequired,
  showConfetti: PropTypes.bool.isRequired,
  canExtendTrial: PropTypes.bool.isRequired,
  shouldShowExtendTrialHeader: PropTypes.bool.isRequired,
  shouldShowGiftIcon: PropTypes.bool.isRequired,
};

export default Checklist;
