import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button } from "@mui/material";
import AutomationsHeader from "@workflow/components/AutomationsDialog/AutomationsHeader";
import { DIALOG_STATE } from "@workflow/constants/automation";

import { Text } from "@shared/UIKit";
import { useWizardDialog } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import { useMediaQuery } from "@shared/hooks";

const AutomationListHeader = ({ automationsState }) => {
  const { t } = useTranslation();
  const { moveToStep, onClose, state } = useWizardDialog();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleAddAutomation = useCallback(() => {
    moveToStep("add-edit-automation");
  }, [moveToStep]);

  return (
    <AutomationsHeader
      startContent={
        <Text variant="textXl" data-testid="automations-title-label">
          {t("AUTOMATIONS.BUTTON.TITLE")}
        </Text>
      }
      endContent={
        state.canManageAutomations &&
        automationsState === DIALOG_STATE.HAS_LIMIT && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddAutomation}
            data-testid="create-automation-button"
            sx={{
              "& .MuiButton-startIcon": {
                ml: { xs: 1, sm: 0 },
              },
            }}
          >
            {!isMobile && t("AUTOMATIONS.DIALOG.BUTTON.CREATE-AUTOMATION")}
          </Button>
        )
      }
      closeDialog={onClose}
    />
  );
};

AutomationListHeader.propTypes = {
  automationsState: PropTypes.oneOf([
    DIALOG_STATE.HAS_LIMIT,
    DIALOG_STATE.NOT_SUPPORTED,
    DIALOG_STATE.PROFESSIONAL,
    DIALOG_STATE.PROFESSIONAL_LIMIT,
  ]).isRequired,
};

export default AutomationListHeader;
