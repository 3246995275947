import PropTypes from "prop-types";

import useActiveUser from "@supporting/hooks/useActiveUser";

import { Box, Dialog } from "@shared/UIKit";

import AIDialogCategoryList from "./AIDialogCategoryList";
import AIMarketplaceDialogHeader from "./AIMarketplaceDialogHeader";
import AIMarketplaceDialogTitle from "./AIMarketplaceDialogTitle";
import AIMarketplaceDialogTutorial from "./AIMarketplaceTutorial";

const selecteDismissedAIMarketplaceVideoAt = (user) =>
  user.settings.dismissedAIMarketplaceVideoAt;

function AIMarketplaceDialog({ closeDialog, isOpen }) {
  const dismissedAIMarketplaceVideoAt = useActiveUser(
    selecteDismissedAIMarketplaceVideoAt
  );

  return (
    <Dialog
      cancel={closeDialog}
      showCloseButton={false}
      useDefaultBackground={false}
      data-testid="ai-marketplace-dialog"
      isOpen={isOpen}
      maxWidth="md"
      title={<AIMarketplaceDialogTitle closeDialog={closeDialog} />}
      sx={{
        "& .MuiDialog-paper, .MuiDialogTitle-root": {
          padding: 0,
        },
      }}
    >
      <Box display="flex" flexDirection="column">
        <AIMarketplaceDialogHeader />
        {!dismissedAIMarketplaceVideoAt && <AIMarketplaceDialogTutorial />}
        <AIDialogCategoryList />
      </Box>
    </Dialog>
  );
}

AIMarketplaceDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default AIMarketplaceDialog;
