import PropTypes from "prop-types";
import { useTranslation, Trans } from "react-i18next";

import { Link } from "@mui/material";
import useBillingLimits from "@supporting/hooks/useBillingLimits";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import ConfirmationDialog from "@shared/UIKit/ConfirmationDialog";

import { useNavigation } from "@shared/hooks";
import { userProp } from "@shared/props/authentication";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

function RemoveTeamMemberDialog({
  cancel,
  teamMember,
  isSelf,
  isOpen,
  teamId,
  teamName,
  hasApiKeyOrWebhook,
}) {
  const { t } = useTranslation();
  const { goTo } = useNavigation();
  const { includedUsers } = useBillingLimits(teamId);
  const onRemoveTeamMemberSubmitted = async () => {
    try {
      const team = await teamService.removeTeamMember(teamMember._id);
      toastService.sendToast({
        title: "REMOVE-TEAM-MEMBER.TOAST.TITLE",
        body: "REMOVE-TEAM-MEMBER.TOAST.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: { url: `teams/${teamId}/subscription` },
          title: { Firstname: teamMember.displayName },
        },
      });
      analytics.track(
        analytics.ACTION.REMOVED,
        analytics.CATEGORY.TEAM_MEMBER,
        {
          removedUserId: teamMember._id,
          removedEmail: teamMember.email,
          teamId,
          teamName,
        }
      );
      if (!team) {
        goTo("DASHBOARD");
        return;
      }
    } catch (error) {
      errorHandlerService.handleError(error);
    }
  };
  const translationLabel = isSelf ? ".SELF" : "";
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      title={t(`TEAM.REMOVE_MEMBER${translationLabel}.HEADLINE`)}
      description={
        <Trans>
          {t(`TEAM.REMOVE_MEMBER${translationLabel}.DESCRIPTION-1`, {
            teamMember: teamMember.displayName,
          })}
        </Trans>
      }
      infoMessage={
        includedUsers !== -1 || hasApiKeyOrWebhook ? (
          <Trans
            components={{
              anchor: (
                <Link
                  href={t(
                    hasApiKeyOrWebhook
                      ? "TEAM_MEMBER.REMOVE.DIALOG.INFO_LINK"
                      : "TEAM.REMOVE_MEMBER.INFO_LINK"
                  )}
                  target="_blank"
                  color="accent"
                />
              ),
            }}
          >
            {t(
              hasApiKeyOrWebhook
                ? `TEAM_MEMBER.REMOVE.DIALOG.HELP-1`
                : `TEAM.REMOVE_MEMBER.INFO`,
              hasApiKeyOrWebhook && {
                teamMember: teamMember.displayName,
              }
            )}
          </Trans>
        ) : null
      }
      cancelLabel={t("CORE.CANCEL")}
      confirmLabel={t(`TEAM.REMOVE_MEMBER${translationLabel}.CONFIRM`)}
      closeDialog={cancel}
      answer={onRemoveTeamMemberSubmitted}
      checkMessage={
        hasApiKeyOrWebhook ? t("TEAM_MEMBER.REMOVE.DIALOG.HELP-2") : null
      }
    />
  );
}

RemoveTeamMemberDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  teamMember: userProp.isRequired,
  isSelf: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  teamId: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  hasApiKeyOrWebhook: PropTypes.bool,
};

RemoveTeamMemberDialog.defaultProps = {
  isSelf: false,
  hasApiKeyOrWebhook: false,
};

export default RemoveTeamMemberDialog;
