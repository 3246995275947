import PropTypes from "prop-types";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Brush, Image } from "@mui/icons-material";
import { Button } from "@mui/material";

/**
 * UIKit Comment Badge Component
 * @description A component to display comment actions within a badge on the CommentCard.
 * It supports multiple types of actions, including a button and an icon.
 * @param {object} props - Component props
 * @param {string} [props.type] [Optional] - The type of the button. It can be one of the following: `show_annotation`, `attachment`, `hide_annotation`.
 * @param {string} [props.filename] [Optional] - Filename for the attachment.
 * @param {string} [props.className] [Optional] - Additional class name
 * @param {boolean} [props.setTitle] [Optional] - If true, the title will be set to the filename.
 * @param {import("@mui/material").ButtonProps} props.restProps [Optional] - Additional props for the Button component.
 * @returns {ReactElement} - React component
 */
function CommentBadge({ type, filename, setTitle, ...restProps }) {
  const { t } = useTranslation();

  let IconComponent;
  if (type === "attachment") {
    IconComponent = <Image alt="Attachment" />;
  } else {
    IconComponent = <Brush />;
  }

  let renderByType;
  switch (type) {
    case "show_annotation":
      renderByType = t(`ANNOTATION.SHOW_ANNOTATION`);
      break;
    case "hide_annotation":
      renderByType = t(`ANNOTATION.HIDE_ANNOTATION`);
      break;
    case "attachment":
      renderByType = `${filename.substring(0, 10)}...`;
      break;
  }

  return (
    <Button
      variant="outlined"
      color="default-light"
      startIcon={IconComponent}
      title={setTitle ? filename : ""}
      {...restProps}
    >
      {renderByType}
    </Button>
  );
}

CommentBadge.propTypes = {
  type: PropTypes.oneOf(["show_annotation", "attachment", "hide_annotation"]),
  filename: PropTypes.string,
  setTitle: PropTypes.bool,
};

CommentBadge.defaultProps = {
  filename: "",
  type: "show_annotation",
  setTitle: false,
};

export default CommentBadge;
