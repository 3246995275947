/**
 * This service is used to cache the visible sections for each project.
 * The cache is a map of project ID to ProjectData.
 *
 * @typedef ProjectData
 * @property {Array<SectionData>} sections the sections to show on the UI.
 * @property {boolean} hasMore whether there are more files to fetch.
 */

import dashboardSectionFilesCache from "@workflow/services/dashboardSectionFilesCache";
import projectService from "@workflow/services/projectService";
import debounce from "lodash/debounce";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";

export default {
  initialize,
  getProjectSections,
  getMoreProjectSections,
};

const projectSectionsCache = new Map();

/**
 * Initialize the service by setting up event listeners.
 */
function initialize() {
  clearCache();

  eventService.addListener(
    EVENT_NAMES.PROJECT.SECTION.COLLAPSED,
    onSectionUpdate
  );
  eventService.addListener(
    EVENT_NAMES.PROJECT.SECTIONS.COLLAPSED,
    onSectionsCollapsed
  );
  eventService.addListener(
    EVENT_NAMES.PROJECT.SECTION.CREATED,
    onSectionUpdate
  );
  eventService.addListener(
    EVENT_NAMES.PROJECT.SECTION.REMOVED,
    onSectionUpdate
  );
  eventService.addListener(
    EVENT_NAMES.PROJECT.SECTION.RENAMED,
    onSectionUpdate
  );
  eventService.addListener(EVENT_NAMES.PROJECT.SECTION.MOVED, onSectionUpdate);
  eventService.addListener(
    EVENT_NAMES.PROJECT.SECTION_FILES.UPDATED,
    onSectionFilesUpdated
  );
  eventService.addListener(EVENT_NAMES.USER.SIGNED_UP, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_IN, clearCache);
  eventService.addListener(EVENT_NAMES.USER.LOGGED_OUT, clearCache);
}

/**
 * Get the ProjectData for the given project.
 * If the project is not in the cache, ProjectData will be calculated, cached and returned.
 * If the project is in the cache, the cached ProjectData will be returned.
 *
 * @param {string} projectId the project ID.
 * @returns {Promise<ProjectData>} the project data for the given project.
 */
async function getProjectSections(projectId) {
  if (!projectSectionsCache.has(projectId)) {
    const project = await projectService.fetchProjectById(projectId);
    updateProjectSections(project);
  }
  return projectSectionsCache.get(projectId);
}

/**
 * Fetch the next batch of files from the first incomplete section of the given project and
 * return the updated ProjectData.
 *
 * @param {string} projectId the project ID.
 * @returns {Promise<any>} the project data for the given project.
 */
async function getMoreProjectSections(projectId) {
  const sections = projectSectionsCache.get(projectId).sections;
  await dashboardSectionFilesCache.getMoreSectionFiles(
    projectId,
    sections[sections.length - 1].id
  );
  const project = await projectService.fetchProjectById(projectId);
  updateProjectSections(project);
  return projectSectionsCache.get(projectId);
}

const debouncedUpdateProjectSectionsAndNotify = debounce(
  updateProjectSectionsAndNotify,
  10
);

function onSectionUpdate(event) {
  const { project } = event.eventData;
  debouncedUpdateProjectSectionsAndNotify(project);
}

async function onSectionFilesUpdated(event) {
  const { sectionId } = event.eventData;
  const project = await getSectionProject(sectionId);
  if (project) {
    debouncedUpdateProjectSectionsAndNotify(project);
  }
}

function updateProjectSectionsAndNotify(project) {
  updateProjectSections(project);
  emitProjectSectionsUpdated(project.id, projectSectionsCache.get(project.id));
}

async function getSectionProject(sectionId) {
  for (const [projectId, sectionData] of projectSectionsCache.entries()) {
    if (sectionData.sections.some((section) => section.id === sectionId)) {
      return await projectService.fetchProjectById(projectId);
    }
  }
  return null;
}

function updateProjectSections(project) {
  projectSectionsCache.set(
    project.id,
    buildSections(
      project.sections.map((section) =>
        buildSection(
          section,
          dashboardSectionFilesCache.getSectionFiles(project.id, section.id)
        )
      )
    )
  );
}

function buildSection(section, { projectId, files, fileCount, hasMore }) {
  return {
    ...section,
    projectId,
    files: section.isCollapsed ? [] : files,
    fileCount,
    hasMore,
  };
}

function onSectionsCollapsed(event) {
  const { project } = event.eventData;
  debouncedUpdateProjectSectionsAndNotify(project);
}

function buildSections(sections) {
  const newSections = sections.reduce((result, section) => {
    const lastSection = result[result.length - 1];
    return lastSection?.hasMore && !lastSection?.isCollapsed
      ? result
      : [...result, section];
  }, []);
  const lastSection = newSections[newSections.length - 1];
  return {
    sections: newSections,
    hasMore:
      newSections.length < sections.length ||
      (lastSection.hasMore && !lastSection.isCollapsed),
  };
}

function emitProjectSectionsUpdated(projectId, { sections, hasMore }) {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PROJECT.SECTIONS.UPDATED,
    eventData: { projectId, sections, hasMore },
  });
}

function clearCache() {
  projectSectionsCache.clear();
}
