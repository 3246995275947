import PropTypes from "prop-types";

import { Dialog, Grow, Paper, Popper } from "@mui/material";
import { Box } from "@mui/system";

import { useMediaQuery } from "@shared/hooks";

const ResponsiveMenu = ({
  children,
  anchorEl,
  showChecklist,
  isFileViewPage,
  ...props
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const translateYValue = showChecklist ? "20px" : "80px";

  if (isMobile) {
    return (
      <Dialog
        fullScreen
        maxWidth="xl"
        sx={{
          ".MuiDialog-paper": {
            textAlign: "left",
          },
        }}
        PaperProps={{ sx: { backgroundImage: "none" } }}
        {...props}
      >
        {children}
      </Dialog>
    );
  }
  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="top-end"
      sx={{
        zIndex: 25,
        width: 360,
        transform: isFileViewPage
          ? "none"
          : `translateY(-${translateYValue}) translateX(-20px)!important`,
      }}
      transition
      {...props}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          sx={{
            transformOrigin: isFileViewPage ? "right top" : "right bottom",
          }}
        >
          <Paper>
            <Box
              sx={{
                overflow: "auto",
                maxHeight: `calc(100vh - ${translateYValue})`,
                position: "relative",
                borderRadius: 1,
              }}
            >
              {children}
            </Box>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

ResponsiveMenu.propTypes = {
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.object,
  showChecklist: PropTypes.bool.isRequired,
  isFileViewPage: PropTypes.bool.isRequired,
};

ResponsiveMenu.defaultProps = {
  anchorEl: null,
};

export default ResponsiveMenu;
