import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { Image } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";

import Logo from "@assets/img/sprite-source/sprite--logo.png";
import Logo2x from "@assets/img/sprite-source/sprite--logo@2x.png";

import Background from "./Background";
import SidePanel from "./SidePanel";

function WizardBase({ children, variant }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box width="100%" position="fixed" bgcolor="white">
      <Box
        width="100%"
        height="100vh"
        display="flex"
        overflow="auto"
        flexDirection="column"
      >
        {!isMobile && <Background />}
        <Box
          pt={4}
          px={5}
          flexGrow={1}
          minWidth={300}
          display="flex"
          boxSizing="border-box"
          justifyContent={{ xs: "center", sm: "flex-start" }}
        >
          <Box>
            <Image
              src={Logo}
              srcSet={`${Logo} 1x, ${Logo2x} 2x`}
              alt="Filestage logo"
              sx={{ width: { xs: 115, sm: "unset" } }}
            />
          </Box>
        </Box>
        <Box display="flex" flexGrow={{ xs: 8, md: 1 }}>
          {!isMobile && <SidePanel variant={variant} />}
          <Box
            width="100%"
            display="flex"
            minWidth={300}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Box
              p={2}
              flexGrow={1}
              maxWidth={432}
              minWidth={300}
              display="flex"
              boxSizing="border-box"
              flexDirection="column"
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
WizardBase.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
export default WizardBase;
