import PropTypes from "prop-types";
import { createContext, useContext } from "react";

import { makeStyles } from "@mui/styles";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import useDragToScroll from "./hooks/useDragToScroll";

export const ScrollBoxState = createContext(null);
export const useScrollBoxState = () => useContext(ScrollBoxState);

const useStyles = makeStyles(() => ({
  ScrollBox: {
    overflow: "auto",
    cursor: "grab",
    "&:active": {
      userSelect: "none",
    },
  },
  disableScroll: {
    overflow: "hidden",
  },
}));

function ScrollBox({
  enableHorizontalDrag,
  enableVerticalDrag,
  className,
  children,
  disableScrollFromRoot,
  ...restProps
}) {
  const classes = useStyles();

  const { containerProps, enableScroll, disableScroll } = useDragToScroll({
    horizontal: enableHorizontalDrag,
    vertical: enableVerticalDrag,
  });

  return (
    <ScrollBoxState.Provider
      value={{
        disableScroll,
        enableScroll,
      }}
    >
      <Box
        {...containerProps}
        {...restProps}
        className={classnames(classes.ScrollBox, className, {
          [classes.disableScroll]: disableScrollFromRoot,
        })}
        data-testid="scroll-box-root"
      >
        {children}
      </Box>
    </ScrollBoxState.Provider>
  );
}

ScrollBox.propTypes = {
  enableHorizontalDrag: PropTypes.bool.isRequired,
  enableVerticalDrag: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  disableScrollFromRoot: PropTypes.bool,
};

ScrollBox.defaultProps = {
  className: "",
  disableScrollFromRoot: false,
};

export default ScrollBox;
