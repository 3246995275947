import PropTypes from "prop-types";

import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useFileCardResized } from "@workflow/components/FileCardResizerProvider/FileCardResizerProvider";

import { Text, Tooltip } from "@shared/UIKit";

import MEDIA_TYPES from "@shared/constants/mediaTypes";
import { fileProp, versionProp } from "@shared/props/file";
import { projectProps } from "@shared/props/project";

import ProjectFileMenu from "../../ProjectFileMenu/ProjectFileMenu";

const FILE_VERSION_PADDING = 98.5;

function FileVersionCard({ project, file, version }) {
  const { width, minWidth, maxWidth } = useFileCardResized();

  const theme = useTheme();

  const fileName =
    version.mediaType === MEDIA_TYPES.WEBSITE
      ? version.websiteUrl
      : version.original.name;

  return (
    <Box
      data-testid="file-version-card"
      height={40}
      display="flex"
      boxSizing="border-box"
      paddingLeft={1.5}
      alignItems="center"
      justifyContent="space-between"
      bgcolor="white"
      border="1px var(--mui-palette-grey-200) solid"
      borderTop={0}
      boxShadow={theme.shadow["box-shadow-file-card"]}
      sx={{
        clipPath: "inset(0px -15px 0px 0px)",
        width: width - FILE_VERSION_PADDING,
        maxWidth: maxWidth - FILE_VERSION_PADDING,
        minWidth: minWidth - FILE_VERSION_PADDING,
      }}
    >
      <Tooltip title={fileName}>
        <Text
          color="text.secondary"
          variant="textXs"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {fileName}
        </Text>
      </Tooltip>
      <Box px={1} py={1} display="flex" alignItems="center" gap={1}>
        <Text color="text.secondary" variant="subtitle2">
          v{version.number}
        </Text>
        <ProjectFileMenu
          project={project}
          version={version}
          file={file}
          variant="version"
        />
      </Box>
    </Box>
  );
}

FileVersionCard.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  version: versionProp.isRequired,
  file: fileProp.isRequired,
};

export default FileVersionCard;
