import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import googleDriveService from "@integrations/services/googleDriveService";
import { SvgIcon, IconButton } from "@mui/material";
import toastService from "@supporting/services/toast";
import projectService from "@workflow/services/projectService";

import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

import GoogleDriveDisabledLogo from "@assets/img/images/google-drive-logo-disabled.svg";
import GoogleDriveLogo from "@assets/img/images/google-drive-logo.svg";

function GoogleDriveSettingsButton({ onClick }) {
  const [projectFolderConnection, setProjectFolderConnection] = useState(null);
  const { projectId } = useParams();

  const handleButtonClick = (event) => {
    onClick(event, "google-drive");
  };

  useEffect(() => {
    const handleFileVersionUploadedToGoogleDrive = ({
      projectId: eventProjectId,
    }) => {
      if (eventProjectId === projectId) {
        toastService.sendToast({
          title: "GOOGLE_DRIVE.TOAST_SUCCESS_APPROVED.TITLE",
          body: "GOOGLE_DRIVE.TOAST_SUCCESS_APPROVED.DESC",
          preset: toastService.PRESETS().SUCCESS,
        });
      }
    };

    const handleFileVersionUploadToGoogleDriveFailed = ({
      projectId: eventProjectId,
    }) => {
      if (eventProjectId === projectId) {
        toastService.sendToast({
          title: "GOOGLE_DRIVE.TOAST_ERROR_APPROVED.TITLE",
          body: "GOOGLE_DRIVE.TOAST_ERROR_APPROVED.DESC",
          preset: toastService.PRESETS().ERROR,
        });
      }
    };

    websocket.addListener(
      websocket.EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.VERSION.UPLOAD.SUCCEEDED,
      handleFileVersionUploadedToGoogleDrive
    );
    websocket.addListener(
      websocket.EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.VERSION.UPLOAD.FAILED,
      handleFileVersionUploadToGoogleDriveFailed
    );

    return () => {
      websocket.removeListener(
        websocket.EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.VERSION.UPLOAD
          .SUCCEEDED,
        handleFileVersionUploadedToGoogleDrive
      );
      websocket.removeListener(
        websocket.EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.VERSION.UPLOAD.FAILED,
        handleFileVersionUploadToGoogleDriveFailed
      );
    };
  }, [projectId]);

  useEffect(() => {
    async function loadGoogleDriveProjectConnection() {
      const { teamId } = await projectService.fetchProjectById(projectId);
      setProjectFolderConnection(null);

      try {
        const googleDriveProjectFolderConnection =
          await googleDriveService.getProjectFolderConnection(
            teamId,
            projectId
          );

        setProjectFolderConnection(googleDriveProjectFolderConnection);

        // eslint-disable-next-line no-empty
      } catch {}
    }

    loadGoogleDriveProjectConnection();

    eventService.addListener(
      EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.PROJECT_CONNECTED,
      loadGoogleDriveProjectConnection
    );
    eventService.addListener(
      EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.PROJECT_DISCONNECTED,
      loadGoogleDriveProjectConnection
    );

    return () => {
      eventService.removeListener(
        EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.PROJECT_CONNECTED,
        loadGoogleDriveProjectConnection
      );
      eventService.removeListener(
        EVENT_NAMES.INTEGRATIONS.GOOGLE_DRIVE.PROJECT_DISCONNECTED,
        loadGoogleDriveProjectConnection
      );
    };
  }, [projectId]);

  return (
    <IconButton
      data-testid="google-drive-settings-button"
      onClick={handleButtonClick}
      size="small"
    >
      <SvgIcon
        component={
          projectFolderConnection ? GoogleDriveLogo : GoogleDriveDisabledLogo
        }
        inheritViewBox
        fontSize="small"
      />
    </IconButton>
  );
}

GoogleDriveSettingsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default GoogleDriveSettingsButton;
