import PropTypes from "prop-types";
import { useState } from "react";

import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { FormControl, FormHelperText, Input, InputLabel } from "@mui/material";
import { useTheme, makeStyles } from "@mui/styles";

import { Text, Box } from "@shared/UIKit";

import { EMAIL_REGEX } from "@shared/constants/validation";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles({
  form: {
    width: "100%",
  },
  helperText: {
    "&.MuiFormHelperText-root": {
      position: "absolute",
      top: "100%",
    },
  },
});

function EmailInput({ onChange, inputState, label }) {
  const classes = useStyles();
  const theme = useTheme();

  const [formErrorMessage, setFormErrorMessage] = useState("");

  const handleEmailChange = (event) => {
    const value = event.target.value;
    const inputState = {
      email: value,
      isEmailValid: EMAIL_REGEX.test(value),
    };
    onChange(inputState);

    if (!inputState.isEmailValid) {
      setFormErrorMessage("Not a valid email");
      return;
    }

    setFormErrorMessage("");
  };

  return (
    <Box
      display="flex"
      mt={2}
      maxWidth={400}
      alignItems="center"
      data-testid="form"
    >
      <Box mt={2.5} ml={0.5} mr={2} lineHeight={FSTGTypography.lineHeight_1_0}>
        <EmailOutlined />
      </Box>
      <FormControl classes={{ root: classes.form }}>
        <InputLabel htmlFor="email">{label}</InputLabel>
        <Input
          id="email"
          value={inputState.email}
          onChange={handleEmailChange}
          data-testid="email-form-input"
        />

        <FormHelperText className={classes.helperText}>
          {formErrorMessage && (
            <Text color={theme.color.red[500]}>{formErrorMessage}</Text>
          )}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}

EmailInput.propTypes = {
  inputState: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};
export default EmailInput;
