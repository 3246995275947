import PropTypes from "prop-types";
import { useState, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";

import DoneIcon from "@mui/icons-material/Done";
import { Button, TextField, Link } from "@mui/material";
import noop from "lodash/noop";

import { Box, Dialog, Text, HintBox } from "@shared/UIKit";

import { integrationProps } from "@shared/props/integration";
import { instance as analytics } from "@shared/services/analytics";

function RegisterInterest({
  integration,
  closeDialog,
  onClickRegisterInterest,
}) {
  const { t } = useTranslation();

  const [isRegisteredInterest, setIsRegisteredInterest] = useState(false);
  const [message, setMessage] = useState("");

  const registerInterest = useCallback(() => {
    onClickRegisterInterest();
    setIsRegisteredInterest(true);
    analytics.track(
      analytics.ACTION.CLICKED,
      analytics.CATEGORY.APP_MARKETPLACE.REGISTER_INTEREST,
      {
        appTitle: integration.title,
        message,
      }
    );
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  return (
    <Dialog
      useDefaultBackground={false}
      content={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
        >
          {isRegisteredInterest && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              bgcolor="success.light"
              width={40}
              height={40}
              borderRadius={5}
            >
              <DoneIcon color="success.main" />
            </Box>
          )}

          <Text
            textAlign="center"
            variant="textXl"
            fontWeight="fontWeight.bold"
          >
            {!isRegisteredInterest
              ? t("APPS.DIALOG.INTERESTED_MODAL.TITLE", {
                  integrationName: integration.title,
                })
              : t("APPS.DIALOG.INTERESTED_SUCCESS_MODAL.TITLE")}
          </Text>
          <Text variant="textSm" color="text.secondary">
            {!isRegisteredInterest
              ? t("APPS.DIALOG.INPUT_TITLE")
              : t("APPS.DIALOG.INTERESTED_SUCCESS_MODAL.DESCRIPTION")}
          </Text>

          {!isRegisteredInterest && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              gap={2}
              width="80%"
            >
              <TextField
                fullWidth
                id="register-interest-message"
                variant="outlined"
                multiline
                maxRows={4}
                rows={4}
                label={t("APPS.DIALOG.INPUT_PLACEHOLDER")}
                onChange={handleMessageChange}
              />

              <Button
                variant="contained"
                color="primaryV2"
                size="large"
                onClick={registerInterest}
                data-testid="register-interest-button"
              >
                {t("APPS.DIALOG.INTERESTED_MODAL.BUTTON")}
              </Button>
              <HintBox
                borderRadius={1}
                border="0px"
                textAlign="left"
                paddingRight={1}
                gap={0}
              >
                <Trans
                  components={{
                    anchor: (
                      <Link
                        fontSize="inherit"
                        target="_blank"
                        color="accent"
                        href={t("APPS.DIALOG.HINT.ZAPIER_LINK")}
                        sx={{ ml: 0.5, mr: 0.5 }}
                      />
                    ),
                  }}
                >
                  {t("APPS.DIALOG.INTERESTED_SUCCESS_MODAL.HINT")}
                </Trans>
              </HintBox>
            </Box>
          )}
        </Box>
      }
      isOpen
      maxWidth="sm"
      cancel={closeDialog}
      data-testid="register-interest-dialog"
      hideBackdrop={false}
      disableBackdropClick
    />
  );
}

RegisterInterest.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onClickRegisterInterest: PropTypes.func,
  integration: integrationProps.isRequired,
};

RegisterInterest.defaultProps = {
  onClickRegisterInterest: noop,
};

export default RegisterInterest;
