/* eslint react/forbid-elements:0 */
import PropTypes from "prop-types";
import { ReactElement } from "react";

import { styled } from "@mui/system";

const StyledImage = styled("img")({});

/**
 * UIKit Image Component
 * @description Renders an the image with the optional of lazy loading.
 * It uses `<img>` tag by default.
 * @param {object} props Component props
 * @param {string} props.src [Required] The path or data URI of the image.
 * @param {string} props.alt [Required] The alt text of the image.
 * @param {string} [props.srcSet] [Optional] The srcset attribute of the image. This is strongly recommended to use for the retina displays.
 * @param {boolean} [props.enableLazyLoad] [Optional] If true then it will use the <Lazyload> component.
 * recommended to use for the retina displays.
 * @param {string} [props.className] [Optional] Additional class name for styling
 * @returns {ReactElement} React component
 */
function Image({ enableLazyLoad, src, srcSet, alt, className, ...restProps }) {
  return (
    <StyledImage
      src={src}
      srcSet={srcSet}
      alt={alt}
      className={className}
      loading={enableLazyLoad ? "lazy" : "eager"}
      {...restProps}
    />
  );
}

Image.propTypes = {
  enableLazyLoad: PropTypes.bool,
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  srcSet: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
  enableLazyLoad: false,
  srcSet: null,
  className: "",
};

export default Image;
