import { useEffect } from "react";
import { useSearchParams, useLocation } from "react-router-dom";

import useSelectedTeam from "@supporting/hooks/useSelectedTeam";

import ROUTE_STATES from "@shared/constants/routeStates";
import { useNavigation } from "@shared/hooks";
import backend from "@shared/services/backendClient";

export default function MondayAppAuthorizationStart() {
  const { goTo } = useNavigation();
  const selectedTeam = useSelectedTeam();

  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const stateParams = Object.fromEntries(searchParams.entries());

    const redirectUser = async () => {
      try {
        const redirectUrl = await backend.get("/integrations/monday-app/auth", {
          ...stateParams,
          ...location.state,
          teamId: selectedTeam._id,
        });

        window.location.replace(redirectUrl);
      } catch (error) {
        goTo(ROUTE_STATES.INTEGRATIONS_AUTHORIZATION_FAILED, {
          params: { appId: "monday-app" },
        });
      }
    };

    if (selectedTeam) {
      redirectUser();
    }
  }, [searchParams, selectedTeam, location, goTo]);

  return null;
}
