import authenticationService from "@supporting/services/authentication";
import { pendingReviewsData } from "@workflow/data/reviews";

import backendClient from "@shared/services/backendClient";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";
import { instance as websocket } from "@shared/services/websocket";

function fetchPendingReviewsCount() {
  return backendClient.get("/pending-reviews/count");
}

function fetchPendingReviews(sort, skip, limit) {
  return backendClient.get("/pending-reviews", {
    sort,
    skip,
    limit,
  });
}

function updatePendingReviews() {
  eventService.emitEvent({
    eventName: EVENT_NAMES.PENDING_REVIEWS.UPDATED,
    eventData: {},
  });
  pendingReviewsData.invalidatePendingReviewsCount();
  pendingReviewsData.invalidatePendingReviews();
}

function updatePendingReviewsIfNecessary({ reviewerId, reviewerIds }) {
  const reviewers = reviewerIds ? reviewerIds : [reviewerId];
  const currentUserId = authenticationService.fetchSession()?.userId;
  const isCurrentUserAffected = reviewers.indexOf(currentUserId) !== -1;

  if (isCurrentUserAffected) {
    updatePendingReviews();
  }
}

function initialize() {
  websocket.addListener(
    websocket.EVENT_NAMES.REVIEW.UPDATED,
    updatePendingReviews
  );
  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.ACCESS.GAINED,
    updatePendingReviews
  );
  websocket.addListener(
    websocket.EVENT_NAMES.PROJECT.UPDATED,
    updatePendingReviews
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.ADDED,
    updatePendingReviewsIfNecessary
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.REMOVED,
    updatePendingReviewsIfNecessary
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.REVIEWER.DECISION_REQUESTED,
    updatePendingReviewsIfNecessary
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.ACCESS.GAINED,
    updatePendingReviews
  );
  websocket.addListener(
    websocket.EVENT_NAMES.STEP.ACCESS.LOST,
    updatePendingReviews
  );

  eventService.addListenerOnce(
    EVENT_NAMES.ISSUE.CREATING_OR_EDITING.FINISHED,
    updatePendingReviews
  );
  eventService.addListener(EVENT_NAMES.FILE.REMOVED, updatePendingReviews);
  eventService.addListener(EVENT_NAMES.VERSION.ADDED, updatePendingReviews);
  eventService.addListener(EVENT_NAMES.VERSION.REMOVED, updatePendingReviews);
  eventService.addListener(EVENT_NAMES.REVIEW.REMOVED, updatePendingReviews);
  eventService.addListener(EVENT_NAMES.REVIEW.CREATED, updatePendingReviews);
  eventService.addListener(
    EVENT_NAMES.REVIEW.DECISIONS.SUBMITTED,
    updatePendingReviews
  );
  eventService.addListener(
    EVENT_NAMES.REVIEW.DECISIONS.UNDO,
    updatePendingReviews
  );
}

export default {
  initialize,
  fetchPendingReviewsCount,
  fetchPendingReviews,
};
