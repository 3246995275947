import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, TextField } from "@mui/material";
import authenticationService from "@supporting/services/authentication";

import { Box, Dialog, Text } from "@shared/UIKit";

const DigitalSignatureDialog = ({
  isOpen,
  email,
  errorText,
  setErrorText,
  cancel,
  submitVerifiedApproval,
}) => {
  const { t } = useTranslation();

  const [code, setCode] = useState("");
  const [validationErrorText, setValidationErrorText] = useState("");

  const handleOtpChange = (event) => {
    const { value } = event.target;
    const regex = new RegExp(/^[0-9]*$/);

    if (regex.test(value)) {
      setValidationErrorText("");
      setErrorText("");
      setCode(value);
    }
  };

  const isDisabled = code.length !== 6;
  const hasError = validationErrorText.length || errorText.length;

  const handleOnBlur = () => {
    if (isDisabled && !validationErrorText.length) {
      setValidationErrorText(t("AUTHENTICATION.DIALOGS.OTP.INCOMPLETE_CODE"));
    }
  };

  useEffect(() => {
    if (isOpen && email) {
      setCode("");
      setErrorText("");
      setValidationErrorText("");
      authenticationService.sendOTP(email, true);
    }
  }, [isOpen, email, setErrorText]);

  const content = (
    <>
      <Box px={5} py={1} mb={4} data-testid="signature-dialog">
        <Text color="text.secondary" variant="body2">
          {t("VERIFIED_APPROVAL.SIGNED_IN_MODAL_BODY", { email })}
        </Text>
      </Box>
      <Box p={1} mb={1} data-testid="otp-input-container">
        <TextField
          id="otp-input"
          onChange={handleOtpChange}
          onBlur={handleOnBlur}
          value={code}
          error={Boolean(hasError)}
          helperText={
            hasError
              ? errorText || validationErrorText
              : t("VERIFIED_APPROVAL_MODAL_HELPER.TEXT")
          }
          label="Enter code"
          inputProps={{
            "data-testid": "otp-input",
            inputMode: "numeric",
          }}
          fullWidth
          autoFocus
        />
      </Box>
    </>
  );

  const actions = (
    <Button
      onClick={() => submitVerifiedApproval(code)}
      color="primary"
      variant="contained"
      size="large"
      disabled={isDisabled}
      data-testid="otp-dialog-confirm"
    >
      {t("VERIFIED_APPROVAL.CTA_PRIMARY")}
    </Button>
  );

  return (
    <Dialog
      hideBackdrop={false}
      cancel={cancel}
      open={isOpen}
      maxWidth="xs"
      content={content}
      actions={actions}
      title={t("VERIFIED_APPROVAL.SIGNED_IN_MODAL_TITLE")}
    />
  );
};

DigitalSignatureDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  errorText: PropTypes.string.isRequired,
  setErrorText: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  submitVerifiedApproval: PropTypes.func.isRequired,
};

export default DigitalSignatureDialog;
