import PropTypes from "prop-types";
import { memo } from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";

import { Text, Image, Box } from "@shared/UIKit";

import CelebrateImage from "@assets/img/images/celebrate_complete_unread_notifications.png";
import CelebrateImage2x from "@assets/img/images/celebrate_complete_unread_notifications@2x.png";
import EmptyStateImage from "@assets/img/images/emptystate-notification-center.png";

const useStyles = makeStyles((theme) => ({
  ArrowIcon: {
    marginLeft: 105,
  },
  Title: {
    marginBottom: 12,
    marginTop: 20,
  },
  Description: {
    padding: "0 12%",

    "& strong": {
      color: theme.color["black-translucent-85"],
    },
  },
}));

function NotificationCenterEmptyState({ type }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      {type === "UNREAD_NOTIFICATIONS_EMPTY_STATE" && (
        <Box position="absolute" top={80}>
          <Image
            src={EmptyStateImage}
            className={classes.ArrowIcon}
            alt="Arrow Icon"
          />
        </Box>
      )}

      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
        alignItems="center"
        height="100%"
      >
        <Image
          src={CelebrateImage}
          srcSet={`${CelebrateImage} 1x, ${CelebrateImage2x} 2x`}
          alt={t(`NOTIFICATION_CENTER.${type}.TITLE`)}
        />
        <Text
          component="p"
          variant="h6"
          color={theme.color["black-translucent-85"]}
          className={classes.Title}
        >
          {t(`NOTIFICATION_CENTER.${type}.TITLE`)}
        </Text>
        <Text
          variant="subtitle2"
          color={theme.color.gray[500]}
          component="p"
          className={classes.Description}
        >
          {t(`NOTIFICATION_CENTER.${type}.DESCRIPTION`)}
        </Text>
      </Box>
    </>
  );
}

NotificationCenterEmptyState.propTypes = {
  type: PropTypes.oneOf([
    "UNREAD_NOTIFICATIONS_EMPTY_STATE",
    "ALL_NOTIFICATIONS_EMPTY_STATE",
  ]).isRequired,
};

export default memo(NotificationCenterEmptyState);
