import PropTypes from "prop-types";
import { useCallback, useState, ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { InfoOutlined } from "@mui/icons-material";
import { Button, Checkbox, FormControlLabel } from "@mui/material";

import { Box, Dialog, Text, HintBox } from "@shared/UIKit";

/**
 * UIKit Confirmation Dialog Component
 * @description An extension of UIKit Dialog component for confirmation dialogs.
 * It is a flexible component that extends all the props of the UIKit dialog
 * and creates a standardized confirmation dialog.
 * @param {object} props - Component props
 * @param {string|ReactElement} [props.title] [Required] - Title of the dialog.
 * @param {string} [props.description] [Optional] - Description of the dialog.
 * @param {boolean} [props.isOpen] [Required] - If true, the dialog will be displayed.
 * @param {Function} [props.answer] [Required] - Confirmation action callback function.
 * @param {Function} [props.closeDialog] [Required] - Close dialog action callback function.
 * @param {string} [props.cancelLabel] [Optional] - Label of the cancel action button.
 * @param {string} [props.confirmLabel] [Optional] - Label of the confirm action button.
 * @param {string} [props.checkMessage] [Optional] - Checkbox label/message that restricts to confirm.
 * @param {string} [props.checkHint] [Optional] - Checkbox hint message that restricts to confirm.
 * @param {string} [props.maxWidth] [Optional] - Max width of the dialog that extends from MUI Dialog. Defaults to `xs`.
 * @param {string} [props.infoMessage] [Optional] - Information message that will be displayed in the dialog.
 * @returns {ReactElement} - React component
 */
function ConfirmationDialog({
  isOpen,
  title,
  description,
  infoMessage,
  checkMessage,
  checkHint,
  cancelLabel,
  confirmLabel,
  answer,
  closeDialog,
  maxWidth,
  ...restProps
}) {
  const { t } = useTranslation();

  const confirmDialog = useCallback(() => {
    answer();
    closeDialog();
  }, [answer, closeDialog]);

  const [isConfirmed, setIsConfirmed] = useState(false);

  const changeConfirm = useCallback(() => {
    setIsConfirmed((previousIsConfirmed) => !previousIsConfirmed);
  }, []);

  const content = (
    <>
      <Box mb={2.5}>
        {description && (
          <Text variant="body2" color="text.secondary">
            {description}
          </Text>
        )}
      </Box>
      {infoMessage && (
        <Box
          display="flex"
          flexDirection="row"
          p={0.8}
          borderRadius={1}
          bgcolor="grey.100"
          gap={1}
          data-testid="confirmation-dialog-infobox"
        >
          <InfoOutlined color="grey.600" fontSize="small" />
          <Text
            color="text.secondary"
            variant="textSm"
            data-testid="info-box-text"
          >
            {infoMessage}
          </Text>
        </Box>
      )}
      {checkMessage && (
        <Box p={1} borderRadius={1} textAlign="left">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isConfirmed}
                onChange={changeConfirm}
                data-testid="confirmation-dialog-checkbox"
              />
            }
            label={checkMessage}
          />
        </Box>
      )}

      {checkHint && (
        <HintBox
          textAlign="left"
          mt={2.5}
          data-testid="confirmation-dialog-hintbox"
        >
          {checkHint}
        </HintBox>
      )}
    </>
  );

  const actions = (
    <>
      <Button
        onClick={closeDialog}
        data-testid="confirmation-dialog-cancel"
        variant="outlined"
        color="primaryV2"
      >
        {cancelLabel || t("CORE.CANCEL")}
      </Button>
      <Button
        disabled={checkMessage && !isConfirmed}
        onClick={confirmDialog}
        variant="contained"
        color="error"
        data-testid="confirmation-dialog-confirm"
      >
        {confirmLabel || t("CORE.CONFIRM")}
      </Button>
    </>
  );

  return (
    <Dialog
      cancel={closeDialog}
      open={isOpen}
      maxWidth={maxWidth}
      title={title}
      content={content}
      actions={actions}
      useDefaultBackground={false}
      hideBackdrop={false}
      titleProps={{ textAlign: "center", fontWeight: "fontWeight.semiBold" }}
      {...restProps}
    />
  );
}

ConfirmationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.node,
  infoMessage: PropTypes.node,
  checkMessage: PropTypes.string,
  checkHint: PropTypes.string,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  answer: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
};

ConfirmationDialog.defaultProps = {
  infoMessage: null,
  checkMessage: null,
  checkHint: null,
  cancelLabel: null,
  confirmLabel: null,
  maxWidth: "sm",
  description: "",
};

export default ConfirmationDialog;
