import PropTypes from "prop-types";

import { MoreVert } from "@mui/icons-material";
import { IconButton } from "@mui/material";

function ReviewCardMenuButton({ openMenu }) {
  return (
    <IconButton
      aria-controls="review-card-menu"
      aria-haspopup="true"
      data-testid="review-card-menu-open-icon"
      onClick={openMenu}
      size="small"
      edge="end"
    >
      <MoreVert fontSize="small" />
    </IconButton>
  );
}

ReviewCardMenuButton.propTypes = {
  openMenu: PropTypes.func.isRequired,
};

export default ReviewCardMenuButton;
