import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { Text } from "@shared/UIKit";

function DisabledCell({ children, ...restProps }) {
  return (
    <Box
      bgcolor="grey.100"
      width={200}
      minWidth={200}
      height={50}
      borderRight="1px solid var(--mui-palette-grey-100)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      data-testid="not-shared-version-reviews-cell"
      {...restProps}
    >
      <Text variant="textSm" color="grey.400">
        {children}
      </Text>
    </Box>
  );
}

DisabledCell.propTypes = {
  children: PropTypes.string.isRequired,
};

export default DisabledCell;
