/* eslint react/forbid-component-props:0 */
import { useTranslation } from "react-i18next";

import { makeStyles, useTheme } from "@mui/styles";

import { MimeTypeIcon, Box, Text } from "@shared/UIKit";

import { pendingReviewFileProp } from "@shared/props/pendingReview";
import FSTGTypography from "@shared/theme/typography";

const useStyles = makeStyles((theme) => ({
  thumbnailWrapper: {
    minHeight: 72,
    width: 100,
    minWidth: 100,
    backgroundColor: theme.color.gray[50],
  },
  thumbnail: {
    minWidth: 100,
    width: 100,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    transition:
      "filter 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s cubic-bezier(0.23, 1, 0.32, 1)",
  },
  thumbnailFallback: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 100,
    height: "100%",
    minWidth: 0,
    transition:
      "filter 0.2s cubic-bezier(0.23, 1, 0.32, 1), transform 0.2s cubic-bezier(0.23, 1, 0.32, 1)",
  },
  thumbnailIcon: {
    color: theme.color.white,
    width: 26,
    height: 26,
  },
}));

export default function PendingReviewsThumbnail({ file }) {
  const { version } = file;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      className={classes.thumbnailWrapper}
    >
      <Box
        overflow="hidden"
        bgcolor={theme.color.gray[200]}
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
      >
        {version?.thumbnail?.url ? (
          <Box
            className={classes.thumbnail}
            style={{ backgroundImage: `url(${version.thumbnail.url})` }}
            data-testid="pending-review-thumbnail"
          />
        ) : (
          <Box className={classes.thumbnailFallback}>
            <MimeTypeIcon
              className={classes.thumbnailIcon}
              version={version}
              data-testid="pending-review-fallback-thumbnail"
            />
          </Box>
        )}
      </Box>

      <Box alignItems="center" justifyContent="center" className="overlay">
        <Text
          color={theme.color.white}
          fontSize={FSTGTypography.fontSize_1_5}
          fontWeight={theme.typography.fontWeight.bold}
          textAlign="center"
        >
          {t("FILE_REVIEW.OPEN_REVIEW")}
        </Text>
      </Box>
    </Box>
  );
}

PendingReviewsThumbnail.propTypes = {
  file: pendingReviewFileProp.isRequired,
};
