import { useTranslation } from "react-i18next";

import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";

import { Box, Image, Text } from "@shared/UIKit";
import { useWizardDialog } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import ArrowIcon from "@assets/img/images/swimlane-upload-file.png";

const ProjectAutomationsListEmptyState = () => {
  const { t } = useTranslation();
  const { state } = useWizardDialog();

  const canManageAutomations = state.canManageAutomations;

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-testid="automations-list-empty-state"
      marginTop={canManageAutomations ? 0 : -12}
    >
      {canManageAutomations && (
        <Box
          position="relative"
          marginLeft="65%"
          minHeight={148}
          marginTop={-23.75}
          marginBottom={-10.25}
        >
          <Image
            width={135}
            alt={t("AUTOMATIONS.DIALOG.CREATE-AUTOMATION")}
            src={ArrowIcon}
            sx={{
              transform: "rotate(52deg)",
              pointerEvents: "none",
              userSelect: "none",
            }}
          />
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          width={155}
          height={155}
          bgcolor="grey.100"
          justifyContent="center"
          alignItems="center"
          marginBottom={3.5}
          borderRadius="50%"
        >
          <Box
            width={150}
            height={35}
            bgcolor="white"
            borderRadius="4px"
            display="flex"
            marginLeft={10.625}
            paddingLeft={0.4}
            alignItems="center"
            flexDirection="row"
            boxShadow={2}
          >
            <OfflineBoltIcon width={30} height={30} color="primaryV2" />

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginLeft={1}
            >
              <Box
                width={32}
                height={6}
                borderRadius="4px"
                bgcolor="grey.300"
              />
              <Box
                width={52}
                height={6}
                borderRadius="4px"
                marginTop={0.75}
                bgcolor="grey.200"
              />
            </Box>
          </Box>

          <Box
            width={150}
            height={35}
            bgcolor="white"
            borderRadius="4px"
            marginTop={1.25}
            marginLeft={5.75}
            paddingLeft={0.4}
            display="flex"
            alignItems="center"
            flexDirection="row"
            boxShadow={2}
          >
            <OfflineBoltIcon width={30} height={30} color="primaryV2" />

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginLeft={1}
            >
              <Box
                width={32}
                height={6}
                borderRadius="4px"
                bgcolor="grey.300"
              />
              <Box
                width={52}
                height={6}
                borderRadius="4px"
                marginTop={0.75}
                bgcolor="grey.200"
              />
            </Box>
          </Box>
        </Box>

        <Text
          variant="textXl"
          data-testid={`automation-empty-title${
            canManageAutomations ? "" : "-2"
          }`}
        >
          {t(
            `AUTOMATIONS.DIALOG.EMPTY-STATE.TITLE${
              canManageAutomations ? "" : "-2"
            }`
          )}
        </Text>
        {!canManageAutomations && (
          <Text variant="textMd" color="text.secondary">
            {t("AUTOMATIONS.DIALOG.EMPTY-STATE.DESCRIPTION-2")}
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default ProjectAutomationsListEmptyState;
