import { useTranslation, Trans } from "react-i18next";

import { Button, Link } from "@mui/material";
import UpgradePlanDialog from "@supporting/components/UpgradePlanDialog/UpgradePlanDialog";

import { Text, Image, Box } from "@shared/UIKit";

import ReviewLiveWebsiteImage from "@assets/img/images/review_live_website.gif";

function UpsellReviewLiveWebsitesDialog(props) {
  const { t } = useTranslation();

  return (
    <UpgradePlanDialog
      dialogTitle="REVIEW_LIVE_WEBSITES.UPGRADE_CTA.TITLE"
      {...props}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        px={2}
        gap={2}
      >
        <Text variant="body2">
          <Trans
            components={{
              anchor: (
                <Link
                  color="accent"
                  className="launch-intercom-chat"
                  href={t("URLS.HELLO_MAIL")}
                  target="_blank"
                />
              ),
            }}
          >
            {t("REVIEW_LIVE_WEBSITES.UPGRADE_CTA.DESCRIPTION")}
          </Trans>
        </Text>
        <Image
          src={ReviewLiveWebsiteImage}
          alt="review-live-websites-thumbnail"
          width="100%"
        />
        <Button
          data-testid="cta-upgrade-button"
          color="upgrade"
          variant="contained"
          className="launch-intercom-chat"
          href={t("URLS.HELLO_MAIL")}
          target="_blank"
        >
          {t("REVIEW_LIVE_WEBSITES.UPGRADE_CTA.REACH_OUT")}
        </Button>
        <Box mt={2}>
          <Text variant="body2">
            {t("REVIEW_LIVE_WEBSITES.UPGRADE_CTA.DISCUSS_PLAN")}
          </Text>
        </Box>
      </Box>
    </UpgradePlanDialog>
  );
}

export default UpsellReviewLiveWebsitesDialog;
