import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, TextField, InputAdornment } from "@mui/material";

import { Dialog, Text } from "@shared/UIKit";

import { EMAIL_REGEX } from "@shared/constants/validation";

import VerifyDomainEmailInput from "./VerifyDomainEmailInput";

function VerifyTeamDomainDialog({
  cancel,
  isOpen,
  domainName,
  defaultRole,
  answer,
}) {
  const { t } = useTranslation();

  const [recipientEmail, setRecipientEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirmDialog = useCallback(() => {
    setIsSubmitting(true);
    answer(domainName, defaultRole, recipientEmail);
    cancel();
  }, [domainName, defaultRole, recipientEmail, answer, cancel]);

  const isEmailValid = useMemo(() => {
    return EMAIL_REGEX.test(recipientEmail);
  }, [recipientEmail]);

  const handleInputChange = (event) => {
    setErrorText("");
    setRecipientEmail(event.target.value);
  };

  const handleOnBlur = () => {
    if (!isEmailValid) {
      setErrorText("FORM.EMAIL.MESSAGE_FORMAT");
    }
  };

  const isDisabled = !isEmailValid || isSubmitting;

  return (
    <Dialog
      title={t("JOIN_WITH_SAME_DOMAIN.VERIFY.MODAL.TITLE")}
      isOpen={isOpen}
      actions={
        <Button
          disabled={isDisabled}
          onClick={confirmDialog}
          color="primary"
          variant="contained"
          size="large"
        >
          {t("TEAM_LINK.SEND_VERIFICATION.CTA")}
        </Button>
      }
      cancel={cancel}
      hideBackdrop={false}
      maxWidth="xs"
      disableBackdropClick
    >
      <Text
        variant="body2"
        translate="JOIN_WITH_SAME_DOMAIN.VERIFY.MODAL.BODY"
      />
      <TextField
        label={t("FORM.EMAIL.LABEL")}
        placeholder={t("TEAM_LINK.EMAIL_PLACEHOLDER.TEXT")}
        type="text"
        id="email"
        value={recipientEmail}
        inputProps={{
          "data-testid": "verify-team-domain-dialog-input",
          domainName,
        }}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        required
        autoFocus
        fullWidth
        InputLabelProps={{ required: false, shrink: true }}
        helperText={t(errorText)}
        error={Boolean(errorText.length)}
        InputProps={{
          inputComponent: VerifyDomainEmailInput,
          endAdornment: !recipientEmail.length && (
            <InputAdornment position="end">{`@${domainName}`}</InputAdornment>
          ),
        }}
      />
    </Dialog>
  );
}

VerifyTeamDomainDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  defaultRole: PropTypes.string,
  domainName: PropTypes.string.isRequired,
  answer: PropTypes.func.isRequired,
};

VerifyTeamDomainDialog.defaultProps = {
  defaultRole: "",
};

export default VerifyTeamDomainDialog;
