import PropTypes from "prop-types";

import { Box } from "@mui/material";

import { Dialog } from "@shared/UIKit";

function YoutubeDialog({ cancel, isOpen, video }) {
  return (
    <Dialog
      isOpen={isOpen}
      hideBackdrop={false}
      maxWidth="lg"
      fullWidth
      cancel={cancel}
      useDefaultBackground={false}
    >
      <Box position="relative">
        <iframe
          width="100%"
          height="600"
          src={video}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Box>
    </Dialog>
  );
}

YoutubeDialog.propTypes = {
  video: PropTypes.string.isRequired,
  cancel: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default YoutubeDialog;
