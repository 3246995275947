import PropTypes from "prop-types";

import { ClickAwayListener, Fade, Paper, Popper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Collaborator from "@workflow/components/Collaborators/Collaborator/Collaborator";

import { Box } from "@shared/UIKit";

import { projectProps } from "@shared/props/project";

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 79,
  },
  paper: {
    marginTop: 5,
  },
  avatar: {
    marginLeft: "-6px !important",
    height: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& > div": {
      width: 32,
      height: 32,
    },
  },
}));

function ExtraCollaboratorsPopper({
  anchorEl,
  onClosePopper,
  skipCollaborators,
  project,
}) {
  const classes = useStyles();
  const isOpen = Boolean(anchorEl);
  return (
    <Popper
      className={classes.popper}
      anchorEl={anchorEl}
      open={isOpen}
      transition
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box>
            <ClickAwayListener
              touchEvent="onTouchStart"
              onClickAway={onClosePopper}
            >
              <Paper className={classes.paper} elevation={4}>
                <Box
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                  alignItems="center"
                  alignContent="center"
                  justifyContent="flex-start"
                  pt={1.625}
                  pb={1.625}
                  pl={2}
                  pr={2}
                  maxWidth={330}
                  ml={0.625}
                  data-testid="collaborators-extra-avatars"
                >
                  {project.collaborators
                    .slice(skipCollaborators)
                    .map((collaborator, index) => (
                      <Collaborator
                        key={collaborator._id}
                        collaborator={collaborator}
                        avatarClassName={classes.avatar}
                        project={project}
                        index={index}
                      />
                    ))}
                </Box>
              </Paper>
            </ClickAwayListener>
          </Box>
        </Fade>
      )}
    </Popper>
  );
}

ExtraCollaboratorsPopper.propTypes = {
  anchorEl: PropTypes.any,
  onClosePopper: PropTypes.func.isRequired,
  project: PropTypes.shape(projectProps).isRequired,
  skipCollaborators: PropTypes.number.isRequired,
};

ExtraCollaboratorsPopper.defaultProps = {
  anchorEl: null,
};

export default ExtraCollaboratorsPopper;
