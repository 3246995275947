import PropTypes from "prop-types";
import { useEffect } from "react";

import { DIALOG_STATE } from "@workflow/constants/automation";
import useAutomationsState from "@workflow/hooks/useAutomationsState";
import projectService from "@workflow/services/projectService";

import { Box } from "@shared/UIKit";
import { useWizardDialog } from "@shared/UIKit/WizardDialog/wizardDialogContext";

import { projectProps } from "@shared/props/project";
import { stepProps } from "@shared/props/step";
import { instance as analytics } from "@shared/services/analytics";

import AutomationsUpgradeMessage from "../AutomationsUpgradeMessage";
import AutomationCard from "./AutomationCard";
import ProjectAutomationsListEmptyState from "./ProjectAutomationsListEmptyState";

function ProjectAutomationsList({ project, steps }) {
  const [automationsState, visibleAutomations] = useAutomationsState(
    project,
    steps
  );

  const { setDialogState, state } = useWizardDialog();

  useEffect(() => {
    analytics.track(
      analytics.ACTION.OPENED,
      analytics.CATEGORY.AUTOMATION_OVERVIEW
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const fetchTriggers = async () => {
      const triggers = await projectService.fetchTriggers(project.teamId);
      setDialogState((prevState) => ({
        ...prevState,
        triggers,
      }));
    };

    if (!state.triggers) {
      fetchTriggers();
    }
  }, [project.teamId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box
      display="flex"
      flexDirection="column"
      data-testid="project-automations-list"
    >
      {automationsState !== DIALOG_STATE.HAS_LIMIT && (
        <AutomationsUpgradeMessage
          automationsState={automationsState}
          teamId={project.teamId}
        />
      )}

      <Box
        height={510}
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {visibleAutomations.length > 0 ? (
          <Box overflow="auto">
            {visibleAutomations.map((automation) => (
              <AutomationCard
                key={automation.automationId}
                automation={automation}
                projectId={project.id}
                steps={steps}
                sections={project.sections}
              />
            ))}
          </Box>
        ) : (
          <ProjectAutomationsListEmptyState />
        )}
      </Box>
    </Box>
  );
}

ProjectAutomationsList.propTypes = {
  project: PropTypes.shape(projectProps).isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
};

export default ProjectAutomationsList;
