import PropTypes from "prop-types";
import { Trans, useTranslation } from "react-i18next";

import HelpIcon from "@mui/icons-material/Help";
import {
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";

import { Text, Tooltip } from "@shared/UIKit";

const ReviewerGroupEmailNotificationsTab = ({ settings, onChange }) => {
  const { t } = useTranslation();

  return (
    <TableContainer data-testid="email-notifications-tab">
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ width: "40%" }}>
              <Box display="flex" alignItems="center" gap={1}>
                <Text fontWeight="fontWeight.semiBold">
                  {t("REVIEWER_SETTINGS.EMAIL_PROJECT_UPDATES")}
                </Text>
                <Tooltip
                  title={
                    <Trans
                      i18nKey="REVIEWER_SETTINGS.EMAIL_PROJECT_UPDATES_TOOLTIP"
                      components={{
                        1: <ul />,
                        2: <li />,
                      }}
                    />
                  }
                >
                  <HelpIcon fontSize="small" sx={{ color: "grey.300" }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell align="center">
              <Switch
                checked={settings["emailNotifications"]}
                onChange={({ currentTarget }) => {
                  onChange({
                    field: "emailNotifications",
                    value: currentTarget.checked,
                  });
                }}
                inputProps={{
                  "data-testid": "reviewer-settings-emailNotifications-switch",
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderBottom: 0, width: "40%" }}>
              <Box display="flex" alignItems="center" gap={1}>
                <Text fontWeight="fontWeight.semiBold">
                  {t("REVIEWER_SETTINGS.EMAIL_REVIEW_STARTED")}
                </Text>
                <Tooltip
                  title={t("REVIEWER_SETTINGS.EMAIL_REVIEW_STARTED_TOOLTIP")}
                >
                  <HelpIcon fontSize="small" sx={{ color: "grey.300" }} />
                </Tooltip>
              </Box>
            </TableCell>
            <TableCell align="center" sx={{ borderBottom: 0, pl: 2 }}>
              <Switch
                checked={settings["reviewStartedEmailNotification"]}
                onChange={({ currentTarget }) => {
                  onChange({
                    field: "reviewStartedEmailNotification",
                    value: currentTarget.checked,
                  });
                }}
                inputProps={{
                  "data-testid":
                    "reviewer-settings-reviewStartedEmailNotification-switch",
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ReviewerGroupEmailNotificationsTab.propTypes = {
  settings: PropTypes.shape({
    reviewStartedEmailNotification: PropTypes.bool.isRequired,
    emailNotifications: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReviewerGroupEmailNotificationsTab;
