import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button } from "@mui/material";
import noop from "lodash/noop";

function UploadSequenceButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <Button
      variant="contained"
      color="success"
      onClick={onClick}
      data-testid="upload-button"
    >
      {t("FILE.UPLOADING_NEW_SEQUENCE_ALERT.PRIMARY_BUTTON")}
    </Button>
  );
}

UploadSequenceButton.propTypes = {
  onClick: PropTypes.func,
};

UploadSequenceButton.defaultProps = {
  onClick: noop,
};

export default UploadSequenceButton;
