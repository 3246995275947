import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ExportFileReportMenuItem from "@feedback/components/ExportFeedback/ExportFileReportMenuItem/ExportFileReportMenuItem";
import ExportReviewReportMenuItem from "@feedback/components/ExportFeedback/ExportReviewReportMenuItem/ExportReviewReportMenuItem";
import { SvgIcon, ListItemIcon, ListItemText } from "@mui/material";

import SubMenuItem from "@shared/UIKit/SubMenu/SubMenu";

import pagesEnum from "@shared/constants/pages";
import { fileProp } from "@shared/props/file";
import { reviewProp } from "@shared/props/review";
import { stepProps } from "@shared/props/step";
import { teamProp } from "@shared/props/team";

import DownloadFileReportIcon from "@assets/img/icons/export-file-report.svg";

const ReviewCardExportFileReportMenu = ({
  team,
  step,
  file,
  review,
  closeMenu,
}) => {
  const { isExportFileReportEnabled } = team.subscriptionLimits;

  const { t } = useTranslation();

  return (
    <SubMenuItem
      menuItem={
        <>
          <ListItemIcon>
            <SvgIcon component={DownloadFileReportIcon} />
          </ListItemIcon>
          <ListItemText>{t("FILE.MENU.EXPORT-FILE-REPORT.TITLE")}</ListItemText>
        </>
      }
      menuItemProps={{
        "data-testid": "review-card-export-menu",
      }}
    >
      <ExportReviewReportMenuItem
        closeMenu={closeMenu}
        label="FILE.MENU.EXPORT-FILE-REPORT.EXPORT-THIS-REVIEW"
        page={pagesEnum.PROJECT_DASHBOARD}
        team={team}
        step={step}
        file={file}
        review={review}
      />
      <ExportFileReportMenuItem
        isExportFileReportEnabled={isExportFileReportEnabled}
        closeMenu={closeMenu}
        label="FILE.MENU.EXPORT-FILE-REPORT.EXPORT-FULL-FILE"
        page={pagesEnum.PROJECT_DASHBOARD}
        fileId={file.id}
        teamId={team._id}
      />
    </SubMenuItem>
  );
};

ReviewCardExportFileReportMenu.displayName = "ReviewCardExportFileReportMenu";

ReviewCardExportFileReportMenu.propTypes = {
  team: teamProp.isRequired,
  step: stepProps.isRequired,
  file: fileProp.isRequired,
  review: reviewProp.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default ReviewCardExportFileReportMenu;
