import { useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

import {
  Icon,
  Link,
  SvgIcon,
  Button,
  Paper,
  Card,
  CardMedia,
} from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

import { Box, Text } from "@shared/UIKit";

import { instance as analytics } from "@shared/services/analytics";

import PremiumIcon from "@assets/img/icons/ic_upgrade_24px.svg";
import InsightsMiniBgUpsell from "@assets/img/images/insights_mini_bg_upsell.png";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2, 2, 2, 2),
    width: 329,
    minHeight: 513,
    background: theme.color.white,
    border: `1px solid ${theme.color.gray[200]}`,
    boxShadow:
      "0px 4px 6px -1px rgb(16 24 40 / 10%), 0px 2px 4px -2px rgb(16 24 40 / 10%)",
    borderRadius: "10px",
    maxWidth: "80%",
    marginTop: 20,
    boxSizing: "border-box",
    [theme.breakpoints.up("sm")]: {
      marginTop: 130,
      width: 620,
      marginBottom: 30,
      padding: theme.spacing(2, 0, 2, 1),
    },
  },
  requestMetricButton: {
    borderRadius: 6,
    backgroundColor: theme.color.gray[700],
    "&:hover": {
      backgroundColor: theme.color.gray[600],
    },
  },
  title: {
    textAlign: "center",
    fontWeight: 600,
    fontSize: 20,
  },
  description: {
    textAlign: "center",
    padding: theme.spacing(0, 4, 0, 4),
  },
}));

function InsightsUpsellPaper() {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  const onGetInTouchClick = () => {
    analytics.trackV2({
      action: analytics.ACTION.CLICKED,
      category: analytics.CATEGORY.INSIGHTS.NAME,
      subCategory: analytics.SUB_CATEGORY.UPGRADE_INTEREST_BUTTON,
    });
  };

  useEffect(() => {
    analytics.trackV2({
      action: analytics.ACTION.REACHED,
      category: analytics.CATEGORY.SUBSCRIPTION_LIMIT,
      subCategory: analytics.SUB_CATEGORY.INSIGHTS,
      type: analytics.TYPE.FEATURE,
    });
  }, []);

  return (
    <Paper
      sx={{
        px: 4,
        py: 4,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        position: "absolute",
        left: 0,
        right: 0,
        width: {
          xs: 300,
          sm: 620,
        },
        top: {
          xs: 0,
          sm: 70,
        },
        margin: "0 auto",
      }}
    >
      <Box
        display="flex"
        alignItems="flex-start"
        paddingTop={2}
        width="100%"
        paddingLeft={4}
      >
        <Icon
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <SvgIcon
            component={PremiumIcon}
            sx={{
              color: theme.color.orange[500],
            }}
          />
        </Icon>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap={2}
        width="100%"
      >
        <Text className={classes.title}>{t("INSIGHTS.UPSELL.TITLE")}</Text>
        <Text
          variant="body2"
          color={theme.color.gray[600]}
          className={classes.description}
        >
          <Trans
            components={{
              anchor: (
                <Link
                  target="_blank"
                  href={t("URLS.LEARN_MORE.INSIGHTS_UPSELL")}
                  color={theme.color.orange[500]}
                  fontSize={theme.typography.body2.fontSize}
                  fontWeight={theme.typography.fontWeight.semiBold}
                  underline="none"
                  hoverColor={theme.color.blue[600]}
                />
              ),
            }}
          >
            {t("INSIGHTS.UPSELL.DESCRIPTION")}
          </Trans>
        </Text>
        <Card sx={{ maxWidth: 620, boxShadow: "none" }}>
          <CardMedia
            sx={{
              height: {
                xs: 200,
                sm: 300,
              },
              width: {
                xs: 310,
                sm: 620,
              },
              backgroundSize: "contain",
            }}
            image={InsightsMiniBgUpsell}
            title="green iguana"
          />
        </Card>
        <Button
          color="primaryV2"
          variant="contained"
          size="small"
          className="launch-intercom-chat"
          onClick={onGetInTouchClick}
          sx={{ textWrap: "nowrap" }}
        >
          {t("HELP_CARD.BUTTON")}
        </Button>
      </Box>
    </Paper>
  );
}

export default InsightsUpsellPaper;
