import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton as Button } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useTeamRoleForm } from "@supporting/hooks/useTeamRoleForm";
import teamRoleService from "@supporting/services/teamRoleService";
import toastService from "@supporting/services/toast";

import { roleProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

import TeamRoleForm from "../TeamRoleForm/TeamRoleForm";

function UpdateTeamRoleDialog({
  orderedPermissions,
  teamRoles,
  setIsOpenDialog,
  teamId,
  teamRole,
}) {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty, isSubmitting },
  } = useTeamRoleForm({
    defaultValues: {
      roleName: teamRole.roleName,
      permissions: teamRole.permissions,
    },
    teamRoles,
  });

  const cancelDialog = useCallback(() => {
    setIsOpenDialog(false);
  }, [setIsOpenDialog]);

  const updateRole = useCallback(
    async (role) => {
      try {
        const teamRoleResponse = await teamRoleService.updateTeamRole(
          teamId,
          teamRole._id,
          role
        );
        analytics.trackV2({
          action: analytics.ACTION.UPDATED,
          category: analytics.CATEGORY.CUSTOM_ROLES,
        });
        toastService.sendToast({
          title: "TEAM.ROLES.UPDATE_SUCCESS.TITLE",
          body: "TEAM.ROLES.UPDATE_SUCCESS.BODY",
          preset: toastService.PRESETS().SUCCESS,
          translationVariables: {
            body: {
              roleName: teamRoleResponse.roleName,
            },
          },
        });
        setIsOpenDialog(false);
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [setIsOpenDialog, teamId, teamRole._id]
  );

  return (
    <Dialog
      open
      fullWidth
      maxWidth="xs"
      onClose={cancelDialog}
      data-testid="update-team-role-dialog"
    >
      <DialogTitle fontWeight="fontWeight.medium" textAlign="center">
        {t("TEAM.ROLES.EDIT")}
      </DialogTitle>
      <IconButton
        onClick={cancelDialog}
        sx={{ position: "absolute", top: 8, right: 8 }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: 4 }}>
        <TeamRoleForm
          orderedPermissions={orderedPermissions}
          control={control}
          handleSubmit={handleSubmit(updateRole)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          form="team-role-form"
          color="primary"
          size="large"
          variant="contained"
          loading={isSubmitting}
          data-testid="create-role-button"
          disabled={!isDirty || !isValid}
        >
          {t("TEAM.ROLES.UPDATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

UpdateTeamRoleDialog.propTypes = {
  orderedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamRoles: PropTypes.arrayOf(roleProp).isRequired,
  setIsOpenDialog: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  teamRole: roleProp.isRequired,
};

export default UpdateTeamRoleDialog;
