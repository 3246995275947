import debounce from "lodash/debounce";

export default function waitForRender(element) {
  let observer;
  let checkPendingRequests;
  const debounceDelay = 1000;
  const render = new Promise((resolve) => {
    const startTime = Date.now();
    checkPendingRequests = debounce((endTime) => {
      observer.disconnect();
      resolve(endTime - startTime);
    }, debounceDelay);
    observer = new MutationObserver(() => checkPendingRequests(Date.now()));
    observer.observe(element, {
      childList: true,
      subtree: true,
      attributes: false,
    });
  });
  return {
    render,
    cancel: () => {
      checkPendingRequests.cancel();
      observer.disconnect();
    },
  };
}
