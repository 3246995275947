/* eslint-disable react/forbid-component-props */
import { useVirtualizer, defaultRangeExtractor } from "@tanstack/react-virtual";
import PropTypes from "prop-types";
import {
  useMemo,
  useRef,
  useCallback,
  useEffect,
  Fragment,
  useState,
  useLayoutEffect,
} from "react";

import { makeStyles } from "@mui/styles";
import FloatingSelectionBar from "@workflow/components/FloatingSelectionBar/FloatingSelectionBar";
import { useSelectedFiles } from "@workflow/components/SelectFilesContext";
import StepHeaders from "@workflow/components/StepHeaders/StepHeaders";
import cx from "classnames";

import { Box } from "@shared/UIKit";

import useSessionStorage from "@shared/hooks/useSessionStorage";

import RenderRow from "./RenderRow";
import RenderRowFiles from "./RenderRowFiles";
import { swimlaneRowTypesEnum } from "./SwimlaneRowTypesEnum";

const COMPENSATION_FOR_VIRTUAL_LIST_FOR_PAGE = 64;
const COMPENSATION_LAST_ITEM_IN_SECTION = 29;

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    flex: "auto",
    [theme.breakpoints.down("md")]: {
      width: "100vw",
    },
  },
  rootWithHorizontalScroll: {
    overflowX: "auto",
  },
  projectFilesContainer: {
    display: "flex",
    flex: 1,
  },
  listAutosizer: {
    flex: 1,
  },
  virtualList: {
    position: "relative",
    zIndex: 50,
  },
  virtualRow: {
    top: 0,
    left: 0,
    zIndex: 50,
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      minWidth: "100vw",
    },
  },
  stickyRow: {
    zIndex: 150,
  },
  activeStickyRow: {
    position: "sticky",
    top: 86,
    zIndex: 100,
  },
}));

const ROW_HEIGHTS = {
  [swimlaneRowTypesEnum.STEP]: 93,
  [swimlaneRowTypesEnum.SECTION]: 45,
  [swimlaneRowTypesEnum.EMPTY_SECTION]: 257,
  [swimlaneRowTypesEnum.ADD_SECTION]: 61,
  [swimlaneRowTypesEnum.FILE]: 70,
  [swimlaneRowTypesEnum.LOADER]: 350,
  [swimlaneRowTypesEnum.EMPTY_SECTION_NO_UPLOAD_ACCESS]: 140,
};
const VERSION_SUB_ROW_HEIGHT = 40;

const principalRows = [
  swimlaneRowTypesEnum.SECTION,
  swimlaneRowTypesEnum.ADD_SECTION,
  swimlaneRowTypesEnum.LOADER,
];
const secondaryRows = [
  swimlaneRowTypesEnum.FILE,
  swimlaneRowTypesEnum.EMPTY_SECTION,
  swimlaneRowTypesEnum.EMPTY_SECTION_NO_UPLOAD_ACCESS,
];

const getPositionRow = (virtualRow, isRowActiveSticky, isLoader, items) => {
  /* istanbul ignore next */
  if (
    isLoader &&
    items[virtualRow.index - 1]?.type !== swimlaneRowTypesEnum.SECTION
  ) {
    return `translateY(${virtualRow.start - 30}px)`;
  }
  return !isRowActiveSticky && `translateY(${virtualRow.start}px)`;
};

function Swimlane({
  sections,
  hasMore,
  steps,
  project,
  team,
  isTeamMember,
  isLoadingFiles,
  isLoadingSteps,
  fetchMoreFiles,
}) {
  const parentRef = useRef(0);
  const activeStickyIndexRef = useRef(0);
  const visibleProjectRef = useRef(project.id);

  const [selectedFileId, setSelectedFileId] =
    useSessionStorage("selectedFileId");

  const classes = useStyles();
  const openedFileIds = useRef([]);

  const canManageSection =
    !project.isArchived && project.permissions.manageSections;
  const canUploadFiles = !project.isArchived && project.permissions.uploadFiles;
  const { exitSelectState, isSelectionState } = useSelectedFiles();

  useEffect(() => {
    openedFileIds.current = [];
    exitSelectState();
  }, [project.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const items = useMemo(() => {
    const sectionFiles = sections.reduce(
      (acc, section) => [
        ...acc,
        {
          type: swimlaneRowTypesEnum.SECTION,
          ...section,
        },
        ...(section.fileCount === 0 && !section.hasMore && !section.isCollapsed
          ? [
              {
                type: canUploadFiles
                  ? swimlaneRowTypesEnum.EMPTY_SECTION
                  : swimlaneRowTypesEnum.EMPTY_SECTION_NO_UPLOAD_ACCESS,
                sectionId: section.id,
              },
            ]
          : []),
        ...((!section.isCollapsed &&
          section.files.map((file) => ({
            type: swimlaneRowTypesEnum.FILE,
            ...file,
          }))) ||
          []),
      ],
      []
    );

    return [
      ...sectionFiles,
      ...(hasMore && isLoadingFiles
        ? [{ type: swimlaneRowTypesEnum.LOADER }]
        : []),
      ...(!hasMore && !isLoadingFiles && canManageSection
        ? [{ type: swimlaneRowTypesEnum.ADD_SECTION }]
        : []),
    ];
  }, [sections, hasMore, isLoadingFiles, canManageSection, canUploadFiles]);

  const stickyIndexes = useMemo(() => {
    return items.reduce((acc, item, index) => {
      if (item.type === swimlaneRowTypesEnum.SECTION) {
        return [...acc, index];
      }
      return acc;
    }, []);
  }, [items]);

  const isSticky = (index) => stickyIndexes.includes(index);

  const isActiveSticky = (index) => activeStickyIndexRef.current === index;

  const itemData = {
    items,
    steps,
    project,
    team,
    isLoadingFiles,
    isLoadingSteps,
    isTeamMember,
    stickyIndices: stickyIndexes,
  };

  const rowVirtualizer = useVirtualizer({
    count: items.length,
    overscan: 10,
    estimateSize: (index) => {
      const item = items[index];
      if (item.type === swimlaneRowTypesEnum.LOADER) {
        return ROW_HEIGHTS[swimlaneRowTypesEnum.LOADER];
      }
      if (item.type === swimlaneRowTypesEnum.SECTION) {
        return ROW_HEIGHTS[swimlaneRowTypesEnum.SECTION];
      }
      if (item.type === swimlaneRowTypesEnum.EMPTY_SECTION) {
        return ROW_HEIGHTS[swimlaneRowTypesEnum.EMPTY_SECTION];
      }
      if (item.type === swimlaneRowTypesEnum.EMPTY_SECTION_NO_UPLOAD_ACCESS) {
        return ROW_HEIGHTS[swimlaneRowTypesEnum.EMPTY_SECTION_NO_UPLOAD_ACCESS];
      }
      if (item.type === swimlaneRowTypesEnum.FILE) {
        const section = sections.find(
          (section) => section.id === item.sectionId
        );

        const marginBottomLastItem =
          section?.files[section.files.length - 1].id === item.id &&
          !section?.files[section.files.length - 1].isDummy &&
          COMPENSATION_LAST_ITEM_IN_SECTION;

        if (openedFileIds && openedFileIds.current.includes(item.id)) {
          return (
            ROW_HEIGHTS[swimlaneRowTypesEnum.FILE] +
            (item.versions.length - 1) * VERSION_SUB_ROW_HEIGHT +
            marginBottomLastItem
          );
        }
        return ROW_HEIGHTS[item.type] + marginBottomLastItem;
      }

      return ROW_HEIGHTS[swimlaneRowTypesEnum.ADD_SECTION];
    },
    getScrollElement: () => parentRef.current,
    rangeExtractor: (range) => {
      activeStickyIndexRef.current = [...stickyIndexes]
        .reverse()
        .find((index) => range.startIndex >= index);

      /* istanbul ignore next */
      const next = new Set([
        ...(activeStickyIndexRef.current !== undefined
          ? [activeStickyIndexRef.current]
          : /* istanbul ignore next */
            []),
        ...defaultRangeExtractor(range),
      ]);

      return [...next].sort(
        (prevSticky, nextSticky) => prevSticky - nextSticky
      );
    },
  });

  useLayoutEffect(() => {
    const virtualItems = rowVirtualizer.getVirtualItems();
    const lastItem = virtualItems[virtualItems.length - 1];

    if (lastItem?.index === items.length - 1 && hasMore && !isLoadingFiles) {
      fetchMoreFiles(project.id);
    } else {
      /* istanbul ignore next */
      if (
        selectedFileId &&
        items.length > 1 &&
        project.id === visibleProjectRef.current &&
        !isLoadingFiles
      ) {
        const itemIndex = items.findIndex(
          (item) =>
            item.type === swimlaneRowTypesEnum.FILE &&
            item._id === selectedFileId
        );

        if (itemIndex !== -1) {
          rowVirtualizer.scrollToIndex(itemIndex, {
            align: "center",
            smoothScroll: false,
          });
          setSelectedFileId(null);
        }
      }
    }
  }, [
    fetchMoreFiles,
    hasMore,
    isLoadingFiles,
    items,
    items.length,
    project.id,
    rowVirtualizer,
    selectedFileId,
    setSelectedFileId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    rowVirtualizer.getVirtualItems(),
  ]);

  useEffect(() => {
    rowVirtualizer.measure();
  }, [rowVirtualizer, sections, hasMore, isLoadingFiles]);

  const setOpenVersions = useCallback(
    (wasOpened, _count, fileId) => {
      if (wasOpened) {
        openedFileIds.current.push(fileId);
      } else {
        openedFileIds.current = openedFileIds.current.filter(
          (id) => id !== fileId
        );
      }
      rowVirtualizer.measure();
    },
    [rowVirtualizer]
  );

  const [sectionDragActive, setSectionDragActive] = useState([]);
  const handleDragActive = useCallback((isDragActive, sectionId) => {
    setSectionDragActive((prev) => {
      if (isDragActive) {
        return [...prev, sectionId];
      }
      return prev.filter((id) => id !== sectionId);
    });
  }, []);

  return (
    <Box
      ref={parentRef}
      id="swimlane-container"
      data-testid="swimlane-container"
      className={classes.root}
    >
      <StepHeaders
        project={project}
        steps={steps}
        isLoadingSteps={isLoadingSteps}
        team={team}
      />
      <Box
        id="virtual-list"
        data-testid="project-files"
        height={`${
          rowVirtualizer.getTotalSize() - COMPENSATION_FOR_VIRTUAL_LIST_FOR_PAGE
        }px`}
        className={classes.virtualList}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const isLoader =
            items[virtualRow.index]?.type === swimlaneRowTypesEnum.LOADER;
          const isRowSticky = isSticky(virtualRow.index);
          const isRowActiveSticky = isActiveSticky(virtualRow.index);

          if (secondaryRows.includes(items[virtualRow.index]?.type)) {
            return null;
          }

          let filesVirtualRows = [];
          let section = null;
          if (isRowSticky) {
            section = sections.find(
              (section) => section.id === items[virtualRow.index].id
            );

            filesVirtualRows = rowVirtualizer
              .getVirtualItems()
              .filter(
                (virtualRowItem) =>
                  virtualRowItem.index > virtualRow.index &&
                  items[virtualRowItem.index]?.sectionId === section.id
              );
          }

          return (
            <Fragment key={`virtual-row-${virtualRow.index}`}>
              <Box
                key={virtualRow.index}
                ref={virtualRow.measureRef}
                data-index={virtualRow.index}
                data-typerow={items[virtualRow.index]?.type}
                id={`virtual-row-${virtualRow.index}-${
                  items[virtualRow.index]?.type
                }`}
                data-testid={`virtual-row-${virtualRow.index}-${
                  items[virtualRow.index]?.type
                }`}
                className={cx({
                  [classes.virtualRow]: true,
                  [classes.stickyRow]: isRowSticky,
                  [classes.activeStickyRow]: isRowActiveSticky,
                })}
                minHeight={`${virtualRow.size}px`}
                style={{
                  transform: getPositionRow(
                    virtualRow,
                    isRowActiveSticky,
                    isLoader,
                    items
                  ),
                  ...(isLoader && {
                    zIndex: -virtualRow.index,
                  }),
                }}
              >
                {principalRows.includes(items[virtualRow.index]?.type) && (
                  <RenderRow
                    data={itemData}
                    index={virtualRow.index}
                    toggleOpenVersions={setOpenVersions}
                    openedFileIds={openedFileIds.current}
                    isLoadingSteps={isLoadingSteps}
                    onDragIsActive={handleDragActive}
                    sectionDragActive={sectionDragActive}
                    sectionVirtualRow={virtualRow}
                    isSectionRowActiveSticky={isRowActiveSticky}
                    showSectionHeader={isLoadingFiles && sections.length === 0}
                  />
                )}
              </Box>
              {items[virtualRow.index]?.type ===
                swimlaneRowTypesEnum.SECTION && (
                <RenderRowFiles
                  rows={filesVirtualRows}
                  data={itemData}
                  toggleOpenVersions={setOpenVersions}
                  openedFileIds={openedFileIds.current}
                  section={section}
                  steps={steps}
                  isLoadingSteps={isLoadingSteps}
                  onDragIsActive={handleDragActive}
                  canUploadFiles={canUploadFiles}
                  projectId={project.id}
                  teamId={team._id}
                />
              )}
            </Fragment>
          );
        })}
      </Box>
      {isSelectionState && <FloatingSelectionBar projectId={project.id} />}
    </Box>
  );
}

Swimlane.propTypes = {
  sections: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  steps: PropTypes.array.isRequired,
  project: PropTypes.object.isRequired,
  team: PropTypes.object.isRequired,
  isLoadingFiles: PropTypes.bool.isRequired,
  isLoadingSteps: PropTypes.bool.isRequired,
  isTeamMember: PropTypes.bool.isRequired,
  fetchMoreFiles: PropTypes.func.isRequired,
};

export default Swimlane;
