import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Button, Card, CardContent } from "@mui/material";

import { Box, Text } from "@shared/UIKit";

import FilestageResponsiveLogo from "@shared/components/FilestageResponsiveLogo/FilestageResponsiveLogo";
import { APP_ROUTES } from "@shared/constants/routes";

function ProfitWell() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      width="100%"
      data-testid="profitwell"
      mt={4}
      alignItems="center"
      justifyContent="center"
    >
      <Card sx={{ padding: 2 }}>
        <CardContent
          sx={{
            maxWidth: 248,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FilestageResponsiveLogo />
          <Text textAlign="center">{t("PROFITWELL.MESSAGE")}</Text>
          <Button
            size="large"
            color="primary"
            variant="contained"
            to={APP_ROUTES.DASHBOARD.path}
            component={Link}
          >
            {t("PROFITWELL.CTA")}
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ProfitWell;
