import NiceModal, { useModal } from "@ebay/nice-modal-react";
import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";

import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import SelectPlanDialog from "@supporting/components/SelectPlanDialog/SelectPlanDialog";
import useProjectSteps from "@workflow/hooks/useProjectSteps";
import noop from "lodash/noop";

import { Box } from "@shared/UIKit";

import pagesEnum from "@shared/constants/pages";
import { UPLOAD_COMPONENT_TYPE } from "@shared/constants/upload";
import { fileProp } from "@shared/props/file";
import { instance as healthMetrics } from "@shared/services/HealthMetrics";

import UploadPanel from "../UploadFileButton/UploadPanel";

const useStyles = makeStyles((theme) => ({
  uploadPanelContainer: {
    boxShadow: theme.shadow["box-shadow-upload-button-panel"],
    borderRadius: "8px",
    margin: "auto",
  },
}));

function UploadPanelDialog({
  droppedFiles,
  projectId,
  sectionId,
  stepIds,
  selectedFile,
  page,
  component,
  teamId,
}) {
  const classes = useStyles();
  const modal = useModal();
  const [steps] = useProjectSteps(projectId, false);

  useEffect(() => {
    healthMetrics.trackAttempt("workflow.upload-file");
  }, []);

  const cancelDialog = useCallback(
    (event, reason) => {
      if (["backdropClick", "escapeKeyDown", "cancel"].includes(reason)) {
        healthMetrics.trackCancellation("workflow.upload-file");
      }
      modal.hide();
      modal.remove();
    },
    [modal]
  );

  function cancelSelectPlanDialog() {
    cancelDialog({}, "cancel");
  }

  const openDialog = () =>
    NiceModal.show(SelectPlanDialog, {
      cancel: cancelSelectPlanDialog,
      teamId,
    });

  return (
    <Modal
      open={modal.visible}
      onClose={cancelDialog}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        data-testid="upload-file-button-upload-panel-root"
        className={classes.uploadPanelContainer}
      >
        <UploadPanel
          closePanel={cancelDialog}
          projectId={projectId}
          sectionId={sectionId}
          selectedFile={selectedFile}
          steps={steps}
          droppedFiles={droppedFiles}
          stepIds={stepIds}
          teamId={teamId}
          page={page}
          component={component}
          openBillingDialog={openDialog}
        />
      </Box>
    </Modal>
  );
}

UploadPanelDialog.propTypes = {
  page: PropTypes.oneOf([
    pagesEnum.PROJECT_DASHBOARD,
    pagesEnum.ONBOARDING_WIZARD,
    pagesEnum.FILE_VIEW,
  ]).isRequired,
  component: PropTypes.oneOf([
    UPLOAD_COMPONENT_TYPE.POPOVER,
    UPLOAD_COMPONENT_TYPE.NAVIGATION_WORKSPACE,
    UPLOAD_COMPONENT_TYPE.WORKSPACE_SIDEBAR,
  ]).isRequired,
  projectId: PropTypes.string.isRequired,
  sectionId: PropTypes.string,
  selectedFile: fileProp,
  stepIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  teamId: PropTypes.string.isRequired,
  isGuestSession: PropTypes.bool,
  onClosePanel: PropTypes.func,
  onOpenPanel: PropTypes.func,
  droppedFiles: PropTypes.arrayOf(PropTypes.object),
};

UploadPanelDialog.defaultProps = {
  sectionId: null,
  selectedFile: null,
  isGuestSession: false,
  onClosePanel: null,
  onOpenPanel: noop,
  droppedFiles: [],
};

export default NiceModal.create(UploadPanelDialog);
