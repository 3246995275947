import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton as Button } from "@mui/lab";
import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import { TEAM_ROLES } from "@supporting/constants/team";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Avatar, Box, Text, Tooltip } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import { roleProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";
import { instance as logger } from "@shared/services/logger";

const TAG = "supporting.pending-members-row";
const errorToToastMessageMap = {
  TEAM_MEMBER_LIMIT_EXCEEDED: "TEAM.ADD-MEMBER.SEAT-LIMIT-REACHED.INFO",
  ALREADY_MEMBER_OF_TEAM: "TEAM.ADD_MEMBER.MESSAGE_EXISTS",
};

function PendingMembersRow({ teamId, member, defaultRole }) {
  const { t } = useTranslation();
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const handleApprove = async () => {
    try {
      setIsApproving(true);
      await teamService.addTeamMember(member.email, defaultRole._id);
      analytics.track(
        analytics.ACTION.APPROVED,
        analytics.CATEGORY.TEAM_MEMBER_JOIN_REQUEST,
        {
          approvedEmail: member.email,
        }
      );
    } catch (error) {
      setIsApproving(false);

      const toastMesage = errorToToastMessageMap[error.message];
      if (toastMesage) {
        toastService.sendToast({
          title: "ERROR.ACTION_NOT_POSSIBLE.TITLE",
          body: toastMesage,
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
      } else {
        errorHandlerService.handleError(error);
      }
      logger.error(TAG, "failed to add team member", { error });
    }
  };

  const handleRejection = async () => {
    try {
      setIsRejecting(true);
      await teamService.removePendingMembers(teamId, [member._id]);
      analytics.track(
        analytics.ACTION.REJECTED,
        analytics.CATEGORY.TEAM_MEMBER_JOIN_REQUEST,
        {
          rejectedEmail: member.email,
        }
      );
    } catch (error) {
      setIsRejecting(false);
      logger.error(TAG, "failed to remove pending member", { error });
    }
  };

  return (
    <TableRow data-testid="team-member-row">
      <TableCell>
        <Avatar
          user={member}
          sx={{
            width: 40,
            height: 40,
            margin: "0 auto",
            marginRight: 0,
          }}
        />
      </TableCell>
      <TableCell>
        <Text variant="h6" fontWeight="fontWeight.bold">
          {member.displayName}
        </Text>
      </TableCell>
      <TableCell>{member.email}</TableCell>
      {defaultRole ? (
        <TableCell>
          {TEAM_ROLES[defaultRole.roleName]
            ? t(`TEAM.ROLES.${defaultRole.roleName}`)
            : defaultRole.roleName}
        </TableCell>
      ) : (
        <TableCell />
      )}
      <TableCell
        align="right"
        sx={{
          display: "flex",
          whiteSpace: "nowrap",
          flexDirection: "row",
          alignItems: "center",
          minWidth: 100,
        }}
      >
        <Tooltip
          title={t("TEAM_INVITE_LINK.DIALOGUE_TOOLTIP")}
          disabled={Boolean(defaultRole)}
        >
          <Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={handleApprove}
              disabled={isApproving || isRejecting || !defaultRole}
              loading={isApproving}
            >
              {t("CORE.APPROVE")}
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title={t("TEAM_INVITE_LINK.MEMBER_DECLINE")}>
          <Box component="span">
            <IconButton
              onClick={handleRejection}
              disabled={isApproving || isRejecting}
              data-testid="pending-member-decline-button"
              size="large"
            >
              {isRejecting ? (
                <Box
                  display="flex"
                  alignItems="center"
                  position="absolute"
                  justifyContent="center"
                >
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <CancelOutlinedIcon />
              )}
            </IconButton>
          </Box>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}

PendingMembersRow.propTypes = {
  member: userProp.isRequired,
  defaultRole: roleProp,
  teamId: PropTypes.string.isRequired,
};

PendingMembersRow.defaultProps = {
  defaultRole: undefined,
};

export default PendingMembersRow;
