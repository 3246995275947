import PropTypes from "prop-types";
import { ReactElement } from "react";

import {
  AddBox,
  Image,
  PlayCircleFilled,
  Description,
  Audiotrack,
} from "@mui/icons-material";

import { versionProp } from "@shared/props/file";

import WebsiteDefaultThumbnail from "@assets/img/icons/website-default-thumbnail.svg";

/**
 * UIKit Mime Type Icon
 * @description Renders an icon based on the mime type of the file.
 * Currently supports images, videos, audio, pdfs, and websites.
 * @param {{mimeType: string}} props Component props
 * @param {string} [props.mimeType] [Required] Mime type of the file.
 * @returns {ReactElement} React component
 */

const MimeTypeIcon = ({ mimeType, version, ...restProps }) => {
  let mimeTypeValue = mimeType;

  if (version) {
    mimeTypeValue =
      version.mediaType === "WEBSITE" ||
      version.mediaType === "INTERACTIVE_HTML"
        ? "text/html"
        : version.original?.mimeType;
  }

  if (mimeTypeValue?.includes("image")) {
    return <Image data-testid="image-icon" {...restProps} />;
  }

  if (mimeTypeValue?.includes("video")) {
    return <PlayCircleFilled data-testid="video-icon" {...restProps} />;
  }

  if (mimeTypeValue?.includes("audio")) {
    return <Audiotrack data-testid="audio-icon" {...restProps} />;
  }

  if (mimeTypeValue?.includes("application/pdf")) {
    return <Description data-testid="pdf-icon" {...restProps} />;
  }

  if (mimeTypeValue?.includes("html")) {
    return (
      <WebsiteDefaultThumbnail data-testid="website-icon" {...restProps} />
    );
  }

  return <AddBox data-testid="default-icon" {...restProps} />;
};

MimeTypeIcon.propTypes = {
  mimeType: PropTypes.string,
  version: versionProp,
};

MimeTypeIcon.defaultProps = {
  mimeType: "",
  version: null,
};

export default MimeTypeIcon;
