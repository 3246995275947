import PropTypes from "prop-types";

import { makeStyles, useTheme } from "@mui/styles";

import { Box, DueDate, Text } from "@shared/UIKit";

import { REVIEW_STATUS } from "@shared/constants/reviewStatus";
import { pendingReviewFileProp } from "@shared/props/pendingReview";

import PendingReviewsCardVersion from "./PendingReviewsCardVersion";
import PendingReviewsThumbnail from "./PendingReviewsThumbnail";

const useStyles = makeStyles((theme) => ({
  fileCard: {
    minHeight: 72,
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
  },
  fileName: {
    marginLeft: 10,
  },
  bottomBoxContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "100%",
    boxSizing: "border-box",
  },
  notProcessingBottomBoxContainer: {
    paddingBottom: 6,
    paddingLeft: 9,
    paddingRight: 12,
  },
  dateVersionWrapper: {
    padding: 5,
  },
  fileNameWrapper: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 5,
    wordWrap: "break-word",
  },
}));

function PendingReviewsCardContent({ file, dueDate }) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display="flex" data-testid="file-card" className={classes.fileCard}>
      <PendingReviewsThumbnail file={file} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={280}
        overflow="hidden"
      >
        <Box
          className={classes.fileNameWrapper}
          data-testid={`file-name-${file.name}`}
        >
          <Text fontWeight={theme.typography.fontWeight.semiBold}>
            {file.name}
          </Text>
        </Box>
        <Box
          className={classes.dateVersionWrapper}
          display="flex"
          alignItems="center"
        >
          <DueDate
            review={{
              reviewDueDate: dueDate,
              status: {
                state: REVIEW_STATUS.IN_REVIEW,
              },
              isPendingYourReview: true,
            }}
            isReadOnly
            useColors
          />
          <PendingReviewsCardVersion versionNumber={file.version.number} />
        </Box>
      </Box>
    </Box>
  );
}

PendingReviewsCardContent.propTypes = {
  file: pendingReviewFileProp.isRequired,
  dueDate: PropTypes.string,
};
PendingReviewsCardContent.defaultProps = {
  dueDate: null,
};

export default PendingReviewsCardContent;
