import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import { makeStyles, useTheme } from "@mui/styles";
import SectionHeaderMenu from "@workflow/components/SectionHeaderMenu/SectionHeaderMenu";
import SectionName from "@workflow/components/SectionName/SectionName";
import projectTemplateService from "@workflow/services/projectTemplateService";
import classnames from "classnames";

import { Box } from "@shared/UIKit";

import { useMediaQuery } from "@shared/hooks";
import { projectTemplateProps, sectionProps } from "@shared/props/project";
import errorHandlerService from "@shared/services/errorHandler";

const useStyles = makeStyles((theme) => ({
  tooltipContainerDisabled: {
    width: "auto !important",
    [theme.breakpoints.down("md")]: {
      maxWidth: "34%",
    },
  },
  sectionName: {
    flexGrow: 1,
    paddingRight: 10,
  },
  addSectionLabelEditing: {
    width: "90%",
  },
}));

function TemplateSection({ section, project }) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleChangeEditMode = useCallback((value) => {
    setIsEditing(value);
  }, []);

  const theme = useTheme();
  const canManageTemplate = project.permissions.canmanageTemplates;

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isEditableLabelDisabled =
    !canManageTemplate || (isMobile && !isEditing);

  const renameSection = useCallback(
    async (name) => {
      try {
        await projectTemplateService.renameProjectTemplateSection(
          project.id,
          section.id,
          name
        );
      } catch (error) {
        errorHandlerService.handleError(error);
      }
    },
    [section.id, project.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Box display="flex" py={1} width={250}>
      <SectionName
        text={section.name}
        onChange={handleChangeEditMode}
        className={classnames(classes.sectionName, {
          [classes.addSectionLabelEditing]: isEditing,
        })}
        tooltipContainerDisabledClassName={classes.tooltipContainerDisabled}
        isDisabled={isEditableLabelDisabled}
        isEditing={isEditing}
        onSave={renameSection}
      />
      {canManageTemplate && !isEditing && (
        <SectionHeaderMenu
          section={section}
          project={project}
          onRename={handleChangeEditMode}
          isTemplate
        />
      )}
    </Box>
  );
}

TemplateSection.propTypes = {
  section: sectionProps.isRequired,
  project: PropTypes.shape(projectTemplateProps).isRequired,
};

export default TemplateSection;
