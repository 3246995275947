import { useCallback, useState } from "react";

import { LoadingButton as Button } from "@mui/lab";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useTheme, makeStyles } from "@mui/styles";
import Title from "@supporting/components/Admin/Title";
import userService from "@supporting/services/userService";
import clsx from "clsx";

import { Text, Box } from "@shared/UIKit";

import EmailInput from "./EmailInput";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 310,
  },
  form: {
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
}));

function MergeAccounts() {
  const classes = useStyles();
  const theme = useTheme();

  const [sourceEmail, setSourceEmail] = useState({
    email: "",
    isEmailValid: false,
  });
  const [targetEmail, setTargetEmail] = useState({
    email: "",
    isEmailValid: false,
  });
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("");
      await userService.mergeAccounts(sourceEmail.email, targetEmail.email);

      setSuccessMessage(`Merged ${sourceEmail.email} to ${targetEmail.email} `);
      setSourceEmail({ email: "", isEmailValid: false });
      setTargetEmail({ email: "", isEmailValid: false });
      setIsLoading(false);
    } catch (response) {
      setIsLoading(false);
      setErrorMessage("Failed to merge accounts");
    }
  }, [sourceEmail, targetEmail, setIsLoading]);

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <>
      <Title>Merge Accounts</Title>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Paper className={fixedHeightPaper}>
            <Box className={classes.formContainer}>
              <EmailInput
                onChange={setSourceEmail}
                inputState={sourceEmail}
                label="Source Email"
              />
              <EmailInput
                onChange={setTargetEmail}
                inputState={targetEmail}
                label="Target Email"
              />
              <Box mt={2} maxWidth={400} width="100%">
                <Button
                  loading={isLoading}
                  size="large"
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleClick}
                  disabled={
                    !sourceEmail.isEmailValid || !targetEmail.isEmailValid
                  }
                  data-testid="form-submit"
                >
                  {isLoading ? "Merging..." : "Merge Accounts"}
                </Button>
              </Box>
              {errorMessage && (
                <Box my={2} ml={5} data-testid="form-error">
                  <Text color={theme.color.red[500]}>{errorMessage}</Text>
                </Box>
              )}
              {successMessage && (
                <Box my={2} ml={1} data-testid="form-success">
                  <Text color={theme.color.green[500]}>{successMessage}</Text>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default MergeAccounts;
