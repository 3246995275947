import PropTypes from "prop-types";
import LazyLoad from "react-lazy-load";

import { makeStyles } from "@mui/styles";

import { Box, Avatar, Text } from "@shared/UIKit";

import { userProp } from "@shared/props/authentication";
import FSTGTypography from "@shared/theme/typography";

import AddReviewerDialogAutocompleteOptionGuest from "../AddReviewerDialogAutocompleteOptionGuest/AddReviewerDialogAutocompleteOptionGuest";
import AIAutocompleteButton from "./AIAutocompleteButton";
import AIAutocompleteOption from "./AIAutocompleteOption";

const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: FSTGTypography.fontSize_1_3,
    lineHeight: FSTGTypography.lineHeight_1_1,
    color: theme.color.white,
    backgroundColor: theme.color.blue[400],
  },
  reviewerOptionText: {
    textWrap: "nowrap",
    [theme.breakpoints.down("md")]: {
      maxWidth: 150,
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

const AddReviewerDialogAutocompleteOption = ({
  renderOptionProps,
  ...user
}) => {
  const classes = useStyles();
  const {
    fullName,
    inputValue,
    hasValidEmail,
    validationOptionError,
    errorText,
    emptyText,
    optionType,
    openAIMarketplaceDialog,
  } = user;
  const userFullName = fullName || inputValue;

  const avatarProps = {
    ...user,
    fullName: userFullName,
  };

  if (optionType === "AIMarketplaceLink") {
    return (
      <li {...renderOptionProps}>
        <AIAutocompleteOption onClick={openAIMarketplaceDialog} />
      </li>
    );
  }

  if (optionType === "AIMarketplaceButton") {
    return <AIAutocompleteButton onClick={openAIMarketplaceDialog} />;
  }

  return (
    <li {...renderOptionProps}>
      <LazyLoad height={46} offset={250} sx={{ display: "flex" }}>
        <Box
          display="flex"
          alignItems="center"
          ml={1.5}
          pt={0.5}
          pb={0.5}
          height="fill-available"
        >
          {user && user._id && (
            <Avatar className={classes.avatar} user={avatarProps} />
          )}
          <Box ml={1}>
            {user && user._id ? (
              <Text className={classes.reviewerOptionText}>
                {userFullName} ({inputValue})
              </Text>
            ) : (
              <AddReviewerDialogAutocompleteOptionGuest
                input={inputValue}
                validEmail={hasValidEmail}
                validationOptionError={validationOptionError}
                errorText={errorText}
                emptyText={emptyText}
              />
            )}
          </Box>
        </Box>
      </LazyLoad>
    </li>
  );
};

AddReviewerDialogAutocompleteOption.propTypes = {
  renderOptionProps: PropTypes.shape({
    inputValue: PropTypes.string,
    isFocused: PropTypes.bool,
    isSelected: PropTypes.bool,
    key: PropTypes.string,
    onClick: PropTypes.func,
  }).isRequired,
  user: userProp,
};

AddReviewerDialogAutocompleteOption.defaultProps = {
  user: null,
};

export default AddReviewerDialogAutocompleteOption;
