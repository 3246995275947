import PropTypes from "prop-types";

import projectSwitcherService from "@workflow/services/projectSwitcherService";

import { projectProps } from "@shared/props/project";

import SidebarListItem from "../../SidebarListItem/SidebarListItem";

export default function ProjectListItem({ isSelected, ...project }) {
  const { isArchived, name } = project;

  const handleClick = () => {
    projectSwitcherService.determineSelectedProjectById(
      project.teamId,
      project.id
    );
  };

  return (
    <SidebarListItem
      id={project.id}
      isSelected={isSelected}
      isArchived={isArchived}
      name={name}
      onClick={handleClick}
    />
  );
}

ProjectListItem.propTypes = {
  ...projectProps,
  isSelected: PropTypes.bool.isRequired,
};
