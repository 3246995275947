/**
 * Core Bytes helper function
 *
 * @description This function is the opposite of converter helper function.
 * It converts a number of bytes into most appropriate unit. Also with
 * the option to specify the precision (`Float.toFixed`) of the output.
 * @param {number} bytes - [Required] Number of bytes to convert.
 * @param {number} precision - [Optional] Number of decimal places to round to.
 * @returns {string} - Returns the number converted to the bytes.
 */

const coreBytes = (bytes, precision) => {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || parseFloat(bytes) <= 0) {
    return "0 bytes";
  }

  if (typeof precision === "undefined") {
    precision = 0;
  }

  const units = ["bytes", "kB", "MB", "GB", "TB", "PB"];
  const number = Math.floor(Math.log(bytes) / Math.log(1000));

  // only add decimal places if they're not 0
  if (
    (bytes / Math.pow(1000, Math.floor(number)))
      .toFixed(precision)
      .replace(/^-?\d*\.?|0+$/g, "").length === 0
  ) {
    precision = 0;
  }

  return `${(bytes / Math.pow(1000, Math.floor(number))).toFixed(precision)} ${
    units[number]
  }`;
};

export default coreBytes;
