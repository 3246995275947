import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { Button, Menu, MenuItem } from "@mui/material";
import teamRoleService from "@supporting/services/teamRoleService";

import { Box, Text, Toggle, Tooltip } from "@shared/UIKit";
import ShareLinkPopper from "@shared/UIKit/ShareLink/ShareLinkPopper/ShareLinkPopper";

import fstgId from "@shared/helpers/fstgId";
import { useNavigation } from "@shared/hooks";
import { instance as analytics } from "@shared/services/analytics";
import eventService, { EVENT_NAMES } from "@shared/services/eventService";

import SelectRoleMenu from "./TeamInviteLinkMenu/SelectRoleMenu";

const APPROVAL_OPTIONS = ["YES", "NO"];
const DEFAULT_ROLE_OPTIONS = ["ADMIN", "FULL_MEMBER"];
const LITE_MEMBER = "LITE_MEMBER";

function TeamInviteLink({
  teamId,
  inviteLink,
  updateInviteLink,
  isMemberRoleEnabled,
  enableInviteSetting,
  setEnableInviteSetting,
  page,
}) {
  const { t } = useTranslation();
  const copyIconRef = useRef(null);
  const [teamRoles, setTeamRoles] = useState([]);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const [anchorRequestApproval, setAnchorRequestApproval] = useState(false);
  const { buildUrl } = useNavigation();

  const completeLink = inviteLink?.linkId
    ? `${window.fs.config.appBase}${buildUrl("TEAM_JOIN_LINK", {
        params: { inviteLinkId: inviteLink.linkId },
      })}`
    : "";

  if (isMemberRoleEnabled) {
    DEFAULT_ROLE_OPTIONS.push(LITE_MEMBER);
  }

  const defaultRoleOptionIndex = DEFAULT_ROLE_OPTIONS.length - 1;

  useEffect(() => {
    const updateTeamRoles = async (teamId) => {
      let newRoles = await teamRoleService.fetchTeamRoles(teamId);
      if (!isMemberRoleEnabled) {
        newRoles = newRoles.filter(({ roleName }) => roleName !== LITE_MEMBER);
      }
      setTeamRoles(newRoles);
      if (!inviteLink.linkId) {
        updateInviteLink({
          defaultRole: newRoles.find(
            ({ roleName }) =>
              roleName === DEFAULT_ROLE_OPTIONS[defaultRoleOptionIndex]
          )._id,
          requestApproval: false,
          linkId: fstgId.generate(),
        });
      }
    };

    const onTeamRolesChanged = ({ eventData }) => {
      const newRoles = eventData.teamRoles;
      setTeamRoles(newRoles);
    };

    eventService.addListener(
      EVENT_NAMES.TEAM.ROLES.UPDATED,
      onTeamRolesChanged
    );

    updateTeamRoles(teamId);

    return () => {
      eventService.removeListener(
        EVENT_NAMES.TEAM.ROLES.UPDATED,
        onTeamRolesChanged
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, isMemberRoleEnabled, inviteLink]);

  const handleOpenRequestApproval = (event) => {
    /* istanbul ignore next */
    setAnchorRequestApproval(enableInviteSetting ? event.currentTarget : null);
  };

  const handleCloseRequestApproval = () => {
    setAnchorRequestApproval(null);
  };

  const handleRequestApprovalSelection = (event) => {
    updateInviteLink({
      requestApproval: event.target.id === APPROVAL_OPTIONS[0],
    });
    handleCloseRequestApproval();
  };

  const handleSwitchInviteLink = () => {
    if (enableInviteSetting) {
      setEnableInviteSetting(false);
    } else {
      setEnableInviteSetting(true);
    }
  };

  const handleClickLinkToCopy = () => {
    navigator.clipboard.writeText(completeLink);
    analytics.track(
      analytics.ACTION.COPIED,
      analytics.CATEGORY.TEAM_INVITE_LINK,
      {
        page,
      }
    );
    setShowCopySuccess(true);
    /* istanbul ignore next */
    setTimeout(() => {
      setShowCopySuccess(false);
    }, 2000);
  };

  return (
    <>
      <Box my={2} pl={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          gap={1}
        >
          <Toggle
            label={t("TEAM_INVITE_LINK.SETTINGS.SECTION_TITLE")}
            helpText={t("TEAM_INVITE_LINK.SETTINGS.SECTION_BODY")}
            slotProps={{
              switch: {
                checked: enableInviteSetting,
                onChange: handleSwitchInviteLink,
                "data-testid": "toggle-invite-link",
              },
            }}
          />
          {enableInviteSetting && (
            <Tooltip
              title={t("CORE.COPY")}
              placement="top"
              disabled={!enableInviteSetting}
            >
              <Button
                ref={copyIconRef}
                startIcon={<LinkIcon />}
                variant="outlined"
                color="default-light"
                size="small"
                data-testid="click-to-copy"
                onClick={handleClickLinkToCopy}
              >
                {t("CORE.COPY_LINK")}
              </Button>
            </Tooltip>
          )}
        </Box>

        {enableInviteSetting && (
          <Box
            mt={3}
            ml={6}
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
          >
            <SelectRoleMenu
              enableInviteSetting={enableInviteSetting}
              updateInviteLink={updateInviteLink}
              teamRoles={teamRoles}
              inviteLink={inviteLink}
              filteredDefaultRoleOptions={DEFAULT_ROLE_OPTIONS}
            />
            <Box
              display="flex"
              mt={{ xs: 3, sm: 0 }}
              flexDirection="column"
              alignItems="flex-start"
              flexGrow={1}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <Text
                  variant="textSm"
                  fontWeight="fontWeight.bold"
                  translate="TEAM_INVITE_LINK.NEW_MEMBER_APPROVE"
                />
                <Tooltip
                  placement="top"
                  title={t("TEAM_INVITE_LINK.NEW_MEMBER_APPROVE_TOOLTIP")}
                >
                  <InfoOutlinedIcon fontSize="small" htmlColor="grey.500" />
                </Tooltip>
              </Box>

              <Button
                onClick={handleOpenRequestApproval}
                data-testid="open-requestApproval-menu"
                disabled={!enableInviteSetting}
                color="inherit"
                endIcon={
                  anchorRequestApproval ? <ExpandLess /> : <ExpandMore />
                }
              >
                {t(`CORE.${inviteLink.requestApproval ? "YES" : "NO"}`)}
              </Button>
              <Menu
                keepMounted
                id="request-approval"
                anchorEl={anchorRequestApproval}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={handleCloseRequestApproval}
                open={enableInviteSetting && Boolean(anchorRequestApproval)}
              >
                {APPROVAL_OPTIONS.map((item) => (
                  <MenuItem
                    id={item}
                    key={item}
                    onClick={handleRequestApprovalSelection}
                  >
                    {t(`CORE.${item}`)}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        )}
      </Box>
      <ShareLinkPopper
        placement="right-start"
        wasCopiedSuccessful
        open={showCopySuccess}
        anchorEl={copyIconRef?.current}
      />
    </>
  );
}

TeamInviteLink.propTypes = {
  teamId: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  inviteLink: PropTypes.shape({
    linkId: PropTypes.string.isRequired,
    defaultRole: PropTypes.string.isRequired,
    requestApproval: PropTypes.bool.isRequired,
  }).isRequired,
  updateInviteLink: PropTypes.func.isRequired,
  isMemberRoleEnabled: PropTypes.bool,
  enableInviteSetting: PropTypes.bool.isRequired,
  setEnableInviteSetting: PropTypes.func.isRequired,
};

TeamInviteLink.defaultProps = {
  isMemberRoleEnabled: false,
};

export default TeamInviteLink;
