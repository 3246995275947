import NiceModal from "@ebay/nice-modal-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { CancelOutlined, HelpOutline } from "@mui/icons-material";
import { LoadingButton as Button } from "@mui/lab";
import { IconButton } from "@mui/material";
import CancelUploadDialog from "@workflow/components/CancelUploadDialog/CancelUploadDialog";
import TranscodingHelperDialog from "@workflow/components/TranscodingHelperDialog/TranscodingHelperDialog";
import { instance as fileService } from "@workflow/services/fileService";

import { Box, Text, UploadProgressBar, Tooltip } from "@shared/UIKit";

import { FILE_VERSION_UPLOAD_STATUS } from "@shared/constants/upload";
import { fileProp, UPLOAD_STATES } from "@shared/props/file";

export default function FileCardUploadProgress({ file }) {
  const uploadStatus = file.versions[file.versions.length - 1].uploadStatus;
  const { t } = useTranslation();

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const openConfirmationDialog = () => {
    setIsConfirmationDialogOpen(true);
  };

  const closeConfirmationDialog = () => {
    setIsConfirmationDialogOpen(false);
  };
  const retryTranscoding = useCallback(async () => {
    try {
      setLoading(true);
      await fileService.retryTranscoding(file);
    } finally {
      setLoading(false);
    }
  }, [file]);

  const cancelUpload = useCallback(() => {
    closeConfirmationDialog();
    fileService.cancelUpload(file);
  }, [file]);

  const openHelperDialog = () => {
    NiceModal.show(TranscodingHelperDialog);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" flex={1} gap={0.75}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flex={1}
          px={1}
        >
          {uploadStatus === FILE_VERSION_UPLOAD_STATUS.FAILED ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Text
                variant="textXs"
                color="error.main"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                translate="TRANSCODING.ERROR.UPLOAD_FILE"
              />
              <Tooltip
                title={t("TRANSCODING_RETRY.TOOLTIP")}
                placement="bottom"
              >
                <Button
                  data-testid="retry-transcoding-button"
                  variant="outlined"
                  size="extraSmall"
                  color="error"
                  loading={loading}
                  onClick={retryTranscoding}
                >
                  {t("MONDAY.AUTH.ERROR.CTA")}
                </Button>
              </Tooltip>
            </Box>
          ) : (
            <Box>
              <Text
                variant="textXs"
                color="text.secondary"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {t(
                  `VERSION.${
                    file.progress?.state || UPLOAD_STATES.TRANSCODING
                  }`,
                  {
                    version: file.progress?.versionNumber,
                  }
                )}
                {(file.progress?.state === UPLOAD_STATES.TRANSCODING ||
                  uploadStatus === FILE_VERSION_UPLOAD_STATUS.PROCESSING) && (
                  <IconButton
                    onClick={openHelperDialog}
                    data-testid="file-card-transcoding-helper"
                    size="small"
                  >
                    <HelpOutline fontSize="inherit" />
                  </IconButton>
                )}
              </Text>
            </Box>
          )}

          <Tooltip title={t("PROJECT.FILE.DELETE")} placement="bottom">
            <IconButton
              data-testid="cancel-upload-button"
              onClick={openConfirmationDialog}
              size="small"
            >
              <CancelOutlined fontSize="inherit" />
            </IconButton>
          </Tooltip>
        </Box>
        <UploadProgressBar
          uploadId={file.progress?.uploadId || file.id}
          state={
            file.progress?.state ||
            (uploadStatus === FILE_VERSION_UPLOAD_STATUS.FAILED
              ? UPLOAD_STATES.FAILED
              : UPLOAD_STATES.TRANSCODING)
          }
          variant="fileCard"
        />
      </Box>
      {isConfirmationDialogOpen && (
        <CancelUploadDialog
          cancel={closeConfirmationDialog}
          answer={cancelUpload}
        />
      )}
    </>
  );
}

FileCardUploadProgress.propTypes = {
  file: fileProp.isRequired,
};
