import BackgroundImage1 from "@assets/img/images/onboarding-wizard-background-1.png";
import BackgroundImage2 from "@assets/img/images/onboarding-wizard-background-2.png";
import BackgroundImage3 from "@assets/img/images/onboarding-wizard-background-3.png";
import CompanyLogo1 from "@assets/img/images/onboarding-wizard-company-logo-1.png";
import CompanyLogo2 from "@assets/img/images/onboarding-wizard-company-logo-2.png";
import CompanyLogo3 from "@assets/img/images/onboarding-wizard-company-logo-3.png";
import CompanyLogo4 from "@assets/img/images/onboarding-wizard-company-logo-4.png";
import CompanyLogo5 from "@assets/img/images/onboarding-wizard-company-logo-5.png";
import CompanyLogo6 from "@assets/img/images/onboarding-wizard-company-logo-6.png";
import Customer1 from "@assets/img/images/onboarding-wizard-customer-profile-1.png";
import Customer2 from "@assets/img/images/onboarding-wizard-customer-profile-2.png";
import Customer3 from "@assets/img/images/onboarding-wizard-customer-profile-3.png";
import Customer4 from "@assets/img/images/onboarding-wizard-customer-profile-4.png";
import Customer5 from "@assets/img/images/onboarding-wizard-customer-profile-5.png";
import Customer6 from "@assets/img/images/onboarding-wizard-customer-profile-6.png";

const SIDE_PANEL_CONTENTS = {
  LOGIN: {
    name: "SIXT.SUCCESS_STORIES.ONBOARDING.TITLE",
    position: "SIXT.SUCCESS_STORIES.ONBOARDING.CAPTION",
    text: "SIXT.SUCCESS_STORIES.ONBOARDING.BODY",
    companyLogo: CompanyLogo1,
    profile: Customer1,
    backgroundImage: BackgroundImage1,
  },
  VERIFY_OTP: {
    name: "GROUPM.SUCCESS_STORIES.ONBOARDING.TITLE",
    position: "GROUPM.SUCCESS_STORIES.ONBOARDING.CAPTION",
    text: "GROUPM.SUCCESS_STORIES.ONBOARDING.BODY",
    companyLogo: CompanyLogo2,
    profile: Customer2,
    backgroundImage: BackgroundImage2,
  },
  COMPANY_SIZE: {
    name: "QUEISSER.SUCCESS_STORIES.ONBOARDING.TITLE",
    position: "QUEISSER.SUCCESS_STORIES.ONBOARDING.CAPTION",
    text: "QUEISSER.SUCCESS_STORIES.ONBOARDING.BODY",
    companyLogo: CompanyLogo3,
    profile: Customer3,
    backgroundImage: BackgroundImage3,
  },
  INVITE_TEAM_MEMBERS: {
    customer1: {
      name: "SHARP.SUCCESS_STORIES.ONBOARDING.TITLE",
      position: "SHARP.SUCCESS_STORIES.ONBOARDING.CAPTION",
      text: "SHARP.SUCCESS_STORIES.ONBOARDING.BODY",
      companyLogo: CompanyLogo4,
      profile: Customer4,
    },
    customer2: {
      name: "BBRAUN.SUCCESS_STORIES.ONBOARDING.TITLE",
      position: "BBRAUN.SUCCESS_STORIES.ONBOARDING.CAPTION",
      text: "BBRAUN.SUCCESS_STORIES.ONBOARDING.BODY",
      companyLogo: CompanyLogo5,
      profile: Customer5,
    },
    customer3: {
      name: "KHARABEESH.SUCCESS_STORIES.ONBOARDING.TITLE",
      position: "KHARABEESH.SUCCESS_STORIES.ONBOARDING.CAPTION",
      text: "KHARABEESH.SUCCESS_STORIES.ONBOARDING.BODY",
      companyLogo: CompanyLogo6,
      profile: Customer6,
    },
  },
};

export { SIDE_PANEL_CONTENTS };
