import PropTypes from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  CancelOutlined,
  FlashOnRounded,
  MoreVertOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { AUTOMATION_KEY_VALUES } from "@workflow/constants/automation";

import { Box, InlineBadge } from "@shared/UIKit";

import { projectTemplateProps } from "@shared/props/project";

function ProjectTemplateMenu({
  projectTemplate,
  onDelete,
  openAutomationsDialog,
  ...restProps
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();

  const openMenu = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const deleteHandler = useCallback(() => {
    onDelete();
    closeMenu();
  }, [onDelete, closeMenu]);

  const automationsCount = useMemo(() => {
    return projectTemplate.automations.filter(
      (automation) =>
        automation.enabled &&
        (automation.conditions.step === AUTOMATION_KEY_VALUES.ANY ||
          projectTemplate.steps.some(
            (step) => step.id === automation.conditions.step
          ))
    ).length;
  }, [projectTemplate.automations, projectTemplate.steps]);

  return (
    <Box
      marginLeft={-0.625}
      marginRight={-0.625}
      data-testid="project-template-menu"
    >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={openMenu}
        data-testid="project-template-menu-button"
        size="large"
      >
        <MoreVertOutlined fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        {...restProps}
      >
        <MenuItem
          data-testid="project-automations-button"
          sx={{ display: { md: "none" } }}
          onClick={openAutomationsDialog}
        >
          <ListItemIcon>
            <FlashOnRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText sx={{ mr: 1 }}>
            {t("AUTOMATIONS.BUTTON.TITLE")}
          </ListItemText>
          <InlineBadge
            data-testid="project-automations-button-count"
            variant="contained"
            color="primaryV2"
            size="xs"
          >
            {automationsCount}
          </InlineBadge>
        </MenuItem>
        <MenuItem
          data-testid="project-template-delete-button"
          onClick={deleteHandler}
          data-accent-color="error"
        >
          <ListItemIcon>
            <CancelOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("PROJECT_TEMPLATES.DELETE")}</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}

ProjectTemplateMenu.propTypes = {
  onDelete: PropTypes.func.isRequired,
  openAutomationsDialog: PropTypes.func.isRequired,
  projectTemplate: PropTypes.shape(projectTemplateProps).isRequired,
};

export default ProjectTemplateMenu;
