import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Button, Box } from "@mui/material";
import teamRoleService from "@supporting/services/teamRoleService";
import toastService from "@supporting/services/toast";

import { Dialog, Text } from "@shared/UIKit";

import { roleProp, teamProp } from "@shared/props/team";
import { instance as analytics } from "@shared/services/analytics";
import errorHandlerService from "@shared/services/errorHandler";

const errorToastMessages = {
  ROLE_ALREADY_IN_USE: "USER.ROLE_DELETE_ERROR.TOAST.BODY",
  ROLE_ASSIGNED_TO_INVITE_LINK: "TEAM.ROLE_DELETE_ERROR.TOAST.BODY",
  ROLE_ASSIGNED_TO_DOMAIN: "DOMAIN.ROLE_DELETE_ERROR.TOAST.BODY",
};

function RemoveTeamRoleDialog({ cancel, teamRole, isOpen, team }) {
  const { t } = useTranslation();

  const onRemoveTeamRoleSubmitted = async () => {
    try {
      await teamRoleService.removeTeamRole(team._id, teamRole._id);
      toastService.sendToast({
        title: "TEAM.ROLES.DELETE_SUCCESS.TITLE",
        body: "TEAM.ROLES.DELETE_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            roleName: teamRole.roleName,
          },
        },
      });
      analytics.trackV2({
        action: analytics.ACTION.REMOVED,
        category: analytics.CATEGORY.CUSTOM_ROLES,
      });
    } catch (error) {
      const body = errorToastMessages[error.message];
      if (body) {
        toastService.sendToast({
          title: "USER.ROLE_DELETE_ERROR.TOAST.TITLE",
          body,
          preset: toastService.PRESETS().ERROR_DELAYED,
        });
      } else {
        errorHandlerService.handleError(error);
      }
      cancel();
    }
  };

  const affectedMembers = team.members.filter(
    (member) => member.role._id === teamRole._id
  );

  return (
    <Dialog
      open={isOpen}
      cancel={cancel}
      actions={
        <>
          <Button onClick={cancel} variant="text" color="primaryV2">
            {t("CORE.CANCEL")}
          </Button>
          {affectedMembers.length === 0 && (
            <Button
              onClick={onRemoveTeamRoleSubmitted}
              color="error"
              variant="text"
            >
              {t(`TEAM.ROLES.DELETE.CONFIRM`)}
            </Button>
          )}
        </>
      }
      title={t(`TEAM.ROLES.DELETE.HEADER`)}
    >
      <>
        <Box mb={2.5}>
          <Text variant="body2" color="text.secondary" whiteSpace="pre-line">
            {affectedMembers.length > 0
              ? t("TEAM.ROLES.DELETE.WARNING", { roleName: teamRole.roleName })
              : t("TEAM.ROLES.DELETE.DESCRIPTION")}
          </Text>
        </Box>
        {affectedMembers.length > 0 && (
          <Box py={3} px={5} boxShadow={3} mb={3}>
            {affectedMembers.map(({ user }) => (
              <Text key={user._id} variant="body2">
                {user.displayName}
              </Text>
            ))}
          </Box>
        )}
      </>
    </Dialog>
  );
}

RemoveTeamRoleDialog.propTypes = {
  cancel: PropTypes.func.isRequired,
  teamRole: roleProp.isRequired,
  isOpen: PropTypes.bool.isRequired,
  team: teamProp.isRequired,
};

export default RemoveTeamRoleDialog;
