import PropTypes from "prop-types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { REVIEW_DECISION_STATES } from "@feedback/constants/reviewDecisionState";
import { Check, Close, Replay } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  TextField,
  Divider,
  ListSubheader,
  ListItemText,
  ListItemIcon,
} from "@mui/material";

import { Text } from "@shared/UIKit";

import { automationFieldConditionProps } from "@shared/props/project";

const ICONS = {
  REVIEW_DECISION: {
    [REVIEW_DECISION_STATES.APPROVED]: (
      <Check fontSize="inherit" color="approved" />
    ),
    [REVIEW_DECISION_STATES.NEEDS_CHANGES]: (
      <Replay
        fontSize="inherit"
        sx={{
          transform: "rotate(-45deg)",
        }}
        color="needsChanges"
      />
    ),
    [REVIEW_DECISION_STATES.APPROVED_WITH_CHANGES]: (
      <Check fontSize="inherit" color="approved" />
    ),
    [REVIEW_DECISION_STATES.REJECTED]: (
      <Close fontSize="inherit" color="rejected" />
    ),
  },
};

function TriggerConditionsDropdown({
  condition,
  selected,
  changeHandler,
  isEditable,
}) {
  const { t } = useTranslation();

  const handleOptionChange = useCallback(
    ({ target }) => {
      changeHandler(condition.id, target.value);
    },
    [condition, changeHandler]
  );

  return (
    <Box display="flex" flexDirection="row" justifyContent="center" py={0.75}>
      <Text variant="subtitle2" color="text.secondary" sx={{ flex: 1, mt: 1 }}>
        {t(condition.phraseKey ?? condition.name)}
      </Text>
      <TextField
        select
        size="small"
        variant="filled"
        sx={{ flex: 2 }}
        label={null}
        id={`automation-dropdown-${condition.id}`}
        data-testid={`automation-dropdown-${condition.id}`}
        value={selected}
        onChange={handleOptionChange}
        disabled={!isEditable}
        SelectProps={{
          label: null,
          disableUnderline: true,
          displayEmpty: true,
          renderValue:
            selected !== ""
              ? undefined
              : () => t("AUTOMATIONS.CONDITION.SELECT_AN_OPTION"),
        }}
        inputProps={{
          "data-testid": `automation-dropdown-${condition.id}-input`,
          "aria-label": t("AUTOMATIONS.CONDITION.SELECT_AN_OPTION"),
          sx: {
            py: 0.5, // needed because of a bug in filled variant of MUI
          },
        }}
      >
        {condition?.options?.map((option) => {
          if (option.divider) {
            return <Divider key={option.key} />;
          }
          if (option.categoryName) {
            return (
              <ListSubheader key={option.categoryName} disableSticky>
                {t(option.categoryName)}
              </ListSubheader>
            );
          }

          return (
            <MenuItem
              data-testid={`dropdown-option-${option.value}`}
              key={option.value}
              value={option.value}
              disabled={option.disabled}
            >
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  "& .MuiListItemIcon-root": {
                    minWidth: "unset",
                    marginRight: 1,
                    fontSize: "20px",
                  },
                }}
              >
                {ICONS[condition.key]?.[option.value] && (
                  <ListItemIcon>
                    {ICONS[condition.key][option.value]}
                  </ListItemIcon>
                )}

                <ListItemText>
                  {option.skipTranslation ? option.text : t(option.text)}
                </ListItemText>
              </Box>
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
}

TriggerConditionsDropdown.defaultProps = {
  selected: "",
};

TriggerConditionsDropdown.propTypes = {
  condition: PropTypes.shape(automationFieldConditionProps).isRequired,
  selected: PropTypes.string,
  changeHandler: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default TriggerConditionsDropdown;
