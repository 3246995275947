import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingButton as Button } from "@mui/lab";
import TeamRolePicker from "@supporting/components/TeamRolePicker/TeamRolePicker";
import { TEAM_ROLES } from "@supporting/constants/team";
import { instance as teamService } from "@supporting/services/team";
import toastService from "@supporting/services/toast";

import { Dialog } from "@shared/UIKit";

import { teamProp, teamMemberProp } from "@shared/props/team";
import errorHandlerService from "@shared/services/errorHandler";

function ChangeTeamMemberRoleDialog({ isOpen, teamMember, team, onClose }) {
  const { t } = useTranslation();
  const [state, setState] = useState({
    isConfirmDisabled: true,
    teamMemberName: "",
    selectedTeamRoleId: teamMember.role._id,
    teamMember: "",
    teamRoles: [],
    isSubmitting: false,
  });
  const onTeamRoleSelected = (roleId) => {
    setState({
      ...state,
      selectedTeamRoleId: roleId,
      isConfirmDisabled: roleId === teamMember.role._id,
    });
  };

  const confirmDialog = async () => {
    setState({ ...state, isSubmitting: true });
    try {
      const response = await teamService.assignRoleToTeamMember(
        teamMember.user._id,
        state.selectedTeamRoleId
      );
      const updatedTeamMember = response.members.find(
        (member) => member.user._id === teamMember.user._id
      );
      toastService.sendToast({
        title: "TEAM.ROLES.ASSIGN_SUCCESS.TITLE",
        body: "TEAM.ROLES.ASSIGN_SUCCESS.BODY",
        preset: toastService.PRESETS().SUCCESS,
        translationVariables: {
          body: {
            userName: teamMember.user.displayName,
            roleName: Object.keys(TEAM_ROLES).includes(
              updatedTeamMember.role.roleName
            )
              ? t(`TEAM.ROLES.${updatedTeamMember.role.roleName}`)
              : updatedTeamMember.role.roleName,
          },
        },
      });
    } catch (error) {
      errorHandlerService.handleError(error);
    }
    onClose();
  };

  const actions = (
    <>
      <Button
        variant="text"
        color="primaryV2"
        onClick={onClose}
        data-testid="confirmation-dialog-cancel"
      >
        {t("CORE.CANCEL")}
      </Button>
      <Button
        onClick={confirmDialog}
        color="primary"
        data-testid="change-team-member-role-dialog-button"
        variant="contained"
        disabled={state.isConfirmDisabled}
        loading={state.isSubmitting}
      >
        {t("CORE.CONFIRM")}
      </Button>
    </>
  );

  return (
    <Dialog
      data-testid="change-team-role-dialog"
      open={isOpen}
      title={t("TEAM.ROLES.CHANGE_TEAM_MEMBER_ROLE", {
        teamMemberName: teamMember.user.displayName,
      })}
      cancel={onClose}
      actions={actions}
    >
      <TeamRolePicker
        team={team}
        onTeamRoleSelected={onTeamRoleSelected}
        defaultSelected={state.selectedTeamRoleId}
      />
    </Dialog>
  );
}

ChangeTeamMemberRoleDialog.propTypes = {
  team: teamProp.isRequired,
  teamMember: teamMemberProp.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ChangeTeamMemberRoleDialog;
